<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.625 3.28125H6.83438H3.375C2.96016 3.28125 2.625 3.61641 2.625 4.03125V8.71875C2.625 8.82188 2.70937 8.90625 2.8125 8.90625H3.75V19.9688C3.75 20.3836 4.08516 20.7188 4.5 20.7188H19.5C19.9148 20.7188 20.25 20.3836 20.25 19.9688V8.90625H21.1875C21.2906 8.90625 21.375 8.82188 21.375 8.71875V4.03125C21.375 3.61641 21.0398 3.28125 20.625 3.28125ZM4.21875 4.875V7.3125H19.7812V4.875H4.21875ZM18.6562 8.90625H5.34375V19.125H18.6562V8.90625Z"
      fill="currentColor"
    />
    <path
      d="M8.38947 12.6139V11.0139H15.6105V12.6139H8.38947Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default { name: 'Archive' };
</script>

<style scoped lang="scss" type="text/scss">

</style>
