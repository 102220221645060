<template>
  <div :class="['m-tab', active ? '-active' : '', small ? '-small' : '']">
    <m-btn
      hide-border
      :small="small"
      @click="$emit('click', $event)"
    >
      <div class="_inner">
        <div class="_text">
          {{ title }}
        </div>
        <m-badge
          v-if="badge !== ''"
          :background-color="clr"
          :number="badge"
          class="_badge"
        />
      </div>
    </m-btn>
  </div>
</template>

<script>
export default {
  name: 'MTab',
  props: {
    title: {
      type: String,
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: '',
    },
    small: {
      type: Boolean,
      default: false,
    },
    badge: {
      type: String,
      default: '',
    },
  },
  emits: ['click'],
  computed: {
    clr() {
      if (this.color === '') {
        return this.$colors.red.base;
      }

      return this.color;
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .m-tab {
    display: inline-flex;
    align-items: center;
    height: 4.4rem;

    &.-small {
      height: 3.6rem;
    }

    &.-active {
      border-bottom: 3px solid map_get($grey, 'darken-3');
    }

    ._inner {
      display: flex;
      align-items: center;

      ._badge {
        margin-left: .4rem;
      }
    }
  }
</style>
