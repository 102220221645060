import { Plugin, PluginKey } from 'prosemirror-state';

function textRange(node, from, to) {
  const range = document.createRange();
  range.setEnd(node, to == null ? node.nodeValue.length : to);
  range.setStart(node, from || 0);
  return range;
}

function singleRect(object, bias) {
  const rects = object.getClientRects();
  return !rects.length ? object.getBoundingClientRect() : rects[bias < 0 ? 0 : rects.length - 1];
}

export function coordsAtPos(view, pos) {
  const { node, offset } = view.docView.domFromPos(pos);

  if (node.nodeType === 3 && offset < node.nodeValue.length) {
    return singleRect(textRange(node, offset, offset + 1), -1).top;
  }

  if (!node.firstChild) {
    return node.getBoundingClientRect().top;
  }

  if (offset < node.childNodes.length) {
    const child = node.childNodes[offset];
    return singleRect(child.nodeType === 3 ? textRange(child) : child, -1).top;
  }

  const child = node.childNodes[offset - 1];
  return singleRect(child.nodeType === 3 ? textRange(child) : child, 1).top;
}

class MobileMenu {
  constructor({ options }) {
    this.options = {
      ...{
        element: null,
        keepInBounds: true,
        onUpdate: () => false,
      },
      ...options,
    };
    this.top = 0;
  }

  update(view) {
    const { state } = view;

    const { from } = state.selection;
    const top = coordsAtPos(view, from);

    const parent = this.options.element.offsetParent;

    if (!parent) {
      return;
    }

    const box = parent.getBoundingClientRect();

    this.top = Math.round(top - box.top);

    this.sendUpdate();
  }

  sendUpdate() {
    this.options.onUpdate({ top: this.top });
  }
}

export default function mobileMenu(options) {
  return new Plugin({
    key: new PluginKey('mobile_menu'),
    view(editorView) {
      return new MobileMenu({ editorView, options });
    },
  });
}
