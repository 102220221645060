import { copy } from 'shared/lib/copy';
import { createViewId } from '@/lib/saved-view/saved-view';
import { ref } from 'vue';

const localOverwrites = ref({});
export default function useLocalOverwrite(edge, localStorageKey, localStorage) {
  const initOverwrites = () => {
    try {
      localOverwrites.value[localStorageKey] = {};
      const savedViewsOverwrites = JSON.parse(localStorage.getItem(localStorageKey));
      if (savedViewsOverwrites === null || savedViewsOverwrites === undefined) {
        return;
      }

      localOverwrites.value[localStorageKey] = savedViewsOverwrites;
    } catch (e) {
      // dismiss data from localstorage.
    }
  };

  const valid = (overwrite) => {
    if (overwrite === undefined) {
      return false;
    }

    return true;
  };

  const mergeWithLocalOverwrites = (view) => {
    const overwrite = localOverwrites.value[localStorageKey][createViewId(view)];
    if (!valid(overwrite)) {
      return view;
    }

    return {
      ...view,
      [edge]: overwrite,
    };
  };

  const saveOverwrite = (view) => {
    const cp = copy(localOverwrites.value[localStorageKey]);
    cp[createViewId(view)] = view[edge];
    localOverwrites.value[localStorageKey] = cp;
    localStorage.setItem(localStorageKey, JSON.stringify(cp));
  };

  const removeLocalOverwrite = (view) => {
    const cp = copy(localOverwrites.value[localStorageKey]);
    delete cp[createViewId(view)];
    localOverwrites.value[localStorageKey] = cp;
    localStorage.setItem(localStorageKey, JSON.stringify(cp));
  };

  const findLocalOverwrite = (view) => localOverwrites.value[localStorageKey][createViewId(view)];

  return {
    saveOverwrite,
    removeLocalOverwrite,
    mergeWithLocalOverwrites,
    initOverwrites,
    findLocalOverwrite,
  };
}
