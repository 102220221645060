<template>
  <div
    :class="['collection-item']"
    :data-id="item.uid"
    @mouseover="hover = true"
    @mouseleave="hover = false"
    @contextmenu="handleContextMenuClick"
  >
    <collection-item-inner
      :key="item.uid"
      ref="inner"
      :item="item"
      :hover="hover"
      :dragging="dragging"
      :focused="focused"
      :selected="selected"
      :disabled="disabled"
      :collection-id="collectionId"
      :parent-breadcrumbs="parentBreadcrumbs"
      :dragging-over-bottom="draggingOverBottom"
      :dragging-over-top="draggingOverTop"
      @down="$emit('down')"
      @up="$emit('up')"
      @enter="$emit('enter')"
      @deleted="$emit('deleted')"
    />
  </div>
</template>

<script>
import CollectionItemInner from '@/components/CollectionItemInner.vue';

export default {
  name: 'CollectionItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
    parentBreadcrumbs: {
      type: Array,
      default: () => [],
    },
    collectionId: {
      type: Number,
      required: true,
    },
    dragging: {
      type: Boolean,
      default: false,
    },
    focused: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    draggingOverTop: {
      type: Boolean,
      default: false,
    },
    draggingOverBottom: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['selection-right-click', 'select-row', 'down', 'up', 'enter', 'deleted'],
  components: { CollectionItemInner },
  data() {
    return { hover: false };
  },
  methods: {
    handleContextMenuClick(event) {
      event.preventDefault();
      if (this.selected) {
        this.$emit('selection-right-click', event);
        return;
      }

      this.$emit('select-row', { event, item: this.item });
    },
    focus() {
      this.$refs.inner.focus();
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .collection-item {
    padding: 0 10rem;
    margin: 0 -10rem;
  }
</style>
