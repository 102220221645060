<template>
  <m-content
    padding
    class="after-publish"
  >
    <div class="_emoji">
      🎉
    </div>
    <div class="_text">
      {{
        $t('planningDetails.published', {title: goalSettings.featureNamePlural})
      }}
    </div>
    <div class="_next-steps">
      <div class="_hint">
        {{
          $t('planningDetails.nextStepsHint', {title: goalSettings.featureNamePlural})
        }}
      </div>
      <div class="_page-links">
        <m-content
          v-for="(item, i) in pageLinks"
          :key="i"
          padding-xs
          class="_page-link"
          @click="goToPage(item)"
        >
          <div class="_left">
            <div class="_icon">
              <m-avatar
                v-if="isEmptyIcon(item.icon)"
                :size="18"
                :text="item.title"
                :amount-chars="1"
                :color="getColor(item.color)"
              />
              <m-icon-display
                v-else
                :icon="item.icon"
              />
            </div>
            <div class="_title">
              {{ item.title }}
            </div>
          </div>
          <div class="_right">
            <m-icon
              type="right"
              :color="$colors.grey.lighten2"
            />
          </div>
        </m-content>
      </div>
    </div>
  </m-content>
</template>

<script>
import useGoalSettings from '@/composables/logged-in-user-account/goal-settings';
import useGoals from '@/composables/goal/goals';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import usePersistedGoalCycle from '@/composables/goal-cycle/persisted-goal-cycle';
import useSelectedViews from '@/composables/saved-views/selected-views';
import { GOAL_VIEW } from '@/route-params';
import { buildIcon, buildIconFromEntity, isEmptyIcon } from 'shared/lib/icon';
import { getColor } from 'shared/lib/color-map';
import { propertyType, routeName, viewApplication } from 'shared/constants.json';
import { uniqBy } from 'lodash-es';

export default {
  name: 'AfterPublishGoals',
  props: {
    planning: {
      type: Object,
      required: true,
    },
    selectedGoalIds: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const { getSelectedViewViewIdForApplication } = useSelectedViews();
    const { selectMultiple } = useGoals();
    const { changeSelectedGoalCycles } = usePersistedGoalCycle();
    const { goalSettings } = useGoalSettings();
    const { loggedInUserAccount } = useLoggedInUserAccount();
    return { changeSelectedGoalCycles, selectMultiple, getSelectedViewViewIdForApplication, goalSettings, account: loggedInUserAccount };
  },
  computed: {
    pageLinks() {
      const teams = [];
      let accountGoals = false;

      this.selectMultiple(this.selectedGoalIds).forEach((g) => {
        const options = g.properties.reduce((res, p) => {
          if (p.property.type !== propertyType.space) {
            return res;
          }
          res.push(...p.spaces);
          return res;
        }, []);

        teams.push(...options);

        if (accountGoals || options.length > 0) {
          return;
        }

        accountGoals = true;
      });

      const res = [
        ...uniqBy(teams, (o) => o.uid).map((o) => ({
          icon: buildIconFromEntity(o),
          title: o.title,
          color: o.color,
          to: {
            name: routeName.spaceDetails,
            params: { optionId: o.uid },
            query: { view: GOAL_VIEW },
            viewId: this.getSelectedViewViewIdForApplication({
              application: viewApplication.goalSpace,
              space: o,
            }).value,
          },
        })),
      ];
      if (accountGoals) {
        res.push({
          icon: buildIcon(this.companyImage),
          title: this.account.companyName,
          to: {
            name: routeName.accountSpace,
            query: { view: GOAL_VIEW },
            viewId: this.getSelectedViewViewIdForApplication({ application: viewApplication.goalAccount }).value,
          },
        });
      }

      return res;
    },
    companyImage() {
      if (this.account.companyImage === null) {
        return '';
      }
      return this.account.companyImage.getURL;
    },
  },
  methods: {
    getColor,
    isEmptyIcon,
    goToPage(item) {
      if (this.planning.goalCycles.length > 0) {
        this.changeSelectedGoalCycles(this.planning.goalCycles);
      }
      this.$router.push(item.to);
    },
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  @import "shared/assets/scss/box-shadow";

  .after-publish {
    display: flex;
    flex-direction: column;
    align-items: center;

    ._emoji {
      font-size: 6rem;
    }

    ._text {
      font-size: $font-size-6;
      font-weight: $font-weight-semibold;
    }

    ._next-steps {
      margin-top: 2rem;

      ._hint {
        margin-bottom: 1rem;
        color: $font-color-secondary;
      }

      ._page-links {
        display: flex;
        flex-direction: column;
        align-items: center;

        ._page-link {
          display: flex;
          align-items: center;
          width: 30rem;
          margin-bottom: .8rem;
          color: $font-color-primary;
          cursor: pointer;
          border-radius: $default-border-radius;

          @include box_shadow(1);

          &:hover {
            background-color: $hover-color;
          }

          ._left {
            display: flex;
            align-items: center;

            ._title {
              margin-left: .8rem;
            }
          }

          ._right {
            margin-left: auto;
          }
        }
      }
    }
  }
</style>
