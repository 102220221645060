<template>
  <i18n-t
    :keypath="`oauthError.emailNotRegistered.${provider}`"
    tag="div"
  >
    <template #email>
      <span>{{ email }}</span>
    </template>
    <template #signup>
      <a
        :target="target"
        href="/#/signup"
      >
        app.mooncamp.com/#/signup
      </a>
    </template>
  </i18n-t>
</template>

<script>
export default {
  name: 'OAuthError',
  props: {
    email: {
      type: String,
      required: true,
    },
    provider: {
      type: String,
      required: true,
    },
    signupNewWindow: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    target() {
      if (this.signupNewWindow) {
        return '_blank';
      }
      return '_self';
    },
  },
};
</script>
