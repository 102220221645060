<template>
  <div
    class="update-divider"
  >
    <m-content
      :padding-x="6"
      :padding-y="4"
      class="_date"
    >
      <div class="_inner">
        {{ text }}
      </div>
    </m-content>
  </div>
</template>

<script>
import { DateTime } from 'luxon';

export default {
  name: 'UpdateDivider',
  props: {
    date: {
      type: String,
      required: true,
    },
  },
  computed: {
    text() {
      const d = DateTime.fromISO(this.date);
      if (DateTime.local().diff(d).as('weeks') < 2) {
        return this.$t('updateDivider.lastWeek');
      }

      return this.$t('updateDivider.weekOf', { date: d.toLocaleString(DateTime.DATE_MED) });
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .update-divider {
    display: flex;
    align-items: center;
    justify-content: center;

    ._date {
      width: 20rem;
      background-color: white;
      border: 1px solid map_get($grey, 'lighten-3');
      border-radius: 1.4rem;

      ._inner {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: $font-size-3;
        font-weight: $font-weight-semibold;
        color: $font-color-primary;
      }
    }
  }
</style>
