<template>
  <div class="saved-view-info">
    <div class="_label">
      {{ label }}
    </div>
    <div class="_view">
      <saved-view-title
        :view="view"
      />
    </div>
  </div>
</template>

<script>
import SavedViewTitle from '@/components/list/SavedViewTitle.vue';
import useAccess from '@/composables/access/access';

export default {
  name: 'SavedViewInfo',
  props: {
    view: {
      type: Object,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
  },
  setup() {
    const { userHasRight } = useAccess();
    return { userHasRight };
  },
  components: { SavedViewTitle },
};
</script>

<style scoped lang="scss" type="text/scss">
  .saved-view-info {
    display: flex;
    align-items: center;

    ._privacy {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: .2rem;
    }

    ._label {
      margin-right: .8rem;
    }

    ._view {
      display: flex;
      align-items: center;

      ._text {
        max-width: 15rem;
        margin-right: .8rem;
        margin-left: .6rem;
        font-weight: $font-weight-semibold;
      }
    }
  }
</style>
