<template>
  <div class="stat-fact">
    <div class="_label">
      {{ label }}
    </div>
    <div :class="['_value', loading ? '-loading' : '']">
      {{ value }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'StatFact',
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .stat-fact {
    font-weight: $font-weight-semibold;

    ._label {
      color: $font-color-secondary;
    }

    ._value {
      margin-top: .8rem;
      font-size: $font-size-h1;

      &.-loading {
        color: $font-color-tertiary;
      }
    }
  }

</style>
