<template>
  <m-dropdown
    v-model:value="showMenu"
    :title="$t('compareToPrevious.title')"
    class="compare-to-previous"
    :block="component === 'card-item' || fullWidth"
    :disabled="disabled"
  >
    <template v-if="component === 'card-item'">
      <m-card-item
        class="_card"
        icon="last-update"
        :clickable="!disabled"
        tooltip-placement="left"
        :tooltip="disabled ? $t('documentListHeader.lockedHint') : ''"
        @click="showMenu = true"
      >
        <div class="_left">
          {{ $t('compareToPrevious.title') }}
        </div>
        <template #right>
          <div
            class="_right"
            @click="showMenu = true"
          >
            <div class="_text">
              {{ valueText }}
            </div>
            <div class="_icon">
              <m-icon
                type="down"
                :color="$colors.grey.lighten1"
                size="11"
              />
            </div>
          </div>
        </template>
      </m-card-item>
    </template>
    <template v-else-if="component === 'button'">
      <m-btn
        ref="button"
        class="_btn"
        small
        hide-border
        light
        :block="fullWidth"
        :disabled="disabled"
        @click="showMenu = true"
      >
        <div class="_left">
          {{ $t('compareToPrevious.title') }}
        </div>
        <div class="_right">
          {{ valueText }}
        </div>
      </m-btn>
    </template>
    <template v-else-if="component === 'focusable'">
      <m-focusable
        type="clickable"
        :full-width="fullWidth"
      >
        {{ valueText }}
        <template #suffix>
          <m-icon
            type="down"
            size="11"
            :color="$colors.grey.lighten1"
          />
        </template>
      </m-focusable>
    </template>
    <template #overlay>
      <m-card
        no-padding
        list
        class="_compare-to-previous-overlay"
      >
        <m-card-item
          v-for="item in items"
          :key="item.value"
          class="_item"
          @click="updateValue(item.value)"
        >
          <div class="_label">
            {{ item.text }}
          </div>
          <div
            v-if="item.value !== 'none'"
            class="_sub-label"
          >
            ({{ formatDate(item.date) }})
          </div>
          <template #right>
            <m-icon
              v-if="value.value === item.value"
              type="check"
            />
          </template>
        </m-card-item>
        <m-dropdown
          v-model:value="showDatePicker"
          block
          :title="$t('compareToPrevious.pickADate')"
        >
          <m-card-item @click="openCustomDate">
            <div class="_label">
              {{ $t('compareToPrevious.customDate') }}
            </div>
            <div
              v-if="value.date !== undefined && value.date !== null"
              class="_sub-label"
            >
              ({{ formatDate(DateTime.fromISO(value.date)) }})
            </div>
            <template #right>
              <m-icon
                v-if="value.value === CUSTOM_DATE"
                type="check"
              />
            </template>
          </m-card-item>
          <template #overlay>
            <m-date-picker
              :value="dateValue"
              :date-time="DateTime"
              auto-focus
              full-width
              close-on-click
              @input="updateDate"
            >
              <template #trigger />
            </m-date-picker>
          </template>
        </m-dropdown>
      </m-card>
    </template>
  </m-dropdown>
</template>

<script>
import { CUSTOM_DATE, NONE, ONE_DAY_AGO, ONE_MONTH_AGO, ONE_WEEK_AGO } from '@/lib/constants';
import { DateTime } from 'luxon';
import { compareToDateTime } from '@/lib/compare-to';
import { findInArray } from 'shared/lib/array/array';
import { fromISO, toISO } from 'shared/lib/time';

export default {
  name: 'CompareToPrevious',
  props: {
    component: {
      type: String,
      default: 'button',
    },
    value: {
      type: Object,
      default: () => ({ value: NONE, date: null }),
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input', 'update:value'],
  data() {
    return {
      showMenu: false,
      showDatePicker: false,
      DateTime,
      initiated: false,
      CUSTOM_DATE,
    };
  },
  computed: {
    dateValue() {
      return fromISO(this.value.date);
    },
    items() {
      return [
        {
          text: this.$t('compareToPrevious.none'),
          value: NONE,
        },
        {
          text: this.$t('compareToPrevious.oneDayAgo'),
          value: ONE_DAY_AGO,
          date: compareToDateTime({ value: ONE_DAY_AGO }),
        },
        {
          text: this.$t('compareToPrevious.oneWeekAgo'),
          value: ONE_WEEK_AGO,
          date: compareToDateTime({ value: ONE_WEEK_AGO }),
        },
        {
          text: this.$t('compareToPrevious.oneMonthAgo'),
          value: ONE_MONTH_AGO,
          date: compareToDateTime({ value: ONE_MONTH_AGO }),
        },
      ];
    },
    valueText() {
      if (this.value.value === CUSTOM_DATE) {
        return this.formatDate(compareToDateTime({ value: CUSTOM_DATE, date: this.value.date }));
      }

      return findInArray({ haystack: this.items, needle: this.value.value, key: 'value' }).text;
    },
  },
  methods: {
    openCustomDate() {
      this.showDatePicker = true;
      this.updateValue(CUSTOM_DATE);
    },
    formatDate(date) {
      return date.toLocaleString(DateTime.DATE_MED);
    },
    updateValue(val) {
      if (val === CUSTOM_DATE && (this.value.date === undefined || this.value.date === null)) {
        return;
      }
      this.$emit('input', { ...this.value, value: val });
      this.$emit('update:value', { ...this.value, value: val });
    },
    updateDate(val) {
      this.showDatePicker = false;
      this.$emit('input', { value: CUSTOM_DATE, date: toISO(val) });
      this.$emit('update:value', { value: CUSTOM_DATE, date: toISO(val) });
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .compare-to-previous {
    ._card {
      ._right {
        display: flex;
        align-items: center;

        ._text {
          color: $font-color-secondary;
        }

        ._icon {
          margin-left: .4rem;
        }
      }
    }

    ._btn {
      justify-content: space-between;

      ._left {
        margin-right: .4rem;
      }

      ._right {
        color: $font-color-secondary;
      }
    }
  }

  ._compare-to-previous-overlay {
    ._sub-label {
      margin-left: .4rem;
      color: $font-color-tertiary;
    }
  }

  ._date-picker-overlay {
    min-width: 20rem;

    ._actions {
      margin-top: .4rem;
    }
  }
</style>
