<template>
  <div
    ref="formContent"
    class="form-content"
  >
    <div class="_container">
      <div
        :class="['_distance-holder', hasQuestionSelector ? '-small' : '']"
        :style="distanceHolderStyle"
      />
      <div
        v-for="(orderItem, index) in form.orderItems"
        :key="orderItem.field.id"
        :ref="'field_' + index"
        :class="fieldClasses"
      >
        <opinion-scale
          v-if="orderItem.field.type === opinionScale"
          :field="orderItem.field"
          :question-index="index"
          :active="index === currentQuestionIndex"
          :lang="lang"
          @question-selected="goToNextQuestion"
          @answer-changed="answerChanged"
        />
        <long-text
          v-if="orderItem.field.type === longText"
          :field="orderItem.field"
          :question-index="index"
          :active="index === currentQuestionIndex"
          :lang="lang"
          @question-selected="goToNextQuestion"
          @answer-changed="answerChanged"
        />
      </div>
      <div
        :class="['_distance-holder', hasQuestionSelector ? '-small' : '']"
        :style="bottomDistanceHolderStyle"
      />
    </div>
    <submit-area
      :active="currentQuestionIndex === form.orderItems.length"
      @send="send"
    />
  </div>
</template>

<script>
import LongText from 'shared/components/internal/form/LongText.vue';
import OpinionScale from 'shared/components/internal/form/OpinionScale.vue';
import SubmitArea from 'shared/components/internal/form/SubmitArea.vue';
import { fieldType } from 'shared/constants.json';

export default {
  name: 'FormContent',
  props: {
    form: {
      type: Object,
      required: true,
    },
    currentQuestionIndex: {
      type: Number,
      required: true,
    },
    missingAnswers: {
      type: Array,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
    hasQuestionSelector: {
      type: Boolean,
      required: true,
    },
    formHeight: {
      type: Number,
      required: true,
    },
    width: {
      type: String,
      required: true,
    },
  },
  emits: ['go-to-next-question', 'answer-changed', 'question-heights-updated', 'send'],
  components: {
    LongText,
    OpinionScale,
    SubmitArea,
  },
  data() {
    return {
      opinionScale: fieldType.opinionScale,
      longText: fieldType.longText,
      questionHeights: [],
    };
  },
  computed: {
    fieldClasses() {
      const width = parseInt(this.width, 10);
      return [
        '_fields',
        width < 960 ? '-small' : '',
      ];
    },
    distanceHolderStyle() {
      return { height: `${(this.formHeight / 2) - (this.questionHeights[0] / 2)}px` };
    },
    bottomDistanceHolderStyle() {
      return { height: `${this.questionHeights[this.questionHeights.length - 1]}px` };
    },
  },
  methods: {
    goToNextQuestion(payload) {
      this.$emit('go-to-next-question', payload);
    },
    answerChanged(payload) {
      this.$emit('answer-changed', payload);
    },
    updateHeights() {
      const questionHeights = [];
      this.form.orderItems.forEach((_, index) => {
        const element = this.$refs[`field_${index}`];
        questionHeights.push(element.clientHeight);
      });
      this.$emit('question-heights-updated', questionHeights);
      this.questionHeights = questionHeights;
      return questionHeights;
    },
    send() {
      this.$emit('send');
    },
  },
  watch: {
    form: {
      handler() {
        setTimeout(this.updateHeights, 100);
      },
      deep: true,
    },
  },
  mounted() {
    this.updateHeights();

    window.addEventListener('resize', this.updateHeights);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateHeights);
  },
};
</script>

<style scoped lang="scss">
  .form-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: auto;
    margin-left: auto;
    background: white;

    ._container {
      width: 95%;
      max-width: 70rem;

      ._empty-list {
        text-align: center;
      }
    }

    ._distance-holder {
      height: calc(50vh - 17rem);

      &.-small {
        height: calc(50vh - 25rem);
      }
    }

    ._fields {
      padding: 3rem;
    }
  }
</style>
