<template>
  <div class="duration-picker">
    <m-input-number
      v-model:value="amount"
      type="number"
      class="_amount"
    />
    <m-select
      v-model:value="unit"
      :items="unitItems"
      class="_select"
    />
  </div>
</template>

<script>
import { Hour, Minute } from 'shared/lib/time';

const ONE_DAY = 24 * Hour;
const ONE_HOUR = Hour;
const ONE_MINUTE = Minute;

export default {
  name: 'DurationPicker',
  props: {
    value: {
      type: Number,
      default: ONE_DAY, // 1 day
    },
  },
  data() {
    return {
      amount: 1,
      unit: ONE_DAY,
    };
  },
  computed: {
    unitItems() {
      return [
        {
          text: this.$t('durationPicker.minute', this.amount),
          value: ONE_MINUTE,
        },
        {
          text: this.$t('durationPicker.hour', this.amount),
          value: ONE_HOUR,
        },
        {
          text: this.$t('durationPicker.day', this.amount),
          value: ONE_DAY,
        },
      ];
    },
  },
  methods: {
    setDuration(val) {
      switch (true) {
        case val % ONE_DAY === 0:
          this.unit = ONE_DAY;
          break;
        case val % ONE_HOUR === 0:
          this.unit = ONE_HOUR;
          break;
        case val % ONE_MINUTE === 0:
          this.unit = ONE_MINUTE;
          break;
        default:
      }
      this.amount = val / this.unit;
    },
  },
  emits: ['update:value', 'input'],
  watch: {
    value(val, oldVal) {
      if (val === oldVal) {
        return;
      }
      this.setDuration(val);
    },
    unit(val) {
      this.$emit('update:value', val * this.amount);
      this.$emit('input', val * this.amount);
    },
    amount(val) {
      this.$emit('update:value', val * this.unit);
      this.$emit('input', val * this.unit);
    },
  },
  created() {
    this.setDuration(this.value);
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .duration-picker {
    display: flex;

    ._amount {
      max-width: 10rem;
      margin-right: 1rem;
    }

    ._select {
      max-width: 16rem;
    }
  }
</style>
