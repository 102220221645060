<template>
  <top-bar class="create-form-template-top-bar">
    <template #left>
      <div

        class="_left"
      >
        <div class="_btn">
          <m-btn
            :to="to"
            icon="arrow-left"
            hide-border
            fab
          />
        </div>
        <div
          class="_last"
        >
          <div class="_icon">
            <icon-picker
              :icon="buildIcon(formTemplate.icon)"
              show-placeholder
              size="2rem"
              class="_emoji-picker"
              :style="{ display: 'flex' }"
              tooltip-placement="bottom"
              @change="updateIcon"
            />
          </div>
          <top-bar-input
            :value="formTemplate === null ? '-' : formTemplate.title"
            :update-method="updateTitle"
          />
        </div>
        <m-tooltip
          placement="bottom"
        >
          <div>
            <m-tag
              large
              :type="released ? 'success': ''"
              :title="released ? $t('createFormTemplateTopBar.released') : $t('createFormTemplateTopBar.notReleased')"
            />
          </div>
          <template #title>
            {{ released ? $t('createFormTemplateTopBar.releaseTooltip') : $t('createFormTemplateTopBar.draftTooltip') }}
          </template>
        </m-tooltip>
      </div>
    </template>
    <template #right>
      <div

        class="_right"
      >
        <div class="_action">
          <m-select
            v-if="languages.length > 1"
            :items="languages"
            :value="lang"
            class="_select-language"
            hide-details
            height="2rem"
            background-color="white"
            @change="changeLanguage"
          >
            <template #item="content">
              <div

                class="_select-option"
              >
                {{ getName(content.item) }}
              </div>
            </template>
          </m-select>
          <m-btn
            class="_action-item"
            @click="toggleRelease"
          >
            {{ released ? $t('createFormTemplateTopBar.setToDraft') : $t('createFormTemplateTopBar.release') }}
          </m-btn>
          <m-tooltip placement="bottomRight">
            <span>
              <m-btn
                color="primary"
                text
                :disabled="!unsavedChangesExist"
                :loading="loading"
                @click="saveFormTemplate"
              >
                <template v-if="unsavedChangesExist">
                  {{ $t('general.save') }}
                </template>
                <template v-else>
                  {{ $t('general.saved') }}
                </template>
              </m-btn>
            </span>
            <template #title>
              <div

                :style="{ minWidth: '15rem' }"
              >
                <span v-if="unsavedChangesExist">{{ $t('createForm.needsSaving') }}</span>
                <span v-else>{{ $t('createForm.allSaved') }}</span>
              </div>
            </template>
          </m-tooltip>
        </div>
      </div>
    </template>
  </top-bar>
</template>

<script>
import IconPicker from '@/components/IconPicker.vue';
import TopBar from '@/components/navigation/TopBar.vue';
import TopBarInput from '@/components/TopBarInput.vue';
import colors from 'shared/colors';
import useAddFormEditor from '@/composables/form/add-form-editor';
import { DateTime } from 'luxon';
import { buildIcon } from 'shared/lib/icon';
import { formTemplateById } from 'shared/api/query/form-template';
import { formTemplate as formTemplateConfig } from 'shared/api/query/configs.json';
import { formTemplateType, routeName } from 'shared/constants.json';
import { mapActions, mapState } from 'vuex';
import { nameByCode } from '@/lib/language';

export default {
  name: 'CreateFormTemplateTopBar',
  props: {
    lang: {
      type: String,
      required: true,
    },
    languages: {
      type: Array,
      required: true,
    },
    formTemplate: {
      type: Object,
      default: null,
    },
    formTemplateId: {
      type: Number,
      required: true,
    },
  },
  emits: ['language-changed'],
  components: {
    TopBarInput,
    TopBar,
    IconPicker,
  },
  setup() {
    const { appendEditor } = useAddFormEditor();
    return { appendEditor };
  },
  data() {
    return {
      loading: false,
      sendBtnLoading: false,
      showActivation: false,
      showDeactivation: false,
      colors,
      formTemplateConfig,
      formTemplateById,
    };
  },
  computed: {
    ...mapState({ unsavedChangesExist: (state) => state.unsavedFormTemplateChangesExist }),
    to() {
      if (this.formTemplate.type === formTemplateType.instantFeedback) {
        return { name: routeName.instantFeedbackTemplateList };
      }
      return { name: routeName.formTemplateList };
    },
    released() {
      return this.formTemplate.releasedAt !== null;
    },
  },
  methods: {
    buildIcon,
    ...mapActions([
      'updateEntity',
    ]),
    changeLanguage(value) {
      this.$emit('language-changed', { lang: value });
    },
    updateTitle(event) {
      this.$store.commit('FORM_TEMPLATE_TITLE_UPDATED', { title: event.srcElement.value });
    },
    updateIcon(val) {
      this.$store.commit('FORM_TEMPLATE_ICON_UPDATED', { icon: val.value });
    },
    toggleRelease() {
      if (this.formTemplate.releasedAt === null) {
        this.release();
        return;
      }
      this.unRelease();
    },
    unRelease() {
      const func = this.unReleaseFormTemplate;
      this.$confirm({
        title: this.$t('createFormTemplateTopBar.unReleasePrompt'),
        okText: this.$t('general.okay'),
        okType: 'warning',
        maskClosable: true,
        cancelText: this.$t('general.cancel'),
        onOk() {
          func();
        },
      });
    },
    release() {
      const releaseMethod = this.releaseFormTemplate;
      this.$confirm({
        title: this.$t('createFormTemplateTopBar.releasePrompt'),
        okText: this.$t('createFormTemplateTopBar.buttonLabel'),
        okType: 'warning',
        maskClosable: true,
        cancelText: this.$t('general.cancel'),
        onOk() {
          releaseMethod();
        },
      });
    },
    releaseFormTemplate() {
      this.update({
        formTemplate: {
          ...this.appendEditor(this.formTemplate),
          releasedAt: DateTime.local().toISO(),
        },
      });
    },
    unReleaseFormTemplate() {
      this.update({
        formTemplate: {
          ...this.appendEditor({
            ...this.formTemplate,
            tags: this.formTemplate.tags.map((v) => {
              if (typeof v.uid !== 'number') {
                return {
                  ...v,
                  uid: 0,
                };
              }

              return v;
            }),
          }),
          releasedAt: null,
        },
      });
    },
    saveFormTemplate() {
      this.update({
        formTemplate: this.appendEditor({
          ...this.formTemplate,
          tags: this.formTemplate.tags.map((v) => {
            if (typeof v.uid !== 'number') {
              return {
                ...v,
                uid: 0,
              };
            }

            return v;
          }),
        }),
      });
    },
    update({ formTemplate }) {
      this.loading = true;
      this.updateEntity({ entity: formTemplate, model: formTemplateConfig.model, entityById: formTemplateById })
        .then((response) => {
          this.loading = false;
          if (response.status !== 200) {
            this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
            return;
          }
          this.$store.commit('UNSAVED_FORM_TEMPLATE_CHANGES_EXIST_CHANGED', false);
          this.$showSnackbar({ color: 'success', message: this.$t('success.saved') });
        });
    },
    getName(code) {
      return nameByCode(code);
    },
  },
  watch: {
    formTemplate: {
      handler(newValue, oldValue) {
        if (oldValue === null) {
          return;
        }
        this.$store.commit('UNSAVED_FORM_TEMPLATE_CHANGES_EXIST_CHANGED', true);
      },
      deep: true,
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .create-form-template-top-bar {
    ._left {
      display: flex;
      align-items: center;
      font-weight: 400;

      ._btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: $side-bar-collapsed-width;
        height: $side-bar-collapsed-width;
        padding: .85rem;
      }
    }

    ._right {
      display: flex;
      align-items: center;
      padding: 0 1.6rem;

      ._action {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        ._action-item {
          margin-right: .8rem;
        }

        ._select-language {
          padding: 0;
          margin-right: .8rem;
        }
      }
    }

    ._last {
      display: flex;
      align-items: center;
      margin-right: .8rem;

      ._icon {
        width: 2rem;
        height: 2rem;
        margin-right: .4rem;

        ._emoji-picker {
          width: 2rem;
          height: 2rem;
        }
      }
    }
  }
</style>
