<template>
  <div class="ms-teams-team-details">
    <template v-if="otherEmail != null">
      <m-s-error
        :email="otherEmail"
        @exit="otherEmail = null"
      />
    </template>
    <template v-else>
      <div
        v-if="noTeam"
        class="_no-team"
      >
        <div class="_hint">
          🙃 {{ $t('msTeamsTeamDetails.hint') }}
        </div>
        <i18n-t
          v-if="canEditUser"
          tag="div"
          class="_user-link"
          keypath="msTeamsTeamDetails.employeePage"
        >
          <template #employeePage>
            <a href="/#/users">{{ $t('msTeamsTeamDetails.employeePageLinkDesc') }}</a>
          </template>
        </i18n-t>
        <div
          v-else
          class="_request"
        >
          {{ $t('msTeamsTeamDetails.adminRequest') }}
        </div>
      </div>
      <template v-else-if="isCreateUpdateView">
        <m-s-teams-update-editor />
      </template>
      <template v-else>
        <space-details-template
          :key="space.uid"
          :tabs="tabs"
          :property="property"
          :space="space"
          :allow-edit="false"
          :show-mobile-menu-toggle="false"
          show-top-bar
          show-my-teams-selector
          show-logout
        />
      </template>
    </template>
  </div>
</template>

<script>
import MSError from '@/components/msteams/MSError.vue';
import MSTeamsUpdateEditor from '@/components/MSTeamsUpdateEditor.vue';
import SpaceDetailsTemplate from '@/views/SpaceDetailsTemplate.vue';
import useAccess from '@/composables/access/access';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useMSTeams from '@/composables/msteams';
import useMSTeamsConfiguration from '@/composables/logged-in-user/ms-teams-configuration';
import useProperties from '@/composables/property/property';
import useViewNavigator from '@/composables/saved-views/navigator';
import { CREATE_UPDATE_VIEW, GOAL_FEED_VIEW, GOAL_INSIGHT_VIEW, GOAL_VIEW, UPDATE_VIEW, USER_VIEW, VIEW } from '@/route-params';
import { accessGroupFlag, moduleFlag, propertyApplication, routeName, viewApplication } from 'shared/constants.json';
import { getQueryParam } from '@/lib/route';

export default {
  name: 'MSTeamsTeamDetails',
  setup() {
    const { accountHasFeature, userHasRight } = useAccess();
    const { loggedInUserAccount } = useLoggedInUserAccount();
    const { myTeams } = useLoggedInUser();
    const { selectedTeam } = useMSTeamsConfiguration();
    const { linkToView } = useViewNavigator();
    const { spaceProperty } = useProperties();
    const { handleTabAction, msTeamsError } = useMSTeams();

    return {
      accountHasFeature,
      userHasRight,
      account: loggedInUserAccount,
      linkToView,
      handleTabAction,
      msTeamsError,
      myTeams,
      property: spaceProperty,
      space: selectedTeam,
    };
  },
  props: {
    optionId: {
      type: [String, Number],
      default: '',
    },
  },
  components: {
    SpaceDetailsTemplate,
    MSTeamsUpdateEditor,
    MSError,
  },
  data() {
    return {
      noTeam: false,
      routeName,
      otherEmail: null,
    };
  },
  computed: {
    canEditUser() {
      return this.userHasRight([accessGroupFlag.propertyWriteAccess]);
    },
    titleSuggestion() {
      switch (true) {
        case this.isGoalView:
          return `${this.title} - ${this.account.goalSettings.featureNamePlural}`;
        case this.isUpdateView:
          return `${this.title} - ${this.$t('navigation.updatesExplorer')}`;
        case this.isGoalInsightView:
          return `${this.title} - ${this.$t('navigation.goalInsights')}`;
        default:
          return '';
      }
    },
    tabs() {
      const tabs = [];
      if (this.property === null) {
        return tabs;
      }
      if (this.property.usedFor.includes(propertyApplication.goal) && this.accountHasFeature([moduleFlag.goals])) {
        tabs.push({
          title: this.account.goalSettings.featureNamePlural,
          to: this.linkToView(GOAL_VIEW, viewApplication.goalSpace, this.space),
          view: GOAL_VIEW,
        });
      }
      if (this.property.usedFor.includes(propertyApplication.goal) && this.accountHasFeature([moduleFlag.goals])) {
        tabs.push({
          title: this.$t('navigation.feed'),
          to: this.linkToView(GOAL_FEED_VIEW, viewApplication.updateFeedSpace, this.space),
          view: GOAL_FEED_VIEW,
        });
      }
      if (this.property.usedFor.includes(propertyApplication.update) && this.accountHasFeature([moduleFlag.updates])) {
        tabs.push({
          title: this.$t('navigation.updatesExplorer'),
          to: this.linkToView(UPDATE_VIEW, viewApplication.updateSpace, this.space),
          view: UPDATE_VIEW,
        });
      }
      if (this.property.usedFor.includes(propertyApplication.goal) && this.accountHasFeature([moduleFlag.goals]) && this.userHasRight([accessGroupFlag.goalDashboardView])) {
        tabs.push({
          title: this.$t('navigation.goalInsights'),
          to: this.linkToView(GOAL_INSIGHT_VIEW, viewApplication.insightSpace, this.space),
          view: GOAL_INSIGHT_VIEW,
        });
      }
      if (this.property.usedFor.includes(propertyApplication.user)) {
        tabs.push({
          title: this.$t('navigation.users'),
          to: this.linkToView(USER_VIEW),
          view: USER_VIEW,
        });
      }
      return tabs;
    },
    currentView() {
      return getQueryParam(this.$route, VIEW);
    },
    isCreateUpdateView() {
      return this.currentView === CREATE_UPDATE_VIEW;
    },
  },
  methods: {
    init() {
      if (this.myTeams.length === 0) {
        this.noTeam = true;
      }
      if (this.currentView === '') {
        this.initRoute();
      }
    },
    initRoute() {
      if (this.tabs.length > 0) {
        this.$router.replace(this.tabs[0].to);
      }
    },

  },
  created() {
    this.init();
    this.handleTabAction('team_okrs').then((error) => {
      if (error === null) {
        return;
      }

      switch (error.error) {
        case this.msTeamsError.otherEmail:
          this.otherEmail = error.data;
          break;
        case this.msTeamsError.connectionError:
          this.$showSnackbar({ color: 'error', message: this.$t('msTeams.connectionError') });
          break;
        default:
          this.$showSnackbar({ color: 'success', message: this.$t('msTeams.connectionEstablished') });
          break;
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    if ([routeName.teamsAccountOkrs, routeName.teamsTeamOkrs, routeName.logIn, routeName.logout, routeName.teamsLogin].includes(to.name)) {
      next();
      return;
    }
    window.open(`/#${to.fullPath}`);
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .ms-teams-team-details {
    ._no-team {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100vh;
      font-size: $font-size-8;
    }
  }
</style>
