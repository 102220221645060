<template>
  <div class="product-plan-selection">
    <pricing-table class="_pricing-table" />
  </div>
</template>

<script>
import PricingTable from '@/components/plans/PricingTable.vue';

export default {
  name: 'ProductPlanSelection',
  components: { PricingTable },
};
</script>

<style scoped lang="scss" type="text/scss">
  @import 'shared/assets/scss/padding';

  .product-plan-selection {
    position: relative;
    width: 100%;

    ._pricing-table {
      @include layoutPaddingX();
    }
  }
</style>
