import jsonwebtoken from '@/lib/jwt';

const findToken = (fragment, url) => new URLSearchParams(fragment.split('?')[1]).getAll('s').filter((t) => jsonwebtoken.decode(t).pat === url);

export const hasSignedRequest = (fragment, url) => {
  if (fragment.split('?').length === 1) {
    return false;
  }

  try {
    return findToken(fragment, url).length > 0;
  } catch (e) {
    return false;
  }
};

export const token = (fragment, url) => findToken(fragment, url)[0];
