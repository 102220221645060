import { RESULT } from 'shared/api/query/constants';
import { copy } from 'shared/lib/copy';

const referenced = (attr, gq) => {
  if (!(gq.children instanceof Array)) {
    return false;
  }

  const children = gq.children;

  for (let i = 0; i < children.length; i++) {
    const e = children[i];
    if (e.alias !== '') {
      if (e.alias === attr) {
        return true;
      }
      continue;
    }

    if (e.attr === attr) {
      return true;
    }
  }

  return false;
};

const copyUnreferencedKeys = (source, q) => Object.keys(source).reduce((res, next) => {
  if (!referenced(next, q)) {
    res[next] = source[next];
  }

  return res;
}, {});

export const getNodeName = (gq) => {
  if (typeof gq.alias !== 'undefined' && gq.alias !== '') {
    return gq.alias;
  }

  return gq.attr;
};

const setDefault = (q, node) => {
  // 1. if at []any, apply gq to all entities
  // 2. if at Object, check for all nodes if defaults are set if empty

  switch (true) {
    case (node instanceof Array): {
      const r = [];
      node.forEach((n) => {
        r.push(setDefault(q, n));
      });

      return r;
    }
    case (node instanceof Object): {
      const res = copyUnreferencedKeys(node, q);

      if (!(q.children instanceof Array)) {
        return res;
      }

      const children = q.children;

      children.forEach((e) => {
        const ok = typeof node[getNodeName(e)] !== 'undefined'; // this means, default from server wins.
        if (ok) {
          res[getNodeName(e)] = setDefault(e, node[getNodeName(e)]);
          return;
        }

        if (typeof e.default !== 'undefined') {
          res[getNodeName(e)] = copy(e.default);
        }
      });

      return res;
    }
    default:
      return node;
  }
};

const applyDefaults = (queries, resp) => {
  const res = copy(resp);
  queries.forEach((q) => {
    if (typeof resp[q.alias] === 'undefined') {
      return;
    }

    res[q.alias] = setDefault(q, resp[q.alias]);
  });

  return res;
};

export const newDefaultsQuery = (next) => (queries) => next(queries)
  .then((response) => {
    response.data = applyDefaults(queries, response.data);
    return response;
  })

  .catch((error) => error);

export const newDefaultsMutation = (next) => (
  nodes,
  model,
  attributes,
  updateHookParameter,
  createHookParameter,
) => next(
  nodes,
  model,
  attributes,
  updateHookParameter,
  createHookParameter,
)
  .then((response) => {
    if (attributes.length === 0) {
      return response;
    }

    const q = {
      alias: RESULT,
      children: attributes,
    };

    if (Array.isArray(response.data)) {
      return {
        ...response,
        data: response.data.map((d) => setDefault(q, d)),
      };
    }

    return {
      ...response,
      data: setDefault(q, response.data),
    };
  })
  .catch((error) => error);

export const newDefaultsUpsert = (next) => (
  nodes,
  model,
  attributes,
  hookParameter,
) => next(
  nodes,
  model,
  attributes,
  hookParameter,
)
  .then((response) => {
    if (attributes.length === 0) {
      return response;
    }

    const q = {
      alias: RESULT,
      children: attributes,
    };

    if (Array.isArray(response.data)) {
      return {
        ...response,
        data: response.data.map((d) => setDefault(q, d)),
      };
    }

    return {
      ...response,
      data: setDefault(q, response.data),
    };
  })
  .catch((error) => error);

export const newDefaultBulkUpsert = (next) => (
  bulkPayloads,
) => next(
  bulkPayloads,
)
  .then((bulkResponse) => {
    const data = bulkPayloads.reduce((res, { attributes, alias }) => {
      const payloadResponse = bulkResponse.data[alias];

      if (attributes.length === 0) {
        res[alias] = payloadResponse;
        return res;
      }

      const q = {
        alias: RESULT,
        children: attributes,
      };
      if (Array.isArray(payloadResponse)) {
        res[alias] = payloadResponse.map((d) => setDefault(q, d));
        return res;
      }

      res[alias] = setDefault(q, payloadResponse);
      return res;
    }, {});

    return {
      ...bulkResponse,
      data,
    };
  });
