import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useRepo from '@/nebula/repo';
import { EVENTS } from '@/lib/constants';
import { EventBus } from '@/lib/event-bus';
import { computed } from 'vue';
import { reaction as reactionConfig } from 'shared/api/query/configs.json';

export default function useReactions(update, readOnly = false) {
  const repo = useRepo(reactionConfig.model);
  const { loggedInUser } = useLoggedInUser();

  const reactions = computed(() => repo.entityList.value.filter((r) => r.update?.uid === update.value.uid));

  const groupedReactions = computed(() => Object.values(reactions.value.reduce((acc, current) => {
    if (typeof acc[current.emoji] === 'undefined') {
      acc[current.emoji] = {
        emoji: current.emoji,
        users: [current.creator],
      };
      return acc;
    }

    acc[current.emoji].users.push(current.creator);
    return acc;
  }, {})));

  const toggleReaction = (emoji) => {
    if (readOnly === true) {
      return;
    }
    const reaction = reactions.value.filter((r) => r.emoji === emoji && r.creator !== null && r.creator.uid === loggedInUser.value.uid);
    if (reaction.length === 0) {
      createReaction(emoji);
      return;
    }

    deleteReaction(reaction[0]);
  };

  const createReaction = (emoji) => repo.createSingle({
    creator: { uid: loggedInUser.value.uid },
    emoji,
    update: update.value,
  }).then((reactionId) => {
    EventBus.$emit(EVENTS.INTERACTION.REACTION_CREATED);
    return reactions.value.find((v) => v.uid === reactionId);
  });

  const deleteReaction = (reaction) => repo.deleteSingle(reaction.uid);

  return {
    reactions,
    groupedReactions,
    toggleReaction,
  };
}
