import usePlannings from '@/composables/planning/plannings';
import { computed } from 'vue';
import { mergePropertyValues } from '@/lib/goal-grouping';
import { routeName } from 'shared/constants.json';
import { useRoute } from 'vue-router';

export default function usePlanningDetailsCtx() {
  const { selectSingle } = usePlannings();
  const route = useRoute();
  const inCtx = computed(() => route.name === routeName.planningDetails);

  const paramId = computed(() => {
    if (!inCtx.value) {
      return 0;
    }
    return parseInt(route.params.id, 10);
  });
  const planning = computed(() => selectSingle(paramId.value));

  const goalModifiers = computed(() => {
    const mods = [(goal) => ({ ...goal, publishedAt: null })];
    if (planning.value === undefined) {
      return mods;
    }
    mods.push(...[
      (goal) => {
        goal.properties = mergePropertyValues(planning.value.properties, goal.properties);
        return goal;
      },
      (goal) => {
        goal.goalCycle = planning.value.goalCycles;
        return goal;
      },
      (goal) => {
        goal.planning = { uid: planning.value.uid };
        return goal;
      },
    ]);
    return mods;
  });

  return { inCtx, paramId, planning, goalModifiers };
}
