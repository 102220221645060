import { EQ, GE, GT, LE, LT } from 'shared/api/query/constants';

const isNotANumber = (value) => (Number.isNaN(value) || typeof value !== 'number');

const isInRange = (value, range) => {
  if (isNotANumber(value)) {
    return false;
  }

  const filters = [];
  if (range.min !== undefined && range.min !== null) {
    let funcName = range.minType;
    if (funcName === undefined || funcName === '') {
      funcName = GE;
    }
    if (funcName === GT) {
      filters.push(value > range.min);
    }
    if (funcName === GE) {
      filters.push(value >= range.min);
    }
    if (funcName === EQ) {
      filters.push(value === range.min);
    }
  }

  if (range.max !== undefined && range.max !== null) {
    let funcName = range.maxType;
    if (funcName === undefined || funcName === '') {
      funcName = LE;
    }
    if (funcName === LT) {
      filters.push(value < range.max);
    }
    if (funcName === LE) {
      filters.push(value <= range.max);
    }
    if (funcName === EQ) {
      filters.push(value === range.max);
    }
  }
  return filters.reduce((acc, next) => acc && next, true);
};

export const numberFn = ({ propertyValues, scope }) => {
  const pvs = propertyValues.filter((pv) => pv.property.uid === scope.property.uid);
  if (pvs.length === 0) {
    return false;
  }

  if (scope.isEmpty) {
    return pvs[0].number === null;
  }

  return isInRange(pvs[0].number, scope.numberRange);
};

export const directNumberFn = ({ entity, scope }) => {
  if (scope.numberRange === undefined) {
    return (scope.isEmpty === true && entity[scope.directProperty.edgeName] === undefined)
      || (scope.isEmpty === false && entity[scope.directProperty.edgeName] !== undefined);
  }

  return isInRange(entity[scope.directProperty.edgeName], scope.numberRange);
};
