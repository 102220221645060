import { AND, GE, LE, UID, VAR } from 'shared/api/query/constants';
import { notHasFilter } from 'shared/api/query/filter';
import { property, propertyValue } from 'shared/api/query/configs.json';
import { reverseEdge } from 'shared/api/query/edges';

export const makeNumberFn = (childrenFn, varIdFn) => (scope, index) => {
  const varName = varIdFn(index);

  if (scope.isEmpty === true) {
    const varBlocks = [
      {
        alias: VAR,
        uid: [scope.property.uid],
        model: property.model,
        func: { name: UID },
        children: [
          {
            attr: reverseEdge(propertyValue.edges.property),
            model: propertyValue.model,
            filter: notHasFilter(propertyValue.edges.number),
            children: childrenFn(varName),
          },
        ],
      },
    ];
    const filterTree = {
      func: {
        name: UID,
        needsVar: [{ name: varName, typ: 1 }],
      },
    };
    return { filterTrees: [filterTree], varBlocks };
  }

  if (scope.numberRange === undefined
    || (scope.numberRange.maxType !== undefined && scope.numberRange.max === null)
    || (scope.numberRange.maxType !== undefined && scope.numberRange.max === '')
    || (scope.numberRange.minType !== undefined && scope.numberRange.min === null)
    || (scope.numberRange.minType !== undefined && scope.numberRange.min === '')
  ) {
    return { filterTrees: [], varBlocks: [] };
  }

  const filters = [];
  if (scope.numberRange.min !== undefined && scope.numberRange.min !== null) {
    let funcName = scope.numberRange.minType;
    if (funcName === undefined || funcName === '') {
      funcName = GE;
    }
    filters.push({
      func: {
        name: funcName,
        attr: propertyValue.edges.number,
        args: [{ value: `${scope.numberRange.min}` }],
      },
    });
  }

  if (scope.numberRange.max !== undefined && scope.numberRange.max !== null) {
    let funcName = scope.numberRange.maxType;
    if (funcName === undefined || funcName === '') {
      funcName = LE;
    }
    filters.push({
      func: {
        name: funcName,
        attr: propertyValue.edges.number,
        args: [{ value: `${scope.numberRange.max}` }],
      },
    });
  }

  const varBlocks = [
    {
      alias: VAR,
      func: { name: UID },
      uid: [scope.property.uid],
      model: property.model,
      children: [
        {
          attr: reverseEdge(propertyValue.edges.property),
          model: propertyValue.model,
          filter: {
            op: AND,
            child: filters,
          },
          children: childrenFn(varName),
        },
      ],
    },
  ];

  const filterTree = {
    func: {
      name: UID,
      needsVar: [{ name: varName, typ: 1 }],
    },
  };
  return { filterTrees: [filterTree], varBlocks };
};

export const directPropNumberFn = (scope) => {
  if (scope.isEmpty === true) {
    return {
      filterTrees: [notHasFilter(scope.directProperty.edgeName)],
      varBlocks: [],
    };
  }

  if (scope.numberRange === undefined
    || (scope.numberRange.maxType !== undefined && scope.numberRange.max === null)
    || (scope.numberRange.maxType !== undefined && scope.numberRange.max === '')
    || (scope.numberRange.minType !== undefined && scope.numberRange.min === null)
    || (scope.numberRange.minType !== undefined && scope.numberRange.min === '')
  ) {
    return { filterTrees: [], varBlocks: [] };
  }

  return {
    filterTrees: [
      {
        func: {
          attr: scope.directProperty.edgeName,
          name: scope.numberRange.maxType !== undefined ? scope.numberRange.maxType : scope.numberRange.minType,
          args: [{
            value: scope.numberRange.max !== undefined
              ? `${scope.numberRange.max}` : `${scope.numberRange.min}`,
          }],
        },
      },
    ],
    varBlocks: [],
  };
};
