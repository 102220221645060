import { UID } from 'shared/api/query/constants';
import { gridPageTile as gridPageTileConfig, planning as planningConfig, savedView as savedViewConfig, selectedView as selectedViewConfig, space as spaceConfig } from 'shared/api/query/configs.json';

export const selectedViewChildren = [
  { attr: UID },
  { attr: selectedViewConfig.edges.viewApplication },
  { attr: selectedViewConfig.edges.space, model: spaceConfig.model, children: [{ attr: UID }] },
  {
    attr: selectedViewConfig.edges.view,
    model: savedViewConfig.model,
    default: { uid: 0 },
    children: [{ attr: UID }],
  },
  {
    attr: selectedViewConfig.edges.gridPageTile,
    model: gridPageTileConfig.model,
    children: [{ attr: UID }],
  },
  {
    attr: selectedViewConfig.edges.planning,
    model: planningConfig.model,
    children: [{ attr: UID }],
  },
];
