import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useRepo from '@/nebula/repo';
import { EVENTS } from '@/lib/constants';
import { EventBus } from '@/lib/event-bus';
import { accessPolicyType } from 'shared/constants.json';
import { updateTemplate as updateTemplateConfig } from 'shared/api/query/configs.json';

export default function useUpdateTemplates() {
  const repo = useRepo(updateTemplateConfig.model);

  const { loggedInUser } = useLoggedInUser();
  const { loggedInUserAccount } = useLoggedInUserAccount();

  const createUpdateTemplate = (updateTemplate) => repo.createSingle({
    ...updateTemplate,
    creator: { uid: loggedInUser.value.uid },
    accessPolicy: {
      account: { uid: loggedInUserAccount.value.uid },
      accountAccess: accessPolicyType.disabled,
    },
  }).then((updateTemplate) => {
    EventBus.$emit(EVENTS.CHECKIN.UPDATE_TEMPLATE_CREATED);
    return updateTemplate;
  });

  const deleteUpdateTemplate = (updateTemplate) => repo.deleteSingle(updateTemplate.uid).then(() => {
    EventBus.$emit(EVENTS.CHECKIN.UPDATE_TEMPLATE_DELETED);
  });

  return {
    selectSingle: repo.selectSingle,
    createUpdateTemplateLoading: repo.createLoading,
    createUpdateTemplate,
    updateUpdateTemplateLoading: repo.updateLoading,
    updateUpdateTemplate: repo.updateSingle,
    deleteUpdateTemplateLoading: repo.deleteLoading,
    deleteUpdateTemplate,
    fetchUpdateTemplatesLoading: repo.getListLoading,
    fetchUpdateTemplates: repo.getList,

    updateTemplates: repo.entityList,
  };
}
