<template>
  <i>
    <svg
      class="SVGInline-svg"
      width="1em"
      height="1em"
      viewBox="0 0 14 13"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <!--eslint-disable-->
      <path d="M12 0c1.1045 0 2 .8955 2 2v11h-4V0h2zM4 10.5V13H0V8.5h2c1.1045 0 2 .8955 2 2zM9 6v7H5V4h2c1.1045 0 2 .8955 2 2z"/>
      <!--eslint-enable-->
    </svg>
  </i>
</template>

<script>
export default { name: 'Scale' };
</script>
