<template>
  <div class="new-form-template-preview">
    <full-screen-spinner v-if="loading" />
    <template v-else>
      <new-form-sidebar-template-preview :form-template="formTemplate" />
      <div class="_right">
        <page-header
          class="_top"
          small
        >
          <template #actions>
            <div

              class="_actions"
            >
              <m-btn
                large
                icon="close"
                fab
                hide-border
                to="/surveys"
              />
            </div>
          </template>
        </page-header>
        <new-form-template-preview-content :form-template="formTemplate" />
      </div>
    </template>
  </div>
</template>

<script>
import FullScreenSpinner from 'shared/components/FullScreenSpinner.vue';
import NewFormSidebarTemplatePreview from '@/components/survey/NewFormSidebarTemplatePreview.vue';
import NewFormTemplatePreviewContent from '@/components/survey/NewFormTemplatePreviewContent.vue';
import PageHeader from 'shared/components/PageHeader.vue';
import useFormTemplate from '@/composables/form/form-template';
import { mapActions } from 'vuex';

export default {
  name: 'NewFormTemplatePreview',
  components: { NewFormTemplatePreviewContent, PageHeader, NewFormSidebarTemplatePreview, FullScreenSpinner },
  setup() {
    const { formTemplate, formTemplateAvailable, formTemplateId } = useFormTemplate();
    return { formTemplate, formTemplateAvailable, formTemplateId };
  },
  computed: {
    loading() {
      return !this.formTemplateAvailable;
    },
  },
  methods: {
    ...mapActions([
      'getFormTemplate',
    ]),
    retrieveFormTemplate() {
      if (this.formTemplateAvailable) {
        return;
      }
      this.getFormTemplate({ formTemplateId: this.formTemplateId }).then((response) => {
        if (response.status !== 200) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
        }
      });
    },
  },
  mounted() {
    this.retrieveFormTemplate();
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .new-form-template-preview { // TODO: extract layout
    display: flex;
    height: 100vh;

    ._right {
      flex: 1 1 auto;
      width: 100%;
      margin-left: auto;
      background-color: map_get($grey, 'lighten-6');
    }

    ._top {
      display: flex;

      ._actions {
        margin-left: auto;
      }
    }
  }
</style>
