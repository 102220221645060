<template>
  <div class="icon-nav-items">
    <icon-nav-item
      v-for="(item, index) in items"
      :key="index"
      :title="item.title"
      :icon="item.icon"
      :to="item.to"
      :icon-size="iconSize"
      :active="item.active"
      :show-tooltip="showTooltip"
    />
  </div>
</template>

<script>
import IconNavItem from '@/components/navigation/IconNavItem.vue';

export default {
  name: 'IconNavItems',
  props: {
    items: {
      type: Array,
      required: true,
    },
    showTooltip: {
      type: Boolean,
      default: false,
    },
    iconSize: {
      type: String,
      default: '20',
    },
  },
  components: { IconNavItem },
};
</script>

<style scoped lang="scss" type="text/scss">

</style>
