<template>
  <update-feed
    :show-create-view="props.isLoggedInUser"
    show-views-selector
  />
</template>

<script setup>
import UpdateFeed from '@/components/update-feed/UpdateFeed.vue';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useUpdateFeedDefaultProps from '@/composables/saved-views/update-feed-defaut-props';
import useViewServiceInit from '@/composables/saved-views/view-service-init';
import { AND, OR } from '@/lib/filter/scope-tree';
import { CREATED_AT } from 'shared/api/query/constants';
import { VIEWS_SERVICE } from '@/lib/constants';
import { creatorProperty } from '@/lib/updates/properties';
import { guid } from 'shared/lib/uuid';
import { provide } from 'vue';
import { useI18n } from 'vue-i18n';
import { viewApplication, viewType } from 'shared/constants.json';

const props = defineProps({
  user: {
    type: Object,
    required: true,
  },
  isLoggedInUser: {
    type: Boolean,
    default: false,
  },
});

const { loggedInUserAccount } = useLoggedInUserAccount();
const i18n = useI18n();
const defaultPropsSvc = useUpdateFeedDefaultProps();
const viewServiceInitService = useViewServiceInit();

let viewApp = viewApplication.updateFeedProfile;
if (props.isLoggedInUser) {
  viewApp = viewApplication.updateFeedHome;
}

const initialFilter = {
  account: { uid: loggedInUserAccount.value.uid },
  op: OR,
  children: [
    {
      key: guid(),
      op: AND,
      children: [
        {
          op: AND,
          scope: {
            isEmpty: false,
            directProperty: creatorProperty(i18n.t),
            users: [props.user],
          },
        },
      ],
    },
  ],
};

const defaultView = {
  title: i18n.t('list.feed'),
  viewType: viewType.feed,
  viewApplication: viewApp,
  params: {
    filter: initialFilter,
    order: [{ attr: CREATED_AT, desc: true }],
    props: [],
  },
};

let routeAwareConfig = viewServiceInitService.defaultConfig();
routeAwareConfig = viewServiceInitService.withDefaultView(routeAwareConfig, defaultView);
routeAwareConfig = viewServiceInitService.withViewApplication(routeAwareConfig, viewApp);
routeAwareConfig = viewServiceInitService.withDefaultProps(routeAwareConfig, defaultPropsSvc.defaultProps);
routeAwareConfig = viewServiceInitService.withUser(routeAwareConfig, props.user);

const viewSvc = viewServiceInitService.routeAwareViewService(routeAwareConfig);
provide(VIEWS_SERVICE, viewSvc);
</script>

<style scoped lang="scss" type="text/scss">

</style>
