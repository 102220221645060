<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!--eslint-disable-->
    <path
      d="M13.3169 3.06592L12.4907 3.71191C11.3643 2.27197 9.61231 1.34766 7.64502 1.34766C4.24805 1.34766 1.49854 4.09424 1.49415 7.49268C1.48975 10.894 4.24512 13.6523 7.64502 13.6523C10.3008 13.6523 12.564 11.9678 13.4253 9.60791C13.4473 9.54639 13.415 9.47754 13.3535 9.45703L12.523 9.17139C12.494 9.16146 12.4623 9.16326 12.4347 9.1764C12.407 9.18954 12.3856 9.21298 12.375 9.2417C12.3486 9.31494 12.3193 9.38818 12.2886 9.45996C12.0352 10.0605 11.6719 10.5996 11.209 11.0625C10.7498 11.5225 10.2062 11.8896 9.60791 12.1436C8.98829 12.4058 8.32764 12.5391 7.64795 12.5391C6.9668 12.5391 6.30762 12.4058 5.68799 12.1436C5.08913 11.8907 4.5453 11.5235 4.08692 11.0625C3.62652 10.6034 3.25984 10.0591 3.00733 9.45996C2.74512 8.83887 2.61182 8.17969 2.61182 7.49854C2.61182 6.81738 2.74512 6.1582 3.00733 5.53711C3.26075 4.93652 3.62403 4.39746 4.08692 3.93457C4.54981 3.47168 5.08887 3.1084 5.68799 2.85352C6.30762 2.59131 6.96827 2.45801 7.64795 2.45801C8.32911 2.45801 8.98829 2.59131 9.60791 2.85352C10.2068 3.10641 10.7506 3.47361 11.209 3.93457C11.354 4.07959 11.4902 4.2334 11.6162 4.39453L10.7344 5.08301C10.7169 5.09651 10.7036 5.11465 10.6961 5.13536C10.6885 5.15608 10.6869 5.17851 10.6915 5.20009C10.6961 5.22166 10.7066 5.2415 10.722 5.25733C10.7374 5.27316 10.7569 5.28433 10.7783 5.28955L13.3506 5.91943C13.4238 5.93701 13.4956 5.88135 13.4956 5.80664L13.5073 3.15674C13.5059 3.06006 13.3931 3.00586 13.3169 3.06592Z"
      fill="currentColor"
    />
    <path
      d="M10.0591 9.35448L7.97021 7.84423V4.21875C7.97021 4.1543 7.91748 4.10156 7.85302 4.10156H7.14844C7.08398 4.10156 7.03125 4.1543 7.03125 4.21875V8.25292C7.03125 8.291 7.04883 8.32616 7.07959 8.34813L9.50243 10.1147C9.55517 10.1528 9.62841 10.1411 9.6665 10.0898L10.0854 9.51854C10.1235 9.46434 10.1118 9.3911 10.0591 9.35448Z"
      fill="currentColor"
    />
    <!--eslint-enable-->
  </svg>
</template>

<script>
export default { name: 'LastUpdate' };
</script>

<style scoped lang="scss" type="text/scss">

</style>
