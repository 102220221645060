<template>
  <div class="can-be-child-of-error">
    {{ $t('canBeChildOfError.allowedTypes', allowedTypes.length) }}
    <ul>
      <li
        v-for="(type, index) in allowedTypes"
        :key="index"
      >
        {{ textByLang(type.label, userLang) }}
      </li>
    </ul>
  </div>
</template>

<script>
import useGoalTypeProperty from '@/composables/customize-page/goal-type-property';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import { textByLang } from 'shared/lib/language';

export default {
  name: 'CanBeChildOfError',
  props: {
    err: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { goalTypeOption, goalTypeProperty } = useGoalTypeProperty();
    const { loggedInUser } = useLoggedInUser();
    return { goalTypeOption, goalTypeProperty, userLang: loggedInUser.value.language };
  },
  data() {
    return { textByLang };
  },
  computed: {
    allowedTypes() {
      const option = this.goalTypeOption(this.err.payload.goal);
      if (option === null) {
        throw new Error('goal type option not found');
      }
      const allowedOptionIds = option.canBeChildOf.map((t) => t.uid);
      return this.goalTypeProperty.options.filter((o) => allowedOptionIds.includes(o.uid));
    },
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  .can-be-child-of-error {
    ul {
      margin-block-start: .8rem;
      padding-inline-start: 1.4rem;
      margin: .2rem 0;

      li {
        margin-bottom: .2rem;
      }
    }
  }
</style>
