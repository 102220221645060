import { UID } from 'shared/api/query/constants';
import {
  accessPolicy as accessPolicyConfig,
  accessPolicyScope as accessPolicyScopeConfig,
  account as accountConfig,
  numberRange as numberRangeConfig,
  property as propertyConfig,
  propertyOption as propertyOptionConfig,
  space as spaceConfig,
  timeRange as timeRangeConfig,
  user as userConfig,
  userScope as userScopeConfig,
  userScopeTree as userScopeTreeConfig,
} from 'shared/api/query/configs.json';

export const userScopeChildren = [
  { attr: UID },
  { attr: userScopeConfig.edges.isEmpty, default: false },
  { attr: userScopeConfig.edges.type },
  {
    attr: userScopeConfig.edges.property,
    model: propertyConfig.model,
    default: null,
    children: [
      { attr: UID },
    ],
  },
  {
    attr: userScopeConfig.edges.timeRange,
    model: timeRangeConfig.model,
    default: null,
    children: [
      { attr: UID },
      { attr: timeRangeConfig.edges.start, default: null },
      { attr: timeRangeConfig.edges.end, default: null },
    ],
  },
  {
    attr: userScopeConfig.edges.numberRange,
    model: numberRangeConfig.model,
    default: null,
    children: [
      { attr: UID },
      { attr: numberRangeConfig.edges.min },
      { attr: numberRangeConfig.edges.minType },
      { attr: numberRangeConfig.edges.max },
      { attr: numberRangeConfig.edges.maxType },
    ],
  },
  {
    attr: userScopeConfig.edges.users,
    model: userConfig.model,
    default: [],
    children: [
      { attr: UID },
    ],
  },
  {
    attr: userScopeConfig.edges.selectedOptions,
    model: propertyOptionConfig.model,
    default: [],
    children: [{ attr: UID }],
  },
  {
    attr: userScopeConfig.edges.spaces,
    model: spaceConfig.model,
    default: [],
    children: [{ attr: UID }],
  },
  {
    attr: userScopeConfig.edges.staticUsers,
    model: userConfig.model,
    default: [],
    children: [
      { attr: UID },
    ],
  },
];

export const userScopeTreeChildren = [
  { attr: UID },
  { attr: userScopeTreeConfig.edges.op },
  { attr: userScopeTreeConfig.edges.treeHash },
  {
    attr: userScopeTreeConfig.edges.account,
    model: accountConfig.model,
    default: null,
    children: [{ attr: UID }],
  },
  {
    attr: userScopeTreeConfig.edges.scope,
    model: userScopeConfig.model,
    default: null,
    children: userScopeChildren,
  },
  {
    attr: userScopeTreeConfig.edges.children,
    model: userScopeTreeConfig.model,
    default: [],
    children: [
      { attr: UID },
      { attr: userScopeTreeConfig.edges.op },
      {
        attr: userScopeTreeConfig.edges.scope,
        model: userScopeConfig.model,
        default: null,
        children: userScopeChildren,
      },
      {
        attr: userScopeTreeConfig.edges.children,
        model: userScopeTreeConfig.model,
        default: [],
        children: [
          { attr: UID },
          { attr: userScopeTreeConfig.edges.op },
          {
            attr: userScopeTreeConfig.edges.scope,
            model: userScopeConfig.model,
            default: null,
            children: userScopeChildren,
          },
        ],
      },
    ],
  },
];
export const accessPolicyScopeChildren = [
  { attr: UID },
  { attr: accessPolicyScopeConfig.edges.accessType },
  { attr: accessPolicyScopeConfig.edges.userIsInScope, default: false },
  {
    attr: accessPolicyScopeConfig.edges.scope,
    model: userScopeTreeConfig.model,
    default: null,
    children: userScopeTreeChildren,
  },
  {
    attr: accessPolicyScopeConfig.edges.users,
    model: userConfig.model,
    default: [],
    children: [{ attr: UID }],
  },
  {
    attr: accessPolicyScopeConfig.edges.scopeUsers,
    model: userConfig.model,
    default: [],
    children: [{ attr: UID }],
  },
];
export const accessPolicyChildren = [
  { attr: UID },
  { attr: accessPolicyConfig.edges.accountAccess },
  {
    attr: accessPolicyConfig.edges.account,
    model: accountConfig.model,
    default: null,
    children: [{ attr: UID }],
  },
  {
    attr: accessPolicyConfig.edges.scopes,
    model: accessPolicyScopeConfig.model,
    default: [],
    children: accessPolicyScopeChildren,
  },
];
