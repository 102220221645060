import { CREATED_AT } from 'shared/api/query/constants';
import { DateTime } from 'luxon';

export default {
  computed: {
    minDate() {
      if (this.filteredUpdates.length === 0) {
        return DateTime.local().startOf('week');
      }
      return this.filteredUpdates.reduce((res, next) => {
        const d = DateTime.fromISO(next.createdAt).startOf('week');
        if (d.diff(res) < 0) {
          return d;
        }

        return res;
      }, DateTime.fromISO(this.filteredUpdates[0].createdAt).startOf('week'));
    },
    maxDate() {
      if (this.filteredUpdates.length === 0) {
        return DateTime.local().startOf('week');
      }
      return this.filteredUpdates.reduce((res, next) => {
        const d = DateTime.fromISO(next.createdAt).startOf('week');
        if (d.diff(res) > 0) {
          return d;
        }

        return res;
      }, DateTime.fromISO(this.filteredUpdates[0].createdAt).startOf('week'));
    },
    groupedUpdates() {
      const weeks = [];
      for (let d = this.minDate; d.diff(this.maxDate) <= 0; d = d.plus({ weeks: 1 })) {
        weeks.push(d);
      }

      return this.filteredUpdates.map((update) => ({
        ...update,
        startOfWeek: this.findWeek(DateTime.fromISO(update.createdAt), weeks),
      })).reduce((res, next) => {
        if (typeof res[next.startOfWeek.toISO()] === 'undefined') {
          res[next.startOfWeek.toISO()] = [next];
          return res;
        }

        res[next.startOfWeek.toISO()].push(next);
        return res;
      }, {});
    },
    isSortedByDate() {
      if (this.order.length === 0) {
        return true;
      }
      if (this.order[0].attr === CREATED_AT) {
        return true;
      }
      return false;
    },
    orderIsDesc() {
      if (this.order.length === 0) {
        return false;
      }

      if (!this.isSortedByDate) {
        return false;
      }

      return this.order[0].desc;
    },
  },
  methods: {
    findWeek(createdAt, weeks) {
      for (let i = 0; i < weeks.length; i++) {
        let max = weeks[i + 1];
        if (typeof max === 'undefined') {
          max = DateTime.local();
        }
        if (weeks[i].diff(createdAt) <= 0 && max.diff(createdAt) >= 0) {
          return weeks[i];
        }
      }

      return DateTime.local();
    },
  },
};
