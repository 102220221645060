<template>
  <m-content
    padding-x="layout"
    :padding-y="0"
    class="_header"
  >
    <meetings-list-header
      ref="header"
      :slots="headerSlots"
      :allowed-view-types="allowedViewTypes"
      :show-views-selector="showViewsSelector"
      :filter-props="defaultProps"
      :show-create-view="showCreateView"
      show-saved-view-info
      @reload="reload"
      @create="create"
      @string-filter-changed="updateSearchTerm"
    />
    <m-divider
      v-if="currentView.viewType !== viewType.cascade"
      small
    />
  </m-content>
  <div
    ref="wrapper"
    :class="['meetings-list-wrapper', fullscreen ? '-fullscreen' : '']"
  >
    <m-selectable
      v-model:value="selectedIds"
      class="_content"
      :selector-class="selectorClass"
    >
      <meetings-explorer-list
        v-if="currentView !== null && currentView.viewType === viewType.list"
        ref="list"
        :selected-ids="selectedIds"
        :bordered="bordered"
        :create-loading="createLoading"
        :search-term="searchTerm"
        :item-class="selectorClass"
        :style="listStyle"
        @create="create"
        @loading-changed="setHeaderWidth"
        @select-row="selectRow"
        @selection-right-click="handleSelectionRightClick"
      />
      <meetings-context-menu
        ref="contextMenu"
        :ids="selectedIds"
        @deleted="reload"
        @duplicated="reload"
      />
      <m-dialog
        :value="showModal"
        :max-width="$modalSizes.xl"
        hide-footer
        no-padding
        keep-height
        hide-header
        top="7rem"
        @close="closeModal"
      >
        <meeting-page
          :id="selectedMeetingId"
          :route-after-delete="''"
          open-in-modal
          @close="closeModal"
        />
      </m-dialog>
    </m-selectable>
  </div>
</template>

<script>
import MeetingPage from '@/components/meeting/MeetingPage.vue';
import MeetingsContextMenu from '@/components/meeting/MeetingsContextMenu.vue';
import MeetingsExplorerList from '@/components/meeting/MeetingsExplorerList.vue';
import MeetingsListHeader from '@/components/meeting/MeetingsListHeader.vue';
import navigationMixin from '@/mixins/router-navigation';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useProperties from '@/composables/property/property';
import useRouteAwareViews from '@/composables/saved-views/route-aware-views';
import { VIEWS_SERVICE } from '@/lib/constants';
import { accessPolicyType, routeName, viewType } from 'shared/constants.json';
import { computed, provide } from 'vue';
import { createPropsList } from '@/lib/props';
import { directProperties } from '@/lib/meeting/properties';
import { emptyValues } from '@/lib/property';
import { getPropertyValuesFromFilter } from '@/lib/property-value';
import { mapActions } from 'vuex';
import { children as meetingChildren, meetingTemplates } from '@/api/query/meeting';
import { meeting as meetingConfig } from 'shared/api/query/configs.json';
import { savedViewProps } from '@/lib/saved-view/props';
import { useI18n } from 'vue-i18n';

export default {
  name: 'MeetingsListWrapper',
  props: {
    ...savedViewProps,
    bordered: {
      type: Boolean,
      default: false,
    },
    showCreateView: {
      type: Boolean,
      default: false,
    },
    showViewsSelector: {
      type: Boolean,
      default: false,
    },
    viewApplication: {
      type: String,
      default: '',
    },
    allowedViewTypes: {
      type: Array,
      default: () => [
        viewType.list,
      ],
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    headerSlots: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const { t } = useI18n();
    const { meetingProperties } = useProperties();
    const { userLang, loggedInUser } = useLoggedInUser();

    const defaultProps = computed(() => createPropsList({
      properties: meetingProperties.value,
      directProperties: directProperties((key) => t(key)),
      userLang: userLang.value,
    }));

    const routeAwareViewsSvc = useRouteAwareViews({
      defaultProps,
      defaultView: props.defaultView,
      application: props.viewApplication,
      space: props.space,
      readOnly: props.readOnly,
    });

    provide(VIEWS_SERVICE, routeAwareViewsSvc);
    const { loggedInUserAccount } = useLoggedInUserAccount();

    return {
      meetingProperties,
      defaultProps,
      loggedInUserAccount,

      currentView: routeAwareViewsSvc.currentView,
      initCurrentView: routeAwareViewsSvc.initCurrentView,
      loggedInUser,
    };
  },
  components: {
    MeetingsListHeader,
    MeetingsContextMenu,
    MeetingsExplorerList,
    MeetingPage,
  },
  data() {
    return {
      viewType,
      createLoading: false,
      selectedIds: [],
      selectorClass: 'meeting-item',
      searchTerm: '',
      headerWidth: 0,
      resizeObserver: null,
    };
  },
  computed: {
    showModal() {
      return typeof this.$route.query.meetingId !== 'undefined';
    },
    listStyle() {
      return { width: `${this.headerWidth}px` };
    },
    selectedMeetingId() {
      const id = parseInt(this.$route.query.meetingId, 10);
      if (Number.isNaN(id)) {
        return 0;
      }

      return id;
    },
    filter() {
      if (this.currentView === null
        || typeof this.currentView.params === 'undefined'
        || typeof this.currentView.params.filter === 'undefined'
      ) {
        return null;
      }
      return this.currentView.params.filter;
    },
  },
  methods: {
    ...mapActions(['createEntity', 'getMeetingTemplates']),
    handleSelectionRightClick(event) {
      this.$refs.contextMenu.show(event);
    },
    selectRow({ uid, event }) {
      this.handleSelectionRightClick(event);
      this.selectedIds = [uid];
    },
    closeModal() {
      const query = { ...this.$route.query };
      delete query.meetingId;
      this.$router.push({ query });
    },
    reload() {
      this.$refs.list.load(false);
    },
    create() {
      this.createLoading = true;

      const values = getPropertyValuesFromFilter(this.filter, emptyValues(this.meetingProperties), this.loggedInUser, 'propertyValues');

      this.createEntity({
        entity: {
          account: { uid: this.loggedInUserAccount.uid },
          creator: this.loggedInUser,
          accessPolicy: {
            account: { uid: this.loggedInUserAccount.uid },
            accountAccess: accessPolicyType.disabled,
          },
          ...values,
          participants: [this.loggedInUser],
        },
        model: meetingConfig.model,
        entityById: () => [{ children: meetingChildren }],
      }).then((response) => {
        if (response.status !== 201) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
          return;
        }

        this.createLoading = false;
        this.open({ uid: response.data.uid, paramKey: 'meetingId', mobileRouteName: routeName.meetingDetails });
        this.reload();
      });
    },
    updateSearchTerm(term) {
      this.searchTerm = term;
    },
    setHeaderWidth() {
      this.$nextTick(() => {
        this.$nextTick(() => {
          if (this.$refs.header === null || typeof this.$refs.header === 'undefined') {
            return;
          }
          this.headerWidth = this.$refs.header.$el.clientWidth;
        });
      });
    },
    loadMeetingTemplates() {
      if (this.$store.state.meetingTemplatesRetrieved) {
        return;
      }
      this.getMeetingTemplates({ queries: meetingTemplates() }).then((response) => {
        if (response.status !== 200) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
        }
      });
    },
  },
  mounted() {
    this.resizeObserver = new ResizeObserver(this.setHeaderWidth);
    this.resizeObserver.observe(this.$refs.wrapper);
    this.loadMeetingTemplates();

    this.initCurrentView();
    this.setHeaderWidth();
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.setHeaderWidth);
    this.resizeObserver.disconnect();
  },
  mixins: [navigationMixin],
};
</script>

<style scoped lang="scss" type="text/scss">
  @import 'shared/assets/scss/padding';

  ._header {
    position: sticky;
    left: 0;
  }

  .meetings-list-wrapper {
    display: inline-flex;
    flex-direction: column;
    padding-bottom: 8rem;

    &:not(.-fullscreen) {
      overflow: auto;
    }

    &.-container-scrollable {
      overflow: auto;
    }

    ._spinner {
      position: absolute;
      top: 2rem;
      left: 48%;
    }

    ._content {
      position: relative;
      float: left;
    }

    &.-fullscreen {
      ._content {
        @include layoutPaddingX();
      }
    }
  }
</style>
