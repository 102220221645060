<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.3114 11.8661L18.3208 9.50592C18.2957 9.48624 18.2657 9.474 18.234 9.47062C18.2024 9.46723 18.1704 9.47283 18.1418 9.48677C18.1132 9.50071 18.0891 9.52244 18.0722 9.54945C18.0554 9.57647 18.0465 9.60768 18.0465 9.63952V11.1559H12.8434V5.9528H14.3622C14.5028 5.9528 14.5825 5.78873 14.4958 5.67858L12.1333 2.68795C12.1176 2.66772 12.0976 2.65133 12.0746 2.64006C12.0517 2.62879 12.0264 2.62292 12.0008 2.62292C11.9753 2.62292 11.95 2.62879 11.9271 2.64006C11.9041 2.65133 11.8841 2.66772 11.8684 2.68795L9.50592 5.67858C9.48624 5.7036 9.474 5.73366 9.47062 5.76532C9.46723 5.79697 9.47283 5.82894 9.48677 5.85756C9.50071 5.88618 9.52244 5.91029 9.54945 5.92714C9.57647 5.94398 9.60768 5.95287 9.63952 5.9528H11.1559V11.1559H5.9528V9.63717C5.9528 9.49655 5.78873 9.41686 5.67858 9.50358L2.68795 11.8661C2.66772 11.8817 2.65133 11.9018 2.64006 11.9247C2.62879 11.9477 2.62292 11.9729 2.62292 11.9985C2.62292 12.0241 2.62879 12.0493 2.64006 12.0723C2.65133 12.0952 2.66772 12.1153 2.68795 12.1309L5.67623 14.4934C5.78639 14.5801 5.95045 14.5028 5.95045 14.3598V12.8434H11.1536V18.0465H9.63483C9.4942 18.0465 9.41452 18.2106 9.50123 18.3208L11.8637 21.309C11.9317 21.3958 12.063 21.3958 12.1286 21.309L14.4911 18.3208C14.5778 18.2106 14.5005 18.0465 14.3575 18.0465H12.8434V12.8434H18.0465V14.3622C18.0465 14.5028 18.2106 14.5825 18.3208 14.4958L21.309 12.1333C21.3292 12.1174 21.3456 12.0972 21.3569 12.0742C21.3682 12.0512 21.3742 12.0259 21.3744 12.0002C21.3746 11.9746 21.3691 11.9492 21.3582 11.926C21.3473 11.9028 21.3313 11.8823 21.3114 11.8661Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default { name: 'Move' };
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>

</style>
