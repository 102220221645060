export const POSITIVE = '2';
export const NEUTRAL = '1';
export const NEGATIVE = '0';

export const categoryOfValue = (value, maxValue, startsFromZero) => {
  const val = parseInt(value, 10);
  /* eslint-disable */
  if (isNaN(val)) {
    return NEGATIVE;
  }
  /* eslint-enable */
  let start = 1;
  if (startsFromZero) {
    start = 0;
  }
  const range = maxValue - start + 1;

  const mid = start + ((range - 1) / 2);
  if (val < mid) {
    return NEGATIVE;
  }
  if (val === mid) {
    return NEUTRAL;
  }
  return POSITIVE;
};

export const distributionToFavorability = (distribution, maxValue, startsFromZero) => {
  if (distribution.length === 0) {
    return [];
  }
  const initial = [
    { key: POSITIVE, proportion: 0, count: 0 },
    { key: NEUTRAL, proportion: 0, count: 0 },
    { key: NEGATIVE, proportion: 0, count: 0 },
  ];
  return distribution.reduce((acc, next) => acc.map((item) => {
    if (categoryOfValue(next.key, maxValue, startsFromZero) === item.key) {
      return {
        ...item,
        proportion: item.proportion + next.proportion,
        count: item.count + next.count,
      };
    }
    return item;
  }), initial);
};
