import useGoals from '@/composables/goal/goals';
import { logCatch } from '@/lib/logger/logger';

export default function useGoalAccessPolicy() {
  const { updateMultiple, deleteMultiple } = useGoals();

  const updateAccessPolicy = (goals) => updateMultiple(goals, { optimistic: false }).then((data) => {
    if (data.length !== goals.length) {
      const ids = data.map((g) => g.uid);
      const filtered = goals.filter((e) => !ids.includes(e.uid));
      deleteMultiple(filtered.map((g) => g.uid), { commitToRemote: false });
    }
  }).catch(logCatch(() => {
    throw new Error('failed to update goal access policy');
  }));

  return { updateAccessPolicy };
}
