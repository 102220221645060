<template>
  <svg
    fill="none"
    viewBox="0 0 100 100"
    width="1em"
    height="1em"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <linearGradient
        id="a"
        x1="44.291%"
        y1="0%"
        x2="44.291%"
        y2="100%"
      >
        <stop
          stop-color="#FFF"
          stop-opacity="0"
          offset="0%"
        />
        <stop
          stop-color="#FFD75E"
          stop-opacity=".3"
          offset="100%"
        />
      </linearGradient>
    </defs>
    <g
      fill="none"
      fill-rule="evenodd"
    >
      <path
        d="M49.343 17.657L55 12h56a4 4 0 0 1 4 4v62a4 4 0 0 1-4 4H7a4 4 0 0 1-4-4V20h40.686a8 8 0 0 0 5.657-2.343z"
        fill="#FFD75E"
        fill-rule="nonzero"
      />
      <path
        d="M65.657 5.657L60 0H4a4 4 0 0 0-4 4v62a4 4 0 0 0 4 4h104a4 4 0 0 0 4-4V8H71.314a8 8 0 0 1-5.657-2.343z"
        fill="url(#a)"
        fill-rule="nonzero"
        style="mix-blend-mode:multiply;"
        transform="matrix(-1 0 0 1 115 12)"
      />
      <path
        d="M3 78h112H3zm111.299 2.263A3.996 3.996 0 0 1 111 82H7a3.996 3.996 0 0 1-3.3-1.738l1.295.484A4 4 0 0 0 6.397 81h105.211a4 4 0 0 0 1.405-.255l1.286-.482z"
        fill="#E67628"
      />
      <path
        d="M49.343 17.657L55 12h1.5l-6.364 6.364A9 9 0 0 1 43.772 21H3v-1h40.686a8 8 0 0 0 5.657-2.343z"
        fill="#FFF"
        fill-rule="nonzero"
        opacity=".4"
      />
    </g>
  </svg>
</template>

<script>export default { name: 'Folder' }; </script>

<style scoped lang="scss" type="text/scss">
</style>
