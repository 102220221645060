import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useRepo from '@/nebula/repo';
import { EVENTS } from '@/lib/constants';
import { EventBus } from '@/lib/event-bus';
import { invitation as invitationConfig } from 'shared/api/query/configs.json';

export default function useInvitations() {
  const repo = useRepo(invitationConfig.model);
  const { loggedInUser } = useLoggedInUser();
  const { loggedInUserAccount } = useLoggedInUserAccount();

  const inviteUser = (user) => {
    const invitation = {
      recipient: { uid: user.uid },
      actor: { uid: loggedInUser.value.uid },
      account: { uid: loggedInUserAccount.value.uid },
    };
    return repo.createSingle(invitation).then((invitation) => {
      EventBus.$emit(EVENTS.USER.USERS_INVITED, { users: [user] });
      return invitation;
    });
  };

  const inviteUsers = (users) => {
    const invitations = users.map((u) => ({
      recipient: { uid: u.uid },
      actor: { uid: loggedInUser.value.uid },
      account: { uid: loggedInUserAccount.value.uid },
    }));
    return repo.createMultiple(invitations).then((invitations) => {
      EventBus.$emit(EVENTS.USER.USERS_INVITED, { users });
      return invitations;
    });
  };

  return {
    inviteUser,
    inviteUsers,
  };
}
