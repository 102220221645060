import useGoals from '@/composables/goal/goals';
import useSubscription from '@/composables/subscription/subscription';
import { AND, COUNT, HAS, RESULT, SOFT_DELETED_AT, UID } from 'shared/api/query/constants';
import { combine } from 'shared/api/query/filter';
import { computed, ref } from 'vue';
import { deleteEntity, getEntitiesV2, restoreEntity } from '@/store/actions';
import { difference } from 'lodash-es';
import { goal as goalConfig } from 'shared/api/query/configs.json';
import { goalList } from '@/composables/trash/goal';
import { subscriptionAction } from 'shared/constants.json';
import { useStore } from 'vuex';

export default (model) => {
  const store = useStore();
  const subscriptionSvc = useSubscription();
  const goalsSvc = useGoals();

  const getSoftDeletedLoading = ref(false);

  const trashItems = computed(() => store.state.trashItems[model.value]);

  const updateCountHandler = (response) => {
    store.commit('UPDATE_TRASH_ITEMS_COUNT', { model: model.value, count: response.data.count[0].count });
    return response.data[RESULT].map((g) => store.state.trashItems[model.value].find((ti) => ti.uid === g.uid));
  };

  const getSoftDeleted = ({ page = 1, itemsPerPage = 20, searchTerm = '' }) => {
    getSoftDeletedLoading.value = true;

    const finallyHandler = () => {
      getSoftDeletedLoading.value = false;
    };

    switch (model.value) {
      case goalConfig.model:
        return getEntitiesV2(store, {
          queries: goalList({
            pagination: {
              page,
              itemsPerPage,
              countAlias: COUNT,
            },
            searchTerm,
            filter: {
              func: {
                attr: 'softDeletedAt',
                name: 'has',
              },
            },
            order: [
              {
                attr: 'softDeletedAt',
                desc: true,
              },
            ],
            alias: RESULT,
          }),
          model: goalConfig.model,
          mutation: 'TRASH_ITEMS_RETRIEVED',
          options: { alias: RESULT },
          headerOpts: { 'soft-deleted-models': ['*'] },
        })
          .then(updateCountHandler)
          .finally(finallyHandler);
      default:
        throw new Error('model not supported');
    }
  };

  const getSoftDeletedGoalsById = (ids) => {
    const filter = { func: { name: UID, uid: ids } };
    return getEntitiesV2(store, {
      queries: [
        ...goalList({
          filter: combine(AND, [filter, {
            func: {
              attr: 'softDeletedAt',
              name: 'has',
            },
          }]),
          order: [
            {
              attr: 'softDeletedAt',
              desc: true,
            },
          ],
          alias: RESULT,
        }),
        {
          alias: 'count',
          model: goalConfig.model,
          func: { name: HAS, attr: SOFT_DELETED_AT },
          children: [{ attr: 'uid', isCount: true, alias: 'count' }],
        },
      ],
      model: goalConfig.model,
      mutation: 'TRASH_ITEMS_RETRIEVED',
      options: { alias: RESULT },
      headerOpts: { 'soft-deleted-models': ['*'] },
    })
      .then(updateCountHandler);
  };

  const deleteSoftDeleted = (uid, model) => deleteEntity(
    store,
    {
      model,
      entity: { uid },
      mutation: 'TRASH_ITEM_REMOVE',
      headerOpts: { 'soft-deleted-models': ['*'] },
    },
  );

  const restoreSoftDeleted = (uid, model) => restoreEntity(
    store,
    {
      model,
      entity: { uid },
      mutation: 'TRASH_ITEM_RESTORED',
      headerOpts: { 'soft-deleted-models': ['*'] },
    },
  ).then((response) => {
    if (response.status !== 200) {
      return response;
    }

    switch (model) {
      case goalConfig.model: {
        goalsSvc.selectSingle(uid, { commitToRemote: true });
        break;
      }
      default:
        break;
    }

    return response;
  });

  const cleanCacheForModel = (model) => {
    store.commit('TRASH_ITEMS_RESET', { model });
  };

  const hasMore = (model) => store.state.trashItems.count[model] === undefined || store.state.trashItems[model].length < store.state.trashItems.count[model];

  const getNextPage = (model, itemsPerPage) => Math.ceil(store.state.trashItems[model].length / itemsPerPage) + 1;

  const subscribe = () => {
    switch (model.value) {
      case goalConfig.model:
        subscriptionSvc.subscribe({
          model: goalConfig.model,
          syncFn({ ids, action }) {
            switch (action) {
              case subscriptionAction.update: {
                getSoftDeletedGoalsById(ids)
                  .then((goals) => {
                    const goalIds = goals.map((g) => g.uid);
                    const toRemove = difference(ids, goalIds);
                    if (toRemove.length === 0) {
                      return;
                    }
                    store.commit('TRASH_ITEMS_REMOVE', { entities: toRemove.map((id) => ({ uid: id })), model: goalConfig.model });
                  });
                break;
              }

              case subscriptionAction.delete:
                store.commit('TRASH_ITEMS_REMOVE', { entities: ids.map((id) => ({ uid: id })), model: goalConfig.model });
                break;
              default:
                break;
            }
          },
        });
        break;
      default:
        throw new Error('model not supported');
    }
  };

  return {
    cleanCacheForModel,
    deleteSoftDeleted,
    getNextPage,
    getSoftDeleted,
    getSoftDeletedLoading,
    trashItems,
    hasMore,
    restoreSoftDeleted,
    subscribe,
  };
};
