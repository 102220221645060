<template>
  <div
    v-bind="$attrs"
    :class="['m-divider', small ? '-small' : '', none ? '-none' : '', large ? '-large' : '', noBorder ? '-no-border' : '', xs ? '-xs' : '', xxs ? '-xxs' : '']"
    role="separator"
  />
</template>

<script>
export default {
  name: 'MDivider',
  props: {
    xxs: {
      type: Boolean,
      default: false,
    },
    xs: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    xl: {
      type: Boolean,
      default: false,
    },
    none: {
      type: Boolean,
      default: false,
    },
    noBorder: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" type="text/scss">
  .m-divider {
    display: block;
    width: 100%;
    min-width: 100%;
    height: 1px;
    margin: 24px 0;
    clear: both;
    background-color: map_get($grey, 'lighten-4');

    &.-xxs {
      margin: $xxs-container-padding-y 0;
    }

    &.-xs {
      margin: $xs-container-padding-y 0;
    }

    &.-small {
      margin: $small-container-padding-y 0;
    }

    &.-large {
      margin: $large-container-padding-y 0;
    }

    &.-xl {
      margin: $xl-container-padding-y 0;
    }

    &.-none {
      margin: 0;
    }

    &.-no-border {
      background-color: transparent;
    }
  }
</style>
