import useSort from '@/composables/sort/sort';
import { CREATED_AT } from 'shared/api/query/constants';
import { update as updateConfig } from 'shared/api/query/configs.json';

export default function useUpdatesSorting(properties, userSvc) {
  const attributeMap = { [CREATED_AT]: { type: 'date' } };
  const sortOptions = [
    { attr: CREATED_AT, desc: true },
  ];

  const { sort } = useSort(attributeMap, properties, updateConfig.edges.properties, userSvc);
  return {
    sort,
    sortOptions,
  };
}
