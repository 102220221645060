<template>
  <m-content
    padding
    class="goal-type-form"
  >
    <m-form-item
      class="_form-item -flex"
      :style="{ marginBottom: 0 }"
    >
      <icon-picker
        :icon="icon"
        show-placeholder
        size="3.2rem"
        :icon-size="18"
        bordered
        class="_emoji-picker"
        :show-icon-variants="false"
        :icon-types="[iconType.icon, iconType.custom]"
        :style="{ marginRight: '.8rem' }"
        @change="setIcon"
      />
      <color-picker
        :value="localGoalType.color"
        :style="{ marginRight: '.8rem' }"
        @input="updateColor"
      />
      <m-text-field
        :value="textByLang(localGoalType.label, userLang)"
        :auto-focus="textByLang(localGoalType.label, userLang) === ''"
        @input="updateLabel"
      />
    </m-form-item>
  </m-content>
  <m-divider small />
  <div
    v-if="!showPropertySettingsForm"
    class="_properties"
  >
    <m-card-item
      :clickable="false"
      no-hover
      large
      light
      :style="{ fontWeight: 500 }"
    >
      {{ $t('goalTypeForm.properties') }}
    </m-card-item>
    <m-card-item
      v-for="prop in goalProperties"
      :key="prop.key"
      large
      :icon="getIcon(prop)"
      @click="editProp(prop)"
    >
      {{ textByLang(prop.label, userLang) }}
      <template #right>
        <m-icon
          type="right"
          :color="$colors.grey.lighten1"
        />
      </template>
    </m-card-item>
  </div>
  <div
    v-else
    class="_edit-property"
  >
    <property-settings-form
      :property="propToEdit"
      :user-lang="userLang"
      :goal-type="localGoalType"
      :goal-types="goalTypeProperty.options"
      @back="handleBack"
      @update-visibility="updateLocalVisibility"
      @update-goal-type="updateGoalType"
    />
  </div>
  <m-content
    padding
    class="_footer"
  >
    <div class="_left">
      <m-btn
        v-if="isDeletable"
        color="danger"
        outlined
        @click="deleteEntity"
      >
        {{ $t('general.delete') }}
      </m-btn>
    </div>
    <div class="_right">
      <m-btn
        hide-border
        class="_cancel"
        light
        @click="close"
      >
        {{ $t('general.cancel') }}
      </m-btn>
      <m-btn
        color="primary"
        @click="save"
      >
        {{ $t('general.save') }}
      </m-btn>
    </div>
  </m-content>
</template>

<script>
import ColorPicker from '@/components/ColorPicker.vue';
import IconPicker from '@/components/IconPicker.vue';
import PropertySettingsForm from '@/components/goal-type/PropertySettingsForm.vue';
import useGoalTypeProperty from '@/composables/customize-page/goal-type-property';
import useGoalTypes from '@/composables/property/goal-type';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import usePropertySettings from '@/composables/customize-page/property-settings';
import { buildIconFromEntity, replaceIconOnEntity } from 'shared/lib/icon';
import { copy } from 'shared/lib/copy';
import { getVisibilityEdge } from '@/lib/property-settings';
import { iconByType } from '@/lib/property';
import { iconType } from 'shared/constants.json';
import { textByLang } from 'shared/lib/language';
import { toRef } from 'vue';

export default {
  name: 'GoalTypeForm',
  props: {
    option: {
      type: Object,
      required: true,
    },
    rule: {
      type: String,
      default: null,
    },
  },
  components: { PropertySettingsForm, IconPicker, ColorPicker },
  setup(props) {
    const { goalTypeProperty } = useGoalTypeProperty();
    const { userLang } = useLoggedInUser();
    const { goalProperties } = usePropertySettings({
      goalType: toRef(props, 'option'),
      userLang,
    });
    const { updateEntity, deleteSingle } = useGoalTypes();
    return {
      updateEntity,
      deleteSingle,
      goalTypeProperty,
      goalProperties,
      userLang,
    };
  },
  data() {
    return {
      dirty: false,
      textByLang,
      localGoalType: null,
      propToEdit: null,
      showPropertySettingsForm: false,
      iconType,
    };
  },
  emits: ['close'],
  computed: {
    icon() {
      return buildIconFromEntity(this.localGoalType);
    },
    isDeletable() {
      return this.option.isObjective === false && this.option.isKeyResult === false;
    },
    propertyFromRule() {
      if (this.rule === null) {
        return null;
      }
      return this.goalProperties.find((p) => p.key === this.rule);
    },
  },
  methods: {
    setIcon(icon) {
      replaceIconOnEntity(this.localGoalType, icon);
      this.dirty = true;
    },
    updateColor(val) {
      this.localGoalType.color = val;
      this.dirty = true;
    },
    updateLabel(val) {
      this.localGoalType.label = { de: val, en: val };
      this.dirty = true;
    },
    close() {
      const { $emit } = this;
      if (!this.dirty && textByLang(this.localGoalType.label, this.userLang) === '') {
        const title = this.$t('goalTypeForm.deleteEmptyPrompt');
        const okText = this.$t('general.yesDelete');
        const cancelText = this.$t('general.cancel');
        const { deleteSingle, $emit, $showSnackbar, option } = this;

        this.$confirm({
          title,
          okText,
          okType: 'danger',
          cancelText,
          maskClosable: true,
          onOk(setLoading) {
            setLoading(true);
            deleteSingle(option.uid).then(() => {
              $emit('close');
              $showSnackbar({ color: 'success', message: this.$t('success.deleted') });
              setLoading(false);
            });
          },
        });
        return;
      }

      if (!this.dirty) {
        $emit('close');
        return;
      }

      const title = this.$t('general.discardEditPrompt');
      const okText = this.$t('general.yesDiscard');
      const cancelText = this.$t('general.close');
      this.$confirm({
        title,
        okText,
        okType: 'danger',
        cancelText,
        maskClosable: true,
        onOk() {
          $emit('close');
        },
      });
    },
    updateGoalType(val) {
      this.dirty = true;
      this.localGoalType = val;
    },
    updateLocalVisibility({ property, visibility }) {
      this.dirty = true;
      if (property.isDirect) {
        this.localGoalType[getVisibilityEdge(property.key)] = visibility;
        return;
      }

      this.localGoalType.propertySettings.forEach((ps) => {
        if (ps.property.uid !== property.uid) {
          return;
        }

        ps.visibility = visibility;
      });
    },
    editProp(prop) {
      this.propToEdit = prop;
      this.showPropertySettingsForm = true;
    },
    handleBack() {
      this.propToEdit = null;
      this.showPropertySettingsForm = false;
    },
    save() {
      this.updateEntity(this.localGoalType).then(() => {
        this.$showSnackbar({ color: 'success', message: this.$t('success.updated') });
        this.$emit('close');
      });
    },
    deleteEntity() {
      const title = this.$t('general.deletePrompt');
      const okText = this.$t('general.yesDelete');
      const cancelText = this.$t('general.cancel');
      const { deleteSingle, $emit, $showSnackbar, option } = this;

      this.$confirm({
        title,
        okText,
        okType: 'danger',
        cancelText,
        maskClosable: true,
        onOk(setLoading) {
          setLoading(true);
          deleteSingle(option.uid).then(() => {
            $emit('close');
            $showSnackbar({ color: 'success', message: this.$t('success.deleted') });
            setLoading(false);
          });
        },
      });
    },
    getIcon(property) {
      if (typeof property.icon !== 'undefined') {
        return property.icon;
      }
      return iconByType(property);
    },
  },
  watch: {
    propertyFromRule: {
      handler(val) {
        if (val !== undefined && val !== null && this.propToEdit === null) {
          this.editProp(val);
        }
      },
      immediate: true,
    },
  },
  created() {
    this.localGoalType = copy(this.option);
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  .goal-type-form {
    ._form-item {
      &.-flex {
        display: flex;
        align-items: center;
      }
    }
  }

  ._footer {
    display: flex;
    align-items: center;
    margin-top: auto;
    border-top: 1px solid $input-border-color;

    ._right {
      display: flex;
      align-items: center;
      margin-left: auto;

      ._cancel {
        margin-right: .8rem;
      }
    }
  }
</style>
