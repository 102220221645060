<template>
  <div class="new-form-sidebar-template-preview">
    <page-header class="_header">
      <template #title>
        <m-btn

          icon="arrow-left"
          class="_btn"
          hide-border
          @click="$router.push('/new/survey')"
        >
          {{ $t('newFormSidebarTemplatePreview.back') }}
        </m-btn>
      </template>
    </page-header>
    <m-content padding>
      <h3>{{ formTemplate.title }}</h3>
      <p class="_description">
        {{ formTemplate.description }}
      </p>
      <div class="_actions">
        <div class="_content">
          <m-btn
            color="primary"
            large
            class="_btn"
            :loading="loading"
            @click="createFormFromTemplate"
          >
            {{ $t('newFormSidebarTemplatePreview.useThisTemplate') }}
          </m-btn>
          <m-btn
            class="_btn"
            hide-border
            @click="showModal = true"
          >
            {{ $t('newFormSidebarTemplatePreview.startFromScratch') }}
          </m-btn>
          <m-dialog
            v-model:value="showModal"
            :title="$t('createFormCard.heading')"
            :ok-text="$t('general.save')"
            :cancel-text="$t('general.cancel')"
            @ok="$refs.createFormCard.create()"
            @cancel="showModal = false"
          >
            <create-form-card
              ref="createFormCard"
              @created="created"
            />
          </m-dialog>
        </div>
      </div>
    </m-content>
  </div>
</template>

<script>
import CreateFormCard from '@/components/CreateFormCard.vue';
import PageHeader from 'shared/components/PageHeader.vue';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import { CREATE_FORM } from '@/route-names';
import { defaultSurveyProcess } from '@/lib/survey-process';
import { formById } from 'shared/api/query/form';
import { form as formConfig } from 'shared/api/query/configs.json';
import { mapActions } from 'vuex';
import { userScopeType } from 'shared/constants.json';

export default {
  name: 'NewFormSidebarTemplatePreview',
  props: {
    formTemplate: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { loggedInUser, userLang } = useLoggedInUser();
    const { loggedInUserAccount } = useLoggedInUserAccount();
    return { user: loggedInUser, userLang, loggedInUserAccount };
  },
  components: { CreateFormCard, PageHeader },
  data() {
    return {
      loading: false,
      showModal: false,
    };
  },
  methods: {
    ...mapActions([
      'createForm',
      'updateEntity',
      'createSurveyProcess',
    ]),
    created({ form }) {
      this.$router.push({ name: CREATE_FORM, params: { formId: form.uid } });
    },
    createFormFromTemplate() {
      const afterCreateFn = this.afterCreateFn.bind(this);

      this.loading = true;
      const form = {
        ...this.formTemplate,
        account: { uid: this.user.account.uid },
        creator: this.user,
        editors: [this.user],
        resourcePolicy: {
          account: { uid: this.loggedInUserAccount.uid },
          writeScope: {
            account: { uid: this.loggedInUserAccount.uid },
            op: 'and',
            children: [{ op: 'and', scope: { staticUsers: [{ uid: this.user.uid }], type: userScopeType.staticUsers } }],
          },
        },
        analyticAccess: {
          account: { uid: this.user.account.uid },
          readScope: {
            account: { uid: this.loggedInUserAccount.uid },
            op: 'and',
            children: [{ op: 'and', scope: { staticUsers: [{ uid: this.user.uid }], type: userScopeType.staticUsers } }],
          },
        },
        orderItems: this.formTemplate.orderItems.map((item) => ({
          ...item,
          field: {
            ...item.field,
            templateField: item.field,
          },
        })),
      };
      delete form.uid;
      this.createForm({ form }).then((response) => {
        if (response.status !== 201) {
          this.loading = false;
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
          return;
        }

        afterCreateFn({ form: response.data });
      });
    },
    afterCreateFn({ form }) {
      // https://app.mooncamp.com/#/goals/1657713
      const createFormFactors = () => {
        const factors = [];
        this.formTemplate.formFactors.forEach((factor) => {
          factors.push({
            ...factor,
            uid: 0,
            factorFields: factor.factorFields.map((factorField) => ({
              ...factorField,
              uid: 0,
              field: this.findField({ factorField, form }),
            })),
          });
        });

        if (factors.length === 0) {
          this.created({ form });
          return;
        }

        this.updateEntity({
          entity: { ...form, formFactors: factors },
          model: formConfig.model,
          entityById: formById,
        }).then((response) => {
          if (response.status !== 200) {
            this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
            return;
          }
          this.created({ form: response.data });
        });
      };

      this.createSurveyProcess({
        surveyProcess: defaultSurveyProcess({
          account: { uid: this.loggedInUserAccount.uid },
          languages: [this.userLang],
          form,
        }),
      }).then((response) => {
        if (response.status !== 201) {
          this.loading = false;
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
          return;
        }
        createFormFactors();
      });
    },
    findField({ factorField, form }) {
      const filtered = form.orderItems.filter((item) => item.field.templateField.uid === factorField.field.uid);
      if (filtered.length !== 1) {
        return null;
      }

      return filtered[0].field;
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .new-form-sidebar-template-preview {
    flex: 1 0 32rem;
    background-color: white;
    border-right: 1px solid $border-color;

    ._header {
      margin-bottom: 4rem;

      ._btn {
        margin-left: -1.5rem;
      }
    }

    ._actions {
      display: inline-block;
      margin-top: 4rem;

      ._content {
        display: flex;
        flex-direction: column;

        ._btn {
          margin-bottom: 1.2rem;
        }
      }
    }
  }
</style>
