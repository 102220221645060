<template>
  <m-tag
    :icon="icon"
    :icon-size="8"
    :title="title"
    :small="small"
    :xs="xs"
    :m-style="mStyle"
    :color="color"
    rounded
  />
</template>

<script>
import useGoalProperty from '@/composables/property/goal-property';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useStatusProperty from '@/composables/goal/status-property';
import { mStyleProps } from 'shared/lib/m-style-props';
import { textByLang } from 'shared/lib/language';

export default {
  name: 'StatusItem',
  props: {
    ...mStyleProps,
    small: {
      type: Boolean,
      default: false,
    },
    xs: {
      type: Boolean,
      default: false,
    },
    selectedOption: {
      type: Object,
      required: true,
    },
    showAsDraft: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { loggedInUser } = useLoggedInUser();
    const { statusProperty } = useGoalProperty();
    const { translateStatusOptionToIcon } = useStatusProperty(statusProperty, loggedInUser.value.language);
    return { userLang: loggedInUser, translateStatusOptionToIcon };
  },
  computed: {
    color() {
      if (this.showAsDraft) {
        return '';
      }
      return this.selectedOption.color;
    },
    title() {
      if (this.showAsDraft) {
        return this.$t('goal.status.unpublished');
      }
      return textByLang(this.selectedOption.label, this.userLang);
    },
    icon() {
      if (this.showAsDraft) {
        return 'status';
      }
      return this.translateStatusOptionToIcon(this.selectedOption);
    },
  },
};
</script>
