import { goalsByTitle } from '@/api/query/nebula/goal';

export const mentionQuery = ({ query, accountId, includeGoals }) => {
  const q = [];
  if (includeGoals) {
    q.push(
      ...goalsByTitle(query, accountId, 'goals'),
    );
  }
  return q;
};
