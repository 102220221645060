import colors from 'shared/colors';
import { optionColor, propertyType } from 'shared/constants.json';
import { rgbaToHex } from 'shared/lib/color';

const maxScore = 10;
const minScore = 0;

export const translatePropertyOptionColor = (propertyOption) => {
  switch (propertyOption.color) {
    case optionColor.grey:
      return colors.grey.base;
    case optionColor.brown:
      return colors.brown.base;
    case optionColor.orange:
      return colors.orange.base;
    case optionColor.yellow:
      return colors.yellow.base;
    case optionColor.green:
      return colors.green.darken1;
    case optionColor.blue:
      return colors.blue.base;
    case optionColor.purple:
      return colors.purple.base;
    case optionColor.pink:
      return colors.pink.base;
    case optionColor.red:
      return colors.red.base;
    default:
      return colors.grey.base;
  }
};

export const getStatusColor = (statusOption) => {
  switch (statusOption.color) {
    case optionColor.grey:
      return colors.grey.base;
    case optionColor.brown:
      return colors.brown.base;
    case optionColor.orange:
      return colors.orange.base;
    case optionColor.yellow:
      return colors.yellow.base;
    case optionColor.green:
      return colors.green.base;
    case optionColor.blue:
      return colors.blue.base;
    case optionColor.purple:
      return colors.purple.base;
    case optionColor.pink:
      return colors.pink.base;
    case optionColor.red:
      return colors.red.base;
    default:
      return colors.grey.base;
  }
};

export const getStatusChartColor = (statusOption) => {
  switch (statusOption.color) {
    case optionColor.grey:
      return rgbaToHex(colors.grey.lighten3);
    case optionColor.brown:
      return colors.brown.lighten4;
    case optionColor.orange:
      return colors.orange.lighten3;
    case optionColor.yellow:
      return colors.yellow.lighten3;
    case optionColor.green:
      return colors.green.lighten3;
    case optionColor.blue:
      return colors.blue.lighten4;
    case optionColor.purple:
      return colors.purple.lighten4;
    case optionColor.pink:
      return colors.pink.lighten3;
    case optionColor.red:
      return colors.red.lighten3;
    default:
      return rgbaToHex(colors.grey.lighten4);
  }
};

// Used for automatic status calculation
export const findNearestPropertyToScore = (possibleStatusProperties, targetScore, defaultValue) => {
  const sorted = possibleStatusProperties.filter((o) => o.score !== null);
  sorted.sort((a, b) => a.score - b.score);
  if (sorted.length === 0) {
    return defaultValue;
  }
  let nearestProperty = sorted[0];
  let minDifference = maxScore;

  for (let i = 0; i < sorted.length; i++) {
    if (sorted[i].score - targetScore > 0) {
      continue;
    }
    const difference = Math.abs(sorted[i].score - targetScore);
    if (difference < minDifference) {
      minDifference = difference;
      nearestProperty = sorted[i];
    }
  }

  return nearestProperty;
};

export const getStatusProperty = (goal) => goal.properties.find((item) => item.property.type === propertyType.status);

export const getScoreSuggestion = (value, timePassed) => {
  if (timePassed === 0) {
    return maxScore;
  }
  return Math.max(minScore, Math.min(maxScore, Math.trunc(maxScore * (value / (timePassed * 100)))));
};
