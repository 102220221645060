<template>
  <div />
</template>

<script>
import { EventBus } from '@/lib/event-bus';

export default {
  name: 'Logout',
  created() {
    const router = this.$router;

    EventBus.$emit(
      'logout',
      {
        cleanup() {
          router.replace('/');
        },
      },
    );
  },
};
</script>
