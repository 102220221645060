<template>
  <div
    :class="['form-field-icon', square ? '-square' : '', small ? '-small' : '']"
    :style="style"
  >
    <m-icon
      color="white"
      :size="iconSize"
      :type="icon"
    />
    <span
      v-if="showIndex"
      class="_number"
    >{{ fieldIndex + 1 }}</span>
  </div>
</template>

<script>
import colors from 'shared/colors';
import {
  LONG_TEXT_ICON, OPINION_SCALE_ICON, WELCOME_SCREEN_ICON,
} from 'shared/transformers';
import { fieldType } from 'shared/constants.json';

export default {
  name: 'FormFieldIcon',
  props: {
    type: {
      type: String,
      required: true,
    },
    fieldIndex: {
      type: Number,
      default: 0,
    },
    showIndex: {
      type: Boolean,
      default: true,
    },
    square: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    iconSize() {
      switch (true) {
        case this.small:
          return '12';
        default:
          return '14';
      }
    },
    backgroundColor() {
      switch (this.type) {
        case fieldType.opinionScale:
          return colors.red.lighten2;
        case fieldType.longText:
          return colors.green.lighten2;
        case fieldType.welcomeScreen:
          return colors.blue.lighten2;
        default:
          return colors.shades.white;
      }
    },
    style() {
      return { 'background-color': this.backgroundColor };
    },
    icon() {
      switch (this.type) {
        case fieldType.opinionScale:
          return OPINION_SCALE_ICON;
        case fieldType.longText:
          return LONG_TEXT_ICON;
        case fieldType.welcomeScreen:
          return WELCOME_SCREEN_ICON;
        default:
          return '';
      }
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .form-field-icon {
    display: flex;
    align-items: center;
    width: 5.2rem;
    height: 2.4rem;
    padding: 0 .8rem 0 .5rem;
    border-radius: $border-radius-sm;

    &.-square {
      width: 2.4rem;
    }

    &.-small {
      width: 4rem;
      height: 2.2rem;

      &.-square {
        width: 2.2rem;
      }
    }

    ._number {
      margin-left: auto;
      font-weight: bold;
      color: white;
    }
  }
</style>
