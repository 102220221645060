import useAccess from '@/composables/access/access';
import useCurrentView from '@/composables/saved-views/current-view';
import useDefaultView from '@/composables/saved-views/default-view';
import useEnrichParams from '@/composables/saved-views/enrich-params';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useReadOnly from '@/composables/saved-views/read-only';
import useSelectedViews from '@/composables/saved-views/selected-views';
import useViews from '@/composables/saved-views/views';
import useViewsRepo from '@/composables/saved-views/views-repo';
import useViewsRepoLocal from '@/composables/saved-views/views-repo-local';
import { accessGroupFlag } from 'shared/constants.json';
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export default function useRouteAwareViews({ defaultProps, defaultView, application, space = null, planning = null, user = null, readOnly }) {
  const router = useRouter();
  const route = useRoute();
  const { loggedInUserAccount } = useLoggedInUserAccount();
  const { loggedInUser } = useLoggedInUser();
  const selectedViewsSvc = useSelectedViews();
  const viewsRepo = useViewsRepo({ application, space, planning, user });
  const defaultViewRepo = useDefaultView(defaultView, defaultProps, application, space, planning, user, loggedInUser, loggedInUserAccount, null, viewsRepo);
  const viewsRepoLocal = useViewsRepoLocal(defaultViewRepo, loggedInUserAccount);
  const enrichParamsRepo = useEnrichParams(viewsRepoLocal);
  const readOnlyRepo = useReadOnly(readOnly, enrichParamsRepo);

  const { userHasRight } = useAccess();

  const selectedViewForApplication = selectedViewsSvc.getSelectedViewForApplication({
    application,
    space,
    planning,
    user,
  });

  const changeSelectedView = (view) => {
    router.push({ query: { ...route.query, viewId: view.uid } });
    selectedViewsSvc.saveOrUpdateSelectedView(selectedViewForApplication.value, view.uid);
  };

  const viewsSvc = useViews({
    application,
    space,
    planning,
    user,
    selectedViewsSvc,
    changeSelectedView,
    repo: readOnlyRepo,
  });

  const currentViewSvc = useCurrentView({
    defaultProps,
    views: viewsSvc.views,
    hasPublicViewAccess: userHasRight([accessGroupFlag.publicSavedViews]),
    selectedView: selectedViewForApplication,
    readOnly,
  });

  const parseViewId = (routeViewId) => {
    if (typeof routeViewId === 'undefined') {
      return 0;
    }
    const viewId = parseInt(routeViewId, 10);
    return Number.isNaN(viewId) ? 0 : viewId;
  };

  const routeViewId = computed(() => {
    if (readOnly) {
      return parseViewId(route.params.savedViewId);
    }
    return parseViewId(route.query.viewId);
  });

  if (routeViewId.value !== 0 && viewsSvc.selectedView.value.view.uid !== routeViewId.value.uid) {
    selectedViewsSvc.saveOrUpdateSelectedView(selectedViewForApplication.value, routeViewId.value);
  }

  return {
    createLoading: viewsSvc.createLoading,
    createViews: viewsSvc.createViews,

    loadingUpdateView: viewsSvc.loadingUpdateView,
    updateView: viewsSvc.updateView,

    loadingUpdateMetaData: viewsSvc.loadingUpdateMetaData,
    updateMetaData: viewsSvc.updateMetaData,

    loadingDeleteView: viewsSvc.loadingDeleteView,
    deleteView: viewsSvc.deleteView,

    setParams: viewsSvc.setParams,
    updateSort: viewsSvc.updateSort,
    updateCycles: viewsSvc.updateCycles,

    hasUnsavedChanges: viewsSvc.hasUnsavedChanges,

    resetView: viewsSvc.resetView,

    views: viewsSvc.views,
    viewCollection: viewsSvc.viewCollection,

    selectSingle: viewsRepo.selectSingle,

    currentView: currentViewSvc.currentView,
    canEditCurrentView: currentViewSvc.canEditCurrentView,

    changeSelectedView,

    initCurrentView: viewsSvc.initCurrentView,
  };
}
