<template>
  <m-dialog
    :value="showReleaseNotes"
    max-width="80rem"
    no-padding
    hide-header
    hide-footer
    center
    max-height="80vh"
    @close="closeReleaseNotes"
  >
    <release-notes-wrapper
      :unread-release-notes="unreadReleaseNotes"
      :release-notes="releaseNotes"
      @close="closeReleaseNotes"
    />
  </m-dialog>
</template>

<script>
import ReleaseNotesWrapper from '@/release-notes/ReleaseNotesWrapper.vue';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import usePersonalAppSettings from '@/composables/logged-in-user/personal-app-settings';
import useReleaseNotes from '@/composables/release-notes/release-notes';
import useSnackBar from '@/composables/snackbar';
import { EventBus } from '@/lib/event-bus';
import { logCatch } from '@/lib/logger/logger';

export default {
  name: 'ReleaseNotesDialog',
  components: { ReleaseNotesWrapper },
  data() {
    return { showReleaseNotes: false };
  },
  setup() {
    const snackbar = useSnackBar();

    const { loggedInUser } = useLoggedInUser();
    const personalAppSettingsSvc = usePersonalAppSettings(loggedInUser);
    const { releaseNotes, unreadReleaseNotes, markAsRead, fetchReleaseNotes } = useReleaseNotes(personalAppSettingsSvc, loggedInUser);
    return {
      snackbar,
      personalAppSettings: personalAppSettingsSvc.personalAppSettings,
      unreadReleaseNotes,
      markAsRead,
      fetchReleaseNotes,
      releaseNotes,
    };
  },
  methods: {
    closeReleaseNotes() {
      this.showReleaseNotes = false;
      this.markAsRead().catch(logCatch(() => {
        this.snackbar.error();
      }));
    },
    show() {
      this.showReleaseNotes = true;
    },
  },
  watch: {
    $route() {
      this.showReleaseNotes = false;
    },
  },
  created() {
    EventBus.$on('show-release-notes', this.show);
    this.fetchReleaseNotes().then(() => {
      this.showReleaseNotes = this.unreadReleaseNotes.some((next) => next.automaticallyShowDialog);
    });
  },
  beforeUnmount() {
    EventBus.$off('show-release-notes', this.show);
  },
};
</script>

<style scoped lang="scss" type="text/scss">

</style>
