import useBuchheim from '@/lib/tree/buchheim';
import { computed } from 'vue';
import { normalizeTree } from '@/lib/goal/normalize-tree';

export default function useTreeData(goalTreeService, childrenKey, distanceX, distanceY, levelHeight, cardWidth, cardPadding) {
  const { buchheimLayout } = useBuchheim(childrenKey, 0.2);
  const translatePosition = (height) => (node) => {
    node.x *= (cardWidth.value + distanceX);
    node.isRoot = node.y - 1 === 0;
    node.y = (node.y - 1) * (height.value + distanceY);
  };

  const transformTree = (tree, transformer, childrenKey) => {
    for (let i = 0; i < tree[childrenKey].length; i++) {
      transformTree(tree[childrenKey][i], transformer, childrenKey);
    }
    transformer(tree);
  };

  const layout = (tree, height) => {
    if (tree.length === 0) {
      return [];
    }
    const root = { uid: 'root', [childrenKey]: tree, entity: { uid: 'root' } };
    buchheimLayout(root);
    transformTree(root, translatePosition(height), childrenKey);

    return root[childrenKey];
  };

  const line = (from, to) => ({
    from: {
      uid: from.index,
      x: from.x,
      y: from.y + cardPadding,
    },
    to: {
      uid: to.index,
      x: to.x,
      y: to.y + cardPadding,
    },
  });

  const makeEdges = (node, from, childrenKey) => {
    const res = [];
    node[childrenKey].forEach((c) => {
      res.push(...makeEdges(c, node, childrenKey));
    });

    if (from === null) {
      return res;
    }

    res.push(line(from, node));
    return res;
  };

  const createEdges = (tree) => tree.reduce((res, node) => {
    res.push(...makeEdges(node, null, childrenKey));
    return res;
  }, []);

  const goalTree = computed(() => layout(goalTreeService.goalTree.value, levelHeight));

  const normalizedTree = computed(() => normalizeTree(childrenKey)(goalTree.value));

  const edges = computed(() => createEdges(goalTree.value));

  const minMax = computed(() => normalizedTree.value.reduce(
    (res, next) => {
      res.minX = Math.min(next.x, res.minX);
      res.minY = Math.min(next.y, res.minY);
      res.maxX = Math.max(next.x + cardWidth.value, res.maxX);
      res.maxY = Math.max(next.y + levelHeight.value, res.maxY);
      return res;
    },
    { maxX: 0, maxY: 0, minX: 0, minY: 0 },
  ));

  const width = computed(() => minMax.value.maxX - minMax.value.minX);
  const height = computed(() => minMax.value.maxY - minMax.value.minY);

  return {
    nodes: normalizedTree,
    edges,
    width,
    height,
    minMax,
  };
}
