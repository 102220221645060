import { DateTime } from 'luxon';
import { intervalType } from 'shared/num_constants.json';

export const getMonthFromIntervals = (intervals) => intervals.map((i) => {
  const from = DateTime.fromISO(i.from);
  const till = DateTime.fromISO(i.till);
  const midDate = from.plus(till.diff(from));
  return midDate.toFormat('LLL yyyy');
});

export const getLastDayFromIntervals = (intervals) => intervals.map((i) => {
  const till = DateTime.fromISO(i.till);
  return till.toLocaleString();
});

export const getCategoriesFromIntervals = (intervals, type) => {
  switch (type) {
    case intervalType.monthly:
      return getMonthFromIntervals(intervals);
    case intervalType.weekly:
    case intervalType.daily:
      return getLastDayFromIntervals(intervals);
    default:
      return getMonthFromIntervals(intervals);
  }
};
