import { DateTime } from 'luxon';
import { accountStatus } from 'shared/constants.json';
import { shallowCopy } from 'shared/lib/copy';

const contractStartThreshold = { months: 1 };
const userFirstSessionCreatedAtThreshold = { months: 1 };
const userSessionsThreshold = 4;
const feedbackLastAskedThreshold = { months: 3 };
const feedbackLastAnsweredThreshold = { months: 6 };

export const canDisplayAppFeedback = (account, user, appFeedbacks, t = DateTime.local().toISO()) => {
  const now = DateTime.fromISO(t);
  if (account.accountSettings.status !== accountStatus.active) {
    return false;
  }
  if (DateTime.fromISO(account.accountSettings.expiresAt) < now) {
    return false;
  }
  if (account.customerContract.startedAt === undefined) {
    return false;
  }
  if (!account.accountSettings.receivesInAppFeedback) {
    return false;
  }
  if (DateTime.fromISO(account.customerContract.startedAt) > now.minus(contractStartThreshold)) {
    return false;
  }
  if (DateTime.fromISO(user.firstSessionCreatedAt) > now.minus(userFirstSessionCreatedAtThreshold)) {
    return false;
  }
  if (user.sessionCount < userSessionsThreshold) {
    return false;
  }

  const nullDate = DateTime.local(2020);
  const sortByDateDescending = (a, b) => DateTime.fromISO(b.createdAt).diff(DateTime.fromISO(a.createdAt)).valueOf();
  const appFeedbacksCopy = shallowCopy(appFeedbacks);
  const feedbackHistory = appFeedbacksCopy.sort(sortByDateDescending).reduce((acc, next) => {
    const createdAt = DateTime.fromISO(next.createdAt);
    if (createdAt > acc.lastAskedTimestamp) {
      acc.lastAskedTimestamp = createdAt;
    }
    if (!next.ignored && createdAt > acc.lastFeedbackTimestamp) {
      acc.lastFeedbackTimestamp = createdAt;
    }
    return acc;
  }, {
    lastFeedbackTimestamp: nullDate,
    lastAskedTimestamp: nullDate,
  });

  if (feedbackHistory.lastAskedTimestamp > now.minus(feedbackLastAskedThreshold)) {
    return false;
  }
  if (feedbackHistory.lastFeedbackTimestamp < now.minus(feedbackLastAnsweredThreshold)) {
    return true;
  }
  return false;
};
