import { computed, ref } from 'vue';

const currentInlineEditingIdentifier = ref(null);
export default function useInlineEditing() {
  const isInlineEditing = (identifier) => computed(() => identifier === currentInlineEditingIdentifier.value);

  const set = (identifier) => {
    currentInlineEditingIdentifier.value = identifier;
  };

  const reset = () => {
    currentInlineEditingIdentifier.value = null;
  };

  return {
    isInlineEditing,
    set,
    reset,
  };
}
