<template>
  <div
    class="tree-edge"
    :style="style"
  >
    <div
      :style="{ position: 'relative', width: `${width + borderWidth}px`, height: `${height}px` }"
      class="_wrapper"
    >
      <div
        :style="verticalLine1"
        class="_line"
      />
      <div
        :style="horizontalLine"
        class="_line"
      />
      <div
        :style="verticalLine2"
        class="_line"
      />
    </div>
  </div>
</template>

<script>
import { rgbaToHex } from 'shared/lib/color';

const turningPointDistance = 30;
export default {
  name: 'TreeEdge',
  props: {
    from: {
      type: Object,
      required: true,
    },
    to: {
      type: Object,
      required: true,
    },
    cardWidth: {
      type: Number,
      required: true,
    },
  },
  data() {
    return { borderWidth: 2 };
  },
  computed: {
    borderColor() {
      return rgbaToHex(this.$colors.grey.lighten3);
    },
    drawToLeft() {
      return this.from.x > this.to.x;
    },
    verticalLine1() {
      const baseStyle = {
        position: 'absolute',
        width: `${this.borderWidth}px`,
        backgroundColor: this.borderColor,
      };
      if (this.drawToLeft) {
        return {
          ...baseStyle,
          top: 0,
          left: `${this.width}px`,
          height: `${this.height - turningPointDistance}px`,
        };
      }
      return {
        ...baseStyle,
        top: 0,
        left: '0px',
        height: `${this.height - turningPointDistance}px`,
      };
    },
    horizontalLine() {
      return {
        position: 'absolute',
        height: `${this.borderWidth}px`,
        backgroundColor: this.borderColor,
        width: 'calc(100% - 1px)',
        top: `${this.height - turningPointDistance}px`,
        left: '0px',
      };
    },
    verticalLine2() {
      const baseStyle = {
        position: 'absolute',
        width: `${this.borderWidth}px`,
        backgroundColor: this.borderColor,
      };
      if (this.drawToLeft) {
        return {
          ...baseStyle,
          left: '0px',
          top: `${this.height - turningPointDistance}px`,
          height: `${turningPointDistance}px`,
        };
      }
      return {
        ...baseStyle,
        left: `${this.width}px`,
        top: `${this.height - turningPointDistance}px`,
        height: `${turningPointDistance}px`,
      };
    },
    width() {
      return Math.abs(this.from.x - this.to.x);
    },
    height() {
      return Math.abs(this.from.y - this.to.y);
    },
    left() {
      if (this.drawToLeft) {
        return this.to.x + this.cardWidth / 2 - this.borderWidth / 2;
      }
      return this.from.x + this.cardWidth / 2 - this.borderWidth / 2;
    },
    style() {
      return {
        position: 'absolute',
        top: `${this.from.y}px`,
        left: `${this.left}px`,
      };
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
.tree-edge {
  transition: top $goal-tree-transition-length, left $goal-tree-transition-length;
  animation: 0.5s fade;

  ._wrapper {
    transition: width $goal-tree-transition-length;

    ._line {
      transition: top $goal-tree-transition-length, left $goal-tree-transition-length, width $goal-tree-transition-length;
    }
  }

  @keyframes fade {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}
</style>
