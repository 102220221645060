<template>
  <page-layout :class="['meetings-explorer']">
    <template #topBar>
      <page-top-bar
        :breadcrumbs="breadcrumbs"
        class="_header"
      >
        <template #actions>
          <div>
            <favorite-button
              v-if="!$store.state.breakpoint.smAndDown"
              :title-suggestion="currentView.title"
              :icon-suggestion="buildIcon(currentView.emoji)"
            />
            <m-dropdown
              v-else
              :title="$t('general.actions')"
            >
              <m-btn
                icon="ellipsis"
                hide-border
                fab
              />
              <template #overlay>
                <m-card

                  no-padding
                  list
                >
                  <favorite-button
                    :title-suggestion="currentView.title"
                    :icon-suggestion="buildIcon(currentView.emoji)"
                  />
                </m-card>
              </template>
            </m-dropdown>
          </div>
        </template>
      </page-top-bar>
    </template>
    <scroll-container>
      <m-content
        padding-x="layout"
        class="_header"
      >
        <editable-page-header
          :key="title"
          :title="title"
          disabled
        />
      </m-content>
      <template v-if="$route.name === routeName.meetingsExplorer">
        <meetings-list-wrapper
          :header-slots="headerSlots"
          :view-application="viewApplication"
          :allowed-view-types="allowedViewTypes"
          :default-view="listDefaultView"
          show-views-selector
          show-create-view
          fullscreen
          stretch-content
        />
      </template>
      <template v-if="$route.name === routeName.meetingDashboard && canViewDashboards">
        <meeting-insights
          :view-application="viewApplication"
          :default-view="insightsDefaultView"
          show-create-view
        />
      </template>
    </scroll-container>
  </page-layout>
</template>

<script>
import EditablePageHeader from '@/components/page/EditablePageHeader.vue';
import FavoriteButton from '@/components/favorite/FavoriteButton.vue';
import MeetingInsights from '@/components/meeting/MeetingInsights.vue';
import MeetingsListWrapper from '@/components/meeting/MeetingsListWrapper.vue';
import PageLayout from '@/components/page/PageLayout.vue';
import PageTopBar from '@/components/page/PageTopBar.vue';
import ScrollContainer from '@/components/page/ScrollContainer.vue';
import useAccess from '@/composables/access/access';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useSelectedViews from '@/composables/saved-views/selected-views';
import { AND, CREATED_AT } from 'shared/api/query/constants';
import { SLOTS } from '@/lib/constants';
import { accessGroupFlag, routeName, viewApplication, viewType } from 'shared/constants.json';
import { buildIcon } from 'shared/lib/icon';

export default {
  name: 'MeetingsExplorer',
  components: {
    EditablePageHeader,
    PageTopBar,
    PageLayout,
    ScrollContainer,
    MeetingInsights,
    FavoriteButton,
    MeetingsListWrapper,
  },
  data() {
    return {
      routeName,
      headerSlots: [{ name: SLOTS.FILTER }, { name: SLOTS.SORTER }, { name: SLOTS.STRING_FILTER }, { name: SLOTS.PROPERTIES }, { name: SLOTS.CREATE }],
    };
  },
  computed: {
    viewApplication() {
      if (this.$route.name === routeName.meetingDashboard) {
        return viewApplication.meetingDashboard;
      }

      return viewApplication.meeting;
    },
    currentView() {
      return this.getSelectedViewViewForApplication({ application: this.viewApplication }).value || {};
    },
    listDefaultView() {
      return {
        viewType: viewType.list,
        title: this.$t('list.list'),
        viewApplication: this.viewApplication,
        params: {
          filter: { account: { uid: this.account.uid }, children: [], op: AND },
          order: [{ attr: CREATED_AT, desc: true }],
          props: [],
        },
      };
    },
    insightsDefaultView() {
      return {
        viewType: viewType.default,
        title: this.$t('list.dashboard'),
        viewApplication: this.viewApplication,
        params: {
          filter: { account: { uid: this.account.uid }, children: [], op: AND },
          order: [],
          props: [],
        },
      };
    },
    allowedViewTypes() {
      if (this.$route.name === routeName.meetingDashboard) {
        return [viewType.default];
      }
      return [viewType.list];
    },
    canViewDashboards() {
      return this.userHasRight([accessGroupFlag.meetingDashboardsAccess]);
    },
    title() {
      return this.breadcrumbs[this.breadcrumbs.length - 1].title;
    },
    breadcrumbs() {
      const currentItem = () => {
        if (this.$route.name === routeName.meetingDashboard) {
          return {
            title: this.$t('meetingsExplorer.dashboard'),
            disabled: true,
          };
        }
        return {
          title: this.$t('navigation.explorer'),
          disabled: true,
        };
      };
      return [
        {
          title: this.$t('navigation.meetings'),
          disabled: true,
          icons: [{ value: buildIcon('Calendar') }],
        },
        currentItem(),
      ];
    },
  },
  methods: { buildIcon },
  setup() {
    const { userHasRight } = useAccess();
    const { getSelectedViewViewForApplication } = useSelectedViews();
    const { loggedInUserAccount } = useLoggedInUserAccount();
    return {
      getSelectedViewViewForApplication,
      userHasRight,
      account: loggedInUserAccount,
    };
  },
};
</script>

<style
  scoped
  lang="scss"
  type="text/scss"
>
</style>
