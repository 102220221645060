import useBulkMutate from '@/nebula/bulk-mutate';
import useRepo from '@/nebula/repo';
import { property as propertyConfig, propertyOption as propertyOptionConfig, resourceSettings as resourceSettingsConfig, space as spaceConfig } from 'shared/api/query/configs.json';
import { propertyEdges } from '@/api/query/nebula/property';
import { propertyOptionEdges } from '@/api/query/nebula/property-option';
import { children as resourceSettingsEdges } from '@/api/query/nebula/resource-settings';
import { spaceEdges } from '@/api/query/nebula/space';

export default function usePropertyRepo() {
  const model = propertyConfig.model;
  const repo = useRepo(model);

  const bulkMutator = useBulkMutate();
  const createSingleWithOptions = (entity) => {
    const ridProperty = 1;
    const options = entity.options.map((o) => ({
      ...o,
      property: { rid: ridProperty },
    }));
    const payloads = [
      {
        alias: 'createProperty',
        nodes: [{ ...entity, rid: ridProperty, propertyOptionOrder: undefined }],
        model: propertyConfig.model,
        attributes: propertyEdges,
      },
    ];
    if (options.length > 0) {
      payloads.push({
        alias: 'createPropertyOptions',
        nodes: options,
        model: propertyOptionConfig.model,
        attributes: propertyOptionEdges,
      });
    }
    return bulkMutator.bulkMutate(payloads).then((res) => repo.selectSingle(res.data.createProperty[0].uid));
  };

  const updateSingleWithOptions = (entity) => {
    const options = entity.options.map((o) => ({
      ...o,
      property: { uid: entity.uid },
    }));
    const payloads = [
      {
        alias: 'updateProperty',
        nodes: [entity],
        model: propertyConfig.model,
        attributes: propertyEdges,
      },
    ];
    if (options.length > 0) {
      payloads.push({
        alias: 'updatePropertyOptions',
        nodes: options,
        model: propertyOptionConfig.model,
        attributes: propertyOptionEdges,
      });
      payloads.push({
        alias: 'orderPropertyOptions',
        nodes: [{ uid: entity.uid, propertyOptionOrder: entity.propertyOptionOrder }],
        model: propertyConfig.model,
        attributes: propertyEdges,
      });
    }
    return bulkMutator.bulkMutate(payloads).then((res) => repo.selectSingle(res.data.updateProperty[0].uid));
  };

  const createSingleWithSpaces = (entity) => {
    const payloads = [
      {
        alias: 'createProperty',
        nodes: [entity],
        model: propertyConfig.model,
        attributes: propertyEdges,
      },
    ];
    if (entity.spaces.length > 0) {
      payloads.push({
        alias: 'createSpaces',
        nodes: entity.spaces,
        model: spaceConfig.model,
        attributes: spaceEdges,
      });
      payloads.push({
        alias: 'orderSpaces',
        nodes: [entity.resourceSettings],
        model: resourceSettingsConfig.model,
        attributes: resourceSettingsEdges,
      });
    }
    return bulkMutator.bulkMutate(payloads).then((res) => repo.selectSingle(res.data.createProperty[0].uid));
  };

  const updateSingleWithSpaces = (entity) => {
    const payloads = [
      {
        alias: 'updateProperty',
        nodes: [entity],
        model: propertyConfig.model,
        attributes: propertyEdges,
      },
    ];
    if (entity.spaces.length > 0) {
      payloads.push({
        alias: 'updateSpaces',
        nodes: entity.spaces,
        model: spaceConfig.model,
        attributes: spaceEdges,
      });
      payloads.push({
        alias: 'orderSpaces',
        nodes: [entity.resourceSettings],
        model: resourceSettingsConfig.model,
        attributes: resourceSettingsEdges,
      });
    }
    return bulkMutator.bulkMutate(payloads).then((res) => repo.selectSingle(res.data.updateProperty[0].uid));
  };

  return {
    entityList: repo.entityList,
    getList: repo.getList,

    updateSingle: repo.updateSingle,
    updateSingleLoading: repo.updateLoading,

    createLoading: repo.createLoading,
    createSingle: repo.createSingle,

    deleteLoading: repo.deleteLoading,
    deleteSingle: repo.deleteSingle,

    createSingleWithOptions,
    updateSingleWithOptions,
    createSingleWithSpaces,
    updateSingleWithSpaces,

    selectSingle: repo.selectSingle,
    selectMultiple: repo.selectMultiple,
  };
}
