export const searchTermFilter = ({ searchTerm = '', edgeName }) => {
  if (searchTerm === '') {
    return null;
  }

  return {
    func: {
      attr: edgeName,
      name: 'regexp',
      args: [{ value: searchTerm }, { value: 'i' }],
    },
  };
};
