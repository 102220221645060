<template>
  <div
    :style="style"
    class="form-footer"
  >
    <div class="_container">
      <div class="_left">
        <div class="_description">
          {{ answers.length }} {{ $t('formFooter.from') }} {{ numberOfQuestions }} {{ $t('formFooter.answered') }}
        </div>
        <div class="_progress">
          <progress-bar :value="progress" />
        </div>
      </div>
      <div class="_right">
        <div class="_navigation">
          <div
            :class="['btn -back', backButtonInactive ? '-inactive' : '']"
            @click="goBack"
          >
            <m-icon type="up" />
          </div>
          <div
            :class="['btn -forward', forwardButtonInactive ? '-inactive' : '']"
            @click="goForward"
          >
            <m-icon type="down" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProgressBar from 'shared/components/internal/form/ProgressBar.vue';

export default {
  name: 'FormFooter',
  props: {
    currentQuestionIndex: {
      type: Number,
      required: true,
    },
    answers: {
      type: Array,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
    width: {
      type: String,
      required: true,
    },
    right: {
      type: String,
      required: true,
    },
    bottom: {
      type: String,
      required: true,
    },
  },
  emits: ['scroll-to-next-question', 'scroll-to-previous-question'],
  components: { ProgressBar },
  computed: {
    numberOfQuestions() {
      return this.form.orderItems.length;
    },
    progress() {
      return Math.round((this.answers.length / this.numberOfQuestions) * 100);
    },
    backButtonInactive() {
      return this.currentQuestionIndex === 0;
    },
    forwardButtonInactive() {
      return this.currentQuestionIndex >= this.numberOfQuestions;
    },
    style() {
      return { width: this.width, right: this.right, bottom: this.bottom };
    },
  },
  methods: {
    goForward() {
      if (this.forwardButtonInactive) {
        this.$emit('scroll-to-next-question', { questionIndex: this.currentQuestionIndex });
      }
      this.$emit('scroll-to-next-question', { questionIndex: this.currentQuestionIndex + 1 });
    },

    goBack() {
      if (this.backButtonInactive) {
        this.$emit('scroll-to-previous-question', { questionIndex: this.currentQuestionIndex });
      }
      this.$emit('scroll-to-previous-question', { questionIndex: this.currentQuestionIndex - 1 });
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .form-footer {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 7.2rem;
    background-color: rgb(255 255 255 / 90%);

    ._container {
      display: flex;
      width: 95%;
      max-width: 70rem;
      padding: 0 2rem;

      ._left {
        flex: 1 1 auto;

        ._description {
          margin: .7rem 0 .4rem;
          color: map_get($grey, 'darken-1');
        }

        ._progress {
          max-width: 24rem;
        }
      }

      ._right {
        ._navigation {
          display: flex;

          .btn {
            width: 4rem;
            height: 4rem;
            padding: 0;
            margin-left: .4rem;
          }
        }
      }
    }
  }
</style>
