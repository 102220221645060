import useLocalOverwrite from '@/composables/saved-views/local-overwrite';
import { savedView as savedViewConfig } from 'shared/api/query/configs.json';

const SAVED_VIEW_LOCAL_OVERWRITE_SORT = 'saved_view_local_overwrite_sort_v2';

export default function useLocalOverwritesSort(localStorage, loggedInUserAccount) {
  const localStorageKey = `${loggedInUserAccount.value.uid}_${SAVED_VIEW_LOCAL_OVERWRITE_SORT}`;

  const localOverwrites = useLocalOverwrite(savedViewConfig.edges.goalSort, localStorageKey, localStorage);

  return {
    saveOverwrite: localOverwrites.saveOverwrite,
    removeLocalOverwrite: localOverwrites.removeLocalOverwrite,
    mergeWithLocalOverwrites: localOverwrites.mergeWithLocalOverwrites,
    initOverwrites: localOverwrites.initOverwrites,
  };
}
