<template>
  <div class="customize-page">
    <div class="_header">
      {{ $t('customizePage.header') }}
      <m-tag
        :title="goalTypeLabel"
        :color="goalType.color"
        small
        class="_tag"
      />
    </div>
    <m-draggable
      class="_items"
      draggable-item-class="m-card-item"
      dragover-item-class="m-card-item"
      can-drag-over-top
      can-drag-over-bottom
      :drag-between-height="12"
      @set-drag-item="setDragItem"
      @over-top="setOverTop"
      @over-bottom="setOverBottom"
      @drag-drop="handleDrop"
      @cancel="cancelDragging"
    >
      <m-card-item
        v-for="p in sortedGoalDetailPageProperties"
        :key="p.key"
        class="_item"
        :data-id="p.key"
        @click="$refs[`select_${p.key}`][0].show()"
      >
        <div
          v-if="draggingOverTop.includes(p.key)"
          class="_drag-over-top"
        />
        <div class="_icon">
          <m-icon
            type="drag"
            :color="$colors.grey.base"
          />
        </div>
        <div class="_property-icon">
          <m-icon :type="getIcon(p)" />
        </div>
        {{ textByLang(p.label, userLang) }}
        <template #right>
          <m-select
            :ref="`select_${p.key}`"
            :items="p.key === DIRECT_PROPERTY_PROGRESS_KEY ? directPropsVisibilityOptions : visibilityOptions"
            :value="p.goalVisibility"
            light
            xs
            hide-border
            @input="updateVisibility(p, $event, 'goalVisibility')"
          />
        </template>
        <div
          v-if="draggingOverBottom.includes(p.key)"
          class="_drag-over-bottom"
        />
      </m-card-item>
    </m-draggable>
  </div>
</template>

<script>
import useGoalDetailProperties from '@/composables/customize-page/goal-detail-page-properties';
import useGoalSettings from '@/composables/logged-in-user-account/goal-settings';
import useGoalTypeProperty from '@/composables/customize-page/goal-type-property';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useSort from '@/composables/draggable/sort';
import { DIRECT_PROPERTY_PROGRESS_KEY } from '@/lib/constants';

import { iconByType } from '@/lib/property';
import { logCatch } from '@/lib/logger/logger';
import { mapActions } from 'vuex';
import { propertyVisibility } from 'shared/constants.json';
import { textByLang } from 'shared/lib/language';
import { toRef } from 'vue';

export default {
  name: 'CustomizePage',
  props: {
    goal: {
      type: Object,
      required: true,
    },
  },
  data() {
    return { textByLang, DIRECT_PROPERTY_PROGRESS_KEY };
  },
  setup(props) {
    const {
      setDragItem,
      setOverBottom,
      setOverTop,
      draggingOverBottom,
      draggingOverTop,
      dropItem,
      cancelDragging,
    } = useSort('key');

    const { loggedInUser } = useLoggedInUser();
    const { goalSettings, updateGoalSettings } = useGoalSettings();
    const { goalTypeProperty } = useGoalTypeProperty();
    const { sortedGoalDetailPageProperties, updateVisibility, goalType } = useGoalDetailProperties({
      goal: toRef(props, 'goal'),
      userLang: loggedInUser.value.language,
      goalTypeProperty,
    });
    return {
      sortedGoalDetailPageProperties,
      updateVisibility,
      goalType,
      userLang: loggedInUser.value.language,

      updateGoalSettings,
      setDragItem,
      setOverBottom,
      setOverTop,
      draggingOverBottom,
      draggingOverTop,
      dropItem,
      cancelDragging,
      goalSettings,
    };
  },
  computed: {
    goalTypeLabel() {
      return textByLang(this.goalType.label, this.userLang);
    },
    directPropsVisibilityOptions() {
      return [
        {
          text: this.$t('propertyLabel.alwaysVisible'),
          value: propertyVisibility.alwaysVisible,
        },
        {
          text: this.$t('propertyLabel.alwaysHide'),
          value: propertyVisibility.alwaysHidden,
        },
      ];
    },
    visibilityOptions() {
      return [
        {
          text: this.$t('propertyLabel.alwaysVisible'),
          value: propertyVisibility.alwaysVisible,
        },
        {
          text: this.$t('propertyLabel.hideWhenEmpty'),
          value: propertyVisibility.hideWhenEmpty,
        },
        {
          text: this.$t('propertyLabel.alwaysHide'),
          value: propertyVisibility.alwaysHidden,
        },
      ];
    },
  },
  methods: {
    ...mapActions(['updateEntityV2']),
    getIcon(property) {
      if (typeof property.icon !== 'undefined') {
        return property.icon;
      }
      return iconByType(property);
    },
    handleDrop() {
      const newOrder = this.dropItem(this.sortedGoalDetailPageProperties).map((i) => i.key);
      this.savePropertyOrder(newOrder);
    },
    savePropertyOrder(order) {
      this.updateGoalSettings(
        { uid: this.goalSettings.uid, propertyOrder: order },
      ).catch(logCatch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      }));
    },
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  .customize-page {
    ._header {
      display: flex;
      align-items: center;
      padding: 1.2rem 1.6rem;
      border-bottom: 1px solid $border-color;

      ._tag {
        margin-left: .4rem;
      }
    }

    ._sub-header {
      padding: 1.6rem 1.6rem .4rem;
      font-size: $font-size-2;
      font-weight: $font-weight-semibold;
      color: $font-color-secondary;
      text-transform: uppercase;
      letter-spacing: .05em;
    }

    ._items {
      padding-top: .4rem;

      ._icon {
        margin: 0 .4rem 0 -.3rem;
        cursor: grab;
      }

      ._property-icon {
        margin: 0 .6rem 0 .4rem;
      }

      ._item {
        position: relative;

        ._drag-over-top {
          position: absolute;
          top: -2px;
          right: 0;
          left: 0;
          z-index: 88;
          width: 100%;
          height: 4px;
          pointer-events: none;
          background: $highlighted-color-dark;
          opacity: 1;
        }

        ._drag-over-bottom {
          position: absolute;
          right: 0;
          bottom: -2px;
          left: 0;
          z-index: 88;
          width: 100%;
          height: 4px;
          pointer-events: none;
          background: $highlighted-color-dark;
          opacity: 1;
        }
      }
    }
  }
</style>
