<template>
  <div class="salesforce-form">
    <m-content
      v-if="!getReportDetailsLoading && hasError"
      :padding-top="0"
      :padding-x="11"
      :padding-bottom="2"
    >
      <m-alert
        class="_error-message"
        type="error"
        :message="errorMessage"
      />
    </m-content>
    <template v-if="dataSource[customDatasourceProperty.salesforceError] === ''">
      <m-content
        :padding-top="0"
        :padding-x="11"
        :padding-bottom="2"
      >
        <div class="_item">
          <div class="_label">
            {{ $t('salesforce.selectReportLabel') }}
          </div>
          <m-select
            v-model:value="localSalesforceQuery.reportId"
            :items="dataSource[customDatasourceProperty.salesforceReports]"
            value-key="id"
            item-text="name"
            full-width
            match-trigger-width
            show-search
            @change="reset"
          />
        </div>
        <template
          v-if="localSalesforceQuery.reportId !== null && dataSource[customDatasourceProperty.salesforceReports].length > 0"
        >
          <div class="_item">
            <div class="_label">
              {{ $t('salesforce.selectFieldLabel') }}
            </div>
            <m-select
              v-model:value="localSalesforceQuery.reportFieldId"
              :items="fields"
              value-key="identifier"
              item-text="label"
              full-width
              match-trigger-width
              show-search
            />
          </div>
        </template>
        <div
          v-if="localSalesforceQuery.reportId !== null"
          class="_item"
          :style="{ marginTop: '2rem' }"
        >
          <always-sync-checkbox v-model:value="localSalesforceQuery.alwaysSync" />
        </div>
      </m-content>
      <template v-if="typeof localSalesforceQuery.uid === 'undefined'">
        <m-divider none />
        <m-content padding-small>
          <div class="_actions">
            <div class="_btns">
              <m-btn
                class="_btn"
                @click="$emit('close')"
              >
                {{ $t('general.cancel') }}
              </m-btn>
              <m-btn
                color="primary"
                class="_btn"
                :loading="mutateLoading"
                :disabled="selectedReport === null || selectedField === null"
                @click="save"
              >
                {{ $t('general.save') }}
              </m-btn>
            </div>
          </div>
        </m-content>
      </template>
      <template v-if="typeof localSalesforceQuery.uid !== 'undefined'">
        <m-divider xs />
        <m-card-item
          icon="save"
          :loading="mutateLoading"
          :padding-x="8"
          :disabled="selectedReport === null || selectedField === null"
          @click="save"
        >
          {{ $t('general.save') }}
        </m-card-item>
        <m-card-item
          icon="sync"
          :loading="syncLoading"
          :padding-x="8"
          :disabled="selectedReport === null || selectedField === null"
          @click="syncNow"
        >
          {{ $t('dataSource.syncNow') }}
        </m-card-item>
        <m-card-item
          icon="delete"
          :padding-x="8"
          :loading="deleteLoading"
          :disabled="selectedReport === null || selectedField === null"
          @click="deleteSalesforceQuery"
        >
          {{ $t('general.delete') }}
        </m-card-item>
      </template>
    </template>
  </div>
</template>

<script>
import AlwaysSyncCheckbox from '@/components/datasource/AlwaysSyncCheckbox.vue';
import useSalesforce from '@/composables/integrations/salesforce/salesforce';
import { camelCase } from 'lodash-es';
import { computed } from 'vue';
import { copy } from 'shared/lib/copy';
import { customDatasourceProperty } from 'shared/constants.json';
import { logCatch } from '@/lib/logger/logger';

const defaultSalesforceQuery = () => ({
  reportId: null,
  reportName: null,
  reportFieldId: null,
  reportfieldname: null,
  error: null,
});

export default {
  name: 'SalesforceForm',
  props: {
    dataSource: {
      type: Object,
      required: true,
    },
    salesforceQuery: {
      type: Object,
      default: () => null,
    },
    goal: {
      type: Object,
      required: true,
    },
  },
  emits: ['close', 'deleted'],
  components: { AlwaysSyncCheckbox },
  setup() {
    const {
      createQuery,
      createQueryLoading,
      updateQuery,
      updateQueryLoading,
      deleteQuery,
      deleteQueryLoading,
      getReportDetails,
      getReportDetailsLoading,
    } = useSalesforce();

    return {
      createQuery,
      updateQuery,
      deleteQuery,
      mutateLoading: computed(() => createQueryLoading.value || updateQueryLoading.value),
      deleteLoading: deleteQueryLoading,
      getReportDetails,
      getReportDetailsLoading,
    };
  },
  data() {
    return {
      localSalesforceQuery: { ...defaultSalesforceQuery() },
      camelCase,
      syncLoading: false,
      customDatasourceProperty,
    };
  },
  computed: {
    fields() {
      if (this.localSalesforceQuery.reportId === null) {
        return [];
      }

      const report = this.dataSource[customDatasourceProperty.salesforceReports].find((r) => r.id === this.localSalesforceQuery.reportId);
      if (typeof report === 'undefined') {
        return [];
      }

      if (report.fields === undefined) {
        return [];
      }

      return report.fields;
    },
    hasError() {
      return this.dataSource[customDatasourceProperty.salesforceError] !== '';
    },
    errorMessage() {
      if (this.dataSource[customDatasourceProperty.salesforceError] !== '') {
        return this.$t(`salesforce.error.${this.dataSource[customDatasourceProperty.salesforceError]}`);
      }

      if (this.localSalesforceQuery.error !== null && this.localSalesforceQuery.error !== '') {
        return this.$t(`salesforce.error.${this.localSalesforceQuery.error}`);
      }

      return '';
    },
    selectedReport() {
      if (this.localSalesforceQuery.reportId === null) {
        return null;
      }

      const report = this.dataSource[customDatasourceProperty.salesforceReports].find((r) => r.id === this.localSalesforceQuery.reportId);
      if (typeof report === 'undefined') {
        return null;
      }

      return report;
    },
    selectedField() {
      if (this.localSalesforceQuery.reportFieldId === null) {
        return null;
      }

      if (this.selectedReport === null) {
        return null;
      }

      if (this.selectedReport.fields === undefined) {
        return null;
      }

      const field = this.selectedReport.fields.find((f) => f.identifier === this.localSalesforceQuery.reportFieldId);
      if (typeof field === 'undefined') {
        return null;
      }
      return field;
    },
  },
  methods: {
    save() {
      if (typeof this.localSalesforceQuery.uid === 'undefined') {
        this.createSalesforceQuery(this.localSalesforceQuery);
        return;
      }

      this.updateSalesforceQuery(this.localSalesforceQuery);
    },
    createSalesforceQuery(salesforceQuery) {
      this.createQuery({
        ...salesforceQuery,
        reportName: this.selectedReport.name,
        reportFieldName: this.selectedField.label,
        dataSource: { uid: this.dataSource.uid },
        goal: { uid: this.goal.uid },
      }).then((query) => {
        this.localSalesforceQuery = copy(query);
      }).catch(logCatch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      }));
    },
    updateSalesforceQuery(salesforceQuery) {
      this.updateQuery({
        ...salesforceQuery,
        reportName: this.selectedReport.name,
        reportFieldName: this.selectedField.label,
        dataSource: { uid: this.dataSource.uid },
      }, { ignoreResponse: false }).then((query) => {
        this.localSalesforceQuery = copy(query);
      }).catch(logCatch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      }));
    },
    syncNow() {
      this.syncLoading = true;
      this.updateQuery(this.goal.salesforceQuery).then((query) => {
        this.localSalesforceQuery = copy(query);
        this.$showSnackbar({ color: 'success', message: this.$t('dataSource.successfullySynced') });
      }, { ignoreResponse: false }).catch(logCatch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      })).finally(() => {
        this.syncLoading = false;
      });
    },
    deleteSalesforceQuery() {
      const deleteMethod = () => this.deleteQuery(this.goal.salesforceQuery.uid).then(() => {
        this.$emit('deleted');
        this.$emit('close');
      }).catch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      });

      this.$confirm({
        title: this.$t('dataSource.deleteConnectionPrompt'),
        okText: this.$t('general.yesDelete'),
        okType: 'danger',
        maskClosable: true,
        cancelText: this.$t('general.cancel'),
        onOk() {
          deleteMethod();
        },
      });
    },
    reset() {
      this.localSalesforceQuery.reportFieldId = null;
      this.localSalesforceQuery.reportFieldName = null;
    },
  },
  watch: {
    selectedReport(newReport, oldReport) {
      if (oldReport !== null && newReport.id === oldReport.id) {
        return;
      }

      this.getReportDetails(this.dataSource, newReport).catch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      });
    },
  },
  created() {
    if (this.salesforceQuery !== null) {
      this.localSalesforceQuery = copy(this.salesforceQuery);
    }
  },
};
</script>

<style scoped lang="scss" type="text/scss">
._section-sel {
  display: flex;

  ._and-operator {
    flex: 0 0 4rem;
    margin-top: 3.6rem;
  }

  ._section-form {
    flex: 1 1 auto;
  }
}

._label {
  margin-bottom: .4rem;
  font-size: $font-size-2;
  color: $font-color-secondary;

  ._inner {
    position: relative;

    ._help {
      position: absolute;
      top: .1rem;
      right: -1.6rem;
      cursor: help;
    }
  }
}

.salesforce-form {
  ._operation-selection {
    display: flex;
    margin-bottom: 1.2rem;
  }

  ._operation-mode-description {
    margin-bottom: 1.2rem;
    font-size: $font-size-2;
    color: $font-color-secondary;
  }

  ._item {
    margin-bottom: 1.4rem;

    &._single-switch {
      margin-top: 2rem;
    }

    ._link {
      display: flex;

      ._icon {
        margin-left: .4rem;
      }
    }
  }

  ._actions {
    display: flex;

    ._btns {
      display: flex;
      margin-left: auto;

      ._btn {
        margin-left: .4rem;
      }
    }
  }
}
</style>
