import colors from 'shared/colors';
import { $confirm } from '@/lib/confirm';
import { EventBus } from '@/lib/event-bus';
import { fontSizes } from 'shared/font-sizes';
import { modalSizes } from 'shared/modal-sizes';

export const initAppMixins = (app, i18n) => {
  app.mixin({
    computed: {
      $colors: () => colors,
      $fontSizes: () => fontSizes,
      $modalSizes: () => modalSizes,
    },
    methods: {
      $confirm,
      $hideConfirm: () => EventBus.$emit('hide-confirm'),
      // FIXME: $showSnackbar below is currently irremovable, although we shouldn't use it anymore.
      //  Elements on our shared library (such as MUrlField and MDatePicker) depend on the existence of `this.$showSnackbar` to operate correctly.
      //  This is a flaw on the design of the shared components.
      $showSnackbar: (options) => EventBus.$emit('show-snackbar', options),
      $t: i18n.global.t,
      $tm: i18n.global.tm,
    },
  });
};
