<template>
  <component
    :is="href === '' ? 'div' : 'm-link'"
    class="onboarding-card-item"
    :href="href === '' ? undefined : href"
    :target="href === '' ? undefined : '_blank'"
  >
    <m-card-item
      icon-size="16"
      :icon="icon"
      :icon-color="$colors.grey.base"
      large
      :padding-x="9"
      :padding-y="9"
      :align-top="subTitle !== '' || (progress !== undefined && progress !== 100)"
      show-as-card
      class="_item"
      @click="$emit('click')"
    >
      <div class="_inner">
        <div
          class="_top"
        >
          {{ title }}
        </div>
        <div
          v-if="progress !== undefined && progress !== 100"
          class="_bottom"
        >
          <progress-tag
            :progress="progress"
            show-text
          />
        </div>
        <div
          v-else-if="subTitle !== ''"
          class="_bottom"
        >
          {{ subTitle }}
        </div>
      </div>
      <template #right>
        <m-icon
          :type="href === '' ? 'right' : 'external-link'"
          :color="$colors.grey.lighten1"
        />
      </template>
    </m-card-item>
  </component>
</template>

<script>
import ProgressTag from '@/components/ProgressTag.vue';

export default {
  name: 'OnboardingCardItem',
  props: {
    progress: {
      type: Number,
      default: undefined,
    },
    href: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      default: '',
    },
  },
  components: { ProgressTag },
  emits: ['click'],
};
</script>

<style scoped lang="scss" type="text/scss">
  .onboarding-card-item {
    ._inner {
      ._top {
        font-weight: $font-weight-medium;
        white-space: pre-wrap;
      }

      ._bottom {
        margin-top: .4rem;
        white-space: pre-wrap;
      }
    }
  }
</style>
