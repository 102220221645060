<template>
  <div :class="['users-header']">
    <document-list-header
      :slots="slotsWithDefaults"
      :read-only="readOnly"
      :allowed-view-types="allowedViewTypes"
      :filter-props="filterProps"
      :show-create-view="showCreateView"
      :show-views-selector="showViewsSelector"
      :sort-options="sortOptions"
      :data-loading="dataLoading"
      show-saved-view-info
      has-dynamic-date-filters
    />
  </div>
</template>

<script>
import DocumentListHeader from '@/components/list/DocumentListHeader.vue';
import useAccess from '@/composables/access/access';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useUserProperty from '@/composables/property/user-property';
import useUsers from '@/composables/user/users';
import useUsersSorting from '@/composables/user/sort';
import { SLOTS } from '@/lib/constants';
import { viewType } from 'shared/constants.json';

export default {
  name: 'UsersHeader',
  props: {
    showViewsSelector: {
      type: Boolean,
      default: false,
    },
    showCreateView: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    createLoading: {
      type: Boolean,
      default: false,
    },
    filterProps: {
      type: Array,
      required: true,
    },
    allowedViewTypes: {
      type: Array,
      default: () => [
        viewType.list,
      ],
    },
    slots: {
      type: Array,
      default: () => [{ name: SLOTS.FILTER }],
      validator(slots) {
        return slots.every((slot) => [SLOTS.PROPERTIES, SLOTS.FILTER, SLOTS.SORTER].includes(slot.name));
      },
    },
    dataLoading: {
      type: Boolean,
      default: false,
    },
  },
  components: { DocumentListHeader },
  setup() {
    const { userHasRight } = useAccess();
    const { userLang, loggedInUser } = useLoggedInUser();
    const { properties: userProperties } = useUserProperty();
    const userSvc = useUsers();
    const { sortOptions } = useUsersSorting(userProperties, userSvc);
    return {
      userHasRight,
      loggedInUser,
      userLang,
      userProperties,
      sortOptions,
    };
  },
  data() {
    return { viewType };
  },
  computed: {
    slotsWithDefaults() {
      const slots = [];
      slots.push(...this.slots);
      if (this.$store.state.breakpoint.smAndDown) {
        slots.forEach((slot) => {
          if (slot.inMenu === undefined && [SLOTS.PROPERTIES, SLOTS.FILTER].includes(slot.name)) {
            slot.inMenu = true;
          }
        });
      }
      return slots;
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .users-header {
    ._new {
      display: flex;
      margin-left: .4rem;

      ._templates {
        border-left: 1px solid map_get($blue, 'darken-1');
      }
    }
  }
</style>
