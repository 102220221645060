<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7 3H9.42871V5H7C5.89543 5 5 5.89543 5 7V9.42859H3V7C3 4.79086 4.79086 3 7 3ZM3 14.5714V17C3 19.2091 4.79086 21 7 21H9.42871V19H7C5.89543 19 5 18.1046 5 17V14.5714H3ZM19 14.5714V17C19 18.1046 18.1046 19 17 19H14.5716V21H17C19.2091 21 21 19.2091 21 17V14.5714H19ZM21 9.42859V7C21 4.79086 19.2091 3 17 3H14.5716V5H17C18.1046 5 19 5.89543 19 7V9.42859H21Z"
      fill="currentColor"
    />
    <circle
      cx="12"
      cy="12"
      r="3"
      fill="#37474F"
    />
  </svg>
</template>

<script>
export default { name: 'CenterFocus' };
</script>

<style scoped lang="scss" type="text/scss">

</style>
