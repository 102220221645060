import { ref, watch } from 'vue';

export default function useLocalStorage(localStorage, keyMaker, defaultValue = null) {
  const key = keyMaker.getKey();
  const data = ref({});
  const loadFromLocalStorage = () => {
    try {
      const stored = JSON.parse(localStorage.getItem(key.value));
      if (stored === null || stored === undefined) {
        if (defaultValue !== null) {
          data.value = defaultValue;
        }
        return;
      }

      data.value = stored;
    } catch (e) {
      // dismiss data from localStorage.
    }
  };
  loadFromLocalStorage();

  watch(key, () => loadFromLocalStorage());

  watch(() => data.value, (newVal) => {
    storeLocally(newVal);
  }, { deep: true });

  const storeLocally = (items) => {
    localStorage.setItem(key.value, JSON.stringify(items));
  };

  return {
    data,
    load: loadFromLocalStorage,
  };
}
