<template>
  <div class="space-tree-item">
    <space-item
      :key="space.uid"
      :space="space"
      :removable="removable"
      :link="optionLink"
      :expanded="expanded"
      :language="language"
      :pin="pin"
      :property="property"
      :indentation="indentation"
      :is-pinned="isPinned"
      @toggle-expand="expanded = !expanded"
    />
    <m-transition-expand>
      <div
        v-show="expanded"
        class="_children"
      >
        <space-tree-item
          v-for="child in space.children"
          :key="child.uid"
          :space="child"
          :indentation="indentation + 1"
          :language="language"
          :all-spaces="allSpaces"
          :property="property"
          :pins="pins"
        />
      </div>
    </m-transition-expand>
  </div>
</template>

<script>
import SpaceItem from '@/components/navigation/SpaceItem.vue';
import useSelectedViews from '@/composables/saved-views/selected-views';
import { GOAL_VIEW } from '@/route-params';
import { findInArray } from 'shared/lib/array/array';
import { mapActions } from 'vuex';
import { pinType, routeName, viewApplication } from 'shared/constants.json';
import { textByLang } from 'shared/lib/language';

export default {
  name: 'SpaceTreeItem',
  props: {
    space: {
      type: Object,
      required: true,
    },
    property: {
      type: Object,
      required: true,
    },
    allSpaces: {
      type: Array,
      required: true,
    },
    indentation: {
      type: Number,
      required: true,
    },
    language: {
      type: String,
      required: true,
    },
    pins: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const { getSelectedViewViewIdForApplication } = useSelectedViews();
    return { getSelectedViewViewIdForApplication };
  },
  components: { SpaceItem },
  data() {
    return {
      textByLang,
      expanded: true,
      loading: false,
    };
  },
  computed: {
    optionLink() {
      return {
        name: routeName.spaceDetails,
        params: { optionId: this.space.uid },
        query: { view: GOAL_VIEW, viewId: this.getSelectedViewViewIdForApplication({ application: viewApplication.goalSpace, space: this.space }).value },
      };
    },
    pin() {
      const f = this.pins.filter((p) => {
        if (p.type === pinType.space && p.space.uid === this.space.uid) {
          return true;
        }
        return p.type === pinType.company && this.space.type === pinType.company;
      });
      if (f.length === 0) {
        return null;
      }

      return f[0];
    },
    removable() {
      if (this.pin === null) {
        return true;
      }

      return this.pin.removable;
    },
    isPinned() {
      return this.pin !== null;
    },
  },
  methods: {
    ...mapActions(['createEntityV2']),
    findItem(item) {
      return findInArray({ haystack: this.allSpaces, needle: item.uid });
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
</style>
