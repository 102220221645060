<template>
  <m-context-menu
    ref="contextMenu"
    class="collection-item-context-menu"
  >
    <m-card
      list
      no-padding
    >
      <m-card-item
        icon="delete"
        :loading="deleteLoading"
        @click="deleteM"
      >
        {{ $t('general.delete') }}
      </m-card-item>
    </m-card>
  </m-context-menu>
</template>

<script>
import { collectionItem as collectionItemConfig } from 'shared/api/query/configs.json';
import { mapActions } from 'vuex';

export default {
  name: 'CollectionItemContextMenu',
  props: {
    collectionItems: {
      type: Array,
      required: true,
    },
    collectionId: {
      type: Number,
      required: true,
    },
  },
  emits: ['deleted'],
  data() {
    return { deleteLoading: false };
  },
  methods: {
    ...mapActions(['deleteEntities']),
    hideMenu() {
      this.$refs.contextMenu.hide();
    },
    show(event) {
      this.$refs.contextMenu.show(event);
    },
    onDelete() {
      this.$emit('deleted');
      this.$refs.contextMenu.hide();
    },
    onDuplicate() {
      this.$refs.contextMenu.hide();
    },
    deleteM() {
      this.deleteLoading = true;
      this.deleteEntities({
        entities: this.collectionItems.map((uid) => ({ uid })),
        model: collectionItemConfig.model,
        mutation: 'COLLECTION_ITEMS_DELETED',
        onDelete: () => {
          this.hideMenu();
          this.$emit('deleted');
        },
        options: { collectionId: this.collectionId },
      }).then((response) => {
        this.deleteLoading = false;
        if (response.status !== 200) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
        }
      });
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">

</style>
