<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.05961 15.171C5.05961 16.567 3.93316 17.6938 2.53945 17.6938C1.14574 17.6938 0.0192871 16.567 0.0192871 15.171C0.0192871 13.7762 1.14574 12.6494 2.53945 12.6494H5.05961V15.171ZM6.31976 15.171C6.31976 13.7762 7.44621 12.6494 8.83992 12.6494C10.2336 12.6494 11.3601 13.7762 11.3601 15.171V21.4768C11.3601 22.8716 10.2336 23.9996 8.83992 23.9996C7.44621 23.9996 6.31976 22.8716 6.31976 21.4768V15.171Z"
      fill="#E01E5A"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.83965 5.04438C7.44593 5.04438 6.31948 3.91644 6.31948 2.5216C6.31948 1.12676 7.44593 0 8.83965 0C10.2334 0 11.3598 1.12676 11.3598 2.5216V5.04438H8.83965ZM8.83965 6.32433C10.2334 6.32433 11.3598 7.45112 11.3598 8.84595C11.3598 10.2408 10.2334 11.3687 8.83965 11.3687H2.52016C1.12645 11.3687 0 10.2408 0 8.84595C0 7.45112 1.12645 6.32433 2.52016 6.32433H8.83965Z"
      fill="#36C5F0"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.9393 8.84595C18.9393 7.45112 20.0657 6.32433 21.4594 6.32433C22.8533 6.32433 23.9796 7.45112 23.9796 8.84595C23.9796 10.2408 22.8533 11.3687 21.4594 11.3687H18.9393V8.84595ZM17.6792 8.84595C17.6792 10.2408 16.5528 11.3687 15.1591 11.3687C13.7654 11.3687 12.6389 10.2408 12.6389 8.84595V2.5216C12.6389 1.12676 13.7654 0 15.1591 0C16.5528 0 17.6792 1.12676 17.6792 2.5216V8.84595Z"
      fill="#2EB67D"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.1591 18.9552C16.5528 18.9552 17.6792 20.082 17.6792 21.4768C17.6792 22.8716 16.5528 23.9996 15.1591 23.9996C13.7654 23.9996 12.6389 22.8716 12.6389 21.4768V18.9552H15.1591ZM15.1591 17.6938C13.7654 17.6938 12.6389 16.567 12.6389 15.171C12.6389 13.7762 13.7654 12.6494 15.1591 12.6494H21.4786C22.8723 12.6494 23.9987 13.7762 23.9987 15.171C23.9987 16.567 22.8723 17.6938 21.4786 17.6938H15.1591Z"
      fill="#ECB22E"
    />
  </svg>
</template>

<script>
export default { name: 'Slack' };
</script>

<style scoped lang="scss" type="text/scss">

</style>
