<template>
  <svg
    height="1em"
    preserveAspectRatio="xMidYMid"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 256 221.4"
  >
    <linearGradient
      id="a"
      x1="0%"
      y1="0%"
      y2="100%"
    >
      <stop
        offset="0"
        stop-color="#f6c338"
      />
      <stop
        offset=".523"
        stop-color="#ffd351"
      />
      <stop
        offset="1"
        stop-color="#f6c338"
      />
    </linearGradient>
    <linearGradient
      id="b"
      x1="100%"
      x2="0%"
      y1="0%"
      y2="100%"
    >
      <stop
        offset="0"
        stop-color="#286ee6"
      />
      <stop
        offset=".521"
        stop-color="#4286fb"
      />
      <stop
        offset="1"
        stop-color="#286ee6"
      />
    </linearGradient>
    <linearGradient
      id="c"
      x1="65.289%"
      x2="35.995%"
      y1="0%"
      y2="100%"
    >
      <stop
        offset="0"
        stop-color="#069b5a"
      />
      <stop
        offset=".531"
        stop-color="#11aa62"
      />
      <stop
        offset="1"
        stop-color="#069b5a"
      />
    </linearGradient>
    <path
      d="M83.3 0h89L256 144.3h-89.2z"
      fill="url(#a)"
    />
    <path
      d="M256 144.3l-44.6 77.1h-167l44.7-77.1z"
      fill="url(#b)"
    />
    <path
      d="M44.4 221.4L0 144.3 83.3 0 128 77.3z"
      fill="url(#c)"
    />
    <path
      d="M44.4 221.4l83.1-77.1H89.1zM256 144.3h-89.1l-19.6-33.8zM83.3 0L109 110l19-32.7z"
      opacity=".1"
    />
  </svg>
</template>

<script>
export default { name: 'GoogleDrive' };
</script>

<style scoped lang="scss" type="text/scss">

</style>
