<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.166 22L7.40288 17.5L16.9292 17.5L12.166 22Z"
      fill="currentColor"
    />
    <path
      d="M21.737 2.95093H2.26465V14.8037H21.737V2.95093Z"
      stroke="currentColor"
      stroke-linejoin="round"
    />
    <rect
      x="4.38184"
      y="4.3363"
      width="1.38539"
      height="1.38539"
      fill="currentColor"
    />
    <rect
      x="4.38086"
      y="8.03067"
      width="1.38539"
      height="1.38539"
      fill="currentColor"
    />
    <rect
      x="4.38086"
      y="11.725"
      width="1.38539"
      height="1.38539"
      fill="currentColor"
    />
    <rect
      x="7.15137"
      y="4.3363"
      width="1.38539"
      height="1.38539"
      fill="currentColor"
    />
    <rect
      x="7.15137"
      y="8.03067"
      width="1.38539"
      height="1.38539"
      fill="currentColor"
    />
    <rect
      x="7.15137"
      y="11.725"
      width="9.79006"
      height="1.38539"
      fill="currentColor"
    />
    <rect
      x="9.92285"
      y="4.3363"
      width="1.38539"
      height="1.38539"
      fill="currentColor"
    />
    <rect
      x="9.92285"
      y="8.03067"
      width="1.38539"
      height="1.38539"
      fill="currentColor"
    />
    <rect
      x="12.6934"
      y="4.3363"
      width="1.38539"
      height="1.38539"
      fill="currentColor"
    />
    <rect
      x="12.6934"
      y="8.03067"
      width="1.38539"
      height="1.38539"
      fill="currentColor"
    />
    <rect
      x="15.4639"
      y="4.3363"
      width="1.38539"
      height="1.38539"
      fill="currentColor"
    />
    <rect
      x="15.4639"
      y="8.03067"
      width="1.38539"
      height="1.38539"
      fill="currentColor"
    />
    <rect
      x="18.2344"
      y="4.3363"
      width="1.38539"
      height="1.38539"
      fill="currentColor"
    />
    <rect
      x="18.2344"
      y="8.03067"
      width="1.38539"
      height="1.38539"
      fill="currentColor"
    />
    <rect
      x="18.2344"
      y="11.725"
      width="1.38539"
      height="1.38539"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default { name: 'KeyboardDown' };
</script>

<style scoped lang="scss" type="text/scss">

</style>
