<template>
  <div class="form-wrapper">
    <create-form-template-top-bar
      v-if="formTemplateAvailable"
      :languages="languages"
      :form-template="formTemplate"
      :form-template-id="formTemplateId"
      :lang="lang"
      @language-changed="changeLanguage"
    />
    <full-screen-spinner v-if="!formTemplateAvailable" />
    <template v-else>
      <router-view />
    </template>
  </div>
</template>

<script>
import CreateFormTemplateTopBar from '@/components/CreateFormTemplateTopBar.vue';
import FullScreenSpinner from 'shared/components/FullScreenSpinner.vue';
import useFormTemplate from '@/composables/form/form-template';
import { mapActions } from 'vuex';

export default {
  name: 'FormTemplateWrapper',
  components: {
    CreateFormTemplateTopBar,
    FullScreenSpinner,
  },
  setup() {
    const { formTemplate, formTemplateAvailable, languages, formTemplateId, changeLanguage, lang } = useFormTemplate();
    return { formTemplate, formTemplateAvailable, languages, formTemplateId, changeLanguage, lang };
  },
  methods: {
    ...mapActions([
      'getFormTemplate',
    ]),
    retrieveFormTemplate() {
      if (this.formTemplateAvailable) {
        return;
      }
      this.getFormTemplate({ formTemplateId: this.formTemplateId }).then((response) => {
        if (response.status !== 200) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
        }
      });
    },
  },
  mounted() {
    this.retrieveFormTemplate();
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  .create-form {
    ._content {
      display: flex;
    }
  }
</style>
