<template>
  <div class="data-source-summary">
    <m-content
      v-if="error !== null"
      :padding-top="0"
      :padding-x="8"
      :padding-bottom="2"
    >
      <m-alert
        class="_error-message"
        type="warning"
        :message="error"
      />
    </m-content>
    <m-content
      padding-xs
      :padding-bottom="0"
    >
      <google-sheets-summary
        v-if="[dataSourceType.sheets, dataSourceType.excel].includes(goalDataSourceType)"
        :spreadsheet-cell="query"
      />
      <jira-summary
        v-if="goalDataSourceType === dataSourceType.jira"
        :jira-query="query"
      />
      <hubspot-summary
        v-if="goalDataSourceType === dataSourceType.hubspot"
        :hubspot-query="query"
      />
      <asana-summary
        v-if="goalDataSourceType === dataSourceType.asana"
        :asana-query="query"
      />
      <salesforce-summary
        v-if="goalDataSourceType === dataSourceType.salesforce"
        :salesforce-query="query"
      />
      <div
        v-if="dataSource !== null"
        class="_connection"
      >
        {{ $t('dataSourceSummary.connectedBy') }} {{ dataSource.name }}
      </div>
      <div
        v-else
        class="_connection"
      >
        {{ $t('dataSourceSummary.connectedBy') }}: {{ $t('dataSourceSummary.private') }}
      </div>
    </m-content>
    <m-divider
      v-if="dataSource !== null"
      xxs
    />
    <m-card-item
      v-if="dataSource !== null"
      icon="sync"
      :loading="syncLoading"
      :disabled="error !== null"
      @click="syncNow"
    >
      {{ $t('dataSource.syncNow') }}
    </m-card-item>
  </div>
</template>

<script>
import AsanaSummary from '@/components/datasource/AsanaSummary.vue';
import GoogleSheetsSummary from '@/components/datasource/GoogleSheetsSummary.vue';
import HubspotSummary from '@/components/datasource/HubspotSummary.vue';
import JiraSummary from '@/components/datasource/JiraSummary.vue';
import SalesforceSummary from '@/components/datasource/SalesforceSummary.vue';
import useAsana from '@/composables/integrations/asana/asana';
import useGoalDatasource from '@/composables/goal/goal-datasource';
import useHubspot from '@/composables/integrations/hubspot/hubspot';
import useJira from '@/composables/integrations/jira/jira';
import useSalesforce from '@/composables/integrations/salesforce/salesforce';
import useSpreadsheet from '@/composables/integrations/spreadsheet/spreadsheet';
import { dataSourceError } from '@/lib/data-source';
import { dataSourceType, salesforceError } from 'shared/constants.json';
import { logCatch } from '@/lib/logger/logger';
import { toRef } from 'vue';

export default {
  name: 'DataSourceSummary',
  props: {
    goal: {
      type: Object,
      required: true,
    },
  },
  components: { AsanaSummary, GoogleSheetsSummary, JiraSummary, HubspotSummary, SalesforceSummary },
  setup(props) {
    const goal = toRef(props, 'goal');
    const { query, dataSource, type: goalDataSourceType } = useGoalDatasource(goal);
    const { updateQuery: updateJiraQuery } = useJira();
    const { updateQuery: updateSpreadsheetQuery } = useSpreadsheet();
    const { updateQuery: updateHubspotQuery } = useHubspot();
    const { updateQuery: updateAsanaQuery } = useAsana();
    const { updateQuery: updateSalesforceQuery } = useSalesforce();

    let updateQuery;
    switch (goalDataSourceType.value) {
      case dataSourceType.excel:
      case dataSourceType.sheets:
        updateQuery = updateSpreadsheetQuery;
        break;
      case dataSourceType.jira:
        updateQuery = updateJiraQuery;
        break;
      case dataSourceType.hubspot:
        updateQuery = updateHubspotQuery;
        break;
      case dataSourceType.asana:
        updateQuery = updateAsanaQuery;
        break;
      case dataSourceType.salesforce:
        updateQuery = updateSalesforceQuery;
        break;
      default:
        break;
    }
    return {
      query,
      dataSource,
      goalDataSourceType,
      updateQuery,
    };
  },
  data() {
    return {
      dataSourceType,
      syncLoading: false,
    };
  },
  computed: {
    error() {
      switch (this.goalDataSourceType) {
        case dataSourceType.salesforce: {
          switch (dataSourceError({ salesforceQuery: this.query })) {
            case salesforceError.unknown:
            case salesforceError.reportNotFound:
            case salesforceError.connectionError:
              return this.$t(`salesforce.error.${this.query.error}`);
            case null:
            default:
              return null;
          }
        }
        default:
          return null;
      }
    },
  },
  methods: {
    syncNow() {
      this.syncLoading = true;
      this.updateQuery(this.query).then(() => {
        this.$showSnackbar({ color: 'success', message: this.$t('dataSource.successfullySynced') });
      }).catch(logCatch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      })).finally(() => {
        this.syncLoading = false;
      });
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .data-source-summary {
    ._connection {
      margin-top: 1rem;
      font-size: $font-size-3;
      color: $font-color-secondary;
    }

    ._error-message {
      margin-top: 1.2rem;
      margin-bottom: .8rem;
    }
  }
</style>
