<template>
  <div
    ref="root"
    class="m-selectable"
  >
    <slot />
    <div
      v-if="!disabled && mouseDown"
      :style="selectionBoxStyling"
      class="_selection-box"
    />
  </div>
</template>

<script>
import useScroll from 'shared/composables/scroll';
import { computed, onMounted, ref } from 'vue';
import { copy } from 'shared/lib/copy';
import { debounce, uniq } from 'lodash-es';
import { upToContainerWithClass } from 'shared/lib/dom';

export default {
  name: 'MSelectable',
  props: {
    value: {
      type: Array,
      required: true,
    },
    selectorClass: {
      type: String,
      required: true,
    },
    dataKey: {
      type: String,
      default: 'id',
    },
    scrollContainerClass: {
      type: String,
      default: 'scroll-container',
    },
    selectAreaClass: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    scrollHandler: {
      type: Function,
      default: null,
    },
  },
  emits: ['input', 'update:value'],
  data() {
    return {
      mouseDown: false,
      concat: false,
      startPoint: null,
      endPoint: null,
      selectedItems: [],
      // TODO: replace me with debounce composable
      debounced: null,
    };
  },
  setup(props) {
    const initialScroll = ref({ x: 0, y: 0 });
    const scroll = ref({ x: 0, y: 0 });
    const root = ref(null);
    const scrollArea = ref(document.body);
    const scrollAreaDimensions = computed(() => scrollArea.value.getBoundingClientRect());

    const setScrollArea = () => {
      if (props.scrollContainerClass === '') {
        return;
      }

      scrollArea.value = upToContainerWithClass(root.value, props.scrollContainerClass);
    };
    onMounted(() => {
      setScrollArea();
    });

    const doScroll = ({ deltaX, deltaY }) => {
      if (props.scrollHandler !== null) {
        const { actualDeltaX, actualDeltaY } = props.scrollHandler({ deltaX, deltaY });
        scroll.value.x += actualDeltaX;
        scroll.value.y += actualDeltaY;

        return;
      }

      const scrollTopBefore = scrollArea.value.scrollTop;
      const scrollLeftBefore = scrollArea.value.scrollLeft;
      scrollArea.value.scrollTop += deltaY;
      scrollArea.value.scrollLeft += deltaX;
      scroll.value.y += scrollTopBefore - scrollArea.value.scrollTop;
      scroll.value.x += scrollLeftBefore - scrollArea.value.scrollLeft;
    };

    const scrollService = useScroll(doScroll);
    return {
      scrollService,
      initialScroll,
      scroll,
      scrollArea,
      scrollAreaDimensions,
      root,
    };
  },
  computed: {
    selectArea() {
      if (this.selectAreaClass === '') {
        return window;
      }

      return upToContainerWithClass(this.root, this.selectAreaClass);
    },
    selectionBox() {
      if (!this.mouseDown || !this.startPoint || !this.endPoint) {
        return {};
      }

      const startPointX = this.startPoint.x + (this.initialScroll.x - this.scroll.x);
      const startPointY = this.startPoint.y + (this.initialScroll.y - this.scroll.y);
      const getLeft = () => {
        if (startPointX > this.endPoint.x) {
          return this.endPoint.x;
        }

        return startPointX;
      };

      const getTop = () => {
        if (startPointY > this.endPoint.y) {
          return this.endPoint.y;
        }

        return startPointY;
      };

      const getWidth = () => Math.abs(startPointX - this.endPoint.x);

      const getHeight = () => Math.abs(startPointY - this.endPoint.y);

      return {
        left: getLeft(),
        top: getTop(),
        width: getWidth(),
        height: getHeight(),
      };
    },
    selectionBoxStyling() {
      if (!this.mouseDown || !this.startPoint || !this.endPoint) {
        return {};
      }

      return {
        ...Object.keys(this.selectionBox).reduce((acc, k) => {
          acc[k] = `${this.selectionBox[k]}px`;
          return acc;
        }, {}),
        position: 'fixed',
      };
    },
  },
  methods: {
    getScroll() {
      return {
        x: this.scrollArea.scrollLeft,
        y: this.scrollArea.scrollTop,
      };
    },
    handleScrolling() {
      const handleScroll = () => {
        this.scroll = this.getScroll();
        this.setSelectedItems();
      };

      if (this.debounced !== null) {
        this.debounced.cancel();
      }

      this.debounced = debounce(handleScroll, 5);
      this.debounced();
    },
    onMouseDown(event) {
      // Ignore right clicks and mouse clicks
      if (event.which !== 1 || this.disabled) {
        return;
      }

      // Check if shift is down
      this.concat = event.shiftKey;

      this.mouseDown = true;
      this.startPoint = {
        x: event.pageX,
        y: event.pageY,
      };

      const scroll = this.getScroll();
      this.initialScroll = { x: scroll.x, y: scroll.y };
      this.scroll = { x: scroll.x, y: scroll.y };

      this.scrollArea.addEventListener('scroll', this.handleScrolling);
      this.selectArea.addEventListener('mousemove', this.onMouseMove);
      this.selectArea.addEventListener('mouseup', this.onMouseUp);
    },
    onMouseMove(event) {
      if (!this.mouseDown || ((Math.abs(event.clientY - this.startPoint.y) < 2) && Math.abs(event.clientX - this.startPoint.x) < 2)) {
        return;
      }

      const distanceRight = (this.scrollAreaDimensions.x + this.scrollAreaDimensions.width) - event.clientX;
      const distanceBottom = (this.scrollAreaDimensions.y + this.scrollAreaDimensions.height) - event.clientY;
      const distanceLeft = event.clientX - this.scrollAreaDimensions.x;
      const distanceTop = event.clientY - this.scrollAreaDimensions.y;
      this.scrollService.handleScroll({ distanceTop, distanceRight, distanceBottom, distanceLeft });

      this.endPoint = {
        x: event.pageX,
        y: event.pageY,
      };

      this.setSelectedItems();
    },
    onMouseUp() {
      this.scrollService.stop();
      this.selectArea.removeEventListener('mousemove', this.onMouseMove);
      this.selectArea.removeEventListener('mouseup', this.onMouseUp);
      this.scrollArea.removeEventListener('scroll', this.handleScrolling);

      if (this.endPoint === null || (this.endPoint.x === this.startPoint.x && this.endPoint.y === this.startPoint.y)) {
        this.$emit('input', []);
        this.$emit('update:value', []);
      }

      this.mouseDown = false;
      this.concat = false;
      this.startPoint = null;
      this.endPoint = null;
    },
    findKey(element) {
      if (typeof element.dataset[this.dataKey] === 'undefined') {
        throw new Error('element doesnt have data key installed');
      }

      if (Number.isNaN(parseInt(element.dataset[this.dataKey], 10))) {
        return element.dataset[this.dataKey];
      }

      return parseInt(element.dataset[this.dataKey], 10);
    },
    setSelectedItems() {
      if (this.disabled) {
        this.selectedItems = [];
        return;
      }

      /* eslint-disable no-underscore-dangle */
      const items = Array.from(this.$el.querySelectorAll(`.${this.selectorClass}`))
        .filter((el) => this.isItemSelected(el))
        .map((el) => this.findKey(el));
      /* eslint-enable no-underscore-dangle */

      if (this.concat) {
        this.selectedItems = uniq([...this.selectedItems, ...items]);
        return;
      }
      this.selectedItems = items;
    },
    isItemSelected(el) {
      if (!el.classList.contains(this.selectorClass)) {
        return false;
      }

      if (this.selectionBox.left === undefined || this.selectionBox.top === undefined) {
        return false;
      }

      const boxB = el.getBoundingClientRect();

      const hasHorizontalOverlap = () => {
        if (this.selectionBox.left < boxB.left && this.selectionBox.left + this.selectionBox.width < boxB.left) {
          return false;
        }
        return !(this.selectionBox.left > boxB.left + boxB.width);
      };

      const hasVerticalOverlap = () => {
        if (this.selectionBox.top < boxB.top && this.selectionBox.top + this.selectionBox.height < boxB.top) {
          return false;
        }

        return !(this.selectionBox.top > boxB.top + boxB.height);
      };

      return hasHorizontalOverlap() && hasVerticalOverlap();
    },
  },
  watch: {
    value(val, old) {
      if (JSON.stringify(val) === JSON.stringify(old)) {
        return;
      }
      this.selectedItems = copy(val);
    },
    selectedItems(val, old) {
      if (JSON.stringify(val) === JSON.stringify(old)) {
        return;
      }
      this.$emit('input', val);
      this.$emit('update:value', val);
    },
  },
  mounted() {
    this.selectedItems = copy(this.value);
    this.selectArea.addEventListener('mousedown', this.onMouseDown);
  },
  beforeUnmount() {
    this.selectArea.removeEventListener('mousemove', this.onMouseMove);
    this.selectArea.removeEventListener('mouseup', this.onMouseUp);
    this.selectArea.removeEventListener('mousedown', this.onMouseDown);
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .m-selectable {
    position: relative;
    user-select: none;
  }

  ._selection-box {
    z-index: 1;
    background: $selection-color;
  }
</style>
