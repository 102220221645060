<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M20.5714 2.57143H3.42857C2.95519 2.57143 2.57143 2.95519 2.57143 3.42857V20.5714C2.57143 21.0447 2.95519 21.4286 3.42857 21.4286H20.5714C21.0447 21.4286 21.4286 21.0447 21.4286 20.5714V3.42857C21.4286 2.95519 21.0447 2.57143 20.5714 2.57143ZM3.42857 0H20.5714C22.465 0 24 1.53502 24 3.42857V20.5714C24 22.465 22.465 24 20.5714 24H3.42857C1.53502 24 0 22.465 0 20.5714V3.42857C0 1.53502 1.53502 0 3.42857 0Z"
        fill="currentColor"
      />
      <path
        d="M9.75457 5.84C9.47843 5.84 9.25457 6.06385 9.25457 6.34V9.44793C9.25457 9.72407 9.47843 9.94793 9.75457 9.94793H11.5951V11.6019H7.51367C7.29276 11.6019 7.11367 11.781 7.11367 12.0019V14.0559H5.28003C5.00389 14.0559 4.78003 14.2797 4.78003 14.5559V17.6638C4.78003 17.9399 5.00389 18.1638 5.28003 18.1638H9.75727C10.0334 18.1638 10.2573 17.9399 10.2573 17.6638V14.5559C10.2573 14.2797 10.0334 14.0559 9.75727 14.0559H7.91367V12.4019H16.0764V14.0559H14.2428C13.9666 14.0559 13.7428 14.2797 13.7428 14.5559V17.6638C13.7428 17.9399 13.9666 18.1638 14.2428 18.1638H18.72C18.9962 18.1638 19.22 17.9399 19.22 17.6638V14.5559C19.22 14.2797 18.9962 14.0559 18.72 14.0559H16.8764V12.0019C16.8764 11.781 16.6973 11.6019 16.4764 11.6019H12.3951V9.94793H14.2318C14.508 9.94793 14.7318 9.72407 14.7318 9.44793V6.34C14.7318 6.06385 14.508 5.84 14.2318 5.84H9.75457Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>

<script>
export default { name: 'TreeView' };
</script>

<style scoped lang="scss" type="text/scss">

</style>
