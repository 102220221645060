<template>
  <div class="ms-teams-install-hint">
    <p>{{ $t('profileSettings.microsoftHint') }}</p>
    <m-btn
      :href="$t('msTeamsInstallHint.link')"
      target="_blank"
      hide-border
      icon="question-circle"
    >
      {{ $t('msTeamsInstallHint.msTeamsGuide') }}
    </m-btn>
  </div>
</template>

<script>
export default { name: 'MsTeamsInstallHint' };
</script>

<style scoped lang="scss" type="text/scss">

</style>
