<template>
  <div class="icon-nav-item">
    <m-tooltip
      :disabled="!showTooltip"
      placement="right"
    >
      <m-link :to="to">
        <div class="_wrapper">
          <div :class="classes">
            <div
              class="_icon"
            >
              <m-icon
                :size="iconSize"
                :color="iconColor"
                :type="icon"
              />
            </div>
          </div>
        </div>
      </m-link>
      <template #title>
        {{ title }}
      </template>
    </m-tooltip>
  </div>
</template>

<script>
export default {
  name: 'IconNavItem',
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: '',
    },
    to: {
      type: [Object, String],
      default: '',
    },
    iconSize: {
      type: String,
      default: '20',
    },
    showTooltip: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return { visible: false };
  },
  computed: {
    iconColor() {
      if (this.active) {
        return this.$colors.grey.darken2;
      }
      return this.$colors.grey.base;
    },
    classes() {
      const classes = ['_inner'];
      if (this.active) {
        classes.push('-active');
      }
      return classes;
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .icon-nav-item {
    padding: .4rem .6rem;

    ._wrapper {
      border-radius: $border-radius-sm;

      &:hover {
        background-color: $hover-color;
      }
    }

    ._inner {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1rem 0;
      color: $font-color-primary;
      border-radius: $border-radius-sm;

      &.-active {
        background-color: $selected-color;
      }
    }
  }
</style>
