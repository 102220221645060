<template>
  <form-editor
    :entity-by-id="formById"
    :entity="form"
    :side-nav-items="sideNavItems"
    :model="model"
    :edit-page="CREATE_FORM"
    :settings-page="FORM_SETTINGS"
    :create-url="createUrl"
    :languages="languages"
    :lang="lang"
    :all-questions-url="allQuestionsUrl"
    :template-list-url="templateListUrl"
    :disabled="false"
    :question-bank-template-detail-url="questionBankTemplateDetailUrl"
  />
</template>

<script>
import FormEditor from '@/components/FormEditor.vue';
import useForm from '@/composables/form/form';
import {
  CREATE_FORM,
  FIELD_FACTORS,
  FORM_SETTINGS,
  QUESTION_BANK,
  QUESTION_BANK_TEMPLATE_DETAILS,
  QUESTION_BANK_TEMPLATE_LIST,
} from '@/route-names';
import { form } from 'shared/api/query/configs.json';
import { formById } from 'shared/api/query/form';
import { mapGetters } from 'vuex';

export default {
  name: 'CreateForm',
  setup() {
    const { form, lang } = useForm();
    return { form, lang };
  },
  components: { FormEditor },
  data() {
    return {
      formById,
      model: form.model,
      CREATE_FORM,
      FORM_SETTINGS,
    };
  },
  computed: {
    ...mapGetters(['form']),
    createUrl() {
      return { name: CREATE_FORM, params: { formId: this.form.uid }, query: { lang: this.lang } };
    },
    allQuestionsUrl() {
      return { name: QUESTION_BANK, params: { formId: this.form.uid }, query: { lang: this.lang } };
    },
    templateListUrl() {
      return { name: QUESTION_BANK_TEMPLATE_LIST, params: { formId: this.form.uid }, query: { lang: this.lang } };
    },
    sideNavItems() {
      return [
        {
          to: { name: CREATE_FORM, params: { formId: this.form.uid }, query: { lang: this.lang } },
          icon: 'edit',
          title: this.$t('formEditor.editForm'),
          active: this.$route.name === CREATE_FORM,
        },
        {
          to: { name: FORM_SETTINGS, params: { formId: this.form.uid }, query: { lang: this.lang } },
          icon: 'global',
          title: this.$t('formEditor.languageSettings'),
          active: this.$route.name === FORM_SETTINGS,
        },
        {
          to: { name: QUESTION_BANK, params: { formId: this.form.uid }, query: { lang: this.lang } },
          icon: 'read',
          title: this.$t('formEditor.questionBank'),
          active: [QUESTION_BANK, QUESTION_BANK_TEMPLATE_LIST, QUESTION_BANK_TEMPLATE_DETAILS].includes(this.$route.name),
        },
        {
          to: { name: FIELD_FACTORS, params: { formId: this.form.uid }, query: { lang: this.lang } },
          icon: 'cluster',
          title: this.$t('formEditor.fieldFactors'),
          active: [FIELD_FACTORS].includes(this.$route.name),
        },
      ];
    },
  },
  methods: {
    questionBankTemplateDetailUrl({ entityId, templateId }) {
      return { name: QUESTION_BANK_TEMPLATE_DETAILS, params: { templateId, formId: entityId }, query: { lang: this.lang } };
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
</style>
