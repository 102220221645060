<template>
  <div class="feedback-inquiry">
    <m-content
      :padding="!$store.state.breakpoint.smAndDown"
      :no-padding="$store.state.breakpoint.smAndDown"
      :padding-x="!$store.state.breakpoint.smAndDown ? '13' : ''"
    >
      <div class="_header">
        <h1 v-if="!$store.state.breakpoint.smAndDown">
          {{ $t('feedbackInquiry.title') }}
        </h1>
        <top-bar v-else>
          <template #left>
            <div

              class="_left"
            >
              <m-btn
                hide-border
                small
                color="primary"
                @click="$emit('cancel')"
              >
                {{ $t('general.cancel') }}
              </m-btn>
            </div>
          </template>
          <template #center>
            <div

              class="_center"
            >
              {{ $t('feedbackInquiry.title') }}
            </div>
          </template>
          <template #right>
            <div

              class="_right"
            >
              <m-btn
                hide-border
                small
                color="primary"
                @click="save"
              >
                {{ $t('general.send') }}
              </m-btn>
            </div>
          </template>
        </top-bar>
      </div>
    </m-content>
    <m-content
      :padding="!$store.state.breakpoint.smAndDown"
      :padding-small="$store.state.breakpoint.smAndDown"
      :padding-x="!$store.state.breakpoint.smAndDown ? '13' : ''"
    >
      <div class="_props">
        <property-label
          :label="$t('feedbackInquiry.recipients')"
          icon="user"
          class="_prop"
        >
          <user-picker
            v-model:value="respondents"
            full-width
            hide-border
            hide-arrow
            match-trigger-width
            popup
            :has-error="submitted && respondents.length === 0"
            :excluded-users="[loggedInUser]"
            :placeholder="$t('general.empty')"
          />
        </property-label>
      </div>
      <m-divider :small="$store.state.breakpoint.smAndDown" />
      <div class="_body">
        <div class="_label">
          {{ $t('feedbackInquiry.messageLabel') }}
        </div>
        <div :class="['_input', (message === null && questions.length === 0) && submitted ? '-has-error' : '']">
          <m-editor
            ref="editor"
            v-model:value="message"
            :placeholder="$t('feedbackInquiry.typeYourMessage')"
            :allowed-content="allowedContent"
            @bottom-reached="moveTo(0)"
          />
        </div>
        <div class="_questions">
          <div
            v-if="questions.length > 0"
            class="_label"
          >
            {{ $t('feedbackInquiry.questionsLabel') }}
          </div>
          <m-draggable
            draggable-item-class="_handle"
            ghost-item-class="feedback-question-item"
            dragover-item-class="feedback-question-item"
            :recreate-key="questions.length"
            can-drag-over-top
            can-drag-over-bottom
            :drag-between-height="16"
            @set-drag-item="setDragItem"
            @over-top="setOverTop"
            @over-bottom="setOverBottom"
            @drag-drop="handleDrop"
            @cancel="cancelDragging"
          >
            <feedback-question-item
              v-for="(question, index) in questions"
              :key="question.tid"
              :ref="`item_${index}`"
              :data-id="question.tid"
              :item="question"
              :focused="focusedItem === index"
              :index="index + 1"
              :dragging="dragging"
              :lang="userLang"
              :dragging-over-bottom="dragItemId !== '' && draggingOverBottom.includes(question.tid)"
              :dragging-over-top="index === 0 && dragItemId !== '' && draggingOverTop.includes(question.tid)"
              @update="updateQuestion($event, index)"
              @enter="addEmptyQuestion(index + 1)"
              @up="moveUp"
              @down="moveDown"
              @delete="removeItem(index)"
            />
          </m-draggable>
          <m-dropdown
            v-model:value="addQuestionsDropdown"
            :title="$t('general.actions')"
          >
            <m-btn
              icon="plus"
              hide-border
              light
              small
              class="_add-btn"
            >
              {{ $t('feedbackInquiry.addQuestions') }}
            </m-btn>
            <template #overlay>
              <m-card

                class="_overlay"
                list
                no-padding
              >
                <m-card-item
                  icon="file"
                  @click="addEmptyQuestion(questions.length)"
                >
                  {{ $t('feedbackInquiry.emptyQuestion') }}
                </m-card-item>
                <m-card-item
                  icon="file-text"
                  @click="showTemplates = true"
                >
                  {{ $t('feedbackInquiry.questionFromTemplate') }}
                </m-card-item>
                <m-dialog
                  v-model:value="showTemplates"
                  hide-header
                  hide-footer
                  no-padding
                  max-width="90rem"
                >
                  <instant-feedback-template-gallery
                    @select-questions="addTemplateQuestions"
                    @close="showTemplates = false"
                  />
                </m-dialog>
              </m-card>
            </template>
          </m-dropdown>
        </div>
      </div>
    </m-content>
    <m-divider
      v-if="!$store.state.breakpoint.smAndDown"
      none
    />
    <m-content
      v-if="!$store.state.breakpoint.smAndDown"
      padding
    >
      <div class="_footer">
        <div class="_actions">
          <m-btn
            hide-border
            light
            @click="$emit('cancel')"
          >
            {{ $t('general.cancel') }}
          </m-btn>
          <m-btn
            color="primary"
            class="_btn"
            :loading="loading"
            @click="save"
          >
            {{ $t('general.send') }}
          </m-btn>
        </div>
      </div>
    </m-content>
  </div>
</template>

<script>
import FeedbackQuestionItem from '@/components/feedback/FeedbackQuestionItem.vue';
import InstantFeedbackTemplateGallery from '@/components/feedback/InstantFeedbackTemplateGallery.vue';
import MEditor from '@/components/editor/MEditor.vue';
import PropertyLabel from '@/components/PropertyLabel.vue';
import TopBar from '@/components/navigation/TopBar.vue';
import UserPicker from '@/components/UserPicker.vue';
import useAccess from '@/composables/access/access';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useSort from '@/composables/draggable/sort';
import { UID } from 'shared/api/query/constants';
import { dogma } from '@/api';
import { editorNodeType, featureFlag, feedbackAnswerStatus, fieldType } from 'shared/constants.json';
import { feedbackInquiry as feedbackInquiryConfig } from 'shared/api/query/configs.json';

export default {
  name: 'FeedbackInquiry',
  components: {
    MEditor,
    PropertyLabel,
    UserPicker,
    FeedbackQuestionItem,
    InstantFeedbackTemplateGallery,
    TopBar,
  },
  emits: ['cancel', 'close'],
  setup() {
    const allowedContent = [
      editorNodeType.heading,
      editorNodeType.codeBlock,
      editorNodeType.blockquote,
      editorNodeType.bulletList,
      editorNodeType.orderedList,
    ];

    const { accountHasFeature } = useAccess();
    if (accountHasFeature([featureFlag.fileUpload])) {
      allowedContent.push(editorNodeType.image, editorNodeType.file);
    }

    const {
      setDragItem,
      setOverBottom,
      setOverTop,
      draggingOverBottom,
      draggingOverTop,
      dropItem,
      cancelDragging,
      dragItemId,
    } = useSort('tid');
    const { loggedInUser, userLang } = useLoggedInUser();
    return {
      dragItemId,
      setDragItem,
      setOverBottom,
      setOverTop,
      draggingOverBottom,
      draggingOverTop,
      dropItem,
      cancelDragging,
      loggedInUser,
      userLang,
      allowedContent,
    };
  },
  data() {
    return {
      showTemplates: false,
      dragging: false,
      submitted: false,
      focusedItem: -1,
      addQuestionsDropdown: false,
      questions: [],
      respondents: [],
      message: null,
      loading: false,
    };
  },
  methods: {
    handleDrop() {
      this.questions = this.dropItem(this.questions);
    },
    save() {
      this.submitted = true;

      if (this.respondents.length === 0 || (this.message === null && this.questions.length === 0)) {
        return;
      }

      this.loading = true;

      dogma.createSingle(
        {
          uid: 0,
          creator: { uid: this.loggedInUser.uid },
          message: this.message,
          questions: this.questions,
          answers: this.respondents.map((u) => ({
            respondent: { uid: u.uid },
            status: feedbackAnswerStatus.open,
          })),
        },
        feedbackInquiryConfig.model,
        [
          { attr: UID },
          { attr: feedbackInquiryConfig.edges.message },
        ],
      ).then((response) => {
        this.loading = false;
        if (response.status !== 201) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
          return;
        }

        this.$showSnackbar({ color: 'success', message: this.$t('feedbackInquiry.created') });
        this.$emit('close');
      });
    },
    removeItem(index) {
      this.questions.splice(index, 1);
      if (this.focusedItem >= this.questions.length) {
        this.moveTo(this.questions.length - 1);
      }
    },
    moveDown() {
      if (this.focusedItem === this.questions.length - 1) {
        this.focusedItem = this.questions.length - 1;
        return;
      }
      this.moveTo(this.focusedItem + 1);
    },
    moveUp() {
      if (this.focusedItem === 0) {
        this.$refs.editor.focus();
        this.focusedItem = -1;
        return;
      }
      this.moveTo(this.focusedItem - 1);
    },
    moveTo(index) {
      this.focusedItem = index;
    },
    updateQuestion({ item }, index) {
      this.questions.splice(index, 1, item);
    },
    addTemplateQuestions(questions) {
      this.questions.push(...questions.map((q, index) => ({ ...q, tid: index + this.questions.length })));
      this.showTemplates = false;
      this.addQuestionsDropdown = false;
    },
    addEmptyQuestion(index) {
      this.questions.splice(index, 0, {
        title: { [this.userLang]: '' },
        type: fieldType.longText,
        tid: index,
      });
      this.addQuestionsDropdown = false;

      if (this.questions.length === 1) {
        this.focusedItem = 0;
        return;
      }
      this.focusedItem = index;
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .feedback-inquiry {
    ._header {
      ._center {
        max-width: 12rem;
        overflow: hidden;
        font-weight: $font-weight-semibold;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      ._right {
        margin-right: 1.2rem;
      }

      ._left {
        margin-left: 1.2rem;
      }
    }

    ._body {
      ._label {
        color: $font-color-tertiary;
      }

      ._input {
        border-radius: $border-radius-sm;

        &.-has-error {
          background-color: map_get($red, 'lighten-5');
          border: 1px solid map_get($red, 'base');
        }
      }

      ._questions {
        margin-top: 2rem;

        ._add-btn {
          margin-top: .4rem;
        }
      }
    }

    ._footer {
      display: flex;

      ._actions {
        display: flex;
        margin-left: auto;

        ._btn {
          margin-left: .8rem;
        }
      }
    }
  }
</style>
