import useGoalProperty from '@/composables/property/goal-property';
import { computed } from 'vue';
import { goal as goalConfig } from 'shared/api/query/configs.json';
import { shallowCopy } from 'shared/lib/copy';
import { sortByArray } from 'shared/lib/sort';
import { viewType } from 'shared/constants.json';

export default function useViewParamsProps(view) {
  const { statusProperty } = useGoalProperty();

  const isRenderedInCard = computed(() => [viewType.tree, viewType.atlas].includes(view.value.viewType));

  const props = computed(() => view.value.params.props);

  const propOrder = computed(() => {
    let propOrder = [];
    if (view.value.params.propOrder !== undefined) {
      propOrder = shallowCopy(view.value.params.propOrder);
    }
    if (isRenderedInCard.value) {
      if (props.value.map((p) => p.key).includes(statusProperty.uid) && !propOrder.map((p) => p.key).includes(statusProperty.uid)) {
        propOrder.unshift({ key: statusProperty.uid });
      }
      if (props.value.map((p) => p.key).includes(goalConfig.edges.cachedCalculatedCurrent) && !propOrder.map((p) => p.key).includes(goalConfig.edges.cachedCalculatedCurrent)) {
        propOrder.unshift({ key: goalConfig.edges.cachedCalculatedCurrent });
      }
    }
    if (props.value.map((p) => p.key).includes(goalConfig.edges.title) && !propOrder.map((p) => p.key).includes(goalConfig.edges.title)) {
      propOrder.unshift({ key: goalConfig.edges.title });
    }
    return propOrder;
  });

  const sortedProps = computed(() => shallowCopy(props.value).sort(sortByArray(propOrder.value, 'key')));

  return { props: sortedProps };
}
