<template>
  <m-content>
    <div class="_content">
      <slot />
    </div>
  </m-content>
</template>

<script>
export default { name: 'PlainLayout' };
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  ._content {
    background-color: white;
  }
</style>
