<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 48 48"
    fill="none"
  >
    <defs>
      <linearGradient
        id="a"
        x1="91.867%"
        x2="28.264%"
        y1="40.328%"
        y2="81.66%"
      >
        <stop
          offset="18%"
          stop-color="#0052CC"
        />
        <stop
          offset="100%"
          stop-color="#2684FF"
        />
      </linearGradient>
      <linearGradient
        id="b"
        x1="8.71%"
        x2="72.243%"
        y1="59.166%"
        y2="17.99%"
      >
        <stop
          offset="18%"
          stop-color="#0052CC"
        />
        <stop
          offset="100%"
          stop-color="#2684FF"
        />
      </linearGradient>
    </defs>
    <g
      fill="none"
      fill-rule="nonzero"
    >
      <path
        fill="#2684FF"
        d="M46.962 22.585L25.816 1.995 23.766 0 7.85 15.499.57 22.585a1.86 1.86 0 0 0 0 2.678l14.543 14.16 8.652 8.425L39.684 32.35l.247-.24 7.031-6.846a1.86 1.86 0 0 0 0-2.678zm-23.196 8.413l-7.264-7.074 7.264-7.073 7.265 7.073-7.265 7.074z"
      />
      <path
        fill="url(#a)"
        d="M23.766 16.85A11.69 11.69 0 0 1 23.715.058L7.816 15.53l8.653 8.425 7.297-7.105z"
      />
      <path
        fill="url(#b)"
        d="M31.05 23.905l-7.284 7.093c2.296 2.234 3.586 5.265 3.586 8.425 0 3.16-1.29 6.191-3.586 8.425l15.938-15.517-8.653-8.426z"
      />
    </g>
  </svg>
</template>

<script>
export default { name: 'Jira' };
</script>

<style scoped lang="scss" type="text/scss">

</style>
