import { ONE_TO_ONE, UID } from 'shared/api/query/constants';
import { goal as goalConfig, goalSubscription as goalSubscriptionConfig, user as userConfig } from 'shared/api/query/configs.json';
import { reverseEdge } from 'shared/api/query/edges';

export const goalSubscriptionChildren = [
  { attr: UID },
  { attr: goalSubscriptionConfig.edges.active },
  { attr: goalSubscriptionConfig.edges.user, model: userConfig.model, children: [{ attr: UID }] },
  { attr: reverseEdge(goalConfig.edges.subscriptions), alias: 'goal', model: goalConfig.model, assoc: ONE_TO_ONE, children: [{ attr: UID }] },
];
