<template>
  <div :class="['instant-feedback-template-gallery']">
    <full-screen-spinner
      v-if="loading"
      :height="600"
    />
    <template v-else-if="!$store.state.breakpoint.smAndDown">
      <div class="_left">
        <m-content
          v-if="selectedTemplate !== null"
          :padding-x="15"
          :padding-y="11"
        >
          <template-details
            :template="selectedTemplate"
            :selected-question-ids="selectedQuestionIds"
            @select="handleSelectQuestion"
          />
        </m-content>
      </div>
      <div class="_right">
        <m-content
          padding
          class="_top"
        >
          <m-btn
            color="primary"
            lg
            block
            @click="selectQuestions"
          >
            {{ $t('instantFeedbackTemplateGallery.selected') }} ({{ selectedQuestions.length }})
          </m-btn>
        </m-content>
        <m-divider
          none
          class="_divider"
        />
        <div
          v-for="(group, key) in templatesGroupedByTags"
          :key="key"
          class="_group"
        >
          <template-group-item
            :item="group"
            :title="key"
            :selected-template-id="selectedTemplateId"
            @select="selectTemplate"
          />
        </div>
      </div>
    </template>
    <div
      v-else
      :class="['_mobile', templateSelected ? '-details' : '']"
    >
      <top-bar :style="{ width: '100%' }">
        <template #left>
          <m-btn

            hide-border
            color="primary"
            @click="handleBack"
          >
            {{ $t('general.back') }}
          </m-btn>
        </template>
        <template #center>
          <div

            class="_title"
          >
            <template v-if="!templateSelected">
              {{ $t('instantFeedbackTemplateGallery.template') }}
            </template>
            <template v-else>
              {{ selectedTemplate.icon }} {{ selectedTemplate.title }}
            </template>
          </div>
        </template>
        <template #right>
          <m-btn

            hide-border
            color="primary"
            @click="selectQuestions"
          >
            {{ $t('instantFeedbackTemplateGallery.selected') }} ({{ selectedQuestions.length }})
          </m-btn>
        </template>
      </top-bar>
      <div
        v-if="!templateSelected"
        class="_groups"
      >
        <div
          v-for="(group, key) in templatesGroupedByTags"
          :key="key"
          class="_group"
        >
          <template-group-item
            :item="group"
            :title="key"
            mobile-version
            :selected-template-id="selectedTemplateId"
            @select="selectTemplate"
          />
        </div>
      </div>
      <m-content
        v-else
        padding
      >
        <template-details
          :template="selectedTemplate"
          :selected-question-ids="selectedQuestionIds"
          class="_details"
          @select="handleSelectQuestion"
        />
      </m-content>
    </div>
  </div>
</template>

<script>
import FullScreenSpinner from 'shared/components/FullScreenSpinner.vue';
import TemplateDetails from '@/components/feedback/TemplateDetails.vue';
import TemplateGroupItem from '@/components/feedback/TemplateGroupItem.vue';
import TopBar from '@/components/navigation/TopBar.vue';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import { findInArray } from 'shared/lib/array/array';
import { formTemplateType } from 'shared/constants.json';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'InstantFeedbackTemplateGallery',
  components: { TopBar, TemplateGroupItem, TemplateDetails, FullScreenSpinner },
  setup() {
    const { loggedInUserAccount } = useLoggedInUserAccount();
    return { account: loggedInUserAccount };
  },
  data() {
    return {
      selectedQuestions: [],
      loading: false,
      type: formTemplateType.instantFeedback,
      selectedTemplateId: 0,
      templateSelected: false,
    };
  },
  emits: ['close', 'select-questions'],
  computed: {
    ...mapState({ templates: (state) => state.formTemplates }),
    selectedQuestionIds() {
      return this.selectedQuestions.map((q) => q.uid);
    },
    templatesGroupedByTags() {
      return this.templates.reduce((res, current) => {
        current.tags.forEach((t) => {
          if (typeof res[t.name] === 'undefined') {
            res[t.name] = [current];
            return;
          }

          res[t.name].push(current);
        });

        if (current.tags.length === 0) {
          res[this.$t('instantFeedbackTemplateGallery.uncategorized')].push(current);
        }

        return res;
      }, { [this.$t('instantFeedbackTemplateGallery.uncategorized')]: [] });
    },
    selectedTemplate() {
      return findInArray({ haystack: this.templates, needle: this.selectedTemplateId });
    },
  },
  methods: {
    ...mapActions([
      'getFormTemplatesByAccount',
    ]),
    handleBack() {
      if (!this.templateSelected) {
        this.$emit('close');
      }

      this.templateSelected = false;
    },
    selectQuestions() {
      this.$emit('select-questions', this.selectedQuestions.map((o) => o.field));
      this.selectedQuestions = [];
    },
    handleSelectQuestion(question) {
      if (this.selectedQuestions.filter((q) => q.uid === question.uid).length > 0) {
        this.selectedQuestions = this.selectedQuestions.filter((q) => q.uid !== question.uid);
        return;
      }

      this.selectedQuestions.push(question);
    },
    selectTemplate(id) {
      this.templateSelected = true;
      this.selectedTemplateId = id;
    },
    retrieveFormTemplateList() {
      this.loading = true;
      this.getFormTemplatesByAccount({
        accountId: this.account.uid,
        type: this.type,
        releasedOnly: true,
      }).then((response) => {
        this.loading = false;
        if (response.status !== 200) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
        }

        this.selectedTemplateId = this.templatesGroupedByTags[Object.keys(this.templatesGroupedByTags)[0]][0].uid;
      });
    },
  },
  created() {
    this.retrieveFormTemplateList();
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .instant-feedback-template-gallery {
    display: flex;
    min-height: 60vh;

    ._mobile {
      width: 100%;
      min-height: 100vh;
      background-color: $side-nav-background-color;

      ._title {
        max-width: 12rem;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &.-details {
        background-color: white;
      }

      ._group {
        margin-top: 3rem;
      }
    }

    ._left {
      flex: 1 1 auto;
      max-height: 60vh;
      overflow: auto;
    }

    ._right {
      flex: 0 0 25rem;
      max-height: 60vh;
      overflow: auto;
      background-color: $side-nav-background-color;

      ._divider {
        margin-bottom: 1.4rem;
      }

      ._group {
        margin-bottom: 1rem;

        ._title {
          display: flex;
          align-items: center;
          color: $font-color-secondary;
        }
      }
    }
  }
</style>
