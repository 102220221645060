import { UID } from 'shared/api/query/constants';
import { numberFormat as numberFormatOptions, propertyVisibility } from 'shared/constants.json';
import { property as propertyConfig,
  propertyOption as propertyOptionConfig } from 'shared/api/query/configs.json';
import { reverseEdge } from 'shared/api/query/edges';

export const propertyEdges = [
  { attr: UID },
  { attr: propertyConfig.edges.label, default: { de: '' } },
  { attr: propertyConfig.edges.description, default: { de: '' } },
  { attr: propertyConfig.edges.status, default: '' },
  { attr: propertyConfig.edges.type, default: '' },
  { attr: propertyConfig.edges.isGoalType, default: false },
  { attr: propertyConfig.edges.isNotDeletable, default: false },
  { attr: propertyConfig.edges.usedFor, default: [] },
  { attr: propertyConfig.edges.showInDefaultView, default: false },
  { attr: propertyConfig.edges.numberFormat, default: numberFormatOptions.number },
  { attr: propertyConfig.edges.goalVisibility, default: propertyVisibility.alwaysVisible },
  { attr: propertyConfig.edges.goalChildrenVisibility },
  {
    attr: propertyConfig.edges.defaultPropertyOptions,
    model: propertyOptionConfig.model,
    children: [
      { attr: UID },
    ],
  },
  { attr: propertyConfig.edges.propertyOptionOrder, default: [] },
  {
    attr: propertyConfig.edges.lookupProperty,
    model: propertyConfig.model,
    default: null,
    children: [
      { attr: UID },
      { attr: propertyConfig.edges.type },
    ],
  },
  {
    attr: propertyConfig.edges.lookupRelation,
    model: propertyConfig.model,
    default: null,
    children: [{ attr: UID }],
  },
  {
    attr: reverseEdge(propertyOptionConfig.edges.property),
    alias: 'options',
    default: [],
    model: propertyOptionConfig.model,
    children: [{ attr: UID }],
  },
];
