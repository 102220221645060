<template>
  <item-wrapper
    v-if="date !== null"
    class="date-prop"
    :tooltip="prop.label"
    :show-tooltip="showTooltip"
    :style="{ fontSize }"
  >
    <m-icon
      v-if="showIcon"
      class="_icon"
      type="calendar"
    />
    <div class="_date">
      <m-date-picker
        :value="fromISO(date)"
        :date-time="DateTime"
        hide-border
        :locale="userLang"
        no-padding
        small
        disabled
      />
    </div>
  </item-wrapper>
</template>

<script>
import ItemWrapper from '@/components/list/ItemWrapper.vue';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import { DateTime } from 'luxon';
import { fontSizes } from 'shared/font-sizes';
import { fromISO } from 'shared/lib/time';
import { getValueFromEntity } from '@/lib/props';

export default {
  name: 'DateProp',
  props: {
    prop: {
      type: Object,
      required: true,
    },
    entity: {
      type: Object,
      required: true,
    },
    propertyValuesKey: {
      type: String,
      default: 'properties',
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
    showTooltip: {
      type: Boolean,
      default: false,
    },
    fontSize: {
      type: String,
      default: fontSizes[2],
    },
  },
  setup() {
    const { loggedInUser } = useLoggedInUser();
    return { userLang: loggedInUser.value.language };
  },
  components: { ItemWrapper },
  data() {
    return {
      fromISO,
      DateTime,
    };
  },
  computed: {
    date() {
      return getValueFromEntity(this.prop, this.entity, this.propertyValuesKey);
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .date-prop {
    display: flex;
    align-items: center;
    white-space: nowrap;

    ._icon {
      margin-right: .4rem;
    }
  }
</style>
