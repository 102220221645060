<template>
  <div class="breadcrumbs">
    <template v-if="hiddenCrumbs.length > 0">
      <breadcrumb-item
        :item="breadcrumbs[0]"
        :amount="breadcrumbs.length"
        @selected="$emit('selected', item)"
      />
      <span
        class="_divider"
      >/</span>
    </template>
    <template v-if="hiddenCrumbs.length > 0">
      <m-content
        padding-x="6"
        class="_crumb"
      >
        <m-dropdown :title="$t('breadcrumbs.items')">
          <m-btn
            hide-border
            small
            class="_link"
          >
            <m-icon type="ellipsis" />
          </m-btn>
          <template #overlay>
            <m-card

              no-padding
              list
            >
              <m-card-item
                v-for="item in hiddenCrumbs"
                :key="item.title"
              >
                <router-link
                  :to="item.to"
                  class="_item-link"
                >
                  <span
                    v-if="typeof item.icon !== 'undefined' && item.icon !== ''"
                    class="_icon"
                  >{{ item.icon }}</span>
                  {{ item.title }}
                </router-link>
              </m-card-item>
            </m-card>
          </template>
        </m-dropdown>
      </m-content>
      <span
        class="_divider"
      >/</span>
    </template>
    <template
      v-for="(item, i) in crumbs"
    >
      <template v-if="!hidden(item)">
        <template v-if="crumbs.length === (i + 1) && !!$slots.last">
          <m-content
            :key="i"
            padding-x="6"
          >
            <slot
              name="last"
            />
          </m-content>
        </template>
        <breadcrumb-item
          v-else
          :key="i"
          :item="item"
          :amount="crumbs.length"
          :class="['_crumb', i === (crumbs.length - 1) ? '-last' : '', i === 0 && hiddenCrumbs.length === 0 ? '-first' : '']"
          @selected="$emit('selected', item)"
        />
        <span
          v-if="i !== (crumbs.length - 1)"
          :key="`divider_${i}`"
          class="_divider"
        >/</span>
      </template>
    </template>
  </div>
</template>

<script>

import BreadcrumbItem from '@/components/breadcrumbs/BreadcrumbItem.vue';

export default {
  name: 'Breadcrumbs',
  props: {
    breadcrumbs: {
      type: Array,
      required: true,
    },
  },
  emits: ['selected'],
  components: { BreadcrumbItem },
  computed: {
    sliceAmount() {
      if (this.$store.state.breakpoint.smAndDown) {
        return -1;
      }

      return -2;
    },
    crumbs() {
      if (this.breadcrumbs.length <= 3) {
        return this.breadcrumbs;
      }

      return [
        ...this.breadcrumbs.slice(this.sliceAmount),
      ];
    },
    hiddenCrumbs() {
      if (this.breadcrumbs.length > 3) {
        return this.breadcrumbs.slice(1, this.sliceAmount).reverse();
      }

      return [];
    },
  },
  methods: {
    hidden(item) {
      return this.hiddenCrumbs.map((c) => c.title).indexOf(item.title) > -1;
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .breadcrumbs {
    display: flex;
    align-items: center;
    max-width: 100%;

    ._crumb {
      display: flex;
      height: $breadcrumb-height;
      margin: 0 .4rem;
      border-radius: $border-radius-sm;

      ._link {
        padding: 0 .3rem;
      }

      &.-first {
        margin-left: 0;
      }

      &.-last {
        margin-right: 0;
      }
    }

    ._divider {
      display: flex;
      align-items: center;
      height: $breadcrumb-height;
    }
  }

  ._item-link {
    color: $font-color-primary;
  }
</style>
