<template>
  <m-select
    :placeholder="placeholder === '' ? $t('mLangPicker.selectLanguage') : placeholder"
    :items="languages"
    show-search
    :value="value"
    :disabled="disabled"
    :allow-clear="clearable"
    :filter-option="filterOption"
    :full-width="fullWidth"
    :match-trigger-width="matchTriggerWidth"
    v-bind="$attrs"
    @change="emitAppend"
  />
</template>

<script>
import { platformLanguages } from '@/lib/language';
import { sortAlphaNumeric } from 'shared/lib/sort';

export default {
  name: 'MLangPicker',
  props: {
    value: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    appendIcon: {
      type: String,
      default: '',
    },
    includedLanguageCodes: {
      type: Array,
      default: () => null,
    },
    excludedLanguageCodes: {
      type: Array,
      default: () => [],
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    matchTriggerWidth: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input', 'update:value', 'change'],
  computed: {
    languages() {
      const validLanguages = platformLanguages()
        .filter((lang) => (this.includedLanguageCodes !== null ? this.includedLanguageCodes.includes(lang.code) : true))
        .filter((lang) => !this.excludedLanguageCodes.includes(lang.code))
        .sort((a, b) => sortAlphaNumeric(a.name, b.name));

      const currentLanguage = platformLanguages().find((lang) => lang.code === this.value);
      if (currentLanguage !== undefined && !validLanguages.map((l) => l.code).includes(currentLanguage.code)) {
        validLanguages.push(currentLanguage);
      }
      return validLanguages.map((lang) => ({
        text: lang.name,
        value: lang.code,
      }));
    },
  },
  methods: {
    emitAppend(val) {
      this.$emit('input', val);
      this.$emit('update:value', val);
      this.$emit('change', val);
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">

</style>
