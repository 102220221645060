<template>
  <div class="create-form">
    <div class="_editor">
      <questions-editor
        :entity="entity"
        :selected-question="selectedQuestion"
        :selected-question-index="selectedQuestionIndex"
        :disabled="disabled"
        :welcome-screen-selected="welcomeScreenSelected"
        :lang="lang"
        :languages="languages"
        :model="model"
        :entity-by-id="entityById"
        :settings-open="settingsOpen"
        :side-nav-items="sideNavItems"
        :edit-page="editPage"
        :settings-page="settingsPage"
        :create-url="createUrl"
        :template-list-url="templateListUrl"
        :all-questions-url="allQuestionsUrl"
        :question-bank-template-detail-url="questionBankTemplateDetailUrl"
        @selected="select"
        @open-settings="openSettings"
        @field-added="addField"
        @welcome-screen-added="addWelcomeScreen"
        @welcome-screen-selected="selectWelcomeScreen"
      />
    </div>
    <div
      ref="preview"
      class="_preview"
    >
      <preview
        ref="form"
        :form="entity"
        :width="width"
        :lang="lang"
        :languages="languages"
        :show-language-selection="false"
      />
    </div>
  </div>
</template>

<script>
import Preview from 'shared/components/Form.vue';
import QuestionsEditor from '@/components/QuestionsEditor.vue';
import useAddFormEditor from '@/composables/form/add-form-editor';
import { mapActions } from 'vuex';

export default {
  name: 'FormEditor',
  props: {
    model: {
      type: String,
      default: 'form',
    },
    entityById: {
      type: Function,
      required: true,
    },
    entity: {
      type: Object,
      required: true,
    },
    editPage: {
      type: String,
      required: true,
    },
    settingsPage: {
      type: String,
      required: true,
    },
    sideNavItems: {
      type: Array,
      required: true,
    },
    createUrl: {
      type: Object,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
    languages: {
      type: Array,
      required: true,
    },
    allQuestionsUrl: {
      type: Object,
      required: true,
    },
    templateListUrl: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    questionBankTemplateDetailUrl: {
      type: Function,
      required: true,
    },
  },
  components: {
    QuestionsEditor,
    Preview,
  },
  setup() {
    const { appendEditor } = useAddFormEditor();
    return { appendEditor };
  },
  data() {
    return {
      width: '0',
      selectedQuestionIndex: 0,
      loading: false,
      welcomeScreenSelected: false,
      settingsOpen: false,
    };
  },
  computed: {
    selectedQuestion() {
      if (this.selectedQuestionIndex === -1
        || typeof this.entity.orderItems === 'undefined'
        || this.entity.orderItems.length === 0) {
        return null;
      }
      return this.entity.orderItems[this.selectedQuestionIndex];
    },
  },
  methods: {
    ...mapActions([
      'updateEntity',
    ]),
    addField(field) {
      this.loading = true;
      const entity = {
        ...this.entity,
        orderItems: [
          ...this.entity.orderItems,
          {
            value: this.entity.orderItems.length,
            field,
          },
        ],
      };

      this.updateEntity({
        model: this.model,
        entity: this.appendEditor(entity),
        entityById: this.entityById,
      }).then(((response) => {
        this.loading = false;
        if (response.status !== 200) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
          return;
        }
        this.$store.commit('UNSAVED_FORM_CHANGES_EXIST_CHANGED', false);
      }));
    },
    addWelcomeScreen(welcomeScreen) {
      this.loading = true;
      this.updateEntity({
        entity: {
          ...this.entity,
          welcomeScreen,
        },
        model: this.model,
        entityById: this.entityById,
      }).then(((response) => {
        this.loading = false;
        if (response.status !== 200) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
          return;
        }
        this.$store.commit('UNSAVED_FORM_CHANGES_EXIST_CHANGED', false);
      }));
    },
    setWidth() {
      setTimeout(() => {
        let counts = 0;
        try {
          this.width = `${this.$refs.preview.clientWidth}px`;
        } catch (e) {
          counts += 1;
          if (counts < 5) {
            this.setWidth();
          }
        }
      }, 250);
    },
    select({ fieldIndex }) {
      this.selectedQuestionIndex = fieldIndex;
      this.welcomeScreenSelected = false;
      this.$refs.form.activateQuestion({ questionIndex: fieldIndex });
    },
    openSettings({ fieldIndex }) {
      this.settingsOpen = true;
      this.select({ fieldIndex });
    },
    selectWelcomeScreen() {
      this.selectedQuestionIndex = -1;
      this.welcomeScreenSelected = true;
      this.$refs.form.goToWelcomeScreen();
    },
  },
  mounted() {
    this.setWidth();
    window.addEventListener('resize', this.setWidth);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.setWidth);
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .create-form {
    display: flex;

    ._editor {
      flex: 0 0 50%;
    }

    ._preview {
      flex: 1 1 60rem;
    }
  }
</style>
