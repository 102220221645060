import useBulkMutate from '@/nebula/bulk-mutate';
import useRepo from '@/nebula/repo';
import { AND, combine } from 'shared/api/query/filter';
import { COUNT, RESULT, UID } from 'shared/api/query/constants';
import { copy } from 'shared/lib/copy';
import {
  goalActivity as goalActivityConfig,
  update as updateConfig,
} from 'shared/api/query/configs.json';
import { ref } from 'vue';
import { trimEmptyLines } from '@/lib/editor/editor';
import { updateChildren, updateListForGoal, updatesList } from '@/api/query/nebula/update';

export default function useUpdatesRepo() {
  const repo = useRepo(updateConfig.model);
  const bulkMutator = useBulkMutate();

  const getRid = (obj, index) => {
    if (obj.uid !== undefined) {
      return undefined;
    }

    if (obj.rid !== undefined) {
      return obj.rid;
    }

    return index + 1;
  };

  const createSingleLoading = ref(false);
  const createSingle = (update) => {
    createSingleLoading.value = true;

    const payloads = {};

    const entity = {
      ...update,
      goalActivities: update.goalActivities.map((ga, id) => ({
        ...ga,
        message: trimEmptyLines(copy(ga.message)),
        rid: getRid(ga, id),
      })),
    };

    if (entity.goalActivities.length > 0) {
      payloads.mutateGoalActivities = {
        nodes: entity.goalActivities,
        model: goalActivityConfig.model,
        attributes: [{ attr: UID }],
      };
    }

    payloads.createUpdate = {
      nodes: [entity],
      model: updateConfig.model,
      attributes: updateChildren(),
    };

    const responseHandler = (response) => {
      if (response.status !== 200) {
        throw new Error('failed to create update');
      }
      return repo.entityList.value.find((v) => v.uid === response.data.createUpdate[0].uid);
    };

    const finallyHandler = () => {
      createSingleLoading.value = false;
    };

    Object.keys(payloads).forEach((alias) => {
      payloads[alias] = { alias, ...payloads[alias] };
    });

    const bulkPayloads = [
      payloads.mutateGoalActivities,
      payloads.createUpdate,
    ].filter((p) => p !== undefined);

    return bulkMutator.bulkMutate(bulkPayloads).then(responseHandler).finally(finallyHandler);
  };

  const updateSingleLoading = ref(false);
  const updateSingle = (update) => {
    updateSingleLoading.value = true;
    const payloads = {};

    const entity = {
      ...update,
      goalActivities: update.goalActivities.map((ga, id) => ({
        ...ga,
        message: trimEmptyLines(copy(ga.message)),
        rid: getRid(ga, id),
      })),
    };

    if (entity.goalActivities.length > 0) {
      payloads.mutateGoalActivities = {
        nodes: entity.goalActivities,
        model: goalActivityConfig.model,
        attributes: [{ attr: UID }],
      };
    }

    payloads.updateUpdate = {
      nodes: [{ ...entity, goalActivities: entity.goalActivities.filter((ga) => ga.deletedAt === undefined) }],
      model: updateConfig.model,
      attributes: updateChildren(),
    };

    const handleResponse = (response) => {
      if (response.status !== 200) {
        throw new Error('failed to update update');
      }

      return repo.selectSingle(response.data.updateUpdate[0].uid);
    };

    const handleFinally = () => {
      updateSingleLoading.value = false;
    };

    Object.keys(payloads).forEach((alias) => {
      payloads[alias] = { alias, ...payloads[alias] };
    });

    const bulkPayloads = [
      payloads.mutateGoalActivities,
      payloads.updateUpdate,
    ].filter((p) => p !== undefined);

    return bulkMutator.bulkMutate(bulkPayloads).then(handleResponse).finally(handleFinally);
  };

  const getListLoading = ref(false);
  const getList = ({
    filterObjects,
    order,
    pagination,
  }) => {
    const filter = combine(AND, filterObjects.filter((f) => typeof f.filterTree !== 'undefined' && f.filterTree !== null).map((f) => f.filterTree));
    const varBlocks = filterObjects.map((f) => f.varBlocks).flat();

    const goalActivitiesFilterObject = filterObjects.find((f) => typeof f.children !== 'undefined' && f.children !== null && f.children.model === goalActivityConfig.model);
    const goalActivitiesFilter = goalActivitiesFilterObject ? goalActivitiesFilterObject.children.filterTree : null;

    const handleResponse = (response) => {
      const entities = response[RESULT].map((e) => e.uid);
      const count = response[COUNT][0].count;

      return {
        updates: repo.entityList.value.filter((u) => entities.includes(u.uid)),
        count,
      };
    };

    const handleFinally = () => {
      getListLoading.value = false;
    };

    getListLoading.value = true;

    return repo.query(
      updatesList({
        filter,
        goalActivitiesFilter,
        order,
        pagination: {
          ...pagination,
          countAlias: COUNT,
        },
        varBlocks,
      }),
    ).then(handleResponse).finally(handleFinally);
  };

  const getListForGoal = (goalId) => {
    getListLoading.value = true;
    return repo.query(
      updateListForGoal(goalId),
    ).then((response) => {
      const entities = response[RESULT];
      return { updates: entities.map((e) => ({ uid: e.uid })) };
    }).finally(() => {
      getListLoading.value = false;
    });
  };

  return {
    getSingleLoading: repo.selectLoading,
    getSingle: (id) => repo.selectSingle(id, { commitToRemote: true }),
    selectSingle: repo.selectSingle,
    getListLoading,
    getList,
    getListForGoal,
    createSingleLoading,
    createSingle,
    updateSingleLoading,
    updateSingle,
    deleteSingleLoading: repo.deleteLoading,
    deleteSingle: repo.deleteSingle,

    entityList: repo.entityList,
  };
}
