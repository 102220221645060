import { dataSourceAuthenticationType } from 'shared/constants.json';
import { encodeURLValues } from '@/lib/url';

export const jqlLink = (jiraQuery, jql) => {
  let server = '';
  if (jiraQuery.dataSource === null) {
    return '';
  }

  if ([dataSourceAuthenticationType.oauth, dataSourceAuthenticationType.oauthv2].includes(jiraQuery.dataSource.authenticationType)) {
    server = jiraQuery.dataSource.oauthServer;
  }

  if (jiraQuery.dataSource.authenticationType === dataSourceAuthenticationType.apiToken) {
    server = jiraQuery.dataSource.apiServer;
  }

  return `${server}/issues/?${encodeURLValues({ jql })}`;
};
