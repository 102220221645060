<template>
  <div :class="['distribution-chart']">
    <sub-heading
      v-if="title !== ''"
      :title="title"
      class="_title"
    />
    <no-data v-if="chartData.length === 0" />
    <m-content
      v-for="(item, index) in chartData"
      v-else
      :key="index"
      :padding-y="2"
      :padding-x="5"
      :class="['_row', clickable ? '-clickable': '']"
      @click="handleClick(item)"
    >
      <div
        :ref="`label_${index}`"
        class="_label"
        :style="{ flex: `0 0 ${maxLabelWidth}px`, display: 'flex' }"
      >
        <slot
          name="label"
          :item="item"
        >
          <template v-if="!$slots.label">
            <m-tag
              :title="item.x"
              color="none"
            />
          </template>
        </slot>
      </div>
      <m-content
        padding-xxs
        class="_bar-container"
      >
        <div
          v-if="item.y > 0"
          class="_bar"
          :style="barStyle[index]"
        />
        <div
          :ref="`value_${index}`"
          :class="['_value-wrapper', item.y > 0 ? '-has-bar' : '']"
        >
          <span class="_value">
            {{ item.y }}
          </span>
          <span
            v-if="item.diffY !== undefined"
            class="_value-diff"
          > ({{ item.diffY > 0 ? '+' : item.diffY === 0 ? '±' : '' }}{{ item.diffY }})</span>
        </div>
      </m-content>
    </m-content>
  </div>
</template>

<script>

import NoData from '@/components/dashboard/NoData.vue';
import SubHeading from 'shared/components/SubHeading.vue';

export default {
  name: 'DistributionChart',
  props: {
    title: {
      type: String,
      default: '',
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    chartData: {
      type: Array,
      default: () => [],
    },
  },
  components: { NoData, SubHeading },
  data() {
    return {
      maxLabelWidth: 0,
      maxValueWidth: 0,
    };
  },
  emits: ['click'],
  computed: {
    maxY() {
      return this.chartData.reduce((res, next) => {
        if (next.y > res.y) {
          return next;
        }
        return res;
      }, this.chartData[0]).y;
    },
    barStyle() {
      return this.chartData.reduce((res, next) => {
        const divider = this.maxY === 0 ? next.y : this.maxY;
        return [...res, {
          flex: `0 0 calc((100% - ${this.maxValueWidth}px) *  ${next.y / divider} )`,
          backgroundColor: next.color ? next.color : this.$colors.grey.darken3,
        }];
      }, []);
    },
  },
  methods: {
    dotStyle(item) {
      return { backgroundColor: item.color };
    },
    handleClick(item) {
      if (!this.clickable) {
        return;
      }

      this.$emit('click', item);
    },
    calculateWidths() {
      this.maxLabelWidth = this.chartData.reduce((res, next, index) => {
        const width = this.$refs[`label_${index}`][0].clientWidth;
        if (width > res) {
          return width;
        }

        return res;
      }, 0);
      this.maxValueWidth = this.chartData.reduce((res, next, index) => {
        const width = this.$refs[`value_${index}`][0].clientWidth;
        if (width > res) {
          return width;
        }

        return res;
      }, 0);
    },
  },
  watch: {
    chartData: {
      handler() {
        this.$nextTick(() => {
          this.calculateWidths();
        });
      },
      deep: true,
    },
  },
  mounted() {
    this.calculateWidths();
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  .distribution-chart {
    ._title {
      margin-left: .6rem;
      overflow: hidden;
      font-size: $font-size-5;
      font-weight: $font-weight-medium;
      text-overflow: ellipsis;
      color: $font-color-secondary;
      white-space: nowrap;
    }

    ._row {
      display: flex;
      align-items: center;
      margin: .8rem 0;

      &.-clickable {
        cursor: pointer;

        &:hover {
          background-color: $hover-color;
          border-radius: $default-border-radius;
        }
      }

      ._label {
        margin-right: 2.4rem;
      }

      ._bar-container {
        display: flex;
        flex: 3;
        align-items: inherit;
      }

      ._bar {
        min-width: 1rem;
        height: 1rem;
        overflow: hidden;
        border-radius: .5rem;
      }

      ._value-wrapper {
        white-space: nowrap;

        &.-has-bar {
          ._value {
            margin-left: 1.2rem;
          }
        }

        ._value-diff {
          color: $font-color-secondary;
        }
      }
    }
  }

</style>
