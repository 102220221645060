import useGoals from '@/composables/goal/goals';
import { RESULT } from 'shared/api/query/constants';
import { buildCustomFuncProgressCoursePred } from '@/api/query/custom-func-helper';
import { goal } from 'shared/api/query/configs.json';
import { goalById } from '@/api/query/nebula/goal';
import { logCatch } from '@/lib/logger/logger';
import { reverseEdge } from 'shared/api/query/edges';
import { updateListForGoal } from '@/api/query/nebula/update';

export default function useTileSingleGoalFetcher(progressCourseCtx) {
  const goalsSvc = useGoals();

  const getGoalById = (id, customFuncHandler, compareTo) => {
    const customFuncs = [];
    if (customFuncHandler !== undefined) {
      customFuncs.push(buildCustomFuncProgressCoursePred({ alias: customFuncHandler.alias.value, attr: reverseEdge(goal.edges.paysOnto), timeSeries: compareTo.value }));
    }

    return goalsSvc.getGoals({
      queries: [
        ...goalById(id, RESULT, customFuncs),
        ...updateListForGoal(id, 'updates'),
      ],
      customFuncHandler: [progressCourseCtx, customFuncHandler].filter((i) => i !== undefined),
    }).then((data) => ({
      goal: data[RESULT][0],
      updates: data.updates,
    })).catch(logCatch(() => {
      throw new Error('failed to query goal with id:', id);
    }));
  };

  return { getGoalById };
}
