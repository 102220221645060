const queriesEqual = (a, b) => {
  if (a.attr !== b.attr) {
    return false;
  }

  if (a.alias !== b.alias) {
    return false;
  }

  if (a.model !== b.model) {
    return false;
  }

  return true;
};

export const spreadQueries = (queries) => {
  let res = [];

  queries.reverse().forEach((query) => {
    if (res.find((q) => queriesEqual(q, query)) !== undefined) {
      return;
    }

    res = [query, ...res];
  });

  return res;
};
