<template>
  <div class="personal-infos">
    <div class="_profile-image">
      <div class="_avatar">
        <img
          :src="profileImage"
          alt=""
        >
        <input
          ref="fileinput"
          type="file"
          :style="{display: 'none'}"
          accept="image/*"
          @change="fileChanged"
        >
        <m-dialog
          v-model:value="showCropImage"
          :title="$t('accountSettings.setProfilePicture')"
          hide-footer
          keep-open-on-mask-click
          no-padding
        >
          <image-crop
            v-if="file !== null"
            class="_image-crop"
            :file="file"
            @cropped="uploadImage"
            @cancel="cancelImageCrop"
            @wrong-file-type="handleWrongFileType"
          />
        </m-dialog>
      </div>
      <div class="_upload-btn">
        <m-btn
          hide-border
          small
          light
          @click="triggerInput"
        >
          {{ $t('personalInfos.uploadImage') }}
        </m-btn>
      </div>
    </div>
    <div class="_form">
      <div class="_item">
        <div class="_label">
          {{ $t('personalInfos.firstNameLabel') }}
        </div>
        <m-text-field
          v-model:value="firstName"
          auto-focus
        />
      </div>
      <div class="_item">
        <div class="_label">
          {{ $t('personalInfos.lastNameLabel') }}
        </div>
        <m-text-field
          v-model:value="lastName"
          @keydown.enter="update"
        />
      </div>
      <div
        v-if="!loggedInUserAccount.accountSettings.restrictPasswordLogin"
        class="_item"
      >
        <div class="_label">
          {{ $t('personalInfos.passwordLabel') }}
        </div>
        <m-input-password
          v-model:value="password"
          @keydown.enter="update"
        />
      </div>
      <m-btn
        color="primary"
        :disabled="disabled"
        block
        :loading="loading"
        class="_action"
        @click="update"
      >
        <template v-if="!isLastStep">
          {{ $t('onboarding.continue') }}
        </template>
        <template v-else>
          {{ $t('onboarding.launchMooncamp') }}
        </template>
      </m-btn>
    </div>
  </div>
</template>

<script>
import ImageCrop from '@/components/ImageCrop.vue';
import useGoalCycle from '@/composables/goal-cycle/goal-cycle';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import usePersonalAppSettings from '@/composables/logged-in-user/personal-app-settings';
import { DateTime } from 'luxon';
import { copy } from 'shared/lib/copy';
import { logCatch } from '@/lib/logger/logger';

export default {
  name: 'PersonalInfos',
  props: {
    isLastStep: {
      type: Boolean,
      default: false,
    },
  },
  components: { ImageCrop },
  setup() {
    const { goalCycles } = useGoalCycle();
    const { uploadProfileImage, loggedInUser, updateUser, updateUserLoading } = useLoggedInUser();
    const { personalAppSettings } = usePersonalAppSettings(loggedInUser);
    const { loggedInUserAccount } = useLoggedInUserAccount();
    return {
      loggedInUserAccount,
      updateUser,
      loading: updateUserLoading,
      goalCycles,
      uploadProfileImage,
      personalAppSettings,
      loggedInUser,
    };
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      password: '',
      file: null,
      change: 0,
      showCropImage: false,
    };
  },
  computed: {
    passwordValid() {
      if (this.loggedInUserAccount.accountSettings.restrictPasswordLogin) {
        return true;
      }

      return this.password !== '' && this.password.length >= 8;
    },
    disabled() {
      return this.firstName === '' || this.lastName === '' || !this.passwordValid;
    },
    profileImage() {
      if (this.loggedInUser.profileImage === null) {
        return new URL('@/assets/img/profile-image.svg', import.meta.url).href;
      }

      return this.loggedInUser.profileImage.getURL;
    },
    selectedGoalCycles() {
      if (this.goalCycles.length === 0) {
        return [];
      }

      const now = DateTime.local();
      const res = this.goalCycles.filter((c) => DateTime.fromISO(c.start).diff(now) <= 0 && DateTime.fromISO(c.end).diff(now) >= 0);

      if (res.length === 0) {
        return [this.goalCycles[0]];
      }
      return res;
    },
  },
  methods: {
    update() {
      if (this.disabled) {
        return;
      }
      const toUpdate = {
        ...copy(this.loggedInUser),
        firstName: this.firstName,
        lastName: this.lastName,
        personalInfosCompleted: DateTime.local().toISO(),
        notificationSetting: {
          ...this.loggedInUser.notificationSetting,
          email: true,
        },
      };
      if (this.password !== '') {
        toUpdate.password = this.password;
      }
      if (toUpdate.profileImage === null) {
        delete toUpdate.profileImage;
      }

      const personalAppSettings = {
        uid: this.personalAppSettings.uid,
        selectedGoalCycles: this.selectedGoalCycles,
        user: this.loggedInUser,
      };
      this.updateUser(toUpdate, personalAppSettings, { optimistic: false }).catch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      });
    },
    triggerInput() {
      this.$refs.fileinput.click();
    },
    uploadImage(blob) {
      this.uploadProfileImage(blob)
        .then(() => {
          this.change += 1;
          this.$forceUpdate();
          this.file = null;
          this.showCropImage = false;
        }).catch(logCatch(() => {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
        }));
    },
    cancelImageCrop() {
      this.showCropImage = false;
      this.file = null;
    },
    handleWrongFileType() {
      this.showCropImage = false;
      this.file = null;
      this.$showSnackbar({ color: 'error', message: this.$t('error.notAnImage') });
    },
    fileChanged(event) {
      if (event.target.files.length === 0) {
        return;
      }

      this.file = event.target.files[0];
      this.showCropImage = true;
      this.$refs.fileinput.value = '';
    },
  },
  watch: {
    showCropImage(newValue) {
      if (!newValue) {
        this.file = null;
      }
    },
  },
  created() {
    this.firstName = this.loggedInUser.firstName;
    this.lastName = this.loggedInUser.lastName;
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  @import "shared/assets/scss/box-shadow";

  .personal-infos {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ._profile-image {
      display: flex;
      flex-direction: column;
      justify-content: center;

      ._avatar {
        margin-bottom: 1.6rem;
        text-align: center;

        img {
          width: 6.4rem;
          height: 6.4rem;
          border-radius: 50%;

          @include box_shadow(1);
        }
      }
    }

    ._form {
      width: 100%;
      max-width: 28rem;

      ._item {
        margin-bottom: 1.6rem;

        &:last-of-type {
          margin-bottom: 2.8rem;
        }

        ._label {
          font-size: $font-size-3;
          color: $font-color-secondary;
        }
      }
    }
  }
</style>
