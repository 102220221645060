<template>
  <m-text-field
    :value="value"
    :placeholder="placeholder"
    background-color="white"
    class="_input"
    @input="update"
  />
</template>

<script>
export default {
  name: 'LanguageField',
  props: {
    value: {
      type: String,
      default: '',
    },
    fieldKey: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
  },
  emits: ['update'],
  methods: {
    update(value) {
      this.$emit('update', { value, fieldKey: this.fieldKey });
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">

</style>
