<template>
  <div class="form-wrapper">
    <create-form-top-bar
      v-if="formAvailable"
      :languages="languages"
      :form="form"
      :form-id="formId"
      :lang="lang"
      @language-changed="changeLanguage"
    />
    <full-screen-spinner v-if="!formAvailable" />
    <template v-else>
      <router-view />
    </template>
  </div>
</template>

<script>
import CreateFormTopBar from '@/components/CreateFormTopBar.vue';
import FullScreenSpinner from 'shared/components/FullScreenSpinner.vue';
import useForm from '@/composables/form/form';
import { mapActions } from 'vuex';

export default {
  name: 'FormWrapper',
  components: {
    CreateFormTopBar,
    FullScreenSpinner,
  },
  setup() {
    const { form, lang, formAvailable, formId, changeLanguage, languages } = useForm();
    return { changeLanguage, form, lang, formId, formAvailable, languages };
  },
  methods: {
    ...mapActions([
      'getFormAndProcess',
    ]),
    retrieveForm() {
      if (this.formAvailable) {
        return;
      }
      this.getFormAndProcess({ formId: this.formId }).then((response) => {
        if (response.status !== 200) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
        }
      });
    },
  },
  created() {
    this.retrieveForm();
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  .create-form {
    ._content {
      display: flex;
    }
  }
</style>
