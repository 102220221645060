<template>
  <div class="user-scope">
    <div
      v-if="!hideOp"
      class="_op"
    >
      <m-select
        v-model:value="selectedType"
        :items="operatorTypes"
        :read-only="readOnly"
        :disabled="disabled"
        class="_select"
      />
    </div>
    <user-picker
      v-if="!isEmpty"
      v-model:value="selectedUsers"
      class="_user-select"
      full-width
      :read-only="readOnly"
      :disabled="disabled"
      :max-tag-text-length="maxTagTextLength"
      :placeholder="$t('userScope.placeholder')"
    />
  </div>
</template>

<script>
import UserPicker from '@/components/UserPicker.vue';
import { AND, EMPTY, NOT, NOT_EMPTY } from '@/lib/filter/scope-tree';
import { copy } from 'shared/lib/copy';

export default {
  name: 'UserScope',
  props: {
    value: {
      type: Object,
      required: true,
    },
    fieldName: {
      type: String,
      required: true,
    },
    isNot: {
      type: Boolean,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideOp: {
      type: Boolean,
      default: false,
    },
    maxTagTextLength: {
      type: Number,
      default: 0,
    },
    hideIsEmpty: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input', 'update:value', 'change-not', 'change'],
  components: { UserPicker },
  data() {
    return {
      selectedUsers: [],
      isEmpty: false,
    };
  },
  computed: {
    operatorTypes() {
      if (this.hideIsEmpty) {
        return [
          { text: this.$t('optionScope.contains'), value: AND },
          { text: this.$t('optionScope.notContains'), value: NOT },
        ];
      }
      return [
        { text: this.$t('optionScope.contains'), value: AND },
        { text: this.$t('optionScope.notContains'), value: NOT },
        { text: this.$t('optionScope.isEmpty'), value: EMPTY },
        { text: this.$t('optionScope.isNotEmpty'), value: NOT_EMPTY },
      ];
    },
    selectedType: {
      get() {
        if (this.isNot) {
          if (this.isEmpty) {
            return NOT_EMPTY;
          }
          return NOT;
        }
        if (this.isEmpty) {
          return EMPTY;
        }
        return AND;
      },
      set(val) {
        this.updateEmpty(val);
        if ([NOT, NOT_EMPTY].includes(val)) {
          this.$emit('change-not', NOT);
          return;
        }
        this.$emit('change-not', AND);
      },
    },
    scope() {
      return {
        ...this.value,
        [this.fieldName]: this.selectedUsers,
        isEmpty: this.isEmpty,
      };
    },
  },
  methods: {
    updateEmpty(type) {
      if ([EMPTY, NOT_EMPTY].includes(type)) {
        this.isEmpty = true;
        return;
      }
      this.isEmpty = false;
    },
    updateValues() {
      let isEmpty = false;
      if (this.value !== null && typeof this.value.isEmpty !== 'undefined') {
        isEmpty = this.value.isEmpty;
      }
      this.isEmpty = isEmpty;

      if (this.value === null
        || this.value[this.fieldName] === null
      ) {
        this.selectedUsers = [];
        return;
      }
      this.selectedUsers = this.value[this.fieldName];
    },
    emitUpdate(val) {
      this.$emit('input', val);
      this.$emit('update:value', val);
      this.$emit('change', val);
    },
  },
  watch: {
    scope: {
      handler(newVal, oldVal) {
        if (JSON.stringify(oldVal) === JSON.stringify(newVal)) {
          return;
        }

        const cp = copy(newVal);
        this.emitUpdate(cp);
      },
      deep: true,
    },
    value: {
      handler() {
        this.updateValues();
      },
      deep: true,
    },
  },
  created() {
    this.updateValues();
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .user-scope {
    display: flex;

    ._op {
      margin-right: .4rem;

      ._select {
        width: 100%;
      }
    }

    ._user-select {
      min-width: 20rem;
    }
  }
</style>
