<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    data-icon="status"
    width="1em"
    height="1em"
    fill="currentColor"
    aria-hidden="true"
    focusable="false"
    class=""
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.8097 13.9516L17.8857 13.1753C17.9603 12.7984 18 12.4058 18 12C18 11.5942 17.9603 11.2016 17.8857 10.8247L21.8097 10.0484C21.9345 10.6796 22 11.3321 22 12C22 12.6679 21.9345 13.3204 21.8097 13.9516ZM20.3157 6.44384L16.9918 8.66912C16.5526 8.01309 15.9869 7.44742 15.3309 7.00822L17.5562 3.68435C18.6467 4.41443 19.5856 5.35333 20.3157 6.44384ZM13.9516 2.19034L13.1753 6.11429C12.7984 6.03973 12.4058 6 12 6C11.5942 6 11.2016 6.03973 10.8247 6.11429L10.0484 2.19034C10.6796 2.06547 11.3321 2 12 2C12.6679 2 13.3204 2.06547 13.9516 2.19034ZM6.44384 3.68435L8.66912 7.00822C8.01309 7.44742 7.44742 8.01309 7.00822 8.66912L3.68435 6.44384C4.41443 5.35333 5.35333 4.41443 6.44384 3.68435ZM2.19034 10.0484C2.06547 10.6796 2 11.3321 2 12C2 12.6679 2.06547 13.3204 2.19034 13.9516L6.11429 13.1753C6.03973 12.7984 6 12.4058 6 12C6 11.5942 6.03973 11.2016 6.11429 10.8247L2.19034 10.0484ZM3.68435 17.5562L7.00822 15.3309C7.44742 15.9869 8.01309 16.5526 8.66912 16.9918L6.44384 20.3157C5.35333 19.5856 4.41443 18.6467 3.68435 17.5562ZM10.0484 21.8097L10.8247 17.8857C11.2016 17.9603 11.5942 18 12 18C12.4058 18 12.7984 17.9603 13.1753 17.8857L13.9516 21.8097C13.3204 21.9345 12.6679 22 12 22C11.3321 22 10.6796 21.9345 10.0484 21.8097ZM17.5562 20.3157L15.3309 16.9918C15.9869 16.5526 16.5526 15.9869 16.9918 15.3309L20.3157 17.5562C19.5856 18.6467 18.6467 19.5856 17.5562 20.3157Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default { name: 'Status' };
</script>

<style scoped lang="scss" type="text/scss">

</style>
