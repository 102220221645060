<template>
  <div
    class="edit-user-form"
  >
    <m-content
      padding
      class="_content"
    >
      <h5 class="pa-0">
        {{ $t('editUserForm.groups.personal') }}
      </h5>
      <m-form-item
        :label="$t('editUserForm.firstName')"
      >
        <m-text-field
          v-model:value="firstName"
          :disabled="!canSave"
        />
      </m-form-item>
      <m-form-item
        :label="$t('editUserForm.lastName')"
      >
        <m-text-field
          v-model:value="lastName"
          :disabled="!canSave"
        />
      </m-form-item>
      <m-form-item
        :label="$t('editUserForm.email')"
      >
        <m-text-field
          v-model:value="email"
          :disabled="isEdit || !canSave"
        />
      </m-form-item>
      <m-form-item
        :label="$t('editUserForm.language')"
      >
        <m-lang-picker
          v-model:value="language"
          :included-language-codes="availableSystemLanguageCodes"
          :disabled="!canSave"
          full-width
          match-trigger-width
          clearable
        />
      </m-form-item>
      <m-form-item
        :label="$t('userList.accessGroups')"
      >
        <m-text-field
          :value="user.accessGroups"
          disabled
        >
          <template
            v-if="isEdit"
            #suffix
          >
            <m-btn
              hide-boder
              xs
              @click="$emit('go-to-access-groups')"
            >
              {{ $t('general.edit') }}
            </m-btn>
          </template>
        </m-text-field>
      </m-form-item>
      <h5 class="pa-0">
        {{ $t('editUserForm.groups.other') }}
      </h5>
      <template
        v-for="prop in userProperties"
        :key="prop.uid"
      >
        <m-form-item
          :label="getFieldLabel(prop)"
        >
          <property-form-item
            :property="prop"
            :property-values="propertyValues"
            full-width
            match-trigger-width
            popup
            :disabled="!canSave"
            :placeholder="$t('general.empty')"
            clearable
            @change="updateProperty($event, prop.uid)"
          />
        </m-form-item>
      </template>
    </m-content>
    <m-default-form-actions
      v-if="canSave"
      :show-cancel="showCancel"
      :loading="createAndInviteUserLoading || updateUserLoading"
      clickable
      @cancel="emitCancel"
      @submit="submit"
    >
      <div
        v-if="!isEdit"
        class="_checkbox"
      >
        <m-checkbox
          v-model:value="sendInvitationMail"
          :label="$t('editUserForm.sendInvitationMail')"
        />
      </div>
    </m-default-form-actions>
  </div>
</template>

<script>
import MLangPicker from '@/components/MLangPicker.vue';
import PropertyFormItem from '@/components/PropertyFormItem.vue';
import useAccess from '@/composables/access/access';
import useInvitations from '@/composables/user/invitations/invitations';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useProperties from '@/composables/property/property';
import useUsers from '@/composables/user/users';
import { AVAILABLE_SYSTEM_LANGUAGE_CODES } from '@/lib/language';
import { accessGroupFlag, routeName } from 'shared/constants.json';
import { copy } from 'shared/lib/copy';
import { emptyValue } from '@/lib/property';
import { textByLang } from 'shared/lib/language';

export default {
  name: 'EditUserForm',
  props: {
    user: {
      type: Object,
      default: () => null,
    },
    account: {
      type: Object,
      required: true,
    },
    color: {
      type: String,
      default: 'white',
    },
    showCancel: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['create', 'create:invitation', 'update', 'cancel', 'update:dirty', 'go-to-access-groups'],
  components: { MLangPicker, PropertyFormItem },
  setup() {
    const { userHasRight } = useAccess();
    const { loggedInUser, userLang } = useLoggedInUser();
    const { createUser, updateUser, updateUserLoading } = useUsers();
    const { inviteUser } = useInvitations();
    const { userProperties } = useProperties();
    return {
      userHasRight,
      loggedInUser,
      createUser,
      updateUser,
      updateUserLoading,
      inviteUser,
      userProperties,
      userLang,
    };
  },
  data() {
    return {
      availableSystemLanguageCodes: AVAILABLE_SYSTEM_LANGUAGE_CODES,
      sendInvitationMail: true,
      valid: false,
      firstName: '',
      lastName: '',
      email: '',
      language: '',
      createAndInviteUserLoading: false,
      propertyValues: [],
      routeName,
      dirty: false,
    };
  },
  computed: {
    canSave() {
      return this.userHasRight([accessGroupFlag.userWriteAccess]);
    },
    isEdit() {
      return this.user.uid !== 0;
    },
    values() {
      const userPropIds = this.userProperties.map((p) => p.uid);
      return {
        firstName: this.firstName.trim(),
        lastName: this.lastName.trim(),
        language: this.language,
        email: this.email.trim(),
        values: this.propertyValues.filter((v) => userPropIds.includes(v.property.uid)).map((v) => ({
          ...v,
          spaces: v.spaces === undefined ? [] : v.spaces.map((o) => ({ uid: o.uid })),
          selectedOptions: v.selectedOptions === undefined ? [] : v.selectedOptions.map((o) => ({ uid: o.uid })),
          users: v.users === undefined ? [] : v.users.map((u) => ({ uid: u.uid })),
          property: { uid: v.property.uid },
        })),
      };
    },
  },
  methods: {
    updateProperty(value, propId) {
      this.$emit('update:dirty', true);
      const index = this.propertyValues.reduce((res, v, i) => {
        if (v.property.uid === propId) {
          res = i;
        }
        return res;
      }, -1);
      if (index === -1) {
        throw new Error(`property not found: ${propId}`);
      }
      this.propertyValues.splice(index, 1, value);
    },
    submit() {
      this.$emit('update:dirty', false);
      if (this.isEdit) {
        this.update({ ...this.user, ...this.values });
        return;
      }

      this.create(this.values);
    },
    create(toCreate) {
      this.createAndInviteUserLoading = true;
      this.createUser(toCreate).then((createdUser) => {
        this.$emit('create', createdUser.uid);
        if (!this.sendInvitationMail) {
          this.createAndInviteUserLoading = false;
          this.$showSnackbar({ color: 'success', message: this.$t('success.created') });
          return;
        }

        this.inviteUser(createdUser).then(() => {
          this.$emit('create:invitation', createdUser.uid);
          this.$showSnackbar({ color: 'success', message: this.$t('success.created') });
        }).catch(() => {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
        }).finally(() => {
          this.createAndInviteUserLoading = false;
        });
      }).catch((e) => {
        this.createAndInviteUserLoading = false;
        if (e.message.indexOf('user with email') > -1 && e.message.indexOf('already exists') > -1) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.emailAlreadyExists') });
          return;
        }
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      });
    },
    update(user) {
      const toUpdate = user;
      delete (toUpdate.notificationSetting);
      delete (toUpdate.profileImage);
      this.updateUser(toUpdate).then(() => {
        this.$emit('update');
        this.$showSnackbar({ color: 'success', message: this.$t('success.updated') });
      }).catch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      });
    },
    getFieldLabel(field) {
      return textByLang(field.label, this.userLang);
    },
    emitCancel() {
      this.$emit('cancel');
    },
  },
  watch: {
    firstName(newVal, oldVal) {
      if (oldVal === '') {
        return;
      }
      this.$emit('update:dirty', true);
    },
    lastName(newVal, oldVal) {
      if (oldVal === '') {
        return;
      }
      this.$emit('update:dirty', true);
    },
    language(newVal, oldVal) {
      if (oldVal === '') {
        return;
      }
      this.$emit('update:dirty', true);
    },
    email(newVal, oldVal) {
      if (oldVal === '') {
        return;
      }
      this.$emit('update:dirty', true);
    },
  },
  created() {
    const userPropIds = this.userProperties.map((p) => p.uid);
    if (this.isEdit) {
      this.propertyValues = copy(this.user.values.filter((v) => userPropIds.includes(v.property.uid)));
      this.firstName = this.user.firstName;
      this.lastName = this.user.lastName;
      this.language = this.user.language;
      this.email = this.user.email;
      return;
    }

    this.language = this.loggedInUser.language;
    this.propertyValues = this.userProperties.map((p) => emptyValue(p));
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .edit-user-form {
    ._content {
      height: calc(100vh - 13rem);
      overflow: auto;
    }
  }

  ._checkbox {
    margin-right: 1.2rem;
  }

  .edit-user-card {
    width: 100%;
    max-width: $default-card-max-width;
    height: 100%;
  }
</style>
