import { DateTime } from 'luxon';
import { isEmptyType, isNotEmptyType, translateDynamicTimeRange } from '@/lib/filter/dynamic-date';

const isInRange = (timestamp, range) => {
  const { start, end } = translateDynamicTimeRange(range);

  if (isEmptyType(range)) {
    return timestamp === null;
  }
  if (isNotEmptyType(range)) {
    return timestamp !== null;
  }

  const selected = DateTime.fromISO(timestamp);
  if (!selected.isValid) {
    return false;
  }

  const filters = [];
  if (start !== null) {
    const startDateTime = DateTime.fromISO(start);
    if (!startDateTime.isValid) {
      return false;
    }
    filters.push(selected > startDateTime);
  }
  if (end !== null) {
    const endDateTime = DateTime.fromISO(end);
    if (!endDateTime.isValid) {
      return false;
    }
    filters.push(selected < endDateTime);
  }
  return filters.reduce((acc, next) => acc && next, true);
};

export const dateFn = ({ propertyValues, scope }) => {
  const pvs = propertyValues.filter((pv) => pv.property.uid === scope.property.uid);
  if (pvs.length === 0) {
    return false;
  }

  return isInRange(pvs[0].timestamp, scope.timeRange);
};

export const directDateFn = ({ entity, scope }) => {
  if (scope.timeRange === undefined) {
    return (scope.isEmpty === true && entity[scope.directProperty.edgeName] === null)
      || (scope.isEmpty === false && entity[scope.directProperty.edgeName] !== null);
  }

  return isInRange(entity[scope.directProperty.edgeName], scope.timeRange);
};
