// inspired by, https://github.com/developit/mitt/blob/main/src/index.ts
// which is recommended by the Vue 3 doc.

const handler = {};

export const EventBus = {
  $emit: (key, ...[a, b, c, d, e, f, g, h]) => {
    if (typeof handler[key] === 'undefined') {
      return;
    }

    for (let i = 0; i < handler[key].length; i++) {
      handler[key][i](a, b, c, d, e, f, g, h);
    }
  },
  $on: (key, fn) => {
    if (typeof handler[key] === 'undefined') {
      handler[key] = [fn];
    }

    for (let i = 0; i < handler[key].length; i++) {
      if (handler[key][i] === fn) {
        return;
      }
    }

    handler[key].push(fn);
  },
  $off: (key, fn) => {
    if (typeof handler[key] === 'undefined') {
      return;
    }
    for (let i = 0; i < handler[key].length; i++) {
      if (fn === handler[key][i]) {
        handler[key].splice(i, 1);
        return;
      }
    }
  },
};
