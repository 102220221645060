import useGoals from '@/composables/goal/goals';
import useSnackBar from '@/composables/snackbar';
import { logCatch } from '@/lib/logger/logger';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

export default function useStatusAutoUpdate() {
  const { updateSingle } = useGoals();
  const { error, success } = useSnackBar();
  const { t } = useI18n();

  const automaticStatusUpdateLoading = ref(false);
  const toggleStatusUpdate = (goal) => {
    automaticStatusUpdateLoading.value = true;
    return updateSingle({ uid: goal.uid, disableStatusAutoUpdate: !goal.disableStatusAutoUpdate }).then(() => {
      if (goal.disableStatusAutoUpdate) {
        success(t('goalStatusTag.successfullyDeactivated'));
        return;
      }
      success(t('goalStatusTag.successfullyActivated'));
    }).catch(logCatch(() => {
      error(t('error.default'));
    })).finally(() => {
      automaticStatusUpdateLoading.value = false;
    });
  };

  return {
    automaticStatusUpdateLoading,
    toggleStatusUpdate,
  };
}
