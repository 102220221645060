import useSort from '@/composables/sort/sort';
import { CREATED_AT, MODIFIED_AT } from 'shared/api/query/constants';
import { encodeCachedPropertyUid } from '@/lib/props/cached-property';
import { goal as goalConfig } from 'shared/api/query/configs.json';
import { propertyType } from 'shared/constants.json';

export default function useGoalsSorting(properties, userSvc) {
  const attributeMap = {
    [goalConfig.edges.cachedProperty]: { type: 'cachedProperty' },
    [goalConfig.edges.cachedLastUpdatedAt]: { type: 'date' },
    [CREATED_AT]: { type: 'date' },
    [MODIFIED_AT]: { type: 'date' },
    [goalConfig.edges.cachedParents]: { ignored: true },
  };
  const sortOptions = [
    { attr: goalConfig.edges.title },
    { attr: CREATED_AT, desc: true },
    { attr: goalConfig.edges.cachedLastUpdatedAt, desc: true },
    { attr: goalConfig.edges.cachedCalculatedCurrent, desc: true },
    ...properties.value.filter((p) => p.type !== propertyType.lookup)
      .map((p) => ({
        attr: goalConfig.edges.cachedProperty,
        langs: [encodeCachedPropertyUid(p.uid)],
        desc: [propertyType.date].includes(p.type),
      })),
  ];

  const { sort } = useSort(attributeMap, properties, goalConfig.edges.properties, userSvc);
  return {
    sort,
    sortOptions,
  };
}
