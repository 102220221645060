import useGoalPickerFilter from '@/composables/goal/goal-picker-filter';
import { AND } from 'shared/api/query/constants';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { viewApplication, viewType } from 'shared/constants.json';

export default function useGoalPicker(goal, loggedInUserAccount, validateParent, accountSettings) {
  const { t } = useI18n();
  const { goalPickerFilter } = useGoalPickerFilter(goal.value);

  const defaultView = {
    title: t('list.cascade'),
    viewType: viewType.cascade,
    viewApplication: viewApplication.goalParentPicker,
    params: {
      filter: { account: { uid: loggedInUserAccount.value.uid }, children: [], op: AND },
      applyFilterOnFirstLevelOnly: false,
      props: [],
      order: [],
      wrapCells: true,
    },
  };

  return {
    defaultView,
    viewApplication: viewApplication.goalParentPicker,
    filter: goalPickerFilter,
    multiAlignment: accountSettings.value.usesMultiGoalAlignment,
    disabledCondition: (g) => Object.keys(validateParent({ toValidate: g, selectRulesFrom: goal.value })).length > 0,
    disabled: computed(() => {
      const selectedTypes = goal.value.properties.find((p) => p.property.isGoalType).selectedOptions;
      if (selectedTypes.length === 0) {
        return false;
      }
      return goal.value.parents.length === 0 && selectedTypes[0].canBeChildOf.length === 0;
    }),
  };
}
