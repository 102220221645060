<template>
  <div
    class="sign-in"
    :style="compoentStyle"
  >
    <div class="_content">
      <div
        v-if="header !== ''"
        class="_header"
      >
        <h1>{{ header }}</h1>
      </div>
      <slot name="header" />
      <div class="_inner">
        <slot />
      </div>

      <template v-if="showPlants && !$store.state.breakpoint.smAndDown">
        <img
          class="_plant-left"
          src="@/assets/img/illustrations/plants-2.png"
        >
        <img
          class="_plant-right"
          src="@/assets/img/illustrations/plants-1.png"
        >
      </template>
    </div>
    <div
      v-if="showFooter"
      class="_footer"
    >
      <m-link
        :href="imprint"
        class="_link"
        target="_blank"
        inherit-color
      >
        {{ $t('signIn.imprint') }}
      </m-link>
      <m-link
        :href="terms"
        class="_link"
        target="_blank"
        inherit-color
      >
        {{ $t('signIn.terms') }}
      </m-link>
      <m-link
        :href="privacy"
        class="_link"
        target="_blank"
        inherit-color
      >
        {{ $t('signIn.privacy') }}
      </m-link>
    </div>
  </div>
</template>

<script>
import { IMPRINT_URL, PRIVACY_URL, TERMS_URL } from 'shared/transformers';
import { browserLanguage } from '@/lib/language';
import { changeLocale } from '@/lang';
import { getQueryParam } from '@/lib/route';

export default {
  name: 'SignIn',
  props: {
    header: {
      type: String,
      default: '',
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    compoentStyle: {
      type: Object,
      default: null,
    },
    showPlants: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      privacy: PRIVACY_URL,
      terms: TERMS_URL,
      imprint: IMPRINT_URL,
    };
  },
  computed: {
    lang() {
      const lang = getQueryParam(this.$route, 'lang');
      if (lang !== '') {
        return lang;
      }
      return browserLanguage();
    },
  },
  watch: {
    lang() {
      if (this.lang !== '') {
        changeLocale(this.lang, this);
      }
    },
  },
  created() {
    if (this.lang !== '') {
      changeLocale(this.lang, this);
    }
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .sign-in {
    position: relative;
    width: 100%;
    min-height: 100vh;
    background-color: $side-nav-background-color;

    ._content {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      min-height: calc(100vh - 4rem);
      padding-bottom: 4rem;

      ._header {
        padding: 2rem;
        text-align: center;
      }

      ._inner {
        width: 95%;
        max-width: 30rem;
      }

      ._plant-left {
        position: absolute;
        bottom: -4rem;
        left: 0;
      }

      ._plant-right {
        position: absolute;
        right: 0;
        bottom: -4rem;
      }
    }

    ._footer {
      position: relative;
      display: flex;
      justify-content: center;
      padding-bottom: 1rem;
      font-size: $font-size-3;
      color: $font-color-secondary;

      ._link {
        margin: 0 .8rem;
        color: $font-color-secondary;
        text-decoration: underline;
      }
    }
  }
</style>
