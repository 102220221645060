import { CREATED_AT } from 'shared/api/query/constants';
import { propertyType } from 'shared/constants.json';
import { update } from 'shared/api/query/configs.json';

export const directProperties = ($t) => ([
  {
    label: $t('general.title'),
    edgeName: update.edges.title,
    type: propertyType.text,
    isTitle: true,
    hideInProps: true,
    noFilter: true,
    showByDefault: true,
  },
  {
    label: $t('general.createdAt'),
    edgeName: CREATED_AT,
    type: propertyType.date,
    isTitle: false,
    hideInProps: true,
    noFilter: false,
    showByDefault: true,
  },
  {
    label: $t('general.creator'),
    edgeName: update.edges.creator,
    type: propertyType.user,
    isTitle: false,
    hideInProps: true,
    noFilter: false,
    noIsEmptyFilter: true,
    showByDefault: true,
  },
]);

export const directFeedProperties = ($t) => ([
  {
    label: $t('general.title'),
    edgeName: update.edges.title,
    type: propertyType.text,
    isTitle: true,
    hideInProps: false,
    noFilter: true,
    showByDefault: true,
  },
  {
    label: $t('general.createdAt'),
    edgeName: CREATED_AT,
    type: propertyType.date,
    isTitle: false,
    hideInProps: true,
    noFilter: false,
    showByDefault: false,
  },
  {
    label: $t('general.creator'),
    edgeName: update.edges.creator,
    type: propertyType.user,
    isTitle: false,
    hideInProps: true,
    noFilter: false,
    noIsEmptyFilter: true,
    showByDefault: false,
  },
]);

export const directListProperties = ($t) => ([
  {
    label: $t('general.title'),
    edgeName: update.edges.title,
    type: propertyType.text,
    isTitle: true,
    hideInProps: false,
    noFilter: true,
    showByDefault: true,
  },
  {
    label: $t('general.createdAt'),
    edgeName: CREATED_AT,
    type: propertyType.date,
    isTitle: false,
    hideInProps: false,
    noSortInProps: true,
    noFilter: false,
    showByDefault: true,
  },
  {
    label: $t('general.creator'),
    edgeName: update.edges.creator,
    type: propertyType.user,
    isTitle: false,
    hideInProps: true,
    noFilter: false,
    noIsEmptyFilter: true,
    showByDefault: true,
  },
]);

export const creatorProperty = ($t) => ({
  label: $t('general.creator'),
  edgeName: update.edges.creator,
  type: propertyType.user,
  isTitle: false,
  hideInProps: false,
  noFilter: false,
  showByDefault: true,
  model: update.model,
});
