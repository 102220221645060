import { UID } from 'shared/api/query/constants';
import { defaultTimezone } from 'shared/lib/time';
import { goalCycle as goalCycleConfig, personalAppSettings as personalAppSettingsConfig } from 'shared/api/query/configs.json';

export const personalAppSettingsEdges = [
  { attr: UID },
  { attr: personalAppSettingsConfig.edges.unassignedGoalCycleSelected, default: false },
  { attr: personalAppSettingsConfig.edges.desktopSidebarHidden, default: false },
  { attr: personalAppSettingsConfig.edges.timezone, default: defaultTimezone() },
  { attr: personalAppSettingsConfig.edges.myWorkspaceOrder, default: [] },
  { attr: personalAppSettingsConfig.edges.favoritesOrder, default: [] },
  { attr: personalAppSettingsConfig.edges.readReleaseNotes, default: [] },
  {
    attr: personalAppSettingsConfig.edges.selectedGoalCycles,
    default: [],
    model: goalCycleConfig.model,
    children: [{ attr: UID }],
  },
];
