import useSort from '@/composables/sort/sort';
import { user as userConfig } from 'shared/api/query/configs.json';

export default function useUsersSorting(properties, userSvc) {
  const attributeMap = {};
  const sortOptions = [
    { attr: userConfig.edges.firstName },
    { attr: userConfig.edges.lastName },
    { attr: userConfig.edges.email },
  ];

  const { sort } = useSort(attributeMap, properties, userConfig.edges.values, userSvc);
  return {
    sort,
    sortOptions,
  };
}
