export const getStatusType = ({ activatedAt }) => {
  if (activatedAt !== null) {
    return 'success';
  }
  return 'warning';
};

export const getStatus = ({ activatedAt, $t }) => {
  if (activatedAt !== null) {
    return $t('propertyConstants.active');
  }
  return $t('notificationItem.paused');
};
