import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useRepo from '@/nebula/repo';
import { computed } from 'vue';
import { mSTeamsConfiguration as mSTeamsConfigurationConfig } from 'shared/api/query/configs.json';

export default function useMSTeamsConfiguration() {
  const repo = useRepo(mSTeamsConfigurationConfig.model);
  const { loggedInUser, myTeams } = useLoggedInUser();

  const msTeamsConfiguration = computed(() => {
    if (loggedInUser.value === null || loggedInUser.value.msTeamsConfiguration === null) {
      return null;
    }

    return repo.selectSingle(loggedInUser.value.msTeamsConfiguration?.uid);
  });

  const selectedTeam = computed(() => {
    if (myTeams.value.length === 0) {
      return null;
    }

    if (msTeamsConfiguration.value === null) {
      return myTeams.value[0];
    }

    // selected team is removed
    if (msTeamsConfiguration.value.selectedTeam === null) {
      return myTeams.value[0];
    }

    const teamIDs = myTeams.value.map((t) => t.uid);
    // in case a user had selected a team which she is no longer part of
    if (!teamIDs.includes(msTeamsConfiguration.value.selectedTeam.uid)) {
      return myTeams.value[0];
    }

    return msTeamsConfiguration.value.selectedTeam;
  });

  const createSingle = (entity) => repo.createSingle({ ...entity, user: { uid: loggedInUser.value.uid } });

  return {
    msTeamsConfiguration,
    selectedTeam,
    createSingle,
    updateSingle: repo.updateSingle,
  };
}
