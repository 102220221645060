import EditorImage from '@/components/editor/EditorImage.vue';
import { FILE_UPLOAD_LIMIT } from '@/lib/constants';
import { Image } from '@/tiptap/extensions/nodes';
import { Plugin } from 'prosemirror-state';

export default class ImageUpload extends Image {
  constructor(options = {}) {
    super(options);
    this.uploader = options.uploader;
    this.imageTypes = options.imageTypes;
    this.snackbar = options.snackbar;
    this.translator = options.translator;
  }

  get name() {
    return 'image';
  }

  get schema() {
    return {
      inline: false,
      attrs: {
        src: {},
        size: { default: null },
        alt: { default: null },
        name: { default: null },
        image: { default: null },
        uploader: { default: null },
        autoOpen: { default: null },
      },
      group: 'block',
      draggable: true,
      parseDOM: [{
        tag: 'img[src]',
        getAttrs: (dom) => ({
          src: dom.getAttribute('src'),
          name: dom.getAttribute('name'),
          alt: dom.getAttribute('alt'),
          autoOpen: dom.getAttribute('autoOpen'),
        }),
      }],
      toDOM: (node) => ['img', node.attrs],
    };
  }

  get view() {
    return EditorImage;
  }

  get plugins() {
    const uploader = this.uploader;
    const imageTypes = this.imageTypes;
    const snackbar = this.snackbar;
    const translator = this.translator;

    return [
      new Plugin({
        props: {
          handleDOMEvents: {
            paste(view, event) {
              const items = event.clipboardData.items;
              if (typeof uploader === 'undefined') {
                return;
              }
              Array.from(items).forEach((item) => {
                const { schema } = view.state;
                const image = item.getAsFile();
                if (image === null) {
                  return;
                }
                if (!imageTypes.includes(image.type)) {
                  return;
                }
                if (image.size > FILE_UPLOAD_LIMIT) {
                  snackbar({ color: 'error', message: translator('fileUpload.fileTooBig') });
                  return;
                }
                event.preventDefault();
                const node = schema.nodes.image.create({ src: '', size: image.size, name: image.name, uploader, image });
                const transaction = view.state.tr.replaceSelectionWith(node);
                view.dispatch(transaction);
              });
            },
            drop(view, event) {
              const hasFiles = event.dataTransfer.files.length > 0;
              if (!hasFiles) {
                return;
              }

              const files = Array.from(event.dataTransfer.files);
              const images = files.filter((i) => imageTypes.includes(i.type));

              if (images.length === 0) {
                return;
              }

              if (images.length === files.length) {
                event.preventDefault();
              }

              const { schema } = view.state;
              const coordinates = view.posAtCoords({ left: event.clientX, top: event.clientY });
              let pos = coordinates.pos - 1;
              if (pos < 0) {
                pos = 0;
              }

              images.forEach((image) => {
                if (!imageTypes.includes(image.type)) {
                  return;
                }
                if (image.size > FILE_UPLOAD_LIMIT) {
                  snackbar({ color: 'error', message: translator('fileUpload.fileTooBig') });
                  return;
                }
                if (typeof uploader === 'undefined') {
                  return;
                }

                const node = schema.nodes.image.create({ src: '', size: image.size, name: image.name, uploader, image });
                const transaction = view.state.tr.insert(pos, node);
                view.dispatch(transaction);

                pos += 1;
              });
            },
          },
        },
      }),
    ];
  }
}
