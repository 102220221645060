import { RRule } from 'rrule';
import { englishStrings } from '@/lib/rrule-translations/english';
import { germanStrings } from '@/lib/rrule-translations/german';

export const rruleToText = (rule, lang) => {
  const cleanedRule = new RRule({
    freq: rule.origOptions.freq,
    bysetpos: rule.origOptions.bysetpos,
    byweekday: rule.origOptions.byweekday,
    count: rule.origOptions.count,
    interval: rule.origOptions.interval,
    until: rule.origOptions.until,
  });

  if (lang === 'de') {
    const german = germanStrings(
      rule.options.freq,
      rule.options.byweekday,
      rule.options.interval,
      rule.options.bysetpos,
    );
    return cleanedRule.toText((id) => german[id] || id, german);
  }

  const english = englishStrings(
    rule.options.freq,
    rule.options.byweekday,
    rule.options.interval,
    rule.options.bysetpos,
  );
  return cleanedRule.toText((id) => english[id] || id, english);
};
