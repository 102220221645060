<template>
  <svg
    data-icon="book"
    width="1em"
    height="1em"
    fill="currentColor"
    aria-hidden="true"
    viewBox="64 64 896 896"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M832 64H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V96c0-17.7-14.3-32-32-32zM668 345.9L621.5 312 572 347.4V124h96v221.9z"
    />
  </svg>
</template>

<script>
export default { name: 'Book' };
</script>

<style scoped lang="scss" type="text/scss">

</style>
