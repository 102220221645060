import { equalP } from 'shared/lib/array/array';

export const directPlanningsFn = ({ isFilterMode = false }) => ({ entity, scope }) => {
  let selectedValues = entity[scope.directProperty.edgeName];
  if (selectedValues === undefined) {
    return false;
  }
  if (selectedValues === null) {
    return scope.isEmpty === true;
  }
  if (!Array.isArray(selectedValues)) {
    selectedValues = [selectedValues];
  }

  if (scope.isEmpty) {
    return selectedValues.length === 0;
  }

  const neededValues = scope.plannings.map((o) => ({ uid: o.value }));
  if (neededValues.length === 0 && isFilterMode) {
    return true;
  }
  return equalP({ a: neededValues, b: selectedValues });
};
