<template>
  <space-selector
    v-if="isTypeSpace"
    ref="input"
    :value="selectedSpaces"
    :read-only="readOnly"
    :disabled="disabled"
    :property-label="property.label"
    :hide-border="hideBorder"
    :hide-hover="hideHover"
    :hide-placeholder="hidePlaceholder"
    :placeholder="placeholder"
    :placeholder-icon="showPlaceholderIcon ? property.icon : ''"
    :full-width="fullWidth"
    :small="small"
    :m-style="mStyle"
    hide-arrow
    :restrict-foreign-entity-selection="restrictForeignEntitySelection"
    :placement="popup ? 'onTopLeft' : 'bottomCenter'"
    :match-trigger-width="matchTriggerWidth"
    :wrap="wrap"
    multiple
    :auto-focus="autoFocus"
    @input="changeSelectedSpaces"
    @close="$emit('blur')"
  />
  <m-select
    v-else-if="isTypeOptions"
    ref="input"
    :placeholder-icon="showPlaceholderIcon ? property.icon : ''"
    :value="selectedOptions"
    :placeholder="placeholder"
    :hide-placeholder="hidePlaceholder"
    :items="options"
    :nowrap="!wrap"
    :m-style="mStyle"
    :hide-hover="hideHover"
    item-text="label.de"
    multiple
    :small="small"
    value-key="uid"
    return-object
    hide-arrow
    :clearable="clearable"
    :read-only="readOnly"
    :disabled="disabled"
    :hide-border="hideBorder"
    :full-width="fullWidth"
    :popup="popup"
    automatic-color
    show-search
    keep-open-on-click
    show-description
    :match-trigger-width="matchTriggerWidth"
    tags
    :auto-focus="autoFocus"
    @change="changeSelectedOptions"
    @close="$emit('blur')"
  />
  <m-select
    v-if="isTypeSingleSelect"
    ref="input"
    :value="selectedOption"
    :m-style="mStyle"
    :placeholder="placeholder"
    :placeholder-icon="showPlaceholderIcon ? property.icon : ''"
    :hide-placeholder="hidePlaceholder"
    :items="options"
    item-text="label.de"
    value-key="uid"
    return-object
    hide-arrow
    :nowrap="!wrap"
    :hide-hover="hideHover"
    :clearable="clearable"
    :read-only="readOnly"
    :disabled="disabled"
    :hide-border="hideBorder"
    :full-width="fullWidth"
    show-search
    :popup="popup"
    :small="small"
    hide-selected-values
    show-description
    :auto-focus="autoFocus"
    :match-trigger-width="matchTriggerWidth"
    tags
    @change="changeSelectedOption"
    @close="$emit('blur')"
  />
  <m-input-number
    v-else-if="isTypeNumber"
    ref="input"
    :value="number"
    :m-style="mStyle"
    :placeholder="placeholder"
    :placeholder-icon="showPlaceholderIcon ? property.icon : ''"
    :allow-clear="clearable"
    :read-only="readOnly"
    :disabled="disabled"
    :small="small"
    :hide-border="hideBorder"
    :full-width="fullWidth"
    :hide-hover="hideHover"
    :hide-placeholder="hidePlaceholder"
    :formatter="formatter"
    :parser="parser"
    :auto-focus="autoFocus"
    nullable
    @input="changeNumber"
    @blur="$emit('blur')"
  />
  <m-textarea
    v-else-if="isTypeText"
    ref="input"
    :value="text"
    :m-style="mStyle"
    :placeholder="placeholder"
    :placeholder-icon="showPlaceholderIcon ? property.icon : ''"
    :small="small"
    :allow-clear="clearable"
    :read-only="readOnly"
    :disabled="disabled"
    :hide-hover="hideHover"
    :hide-placeholder="hidePlaceholder"
    :hide-border="hideBorder"
    :full-width="fullWidth"
    auto-size
    :auto-focus="autoFocus"
    @change="changeText"
    @blur="$emit('blur')"
  />
  <m-url-field
    v-else-if="isTypeUrl"
    ref="input"
    :value="url"
    :m-style="mStyle"
    :placeholder="placeholder"
    :placeholder-icon="showPlaceholderIcon ? property.icon : ''"
    :allow-clear="clearable"
    :small="small"
    :read-only="readOnly"
    :disabled="disabled"
    :hide-hover="hideHover"
    :hide-placeholder="hidePlaceholder"
    :hide-border="hideBorder"
    :full-width="fullWidth"
    :auto-focus="autoFocus"
    @change="changeUrl"
    @blur="$emit('blur')"
  />
  <m-date-picker
    v-else-if="isTypeDate"
    ref="input"
    :small="small"
    :m-style="mStyle"
    :value="fromISO(date)"
    :placeholder="placeholder"
    :placeholder-icon="showPlaceholderIcon ? property.icon : ''"
    :clearable="clearable"
    :date-time="DateTime"
    :read-only="readOnly"
    :disabled="disabled"
    :hide-border="hideBorder"
    :hide-hover="hideHover"
    :hide-placeholder="hidePlaceholder"
    :full-width="fullWidth"
    :auto-focus="autoFocus"
    :locale="userLang"
    include-time
    @change="changeDate"
    @close="$emit('blur')"
  />
  <user-picker
    v-else-if="isTypeUser"
    ref="input"
    :m-style="mStyle"
    :value="selectedUsers"
    :placeholder="placeholder"
    :placeholder-icon="showPlaceholderIcon ? property.icon : ''"
    :hide-placeholder="hidePlaceholder"
    :prepend-icon="icon"
    :read-only="readOnly"
    :disabled="disabled"
    :hide-border="hideBorder"
    :hide-hover="hideHover"
    :small="small"
    :nowrap="!wrap"
    :full-width="fullWidth"
    :user-details-page="readOnly ? '' : PROFILE"
    :popup="popup"
    :match-trigger-width="matchTriggerWidth"
    hide-arrow
    :auto-focus="autoFocus"
    :restrict-foreign-entity-selection="restrictForeignEntitySelection"
    @change="changeSelectedUser"
    @close="$emit('blur')"
  />
  <lookup-value
    v-else-if="isTypeLookup"
    :hide-border="hideBorder"
    :m-style="mStyle"
    :placeholder="placeholder"
    :small="small"
    :values="propertyValues"
    :hide-placeholder="hidePlaceholder"
    :lookup-property="property"
    :full-width="fullWidth"
    :wrap="wrap"
    show-user-name
    show-empty-value
  />
</template>

<script>
import LookupValue from '@/components/property/LookupValue.vue';
import SpaceSelector from '@/components/spaces/SpaceSelector.vue';
import UserPicker from '@/components/UserPicker.vue';
import useDebounce from '@/composables/debounce';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useProperties from '@/composables/property/property';
import useResourceSettings from '@/composables/logged-in-user-account/resource-settings';
import { DateTime } from 'luxon';
import { PROFILE } from '@/route-names';
import { buildIconFromEntity } from 'shared/lib/icon';
import { emptyValue, iconByType } from '@/lib/property';
import { fromISO, toISO } from 'shared/lib/time';
import { mStyleProps } from 'shared/lib/m-style-props';
import { numberFormatter, numberParser } from '@/lib/props/number';
import { propertyType } from 'shared/constants.json';
import { sortedPropertyElements } from '@/lib/props';

export default {
  name: 'PropertyFormItem',
  props: {
    ...mStyleProps,
    property: {
      type: Object,
      required: true,
    },
    propertyValues: {
      type: Array,
      required: true,
    },
    wrap: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    hideHover: {
      type: Boolean,
      default: false,
    },
    matchTriggerWidth: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    hideBorder: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    autoFocus: {
      type: Boolean,
      default: false,
    },
    hidePlaceholder: {
      type: Boolean,
      default: false,
    },
    showPlaceholderIcon: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    restrictForeignEntitySelection: {
      type: Boolean,
      default: false,
    },
    popup: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['change', 'blur'],
  components: { SpaceSelector, UserPicker, LookupValue },
  setup() {
    const { userLang } = useLoggedInUser();
    const { resourceSettings } = useResourceSettings();
    const propertySvc = useProperties();
    const { debounce } = useDebounce({ onBeforeUnmountFlush: true });
    return { debounce, resourceSettings, propertySvc, userLang };
  },
  data() {
    return {
      PROFILE,
      DateTime,
      text: '',
      url: '',
      fromISO,
    };
  },
  computed: {
    value() {
      const value = this.propertyValues.find((item) => item.property.uid === this.property.uid);
      if (value === undefined) {
        return emptyValue(this.property);
      }
      return value;
    },
    formatter() {
      if (this.property.type !== propertyType.number || this.value.number === null) {
        return (value) => value;
      }
      return numberFormatter(this.property.numberFormat, this.userLang);
    },
    parser() {
      if (this.property.type !== propertyType.number || this.value.number === null) {
        return (value) => value;
      }
      return numberParser(this.property.numberFormat, this.userLang);
    },
    icon() {
      return iconByType(this.property);
    },
    options() {
      return this.property.options.map((o) => ({ ...o, icon: buildIconFromEntity(o) }));
    },
    selectedOptions() {
      if (this.value === null || typeof this.value.selectedOptions === 'undefined') {
        return [];
      }
      const property = this.propertySvc.selectSingle(this.value.property.uid);
      return sortedPropertyElements(this.value.selectedOptions, property.propertyOptionOrder);
    },
    selectedOption() {
      if (this.value === null
        || typeof this.value.selectedOptions === 'undefined'
        || this.value.selectedOptions.length === 0
      ) {
        return null;
      }
      return this.value.selectedOptions[0];
    },
    selectedSpaces() {
      if (this.value === null || typeof this.value.spaces === 'undefined') {
        return [];
      }
      return sortedPropertyElements(this.value.spaces, this.resourceSettings.spaceOrder.map(({ uid }) => uid));
    },
    selectedUsers() {
      if (this.value === null || typeof this.value.users === 'undefined') {
        return [];
      }
      return this.value.users;
    },
    number() {
      if (this.value === null || typeof this.value.number === 'undefined') {
        return null;
      }
      return this.value.number;
    },
    date() {
      if (this.value === null || typeof this.value.timestamp === 'undefined') {
        return null;
      }
      return this.value.timestamp;
    },
    isTypeDate() {
      return this.property.type === propertyType.date;
    },
    isTypeSpace() {
      return this.property.type === propertyType.space;
    },
    isTypeOptions() {
      return this.property.type === propertyType.options;
    },
    isTypeNumber() {
      return this.property.type === propertyType.number;
    },
    isTypeText() {
      return this.property.type === propertyType.text;
    },
    isTypeUrl() {
      return this.property.type === propertyType.url;
    },
    isTypeSingleSelect() {
      return this.property.type === propertyType.singleSelect;
    },
    isTypeUser() {
      return this.property.type === propertyType.user;
    },
    isTypeLookup() {
      return this.property.type === propertyType.lookup;
    },
  },
  methods: {
    focus() {
      if (this.isTypeDate) {
        this.$refs.input.open();
      }
      if (this.isTypeNumber) {
        this.$refs.input.focus();
      }
      if (this.isTypeText) {
        this.$refs.input.focus();
      }
      if (this.isTypeUrl) {
        this.$refs.input.focus();
      }
      if (this.isTypeSpace) {
        this.$refs.input.show();
      }
      if (this.isTypeOptions) {
        this.$refs.input.show();
      }
      if (this.isTypeSingleSelect) {
        this.$refs.input.show();
      }
      if (this.isTypeUser) {
        this.$refs.input.show();
      }
    },
    changeSelectedOptions(value) {
      this.$emit('change', { uid: this.value.uid, property: { uid: this.property.uid }, selectedOptions: value.map((v) => ({ uid: v.uid })) });
    },
    changeSelectedOption(value) {
      let selectedOptions = [];
      if (value !== null) {
        selectedOptions = [{ uid: value.uid }];
      }
      this.$emit('change', { uid: this.value.uid, property: { uid: this.property.uid }, selectedOptions });
    },
    changeSelectedSpaces(value) {
      this.$emit('change', { ...this.value, property: this.property, spaces: value.map((s) => ({ uid: s.uid })) });
    },
    changeSelectedUser(value) {
      this.$emit('change', { uid: this.value.uid, property: { uid: this.property.uid }, users: value.map((v) => ({ uid: v.uid })) });
    },
    changeText(value) {
      this.text = value;
      const update = () => this.$emit('change', { uid: this.value.uid, property: { uid: this.property.uid }, text: value });
      this.debounce(update, 500, 'text');
    },
    changeUrl(value) {
      this.url = value;
      const update = () => this.$emit('change', { uid: this.value.uid, property: { uid: this.property.uid }, text: value });
      this.debounce(update, 500, 'url');
    },
    changeNumber(value) {
      this.$emit('change', { uid: this.value.uid, property: { uid: this.property.uid }, number: value });
    },
    changeDate(value) {
      this.$emit('change', { uid: this.value.uid, property: { uid: this.property.uid }, timestamp: toISO(value) });
    },
    initText() {
      if (this.value === null || typeof this.value.text === 'undefined') {
        this.text = null;
      }
      this.text = this.value.text;
    },
    initUrl() {
      if (this.value === null || typeof this.value.text === 'undefined') {
        this.url = null;
      }
      this.url = this.value.text;
    },
  },
  created() {
    if (this.isTypeText) {
      this.initText();
    }
    if (this.isTypeUrl) {
      this.initUrl();
    }
  },
};
</script>

<style scoped lang="scss" type="text/scss">
</style>
