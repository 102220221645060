import { RESULT } from 'shared/api/query/constants';

export const newExpectSingle = (next) => (queries) => next(queries)
  .then((response) => {
    if (typeof response === 'undefined' || response.status !== 200) {
      return response;
    }

    if (response.data[RESULT].length === 0) {
      response.data = null;
      return response;
    }

    if (Object.keys(response.data[RESULT][0]).length === 1) {
      response.status = 404;
      return response;
    }

    return {
      ...response,
      data: response.data[RESULT][0],
    };
  })

  .catch((error) => error);
