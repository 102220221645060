<template>
  <component
    :is="tag"
    :class="classes"
    :style="{ ...buttonStyle, color: fontColor }"
    v-bind="link"
    :target="target"
    :rel="rel"
    @mouseover="hover = true"
    @mouseleave="hover = false"
    @click="click"
    @keydown.enter="click"
  >
    <m-icon
      v-if="icon !== ''"
      :type="loading ? 'loading' : icon"
      :color="iColor"
      :size="sizeOfIcon"
      :spin="loading"
      class="_pre-icon"
    />
    <slot />
  </component>
</template>

<script>
export default {
  name: 'MBtn',
  props: {
    round: {
      type: Boolean,
      default: false,
    },
    fab: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    buttonStyle: {
      type: Object,
      default: () => null,
    },
    icon: {
      type: String,
      default: '',
    },
    hasInnerIcon: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    hideBorder: {
      type: Boolean,
      default: false,
    },
    light: {
      type: Boolean,
      default: false,
    },
    superLight: {
      type: Boolean,
      default: false,
    },
    xs: {
      type: Boolean,
      default: false,
    },
    xxs: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    to: {
      type: [Object, String],
      default: '',
    },
    href: {
      type: String,
      default: '',
    },
    target: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    iconSize: {
      type: String,
      default: '',
    },
    iconColor: {
      type: String,
      default: '',
    },
    forceIconColor: {
      type: Boolean,
      default: false,
    },
    alignLeft: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  data() {
    return { hover: false };
  },
  computed: {
    sizeOfIcon() {
      if (this.iconSize !== '') {
        return this.iconSize;
      }

      if (this.xxs) {
        return '9';
      }
      if (this.xs) {
        return '12';
      }
      if (this.small) {
        return '15';
      }
      return '16';
    },
    tag() {
      if (this.to !== '') {
        return 'router-link';
      }

      if (this.href !== '') {
        return 'a';
      }

      return 'button';
    },
    link() {
      if (this.to !== '') {
        return { to: this.to };
      }
      if (this.href !== '') {
        return { href: this.href };
      }
      return {};
    },
    classes() {
      return [
        'm-btn',
        this.round ? '-round' : '',
        this.to !== '' || this.href !== '' ? '-link' : '',
        this.light ? '-light' : '',
        this.superLight ? '-super-light' : '',
        this.hideBorder ? '-hide-border' : '',
        this.outlined ? '-outlined' : '',
        this.fab ? '-fab' : '',
        this.xxs ? '-xxs' : '',
        this.xs ? '-xs' : '',
        this.small ? '-small' : '',
        this.large ? '-large' : '',
        this.color === 'primary' ? '-primary' : '',
        this.color === 'secondary' ? '-secondary' : '',
        this.color === 'danger' ? '-danger' : '',
        this.color === 'warning' ? '-warning' : '',
        this.block ? '-block' : '',
        this.disabled ? '-disabled' : '',
        this.disabled ? '' : '-clickable',
        this.loading ? '-loading' : '',
        this.$store.state.breakpoint.smAndDown ? '-mobile' : '',
        this.alignLeft ? '-left-align' : '',
      ];
    },
    iColor() {
      if (this.forceIconColor || this.iconColor !== '') {
        return this.iconColor;
      }

      if (this.disabled) {
        return this.$colors.grey.lighten2;
      }

      if (this.iconColor === '') {
        return this.fontColor;
      }

      return this.iconColor;
    },
    fontColor() {
      if (this.outlined) {
        if (this.hover && ['primary', 'danger', 'warning'].includes(this.color)) {
          return 'white';
        }
        switch (this.color) {
          case 'primary':
            return this.$colors.blue.darken1;
          case 'danger':
            return this.$colors.red.base;
          case 'warning':
            return this.$colors.yellow.base;
          default:
        }
      }
      if (this.hideBorder) {
        switch (this.color) {
          case 'primary':
            return this.$colors.blue.darken1;
          case 'danger':
            return this.$colors.red.base;
          case 'warning':
            return this.$colors.yellow.base;
          default:
        }
      }

      if (this.superLight) {
        return this.$colors.grey.lighten1;
      }

      if (this.light) {
        return this.$colors.grey.base;
      }

      switch (this.color) {
        case 'primary':
          return 'white';
        case 'danger':
          return 'white';
        case 'warning':
          return 'white';
        default:
          return this.$colors.grey.darken4;
      }
    },
    rel() {
      if (this.tag !== 'a') {
        return null;
      }

      if (this.target === '_blank') {
        return 'noopener';
      }
      return null;
    },
  },
  methods: {
    click(event) {
      if (this.disabled) {
        event.stopPropagation();
        return;
      }
      this.$emit('click', event);
    },
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  .m-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3.2rem;
    padding: 0 1.5rem;
    color: $font-color-primary;
    white-space: nowrap;
    user-select: none;
    background-color: transparent;
    border: 1px solid $input-border-color;
    border-radius: $input-field-border-radius;
    outline: none;

    &:focus-visible {
      background-color: $hover-color;
      outline: none;
    }

    &.-clickable {
      cursor: pointer;
    }

    &.-link {
      &:not(.-block) {
        display: inline-flex;
      }
    }

    &.-fab {
      width: 3.2rem;
      padding: 0;
    }

    &.-xxs {
      height: 2rem;
      padding: 0 .4rem;

      &.-fab {
        width: 2rem;
        padding: 0;
      }
    }

    &.-xs {
      height: 2.2rem;
      padding: 0 .6rem;

      &.-fab {
        width: 2.2rem;
        padding: 0;
      }
    }

    &.-small {
      height: 2.8rem;
      padding: 0 .8rem;

      &.-fab {
        width: 2.8rem;
        padding: 0;
      }
    }

    &.-large {
      height: 4rem;

      &.-fab {
        width: 4rem;
        padding: 0;
      }
    }

    &.-primary {
      color: white;
      background-color: $primary-color;

      &:hover {
        &:not(.-mobile) {
          background-color: darken($primary-color, 10%);
        }
      }
    }

    &.-secondary {
      color: $font-color-primary;
      background-color: white;

      &:hover {
        &:not(.-mobile) {
          // TODO: Fix the solidity of the secondary hover, usage of grey is complicated because it's always somewhat transparent
          background-color: $hover-color;
        }
      }
    }

    &.-light {
      color: $font-color-secondary;
    }

    &.-super-light {
      color: $font-color-secondary;
    }

    &.-danger {
      color: white;
      background-color: $error-color;

      &:hover {
        &:not(.-mobile) {
          background-color: darken($error-color, 10%);
        }
      }

      &.-hide-border {
        background-color: transparent;

        &:hover {
          &:not(.-mobile) {
            background-color: map_get($red, 'lighten-5');
          }
        }
      }
    }

    &.-warning {
      color: white;
      background-color: $warning-color;

      &:hover {
        &:not(.-mobile) {
          background-color: darken($warning-color, 10%);
        }
      }

      &.-hide-border {
        background-color: transparent;

        &:hover {
          &:not(.-mobile) {
            background-color: map_get($yellow, 'lighten-4');
          }
        }
      }
    }

    &.-outlined {
      &.-primary {
        color: $error-color;
        background-color: transparent;
        border: 1px solid $primary-color;

        &:hover {
          color: white;
          background-color: $primary-color;
        }
      }

      &.-danger {
        color: $error-color;
        background-color: transparent;
        border: 1px solid $error-color;

        &:hover {
          color: white;
          background-color: $error-color;
        }
      }

      &.-warning {
        color: $warning-color;
        background-color: transparent;
        border: 1px solid $warning-color;

        &:hover {
          color: white;
          background-color: $warning-color;
        }
      }
    }

    &.-block {
      width: 100%;
    }

    &.-hide-border {
      border: none;

      &.-primary {
        color: $primary-color;
        background-color: transparent;

        &:hover {
          &:not(.-mobile) {
            color: map_get($blue, 'darken-1');
            background-color: map_get($blue, 'lighten-4');
          }
        }
      }

      &.-disabled {
        color: $font-color-tertiary;
        background-color: transparent;
      }
    }

    &.-round {
      border-radius: 1.6rem;

      &.-small {
        border-radius: 1.3rem;
      }
    }

    &:not(.-fab) {
      ._pre-icon {
        margin-right: .4rem;
      }
    }

    &:hover {
      &:not(.-mobile) {
        background-color: $hover-color;
      }
    }

    &.-left-align {
      justify-content: left;
    }

    &.-disabled,
    &.-loading {
      color: $font-color-secondary;

      // TODO: Remove pointer-events and adjust :hover as it gets handled via script
      pointer-events: none;
      cursor: not-allowed;
      background-color: map_get($grey, 'lighten-4');

      &:hover {
        &:not(.-mobile) {
          background-color: map_get($grey, 'lighten-4');
        }
      }
    }
  }
</style>
