<template>
  <div class="space-editor">
    <m-content
      v-if="!isCreate"
      class="_header"
      :padding-x="11"
    >
      <m-tab
        v-for="tab in tabs"
        :key="tab.value"
        :title="tab.text"
        :active="selectedTab === tab.value"
        small
        @click="selectedTab = tab.value"
      />
      <m-divider none />
    </m-content>
    <scroll-container
      :class="['_body', isCreate ? '-create' : '-update']"
      no-padding-bottom
    >
      <m-content :padding-x="11">
        <div
          v-if="selectedTab === 'general'"
          class="_general"
        >
          <space-general-form
            v-model:space="space"
            :read-only="!isCreate && !writeAccess"
            :with-archived="withArchived"
            @enter="submit"
          />
          <m-form-item class="_actions">
            <m-btn
              small
              super-light
              :href="$t('spaceList.helpCenterLink')"
              hide-border
              icon="question-circle"
              target="_blank"
            >
              {{ $t('spaceList.learnMore') }}
            </m-btn>
            <m-tooltip
              :disabled="formIsValid"
            >
              <m-btn
                v-if="isCreate || writeAccess"
                color="primary"
                :loading="submitLoading"
                :disabled="loading || !formIsValid"
                @click="submit"
              >
                <template v-if="isCreate">
                  {{ $t('general.create') }}
                </template>
                <template v-else>
                  {{ $t('general.update') }}
                </template>
              </m-btn>
              <template #title>
                {{ formValidationHint }}
              </template>
            </m-tooltip>
          </m-form-item>
          <m-form-item
            v-if="fullAccess"
            class="_danger-actions _form-item"
            :label="$t('spaceEditor.dangerZoneLabel')"
          >
            <m-btn-group direction="vertical">
              <m-btn
                class="_btn"
                icon="archive"
                :loading="archiveLoading"
                :disabled="loading"
                @click="toggleArchive"
              >
                <div class="_message">
                  <div class="_text">
                    {{ !isArchived ? $t('spaceEditor.archiveText') : $t('spaceEditor.restoreText') }}
                  </div>
                  <div class="_subtext">
                    {{ !isArchived ? $t('spaceEditor.archiveSubtext') : $t('spaceEditor.restoreSubtext') }}
                  </div>
                </div>
              </m-btn>
              <m-btn
                v-if="space.isNotDeletable === false"
                class="_btn"
                icon="delete"
                :icon-color="colors.red.base"
                :loading="deleteSpacesLoading"
                :disabled="loading"
                @click="showDeleteModal"
              >
                <div class="_message">
                  <div class="_text -delete">
                    {{ $t('spaceEditor.deleteText') }}
                  </div>
                  <div class="_subtext">
                    {{ $t('spaceEditor.deleteSubtext') }}
                  </div>
                </div>
              </m-btn>
            </m-btn-group>
          </m-form-item>
        </div>
        <m-content
          v-if="selectedTab === 'members'"
          class="_members"
          :padding-bottom="11"
        >
          <list-header class="_list-header">
            <template #left>
              <m-dropdown
                v-if="fullAccess"
                v-model:value="showSpaceMembershipSelector"
                :title="$t('spaceMembers.addMembers')"
              >
                <m-btn>
                  {{ $t('spaceMembers.addMembers') }}
                </m-btn>
                <template #overlay>
                  <space-membership-selector
                    :space="space"
                    :disabled="loading.value"
                    @select="handleInviteMembers"
                  />
                </template>
              </m-dropdown>
            </template>
            <template #right>
              <list-string-filter
                small
                :disabled="loading.value"
                @input="val => memberSearch = val"
              />
            </template>
          </list-header>
          <m-table
            :columns="columns"
            :data-source="rows"
            class="_table"
            hide-vertical-border
            row-key="user.uid"
            :pagination="{ pageSize: 7 }"
          >
            <template #user="{ user }">
              <user-display
                :user="user"
                xl
              >
                <template #subName>
                  <div class="_email">
                    {{ user.email }}
                  </div>
                </template>
              </user-display>
            </template>
            <template #role="{ row }">
              <m-select
                :items="roles"
                :value="role(row)"
                hide-border
                :read-only="!fullAccess"
                item-text="value.text"
                small
                has-custom-item
                @item-click="clickRole(row, $event)"
                @change="changeRole(row, $event)"
              >
                <template #item="item">
                  <div :style="item.item.type === 'remove' ? { color: colors.red.base } : {}">
                    {{ item.item.text }}
                  </div>
                </template>
              </m-select>
            </template>
          </m-table>
        </m-content>
      </m-content>
    </scroll-container>
  </div>
</template>

<script setup>
import ListHeader from '@/components/list/ListHeader.vue';
import ListStringFilter from '@/components/list/ListStringFilter.vue';
import ScrollContainer from '@/components/page/ScrollContainer.vue';
import SpaceGeneralForm from '@/components/spaces/SpaceGeneralForm.vue';
import SpaceMembershipSelector from '@/components/spaces/SpaceMembershipSelector.vue';
import UserDisplay from 'shared/components/UserDisplay.vue';
import colors from 'shared/colors';
import useConfirmDialog from '@/composables/confirm-dialog';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useSnackBar from '@/composables/snackbar';
import useSpace from '@/composables/space/space';
import useSpaces from '@/composables/space/spaces';
import useUser from 'shared/composables/user';
import { DateTime } from 'luxon';
import { accessPolicyType, optionColor } from 'shared/constants.json';
import { computed, ref, watch } from 'vue';
import { findInArray } from 'shared/lib/array/array';
import { isEmpty } from 'shared/lib/object/object';
import { logCatch } from '@/lib/logger/logger';
import { sortByAttr } from 'shared/lib/sort';
import { textByLang } from 'shared/lib/language';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  space: {
    type: Object,
    default: () => {},
  },
  withArchived: {
    type: Boolean,
    default: false,
  },
});

const { t } = useI18n();
const snackbar = useSnackBar();
const spacesSvc = useSpaces();
const emit = defineEmits(['created', 'updated', 'archived', 'deleted', 'restored', 'close']);

const emptySpace = {
  title: '',
  color: optionColor.default,
  parents: [],
  description: null,
};

const space = ref({
  ...emptySpace,
  ...props.space,
});
watch(() => props.space, (s) => {
  if (s === undefined) {
    return;
  }
  space.value.accessRight = s.accessRight;
  space.value.properties = s.properties;
}, { deep: true });

const { loggedInUser, userLang } = useLoggedInUser();
const spaceSvc = useSpace(space);
const spaceMembers = computed(() => spaceSvc.members.value);

const memberSearch = ref('');
const rows = computed(() => spaceMembers.value.filter((member) => {
  const tokens = memberSearch.value.toLowerCase().split(' ').filter((t) => t !== '');
  if (tokens.length === 0) {
    return true;
  }
  for (let i = 0; i < tokens.length; i++) {
    if (`${member.user.firstName}`.toLowerCase().includes(tokens[i])
    || `${member.user.lastName}`.toLowerCase().includes(tokens[i])
    || `${member.user.email}`.toLowerCase().includes(tokens[i])) {
      return true;
    }
  }
  return false;
}));

const columns = [
  {
    key: 'user',
    dataIndex: 'user',
    title: t('spaceMembers.header.name'),
    sorter: sortByAttr('user', (a, b) => {
      const userA = useUser(ref(a));
      const userB = useUser(ref(b));
      return userA.displayName.value.localeCompare(userB.displayName.value);
    }),
    scopedSlots: { customRender: 'user' },
  },
  {
    key: 'role',
    dataIndex: 'role',
    title: t('spaceMembers.header.role'),
    sorter: sortByAttr('role', sortByAttr('label', (a, b) => textByLang(a, userLang).localeCompare(b, userLang))),
    scopedSlots: { customRender: 'role' },
  },
];

const showSpaceMembershipSelector = ref(false);
const handleInviteMembers = (invitees) => {
  if (invitees.length > 0) {
    spaceSvc.addMembers(invitees).then(() => {
      snackbar.success(t('success.added'));
    }).catch(logCatch(() => {
      snackbar.error(t('error.default'));
    }));
  }
  showSpaceMembershipSelector.value = false;
};
const propertyRole = (p) => ({ text: textByLang(p.label, userLang), type: 'property', property: { uid: p.uid } });
const memberRole = { text: t('spaceMembers.memberRole'), type: 'member' };
const removeRole = { text: t('general.remove'), type: 'remove' };
const roles = [
  ...spaceSvc.roles.value.map((p) => ({ value: propertyRole(p) })),
  { value: memberRole },
  { value: removeRole, selectable: false },
];
const role = (member) => {
  if (member.role === null) {
    return memberRole;
  }
  return propertyRole(member.role);
};

const confirmDialog = useConfirmDialog();
const clickRole = (member, value) => {
  if (value.type === 'remove') {
    changeRole(member, value);
  }
};
const changeRole = (member, value) => {
  const addNewRole = () => {
    if (value.type === 'member') {
      return new Promise((resolve) => { resolve(); });
    }
    return spaceSvc.toggleRole(member, value.property);
  };
  const removeOldRole = () => {
    if (member.role === null) {
      return new Promise((resolve) => { resolve(); });
    }
    return spaceSvc.toggleRole(member, member.role);
  };

  const replaceRole = () => removeOldRole().then(addNewRole).catch(logCatch(() => {
    snackbar.error(t('error.default'));
  }));
  const removeMember = () => removeOldRole().then(() => spaceSvc.removeMember(member)).catch(logCatch(() => {
    snackbar.error(t('error.default'));
  }));

  if (value.type === 'remove') {
    confirmDialog.confirm({
      title: t('spaceMembers.removeMemberPrompt'),
      okText: t('general.yesRemove'),
      cancelText: t('general.cancel'),
      onOk: removeMember,
    });
    return;
  }

  if (member.user.uid === loggedInUser.value.uid && member.role !== null) {
    confirmDialog.confirm({
      title: t('spaceMembers.removeRolePrompt'),
      okText: t('general.yesRemove'),
      cancelText: t('general.cancel'),
      onOk: replaceRole,
    });
    return;
  }
  replaceRole();
};

const tabs = computed(() => [
  {
    text: t('spaceEditor.generalTab'),
    value: 'general',
  },
  {
    text: t('spaceEditor.membersTab', { count: spaceMembers.value.length }),
    value: 'members',
  },
]);
const selectedTab = ref(tabs.value[0].value);

const isCreate = computed(() => space.value.uid === undefined);
const writeAccess = computed(() => [accessPolicyType.write, accessPolicyType.full].includes(space.value.accessRight));
const fullAccess = computed(() => [accessPolicyType.full].includes(space.value.accessRight));

const { createSpaceLoading, updateSpaceLoading, deleteSpacesLoading } = spacesSvc;
const submitLoading = computed(() => (createSpaceLoading.value || updateSpaceLoading.value) && !archiveLoading.value);
const loading = computed(() => createSpaceLoading.value || updateSpaceLoading.value || deleteSpacesLoading.value || archiveLoading.value);

const titleIsEmpty = computed(() => isEmpty(space.value.title));
const titleAlreadyExists = computed(() => {
  const otherTeam = findInArray({ haystack: spacesSvc.allSpaces.value, needle: space.value.title, key: 'title' });
  return otherTeam !== null && otherTeam.uid !== space.value.uid;
});
const formIsValid = computed(() => !titleIsEmpty.value && !titleAlreadyExists.value);
const formValidationHint = computed(() => {
  if (formIsValid.value === true) {
    return '';
  }
  if (titleIsEmpty.value === true) {
    return t('spaceEditor.validation.titleIsEmpty');
  }
  if (titleAlreadyExists.value === true) {
    return t('spaceEditor.validation.titleAlreadyExists');
  }
  return '';
});
const submit = () => {
  if (isCreate.value) {
    create(space.value);
    return;
  }
  update(space.value);
};
const create = (space) => {
  spacesSvc.createSpace(space).then((data) => {
    emit('created', data);
  }).catch(logCatch(() => {
    snackbar.error();
  }));
};
const update = (space) => {
  spacesSvc.updateSpace(space, { ignoreResponse: false }).then((data) => {
    emit('updated', data);
  }).catch(logCatch(() => {
    snackbar.error();
  }));
};

const isArchived = computed(() => space.value.archivedAt !== null);
const archiveLoading = ref(false);
const toggleArchive = () => {
  archiveLoading.value = true;
  let fn = handleArchive;
  if (isArchived.value) {
    fn = handleRestore;
  }
  return fn().finally(() => {
    archiveLoading.value = false;
  });
};
const handleArchive = () => spacesSvc.updateSpace({ uid: space.value.uid, archivedAt: DateTime.local().toISO() }).then((res) => {
  space.value.archivedAt = res.archivedAt;
  snackbar.success(t('success.archived'));
  emit('archived');
});
const handleRestore = () => spacesSvc.updateSpace({ uid: space.value.uid, archivedAt: null }).then((res) => {
  space.value.archivedAt = res.archivedAt;
  snackbar.success(t('success.restored'));
  emit('restored');
});

const showDeleteModal = () => {
  const handleDelete = () => spacesSvc.deleteSpace(space.value).then(() => {
    snackbar.success(t('success.deleted'));
    emit('deleted');
  });

  confirmDialog.confirm({
    title: t('spaceEditor.deleteModal.title', { optionTitle: space.value.title }),
    okText: t('spaceEditor.deleteModal.okText'),
    message: t('spaceEditor.deleteModal.message'),
    secure: {
      question: t('spaceEditor.deleteModal.securityQuestion'),
      answer: space.value.title,
    },
    onOk() {
      handleDelete();
    },
  });
};
</script>

<style lang="scss" type="text/scss">
  .space-editor {
    ._header {
      margin-bottom: 2rem;
    }

    ._body {
      &.-update {
        height: 52rem;
      }
    }

    ._general {
      ._actions {
        display: flex;
        justify-content: space-between;
      }

      ._danger-actions{
        &._form-item {
          ._label{
            color: $font-color-primary;
            font-weight: $font-weight-medium;
          }
        }

        ._btn {
          height: 100%;
        }

        ._message {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          white-space: normal;
          text-align: start;
          padding: 1rem;
          width: 100%;
          color: $font-color-secondary;

          ._text {
            color: $font-color-primary;

            &.-delete {
              color: $error-color;
            }
          }

          ._subtext {
            font-size: $font-size-2;
          }
        }
      }
    }

    ._members {
      ._list-header {
        margin-bottom: 1rem;
      }

      ._row {
        display: flex;
        justify-content: space-between;
        padding: .4rem 0;
      }

      ._email {
        max-width: 27rem;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

</style>
