import editGoalTree from '@/assets/media/edit-goal-tree-view.mp4';
import handAndSelectTool from '@/assets/media/hand-and-select-tool.mp4';
import navigatingTreeView from '@/assets/media/navigating-tree-view.mp4';
import viewControls from '@/assets/media/view-controls.mp4';
import { EventBus } from '@/lib/event-bus';
import { onboardingSteps } from 'shared/constants.json';
import { useI18n } from 'vue-i18n';

export default function useTreeViewSlideShow(onboardingMarker) {
  const { t } = useI18n();
  const slides = [
    {
      title: t('treeViewSlideShow.navigation.heading'),
      // eslint-disable-next-line global-require
      media: { type: 'video', src: navigatingTreeView },
      description: t('treeViewSlideShow.navigation.description'),
    },
    {
      title: t('treeViewSlideShow.edit.heading'),
      // eslint-disable-next-line global-require
      media: { type: 'video', src: editGoalTree },
      description: t('treeViewSlideShow.edit.description'),
    },
    {
      title: t('treeViewSlideShow.viewControls.heading'),
      // eslint-disable-next-line global-require
      media: { type: 'video', src: viewControls },
      description: t('treeViewSlideShow.viewControls.description'),
    },
    {
      title: t('treeViewSlideShow.handAndSelectTool.heading'),
      // eslint-disable-next-line global-require
      media: { type: 'video', src: handAndSelectTool },
      description: t('treeViewSlideShow.handAndSelectTool.description'),
      link: { href: t('treeViewSlideShow.helpCenterLink'), target: '_blank', text: t('general.learnMore') },
    },
  ];

  const showTreeViewSlideShow = () => {
    EventBus.$emit('show-slide-show', slides);
    onboardingMarker.markAsDone([onboardingSteps.goalTreeSlideShow]);
  };

  return { showTreeViewSlideShow };
}
