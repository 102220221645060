import { AND } from 'shared/api/query/filter';
import { RESULT, UID } from 'shared/api/query/constants';
import {
  accessPolicy,
  notification,
  notificationReminder as notificationReminderConfig,
  propertyValue,
  updateTemplate,
  user,
  userScopeTree,
} from 'shared/api/query/configs.json';
import { accessPolicyChildren, userScopeTreeChildren } from '@/api/query/nebula/access-policy';
import { buildCustomFuncUpdateParticipationListPred } from '@/api/query/custom-func-helper';
import { propertyValueEdges } from '@/api/query/nebula/property-value';

export const notificationChildren = (timeSeries) => [
  { attr: UID },
  { attr: notification.edges.activatedAt, default: null },
  { attr: notification.edges.cancelledAt, default: null },
  { attr: notification.edges.title },
  { attr: notification.edges.typ },
  { attr: notification.edges.schedule, default: '' },
  { attr: notification.edges.accessRight, default: '' },
  { attr: notification.edges.icon, default: '' },
  { attr: 'isDirty', default: false },
  buildCustomFuncUpdateParticipationListPred({ timeSeries, attr: UID }),
  {
    attr: notification.edges.reminders,
    default: [],
    model: notificationReminderConfig.model,
    children: [
      { attr: UID },
      { attr: notificationReminderConfig.edges.after },
    ],
  },
  {
    attr: notification.edges.updateTemplate,
    model: updateTemplate.model,
    default: null,
    children: [
      { attr: UID },
      { attr: updateTemplate.edges.title },
      { attr: updateTemplate.edges.icon },
      { attr: updateTemplate.edges.template, default: null },
      {
        attr: updateTemplate.edges.propertyValues,
        default: [],
        model: propertyValue.model,
        children: propertyValueEdges,
      },
    ],
  },
  {
    attr: notification.edges.creator,
    model: user.model,
    children: [{ attr: UID }],
    default: null,
  },
  {
    attr: notification.edges.recipients,
    model: userScopeTree.model,
    default: null,
    children: userScopeTreeChildren,
  },
  {
    attr: notification.edges.recipientsList,
    model: user.model,
    default: [],
    children: [{ attr: UID }],
  },
  {
    attr: notification.edges.accessPolicy,
    model: accessPolicy.model,
    default: null,
    children: accessPolicyChildren,
  },
];

export const notificationList = ({ type = '', timeSeries }) => {
  if (type === '') {
    return [{
      alias: RESULT,
      func: { name: 'type', args: [{ value: notification.model }] },
      model: notification.model,
      children: notificationChildren(timeSeries),
    }];
  }

  return [{
    alias: RESULT,
    func: { name: 'type', args: [{ value: notification.model }] },
    filter: {
      op: AND,
      child: [{
        func: {
          attr: notification.edges.typ,
          name: 'eq',
          args: [{ value: type }],
        },
      }],
    },
    model: notification.model,
    children: notificationChildren(timeSeries),
  }];
};
