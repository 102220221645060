<template>
  <m-content
    :class="['m-card-item', active ? '-active' : '', clickable && !disabled ? '-clickable' : '', loading ? '-loading' : '', focused ? '-focused' : '', rightOnFocusOnly ? '-right-on-focus-only' : '', noHover ? '-no-hover' : '', $store.state.breakpoint.smAndDown ? '-mobile': '', disabled ? '-disabled' : '', selected ? '-selected' : '', `-hover-color-${hoverColor}`, showAsCard ? '-show-as-card' : '']"
    :padding-xxs="!large"
    :padding-xs="large"
    :padding-x="paddingX"
    :padding-y="paddingY"
    :data-id="dataId"
    @click="handleClick"
  >
    <component
      :is="hasTooltip ? 'm-tooltip' : 'div'"
      :disabled="!hasTooltip"
      :placement="tooltipPlacement"
      :style="{ width: '100%' }"
    >
      <div class="_inner">
        <div
          :class="['_left', icon !== '' || emoji !== '' || !!$slots.left ? '-icon' : '', emoji !== '' ? '-has-emoji' : '', icon !== '' ? '-has-icon' : '', !!$slots.right ? '-has-right' : '']"
          :style="leftStyle"
        >
          <div
            v-if="loading || icon !== '' || emoji !== '' || !!$slots.left"
            class="_icon"
            :style="iconStyle"
          >
            <slot name="left" />
            <m-icon
              v-if="loading"
              :size="iconSize"
              type="loading"
              spin
            />
            <template v-else>
              <m-icon
                v-if="icon !== ''"
                :type="icon"
                :color="iconColor"
                :size="iconSize"
              />
              <m-emoji
                v-if="emoji !== ''"
                class="_emoji"
                :emoji="emoji"
                :size="parseInt(iconSize, 10)"
              />
            </template>
          </div>
          <div
            :style="textStyle"
            class="_text"
          >
            <slot />
          </div>
        </div>
        <div
          v-if="!!$slots.right"
          class="_right"
        >
          <slot name="right" />
        </div>
      </div>
      <template
        v-if="tooltip !== ''"
        #title
      >
        {{ tooltip }}
      </template>
    </component>
  </m-content>
</template>

<script>
export default {
  name: 'MCardItem',
  props: {
    icon: {
      type: String,
      default: '',
    },
    iconColor: {
      type: String,
      default: '',
    },
    emoji: {
      type: String,
      default: '',
    },
    showAsCard: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: '',
    },
    iconSize: {
      type: String,
      default: '14',
    },
    active: {
      type: Boolean,
      default: false,
    },
    focused: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    rightOnFocusOnly: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: true,
    },
    hoverColor: {
      type: String,
      default: 'grey',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    noHover: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    paddingX: {
      type: Number,
      default: -1,
    },
    maxWidthLeft: {
      type: String,
      default: '100%',
    },
    alignTop: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: '',
    },
    tooltipPlacement: {
      type: String,
      default: '',
    },
    light: {
      type: Boolean,
      default: false,
    },
    superLight: {
      type: Boolean,
      default: false,
    },
    textStyle: {
      type: Object,
      default: () => {
      },
    },
    dataId: {
      type: [String, Number],
      default: () => null,
    },
  },
  emits: ['click'],
  computed: {
    hasTooltip() {
      return this.tooltip !== '';
    },
    iconStyle() {
      const middlewares = [
        this.makeIconMargin,
        this.makeIconPosition,
      ];
      return middlewares.reduce((res, m) => {
        res = m(res);
        return res;
      }, { display: 'flex' });
    },
    leftStyle() {
      return {
        maxWidth: this.maxWidthLeft,
        color: this.c,
      };
    },
    c() {
      if (this.disabled) {
        return this.$colors.grey.lighten1;
      }

      if (this.color !== '') {
        return this.color;
      }

      if (this.superLight) {
        return this.$colors.grey.lighten1;
      }

      if (this.light) {
        return this.$colors.grey.darken1;
      }

      return this.$colors.grey.darken4;
    },
    paddingY() {
      if (this.$store.state.breakpoint.smAndDown) {
        return 7;
      }

      return -1;
    },
  },
  methods: {
    makeIconMargin(style) {
      if (this.alignTop) {
        style.marginTop = '.2rem';
      }

      if (this.large) {
        return {
          ...style,
          marginRight: '1rem',
        };
      }

      return { ...style, marginRight: '.8rem' };
    },
    makeIconPosition(style) {
      if (this.alignTop) {
        return {
          ...style,
          alignSelf: 'flex-start',
        };
      }

      return style;
    },
    handleClick(event) {
      if (this.disabled || !this.clickable) {
        event.stopPropagation();
        event.preventDefault();
        return;
      }

      this.$emit('click', event);
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  @import "shared/assets/scss/box-shadow";

  .m-card-item {
    flex: 1 1 auto;
    min-height: 30px;
    white-space: nowrap;

    ._inner {
      display: flex;
      align-items: center;
      width: 100%;
      min-height: 2.2rem;
      user-select: none;
    }

    &.-clickable {
      cursor: pointer;
    }

    &.-show-as-card {
      border-radius: $default-border-radius;

      @include box_shadow(1);
    }

    &.-loading {
      pointer-events: none;
      cursor: default;
    }

    &:hover {
      &.-clickable {
        &:not(.-no-hover) {
          background-color: $hover-color;

          &.-hover-color-beige {
            background-color: $sidebar-hover-color;
          }
        }

        &.-selected {
          background-color: $highlighted-color;
        }
      }
    }

    ._left {
      flex: 1 1 auto;
      min-width: 0;
      padding: 0 1.2rem;

      ._text {
        display: flex;
        align-items: center;
        width: 100%;
        min-height: 22px;
        overflow: hidden;
        line-height: normal;
        text-overflow: ellipsis;
        white-space: nowrap;
        vertical-align: center;
      }

      &.-has-right {
        margin-right: 1rem;
      }

      &.-icon {
        display: flex;
        align-items: center;
        min-height: 22px;
      }

      &.-has-emoji {
        &.-has-icon {
          ._emoji {
            margin-left: .6rem;
          }

          ._icon {
            margin-right: .4rem;
          }
        }
      }
    }

    &.-active {
      background-color: $selected-color;

      ._left {
        font-weight: $font-weight-semibold;
      }
    }

    &.-focused {
      background-color: $hover-color;

      &.-hover-color-beige {
        background-color: $sidebar-hover-color;
      }
    }

    &.-selected {
      background-color: $highlighted-color;

      &.-hover-color-beige {
        background-color: $sidebar-hover-color;
      }
    }

    &.-disabled {
      color: $font-color-tertiary;
    }

    ._right {
      display: flex;
      align-items: center;
      height: 2.2rem;
      padding-right: .8rem;
      margin-left: auto;
    }

    &.-right-on-focus-only {
      ._right {
        display: none;
      }

      &.-focused {
        ._right {
          display: flex;
        }
      }
    }

    &.-mobile {
      background-color: white;
      border-bottom: 1px solid $border-color;
    }
  }
</style>
