<template>
  <div :class="['template-group-item', mobileVersion ? '-mobile-version' : '']">
    <div class="_title">
      <component
        :is="mobileVersion ? 'm-content' : 'm-card-item'"
        :icon="expanded ? 'chevron-down' : 'chevron-right'"
        :padding-x="mobileVersion ? 9 : 1"
        :padding-y="6"
        hide-border
        small
        icon-size="16"
        light
        @click="expanded = !expanded"
      >
        {{ title }}
      </component>
    </div>
    <m-transition-expand>
      <div
        v-if="expanded || mobileVersion"
        class="_children"
      >
        <m-card-item
          v-for="i in item"
          :key="i.uid"
          :padding-x="mobileVersion ? undefined : 9"
          :light="i.uid !== selectedTemplateId"
          :active="i.uid === selectedTemplateId && !mobileVersion"
          :emoji="i.icon"
          @click="$emit('select', i.uid)"
        >
          <div :class="['_item-title', i.icon === '' ? '-no-icon' : '']">
            {{ i.title }}
          </div>
        </m-card-item>
      </div>
    </m-transition-expand>
  </div>
</template>

<script>

export default {
  name: 'TemplateGroupItem',
  props: {
    item: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    selectedTemplateId: {
      type: Number,
      required: true,
    },
    mobileVersion: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['select'],
  data() {
    return { expanded: false };
  },
  mounted() {
    if (this.item.filter((i) => i.uid === this.selectedTemplateId).length > 0) {
      this.expanded = true;
    }
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .template-group-item {
    ._title {
      font-weight: $font-weight-medium;
    }

    &.-mobile-version {
      ._title {
        color: $font-color-secondary;
      }
    }

    ._children {
      ._item-title {
        &.-no-icon {
          margin-left: 1rem;
        }
      }
    }
  }
</style>
