<template>
  <div class="survey">
    <full-screen-spinner v-if="form === null" />
    <survey-form
      v-else
      :form="form"
      :lang="lang"
      :languages="languages"
      @change-language="changeLanguage"
      @submit="submit"
    />
  </div>
</template>

<script>
import FullScreenSpinner from 'shared/components/FullScreenSpinner.vue';
import SurveyForm from 'shared/components/Form.vue';
import { DEFAULT_LANGUAGE, isValidLanguageCode } from '@/lib/language';
import { answer as answerConfig } from 'shared/api/query/configs.json';
/* eslint-disable */
import { dogma } from '@/api';
/* eslint-enable */
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useUsers from '@/composables/user/users';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'Survey',
  components: { SurveyForm, FullScreenSpinner },
  setup() {
    const { userLang } = useLoggedInUser();
    const { fetchUsers } = useUsers();
    return { fetchUsers, userLang };
  },
  computed: {
    ...mapState({ form: (state) => state.surveyForm }),
    lang() {
      if (typeof this.$route.query.lang !== 'undefined' && isValidLanguageCode(this.$route.query.lang)) {
        return this.$route.query.lang;
      }
      if (this.languages.includes(this.userLang)) {
        return this.userLang;
      }
      if (this.languages.includes(DEFAULT_LANGUAGE)) {
        return DEFAULT_LANGUAGE;
      }
      return this.languages[0];
    },
    languages() {
      if (this.form === null || (this.form.orderItems.length === 0 && this.form.welcomeScreen === null)) {
        return [this.userLang];
      }
      if (this.form.orderItems.length > 0) {
        return Object.keys(this.form.orderItems[0].field.title);
      }
      return Object.keys(this.form.welcomeScreen.title);
    },
    formId() {
      const param = this.$route.params.id;
      if (typeof param === 'undefined') {
        return 0;
      }
      return parseInt(param, 10);
    },
  },
  methods: {
    ...mapActions(['surveyViewRequest']),
    changeLanguage({ lang }) {
      this.$router.push({ ...this.$route, query: { ...this.$route.query, lang } });
    },
    submit({ answer }) {
      dogma.createSingle(answer, answerConfig.model, [])
        .then((response) => {
          if (response.status === 401) {
            this.$showSnackbar({ color: 'error', message: this.$t('error.unauthorized') });
            return;
          }
          if (response.status !== 201) {
            this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
            return;
          }
          this.$router.push(`/surveys/${this.form.uid}/success`);
        });
    },
  },
  created() {
    this.surveyViewRequest({ form: { uid: parseInt(this.$route.params.id, 10) } }).then((response) => {
      if (response.status !== 200) {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      }
      this.fetchUsers();
    });
  },
};
</script>

<style scoped lang="scss" type="text/scss">
</style>
