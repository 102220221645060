export const getSpaceTitle = (title) => {
  if (['Team', 'Department'].includes(title)) {
    return `${title}s`;
  }
  if (['Abteilung'].includes(title)) {
    return `${title}en`;
  }
  if (['Organisationseinheit'].includes(title)) {
    return `${title}en`;
  }
  return title;
};
