import i18n from '@/lang';

import store from '@/store';
import { DateTime } from 'luxon';
import { EVENTS } from '@/lib/constants';
import { EventBus } from '@/lib/event-bus';
import { accountCanActivateFeature, accountHasFeature, userHasRight } from 'shared/lib/access';
import { dataSourceType, featureFlag, productPlan, routeName } from 'shared/constants.json';
import { minimumPlanForFeature, nextPlan as nextPlanFn } from 'shared/lib/plans';
import { routeNameToAccess } from '@/route-access';

const featureDialogs = (flag) => {
  switch (flag) {
    case featureFlag.healthDashboard:
      return {
        title: i18n.t('planGateDialogs.shared.title'),
        titleIcon: 'upgrade-plan',
        subTitle: i18n.t('featureGateDialogs.healthDashboard.subTitle'),
        description: [{ type: 'paragraph', content: i18n.t('featureGateDialogs.healthDashboard.description') }],
        actions: [],
        image: {
          style: { position: 'absolute', top: '3rem', left: '3rem' },
          src: 'img/illustrations/health-dashboard.png',
          alt: i18n.t('featureGateDialogs.healthDashboard.image.alt'),
        },
      };
    case featureFlag.customDashboards:
      return {
        title: i18n.t('planGateDialogs.shared.title'),
        titleIcon: 'upgrade-plan',
        subTitle: i18n.t('featureGateDialogs.customDashboards.subTitle'),
        description: [
          { type: 'paragraph', content: i18n.t('featureGateDialogs.customDashboards.description') },
          { type: 'bullet', icon: 'StarOutlined', content: i18n.t('featureGateDialogs.customDashboards.bullet1') },
          { type: 'bullet', icon: 'StarOutlined', content: i18n.t('featureGateDialogs.customDashboards.bullet2') },
          { type: 'bullet', icon: 'StarOutlined', content: i18n.t('featureGateDialogs.customDashboards.bullet3') },
          { type: 'bullet', icon: 'StarOutlined', content: i18n.t('featureGateDialogs.customDashboards.bullet4') },
          { type: 'bullet', icon: 'StarOutlined', content: i18n.t('featureGateDialogs.customDashboards.bullet5') },
        ],
        actions: [],
        image: {
          style: { position: 'absolute', top: '3rem', left: '3rem' },
          src: 'img/illustrations/custom-dashboards.png',
          alt: i18n.t('featureGateDialogs.customDashboards.image.alt'),
        },
      };
    case featureFlag.goalTree:
      return {
        title: i18n.t('planGateDialogs.shared.title'),
        titleIcon: 'upgrade-plan',
        subTitle: i18n.t('featureGateDialogs.goalTree.subTitle'),
        description: [
          { type: 'bullet', icon: 'StarOutlined', content: i18n.t('featureGateDialogs.goalTree.bullet1') },
          { type: 'bullet', icon: 'StarOutlined', content: i18n.t('featureGateDialogs.goalTree.bullet2') },
          { type: 'bullet', icon: 'StarOutlined', content: i18n.t('featureGateDialogs.goalTree.bullet3') },
          { type: 'bullet', icon: 'StarOutlined', content: i18n.t('featureGateDialogs.goalTree.bullet4') },
        ],
        actions: [],
        image: {
          style: { position: 'absolute', top: '0', left: '3rem', width: '100%' },
          src: 'img/illustrations/goal-tree.png',
          alt: i18n.t('featureGateDialogs.goalTree.image.alt'),
        },
      };
    default:
      throw new Error(`featureDialogs for ${flag} doesn't exist`);
  }
};

const planDialogs = (plan) => {
  switch (plan) {
    case productPlan.pro:
      return {
        title: i18n.t('planGateDialogs.shared.title'),
        titleIcon: 'upgrade-plan',
        subTitle: i18n.t(`planGateDialogs.${productPlan.pro}.subTitle`),
        description: [
          { type: 'paragraph', content: i18n.t(`planGateDialogs.${productPlan.pro}.description.bulletIntro`) },
          { type: 'bullet', icon: 'StarOutlined', content: i18n.t(`planGateDialogs.${productPlan.pro}.description.bulletHealth`) },
          { type: 'bullet', icon: 'StarOutlined', content: i18n.t(`planGateDialogs.${productPlan.pro}.description.bulletOKRRule`) },
          { type: 'bullet', icon: 'StarOutlined', content: i18n.t(`planGateDialogs.${productPlan.pro}.description.bulletGoalWeight`) },
          { type: 'bullet', icon: 'StarOutlined', content: i18n.t(`planGateDialogs.${productPlan.pro}.description.bulletMultiAlignment`) },
          { type: 'bullet', icon: 'StarOutlined', content: i18n.t(`planGateDialogs.${productPlan.pro}.description.bulletGoalTree`) },
          { type: 'paragraph', content: i18n.t(`planGateDialogs.${productPlan.pro}.description.bulletMore`) },
        ],
        actions: [],
        image: {
          style: {
            position: 'absolute',
            top: '0',
            left: '0',
            width: '103%',
          },
          src: 'img/illustrations/pro-plan.png',
          alt: i18n.t(`planGateDialogs.${productPlan.pro}.image.alt`),
        },
      };
    case productPlan.enterprise:
      return {
        title: i18n.t('planGateDialogs.shared.title'),
        titleIcon: 'upgrade-plan',
        subTitle: i18n.t(`planGateDialogs.${productPlan.enterprise}.subTitle`),
        description: [
          { type: 'paragraph', content: i18n.t(`planGateDialogs.${productPlan.enterprise}.description.bulletIntro`) },
          { type: 'bullet', icon: 'StarOutlined', content: i18n.t(`planGateDialogs.${productPlan.enterprise}.description.bulletPublishedViews`) },
          { type: 'bullet', icon: 'StarOutlined', content: i18n.t(`planGateDialogs.${productPlan.enterprise}.description.bulletScim`) },
          { type: 'bullet', icon: 'StarOutlined', content: i18n.t(`planGateDialogs.${productPlan.enterprise}.description.bulletSaml`) },
          { type: 'bullet', icon: 'StarOutlined', content: i18n.t(`planGateDialogs.${productPlan.enterprise}.description.bulletSupport`) },
          { type: 'paragraph', content: i18n.t(`planGateDialogs.${productPlan.enterprise}.description.bulletMore`) },
        ],
        actions: [],
        image: {
          src: 'img/illustrations/enterprise-plan.png',
          alt: i18n.t(`planGateDialogs.${productPlan.enterprise}.image.alt`),
        },
      };
    default:
      throw new Error(`planDialogs for ${plan} doesn't exist`);
  }
};

const canSeeRoute = (route) => {
  const { state, getters } = store;
  if (!state.isLoggedIn) {
    return false;
  }
  return userHasRight(routeNameToAccess(route), getters.accessGroupsLoggedInUser);
};

const accountIsExpired = () => {
  const { getters } = store;
  if (getters.loggedInUserAccount.accountSettings.expiresAt === null) {
    return false;
  }
  return DateTime.fromISO(getters.loggedInUserAccount.accountSettings.expiresAt).diff(DateTime.local()).milliseconds < 0;
};

export const mapFeatureToRoute = (flag) => {
  switch (flag) {
    case featureFlag.multiGoalAlignment:
    case featureFlag.weightedGoals:
    case featureFlag.publishedViews:
      return routeName.goalSettings;
    case dataSourceType.salesforce:
      return routeName.integrationSettings;
    case featureFlag.saml:
      return routeName.identityAndProvisioning;
    case featureFlag.userProvisioning:
      return routeName.identityAndProvisioning;
    default:
      return undefined;
  }
};

const injectActions = (flag) => {
  const updatePlanAction = () => {
    const action = {
      title: i18n.t('planGateDialogs.shared.actions.comparePlans'),
      color: 'primary',
    };

    if (accountIsExpired()) {
      action.href = i18n.t('planGateDialogs.shared.actions.comparePlansWebsiteLink');
      action.targe = '_blank';
      return action;
    }

    const mustContactAdmin = !canSeeRoute(routeName.plans);
    action.route = routeName.plans;
    action.tooltip = mustContactAdmin ? i18n.t('planGateDialogs.shared.actions.contactAdminToUpgrade') : undefined;
    action.disabled = mustContactAdmin;
    return action;
  };

  const learnMoreAction = (link) => ({
    title: i18n.t('planGateDialogs.shared.actions.learnMore'),
    light: true,
    href: link,
    target: '_blank',
  });

  const activateFeatureAction = (flag) => {
    const featureRoute = mapFeatureToRoute(flag);
    if (featureRoute === undefined) {
      return [];
    }
    const mustContactAdmin = !canSeeRoute(featureRoute);
    return [{
      title: i18n.t('planGateDialogs.shared.actions.activateFeature'),
      color: 'primary',
      route: featureRoute,
      tooltip: mustContactAdmin ? i18n.t('planGateDialogs.shared.actions.contactAdminToActivate') : undefined,
      disabled: mustContactAdmin,
    }];
  };

  const minimumPlan = minimumPlanForFeature(flag).id;
  const { getters } = store;
  const currentPlan = getters.loggedInUserAccount.accountSettings.planId;
  const nextPlan = nextPlanFn(currentPlan);
  const activateOrUpgradeAction = (flag, minimumPlan) => (canActivateFeature(flag) ? activateFeatureAction(flag) : [updatePlanAction(minimumPlan)]);
  switch (flag) {
    // toggleable by user
    case featureFlag.publishedViews:
    case featureFlag.multiGoalAlignment:
    case featureFlag.weightedGoals:
    case dataSourceType.salesforce:
      return [
        ...activateOrUpgradeAction(flag, minimumPlan),
      ];
    // not-toggleable by user
    case featureFlag.healthDashboard:
      return [
        updatePlanAction(minimumPlan),
        learnMoreAction(i18n.t('featureGateDialogs.healthDashboard.actions.learnMoreLink')),
      ];
    case featureFlag.customDashboards: {
      return [
        updatePlanAction(nextPlan),
        learnMoreAction(i18n.t('featureGateDialogs.customDashboards.actions.learnMoreLink')),
      ];
    }
    case featureFlag.advancedOkrRules:
    case featureFlag.companyInfo:
    case featureFlag.saml:
    case featureFlag.userProvisioning:
    case featureFlag.goalTree:
      return [
        updatePlanAction(minimumPlan),
      ];
    default:
      return [];
  }
};

export const shouldShowFeatureGate = (flag) => {
  const { state, getters } = store;
  if (!state.isLoggedIn) {
    return true;
  }
  return !accountHasFeature([flag], getters.loggedInUserAccount);
};

export const canActivateFeature = (flag) => {
  const { state, getters } = store;
  if (!state.isLoggedIn) {
    return false;
  }
  return accountCanActivateFeature([flag], getters.loggedInUserAccount.accountSettings.planId);
};

export const showFeatureGate = (flag, force = false) => {
  if (!force && !shouldShowFeatureGate(flag)) {
    return false;
  }

  function mapFeatureToDialog(f) {
    switch (f) {
      case featureFlag.healthDashboard:
      case featureFlag.customDashboards:
      case featureFlag.goalTree:
        return featureDialogs(f);
      case featureFlag.advancedOkrRules:
      case featureFlag.multiGoalAlignment:
      case featureFlag.weightedGoals:
      case dataSourceType.salesforce:
      case featureFlag.publishedViews:
      case featureFlag.companyInfo:
      case featureFlag.saml:
      case featureFlag.userProvisioning:
        return planDialogs(minimumPlanForFeature(f).id);
      default:
        return undefined;
    }
  }

  const dialog = mapFeatureToDialog(flag);
  if (dialog === undefined) {
    return false;
  }
  dialog.flag = flag;
  dialog.actions = injectActions(flag);
  EventBus.$emit('show-feature-gate', dialog);
  EventBus.$emit(EVENTS.UPSELL.FEATURE_GATE_VIEWED, { flag, plan: minimumPlanForFeature(flag).id });
  return true;
};
