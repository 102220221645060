import { AND } from '@/lib/filter/scope-tree';
import { CREATED_AT, DEFAULT_ATTRIBUTES, RESULT, TYPE, UID, VAR } from 'shared/api/query/constants';
import { comment as commentConfig,
  goalActivity as goalActivityConfig,
  goal as goalConfig,
  notification as notificationConfig,
  propertyValue as propertyValueConfig,
  reaction as reactionConfig,
  storageObject as storageObjectConfig,
  update as updateConfig,
  user as userConfig } from 'shared/api/query/configs.json';
import { commentEdges } from '@/api/query/nebula/comment';
import { goalActivityChildren } from '@/api/query/nebula/goal-activity';
import { propertyValueEdges } from '@/api/query/nebula/property-value';
import { reactionEdges } from '@/api/query/nebula/reaction';
import { reverseEdge } from 'shared/api/query/edges';
import { storageObjectEdges } from '@/api/query/nebula/storage-object';

export const updateChildren = (goalActivitiesFilter = null) => [
  ...DEFAULT_ATTRIBUTES,
  { attr: updateConfig.edges.type },
  { attr: updateConfig.edges.title, default: '' },
  { attr: updateConfig.edges.icon, default: '' },
  {
    attr: updateConfig.edges.image,
    model: storageObjectConfig.model,
    children: storageObjectEdges,
    default: null,
  },
  { attr: updateConfig.edges.generated, default: false },
  { attr: updateConfig.edges.goalActivitiesOrder, default: [] },
  {
    attr: updateConfig.edges.message,
    default: null,
  },
  {
    attr: updateConfig.edges.goal,
    model: goalConfig.model,
    default: null,
    children: [
      { attr: UID },
    ],
  },
  {
    attr: updateConfig.edges.creator,
    model: userConfig.model,
    default: null,
    children: [{ attr: UID }],
  },
  {
    attr: updateConfig.edges.goalActivities,
    model: goalActivityConfig.model,
    filter: goalActivitiesFilter,
    facets: { param: [{ key: 'referenced', alias: 'referenced' }] },
    facetVar: {},
    default: [],
    children: goalActivityChildren,
  },
  {
    alias: 'reactions',
    attr: reverseEdge(reactionConfig.edges.update),
    default: [],
    model: reactionConfig.model,
    children: reactionEdges,
  },
  {
    alias: 'comments',
    attr: reverseEdge(commentConfig.edges.update),
    default: [],
    model: commentConfig.model,
    order: [{
      attr: CREATED_AT,
      desc: false,
    }],
    children: commentEdges,
  },
  {
    attr: updateConfig.edges.properties,
    default: [],
    model: propertyValueConfig.model,
    children: propertyValueEdges,
  },
  {
    attr: updateConfig.edges.notification,
    model: notificationConfig.model,
    children: [{ attr: UID }],
    default: null,
  },
];

export const updatesList = ({
  pagination,
  filter = null,
  varBlocks = [],
  order = [],
  alias = RESULT,
  goalActivitiesFilter = null,
  uid = null,
  func = { name: TYPE, args: [{ value: updateConfig.model }] },
}) => {
  if (goalActivitiesFilter === null) {
    return [
      {
        model: updateConfig.model,
        alias,
        func,
        uid,
        order,
        filter,
        pagination,
        default: [],
        children: updateChildren(goalActivitiesFilter),
      },
      ...varBlocks,
    ];
  }

  return [
    {
      alias: VAR,
      func: { name: TYPE, args: [{ value: goalActivityConfig.model }] },
      filter: goalActivitiesFilter,
      model: goalActivityConfig.model,
      children: [
        {
          attr: reverseEdge(updateConfig.edges.goalActivities),
          model: updateConfig.model,
          children: [{ attr: UID, var: 'updates_with_goal_activities' }],
        },
      ],
    },
    {
      model: updateConfig.model,
      alias,
      uid,
      func,
      order,
      filter: {
        op: AND,
        child: [
          filter,
          {
            func: {
              name: UID,
              needsVar: [{ name: 'updates_with_goal_activities', typ: 1 }],
            },
          },
        ],
      },
      pagination,
      default: [],
      children: updateChildren(goalActivitiesFilter),
    },
    ...varBlocks,
  ];
};

export const updateListForGoal = (goalId, alias = RESULT) => [
  {
    alias: VAR,
    model: goalConfig.model,
    uid: [goalId],
    func: { name: UID },
    children: [
      {
        attr: reverseEdge(updateConfig.edges.goal),
        model: updateConfig.model,
        var: 'updates',
      },
    ],
  },
  {
    alias: VAR,
    model: goalConfig.model,
    uid: [goalId],
    func: { name: UID },
    children: [
      {
        attr: reverseEdge(goalActivityConfig.edges.goal),
        model: goalActivityConfig.model,
        children: [
          {
            attr: reverseEdge(updateConfig.edges.goalActivities),
            model: updateConfig.model,
            children: [
              {
                attr: UID,
                var: 'goalActivities',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    alias: VAR,
    model: goalConfig.model,
    uid: [goalId],
    func: { name: UID },
    children: [
      {
        model: goalConfig.model,
        attr: reverseEdge(goalConfig.edges.parents),
        children: [
          {
            attr: reverseEdge(updateConfig.edges.goal),
            model: updateConfig.model,
            var: 'childrenUpdates',
          },
        ],
      },
    ],
  },
  {
    alias: VAR,
    model: goalConfig.model,
    uid: [goalId],
    func: { name: UID },
    children: [
      {
        model: goalConfig.model,
        attr: reverseEdge(goalConfig.edges.parents),
        children: [
          {
            attr: reverseEdge(goalActivityConfig.edges.goal),
            model: goalActivityConfig.model,
            children: [
              {
                attr: reverseEdge(updateConfig.edges.goalActivities),
                model: updateConfig.model,
                children: [
                  {
                    attr: UID,
                    var: 'childrenGoalActivities',
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    model: updateConfig.model,
    alias,
    needsVar: [
      {
        name: 'updates',
        typ: 1,
      },
      {
        name: 'childrenUpdates',
        typ: 1,
      },
      {
        name: 'goalActivities',
        typ: 1,
      },
      {
        name: 'childrenGoalActivities',
        typ: 1,
      },
    ],
    func: {
      name: UID,
      needsVar: [
        {
          name: 'updates',
          typ: 1,
        },
        {
          name: 'childrenUpdates',
          typ: 1,
        },
        {
          name: 'goalActivities',
          typ: 1,
        },
        {
          name: 'childrenGoalActivities',
          typ: 1,
        },
      ],
    },
    order: [{
      attr: CREATED_AT,
      desc: true,
    }],
    default: [],
    children: updateChildren(),
  },
];
