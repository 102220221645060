import { fieldType } from 'shared/constants.json';

export const getMinMaxOfField = (field) => {
  switch (field.type) {
    case fieldType.opinionScale: {
      if (field.opinionScaleProperties.startsFromZero) {
        return { min: 0, max: field.opinionScaleProperties.steps - 1 };
      }
      return { min: 1, max: field.opinionScaleProperties.steps };
    }
    default:
      return { min: 0, max: 0 };
  }
};
