<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.8336 20.511L10.8336 5.65979H13.167V20.511H10.8336Z"
      fill="currentColor"
    />
    <path
      d="M5.71614 15.7648C5.32146 15.3736 5.32178 14.7359 5.71646 14.3447L6.04599 14.0181C6.43579 13.6317 7.06416 13.6317 7.45396 14.0181L13.75 20.2589C13.75 20.2589 12.6834 21.3161 12 21.9936C9.95326 19.9648 7.04734 17.0843 5.71614 15.7648Z"
      fill="currentColor"
    />
    <path
      d="M16.546 14.0181C16.9358 13.6317 17.5641 13.6317 17.9539 14.0181L18.2835 14.3447C18.6782 14.7359 18.6785 15.3736 18.2838 15.7648C16.9526 17.0843 14.0467 19.9648 12 21.9936C11.3166 21.3161 10.2499 20.2589 10.2499 20.2589L16.546 14.0181Z"
      fill="currentColor"
    />
    <circle
      cx="6.25"
      cy="2.75"
      r="1.25"
      fill="currentColor"
    />
    <circle
      cx="10.25"
      cy="2.75"
      r="1.25"
      fill="currentColor"
    />
    <circle
      cx="14.25"
      cy="2.75"
      r="1.25"
      fill="currentColor"
    />
    <circle
      cx="18.25"
      cy="2.75"
      r="1.25"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default { name: 'BelowOrEqual' };
</script>

<style scoped lang="scss" type="text/scss">

</style>
