import useGoals from '@/composables/goal/goals';
import { computed } from 'vue';
import { routeName } from 'shared/constants.json';
import { useRoute } from 'vue-router';

export default function useGoalDetailsCtx() {
  const route = useRoute();
  const inCtx = computed(() => route.name === routeName.goalDetails);

  const paramId = computed(() => {
    if (!inCtx.value) {
      return 0;
    }
    return parseInt(route.params.goalId, 10);
  });
  const { selectSingle } = useGoals();
  const goal = computed(() => selectSingle(paramId.value));

  const goalModifiers = computed(() => {
    const modifiers = [];
    if (goal.value === undefined) {
      return modifiers;
    }
    if (goal.value.publishedAt === null) {
      modifiers.push(
        (goal) => {
          goal.publishedAt = null;
          return goal;
        },
      );
    }
    if (goal.value.planning !== null && goal.value.planning !== undefined) {
      const planningId = goal.value.planning.uid;
      modifiers.push(
        (goal) => {
          goal.planning = { uid: planningId };
          return goal;
        },
      );
    }
    return modifiers;
  });

  return { inCtx, paramId, goal, goalModifiers };
}
