import { routeName } from 'shared/constants.json';
// routes
export const ACCESS_GROUP_LIST = routeName.accessGroupList;
export const CREATE_FORM = routeName.createForm;
export const CREATE_FORM_TEMPLATE = routeName.createFormTemplate;
export const FORM_TEMPLATE_FIELD_FACTORS = routeName.formTemplateFieldFactors;
export const FORM_TEMPLATE_LANGUAGE_SETTINGS = routeName.formTemplateLanguageSettings;
export const FIELD_FACTORS = routeName.fieldFactors;
export const QUESTION_BANK = routeName.questionBank;
export const QUESTION_BANK_TEMPLATE_LIST = routeName.questionBankTemplateList;
export const ACCOUNT_SETTINGS = routeName.accountSettings;
export const QUESTION_BANK_TEMPLATE_DETAILS = routeName.questionBankTemplateDetails;
export const QUESTION_BANK_FORM_TEMPLATES = routeName.questionBankFormTemplates;
export const QUESTION_BANK_TEMPLATE_LIST_FORM_TEMPLATES = routeName.questionBankTemplateListFormTemplates;
export const QUESTION_BANK_TEMPLATE_DETAILS_FORM_TEMPLATES = routeName.QuestionBankTemplateDetailsFormTemplates;
export const GOALS_EXPLORER = routeName.goalsExplorer;
export const UPDATES_EXPLORER = routeName.updatesExplorer;
export const GOAL_INSIGHTS = routeName.goalInsights;
export const GOAL_DETAILS = routeName.goalDetails;
export const FORM = routeName.form;
export const FORM_DETAILS = routeName.formDetails;
export const FORM_RESULT_QUESTIONS = routeName.formResultQuestions;
export const FORM_RESULTS = routeName.formResults;
export const FORM_SEND_CHANNELS = routeName.formSendChannels;
export const FORM_SEND_OPTIONS = routeName.formSendOptions;
export const FORM_SEND_PARTICIPANTS = routeName.formSendParticipants;
export const FORM_SEND_SETTINGS = routeName.formSendSettings;
export const FORM_SEND_VERIFY = routeName.formSendVerify;
export const FORM_SETTINGS = routeName.formSettings;
export const FORM_SHARE = routeName.formShare;
export const FORM_TEMPLATE_LIST = routeName.formTemplateList;
export const NEW_FORM = routeName.newForm;
export const NEW_FORM_TEMPLATE_PREVIEW = routeName.newFormTemplatePreview;
export const FORM_TEMPLATE_WRAPPER = routeName.formTemplateWrapper;
export const FORM_WRAPPER = routeName.formWrapper;
export const LAYOUT_DETAILS = routeName.layoutDetails;
export const LAYOUT_LIST = routeName.layoutList;
export const SPACE_DETAILS = routeName.spaceDetails;
export const ACCOUNT_SPACE = routeName.accountSpace;
export const PROFILE = routeName.profile;
export const HOME = routeName.home;
export const USER_FIELDS_NO_SELECTION = routeName.propertysNoSelection;
export const USER_LIST = routeName.userList;
export const PROFILE_SETTINGS = routeName.profileSettings;
export const NOT_FOUND = routeName.notFound;
export const OAUTH_REDIRECT = routeName.oauthRedirect;
