const fromString = (key, value) => {
  const from = `${key}${value}`;
  let res = 0;
  for (let i = 0; i < from.length; i++) {
    res += from.charCodeAt(i);
  }

  return res;
};

const fromBool = (key, value) => {
  const fromKey = fromString(key, '');
  if (value) {
    return fromKey + 1;
  }
  return fromKey;
};

const fromNumber = (key, value) => {
  const fromKey = fromString(key, '');
  return fromKey + value;
};

const fromArray = (key, value) => {
  if (typeof value[0] === 'string') {
    return fromString(key, value.join());
  }

  if (typeof value[0] === 'boolean') {
    return fromNumber(key, value.reduce((res, cur) => (res + cur ? 1 : 0), 0));
  }

  if (typeof value[0] === 'number') {
    return fromNumber(key, value.reduce((res, cur) => res + cur, 0));
  }

  return 0;
};

export const valueOf = (input) => {
  if (Array.isArray(input)) {
    return input.map((next) => valueOf(next)).reduce((res, cur) => cur + res, 0);
  }

  let res = 0;

  const keys = Object.keys(input);
  for (let i = 0; i < keys.length; i++) {
    const k = keys[i];
    const next = input[k];

    if (typeof next === 'object') {
      if (next === null) {
        res += fromString(k, '');
        continue;
      }

      if (Array.isArray(next)) {
        if (next.length === 0) {
          res += fromString(k, '');
          continue;
        }

        if (typeof next[0] === 'object') {
          res += fromNumber(k, valueOf(next));
          continue;
        }

        res += fromArray(k, next);
        continue;
      }

      res += fromNumber(k, valueOf(next));
    }

    if (typeof next === 'string') {
      res += fromString(k, next);
      continue;
    }

    if (typeof next === 'number') {
      res += fromNumber(k, next);
      continue;
    }

    if (typeof next === 'boolean') {
      res += fromBool(k, next);
      continue;
    }
  }

  return res;
};

export const isNullOrUndefined = (object) => (object === undefined || object === null);
export const isEmpty = (object) => (isNullOrUndefined(object) || object === '');
