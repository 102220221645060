import i18n from '@/lang';
import { dataAggregation } from 'shared/num_constants.json';

export const aggregationText = (aggregation) => {
  switch (aggregation) {
    case dataAggregation.avg:
      return () => i18n.t('charts.avg');
    case dataAggregation.top2:
      return () => i18n.t('charts.top2');
    case dataAggregation.top3:
      return () => i18n.t('charts.top3');
    case dataAggregation.count:
      return () => i18n.t('charts.count');
    default:
      return () => '';
  }
};

export const favorabilityLabels = (value) => {
  switch (value) {
    case '2':
      return i18n.t('charts.positiveAnswers');
    case '1':
      return i18n.t('charts.neutralAnswers');
    case '0':
      return i18n.t('charts.negativeAnswers');
    default:
      return '';
  }
};
