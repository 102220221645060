<template>
  <m-content
    padding-x
    class="_content-header"
  >
    <div class="_header">
      <div class="_right">
        <div class="_search">
          <list-string-filter
            small
            @input="updateSearchTerm"
          />
        </div>
        <m-btn
          color="primary"
          small
          @click="create"
        >
          {{ $t('general.new') }}
        </m-btn>
      </div>
    </div>
    <div class="goal-cycles-table">
      <m-table
        :columns="columns"
        :data-source="rows"
        class="_table"
        row-class-name="_row"
        row-key="uid"
        rows-clickable
        :custom-row="handleRowClick"
        show-new-button
        @new="create"
      >
        <template #title="{ title, row }">
          <div class="_title">
            <div :style="{ 'padding-left': `${isLeafOfCycles(row)*2}rem` }">
              <m-tag
                :key="row.uid"
                :color="row.color"
                :title="title"
                :title-style="{ 'max-width': '15rem', 'font-weight': 'normal' }"
                small
                class="_m-tag"
              />
            </div>
          </div>
        </template>
        <template #date="{ date }">
          <div class="_date">
            {{ formatDate(date) }}
          </div>
        </template>
        <template #enddate="{ enddate, row }">
          <div
            class="_end-date"
          >
            {{ formatDate(enddate) }}
            <div
              class="_actions"
            >
              <m-dropdown
                :title="$t('goalTypes.editTitle')"
                close-on-click
                @click.stop
              >
                <m-btn
                  icon="ellipsis"
                  small
                  light
                  hide-border
                  fab
                />
                <template #overlay>
                  <m-card list>
                    <m-card-item
                      icon="edit"
                      @click="edit(row)"
                    >
                      {{ $t('general.edit') }}
                    </m-card-item>
                    <m-card-item
                      icon="delete"
                      @click="deleteEntities([row])"
                    >
                      {{ $t('general.delete') }}
                    </m-card-item>
                  </m-card>
                </template>
              </m-dropdown>
            </div>
          </div>
        </template>
      </m-table>
    </div>
  </m-content>
</template>

<script>
import ListStringFilter from '@/components/list/ListStringFilter.vue';
import useGoalCycle from '@/composables/goal-cycle/goal-cycle';
import { DateTime } from 'luxon';
import { copy } from 'shared/lib/copy';
import { getColor } from 'shared/lib/color-map';
import { sortDatetimeInterval } from 'shared/lib/sort';

export default {
  name: 'GoalCyclesTable',
  components: { ListStringFilter },
  data() {
    return {
      getColor,
      showDetails: false,
      selectedOption: null,
      search: '',
    };
  },
  emits: ['create', 'update', 'delete'],
  setup() {
    const { goalCycles } = useGoalCycle();
    return { goalCycles };
  },
  computed: {
    columns() {
      return [
        {
          key: 'title',
          dataIndex: 'title',
          title: this.$t('goalCyclesTable.title'),
          scopedSlots: { customRender: 'title' },
        },
        {
          key: 'start',
          dataIndex: 'start',
          title: this.$t('goalCyclesTable.start'),
          scopedSlots: { customRender: 'date' },
        },
        {
          key: 'end',
          dataIndex: 'end',
          title: this.$t('goalCyclesTable.end'),
          scopedSlots: { customRender: 'enddate' },
        },
      ];
    },
    filteredGoalCycles() {
      return this.goalCycles.filter((c) => this.search === '' || c.title.toLowerCase().indexOf(this.search.toLowerCase()) > -1);
    },
    filteredAndSortedGoalCycles() {
      return copy(this.filteredGoalCycles).sort(sortDatetimeInterval('start', 'end'));
    },
    rows() {
      return this.filteredAndSortedGoalCycles;
    },
  },
  methods: {
    dateSorter(key) {
      return (a, b) => DateTime.fromISO(a[key]).diff(DateTime.fromISO(b[key])).valueOf();
    },
    formatDate(val) {
      return DateTime.fromISO(val).toLocaleString(DateTime.DATE_MED);
    },
    isLeafOfCycles(cycle) {
      return this.filteredGoalCycles.filter((c) => cycle.start >= c.start && cycle.end <= c.end && !(cycle.start === c.start && cycle.end === c.end)).length;
    },
    updateSearchTerm(val) {
      this.search = val;
    },
    handleRowClick(record) {
      return {
        on: {
          click: () => {
            this.edit(record);
          },
        },
      };
    },
    create() {
      this.$emit('create');
    },
    edit(item) {
      this.$emit('update', item);
    },
    deleteEntities(items) {
      this.$emit('delete', items);
    },
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  ._content-header {
    position: sticky;
    left: 0;
  }

  ._header {
    display: flex;
    padding-bottom: .6rem;

    ._right {
      display: flex;
      margin-left: auto;

      ._search {
        margin-right: .4rem;
      }
    }
  }

  .goal-cycles-table {
    ._table {
      ._title {
        display: flex;
        justify-content: space-between;
      }

      ._end-date {
        display: flex;
        align-items: center;
        justify-content: space-between;

        ._actions {
          margin-right: .4rem;
        }
      }
    }
  }
</style>
