<template>
  <card-list-item
    class="form-template-card"
    :title="formTemplate.title"
    :icon="formTemplate.icon"
    :menu-items="menuItems"
    :to="editLink"
    :tag-title="isReleased ? $t('createFormTemplateTopBar.released'): $t('createFormTemplateTopBar.notReleased') "
    :tag-type="isReleased ? 'success': '' "
    @delete="deleteTemplate(formTemplate)"
  />
</template>

<script>
import CardListItem from '@/components/CardListItem.vue';
import { CREATE_FORM_TEMPLATE } from '@/route-names';
import { formTemplate as formTemplateConfig } from 'shared/api/query/configs.json';
import { mapActions } from 'vuex';

export default {
  name: 'FormTemplateCard',
  props: {
    formTemplate: {
      type: Object,
      required: true,
    },
  },
  components: { CardListItem },
  computed: {
    menuItems() {
      return [
        {
          name: this.$t('general.edit'),
          to: this.editLink,
        },
        {
          name: this.$t('general.delete'),
          emit: 'delete',
          style: { color: this.$colors.red.base },
          hasDivider: true,
        },
      ];
    },
    editLink() {
      return {
        name: CREATE_FORM_TEMPLATE,
        params: { formTemplateId: this.formTemplate.uid },
      };
    },
    isReleased() {
      return this.formTemplate.releasedAt !== null;
    },
  },
  methods: {
    ...mapActions(['deleteEntity']),
    deleteTemplate(formTemplate) {
      const deleteEntity = (entity) => {
        this.deleteEntity({
          entity,
          model: formTemplateConfig.model,
          mutation: 'FORM_TEMPLATE_DELETED',
        }).then((response) => {
          if (response.status !== 200) {
            this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
          }
        });
      };

      this.$confirm({
        title: this.$t('formTemplateList.deleteTitle'),
        okText: this.$t('general.yesDelete'),
        okType: 'danger',
        cancelText: this.$t('general.cancel'),
        maskClosable: true,
        onOk() {
          deleteEntity(formTemplate);
        },
      });
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">

</style>
