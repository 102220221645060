<template>
  <m-content
    padding
    class="reset-account-card"
  >
    <m-section
      :title="$t('resetAccountCard.heading')"
      heading-size="h4"
    />
    <m-alert
      type="info"
      class="_alert"
    >
      <!--eslint-disable vue/no-v-html-->
      <div
        class="_description"
        v-html="$t('resetAccountCard.description')"
      />
      <!--eslint-enable vue/no-v-html-->
    </m-alert>
    <m-form-item>
      <m-switch
        v-model:value="payload.allGoals"
        :label="$t('resetAccountCard.goalLabel')"
        color="primary"
      />
    </m-form-item>
    <m-form-item>
      <m-switch
        v-model:value="payload.allUpdates"
        :label="$t('resetAccountCard.updateLabel')"
        color="primary"
      />
    </m-form-item>
    <m-form-item>
      <m-switch
        v-model:value="payload.demoUsers"
        :label="$t('resetAccountCard.demoUserLabel')"
        color="primary"
      />
    </m-form-item>
    <div class="_actions">
      <m-btn
        @click="emitClose"
      >
        {{ $t('general.cancel') }}
      </m-btn>
      <m-btn
        color="danger"
        class="_submit"
        :disabled="!canSubmit"
        :loading="loading"
        @click="showConfirm"
      >
        {{ $t('resetAccountCard.submitButton') }}
      </m-btn>
    </div>
  </m-content>
</template>

<script>
import useAccountSettings from '@/composables/logged-in-user-account/account-settings';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useOnboardingMarker from '@/composables/onboarding/onboarding-marker';
import { doResetAccount } from '@/api';
import { onboardingGuideSteps } from 'shared/constants.json';

export default {
  name: 'ResetAccountCard',
  data() {
    return {
      payload: {
        allGoals: false,
        allUpdates: false,
        demoUsers: false,
      },
      loading: false,
    };
  },
  setup() {
    const { loggedInUserAccount } = useLoggedInUserAccount();
    const accountSettingsSvc = useAccountSettings();
    const { markAsDone, isDone } = useOnboardingMarker(loggedInUserAccount, accountSettingsSvc);
    return { markAsDone, isDone };
  },
  emits: ['close'],
  computed: {
    canSubmit() {
      return this.payload.allGoals || this.payload.demoUsers || this.payload.allUpdates;
    },
  },
  methods: {
    showConfirm() {
      const deletedMethod = this.submit;
      this.$confirm({
        title: this.$t('resetAccountCard.confirmText'),
        okText: this.$t('general.yesDelete'),
        okType: 'danger',
        maskClosable: true,
        cancelText: this.$t('general.cancel'),
        onOk() {
          deletedMethod();
        },
      });
    },
    emitClose() {
      this.$emit('close');
    },
    submit() {
      this.loading = true;
      doResetAccount(this.payload).then((response) => {
        this.loading = false;
        if (response.status === 401) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.unauthorizedAction') });
          return;
        }
        if (response.status !== 200) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
          return;
        }
        if (!this.isDone(onboardingGuideSteps.deleteDemoData)) {
          this.markAsDone([onboardingGuideSteps.deleteDemoData]);
        }
        this.$emit('close');
        this.$showSnackbar({ color: 'success', message: this.$t('success.deleted') });
      });
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">

  .reset-account-card {
    ._alert {
      margin: 2rem 0;
    }

    ._actions {
      display: flex;
      justify-content: flex-end;
      margin-top: 2rem;

      ._submit {
        margin-left: .8rem;
      }
    }
  }

</style>
