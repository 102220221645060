import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useRepo from '@/nebula/repo';
import { computed } from 'vue';
import { findViews } from '@/lib/saved-view/saved-view';
import { savedView as savedViewConfig, selectedView as selectedViewConfig } from 'shared/api/query/configs.json';

export default function useSelectedViews() {
  const { selectSingle } = useRepo(savedViewConfig.model);
  const { entityList: selectedViews, deleteSingle, updateSingle, mutateSingle } = useRepo(selectedViewConfig.model);
  const { loggedInUser } = useLoggedInUser();

  const getSelectedViewForApplication = ({ application, space = null, planning = null, user = null, gridPageTile = null }) => computed(() => {
    const filtered = findViews({ views: selectedViews.value, application, space, planning, user, gridPageTile });

    if (filtered.length === 0) {
      const newSelectedView = {
        uid: 0,
        view: { uid: 0 },
        viewApplication: application,
        user: { uid: loggedInUser.value.uid },
      };
      if (space !== null) {
        newSelectedView.space = space;
      }
      if (planning !== null) {
        newSelectedView.planning = planning;
      }
      if (gridPageTile !== null) {
        newSelectedView.gridPageTile = gridPageTile;
      }
      return newSelectedView;
    }

    return filtered[0];
  });

  const getSelectedViewViewForApplication = ({ application, space = null, planning = null, user = null, gridPageTile = null }) => computed(() => {
    const viewId = getSelectedViewViewIdForApplication({
      application,
      space,
      planning,
      user,
      gridPageTile,
    }).value;
    return selectSingle(viewId);
  });

  const getSelectedViewViewIdForApplication = ({ application, space = null, planning = null, user = null, gridPageTile = null }) => computed(() => {
    const v = getSelectedViewForApplication({
      application,
      space,
      planning,
      user,
      gridPageTile,
    }).value;
    if (v === null || v.view === null) {
      return undefined;
    }

    return v.view.uid;
  });

  const localSaveOrUpdateSelectedView = (selectedView, viewId) => {
    if (selectedView.uid === 0) {
      return;
    }

    const entity = {
      ...selectedView,
      view: { uid: viewId },
    };

    updateSingle(entity, { commitToRemote: false });
  };

  const saveOrUpdateSelectedView = (selectedView, viewId) => {
    if (selectedView.view.uid === viewId || viewId === 0) {
      return new Promise((resolve) => { resolve(); });
    }
    // Optimistic update
    localSaveOrUpdateSelectedView(selectedView, viewId);
    return mutateSingle(
      {
        ...selectedView,
        view: { uid: viewId },
      },
    );
  };

  const deleteSelectedView = (selectedView) => deleteSingle(selectedView.uid);

  return {
    getSelectedViewForApplication,
    getSelectedViewViewForApplication,
    getSelectedViewViewIdForApplication,
    localSaveOrUpdateSelectedView,
    saveOrUpdateSelectedView,
    deleteSelectedView,
  };
}
