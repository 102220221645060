import apiConfig, { asanaQuery, goal, hubspotQuery, jiraQuery, property as propertyConfig, propertyOption, propertyValue, salesforceQuery, space, spreadsheetCell } from 'shared/api/query/configs.json';
import { AND, TYPE, UID, VAR } from 'shared/api/query/constants';
import { OR } from 'shared/lib/access';
import { combine, eqFilter, hasFilter, notFilter } from 'shared/api/query/filter';
import { dataAutomation, goalProgressMeasurement, propertyType } from 'shared/constants.json';
import { goalsByType } from '@/api/query/goal-insights-performance';
import { goalsInCycleVarBlock } from '@/api/query/nebula/goal';
import { reverseEdge } from 'shared/api/query/edges';
import { uniq } from 'lodash-es';

const measurementTypesFilter = (filter, measurementTypes) => combine(AND, [
  eqFilter(goal.edges.progressMeasurement, measurementTypes),
  filter,
]);

const goalChildrenVar = (cycleGoalsVar, name) => ({
  alias: VAR,
  needsVar: [
    { name: cycleGoalsVar, typ: 1 },
  ],
  func: {
    name: UID,
    needsVar: [
      { name: cycleGoalsVar, typ: 1 },
    ],
  },
  model: goal.model,
  children: [
    {
      attr: goal.edges.parents,
      model: goal.model,
      children: [
        {
          attr: reverseEdge(goal.edges.parents),
          model: goal.model,
          children: [
            {
              attr: UID,
              var: name,
            },
          ],
        },
      ],
    },
  ],
});

const getDataSourceVar = (model, name) => ({
  alias: VAR,
  func: { name: TYPE, args: [{ value: model, typ: 1 }] },
  model,
  children: [
    {
      attr: apiConfig[model].edges.goal,
      model: goal.model,
      children: [
        {
          attr: UID,
          var: name,
        },
      ],
    },
  ],
});

const viaVarsFilter = (vars) => ({
  func: {
    name: UID,
    needsVar: vars.map((v) => ({ name: v, typ: 1 })),
  },
});

const automatedFilter = (filter, viaDatasourceVars) => combine(AND, [
  eqFilter(goal.edges.progressMeasurement, [goalProgressMeasurement.continuous, goalProgressMeasurement.threshold]),
  viaVarsFilter(viaDatasourceVars),
  filter,
]);

const manualFilter = (filter, viaDatasourceVars) => combine(AND, [
  eqFilter(goal.edges.progressMeasurement, [goalProgressMeasurement.continuous, goalProgressMeasurement.threshold]),
  notFilter(viaVarsFilter(viaDatasourceVars)),
  filter,
]);

const getChildrenOfAllowedParentTypeVar = (parentTypeUids, name) => ({
  alias: VAR,
  func: { name: UID },
  uid: parentTypeUids,
  model: propertyOption.model,
  children: [
    {
      attr: reverseEdge(propertyValue.edges.selectedOptions),
      model: propertyValue.model,
      children: [
        {
          attr: reverseEdge(goal.edges.properties),
          model: goal.model,
          children: [
            {
              attr: reverseEdge(goal.edges.parents),
              model: goal.model,
              children: [
                {
                  attr: UID,
                  var: name,
                },
              ],
            },
          ],
        },
      ],
    },
  ],
});

const parentTypeFilter = (viaAllowedParentTypeVars, parentRequired, isChildrenVar) => {
  let extraFilters = [];
  if (viaAllowedParentTypeVars.length > 0) {
    extraFilters.push(viaVarsFilter(viaAllowedParentTypeVars));
  }

  if (parentRequired === false) {
    extraFilters = [combine(OR, [
      ...extraFilters,
      notFilter(viaVarsFilter([isChildrenVar])),
    ])];
  }
  return extraFilters;
};

const allowedParentTypeFilter = (filter, viaAllowedParentTypeVars, parentRequired, isChildrenVar) => combine(AND, [
  ...parentTypeFilter(viaAllowedParentTypeVars, parentRequired, isChildrenVar),
  filter,
]);

const deniedParentTypeFilter = (filter, viaAllowedParentTypeVars, parentRequired, isChildrenVar) => combine(AND, [
  notFilter(parentTypeFilter(viaAllowedParentTypeVars, parentRequired, isChildrenVar)),
  filter,
]);

const withVarFilter = (filter, varName) => combine(AND, [
  {
    func: {
      name: UID,
      needsVar: [
        { name: varName, typ: 1 },
      ],
    },
  },
  filter,
]);

const withNotVarFilter = (filter, varName) => combine(AND, [
  notFilter([
    {
      func: {
        name: UID,
        needsVar: [
          { name: varName, typ: 1 },
        ],
      },
    },
  ]),
  filter,
]);

const goalsViaSpacesVarBlock = (uids, name) => ({
  alias: VAR,
  func: { name: UID },
  uid: uids,
  model: space.model,
  children: [
    {
      attr: reverseEdge(propertyValue.edges.spaces),
      model: propertyValue.model,
      children: [
        {
          attr: reverseEdge(goal.edges.properties),
          model: goal.model,
          children: [
            {
              attr: UID,
              var: name,
            },
          ],
        },
      ],
    },
  ],
});

const goalsViaPropertyOptionsVarBlock = (uids, name) => ({
  alias: VAR,
  func: { name: UID },
  uid: uids,
  model: propertyOption.model,
  children: [
    {
      attr: reverseEdge(propertyValue.edges.selectedOptions),
      model: propertyValue.model,
      children: [
        {
          attr: reverseEdge(goal.edges.properties),
          model: goal.model,
          children: [
            {
              attr: UID,
              var: name,
            },
          ],
        },
      ],
    },
  ],
});

const getGoalsForCycleWithFilter = (alias, cycleGoalsVar, filter) => ({
  alias,
  model: goal.model,
  needsVar: [
    { name: cycleGoalsVar, typ: 1 },
  ],
  func: {
    name: UID,
    needsVar: [
      { name: cycleGoalsVar, typ: 1 },
    ],
  },
  filter,
  default: [],
  children: [
    { attr: UID },
  ],
});

const goalsByMeasurementTypes = (prefix, goalTypeOptionVar, measurementTypes, cycleGoalsVar, filter) => measurementTypes.map((measurementType) => getGoalsForCycleWithFilter(`${prefix}${measurementType}`, cycleGoalsVar, withVarFilter(measurementTypesFilter(filter, [measurementType]), goalTypeOptionVar)));

export const queryMeasurementTypes = ({
  goalCycles = [],
  filter = null,
  varBlocks = [],
  goalTypeOption = null,
}) => {
  const cycleGoalsVar = 'cycle_goals';
  const goalTypeOptionVarBlocks = goalsViaPropertyOptionsVarBlock([goalTypeOption.uid], `goalType_${goalTypeOption.uid}`);

  return [
    ...goalsInCycleVarBlock({ goalCycles, filter, varName: cycleGoalsVar }),
    goalTypeOptionVarBlocks,
    ...goalsByMeasurementTypes('', `goalType_${goalTypeOption.uid}`, goalProgressMeasurement.all, cycleGoalsVar, filter),
    ...varBlocks,
  ];
};

export const hasRuleInvalidMeasurementTypes = (goalTypeOption) => goalTypeOption.allowedMeasurementTypes.length > 0
    && goalTypeOption.allowedMeasurementTypes.length < goalProgressMeasurement.all.length;

const goalsByWrongMeasurementTypes = (prefix, goalTypeOption, goalTypeOptionVar, cycleGoalsVar, filter) => {
  const wrongMeasurementTypes = goalProgressMeasurement.all.filter((mType) => !goalTypeOption.allowedMeasurementTypes.includes(mType));
  return goalsByMeasurementTypes(`${prefix}_`, goalTypeOptionVar, wrongMeasurementTypes, cycleGoalsVar, filter);
};

export const queryOverviewMeasurementTypes = ({
  goalCycles = [],
  filter = null,
  varBlocks = [],
  goalTypeOptions = [],
}) => {
  const cycleGoalsVar = 'cycle_goals';
  const goalTypesWithInvalidMeasurementTypes = goalTypeOptions.filter((goalTypeOption) => hasRuleInvalidMeasurementTypes(goalTypeOption));
  const goalTypeOptionsVarBlocks = goalTypesWithInvalidMeasurementTypes.map((goalTypeOption) => goalsViaPropertyOptionsVarBlock([goalTypeOption.uid], `goalType_${goalTypeOption.uid}`));

  return [
    ...goalsInCycleVarBlock({ goalCycles, filter, varName: cycleGoalsVar }),
    ...goalTypeOptionsVarBlocks,
    ...goalTypesWithInvalidMeasurementTypes.map((goalTypeOption) => goalsByWrongMeasurementTypes(`goalType_${goalTypeOption.uid}`, goalTypeOption, `goalType_${goalTypeOption.uid}`, cycleGoalsVar, filter)).flat(),
    ...varBlocks,
  ];
};

const goalsByAutomated = (goalTypeOption, cycleGoalsVar, filter) => {
  const datasourceModels = [spreadsheetCell.model, jiraQuery.model, hubspotQuery.model, asanaQuery.model, salesforceQuery.model];
  const res = datasourceModels.map((model) => getDataSourceVar(model, `via_${model}`));
  const viaDatasourcesVar = datasourceModels.map((model) => (`via_${model}`));
  res.push(...[
    goalsViaPropertyOptionsVarBlock([goalTypeOption.uid], `goalType_${goalTypeOption.uid}`),
    getGoalsForCycleWithFilter(dataAutomation.automatic, cycleGoalsVar, withVarFilter(automatedFilter(filter, viaDatasourcesVar), `goalType_${goalTypeOption.uid}`)),
    getGoalsForCycleWithFilter(dataAutomation.manual, cycleGoalsVar, withVarFilter(manualFilter(filter, viaDatasourcesVar), `goalType_${goalTypeOption.uid}`)),
  ]);
  return res;
};

export const queryAutomated = ({
  goalCycles = [],
  filter = null,
  varBlocks = [],
  goalTypeOption = null,
}) => {
  const cycleGoalsVar = 'cycle_goals';
  return [
    ...goalsInCycleVarBlock({ goalCycles, filter, varName: cycleGoalsVar }),
    ...goalsByAutomated(goalTypeOption, cycleGoalsVar, filter),
    ...varBlocks,
  ];
};

const goalsByParentTypeRestriction = (goalTypeOption, isChildrenVar, cycleGoalsVar, filter) => {
  const res = goalTypeOption.canBeChildOf.map((parentType) => getChildrenOfAllowedParentTypeVar([parentType.uid], `via_parentType_${parentType.uid}`));
  const viaAllowedParentsVars = goalTypeOption.canBeChildOf.map((parentType) => (`via_parentType_${parentType.uid}`));
  res.push(...[
    goalsViaPropertyOptionsVarBlock([goalTypeOption.uid], `goalType_${goalTypeOption.uid}`),
    getGoalsForCycleWithFilter('right', cycleGoalsVar, withVarFilter(allowedParentTypeFilter(filter, viaAllowedParentsVars, goalTypeOption.parentRequired, isChildrenVar), `goalType_${goalTypeOption.uid}`)),
    getGoalsForCycleWithFilter('wrong', cycleGoalsVar, withVarFilter(deniedParentTypeFilter(filter, viaAllowedParentsVars, goalTypeOption.parentRequired, isChildrenVar), `goalType_${goalTypeOption.uid}`)),
  ]);
  return res;
};

export const queryParentType = ({
  goalCycles = [],
  filter = null,
  varBlocks = [],
  goalTypeOption = null,
}) => {
  const cycleGoalsVar = 'cycle_goals';
  const isChildrenVar = 'isChildren';
  const goalIsChildrenVarBlock = goalTypeOption.parentRequired === false ? [goalChildrenVar(cycleGoalsVar, isChildrenVar)] : [];
  return [
    ...goalsInCycleVarBlock({ goalCycles, filter, varName: cycleGoalsVar }),
    ...goalIsChildrenVarBlock,
    ...goalsByParentTypeRestriction(goalTypeOption, isChildrenVar, cycleGoalsVar, filter),
    ...varBlocks,
  ];
};

export const hasRuleInvalidParentType = (goalTypeOption, goalTypeProperty) => goalTypeOption.parentRequired === true || goalTypeOption.canBeChildOf.length < goalTypeProperty.options.length;

const goalsByWrongParentType = (prefix, goalTypeProperty, goalTypeOption, goalTypeOptionVar, isChildrenVar, cycleGoalsVar, filter) => {
  const viaAllowedParentsVars = goalTypeOption.canBeChildOf.map((parentType) => (`via_parentType_${parentType.uid}`));
  return [getGoalsForCycleWithFilter(`${prefix}_wrong`, cycleGoalsVar, withVarFilter(deniedParentTypeFilter(filter, viaAllowedParentsVars, goalTypeOption.parentRequired, isChildrenVar), goalTypeOptionVar))];
};

export const queryOverviewParentType = ({
  goalCycles = [],
  filter = null,
  varBlocks = [],
  goalTypeProperty = { options: [] },
  goalTypeOptions = [],
}) => {
  const cycleGoalsVar = 'cycle_goals';
  const goalTypesWithInvalidParents = goalTypeOptions.filter((goalTypeOption) => hasRuleInvalidParentType(goalTypeOption, goalTypeProperty));
  const goalTypeOptionsVarBlocks = goalTypesWithInvalidParents.map((goalTypeOption) => goalsViaPropertyOptionsVarBlock([goalTypeOption.uid], `goalType_${goalTypeOption.uid}`));
  const parentGoalTypeOptionsCanBeChildOf = uniq(goalTypesWithInvalidParents.map((goalTypeOption) => goalTypeOption.canBeChildOf.map((parentType) => parentType.uid)).flat());
  const parentGoalTypeOptionsVarBlocks = parentGoalTypeOptionsCanBeChildOf.map((parentTypeUid) => getChildrenOfAllowedParentTypeVar([parentTypeUid], `via_parentType_${parentTypeUid}`));
  const isChildrenVar = 'isChildren';
  const goalIsChildrenVarBlock = goalTypesWithInvalidParents.filter((goalTypeOption) => goalTypeOption.parentRequired === false).length > 0 ? [goalChildrenVar(cycleGoalsVar, isChildrenVar)] : [];

  return [
    ...goalsInCycleVarBlock({ goalCycles, filter, varName: cycleGoalsVar }),
    ...goalIsChildrenVarBlock,
    ...goalTypeOptionsVarBlocks,
    ...parentGoalTypeOptionsVarBlocks,
    ...goalTypesWithInvalidParents.map((goalTypeOption) => goalsByWrongParentType(`goalType_${goalTypeOption.uid}`, goalTypeProperty, goalTypeOption, `goalType_${goalTypeOption.uid}`, isChildrenVar, cycleGoalsVar, filter)).flat(),
    ...varBlocks,
  ];
};

const goalsForRequiredPropertyVar = (name, property) => {
  let filter;
  switch (property.type) {
    case propertyType.space:
      filter = hasFilter(propertyValue.edges.spaces);
      break;
    case propertyType.options:
    case propertyType.singleSelect:
      filter = hasFilter(propertyValue.edges.selectedOptions);
      break;
    case propertyType.text:
    case propertyType.url:
      filter = hasFilter(propertyValue.edges.text);
      break;
    case propertyType.date:
      filter = hasFilter(propertyValue.edges.timestamp);
      break;
    case propertyType.number:
      filter = hasFilter(propertyValue.edges.number);
      break;
    case propertyType.user:
      filter = hasFilter(propertyValue.edges.users);
      break;
    case propertyType.lookup:
    default:
      return null;
  }
  return {
    alias: VAR,
    func: { name: UID },
    uid: [property.uid],
    model: propertyConfig.model,
    children: [
      {
        attr: reverseEdge(propertyValue.edges.property),
        model: propertyValue.model,
        filter,
        children: [
          {
            attr: reverseEdge(goal.edges.properties),
            model: goal.model,
            children: [
              {
                attr: UID,
                var: name,
              },
            ],
          },
        ],
      },
    ],
  };
};

function buildRequiredsScopeTree(prefix, goalTypeOption) {
  const res = { filterTrees: [], varBlocks: [] };
  if (goalTypeOption.descriptionRequired === true) {
    res.filterTrees.push(combine(AND, [
      hasFilter(goal.edges.description),
      notFilter(eqFilter(goal.edges.description, [''])),
    ]));
  }
  if (goalTypeOption.cycleRequired === true) {
    res.filterTrees.push(hasFilter(goal.edges.goalCycle));
  }
  goalTypeOption.requiredProperties.forEach((propertyRequired) => {
    const prVar = goalsForRequiredPropertyVar(`${prefix}goalType_${goalTypeOption.uid}_via_property_${propertyRequired.uid}`, propertyRequired);
    if (prVar !== null) {
      res.varBlocks.push(prVar);
      res.filterTrees.push(viaVarsFilter([`${prefix}goalType_${goalTypeOption.uid}_via_property_${propertyRequired.uid}`]));
    }
  });
  return res;
}

const goalsByPropertiesRequiredRestriction = (goalTypeOption, cycleGoalsVar, filter) => {
  const res = [goalsViaPropertyOptionsVarBlock([goalTypeOption.uid], `goalType_${goalTypeOption.uid}`)];
  const requiredScope = buildRequiredsScopeTree('', goalTypeOption);
  res.push(...requiredScope.varBlocks);
  res.push(...[
    getGoalsForCycleWithFilter('right', cycleGoalsVar, withVarFilter(combine(AND, [...requiredScope.filterTrees, filter]), `goalType_${goalTypeOption.uid}`)),
    getGoalsForCycleWithFilter('wrong', cycleGoalsVar, withVarFilter(combine(AND, [notFilter(combine(AND, requiredScope.filterTrees)), filter]), `goalType_${goalTypeOption.uid}`)),
  ]);
  return res;
};

export const queryPropertiesRequired = ({
  goalCycles = [],
  filter = null,
  varBlocks = [],
  goalTypeOption = null,
}) => {
  const cycleGoalsVar = 'cycle_goals';
  return [
    ...goalsInCycleVarBlock({ goalCycles, filter, varName: cycleGoalsVar }),
    ...goalsByPropertiesRequiredRestriction(goalTypeOption, cycleGoalsVar, filter),
    ...varBlocks,
  ];
};

export const hasRuleInvalidProperties = (goalTypeOption) => goalTypeOption.descriptionRequired === true || goalTypeOption.cycleRequired === true || goalTypeOption.requiredProperties.length > 0;

const goalsByWrongPropertiesRequired = (prefix, goalTypeOption, goalTypeOptionVar, cycleGoalsVar, filter) => {
  const res = [];
  const requiredScope = buildRequiredsScopeTree(prefix, goalTypeOption);
  if (requiredScope.filterTrees.length === 0) {
    return res;
  }
  res.push(...requiredScope.varBlocks);
  res.push(...[
    getGoalsForCycleWithFilter(`${prefix}_wrong`, cycleGoalsVar, withVarFilter(combine(AND, [notFilter(combine(AND, requiredScope.filterTrees)), filter]), goalTypeOptionVar)),
  ]);
  return res;
};

export const queryOverviewPropertiesRequired = ({
  goalCycles = [],
  filter = null,
  varBlocks = [],
  goalTypeOptions = [],
}) => {
  const cycleGoalsVar = 'cycle_goals';
  const goalTypesWithInvalidProperties = goalTypeOptions.filter((goalTypeOption) => hasRuleInvalidProperties(goalTypeOption));
  const goalTypeOptionsVarBlocks = goalTypesWithInvalidProperties.map((goalTypeOption) => goalsViaPropertyOptionsVarBlock([goalTypeOption.uid], `goalType_${goalTypeOption.uid}`));
  return [
    ...goalsInCycleVarBlock({ goalCycles, filter, varName: cycleGoalsVar }),
    ...goalTypeOptionsVarBlocks,
    ...goalTypesWithInvalidProperties.map((goalTypeOption) => goalsByWrongPropertiesRequired(`goalType_${goalTypeOption.uid}`, goalTypeOption, `goalType_${goalTypeOption.uid}`, cycleGoalsVar, filter)).flat(),
    ...varBlocks,
  ];
};

export const queryGoalsCount = ({
  goalCycles = [],
  filter = null,
  varBlocks = [],
  goalTypeOptions = [],
}) => {
  const cycleGoalsVar = 'cycle_goals';
  return [
    ...goalsInCycleVarBlock({ goalCycles, filter, varName: cycleGoalsVar }),
    getGoalsForCycleWithFilter('goalsTotalIds', cycleGoalsVar, filter),
    ...goalsByType('goalsByTypeCount', goalTypeOptions, cycleGoalsVar, filter),
    ...varBlocks,
  ];
};

export const queryInsights = ({
  goalCycles = [],
  filter = null,
  varBlocks = [],
  teams = [],
  goalTypeProperty = { options: [] },
  goalTypeOptions = [],
}) => {
  const cycleGoalsVar = 'cycle_goals';

  const goalTypesWithInvalidProperties = goalTypeOptions.filter((goalTypeOption) => hasRuleInvalidProperties(goalTypeOption));
  const goalTypesWithInvalidParents = goalTypeOptions.filter((goalTypeOption) => hasRuleInvalidParentType(goalTypeOption, goalTypeProperty));
  const goalTypesWithInvalidMeasurementTypes = goalTypeOptions.filter((goalTypeOption) => hasRuleInvalidMeasurementTypes(goalTypeOption));
  const goalTypesWithInvalid = uniq([
    ...goalTypesWithInvalidProperties,
    ...goalTypesWithInvalidParents,
    ...goalTypesWithInvalidMeasurementTypes,
  ]);

  const goalTypeOptionsVarBlocks = goalTypesWithInvalid.map((goalTypeOption) => goalsViaPropertyOptionsVarBlock([goalTypeOption.uid], `goalType_${goalTypeOption.uid}`));
  const parentGoalTypeOptionsCanBeChildOf = uniq(goalTypesWithInvalidParents.map((goalTypeOption) => goalTypeOption.canBeChildOf.map((parentType) => parentType.uid)).flat());
  const parentGoalTypeOptionsVarBlocks = parentGoalTypeOptionsCanBeChildOf.map((parentTypeUid) => getChildrenOfAllowedParentTypeVar([parentTypeUid], `via_parentType_${parentTypeUid}`));

  const isChildrenVar = 'isChildren';
  const goalIsChildrenVarBlock = goalTypesWithInvalidParents.filter((goalTypeOption) => goalTypeOption.parentRequired === false).length > 0 ? [goalChildrenVar(cycleGoalsVar, isChildrenVar)] : [];

  const perTeamInsights = (prefix, varBlock, filter) => [
    varBlock,
    getGoalsForCycleWithFilter(`${prefix}_count`, cycleGoalsVar, filter),
    ...goalTypesWithInvalidProperties.map((goalTypeOption) => goalsByWrongPropertiesRequired(`${prefix}_props_goalType_${goalTypeOption.uid}`, goalTypeOption, `goalType_${goalTypeOption.uid}`, cycleGoalsVar, filter)).flat(),
    ...goalTypesWithInvalidParents.map((goalTypeOption) => goalsByWrongParentType(`${prefix}_parents_goalType_${goalTypeOption.uid}`, goalTypeProperty, goalTypeOption, `goalType_${goalTypeOption.uid}`, isChildrenVar, cycleGoalsVar, filter)).flat(),
    ...goalTypesWithInvalidMeasurementTypes.map((goalTypeOption) => goalsByWrongMeasurementTypes(`${prefix}_mtypes_goalType_${goalTypeOption.uid}`, goalTypeOption, `goalType_${goalTypeOption.uid}`, cycleGoalsVar, filter)).flat(),
  ];

  const perTeamFilters = teams.map(({ uid }) => {
    const prefix = `team${uid}`;
    const teamVarBlock = goalsViaSpacesVarBlock([uid], prefix);
    const withTeamFilter = withVarFilter(filter, prefix);

    return perTeamInsights(prefix, teamVarBlock, withTeamFilter);
  }).flat();

  const noTeamPrefix = 'noTeam';
  const noTeamVarBlock = goalsViaSpacesVarBlock(teams.map((o) => o.uid), noTeamPrefix);
  const withNoTeamFilter = withNotVarFilter(filter, noTeamPrefix);
  const noTeamFilters = perTeamInsights(noTeamPrefix, noTeamVarBlock, withNoTeamFilter);

  return [
    ...goalsInCycleVarBlock({ goalCycles, filter, varName: cycleGoalsVar }),
    ...goalIsChildrenVarBlock,
    ...goalTypeOptionsVarBlocks,
    ...parentGoalTypeOptionsVarBlocks,
    ...perTeamFilters,
    ...noTeamFilters,
    ...varBlocks,
  ];
};
