<template>
  <m-card-item
    class="saved-view-item"
    :active="active"
    :style="{'font-weight': '500'}"
    :data-id="view.uid"
    @click="selectView(view)"
  >
    <div
      v-if="showDragHandle"
      class="_icon"
    >
      <m-icon
        type="drag"
        :color="$colors.grey.base"
      />
    </div>
    <saved-view-title
      :view="view"
      :show-visibility-hint="showVisibilityHint"
    />
    <div
      v-if="!readOnly && hasUnsavedChanges"
      class="_dot"
    />
    <template #right>
      <div
        class="_actions"
      >
        <m-dropdown
          v-if="showActions"
          v-model:value="showEditor"
          placement="topRight"
          :title="$t('savedViewSelector.editView')"
        >
          <m-btn
            hide-border
            icon="ellipsis"
            fab
            xs
            @click.stop="showEditor = true"
          />
          <template #overlay>
            <m-card
              no-padding
              list
            >
              <saved-view-editor
                :entity="view"
                :params="view.params"
                :goal-sort="view.goalSort"
                :allowed-view-types="allowedViewTypes"
                :view-application="viewApplication"
                :show-visibility-switch="showVisibilitySwitch"
                :disable-delete="disableDelete"
                :is-selected="active"
                @update="updateView"
                @delete="deleteView"
              />
            </m-card>
          </template>
        </m-dropdown>
      </div>
    </template>
  </m-card-item>
</template>

<script>
import SavedViewEditor from '@/components/list/SavedViewEditor.vue';
import SavedViewTitle from '@/components/list/SavedViewTitle.vue';
import useAccess from '@/composables/access/access';
import { accessGroupFlag, viewApplication, viewType } from 'shared/constants.json';
import { iconByType } from '@/lib/saved-view/saved-view';

export default {
  name: 'SavedViewItem',
  props: {
    view: {
      type: Object,
      required: true,
    },
    allowedViewTypes: {
      type: Array,
      default: () => [],
    },
    viewApplication: {
      type: String,
      default: '',
    },
    active: {
      type: Boolean,
      default: false,
    },
    showDragHandle: {
      type: Boolean,
      default: false,
    },
    showVisibilityHint: {
      type: Boolean,
      default: false,
    },
    showVisibilitySwitch: {
      type: Boolean,
      default: false,
    },
    autoOpen: {
      type: Boolean,
      default: false,
    },
    hasUnsavedChanges: {
      type: Boolean,
      default: false,
    },
    disableDelete: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['select', 'update', 'delete', 'close-editor'],
  components: { SavedViewTitle, SavedViewEditor },
  setup() {
    const { userHasRight } = useAccess();
    return { userHasRight };
  },
  data() {
    return { showEditor: false, viewType };
  },
  computed: {
    typeIcon() {
      return iconByType(this.view.viewType);
    },
    hasPublicViewAccess() {
      return this.userHasRight([accessGroupFlag.publicSavedViews]);
    },
    showActions() {
      if (this.readOnly) {
        return false;
      }
      if (this.view.isTemp) {
        return false;
      }
      if (!this.view.isPublic) {
        return true;
      }
      return this.hasPublicViewAccess || ![
        viewApplication.goal,
        viewApplication.update,
        viewApplication.updateFeed,
        viewApplication.goalAccount,
        viewApplication.updateAccount,
        viewApplication.updateFeedAccount,
      ].includes(this.viewApplication);
    },
  },
  methods: {
    updateView({ view, emitClose }) {
      if (emitClose) {
        this.showEditor = false;
      }
      this.$emit('update', view);
    },
    deleteView({ view }) {
      this.showEditor = false;
      this.$emit('delete', { view });
    },
    selectView(view) {
      this.$emit('select', { view });
    },
  },
  watch: {
    autoOpen: {
      handler(newVal) {
        if (!this.$store.state.breakpoint.smAndDown) {
          return;
        }
        if (newVal === true) {
          this.showEditor = true;
        }
        this.$emit('close-editor');
      },
      immediate: true,
    },
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>

  .saved-view-item {
    ._icon {
      margin: 0 .4rem 0 -.3rem;
      cursor: grab;
    }

    ._dot {
      width: .6rem;
      height: .6rem;
      margin-left: .6rem;
      background-color: map_get($yellow, 'base');
      border-radius: .3rem;
    }

    ._actions {
      display: flex;
      align-items: center;
    }
  }

</style>
