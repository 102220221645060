import {
  DIRECT_PROPERTY_CYCLE_KEY,
  DIRECT_PROPERTY_DESCRIPTION_KEY,
  DIRECT_PROPERTY_PARENT_KEY, DIRECT_PROPERTY_PROGRESS_KEY,
} from '@/lib/constants';
import {
  propertyOption as propertyOptionConfig,
} from 'shared/api/query/configs.json';

export const getVisibilityEdge = (key) => {
  switch (key) {
    case DIRECT_PROPERTY_DESCRIPTION_KEY:
      return propertyOptionConfig.edges.descriptionVisibility;
    case DIRECT_PROPERTY_CYCLE_KEY:
      return propertyOptionConfig.edges.goalCycleVisibility;
    case DIRECT_PROPERTY_PARENT_KEY:
      return propertyOptionConfig.edges.goalParentVisibility;
    case DIRECT_PROPERTY_PROGRESS_KEY:
      return propertyOptionConfig.edges.goalProgressVisibility;
    default:
      return '';
  }
};
