import { mapActions } from 'vuex';

export default {
  data() {
    return { deleteLoading: false };
  },
  methods: {
    ...mapActions([
      'deleteEntities',
    ]),
    showDeleteModal({ entities, redirect, model, mutation = '' }) {
      const { deleteMethod, closeModal } = this;
      const confirm = this.$confirm;
      const title = this.$t('general.deletePrompt');
      const okText = this.$t('general.yesDelete');
      const cancelText = this.$t('general.cancel');

      return () => {
        confirm({
          title,
          okText,
          okType: 'danger',
          cancelText,
          maskClosable: true,
          onOk() {
            deleteMethod({ entities, redirect, model, mutation });
            if (typeof closeModal !== 'undefined') {
              closeModal();
            }
          },
        });
      };
    },
    deleteMethod({ entities, redirect, model, mutation = '' }) {
      this.deleteLoading = true;
      const errorMessage = this.$t('error.default');
      return this.deleteEntities({
        entities,
        model,
        mutation,
        onDelete: this.redirect(redirect),
        maskClosable: true,
      }).then((response) => {
        this.deleteLoading = false;
        if (response.status !== 200) {
          this.$showSnackbar({ color: 'error', message: errorMessage });
          return response;
        }

        return response;
      });
    },
    onDelete() {},
    redirect(redirect) {
      const router = this.$router;
      const { onDelete } = this;
      return () => {
        onDelete();
        if (typeof redirect !== 'undefined' && Object.keys(redirect).length > 0) {
          router.push(redirect);
        }
      };
    },
  },
};
