import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useRepo from '@/nebula/repo';
import { EVENTS } from '@/lib/constants';
import { EventBus } from '@/lib/event-bus';
import { goalCycle as goalCycleConfig } from 'shared/api/query/configs.json';

export default function useGoalCycle() {
  const model = goalCycleConfig.model;
  const repo = useRepo(model);

  const { loggedInUser } = useLoggedInUser();
  const { loggedInUserAccount } = useLoggedInUserAccount();

  const previousCycle = (from = new Date()) => repo.entityList.value.reduce((res, next) => {
    const nextEnd = Date.parse(next.end);
    if ((nextEnd - from) > 0) {
      return res;
    }

    if ((nextEnd - from) < 0 && res === null) {
      return next;
    }
    const nextStart = Date.parse(next.start);
    const nextDistance = nextEnd - nextStart;

    const existingStart = Date.parse(res.start);
    const existingEnd = Date.parse(res.end);
    const existingDistance = existingEnd - existingStart;

    if ((nextEnd - from) < 0) {
      if (Math.abs(nextEnd - from) < Math.abs(existingEnd - from)
        || (Math.abs(nextEnd - from) === Math.abs(existingEnd - from) && existingDistance > nextDistance)) {
        return next;
      }
    }

    return res;
  }, null);

  const nextCycle = (from = new Date()) => repo.entityList.value.reduce((res, next) => {
    const nextStart = Date.parse(next.start);
    if ((nextStart - from) < 0) {
      return res;
    }

    if ((nextStart - from) > 0 && res === null) {
      return next;
    }
    const nextEnd = Date.parse(next.start);
    const nextDistance = nextEnd - nextStart;

    const existingStart = Date.parse(res.start);
    const existingEnd = Date.parse(res.end);
    const existingDistance = existingEnd - existingStart;
    if ((nextStart - from) > 0) {
      if (Math.abs(nextStart - from) < Math.abs(existingStart - from)
        || (Math.abs(nextStart - from) === Math.abs(existingStart - from) && existingDistance > nextDistance)) {
        return next;
      }
    }

    return res;
  }, null);

  const createGoalCycle = (goalCycle) => repo.createSingle({
    account: { uid: loggedInUserAccount.value.uid },
    creator: { uid: loggedInUser.value.uid },
    ...goalCycle,
  }).then((goalCycle) => {
    EventBus.$emit(EVENTS.PROPERTY.GOAL_CYCLE_CREATED);
    return goalCycle;
  });

  const deleteGoalCycle = (goalCycle) => repo.deleteSingle(goalCycle.uid).then(() => {
    EventBus.$emit(EVENTS.PROPERTY.GOAL_CYCLE_DELETED);
  });

  return {
    model,
    createGoalCycleLoading: repo.createLoading,
    createGoalCycle,

    updateGoalCycleLoading: repo.updateLoading,
    updateGoalCycle: (entity) => repo.updateSingle(entity),

    deleteGoalCycleLoading: repo.deleteLoading,
    deleteGoalCycle,

    previousCycle,
    nextCycle,

    getListLoading: repo.getListLoading,
    getList: repo.getList,

    selectLoading: repo.selectLoading,
    selectMultiple: repo.selectMultiple,
    selectSingle: repo.selectSingle,

    goalCycles: repo.entityList,
  };
}
