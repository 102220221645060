import { UID } from 'shared/api/query/constants';
import { dataSource, meeting, msCalendarEvent, user } from 'shared/api/query/configs.json';

export const children = [
  { attr: UID },
  { attr: msCalendarEvent.edges.creator, model: user.model, children: [{ attr: UID }, { attr: user.edges.email }, { attr: user.edges.firstName }, { attr: user.edges.lastName }] },
  { attr: msCalendarEvent.edges.dataSource, model: dataSource.model, children: [{ attr: UID }], default: null },
  { attr: msCalendarEvent.edges.error, default: '' },
  { attr: msCalendarEvent.edges.eventId },
  { attr: msCalendarEvent.edges.meeting, model: meeting.model, children: [{ attr: UID }, { attr: meeting.edges.date, default: null }] },
  { attr: msCalendarEvent.edges.nativeDate, default: null },
  { attr: msCalendarEvent.edges.weblink, default: null },
];
