<template>
  <div class="error-summary">
    <m-content padding-xxs>
      <goal-properties
        class="_props"
        :properties="properties"
        :goal="goal"
        :can-edit="canEdit"
        :goal-cycles="goalCycles"
        :update-property="updateProperty"
        :validation-errors="errors"
      />
    </m-content>
    <m-divider none />
    <m-content padding-xxs>
      <m-btn
        xs
        super-light
        :href="$t('goalType.helpCenterLink')"
        hide-border
        icon="question-circle"
        target="_blank"
      >
        {{ $t('goalType.learnMore') }}
      </m-btn>
    </m-content>
  </div>
</template>

<script>
import GoalProperties from '@/components/goal/GoalProperties.vue';
import useGoalCycle from '@/composables/goal-cycle/goal-cycle';
import useGoalDetailProperties from '@/composables/customize-page/goal-detail-page-properties';
import useGoalTypeProperty from '@/composables/customize-page/goal-type-property';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import { toRef } from 'vue';

export default {
  name: 'ErrorSummary',
  props: {
    goal: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    updateProperty: {
      type: Function,
      required: true,
    },
  },
  components: { GoalProperties },
  setup(props) {
    const { goalCycles } = useGoalCycle();
    const { loggedInUser } = useLoggedInUser();
    const { goalTypeProperty } = useGoalTypeProperty();
    const goal = toRef(props, 'goal');
    const { sortedGoalDetailPageProperties } = useGoalDetailProperties({
      goal,
      userLang: loggedInUser.value.language,
      goalTypeProperty,
    });

    return { sortedGoalDetailPageProperties, goalCycles };
  },
  data() {
    return { invalidPropValues: [] };
  },
  computed: {
    properties() {
      return this.sortedGoalDetailPageProperties.filter((p) => {
        if (p.uid !== undefined) {
          return this.invalidPropValues.includes(`${p.uid}`);
        }
        return this.invalidPropValues.includes(`${p.type}`);
      });
    },
  },
  created() {
    this.invalidPropValues = Object.keys(this.errors);
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  .error-summary {
    ._props {
      padding: 0 .8rem 0 3rem;
    }
  }
</style>
