<template>
  <div
    ref="headerObject"
    :class="['document-list-header']"
  >
    <list-header
      class="_list-header"
      :tabs-shown="tabsShown"
      @set-header-tight="(val) => headerTight = val"
    >
      <template #left>
        <saved-view-tabs
          v-if="showViewsSelector"
          id="view-selector"
          :right-element-width="rightElementWidth"
          :header-width="headerWidth"
          :header-tight="headerTight"
          :show-create="showCreateView"
          :is-public-default="isPublicDefault"
          :shared-view-order="sharedViewOrder"
          :allowed-view-types="allowedViewTypes"
          :view-application="currentView.viewApplication"
          :read-only="readOnly"
          @tabs-shown-updated="(val) => tabsShown = val"
        />
      </template>
      <template
        #right
      >
        <div
          ref="right"
        >
          <m-slots-container
            v-model:showMenu="showMenu"
            :slots="slotsWithDefaults"
          >
            <template #[SLOTS.DATA_LOADING]>
              <m-icon
                v-if="dataLoading"
                type="loading"
                spin
                :color="$colors.grey.lighten1"
                :style="{ marginRight: '.8rem' }"
              />
            </template>
            <template #[SLOTS.LOCKED]>
              <m-tooltip
                v-if="readOnly"
              >
                <m-btn
                  icon="lock"
                  small
                  light
                  hide-border
                  disabled
                >
                  {{ $t('documentListHeader.locked') }}
                </m-btn>
                <template #title>
                  {{ $t('documentListHeader.lockedHint') }}
                </template>
              </m-tooltip>
            </template>
            <template #[SLOTS.CYCLE_SELECTOR]>
              <goal-cycle-app-settings-selector
                :in-card-menu="slotIsInMenu(SLOTS.CYCLE_SELECTOR)"
                :selected="selectedGoalCycles"
                :change-selected-goal-cycles="changeSelectedGoalCycles"
                :editable="!readOnly"
                with-unassigned
              />
            </template>
            <template #[SLOTS.GROUPING]>
              <component :is="$store.state.breakpoint.smAndDown ? 'm-card-item' : 'div'">
                <m-select
                  :items="groupingItems"
                  :value="currentView.params.grouping"
                  :disabled="locked"
                  small
                  hide-border
                  :hide-hover="$store.state.breakpoint.smAndDown"
                  @change="updateViewParams($event, 'grouping')"
                />
              </component>
            </template>
            <template #[SLOTS.STRING_FILTER]>
              <list-string-filter
                v-if="!slotIsInMenu(SLOTS.FILTER)"
                small
                @input="$emit('string-filter-changed', $event)"
              />
            </template>
            <template #[SLOTS.FILTER]>
              <list-filter
                :in-card-menu="slotIsInMenu(SLOTS.FILTER)"
                :value="filter"
                :props="filterProps"
                :has-dynamic-dates="hasDynamicDateFilters"
                :saved-view="currentView"
                :show-saved-view-info="showSavedViewInfo"
                :multi-model="multiModel"
                :disabled="readOnly"
                :apply-filter-on-first-level-only="currentView.params.applyFilterOnFirstLevelOnly"
                :show-apply-filter-on-first-level-only="showApplyFilterOnFirstLevelOnly"
                class="_btn"
                @input="updateViewParams($event, 'filter')"
                @change-apply-filter-on-first-level-only="updateViewParams($event, 'applyFilterOnFirstLevelOnly')"
              />
            </template>
            <template #[SLOTS.PROPERTIES]>
              <props-filter
                :in-card-menu="slotIsInMenu(SLOTS.PROPERTIES)"
                :value="props"
                class="_btn"
                :disabled="readOnly"
                @input="updateViewParams($event, 'props')"
                @update:prop-order="updateViewParams($event, 'propOrder')"
              >
                <template #header>
                  <m-card-item
                    no-hover
                    :clickable="false"
                    :style="{ marginBottom: '.4rem' }"
                  >
                    <saved-view-info
                      :view="currentView"
                      :label="$t('savedViewInfo.propertyLabel')"
                    />
                  </m-card-item>
                </template>
              </props-filter>
            </template>
            <template #[SLOTS.SORTER]>
              <list-sorter
                :in-card-menu="slotIsInMenu(SLOTS.SORTER)"
                :value="order"
                :sort-options="sortOptions"
                :show-saved-view-info="showSavedViewInfo"
                :saved-view="currentView"
                :disabled="readOnly"
                class="_btn"
                @input="updateViewParams($event, 'order')"
              />
            </template>
            <template #[SLOTS.COMPARE_TO]>
              <compare-to-previous
                :component="slotIsInMenu(SLOTS.COMPARE_TO) ? 'card-item' : 'button'"
                :value="currentView.params.compare"
                :disabled="locked"
                @input="updateViewParams($event, 'compare')"
              />
            </template>
            <template #[SLOTS.SHOW_PARENTS]>
              <m-card-item
                class="_show-parents"
                :clickable="!locked"
                :no-hover="locked"
                tooltip-placement="left"
                :tooltip="locked ? $t('documentListHeader.lockedHint') : ''"
                @click="updateShowParents"
              >
                <m-switch
                  :value="showParents"
                  small
                  :disabled="locked"
                  :label="$t('goalsFilter.parent')"
                />
              </m-card-item>
            </template>
            <template #[SLOTS.WRAP_CELLS]>
              <m-divider
                v-if="hasSlotsInMenuBefore(SLOTS.WRAP_CELLS)"
                xxs
              />
              <m-card-item
                class="_wrap-cells"
                :clickable="!locked"
                :no-hover="locked"
                tooltip-placement="left"
                :tooltip="locked ? $t('documentListHeader.lockedHint') : ''"
                @click="updateWrapCells"
              >
                <m-switch
                  :value="wrapCells"
                  small
                  :disabled="locked"
                  :label="$t('documentListHeader.wrapCells')"
                />
              </m-card-item>
            </template>
            <template #[SLOTS.CARD_WIDTH]>
              <m-divider
                v-if="hasSlotsInMenuBefore(SLOTS.CARD_WIDTH)"
                xxs
              />
              <card-width-selector
                :value="cardWidth"
                :disabled="locked"
                @update:value="updateCardWidth"
              />
            </template>
            <template #[SLOTS.WRAP_GOAL_TITLES]>
              <m-divider
                v-if="hasSlotsInMenuBefore(SLOTS.WRAP_GOAL_TITLES)"
                xxs
              />
              <m-card-item
                class="_wrap-titles"
                :tooltip="$t('documentListHeader.wrapTitlesTooltip')"
                tooltip-placement="left"
                @click="updateWrapTitles"
              >
                <m-switch
                  :value="wrapTitles"
                  small
                  :disabled="locked"
                  :label="$t('documentListHeader.wrapTitles')"
                />
              </m-card-item>
            </template>
            <template #[SLOTS.EXPAND_BUTTONS]>
              <m-divider
                v-if="hasSlotsInMenuBefore(SLOTS.EXPAND_BUTTONS)"
                xxs
              />
              <m-card-item
                class="_expand-next-level"
                icon="plus-square"
                @click="handleExpandNextLevel"
              >
                {{ $t('documentListHeader.expandNextLevel') }}
              </m-card-item>
              <m-card-item
                class="_expand-all"
                icon="expand-plus"
                @click="handleExpandAll"
              >
                {{ $t('documentListHeader.expandAll') }}
              </m-card-item>
              <m-card-item
                class="_collapse-next-level"
                icon="minus-square"
                @click="handleCollapseNextLevel"
              >
                {{ $t('documentListHeader.collapseNextLevel') }}
              </m-card-item>
              <m-card-item
                class="_collapse-all"
                icon="expand-minus"
                @click="handleCollapseAll"
              >
                {{ $t('documentListHeader.collapseAll') }}
              </m-card-item>
            </template>
            <template #[SLOTS.CREATE]>
              <div class="_create">
                <slot name="create" />
              </div>
            </template>
          </m-slots-container>
        </div>
      </template>
    </list-header>
  </div>
</template>

<script>
import CardWidthSelector from '@/components/list/CardWidthSelector.vue';
import CompareToPrevious from '@/components/list/CompareToPrevious.vue';
import GoalCycleAppSettingsSelector from '@/components/goal/cycle/GoalCycleAppSettingsSelector.vue';
import ListFilter from '@/components/list/ListFilter.vue';
import ListHeader from '@/components/list/ListHeader.vue';
import ListSorter from '@/components/list/ListSorter.vue';
import ListStringFilter from '@/components/list/ListStringFilter.vue';
import PropsFilter from '@/components/PropsFilter.vue';
import SavedViewInfo from '@/components/list/SavedViewInfo.vue';
import SavedViewTabs from '@/components/list/SavedViewTabs.vue';
import useGoalProperty from '@/composables/property/goal-property';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useViewParamsProps from '@/composables/view-params/view-params-props';
import { CARD_SIZE_MEDIUM, SLOTS, VIEWS_SERVICE } from '@/lib/constants';
import { copy } from 'shared/lib/copy';
import { inject } from 'vue';
import { textByLang } from 'shared/lib/language';
import { viewApplication as viewApplicationConfig } from 'shared/constants.json';

export default {
  name: 'DocumentListHeader',
  props: {
    selectedGoalCycles: {
      type: Array,
      default: () => [],
    },
    changeSelectedGoalCycles: {
      type: Function,
      default: () => {
      },
    },
    dataLoading: {
      type: Boolean,
      default: false,
    },
    sortOptions: {
      type: Array,
      default: () => [],
    },
    showViewsSelector: {
      type: Boolean,
      default: false,
    },
    showCreateView: {
      type: Boolean,
      default: false,
    },
    showApplyFilterOnFirstLevelOnly: {
      type: Boolean,
      default: false,
    },
    allowedViewTypes: {
      type: Array,
      default: () => [],
    },
    filterProps: {
      type: Array,
      default: () => [],
    },
    hasDynamicDateFilters: {
      type: Boolean,
      default: false,
    },
    showSavedViewInfo: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    multiModel: {
      type: Boolean,
      default: false,
    },
    slots: {
      type: Array,
      default: () => [],
      validator(slots) {
        return slots.every((slot) => [SLOTS.CYCLE_SELECTOR, SLOTS.STRING_FILTER, SLOTS.FILTER,
          SLOTS.SORTER, SLOTS.PROPERTIES, SLOTS.COMPARE_TO, SLOTS.WRAP_CELLS, SLOTS.SHOW_PARENTS, SLOTS.CARD_WIDTH, SLOTS.WRAP_GOAL_TITLES,
          SLOTS.EXPAND_BUTTONS, SLOTS.CREATE].includes(slot.name));
      },
    },
  },
  setup() {
    const viewsService = inject(VIEWS_SERVICE);
    const currentView = viewsService.currentView;
    const { loggedInUser, userLang } = useLoggedInUser();
    const { loggedInUserAccount } = useLoggedInUserAccount();
    const { statusProperty } = useGoalProperty();
    const { props } = useViewParamsProps(currentView);
    return {
      viewsService,
      currentView,
      loggedInUser,
      loggedInUserAccount,
      props,
      statusLabel: textByLang(statusProperty.value.label, userLang.value),
    };
  },
  emits: ['string-filter-changed', 'expand-next-level', 'expand-all', 'collapse-next-level', 'collapse-all'],
  components: {
    CardWidthSelector,
    SavedViewInfo,
    CompareToPrevious,
    ListStringFilter,
    GoalCycleAppSettingsSelector,
    SavedViewTabs,
    ListHeader,
    PropsFilter,
    ListSorter,
    ListFilter,
  },
  data() {
    return {
      headerTight: false,
      showMenu: false,
      rightElementWidth: 0,
      headerWidth: 0,
      SLOTS,
      tabsShown: 0,
    };
  },
  computed: {
    locked() {
      return !this.viewsService.canEditCurrentView.value;
    },
    groupingItems() {
      return [
        {
          text: this.$t('networkControls.colorByStatus', { statusLabel: this.statusLabel }),
          value: 'status',
          customColor: !this.$store.state.breakpoint.smAndDown ? {
            color: this.$colors.grey.base,
            background: 'transparent',
          } : undefined,
        },
        {
          text: this.$t('networkControls.progress'),
          value: 'progress',
          customColor: !this.$store.state.breakpoint.smAndDown ? {
            color: this.$colors.grey.base,
            background: 'transparent',
          } : undefined,
        },
      ];
    },
    filter() {
      if (this.currentView.params === undefined) {
        return null;
      }
      return this.currentView.params.filter;
    },
    order() {
      if (!Array.isArray(this.currentView.params?.order)) {
        return [];
      }
      return this.currentView.params.order;
    },
    cardWidth() {
      if (this.currentView.params === undefined || this.currentView.params.cardWidth === undefined) {
        return CARD_SIZE_MEDIUM;
      }

      return this.currentView.params.cardWidth;
    },
    wrapCells() {
      if (this.currentView.params === undefined || this.currentView.params.wrapCells === undefined) {
        return false;
      }

      return this.currentView.params.wrapCells;
    },
    showParents() {
      if (this.currentView.params === undefined || this.currentView.params.showParents === undefined) {
        return false;
      }

      return this.currentView.params.showParents;
    },
    wrapTitles() {
      if (this.currentView.params === undefined || this.currentView.params.wrapTitles === undefined) {
        return false;
      }

      return this.currentView.params.wrapTitles;
    },
    sharedViewOrder() {
      if ([
        viewApplicationConfig.goal,
        viewApplicationConfig.update,
        viewApplicationConfig.updateFeed,
        viewApplicationConfig.goalAccount,
        viewApplicationConfig.updateAccount,
        viewApplicationConfig.insightAccount,
        viewApplicationConfig.updateFeedAccount,
        viewApplicationConfig.memberAccount,
        viewApplicationConfig.goalSpace,
        viewApplicationConfig.updateSpace,
        viewApplicationConfig.updateFeedSpace,
        viewApplicationConfig.insightSpace,
        viewApplicationConfig.memberSpace,
        viewApplicationConfig.gridPage,
        viewApplicationConfig.gridPageTileCheckin,
        viewApplicationConfig.gridPageTileGoal,
        viewApplicationConfig.gridPageTileUpdateFeed,
        viewApplicationConfig.gridPageTileMember,
      ].includes(this.currentView.viewApplication)) {
        return true;
      }

      return false;
    },
    isPublicDefault() {
      if ([
        viewApplicationConfig.goal,
        viewApplicationConfig.update,
        viewApplicationConfig.updateFeed,
        viewApplicationConfig.insight,
        viewApplicationConfig.insightHealth,
        viewApplicationConfig.goalAccount,
        viewApplicationConfig.updateAccount,
        viewApplicationConfig.insightAccount,
        viewApplicationConfig.updateFeedAccount,
        viewApplicationConfig.memberAccount,
        viewApplicationConfig.goalSpace,
        viewApplicationConfig.updateSpace,
        viewApplicationConfig.updateFeedSpace,
        viewApplicationConfig.insightSpace,
        viewApplicationConfig.memberSpace,
        viewApplicationConfig.gridPage,
        viewApplicationConfig.gridPageTileCheckin,
        viewApplicationConfig.gridPageTileGoal,
        viewApplicationConfig.gridPageTileUpdateFeed,
        viewApplicationConfig.gridPageTileMember,
        viewApplicationConfig.planningDetails,
      ].includes(this.currentView.viewApplication)) {
        return true;
      }

      return false;
    },
    slotsWithDefaults() {
      const slots = [{ name: SLOTS.DATA_LOADING }, { name: SLOTS.LOCKED }, ...copy(this.slots)];
      if (this.$store.state.breakpoint.smAndDown) {
        const index = slots.findIndex((slot) => slot.name === SLOTS.STRING_FILTER);
        if (index >= 0) {
          slots.splice(index, 1);
        }

        slots.forEach((slot) => {
          if (slot.inMenu === undefined && [SLOTS.PROPERTIES, SLOTS.SORTER, SLOTS.FILTER, SLOTS.GROUPING].includes(slot.name)) {
            slot.inMenu = true;
          }
        });
      }

      slots.forEach((slot) => {
        if (slot.inMenu === undefined && [
          SLOTS.PROPERTIES,
          SLOTS.COMPARE_TO,
          SLOTS.WRAP_CELLS,
          SLOTS.SHOW_PARENTS,
          SLOTS.WRAP_GOAL_TITLES,
          SLOTS.EXPAND_BUTTONS,
        ].includes(slot.name)) {
          slot.inMenu = true;
        }
      });

      if (this.headerTight === true) {
        slots.forEach((slot) => {
          if ([SLOTS.DATA_LOADING, SLOTS.CREATE].includes(slot.name)) {
            return;
          }
          slot.inMenu = true;
        });
      }
      return slots;
    },
  },
  methods: {
    slotIsInMenu(slotName) {
      return this.slotsWithDefaults.some((s) => s.name === slotName && s.inMenu === true);
    },
    hasSlotsInMenuBefore(slotName) {
      return this.slotsWithDefaults.filter((s) => s.inMenu === true).findIndex((s) => s.name === slotName) !== 0;
    },
    handleExpandNextLevel() {
      this.$emit('expand-next-level');
      this.showMenu = false;
    },
    handleExpandAll() {
      this.$emit('expand-all');
      this.showMenu = false;
    },
    handleCollapseNextLevel() {
      this.$emit('collapse-next-level');
      this.showMenu = false;
    },
    handleCollapseAll() {
      this.$emit('collapse-all');
      this.showMenu = false;
    },
    updateCardWidth(value) {
      if (this.locked) {
        return;
      }
      this.updateViewParams(value, 'cardWidth');
    },
    updateWrapCells() {
      if (this.locked) {
        return;
      }
      this.updateViewParams(!this.wrapCells, 'wrapCells');
    },
    updateShowParents() {
      if (this.locked) {
        return;
      }
      this.updateViewParams(!this.showParents, 'showParents');
    },
    updateWrapTitles() {
      if (this.locked) {
        return;
      }
      this.updateViewParams(!this.wrapTitles, 'wrapTitles');
    },
    updateViewParams(value, key) {
      this.viewsService.setParams(
        this.currentView,
        {
          ...this.currentView.params,
          [key]: value,
        },
      );
    },
    openMenu() {
      this.showMenu = true;
    },
  },
  mounted() {
    this.rightElementWidth = this.$refs.right.getBoundingClientRect().width;
    this.resizeObserver = new ResizeObserver(() => {
      this.rightElementWidth = this.$refs.right.getBoundingClientRect().width;
    });
    this.resizeObserver.observe(this.$refs.right);

    this.headerWidth = this.$refs.headerObject.getBoundingClientRect().width;
    this.headerSizeObserver = new ResizeObserver(() => {
      this.headerWidth = this.$refs.headerObject.getBoundingClientRect().width;
    });
    this.headerSizeObserver.observe(this.$refs.headerObject);
  },
  unmounted() {
    this.resizeObserver.disconnect();
    this.headerSizeObserver.disconnect();
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  .document-list-header {
    ._list-header {
      ._right {
        display: flex;
        margin-left: auto;

        ._btn {
          margin-left: .4rem;
        }

        ._create {
          margin-left: .4rem;
        }
      }
    }
  }

</style>
