export const textByLang = (multiLangObject, lang) => {
  if (multiLangObject[lang] !== undefined) {
    return multiLangObject[lang];
  }

  if (multiLangObject.en !== undefined) {
    return multiLangObject.en;
  }

  const keys = Object.keys(multiLangObject);
  if (keys.length === 0) {
    return '';
  }

  return multiLangObject[keys[0]];
};

const pluralizeGerman = (s) => {
  switch (true) {
    case s.slice(-1) === 'e':
    case ['in', 'ik', 'or'].includes(s.slice(-2)):
    case ['ion', 'tät', 'ung', 'ent', 'and', 'ant', 'ist'].includes(s.slice(-3)):
    case ['heit', 'keit'].includes(s.slice(-4)):
    case ['schaft'].includes(s.slice(-6)):
      return `${s}en`;
    case ['ig', 'ör', 'kt'].includes(s.slice(-2)):
    case ['eur', 'ich', 'ier'].includes(s.slice(-3)):
    case ['ling'].includes(s.slice(-4)):
      return `${s}e`;
    case 'a':
    case ['a', 'i', 'o', 'u', 'y'].includes(s.slice(-1)):
      return `${s}s`;
    case ['el', 'en', 'er'].includes(s.slice(-2)):
    case ['chen', 'lein'].includes(s.slice(-4)):
      return s;
    default:
      return `${s}s`;
  }
};

const pluralizeEnglish = (s) => {
  switch (true) {
    case s.slice(-2) === 'ss':
    case s.slice(-2) === 'sh':
    case s.slice(-2) === 'ch':
    case s.slice(-1) === 'x':
    case s.slice(-1) === 'z':
    case s.slice(-2) === 'es':
    case s.slice(-1) === 'o':
      return `${s}es`;
    case s.slice(-1) === 'f':
      return `${s.slice(0, -1)}ves`;
    case s.slice(-2) === 'fe':
      return `${s.slice(0, -2)}ves`;
    case s.slice(-1) === 'y':
      return `${s.slice(0, -1)}ies`;
    case s.slice(-1) === 's':
      return s;
    default:
      return `${s}s`;
  }
};

export const pluralize = (s, language) => {
  switch (language) {
    case 'de':
      return pluralizeGerman(s);
    case 'en':
      return pluralizeEnglish(s);
    default:
      return s;
  }
};
