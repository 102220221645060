import i18n from '@/lang';
import { SPLINE } from '@/lib/constants';
import { getBaseChart } from '@/lib/charts/base-options';
import { getValueAsPercent } from '@/lib/charts/format';

export const getBaseLineChartOptions = (categories, multiSeries) => ({
  chart: {
    type: SPLINE,
    height: 220,
    marginBottom: 22,
  },
  series: multiSeries,
  xAxis: { categories },
  yAxis: {
    title: { text: i18n.t('charts.favorability') },
    labels: {
      formatter() {
        return `${getValueAsPercent(this.value, 0)}`;
      },
    },
    max: 1,
    min: 0,
    tickInterval: 0.25,
    gridLineDashStyle: 'Dot',
  },
  tooltip: {
    shared: true,
    pointFormatter() {
      return `<span style="color: ${this.color}">●</span> ${this.series.name}: ${getValueAsPercent(this.y, 0)}</br><br/>`;
    },
  },
  plotOptions: {
    series: {
      clip: false,
      marker: {
        fillColor: '#FFFFFF',
        lineWidth: 2,
        lineColor: null, // inherit from series
        symbol: 'circle',
      },
      connectNulls: true,
    },
  },
});

export const getBaseAreaChartOptions = (categories, multiSeries) => {
  const options = getBaseChart(categories, multiSeries, 'area');

  return {
    ...options,
    yAxis: {
      ...options.yAxis,
      opposite: true,
    },
    plotOptions: { area: { stacking: 'normal' } },
  };
};

export const getPointInLineBounded = (line, x) => {
  if (x > line[1].x) {
    return line[1];
  }
  if (x < line[0].x) {
    return line[0];
  }
  return getPointInLine(line, x);
};

export const getPointInLine = (line, x) => {
  const diffX = x - line[0].x;
  const gradient = (line[0].y - line[1].y) / (line[0].x - line[1].x);
  return { x, y: line[0].y + (diffX * gradient) };
};

export const linearRegression = (points) => {
  const pairsAmount = points.length;
  const sum = points.reduce((acc, pair) => ({
    x: acc.x + pair.x,
    y: acc.y + pair.y,
  }), { x: 0, y: 0 });

  const average = {
    x: sum.x / pairsAmount,
    y: sum.y / pairsAmount,
  };

  const SSxx = points.reduce((acc, pair) => parseFloat(acc + (average.x - pair.x) ** 2), 0);
  const SSxy = points.reduce((acc, pair) => parseFloat(acc + ((average.x - pair.x) * (average.y - pair.y))), 0);

  const slope = SSxx !== 0 ? (SSxy / SSxx) : 0;

  const intercept = average.y - (slope * average.x);

  return { slope, intercept };
};
