<template>
  <div class="company-info">
    <m-section
      :title="$t('companyInfo.title')"
      heading-size="h4"
    >
      <template #after-title>
        <plan-tag
          :style="{paddingLeft: '.8rem'}"
          :feature-flag="featureFlag.companyInfo"
        />
      </template>
    </m-section>
    <m-content
      boxed-xs
      no-padding
    >
      <m-btn
        small
        super-light
        :href="$t('companyInfo.helpLink')"
        hide-border
        icon="question-circle"
        target="_blank"
        class="_help-btn"
      >
        {{ $t('companyInfo.help') }}
      </m-btn>
    </m-content>
    <template v-if="featureEnabled">
      <settings-switch-item
        :title="$t('companyInfo.actions.activate.title')"
        :sub-title="$t('companyInfo.actions.activate.subTitle')"
        :value="companyInfo.showInHelpArea"
        :style="{ marginTop: '2rem' }"
        @click="update({showInHelpArea: !companyInfo.showInHelpArea})"
      />
      <m-divider small />
      <settings-switch-item
        :title="$t('companyInfo.actions.onboarding.title')"
        :sub-title="$t('companyInfo.actions.onboarding.subTitle')"
        :value="companyInfo.onboardingStep"
        @click="update({onboardingStep: !companyInfo.onboardingStep})"
      />
      <m-divider small />
      <div class="_content-header">
        {{ $t('companyInfo.contentHeader') }}
      </div>
      <div class="_content">
        <div class="_action">
          <m-btn
            small
            icon="open-as-page"
            :icon-color="$colors.grey.base"
            @click="dialog = true"
          >
            <div :style="{color: $colors.grey.base}">
              {{ $t('companyInfo.actions.open') }}
            </div>
          </m-btn>
        </div>
        <company-info-editor
          ref="preview"
          :company-info="companyInfo"
          disabled
        />
      </div>
      <m-dialog
        v-model:value="dialog"
        :max-width="$modalSizes.xl"
        no-padding
        keep-height
        hide-header
        top="7rem"
        hide-footer
        keep-open-on-mask-click
        @overlay-clicked="askToClose"
      >
        <company-info-editor
          ref="companyInfoEditor"
          :company-info="companyInfo"
          show-footer
          show-cancel
          fullscreen
          @saved="handleSave"
          @close="close"
        />
      </m-dialog>
    </template>
  </div>
</template>

<script>
import CompanyInfoEditor from '@/components/CompanyInfoEditor.vue';
import PlanTag from '@/components/plans/PlanTag.vue';
import SettingsSwitchItem from '@/components/SettingsSwitchItem.vue';
import useAccess from '@/composables/access/access';
import useCompanyInfo from '@/composables/logged-in-user-account/company-info';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import { featureFlag } from 'shared/constants.json';
import { logCatch } from '@/lib/logger/logger';

export default {
  name: 'CompanyInfo',
  components: {
    PlanTag,
    SettingsSwitchItem,
    CompanyInfoEditor,
  },
  data() {
    return {
      dialog: false,
      featureFlag,
    };
  },
  setup() {
    const { loggedInUserAccount } = useLoggedInUserAccount();
    const { accountHasFeature, accountCanActivateFeature } = useAccess();
    const { updateCompanyInfo, companyInfo } = useCompanyInfo();
    return {
      account: loggedInUserAccount,
      accountHasFeature,
      accountCanActivateFeature,
      updateCompanyInfo,
      companyInfo,
    };
  },
  computed: {
    featureEnabled() {
      return this.accountHasFeature([featureFlag.companyInfo]) || this.accountCanActivateFeature([featureFlag.companyInfo]);
    },
  },
  methods: {
    update(val) {
      this.updateCompanyInfo(
        { ...this.companyInfo, ...val },
      ).then(() => {
        this.$showSnackbar({ color: 'success', message: this.$t('success.saved') });
      }).catch(logCatch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      }));
    },
    handleSave() {
      this.$refs.preview.updateContent();
      this.$showSnackbar({ color: 'success', message: this.$t('success.saved') });
    },
    close() {
      this.dialog = false;
    },
    askToClose() {
      this.$refs.companyInfoEditor.closeForced();
    },
  },
};
</script>

<style lang="scss" scoped type="text/scss">
  .company-info {
    ._content-header {
      margin-bottom: .6rem;
      color: $secondary-color;
    }

    ._content {
      position: relative;
      max-height: 20rem;
      overflow: hidden;
      border: 1px solid $border-color;
      border-radius: $default-border-radius;

      &::after {
        position: absolute;
        top: 65%;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        content: '';
        background-image: linear-gradient(180deg, rgba(255, 255, 255, 10%), rgba(255, 255, 255, 80%));
      }

      ._placeholder {
        color: $secondary-color;
      }

      ._action {
        position: absolute;
        top: 1.6rem;
        right: 1.6rem;
        z-index: 1;
        display: none;
      }

      &:hover {
        ._action {
          display: flex;
        }
      }

      ._preview-overlay {
        background-image: linear-gradient(red, yellow);
      }
    }
  }
</style>
