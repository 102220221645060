<template>
  <div class="product-tour">
    <m-card
      v-show="show"
      :key="currentStep"
      :class="['_content', classes]"
      :style="style"
      no-padding
    >
      <m-content
        padding-small
        class="_body"
      >
        <div
          v-if="!hideAvatar"
          class="_author"
        />
        <div class="_close">
          <m-btn
            icon="close"
            fab
            small
            light
            hide-border
            @click="close"
          />
        </div>
        <div class="_heading">
          {{ step.heading }}
        </div>
        <!-- eslint-disable vue/no-v-html -->
        <div
          class="_content"
          v-html="step.content"
        />
        <!-- eslint-enable vue/no-v-html -->
      </m-content>
      <m-divider none />
      <m-content
        padding-small
        class="_footer"
      >
        <div class="_footer-inner">
          <div class="_next">
            <m-btn
              v-if="currentStep !== 0"
              hide-border
              small
              light
              @click="stepBack"
            >
              {{ $t('productTour.previous') }}
            </m-btn>
          </div>
          <div class="_steps">
            {{ currentStep + 1 }} {{ $t('productTour.of') }} {{ steps.length }}
          </div>
          <div class="_next">
            <m-btn
              color="primary"
              small
              @click="stepForward"
            >
              <template v-if="currentStep === (steps.length - 1)">
                {{ $t('productTour.done') }}
              </template>
              <template v-else>
                {{ $t('productTour.next') }}
              </template>
            </m-btn>
          </div>
        </div>
      </m-content>
    </m-card>
  </div>
</template>

<script>
import kebabCase from 'lodash-es/kebabCase';

export default {
  name: 'ProductTour',
  props: {
    steps: {
      type: Array,
      required: true,
    },
    hideAvatar: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'finish'],
  data() {
    return {
      currentStep: 0,
      show: false,
      element: null,
      elementPosition: { width: 0, height: 0, left: 0, top: 0 },
      style: {},
    };
  },
  computed: {
    classes() {
      return [
        `-${kebabCase(this.step.placement)}`,
      ];
    },
    step() {
      return this.steps[this.currentStep];
    },
  },
  methods: {
    setStyle() {
      if (this.element === null) {
        return;
      }

      this.element.scrollIntoView({ block: 'center', inline: 'nearest', behavior: 'smooth' });
      [100, 200, 300, 400, 600, 800, 1600].forEach((t) => {
        setTimeout(this.setPosition, t);
      });
    },
    setPosition() {
      const { width, height, left, top } = this.element.getBoundingClientRect();
      if (this.elementPosition.top === top && this.elementPosition.left === left) {
        return;
      }

      this.elementPosition = { width, height, left, top };
      switch (this.step.placement) {
        case 'rightBottom':
          this.style = {
            position: 'fixed',
            top: `${top + height}px`,
            left: `${left + width + 5}px`,
            transform: 'translateY(-100%) translateX(0%)',
          };
          break;
        case 'rightCenter':
          this.style = {
            position: 'fixed',
            top: `${top + (height / 2)}px`,
            left: `${left + width + 5}px`,
            transform: 'translateY(-50%) translateX(0%)',
          };
          break;
        case 'rightTop':
          this.style = {
            position: 'fixed',
            top: `${top - 10}px`,
            left: `${left + width + 5}px`,
          };
          break;
        case 'topLeft':
          this.style = {
            position: 'fixed',
            top: `${top - 10}px`,
            left: `${left + width}px`,
            transform: 'translateY(-100%) translateX(-100%)',
          };
          break;
        case 'leftCenter':
          this.style = {
            position: 'fixed',
            top: `${top + (height / 2)}px`,
            left: `${left - 5}px`,
            transform: 'translateX(-100%) translateY(-50%)',
          };
          break;
        case 'bottomLeft':
          this.style = {
            position: 'fixed',
            top: `${top + height + 10}px`,
            left: `${left - 5}px`,
          };
          break;
        default:
          this.style = {
            position: 'fixed',
            top: `${top + height + 10}px`,
            left: `${left + width + 10}px`,
            transform: 'translateX(-100%)',
          };
      }
      this.show = true;
    },
    close() {
      this.resetHighlight();
      this.$emit('close');
    },
    stepForward() {
      if (this.currentStep + 1 >= this.steps.length) {
        this.resetHighlight();
        this.$emit('finish');
        return;
      }

      this.currentStep += 1;
      this.resetHighlight();
      this.setElement().then((element) => {
        if (element === null) {
          this.stepForward();
        }
      });
    },
    resetHighlight() {
      if (this.element === null) {
        return;
      }

      this.element.classList = this.element.classList.value.replace('product-tour-highlight-box', '');
    },
    stepBack() {
      if (this.currentStep === 0) {
        return;
      }

      this.currentStep -= 1;
      this.resetHighlight();
      this.setElement().then((element) => {
        if (element === null) {
          this.stepBack();
        }
      });
    },
    setElement(counter = 0) {
      if (counter > 9) {
        return new Promise((resolve) => { resolve(null); });
      }

      return new Promise((resolve) => {
        if (typeof this.step.highlightedElement !== 'undefined') {
          const el = document.getElementById(this.step.highlightedElement);
          if (el === null) {
            setTimeout(() => {
              this.setElement(counter + 1).then((element) => {
                resolve(element);
              });
            }, 100);
            return;
          }
          this.element = el;
          this.element.classList += ' product-tour-highlight-box';
          resolve(this.element);
          return;
        }

        if (typeof this.step.highlightedElementClass !== 'undefined') {
          const els = document.getElementsByClassName(this.step.highlightedElementClass);
          if (els.length === 0) {
            setTimeout(() => {
              this.setElement(counter + 1).then((element) => {
                resolve(element);
              });
            }, 100);
            return;
          }
          this.element = els[0];
          this.element.classList += ' product-tour-highlight-box';
          resolve(this.element);
          return;
        }

        resolve(null);
      });
    },
  },
  watch: {
    element() {
      this.show = false;
      this.setStyle();
    },
  },
  mounted() {
    this.setElement(0).then((element) => {
      if (element === null) {
        this.stepForward();
      }
    });
  },
};
</script>

<style
    lang="scss"
    type="text/scss"
>
  .product-tour {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100vw;
    height: 100vh;
    overflow: hidden;

    ._content {
      white-space: pre-line;
      transition: top .2s ease-in-out, left .2s ease-in-out;

      ul {
        margin-bottom: 0;
        line-height: 1;
        margin-inline-start: 0;
      }

      &.-right-top {
        animation: slide-in-right .4s ease-in-out;
      }

      &.-top-left {
        animation: slide-in-top .4s ease-in-out;
      }

      &.-bottom-left {
        animation: slide-in-bottom .4s ease-in-out;
      }

      &.-bottom-right {
        animation: slide-in-bottom-right .4s ease-in-out;
      }

      &.-left-center {
        animation: slide-in-left-center .4s ease-in-out;
      }
    }

    @keyframes slide-in-top {
      0% {
        opacity: 0;
        transform: translateX(-100%) translateY(-102%);
      }

      100% {
        opacity: 1;
        transform: translateX(-100%) translateY(-100%);
      }
    }

    @keyframes slide-in-bottom {
      0% {
        opacity: 0;
        transform: translateY(2%);
      }

      100% {
        opacity: 1;
        transform: translateY(0);
      }
    }

    @keyframes slide-in-bottom-right {
      0% {
        opacity: 0;
        transform: translateX(-100%) translateY(2%);
      }

      100% {
        opacity: 1;
        transform: translateX(-100%) translateY(0);
      }
    }

    @keyframes slide-in-right {
      0% {
        opacity: 0;
        transform: translateX(2%);
      }

      100% {
        opacity: 1;
        transform: translateX(0);
      }
    }

    @keyframes slide-in-left-center {
      0% {
        opacity: 0;
        transform: translateX(-102%) translateY(-50%);
      }

      100% {
        opacity: 1;
        transform: translateX(-100%) translateY(-50%);
      }
    }

    @keyframes slide-in-left {
      0% {
        opacity: 0;
        transform: translateX(-2%);
      }

      100% {
        opacity: 1;
        transform: translateX(0);
      }
    }

    ._body {
      position: relative;

      ._close {
        position: absolute;
        top: .4rem;
        right: .4rem;
      }

      ._author {
        display: flex;
        align-items: center;
        margin-bottom: .8rem;

        ._image {
          img {
            width: 3.4rem;
            height: auto;
          }
        }

        ._name {
          margin-left: 1.2rem;
          color: $font-color-secondary;
        }
      }

      ._heading {
        margin-bottom: .4rem;
        font-size: $font-size-4;
        font-weight: $font-weight-bold;
      }

      ._content {
        width: 30rem;
      }
    }

    ._footer {
      ._footer-inner {
        display: flex;
        align-items: center;
        justify-content: space-between;

        ._steps {
          color: $font-color-secondary;
        }

        ._next {
          display: flex;
          flex: 0 0 6rem;
          justify-content: flex-start;

          &:last-of-type {
            justify-content: flex-end;
          }
        }
      }
    }
  }
</style>
