<template>
  <page-layout class="account-space-template">
    <template #topBar>
      <page-top-bar
        v-if="showTopBar"
        class="_header"
        :breadcrumbs="breadcrumbs"
        :show-mobile-menu-toggle="showMobileMenuToggle"
      >
        <template #actions>
          <div
            class="_actions"
          >
            <template v-if="!breakpoint.smAndDown">
              <m-dropdown
                :title="$t('goal.detailHeader.share')"
              >
                <m-btn
                  hide-border
                  class="_action -share"
                  small
                >
                  {{ $t('goal.detailHeader.share') }}
                </m-btn>
                <template
                  #overlay
                >
                  <m-card
                    class="_overlay"
                    padding-xs
                  >
                    <m-btn
                      v-clipboard:copy="pageLink"
                      v-clipboard:error="onCopyError"
                      color="primary"
                      block
                    >
                      {{ $t('accessPolicy.copyLink') }}
                    </m-btn>
                  </m-card>
                </template>
              </m-dropdown>
              <favorite-button
                v-if="(isGoalView || isUpdateView || isGoalInsightView || isUpdateFeedView)"
                :title-suggestion="titleSuggestion"
              />
            </template>
            <template v-else>
              <m-dropdown
                :title="$t('general.actions')"
              >
                <m-btn
                  icon="ellipsis"
                  hide-border
                  fab
                />
                <template
                  #overlay
                >
                  <m-card
                    list
                    no-padding
                  >
                    <favorite-button
                      v-if="(isGoalView || isUpdateView || isGoalInsightView)"
                      :title-suggestion="titleSuggestion"
                    />
                  </m-card>
                </template>
              </m-dropdown>
            </template>
          </div>
        </template>
      </page-top-bar>
    </template>
    <scroll-container :no-padding-bottom="isGoalView">
      <m-content
        class="_content"
        padding-x="layout"
      >
        <div class="_page-title">
          <div class="_left">
            <m-avatar
              :size="85"
              :src="companyImage"
              class="_company-avatar"
              :text="account.companyName"
              :amount-chars="1"
              shape="square"
            />
          </div>
          <div class="_right">
            <div class="_top">
              <h1
                :style="{ paddingTop: 0, marginLeft: '-.4rem', marginBottom: 0, marginTop: 0 }"
              >
                {{ title }}
              </h1>
              <m-btn
                v-if="showLogout"
                small
                hide-border
                light
                @click="EventBus.$emit('logout')"
              >
                {{ $t('navigation.logout') }}
              </m-btn>
            </div>
            <div
              v-if="account.accountSettings.vision !== ''"
              class="_vision"
            >
              {{ account.accountSettings.vision }}
            </div>
          </div>
        </div>
        <sub-menu-tabs
          :items="localTabs"
          small
          light
          class="_tabs"
        />
      </m-content>

      <account-goals v-if="isGoalView" />
      <m-content
        padding-x="layout"
        class="_header"
      >
        <account-updates v-if="isUpdateView" />
        <account-update-feed v-if="isUpdateFeedView" />
        <account-users v-if="isUserView" />
      </m-content>
      <goal-insights-performance
        v-if="isGoalInsightView && goalInsightAccess"
        :view-application="viewApplication.insightAccount"
        :default-view="dashboardDefaultView"
        :show-create-view="hasPublicViewAccess"
        show-views-selector
      />
    </scroll-container>
  </page-layout>
</template>

<script>

import AccountGoals from '@/components/account/AccountGoals.vue';
import AccountUpdateFeed from '@/components/account/AccountUpdateFeed.vue';
import AccountUpdates from '@/components/account/AccountUpdates.vue';
import AccountUsers from '@/components/account/AccountUsers.vue';
import FavoriteButton from '@/components/favorite/FavoriteButton.vue';
import GoalInsightsPerformance from '@/components/goal-insights/performance/GoalInsightsPerformance.vue';
import PageLayout from '@/components/page/PageLayout.vue';
import PageTopBar from '@/components/page/PageTopBar.vue';
import ScrollContainer from '@/components/page/ScrollContainer.vue';
import SubMenuTabs from '@/components/SubMenuTabs.vue';
import useAccess from '@/composables/access/access';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import { AND } from 'shared/api/query/constants';
import { EventBus } from '@/lib/event-bus';
import { GOAL_FEED_VIEW, GOAL_INSIGHT_VIEW, GOAL_VIEW, UPDATE_VIEW, USER_VIEW, VIEW } from '@/route-params';
import { accessGroupFlag, goalInsightsGroupBy, viewApplication, viewType } from 'shared/constants.json';
import { getQueryParam } from '@/lib/route';
import { mapState } from 'vuex';

export default {
  name: 'AccountSpaceTemplate',
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    showTopBar: {
      type: Boolean,
      default: true,
    },
    showLogout: {
      type: Boolean,
      default: false,
    },
    showMobileMenuToggle: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    AccountUsers,
    ScrollContainer,
    PageLayout,
    GoalInsightsPerformance,
    FavoriteButton,
    PageTopBar,
    SubMenuTabs,
    AccountGoals,
    AccountUpdates,
    AccountUpdateFeed,
  },
  setup() {
    const { userHasRight } = useAccess();
    const { loggedInUserAccount } = useLoggedInUserAccount();

    return {
      account: loggedInUserAccount,
      userHasRight,
    };
  },
  data() {
    return {
      viewType,
      viewApplication,
      EventBus,
    };
  },
  computed: {
    ...mapState({ breakpoint: (state) => state.breakpoint }),
    dashboardDefaultView() {
      return {
        title: this.$t('list.dashboard'),
        viewType: viewType.default,
        viewApplication: viewApplication.insightAccount,
        params: {
          filter: { account: { uid: this.account.uid }, children: [], op: AND },
          order: [],
          props: [],
          goalInsightsGroupBy: { value: goalInsightsGroupBy.user, uid: [], id: goalInsightsGroupBy.user },
        },
      };
    },
    localTabs() {
      return this.tabs.map((t) => ({
        ...t,
        active: t.view === this.currentView,
      }));
    },
    hasPublicViewAccess() {
      return this.userHasRight([accessGroupFlag.publicSavedViews]);
    },
    companyImage() {
      if (this.account.companyImage === null) {
        return '';
      }
      return this.account.companyImage.getURL;
    },
    titleSuggestion() {
      switch (true) {
        case this.isGoalView:
          return `${this.title} - ${this.account.goalSettings.featureNamePlural}`;
        case this.isUpdateView:
          return `${this.title} - ${this.$t('navigation.updatesExplorer')}`;
        case this.isGoalInsightView:
          return `${this.title} - ${this.$t('navigation.goalInsights')}`;
        case this.isUpdateFeedView:
          return `${this.title} - ${this.$t('navigation.feed')}`;
        default:
          return '';
      }
    },
    title() {
      return this.account.companyName;
    },
    currentView() {
      const v = getQueryParam(this.$route, VIEW);
      if (v === '') {
        return GOAL_VIEW;
      }

      return v;
    },
    activeTab() {
      const filtered = this.tabs.filter((t) => t.view === this.currentView);
      if (filtered.length === 0) {
        return null;
      }
      return filtered[0];
    },
    isGoalView() {
      return this.currentView === GOAL_VIEW;
    },
    goalInsightAccess() {
      return this.userHasRight([accessGroupFlag.goalDashboardView]);
    },
    isGoalInsightView() {
      return this.currentView === GOAL_INSIGHT_VIEW;
    },
    isUserView() {
      return this.currentView === USER_VIEW;
    },
    isUpdateView() {
      return this.currentView === UPDATE_VIEW;
    },
    isUpdateFeedView() {
      return this.currentView === GOAL_FEED_VIEW;
    },
    breadcrumbs() {
      const breadcrumbs = [
        {
          title: this.title,
          disabled: true,
        },
      ];

      if (this.activeTab !== null) {
        breadcrumbs.push({
          title: this.activeTab.title,
          disabled: true,
        });
      }
      return breadcrumbs;
    },
    pageLink() {
      return window.location;
    },
  },
  methods: {
    onCopyError() {
      this.$showSnackbar({ color: 'error', message: this.$t('error.duringCopying') });
    },
  },
  watch: {
    currentView(newVal) {
      if (newVal === '') {
        this.initRoute();
      }
    },
  },
};
</script>

<style
  scoped
  lang="scss"
  type="text/scss"
>
  .account-space-template {
    max-width: 100vw;

    @media (min-width: $screen-size-md) {
      height: 100vh;
    }

    ._header {
      position: sticky;
      left: 0;

      ._actions {
        display: flex;
        align-items: center;
      }
    }

    ._content {
      position: sticky;
      left: 0;

      ._page-title {
        display: flex;
        align-items: center;
        padding-top: 2rem;

        ._left {
          margin-right: 2rem;
        }

        ._right {
          width: 100%;

          ._top {
            display: flex;
            justify-content: space-between;
          }

          ._vision {
            margin-top: .4rem;
            color: $font-color-secondary;
            white-space: pre-line;
          }
        }
      }

      ._tabs {
        margin: 2.6rem 0 3rem;
        overflow: auto;
      }
    }
  }
</style>
