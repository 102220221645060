import Extension from '@/tiptap/tiptap/Utils/Extension';
import Suggestions from '@/vendor/suggestions';
import { replaceText } from '@/tiptap/commands/commands';

export class ContextMenuExtension extends Extension {
  get name() {
    return 'context-menu-extension';
  }

  get defaultOptions() {
    return {
      matcher: {
        char: '/',
        allowSpaces: false,
        startOfLine: false,
      },
      suggestionClass: 'context-menu',
      commands: {},
    };
  }

  get plugins() {
    return [Suggestions({
      command: ({
        range,
        attrs,
        schema,
      }) => replaceText(range, schema.nodes[this.name], attrs),
      appendText: ' ',
      matcher: this.options.matcher,
      items: this.options.items,
      onEnter: this.options.onEnter,
      onChange: this.options.onChange,
      onExit: this.options.onExit,
      onKeyDown: this.options.onKeyDown,
      onFilter: this.options.onFilter,
      suggestionClass: this.options.suggestionClass,
    })];
  }
}
