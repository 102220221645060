<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M12 1.5C6.20156 1.5 1.5 6.20156 1.5 12C1.5 17.7984 6.20156 22.5 12 22.5C17.7984 22.5 22.5 17.7984 22.5 12C22.5 6.20156 17.7984 1.5 12 1.5ZM12 20.7188C7.18594 20.7188 3.28125 16.8141 3.28125 12C3.28125 7.18594 7.18594 3.28125 12 3.28125C16.8141 3.28125 20.7188 7.18594 20.7188 12C20.7188 16.8141 16.8141 20.7188 12 20.7188Z"
      fill="currentColor"
    />
    <path
      d="M16.8608 11.6978L9.92091 6.65877C9.86526 6.61797 9.79936 6.59343 9.73057 6.58788C9.66178 6.58233 9.5928 6.59599 9.53132 6.62733C9.46984 6.65868 9.41827 6.70649 9.38236 6.76542C9.34645 6.82436 9.32761 6.8921 9.32795 6.96112V17.0393C9.32795 17.3463 9.67482 17.5197 9.92091 17.3416L16.8608 12.3025C16.9087 12.268 16.9477 12.2226 16.9746 12.17C17.0015 12.1175 17.0156 12.0592 17.0156 12.0002C17.0156 11.9411 17.0015 11.8829 16.9746 11.8304C16.9477 11.7778 16.9087 11.7324 16.8608 11.6978ZM10.8233 14.8385V9.1619L14.7303 12.0002L10.8233 14.8385Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default { name: 'PlayOutlined' };
</script>

<style scoped lang="scss" type="text/scss">

</style>
