<template>
  <div :class="['list-string-filter', inCardMenu ? '-full-width':'', showTextField ? '-show' : '']">
    <component
      :is="inCardMenu ? 'm-card-item' : 'm-btn'"
      v-show="!showTextField"
      ref="button"
      :small="small"
      hide-border
      fab
      class="_btn"
      :light="!inCardMenu"
      icon="search"
      :disabled="disabled"
      @click="show"
    >
      <template v-if="inCardMenu">
        {{ $t('general.search') }}
      </template>
    </component>
    <m-input
      v-if="showTextField"
      v-model:value="search"
      class="_input"
      hide-border
      :small="small"
      auto-focus
      :disabled="disabled"
      :placeholder="$t('general.typeToSearch')"
      :full-width="inCardMenu"
      @input="emitInput"
      @blur="hideIfEmpty"
    >
      <template #prefix>
        <m-icon
          :type="loading ? 'loading' : 'search'"
          :color="$colors.grey.base"
          size="14"
        />
      </template>
      <template #suffix>
        <m-btn
          class="_btn"
          icon="close-circle"
          hide-border
          xs
          light
          fab
          @click="clear"
        />
      </template>
    </m-input>
  </div>
</template>

<script>
import { EventBus as Eventbus } from '@/lib/event-bus';

export default {
  name: 'ListStringFilter',
  props: {
    inCardMenu: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input'],
  data() {
    return {
      search: '',
      showTextField: false,
    };
  },
  methods: {
    emitInput(value) {
      this.$emit('input', value);
    },
    show() {
      this.showTextField = true;
    },
    clear() {
      this.search = '';
      this.hide();
    },
    hide() {
      this.emitInput(this.search);
      this.showTextField = false;
    },
    hideIfEmpty() {
      if (this.search === '') {
        this.hide();
      }
    },
  },
  mounted() {
    Eventbus.$on('applyText', this.show);
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .list-string-filter {
    display: inline-flex;

    ._input {
      width: 16rem;
      animation: .2s expand ease-in-out;

      @keyframes expand {
        0% {
          width: 8rem;
        }

        100% {
          width: 16rem;
        }
      }
    }

    &.-full-width {
      width: 100%;

      ._input {
        width: 100%;
        max-width: none;
      }
    }
  }
</style>
