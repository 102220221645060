import useRouteAwareViews from '@/composables/saved-views/route-aware-views';

import { onMounted } from 'vue';

import useInMemoryViews from '@/composables/saved-views/in-memory-views';

export default function useViewServiceInit() {
  const withDefaultView = (config, value) => {
    config.defaultView = value;
    return config;
  };

  const withViewApplication = (config, value) => {
    config.viewApplication = value;
    return config;
  };

  const withReadOnly = (config, value) => {
    config.readOnly = value;
    return config;
  };

  const withSpace = (config, value) => {
    config.space = value;
    return config;
  };

  const withPlanning = (config, value) => {
    config.planning = value;
    return config;
  };

  const withUser = (config, value) => {
    config.user = value;
    return config;
  };

  const withDefaultProps = (config, value) => {
    config.defaultProps = value;
    return config;
  };

  const withGridPageTile = (config, value) => {
    config.gridPageTile = value;
    return config;
  };

  const defaultConfig = () => ({ defaultView: null, viewApplication: null, space: null, planning: null, user: null, readOnly: false, defaultProps: null, gridPageTile: null });

  const gridPageViewService = (config) => {
    const gridPageViewSvc = useInMemoryViews({
      defaultProps: config.defaultProps,
      defaultView: config.defaultView,
      gridPageTile: config.gridPageTile,
      readOnly: config.readOnly,
      application: config.viewApplication,
      includeGoalCycle: true,
    });

    onMounted(() => {
      gridPageViewSvc.initCurrentView();
    });

    return gridPageViewSvc;
  };

  const routeAwareViewService = (config) => {
    const routeAwareViewsSvc = useRouteAwareViews({
      defaultProps: config.defaultProps,
      defaultView: config.defaultView,
      application: config.viewApplication,
      space: config.space,
      planning: config.planning,
      user: config.user,
      readOnly: config.readOnly,
    });

    onMounted(() => {
      routeAwareViewsSvc.initCurrentView();
    });

    return routeAwareViewsSvc;
  };

  return {
    routeAwareViewService,
    defaultConfig,
    gridPageViewService,

    withDefaultView,
    withViewApplication,
    withSpace,
    withPlanning,
    withUser,
    withReadOnly,
    withDefaultProps,
    withGridPageTile,
  };
}
