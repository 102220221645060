import { UID } from 'shared/api/query/constants';
import {
  collection as collectionConfig,
  collectionItem as collectionItemConfig,
  meeting as meetingConfig,
} from 'shared/api/query/configs.json';
import { children as collectionItemChildren } from '@/api/query/collection-item';

export const children = [
  { attr: UID },
  {
    attr: collectionConfig.edges.meeting,
    model: meetingConfig.model,
    children: [{ attr: UID }],
  },
  { attr: collectionConfig.edges.type },
  {
    attr: collectionConfig.edges.title,
    default: '',
  },
  {
    attr: collectionConfig.edges.hidden,
    default: '',
  },
  {
    attr: collectionConfig.edges.items,
    default: [],
    model: collectionItemConfig.model,
    children: collectionItemChildren,
    facets: { param: [{ key: 'order' }] },
    facetVar: {},
    facetOrder: 'order',
    facetDesc: false,
  },
];
