import useBulkMutate from '@/nebula/bulk-mutate';
import useRepo from '@/nebula/repo';
import { UID } from 'shared/api/query/constants';
import { goal as goalConfig, goalSubscription as goalSubscriptionConfig, user as userConfig } from 'shared/api/query/configs.json';

export default function useGoalSubscription() {
  const repo = useRepo(goalSubscriptionConfig.model);

  const { bulkMutate } = useBulkMutate();

  const createSubscription = (entity) => bulkMutate([
    {
      alias: goalSubscriptionConfig.model,
      nodes: [{ ...entity, rid: 1 }],
      model: goalSubscriptionConfig.model,
      attributes: [
        { attr: UID },
        { attr: goalSubscriptionConfig.edges.active },
        {
          attr: goalSubscriptionConfig.edges.user,
          model: userConfig.model,
          children: [{ attr: UID }],
        },
      ],
    },
    {
      alias: goalConfig.model,
      nodes: [{ uid: entity.goal.uid, subscriptions: [{ rid: 1 }] }],
      model: goalConfig.model,
      attributes: [
        { attr: UID },
        {
          attr: goalConfig.edges.subscriptions,
          model: goalSubscriptionConfig.model,
          children: [{ attr: UID }],
        }],
    },
  ]);

  return {
    createLoading: repo.createLoading,
    createSubscription,
    updateSubscription: repo.updateSingle,
  };
}
