import colors from 'shared/colors';
import { camelCase } from 'lodash-es';
import { goalProgressMeasurement } from 'shared/constants.json';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

export default function useMeasurementTypeDistribution() {
  const { t } = useI18n();
  const init = () => {
    const distribution = [
      { value: goalProgressMeasurement.continuous },
      { value: goalProgressMeasurement.threshold },
      { value: goalProgressMeasurement.binary },
      { value: goalProgressMeasurement.alignedItems },
      { value: goalProgressMeasurement.none },
    ];
    distribution.forEach((e) => {
      e.x = `${t(`metricForm.${camelCase(e.value)}`)}`;
      e.y = 0;
      e.color = colors.dashboard.green;
    });
    return distribution;
  };
  const distribution = ref(init());

  const setup = ({ allowedMeasurementTypes }) => {
    distribution.value.forEach((bucket) => {
      bucket.color = colors.dashboard.green;
    });
    if (allowedMeasurementTypes.length === 0) {
      return;
    }

    distribution.value.forEach((bucket) => {
      bucket.color = allowedMeasurementTypes.includes(bucket.value) ? colors.dashboard.green : colors.dashboard.red;
    });
  };

  const update = (counters) => {
    distribution.value.forEach((bucket) => {
      bucket.y = 0;
    });
    if (counters.length === 0) {
      return;
    }
    distribution.value.forEach((bucket) => {
      if (bucket.value in counters) {
        bucket.y = counters[bucket.value];
      }
    });
  };

  return {
    distribution,
    setup,
    update,
  };
}
