<template>
  <m-btn
    class="search-box"
    block
    hide-border
    :button-style="{ padding: '0' }"
    @click="showSearch = true"
  >
    <m-input
      class="_inner"
      full-width
      hide-border
      read-only
      :placeholder="$t('navigation.search')"
    >
      <template #prefix>
        <m-icon
          type="search"
          :color="$colors.grey.lighten1"
          size="14"
        />
      </template>
      <template #suffix>
        {{ suffix }}
      </template>
    </m-input>
  </m-btn>
  <search-dialog v-model:value="showSearch" />
</template>
<script>
import SearchDialog from '@/components/navigation/SearchDialog.vue';
import { EventBus } from '@/lib/event-bus';

export default {
  name: 'SearchBox',
  components: { SearchDialog },
  data() {
    return { showSearch: false };
  },
  computed: {
    suffix() {
      const isMac = navigator.platform.toUpperCase().indexOf('MAC') > -1;
      if (isMac) {
        return this.$t('mainNavigation.macSearchKeyBinding');
      }
      return this.$t('mainNavigation.searchKeyBinding');
    },
  },
  methods: {
    handleKeyDown(event) {
      if ((event.ctrlKey || event.metaKey) && event.key === 'k') {
        event.stopPropagation();
        event.preventDefault();
        this.showSearch = true;
      }
    },
  },
  created() {
    window.addEventListener('keydown', this.handleKeyDown);
    EventBus.$on('show-search', () => {
      this.showSearch = true;
    });
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
    EventBus.$off('show-search', () => {
      this.showSearch = true;
    });
  },
};

</script>
<style
    scoped
    lang="scss"
    type="text/scss"
>
  @import "shared/assets/scss/box-shadow";

  .search-box {
    @include box_shadow(1);

    ._inner {
      font-size: $font-size-3;
      font-weight: 500;
      color: $font-color-tertiary;
    }
  }
</style>
