<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.33984 6.5122L8.92521 3L15.0716 6.5122L21.6569 3"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2.33984 21L8.92521 17.4878L15.0716 21L21.6569 17.4878"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linejoin="round"
    />
    <path
      d="M2.33984 6.5122V21M8.92521 3V17.4878M15.0716 6.5122V21M21.6569 3V17.4878"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default { name: 'Plan' };
</script>

<style scoped lang="scss" type="text/scss">

</style>
