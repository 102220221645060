export default function useViewParamsPropsOperationValidator(collection) {
  const isValidListSort = ({ list, anchor, toSort, sortAfter }) => {
    if (toSort.length > 1) {
      return { isValid: true };
    }

    if (anchor === toSort[0]) {
      return { isValid: false, message: 'no-op anchor == self' };
    }

    const aIdx = list.findIndex((e) => e === anchor);
    if (sortAfter === false && aIdx === 0) {
      return { isValid: true };
    }
    if (sortAfter === true && aIdx === list.length - 1) {
      return { isValid: true };
    }

    const target = sortAfter ? list[aIdx + 1] : list[aIdx - 1];
    if (target !== toSort[0]) {
      return { isValid: true };
    }

    return { isValid: false, message: 'no-op moving next to self' };
  };

  const isValidSort = (operation) => {
    const list = collection.value.map((el) => el.key);
    const { anchorId: anchor, toSortIds: toSort, sortAfter } = operation;

    const noSortEls = collection.value.filter((el) => el.noSortInProps);
    if (noSortEls.map((el) => el.key).includes(anchor) && sortAfter === false) {
      return { isValid: false, message: 'cant order before a element with noSortInProps' };
    }

    return isValidListSort({ list, anchor, toSort, sortAfter });
  };

  const isValidDrag = (operation) => {
    const validators = [];
    if (operation.isSort) {
      validators.push(isValidSort(operation));
    }

    return validators.reduce((res, next) => {
      if (res.isValid === false) {
        return res;
      }
      return next;
    }, { isValid: true });
  };

  return { isValidDrag };
}
