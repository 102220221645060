import useRepo from '@/nebula/repo';
import { RESULT } from 'shared/api/query/constants';
import { planning as planningConfig } from 'shared/api/query/configs.json';
import { planningList } from '@/api/query/nebula/planning';
import { ref } from 'vue';

export default function usePlanningsRepo() {
  const repo = useRepo(planningConfig.model);

  const getListLoading = ref(false);
  const getList = ({ status, searchTerm }) => {
    getListLoading.value = true;
    return repo.query(
      planningList({ status, searchTerm }),
    ).then((response) => {
      const entities = response[RESULT];
      return entities.map((e) => repo.selectSingle(e.uid));
    }).finally(() => {
      getListLoading.value = false;
    });
  };

  return {
    ...repo,

    getList,
    getListLoading,
  };
}
