export const environmentVariable = {
  CUSTIFY_ACTIVE: 'VITE_CUSTIFY_ACTIVE',
  CUSTIFY_ACCOUNT_ID: 'VITE_CUSTIFY_ACCOUNT_ID',
  DATADOG_RUM_ACTIVE: 'VITE_DATADOG_RUM_ACTIVE',
  DATADOG_RUM_APPLICATION_ID: 'VITE_DATADOG_RUM_APPLICATION_ID',
  DATADOG_RUM_CLIENT_TOKEN: 'VITE_DATADOG_RUM_CLIENT_TOKEN',
  DATADOG_RUM_SERVICE_NAME: 'VITE_DATADOG_RUM_SERVICE_NAME',
  DATADOG_RUM_ENVIRONMENT: 'VITE_DATADOG_RUM_ENVIRONMENT',
  DATADOG_RUM_SERVICE_VERSION: 'VITE_DATADOG_RUM_SERVICE_VERSION',
  ENVIRONMENT: 'VITE_ENVIRONMENT',
  GOOGLE_PICKER_API_KEY: 'VITE_GOOGLE_PICKER_API_KEY',
  GOOGLE_CLIENT_ID: 'VITE_GOOGLE_CLIENT_ID',
  SEMVER: 'VITE_SEMVER',
  WEBSITE_URL: 'VITE_WEBSITE_URL',
  VORTEX_HOST: 'VITE_VORTEX_HOST',
  PUBLIC_PATH: 'VITE_PUBLIC_PATH',
  SANITY_PROJECT_ID: 'VITE_SANITY_PROJECT_ID',
  SANITY_DATASET: 'VITE_SANITY_DATASET',
  SANITY_API_VERSION: 'VITE_SANITY_API_VERSION',
  SANITY_ACTIVE: 'VITE_SANITY_ACTIVE',
};

export const resolveEnvironmentVariable = (variable) => (import.meta.env[variable]);
