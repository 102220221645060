<template>
  <div class="email-preview">
    <div class="_logo">
      <img src="@/assets/img/logo.svg">
    </div>
    <div class="_body">
      <div class="_text">
        {{ body }}
      </div>
      <slot name="actions" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmailPreview',
  props: {
    body: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  @import "shared/assets/scss/box-shadow";
  @import "shared/assets/scss/padding";

  .email-preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: map_get($grey, 'lighten-6');

    @include padding();

    ._logo {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 1.2rem;

      @include padding();

      img {
        width: 22rem;
      }
    }

    ._body {
      @include box_shadow(1);

      width: 95%;
      margin-bottom: 2rem;
      border-radius: $default-border-radius;

      @include padding();

      ._text {
        margin-bottom: 1.2rem;
        text-align: left;
        white-space: pre-line;
      }
    }

    ._actions {
      display: flex;
      align-items: center;
      justify-content: center;

      @include padding();
    }
  }
</style>
