import { goal as goalConfig } from 'shared/api/query/configs.json';
import { goalValidationError } from 'shared/constants.json';

export default function useParentSelectorRules(goalTypeOptions) {
  const createRules = (type) => [
    canBeChildOf(type),
  ];

  const canBeChildOf = (goalTypeOption) => {
    const allowedOptionIds = goalTypeOption.canBeChildOf.map((t) => t.uid);

    return (goal) => {
      if (allowedOptionIds.length === 0) {
        return [{
          key: goalValidationError.canBeChildOfMustBeEmpty,
          property: { isDirect: true, key: goalConfig.edges.parents },
          payload: { expected: [], goal },
        }];
      }
      const selectedOptions = goal.properties.find((p) => p.property.isGoalType).selectedOptions;
      if (selectedOptions.length === 0) {
        return [{
          key: goalValidationError.canBeChildOf,
          property: { isDirect: true, key: goalConfig.edges.parents },
          payload: { expected: allowedOptionIds, actual: [], goal },
        }];
      }
      return selectedOptions.reduce((res, next) => {
        if (!allowedOptionIds.includes(next.uid)) {
          res.push({
            key: goalValidationError.canBeChildOf,
            property: { isDirect: true, key: goalConfig.edges.parents },
            payload: { expected: allowedOptionIds, actual: next, goal },
          });
        }
        return res;
      }, []);
    };
  };

  const rules = goalTypeOptions.reduce((res, next) => {
    res[`${next.uid}`] = createRules(next);
    return res;
  }, {});

  return { rules };
}
