<template>
  <page-layout
    class="space-list"
    hide-top-bar
  >
    <scroll-container>
      <m-content
        padding-x="layout"
        class="_header"
      >
        <page-header
          :title="$t('spaceList.title', { total: spaces.length })"
          heading="h3"
          underlined
          boxed
          no-padding-x
        />
        <m-content
          boxed
          no-padding
        >
          <m-content :padding-y="8" />
          <m-btn
            small
            super-light
            :href="$t('spaceList.helpCenterLink')"
            hide-border
            icon="question-circle"
            target="_blank"
          >
            {{ $t('spaceList.learnMore') }}
          </m-btn>
        </m-content>
      </m-content>
      <m-content
        padding-x="layout"
        boxed
        class="_table-header"
      >
        <list-header class="_list-header">
          <template #left>
            <list-tab
              v-for="filter in filterList"
              :key="filter.value"
              :active="selectedFilter === filter.value"
              @click="selectedFilter = filter.value"
            >
              <item-title :title="filter.text" />
            </list-tab>
          </template>
          <template
            v-if="canCreate"
            #right
          >
            <list-string-filter
              class="_btn"
              small
              @input="val => search = val"
            />
            <m-btn
              color="primary"
              small
              @click="showAddModal = true"
            >
              {{ $t('spaceList.create') }}
            </m-btn>
            <m-dialog
              :value="showAddModal"
              :title="$t('spaceEditor.create')"
              hide-footer
              no-padding
              @close="hide"
            >
              <space-editor
                @created="showAddModal = false"
              />
            </m-dialog>
          </template>
        </list-header>
      </m-content>
      <m-content
        boxed
        padding-x="layout"
      >
        <space-list-table
          :search="search"
          :filter="selectedFilter"
        />
      </m-content>
    </scroll-container>
  </page-layout>
</template>

<script>
import ItemTitle from '@/components/ItemTitle.vue';
import ListHeader from '@/components/list/ListHeader.vue';
import ListStringFilter from '@/components/list/ListStringFilter.vue';
import ListTab from '@/components/list/ListTab.vue';
import PageHeader from 'shared/components/PageHeader.vue';
import PageLayout from '@/components/page/PageLayout.vue';
import ScrollContainer from '@/components/page/ScrollContainer.vue';
import SpaceEditor from '@/components/spaces/SpaceEditor.vue';
import SpaceListTable from '@/components/spaces/SpaceListTable.vue';
import useAccess from '@/composables/access/access';
import useSpaces from '@/composables/space/spaces';
import { SPACE_STATUS_ACTIVE, SPACE_STATUS_ALL, SPACE_STATUS_ARCHIVED, SPACE_STATUS_JOINED } from '@/lib/constants';
import { accessGroupFlag } from 'shared/constants.json';

export default {
  name: 'SpaceList',
  components: {
    ItemTitle,
    ListTab,
    SpaceEditor,
    SpaceListTable,
    ListHeader,
    ListStringFilter,
    ScrollContainer,
    PageLayout,
    PageHeader,
  },
  setup() {
    const { userHasRight } = useAccess();
    const { spaces } = useSpaces();
    return {
      userHasRight,
      spaces,
    };
  },
  data() {
    const filterList = [SPACE_STATUS_ACTIVE, SPACE_STATUS_ARCHIVED, SPACE_STATUS_JOINED, SPACE_STATUS_ALL].map((f) => ({
      text: this.$t(`spaceList.filters.${f}`),
      value: f,
    }));
    return {
      showAddModal: false,
      search: '',
      // TODO: communicate modal dirtyness
      modalDirty: false,
      filterList,
      selectedFilter: filterList[0].value,
    };
  },
  computed: {
    canCreate() {
      return this.userHasRight([accessGroupFlag.spaceWriteAccess]);
    },
  },
  methods: {
    hide() {
      const hideFn = () => {
        this.showAddModal = false;
      };

      if (!this.modalDirty) {
        hideFn();
        return;
      }

      const title = this.$t('general.discardEditPrompt');
      const okText = this.$t('general.yesDiscard');
      const cancelText = this.$t('general.close');
      this.$confirm({
        title,
        okText,
        okType: 'danger',
        cancelText,
        maskClosable: true,
        onOk() {
          hideFn();
        },
      });
    },
  },
};
</script>

<style
  scoped
  lang="scss"
  type="text/scss"
>
  .space-list {
    ._header {
      position: sticky;
      left: 0;
      margin-bottom: 4rem;
    }

    ._table-header {
      position: sticky;
      left: 0;
      display: flex;
      align-items: center;

      :deep(._list-header) {
        flex: 1 1 auto;
        padding-bottom: .6rem;
      }
    }

    ._left {
      margin-right: 2rem;
      font-size: $font-size-5;
      font-weight: $font-weight-bold;
    }

    ._right {
      display: flex;
      flex-direction: row;
      margin-left: auto;
      overflow: auto;

      ._btn {
        margin-right: .2rem;
      }
    }
  }
</style>
