// TODO: Improve this to look more like the text search mechanism of DGraph
const containsAll = (value, searchText) => {
  const tokens = searchText.toLowerCase().split(' ');
  const lcValue = value.toLowerCase();
  for (let i = 0; i < tokens.length; i++) {
    if (lcValue.search(tokens[i]) === -1) {
      return false;
    }
  }
  return true;
};

export const textFn = ({ propertyValues, scope }) => {
  const pvs = propertyValues.filter((pv) => pv.property.uid === scope.property.uid);
  if (pvs.length === 0) {
    return false;
  }

  if (scope.isEmpty) {
    return pvs[0].number === null;
  }

  return containsAll(pvs[0].text, scope.text);
};

export const directTextFn = ({ entity, scope }) => {
  if (scope.text === undefined) {
    return (scope.isEmpty === true && (entity[scope.directProperty.edgeName] === undefined || entity[scope.directProperty.edgeName] === ''))
      || (scope.isEmpty === false && !(entity[scope.directProperty.edgeName] === undefined || entity[scope.directProperty.edgeName] === ''));
  }

  return containsAll(entity[scope.directProperty.edgeName], scope.text);
};
