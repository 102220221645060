<template>
  <div class="meeting-template-list">
    <m-card-item :clickable="false">
      {{ $t('meetingTemplateList.templatesFor') }} <strong>{{ $t('navigation.meetings') }}</strong>
    </m-card-item>
    <m-divider
      no-border
      xxs
    />
    <m-card-item
      v-if="templates.length === 0"
      light
      class="_hint"
      :clickable="false"
      tertiary
    >
      <div class="_inner">
        {{ $t('meetingTemplateList.emptyList') }}
      </div>
    </m-card-item>
    <template v-else>
      <template-list-item
        v-for="template in meetingTemplates"
        :key="template.uid"
        :template="template"
        always-show-actions
        @created="handleCreate"
        @select-template="createFromTemplate"
        @edit="openTemplate"
        @delete="deleteTemplate"
      />
    </template>
    <m-divider xxs />
    <m-card-item
      icon="plus"
      @click="createTemplate"
    >
      {{ $t('meetingTemplateList.newTemplate') }}
    </m-card-item>
  </div>
</template>

<script>
import TemplateListItem from '@/components/page/TemplateListItem.vue';
import deleteMixin from '@/mixins/delete';
import navigationMixin from '@/mixins/router-navigation';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useProperties from '@/composables/property/property';
import { VIEWS_SERVICE } from '@/lib/constants';
import { accessPolicyType, routeName } from 'shared/constants.json';
import { children as collectionChildren } from '@/api/query/collection';
import { collection as collectionConfig, meeting as meetingConfig } from 'shared/api/query/configs.json';
import { emptyValues } from '@/lib/property';
import { getPropertyValuesFromFilter } from '@/lib/property-value';
import { inject } from 'vue';
import { mapActions, mapState } from 'vuex';
import { children as meetingChildren } from '@/api/query/meeting';

export default {
  name: 'MeetingTemplateList',
  props: {
    templates: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const viewsService = inject(VIEWS_SERVICE);
    const { meetingProperties } = useProperties();
    const { loggedInUser } = useLoggedInUser();
    const { loggedInUserAccount } = useLoggedInUserAccount();
    return {
      meetingProperties,
      loggedInUser,
      loggedInUserAccount,
      selectedView: viewsService.currentView,
    };
  },
  emits: ['created'],
  components: { TemplateListItem },
  data() {
    return { createLoading: false };
  },
  computed: {
    ...mapState({ collections: (state) => state.collection }),
    meetingTemplates() {
      return [
        ...this.templates,
        {
          title: this.$t('meetingTemplateList.empty'),
          ...this.defaultPropertyValues,
          content: null,
          isEmpty: true,
        },
      ];
    },
    defaultPropertyValues() {
      return getPropertyValuesFromFilter(this.selectedView.params.filter, emptyValues(this.meetingProperties), this.loggedInUser, 'propertyValues');
    },
  },
  methods: {
    ...mapActions(['createEntity']),
    openTemplate(template) {
      this.open({ uid: template.uid, paramKey: 'meetingId', mobileRouteName: routeName.meetingDetails, name: routeName.meetingsExplorer });
    },
    deleteTemplate(template) {
      this.showDeleteModal({ entities: [template], model: meetingConfig.model, mutation: 'MEETINGS_DELETED' })();
    },
    handleCreate() {
      this.$emit('created');
    },
    createFromTemplate({ template }) {
      let title = template.title;
      if (template.isEmpty) {
        title = '';
      }
      this.createEntity({
        entity: {
          account: { uid: this.loggedInUserAccount.uid },
          creator: { uid: this.loggedInUser.uid },
          isTemplate: false,
          accessPolicy: {
            account: { uid: this.loggedInUserAccount.uid },
            accountAccess: accessPolicyType.disabled,
          },
          propertyValues: template.propertyValues.map((v) => ({
            ...v,
            uid: 0,
          })),
          title,
          icon: template.icon,
          participants: [{ uid: this.loggedInUser.uid }],
          content: template.content,
        },
        model: meetingConfig.model,
        entityById: () => [{ children: meetingChildren }],
      }).then((response) => {
        if (response.status !== 201) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
          return;
        }

        if (template.collection === null) {
          this.createLoading = false;
          this.open({ uid: response.data.uid, paramKey: 'meetingId', mobileRouteName: routeName.meetingDetails });
          this.$emit('created');
          return;
        }

        this.createEntity({
          entity: {
            ...template.collection,
            uid: 0,
            meeting: { uid: response.data.uid },
            items: template.collection.items.map((i) => ({
              ...i,
              uid: 0,
              content: {
                ...i.content,
                uid: 0,
              },
            })),
          },
          entityById: () => [{ children: collectionChildren }],
          mutation: 'MEETING_AGENDA_CREATED',
          model: collectionConfig.model,
        }).then((r) => {
          if (r.status !== 201) {
            this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
            return;
          }

          this.createLoading = false;
          this.open({ uid: response.data.uid, paramKey: 'meetingId', mobileRouteName: routeName.meetingDetails });
          this.$emit('created');
        });
      });
    },
    createTemplate() {
      this.createLoading = true;

      this.createEntity({
        entity: {
          account: { uid: this.loggedInUserAccount.uid },
          creator: this.loggedInUser,
          isTemplate: true,
          accessPolicy: {
            account: { uid: this.loggedInUserAccount.uid },
            accountAccess: accessPolicyType.disabled,
          },
          ...this.defaultPropertyValues,
          participants: [],
        },
        model: meetingConfig.model,
        entityById: () => [{ children: meetingChildren }],
      }).then((response) => {
        if (response.status !== 201) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
          return;
        }

        this.createLoading = false;
        this.open({ uid: response.data.uid, paramKey: 'meetingId', mobileRouteName: routeName.meetingDetails });
      });
    },
  },
  mixins: [navigationMixin, deleteMixin],
};
</script>

<style scoped lang="scss" type="text/scss">
  .meeting-template-list {
    ._hint {
      ._inner {
        font-size: $font-size-2;
        white-space: normal;
      }
    }
  }
</style>
