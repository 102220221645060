<template>
  <m-card
    class="grid-page-tile-single-goal-editor _overlay"
    padding-small
  >
    <m-form-item
      :style="{marginBottom: '1rem'}"
      :label="$t('gridPageTileSingleGoalEditor.goalPicker')"
    >
      <goal-picker-v2
        :value="goal"
        :placeholder="$t('gridPageTileSingleGoalEditor.goalPickerPlaceholder')"
        :default-view="defaultView"
        :view-application="viewApplication.gridPageTileGoalGoalPicker"
        :initial-selected-cycles="selectedCycles"
        :base-filter="baseFilter"
        :disabled-condition="disabledCondition"
        block
        placement="bottomCenter"
        @update:value="updateGoal"
      />
    </m-form-item>
    <m-form-item
      :style="{marginBottom: '1rem'}"
      :label="$t('gridPageTileSingleGoalEditor.visualizeAs')"
    >
      <m-select
        :value="visualizeAs"
        :items="visualizationTypes"
        full-width
        match-trigger-width
        @update:value="updateVisualizeAs"
      />
    </m-form-item>
    <m-form-item
      v-if="visualizeAs === gridPageTileSingleGoalType.number"
      :label="$t('gridPageTileSingleGoalEditor.compareTo')"
    >
      <compare-to-previous
        :style="{marginBottom: '0rem'}"
        :value="compareTo"
        component="focusable"
        full-width
        @update:value="updateCompareTo"
      />
    </m-form-item>
    <m-form-item
      v-if="visualizeAs === gridPageTileSingleGoalType.line"
      :style="{marginBottom: '0'}"
      :label="$t('gridPageTileSingleGoalEditor.dateRange')"
    >
      <m-select
        :value="dateRange"
        :items="dateRangeOptions"
        full-width
        match-trigger-width
        @update:value="updateDateRange"
      />
    </m-form-item>
  </m-card>
</template>

<script setup>
import CompareToPrevious from '@/components/list/CompareToPrevious.vue';
import GoalPickerV2 from '@/components/goal/GoalPickerV2.vue';
import useGoalDefaultProps from '@/composables/saved-views/goal-default-props';
import useGridPage from '@/composables/grid-page/grid-page';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import usePersistedGoalCycle from '@/composables/goal-cycle/persisted-goal-cycle';
import usePublishedAtFilter from '@/composables/goal/published-at-filter';
import useSnackBar from '@/composables/snackbar';
import { AND } from 'shared/api/query/constants';
import { computed, ref, watch } from 'vue';
import { dateScopeDynamicType, goalProgressMeasurement, gridPageTileSingleGoalType, viewApplication } from 'shared/constants.json';
import { logCatch } from '@/lib/logger/logger';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const snackbar = useSnackBar();

const props = defineProps({
  gridPageTileSingleGoal: {
    type: Object,
    required: true,
  },
});

const gridPageSvc = useGridPage();
const updateGridPageTileSingleGoal = (gridPageTileSingleGoal) => gridPageSvc.updateGridPageTileSingleGoal(gridPageTileSingleGoal).catch(logCatch(() => {
  snackbar.error();
}));

const goal = ref([]);
watch(props.gridPageTileSingleGoal, (val) => {
  if (val.goal !== null) {
    goal.value = [val.goal.uid];
    return;
  }
  goal.value = [];
}, { immediate: true });
const updateGoal = (val) => {
  let goal = null;
  if (val.length > 0) {
    goal = {
      ...props.gridPageTileSingleGoal.goal,
      uid: val[0],
    };
  }
  if (props.gridPageTileSingleGoal.goal === null && goal === null) {
    return;
  }
  if (props.gridPageTileSingleGoal.goal !== null && goal !== null && props.gridPageTileSingleGoal.goal.uid === goal.uid) {
    return;
  }
  updateGridPageTileSingleGoal({
    ...props.gridPageTileSingleGoal,
    goal,
  });
};

const { loggedInUserAccount } = useLoggedInUserAccount();
const defaultPropsSvc = useGoalDefaultProps();
const defaultView = ref({
  params: {
    filter: { account: { uid: loggedInUserAccount.value.uid }, children: [], op: AND },
    order: [],
    props: defaultPropsSvc.defaultProps.value,
    applyFilterOnFirstLevelOnly: false,
  },
});
const { composedSelectedCycles: persistedSelectedCycles } = usePersistedGoalCycle();
const selectedCycles = ref(persistedSelectedCycles);
const { publishedAtFilter: baseFilter } = usePublishedAtFilter();
const disabledCondition = (g) => ![goalProgressMeasurement.alignedItems, goalProgressMeasurement.threshold, goalProgressMeasurement.continuous].includes(g.progressMeasurement);

const visualizationTypes = gridPageTileSingleGoalType.all.map((type) => ({
  text: t(`gridPageTileSingleGoalType.${type}`),
  value: type,
}));
const visualizeAs = computed(() => props.gridPageTileSingleGoal.type);
const updateVisualizeAs = (type) => updateGridPageTileSingleGoal({ ...props.gridPageTileSingleGoal, type });

const compareTo = computed(() => props.gridPageTileSingleGoal.compareTo);
const updateCompareTo = (compareTo) => updateGridPageTileSingleGoal({ ...props.gridPageTileSingleGoal, compareTo });

const dateRangeOptions = [
  {
    text: t('dateScope.allTime'),
    value: { type: 'between', dynamicType: dateScopeDynamicType.allTime },
  },
  {
    text: t('dateScope.thisWeek'),
    value: { type: 'between', dynamicType: dateScopeDynamicType.thisWeek },
  },
  {
    text: t('dateScope.lastNDays', { value: 7 }),
    value: { type: 'between', dynamicType: dateScopeDynamicType.lastXDays, amount: 7 },
  },
  {
    text: t('dateScope.lastWeek'),
    value: { type: 'between', dynamicType: dateScopeDynamicType.lastWeek },
  },
  {
    text: t('dateScope.lastNDays', { value: 14 }),
    value: { type: 'between', dynamicType: dateScopeDynamicType.lastXDays, amount: 14 },
  },
  {
    text: t('dateScope.thisMonth'),
    value: { type: 'between', dynamicType: dateScopeDynamicType.thisMonth },
  },
  {
    text: t('dateScope.lastNDays', { value: 30 }),
    value: { type: 'between', dynamicType: dateScopeDynamicType.lastXDays, amount: 30 },
  },
  {
    text: t('dateScope.lastMonth'),
    value: { type: 'between', dynamicType: dateScopeDynamicType.lastMonth },
  },
  {
    text: t('dateScope.lastNDays', { value: 60 }),
    value: { type: 'between', dynamicType: dateScopeDynamicType.lastXDays, amount: 60 },
  },
  {
    text: t('dateScope.thisQuarter'),
    value: { type: 'between', dynamicType: dateScopeDynamicType.thisQuarter },
  },
  {
    text: t('dateScope.lastNDays', { value: 90 }),
    value: { type: 'between', dynamicType: dateScopeDynamicType.lastXDays, amount: 90 },
  },
  {
    text: t('dateScope.lastQuarter'),
    value: { type: 'between', dynamicType: dateScopeDynamicType.lastQuarter },
  },
  {
    text: t('dateScope.thisYear'),
    value: { type: 'between', dynamicType: dateScopeDynamicType.thisYear },
  },
  {
    text: t('dateScope.lastNDays', { value: 365 }),
    value: { type: 'between', dynamicType: dateScopeDynamicType.lastXDays, amount: 365 },
  },
  {
    text: t('dateScope.lastYear'),
    value: { type: 'between', dynamicType: dateScopeDynamicType.lastYear },
  },
];
const dateRange = computed(() => props.gridPageTileSingleGoal.timeRange);
const updateDateRange = (timeRange) => updateGridPageTileSingleGoal({ ...props.gridPageTileSingleGoal, timeRange });

</script>

<style scoped lang="scss" type="text/scss">
._overlay {
  min-width: 25rem;
}

</style>
