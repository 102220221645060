import { schema } from 'normalizr';

export class Entity extends schema.Entity {
  constructor(key, definition = {}, options = {}) {
    super(key, definition, { idAttribute: 'uid', ...options });
    this.reverseEdges = options.reverseEdges;
    this.mergeStrategy = options.mergeStrategy;
    this.exported = options.exported;
    if (options.exported === undefined) {
      this.exported = false;
    }
    this.excludeFromDenormalization = options.excludeFromDenormalization;
    if (options.excludeFromDenormalization === undefined) {
      this.excludeFromDenormalization = {};
    }
    this.simple = false;
    if (options.simple !== undefined) {
      this.simple = options.simple;
    }
  }
}
