<template>
  <user-list
    class="_section-content"
  />
</template>

<script setup>
import UserList from '@/components/users/UserList.vue';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useUserDefaultProps from '@/composables/saved-views/user-default-props';
import useViewServiceInit from '@/composables/saved-views/view-service-init';
import { VIEWS_SERVICE } from '@/lib/constants';
import { computed, provide } from 'vue';
import { filter as spaceFilter } from '@/lib/filter/space/filter';
import { useI18n } from 'vue-i18n';
import { viewApplication, viewType } from 'shared/constants.json';

const props = defineProps({
  property: {
    type: Object,
    required: true,
  },
  space: {
    type: Object,
    required: true,
  },
});

const { loggedInUserAccount } = useLoggedInUserAccount();

const viewServiceInitService = useViewServiceInit();
const i18n = useI18n();

const { defaultProps } = useUserDefaultProps();

const initialFilter = computed(() => spaceFilter({
  property: props.property,
  space: props.space,
  account: { uid: loggedInUserAccount.value.uid },
}));

const viewApp = viewApplication.memberSpace;

const defaultView = {
  viewType: viewType.list,
  title: i18n.t('list.list'),
  viewApplication: viewApp,
  space: props.space,
  params: {
    filter: initialFilter.value,
    order: [],
    props: [],
  },
};

let config = viewServiceInitService.defaultConfig();
config = viewServiceInitService.withDefaultView(config, defaultView);
config = viewServiceInitService.withViewApplication(config, viewApp);
config = viewServiceInitService.withDefaultProps(config, defaultProps);
config = viewServiceInitService.withSpace(config, props.space);

const viewSvc = viewServiceInitService.routeAwareViewService(config);
provide(VIEWS_SERVICE, viewSvc);

</script>

<style scoped lang="scss" type="text/scss">

</style>
