<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="5"
      y="2.00999"
      width="14"
      height="2.07006"
      rx="1.03503"
      fill="currentColor"
    />
    <rect
      x="10.834"
      y="20.511"
      width="14.8512"
      height="2.33334"
      transform="rotate(-90 10.834 20.511)"
      fill="currentColor"
    />
    <path
      d="M5.71618 15.7648C5.32149 15.3736 5.32181 14.7359 5.7165 14.3447L6.04603 14.018C6.43583 13.6317 7.0642 13.6317 7.454 14.018L13.7501 20.2589C13.7501 20.2589 12.6835 21.3161 12.0001 21.9935C9.9533 19.9647 7.04738 17.0843 5.71618 15.7648Z"
      fill="currentColor"
    />
    <path
      d="M16.546 14.018C16.9358 13.6317 17.5642 13.6317 17.954 14.018L18.2835 14.3447C18.6782 14.7359 18.6785 15.3736 18.2838 15.7648C16.9527 17.0843 14.0468 19.9647 12.0001 21.9935C11.3166 21.3161 10.2499 20.2589 10.2499 20.2589L16.546 14.018Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default { name: 'BelowOrEqual' };
</script>

<style scoped lang="scss" type="text/scss">

</style>
