<template>
  <a
    :class="['link']"
    :href="value.href"
    :rel="rel"
    :target="target"
  >
    {{ value.children }}
  </a>
</template>

<script setup>
const props = defineProps({
  value: {
    type: Object,
    required: true,
  },
});
const isExternal = !props.value.href.startsWith('/');
const rel = isExternal ? 'noreferrer noopener' : undefined;
const target = isExternal ? '_blank' : undefined;
</script>

<style scoped>

</style>
