<template>
  <div class="hierarchical-table-cell">
    <div :style="indentationStyle" />
    <div class="_expand">
      <m-btn
        :disabled="disabled || !hasExpand"
        :icon="expandIcon"
        fab
        xs
        hide-border
        super-light
        :button-style="{'margin-right': '0.4rem'}"
        @click.stop.prevent="handleExpandClick"
      />
    </div>
    <slot />
  </div>
</template>
<script>

import { INDENTATION_WIDTH } from 'shared/constants';

export default {
  name: 'HierarchicalTableCell',
  props: {
    level: {
      type: Number,
      required: true,
    },
    hasExpand: {
      type: Boolean,
      default: false,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    indentationWidth: {
      type: Number,
      default: INDENTATION_WIDTH,
    },
  },
  emits: ['toggle-expand'],
  computed: {
    indentationStyle() {
      return {
        minWidth: `${this.level * this.indentationWidth}px`,
        width: `${this.level * this.indentationWidth}px`,
      };
    },
    expandIcon() {
      if (!this.hasExpand) {
        return 'dot';
      }
      return this.expanded ? 'chevron-down' : 'chevron-right';
    },
  },
  methods: {
    handleExpandClick($event) {
      if (this.disabled) {
        return;
      }
      this.$emit('toggle-expand', $event);
    },
  },
};
</script>

<style lang="scss" type="text/scss">
  @import 'shared/assets/scss/_padding.scss';

  .hierarchical-table-cell {
    display: flex;
    align-items: center;
    min-width: 24rem;
    min-height: 3rem;
    white-space: nowrap;
  }
</style>
