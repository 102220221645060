<template>
  <div :style="{ position: 'relative', width: size, height: size }">
    <div
      class="pie"
      :style="backgroundStyle"
    />
    <div
      :class="['pie', roundedBorders ? '' : '-sharp-borders', animate ? '-animate' : '']"
      :style="foregroundStyle"
    />
  </div>
</template>

<script>
import { optionColor } from 'shared/constants.json';

export default {
  name: 'SimplePieChart',
  props: {
    progress: {
      type: Number,
      required: true,
    },
    color: {
      type: String,
      default: optionColor.default,
    },
    size: {
      type: String,
      default: '20px',
    },
    borderWidth: {
      type: String,
      default: '5px',
    },
    roundedBorders: {
      type: Boolean,
      default: false,
    },
    animate: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    mappedColor() {
      switch (this.color) {
        case optionColor.red:
          return {
            foreground: this.$colors.red.base,
            background: this.$colors.red.lighten4,
          };
        case optionColor.blue:
          return {
            foreground: this.$colors.blue.darken3,
            background: this.$colors.blue.lighten2,
          };
        case optionColor.green:
          return {
            foreground: this.$colors.green.base,
            background: this.$colors.green.lighten4,
          };
        default:
          return {
            foreground: this.$colors.grey.base,
            background: this.$colors.grey.lighten4,
          };
      }
    },
    backgroundStyle() {
      return {
        '--p': 100,
        '--c': this.mappedColor.background,
        '--w': this.size,
        '--b': this.borderWidth,
        position: 'absolute',
        top: 0,
        left: 0,
      };
    },
    foregroundStyle() {
      return {
        '--p': this.progress,
        '--c': this.mappedColor.foreground,
        '--w': this.size,
        '--b': this.borderWidth,
        position: 'absolute',
        top: 0,
        left: 0,
      };
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .pie {
    position: relative;
    display: inline-grid;
    width: var(--w);
    aspect-ratio: 1;
    place-content: center;

    &::before,
    &::after {
      position: absolute;
      content: "";
      border-radius: 50%;
    }

    &::before {
      background: radial-gradient(farthest-side, var(--c) 98%, #0000) top/var(--b) var(--b) no-repeat, conic-gradient(var(--c) calc(var(--p) * 1%), #0000 0);
      mask: radial-gradient(farthest-side, #0000 calc(99% - var(--b)), #000 calc(100% - var(--b)));
      inset: 0;
    }

    &::after {
      background: var(--c);
      transform: rotate(calc(var(--p) * 3.6deg)) translateY(calc(50% - var(--w) / 2));
      inset: calc(50% - var(--b) / 2);
    }

    &.-animate {
      animation: p 1s .5s both;
    }

    &.-sharp-borders::before {
      background-size: 0 0, auto;
    }

    &.-sharp-borders::after {
      content: none;
    }

    @keyframes p {
      from {
        --p: 0;
      }
    }
  }
</style>
