<template>
  <m-dialog
    v-model:value="showModal"
    :max-width="$modalSizes.lg"
    hide-footer
    keep-open-on-mask-click
    center
  >
    <div
      :style="{ textAlign: 'center', display: 'flex', alignItems: 'center', flexDirection: 'column' }"
    >
      <h3>
        {{ $t('productSurveyDialog.icons') }}<br>
        {{ $t('productSurveyDialog.title') }}
      </h3>
      <p :style="{ marginBottom: '2.4rem' }">
        {{ $t('productSurveyDialog.content') }}
      </p>
      <div :style="{ marginBottom: '1rem', fontWeight: '600' }">
        {{ $t('productSurveyDialog.hint') }}
      </div>
      <m-btn
        block
        color="primary"
        :style="{ marginBottom: '1rem' }"
        @click="takeSurvey"
      >
        {{ $t('productSurveyDialog.callToAction') }}
        <m-icon
          type="external-link"
          color="white"
          :style="{ marginLeft: '.8rem' }"
        />
      </m-btn>
      <m-btn
        block
        @click="hide"
      >
        {{ $t('productSurveyDialog.cancel') }}
      </m-btn>
      <div
        v-if="user.language === 'de'"
        :style="{ marginTop: '3rem', color: $colors.grey.base }"
      >
        P.S. Die Umfrage ist auf Englisch, kann aber auch auf Deutsch beantwortet werden.
      </div>
    </div>
  </m-dialog>
</template>

<script>
import useUsers from '@/composables/user/users';
import { mapActions } from 'vuex';

export default {
  name: 'ProductSurveyDialog',
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { updateUser } = useUsers();
    return { updateUser };
  },
  data() {
    return { showModal: true };
  },
  methods: {
    ...mapActions(['updateProductSurveyStatus']),
    takeSurvey() {
      this.updateStatus('clicked');
      const url = new URL(this.$t('productSurveyDialog.surveyLink'));
      url.hash = `user_id=${this.user.uid}&source=app`;
      window.open(url.toString(), '_blank').focus();
      this.showModal = false;
    },
    hide() {
      this.updateStatus('declined');
      this.showModal = false;
    },
    updateStatus(status) {
      this.updateUser({ uid: this.user.uid, productSurveyStatus: status }).catch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      });
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">

</style>
