import { UID } from 'shared/api/query/constants';
import {
  dataSource as dataSourceConfig,
  goal as goalConfig,
  spreadsheetCell as spreadsheetCellConfig,
} from 'shared/api/query/configs.json';

export const spreadsheetCellChildren = [
  { attr: UID },
  { attr: spreadsheetCellConfig.edges.documentId },
  { attr: spreadsheetCellConfig.edges.documentTitle },
  { attr: spreadsheetCellConfig.edges.sheetName },
  { attr: spreadsheetCellConfig.edges.alwaysSync, default: false },
  { attr: spreadsheetCellConfig.edges.sheetId },
  {
    attr: spreadsheetCellConfig.edges.dataSource,
    model: dataSourceConfig.model,
    default: null,
    children: [{ attr: UID }],
  },
  {
    attr: spreadsheetCellConfig.edges.goal,
    model: goalConfig.model,
    children: [{ attr: UID }],
  },
  { attr: spreadsheetCellConfig.edges.column },
  { attr: spreadsheetCellConfig.edges.row },
  { attr: spreadsheetCellConfig.edges.error, default: '' },
  { attr: spreadsheetCellConfig.edges.url, default: '' },
];
