<template>
  <div class="goal-scope">
    <div
      v-if="!hideOp"
      class="_op"
    >
      <m-select
        v-model:value="selectedType"
        :items="operatorTypes"
        :read-only="readOnly"
        :disabled="disabled"
        class="_select"
      />
    </div>
    <goal-picker
      v-if="!isEmpty"
      v-model:value="selectedGoal"
      class="_goal-select"
      :placeholder="$t('general.empty')"
      :read-only="readOnly"
      :disabled="disabled"
      :style="{maxWidth: '30rem'}"
      full-width
      show-description
      :max-tag-text-length="20"
    />
  </div>
</template>

<script>
import GoalPicker from '@/components/goal/GoalPicker.vue';
import { AND, EMPTY, NOT, NOT_EMPTY } from '@/lib/filter/scope-tree';
import { copy } from 'shared/lib/copy';

export default {
  name: 'GoalScope',
  props: {
    value: {
      type: Object,
      required: true,
    },
    fieldName: {
      type: String,
      required: true,
    },
    isNot: {
      type: Boolean,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideOp: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input', 'update:value', 'change-not', 'change'],
  components: { GoalPicker },
  data() {
    return {
      selectedGoal: null,
      operatorTypes: [
        { text: this.$t('optionScope.is'), value: AND },
        { text: this.$t('optionScope.not'), value: NOT },
        { text: this.$t('optionScope.isEmpty'), value: EMPTY },
        { text: this.$t('optionScope.isNotEmpty'), value: NOT_EMPTY },
      ],
      isEmpty: false,
    };
  },
  computed: {
    selectedType: {
      get() {
        if (this.isNot) {
          if (this.isEmpty) {
            return NOT_EMPTY;
          }
          return NOT;
        }
        if (this.isEmpty) {
          return EMPTY;
        }
        return AND;
      },
      set(val) {
        this.updateEmpty(val);
        if ([NOT, NOT_EMPTY].includes(val)) {
          this.$emit('change-not', NOT);
          return;
        }
        this.$emit('change-not', AND);
      },
    },
    scope() {
      return {
        ...this.value,
        [this.fieldName]: this.selectedGoal,
        isEmpty: this.isEmpty,
      };
    },
  },
  methods: {
    updateEmpty(type) {
      if ([EMPTY, NOT_EMPTY].includes(type)) {
        this.isEmpty = true;
        this.selectedGoal = null;
        return;
      }
      this.isEmpty = false;
    },
    updateValues() {
      let isEmpty = false;
      if (this.value !== null && typeof this.value.isEmpty !== 'undefined') {
        isEmpty = this.value.isEmpty;
      }
      this.isEmpty = isEmpty;

      if (this.value === null
        || this.value[this.fieldName] === null
      ) {
        this.selectedGoal = null;
        return;
      }
      this.selectedGoal = this.value[this.fieldName];
    },
    emitUpdate(val) {
      this.$emit('input', val);
      this.$emit('update:value', val);
      this.$emit('change', val);
    },
  },
  watch: {
    scope: {
      handler(newVal, oldVal) {
        if (JSON.stringify(oldVal) === JSON.stringify(newVal)) {
          return;
        }

        this.emitUpdate(copy(newVal));
      },
      deep: true,
    },
    value: {
      handler() {
        this.updateValues();
      },
      deep: true,
    },
  },
  created() {
    this.updateValues();
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .goal-scope {
    display: flex;

    ._op {
      margin-right: .4rem;

      ._select {
        max-width: 100%;
      }
    }

    ._goal-select {
      min-width: 20rem;
    }
  }
</style>
