export const installRequestID = (config) => {
  const res = btoa([...Array(2).keys()].map(() => Math.random() * Number.MAX_SAFE_INTEGER));
  return {
    ...config,
    headers: {
      ...config.headers,
      'x-request-id': res,
    },
  };
};
