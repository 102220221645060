<template>
  <div class="participation-list">
    <template v-if="missing.length > 0">
      <div class="_heading">
        {{ $t('participationList.missing') }}
      </div>
      <m-card-item
        v-for="user in missing"
        :key="user.uid"
        class="_item"
        no-hover
        :clickable="false"
      >
        <user-display
          :user="selectSingle(user.uid)"
          small
        />
      </m-card-item>
    </template>
    <template v-if="done.length > 0">
      <div class="_heading">
        {{ $t('participationList.done') }}
      </div>
      <m-card-item
        v-for="item in done"
        :key="item.user.uid"
        no-hover
        :clickable="false"
        class="_item"
      >
        <user-display :user="selectSingle(item.user.uid)">
          <template #subName>
            {{ formatDate(item.fulfilledAt) }}
          </template>
        </user-display>
      </m-card-item>
    </template>
  </div>
</template>

<script>
import UserDisplay from 'shared/components/UserDisplay.vue';
import useUsers from '@/composables/user/users';
import { DateTime } from 'luxon';

export default {
  name: 'ParticipationList',
  props: {
    participation: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { selectSingle } = useUsers();
    return { selectSingle };
  },
  components: { UserDisplay },
  computed: {
    missing() {
      return this.participation.users.filter((u) => !u.participated).map((u) => u.user);
    },
    done() {
      return this.participation.users.filter((u) => u.participated);
    },
  },
  methods: {
    formatDate(date) {
      return DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED);
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .participation-list {
    ._heading {
      &:first-of-type {
        margin-top: 0;
      }

      margin-top: .8rem;
      margin-left: 1.6rem;
      font-weight: $font-weight-bold;
    }

    ._item {
      ._inner {
        display: flex;
        align-items: center;

        ._name {
          line-height: 1.2;
        }

        ._fulfilled-at {
          font-size: $font-size-2;
          line-height: 1.2;
          color: $font-color-tertiary;
        }
      }

      ._avatar {
        margin-right: 1rem;
      }
    }
  }
</style>
