import useGridPageConfig from '@/composables/grid-page/grid-page-config';
import useGridPageRepo from '@/composables/grid-page/grid-page-repo';
import { computed, ref } from 'vue';

const heightDiff = ref(null);
const widthDiff = ref({});

export default function useGridPageResize() {
  const gridPageRepo = useGridPageRepo();
  const gridPageConfig = useGridPageConfig();

  const isResizing = computed(() => heightDiff.value !== null || Object.keys(widthDiff.value).length !== 0);
  const tilesResizing = computed(() => Object.keys(widthDiff.value).map((k) => parseInt(k, 10)));
  const rowResizing = computed(() => (heightDiff.value !== null ? heightDiff.value.gridPageRow : null));

  const tilesWithDiff = computed(() => gridPageRepo.gridPageTiles.value.reduce((res, tile) => {
    let diff = widthDiff.value[tile.uid];
    if (diff === undefined) {
      diff = null;
    }

    const next = { ...tile, diff };

    return {
      ...res,
      [tile.uid]: next,
    };
  }, {}));

  const rowsWithDiff = computed(() => gridPageRepo.gridPageRows.value.reduce((res, row) => {
    let diff = null;
    if (heightDiff.value !== null && heightDiff.value.gridPageRow.uid === row.uid) {
      diff = heightDiff.value.diff;
    }

    return {
      ...res,
      [row.uid]: { height: row.height, diff },
    };
  }, {}));

  function columnDiff(diff, columnWidth) {
    return Math.round(diff / columnWidth);
  }

  function addWidthDiff(tile, diff, columnWidth) {
    const actualTile = gridPageRepo.gridPageTiles.value.find((t) => t.uid === tile.uid);
    const actualRow = gridPageRepo.gridPageRows.value.find((r) => r.uid === actualTile.gridPageRow.uid);
    const index = actualRow.tileOrder.findIndex(({ uid }) => uid === tile.uid);
    const nextTile = gridPageRepo.gridPageTiles.value.find((tile) => tile.uid === actualRow.tileOrder[index + 1].uid);
    const rowTiles = [actualTile, nextTile];

    if (rowTiles[0].columns + columnDiff(diff, columnWidth) < gridPageConfig.minColumns
    || (rowTiles[0].columns + columnDiff(diff, columnWidth) === gridPageConfig.minColumns && diff < 0 && (diff % columnWidth > -columnWidth / 2))) {
      return;
    }

    if (rowTiles[1].columns - columnDiff(diff, columnWidth) < gridPageConfig.minColumns
    || (rowTiles[1].columns - columnDiff(diff, columnWidth) === gridPageConfig.minColumns && diff > 0 && (diff % columnWidth < columnWidth / 2))) {
      return;
    }

    widthDiff.value[rowTiles[0].uid] = diff;
    widthDiff.value[rowTiles[1].uid] = -1 * diff;
  }

  function commitWidth(tile, diff, columnWidth) {
    const actualTile = gridPageRepo.gridPageTiles.value.find((t) => t.uid === tile.uid);
    const actualRow = gridPageRepo.gridPageRows.value.find((r) => r.uid === actualTile.gridPageRow.uid);
    const index = actualRow.tileOrder.findIndex(({ uid }) => uid === tile.uid);
    const nextTile = gridPageRepo.gridPageTiles.value.find((tile) => tile.uid === actualRow.tileOrder[index + 1].uid);
    const rowTiles = [actualTile, nextTile];

    let nextLeft = rowTiles[0].columns + columnDiff(diff, columnWidth);
    let nextRight = rowTiles[1].columns - columnDiff(diff, columnWidth);

    if (nextLeft < gridPageConfig.minColumns) {
      nextRight = rowTiles[1].columns + (rowTiles[0].columns - gridPageConfig.minColumns);
      nextLeft = gridPageConfig.minColumns;
    }

    if (nextRight < gridPageConfig.minColumns) {
      nextLeft = rowTiles[0].columns + (rowTiles[1].columns - gridPageConfig.minColumns);
      nextRight = gridPageConfig.minColumns;
    }

    const res = [
      { ...rowTiles[0], columns: nextLeft },
      { ...rowTiles[1], columns: nextRight },
    ];

    return gridPageRepo.updateGridPageTiles(res).finally(() => { widthDiff.value = {}; });
  }

  function addHeightDiff(row, diff) {
    const actualRow = gridPageRepo.gridPageRows.value.find((r) => r.uid === row.uid);
    const actualTiles = actualRow.tiles.map(({ uid }) => gridPageRepo.gridPageTiles.value.find((tile) => tile.uid === uid)).filter((tile) => tile !== undefined);

    if (actualRow.height + diff < gridPageConfig.getMinHeight(actualTiles)) {
      return;
    }

    heightDiff.value = { gridPageRow: row, diff };
  }

  function commitHeight(row) {
    const actualRow = gridPageRepo.gridPageRows.value.find((r) => r.uid === row.uid);

    const next = rowsWithDiff.value[row.uid].height + rowsWithDiff.value[row.uid].diff;
    return gridPageRepo.updateGridPageRow({ uid: actualRow.uid, height: next }).then(() => { heightDiff.value = null; });
  }

  return {
    isResizing,
    tilesResizing,
    rowResizing,
    tilesWithDiff,
    rowsWithDiff,
    addWidthDiff,
    commitWidth,
    addHeightDiff,
    commitHeight,
  };
}
