import { EQ, OR, UID, VAR } from 'shared/api/query/constants';
import { notHasFilter } from 'shared/api/query/filter';
import { property, propertyOption, propertyValue } from 'shared/api/query/configs.json';
import { reverseEdge } from 'shared/api/query/edges';

export const makeOptionsFn = (childrenFn, varIdFn, isFilterMode = false) => (scope, index) => {
  const varName = varIdFn(index);

  if (scope.isEmpty === true) {
    const varBlocks = [
      {
        alias: VAR,
        uid: [scope.property.uid],
        model: property.model,
        func: { name: UID },
        children: [
          {
            attr: reverseEdge(propertyValue.edges.property),
            model: propertyValue.model,
            filter: notHasFilter(propertyValue.edges.selectedOptions),
            children: childrenFn(varName),
          },
        ],
      },
    ];
    const filterTree = {
      func: {
        name: UID,
        needsVar: [{ name: varName, typ: 1 }],
      },
    };
    return { filterTrees: [filterTree], varBlocks };
  }

  if ((scope.selectedOptions === undefined || scope.selectedOptions.length === 0) && isFilterMode) {
    return { filterTrees: [], varBlocks: [] };
  }

  const optionIds = scope.selectedOptions.map((u) => u.uid);
  const varBlocks = [
    {
      alias: VAR,
      uid: optionIds,
      model: propertyOption.model,
      func: { name: UID },
      children: [
        {
          attr: reverseEdge(propertyValue.edges.selectedOptions),
          model: propertyValue.model,
          children: childrenFn(varName),
        },
      ],
    },
  ];

  const filterTree = {
    func: {
      name: UID,
      needsVar: [{ name: varName, typ: 1 }],
    },
  };
  return { filterTrees: [filterTree], varBlocks };
};

export const directPropOptionFn = (isFilterMode = false) => (scope) => {
  if (scope.isEmpty === true) {
    return {
      filterTrees: [notHasFilter(scope.directProperty.edgeName)],
      varBlocks: [],
    };
  }

  if ((scope.selectedOptions === undefined || scope.selectedOptions.length === 0) && isFilterMode) {
    return { filterTrees: [], varBlocks: [] };
  }

  return {
    filterTrees: [
      {
        op: OR,
        child: scope.selectedOptions.map((opt) => ({
          func: {
            attr: scope.directProperty.edgeName,
            name: EQ,
            args: [{ value: opt.value }],
          },
        })),
      },
    ],
    varBlocks: [],
  };
};
