<template>
  <div class="property-values">
    <property-label
      v-for="property in properties"
      :key="property.uid"
      :label="getLabel(property)"
      :icon="iconBy(property.type)"
      :has-menu="canEditProperties"
      class="_prop"
    >
      <property-form-item
        :property="property"
        :property-values="value"
        :placeholder="$t('general.empty')"
        :disabled="disabled"
        :read-only="readOnly"
        clearable
        hide-border
        popup
        full-width
        match-trigger-width
        :restrict-foreign-entity-selection="true"
        @change="emitUpdate"
      />
      <template #labelMenu>
        <slot
          v-if="canEditProperties"
          name="labelMenu"
          :property="property"
        />
      </template>
    </property-label>
  </div>
</template>

<script>
import PropertyFormItem from '@/components/PropertyFormItem.vue';
import PropertyLabel from '@/components/PropertyLabel.vue';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import { copy } from 'shared/lib/copy';
import { iconByType } from '@/lib/property';
import { propertyVisibility } from 'shared/constants.json';
import { textByLang } from 'shared/lib/language';
import { uniqBy } from 'lodash-es';

export default {
  name: 'PropertyValues',
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    properties: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    canEditProperties: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { userLang } = useLoggedInUser();
    return { userLang };
  },
  emits: ['input', 'update:value'],
  components: { PropertyFormItem, PropertyLabel },
  data() {
    return { propertyVisibility };
  },
  methods: {
    iconBy(type) {
      return iconByType({ type });
    },
    getLabel(property) {
      return textByLang(property.label, this.userLang);
    },
    emitUpdate(val) {
      const values = copy(this.value);
      this.$emit('input', uniqBy([val, ...values], (item) => item.property.uid));
      this.$emit('update:value', uniqBy([val, ...values], (item) => item.property.uid));
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
</style>
