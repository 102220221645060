import useRepo from '@/nebula/repo';
import { EVENTS } from '@/lib/constants';
import { EventBus } from '@/lib/event-bus';
import { favorite as favoriteConfig } from 'shared/api/query/configs.json';

export default function useFavorites() {
  const repo = useRepo(favoriteConfig.model);

  const createFavorite = (favorite) => repo.createSingle(favorite).then((favorite) => {
    EventBus.$emit(EVENTS.NAVIGATION.FAVORITE_CREATED);
    return favorite;
  });

  const deleteFavorite = (favorite) => repo.deleteSingle(favorite.uid).then(() => {
    EventBus.$emit(EVENTS.NAVIGATION.FAVORITE_DELETED);
  });

  return {
    createFavoriteLoading: repo.createSingleLoading,
    createFavorite,
    updateFavoriteLoading: repo.updateLoading,
    updateFavorite: repo.updateSingle,
    deleteFavoriteLoading: repo.deleteSingleLoading,
    deleteFavorite,

    favorites: repo.entityList,
  };
}
