<template>
  <div class="template-details">
    <div class="_header">
      <div class="_icon">
        {{ template.icon }}
      </div>
      <div class="_title">
        <h1>
          {{ template.title }}
        </h1>
      </div>
    </div>
    <div class="_questions">
      <div
        v-if="template.orderItems.length === 0"
        class="_empty"
      >
        {{ $t('templateDetails.noQuestions') }}
      </div>
      <div
        v-for="question in template.orderItems"
        :key="question.uid"
        class="_question"
      >
        <template-question-item
          :question="question"
          :selected="selectedQuestionIds.includes(question.uid)"
          @select="$emit('select', question)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TemplateQuestionItem from '@/components/feedback/TemplateQuestionItem.vue';

export default {
  name: 'TemplateDetails',
  props: {
    template: {
      type: Object,
      required: true,
    },
    selectedQuestionIds: {
      type: Array,
      required: true,
    },
  },
  emits: ['select'],
  components: { TemplateQuestionItem },
};
</script>

<style scoped lang="scss" type="text/scss">
  .template-details {
    margin-top: 5rem;

    ._header {
      ._icon {
        font-size: $font-size-10;
      }

      ._title {
        h1 {
          margin-top: .8rem;
        }
      }
    }

    ._questions {
      ._empty {
        color: $font-color-secondary;
      }

      ._question {
        margin-bottom: 2rem;
      }
    }
  }
</style>
