<template>
  <base-chart
    class="history-chart"
    :options="chartOptions"
  />
</template>

<script>
import BaseChart from '@/components/dashboard/BaseChart.vue';
import { NEGATIVE, NEUTRAL, POSITIVE, distributionToFavorability } from '@/lib/charts/favorability';
import { getBaseLineChartOptions } from '@/lib/charts/line-chart';
import { getCategoriesFromIntervals } from '@/lib/charts/categories';
import { intervalType } from 'shared/num_constants.json';
import {
  transformMultipleRawSeries,
} from '@/lib/charts/transform';

export default {
  name: 'HistoryChart',
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    chart: {
      type: Object,
      required: true,
    },
    intervalType: {
      type: String,
      default: intervalType.monthly,
    },
    maxValue: {
      type: Number,
      default: 0,
    },
    startsFromZero: {
      type: Boolean,
      default: false,
    },
    useDistribution: {
      type: Boolean,
      default: true,
    },
  },
  components: { BaseChart },
  computed: {
    minValue() {
      if (this.startsFromZero) {
        return 0;
      }
      return 1;
    },
    chartOptions() {
      const categories = getCategoriesFromIntervals(this.chartData.intervals, this.intervalType);
      const multiSeries = this.getMultiSeries();

      return getBaseLineChartOptions(categories, multiSeries);
    },
  },
  methods: {
    getMultiSeries() {
      if (!this.useDistribution) {
        return transformMultipleRawSeries(this.chartData.series);
      }

      const chartDataWithFavDistribution = this.distributionToFavorability(this.chartData);
      return this.favorabilityDistributionToSeries(chartDataWithFavDistribution);
    },
    distributionToFavorability(chartData) {
      const newSeries = chartData.series.map((s) => {
        const newData = s.data.map((item) => ({
          ...item,
          distribution: distributionToFavorability(item.distribution, this.maxValue, this.startsFromZero),
        }));
        return {
          ...s,
          data: newData,
        };
      });

      return { ...chartData, series: newSeries };
    },
    favorabilityDistributionToSeries(chartData) {
      const initial = [
        { name: this.$t('charts.positiveAnswers'), key: POSITIVE, data: [], color: this.$colors.green.base },
        { name: this.$t('charts.neutralAnswers'), key: NEUTRAL, data: [], color: this.$colors.grey.lighten1 },
        { name: this.$t('charts.negativeAnswers'), key: NEGATIVE, data: [], color: this.$colors.red.lighten1 },
      ];
      return chartData.series[0].data.reduce((acc, next) => acc.map((item) => {
        const value = next.distribution.filter((i) => i.key === item.key)[0];
        if (typeof value === 'undefined' || value === null) {
          item.data.push(null);
        } else {
          item.data.push(value.proportion);
        }
        return item;
      }), initial);
    },
  },
};
</script>

<style lang="scss" type="text/scss">

</style>
