import { numberFormat } from 'shared/constants.json';

export const numberFormatter = (format, locale) => {
  let valueAdjuster = (value) => value;
  const options = { style: 'decimal' };
  switch (format) {
    case numberFormat.number:
      options.useGrouping = false;
      break;
    case numberFormat.percent:
      options.maximumFractionDigits = 2;
      valueAdjuster = (value) => `${value}%`;
      break;
    case numberFormat.dollar:
      options.maximumFractionDigits = 2;
      valueAdjuster = (value) => `$${value}`;
      break;
    case numberFormat.euro:
      options.maximumFractionDigits = 2;
      valueAdjuster = (value) => `${value}€`;
      break;
    case numberFormat.pound:
      options.maximumFractionDigits = 2;
      valueAdjuster = (value) => `£${value}`;
      break;
    case numberFormat.commaSeparated:
    default:
      break;
  }
  return (value) => valueAdjuster(new Intl.NumberFormat(locale, options).format(value));
};

export const numberParser = (format, locale) => {
  let valueAdjuster = (value) => value;
  switch (format) {
    case numberFormat.percent:
      valueAdjuster = (value) => `${value}`.replace('%', '');
      break;
    case numberFormat.dollar:
      valueAdjuster = (value) => `${value}`.replace('$', '');
      break;
    case numberFormat.euro:
      valueAdjuster = (value) => `${value}`.replace('€', '');
      break;
    case numberFormat.pound:
      valueAdjuster = (value) => `${value}`.replace('£', '');
      break;
    case numberFormat.number:
    case numberFormat.commaSeparated:
    default:
      break;
  }
  return (value) => parseLocaleNumber(valueAdjuster(value), locale);
};

/**
 * Parse a localized number to a float.
 * @param {string} stringNumber - the localized number
 * @param {string} locale - [optional] the locale that the number is represented in. Omit this parameter to use the current locale.
 */
const parseLocaleNumber = (stringNumber, locale) => {
  const formatter = new Intl.NumberFormat(locale);
  const thousandSeparator = formatter.formatToParts(11111)[1].value;
  const decimalSeparator = formatter.formatToParts(1.1)[1].value;

  return parseFloat(stringNumber
    .replace(new RegExp(`\\${thousandSeparator}`, 'g'), '')
    .replace(new RegExp(`\\${decimalSeparator}`), '.'));
};
