import { gridPageType } from 'shared/constants.json';
import { useI18n } from 'vue-i18n';

export default function useGridPageConfig() {
  const { t } = useI18n();

  const defaultHeight = 215;

  const getDefaultHeightPerType = (type) => {
    switch (type) {
      case gridPageType.text:
        return 45;
      case gridPageType.goalList:
      case gridPageType.updateFeedList:
      case gridPageType.memberList:
      case gridPageType.checkinList:
        return 400;
      default:
        return defaultHeight;
    }
  };

  const createActions = [
    { label: t('gridPage.gridActions.singleGoalProgress'), icon: 'number', gridPageType: gridPageType.singleGoal },
    { label: t('gridPage.gridActions.text'), icon: 'alignLeft', gridPageType: gridPageType.text },
    { label: t('gridPage.gridActions.goalList'), icon: 'compass', gridPageType: gridPageType.goalList },
    { label: t('gridPage.gridActions.checkinList'), icon: 'history', gridPageType: gridPageType.checkinList },
    { label: t('gridPage.gridActions.updateFeedList'), icon: 'feedViewIcon', gridPageType: gridPageType.updateFeedList },
    { label: t('gridPage.gridActions.memberList'), icon: 'team', gridPageType: gridPageType.memberList },
  ];

  const getMinHeight = (tiles) => {
    const getMinHeightPerTileType = (type) => {
      switch (type) {
        case gridPageType.text:
          return 45;
        default:
          return defaultHeight;
      }
    };

    const minHeights = tiles.map((t) => getMinHeightPerTileType(t.type));
    return Math.max(...minHeights);
  };

  return {
    minColumns: 3,
    maxColumns: 12,
    maxTiles: 4,
    gapWidth: 20,
    defaultHeight,
    getDefaultHeightPerType,
    getMinHeight,
    indicatorHighlightSize: 32,

    createActions,
  };
}
