import { DateTime } from 'luxon';
import { dateScopeDynamicType, dateScopeType } from 'shared/constants.json';

export const getType = (timeRange) => {
  if (timeRange.type !== undefined) {
    return timeRange.type;
  }

  let start = null;
  let end = null;
  if (timeRange.start !== undefined) {
    start = timeRange.start;
  }
  if (timeRange.end !== undefined) {
    end = timeRange.end;
  }

  if (start !== null && end !== null) {
    return dateScopeType.between;
  }
  if (start === null) {
    return dateScopeType.lessThan;
  }
  return dateScopeType.greaterThan;
};

export const isEmptyType = (timeRange) => getType(timeRange) === dateScopeType.empty;

export const isNotEmptyType = (timeRange) => getType(timeRange) === dateScopeType.notEmpty;

const toOutputFormat = (dateTime) => dateTime.startOf('second').toISO({ suppressMilliseconds: true });

const getDynamicRanges = (dynamicType, amount, now = null) => {
  let nowTimestamp = toOutputFormat(DateTime.local());
  if (now !== null) {
    nowTimestamp = now;
  }

  const zonedNow = DateTime.fromISO(nowTimestamp, { setZone: true });

  switch (dynamicType) {
    case dateScopeDynamicType.today:
      return {
        start: toOutputFormat(zonedNow.startOf('day')),
        end: toOutputFormat(zonedNow.endOf('day')),
      };
    case dateScopeDynamicType.yesterday:
      return {
        start: toOutputFormat(zonedNow.minus({ days: 1 }).startOf('day')),
        end: toOutputFormat(zonedNow.minus({ days: 1 }).endOf('day')),
      };
    case dateScopeDynamicType.lastXDays:
      return {
        start: toOutputFormat(zonedNow.minus({ days: amount }).startOf('day')),
        end: toOutputFormat(zonedNow.endOf('day')),
      };
    case dateScopeDynamicType.nextXDays:
      return {
        start: toOutputFormat(zonedNow.startOf('day')),
        end: toOutputFormat(zonedNow.plus({ days: amount }).endOf('day')),
      };
    case dateScopeDynamicType.thisWeek:
      return {
        start: toOutputFormat(zonedNow.startOf('week')),
        end: toOutputFormat(zonedNow.endOf('week')),
      };
    case dateScopeDynamicType.lastWeek:
      return {
        start: toOutputFormat(zonedNow.minus({ days: 7 }).startOf('week')),
        end: toOutputFormat(zonedNow.minus({ days: 7 }).endOf('week')),
      };
    case dateScopeDynamicType.thisMonth:
      return {
        start: toOutputFormat(zonedNow.startOf('month')),
        end: toOutputFormat(zonedNow.endOf('month')),
      };
    case dateScopeDynamicType.lastMonth:
      return {
        start: toOutputFormat(zonedNow.minus({ month: 1 }).startOf('month')),
        end: toOutputFormat(zonedNow.minus({ month: 1 }).endOf('month')),
      };
    case dateScopeDynamicType.thisQuarter:
      return {
        start: toOutputFormat(zonedNow.startOf('quarter')),
        end: toOutputFormat(zonedNow.endOf('quarter')),
      };
    case dateScopeDynamicType.lastQuarter:
      return {
        start: toOutputFormat(zonedNow.minus({ quarter: 1 }).startOf('quarter')),
        end: toOutputFormat(zonedNow.minus({ quarter: 1 }).endOf('quarter')),
      };
    case dateScopeDynamicType.thisYear:
      return {
        start: toOutputFormat(zonedNow.startOf('year')),
        end: toOutputFormat(zonedNow.endOf('year')),
      };
    case dateScopeDynamicType.lastYear:
      return {
        start: toOutputFormat(zonedNow.minus({ year: 1 }).startOf('year')),
        end: toOutputFormat(zonedNow.minus({ year: 1 }).endOf('year')),
      };
    default:
      return { start: null, end: null };
  }
};

export const translateDynamicTimeRange = (timeRange, now = null) => {
  let dynamicType = dateScopeDynamicType.exactDate;
  let amount = 0;
  if (timeRange.dynamicType !== undefined) {
    dynamicType = timeRange.dynamicType;
  }
  if (timeRange.amount !== undefined) {
    amount = timeRange.amount;
  }

  const type = getType(timeRange);
  if (dynamicType === dateScopeDynamicType.exactDate) {
    switch (type) {
      case dateScopeType.lessThan:
        return { start: null, end: timeRange.end };
      case dateScopeType.greaterThan:
        return { start: timeRange.start, end: null };
      case dateScopeType.between:
        return { start: timeRange.start, end: timeRange.end };
      default:
        return { start: null, end: null };
    }
  }

  const { start, end } = getDynamicRanges(dynamicType, amount, now);
  switch (type) {
    case dateScopeType.lessThan:
      if ([dateScopeDynamicType.nextXDays].includes(dynamicType)) {
        return { start: null, end };
      }
      return { start: null, end: start };
    case dateScopeType.greaterThan:
      if ([
        dateScopeDynamicType.lastXDays,
        dateScopeDynamicType.thisWeek,
        dateScopeDynamicType.lastWeek,
        dateScopeDynamicType.thisMonth,
        dateScopeDynamicType.lastMonth,
      ].includes(dynamicType)) {
        return { start, end: null };
      }
      return { start: end, end: null };
    case dateScopeType.between:
      return { start, end };
    default:
      return { start: null, end: null };
  }
};
