<template>
  <div class="r-rule-picker">
    <div class="_row">
      <m-form-item
        :label="$t('rRulePicker.schedule')"
        class="_item -start-date"
        :validate-status="startTimeHint === '' || hideError ? '': 'error'"
        hide-details
        :colon="false"
      >
        <m-date-picker
          :value="startDate"
          :disabled="disabled"
          :locale="userLang"
          :date-time="DateTime"
          include-time
          show-time-zone-picker
          @input="setStartDate"
        />
      </m-form-item>
      <m-form-item
        class="_item -recurrence"
        :label="$t('rRulePicker.recurrencePattern')"
        :colon="false"
        hide-details
      >
        <recurrence-selector
          :value="reccurrenceOptions"
          :start-date="startDate.startDate"
          :disabled="disabled"
          class="_recurrence-selector"
          @input="setRecurrenceOptions"
        />
      </m-form-item>
    </div>
    <div
      v-if="!hideError"
      class="_hint"
    >
      {{ startTimeHint }}
    </div>
  </div>
</template>

<script>
import RecurrenceSelector from '@/components/rrule/RecurrenceSelector.vue';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import { DateTime } from 'luxon';
import { RRule, rrulestr } from 'rrule';
import { frontendRRuleToBackend, hasValidStartDate } from '@/lib/rrule';
import { generateTime } from 'shared/lib/time';
import { isEqual } from 'lodash-es';

export default {
  name: 'RRulePicker',
  props: {
    value: {
      type: String,
      default: '',
    },
    hideError: {
      type: Boolean,
      default: false,
    },
    numberOccurrences: {
      type: Number,
      default: 5,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { userLang } = useLoggedInUser();
    return { userLang };
  },
  emits: ['input', 'update:value'],
  components: { RecurrenceSelector },
  data() {
    const d = DateTime.local();
    return {
      startDate: {
        startDate: d.toISODate(),
        startTime: d.toLocaleString(DateTime.TIME_24_SIMPLE),
        timeZone: d.zoneName,
      },
      reccurrenceOptions: null,
      DateTime,
    };
  },
  computed: {
    dtstartOptions() {
      return { dtstart: this.dtstart };
    },
    startTimeHint() {
      if (this.disabled || hasValidStartDate(this.value)) {
        return '';
      }
      return this.$t('rRulePicker.startTimeHint');
    },
    today() {
      return DateTime.local().toISO();
    },
    dtstart() {
      const startDate = DateTime.fromISO(this.startDate.startDate);
      const hours = parseInt(this.startDate.startTime.split(':')[0], 10);
      const minutes = parseInt(this.startDate.startTime.split(':')[1], 10);
      // rrule spec defines to either use the local time with a tzid or
      // to use UTC. We use a tzid, but the rrule.js frontend implementation
      // requires to put in a UTC date as dtstart. Otherwise, it transforms it
      // which results in a mismatch of byhour and dtstart and causes wrong schedules.
      return new Date(Date.UTC(
        startDate.year,
        startDate.month - 1,
        startDate.day,
        hours,
        minutes,
        0,
      ));
    },
    options() {
      const hours = parseInt(this.startDate.startTime.split(':')[0], 10);
      const minutes = parseInt(this.startDate.startTime.split(':')[1], 10);

      return {
        ...this.reccurrenceOptions,
        ...this.dtstartOptions,
        byminute: minutes,
        byhour: hours,
        bysecond: 0,
        tzid: this.startDate.timeZone,
      };
    },
    occurrences() {
      const strRRule = rrulestr(this.value);
      return strRRule.all((_, len) => len < this.numberOccurrences);
    },
  },
  methods: {
    setStartDate(date) {
      if (this.startDate === date) {
        return;
      }

      this.startDate = date;
      this.emitUpdate();
    },
    setRecurrenceOptions(recurrenceOptions) {
      if (isEqual(this.reccurrenceOptions, recurrenceOptions)) {
        return;
      }

      this.reccurrenceOptions = recurrenceOptions;
      this.emitUpdate();
    },
    emitUpdate() {
      const rrule = new RRule(this.options);
      this.$emit('input', frontendRRuleToBackend(rrule));
      this.$emit('update:value', frontendRRuleToBackend(rrule));
    },
    minutesFromOrigOptions(origOptions) {
      if (typeof origOptions.byminute === 'undefined') {
        return 0;
      }
      return origOptions.byminute;
    },
    hoursFromOrigOptions(origOptions) {
      if (typeof origOptions.byhour === 'undefined') {
        return 0;
      }
      return origOptions.byhour;
    },
    init(options, origOptions) {
      let { byweekday } = options;
      if (typeof origOptions.byweekday === 'undefined') {
        byweekday = [];
      }

      const d = DateTime.fromJSDate(options.dtstart);
      if (d.isValid) {
        this.startDate = {
          startDate: d.toISODate(),
          startTime: generateTime(options.byhour[0], options.byminute[0]),
          timeZone: options.tzid,
        };
      }

      this.reccurrenceOptions = {
        freq: options.freq,
        bysetpos: options.bysetpos,
        byweekday,
        count: options.count,
        interval: options.interval,
        until: options.until,
      };
    },
  },
  watch: {
    value(val) {
      if (val === this.value) {
        return;
      }
      const rrule = rrulestr(val);
      this.init(rrule.options, rrule.origOptions);
    },
  },
  created() {
    if (this.value !== '') {
      try {
        const rrule = rrulestr(this.value);
        this.init(rrule.options, rrule.origOptions);
      } catch (e) {
        // do nothing
      }
    }
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .r-rule-picker {
    ._recurrence-selector {
      max-width: 100%;
    }

    ._hint {
      height: 2rem;
      color: $error-color;
    }

    ._row {
      display: flex;
      flex-wrap: wrap;

      ._item {
        margin-bottom: .6rem;

        &:not(:last-of-type) {
          margin-right: .8rem;
        }

        &.-start-date {
          flex: 0 0 14rem;
        }

        &.-start-time {
          flex: 0 0 8rem;
        }

        &.-recurrence {
          flex: 1 1 auto;
        }
      }
    }
  }
</style>
