<template>
  <m-card
    class="atlas-legend"
    padding-xs
  >
    <expandable-content :title="$t('networkLegend.legend')">
      <div
        class="_content"
      >
        <div
          v-for="group in groups"
          :key="group.name"
          class="_item"
        >
          <div class="_icon">
            <div
              class="_circle"
              :style="{ backgroundColor: group.color }"
            />
          </div>
          <div class="_description">
            {{ group.name }}
          </div>
        </div>
        <div
          class="_item"
        >
          <div class="_connection -belongs-to" />
          <div class="_description">
            {{ $t('networkLegend.belongsTo') }}
          </div>
        </div>
      </div>
    </expandable-content>
  </m-card>
</template>

<script>
import ExpandableContent from '@/components/ExpandableContent.vue';
import useGoalProperty from '@/composables/property/goal-property';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useStatusProperty from '@/composables/goal/status-property';
import { getStatusColor } from '@/lib/goal/status';
import { groupByStatus } from '@/lib/goal/visify-goals';
import { textByLang } from 'shared/lib/language';

export default {
  name: 'AtlasLegend',
  props: {
    grouping: {
      type: String,
      default: '',
    },
  },
  components: { ExpandableContent },
  setup() {
    const { userLang } = useLoggedInUser();
    const { statusProperty: statusProp } = useGoalProperty();
    const { options } = useStatusProperty(statusProp, userLang);
    return { options, userLang };
  },
  data() {
    return { expanded: true };
  },
  computed: {
    groups() {
      if (this.grouping === groupByStatus) {
        return this.status;
      }
      return [];
    },
    status() {
      return this.options.map((o) => ({
        name: textByLang(o.label, this.userLang),
        color: getStatusColor(o),
      }));
    },
  },
};

</script>

<style scoped lang="scss" type="text/scss">
  .atlas-legend {
    position: absolute;
    bottom: 2rem;
    left: 2rem;
    width: 20rem;
    font-size: $font-size-3;

    ._content {
      ._item {
        display: flex;
        align-items: center;

        ._icon {
          margin-right: .6rem;
          margin-left: .2rem;

          ._circle {
            width: 1.2rem;
            height: 1.2rem;
            border-radius: 50%;
          }
        }

        ._connection {
          width: 2rem;
          height: .5rem;
          margin-right: .8rem;
          margin-left: .4rem;

          &.-belongs-to {
            border-bottom: .1rem solid map_get($grey, 'base');
          }

          &.-aligned-with {
            border-bottom: .3rem solid map_get($grey, 'base');
          }

          &.-depends-on {
            border-bottom: .3rem dashed map_get($grey, 'base');
          }
        }
      }
    }
  }
</style>
