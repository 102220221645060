<template>
  <mobile-menu-wrapper
    :editor="editor"
  >
    <template
      #default="{ commands, top }"
    >
      <div>
        <m-card
          v-if="(noSelection && focused) || showPlus"
          no-padding
          :style="cardStyle(top)"
        >
          <m-btn-group class="_btn-group">
            <m-dropdown
              v-model:value="showPlus"
              :title="$t('mobileMenuComponent.add')"
            >
              <m-btn
                icon="plus"
                hide-border
                large
                :style="{ borderRight: `1px solid ${$colors.grey.lighten3}` }"
                @mousedown="showPlusMenu"
              >
                <m-icon
                  :color="$colors.grey.lighten1"
                  type="down"
                  size="11"
                />
              </m-btn>
              <template #overlay>
                <m-card
                  no-padding
                  list
                >
                  <component-list-item
                    v-for="(item, index) in items"
                    :key="index"
                    class="_item"
                    :item="item"
                    @click="handleAddClick(item)"
                  />
                </m-card>
              </template>
            </m-dropdown>
            <m-dropdown
              v-model:value="showTurnInto"
              :title="$t('mobileMenuComponent.turnInto')"
            >
              <m-btn
                hide-border
                large
                :style="{ borderRight: `1px solid ${$colors.grey.lighten3}` }"
                @mousedown="showTurnIntoMenu"
              >
                {{ $t('mobileMenuComponent.turnInto') }}
              </m-btn>
              <template #overlay>
                <m-card
                  no-padding
                  list
                >
                  <turn-into-list
                    :allowed-content="allowedContent"
                    :commands="commands"
                    :is-active="isActive"
                    @close="showTurnInto = false"
                  />
                </m-card>
              </template>
            </m-dropdown>
            <m-btn
              large
              hide-border
              icon="undo"
              :style="{ borderRight: `1px solid ${$colors.grey.lighten3}` }"
              @mousedown="undo(commands)"
            />
            <m-btn
              large
              hide-border
              icon="redo"
              :style="{ borderRight: `1px solid ${$colors.grey.lighten3}` }"
              @mousedown="redo(commands)"
            />
            <m-btn
              large
              hide-border
              icon="keyboard-down"
              icon-size="24"
              class="_last-btn"
              @mousedown="$emit('blur')"
            />
          </m-btn-group>
        </m-card>
      </div>
    </template>
  </mobile-menu-wrapper>
</template>

<script>
import ComponentList from '@/components/editor/ComponentList';
import ComponentListItem from '@/components/editor/ComponentListItem.vue';
import MobileMenuWrapper from '@/components/editor/mobile-menu-wrapper';
import TurnIntoList from '@/components/editor/TurnIntoList.vue';

export default {
  name: 'MobileMenuComponent',
  props: {
    editor: {
      type: Object,
      required: true,
    },
    focused: {
      type: Boolean,
      default: false,
    },
    allowedContent: {
      type: Array,
      default: () => [],
    },
    customItems: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['blur'],
  components: { TurnIntoList, ComponentListItem, MobileMenuWrapper },
  data() {
    return {
      showPlus: false,
      showTurnInto: false,
    };
  },
  computed: {
    noSelection() {
      return this.editor.selection.from === this.editor.selection.to;
    },
    view() {
      return this.editor.view;
    },
    schema() {
      return this.editor.schema;
    },
  },
  methods: {
    undo(commands) {
      commands.undo();
      setTimeout(() => {
        this.editor.focus();
      }, 20);
    },
    redo(commands) {
      commands.redo();
      setTimeout(() => {
        this.editor.focus();
      }, 20);
    },
    focus() {
      this.$nextTick(() => {
        this.editor.focus();
      });
    },
    handleAddClick(item) {
      this.addBlock(item, this.editor.selection.from, this.editor.selection.to);
      this.showPlus = false;
    },
    cardStyle(top) {
      if (top === null) {
        return { display: 'none' };
      }

      return {
        position: 'absolute',
        top: `${top - 60}px`,
        left: 0,
        width: '100%',
        overflow: 'auto',
        zIndex: 999,
      };
    },
    showPlusMenu() {
      this.showPlus = true;
    },
    showTurnIntoMenu() {
      this.showTurnInto = true;
    },
  },
  watch: {
    showTurnInto(newVal) {
      if (!newVal) {
        this.focus();
      }
    },
    showPlus(newVal) {
      if (!newVal) {
        this.focus();
      }
    },
  },
  mixins: [ComponentList],
};
</script>

<style scoped lang="scss" type="text/scss">
  ._btn-group {
    display: flex;

    ._last-btn {
      margin-left: auto;
    }
  }
</style>
