<template>
  <div class="invite-users">
    <div class="_item">
      <div class="_label">
        {{ $t('inviteUsers.inviteUsersLabel') }}
      </div>
      <m-textarea
        v-model:value="emails"
        :rows="5"
        :placeholder="$t('inviteUsers.inviteUsersPlaceholder')"
      />
    </div>
    <div
      v-if="recognizedEmails.length > 0"
      class="_item"
    >
      <div class="_label">
        {{ $t('inviteUsers.recognizedEmailsLabel') }}
      </div>
      <div class="_email-list">
        <div
          v-for="(email, index) in recognizedEmails"
          :key="index"
          class="_email"
        >
          <m-tag :title="email" />
        </div>
      </div>
    </div>
    <div
      class="_actions"
      hide-border
    >
      <div class="_btns">
        <m-btn
          class="_btn"
          @click="$emit('close')"
        >
          {{ $t('general.cancel') }}
        </m-btn>
        <m-btn
          color="primary"
          class="_btn"
          :loading="createAndInviteUsersLoading"
          @click="sendInvites"
        >
          {{ $t('inviteUsers.sendInvite') }}
        </m-btn>
      </div>
    </div>
  </div>
</template>

<script>
import useAccountSettings from '@/composables/logged-in-user-account/account-settings';
import useInvitations from '@/composables/user/invitations/invitations';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useOnboardingMarker from '@/composables/onboarding/onboarding-marker';
import useUsers from '@/composables/user/users';
import { emailRegex } from 'shared/lib/email-regex';
import { onboardingGuideSteps } from 'shared/constants.json';

export default {
  name: 'InviteUsers',
  data() {
    return {
      emails: '',
      createAndInviteUsersLoading: false,
    };
  },
  setup() {
    const { loggedInUserAccount } = useLoggedInUserAccount();
    const { loggedInUser } = useLoggedInUser();
    const accountSettingsSvc = useAccountSettings();
    const { markAsDone } = useOnboardingMarker(loggedInUserAccount, accountSettingsSvc);
    const { createUsers } = useUsers();
    const { inviteUsers } = useInvitations();
    return {
      loggedInUser,
      markAsDone,
      createUsers,
      inviteUsers,
    };
  },
  emits: ['close'],
  computed: {
    recognizedEmails() {
      const s = this.emails.split(/,| |;|\n/);
      const r = RegExp(emailRegex());
      return s.filter((e) => r.test(e));
    },
  },
  methods: {
    sendInvites() {
      this.createAndInviteUsersLoading = true;
      const toCreate = this.recognizedEmails.map((e) => ({
        email: e,
        language: this.loggedInUser.language,
      }));
      this.createUsers(toCreate).then((createdUsers) => {
        this.inviteUsers(createdUsers).then(() => {
          this.markAsDone([onboardingGuideSteps.inviteOthers]).then(() => {
            this.$showSnackbar({ color: 'success', message: this.$t('inviteUsers.success') });
            this.$emit('close');
          }).finally(() => {
            this.createAndInviteUsersLoading = false;
          });
        }).finally(() => {
          this.createAndInviteUsersLoading = false;
        });
      }).catch((e) => {
        if (e.message.includes('duplicateemail')) {
          const emails = this.parseDuplicateEmailError(e.message);
          this.$showSnackbar({ color: 'error', message: this.$t('inviteUsers.oneEmailAlreadyExists'), description: `- ${emails.join(', ')}`, duration: 30 });
          return;
        }
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      }).finally(() => {
        this.createAndInviteUsersLoading = false;
      });
    },
    parseDuplicateEmailError(message) {
      const emails = [];
      const lines = message.split('\n');
      lines.forEach((line) => {
        const s1 = 'user with email ';
        const s2 = ' already exists';
        const i1 = line.indexOf(s1);
        const i2 = line.indexOf(s2);
        if (i1 > -1 && i2 > -1) {
          emails.push(line.substring(i1 + s1.length, i2));
        }
      });
      return emails;
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .invite-users {
    ._item {
      margin-bottom: 1rem;

      ._label {
        color: $font-color-secondary;
      }
    }

    ._email-list {
      display: flex;
      flex-wrap: wrap;
      max-height: 12rem;
      margin: 0 -.2rem;
      overflow-y: auto;

      ._email {
        margin: .2rem;
      }
    }

    ._actions {
      display: flex;
      margin-top: 2rem;

      ._btns {
        display: flex;
        margin-left: auto;

        ._btn {
          margin-left: .8rem;
        }
      }
    }
  }
</style>
