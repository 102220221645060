import GoalMentionComponent from '@/components/editor/GoalMentionComponent.vue';
import Node from '@/tiptap/tiptap/Utils/Node';

export default class GoalMention extends Node {
  get name() {
    return 'goalMention';
  }

  get schema() {
    return {
      attrs: {
        id: {},
        title: {},
        icon: { default: '' },
      },
      group: 'inline',
      content: 'text*',
      inline: true,
      selectable: false,
      atom: true,
      toDOM: (node) => [
        'a',
        {
          class: 'goal-mention-component',
          'data-goal-id': node.attrs.id,
          'data-goal-title': node.attrs.title,
          'data-goal-icon': node.attrs.icon,
        },
        ['span'],
        [
          'span',
          {},
          [
            'span',
            { class: '_icon' },
          ],
          [
            'span',
            { class: '_emoji' },
            node.attrs.icon,
          ],
        ],
        [
          'span',
          { class: '_title' },
          node.attrs.title,
        ],
        ['span'],
      ],
      parseDOM: [
        {
          tag: 'a[data-goal-id]',
          getAttrs: (dom) => {
            const id = parseInt(dom.getAttribute('data-goal-id'), 10);
            const title = dom.getAttribute('data-goal-title');
            const icon = dom.getAttribute('data-goal-icon');
            return { id, title, icon };
          },
        },
      ],
    };
  }

  get view() {
    return GoalMentionComponent;
  }
}
