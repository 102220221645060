import { sortAlphaNumeric } from 'shared/lib/sort';

export const dataItemToPoint = (dataItem) => ({
  y: dataItem.value,
  raw: dataItem,
});

export const distributionItemToPoint = (distributionItem) => ({
  y: distributionItem.count,
  raw: distributionItem,
});

export const transformSingleRawSeries = (rawSeries) => ({
  ...rawSeries,
  data: rawSeries.data.map((i) => dataItemToPoint(i)),
});

export const transformMultipleRawSeries = (rawSeries) => rawSeries.map((i) => transformSingleRawSeries(i));

export const transformDistributionToSeries = (distribution) => distribution.map((i) => ({
  name: i.key,
  data: [distributionItemToPoint(i)],
}));

export const sortSeriesByName = (multiSeries) => multiSeries.sort((a, b) => sortAlphaNumeric(a.name, b.name));

export const fillEmptyValuesInMultiSeries = (multiSeries, min, max) => {
  for (let i = min; i <= max; i++) {
    const filtered = multiSeries.filter((item) => parseInt(item.name, 10) === i);
    if (filtered.length === 0) {
      multiSeries.unshift({ name: `${i}`, data: [{ y: 0, raw: {} }] });
    }
  }
};

export const getCategoriesFromSeriesNames = (multiSeries) => multiSeries.map((i) => {
  if (typeof i.name === 'undefined') {
    return '';
  }
  return i.name;
});

export const flattenSeriesByIndex = (multiSeries, index = 0) => [{
  name: '',
  data: multiSeries.map((i) => i.data[index]),
}];
