<template>
  <updates-explorer
    :show-create-view="isLoggedInUser"
    show-views-selector
    show-onboarding
  />
</template>

<script>
import UpdatesExplorer from '@/components/updates/UpdatesExplorer.vue';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useUpdateDefaultProps from '@/composables/saved-views/update-default-props';
import useViewServiceInit from '@/composables/saved-views/view-service-init';
import { AND, OR } from '@/lib/filter/scope-tree';
import { VIEWS_SERVICE } from '@/lib/constants';
import { creatorProperty } from '@/lib/updates/properties';
import { guid } from 'shared/lib/uuid';
import { provide, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { viewApplication, viewType } from 'shared/constants.json';

export default {
  name: 'ProfileUpdates',
  props: {
    user: {
      type: Object,
      required: true,
    },
    isLoggedInUser: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { loggedInUserAccount } = useLoggedInUserAccount();
    const i18n = useI18n();

    const isFeedRef = ref(false);

    const defaultPropsSvc = useUpdateDefaultProps({ isFeed: isFeedRef });
    const viewServiceInitService = useViewServiceInit();

    const initialFilter = {
      account: loggedInUserAccount.value,
      key: guid(),
      op: OR,
      children: [{
        key: guid(),
        op: AND,
        children: [
          {
            key: guid(),
            scope: {
              isEmpty: false,
              users: [props.user],
              directProperty: creatorProperty(i18n.t),
            },
          },
        ],
      }],
    };

    let viewApp = viewApplication.updateUserProfile;
    if (props.isLoggedInUser) {
      viewApp = viewApplication.updateHome;
    }

    const defaultView = {
      title: i18n.t('list.feed'),
      viewType: viewType.feed,
      viewApplication: viewApp,
      params: {
        filter: initialFilter,
        order: [{ attr: 'createdAt', desc: true }],
        props: [],
      },
    };

    let routeAwareConfig = viewServiceInitService.defaultConfig();
    routeAwareConfig = viewServiceInitService.withDefaultView(routeAwareConfig, defaultView);
    routeAwareConfig = viewServiceInitService.withViewApplication(routeAwareConfig, viewApp);
    routeAwareConfig = viewServiceInitService.withDefaultProps(routeAwareConfig, defaultPropsSvc.defaultProps);
    routeAwareConfig = viewServiceInitService.withUser(routeAwareConfig, props.user);

    const viewSvc = viewServiceInitService.routeAwareViewService(routeAwareConfig);
    provide(VIEWS_SERVICE, viewSvc);

    watch(viewSvc.currentView, (currentView) => {
      if (currentView.viewType === viewType.feed) {
        isFeedRef.value = true;
        return;
      }
      isFeedRef.value = false;
    }, { immediate: true });

    return { viewApp };
  },
  components: { UpdatesExplorer },
};
</script>

<style scoped lang="scss" type="text/scss">

</style>
