<template>
  <div
    :style="style"
    class="m-badge"
  >
    {{ number }}
  </div>
</template>

<script>
export default {
  name: 'MBadge',
  props: {
    number: {
      type: String,
      required: true,
    },
    backgroundColor: {
      type: String,
      default: '',
    },
  },
  computed: {
    style() {
      return { backgroundColor: this.backgroundColor === '' ? this.$colors.grey.base : this.backgroundColor };
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .m-badge {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 1.8rem;
    height: 1.8rem;
    padding: .4rem;
    font-size: $font-size-0;
    font-weight: $font-weight-semibold;
    line-height: normal;
    color: white;
    border-radius: $default-border-radius;
  }
</style>
