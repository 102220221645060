<template>
  <div :class="['m-slots-container']">
    <div
      v-for="slot in slotsSplitByMenu.before"
      :key="slot"
    >
      <slot :name="slot" />
    </div>
    <m-dropdown
      v-if="slotsSplitByMenu.menu.length > 0"
      class="_actions"
      :value="localShowMenu"
      :title="$t('general.actions')"
      @update:value="updateShowMenu"
    >
      <m-btn
        icon="ellipsis"
        hide-border
        small
        fab
        light
      />
      <template #overlay>
        <m-card
          class="_overlay"
          no-padding
          list
        >
          <div
            v-for="slot in slotsSplitByMenu.menu"
            :key="slot"
          >
            <slot :name="slot" />
          </div>
        </m-card>
      </template>
    </m-dropdown>
    <div
      v-for="slot in slotsSplitByMenu.after"
      :key="slot"
    >
      <slot :name="slot" />
    </div>
  </div>
</template>

<script>

export default {
  name: 'MSlotsContainer',
  props: {
    slots: {
      type: Array,
      default: () => [],
    },
    showMenu: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return { localShowMenu: false };
  },
  emits: [
    'update:showMenu',
  ],
  computed: {
    slotsSplitByMenu() {
      let menuUp = false;
      let menuDown = false;
      return this.slots.reduce((res, slot) => {
        if (slot.inMenu === true) {
          if (menuDown === true) {
            throw Error(`<slot-container> is hiding slot: ${slot.name}`);
          }
          menuUp = true;
          res.menu.push(slot.name);
          return res;
        }
        if (menuUp === true) {
          menuDown = true;
          res.after.push(slot.name);
          return res;
        }
        res.before.push(slot.name);
        return res;
      }, { before: [], menu: [], after: [] });
    },
  },
  methods: {
    updateShowMenu(event) {
      this.$emit('update:showMenu', event);
    },
  },
  watch: {
    showMenu(newVal) {
      this.localShowMenu = newVal;
    },
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  .m-slots-container {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
</style>
