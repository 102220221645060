import { DateTime } from 'luxon';
import { timeZones } from 'shared/lib/timezones';

const Nanosecond = 1;
const Microsecond = 1000 * Nanosecond;
const Millisecond = 1000 * Microsecond;
const Second = 1000 * Millisecond;
export const Minute = 60 * Second;
export const Hour = 60 * Minute;

export const defaultTimezone = () => {
  const zone = DateTime.local().zoneName;
  const timeZoneValues = timeZones.map((t) => t.value);

  if (timeZoneValues.includes(zone)) {
    return zone;
  }

  return timeZoneValues[0];
};

export const generateTime = (hour, minute) => {
  let h = `${hour}`;
  let m = `${minute}`;
  if (h.length === 1) {
    h = `0${h}`;
  }
  if (m.length === 1) {
    m = `0${m}`;
  }

  return `${h}:${m}`;
};

export const toISO = (value) => {
  if (value === null) {
    return null;
  }

  if (typeof value.startDate === 'undefined' || value.startDate === null) {
    return null;
  }

  let hour = 0;
  let minute = 0;
  if (typeof value.startTime !== 'undefined' && value.startTime !== null) {
    const s = value.startTime.split(':');
    if (s.length >= 2) {
      hour = parseInt(value.startTime.split(':')[0], 10);
      minute = parseInt(value.startTime.split(':')[1], 10);
    }
  }

  const d = DateTime.fromISO(value.startDate);
  if (d.invalid !== null) {
    return null;
  }

  if (typeof value.timeZone === 'undefined' || value.timeZone === null) {
    return DateTime.local(d.year, d.month, d.day, hour, minute).toISO();
  }

  const r = DateTime.local(d.year, d.month, d.day, hour, minute);
  const zoned = r.setZone(value.timeZone, { keepLocalTime: true });
  return zoned.toISO();
};

export const fromISO = (value) => {
  if (value === null && typeof value === 'undefined') {
    return null;
  }
  const d = DateTime.fromISO(value, { setZone: true });
  if (d.invalid !== null) {
    return null;
  }
  return {
    startDate: d.toISODate(),
    startTime: d.toLocaleString(DateTime.TIME_24_SIMPLE),
    timeZone: d.zoneName,
  };
};
