<template>
  <div
    :class="['m-layout', scrollable ? '-scrollable' : '']"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'MLayout',
  props: {
    scrollable: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .m-layout {
    display: flex;
    width: 100vw;
    height: 100vh;
    background-color: white;
  }
</style>
