<template>
  <m-tooltip
    :disabled="!clickable && !draggable"
  >
    <m-btn
      :icon="icon"
      icon-size="18"
      hide-border
      small
      fab
      super-light
      :button-style="buttonStyle"
      @click="click"
      @mousedown="drag"
    />
    <template #title>
      <div v-if="draggable">
        <span style="color: white;">{{
          $t('actions.drag')
        }}</span><span :style="{ color: tooltipGrey }">{{ $t('actions.toRealign') }}</span>
      </div>
      <div v-if="clickable">
        <span style="color: white;">{{
          $t('actions.click')
        }}</span><span :style="{ color: tooltipGrey }">{{ $t('actions.toSeeActions') }}</span>
      </div>
    </template>
  </m-tooltip>
</template>

<script setup>

import MBtn from 'shared/components/base/MBtn.vue';
import MTooltip from 'shared/components/base/MTooltip.vue';
import colors from 'shared/colors';
import { computed, useAttrs } from 'vue';
import { rgbaToHex } from 'shared/lib/color';

const props = defineProps({
  draggable: {
    type: Boolean,
    default: false,
  },
  clickable: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['drag', 'click']);

const tooltipGrey = rgbaToHex(colors.grey.lighten2);

const icon = computed(() => {
  if (props.draggable) {
    return 'drag';
  }
  if (props.clickable) {
    return 'ellipsis';
  }
  return '';
});
const attrs = useAttrs();
const buttonStyle = computed(() => {
  if (attrs.style) {
    return attrs.style;
  }
  const style = { width: '2rem' };
  if (props.draggable) {
    return { ...style, cursor: 'grab' };
  }
  if (props.clickable) {
    return { ...style, cursor: 'pointer' };
  }
  return style;
});

const click = (event) => {
  if (!props.clickable) {
    return;
  }
  emit('click', event);
};
const drag = (event) => {
  if (!props.draggable) {
    return;
  }
  emit('drag', event);
};
</script>

<style scoped lang="scss">

</style>
