<template>
  <div
    class="ms-calendar-date"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <div class="_integration-date-label">
      <div class="_date">
        {{ DateTime.fromISO(meeting.date).toLocaleString(DateTime.DATETIME_MED) }}
      </div>
      <m-tooltip v-if="eventError !== ''">
        <div class="_icon">
          <m-icon type="warning" />
        </div>
        <template #title>
          <div>
            {{ $t(`msCalendarDate.error.${camelCase(eventError)}`) }}
          </div>
        </template>
      </m-tooltip>
      <div class="_icon">
        <m-dropdown
          :title="$t('general.actions')"
        >
          <m-btn
            icon="outlook"
            fab
            xs
            hide-border
          />
          <template #overlay>
            <m-card
              list
              no-padding
              class="_overlay"
            >
              <m-card-item>
                <m-link
                  :href="meeting.msCalendarEvent.weblink"
                  target="_blank"
                  inherit-color
                >
                  {{ $t('meetingPropsEditor.calendarIntegration.open') }}
                </m-link>
              </m-card-item>
              <m-card-item
                v-if="canDisconnect"
                :color="$colors.red.base"
                @click="disconnectCalendarIntegration"
              >
                {{ $t('meetingPropsEditor.calendarIntegration.disconnect') }}
              </m-card-item>
            </m-card>
          </template>
        </m-dropdown>
      </div>
    </div>
    <div
      v-if="hover || dateActions"
      class="actions"
    >
      <m-dropdown
        v-model:value="dateActions"
        :trigger="[]"
        :title="$t('general.actions')"
        placement="bottomCenter"
      >
        <m-btn
          hide-border
          class="_action"
          small
          icon="ellipsis"
          fab
        />
        <template #overlay>
          <m-card
            list
            no-padding
            class="_overlay"
          >
            <m-card-item>
              <m-link
                :href="meeting.msCalendarEvent.weblink"
                target="_blank"
                inherit-color
              >
                {{ $t('meetingPropsEditor.calendarIntegration.open') }}
              </m-link>
            </m-card-item>
            <m-card-item
              :color="$colors.red.base"
              @click="disconnectCalendarIntegration"
            >
              {{ $t('meetingPropsEditor.calendarIntegration.disconnect') }}
            </m-card-item>
          </m-card>
        </template>
      </m-dropdown>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import { camelCase } from 'lodash-es';
import { mapActions } from 'vuex';
import { msCalendarEvent as msCalendarEventConfig } from 'shared/api/query/configs.json';

export default {
  name: 'MsCalendarDate',
  props: {
    meeting: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dateActions: false,
      hover: false,
      DateTime,
      camelCase,
    };
  },
  computed: {
    canDisconnect() {
      // TODO: This is currently only working after reload as long as meetings is not in nebula
      return this.meeting.msCalendarEvent.dataSource !== null;
    },
    eventError() {
      return this.meeting.msCalendarEvent.error;
    },
  },
  methods: {
    ...mapActions(['deleteEntityV2']),
    disconnectCalendarIntegration() {
      const deleteMethod = () => {
        this.deleteEntityV2({
          entity: { uid: this.meeting.msCalendarEvent.uid },
          model: msCalendarEventConfig.model,
          mutation: 'MS_CALENDAR_EVENT_REMOVED',
          options: { event: this.meeting.msCalendarEvent },
        }).then((response) => {
          if (response.status !== 200) {
            this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
          }
        });
      };

      this.$confirm({
        title: this.$t('meetingPropsEditor.calendarIntegration.deleteConnectionPrompt'),
        okText: this.$t('general.yesDelete'),
        okType: 'danger',
        maskClosable: true,
        cancelText: this.$t('general.cancel'),
        onOk() {
          deleteMethod();
        },
      });
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .ms-calendar-date {
    display: flex;
    justify-content: space-between;
    width: 100%;

    ._integration-date-label {
      display: flex;
      align-items: center;
      width: 100%;
      height: 3.2rem;
      padding: .4rem 1.2rem;
      white-space: nowrap;

      ._date {
        padding-right: .6rem;
      }

      ._icon {
        padding-right: .4rem;
      }
    }
  }
</style>
