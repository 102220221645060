// used for route query or param constants
export const OPTION_ID = 'optionId';
export const PAGE_ID = 'pageId';
export const VIEW = 'view';
export const VIEW_ID = 'viewId';
export const GOAL_VIEW = 'goal';
export const GOAL_FEED_VIEW = 'goal-feed';
export const UPDATE_VIEW = 'checkin';
export const GOAL_INSIGHT_VIEW = 'goal-insight';
export const USER_VIEW = 'user';
export const ABOUT_VIEW = 'about';
export const CREATE_UPDATE_VIEW = 'create-checkin';

export const GOAL_INSIGHTS_PERFORMANCE_VIEW = 'performance';
export const GOAL_INSIGHTS_HEALTH_VIEW = 'health';
