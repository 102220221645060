<template>
  <div :class="['asana-task-selection-trigger', wrapText ? '-wrap-text' : null]">
    <template v-if="anyP(sections, assignedTo)">
      {{ $t('asanaTaskSelectionTrigger.allTasks') }}
    </template>
    <template v-if="assignedToP(sections, assignedTo)">
      <span class="_label">{{ $t('asanaTaskSelectionTrigger.assignedTo') }}:</span>
      <span> {{ assignedTo[0].name }}</span>
    </template>
    <template v-if="singleSectionP(sections, assignedTo)">
      <span class="_label">{{ $t('asanaSectionForm.section') }}:</span> {{ sections[0].gid !== 'global' ? sections[0].name : $t('asanaTaskSelectionTrigger.any') }}
      <span class="_label">{{ $t('asanaSectionForm.status') }}:</span> {{ $t(`asanaForm.asanaCompletionStatus.${sections[0].status}`) }}
    </template>
    <template v-if="criteriaP(sections, assignedTo)">
      {{ criteriaCount }} {{ $t('asanaTaskSelectionTrigger.criteriaSelected') }}
    </template>
  </div>
</template>

<script>
import { anyP, assignedToP, criteriaCount, criteriaP, singleSectionP } from '@/lib/asana';

export default {
  name: 'AsanaTaskSelectionTrigger',
  props: {
    sections: {
      type: Array,
      required: true,
    },
    assignedTo: {
      type: Array,
      required: true,
    },
    wrapText: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return { anyP, assignedToP, singleSectionP, criteriaP };
  },
  computed: {
    criteriaCount() {
      return criteriaCount(this.sections, this.assignedTo);
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .asana-task-selection-trigger {
    max-width: 100%;

    &.-wrap-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    ._label {
      color: $font-color-secondary;
    }
  }
</style>
