import MobileMenu from '@/components/editor/mobile-menu';
import { h } from 'vue';

export default {
  props: {
    editor: {
      default: null,
      type: Object,
    },
    keepInBounds: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    return { top: null };
  },
  watch: {
    editor: {
      immediate: true,
      handler(editor) {
        if (editor === null) {
          return;
        }

        this.$nextTick(() => {
          editor.registerPlugin(MobileMenu({
            editor,
            element: this.$el,
            keepInBounds: this.keepInBounds,
            onUpdate: ({ top }) => {
              this.top = top;
            },
          }));
        });
      },
    },
  },
  render() {
    if (!this.editor) {
      return null;
    }

    return h('div', {}, this.$slots.default({
      focused: this.editor.view.focused,
      focus: this.editor.focus,
      commands: this.editor.commands,
      isActive: this.editor.isActive,
      getMarkAttrs: this.editor.getMarkAttrs.bind(this.editor),
      getNodeAttrs: this.editor.getNodeAttrs.bind(this.editor),
      top: this.top,
    }));
  },
  beforeUnmount() {
    this.editor.unregisterPlugin('mobile_menu');
  },
};
