import usePersonalAppSettings from '@/composables/logged-in-user/personal-app-settings';
import useProperties from '@/composables/property/property';
import useRepo from '@/nebula/repo';
import { browserLanguage } from '@/lib/language';
import { computed } from 'vue';
import { loggedInUserEdges, loggedInUserQuery } from '@/api/query/nebula/logged-in-user';
import { propertyType } from 'shared/constants.json';
import { uploadFile } from '@/lib/image/image';
import { useStore } from 'vuex';
import { user as userConfig } from 'shared/api/query/configs.json';

export default function useLoggedInUser() {
  const store = useStore();
  const userRepo = useRepo(userConfig.model, loggedInUserEdges);
  const { spaceProperty } = useProperties();

  const getLoggedInUser = () => userRepo.query([loggedInUserQuery()]).then(({ user }) => {
    if (user.length === 0) {
      store.commit('USER_NOT_LOGGED_IN');
      return null;
    }

    store.commit('LOGGED_IN_USER_RETRIEVED', { userId: user[0].uid });
    return user[0];
  });

  const loggedInUser = computed(() => {
    if (store.state.loggedInUserId === 0) {
      return null;
    }

    return userRepo.selectSingle(store.state.loggedInUserId);
  });

  const userLang = computed(() => {
    if (loggedInUser.value === null || loggedInUser.value === undefined || loggedInUser.value.language === null || loggedInUser.value.language === undefined || loggedInUser.value.language === '') {
      return browserLanguage();
    }
    return loggedInUser.value.language;
  });

  const personalAppSettingsSvc = usePersonalAppSettings(loggedInUser);

  const myTeams = computed(() => {
    if (loggedInUser.value === null || loggedInUser.value === undefined) {
      return [];
    }
    return loggedInUser.value.values.filter((propertyValue) => propertyValue.property.type === propertyType.space)
      .reduce((res, cur) => [...res, ...cur.spaces], []);
  });

  const updateUser = (user, personalAppSettings = null, options = undefined) => userRepo.updateSingle(user, options).then((user) => {
    if (personalAppSettings !== null) {
      return personalAppSettingsSvc.updateSingle(personalAppSettings).then((personalAppSettings) => ({
        ...user,
        personalAppSettings,
      }));
    }
    return user;
  });

  const uploadProfileImage = (blob) => uploadFile(blob, () => {}).then((data) => {
    const profileImage = () => {
      if (loggedInUser.value.profileImage === null) {
        return { blob: data.fileId, getURL: data.getURL };
      }
      return {
        uid: loggedInUser.value.profileImage.uid,
        blob: data.fileId,
        getURL: data.getURL,
      };
    };
    return userRepo.updateSingle({
      uid: loggedInUser.value.uid,
      profileImage: profileImage(),
    }, { optimistic: false });
  });

  const deleteProfileImage = () => {
    if (loggedInUser.value.profileImage === null) {
      return new Promise((resolve) => {
        resolve();
      });
    }
    return userRepo.updateSingle({
      uid: loggedInUser.value.uid,
      profileImage: null,
    }, { optimistic: false });
  };

  const peers = computed(() => userRepo.selectMultiple(myTeams.value.reduce((res, next) => {
    const users = userRepo.entityList.value.filter((u) => {
      const teamPV = u.values.find((v) => v.property.uid === spaceProperty.value.uid);
      return teamPV.spaces.map((o) => o.uid).includes(next.uid) && u.uid !== loggedInUser.value.uid && !res.includes(u.uid);
    });
    res.push(...users.map((u) => u.uid));
    return res;
  }, [])));

  return {
    getLoggedInUser,
    uploadProfileImage,
    loggedInUser,
    userLang,
    myTeams,
    myTeamsIds: myTeams.value.map((o) => o.uid),
    deleteProfileImage,
    peers,
    updateUser,
    updateUserLoading: userRepo.updateLoading,
  };
}
