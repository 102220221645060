<template>
  <m-dialog
    :value="showModal"
    no-padding
    hide-footer
    max-width="90rem"
    center
    @input="val => showModal = val"
  >
    <div style="position: relative; height: 0; padding-bottom: 56.25%;">
      <iframe
        :src="link"
        frameborder="0"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"
      />
    </div>
  </m-dialog>
</template>

<script>
import { EventBus } from '@/lib/event-bus';

export default {
  name: 'LoomVideoDialog',
  data() {
    return { showModal: false, loomLink: '', autoplay: false };
  },
  computed: {
    link() {
      if (this.autoplay) {
        return `${this.loomLink}?autoplay=1`;
      }

      return this.loomLink;
    },
  },
  methods: {
    hide() {
      this.showModal = false;
    },
    show(link, options) {
      this.showModal = true;
      this.loomLink = link;
      this.autoplay = options.autoplay;
    },
  },
  created() {
    EventBus.$on('show-video', this.show);
  },
  beforeUnmount() {
    EventBus.$off('show-video', this.show);
  },
};
</script>

<style scoped lang="scss" type="text/scss">

</style>
