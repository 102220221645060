<template>
  <div class="saved-view-title">
    <m-icon
      v-if="isEmptyIcon(viewIcon)"
      :type="typeIcon"
      class="_emoji"
    />
    <item-title
      :title="view.title"
      :icons="[{ value: viewIcon }]"
      class="_title"
    />
    <m-tooltip
      v-if="!view.isPublic && showVisibilityHint"
      class="_privacy"
      placement="top"
    >
      <div>
        <m-icon
          type="lock"
          :color="$colors.grey.base"
        />
      </div>
      <template #title>
        {{ $t('savedViewInfo.privateTooltip') }}
      </template>
    </m-tooltip>
  </div>
</template>

<script setup>
import ItemTitle from '@/components/ItemTitle.vue';
import { buildIcon, isEmptyIcon } from 'shared/lib/icon';
import { computed, toRef } from 'vue';
import { iconByType } from '@/lib/saved-view/saved-view';

const props = defineProps({
  view: {
    type: Object,
    required: true,
  },
  showVisibilityHint: {
    type: Boolean,
    default: false,
  },
});

const typeIcon = computed(() => iconByType(toRef(props, 'view').value.viewType));
const viewIcon = computed(() => buildIcon(toRef(props, 'view').value.emoji));
</script>

<style scoped lang="scss">
.saved-view-title {
  display: flex;
  align-items: center;

  ._emoji {
    margin-right: 0.6rem;
    width: 1.6rem;
  }

  ._title {
    max-width: 18.2rem;
    font-weight: $font-weight-medium;
  }

  ._privacy {
    display: flex;
    align-items: center;
    margin-left: .6rem;
  }
}
</style>
