import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useRepo from '@/nebula/repo';
import useSalesforceDatasourceRepo from '@/composables/integrations/salesforce/salesforce-datasource-repo';
import { customDatasourceProperty } from 'shared/constants.json';
import { salesforceQuery as salesforceQueryConfig } from 'shared/api/query/configs.json';

export default function useSalesforce() {
  const queryRepo = useRepo(salesforceQueryConfig.model);
  const datasourceRepo = useSalesforceDatasourceRepo();

  const { loggedInUser } = useLoggedInUser();

  const createQuery = (entity) => queryRepo.createSingle({
    ...entity,
    creator: { uid: loggedInUser.value.uid },
  });

  const getReportDetails = (datasource, report) => datasourceRepo.getSingle({
    ...datasource,
    [customDatasourceProperty.salesforceReports]: [report],
  });

  return {
    createQueryLoading: queryRepo.createLoading,
    createQuery,
    updateQueryLoading: queryRepo.updateLoading,
    updateQuery: queryRepo.updateSingle,
    deleteQueryLoading: queryRepo.deleteLoading,
    deleteQuery: queryRepo.deleteSingle,
    getReportDetailsLoading: datasourceRepo.getSingleLoading,
    getReportDetails,
  };
}
