export const sliceByPage = (items, page, itemsPerPage) => {
  const lower = (page - 1) * itemsPerPage;
  const upper = lower + itemsPerPage;
  return items.slice(lower, upper);
};

export const getCurrentBounds = (totalAmount, page, itemsPerPage) => {
  let lower = (page - 1) * itemsPerPage + 1;
  let upper = lower + itemsPerPage - 1;
  if (totalAmount === 0) {
    lower = 0;
    upper = 0;
  }

  if (upper > totalAmount) {
    upper = totalAmount;
  }
  return { lower, upper };
};
