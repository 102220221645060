<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0)">
      <path
        d="M21.0714 2.57143H3.92857C3.45519 2.57143 3.07143 2.95519 3.07143 3.42857V20.5714C3.07143 21.0447 3.45519 21.4286 3.92857 21.4286H21.0714C21.5447 21.4286 21.9286 21.0447 21.9286 20.5714V3.42857C21.9286 2.95519 21.5447 2.57143 21.0714 2.57143ZM3.92857 0H21.0714C22.965 0 24.5 1.53502 24.5 3.42857V20.5714C24.5 22.465 22.965 24 21.0714 24H3.92857C2.03502 24 0.5 22.465 0.5 20.5714V3.42857C0.5 1.53502 2.03502 0 3.92857 0Z"
        fill="currentColor"
      />
      <path
        d="M19.3564 10.7143H9.06641V13.2857H19.3564V10.7143Z"
        fill="currentColor"
      />
      <path
        d="M19.3571 15H12.5V17.5714H19.3571V15Z"
        fill="currentColor"
      />
      <path
        d="M19.3569 6.42856H5.64258V8.99999H19.3569V6.42856Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          x="0.5"
          width="24"
          height="24"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default { name: 'Cascade' };
</script>

<style scoped lang="scss" type="text/scss">

</style>
