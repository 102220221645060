<template>
  <page-layout
    :class="['update-feed-explorer', $store.state.breakpoint.smAndDown ? '-mobile' : '']"
  >
    <template #topBar>
      <page-top-bar
        :breadcrumbs="breadcrumbs"
        class="_header"
      >
        <template #actions>
          <div
            class="_actions"
          >
            <template
              v-if="!$store.state.breakpoint.smAndDown"
            >
              <favorite-button
                :title-suggestion="titleSuggestion"
                :icon-suggestion="iconSuggestion"
              />
            </template>
            <m-dropdown
              v-else
              :title="$t('general.actions')"
              class="actions-menu"
            >
              <m-btn
                icon="ellipsis"
                hide-border
                fab
              />
              <template #overlay>
                <m-card
                  no-padding
                  list
                >
                  <favorite-button
                    :title-suggestion="titleSuggestion"
                    :icon-suggestion="iconSuggestion"
                  />
                </m-card>
              </template>
            </m-dropdown>
          </div>
        </template>
      </page-top-bar>
    </template>
    <scroll-container>
      <m-content
        padding-x="layout"
        class="_header"
      >
        <editable-page-header
          :key="title"
          :title="title"
          disabled
          size="small"
        />
      </m-content>
      <m-content padding-x="layout">
        <update-feed
          show-views-selector
          show-create-view
        />
      </m-content>
    </scroll-container>
  </page-layout>
</template>

<script setup>
import EditablePageHeader from '@/components/page/EditablePageHeader.vue';
import FavoriteButton from '@/components/favorite/FavoriteButton.vue';
import PageLayout from '@/components/page/PageLayout.vue';
import PageTopBar from '@/components/page/PageTopBar.vue';
import ScrollContainer from '@/components/page/ScrollContainer.vue';
import UpdateFeed from '@/components/update-feed/UpdateFeed.vue';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useUpdateFeedDefaultProps from '@/composables/saved-views/update-feed-defaut-props';
import useViewServiceInit from '@/composables/saved-views/view-service-init';
import { AND } from '@/lib/filter/scope-tree';
import { CREATED_AT } from 'shared/api/query/constants';
import { VIEWS_SERVICE } from '@/lib/constants';
import { buildIcon } from 'shared/lib/icon';
import { computed, provide } from 'vue';
import { useI18n } from 'vue-i18n';
import { viewApplication, viewType } from 'shared/constants.json';

const viewApp = viewApplication.updateFeed;

const i18n = useI18n();

const { loggedInUserAccount } = useLoggedInUserAccount();

const defaultPropsSvc = useUpdateFeedDefaultProps();
const viewServiceInit = useViewServiceInit();

const breadcrumbs = computed(() => [
  {
    title: loggedInUserAccount.value.goalSettings.featureNamePlural,
    disabled: true,
    icons: [{ value: buildIcon('CompassOutlined_dark_grey') }],
  },
  {
    title: i18n.t('goalsExplorer.feed'),
    disabled: true,
  },
]);

const title = computed(() => breadcrumbs.value[breadcrumbs.value.length - 1].title);

const defaultView = {
  title: i18n.t('list.feed'),
  viewType: viewType.feed,
  viewApplication: viewApp,
  params: {
    filter: { account: { uid: loggedInUserAccount.value.uid }, children: [], op: AND },
    order: [{ attr: CREATED_AT, desc: true }],
    props: [],
    wrapTitles: false,
  },
};

let viewConfig = viewServiceInit.defaultConfig();
viewConfig = viewServiceInit.withDefaultProps(viewConfig, defaultPropsSvc.defaultProps);
viewConfig = viewServiceInit.withDefaultView(viewConfig, defaultView);
viewConfig = viewServiceInit.withViewApplication(viewConfig, viewApp);

const viewSvc = viewServiceInit.routeAwareViewService(viewConfig);
provide(VIEWS_SERVICE, viewSvc);

const titleSuggestion = computed(() => viewSvc.currentView.value.title);
const iconSuggestion = computed(() => buildIcon(viewSvc.currentView.value.emoji));

</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  .update-feed-explorer {
    ._actions {
      display: flex;
    }

    ._header {
      position: sticky;
      left: 0;
    }
  }
</style>
