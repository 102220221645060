import sha1 from 'tiny-sha1/dist/tiny-sha1';

export function hexToRgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (result === null) {
    return {
      rgbColor: { r: 0, g: 0, b: 0 },
      err: new Error('color not parseable'),
    };
  }
  return {
    rgbColor: {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
    },
    err: null,
  };
}

export function rgbaToHex(color) {
  const values = color
    .replace(/rgba?\(/, '')
    .replace(/\)/, '')
    .replace(/[\s+]/g, '')
    .split(',');
  const a = parseFloat(values[3] || 1);
  const r = Math.floor(a * parseInt(values[0], 10) + (1 - a) * 255);
  const g = Math.floor(a * parseInt(values[1], 10) + (1 - a) * 255);
  const b = Math.floor(a * parseInt(values[2], 10) + (1 - a) * 255);

  return `#${
    (`0${r.toString(16)}`).slice(-2)
  }${(`0${g.toString(16)}`).slice(-2)
  }${(`0${b.toString(16)}`).slice(-2)}`;
}

export const automaticColor = (s, colors) => {
  const enc = new TextEncoder();
  const numberOfDigits = 4;
  const hash = parseInt(sha1(enc.encode(s.trim())).slice(-numberOfDigits), 16);
  const max = 65536; // 16 to the power of 4
  const step = max / colors.length;

  const index = Math.floor(hash / step);

  return colors[index];
};
