export const breakpoints = {
  xs: 600,
  sm: 960,
  md: 1264,
  lg: 1904,
};

export const setBreakpoints = () => ({
  smAndUp: document.body.clientWidth > breakpoints.xs,
  smAndDown: document.body.clientWidth < breakpoints.sm,
  mdAndUp: document.body.clientWidth > breakpoints.sm,
  xs: document.body.clientWidth < breakpoints.xs,
  sm: document.body.clientWidth >= breakpoints.xs && document.body.clientWidth < breakpoints.sm,
  md: document.body.clientWidth >= breakpoints.sm && document.body.clientWidth < breakpoints.md,
  lg: document.body.clientWidth >= breakpoints.md && document.body.clientWidth < breakpoints.lg,
  xl: document.body.clientWidth >= breakpoints.lg,
});
