<template>
  <page-layout class="home">
    <template #topBar>
      <page-top-bar
        :breadcrumbs="breadcrumbs"
      />
    </template>
    <profile-body
      :user="loggedInUser"
    />
  </page-layout>
</template>

<script>
import PageLayout from '@/components/page/PageLayout.vue';
import PageTopBar from '@/components/page/PageTopBar.vue';
import ProfileBody from '@/components/profile/ProfileBody.vue';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import { buildIcon } from 'shared/lib/icon';

export default {
  name: 'Home',
  setup() {
    const { loggedInUser } = useLoggedInUser();
    return { loggedInUser };
  },
  components: { PageLayout, PageTopBar, ProfileBody },
  computed: {
    breadcrumbs() {
      return [
        {
          icons: [{ value: buildIcon('HomeOutlined_dark_grey') }],
          title: this.$t('myWorkspace.home'),
          disabled: true,
        },
      ];
    },
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  ._header {
    position: sticky;
    left: 0;
  }
</style>
