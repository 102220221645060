<template>
  <div class="factor-results-card">
    <full-screen-spinner v-if="showLoading" />
    <chart-table-container
      v-else
      :title-left="$t('formResults.factor')"
      :title-middle="$t('formResults.distribution')"
      :title-right="$t('formResults.score')"
    >
      <div
        v-for="(item, index) in chartList"
        :key="item.factor.uid"
      >
        <m-content padding>
          <chart-row
            class="_row"
            :chart="item.chart"
            :chart-data="item.data"
            :title="item.factor.title[lang]"
            :max-value="2"
            :starts-from-zero="true"
            :title-link="link(item.factor)"
          />
        </m-content>
        <m-divider
          v-if="index !== chartList.length - 1"
          none
        />
      </div>
    </chart-table-container>
  </div>
</template>

<script>
import ChartRow from '@/components/dashboard/ChartRow.vue';
import ChartTableContainer from '@/components/dashboard/ChartTableContainer.vue';
import FullScreenSpinner from 'shared/components/FullScreenSpinner.vue';
import useForm from '@/composables/form/form';
import { UserFilterHandler } from '@/lib/filter/user/handler';
import { chartType } from 'shared/constants.json';
import { dataAggregation } from 'shared/num_constants.json';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'FactorResultsCard',
  props: {
    factors: {
      type: Array,
      default: () => [],
    },
    filterTree: {
      type: Object,
      default: () => null,
    },
    intervalConfig: {
      type: Object,
      default: () => null,
    },
    filterByNewestAnswer: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ChartTableContainer,
    ChartRow,
    FullScreenSpinner,
  },
  setup() {
    const { formId, formAvailable, lang } = useForm();
    return { formId, formAvailable, lang };
  },
  data() {
    return { loading: false };
  },
  computed: {
    ...mapState({ answers: (state) => state.formResultData }),
    gqlFilter() {
      const handler = new UserFilterHandler(true);
      return handler.Translate(this.filterTree).filterTree;
    },
    showLoading() {
      return this.chartList.length !== this.factors.length;
    },
    chartList() {
      if (this.answers === null) {
        return [];
      }
      return this.factors.map((factor) => {
        let data = this.answers[factor.uid];
        if (typeof data === 'undefined') {
          data = null;
        }
        return {
          chart: this.chartByFactor(factor),
          data,
          factor,
        };
      }).filter((e) => e.data !== null);
    },
  },
  methods: {
    ...mapActions(['getFormResultData']),
    link(factor) {
      if (this.routeFactorId() === factor.uid) {
        return null;
      }
      return { ...this.$route, query: { factorId: factor.uid } };
    },
    routeFactorId() {
      const param = this.$route.query.factorId;
      if (typeof param === 'undefined') {
        return 0;
      }
      return parseInt(param, 10);
    },
    getResultData() {
      if (!this.formAvailable) {
        return;
      }
      this.setLoading(true);
      this.getFormResultData({
        formId: this.formId,
        charts: this.chartsByFactors(this.factors),
        userScopeTree: this.filterTree,
        intervalConfig: this.intervalConfig,
      }).then((response) => {
        if (response.status !== 200) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
          return;
        }
        this.setLoading(false);
      });
    },
    setLoading(isLoading) {
      this.loading = isLoading;
      this.$store.commit('FORM_RESULT_SET_LOADING', isLoading);
    },
    chartsByFactors(factors) {
      return factors.map((f) => this.chartByFactor(f));
    },
    chartsByForm(form) {
      if (form.formFactors.length === 0) {
        return [];
      }
      return form.formFactors.map((factor) => this.chartByFactor(factor));
    },
    chartByFactor(factor) {
      return {
        uid: factor.uid,
        factor,
        aggregation: dataAggregation.favorability,
        calculateDistribution: true,
        type: chartType.stackedBarChart,
        filterByNewestAnswer: this.filterByNewestAnswer,
      };
    },
  },
  watch: {
    factors() {
      this.getResultData();
    },
    formId() {
      this.getResultData();
    },
    gqlFilter() {
      this.getResultData();
    },
    intervalConfig() {
      this.getResultData();
    },
  },
  created() {
    this.loading = true;
    this.getResultData();
  },
};
</script>

<style scoped lang="scss" type="text/scss">

</style>
