import { UID } from 'shared/api/query/constants';
import { provisionedGroup as provisionedGroupConfig, provisionedNames as provisionedNamesConfig, provisionedResource as provisionedResourceConfig, provisionedUser as provisionedUserConfig } from 'shared/api/query/configs.json';

export const provisionedGroupChildren = [
  { attr: UID },
  { attr: provisionedGroupConfig.edges.displayName },
];

export const provisionedUserChildren = [
  { attr: UID },
  { attr: provisionedUserConfig.edges.provisioningError, default: null },
  {
    attr: provisionedUserConfig.edges.name,
    model: provisionedNamesConfig.model,
    default: { familyName: '', givenName: '' },
    children: [
      { attr: UID },
      { attr: provisionedNamesConfig.edges.familyName },
      { attr: provisionedNamesConfig.edges.givenName },
    ],
  },
  {
    attr: provisionedUserConfig.edges.emails,
    model: provisionedResourceConfig.model,
    children: [
      { attr: UID },
      { attr: provisionedResourceConfig.edges.value },
    ],
    default: [{ value: '' }],
  },
];
