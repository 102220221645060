<template>
  <svg
    viewBox="64 64 896 896"
    data-icon="exclamation"
    width="1em"
    height="1em"
    fill="currentColor"
    aria-hidden="true"
    focusable="false"
    class=""
  >
    <path
      d="M448 804a64 64 0 1 0 128 0 64 64 0 1 0-128 0zm32-168h64c4.4 0 8-3.6 8-8V164c0-4.4-3.6-8-8-8h-64c-4.4 0-8 3.6-8 8v464c0 4.4 3.6 8 8 8z"
    />
  </svg>
</template>

<script>
export default { name: 'Exclamation' };
</script>

<style scoped lang="scss" type="text/scss">

</style>
