import { viewApplication, viewType } from 'shared/constants.json';

export const iconByType = (type) => {
  switch (type) {
    case viewType.atlas:
      return 'atlas';
    case viewType.cascade:
      return 'cascade';
    case viewType.list:
      return 'list';
    case viewType.feed:
      return 'feed-view';
    case viewType.tree:
      return 'tree-view';
    case viewType.default:
      return 'default-view';
    default:
      return '';
  }
};

export const findViews = ({ views, application, space, planning, user, gridPageTile }) => views.filter((v) => filterViewFn({ view: v, application, space, planning, user, gridPageTile }));

const viewApplicationWithSpace = [
  viewApplication.goalSpace,
  viewApplication.updateSpace,
  viewApplication.updateFeedSpace,
  viewApplication.insightSpace,
  viewApplication.memberSpace,
];

const viewApplicationWithPlanning = [
  viewApplication.planningDetails,
];

const viewApplicationWithUser = [
  viewApplication.goalUserProfile,
];

const viewApplicationWithGridPageTile = [
  viewApplication.gridPageTileGoal,
  viewApplication.gridPageTileCheckin,
  viewApplication.gridPageTileUpdateFeed,
  viewApplication.gridPageTileMember,
];

const filterViewFn = ({ view, application, space, planning, user, gridPageTile }) => {
  if (viewApplicationWithSpace.includes(view.viewApplication)) {
    if (space === null || view.space === undefined) {
      return false;
    }
    return view.viewApplication === application && view.space.uid === space.uid;
  }

  if (viewApplicationWithPlanning.includes(view.viewApplication)) {
    if (planning === null || view.planning === undefined) {
      return false;
    }
    return view.viewApplication === application && view.planning.uid === planning.uid;
  }

  if (viewApplicationWithUser.includes(view.viewApplication)) {
    if (user === null || view.user === undefined) {
      return false;
    }
    return view.viewApplication === application && view.user.uid === user.uid;
  }

  if (viewApplicationWithGridPageTile.includes(view.viewApplication)) {
    if (gridPageTile === null || view.gridPageTile === undefined) {
      return false;
    }

    return view.viewApplication === application && view.gridPageTile.uid === gridPageTile.uid;
  }

  return view.viewApplication === application;
};

export const createViewId = (view) => {
  if (view.user !== null && view.user !== undefined && view.user.uid !== undefined) {
    return `${view.uid}_${view.viewApplication}_${view.user.uid}`;
  }
  if (view.planning !== null && view.planning !== undefined && view.planning.uid !== undefined) {
    return `${view.uid}_${view.viewApplication}_${view.planning.uid}`;
  }
  if (view.space !== null && view.space !== undefined && view.space.uid !== undefined) {
    return `${view.uid}_${view.viewApplication}_${view.space.uid}`;
  }
  if (view.gridPageTile !== null && view.gridPageTile !== undefined && view.gridPageTile.uid !== undefined) {
    return `${view.uid}_${view.viewApplication}_${view.gridPageTile.uid}`;
  }
  return `${view.uid}_${view.viewApplication}`;
};

export const canEditView = (view, readOnly, hasPublicViewAccess, myTeamsIds) => {
  if (readOnly === true) {
    return false;
  }

  if (view.isPublic === false) {
    return true;
  }

  return (hasPublicViewAccess
      || (view.space !== undefined && view.space !== null && myTeamsIds.includes(view.space.uid))
      || (view.planning !== undefined && view.planning !== null)
      || (view.gridPageTile !== undefined && view.gridPageTile !== null)
  );
};
