<template>
  <div class="access-policy">
    <div class="_scopes">
      <template v-if="creator !== null">
        <m-content
          padding-xs
          class="_list-item"
        >
          <user-display
            :user="creator"
            large
          >
            <template #subName>
              <div class="_subtitle">
                {{ creatorDescription === '' ? $t('accessPolicy.creatorDescription') : creatorDescription }}
              </div>
            </template>
          </user-display>
          <div class="_right">
            <access-policy-type-selector
              value="full"
              disabled
            />
          </div>
        </m-content>
        <m-divider none />
      </template>
      <m-content
        padding-xs
        class="_list-item"
      >
        <div class="_left">
          <m-icon
            v-if="companyImage === ''"
            size="30"
            class="_global"
            type="global"
          />
          <m-avatar
            v-else
            :src="companyImage"
            :size="30"
          />
        </div>
        <div class="_middle">
          <div class="_title">
            {{ account.companyName }}
          </div>
          <div class="_subtitle">
            {{ $t('accessPolicy.organizationDescription') }}
          </div>
        </div>
        <div class="_right">
          <access-policy-type-selector
            :value="value.accountAccess"
            :disabled="disabled"
            :access-types="accessTypes"
            show-disable
            @change="updateAccount"
          />
        </div>
      </m-content>
      <div
        v-for="scope in sortedScopes"
        :key="scope.uid"
        @click="showScopeSelector(scope)"
      >
        <m-divider none />
        <m-content
          v-if="typeof scope.scope !== 'undefined' && scope.scope !== null"
          padding-xs
          class="_list-item -scope"
        >
          <div class="_middle">
            <scope-filter
              :value="scope.scope"
              :props="userProps"
              :account="account"
              show-static-user-selection
              disabled
              view-mode
              class="_filter"
            />
          </div>
          <div
            class="_right"
            @click.stop=""
          >
            <access-policy-type-selector
              :value="scope.accessType"
              :disabled="disabled"
              :access-types="accessTypes"
              show-remove
              @change="scopeTypeChanged(scope, $event)"
            />
          </div>
        </m-content>
        <m-content
          v-else-if="scope.users.length > 0"
          padding-xs
          class="_list-item"
        >
          <user-display
            :user="scope.users[0]"
            large
          >
            <template #subName>
              <div class="_subtitle">
                {{ scope.users[0].email }}
              </div>
            </template>
          </user-display>
          <div class="_right">
            <access-policy-type-selector
              :value="scope.accessType"
              :disabled="disabled"
              :access-types="accessTypes"
              show-remove
              @change="scopeTypeChanged(scope, $event)"
              @click.stop=""
            />
          </div>
        </m-content>
      </div>
      <slot name="after-access-scope" />
    </div>
    <m-divider none />
    <slot name="before-buttons" />
    <m-content
      padding-xs
      class="_actions"
    >
      <div class="_btn _invite">
        <m-btn
          :color="buttonColor"
          :disabled="disabled"
          block
          @click.stop="showScopeSelector(null)"
        >
          {{ addUserText === '' ? $t('accessPolicy.inviteButton') : addUserText }}
        </m-btn>
      </div>
      <div
        v-if="pageLink !== ''"
        class="_btn -page-link"
      >
        <m-btn
          v-clipboard="pageLink"
          v-clipboard:success="clipboardSuccessHandler"
          v-clipboard:error="logCatch(onError)"
          block
        >
          {{ $t('accessPolicy.copyLink') }}
        </m-btn>
      </div>
    </m-content>
    <m-dialog
      v-model:value="showModal"
      hide-footer
      no-padding
    >
      <access-policy-scope-selector
        :access-policy-scope="selectedScope"
        :disabled="disabled"
        :button-label="addGroupButtonText"
        :excluded-users="excludedUsers"
        :access-types="accessTypes"
        @submit="scopeSubmitted"
      />
    </m-dialog>
  </div>
</template>

<script>
import AccessPolicyScopeSelector from '@/components/AccessPolicyScopeSelector.vue';
import AccessPolicyTypeSelector from '@/components/access-policy/AccessPolicyTypeSelector.vue';
import ScopeFilter from '@/components/filter/ScopeFilter.vue';
import UserDisplay from 'shared/components/UserDisplay.vue';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useProperties from '@/composables/property/property';
import useSnackBar from '@/composables/snackbar';
import { DateTime } from 'luxon';
import { accessPolicyType } from 'shared/constants.json';
import { addOrUpdate, updateObjectInArray } from 'shared/lib/array/write';
import { copy, shallowCopy } from 'shared/lib/copy';
import { createProp } from '@/lib/props';
import { getAccessTypeOfUser, getDirectUsersFromAccessPolicy } from '@/lib/access-policy';
import { guid } from 'shared/lib/uuid';
import { logCatch } from '@/lib/logger/logger';
import { accessPolicyScope as scopeConfig, user as userConfig } from 'shared/api/query/configs.json';

const REMOVE = 'remove';

export default {
  name: 'AccessPolicy',
  props: {
    value: {
      type: Object,
      required: true,
    },
    creator: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    pageLink: {
      type: String,
      default: '',
    },
    addUserText: {
      type: String,
      default: '',
    },
    accessTypes: {
      type: Array,
      default: () => [accessPolicyType.read, accessPolicyType.write, accessPolicyType.full],
    },
    creatorDescription: {
      type: String,
      default: '',
    },
    suppressConfirm: {
      type: Boolean,
      default: false,
    },
    addGroupButtonText: {
      type: String,
      default: '',
    },
    buttonColor: {
      type: String,
      default: 'primary',
    },
  },
  emits: ['input', 'update:value', 'close'],
  components: { AccessPolicyTypeSelector, ScopeFilter, AccessPolicyScopeSelector, UserDisplay },
  setup() {
    const snackbar = useSnackBar();
    const { userProperties } = useProperties();
    const { loggedInUser, userLang } = useLoggedInUser();
    const { loggedInUserAccount } = useLoggedInUserAccount();
    return { snackbar, userProperties, loggedInUser, userLang, account: loggedInUserAccount };
  },
  data() {
    return {
      showModal: false,
      selectedScope: null,
      logCatch,
    };
  },
  computed: {
    excludedUsers() {
      return getDirectUsersFromAccessPolicy(this.value);
    },
    companyImage() {
      if (this.account.companyImage === null) {
        return '';
      }
      return this.account.companyImage.getURL;
    },
    userProps() {
      return this.userProperties.map((p) => createProp(p, false, true, true, this.userLang, userConfig.model));
    },
    sortedScopes() {
      return shallowCopy(this.value.scopes).filter((s) => s?.deletedAt === undefined).sort((a, b) => a.users.length - b.users.length);
    },
  },
  methods: {
    onError() {
      this.snackbar.error(this.$t('error.duringCopying'));
    },
    updateAccount(value) {
      const updated = {
        ...this.value,
        accountAccess: value,
      };

      const emitUpdate = () => {
        this.$emit('input', updated);
        this.$emit('update:value', updated);
      };
      if (this.suppressConfirm || this.hasFullAccessAfterChange({}, value)) {
        emitUpdate();
        return;
      }

      this.$confirm({
        title: this.$t('accessPolicy.changeText'),
        okText: this.$t('general.change'),
        okType: 'danger',
        maskClosable: true,
        cancelText: this.$t('general.cancel'),
        onOk() {
          emitUpdate();
        },
      });
    },
    showScopeSelector(scope) {
      if (scope !== null && (scope.scope === null || scope.users.length > 0)) {
        return;
      }
      this.selectedScope = scope;
      this.showModal = true;
    },
    scopeSubmitted(scope) {
      this.showModal = false;
      this.updateScopes(this.createSingleScopesPerUser(scope));
    },
    createSingleScopesPerUser(scope) {
      if (scope.users.length <= 1) {
        return [scope];
      }
      const singleScopes = scope.users.map((user) => ({
        [scopeConfig.edges.accessType]: scope[scopeConfig.edges.accessType],
        [scopeConfig.edges.users]: [user],
        [scopeConfig.edges.scope]: null,
      }));
      const deletedAt = DateTime.local().toISO();
      singleScopes.push({ ...scope, deletedAt });

      return singleScopes;
    },
    scopeTypeChanged(scope, newType) {
      const newScope = copy(scope);
      newScope.accessType = newType;

      if (newType === REMOVE) {
        newScope.accessType = accessPolicyType.disabled;
        this.removeScope(newScope);
        return;
      }

      if (this.suppressConfirm || this.hasFullAccessAfterChange(newScope, this.value.accountAccess)) {
        this.updateScopes([newScope]);
        return;
      }
      this.confirmChange([newScope]);
    },
    hasFullAccessAfterChange(newScope, newAccountAccess) {
      const accessPolicy = copy(this.value);
      accessPolicy.accountAccess = newAccountAccess;
      updateObjectInArray(accessPolicy.scopes, newScope);
      return getAccessTypeOfUser({
        accessPolicy,
        creator: this.creator,
      }, this.loggedInUser) === accessPolicyType.full;
    },
    confirmChange(newScope) {
      const update = this.updateScopes;
      this.$confirm({
        title: this.$t('accessPolicy.changeText'),
        okText: this.$t('general.change'),
        okType: 'danger',
        maskClosable: true,
        cancelText: this.$t('general.cancel'),
        onOk() {
          update(newScope);
        },
      });
    },
    removeScope(scope) {
      const deletedAt = DateTime.local().toISO();
      const scopesToDelete = [{ ...scope, deletedAt }];

      if (this.suppressConfirm || this.hasFullAccessAfterChange(scope, this.value.accountAccess)) {
        this.updateScopes(scopesToDelete);
        return;
      }
      this.confirmChange(scopesToDelete);
    },
    setKeyOnScope(scope) {
      let key = guid();
      if (typeof scope.key !== 'undefined') {
        key = scope.key;
      }
      if (typeof scope.uid !== 'undefined') {
        key = scope.uid;
      }
      return { ...scope, key };
    },
    updateScopes(newScopes) {
      let scopes = this.value.scopes.map((s) => this.setKeyOnScope(s));
      const newSc = newScopes.map((s) => this.setKeyOnScope(s));
      scopes = addOrUpdate(scopes, newSc, 'key');
      const updated = {
        ...this.value,
        scopes,
      };
      this.$emit('input', updated);
      this.$emit('update:value', updated);
    },
    clipboardSuccessHandler() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .access-policy {
    min-width: 40rem;
    max-width: 60rem;

    ._scopes {
      max-height: 75vh;
      overflow: auto;
    }

    ._global {
      width: 3rem;
    }

    ._title {
      font-weight: $font-weight-semibold;
    }

    ._subtitle {
      font-size: $font-size-2;
      color: $font-color-secondary;
    }

    ._list-item {
      display: flex;
      flex: 1 1 auto;
      flex-wrap: wrap;
      align-items: center;

      &.-scope {
        min-height: 5.4rem;

        &:hover {
          cursor: pointer;
          background-color: $hover-color;
        }
      }

      ._left {
        margin-right: 1.2rem;
      }

      ._middle {
        flex: 1 1 0;
      }

      ._right {
        margin-left: auto;
      }
    }

    ._actions {
      display: flex;

      ._btn {
        flex: 1 1 50%;

        &.-page-link {
          margin-left: .8rem;
        }
      }
    }
  }
</style>
