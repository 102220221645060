<template>
  <div class="m-slider">
    <input
      :min="min"
      :max="max"
      :value="value"
      type="range"
      class="_slider"
      @input="change"
    >
  </div>
</template>

<script>
export default {
  name: 'MSlider',
  props: {
    value: {
      type: [Number, Array],
      required: true,
    },
    step: {
      type: Number,
      required: true,
    },
    min: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
  },
  emits: ['input', 'update:value', 'change'],
  data() {
    return { val: 0 };
  },
  methods: {
    change(event) {
      const val = parseInt(event.target.value, 10);
      this.$emit('input', val);
      this.$emit('update:value', val);
      this.$emit('change', val);
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .m-slider {
    width: 100%;

    ._slider {
      appearance: none;
      width: 100%;
      height: .4rem;
      background: map_get($grey, 'lighten-3');
      border-radius: .2rem;
      outline: none;
      opacity: .7;
      transition: opacity .2s;

      &::-webkit-slider-thumb {
        width: 1.4rem;
        height: 1.4rem;
        cursor: pointer;
        background: white;
        border: 2px solid $primary-color;
        border-radius: 50%;
        appearance: none;
      }

      &::-moz-range-thumb {
        width: 1.4rem;
        height: 1.4rem;
        cursor: pointer;
        background: white;
        border: 2px solid $primary-color;
        border-radius: 50%;
        appearance: none;
      }

      &:hover {
        opacity: 1;
      }
    }
  }
</style>
