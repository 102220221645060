<template>
  <div class="calendar-connect">
    <template v-if="foreignConnection">
      <m-alert type="warning">
        {{ $t('calendarConnect.alreadySet', {user: `${meeting.msCalendarEvent.creator.firstName} ${meeting.msCalendarEvent.creator.lastName}`}) }}
      </m-alert>
    </template>
    <template v-if="notConnected">
      <div class="_description">
        {{ $t('calendarConnect.description') }}
      </div>
      <m-btn
        icon="outlook"
        :loading="loading"
        @click="connectMsCalendar"
      >
        {{ $t('calendarConnect.msCalendar') }}
      </m-btn>
    </template>
    <div v-if="brokenConnection">
      <div class="_description">
        {{ $t('calendarConnect.finishSetupDescription') }}
      </div>
      <div class="_final-action">
        <m-btn
          icon="outlook"
          @click="finishMsCalendarConnection"
        >
          {{ $t('calendarConnect.connectMsCalendar') }}
        </m-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { dataSourceAuthenticationType, dataSourceType } from 'shared/constants.json';
import { encodeURLValues } from '@/lib/url';
import { mapActions } from 'vuex';

import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useMSCalendarDataSource from '@/composables/ms-calendar/ms-calendar';
import { logCatch } from '@/lib/logger/logger';

export default {
  name: 'CalendarConnect',
  props: {
    meeting: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { loggedInUser } = useLoggedInUser();
    const { loggedInUserAccount } = useLoggedInUserAccount();
    const { getCalendarDataSource, msCalendarDataSource, createCalendarDataSource } = useMSCalendarDataSource();
    return { loggedInUser, getCalendarDataSource, createCalendarDataSource, loggedInUserAccount, msCalendarDataSource };
  },
  emits: ['close'],
  data() {
    return { loading: false };
  },
  computed: {
    msCalendarConnectUrl() {
      if (this.msCalendarDataSource === undefined) {
        return null;
      }

      const params = {
        dataSourceId: this.msCalendarDataSource.uid,
        origin: '/#/oauth-redirect',
      };

      const targetParams = { oauthRedirect: `api/v1/data-source/install?${encodeURLValues(params)}` };
      return `/#/oauth-redirect?${encodeURLValues(targetParams)}`;
    },
    foreignConnection() {
      return this.meeting.msCalendarEvent !== null;
    },
    notConnected() {
      if (this.meeting.msCalendarEvent !== null) {
        return false;
      }

      if (this.msCalendarDataSource === undefined) {
        return true;
      }

      return this.loading;
    },
    brokenConnection() {
      if (this.msCalendarDataSource === undefined) {
        return false;
      }

      if (this.loading) {
        return false;
      }

      return this.msCalendarDataSource.accessToken === '';
    },
  },
  methods: {
    ...mapActions(['createEntityV2', 'getEntitiesV2']),
    finishMsCalendarConnection() {
      window.open(this.msCalendarConnectUrl, '', 'width=700,height=700');

      // this finds the event function, as well as the event name IE, safari compatible
      const eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
      const eventer = window[eventMethod];
      const messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message';

      const errMessage = this.$t('error.default');
      const successMessage = this.$t('success.created');

      eventer(messageEvent, (event) => {
        if (event.data.message !== 'oauthInstall' && event.data.status !== 'success') {
          return;
        }

        this.getCalendarDataSource()
          .then(() => {
            this.$showSnackbar({ color: 'success', message: successMessage });
            this.$emit('close');
          })
          .catch(logCatch(() => {
            this.$showSnackbar({ color: 'error', message: errMessage });
          }))
          .finally(() => {
            this.loading = false;
          });
      });
    },
    connectMsCalendar() {
      this.loading = true;
      this.createCalendarDataSource(
        {
          name: 'MS Outlook Calendar',
          creator: { uid: this.loggedInUser.uid },
          account: { uid: this.loggedInUserAccount.uid },
          shared: false,
          type: dataSourceType.msCalendar,
          authenticationType: dataSourceAuthenticationType.oauth,
        },
      ).then(() => {
        this.finishMsCalendarConnection();
      }).catch(logCatch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      })).finally(() => {
        this.loading = false;
      });
    },
  },
};
</script>

<style
  scoped
  lang="scss"
  type="text/scss"
>
  .calendar-connect {
    ._description {
      padding-bottom: 2.4rem;
      color: $font-color-secondary;
    }

    ._final-action {
      display: flex;
      justify-content: center;
      width: 100%;
      padding: .8rem;
    }
  }
</style>
