<template>
  <div class="share-top-bar">
    <div class="_container">
      <h3>
        {{ $t('formShare.title') }}
      </h3>
      <p>
        {{ $t('formShare.description') }}
      </p>
    </div>
  </div>
</template>

<script>
export default { name: 'ShareTopBar' };
</script>

<style scoped lang="scss" type="text/scss">
  .share-top-bar {
    display: flex;
    justify-content: center;
    height: 18rem;
    padding: 5rem 0 2rem;
    background-color: white;

    ._container {
      width: 100%;
      max-width: 120rem;
      padding: 0 1.6rem;

      p {
        margin-top: 1.6rem;
        color: $font-color-secondary;
      }
    }
  }
</style>
