<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="3.98047"
      y="3.30774"
      width="13.2308"
      height="13.2308"
      fill="white"
    />
    <path
      d="M7.03005 10.5432H14.1623C14.2475 10.5432 14.3173 10.4735 14.3173 10.3882V9.4579C14.3173 9.37263 14.2475 9.30286 14.1623 9.30286H7.03005C6.94477 9.30286 6.875 9.37263 6.875 9.4579V10.3882C6.875 10.4735 6.94477 10.5432 7.03005 10.5432Z"
      fill="currentColor"
    />
    <path
      d="M17.7284 2.17065H3.46394C3.1209 2.17065 2.84375 2.4478 2.84375 2.79085V17.0553C2.84375 17.3983 3.1209 17.6755 3.46394 17.6755H17.7284C18.0714 17.6755 18.3486 17.3983 18.3486 17.0553V2.79085C18.3486 2.4478 18.0714 2.17065 17.7284 2.17065ZM16.9531 16.28H4.23918V3.56609H16.9531V16.28Z"
      fill="currentColor"
    />
    <rect
      x="6.46143"
      y="5.78845"
      width="13.2308"
      height="13.2308"
      fill="white"
    />
    <path
      d="M9.51101 13.024H16.6432C16.7285 13.024 16.7983 12.9542 16.7983 12.8689V11.9386C16.7983 11.8533 16.7285 11.7836 16.6432 11.7836H9.51101C9.42573 11.7836 9.35596 11.8533 9.35596 11.9386V12.8689C9.35596 12.9542 9.42573 13.024 9.51101 13.024Z"
      fill="currentColor"
    />
    <path
      d="M20.2093 4.65137H5.9449C5.60186 4.65137 5.32471 4.92852 5.32471 5.27156V19.536C5.32471 19.879 5.60186 20.1562 5.9449 20.1562H20.2093C20.5524 20.1562 20.8295 19.879 20.8295 19.536V5.27156C20.8295 4.92852 20.5524 4.65137 20.2093 4.65137ZM19.4341 18.7607H6.72014V6.0468H19.4341V18.7607Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default { name: 'ExpandMinus' };
</script>

<style scoped lang="scss" type="text/scss">

</style>
