import { DateTime } from 'luxon';
import { goalProgressMeasurement } from 'shared/constants.json';

export const formatNumber = (n, language) => new Intl.NumberFormat(language, { style: 'decimal' }).format(n);

export const timePassed = ({ goalCycles = [], time }) => {
  switch (goalCycles.length) {
    case 0: {
      return 1;
    }
    case 1: {
      const distance = DateTime.fromISO(goalCycles[0].end).diff(DateTime.fromISO(goalCycles[0].start), 'days');
      const diff = time.diff(DateTime.fromISO(goalCycles[0].start), 'days');

      return Math.min(1, Math.max(0, diff / distance));
    }
    default: {
      let start = DateTime.fromISO(goalCycles[0].start);
      let end = DateTime.fromISO(goalCycles[0].end);
      for (let i = 0; i < goalCycles.length; i++) {
        if (start.diff(DateTime.fromISO(goalCycles[i].start)) > 0) {
          start = DateTime.fromISO(goalCycles[i].start);
        }
        if (end.diff(DateTime.fromISO(goalCycles[i].end)) < 0) {
          end = DateTime.fromISO(goalCycles[i].end);
        }
      }

      const distance = end.diff(start, 'days');
      const diff = time.diff(start, 'days');

      return Math.min(1, Math.max(0, diff / distance));
    }
  }
};

export const current = (goal, value) => {
  if (value !== null && value !== undefined) {
    return value;
  }

  switch (goal.progressMeasurement) {
    case goalProgressMeasurement.alignedItems:
      return goal.cachedCalculatedCurrent;
    case goalProgressMeasurement.continuous:
      return goal.start;
    case goalProgressMeasurement.threshold:
      return goal.threshold;
    case goalProgressMeasurement.binary:
      return 0;
    case goalProgressMeasurement.none:
    default:
      return null;
  }
};

export const continuous = (goal, value) => {
  const distance = Math.abs(goal.start - goal.end);
  if (distance === 0) {
    return 0;
  }

  const reference = goal.start;

  let c = value;
  if (goal.start < goal.end && c < goal.start) {
    c = goal.start;
  }
  if (goal.start > goal.end && c > goal.start) {
    c = goal.start;
  }

  return 100 * Math.abs((c - reference) / distance);
};
