<template>
  <div
    class="space-item"
    :data-id="space.uid"
  >
    <m-link
      :to="link"
      inherit-color
      :class="['_content', !space.match ? '-light' : '']"
      @mousedown.prevent
    >
      <div
        class="_left"
        :style="styleLeft"
      >
        <div class="_expandable">
          <m-btn
            v-if="space.children.length > 0"
            :icon="expanded ? 'down' : 'right'"
            fab
            xs
            hide-border
            :icon-color="$colors.grey.base"
            @click.prevent="$emit('toggle-expand')"
          />
          <m-btn
            v-else
            icon="dot"
            fab
            xs
            disabled
            :icon-color="$colors.grey.base"
            force-icon-color
            hide-border
          />
        </div>
        <div
          class="_icon"
        >
          <m-avatar
            v-if="isEmptyIcon(icon)"
            :size="18"
            :text="space.title"
            :amount-chars="1"
            :color="getColor(space.color)"
          />
          <m-icon-display
            v-else
            :icon="icon"
          />
        </div>
        <div class="_title">
          {{ space.title }}
        </div>
      </div>
      <div class="_right">
        <m-dropdown
          v-model:value="showEdit"
          :title="$t('general.actions')"
        >
          <m-btn
            class="_btn _actions"
            icon="ellipsis"
            xs
            fab
            light
            hide-border
            @click.prevent="showEdit = true"
          />
          <template #overlay>
            <space-nav-menu
              :space="space"
              :pin="pin"
              @close="showEdit = false"
            />
          </template>
        </m-dropdown>
        <m-tooltip :mouse-enter-delay=".3">
          <span>
            <m-btn
              :icon="isPinned || !removable ? 'pushpin-filled' : 'pushpin'"
              hide-border
              xs
              fab
              light
              :loading="loading"
              :disabled="!removable"
              class="_btn _pin"
              @click.prevent="pinItem"
            />
          </span>
          <template #title>
            <template v-if="!removable">
              {{ $t('spaceItem.disabledHelp', { name: space.title, title: spacePropertyTitle }) }}
            </template>
            <template v-else-if="isPinned">
              {{ $t('spaceItem.unpinHelp') }}
            </template>
            <template v-else>
              {{ $t('spaceItem.pinHelp') }}
            </template>
          </template>
        </m-tooltip>
      </div>
    </m-link>
  </div>
</template>

<script>
import SpaceNavMenu from '@/components/spaces/SpaceNavMenu.vue';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import usePersonalAppSettings from '@/composables/logged-in-user/personal-app-settings';
import usePins from '@/composables/pin/pin';
import useSpace from '@/composables/space/space';
import { getColor } from 'shared/lib/color-map';
import { isEmptyIcon } from 'shared/lib/icon';
import { pinType } from 'shared/constants.json';
import { textByLang } from 'shared/lib/language';
import { toRef } from 'vue';

export default {
  name: 'SpaceItem',
  props: {
    space: {
      type: Object,
      required: true,
    },
    property: {
      type: Object,
      required: true,
    },
    pin: {
      type: Object,
      default: () => null,
    },
    indentation: {
      type: Number,
      required: true,
    },
    language: {
      type: String,
      required: true,
    },
    removable: {
      type: Boolean,
      default: false,
    },
    isPinned: {
      type: Boolean,
      default: false,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    link: {
      type: Object,
      required: true,
    },
  },
  emits: ['toggle-expand'],
  components: { SpaceNavMenu },
  setup(props) {
    const spaceSvc = useSpace(toRef(props, 'space'));
    const { icon } = spaceSvc;
    const { createPinLoading, createPin, deletePinLoading, deletePin } = usePins();
    const { loggedInUser } = useLoggedInUser();
    const { personalAppSettings } = usePersonalAppSettings(loggedInUser);
    return {
      icon,
      createPinLoading,
      createPin,
      deletePinLoading,
      deletePin,
      personalAppSettings,
    };
  },
  data() {
    return { showEdit: false };
  },
  computed: {
    loading() {
      return this.createPinLoading || this.deletePinLoading;
    },
    styleLeft() {
      return { paddingLeft: `${this.indentation * 2}rem`, maxWidth: '30rem' };
    },
    spacePropertyTitle() {
      return textByLang(this.property.label, this.language);
    },
  },
  methods: {
    isEmptyIcon,
    getColor,
    pinItem() {
      if (this.isPinned) {
        this.deletePin(this.pin).catch(() => {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
        });
        return;
      }
      this.createPin({
        space: { uid: this.space.uid },
        type: pinType.space,
        personalAppSettings: { uid: this.personalAppSettings.uid },
      }).catch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      });
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .space-item {
    ._content {
      display: flex;
      align-items: center;
      height: 3.2rem;
      padding: 0 1.1rem;
      color: $font-color-primary;
      cursor: pointer;

      &.-light {
        opacity: .4;
      }

      ._left {
        display: flex;
        align-items: center;
        margin-right: 1rem;

        ._expandable {
          margin-right: .6rem;
        }

        ._icon {
          margin-right: .6rem;
        }

        ._title {
          max-width: 18rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      ._right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        min-width: 5rem;
        margin-left: auto;

        ._btn {
          margin-left: .6rem;
        }
      }

      &:hover {
        background-color: $hover-color;
      }
    }
  }
</style>
