<template>
  <div class="user-avatar">
    <component
      :is="hasTooltip ? 'm-tooltip' : 'div'"
      placement="top"
    >
      <m-avatar
        :size="size"
        :src="profileImage"
        :class="['user-avatar']"
        :bordered="bordered"
        :text="avatarText"
        black-and-white
        shape="circle"
      />
      <template
        v-if="hasTooltip"
        #title
      >
        {{ avatarText }}
      </template>
    </component>
  </div>
</template>

<script>
import useUser from 'shared/composables/user';
import { computed, toRef } from 'vue';
import { useI18n } from 'vue-i18n';

export default {
  name: 'UserAvatar',
  props: {
    user: {
      validator: (prop) => typeof prop === 'object' || prop === null,
      required: true,
    },
    size: {
      type: Number,
      default: 24,
    },
    hasTooltip: {
      type: Boolean,
      default: false,
    },
    bordered: {
      type: Boolean,
      default: false,
    },
    src: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const i18n = useI18n();
    const avatarText = computed(() => {
      if (props.user === null) {
        return i18n.t('user.deleted.name');
      }

      return useUser(toRef(props, 'user')).displayName.value;
    });

    return { avatarText };
  },
  computed: {
    profileImage() {
      if (this.user === null || this.user.profileImage === undefined || this.user.profileImage === null) {
        return '';
      }

      return this.user.profileImage.getURL;
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .user-avatar {
    display: inline;
  }
</style>
