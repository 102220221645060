<template>
  <div class="icon-card-input">
    <icon-card
      v-for="(item, index) in items"
      :key="index"
      class="_item"
      :icon="item.icon"
      :title="item.title"
      :description="item.description"
      :selected="selectedItem === item.value"
      :disabled="disabled"
      :height="height"
      :width="width"
      @click="change(item)"
    />
  </div>
</template>

<script>
import IconCard from '@/components/IconCard.vue';

export default {
  name: 'IconCardInput',
  props: {
    items: {
      type: Array,
      required: true,
    },
    value: {
      type: [String, Boolean],
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '17rem',
    },
    height: {
      type: String,
      default: '19rem',
    },
  },
  emits: ['change'],
  components: { IconCard },
  data() {
    return { selectedItem: null };
  },
  methods: {
    change(item) {
      if (this.disabled) {
        return;
      }

      this.selectedItem = item;
      this.$emit('change', item.value);
    },
  },
  watch: {
    value(val) {
      if (val === this.selectedItem) {
        return;
      }

      this.selectedItem = val;
    },
  },
  mounted() {
    this.selectedItem = this.value;
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .icon-card-input {
    display: flex;
    flex-wrap: wrap;
    margin-right: -1rem;
    margin-left: -1rem;

    ._item {
      margin: 1rem;
    }
  }
</style>
