<template>
  <component
    :is="hasTooltip ? 'm-tooltip' : 'div'"
    :placement="hasTooltip ? tooltipPlacement : undefined"
  >
    <div>
      <m-card-item
        :focused="focused"
        class="m-select-item"
        no-hover
        @click="handleClick"
        @mouseover="$emit('focus')"
      >
        <div class="_item">
          <m-tag
            :color="!tags || hasCustomItem ? 'none' : item.color"
            :automatic-color-fallback="automaticColor"
            :icon="item.icon"
            :type="item.type"
            :style="triggerStyle"
            :title="!hasCustomItem ? item.label : ''"
            :has-default-slot="hasCustomItem"
            small
          >
            <slot />
          </m-tag>
        </div>
        <template
          v-if="val !== null && (val === item.key || val.includes(item.key))"
          #right
        >
          <div
            class="_right"
          >
            <m-icon type="check" />
          </div>
        </template>
      </m-card-item>
    </div>
    <template #title>
      <slot name="tooltip" />
    </template>
  </component>
</template>

<script>
import MCardItem from 'shared/components/base/MCardItem.vue';
import MTextField from 'shared/components/base/MTextField.vue';
import MTooltip from 'shared/components/base/MTooltip.vue';

export default {
  name: 'MSelectItem',
  props: {
    val: {
      type: [String, Object, Array],
      default: () => null,
    },
    item: {
      type: Object,
      required: true,
    },
    focused: {
      type: Boolean,
      default: false,
    },
    tagTextColor: {
      type: Function,
      required: true,
    },
    tagBackgroundColor: {
      type: Function,
      required: true,
    },
    automaticColor: {
      type: Boolean,
      default: false,
    },
    hasCustomItem: {
      type: Boolean,
      default: false,
    },
    triggerStyle: {
      type: Object,
      default: () => ({}),
    },
    tags: {
      type: Boolean,
      default: false,
    },
    tooltipPlacement: {
      type: String,
      default: 'top',
    },
    hasTooltip: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['focus', 'click'],
  components: { MTooltip, MCardItem, MTextField },
  methods: {
    handleClick() {
      this.$emit('click');
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .m-select-item {
    line-height: .6;

    ._item {
      display: inline-flex;
      align-items: center;
      min-width: 0;
    }
  }
</style>
