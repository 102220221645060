import { EVENTS } from '@/lib/constants';
import { EventBus } from '@/lib/event-bus';

const goalsByPlanning = (goals) => Object.values(goals.filter((goal) => goal.planning !== null && !goal.publishedAt).reduce((res, goal) => {
  if (res[goal.planning.uid] === undefined) {
    res[goal.planning.uid] = { planning: goal.planning, goals: [] };
  }
  res[goal.planning.uid].goals.push(goal);
  return res;
}, {}));

const registerEventListeners = () => {
  // Goal
  EventBus.$on(EVENTS.GOAL.GOALS_CREATED, ({ goals }) => {
    goalsByPlanning(goals).forEach((planningGoals) => EventBus.$emit(EVENTS.PLANNING.GOALS_CREATED, { planning: planningGoals.planning, goals: planningGoals.goals }));
  });
  EventBus.$on(EVENTS.GOAL.GOALS_DELETED, ({ goals }) => {
    goalsByPlanning(goals).forEach((planningGoals) => EventBus.$emit(EVENTS.PLANNING.GOALS_DELETED, { planning: planningGoals.planning, goals: planningGoals.goals }));
  });
};

const eventPipeline = {
  install() {
    registerEventListeners();
  },
};

export default eventPipeline;
