import { copy } from 'shared/lib/copy';
import { includesArray } from 'shared/lib/array/array';

const searchReplaceInObject = (obj, excludedPaths, currentPath, key, oldValue, newValue) => {
  if (obj === undefined || obj === null || typeof obj === 'number' || typeof obj === 'boolean' || typeof obj === 'string') {
    return obj;
  }

  const r = {};
  Object.keys(obj).forEach((k) => {
    const path = [...currentPath, k];
    if (k === key) {
      if (includesArray(excludedPaths, path) || obj[k] !== oldValue) {
        r[k] = obj[k];
        return;
      }
      r[k] = newValue;
      return;
    }
    if (obj[k] === undefined || obj[k] === null) {
      r[k] = obj[k];
      return;
    }
    if (Array.isArray(obj[k])) {
      r[k] = searchReplaceInArray(obj[k], excludedPaths, path, key, oldValue, newValue);
      return;
    }
    if (typeof obj[k] === 'object') {
      r[k] = searchReplaceInObject(obj[k], excludedPaths, path, key, oldValue, newValue);
      return;
    }

    r[k] = obj[k];
  });
  return r;
};

const searchReplaceInArray = (arr, excludedPaths, currentPath, key, oldValue, newValue) => arr.reduce((res, next) => {
  res.push(searchReplaceInObject(next, excludedPaths, currentPath, key, oldValue, newValue));
  return res;
}, []);

/**
 * SearchReplaceAll returns a copy of the object o with all instances where
 * the needle matches the key and the value matches the old value replacing it by the new value.
 * SearchReplaceAll does not replace values in case the path to the value is
 * included in the array of arrays excludedPaths.
 * Undefined values will be removed from o since we use copy which stringifies and
 * parses an object.
 * @param {object} o
 * @param {string} key
 * @param {any} oldValue
 * @param {any} newValue
 * @param {array} excludedPaths
 */
export const searchReplaceAll = (o, key, oldValue, newValue, excludedPaths = []) => {
  if (Array.isArray(o)) {
    return searchReplaceInArray(copy(o), excludedPaths, [], key, oldValue, newValue);
  }

  return searchReplaceInObject(copy(o), excludedPaths, [], key, oldValue, newValue);
};
