<template>
  <div class="nav-items">
    <nav-item
      v-for="item in items"
      :id="item.id"
      :key="item.uid"
      :title="item.title"
      :icon="item.icon"
      :active="item.active"
      :to="item.to"
      :tag="item.tag"
      :tooltip="item.tooltip"
      :on-click="item.onClick"
      :icon-size="item.iconSize"
      :hover-color="hoverColor"
      :show-avatar="item.showAvatar"
      :avatar-shape="item.avatarShape"
      :avatar-color="item.avatarColor"
    >
      <template
        v-for="slot in Object.keys($slots)"
        #[slot]="scope"
      >
        <slot
          :name="slot"
          v-bind="scope"
        />
      </template>
    </nav-item>
  </div>
</template>

<script>
import NavItem from '@/components/navigation/NavItem.vue';

export default {
  name: 'NavItems',
  props: {
    items: {
      type: Array,
      required: true,
    },
    hoverColor: {
      type: String,
      default: 'regular',
    },
  },
  components: { NavItem },
};
</script>

<style
  scoped
  lang="scss"
  type="text/scss"
>
  .nav-items {
    ._link {
      text-decoration: none;
    }

    ._empty {
      margin-left: 3rem;
      color: $font-color-secondary;
    }
  }
</style>
