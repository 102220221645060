import { UID, VAR } from 'shared/api/query/constants';
import { notHasFilter } from 'shared/api/query/filter';
import { property, propertyValue, user } from 'shared/api/query/configs.json';
import { reverseEdge } from 'shared/api/query/edges';

export const makeUserFn = (childrenFn, varIdFn, isFilterMode = false) => (scope, index) => {
  const varName = varIdFn(index);

  if (scope.isEmpty === true) {
    const varBlocks = [
      {
        alias: VAR,
        uid: [scope.property.uid],
        model: property.model,
        func: { name: UID },
        children: [
          {
            attr: reverseEdge(propertyValue.edges.property),
            model: propertyValue.model,
            filter: notHasFilter(propertyValue.edges.users),
            children: childrenFn(varName),
          },
        ],
      },
    ];
    const filterTree = {
      func: {
        name: UID,
        needsVar: [{ name: varName, typ: 1 }],
      },
    };
    return { filterTrees: [filterTree], varBlocks };
  }

  const userIds = scope.users.map((u) => u.uid);
  if (userIds.length === 0 && isFilterMode) {
    return { filterTrees: [], varBlocks: [] };
  }

  const propertyVar = `${varIdFn(index)}_2`;
  const varBlocks = [
    {
      alias: VAR,
      uid: [scope.property.uid],
      model: property.model,
      func: { name: UID },
      children: [
        {
          attr: reverseEdge(propertyValue.edges.property),
          model: propertyValue.model,
          var: propertyVar,
        },
      ],
    },
    {
      alias: VAR,
      uid: userIds,
      model: user.model,
      func: { name: UID },
      children: [
        {
          attr: reverseEdge(propertyValue.edges.users),
          model: propertyValue.model,
          filter: {
            func: {
              name: UID,
              needsVar: [{ name: propertyVar, typ: 1 }],
            },
          },
          children: childrenFn(varName),
        },
      ],
    },
  ];

  const filterTree = {
    func: {
      name: UID,
      needsVar: [{ name: varName, typ: 1 }],
    },
  };
  return { filterTrees: [filterTree], varBlocks };
};

export const directPropUserFn = (model, varIdFn, isFilterMode = false) => (scope, index) => {
  let userIds = scope.users.map((u) => u.uid);
  if (scope.isEmpty === true) {
    userIds = [];
  }

  if (userIds.length === 0 && isFilterMode && !scope.isEmpty) {
    return { filterTrees: [], varBlocks: [] };
  }

  const varName = varIdFn(index);
  const varBlocks = [
    {
      alias: VAR,
      uid: userIds,
      model: user.model,
      func: { name: UID },
      children: [
        {
          attr: reverseEdge(scope.directProperty.edgeName),
          var: varName,
          model,
        },
      ],
    },
  ];

  const filterTree = {
    func: {
      name: UID,
      needsVar: [{ name: varName, typ: 1 }],
    },
  };
  return { filterTrees: [filterTree], varBlocks };
};
