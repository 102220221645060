<template>
  <m-dropdown
    v-model:value="showMenu"
    :title="$t('cardWidthSelector.title')"
    class="card-width-selector"
    block
    close-on-click
    :disabled="props.disabled"
  >
    <m-card-item
      class="_card"
      :disabled="props.disabled"
      @click="showMenu = true"
    >
      <div class="_left">
        {{ $t('cardWidthSelector.title') }}
      </div>
      <template #right>
        <div
          class="_right"
          @click="showMenu = true"
        >
          <div class="_text">
            {{ valueText }}
          </div>
          <div class="_icon">
            <m-icon
              type="down"
              :color="$colors.grey.lighten1"
              size="11"
            />
          </div>
        </div>
      </template>
    </m-card-item>
    <template #overlay>
      <m-card list>
        <m-card-item
          v-for="item in items"
          :key="item.value"
          @click="updateValue(item)"
        >
          {{ item.text }}
        </m-card-item>
      </m-card>
    </template>
  </m-dropdown>
</template>

<script setup>
import camelCase from 'lodash-es/camelCase';
import { CARD_SIZE_LARGE, CARD_SIZE_MEDIUM, CARD_SIZE_SMALL, NONE } from '@/lib/constants';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps({
  value: {
    type: String,
    default: NONE,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const showMenu = ref(false);

const items = computed(() => [
  {
    text: t('cardWidthSelector.small'),
    value: CARD_SIZE_SMALL,
  },
  {
    text: t('cardWidthSelector.medium'),
    value: CARD_SIZE_MEDIUM,
  },
  {
    text: t('cardWidthSelector.large'),
    value: CARD_SIZE_LARGE,
  },
]);

const emit = defineEmits(['input', 'update:value']);
const valueText = computed(() => t(`cardWidthSelector.${camelCase(props.value)}`));
const updateValue = (val) => {
  emit('input', val.value);
  emit('update:value', val.value);
};
</script>

<style scoped lang="scss" type="text/scss">
  .card-width-selector {
    ._card {
      ._right {
        display: flex;
        align-items: center;

        ._text {
          color: $font-color-secondary;
        }

        ._icon {
          margin-left: .4rem;
        }
      }
    }
  }
</style>
