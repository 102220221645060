import { computed } from 'vue';
import { routeName } from 'shared/constants.json';
import { useRoute } from 'vue-router';

export default function usePublishedViewDetailsCtx() {
  const route = useRoute();
  const inCtx = computed(() => route.name === routeName.publishedView);

  return { inCtx };
}
