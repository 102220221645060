import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import usePersonalAppSettings from '@/composables/logged-in-user/personal-app-settings';
import useRepo from '@/nebula/repo';
import useResourceSettings from '@/composables/logged-in-user-account/resource-settings';
import { viewCollection as viewCollectionConfig } from 'shared/api/query/configs.json';

export default function useViewCollections() {
  const repo = useRepo(viewCollectionConfig.model);
  const { resourceSettings } = useResourceSettings();
  const { loggedInUser } = useLoggedInUser();
  const { personalAppSettings } = usePersonalAppSettings(loggedInUser);

  const createViewCollection = ({ viewApplication, space, planning, gridPageTile, viewOrder }, { shared = false } = { shared: false }) => {
    if (shared === true) {
      return repo.createSingle({
        viewApplication,
        viewOrder,
        resourceSettings: { uid: resourceSettings.value.uid },
        space: space ? { uid: space.uid } : undefined,
        planning: planning ? { uid: planning.uid } : undefined,
        gridPageTile: gridPageTile ? { uid: gridPageTile.uid } : undefined,
      });
    }

    return repo.createSingle({
      viewApplication,
      viewOrder,
      personalAppSettings: { uid: personalAppSettings.value.uid },
      space: space ? { uid: space.uid } : undefined,
      planning: planning ? { uid: planning.uid } : undefined,
      gridPageTile: gridPageTile ? { uid: gridPageTile.uid } : undefined,
    });
  };

  return {
    updateViewCollection: repo.updateSingle,
    createViewCollection,
    viewCollections: repo.entityList,
  };
}
