import { userProvisioningTokenRepresentation } from 'shared/constants.json';

const hexEncodeToString = (input) => {
  let result = '';
  for (let i = 0; i < input.length; i++) {
    result += input.charCodeAt(i).toString(16);
  }
  return result;
};

export const isBlackListed = (rune) => {
  const blackList = ['"', '\'', '`', ' ', '\n', '\\'];
  for (let i = 0; i < blackList.length; i++) {
    if (rune.charCodeAt(0) === blackList[i].charCodeAt(0)) {
      return true;
    }
  }
  return false;
};

export const passphrase = (tokenLength) => {
  let res = '';
  for (;res.length <= tokenLength;) {
    const next = Math.floor(Math.random() * Math.floor(126 - 31));
    const nextRune = String.fromCharCode(next + 31);
    if (isBlackListed(nextRune)) {
      continue;
    }
    res = `${res}${nextRune}`;
  }

  return res;
};

export const token = (userProvisioningId, passPhrase) => {
  const res = hexEncodeToString(`${userProvisioningId}:${passPhrase}:${userProvisioningTokenRepresentation.withUserProvisioning}`);
  return res;
};
