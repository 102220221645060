<template>
  <div
    class="m-icon-display"
    :style="{ width: `${size/10}rem`, height: `${size/10}rem`, fontSize: `${size/10}rem` }"
  >
    <template v-if="[iconType.custom].includes(type)">
      <img
        v-if="imgLoadFailed === false"
        class="_img"
        :src="value.getURL"
        alt=""
        @error="imgLoadFailed = true"
      >
      <m-emoji
        v-else
        class="_img-failed"
        emoji="FileImageOutlined_light_grey"
        :size="size"
        :show-background="showBackground"
      />
    </template>
    <m-emoji
      v-else
      :emoji="value"
      :size="size"
      :show-background="showBackground"
    />
  </div>
</template>

<script setup>
import MEmoji from 'shared/components/base/MEmoji.vue';
import { Icon } from 'shared/lib/icon';
import { computed, ref, watch } from 'vue';
import { iconType } from 'shared/constants.json';

const props = defineProps({
  icon: {
    type: Icon,
    required: true,
  },
  size: {
    type: Number,
    default: 16,
  },
  showBackground: {
    type: Boolean,
    default: false,
  },
});

const value = computed(() => {
  if (props.icon === null) {
    return '';
  }
  return props.icon.value;
});
const type = computed(() => {
  if (props.icon === null) {
    return undefined;
  }
  return props.icon.type;
});

const imgLoadFailed = ref(false);
watch(value, () => {
  imgLoadFailed.value = false;
});
</script>

<style scoped lang="scss">
  .m-icon-display{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $border-radius-sm;

    ._img {
      max-width: 100%;
      max-height: 100%;
      border-radius: $border-radius-sm;
    }

    ._img-failed {
      color: $font-color-tertiary;
    }
  }
</style>
