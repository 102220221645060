<template>
  <m-dropdown
    v-if="showError || hasErrors"
    class="error-indicator-dropdown"
    :title="$t('cascadeTableRow.errorTitle')"
    :value="showError"
    @hide="showError = false"
  >
    <m-btn
      :color="hasErrors ? 'danger' : 'success'"
      small
      hide-border
      :class="['_error-btn', hasErrors ? '-has-error' : '']"
      @click.stop="handleShowErrorClick"
    >
      <m-icon
        v-if="hasErrors"
        type="exclamation-circle-filled"
        size="16"
        :color="$colors.red.base"
      />
      <m-icon
        v-else
        type="check-mark-circle-filled"
        size="16"
        :color="$colors.green.base"
      />
      <span
        v-if="hasErrors"
        class="_error-message"
      >{{ Object.keys(errorMessages).length }}</span>
    </m-btn>
    <template
      #overlay
    >
      <m-card no-padding>
        <error-summary
          :errors="errorMessages"
          :goal="goal"
          :can-edit="canEdit"
          :update-property="updateProperty"
        />
      </m-card>
    </template>
  </m-dropdown>
</template>

<script setup>
import ErrorSummary from '@/components/rules/ErrorSummary.vue';
import { ref } from 'vue';

const props = defineProps({
  canEdit: {
    type: Boolean,
    default: false,
  },
  errorMessages: {
    type: Object,
    default: () => ({}),
  },
  hasErrors: {
    type: Boolean,
    default: false,
  },
  goal: {
    type: Object,
    required: true,
  },
  updateProperty: {
    type: Function,
    default: () => {},
  },
});

const emit = defineEmits(
  ['select'],
);

const showError = ref(false);

const handleShowErrorClick = () => {
  showError.value = true;
  emit('select', props.goal);
};
</script>

<style scoped lang="scss" type="text/scss">
.error-indicator-dropdown {
  ._error-btn {
    &.-has-error {
      background-color: map_get($red, 'lighten-5');
    }

    ._error-message {
      margin-left: .4rem;
      font-weight: 500;
    }
  }
}
</style>
