<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.67391 2.78905C9.67146 2.23332 10.1201 1.78219 10.6758 1.78464L18.7206 1.82009L18.7206 1.82L21.7378 1.83338L21.7511 4.85046L21.7865 12.8953C21.7889 13.451 21.3378 13.8996 20.782 13.8972L19.765 13.8927C19.2162 13.8903 18.7718 13.446 18.7694 12.8971L18.7428 6.84835L4.98464 20.6065C4.42676 21.1644 3.52227 21.1644 2.9644 20.6065C2.40653 20.0486 2.40653 19.1441 2.9644 18.5862L16.7223 4.82831L10.674 4.80166C10.1251 4.79924 9.68081 4.35491 9.67839 3.80607L9.67391 2.78905Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>

<script>
export default { name: 'Lookup' };
</script>

<style scoped lang="scss" type="text/scss">

</style>
