<template>
  <div
    class="factor-item"
    :data-id="item.uid"
  >
    <div
      v-if="draggingOverTop"
      class="_drag-over-top"
    />
    <div
      :class="['_inner', item.uid === selected.uid ? '-selected' : '', disabled ? '-disabled' : '']"
    >
      <div class="_title">
        {{ item.title[lang] }}
      </div>
      <div class="_amount">
        <span class="_number">
          {{ item.factorFields.length }}
        </span>
      </div>
      <div
        v-if="!disabled"
        class="_actions"
      >
        <div class="_action">
          <m-btn
            icon="edit"
            fab
            hide-border
            small
            @click.stop="$emit('edit')"
          />
        </div>
        <div class="_action">
          <m-btn
            icon="delete"
            hide-border
            fab
            small
            @click.stop="$emit('delete-item')"
          />
        </div>
      </div>
    </div>
    <div
      v-if="draggingOverBottom"
      class="_drag-over-bottom"
    />
  </div>
</template>

<script>
export default {
  name: 'FactorItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
    selected: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    lang: {
      type: String,
      required: true,
    },
    draggingOverTop: {
      type: Boolean,
      default: false,
    },
    draggingOverBottom: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['edit', 'delete-item'],
};
</script>

<style scoped lang="scss" type="text/scss">
  @import "shared/assets/scss/padding";

  .factor-item {
    position: relative;
    border-radius: $border-radius-sm;

    &:hover {
      &:not(.-drag) {
        background-color: $hover-color;
      }
    }

    ._drag-over-top {
      position: absolute;
      top: -2px;
      right: 0;
      left: 0;
      z-index: 88;
      width: 100%;
      height: 4px;
      pointer-events: none;
      background: $highlighted-color-dark;
      opacity: 1;
    }

    ._drag-over-bottom {
      position: absolute;
      right: 0;
      bottom: -2px;
      left: 0;
      z-index: 88;
      width: 100%;
      height: 4px;
      pointer-events: none;
      background: $highlighted-color-dark;
      opacity: 1;
    }

    ._inner {
      position: relative;
      display: flex;
      width: 100%;
      padding-top: .2rem;
      padding-bottom: .2rem;
      font-weight: $font-weight-medium;
      cursor: pointer;
      border-radius: $border-radius-sm;

      @include padding('small');

      ._title {
        max-width: calc(100% - 3rem);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      ._amount {
        width: 2rem;
        margin-left: auto;
        text-align: right;
      }

      ._actions {
        position: absolute;
        top: 1.5rem;
        right: .8rem;
        display: none;

        ._action {
          margin-left: .4rem;
        }
      }

      &.-selected {
        background-color: $selected-color;
      }

      &:not(.-selected) {
        ._amount {
          color: $font-color-secondary;
        }
      }

      &:hover {
        &:not(.-disabled) {
          ._amount {
            ._number {
              display: none;
            }
          }
        }

        ._actions {
          display: flex;
        }
      }
    }
  }
</style>
