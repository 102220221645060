<template>
  <grid-page-loading-error
    v-if="error !== null && !loading"
    @retry="init"
  />
  <div
    v-else-if="gridPageTileText === null"
    class="_spinner"
  >
    <m-spinner />
  </div>
  <m-content
    v-else
    :padding-top="gridPageTile.hideBorder ? -1 : 0"
    :padding-x="gridPageTile.hideBorder ? -1 : 9"
    :padding-bottom="gridPageTile.hideBorder ? -1 : 7"
    class="grid-page-tile-text"
  >
    <m-editor
      class="_m-editor"
      :initial-value="gridPageTileText.content"
      :allowed-content="allowedContent"
      light-placeholder
      :auto-focus="inlineEditing"
      :placeholder="$t('general.empty')"
      :disabled="props.readOnly"
      default-font-size="1.6rem"
      @input="updateContent"
      @blur="onBlur"
    />
  </m-content>
</template>

<script setup>
import GridPageLoadingError from '@/components/custom-grid/GridPageLoadingError.vue';
import MEditor from '@/components/editor/MEditor.vue';
import useAccess from '@/composables/access/access';
import useDebounce from '@/composables/debounce';
import useGridPage from '@/composables/grid-page/grid-page';
import useInlineEditing from '@/composables/inline-editing';
import useSnackBar from '@/composables/snackbar';
import { computed, ref } from 'vue';
import { editorNodeType, featureFlag } from 'shared/constants.json';
import { logCatch } from '@/lib/logger/logger';

const props = defineProps({
  gridPageTile: { type: Object, required: true },
  readOnly: { type: Boolean, default: false },
});

const gridPageSvc = useGridPage();
const snackbar = useSnackBar();
const debounceSvc = useDebounce();

const gridPageTileText = computed(() => {
  const res = gridPageSvc.gridPageTileText.value.find((textTile) => textTile.tile.uid === props.gridPageTile.uid);
  if (res === undefined) {
    return null;
  }

  return res;
});

const inlineEditingSvc = useInlineEditing();
const inlineEditing = inlineEditingSvc.isInlineEditing(props.gridPageTile.uid);

const updateContent = (content) => {
  if (JSON.stringify(content) === JSON.stringify(gridPageTileText.value.content)) {
    return;
  }
  const updateFn = () => gridPageSvc.updateGridPageTileText({ uid: gridPageTileText.value.uid, content }).catch(logCatch(() => {
    snackbar.error();
  }));
  debounceSvc.debounce(updateFn, 500);
};
const onBlur = () => {
  if (inlineEditing.value === true) {
    inlineEditingSvc.reset();
  }
};

const error = ref(null);
const loading = ref(false);
const init = () => {
  loading.value = true;
  error.value = null;
  gridPageSvc.queryGridPageTileTextByTile(props.gridPageTile)
    .catch(logCatch((e) => {
      error.value = e;
    }))
    .finally(() => {
      loading.value = false;
    });
};

init();

const allowedContent = [
  editorNodeType.codeBlock,
  editorNodeType.blockquote,
  editorNodeType.bulletList,
  editorNodeType.orderedList,
  editorNodeType.heading,

  editorNodeType.goalMention,
];

const { accountHasFeature } = useAccess();
if (accountHasFeature([featureFlag.fileUpload])) {
  allowedContent.push(editorNodeType.image, editorNodeType.file);
}

</script>

<style
  scoped
  lang="scss"
  type="text/scss"
>
._spinner {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.grid-page-tile-text {
  height: inherit;
  overflow: auto;

  ._m-editor {
    height: calc(100% - 1.2rem);
    max-height: calc(100% - 1.2rem);

    :deep(._editor) {
      height: 100%;

      [contenteditable="true"] {
        height: 100%;
      }
    }

    :deep(.ProseMirror) {
      :first-child {
        margin-top: 0;
      }

      h1 {
        font-size: 3rem;
      }

      h2 {
        font-size: $font-size-8;
      }

      h3 {
        font-size: $font-size-7;
      }
    }
  }

}
</style>
