<template>
  <div :class="['m-radio-group', inline ? '-inline' : '']">
    <slot v-if="customItems" />
    <m-radio
      v-for="option in options"
      v-else
      :key="option.value"
      :value="option.value"
      :selected="option.value === value"
      class="_option"
      @select="change"
    >
      {{ option.label }}
    </m-radio>
  </div>
</template>

<script>
export default {
  name: 'MRadioGroup',
  props: {
    value: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    customItems: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input', 'update:value', 'change'],
  methods: {
    change(value) {
      this.$emit('input', value);
      this.$emit('update:value', value);
      this.$emit('change', value);
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .m-radio-group {
    ._option {
      margin-bottom: 1rem;
    }

    &.-inline {
      display: flex;

      ._option {
        margin-right: 1rem;
        margin-bottom: 0;
      }
    }
  }
</style>
