<template>
  <div
    class="private-note"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <div
      v-if="privateNote.hidden"
      class="_hidden"
    >
      <m-btn
        hide-border
        small
        light
        @click="unhide"
      >
        {{ $t('privateNote.title') }}
      </m-btn>
    </div>
    <template v-else>
      <m-content
        padding-small
        class="_content"
      >
        <div
          v-show="hover || showActions"
          class="_actions"
        >
          <m-dropdown
            v-model:value="showActions"
            :title="$t('general.actions')"
          >
            <m-btn
              icon="drag"
              hide-border
              small
              light
              icon-size="20"
              fab
              :style="{ width: '2rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }"
              @click="showActions = true"
            />
            <template #overlay>
              <m-card

                list
                no-padding
              >
                <m-card-item
                  icon="eye-invisible"
                  @click="hide"
                >
                  {{ $t('privateNote.hide') }}
                </m-card-item>
                <m-card-item
                  icon="delete"
                  @click="deleteM"
                >
                  {{ $t('general.delete') }}
                </m-card-item>
              </m-card>
            </template>
          </m-dropdown>
        </div>
        <div class="_header">
          <div class="_icon">
            <m-icon type="eye-invisible" />
          </div>
          <div class="_note">
            {{ $t('privateNote.hint') }}
          </div>
        </div>
        <m-editor
          :initial-value="initialValue"
          :placeholder="$t('meetingPage.startTyping')"
          :allowed-content="allowedContent"
          @input="updateContent"
        />
      </m-content>
    </template>
  </div>
</template>

<script>
import MEditor from '@/components/editor/MEditor.vue';
import deleteMixin from '@/mixins/delete';
import useAccess from '@/composables/access/access';
import useDebounce from '@/composables/debounce';
import { editorNodeType, featureFlag } from 'shared/constants.json';
import { mapActions } from 'vuex';
import { children as privateNoteChildren } from '@/api/query/private-note';
import { privateNote as privateNoteConfig } from 'shared/api/query/configs.json';

export default {
  name: 'PrivateNote',
  props: {
    privateNote: {
      type: Object,
      required: true,
    },
  },
  components: { MEditor },
  setup() {
    const allowedContent = [
      editorNodeType.heading,
      editorNodeType.codeBlock,
      editorNodeType.blockquote,
      editorNodeType.bulletList,
      editorNodeType.orderedList,
      editorNodeType.goalMention,
    ];

    const { accountHasFeature } = useAccess();
    if (accountHasFeature([featureFlag.fileUpload])) {
      allowedContent.push(editorNodeType.image, editorNodeType.file);
    }

    const { debounce } = useDebounce();
    return { debounce, allowedContent };
  },
  data() {
    return {
      showActions: false,
      hover: false,
    };
  },
  computed: {
    initialValue() {
      if (this.privateNote === null) {
        return null;
      }

      return this.privateNote.content;
    },
  },
  methods: {
    ...mapActions(['updateEntity']),
    save(entity) {
      this.updateEntity({
        entity,
        model: privateNoteConfig.model,
        entityById: () => [{ children: privateNoteChildren }],
      }).then((response) => {
        if (response.status !== 200) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
        }
      });
    },
    updateContent(content) {
      const update = () => {
        this.save({ ...this.privateNote, content });
      };

      this.debounce(update, 1000);
    },
    hide() {
      this.save({ ...this.privateNote, hidden: true });
      this.showActions = false;
    },
    unhide() {
      this.save({ ...this.privateNote, hidden: false });
    },
    deleteM() {
      this.showDeleteModal({
        entities: [this.privateNote],
        model: privateNoteConfig.model,
      })();
    },
  },
  mixins: [deleteMixin],
};
</script>

<style scoped lang="scss" type="text/scss">
  .private-note {
    padding: 0 10rem;
    margin: 0 -10rem;

    ._content {
      position: relative;
      background-color: map_get($yellow, 'lighten-4');
      border-radius: $border-radius-sm;

      ._actions {
        position: absolute;
        top: .3rem;
        left: -2.4rem;
      }

      ._header {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
        font-size: $font-size-2;
        color: $font-color-secondary;

        ._icon {
          margin-right: .6rem;
        }
      }
    }
  }
</style>
