<template>
  <div
    :style="topBarStyle"
    class="top-bar"
  >
    <div class="_column -left">
      <slot name="left" />
      <div
        v-if="!$slots.left"
        class="_left"
      >
        <div class="_btn">
          <m-btn
            v-if="backTo !== ''"
            :to="backTo"
            fab
            hide-border
            icon="arrow-left"
          />
        </div>
        <div
          v-if="title !== ''"
          class="_title"
        >
          {{ title }}
        </div>
        <router-link
          v-if="showLogo"
          class="_branding"
          to="/"
        >
          <img
            src="@/assets/img/moon-icon.svg"
            alt="Mooncamp Icon"
          >
        </router-link>
      </div>
    </div>
    <div class="_column -center">
      <slot name="center" />
    </div>
    <div class="_column -right">
      <slot name="right" />
    </div>
  </div>
</template>

<script>

export default {
  name: 'TopBar',
  props: {
    backTo: {
      type: [Object, String],
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    hideLogo: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showLogo() {
      return this.backTo === '' && this.title === '' && !this.hideLogo;
    },
    topBarStyle() {
      return {
        height: '5.3rem', // we need to make all our variables in js available as well
        'background-color': 'white',
        display: 'flex',
        'align-items': 'center',
        padding: 0,
      };
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .top-bar {
    border-bottom: .1rem solid $border-color;

    .m-toolbar__content {
      ._center {
        margin: 0 auto;
      }
    }

    ._column {
      flex: 0 0 33%;
      align-items: center;

      ._left {
        display: flex;
        align-items: center;
        font-weight: $font-weight-medium;

        ._btn {
          display: flex;
          align-items: center;
          justify-content: center;
          width: $side-bar-collapsed-width;
          height: $side-bar-collapsed-width;
          padding: .85rem;
        }

        ._branding {
          display: flex;
          align-items: center;
          justify-content: center;
          width: $side-bar-collapsed-width;

          img {
            width: 3.2rem;
          }
        }

        ._title {
          margin-left: 1.6rem;
        }
      }

      &.-center {
        display: flex;
        justify-content: center;
        white-space: nowrap;
      }

      &.-right {
        display: flex;
        flex: 0 0 34%;
        justify-content: flex-end;

        ._divider {
          display: flex;
          width: .1rem;
          height: 4rem;
          margin: .7rem 1rem .7rem 2rem;
          background-color: $border-color;
        }
      }
    }
  }
</style>
