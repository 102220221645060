<template>
  <div class="field-factors">
    <div class="_left">
      <m-content
        v-if="factors.length === 0"
        padding
      >
        <m-alert
          type="info"
          class="_empty"
          :message="$t('fieldFactors.empty')"
        />
      </m-content>
      <factor-list
        v-else
        :factors="factors"
        :lang="lang"
        :disabled="disabled"
        :selected="currentFactor"
        :model="model"
        @select="select"
        @edit="edit"
        @delete="deleteItem"
      />
      <m-btn
        v-if="!disabled"
        icon="plus"
        hide-border
        class="_btn"
        @click="createModalVisible = true"
      >
        {{ $t('fieldFactors.addFactor') }}
      </m-btn>
      <m-dialog
        v-model:value="createModalVisible"
        :title="$t('fieldFactors.addTitle')"
        hide-footer
        no-padding
      >
        <factor-form
          :factor="null"
          :languages="languages"
          hide-footer
          :entity="entity"
          :model="model"
          :entity-by-id="entityById"
          @created="createModalVisible = false"
          @cancel="createModalVisible = false"
        />
      </m-dialog>
      <m-dialog
        v-model:value="editModalVisible"
        :title="$t('fieldFactors.editTitle')"
        hide-footer
        no-padding
      >
        <factor-form
          :factor="editEntity"
          :languages="languages"
          hide-footer
          :entity="entity"
          :model="model"
          :entity-by-id="entityById"
          @cancel="editModalVisible = false"
          @updated="editModalVisible = false"
        />
      </m-dialog>
    </div>
    <div class="_right">
      <m-content
        v-if="currentFactor.uid === 0"
        padding
        class="_empty-item"
      >
        {{ $t('fieldFactors.nonSelected') }}
      </m-content>
      <factor-details
        v-else
        :key="currentFactor.uid"
        :factor="currentFactor"
        :lang="lang"
        :entity="entity"
        :model="model"
        :entity-by-id="entityById"
        :disabled="disabled"
      />
    </div>
  </div>
</template>

<script>
import FactorDetails from '@/components/FactorDetails.vue';
import FactorForm from '@/components/FactorForm.vue';
import FactorList from '@/components/FactorList.vue';
import { DateTime } from 'luxon';
import { mapActions } from 'vuex';

export default {
  name: 'FieldFactors',
  props: {
    model: {
      type: String,
      required: true,
    },
    entity: {
      type: Object,
      required: true,
    },
    entityById: {
      type: Function,
      required: true,
    },
    languages: {
      type: Array,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  components: { FactorList, FactorDetails, FactorForm },
  data() {
    return {
      createModalVisible: false,
      editModalVisible: false,
      editEntity: null,
      loading: false,
      selectedFactor: { uid: 0 },
    };
  },
  computed: {
    factors() {
      return this.entity.formFactors;
    },
    currentFactor() {
      const filtered = this.factors.filter((f) => f.uid === this.selectedFactor.uid);
      if (filtered.length !== 1) {
        return { uid: 0 };
      }

      return filtered[0];
    },
  },
  methods: {
    ...mapActions([
      'updateEntity',
    ]),
    select(item) {
      this.selectedFactor = item;
    },
    edit(item) {
      this.editEntity = item;
      this.editModalVisible = true;
    },
    deleteItem(item) {
      const deleteMethod = this.deleteFactor;
      this.$confirm({
        title: this.$t('fieldFactors.prompt'),
        okText: this.$t('general.yesDelete'),
        okType: 'danger',
        maskClosable: true,
        cancelText: this.$t('general.cancel'),
        onOk() {
          deleteMethod(item);
        },
      });
    },
    deleteFactor(item) {
      this.$store.commit('FORM_FACTOR_UPDATED', {
        factor: {
          ...item,
          deletedAt: DateTime.local().toISO(),
          factorFields: item.factorFields.map((f) => ({ ...f, deletedAt: DateTime.local().toISO() })),
        },
        model: this.model,
      });
      this.loading = true;
      this.updateEntity({ entity: this.entity, model: this.model, entityById: this.entityById }).then((response) => {
        this.loading = false;
        if (response.status !== 200) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
          return;
        }

        this.$showSnackbar({ color: 'success', message: this.$t('success.deleted') });
      });
    },
  },
  mounted() {
    if (this.factors.length > 0) {
      this.selectedFactor = this.factors[0];
    }
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .field-factors {
    display: flex;
    width: 100%;

    ._left {
      flex: 1 1 30%;
      max-width: calc(50vw - 5.3rem - 30vw);
      background-color: white;
      border-right: 1px solid $border-color;
    }

    ._right {
      flex: 0 0 30vw;
      max-width: 30vw;
      border-right: 1px solid $border-color;
    }

    ._btn {
      margin-left: .8rem;
    }
  }
</style>
