<template>
  <not-found-page
    v-if="!fetchUpdateLoading && update === null"
    :to="{ name: UPDATES_EXPLORER }"
    :title="$t('notFoundPage.title', {type: $t('updateDetails.update')})"
    :anchor-text=" $t('notFoundPage.buttonText', {types: $t('updateDetails.updates')})"
  />
  <page-layout
    v-else
    :class="['update-details', $store.state.breakpoint.smAndDown ? '-mobile' : '']"
    :loading="fetchUpdateLoading"
  >
    <template #topBar>
      <page-top-bar
        :breadcrumbs="breadcrumbs"
        class="_header"
      />
    </template>
    <scroll-container>
      <m-content
        padding-x="layout"
        class="_inner"
      >
        <update-feed-item
          :update="update"
          class="_item"
          :route-after-delete="{ name: UPDATES_EXPLORER }"
          padding
        />
      </m-content>
    </scroll-container>
  </page-layout>
</template>

<script>
import NotFoundPage from '@/components/NotFoundPage.vue';
import PageLayout from '@/components/page/PageLayout.vue';
import PageTopBar from '@/components/page/PageTopBar.vue';
import ScrollContainer from '@/components/page/ScrollContainer.vue';
import UpdateFeedItem from '@/components/updates/UpdateFeedItem.vue';
import useUpdates from '@/composables/updates/updates';
import { DateTime } from 'luxon';
import { UPDATES_EXPLORER } from '@/route-names';
import { computed } from 'vue';
import { findInArray } from 'shared/lib/array/array';
import { useRoute } from 'vue-router';

export default {
  name: 'UpdateDetails',
  components: {
    ScrollContainer,
    NotFoundPage,
    PageLayout,
    PageTopBar,
    UpdateFeedItem,
  },
  setup() {
    const route = useRoute();
    const updateId = computed(() => parseInt(route.params.id, 10));

    const {
      updates,
      fetchUpdate,
      fetchUpdateLoading,
    } = useUpdates();

    return {
      updates,
      fetchUpdate,
      fetchUpdateLoading,

      updateId,
    };
  },
  data() {
    return { UPDATES_EXPLORER };
  },
  computed: {
    update() {
      return findInArray({ haystack: this.updates, needle: this.updateId });
    },
    breadcrumbs() {
      return [
        {
          title: this.$t('navigation.updatesExplorer'),
          to: { name: UPDATES_EXPLORER },
        },
        {
          title: DateTime.fromISO(this.update.createdAt).toLocaleString(DateTime.DATETIME_MED),
          disabled: true,
        },
      ];
    },
  },
  created() {
    this.fetchUpdate(this.updateId).catch(() => {
      this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
    });
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .update-details {
    ._inner {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 4rem;

      ._item {
        width: 60rem;
        max-width: 100vw;
      }
    }
  }
</style>
