import { Teleport, h, ref } from 'vue';

export default {

  props: {
    editor: {
      default: null,
      type: Object,
    },
  },

  data() {
    return {
      rootEl: ref(),
      nodeViews: [],
    };
  },

  watch: {
    editor: {
      immediate: true,
      handler(editor) {
        if (editor && editor.element) {
          this.$nextTick(() => {
            this.$el.appendChild(editor.element.firstChild);
            editor.setParentComponent(this);
          });
        }
      },
    },
  },

  render() {
    let vueRenderers = [];
    if (this.editor) {
      vueRenderers = this.nodeViews.map((nv) => h(
        Teleport,
        {
          to: nv.teleportElement,
          key: nv.id,
        },
        h(
          nv.component,
          {
            ref: nv.id,
            ...nv.props,
          },
        ),
      ));
    }

    return h(
      'div',
      { ref: (el) => { this.rootEl = el; } },
      ...vueRenderers,
    );
  },

  beforeUnmount() {
    this.editor.element = this.$el;
  },

};
