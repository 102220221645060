import { NOT, TYPE, UID, VAR } from 'shared/api/query/constants';

export const reverseFn = (model, varIdFn) => (scope, index) => {
  const varName = varIdFn(index);

  const varBlocks = [
    {
      alias: VAR,
      model: scope.reverseProperty.model,
      func: { name: TYPE, args: [{ value: scope.reverseProperty.model, typ: 1 }] },
      children: [
        {
          attr: scope.reverseProperty.edgeName,
          model,
          var: varName,
        },
      ],
    },
  ];

  const filterTree = {
    func: {
      name: UID,
      needsVar: [{ name: varName, typ: 1 }],
    },
  };

  if (scope.isEmpty === true) {
    return {
      filterTrees: [{
        op: NOT,
        children: [filterTree],
      }],
      varBlocks,
    };
  }

  return { filterTrees: [filterTree], varBlocks };
};
