import useAccess from '@/composables/access/access';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useRepo from '@/nebula/repo';
import { companyInfo as companyInfoConfig } from 'shared/api/query/configs.json';
import { computed } from 'vue';
import { featureFlag } from 'shared/constants.json';
import { useI18n } from 'vue-i18n';

export default function useCompanyInfo() {
  const { t } = useI18n();
  const repo = useRepo(companyInfoConfig.model);

  const { accountHasFeature } = useAccess();

  const { loggedInUserAccount } = useLoggedInUserAccount();

  const companyInfo = computed(() => repo.selectSingle(loggedInUserAccount.value.companyInfo?.uid));
  const showCompanyInfoInMenu = computed(() => accountHasFeature([featureFlag.companyInfo]) && companyInfo.value.showInHelpArea);
  const showCompanyInfoInOnboarding = computed(() => accountHasFeature([featureFlag.companyInfo]) && companyInfo.value.onboardingStep);

  const companyInfoTitle = computed(() => {
    if (companyInfo.value.title === '') {
      return t('helpButton.companyInfo.emptyTitle');
    }

    return companyInfo.value.title;
  });

  return {
    showCompanyInfoInMenu,
    showCompanyInfoInOnboarding,
    companyInfoTitle,
    companyInfo,
    updateCompanyInfo: repo.updateSingle,
  };
}
