import { UID } from 'shared/api/query/constants';
import { accountSettings } from 'shared/api/query/configs.json';

export const children = [
  { attr: UID },
  { attr: accountSettings.edges.planId, default: '' },
  { attr: accountSettings.edges.status },
  { attr: accountSettings.edges.expiresAt },
  { attr: accountSettings.edges.syncDays, default: [] },
  { attr: accountSettings.edges.vision, default: '' },

  { attr: accountSettings.edges.usesSurveys, default: false },
  { attr: accountSettings.edges.usesGoals, default: false },
  { attr: accountSettings.edges.usesPlannings, default: false },
  { attr: accountSettings.edges.usesUpdates, default: false },
  { attr: accountSettings.edges.usesReviews, default: false },
  { attr: accountSettings.edges.usesMeetings, default: false },
  { attr: accountSettings.edges.usesInstantFeedback, default: false },

  { attr: accountSettings.edges.usesSlack, default: false },
  { attr: accountSettings.edges.usesMicrosoftBot, default: false },
  { attr: accountSettings.edges.usesSheets, default: false },
  { attr: accountSettings.edges.usesExcel, default: false },
  { attr: accountSettings.edges.usesAsana, default: false },
  { attr: accountSettings.edges.usesHubspot, default: false },
  { attr: accountSettings.edges.usesSalesforce, default: false },
  { attr: accountSettings.edges.usesJira, default: false },
  { attr: accountSettings.edges.usesMsCalendar, default: false },

  { attr: accountSettings.edges.usesSaml, default: false },
  { attr: accountSettings.edges.usesUserProvisioning, default: false },
  { attr: accountSettings.edges.usesAdvancedOKRRules, default: false },
  { attr: accountSettings.edges.usesMultiGoalAlignment, default: false },
  { attr: accountSettings.edges.usesWeightedGoals, default: false },
  { attr: accountSettings.edges.usesPublishedViews, default: false },
  { attr: accountSettings.edges.usesGoalTree, default: false },
  { attr: accountSettings.edges.usesFileUpload, default: false },
  { attr: accountSettings.edges.usesCompanyInfo, default: false },
  { attr: accountSettings.edges.usesHealthDashboard, default: false },
  { attr: accountSettings.edges.volumePrivateDashboards, default: 0 },
  { attr: accountSettings.edges.volumePublicDashboards, default: 0 },
  { attr: accountSettings.edges.restrictPasswordLogin, default: false },
  { attr: accountSettings.edges.restrictMicrosoftLogin, default: false },
  { attr: accountSettings.edges.restrictGoogleLogin, default: false },
  { attr: accountSettings.edges.receivesInAppFeedback, default: false },
  { attr: accountSettings.edges.usesBillingPortal, default: false },

  { attr: accountSettings.edges.canGroupByUser, default: false },

  { attr: accountSettings.edges.accountInfosCompleted, default: null },
  { attr: accountSettings.edges.moduleSelectionCompleted, default: null },

  { attr: accountSettings.edges.onboardingStepCheckinChecked, default: false },
  { attr: accountSettings.edges.onboardingStepDeleteDemoData, default: false },
  { attr: accountSettings.edges.onboardingStepInviteOthers, default: false },
];
