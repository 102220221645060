import { Hour } from 'shared/lib/time';
import { RRule } from 'rrule';
import {
  distributionChannel as dcConfig,
  mailProperties as mpConfig,
  surveyProcess as spConfig,
} from 'shared/api/query/configs.json';
import { distributionChannelType, userScopeOperator } from 'shared/constants.json';
import { frontendRRuleToBackend } from '@/lib/rrule';

export const defaultRRule = () => {
  const now = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(now.getDate() + 1);

  const rRule = new RRule({
    dtstart: new Date(Date.UTC(
      tomorrow.getUTCFullYear(),
      tomorrow.getUTCMonth(),
      tomorrow.getUTCDate(),
      7,
      0,
      0,
      0,
    )),
    freq: RRule.WEEKLY,
    interval: 1,
    count: 1,
    byminute: 0,
    bysecond: 0,
    byhour: 7,
  });
  return frontendRRuleToBackend(rRule);
};

export const defaultSurveyProcess = ({ account, languages, form }) => {
  const subject = {};
  const body = {};
  languages.forEach((l) => {
    subject[l] = form.title;
    body[l] = 'Dear colleagues, \n\nplease take a moment to answer our company survey. \n\nThank you!';
  });
  body.de = 'Liebe Kolleginnen und Kollegen, \n\nbitte nehmt euch einen Moment Zeit zur Beantwortung unserer Umfrage. \n\nVielen Dank!';
  return {
    form,
    schedule: defaultRRule(),
    remindAfter: 24 * Hour,
    reminderCount: 0,
    userScopeTree: { account, op: userScopeOperator.and },
    [spConfig.edges.distributionChannels]: [
      {
        [dcConfig.edges.type]: distributionChannelType.mail,
        [dcConfig.edges.mailProperties]: {
          [mpConfig.edges.subject]: subject,
          [mpConfig.edges.body]: body,
        },
      },
      {
        [dcConfig.edges.type]: distributionChannelType.slack,
        [dcConfig.edges.mailProperties]: {
          [mpConfig.edges.subject]: subject,
          [mpConfig.edges.body]: body,
        },
      },
    ],
  };
};
