<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 20.9585C5 21.5301 5.4634 21.9935 6.03503 21.9935H17.965C18.5366 21.9935 19 21.5301 19 20.9585C19 20.3869 18.5366 19.9235 17.965 19.9235H6.03503C5.4634 19.9235 5 20.3869 5 20.9585Z"
      fill="currentColor"
    />
    <path
      d="M10.8337 3.49255L10.8337 18.3438H13.167V3.49255H10.8337Z"
      fill="currentColor"
    />
    <path
      d="M5.71618 8.23874C5.32149 8.62997 5.32181 9.26763 5.7165 9.65886L6.04603 9.98549C6.43583 10.3719 7.0642 10.3719 7.454 9.98549L13.7501 3.74466C13.7501 3.74466 12.6835 2.68743 12.0001 2.01C9.9533 4.0388 7.04738 6.91922 5.71618 8.23874Z"
      fill="currentColor"
    />
    <path
      d="M16.546 9.98549C16.9358 10.3719 17.5642 10.3719 17.954 9.9855L18.2835 9.65885C18.6782 9.26763 18.6785 8.62997 18.2838 8.23874C16.9527 6.91923 14.0468 4.0388 12.0001 2.01C11.3166 2.68743 10.2499 3.74466 10.2499 3.74466L16.546 9.98549Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default { name: 'AboveOrEqual' };
</script>

<style scoped lang="scss" type="text/scss">

</style>
