<template>
  <div class="form-analytic-access">
    <m-content padding-small>
      <p>{{ $t('analyticAccess.description') }}</p>
      <account-scope-filter
        v-if="readScope !== null"
        v-model:value="readScope"
        :props="userProps"
        :account="loggedInUserAccount"
        show-static-user-selection
        :static-users-max-tag-text-length="10"
        class="_filter"
      />
      <m-divider />
      <div class="_section">
        <m-checkbox
          :label="$t('analyticAccess.notify')"
          :value="notify"
          @change="updateNotify"
        />

        <m-textarea
          v-model:value="message"
          :placeholder="$t('analyticAccess.personalMessage')"
        />
      </div>

      <m-form-item :colon="false">
        <template #label>
          <div
            class="_label"
          >
            <div class="_text">
              {{ $t('analyticAccess.link') }}
            </div>
            <m-tooltip trigger="click">
              <span><m-icon type="info-circle" /></span>
              <template #title>
                {{ $t('analyticAccess.linkInfo') }}
              </template>
            </m-tooltip>
          </div>
        </template>

        <div class="_input">
          <m-text-field
            class="_link"
            :value="link"
            :clearable="false"
          />
          <m-link
            v-clipboard:copy="link"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          >
            {{ $t('analyticAccess.copyBtn') }}
          </m-link>
        </div>
      </m-form-item>

      <user-scope-tree-user-list
        v-show="readScope !== null"
        :user-scope-tree="readScope"
      />
    </m-content>

    <m-default-form-actions
      :clickable="scopeChanged"
      :loading="loading"
      @cancel="cancel"
      @submit="update"
    />
  </div>
</template>

<script>
import AccountScopeFilter from '@/components/filter/AccountScopeFilter.vue';
import UserScopeTreeUserList from '@/components/UserScopeTreeUserList.vue';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useProperties from '@/composables/property/property';
import { copy } from 'shared/lib/copy';
import { createProp } from '@/lib/props';
import { isEqual } from 'lodash-es';
import { mapActions, mapState } from 'vuex';
import { user as userConfig } from 'shared/api/query/configs.json';

export default {
  name: 'FormAnalyticAccess',
  components: { AccountScopeFilter, UserScopeTreeUserList },
  setup() {
    const { userProperties } = useProperties();
    const { loggedInUser, userLang } = useLoggedInUser();
    const { loggedInUserAccount } = useLoggedInUserAccount();
    return { userProperties, loggedInUser, userLang, loggedInUserAccount };
  },
  data() {
    return { readScope: null, notify: true, message: '', loading: false };
  },
  emits: ['cancel', 'submit'],
  computed: {
    ...mapState({ form: (state) => state.form }),
    userProps() {
      return this.userProperties.map((p) => createProp(p, false, true, true, this.userLang, userConfig.model));
    },
    scopeChanged() {
      return !isEqual(this.readScope, this.form.analyticAccess.readScope);
    },
    link() {
      return `${window.location.origin}/#/surveys/${this.form.uid}/results`;
    },
  },
  methods: {
    ...mapActions(['updateFormAnalyticAccess']),
    onCopy() {
      this.$showSnackbar({
        color: 'success',
        message: this.$t('analyticAccess.copySuccess'),
      });
    },
    onError() {
      this.$showSnackbar({
        color: 'error',
        message: this.$t('analyticAccess.copyError'),
      });
    },
    copy(obj) {
      return copy(obj);
    },
    cancel() {
      this.readScope = this.copy(this.form.analyticAccess.readScope);
      this.$emit('cancel');
    },
    updateNotify(val) {
      this.notify = val;
    },
    update() {
      this.loading = true;
      const request = {
        form: {
          uid: this.form.uid,
          analyticAccess: { ...this.form.analyticAccess, readScope: this.readScope },
        },
        hookParameter: { sendAnalyticAccessNotification: this.notify, updateAnalyticMessage: this.message },
      };

      this.updateFormAnalyticAccess(request).then((response) => {
        this.loading = false;
        if (response.status !== 200) {
          this.$showSnackbar({
            color: 'error',
            message: this.$t('error.default'),
          });
          return;
        }
        this.readScope = this.copy(this.form.analyticAccess.readScope);
        this.$emit('submit');
      });
    },
  },
  mounted() {
    this.readScope = copy(this.form.analyticAccess.readScope);
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .form-analytic-access {
    ._input {
      ._link {
        display: inline;
        max-width: 70%;
        margin-right: 1.2rem;
      }
    }

    ._label {
      display: flex;
      align-items: center;
      margin-bottom: .8rem;
      line-height: 0;

      ._text {
        margin-right: .4rem;
      }
    }

    ._section {
      margin-bottom: 2.4rem;
    }
  }
</style>
