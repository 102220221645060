<template>
  <m-dropdown
    v-model:value="show"
    :title="$t('general.actions')"
    placement="bottomCenter"
    class="jira-jql-editor"
  >
    <div
      class="_jql"
      @click="show = true"
    >
      <div
        v-if="jql === ''"
        class="_placeholder"
      >
        {{ $t('jiraJqlEditor.placeholder') }}
      </div>
      <div
        v-else
        class="_content"
      >
        {{ jql }}
      </div>
      <div class="_link">
        <m-btn
          target="_blank"
          xs
          light
          :href="jqlLink({ dataSource }, jql)"
        >
          {{ $t('jiraForm.openInJira') }}
        </m-btn>
      </div>
    </div>
    <template #overlay>
      <m-card
        :key="show"
        class="_overlay"
        no-padding
      >
        <m-textarea
          :value="jql"
          auto-focus
          @change="$emit('change', $event)"
        />
      </m-card>
    </template>
  </m-dropdown>
</template>

<script>
import { jqlLink } from '@/lib/jira';

export default {
  name: 'JiraJqlEditor',
  props: {
    jql: {
      type: String,
      required: true,
    },
    dataSource: {
      type: Object,
      required: true,
    },
  },
  emits: ['change'],
  data() {
    return {
      show: false,
      jqlLink,
    };
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .jira-jql-editor {
    ._jql {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      height: $small-input-height;
      min-height: 3.2rem;
      padding: .4rem 1.1rem;
      overflow: hidden;
      white-space: nowrap;
      cursor: pointer;
      border: 1px solid $border-color;
      border-radius: $default-border-radius;

      ._link {
        position: absolute;
        top: .4rem;
        right: .4rem;
        display: none;
        background-color: white;
      }

      &:hover {
        border-color: $primary-color;

        ._link {
          display: flex;
        }
      }

      ._placeholder {
        color: $font-color-tertiary;
      }
    }
  }

  ._overlay {
    width: 40rem;
  }
</style>
