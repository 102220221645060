<template>
  <m-dialog
    :value="showGoalModal"
    :max-width="$modalSizes.xl"
    hide-footer
    no-padding
    keep-height
    hide-header
    top="7rem"
    :body-class="bodyClass"
    @close="closeGoalModal"
  >
    <goal-page
      ref="goalPage"
      :key="goalId"
      :goal-id="goalId"
      :route-after-delete="routeWithoutGoalId"
      open-in-modal
      :open-children-in-modal="openChildrenInModal"
      :goal-modifiers="modifiers"
      :scroll-container="bodyClass"
      :context="context"
      @close="closeGoalModal"
      @open="goToGoalDetail"
    />
  </m-dialog>
</template>

<script>

import useGoalDetailsCtx from '@/composables/goal/goal-details-context';
import useOpenPeekMode from '@/composables/goal/open-peek-mode';
import usePlanningDetailsCtx from '@/composables/planning/planning-details-context';
import usePublishedViewDetailsCtx from '@/composables/published-view/published-view-details-context';
import { CASCADE_CONTEXT_PLANNING, CASCADE_CONTEXT_PUBLISHED_VIEW } from '@/lib/constants';
import { defineAsyncComponent } from 'vue';
import { getQueryParamAsInt } from '@/lib/route';

export default {
  name: 'GoalDetailsDialog',
  setup() {
    const peekModeSvc = useOpenPeekMode();
    const { inCtx: inPlanningDetailsCtx, goalModifiers: planningDetailsCtxModifiers } = usePlanningDetailsCtx();
    const { inCtx: inGoalDetailsCtx, goalModifiers: goalDetailsCtxModifiers } = useGoalDetailsCtx();
    const { inCtx: inPublishedViewDetailsCtx } = usePublishedViewDetailsCtx();
    return {
      peekModeSvc,
      inPlanningDetailsCtx,
      planningDetailsCtxModifiers,
      inGoalDetailsCtx,
      goalDetailsCtxModifiers,
      inPublishedViewDetailsCtx,
    };
  },
  components: { GoalPage: defineAsyncComponent(() => import('@/components/goal/GoalPage.vue')) },
  data() {
    return { bodyClass: '_goal-details-dialog-body' };
  },
  computed: {
    openChildrenInModal() {
      return this.inPublishedViewDetailsCtx;
    },
    showGoalModal() {
      return this.$route.query.goalId !== undefined;
    },
    goalId() {
      return getQueryParamAsInt(this.$route, 'goalId');
    },
    routeWithoutGoalId() {
      const query = { ...this.$route.query };
      delete query.goalId;
      return { ...this.$route, query };
    },
    context() {
      if (this.inPlanningDetailsCtx) {
        return CASCADE_CONTEXT_PLANNING;
      }
      if (this.inPublishedViewDetailsCtx) {
        return CASCADE_CONTEXT_PUBLISHED_VIEW;
      }
      return '';
    },
    modifiers() {
      if (this.inPlanningDetailsCtx) {
        return this.planningDetailsCtxModifiers;
      }
      if (this.inGoalDetailsCtx) {
        return this.goalDetailsCtxModifiers;
      }
      return [];
    },
  },
  methods: {
    closeGoalModal() {
      if (this.showGoalModal) {
        this.$refs.goalPage.checkToDeleteEmpty(() => {
          this.$router.push(this.routeWithoutGoalId);
        });
      }
    },
    goToGoalDetail(goal, modal = false) {
      this.peekModeSvc.openGoal({ goalId: goal.uid, modal });
    },
  },
};
</script>
