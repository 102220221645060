import {
  AND,
  DEFAULT_ATTRIBUTES,
  EQ,
  RESULT,
  TYPE, UID, VAR,
} from 'shared/api/query/constants';
import {
  account,
  factorField,
  field,
  formFactor,
  formFieldValidation,
  formOrderItem,
  formTemplate,
  formTemplateTag,
  opinionScaleProperties,
  user,
  welcomeScreen,
} from 'shared/api/query/configs.json';
import { combine, hasFilter } from 'shared/api/query/filter';
import { reverseEdge } from 'shared/api/query/edges';

export const formTemplateChildren = [
  ...DEFAULT_ATTRIBUTES,
  { attr: formTemplate.edges.title },
  { attr: formTemplate.edges.icon, default: '' },
  { attr: formTemplate.edges.type },
  { attr: formTemplate.edges.releasedAt, default: null },
  {
    attr: formTemplate.edges.account,
    model: account.model,
    children: [
      { attr: UID },
      { attr: account.edges.companyName },
    ],
  },
  {
    attr: formTemplate.edges.orderItems,
    model: formOrderItem.model,
    order: [
      {
        attr: formOrderItem.edges.value,
        desc: false,
      },
    ],
    default: [],
    children: [
      ...DEFAULT_ATTRIBUTES,
      { attr: formOrderItem.edges.value },
      {
        attr: formOrderItem.edges.field,
        model: field.model,
        children: [
          ...DEFAULT_ATTRIBUTES,
          { attr: field.edges.title },
          { attr: field.edges.type },
          { attr: field.edges.allowComment },
          {
            attr: field.edges.opinionScaleProperties,
            model: opinionScaleProperties.model,
            children: [
              ...DEFAULT_ATTRIBUTES,
              { attr: opinionScaleProperties.edges.steps },
              { attr: opinionScaleProperties.edges.startsFromZero },
              { attr: opinionScaleProperties.edges.labelLeft, default: { en: '' } },
              { attr: opinionScaleProperties.edges.labelCenter, default: { en: '' } },
              { attr: opinionScaleProperties.edges.labelRight, default: { en: '' } },
            ],
          },
          {
            attr: field.edges.validations,
            model: formFieldValidation.model,
            children: [
              ...DEFAULT_ATTRIBUTES,
              { attr: formFieldValidation.edges.required },
            ],
          },
        ],
      },
    ],
  },
  {
    attr: formTemplate.edges.formFactors,
    model: formFactor.model,
    default: [],
    order: [
      {
        attr: formFactor.edges.order,
        desc: false,
      },
    ],
    children: [
      ...DEFAULT_ATTRIBUTES,
      { attr: formFactor.edges.title },
      { attr: formFactor.edges.order, default: 0 },
      {
        attr: formFactor.edges.factorFields,
        model: factorField.model,
        default: [],
        children: [
          ...DEFAULT_ATTRIBUTES,
          { attr: factorField.edges.weight },
          {
            attr: factorField.edges.field,
            model: field.model,
            children: [
              ...DEFAULT_ATTRIBUTES,
              { attr: field.edges.title },
              { attr: field.edges.type },
              { attr: field.edges.allowComment },
            ],
          },
        ],
      },
    ],
  },
  {
    model: welcomeScreen.model,
    attr: formTemplate.edges.welcomeScreen,
    default: null,
    children: [
      ...DEFAULT_ATTRIBUTES,
      { attr: welcomeScreen.edges.title },
      { attr: welcomeScreen.edges.description },
    ],
  },
  {
    attr: formTemplate.edges.tags,
    model: formTemplateTag.model,
    default: [],
    children: [
      ...DEFAULT_ATTRIBUTES,
      { attr: formTemplateTag.edges.name },
    ],
  },
  {
    attr: formTemplate.edges.editors,
    model: user.model,
    default: [],
    children: [
      { attr: UID },
      { attr: user.edges.firstName },
      { attr: user.edges.lastName },
      { attr: user.edges.email },
    ],
  },
];

export const formTemplateById = (id) => [
  {
    uid: [id],
    alias: RESULT,
    func: { name: UID },
    model: formTemplate.model,
    children: formTemplateChildren,
  },
];

export const releasedFormTemplates = ({ type }) => () => [
  {
    model: formTemplate.model,
    alias: RESULT,
    func: { name: TYPE, args: [{ value: formTemplate.model }] },
    default: [],
    filter: combine(AND, [
      hasFilter(formTemplate.edges.releasedAt),
      {
        func: {
          name: EQ,
          attr: formTemplate.edges.type,
          args: [{ value: type }],
        },
      },
    ]),
    children: formTemplateChildren,
  },
];

export const formTemplateList = () => [
  {
    model: formTemplate.model,
    alias: RESULT,
    func: { name: TYPE, args: [{ value: formTemplate.model }] },
    default: [],
    children: formTemplateChildren,
  },
];

export const formTemplateListByAccount = ({ accountId, type, releasedOnly }) => {
  let filter = { func: { attr: formTemplate.edges.type, name: EQ, args: [{ value: type }] } };
  if (releasedOnly) {
    filter = combine(AND, [filter, hasFilter(formTemplate.edges.releasedAt)]);
  }
  return [
    {
      alias: VAR,
      uid: [
        accountId,
      ],
      func: { name: UID },
      model: account.model,
      children: [
        {
          model: formTemplate.model,
          attr: reverseEdge(formTemplate.edges.account),
          var: 'formTemplates',
        },
      ],
    },
    {
      alias: RESULT,
      needsVar: [{ name: 'formTemplates', typ: 1 }],
      func: {
        name: UID,
        needsVar: [{ name: 'formTemplates', typ: 1 }],
      },
      default: [],
      filter,
      model: formTemplate.model,
      children: formTemplateChildren,
    },
  ];
};
