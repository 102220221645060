<template>
  <div class="no-data">
    {{ $t('highcharts.noData') }}
  </div>
</template>

<script>
export default { name: 'NoData' };
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  .no-data {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25rem;
    color: $font-color-secondary;
  }
</style>
