import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useRepo from '@/nebula/repo';
import { computed } from 'vue';
import { doProvisioningStatus, dogma } from '@/api';
import { provisionedUsersByUserProvisioning } from '@/api/query/nebula/account';
import { useStore } from 'vuex';
import { userProvisioningAttribute } from 'shared/constants.json';
import { userProvisioning as userProvisioningConfig } from 'shared/api/query/configs.json';

export default function useUserProvisioning() {
  const store = useStore();
  const repo = useRepo(userProvisioningConfig.model);

  const { loggedInUserAccount } = useLoggedInUserAccount();

  const userProvisioning = computed(() => repo.selectSingle(loggedInUserAccount.value.userProvisioning?.uid));

  const isMultiple = (field) => {
    const multipleFields = [
      userProvisioningAttribute.emails,
      userProvisioningAttribute.phoneNumbers,
      userProvisioningAttribute.ims,
      userProvisioningAttribute.photos,
      userProvisioningAttribute.entitlements,
      userProvisioningAttribute.roles,
    ];

    return multipleFields.includes(field);
  };

  const isGroup = (field) => field === userProvisioningAttribute.groups;

  const getProvisioningStatus = () => doProvisioningStatus(userProvisioning.value).then((response) => {
    if (response.status !== 200) {
      throw new Error('error fetching user provisioning status');
    }

    dogma.query(provisionedUsersByUserProvisioning(userProvisioning)).then((queryResponse) => {
      if (queryResponse.status !== 200) {
        throw new Error('error fetching provisioned user entities');
      }

      store.commit('USER_PROVISIONING_STATUS', { provisioningStatus: response.data });
      repo.updateSingle({ uid: userProvisioning.value.uid, provisionedUsers: queryResponse.data.provisionedUsers });
    });

    return response;
  });

  const createSingle = (entity, options, hookParameter) => repo.createSingle(entity, options, hookParameter).then(() => {
    store.commit('USER_PROVISIONING_STATUS', { provisioningStatus: { provisionedUserCount: 0, userCount: 0, updatesAvailable: 0 } });
    repo.updateSingle({ uid: userProvisioning.value.uid, provisionedUsers: [] });
  });

  return { ...repo, createSingle, isMultiple, isGroup, userProvisioning, getProvisioningStatus };
}
