import MEditorEmoji from '@/components/editor/MEditorEmoji.vue';
import Node from '@/tiptap/tiptap/Utils/Node';

export default class Emoji extends Node {
  get name() {
    return 'emoji';
  }

  get schema() {
    return {
      attrs: { emoji: { default: '' } },
      group: 'inline',
      content: 'text*',
      inline: true,
      selectable: true,
      atom: true,
      toDOM: (node) => [
        'span',
        { class: 'emoji' },
        node.attrs.emoji,
      ],
      parseDOM: [
        {
          tag: 'span.emoji',
          getAttrs: (dom) => {
            const emoji = dom.innerText;
            return { emoji };
          },
        },
      ],
    };
  }

  get view() {
    return MEditorEmoji;
  }
}
