import useRepo from '@/nebula/repo';
import { UID } from 'shared/api/query/constants';
import { dogma } from '@/api';
import {
  propertyOption as propertyOptionConfig,
  propertyValue as propertyValueConfig,
} from 'shared/api/query/configs.json';
import { reverseEdge } from 'shared/api/query/edges';

export default function usePropertyOptions() {
  const repo = useRepo(propertyOptionConfig.model);

  const getUsedBy = (option) => dogma.query([
    {
      alias: 'usedBy',
      uid: [option.uid],
      func: { name: UID },
      model: propertyOptionConfig.model,
      children: [{
        alias: 'propertyValues',
        attr: reverseEdge(propertyValueConfig.edges.selectedOptions),
        model: propertyValueConfig.model,
        children: [
          { attr: UID, isCount: true },
        ],
      }],
    },
  ]).then((response) => {
    if (response.data.usedBy.length === 0 || response.data.usedBy[0].propertyValues.length === 0) {
      return 0;
    }
    return response.data.usedBy[0].propertyValues[0].count;
  });

  return {
    getUsedBy,
    ...repo,
    propertyOptions: repo.entityList,
  };
}
