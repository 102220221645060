<template>
  <div :class="['scope-filter', viewMode ? '-view-mode': '']">
    <m-spinner
      v-if="loading"
      size="sm"
    />
    <div
      v-else
      class="_filter"
    >
      <div class="_inner">
        <leaf-node
          v-for="(child, index) in userScopeTree.children"
          :key="getKey(child)"
          :class="['_leaf', disabled ? '-disabled':'']"
          :node="child"
          :op="selectedOperator"
          :hide-op="hideOp || index === 0"
          :can-select-op="index === 1"
          :hide-leaf-op="hideLeafOp"
          :can-delete="userScopeTree.children.length > 1 || canRemoveAll"
          :scope-items="scopeItems"
          :read-only="disabled"
          :max-tag-text-length="maxTagTextLength"
          :has-dynamic-dates="hasDynamicDates"
          :multi-model="multiModel"
          @change-operator="changeOperator"
          @change="updateChild(index, $event)"
          @delete="remove(index)"
        />
      </div>
    </div>
    <div
      v-if="!disabled && firstUnusedScopeItem !== null"
      class="_add"
    >
      <m-btn
        class="_btn"
        icon="plus"
        :disabled="disabled"
        light
        hide-border
        small
        @click="addNode(firstUnusedScopeItem)"
      >
        <template v-if="addBtnLabel === ''">
          {{ $t('userScopeFilter.addFilter') }}
        </template>
        <template v-else>
          {{ addBtnLabel }}
        </template>
      </m-btn>
    </div>
    <div
      v-if="showEmptyInfo && disabled && userScopeTree.children.length === 0"
      class="_empty"
    >
      {{ $t('userScopeFilter.empty') }}
    </div>
  </div>
</template>

<script>
import LeafNode from '@/components/filter/LeafNode.vue';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import { copy } from 'shared/lib/copy';
import { guid } from 'shared/lib/uuid';
import { iconByType } from '@/lib/property';
import { isEqual } from 'lodash-es';
import { isNullOrUndefined } from 'shared/lib/object/object';
import { isValidTree } from '@/lib/filter/scope-tree';
import { propertyType, userScopeOperator, userScopeType } from 'shared/constants.json';
import { textByLang } from 'shared/lib/language';

export default {
  name: 'ScopeFilter',
  props: {
    value: {
      type: Object,
      default: () => null,
    },
    props: {
      type: Array,
      default: () => [],
    },
    account: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showEmptyInfo: {
      type: Boolean,
      default: false,
    },
    viewMode: {
      type: Boolean,
      default: false,
    },
    showStaticUserSelection: {
      type: Boolean,
      default: false,
    },
    openWithStaticUserSelection: {
      type: Boolean,
      default: false,
    },
    canRemoveAll: {
      type: Boolean,
      default: false,
    },
    maxTagTextLength: {
      type: Number,
      default: 0,
    },
    hasDynamicDates: {
      type: Boolean,
      default: false,
    },
    multiModel: {
      type: Boolean,
      default: false,
    },
    initWithProp: {
      type: Object,
      default: () => null,
    },
    openWithProp: {
      type: Object,
      default: () => null,
    },
    hideOp: {
      type: Boolean,
      default: false,
    },
    hideLeafOp: {
      type: Boolean,
      default: false,
    },
    addBtnLabel: {
      type: String,
      default: '',
    },
  },
  setup() {
    const { userLang } = useLoggedInUser();
    return { userLang };
  },
  emits: ['input', 'update:value'],
  components: { LeafNode },
  data() {
    return {
      userScopeTree: null,
      selectedOperator: userScopeOperator.and,
      staticUserItem: {
        value: guid(),
        icon: 'user',
        text: this.$t('userScope.staticUserLabel'),
        type: userScopeType.staticUsers,
      },
    };
  },
  computed: {
    allowedProps() {
      const notAllowed = [
        propertyType.text,
        propertyType.url,
        propertyType.richText,
      ];

      return this.props.filter((p) => !notAllowed.includes(p.property.type));
    },
    scopeItems() {
      const items = this.allowedProps.map((p) => (this.scopeItemByProp(p)));
      if (this.showStaticUserSelection) {
        items.push(this.staticUserItem);
      }
      return items;
    },
    scopeItemsInScopeTree() {
      const res = [];
      const scopeTypeByScope = (scope) => {
        if (scope.type !== undefined) {
          return scope.type;
        }
        if (!isNullOrUndefined(scope.staticUsers)) {
          return userScopeType.staticUsers;
        }
        if (!isNullOrUndefined(scope.directProperty)) {
          return userScopeType.directProperty;
        }
        return userScopeType.property;
      };
      const scopeItemByScope = (scope) => {
        const type = scopeTypeByScope(scope);
        switch (type) {
          case userScopeType.staticUsers:
            return this.scopeItems.find((item) => item.type === type);
          case userScopeType.directProperty:
            return this.scopeItems.find((item) => item.type === type && item.directProperty.edgeName === scope.directProperty.edgeName);
          case userScopeType.property:
            return this.scopeItems.find((item) => item.type === type && item.property.uid === scope.property.uid);
          default:
            throw new Error('unsupported user scope type');
        }
      };

      for (let i = 0; i < this.userScopeTree.children.length; i++) {
        const scopeItem = scopeItemByScope(this.userScopeTree.children[i].children[0].scope);

        if (scopeItem !== undefined && res.find((item) => item.value === scopeItem.value) === undefined) {
          res.push(scopeItem);
        }
      }
      return res;
    },
    firstUnusedScopeItem() {
      const unusedScopeItem = this.scopeItems.filter((item) => !this.scopeItemsInScopeTree.map(({ value }) => value).includes(item.value));
      if (unusedScopeItem.length === 0) {
        return null;
      }
      return unusedScopeItem[0];
    },
  },
  methods: {
    scopeItemByProp(prop) {
      const getKey = (key, property) => (this.multiModel ? `${property.model}-${key}` : key);
      const getPropText = (property) => (property.label === undefined ? '' : textByLang(property.label, this.userLang));
      const scopeItemByProperty = (property) => ({
        value: getKey(property.uid, property),
        text: getPropText(property),
        icon: iconByType(property),
        type: userScopeType.property,
        property,
      });
      const scopeItemByDirectProperty = (property) => ({
        value: getKey(property.edgeName, property),
        text: property.label,
        icon: iconByType({ type: property.type, edgeName: property.edgeName }),
        type: userScopeType.directProperty,
        directProperty: property,
      });

      if (prop.isDirect) {
        return scopeItemByDirectProperty(prop.property);
      }
      return scopeItemByProperty(prop.property);
    },
    changeOperator(val) {
      this.selectedOperator = val;
      this.emitUpdate({
        ...this.userScopeTree,
        op: val,
      });
    },
    updateChild(index, val) {
      this.userScopeTree.children.splice(index, 1, val);
    },
    init() {
      this.userScopeTree = {
        account: { uid: this.account.uid },
        op: userScopeOperator.and,
        scope: null,
        children: [],
      };
      if (this.initWithProp !== null) {
        const prop = this.allowedProps.find((p) => p.key === this.initWithProp.key);
        this.addNode(prop);
      }
    },
    getKey(node) {
      if (typeof node.uid !== 'undefined') {
        return node.uid;
      }
      if (typeof node.key !== 'undefined') {
        return node.key;
      }
      return 0;
    },
    nodeAlreadyInTree(scopeItem) {
      return this.scopeItemsInScopeTree.find((p) => p.value === scopeItem.value) !== undefined;
    },
    addNode(scopeItem = null) {
      if (scopeItem === null || this.nodeAlreadyInTree(scopeItem)) {
        return;
      }
      const scopeFromScopeItem = (scopeItem) => {
        const scope = { type: scopeItem.type };
        const propertyScope = {
          users: [],
          selectedOptions: [],
          spaces: [],
          numberRange: null,
          timeRange: null,
          relation: null,
          isEmpty: false,
        };
        switch (scope.type) {
          case userScopeType.staticUsers:
            return { ...scope, staticUsers: [] };
          case userScopeType.directProperty:
            return {
              ...scope,
              ...propertyScope,
              directProperty: scopeItem.directProperty,
            };
          case userScopeType.property:
            return {
              ...scope,
              ...propertyScope,
              property: scopeItem.property,
            };
          default:
            return scope;
        }
      };
      const scope = scopeFromScopeItem(scopeItem);
      this.userScopeTree.children.push({
        key: guid(),
        op: userScopeOperator.and,
        children: [{ op: userScopeOperator.and, scope }],
      });
    },
    remove(index) {
      this.userScopeTree.children.splice(index, 1);
      if (this.userScopeTree.children.length === 0) {
        this.emitUpdate(null);
      }
    },
    emitUpdate(userScopeTree) {
      if (!isValidTree(userScopeTree)) {
        return;
      }
      this.$emit('input', copy(userScopeTree));
      this.$emit('update:value', copy(userScopeTree));
    },
  },
  watch: {
    userScopeTree: {
      handler(val) {
        if (isEqual(val, this.value)) {
          return;
        }
        this.emitUpdate(val);
      },
      deep: true,
    },
    value: {
      handler(val) {
        if (JSON.stringify(this.userScopeTree) === JSON.stringify(val)) {
          return;
        }
        if (this.value === null || Object.keys(this.value).length === 0) {
          this.init();
          return;
        }
        this.userScopeTree = copy(val);
      },
      deep: true,
    },
  },
  created() {
    if (this.value === null || Object.keys(this.value).length === 0) {
      this.init();
      this.emitUpdate(this.userScopeTree);
      return;
    }
    this.userScopeTree = copy(this.value);
    this.selectedOperator = this.userScopeTree.op;

    if (this.showStaticUserSelection === true && this.openWithStaticUserSelection === true) {
      this.addNode(this.staticUserItem);
    }
    if (this.openWithProp !== null) {
      // TODO: Maybe there's a bug here with multi-modal
      const filterToAdd = this.allowedProps.find((p) => p.key === this.openWithProp.key);
      if (filterToAdd !== undefined) {
        this.addNode(this.scopeItemByProp(filterToAdd));
      }
    }
  },
};
</script>

<style lang="scss" type="text/scss">
  .scope-filter {
    ._filter {
      display: flex;

      ._inner {
        width: 100%;
      }

      ._leaf {
        margin-bottom: .8rem;

        &:last-of-type {
          &.-disabled {
            margin-bottom: 0;
          }
        }
      }
    }

    ._empty {
      color: $font-color-tertiary;
    }

    &.-view-mode {
      .ant-select-disabled,
      .ant-input-disabled,
      .ant-input-number-disabled {
        color: inherit;
        cursor: inherit !important;
        background: $white;
        background-color: $white;

        .ant-select-selection,
        .ant-input-number-input,
        .ant-calendar-range-picker-input {
          cursor: inherit !important;
          background: $white;
        }
      }

      .ant-select-selection-selected-value {
        padding-right: 0;
      }

      .ant-select-arrow {
        display: none;
      }
    }
  }
</style>
