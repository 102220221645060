<template>
  <div class="_factor-form">
    <m-content padding-small>
      <div
        class="factor-form"
      >
        <m-form-item
          v-for="(lang) in languages"
          :key="lang"
          :label="getTitle(lang)"
        >
          <m-text-field
            :value="title[lang]"
            @change="updateLanguages($event, lang)"
          />
        </m-form-item>
      </div>
    </m-content>
    <m-default-form-actions
      :loading="loading"
      clickable
      @cancel="cancel"
      @submit="submit"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { nameByCode } from '@/lib/language';

export default {
  name: 'FactorForm',
  props: {
    languages: {
      type: Array,
      required: true,
    },
    factor: {
      type: Object,
      default: () => null,
    },
    entity: {
      type: Object,
      required: true,
    },
    model: {
      type: String,
      required: true,
    },
    entityById: {
      type: Function,
      required: true,
    },
  },
  emits: ['created', 'updated', 'cancel'],
  data() {
    return {
      loading: false,
      title: {},
    };
  },
  methods: {
    ...mapActions([
      'updateEntity',
    ]),
    updateLanguages(value, lang) {
      this.title[lang] = value;
    },
    submit() {
      if (this.factor === null) {
        const factor = { ...this.factor, title: this.title, factorFields: [] };
        const formFactors = [...this.entity.formFactors, factor];
        this.loading = true;
        this.updateEntity({
          entity: { ...this.entity, formFactors },
          model: this.model,
          entityById: this.entityById,
        }).then((response) => {
          this.loading = false;
          if (response.status !== 200) {
            this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
            return;
          }

          this.$emit('created');
        });
        return;
      }

      this.$store.commit('FORM_FACTOR_UPDATED', {
        factor: { ...this.factor, title: this.title },
        model: this.model,
      });
      this.loading = true;
      this.updateEntity({
        entity: this.entity,
        model: this.model,
        entityById: this.entityById,
      }).then((response) => {
        this.loading = false;
        if (response.status !== 200) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
          return;
        }

        this.$emit('updated');
      });
    },
    cancel() {
      this.init();
      this.$emit('cancel');
    },
    getTitle(lang) {
      return this.$t('factorForm.factorTitleLabel', { lang: nameByCode(lang) });
    },
    init() {
      this.languages.forEach((lang) => {
        if (this.factor === null || typeof this.factor.title[lang] === 'undefined') {
          this.title[lang] = '';
          return;
        }

        this.title[lang] = this.factor.title[lang];
      });
    },
  },
  created() {
    this.init();
  },
};
</script>

<style scoped lang="scss" type="text/scss">

</style>
