<template>
  <m-card
    :class="['icon-card', selected ? '-selected' : '', disabled ? '-disabled' : '']"
    :style="style"
    :hover="!disabled"
    flat
    small-padding
  >
    <div class="_header">
      <div class="_icon">
        <m-icon
          size="24"
          :color="color"
          :type="icon"
        />
      </div>
      <h6 class="_title">
        {{ title }}
      </h6>
    </div>
    <!--eslint-disable vue/no-v-html-->
    <div
      class="_description"
      v-html="description"
    />
    <!--eslint-enable vue/no-v-html-->
  </m-card>
</template>

<script>
export default {
  name: 'IconCard',
  props: {
    icon: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    selected: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '20rem',
    },
    height: {
      type: String,
      default: '100%',
    },
  },
  computed: {
    style() {
      return {
        width: this.width,
        height: this.height,
      };
    },
    color() {
      if (this.selected) {
        return this.$colors.blue.base;
      }
      return this.$colors.grey.base;
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .icon-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    text-align: center;
    cursor: pointer;
    border: 1px solid $border-color;

    ._header {
      display: flex;
      align-items: center;

      ._icon {
        margin-right: .8rem;
      }

      ._title {
        margin: 0;
        font-weight: $font-weight-bold;
        color: $font-color-secondary;
      }
    }

    ._description {
      line-height: 1.5;
      color: $font-color-secondary;
      text-align: left;
    }

    &:hover {
      background-color: $hover-color;
    }

    &.-selected {
      background-color: map_get($blue, 'lighten-5');
      border-color: $primary-color;

      ._header {
        ._title {
          color: $primary-color;
        }
      }
    }

    &.-disabled {
      pointer-events: none;
      opacity: .5;
    }
  }

</style>
