<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 6.03636L17.9636 4L12 9.96364L6.03636 4L4 6.03636L9.96364 12L4 17.9636L6.03636 20L12 14.0364L17.9636 20L20 17.9636L14.0364 12L20 6.03636Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default { name: 'Close' };
</script>

<style scoped lang="scss" type="text/scss">

</style>
