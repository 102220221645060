<template>
  <div
    :style="allStyles"
    class="m-alert"
  >
    <div
      v-if="!hideIcon"
      class="_icon-wrapper"
    >
      <m-icon
        :type="_icon"
        :color="iconColor"
        size="18"
      />
    </div>
    <template v-if="message !== ''">
      {{ message }}
    </template>
    <slot v-else />
    <div
      v-if="closeable"
      class="_right"
    >
      <m-btn
        fab
        small
        icon="close"
        hide-border
        light
        @click="close()"
      />
    </div>
  </div>
</template>

<script>
import colors from 'shared/colors';

export default {
  name: 'MAlert',
  props: {
    type: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    style: {
      type: Object,
      default: () => {},
    },
    hideIcon: {
      type: Boolean,
      default: false,
    },
    hideBorder: {
      type: Boolean,
      default: false,
    },
    closeable: {
      type: Boolean,
      default: false,
    },
    closed: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
  },
  emits: ['update:closed'],
  computed: {
    _icon() {
      if (this.icon !== '') {
        return this.icon;
      }
      switch (this.type) {
        case 'success':
          return 'check-circle';
        case 'info':
          return 'info-circle';
        case 'warning':
          return 'exclamation-circle-filled';
        case 'error':
          return 'close-circle';
        default:
          return '';
      }
    },
    iconColor() {
      if (this.icon !== '') {
        return '';
      }
      switch (this.type) {
        case 'success':
          return this.$colors.green.base;
        case 'info':
          return this.$colors.blue.base;
        case 'warning':
          return this.$colors.yellow.base;
        case 'error':
          return this.$colors.red.base;
        default:
          return '';
      }
    },
    backgroundColor() {
      switch (this.type) {
        case 'success':
          return this.$colors.green.lighten5;
        case 'info':
          return this.$colors.blue.lighten5;
        case 'warning':
          return this.$colors.yellow.lighten4;
        case 'error':
          return this.$colors.red.lighten5;
        default:
          return '';
      }
    },
    allStyles() {
      return {
        color: this.colorCode,
        backgroundColor: this.backgroundColor,
        border: !this.hideBorder ? `.1px solid ${this.colorCode}` : 'none',
        ...this.style,
      };
    },
    colorCode() {
      switch (this.type) {
        case 'success':
          return colors.blue.base;
        case 'warning':
          return colors.grey.darken3;
        case 'info':
          return colors.blue.base;
        case 'error':
          return colors.red.base;
        case '':
          return colors.grey.darken3;
        default:
          return null;
      }
    },
  },
  methods: {
    close() {
      if (!this.closeable) {
        return;
      }
      this.$emit('update:closed', true);
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .m-alert {
    display: flex;
    align-items: center;
    padding: 1.2rem 1.6rem;
    margin-bottom: 1.6rem;
    border-radius: $default-border-radius;

    ._icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 1.2rem;
    }

    ._right {
      margin-left: 1rem;

      @media (max-width: $screen-size-sm) {
        margin-left: auto;
      }
    }
  }
</style>
