import useSpaceDepthValidator from '@/composables/space/depth-validator';
import { indexToIdList } from '@/lib/sort-operation';
import { intersection } from 'lodash-es';
import { validationError } from 'shared/constants.json';

export default function useSpaceOperationValidator(collection, elReader) {
  const depthValidator = useSpaceDepthValidator(elReader);

  const isValidRealign = (operation) => {
    const { toRealignIds: childrenIds, newParentId } = operation;
    if (childrenIds.length === 0) {
      return { isValid: false };
    }
    if (newParentId === null || newParentId === 0) {
      return { isValid: true };
    }

    const parentNodes = collection.value.filter((el) => elReader.getId(el) === newParentId);
    if (parentNodes.length === 0) {
      return { isValid: false, message: 'new parent is not in the collection' };
    }

    for (let i = 0; i < parentNodes.length; i++) {
      const parentChain = indexToIdList(elReader.getDragId(parentNodes[i]));
      if (intersection(childrenIds, parentChain).length > 0) {
        return { isValid: false, message: validationError.circularReferenceNotAllowed };
      }
    }

    for (let i = 0; i < childrenIds.length; i++) {
      const restrictedIds = depthValidator.tooDeepItems(collection.value, { uid: childrenIds[i] }).map((i) => i.uid);
      if (restrictedIds.includes(newParentId)) {
        return { isValid: false, message: 'realigning would result in branch with depth bigger than max allowed' };
      }
    }

    return { isValid: true };
  };

  const isValidSort = ({ list, anchor, toSort, sortAfter }) => {
    if (toSort.length > 1) {
      return { isValid: true };
    }

    if (anchor === toSort[0]) {
      return { isValid: false, message: 'no-op anchor == self' };
    }

    const aIdx = list.findIndex((e) => e === anchor);
    if (sortAfter === false && aIdx === 0) {
      return { isValid: true };
    }
    if (sortAfter === true && aIdx === list.length - 1) {
      return { isValid: true };
    }

    const target = sortAfter ? list[aIdx + 1] : list[aIdx - 1];
    if (target !== toSort[0]) {
      return { isValid: true };
    }

    return { isValid: false, message: 'no-op moving next to self' };
  };

  const isValidDrag = (operation) => {
    const validators = [];
    if (operation.isRealign) {
      validators.push(isValidRealign(operation));
    }
    if (operation.isSort) {
      const anchor = collection.value.find((el) => elReader.getId(el) === operation.anchorId);
      const anchorDepth = elReader.getDepth(anchor);
      const list = collection.value.filter((el) => elReader.getDepth(el) === anchorDepth).map((el) => elReader.getId(el));
      validators.push(isValidSort({
        list,
        anchor: operation.anchorId,
        toSort: operation.toSortIds,
        sortAfter: operation.sortAfter,
      }));
    }

    return validators.reduce((res, next) => {
      if (res.isValid === false) {
        return res;
      }
      return next;
    }, { isValid: true });
  };

  return { isValidDrag };
}
