import { isElementTruncated } from 'shared/lib/dom';
import { onMounted, onUnmounted, ref } from 'vue';

export default function useElementsTruncate(elementRefs) {
  const isTruncated = ref(false);

  const elFromElRef = (elRef) => {
    if (elRef.value === null) {
      return null;
    }
    if (elRef.value.$el !== undefined) {
      return elRef.value.$el;
    }
    return elRef.value;
  };

  const callback = () => {
    isTruncated.value = false;
    elementRefs.forEach((elRef) => {
      const el = elFromElRef(elRef);
      if (el !== null) {
        isTruncated.value = isTruncated.value || isElementTruncated(el);
      }
    });
  };

  const resizeObserver = new ResizeObserver(callback);
  const mutationObserver = new MutationObserver(callback);
  onMounted(() => {
    elementRefs.forEach((elRef) => {
      const el = elFromElRef(elRef);
      if (el !== null) {
        resizeObserver.observe(el);
        mutationObserver.observe(el, { childList: true });
      }
    });
  });
  onUnmounted(() => {
    resizeObserver.disconnect();
    mutationObserver.disconnect();
  });

  return { isTruncated };
}
