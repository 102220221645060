import useRepo from '@/nebula/repo';
import useUserProvisioning from '@/composables/user-provisioning/user-provisioning';
import { computed } from 'vue';
import { oauthCodeGrantClient as oauthCodeGrantClientConfig } from 'shared/api/query/configs.json';

export default function useOauthCodeGrantClient() {
  const repo = useRepo(oauthCodeGrantClientConfig.model);
  const { userProvisioning } = useUserProvisioning();

  const oauthCodeGrantClient = computed(() => repo.selectSingle(userProvisioning.value?.oauthCodeGrantClient?.uid));

  return { oauthCodeGrantClient, ...repo };
}
