<template>
  <div
    :class="['form-field', isSelected ? '-selected' : '', isDragged ? '-drag': '']"
    :data-id="item.uid"
    @click="clicked"
  >
    <div
      v-if="draggingOverTop"
      class="_drag-over-top"
    />
    <div class="_type _handle">
      <form-field-icon
        :type="item.field.type"
        :field-index="fieldIndex"
      />
    </div>
    <textarea
      :rows="1"
      type="text"
      class="_title"
      :value="item.field.title[lang]"
      :placeholder="$t('formField.titlePlaceholder')"
      :readonly="disabled"
      @input="updateFieldTitle"
    />
    <div class="_actions">
      <m-btn
        v-if="hasTranslations && !disabled"
        icon="global"
        fab
        hide-border
        class="_btn"
        small
        @click.stop="$refs.fieldTranslator.show()"
      />
      <m-btn
        v-if="!disabled"
        class="_btn"
        small
        fab
        icon="tool"
        hide-border
        @click="openSettings"
      />
      <field-translator
        ref="fieldTranslator"
        :input="item.field.title"
        @change-translation="updateTranslation"
      />
      <m-btn
        v-if="!disabled"
        icon="delete"
        fab
        small
        hide-border
        class="_btn"
        @click.stop="showDeleteConfirm"
      />
    </div>
    <div
      v-if="draggingOverBottom"
      class="_drag-over-bottom"
    />
  </div>
</template>

<script>
import FieldTranslator from '@/components/FieldTranslator.vue';
import FormFieldIcon from '@/components/FormFieldIcon.vue';
import { DateTime } from 'luxon';
import { copy } from 'shared/lib/copy';

export default {
  name: 'FormField',
  props: {
    item: {
      type: Object,
      required: true,
    },
    fieldIndex: {
      type: Number,
      required: true,
    },
    isSelected: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
    hasTranslations: {
      type: Boolean,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
    model: {
      type: String,
      required: true,
    },
    isDragged: {
      type: Boolean,
      default: false,
    },
    draggingOverTop: {
      type: Boolean,
      default: false,
    },
    draggingOverBottom: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['selected', 'open-settings'],
  components: { FieldTranslator, FormFieldIcon },
  methods: {
    showDeleteConfirm() {
      const deletedMethod = this.setDeleted;
      this.$confirm({
        title: this.$t('formField.prompt'),
        okText: this.$t('formField.submitLabel'),
        okType: 'danger',
        maskClosable: true,
        cancelText: this.$t('general.cancel'),
        onOk() {
          deletedMethod();
        },
      });
    },
    clicked() {
      this.$emit('selected', { fieldIndex: this.fieldIndex, item: this.item });
    },
    openSettings() {
      this.$emit('open-settings', { fieldIndex: this.fieldIndex, item: this.item });
    },
    updateFieldTitle(event) {
      const item = copy(this.item);
      item.field.title[this.lang] = event.srcElement.value;
      this.$store.commit('UPDATE_ORDER_ITEM', {
        item,
        model: this.model,
      });
    },
    setDeleted() {
      this.$store.commit('UPDATE_ORDER_ITEM', {
        item: {
          ...this.item,
          deletedAt: DateTime.local().toISO(),
          field: {
            ...this.item.field,
            deletedAt: DateTime.local().toISO(),
          },
        },
        model: this.model,
      });
    },
    addLanguage({ lang }) {
      this.$store.commit('FIELD_TITLE_LANGUAGE_ADDED', { lang });
    },
    updateTranslation({ title }) {
      const item = copy(this.item);
      item.field.title = title;
      this.$store.commit('UPDATE_ORDER_ITEM', {
        item,
        model: this.model,
      });
    },
    deleteTranslation({ lang }) {
      this.$store.commit('LANGUAGE_DELETED', { lang, model: this.model });
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .form-field {
    position: relative;
    display: flex;
    min-height: 11rem;
    padding: 2.4rem;

    ._drag-over-top {
      position: absolute;
      top: -2px;
      right: 0;
      left: 0;
      z-index: 88;
      width: 100%;
      height: 4px;
      pointer-events: none;
      background: $highlighted-color-dark;
      opacity: 1;
    }

    ._drag-over-bottom {
      position: absolute;
      right: 0;
      bottom: -2px;
      left: 0;
      z-index: 88;
      width: 100%;
      height: 4px;
      pointer-events: none;
      background: $highlighted-color-dark;
      opacity: 1;
    }

    ._actions {
      position: absolute;
      right: 0;
      bottom: 0;
      display: none;
      padding: .2rem;

      ._btn {
        margin: .4rem .2rem;
      }
    }

    &:hover {
      &:not(.-drag) {
        background-color: $hover-color;
      }

      ._actions {
        display: flex;
      }
    }

    &.-selected {
      background-color: $selected-color;
    }

    ._type {
      margin-right: 2rem;
      cursor: move;
    }

    ._title {
      width: 100%;
      resize: none;
      background: none;

      &:focus {
        outline: none;
      }
    }
  }
</style>
