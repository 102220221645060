import useGoals from '@/composables/goal/goals';
import useUpdates from '@/composables/updates/updates';
import { CASCADE_CONTEXT_PLANNING } from '@/lib/constants';
import { computed } from 'vue';
import { intersection } from 'lodash-es';
import { sortByArray, sortByAttr, sortDatetime } from 'shared/lib/sort';

export default function useGoal(context, goalId, includeUpdatesFromChildren = true) {
  const { selectSingle, entityList: goals } = useGoals();
  const { updates } = useUpdates();
  const goal = computed(() => {
    const filtered = selectSingle(goalId.value);
    if (filtered === undefined || !filtered.completelyLoaded) {
      return {};
    }

    return {
      ...filtered,
      parents: filtered.parents.filter((p) => {
        const parent = selectSingle(p.uid);
        if (parent === undefined) {
          return false;
        }
        return goalFilter(filtered)(parent);
      }),
    };
  });

  const goalChildren = computed(() => {
    const filtered = goals.value.filter((g) => {
      if (!g.completelyLoaded) {
        return false;
      }
      if (g.parents.length === 0) {
        return false;
      }
      return g.parents.map((p) => p.uid).includes(goalId.value) && goalFilter(goal.value)(g);
    });

    return filtered.sort(sortByArray(goal.value.children));
  });

  const goalUpdates = computed(() => {
    const goalIds = [goalId.value];
    if (includeUpdatesFromChildren) {
      goalIds.push(...goalChildren.value.map((g) => g.uid));
    }
    return updates.value.filter((u) => {
      // Update has limited shape as it was fetched by goalActivityList()
      if (u.goal === undefined && u.goalActivities === undefined) {
        return false;
      }
      if (u.goal !== null && u.goal.uid === goalId.value) {
        return true;
      }
      const goalsFromActivities = u.goalActivities.filter((a) => a.referenced !== true).map((a) => a.goal.uid);
      return intersection(goalIds, goalsFromActivities).length > 0;
    }).map((u) => {
      let customCreatedAt = u.createdAt;
      if (u.goal !== null && u.goalActivities.length !== 0) {
        customCreatedAt = u.goalActivities[0].customCreatedAt;
      }
      return { ...u, customCreatedAt };
    }).sort(sortByAttr('customCreatedAt', sortDatetime));
  });

  const goalFilter = (goal) => {
    if (context === CASCADE_CONTEXT_PLANNING) {
      return () => true;
    }
    return (g) => goal.publishedAt === null || g.publishedAt !== null;
  };

  return {
    goal,
    goalUpdates,
    goalChildren,
  };
}
