<template>
  <div class="goal-types">
    <page-header
      :title="$t('goalType.title')"
      boxed-xs
      heading="h3"
      underlined
      class="_header"
    />
    <m-content
      boxed-xs
      no-padding
    >
      <m-content :padding-y="8" />
      <m-btn
        small
        super-light
        :href="$t('goalType.helpCenterLink')"
        hide-border
        icon="question-circle"
        target="_blank"
      >
        {{ $t('goalType.learnMore') }}
      </m-btn>
    </m-content>
    <m-content
      boxed-xs
      padding
      class="_content"
    >
      <goal-type-list />
    </m-content>
  </div>
</template>

<script>
import GoalTypeList from '@/components/goal-type/GoalTypeList.vue';
import PageHeader from 'shared/components/PageHeader.vue';

export default {
  name: 'GoalTypes',
  components: { GoalTypeList, PageHeader },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
</style>
