import { AND, NOT, OR } from '@/lib/filter/scope-tree';
import { dateScopeType, propertyType, userScopeType } from 'shared/constants.json';
import { goal } from 'shared/api/query/configs.json';
import { goalTypesFilter } from '@/lib/filter/goal/goals-type-filter';
import { guid } from 'shared/lib/uuid';

export const propertiesRequiredFilter = (property, negated = false) => {
  const children = [];
  if (property.descriptionRequired === true) {
    children.push({
      key: guid(),
      scope: {
        type: userScopeType.directProperty,
        directProperty: {
          edgeName: goal.edges.description,
          type: propertyType.text,
        },
        isEmpty: true,
      },
    });
  }
  if (property.cycleRequired === true) {
    children.push({
      key: guid(),
      scope: {
        type: userScopeType.directProperty,
        directProperty: {
          edgeName: goal.edges.goalCycle,
          type: propertyType.options,
        },
        isEmpty: true,
      },
    });
  }
  for (let i = 0; i < property.requiredProperties.length; i++) {
    const rp = property.requiredProperties[i];
    let scope;
    switch (rp.type) {
      case propertyType.space:
      case propertyType.options:
      case propertyType.singleSelect:
      case propertyType.user:
      case propertyType.number:
      case propertyType.text:
      case propertyType.url:
        scope = { type: userScopeType.property, property: rp, isEmpty: true };
        break;
      case propertyType.date:
        scope = { type: userScopeType.property, property: rp, timeRange: dateScopeType.empty };
        break;
      case propertyType.lookup:
      default:
        continue;
    }
    children.push({
      key: guid(),
      scope,
    });
  }

  const op = negated === true ? NOT : AND;
  return {
    key: guid(),
    op,
    children: [{
      key: guid(),
      op: OR,
      children,
    }],
  };
};

export const allGoalTypesPropertiesRequiredFilter = (goalTypeProperty, goalTypes) => {
  const children = goalTypes.map((goalType) => ({
    op: AND,
    key: guid(),
    children: [
      goalTypesFilter(goalTypeProperty, goalType),
      propertiesRequiredFilter(goalType),
    ],
  }));
  return {
    key: guid(),
    op: OR,
    children,
  };
};
