<template>
  <div class="chart-comments">
    <transition
      mode="out-in"
      name="list-left-fast"
    >
      <div :key="page">
        <div
          v-for="(comment, index) in displayedComments"
          :key="comment.id"
          class="_comment"
        >
          <div class="_row">
            <div class="_score">
              <m-tooltip
                v-if="showValue"
                placement="bottom"
              >
                <div>
                  <m-chip
                    class="_badge"
                    small
                    :background-color="valueColor(comment.value)"
                  >
                    <span>{{ comment.value }}</span>
                  </m-chip>
                </div>
                <template #title>
                  {{ valueComment(comment.value) }}
                </template>
              </m-tooltip>
            </div>
            <div class="_text">
              <span>{{ comment.text }}</span>
            </div>
            <div class="_date">
              {{ formatDate(comment.createdAt) }}
            </div>
          </div>
          <m-divider
            v-if="index !== displayedComments.length -1"
            class="_divider"
          />
        </div>
      </div>
    </transition>
    <div
      v-if="itemsPerPage < comments.length"
      class="_pagination"
    >
      <m-pagination
        v-model:value="page"
        :total="comments.length"
        :items-per-page="itemsPerPage"
        hide-border
        small
      />
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import { getColorForLikertValue } from '@/lib/charts/colors';
import { sliceByPage } from 'shared/lib/pagination';

export default {
  name: 'ChartComments',
  props: {
    comments: {
      type: Array,
      default: () => [],
    },
    itemsPerPage: {
      type: Number,
      default: 3,
    },
    sortDescending: {
      type: Boolean,
      default: true,
    },
    showValue: {
      type: Boolean,
      default: false,
    },
    maxValue: {
      type: Number,
      default: 0,
    },
    startsFromZero: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return { page: 1 };
  },
  computed: {
    amountOfPages() {
      return Math.ceil(this.comments.length / this.itemsPerPage);
    },
    displayedComments() {
      const result = this.comments.map((item, index) => ({
        ...item,
        id: index,
      }));
      this.sort(result, this.sortDescending);
      return sliceByPage(result, this.page, this.itemsPerPage);
    },
  },
  methods: {
    formatDate(timestamp) {
      return DateTime.fromISO(timestamp).toRelative();
    },
    sort(comments, descending) {
      return comments.sort((a, b) => {
        if (descending) {
          return new Date(b.createdAt) - new Date(a.createdAt);
        }
        return new Date(a.createdAt) - new Date(b.createdAt);
      });
    },
    valueComment(value) {
      return this.$t('chartComments.valueTooltip', { value });
    },
    valueColor(value) {
      if (this.maxValue === 0) {
        return '';
      }
      return getColorForLikertValue(value, this.maxValue, this.startsFromZero);
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .chart-comments {
    ._comment {
      ._row {
        display: flex;

        ._score {
          flex: 0 1 auto;

          ._badge {
            margin-top: .2rem;
            margin-right: 1.6rem;
            font-weight: $font-weight-semibold;
          }
        }

        ._text {
          flex: 1 1 auto;
          margin-bottom: .4rem;
          color: $font-color-primary;

          span {
            white-space: pre-wrap;
          }
        }

        ._date {
          flex: 0 1 auto;
          min-width: 10rem;
          font-size: $font-size-3;
          color: $font-color-tertiary;
          text-align: right;
        }
      }

      ._divider {
        margin-bottom: 1.4rem;
      }
    }

    ._pagination {
      display: flex;
      justify-content: center;
      margin-top: 1.6rem;
    }
  }

</style>
