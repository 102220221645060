<template>
  <div class="planning-details-header">
    <editable-page-header
      :title="planning.title"
      :disabled="!canEdit"
      :placeholder="$t('list.noTitle')"
      size="small"
      @change-title="changeTitle"
    />
    <div
      id="planning-properties"
      class="_properties"
    >
      <property-label
        key="goalCycle"
        :label="$t('goal.goalEditor.goalCycle')"
        icon="retweet"
        class="_prop"
      >
        <goal-cycle-selector
          :value="planning.goalCycles"
          :items="goalCycles"
          full-width
          hide-border
          show-search
          keep-open-on-click
          :read-only="!canEdit"
          placement="onTopLeft"
          :placeholder="$t('general.empty')"
          @input="updateCycle"
        />
      </property-label>
      <property-values
        :disabled="!canEdit"
        :properties="planningProperties"
        :value="planning.properties"
        @input="updateProperties"
      />
    </div>
  </div>
</template>

<script>
import EditablePageHeader from '@/components/page/EditablePageHeader.vue';
import GoalCycleSelector from '@/components/goal/cycle/GoalCycleSelector.vue';
import PropertyLabel from '@/components/PropertyLabel.vue';
import PropertyValues from '@/components/PropertyValues.vue';
import useDebounce from '@/composables/debounce';
import useGoalCycle from '@/composables/goal-cycle/goal-cycle';
import usePlannings from '@/composables/planning/plannings';
import useProperties from '@/composables/property/property';
import { accessPolicyType } from 'shared/constants.json';
import { uniqBy } from 'lodash-es';

export default {
  name: 'PlanningDetailsHeader',
  props: {
    planning: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { goalCycles } = useGoalCycle();
    const { updatePlanning } = usePlannings();
    const { debounce } = useDebounce();
    const { planningProperties } = useProperties();
    return { updatePlanning, goalCycles, debounce, planningProperties };
  },
  components: {
    PropertyValues,
    EditablePageHeader,
    PropertyLabel,
    GoalCycleSelector,
  },
  computed: {
    canEdit() {
      return [accessPolicyType.full, accessPolicyType.write].includes(this.planning.accessRight);
    },
  },
  methods: {
    updateProperties(value) {
      this.updatePlanning({
        ...this.planning,
        properties: uniqBy([...value, ...this.planning.properties], (item) => item.property.uid),
      }).catch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      });
    },
    changeTitle(value) {
      const entity = { ...this.planning, title: value };
      const update = () => {
        this.updatePlanning(entity).catch(() => {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
        });
      };
      this.debounce(update, 500);
    },
    updateCycle(value) {
      this.updatePlanning({
        ...this.planning,
        goalCycles: value,
      }).catch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      });
    },
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
</style>
