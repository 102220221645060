import { AND, combine } from 'shared/api/query/filter';
import { CREATED_AT, DGRAPH_TYPE, EQ, ONE_TO_ONE, RESULT, TYPE, UID } from 'shared/api/query/constants';
import { NOT } from '@/lib/filter/scope-tree';
import {
  accessPolicy,
  collection as collectionConfig,
  collectionItem as collectionItemConfig,
  comment as commentConfig,
  meeting as meetingConfig,
  msCalendarEvent as msCalendarEventConfig,
  page as pageConfig,
  privateNote as privateNoteConfig,
  propertyValue as propertyValueConfig,
  user as userConfig,
} from 'shared/api/query/configs.json';
import { accessPolicyChildren, propertyValueEdges, reverseEdge, userEdgesSlim } from 'shared/api/query/edges';
import { accessPolicyType } from 'shared/constants.json';
import { children as collectionChildren } from '@/api/query/collection';
import { children as commentChildren } from '@/api/query/comment';
import { children as msCalendarEventChildren } from '@/api/query/mscalendar-event';
import { children as pageChildren } from '@/api/query/page';
import { children as privateNoteChildren } from '@/api/query/private-note';
import { searchTermFilter } from '@/lib/filter/search-term';

export const children = [
  { attr: UID },
  { attr: CREATED_AT },
  { attr: DGRAPH_TYPE },
  {
    attr: meetingConfig.edges.title,
    default: '',
  },
  {
    attr: meetingConfig.edges.icon,
    default: '',
  },
  {
    attr: meetingConfig.edges.isTemplate,
    default: false,
  },
  {
    attr: meetingConfig.edges.content,
    default: null,
  },
  {
    attr: meetingConfig.edges.participants,
    default: [],
    model: userConfig.model,
    children: userEdgesSlim,
  },
  {
    attr: meetingConfig.edges.date,
    default: null,
  },
  {
    attr: meetingConfig.edges.date,
    alias: 'nativeDate',
    default: null,
  },
  { attr: meetingConfig.edges.accessRight, default: accessPolicyType.read },
  {
    attr: meetingConfig.edges.accessPolicy,
    model: accessPolicy.model,
    default: null,
    children: accessPolicyChildren,
  },
  {
    attr: meetingConfig.edges.creator,
    default: null,
    model: userConfig.model,
    children: userEdgesSlim,
  },
  {
    attr: meetingConfig.edges.propertyValues,
    default: [],
    model: propertyValueConfig.model,
    children: propertyValueEdges,
  },
  {
    attr: reverseEdge(msCalendarEventConfig.edges.meeting),
    alias: 'msCalendarEvent',
    model: msCalendarEventConfig.model,
    assoc: ONE_TO_ONE,
    children: msCalendarEventChildren,
    default: null,
  },
];

export const meetingList = ({
  pagination,
  filter = null,
  varBlocks = [],
  searchTerm = '',
  order = [],
  alias = RESULT,
}) => [
  {
    model: meetingConfig.model,
    alias,
    func: { name: 'type', args: [{ value: meetingConfig.model }] },
    order,
    filter: combine(AND, [
      searchTermFilter({ searchTerm, edgeName: meetingConfig.edges.title }),
      filter,
      {
        op: NOT,
        child: [{ func: { attr: meetingConfig.edges.isTemplate, name: EQ, args: [{ value: 'true' }] } }],
      },
    ]),
    pagination,
    default: [],
    children,
  },
  ...varBlocks,
];

export const meetingDetailsQuery = (id) => [
  {
    alias: 'meetings',
    func: { name: UID },
    uid: [id],
    model: meetingConfig.model,
    children,
  },
  {
    alias: 'var',
    func: { name: UID },
    uid: [id],
    model: meetingConfig.model,
    children: [
      {
        attr: reverseEdge(privateNoteConfig.edges.meeting),
        model: privateNoteConfig.model,
        children: [
          { attr: UID, var: 'private_notes' },
        ],
      },
      {
        attr: reverseEdge(commentConfig.edges.meeting),
        model: commentConfig.model,
        children: [
          { attr: UID, var: 'comments' },
        ],
      },
      {
        attr: reverseEdge(collectionConfig.edges.meeting),
        model: collectionConfig.model,
        children: [
          { attr: UID, var: 'collections' },
          {
            attr: collectionConfig.edges.items,
            model: collectionItemConfig.model,
            children: [
              {
                attr: collectionItemConfig.edges.content,
                model: pageConfig.model,
                children: [
                  {
                    attr: UID,
                    var: 'pages',
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    alias: 'privateNotes',
    needsVar: [{ name: 'private_notes', typ: 1 }],
    func: { name: UID, needsVar: [{ name: 'private_notes', typ: 1 }] },
    model: privateNoteConfig.model,
    children: privateNoteChildren,
  },
  {
    alias: 'collections',
    needsVar: [{ name: 'collections', typ: 1 }],
    func: { name: UID, needsVar: [{ name: 'collections', typ: 1 }] },
    model: collectionConfig.model,
    children: collectionChildren,
  },
  {
    alias: 'comments',
    needsVar: [{ name: 'comments', typ: 1 }],
    func: { name: UID, needsVar: [{ name: 'comments', typ: 1 }] },
    model: commentConfig.model,
    children: commentChildren,
  },
  {
    alias: 'pages',
    needsVar: [{ name: 'pages', typ: 1 }],
    func: { name: UID, needsVar: [{ name: 'pages', typ: 1 }] },
    model: pageConfig.model,
    children: [
      ...pageChildren,
      {
        attr: reverseEdge(collectionItemConfig.edges.content),
        alias: 'collectionItem',
        assoc: ONE_TO_ONE,
        model: collectionItemConfig.model,
        children: [{ attr: UID }],
      },
    ],
  },
];

export const meetingsByTitle = (title) => [
  {
    model: meetingConfig.model,
    alias: 'meetings',
    func: { name: TYPE, args: [{ value: meetingConfig.model }] },
    filter: {
      func: {
        attr: meetingConfig.edges.title,
        name: 'regexp',
        args: [{ value: title }, { value: 'i' }],
      },
    },
    default: [],
    children,
  },
];

export const meetingTemplates = () => [
  {
    model: meetingConfig.model,
    alias: 'templates',
    func: { name: 'type', args: [{ value: meetingConfig.model }] },
    filter: { func: { attr: meetingConfig.edges.isTemplate, name: EQ, args: [{ value: 'true' }] } },
    default: [],
    children: [
      ...children,
      {
        attr: reverseEdge(collectionConfig.edges.meeting),
        model: collectionConfig.model,
        children: [
          { attr: UID, var: 'collections' },
          {
            attr: collectionConfig.edges.items,
            model: collectionItemConfig.model,
            children: [
              {
                attr: collectionItemConfig.edges.content,
                model: pageConfig.model,
                children: [
                  {
                    attr: UID,
                    var: 'pages',
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    alias: 'collections',
    needsVar: [{ name: 'collections', typ: 1 }],
    func: { name: UID, needsVar: [{ name: 'collections', typ: 1 }] },
    model: collectionConfig.model,
    children: collectionChildren,
  },
  {
    alias: 'pages',
    needsVar: [{ name: 'pages', typ: 1 }],
    func: { name: UID, needsVar: [{ name: 'pages', typ: 1 }] },
    model: pageConfig.model,
    children: [
      ...pageChildren,
      {
        attr: reverseEdge(collectionItemConfig.edges.content),
        alias: 'collectionItem',
        assoc: ONE_TO_ONE,
        model: collectionItemConfig.model,
        children: [{ attr: UID }],
      },
    ],
  },
];

export const meetingInsights = (meetingFilter, varBlocks) => [
  {
    alias: RESULT,
    func: { name: 'type', args: [{ value: userConfig.model }] },
    model: userConfig.model,
    children: [
      { attr: UID },
      { attr: userConfig.edges.firstName },
      { attr: userConfig.edges.lastName },
      { attr: userConfig.edges.profileImage },
      {
        attr: userConfig.edges.values,
        model: propertyValueConfig.model,
        children: propertyValueEdges,
      },
      {
        attr: reverseEdge(meetingConfig.edges.participants),
        alias: 'meetings',
        assoc: ONE_TO_ONE,
        model: meetingConfig.model,
        filter: meetingFilter,
        default: { count: 0 },
        children: [
          {
            attr: UID,
            isCount: true,
            alias: 'count',
          },
        ],
      },
    ],
  },
  ...varBlocks,
];
