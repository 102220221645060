<template>
  <div
    ref="itemTitleRef"
    class="item-title"
    :style="wrapTitle ? { whiteSpace: 'pre-wrap' } : { whiteSpace: 'nowrap' }"
  >
    <component
      :is="!isEmpty(icon.tooltip) ? 'm-tooltip' : 'div'"
      v-for="(icon, index) in icons.filter(i => !isEmptyIcon(i.value))"
      :key="index"
      :class="['_icon-wrapper']"
    >
      <m-icon-display
        class="_icon"
        :icon="icon.value"
        :size="icon.size ? icon.size : 14"
        :show-background="icon.showBackground"
      />
      <template
        v-if="icon.tooltip !== undefined && icon.tooltip !== ''"
        #title
      >
        {{ icon.tooltip }}
      </template>
    </component>
    <slot name="title">
      <span class="_title">
        {{ localTitle }}
      </span>
    </slot>
  </div>
</template>

<script>
import useElementsTruncate from 'shared/composables/element-truncate';
import { isEmpty } from 'shared/lib/object/object';
import { isEmptyIcon } from 'shared/lib/icon';
import { ref } from 'vue';

export default {
  name: 'ItemTitle',
  props: {
    icons: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    wrapTitle: {
      type: Boolean,
      default: false,
    },
    isTitleTruncated: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:isTitleTruncated'],
  setup() {
    const itemTitleRef = ref(null);
    const { isTruncated: localIsTitleTruncated } = useElementsTruncate([itemTitleRef]);
    return { itemTitleRef, localIsTitleTruncated };
  },
  computed: {
    localTitle() {
      return this.title === '' ? this.$t('general.untitled') : this.title;
    },
  },
  methods: { isEmpty, isEmptyIcon },
  watch: {
    localIsTitleTruncated(val) {
      this.$emit('update:isTitleTruncated', val);
    },
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  .item-title {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    user-select: none;
    padding: .1rem 0;

    ._icon-wrapper {
      position: relative;
      display: inline-block;
      flex-shrink: 0;
      margin-right: .6rem;
      vertical-align: middle;
    }

    ._title {
      vertical-align: middle;
    }
  }
</style>
