<template>
  <fullscreen-stepper
    :steps="steps"
    class="form-send"
    :current="currentStepIndex"
  >
    <transition
      name="list-left"
      mode="out-in"
    >
      <div>
        <div
          v-if="currentStepIndex === 0"
          :key="0"
          class="_step"
        >
          <page-header
            :title="currentStep.title"
            boxed-small
            heading="h2"
          />
          <m-content
            boxed-small
            padding
          >
            <form-send-settings
              :survey-process="surveyProcess"
              :form="form"
            />
          </m-content>
        </div>
        <div
          v-if="currentStepIndex === 1"
          :key="1"
          class="_step"
        >
          <page-header
            :title="currentStep.title"
            boxed-small
            heading="h2"
          />
          <m-content
            boxed-small
            padding
          >
            <h4>{{ $t('formSend.timing') }}</h4>
            <r-rule-picker
              v-model:value="schedule"
              :disabled="processIsActive"
            />
            <h4 class="_reminder-title">
              {{ $t('formSend.reminders') }}
              <span class="_additional-info">{{ $t('formSend.optional') }}</span>
            </h4>
            <survey-reminder-card
              :survey-process="surveyProcess"
              :disabled="processIsActive"
            />
          </m-content>
        </div>
        <div
          v-else-if="currentStepIndex === 2"
          :key="2"
          class="_step"
        >
          <page-header
            :title="currentStep.title"
            boxed-small
            heading="h2"
          />
          <m-content
            boxed-small
            padding
          >
            <account-scope-filter
              v-model:value="userScopeTree"
              :props="userProps"
              :account="loggedInUserAccount"
              :disabled="processIsActive"
              :all-text="$t('formSend.sendToAll')"
              :segment-text="$t('formSend.sendToSegments')"
              show-static-user-selection
              class="_filter"
            />
            <m-divider />
            <user-scope-tree-user-list
              :user-scope-tree="userScopeTree"
            />
          </m-content>
        </div>
        <div
          v-else-if="currentStepIndex === 3"
          :key="3"
          class="_step"
        >
          <page-header
            :title="currentStep.title"
            :sub-title="currentStep.subTitle"
            boxed-small
            heading="h2"
          />
          <m-content
            boxed-small
            padding
          >
            <page-title
              :title="$t('formSend.emailSettings')"
              :sub-title="$t('formSend.emailSettingsSubTitle')"
              heading="h4"
            />
            <form-send-channels
              :distribution-channel="distributionChannel"
              :lang="lang"
            />
          </m-content>
        </div>
        <div
          v-else-if="currentStepIndex === 4"
          :key="4"
          class="_step"
        >
          <page-header
            :title="currentStep.title"
            boxed-small
            heading="h2"
          />
          <m-content
            boxed-small
            padding
          >
            <h4>
              {{ $t('formSend.configuration') }}
            </h4>
            <m-divider />
            <form-send-summary
              :form="form"
              :survey-process="surveyProcess"
            />
          </m-content>
        </div>
      </div>
    </transition>
    <template #footer>
      <div

        class="_footer-slot"
      >
        <m-btn
          v-if="currentStepIndex > 0"
          class="_btn"
          :to="steps[currentStepIndex - 1].to"
        >
          {{ $t('general.back') }}
        </m-btn>
        <m-spacer />
        <m-btn
          v-if="currentStepIndex !== (steps.length - 1) && processActive"
          class="_btn"
          :to="steps[currentStepIndex + 1].to"
        >
          {{ $t('form.continue') }}
        </m-btn>
        <m-btn
          v-if="currentStepIndex !== (steps.length - 1) && !processActive"
          color="primary"
          class="_btn"
          :loading="loading"
          @click="saveSurveyProcess"
        >
          {{ $t('formSend.saveAndContinue') }}
        </m-btn>
        <m-btn
          v-if="currentStepIndex === (steps.length - 1) && !processActive"
          class="_btn"
          outlined
          color="primary"
          :loading="sendBtnLoading"
          @click="showActivatePrompt"
        >
          {{ $t('createFormTopBar.activateProcess') }}
        </m-btn>
      </div>
    </template>
  </fullscreen-stepper>
</template>

<script>
import AccountScopeFilter from '@/components/filter/AccountScopeFilter.vue';
import FormSendChannels from '@/components/FormSendChannels.vue';
import FormSendSettings from '@/components/FormSendSettings.vue';
import FormSendSummary from '@/components/FormSendSummary.vue';
import FullscreenStepper from '@/components/FullscreenStepper.vue';
import PageHeader from 'shared/components/PageHeader.vue';
import PageTitle from 'shared/components/PageTitle.vue';
import RRulePicker from '@/components/RRulePicker.vue';
import SurveyReminderCard from '@/components/SurveyReminderCard.vue';
import UserScopeTreeUserList from '@/components/UserScopeTreeUserList.vue';
import useForm from '@/composables/form/form';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useProperties from '@/composables/property/property';
import { DateTime } from 'luxon';
import {
  FORM_SEND_CHANNELS,
  FORM_SEND_OPTIONS,
  FORM_SEND_PARTICIPANTS,
  FORM_SEND_SETTINGS,
  FORM_SEND_VERIFY,
} from '@/route-names';
import { createProp } from '@/lib/props';
import { formSendSteps } from '@/bumblebee';
import { hasValidStartDate } from '@/lib/rrule';
import { mapActions } from 'vuex';
import { user as userConfig } from 'shared/api/query/configs.json';

export default {
  name: 'FormSend',
  props: {
    surveyProcess: {
      type: Object,
      required: true,
    },
  },
  components: {
    FormSendChannels,
    AccountScopeFilter,
    FormSendSettings,
    FormSendSummary,
    FullscreenStepper,
    UserScopeTreeUserList,
    SurveyReminderCard,
    PageTitle,
    RRulePicker,
    PageHeader,
  },
  setup() {
    const { formId, lang } = useForm();
    const { userProperties } = useProperties();
    const { loggedInUser, userLang } = useLoggedInUser();
    const { loggedInUserAccount } = useLoggedInUserAccount();
    return { userProperties, loggedInUser, userLang, loggedInUserAccount, formId, lang };
  },
  data() {
    return {
      sendBtnLoading: false,
      loading: false,
    };
  },
  computed: {
    userProps() {
      return this.userProperties.map((p) => createProp(p, false, true, true, this.userLang, userConfig.model));
    },
    currentStep() {
      return this.steps[this.currentStepIndex];
    },
    distributionChannel() {
      if (this.surveyProcess.distributionChannels.length === 0) {
        return null;
      }

      return this.surveyProcess.distributionChannels[0];
    },
    processActive() {
      return this.surveyProcess !== null && this.surveyProcess.activatedAt !== null;
    },
    steps() {
      return [
        {
          title: this.$t('general.settings'),
          key: formSendSteps.settings,
          to: { name: FORM_SEND_SETTINGS, params: { formId: this.formId } },
        },
        {
          title: this.$t('formSend.sendingOptions'),
          key: formSendSteps.sendingOptions,
          to: { name: FORM_SEND_OPTIONS, params: { formId: this.formId } },
        },
        {
          title: this.$t('formSend.participants'),
          key: formSendSteps.participants,
          to: { name: FORM_SEND_PARTICIPANTS, params: { formId: this.formId } },
        },
        {
          title: this.$t('formSend.channels'),
          subTitle: this.$t('formSend.channelSubTitle'),
          key: formSendSteps.channels,
          to: { name: FORM_SEND_CHANNELS, params: { formId: this.formId } },
        },
        {
          title: this.$t('formSend.verify'),
          key: formSendSteps.verify,
          to: { name: FORM_SEND_VERIFY, params: { formId: this.formId } },
        },
      ];
    },
    currentStepIndex() {
      return this.steps.map((s) => s.to.name).indexOf(this.$route.name);
    },
    userScopeTree: {
      get() {
        return this.$store.state.surveyProcess.userScopeTree;
      },
      set(val) {
        this.$store.commit('USER_SCOPE_TREE_CHANGED', { userScopeTree: val });
      },
    },
    processIsActive() {
      return this.surveyProcess.activatedAt !== null;
    },
    schedule: {
      get() {
        return this.$store.state.surveyProcess.schedule;
      },
      set(value) {
        this.$store.commit('UPDATE_SURVEY_PROCESS_SCHEDULE', { schedule: value });
      },
    },
    hasErrors() {
      return !hasValidStartDate(this.schedule);
    },
  },
  methods: {
    ...mapActions([
      'updateSurveyProcess',
    ]),
    activateSurvey() {
      this.sendBtnLoading = true;
      this.updateSurveyProcess({
        surveyProcess: {
          ...this.surveyProcess,
          activatedAt: DateTime.local().toISO({ suppressMilliseconds: true }),
        },
      }).then((response) => {
        this.sendBtnLoading = false;
        if (response.status !== 200) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
          return;
        }

        this.$showSnackbar({ color: 'success', message: this.$t('createFormTopBar.activated') });
      });
    },
    saveSurveyProcess() {
      this.loading = true;
      this.updateSurveyProcess({ surveyProcess: this.surveyProcess }).then((response) => {
        this.loading = false;
        if (response.status !== 200) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
          return;
        }
        this.$showSnackbar({ color: 'success', message: this.$t('success.saved') });
        this.$router.push(this.steps[this.currentStepIndex + 1].to);
      });
    },
    showActivatePrompt() {
      if (this.hasErrors) {
        this.$confirm({
          title: this.$t('formSend.errorsExist'),
          okText: this.$t('general.close'),
          maskClosable: true,
          okType: 'warning',
          hideCancel: true,
        });
        return;
      }
      const activateMethod = this.activateSurvey;
      this.$confirm({
        title: this.$t('createFormTopBar.activatePrompt'),
        okText: this.$t('createFormTopBar.activateNow'),
        okType: 'warning',
        maskClosable: true,
        cancelText: this.$t('general.cancel'),
        onOk() {
          activateMethod();
        },
      });
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .form-send {
    ._sub-title {
      color: $font-color-secondary;
    }

    ._reminder-title {
      margin-top: 2rem;

      ._additional-info {
        font-size: $font-size-4;
      }
    }

    ._footer-slot {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;

      ._btn {
        margin-left: .5rem;
      }
    }
  }
</style>
