<template>
  <div
    :class="['sub-menu-tabs', bordered ? '-bordered' : '']"
    :style="style"
    :height="height"
  >
    <div
      v-for="(item, i) in items"
      :id="item.id"
      :key="i"
      :style="itemStyle"
      :class="['_item', item.active ? '-selected' : '', small ? '-small': '']"
    >
      <m-btn
        :to="item.to"
        :icon="item.icon"
        :light="isLight(item)"
        :small="small"
        :disabled="item.disabled"
        class="_button"
        hide-border
      >
        {{ item.title }}
        <m-tag
          v-if="item.tag !== undefined"
          class="_tag"
          xs
          strong
          :custom-color="{ color: item.tag.color, backgroundColor: item.tag.backgroundColor }"
          :icon="item.tag.icon"
          :title="item.tag.title"
        />
        <plan-tag
          v-if="item.planTag !== undefined"
          class="_tag"
          :feature-flag="item.planTag.featureFlag"
          disabled
        />
      </m-btn>
    </div>
  </div>
</template>

<script>
import PlanTag from '@/components/plans/PlanTag.vue';

export default {
  name: 'SubMenuTabs',
  props: {
    items: {
      type: Array,
      required: true,
    },
    height: {
      type: String,
      default: 'auto',
    },
    bordered: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    light: {
      type: Boolean,
      default: false,
    },
  },
  components: { PlanTag },
  data() {
    return { backgroundColor: 'transparent' };
  },
  computed: {
    style() {
      const styles = {
        height: `${this.height}`,
        'background-color': this.backgroundColor,
      };
      return styles;
    },
    itemStyle() {
      return {
        height: `${this.height}`,
        'background-color': this.backgroundColor,
      };
    },
  },
  methods: {
    isLight(item) {
      if (this.light && item.active) {
        return false;
      }
      return this.light;
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .sub-menu-tabs {
    display: flex;

    &.-bordered {
      border-bottom: 1px solid $border-color;
    }

    ._item {
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 .8rem;
      border-bottom: 3px solid transparent;

      ._tag {
        margin-left: .6rem;
      }

      &.-selected {
        border-bottom: 3px solid $font-color-primary;
      }

      &.-small {
        padding-bottom: .4rem;
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
</style>
