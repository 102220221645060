import { ALL_OF_TEXT, OR, UID, VAR } from 'shared/api/query/constants';
import { combine, eqFilter, notHasFilter } from 'shared/api/query/filter';
import { property, propertyValue } from 'shared/api/query/configs.json';
import { reverseEdge } from 'shared/api/query/edges';

export const makeTextFn = (childrenFn, varIdFn) => (scope, index) => {
  const varName = varIdFn(index);

  if (scope.isEmpty === true) {
    const varBlocks = [
      {
        alias: VAR,
        uid: [scope.property.uid],
        model: property.model,
        func: { name: UID },
        children: [
          {
            attr: reverseEdge(propertyValue.edges.property),
            model: propertyValue.model,
            filter: combine(OR, [
              notHasFilter(propertyValue.edges.text),
              eqFilter(propertyValue.edges.text, ['']),
            ]),
            children: childrenFn(varName),
          },
        ],
      },
    ];
    const filterTree = {
      func: {
        name: UID,
        needsVar: [{ name: varName, typ: 1 }],
      },
    };
    return { filterTrees: [filterTree], varBlocks };
  }

  if (scope.text === undefined || scope.text === null || scope.text === '') {
    return { filterTrees: [], varBlocks: [] };
  }

  const varBlocks = [
    {
      alias: VAR,
      func: { name: UID },
      uid: [scope.property.uid],
      model: property.model,
      children: [
        {
          attr: reverseEdge(propertyValue.edges.property),
          model: propertyValue.model,
          filter: {
            func: {
              name: ALL_OF_TEXT,
              attr: propertyValue.edges.text,
              args: [{ value: scope.text }],
            },
          },
          children: childrenFn(varName),
        },
      ],
    },
  ];

  const filterTree = {
    func: {
      name: UID,
      needsVar: [{ name: varName, typ: 1 }],
    },
  };
  return { filterTrees: [filterTree], varBlocks };
};

export const directPropTextFn = (scope) => {
  if (scope.isEmpty === true) {
    return {
      filterTrees: [
        combine(OR, [
          notHasFilter(scope.directProperty.edgeName),
          eqFilter(scope.directProperty.edgeName, ['']),
        ]),
      ],
      varBlocks: [],
    };
  }

  if (scope.text === undefined || scope.text === null || scope.text === '') {
    return { filterTrees: [], varBlocks: [] };
  }

  return {
    filterTrees: [
      {
        func: {
          attr: scope.directProperty.edgeName,
          name: ALL_OF_TEXT,
          args: [{ value: scope.text }],
        },
      },
    ],
    varBlocks: [],
  };
};
