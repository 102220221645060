<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.5714 2.57143H3.42857C2.95519 2.57143 2.57143 2.95519 2.57143 3.42857V20.5714C2.57143 21.0447 2.95519 21.4286 3.42857 21.4286H20.5714C21.0447 21.4286 21.4286 21.0447 21.4286 20.5714V3.42857C21.4286 2.95519 21.0447 2.57143 20.5714 2.57143ZM3.42857 0H20.5714C22.465 0 24 1.53502 24 3.42857V20.5714C24 22.465 22.465 24 20.5714 24H3.42857C1.53502 24 0 22.465 0 20.5714V3.42857C0 1.53502 1.53502 0 3.42857 0Z"
      fill="currentColor"
    />
    <path
      d="M9 16.5C9 17.6046 8.10457 18.5 7 18.5C5.89543 18.5 5 17.6046 5 16.5C5 15.3954 5.89543 14.5 7 14.5C8.10457 14.5 9 15.3954 9 16.5Z"
      fill="currentColor"
    />
    <circle
      cx="12"
      cy="7.5"
      r="2"
      fill="currentColor"
    />
    <circle
      cx="17"
      cy="16.5"
      r="2"
      fill="currentColor"
    />
    <path
      d="M11.9824 7.49017L6.96973 16.5507H16.9955L11.9824 7.49017Z"
      stroke="currentColor"
    />
  </svg>
</template>

<script>
export default { name: 'NetworkIcon' };
</script>

<style scoped lang="scss" type="text/scss">

</style>
