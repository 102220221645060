export function getCookie(name) {
  const dc = document.cookie;
  const prefix = `${name}=`;
  let begin = dc.indexOf(`; ${prefix}`);
  let end = document.cookie.indexOf(';', begin);
  if (begin === -1) {
    begin = dc.indexOf(prefix);
    if (begin !== 0) return null;
  } else {
    begin += 2;
    if (end === -1) {
      end = dc.length;
    }
  }
  // because unescape has been deprecated, replaced with decodeURI
  // return unescape(dc.substring(begin + prefix.length, end));
  return decodeURI(dc.substring(begin + prefix.length, end));
}

export const objectifyCookie = (cookieString) => {
  const entries = cookieString.split('; ');
  return entries.reduce((res, entry) => {
    const k = entry.split('=', 1);

    return {
      ...res,
      [k]: entry.slice(`${k}=`.length, entry.length),
    };
  }, {});
};

export const getCookieValue = (name) => {
  const cookies = objectifyCookie(document.cookie);
  return cookies[name];
};

export function removeCookie(name, { path } = { path: '/' }) {
  document.cookie = `${name}=; Max-Age=-99999999; Path=${path}`;
}
