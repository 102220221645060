<template>
  <div class="asana-summary">
    <div class="_item">
      <div class="_label">
        {{ $t('asanaSummary.project') }}:
      </div>
      <div class="_content">
        {{ asanaQuery.projectName }}
      </div>
    </div>
    <div class="_item">
      <div class="_label">
        {{ $t('asanaSummary.operationMode') }}:
      </div>
      <div class="_content">
        {{ $t(`asanaForm.operationModes.${camelCase(asanaQuery.operationMode)}.label`) }}
      </div>
    </div>

    <div
      v-for="item in items"
      :key="item.label"
      class="_item"
    >
      <div class="_label">
        {{ item.label }}:
      </div>

      <m-dropdown
        v-if="criteriaP(item.sections, item.assignedTo)"
        :title="$t('general.actions')"
      >
        <m-link
          background-on-hover
          inherit-color
          class="_link"
        >
          <asana-task-selection-trigger
            class="_content -clickable"
            :sections="item.sections"
            :assigned-to="item.assignedTo"
            :wrap-text="false"
          />
        </m-link>
        <template #overlay>
          <m-card
            no-padding
            class="_overlay"
          >
            <asana-section-form
              :asana-query="asanaQuery"
              :sections="item.sections"
              :read-only="true"
            />
          </m-card>
        </template>
      </m-dropdown>

      <asana-task-selection-trigger
        v-else
        class="_content"
        :sections="item.sections"
        :assigned-to="item.assignedTo"
        :wrap-text="false"
      />
    </div>
    <div
      v-if="asanaQuery.operationMode === asanaOperationMode.singleTask"
      class="_item"
    >
      <div class="_label">
        {{ $t('asanaForm.task') }}
      </div>

      <div class="_content">
        {{ asanaQuery.task.name }}
      </div>
    </div>
  </div>
</template>

<script>
import AsanaSectionForm from '@/components/datasource/AsanaSectionForm.vue';
import AsanaTaskSelectionTrigger from '@/components/datasource/AsanaTaskSelectionTrigger.vue';
import { asanaOperationMode } from 'shared/constants.json';
import { camelCase } from 'lodash-es';
import { criteriaP } from '@/lib/asana';

export default {
  name: 'AsanaSummary',
  props: {
    asanaQuery: {
      type: Object,
      required: true,
    },
  },
  components: { AsanaTaskSelectionTrigger, AsanaSectionForm },
  data() {
    return {
      asanaOperationMode,
      camelCase,
      criteriaP,
    };
  },
  computed: {
    assignedTo() {
      return this.asanaQuery.assignedTo.map((m) => m.name).join(', ');
    },
    sections() {
      return this.asanaQuery.sections.map((s) => s.name).join(', ');
    },
    items() {
      switch (this.asanaQuery.operationMode) {
        case asanaOperationMode.overallProgress:
          return [
            {
              label: this.$t('asanaForm.countTasks'),
              sections: this.asanaQuery.sections,
              assignedTo: this.asanaQuery.assignedTo,
            },
            {
              label: this.$t('asanaForm.totalTasks'),
              sections: this.asanaQuery.overallSections,
              assignedTo: this.asanaQuery.overallAssignedTo,
            },
          ];
        case asanaOperationMode.countOfTasks:
          return [{
            label: this.$t('asanaForm.countTasks'),
            sections: this.asanaQuery.countSections,
            assignedTo: this.asanaQuery.countAssignedTo,
          }];
        case asanaOperationMode.singleTask:
          return [{
            label: this.$t('asanaForm.completionMeasuredBy'),
            sections: this.asanaQuery.taskCompletionSections,
            assignedTo: [],
          }];
        default:
          return [];
      }
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  ._overlay {
    width: 30rem;
  }

  .asana-summary {
    ._item {
      display: flex;

      ._label {
        flex: 0 0 17.6rem;
        color: $font-color-secondary;
      }

      ._link {
        border-radius: $border-radius-xs;

        &:hover {
          box-shadow: 0 0 0 1px $hover-color;
        }
      }
    }
  }
</style>
