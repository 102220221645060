<template>
  <m-dialog
    v-model:value="showOnboarding"
    :title="$t('checkinOnboardingModal.onboardingTitle')"
    hide-header
    hide-footer
    keep-open-on-mask-click
  >
    <onboarding-explanation-form
      :title="$t('checkinOnboardingModal.onboardingTitle')"
      :description="$t('checkinOnboardingModal.onboardingDescription')"
      :primary-text="$t('checkinOnboardingModal.createCheckin')"
      :secondary-button-text="$t('checkinOnboardingModal.explore')"
      @close="setCheckInTourAsDone"
      @ok="takeTour"
    />
  </m-dialog>
</template>

<script>
import OnboardingExplanationForm from '@/components/onboarding/OnboardingExplanationForm.vue';
import useAccountSettings from '@/composables/logged-in-user-account/account-settings';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useOnboardingMarker from '@/composables/onboarding/onboarding-marker';
import { onboardingSteps } from 'shared/constants.json';

export default {
  name: 'CheckinOnboardingModal',
  components: { OnboardingExplanationForm },
  setup() {
    const accountSettingsSvc = useAccountSettings();
    const { loggedInUserAccount } = useLoggedInUserAccount();
    const { markAsDone } = useOnboardingMarker(loggedInUserAccount, accountSettingsSvc);
    return { markAsDone };
  },
  data() {
    return { showOnboarding: false };
  },
  methods: {
    takeTour() {
      this.setCheckInTourAsDone();
      this.showOnboarding = false;
      this.$router.push({ query: { ...this.$route.query, showCheckinTour: true } });
    },
    setCheckInTourAsDone() {
      this.markAsDone([onboardingSteps.checkInTour])
        .catch(() => {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
        })
        .finally(() => {
          this.showOnboarding = false;
        });
    },
  },
  mounted() {
    this.showOnboarding = true;
  },
};
</script>

<style scoped lang="scss" type="text/scss">

</style>
