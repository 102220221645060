<template>
  <div
    :class="['collection-item-inner', selected ? '-selected' : '']"
    :data-id="item.uid"
  >
    <div
      v-if="draggingOverTop"
      class="_drag-over-top"
    />
    <div
      v-show="(hover || showModal) && !$store.state.breakpoint.smAndDown && !disabled"
      class="_handle"
      @mousedown="focus"
      @mouseup="focus"
      @click="focus"
    >
      <m-dropdown
        v-if="!dragging"
        v-model:value="showModal"
        :title="$t('general.actions')"
      >
        <m-btn
          icon="drag"
          hide-border
          small
          light
          fab
          :style="{ width: '2rem', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'grab' }"
        />
        <template #overlay>
          <m-card
            list
            no-padding
          >
            <m-card-item
              icon="delete"
              @click="deleteItem"
            >
              {{ $t('general.delete') }}
            </m-card-item>
          </m-card>
        </template>
      </m-dropdown>
    </div>
    <div
      :class="['_checkbox', item.checked ? '-checked' : '', disabled ? '-disabled' : '']"
      @click.stop="toggleChecked"
    >
      <m-icon
        v-if="item.checked"
        type="check"
        color="white"
        size="13"
      />
    </div>
    <div
      :class="['_title', item.checked ? '-checked' : '']"
      @click="handleTitleClick"
    >
      <div
        v-if="!pageIsEmpty"
        class="_icon"
      >
        <m-icon
          type="file-text"
          size="16"
        />
      </div>
      <div
        v-if="page.icon !== ''"
        class="_emoji"
      >
        <m-icon-display
          :icon="pageIcon"
        />
      </div>
      <div class="_text">
        <input
          ref="input"
          :value="localTitle"
          :disabled="disabled"
          @input="updateTitle"
          @keydown="handleKeyPress"
          @mousedown.stop
        >
      </div>
      <div
        v-show="hover && !$store.state.breakpoint.smAndDown"
        class="_actions"
      >
        <m-btn
          small
          icon="arrows-alt"
          @click="showContent = true"
        >
          {{ $t('general.open') }}
        </m-btn>
      </div>
    </div>
    <m-dialog
      v-model:value="showContent"
      :max-width="$modalSizes.xl"
      hide-footer
      no-padding
      keep-height
      hide-header
      top="7rem"
    >
      <editable-page
        v-if="showContent"
        :page="page"
        :breadcrumbs="breadcrumbs"
        :disabled="disabled"
        @close="showContent = false"
      />
    </m-dialog>
    <div
      v-if="draggingOverBottom"
      class="_drag-over-bottom"
    />
  </div>
</template>

<script>
import EditablePage from '@/components/page/EditablePage.vue';
import useDebounce from '@/composables/debounce';
import useSnackBar from '@/composables/snackbar';
import { buildIcon } from 'shared/lib/icon';
import { children as collectionItemChildren } from '@/api/query/collection-item';
import {
  collectionItem as collectionItemConfig, page as pageConfig,
} from 'shared/api/query/configs.json';
import { dogma } from '@/api';
import { logCatch } from '@/lib/logger/logger';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'CollectionItemInner',
  props: {
    item: {
      type: Object,
      required: true,
    },
    parentBreadcrumbs: {
      type: Array,
      default: () => [],
    },
    collectionId: {
      type: Number,
      required: true,
    },
    hover: {
      type: Boolean,
      default: false,
    },
    dragging: {
      type: Boolean,
      default: false,
    },
    focused: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    draggingOverTop: {
      type: Boolean,
      default: false,
    },
    draggingOverBottom: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['down', 'up', 'enter', 'deleted'],
  components: { EditablePage },
  setup() {
    const snackbar = useSnackBar();
    const { debounce, flush, cancel } = useDebounce();
    return {
      snackbar,
      debounce,
      debounceFlush: flush,
      debounceCancel: cancel,
    };
  },
  data() {
    return {
      showModal: false,
      show: false,
      localTitle: '',
      showContent: false,
    };
  },
  computed: {
    ...mapState({ pages: (state) => state.page }),
    pageIsEmpty() {
      if (this.page.content === null) {
        return true;
      }

      const c = this.page.content;
      return typeof c.content[0] === 'undefined'
          || typeof c.content[0].content === 'undefined';
    },
    breadcrumbs() {
      return [
        ...this.parentBreadcrumbs,
        { title: this.page.title, disabled: true },
      ];
    },
    page() {
      const f = this.pages.filter((p) => p.collectionItem.uid === this.item.uid);
      if (f.length === 0) {
        return null;
      }

      return f[0];
    },
    pageTitle() {
      return this.page.title;
    },
    pageIcon() {
      return buildIcon(this.page.icon);
    },
  },
  methods: {
    ...mapActions(['updateEntityV2', 'deleteEntityV2']),
    handleTitleClick() {
      if (!this.$store.state.breakpoint.smAndDown) {
        return;
      }

      this.showContent = true;
    },
    focus(event) {
      event.stopPropagation();
      this.$refs.input.focus();
    },
    updateTitle(event) {
      const val = event.target.value;
      if (val === this.localTitle) {
        return;
      }

      this.localTitle = val;

      const update = () => {
        this.$store.commit('ENTITY_UPDATED', {
          entity: { ...this.page, title: val },
          model: pageConfig.model,
        });
        this.savePage();
      };
      this.debounce(update, 500, 'updateTitle');
    },
    savePage() {
      dogma.updateSingle(
        this.page,
        pageConfig.model,
        [],
      ).then((response) => {
        if (response.status !== 200) {
          throw new Error('failed to update page');
        }
      }).catch(logCatch(() => {
        this.snackbar.error();
      }));
    },
    handleKeyPress(event) {
      if (event.key === 'ArrowDown') {
        this.$emit('down');
        return;
      }
      if (event.key === 'ArrowUp') {
        this.$emit('up');
        return;
      }
      if (event.key === 'Enter' && event.ctrlKey) {
        this.showContent = true;
        return;
      }
      if (event.key === 'Enter') {
        this.debounceFlush('updateTitle');
        this.$emit('enter');
        return;
      }
      if (event.key === 'Backspace' && this.localTitle === '') {
        this.deleteItem();
      }
    },
    deleteItem() {
      this.debounceCancel('updateTitle');

      this.deleteEntityV2({
        entity: this.item,
        model: collectionItemConfig.model,
        mutation: 'COLLECTION_ITEM_DELETED',
        options: { collectionId: this.collectionId },
        onDelete: () => {
          this.$emit('deleted');
        },
      }).then((response) => {
        if (response.status !== 200) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
        }
      });
    },
    toggleChecked() {
      if (this.disabled) {
        return;
      }

      this.save({ ...this.item, checked: !this.item.checked });
    },
    save(entity) {
      this.updateEntityV2({
        entity,
        model: collectionItemConfig.model,
        attributes: collectionItemChildren,
        mutation: 'COLLECTION_ITEM_UPDATED',
        options: { collectionId: this.collectionId },
      }).then((response) => {
        if (response.status !== 200) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
        }
      });
    },
  },
  watch: {
    focused(val) {
      if (val) {
        this.$refs.input.focus();
      }
    },
    showContent(val) {
      this.debounceFlush('updateTitle');
      if (!val) {
        this.$refs.input.focus();
      }
    },
    pageTitle(newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      }

      this.localTitle = newVal;
    },
  },
  mounted() {
    this.localTitle = this.page.title;
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  .collection-item-inner {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: .6rem;

    ._handle {
      position: absolute;
      top: .3rem;
      left: -2.4rem;
      cursor: grab;
    }

    ._drag-over-top {
      position: absolute;
      top: -2px;
      right: 0;
      left: 0;
      z-index: 88;
      width: 100%;
      height: 4px;
      pointer-events: none;
      background: $highlighted-color-dark;
      opacity: 1;
    }

    ._drag-over-bottom {
      position: absolute;
      right: 0;
      bottom: -2px;
      left: 0;
      z-index: 88;
      width: 100%;
      height: 4px;
      pointer-events: none;
      background: $highlighted-color-dark;
      opacity: 1;
    }

    ._checkbox {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1.6rem;
      height: 1.6rem;
      margin-right: .4rem;
      cursor: pointer;
      border: 1.5px solid map_get($grey, 'darken-2');

      &.-checked {
        background-color: $primary-color;
        border-color: $primary-color;
      }

      &.-disabled {
        cursor: initial;
      }
    }

    ._title {
      display: flex;
      flex: 1 1 auto;
      align-items: center;
      padding-left: 1.1rem;

      &.-checked {
        color: $font-color-tertiary;
        text-decoration: line-through;
      }

      ._icon {
        margin-right: .6rem;
      }

      ._emoji {
        margin-right: .4rem;
      }

      ._text {
        flex: 1 1 auto;

        input {
          display: flex;
          align-items: center;
          width: 100%;
          height: 3.2rem;
          overflow: hidden;
          font-size: $font-size-5;
          text-overflow: ellipsis;
          white-space: nowrap;
          background-color: transparent;
          border: none;
          outline: none;
        }
      }

      ._actions {
        position: absolute;
        top: .3rem;
        right: .3rem;
        background-color: white;
        border-radius: $border-radius-sm;
      }
    }

    &.-selected {
      background-color: $highlighted-color;
    }
  }

  ._overlay {
    ._text-field {
      margin: 0;
    }
  }
</style>
