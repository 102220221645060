import { GOAL_RELATION } from '@/lib/props/custom-types';
import { dateScopeDynamicType, dateScopeType, propertyType, userScopeOperator } from 'shared/constants.json';
import { isEmpty, isNullOrUndefined } from 'shared/lib/object/object';

export const AND = userScopeOperator.and;
export const OR = userScopeOperator.or;
export const NOT = userScopeOperator.not;
export const EMPTY = 'empty';
export const NOT_EMPTY = 'not_empty';

const isValidOperator = (operator) => {
  const allowed = [AND, OR, NOT];
  return allowed.includes(operator);
};

const hasUid = (object) => object !== null && object.uid !== undefined;

const hasValidDirectProperty = (scope) => !isNullOrUndefined(scope.directProperty)
  && !isNullOrUndefined(scope.directProperty.edgeName)
  && !isNullOrUndefined(scope.directProperty.type);

const hasValidProperty = (scope) => scope.property !== undefined
  && scope.property !== null
  && hasUid(scope.property)
  && scope.property.type !== undefined;

const hasValidStaticUsers = (scope) => !isNullOrUndefined(scope.staticUsers);

const isValidStaticUsers = (scope) => hasValidStaticUsers(scope) && Array.isArray(scope.staticUsers);

export const isValidTimeRange = (timeRange) => {
  if (isNullOrUndefined(timeRange)) {
    return false;
  }
  if (timeRange.type !== undefined && [dateScopeType.empty, dateScopeType.notEmpty].includes(timeRange.type)) {
    return true;
  }

  if (timeRange.dynamicType === undefined || timeRange.dynamicType === dateScopeDynamicType.exactDate) {
    return !isEmpty(timeRange.start) || !isEmpty(timeRange.end);
  }

  return dateScopeDynamicType.all.includes(timeRange.dynamicType)
    && dateScopeType.all.includes(timeRange.type)
    && !isEmpty(timeRange.amount);
};

export const isValidNumberRange = (numberRange) => {
  if (isNullOrUndefined(numberRange)) {
    return false;
  }

  return !isEmpty(numberRange.min) || !isEmpty(numberRange.max);
};

const isValidDateScope = (scope) => hasValidProperty(scope) && scope.property.type === propertyType.date && isValidTimeRange(scope.timeRange);

const isValidDirectDateScope = (scope) => hasValidDirectProperty(scope) && scope.directProperty.type === propertyType.date && isValidTimeRange(scope.timeRange);

const isValidNumberScope = (scope) => hasValidProperty(scope) && scope.property.type === propertyType.number && isValidNumberRange(scope.numberRange);

const isValidDirectNumberScope = (scope) => hasValidDirectProperty(scope) && scope.directProperty.type === propertyType.number && isValidNumberRange(scope.numberRange);

const isValidSpaceScope = (scope) => hasValidProperty(scope) && scope.property.type === propertyType.space && Array.isArray(scope.spaces);

const isValidDirectSpaceScope = (scope) => hasValidDirectProperty(scope) && scope.directProperty.type === propertyType.space && Array.isArray(scope.spaces);

const isValidOptionScope = (scope) => hasValidProperty(scope)
  && [propertyType.options, propertyType.singleSelect, propertyType.status].includes(scope.property.type)
  && Array.isArray(scope.selectedOptions);

const isValidDirectOptionScope = (scope) => hasValidDirectProperty(scope) && [propertyType.options, propertyType.singleSelect, propertyType.status].includes(scope.directProperty.type) && Array.isArray(scope.selectedOptions);

const isValidUsersScope = (scope) => hasValidProperty(scope) && scope.property.type === propertyType.user && Array.isArray(scope.users);

const isValidDirectUsersScope = (scope) => hasValidDirectProperty(scope) && scope.directProperty.type === propertyType.user && Array.isArray(scope.users);

const isValidDirectGoalScope = (scope) => hasValidDirectProperty(scope) && scope.directProperty.type === GOAL_RELATION && scope.relation !== undefined;

const isValidLookupScope = (scope) => hasValidProperty(scope)
  && scope.property.type === propertyType.lookup
  && (
    (
      scope.property.lookupProperty.type === propertyType.user
      && Array.isArray(scope.users)
    ) || (
      [propertyType.options, propertyType.singleSelect].includes(scope.property.lookupProperty.type)
      && Array.isArray(scope.selectedOptions)
    ) || (
      scope.property.lookupProperty.type === propertyType.space
      && Array.isArray(scope.spaces)
    )
  );

export const isValidScope = (scope) => {
  if (isNullOrUndefined(scope)) {
    return false;
  }

  return (
    isValidStaticUsers(scope)
    || isValidDateScope(scope)
    || isValidNumberScope(scope)
    || isValidSpaceScope(scope)
    || isValidOptionScope(scope)
    || isValidUsersScope(scope)
    || isValidDirectDateScope(scope)
    || isValidDirectNumberScope(scope)
    || isValidDirectSpaceScope(scope)
    || isValidDirectOptionScope(scope)
    || isValidDirectUsersScope(scope)
    || isValidDirectGoalScope(scope)
    || isValidLookupScope(scope)
  );
};

const isValidChildTree = (tree) => {
  if (tree === null) {
    return true;
  }
  if (!Array.isArray(tree.children) || tree.children.length === 0) {
    return isValidScope(tree.scope);
  }
  if (!isValidOperator(tree.op)) {
    return false;
  }
  return tree.children.reduce((prev, next) => prev && isValidChildTree(next), true);
};

export const isValidTree = (tree) => {
  if (tree === undefined) {
    return false;
  }
  if (tree === null) {
    return true;
  }
  if (isNullOrUndefined(tree.account) || !hasUid(tree.account)) {
    return false;
  }
  if ((!Array.isArray(tree.children) || tree.children.length === 0)
    && isNullOrUndefined(tree.scope)) {
    return true;
  }
  return isValidChildTree(tree);
};
