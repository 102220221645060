<template>
  <div class="icon-list">
    <div
      v-for="(group, category) in icons"
      :key="category"
      class="_content"
    >
      <sub-heading
        :title="$t(`iconList.${category}`)"
        class="_title"
        :m-style="{ fontStyle: { textTransform: 'uppercase' } }"
      />
      <div class="_list">
        <div
          v-for="(value, name) in group"
          :key="value"
          @click="handleSelect($event, { name, value })"
        >
          <div class="_icon">
            <template v-if="type=== 'emoji'">
              {{ value }}
            </template>
            <img
              v-else
              :style="{ width: '18px', height: '18px' }"
              :src="resolveFileName(`img/icons/ad/${value}.svg`)"
            >
          </div>
        </div>
      </div>
    </div>
    <m-dropdown
      v-if="variants.length > 0"
      ref="dropdown"
      v-model:value="showColorPicker"
      :get-trigger-dimensions="getTriggerDimensions"
      :title="$t('iconList.pickAColor')"
      :fullscreen-on-mobile="false"
    >
      <template #overlay>
        <m-card
          padding-xxs
          class="_variant-overlay"
        >
          <div class="_list">
            <div
              v-for="(variant, index) in variants"
              :key="index"
              class="_icon"
              @click="select({ name: `${selectedIcon.name}_${variant}`, value: `${selectedIcon.value}_${variant}` })"
            >
              <img
                :style="{ width: '18px', height: '18px' }"
                :src="resolveFileName(`img/icons/ad/${selectedIcon.value}_${variant}.svg`)"
              >
            </div>
          </div>
        </m-card>
      </template>
    </m-dropdown>
  </div>
</template>

<script>
import SubHeading from 'shared/components/SubHeading.vue';
import { environmentVariable, resolveEnvironmentVariable } from '@/lib/env';

export default {
  name: 'IconList',
  props: {
    icons: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: 'emoji',
    },
    variants: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['select'],
  components: { SubHeading },
  data() {
    return {
      selectedIcon: {},
      showColorPicker: false,
      getTriggerDimensions: () => ({ x: 0, y: 0, width: 0, height: 0 }),
    };
  },
  methods: {
    handleSelect(event, icon) {
      if (this.variants.length > 0) {
        this.selectedIcon = icon;
        this.getTriggerDimensions = () => event.target.parentElement.getBoundingClientRect();
        this.showColorPicker = true;
        return;
      }

      this.select(icon);
    },
    select(icon) {
      this.$emit('select', icon);
    },
    resolveFileName(filepath) {
      return resolveEnvironmentVariable(environmentVariable.PUBLIC_PATH) + filepath;
    },
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  .icon-list {
    ._title {
      margin-top: 1.6rem;
      margin-bottom: .2rem;
      margin-left: .3rem;
    }
  }

  ._list {
    display: flex;
    flex-wrap: wrap;

    ._icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 3.2rem;
      height: 3.2rem;
      font-family: "Apple Color Emoji", "Segoe UI Emoji", NotoColorEmoji, "Noto Color Emoji", "Segoe UI Symbol", "Android Emoji", EmojiSymbols, sans-serif;
      font-size: $font-size-7;

      &:hover {
        cursor: pointer;
        background-color: $hover-color;
        border-radius: $border-radius-sm;
      }
    }
  }

  ._variant-overlay {
    ._list {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
    }
  }
</style>
