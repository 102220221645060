import useUnassignedGoalCycle from '@/composables/goal-cycle/unassigned-goal-cycle';
import { UNASSIGNED } from '@/lib/constants';
import { computed, ref } from 'vue';

export default function useInMemoryGoalCycle() {
  const { unassignedCycle } = useUnassignedGoalCycle();
  const settings = ref({
    unassignedGoalCycleSelected: false,
    selectedGoalCycles: [],
  });

  const composedSelectedCycles = computed(() => {
    const res = [...settings.value.selectedGoalCycles];
    if (settings.value.unassignedGoalCycleSelected) {
      res.push(unassignedCycle);
    }
    return res;
  });

  const changeSelectedGoalCycles = (items) => {
    settings.value.selectedGoalCycles = items.filter((item) => item.uid !== UNASSIGNED);

    const unassignedCycle = items.find((c) => c.uid === UNASSIGNED);
    if (typeof unassignedCycle === 'undefined') {
      settings.value.unassignedGoalCycleSelected = false;
      return;
    }

    settings.value.unassignedGoalCycleSelected = true;
  };

  return {
    composedSelectedCycles,
    selectedGoalCycles: settings.value.selectedGoalCycles,
    unassignedGoalCycleSelected: settings.value.unassignedGoalCycleSelected,

    changeSelectedGoalCycles,
  };
}
