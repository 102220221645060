import { walkPath } from 'shared/lib/walker/path-walker';

export default function useFacets(references, config) {
  const addFacetToReference = (path) => (entity, parent, edge) => {
    const value = entity[edge];
    if (value === undefined) {
      return;
    }
    for (let i = 0; i < references[parent.uid][entity.uid].edges.length; i++) {
      if (references[parent.uid][entity.uid].edges[i].edge === path[path.length - 2]) {
        references[parent.uid][entity.uid].edges[i] = {
          ...references[parent.uid][entity.uid].edges[i],
          facetData: {
            ...references[parent.uid][entity.uid].edges[i]?.facetData,
            [edge]: value,
          },
        };
        return;
      }
    }
  };
  const extractFacets = (entities, model) => {
    if (config[model] === undefined) {
      return;
    }

    entities.forEach((e) => {
      walkPath(e, null, config[model].path, addFacetToReference(config[model].path));
    });
  };

  const applyFacetToEntity = (path) => (entity, parent) => {
    const edgeData = references[parent.uid][entity.uid].edges.find((e) => e.edge === path[path.length - 2]);
    if (edgeData.facetData === undefined) {
      return;
    }
    Object.keys(edgeData.facetData).forEach((key) => {
      entity[key] = edgeData.facetData[key];
    });
  };

  const applyFacets = (entity, model) => {
    if (entity === undefined || config[model] === undefined) {
      return entity;
    }

    walkPath(entity, null, config[model].path, applyFacetToEntity(config[model].path));
    return entity;
  };

  return {
    applyFacets,
    extractFacets,
  };
}
