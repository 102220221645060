<template>
  <m-input
    ref="input"
    :value="formattedValue"
    :read-only="readOnly"
    :disabled="disabled"
    :class="classes"
    :size="size"
    :small="small"
    :hide-hover="hideHover"
    :min="min"
    :max="max"
    :placeholder="placeholder"
    :full-width="fullWidth"
    :placeholder-icon="placeholderIcon"
    :hide-placeholder="hidePlaceholder"
    :hide-border="hideBorder"
    :has-error="hasError"
    :m-style="mStyle"
    @blur="applyFormat"
    @input="input"
    @change="change"
  >
    <template
      v-if="!!$slots.prefix"
      #prefix
    >
      <slot
        name="prefix"
      />
    </template>
    <template
      v-if="!!$slots.suffix"
      #suffix
    >
      <slot
        name="suffix"
      />
    </template>
  </m-input>
</template>

<script>
import { mStyleProps } from 'shared/lib/m-style-props';

export default {
  name: 'MInputNumber',
  props: {
    ...mStyleProps,
    value: {
      type: Number,
      default: null,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    hideBorder: {
      type: Boolean,
      default: false,
    },
    hideHover: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    placeholderIcon: {
      type: String,
      default: '',
    },
    hidePlaceholder: {
      type: Boolean,
      default: false,
    },
    min: {
      type: Number,
      default: null,
    },
    max: {
      type: Number,
      default: null,
    },
    formatter: {
      type: Function,
      default: (val) => val,
    },
    parser: {
      type: Function,
      default: (val) => val,
    },
    nullable: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input', 'update:value', 'change', 'blur'],
  data() {
    return { formattedValue: '' };
  },
  computed: {
    classes() {
      return [
        'm-input-number',
        this.fullWidth ? '-full-width' : '',
        this.hideBorder ? '-hide-border' : '',
      ];
    },
    size() {
      if (this.small) {
        return 'small';
      }

      return 'default';
    },
  },
  methods: {
    input(val) {
      const v = this.update(val);
      if (Number.isNaN(v)) {
        return;
      }
      this.$emit('input', v);
      this.$emit('update:value', v);
    },
    change(val) {
      const v = this.update(val);
      if (Number.isNaN(v)) {
        return;
      }
      this.$emit('change', v);
      this.$emit('update:value', v);
    },
    update(val) {
      this.formattedValue = val;
      if (val === '' && this.nullable) {
        return null;
      }
      const v = parseFloat(this.parser(val));
      if (Number.isNaN(v)) {
        return Number.NaN;
      }

      if ((this.max !== null && v > this.max) || (this.min !== null && v < this.min)) {
        return this.fix(v);
      }

      return v;
    },
    applyFormat() {
      this.formattedValue = this.formatter(this.value);
      this.$emit('blur');
    },
    fix(val) {
      if (this.max !== null && val > this.max) {
        return this.max;
      }
      if (this.min !== null && val < this.min) {
        return this.min;
      }

      return this.value;
    },
    focus() {
      if (typeof this.$refs.input === 'undefined' || this.$refs.input === null) {
        return;
      }
      this.$refs.input.focus();
    },
  },
  watch: {
    value() {
      this.formattedValue = this.formatter(this.value);
    },
  },
  beforeUnmount() {
    this.fix(this.value);
  },
  created() {
    this.formattedValue = this.formatter(this.value);
  },
};
</script>

<style lang="scss" type="text/scss">
</style>
