<template>
  <full-screen-spinner v-if="schedulesLoading" />
  <template v-else>
    <m-content
      padding-x="layout"
      class="_content-header"
    >
      <div class="_header">
        <div class="_left">
          {{ $t('updateSchedules.allSchedules') }}
        </div>
        <div class="_right">
          <div class="_search">
            <list-string-filter
              small
              @input="updateSearchTerm"
            />
          </div>
          <m-btn
            v-if="canCreate"
            color="primary"
            small
            :loading="createLoading"
            @click="create"
          >
            {{ $t('general.new') }}
          </m-btn>
        </div>
      </div>
    </m-content>
    <div class="update-schedules">
      <m-content
        padding-x="layout"
        class="_content"
      >
        <schedules-table
          :show-new-button="canCreate"
          :schedules="schedules"
        />
      </m-content>
    </div>
  </template>
</template>

<script>
import FullScreenSpinner from 'shared/components/FullScreenSpinner.vue';
import ListStringFilter from '@/components/list/ListStringFilter.vue';
import SchedulesTable from '@/components/update-schedules/SchedulesTable.vue';
import useAccess from '@/composables/access/access';
import useAccountSettings from '@/composables/logged-in-user-account/account-settings';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useOnboardingMarker from '@/composables/onboarding/onboarding-marker';
import useUpdateSchedules from '@/composables/update-schedules/update-schedules';
import { DateTime } from 'luxon';
import { accessGroupFlag, onboardingGuideSteps } from 'shared/constants.json';

export default {
  name: 'UpdateSchedules',
  components: {
    SchedulesTable,
    ListStringFilter,
    FullScreenSpinner,
  },
  setup() {
    const { userHasRight } = useAccess();
    const { schedules, fetchSchedules, schedulesLoading, createSchedule, createLoading } = useUpdateSchedules();

    const { loggedInUser } = useLoggedInUser();
    const accountSettingsSvc = useAccountSettings();
    const { loggedInUserAccount } = useLoggedInUserAccount();
    const { markAsDone, isDone } = useOnboardingMarker(loggedInUserAccount, accountSettingsSvc);

    return {
      markAsDone,
      isDone,
      userHasRight,
      allSchedules: schedules,
      fetchSchedules,
      schedulesLoading,
      createSchedule,
      createLoading,
      loggedInUser,
      loggedInUserAccount,
    };
  },
  data() {
    return { search: '' };
  },
  computed: {
    canCreate() {
      return this.userHasRight([accessGroupFlag.updateAdminAccess]);
    },
    schedules() {
      if (this.search === '') {
        return this.allSchedules;
      }

      return this.allSchedules.filter((n) => n.title.toLowerCase().indexOf(this.search.toLowerCase()) > -1);
    },
    timeSeries() {
      return [
        DateTime.local().toISO(),
      ];
    },
  },
  methods: {
    updateSearchTerm(val) {
      this.search = val;
    },
    create() {
      this.createSchedule()
        .then((schedule) => {
          if (!this.isDone(onboardingGuideSteps.setupSchedule)) {
            this.markAsDone([onboardingGuideSteps.setupSchedule]);
          }
          this.$router.push({ query: { notificationId: schedule.uid } });
        })
        .catch(() => {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
        });
    },
  },
  created() {
    this.fetchSchedules();
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  ._content-header {
    position: sticky;
    left: 0;
  }

  .update-schedules {
    ._content {
      position: relative;
      float: left;
      min-width: 100%;
    }
  }

  ._header {
    display: flex;
    padding-bottom: .6rem;

    ._left {
      display: flex;
      align-items: center;
      padding-left: 1.2rem;
      font-weight: $font-weight-semibold;
    }

    ._right {
      display: flex;
      margin-left: auto;

      ._search {
        margin-right: .4rem;
      }
    }
  }
</style>
