<template>
  <div class="ms-teams-account-details">
    <account-space-template
      :tabs="tabs"
      :show-top-bar="false"
      :show-mobile-menu-toggle="false"
      show-logout
    />
  </div>
</template>

<script>
import AccountSpaceTemplate from '@/views/AccountSpaceTemplate.vue';
import useAccess from '@/composables/access/access';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useViewNavigator from '@/composables/saved-views/navigator';
import { GOAL_FEED_VIEW, GOAL_INSIGHT_VIEW, GOAL_VIEW, UPDATE_VIEW, USER_VIEW } from '@/route-params';
import { accessGroupFlag, moduleFlag, routeName, viewApplication } from 'shared/constants.json';

export default {
  name: 'MSTeamsAccountDetails',
  setup() {
    const { accountHasFeature, userHasRight } = useAccess();
    const { loggedInUserAccount } = useLoggedInUserAccount();
    const { linkToView } = useViewNavigator();
    return {
      accountHasFeature,
      userHasRight,
      account: loggedInUserAccount,
      linkToView,
    };
  },
  components: { AccountSpaceTemplate },
  computed: {
    tabs() {
      const tabs = [];
      if (this.accountHasFeature([moduleFlag.goals])) {
        tabs.push({
          title: this.account.goalSettings.featureNamePlural,
          to: this.linkToView(GOAL_VIEW, viewApplication.goalAccount),
          view: GOAL_VIEW,
        });
      }
      if (this.accountHasFeature([moduleFlag.goals])) {
        tabs.push({
          title: `${this.$t('navigation.feed')}`,
          to: this.linkToView(GOAL_FEED_VIEW, viewApplication.updateFeedAccount),
          view: GOAL_FEED_VIEW,
        });
      }
      if (this.accountHasFeature([moduleFlag.updates])) {
        tabs.push({
          title: this.$t('navigation.updatesExplorer'),
          to: this.linkToView(UPDATE_VIEW, viewApplication.updateAccount),
          view: UPDATE_VIEW,
        });
      }
      if (this.accountHasFeature([moduleFlag.goals]) && this.userHasRight([accessGroupFlag.goalDashboardView])) {
        tabs.push({
          title: this.$t('navigation.goalInsights'),
          to: this.linkToView(GOAL_INSIGHT_VIEW, viewApplication.insightAccount),
          view: GOAL_INSIGHT_VIEW,
        });
      }
      tabs.push({
        title: this.$t('navigation.users'),
        to: this.linkToView(USER_VIEW),
        view: USER_VIEW,
      });
      return tabs;
    },
  },
  beforeRouteLeave(to, from, next) {
    if ([routeName.teamsAccountOkrs, routeName.teamsTeamOkrs, routeName.logIn, 'logout', routeName.teamsLogin].includes(to.name)) {
      next();
      return;
    }
    window.open(`/#${to.fullPath}`);
  },
};
</script>

<style scoped lang="scss" type="text/scss">
</style>
