import { NOT } from '@/lib/filter/scope-tree';

export const extractSpaceIds = ({ filter, properties, spaces }) => {
  if (filter === null) {
    return [];
  }

  return filter.children.reduce((acc, current) => {
    const spacesIds = current.children.reduce((a, c) => {
      const property = properties.find((p) => p.uid === c.scope.property.uid);
      if (c.scope.spaces === undefined
        || c.scope.spaces.length === 0
        || c.scope.property === undefined
        || property === undefined
      ) {
        return a;
      }

      const spacesIds = c.scope.spaces.map((o) => o.uid);

      if (current.op === NOT) {
        a.push(...spaces.filter((o) => !spacesIds.includes(o.uid)).map((o) => o.uid));
        return a;
      }

      a.push(...spacesIds);
      return a;
    }, []);
    acc.push(...spacesIds);

    return acc;
  }, []);
};
