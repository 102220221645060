<template>
  <div class="grid-page-tile-member-list">
    <user-list
      show-create-view
      show-views-selector
      :read-only="props.readOnly"
    />
  </div>
</template>

<script setup>
import UserList from '@/components/users/UserList.vue';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useUserDefaultProps from '@/composables/saved-views/user-default-props';
import useViewServiceInit from '@/composables/saved-views/view-service-init';
import { AND } from '@/lib/filter/scope-tree';
import { VIEWS_SERVICE } from '@/lib/constants';
import { provide } from 'vue';
import { useI18n } from 'vue-i18n';
import { viewApplication, viewType } from 'shared/constants.json';

const props = defineProps({
  gridPageTile: { type: Object, required: true },
  readOnly: { type: Boolean, default: false },
});

const { loggedInUserAccount } = useLoggedInUserAccount();

const viewServiceInitService = useViewServiceInit();
const i18n = useI18n();

const defaultPropsSvc = useUserDefaultProps();

const viewApp = viewApplication.gridPageTileMember;

const defaultView = {
  viewType: viewType.list,
  title: i18n.t('list.list'),
  gridPageTile: { uid: props.gridPageTile.uid },
  viewApplication: viewApp,
  params: {
    filter: { account: { uid: loggedInUserAccount.value.uid }, children: [], op: AND },
    order: [],
    props: [],
  },
};

let config = viewServiceInitService.defaultConfig();

const defaultProps = defaultPropsSvc.defaultProps;

config = viewServiceInitService.withGridPageTile(config, { uid: props.gridPageTile.uid });
config = viewServiceInitService.withDefaultView(config, defaultView);
config = viewServiceInitService.withDefaultProps(config, defaultProps);
config = viewServiceInitService.withViewApplication(config, viewApp);
config = viewServiceInitService.withReadOnly(config, props.readOnly);

const viewSvc = viewServiceInitService.gridPageViewService(config);
provide(VIEWS_SERVICE, viewSvc);

</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>

.grid-page-tile-member-list {
  padding: 0 1.6rem .8rem 1.6rem;
  height: inherit;
  overflow: auto;
}
</style>
