<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0)">
      <rect
        x="0.991211"
        y="4.50002"
        width="10.4953"
        height="1.55186"
        rx="0.775929"
        fill="currentColor"
      />
      <rect
        x="5.36426"
        y="18.3696"
        width="11.1335"
        height="1.74923"
        transform="rotate(-90 5.36426 18.3696)"
        fill="currentColor"
      />
      <path
        d="M1.65293 14.9353C1.28841 14.5739 1.28843 13.9847 1.65295 13.6234V13.6234C2.01296 13.2666 2.59331 13.2666 2.95332 13.6234L7.55085 18.1806C7.55085 18.1806 6.75126 18.9732 6.23892 19.481C4.76569 18.0207 2.69868 15.9718 1.65293 14.9353Z"
        fill="currentColor"
      />
      <path
        d="M9.52445 13.6234C9.88446 13.2666 10.4648 13.2666 10.8248 13.6234V13.6234C11.1893 13.9847 11.1894 14.5739 10.8248 14.9353C9.77912 15.9718 7.71214 18.0207 6.23892 19.481C5.72655 18.9732 4.92692 18.1806 4.92692 18.1806L9.52445 13.6234Z"
        fill="currentColor"
      />
      <rect
        width="10.4953"
        height="1.55186"
        rx="0.775929"
        transform="matrix(1 0 0 -1 12.5146 19.5)"
        fill="currentColor"
      />
      <rect
        width="11.1335"
        height="1.74923"
        transform="matrix(-4.37114e-08 1 1 4.37114e-08 16.8867 5.63043)"
        fill="currentColor"
      />
      <path
        d="M13.1764 9.06476C12.8118 9.42608 12.8119 10.0153 13.1764 10.3766V10.3766C13.5364 10.7335 14.1168 10.7335 14.4768 10.3766L19.0743 5.81942C19.0743 5.81942 18.2747 5.02684 17.7624 4.519C16.2891 5.97931 14.2221 8.02819 13.1764 9.06476Z"
        fill="currentColor"
      />
      <path
        d="M21.0479 10.3766C21.4079 10.7335 21.9882 10.7335 22.3483 10.3766V10.3766C22.7128 10.0153 22.7128 9.42608 22.3483 9.06476C21.3026 8.02818 19.2356 5.97931 17.7624 4.519C17.25 5.02684 16.4504 5.81942 16.4504 5.81942L21.0479 10.3766Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(24) rotate(90)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default { name: 'Threshold' };
</script>

<style scoped lang="scss" type="text/scss">

</style>
