<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 512 512"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M437.591 436.422C403.966 414.773 376.768 384.011 359.543 347.59C381.155 323.176 394.376 291.168 394.376 256.002C394.376 220.836 381.155 188.836 359.543 164.422C376.768 127.993 403.966 97.2393 437.591 75.5817C483.57 121.858 512 185.611 512 256.002C512 326.393 483.57 390.137 437.591 436.422ZM255.862 346.14C206.117 346.14 165.793 305.777 165.793 256.002C165.793 206.219 206.117 165.873 255.862 165.873C305.599 165.873 345.932 206.219 345.932 256.002C345.932 305.777 305.599 346.14 255.862 346.14Z"
      fill="#006CBB"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M314.521 130.739C296.726 122.411 276.942 117.623 255.993 117.623C179.569 117.623 117.624 179.575 117.624 256C117.624 332.425 179.569 394.377 255.993 394.377C276.942 394.377 296.726 389.589 314.521 381.261C333.412 417.229 360.134 448.435 392.484 472.542C352.975 497.493 306.189 512 255.993 512C114.615 512 0 397.38 0 256C0 114.611 114.615 0 255.993 0C306.189 0 352.975 14.4981 392.484 39.4667C360.134 63.5648 333.412 94.7712 314.521 130.739Z"
      fill="#2A2B3C"
    />
  </svg>
</template>

<script>
export default { name: 'Custify' };
</script>
