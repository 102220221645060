import { dataAggregation } from 'shared/num_constants.json';

export const isNotANumber = (value) => (Number.isNaN(value) || typeof value !== 'number');

export const getRoundedValue = (value, precision = 2) => Number.parseFloat(value).toFixed(precision);

export const getValueAsPercent = (value, precision = 2) => {
  if (isNotANumber(value)) {
    return '0 %';
  }
  return `${Number.parseFloat(value * 100).toFixed(precision)} %`;
};

export const getFormattedValue = (value, aggregation) => {
  if (isNotANumber(value)) {
    return '--';
  }
  switch (aggregation) {
    case dataAggregation.avg:
      return getRoundedValue(value, 1);
    case dataAggregation.top2:
      return getValueAsPercent(value, 0);
    case dataAggregation.top3:
      return getValueAsPercent(value, 0);
    case dataAggregation.favorability:
      return getRoundedValue(value * 100, 0);
    case dataAggregation.count:
      return getRoundedValue(value, 0);
    default:
      return getRoundedValue(value, 1);
  }
};
