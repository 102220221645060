<template>
  <pie-chart-card
    class="automated-card"
    :title="$t('goalInsightsHealth.automated.cardTitle')"
    :loading="loading"
    :chart-data="totalValue === 0 ? [] : data"
    clickable
    @click="handler($event)"
  >
    <template #header>
      <dashboard-card-header
        :title="$t('goalInsightsHealth.automated.cardTitle')"
        text-key="goalInsightsHealth.automated.cardHelp"
        :loading="loading"
      />
    </template>
  </pie-chart-card>
</template>

<script>
import DashboardCardHeader from '@/components/goal-insights/health/DashboardCardHeader.vue';
import PieChartCard from '@/components/dashboard/PieChartCard.vue';
import colors from 'shared/colors';
import useDebounce from '@/composables/debounce';
import useGoalTypeProperty from '@/composables/customize-page/goal-type-property';
import usePublishedAtFilter from '@/composables/goal/published-at-filter';
import useSubscription from '@/composables/subscription/subscription';
import { AND } from 'shared/api/query/filter';
import { GoalFilterHandler } from '@/lib/filter/goal/handler';
import { applyBaseFilter } from '@/lib/filter/base-filter';
import { automatedFilter } from '@/lib/filter/goal/automated-filter';
import { computed } from 'vue';
import { dataAutomation, goalProgressMeasurement } from 'shared/constants.json';
import { dogma } from '@/api';
import { goalTypesFilter } from '@/lib/filter/goal/goals-type-filter';
import { measurementTypesFilter } from '@/lib/filter/goal/measurement-types-filter';
import { queryAutomated } from '@/api/query/goal-insights-health';
import { textByLang } from 'shared/lib/language';
import { useStore } from 'vuex';

export default {
  name: 'AutomatedCard',
  props: {
    selectedCycles: {
      type: Array,
      required: true,
    },
    viewFilter: {
      type: Object,
      required: true,
    },
    goalType: {
      type: Object,
      required: true,
    },
  },
  emits: ['show-goal-list', 'show'],
  setup() {
    const store = useStore();
    const userLang = computed(() => store.getters.userLang);
    const { debounce } = useDebounce();
    const { subscribe } = useSubscription();

    const { goalTypeProperty } = useGoalTypeProperty();
    const { publishedAtFilter } = usePublishedAtFilter();
    return {
      debounce,
      subscribe,

      userLang,
      goalTypeProperty,
      publishedAtFilter,
    };
  },
  components: { DashboardCardHeader, PieChartCard },
  data() {
    return {
      loading: false,
      data: [
        { key: dataAutomation.automatic, label: this.$t('goalInsightsHealth.automated.labelAutomated'), value: 0, series: [], color: colors.dashboard.purple },
        { key: dataAutomation.manual, label: this.$t('goalInsightsHealth.automated.labelManual'), value: 0, series: [], color: colors.dashboard.blue },
      ],
    };
  },
  computed: {
    series() {
      return this.data.map((e) => e.series).flat().map((e) => e.uid);
    },
    totalValue() {
      return this.data.reduce((acc, e) => acc + e.value, 0);
    },
    gqlFilterObject() {
      const handler = new GoalFilterHandler();
      return handler.Translate(this.viewFilter);
    },
    gqlFilter() {
      return this.gqlFilterObject.filterTree;
    },
  },
  methods: {
    handler(bucket) {
      const bucketFilter = [];
      switch (bucket.key) {
        case dataAutomation.automatic:
          bucketFilter.push(automatedFilter());
          break;
        case dataAutomation.manual:
          bucketFilter.push(automatedFilter(true));
          break;
        default:
          break;
      }

      this.$emit('show-goal-list', {
        filter: {
          op: AND,
          children: [
            this.viewFilter,
            goalTypesFilter(this.goalTypeProperty, this.goalType),
            measurementTypesFilter([goalProgressMeasurement.continuous, goalProgressMeasurement.threshold]),
            ...bucketFilter,
          ],
        },
        title: this.$t('goalInsightsHealth.automated.dialogTitle', { label: bucket.label, goalType: textByLang(this.goalType.label, this.userLang) }),
      });
    },
    retrieveMeasurementTypesRestriction() {
      if (this.goalType.allowedMeasurementTypes.length > 0
          && !this.goalType.allowedMeasurementTypes.includes(goalProgressMeasurement.continuous)
          && !this.goalType.allowedMeasurementTypes.includes(goalProgressMeasurement.threshold)
      ) {
        this.$emit('show', false);
        return;
      }
      this.$emit('show', true);

      this.loading = true;
      dogma.query(applyBaseFilter({
        queries: [
          ...queryAutomated({
            goalCycles: this.selectedCycles,
            filter: this.gqlFilter,
            varBlocks: this.gqlFilterObject.varBlocks,
            goalTypeOption: this.goalType,
          }),
        ],
        operator: AND,
        baseFilter: this.publishedAtFilter,
      })).then((response) => {
        this.loading = false;
        if (response.status !== 200) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
          return;
        }

        this.data.forEach((e) => {
          switch (e.key) {
            case dataAutomation.automatic:
            case dataAutomation.manual:
              e.series = response.data[e.key];
              e.value = e.series.length;
              break;
            default:
              break;
          }
        });
      });
    },
    fetch(wait) {
      this.debounce(this.retrieveMeasurementTypesRestriction, wait, 'retrieveMeasurementTypesRestriction');
    },
  },
  watch: {
    selectedCycles(val, oldVal) {
      if (JSON.stringify(val) === JSON.stringify(oldVal)) {
        return;
      }
      this.fetch(1000);
    },
    gqlFilterObject: {
      handler(val, oldVal) {
        if (JSON.stringify(val) === JSON.stringify(oldVal)) {
          return;
        }
        this.fetch(1000);
      },
      deep: true,
    },
  },
  created() {
    this.fetch(0);

    const fn = this.fetch;
    this.subscribe({
      queryFn: Promise.resolve(),
      syncFn({ isSelfEvent }) {
        const delay = isSelfEvent ? 1000 : 30000;
        fn(delay);
      },
      model: 'goal',
      includeSelfEvents: true,
    });
  },
};
</script>
