export const OR = 'or';
export const AND = 'and';
export const NOT = 'not';
export const FUNC_EQ = 'eq';
export const FUNC_LE = 'le';
export const FUNC_GE = 'ge';
export const FUNC_LT = 'lt';
export const FUNC_GT = 'gt';
export const FUNC_REGEXP = 'regexp';

export const notFilter = (filters) => {
  if (typeof filters === 'undefined' || filters === undefined || (Array.isArray(filters) && filters.length === 0)) {
    return null;
  }

  let filtersCopy = filters;
  if (!Array.isArray(filters)) {
    filtersCopy = [filters];
  }

  return {
    op: NOT,
    child: filtersCopy,
  };
};

export const combine = (operator, filters) => {
  if (typeof filters === 'undefined' || !Array.isArray(filters) || filters.length === 0) {
    return null;
  }

  const filtered = filters.filter((f) => f !== null);
  if (filtered.length === 0) {
    return null;
  }

  if (filtered.length === 1) {
    return filtered[0];
  }
  return {
    op: operator,
    child: [...filtered],
  };
};

const args = (filterType, value) => {
  if (filterType === FUNC_REGEXP) {
    return [
      { value },
      { value: 'i' },
    ];
  }
  return [{ value }];
};

export const baseFilter = (filterType, operator, values, attribute) => {
  if (typeof values === 'undefined' || values === '') {
    return null;
  }

  let valueCopy = values;
  if (!Array.isArray(values)) {
    valueCopy = [values];
  }

  const filters = valueCopy.map((el) => ({
    func: {
      attr: attribute,
      name: filterType,
      args: args(filterType, el),
    },
  }));

  return combine(operator, filters);
};

export const eqFilter = (attribute, values, operator = OR) => baseFilter(FUNC_EQ, operator, values, attribute);

export const lteFilter = (attribute, values, operator = OR) => baseFilter(FUNC_LE, operator, values, attribute);

export const gteFilter = (attribute, values, operator = OR) => baseFilter(FUNC_GE, operator, values, attribute);

export const ltFilter = (attribute, values, operator = OR) => baseFilter(FUNC_LT, operator, values, attribute);

export const gtFilter = (attribute, values, operator = OR) => baseFilter(FUNC_GT, operator, values, attribute);

export const rangeFilter = (attribute, range) => {
  const minFilter = baseFilter(range.minType, OR, `${range.min}`, attribute);
  if (typeof range.max !== 'undefined' && range.max !== null) {
    return combine(AND, [
      minFilter,
      baseFilter(range.maxType, OR, `${range.max}`, attribute),
    ]);
  }
  return minFilter;
};

export const hasFilter = (attribute) => ({
  func: {
    attr: attribute,
    name: 'has',
  },
});

export const notHasFilter = (attribute) => notFilter([hasFilter(attribute)]);
