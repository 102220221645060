<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M3 6C3 5.37869 3.50369 4.875 4.125 4.875H19.875C20.4963 4.875 21 5.37869 21 6C21 6.62131 20.4963 7.125 19.875 7.125H4.125C3.50369 7.125 3 6.62131 3 6Z"
      fill="currentColor"
    />
    <path
      d="M5.625 12C5.625 11.3787 6.12869 10.875 6.75 10.875H17.25C17.8713 10.875 18.375 11.3787 18.375 12C18.375 12.6213 17.8713 13.125 17.25 13.125H6.75C6.12869 13.125 5.625 12.6213 5.625 12Z"
      fill="currentColor"
    />
    <path
      d="M8.25 18C8.25 17.3787 8.75369 16.875 9.375 16.875H14.625C15.2463 16.875 15.75 17.3787 15.75 18C15.75 18.6213 15.2463 19.125 14.625 19.125H9.375C8.75369 19.125 8.25 18.6213 8.25 18Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default { name: 'Funnel' };
</script>
