import { EventBus } from '@/lib/event-bus';
import { RESOURCE_CENTER_OKR_RESOURCES } from '@/lib/constants';
import { onboardingGuideSteps } from 'shared/constants.json';
import { useI18n } from 'vue-i18n';

import expandAndCollapse from '@/assets/img/illustrations/expand-and-collapse.png';
import okrRules from '@/assets/media/okr-rules.mp4';
import okrsEditing from '@/assets/media/okrs-editing.mp4';
import properties from '@/assets/img/illustrations/properties.png';
import sortingOnCascade from '@/assets/media/sorting-on-cascade.mp4';

export default function useOnboardingActions(onboardingMarker, resourceCenterRouter) {
  const { t } = useI18n();
  const inviteUsers = () => {
    EventBus.$emit('show-invite-users-dialog');
  };

  const showOnboardingVideo = () => {
    // TODO: replace with 101 video
    EventBus.$emit('show-video', 'https://www.loom.com/embed/bb0d6756412f4145b4eec195ccaeaeb6', { autoplay: true });

    return onboardingMarker.markAsDone([onboardingGuideSteps.welcomeDialog]);
  };

  const whyMooncampIsUniqueSteps = [
    {
      title: t('onboardingSlideShow.sorting.heading'),
      // eslint-disable-next-line global-require
      media: { type: 'video', src: sortingOnCascade },
      description: t('onboardingSlideShow.sorting.description'),
    },
    {
      title: t('onboardingSlideShow.properties.heading'),
      // eslint-disable-next-line global-require
      media: { type: 'image', src: properties, style: { height: '75%' } },
      description: t('onboardingSlideShow.properties.description'),
      link: { href: t('onboardingSlideShow.properties.link'), target: '_blank', text: t('general.learnMore') },
    },
    {
      title: t('onboardingSlideShow.bulkEdit.heading'),
      // eslint-disable-next-line global-require
      media: { type: 'video', src: okrsEditing, style: { height: '75%' } },
      description: t('onboardingSlideShow.bulkEdit.description'),
    },
    {
      title: t('onboardingSlideShow.expand.heading'),
      // eslint-disable-next-line global-require
      media: { type: 'image', src: expandAndCollapse, style: { height: '80%' } },
      description: t('onboardingSlideShow.expand.description'),
    },
    {
      title: t('onboardingSlideShow.okrRules.heading'),
      // eslint-disable-next-line global-require
      media: { type: 'video', src: okrRules, style: { height: '65%' } },
      description: t('onboardingSlideShow.okrRules.description'),
      link: { href: t('onboardingSlideShow.okrRules.link'), target: '_blank', text: t('general.learnMore') },
    },
  ];

  const showWhyMooncampIsUnique = () => {
    EventBus.$emit('show-slide-show', whyMooncampIsUniqueSteps);
    return onboardingMarker.markAsDone([onboardingGuideSteps.whyMooncampIsUnique]);
  };

  const checkOutOKRResources = () => {
    resourceCenterRouter.navigate(RESOURCE_CENTER_OKR_RESOURCES);
  };

  return {
    inviteUsers,
    showOnboardingVideo,
    checkOutOKRResources,
    showWhyMooncampIsUnique,
  };
}
