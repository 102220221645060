<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="14.8512"
      height="2.33333"
      transform="matrix(-1 0 0 1 18.8506 10.8333)"
      fill="currentColor"
    />
    <path
      d="M14.9257 5.85252C14.5274 5.3998 13.8231 5.40092 13.4249 5.85364L13.2174 6.08955C12.8852 6.46721 12.8852 7.03282 13.2174 7.41047L18.7937 13.7501C18.7937 13.7501 19.7319 12.6835 20.333 12C18.5919 10.0206 16.1439 7.23755 14.9257 5.85252Z"
      fill="currentColor"
    />
    <path
      d="M13.2174 16.5895C12.8852 16.9672 12.8852 17.5328 13.2174 17.9104L13.4249 18.1464C13.8231 18.5991 14.5274 18.6002 14.9257 18.1475C16.1439 16.7625 18.5919 13.9795 20.333 12C19.7319 11.3166 18.7937 10.2499 18.7937 10.2499L13.2174 16.5895Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default { name: 'ArrowGoForward' };
</script>

<style scoped lang="scss" type="text/scss">

</style>
