<template>
  <svg
    class="excel-online"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    fill="none"
  >
    <defs /><title>Excel_24x</title><g id="Excel">
      <g
        id="_24"
        data-name="24"
      >
        <path
          class="cls-1"
          d="M16,1H7A1,1,0,0,0,6,2V7l10,5,4,1.5L24,12V7Z"
          fill="#21a366"
        /><rect
          class="cls-2"
          width="24"
          height="24"
          fill="none"
        /><rect
          class="cls-3"
          x="6"
          y="7.02"
          width="10"
          height="4.98"
          fill="#107c41"
        /><path
          class="cls-4"
          d="M24,2V7H16V1h7A1,1,0,0,1,24,2Z"
          fill="#33c481"
        /><path
          class="cls-5"
          d="M16,12H6V22a1,1,0,0,0,1,1H23a1,1,0,0,0,1-1V17Z"
          fill="#185c37"
        /><path
          class="cls-6"
          d="M13.83,6H6V20h7.6A1.5,1.5,0,0,0,15,18.65V7.17A1.18,1.18,0,0,0,13.83,6Z"
          opacity="0.5"
        /><rect
          id="Back_Plate"
          data-name="Back Plate"
          class="cls-3"
          y="5"
          width="14"
          height="14"
          rx="1.17"
          fill="#107c41"
        /><path
          class="cls-7"
          d="M3.43,16,6,12,3.64,8H5.55l1.3,2.55a4.63,4.63,0,0,1,.24.54h0a5.77,5.77,0,0,1,.27-.56L8.76,8h1.75L8.08,12l2.49,4H8.71l-1.5-2.8A2.14,2.14,0,0,1,7,12.83H7a1.54,1.54,0,0,1-.17.36L5.3,16Z"
          fill="#fff"
        /><rect
          class="cls-3"
          x="16"
          y="12"
          width="8"
          height="5"
          fill="#107c41"
        />
      </g>
    </g>
  </svg>
</template>

<script>export default { name: 'ExcelOnline' }; </script>

<style scoped lang="scss" type="text/scss">
</style>
