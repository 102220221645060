import useGoalModifiers from '@/composables/goal/modifiers';
import useGoalTypeProperty from '@/composables/customize-page/goal-type-property';
import useGoals from '@/composables/goal/goals';
import { initChildrenSort } from '@/lib/goal/sort';

export default function useImportGoals(planning) {
  const goalsSvc = useGoals();

  const importGoals = ({ goalIds }) => {
    const goalsToImport = goalsSvc.selectMultiple(goalIds);

    const preparedGoals = prepare(goalsToImport);
    return goalsSvc.createMultiple(preparedGoals);
  };
  const { goalTypeProperty } = useGoalTypeProperty();
  const {
    getModifiers,
    setSelectedCycles,
    setDuplicate,
  } = useGoalModifiers(goalTypeProperty, goalsSvc.selectMultiple);

  setSelectedCycles(planning.goalCycles);

  const planningModifier = (goal) => ({
    ...goal,
    publishedAt: null,
    planning: { uid: planning.uid },
  });

  const prepare = (input) => {
    const { rids, entities } = input.reduce((res, g) => {
      res.rids[g.uid] = g.uid;

      setDuplicate(g);

      res.entities.push([...getModifiers(), planningModifier].reduce((res, m) => {
        res = m(res);
        return res;
      }, {}));

      return res;
    }, { rids: {}, entities: [] });

    const getParents = (goal) => goal.parents.reduce((res, next) => {
      if (typeof rids[next.uid] === 'undefined') {
        return res;
      }

      res.push({ uid: undefined, rid: rids[next.uid] });
      return res;
    }, []);

    const getPaysOnto = (goal) => goal.paysOnto.map((g) => {
      if (typeof rids[g.uid] === 'undefined') {
        if (typeof g.uid !== 'undefined') {
          return { uid: g.uid };
        }
        return g;
      }

      return { uid: undefined, rid: rids[g.uid] };
    });

    return entities.map((g) => ({
      ...g,
      childrenSort: initChildrenSort(g, rids),
      parents: getParents(g),
      paysOnto: getPaysOnto(g),
    }));
  };

  return {
    importGoals,
    importLoading: goalsSvc.createLoading,
  };
}
