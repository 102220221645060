<template>
  <component
    :is="tag"
    ref="editor"
    :class="['simple-editor', !readOnly ? '-editing' : '', fullWidth ? '-full-width':'']"
    :data-placeholder="placeholder"
    :contenteditable="!readOnly"
    @blur="handleBlur"
    @input="handleInput"
    @keydown="handleKeyDown"
    @paste="handlePaste"
  />
</template>

<script>
import ComponentList from '@/components/editor/ComponentList';
import i18n from '@/lang';
import useDebounce from '@/composables/debounce';
import useSimpleEditor from '@/components/simple-editor';

export default {
  name: 'SimpleEditor',
  props: {
    initialValue: {
      type: String,
      default: '',
    },
    tag: {
      type: String,
      default: 'div',
    },
    readOnly: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    enterHandler: {
      type: Function,
      default: () => {
      },
    },
    enterShiftHandler: {
      type: Function,
      default: () => {
      },
    },
    escapeHandler: {
      type: Function,
      default: () => {
      },
    },
    noTitleText: {
      type: String,
      default: i18n.t('list.noTitle'),
    },
    autoFocus: {
      type: Boolean,
      default: false,
    },
    autoSelect: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    preventScrollOnFocus: {
      type: Boolean,
      default: false,
    },
  },
  components: { ComponentList },
  emits: ['blur', 'update:value', 'enter', 'escape', 'ctrl-enter', 'shift-enter'],
  setup(props, { emit }) {
    const { debounce } = useDebounce();
    const {
      history,
      setRef,
      handlePaste,
      handleBlur,
      handleInput,
      handleKeyDown,
      setCaretToEnd,
      select,
    } = useSimpleEditor(debounce, emit, props.initialValue, window);
    return {
      history,
      setRef,
      handlePaste,
      handleBlur,
      handleInput,
      handleKeyDown,
      setCaretToEnd,
      select,
    };
  },
  computed: {
    localValue() {
      if (this.readOnly === false) {
        return this.initialValue;
      }

      if (this.initialValue === '') {
        return this.noTitleText;
      }

      return this.initialValue;
    },
  },
  methods: {
    focus() {
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.$refs.editor === null) {
            return;
          }
          this.$refs.editor.focus({ preventScroll: this.preventScrollOnFocus });
          if (this.autoSelect) {
            this.select();
            return;
          }
          this.setCaretToEnd();
        }, 10);
      });
    },
  },
  watch: {
    readOnly(newVal) {
      if (newVal === false) {
        if (this.initialValue === '') {
          this.$refs.editor.innerText = '';
        }
        if (this.autoFocus) {
          this.focus();
        }
      }
    },
    localValue(newVal, oldVal) {
      if (newVal === oldVal || this.$el === document.activeElement) {
        return;
      }

      this.$refs.editor.innerText = this.localValue;
    },
  },
  mounted() {
    this.$refs.editor.innerText = this.localValue;
    this.setRef(this.$refs.editor);
    if (this.autoFocus && !this.readOnly) {
      this.focus();
    }
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  .simple-editor {
    min-height: 2rem;
    outline: none;

    &.-full-width{
      width: 100%;
    }

    &.-editing {
      cursor: text;
    }

    &:empty::before {
      color: $font-color-tertiary;
      content: attr(data-placeholder);
    }
  }
</style>
