<template>
  <no-data v-if="chartData.length === 0" />
  <highcharts
    v-else
    :options="chartOptions"
    class="pie-chart"
  />
</template>

<script>
import NoData from '@/components/dashboard/NoData.vue';
import { Chart } from 'highcharts-vue';
import { rgbaToHex } from 'shared/lib/color';

export default {
  name: 'PieChart',
  props: {
    chartData: {
      type: Array,
      required: true,
    },
    clickable: {
      type: Boolean,
      required: false,
      default: false,
    },
    tooltip: {
      type: Boolean,
      default: false,
    },
  },
  components: { NoData, highcharts: Chart },
  emits: ['click'],
  data() {
    return { chartHeight: 250 };
  },
  computed: {
    colors() {
      return [{
        name: 'blue',
        code: this.$colors.dashboard.blue,
      }, {
        name: 'light-red',
        code: this.$colors.dashboard.orange,
      }, {
        name: 'lavender',
        code: this.$colors.dashboard.pink,
      }, {
        name: 'yellow',
        code: this.$colors.dashboard.yellow,
      }, {
        name: 'red',
        code: this.$colors.dashboard.red,
      }, {
        name: 'green',
        code: this.$colors.dashboard.green,
      }, {
        name: 'purple',
        code: this.$colors.dashboard.purple,
      }];
    },
    chartOptions() {
      const handleClick = this.handleClick;
      return {
        chart: {
          height: this.chartHeight,
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie',
        },
        tooltip: this.tooltip ? { pointFormat: '{point.y}' } : { enabled: false },
        plotOptions: {
          pie: {
            allowPointSelect: this.clickable,
            cursor: this.clickable ? 'pointer' : undefined,
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: {point.y}',
              color: rgbaToHex(this.$colors.grey.darken3),
              style: { textOutline: 'none' },
            },
            states: { hover: { halo: null, animation: { duration: 10 }, brightness: 0.05 } },
          },
        },
        series: [{
          point: {
            events: {
              click() {
                handleClick(this);
                return false;
              },
            },
          },
          colorByPoint: true,
          data: this.seriesData,
          colors: this.colorMap,
        }],
      };
    },
    colorMap() {
      return this.colors.map((c) => c.code);
    },
    totalValue() {
      let sum = 0;
      this.chartData.forEach((c) => {
        sum += c.value;
      });
      return sum;
    },
    seriesData() {
      const chartHeight = this.chartHeight;
      return this.chartData.map((c) => {
        const point = {};
        const color = this.colorByName(c.color);
        if (color !== undefined) {
          point.color = color;
        }
        return {
          ...point,
          y: c.value,
          name: c.label,
          dataLabels: {
            enabled: c.value !== 0,
            distance: c.value > this.totalValue / 4 ? -(chartHeight / 4) : 10,
          },
          original: c,
        };
      });
    },
  },
  methods: {
    colorByName(color) {
      if (color === undefined) {
        return undefined;
      }
      const mappedColor = this.colors.find((c) => (c.name === color));
      if (mappedColor !== undefined) {
        return mappedColor.code;
      }
      return color;
    },
    handleClick(point) {
      if (!this.clickable) {
        return;
      }
      this.$emit('click', point.original);
    },
  },
};
</script>

<style lang="scss" type="text/scss">
  .pie-chart {
    width: 100%;
    min-width: 250px;
    max-width: 600px;
  }
</style>
