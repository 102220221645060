<template>
  <div class="profile-settings">
    <input
      ref="fileinput"
      type="file"
      :style="{display: 'none'}"
      accept="image/*"
      @change="fileChanged"
    >
    <m-dialog
      v-model:value="showCropImage"
      :title="$t('profileSettings.setProfilePicture')"
      hide-footer
      keep-open-on-mask-click
      no-padding
    >
      <image-crop
        v-if="file !== null"
        class="_image-crop"
        :file="file"
        @cropped="uploadImage"
        @cancel="cancelImageCrop"
        @wrong-file-type="handleWrongFileType"
      />
    </m-dialog>

    <m-content>
      <page-header
        :title="$t('profileSettings.title')"
        boxed-xs
        heading="h3"
        underlined
        class="_header"
      />
      <m-content
        boxed-xs
        padding
        class="_content"
      >
        <m-section
          heading-size="h4"
          :title="$t('profileSettings.photo')"
          class="_profile-image _section"
        >
          <user-avatar
            :user="user"
            :size="170"
            class="_avatar"
          />
          <div class="_buttons">
            <m-tooltip :disabled="accountHasFeature([featureFlag.fileUpload])">
              <template #title>
                {{ $t('fileUpload.featureDisabled') }}
              </template>
              <m-btn
                :disabled="!accountHasFeature([featureFlag.fileUpload])"
                @click="triggerInput"
              >
                {{ $t('profileSettings.uploadImage') }}
              </m-btn>
            </m-tooltip>
            <m-btn
              v-if="user.profileImage !== null"
              @click="deleteImage"
            >
              {{ $t('profileSettings.deleteImage') }}
            </m-btn>
          </div>
        </m-section>
        <m-section
          heading-size="h4"
          :title="$t('profileSettings.sectionHeading')"
          class="_settings _section"
        >
          <div
            class="_form"
          >
            <m-form-item
              :label="$t('editUserForm.firstName')"
            >
              <m-text-field
                v-model:value="firstName"
              />
            </m-form-item>
            <m-form-item
              :label="$t('editUserForm.lastName')"
            >
              <m-text-field
                v-model:value="lastName"
              />
            </m-form-item>
            <m-form-item
              :label="$t('editUserForm.email')"
            >
              <m-text-field
                v-model:value="email"
                disabled
              />
            </m-form-item>
            <m-form-item
              :label="$t('editUserForm.language')"
            >
              <m-lang-picker
                v-model:value="language"
                :included-language-codes="availableSystemLanguageCodes"
                full-width
                match-trigger-width
                clearable
              />
            </m-form-item>
            <m-form-item
              :label="$t('editUserForm.timezone')"
            >
              <m-time-zone-picker
                :value="timezone"
                btn
                block
                :style="{ marginTop: '.4rem' }"
                @input="updateTimeZone"
              />
            </m-form-item>
          </div>
          <m-btn
            class="_button"
            color="primary"
            :loading="loading"
            @click="submit"
          >
            {{ $t('general.save') }}
          </m-btn>
        </m-section>
        <m-section
          v-if="showPasswordSection"
          heading-size="h4"
          :title="$t('profileSettings.password.sectionHeading')"
          class="_section"
        >
          <m-btn
            @click="showPasswordPrompt"
          >
            {{ $t('profileSettings.password.button') }}
          </m-btn>
        </m-section>
        <m-section
          :title="$t('profileSettings.connectMicrosoft')"
          class="_connect-microsoft _section"
          heading-size="h4"
          :sub-title="$t('profileSettings.connectMicrosoftDescription', { email: user.email})"
        >
          <div
            v-for="microsoftEmail in microsoftEmails"
            :key="microsoftEmail"
            class="_emails"
          >
            <div class="_row">
              <div class="_email">
                {{ microsoftEmail }}
              </div>
              <div class="_action">
                <m-btn
                  color="danger"
                  @click="deleteMicrosoftEmail(microsoftEmail)"
                >
                  {{ $t('profileSettings.deleteMicrosoftEmail') }}
                </m-btn>
              </div>
            </div>
          </div>
          <m-btn
            class="_connect"
            :href="`/api/v1/microsoft/user-sync?${encodeURLValues({origin: '/#/profile-settings'})}`"
            target="_blank"
          >
            {{ $t('profileSettings.connectMicrosoftAction') }}
          </m-btn>
        </m-section>
      </m-content>
    </m-content>
  </div>
</template>

<script>
import ImageCrop from '@/components/ImageCrop.vue';
import MLangPicker from '@/components/MLangPicker.vue';
import MTimeZonePicker from 'shared/components/base/MTimeZonePicker.vue';
import PageHeader from 'shared/components/PageHeader.vue';
import UserAvatar from 'shared/components/UserAvatar.vue';
import useAccess from '@/composables/access/access';
import useAppIntegration from '@/composables/app-integration';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import { AVAILABLE_SYSTEM_LANGUAGE_CODES } from '@/lib/language';
import { camelCase } from 'lodash-es';
import { changeLocale } from '@/lang';
import { doSendPasswordForgetMail } from '@/api';
import { encodeURLValues } from '@/lib/url';
import { featureFlag } from 'shared/constants.json';
import { logCatch } from '@/lib/logger/logger';
import { mapState } from 'vuex';
import { microsoftSyncStatus } from '@/constants.json';

export default {
  name: 'ProfileSettings',
  components: { MLangPicker, PageHeader, ImageCrop, UserAvatar, MTimeZonePicker },
  setup() {
    const { accountHasFeature } = useAccess();
    const { loggedInUserAccount } = useLoggedInUserAccount();
    const { loggedInUser, uploadProfileImage, deleteProfileImage, updateUser, updateUserLoading } = useLoggedInUser();
    const appIntegrationRepo = useAppIntegration();
    return {
      accountHasFeature,
      account: loggedInUserAccount,
      user: loggedInUser,
      updateUser,
      loading: updateUserLoading,
      uploadProfileImage,
      deleteProfileImage,
      appIntegrationRepo,
      featureFlag,
    };
  },
  data() {
    return {
      availableSystemLanguageCodes: AVAILABLE_SYSTEM_LANGUAGE_CODES,
      showCropImage: false,
      file: null,
      showMicrosoftHint: false,
      showSlackHint: false,
      encodeURLValues,
      timezone: '',
      firstName: '',
      lastName: '',
      email: '',
      language: '',
    };
  },
  computed: {
    ...mapState({ storageURL: (state) => state.storageURL }),
    microsoftEmails() {
      if (this.user.appIntegration === null) {
        return [];
      }
      return this.user.appIntegration.microsoftEmails;
    },
    showPasswordSection() {
      return !this.accountHasFeature([featureFlag.restrictPasswordLogin]);
    },
    showSlack() {
      if (!this.accountHasFeature([featureFlag.slack])) {
        return false;
      }

      if (typeof this.account.appIntegration === 'undefined' || this.account.appIntegration === null) {
        return false;
      }

      return this.account.appIntegration.slackBotAccessToken !== null;
    },
    showMicrosoftBot() {
      return this.accountHasFeature([featureFlag.microsoftBot]);
    },
    slackConnected() {
      if (this.user.appIntegration === null) {
        return false;
      }

      return this.user.appIntegration.slackBotUser !== '';
    },
    microsoftConnected() {
      if (this.user.appIntegration === null) {
        return false;
      }

      return this.user.appIntegration.microsoftBotConversationID !== '';
    },
    microsoftActivated() {
      if (this.user.appIntegration === null) {
        return false;
      }

      return this.user.notificationSetting.microsoft;
    },
    slackActivated() {
      if (!this.slackConnected) {
        return false;
      }

      return this.user.notificationSetting.slack;
    },
  },
  methods: {
    updateTimeZone(value) {
      this.timezone = value;
    },
    cancelImageCrop() {
      this.showCropImage = false;
      this.file = null;
    },
    handleWrongFileType() {
      this.showCropImage = false;
      this.file = null;
      this.$showSnackbar({ color: 'error', message: this.$t('error.notAnImage') });
    },
    submit() {
      this.update({
        uid: this.user.uid,
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        language: this.language,
      });
    },
    update(user) {
      const toUpdate = user;
      delete toUpdate.notificationSetting;
      delete toUpdate.appIntegration;
      delete toUpdate.values;
      delete toUpdate.profileImage;

      const personalAppSettings = { uid: this.user.personalAppSettings.uid, timezone: this.timezone };
      this.updateUser(toUpdate, personalAppSettings).then((updatedUser) => {
        changeLocale(updatedUser.language);
        this.timezone = updatedUser.personalAppSettings.timezone;
        this.$showSnackbar({ color: 'success', message: this.$t('success.updated') });
      }).catch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      });
    },
    showPasswordPrompt() {
      const sendMail = this.sendPasswordMail;
      this.$confirm({
        title: this.$t('profileSettings.password.promptTitle'),
        content: this.$t('profileSettings.password.content', { email: this.user.email }),
        okText: this.$t('profileSettings.password.okText'),
        okType: 'primary',
        maskClosable: true,
        cancelText: this.$t('general.cancel'),
        onOk() {
          sendMail();
        },
      });
    },
    sendPasswordMail() {
      doSendPasswordForgetMail({ email: this.user.email })
        .then((response) => {
          if (response.status !== 200) {
            this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
            return;
          }

          this.$showSnackbar({ color: 'success', message: this.$t('profileSettings.password.success') });
        });
    },
    uploadImage(blob) {
      this.uploadProfileImage(blob)
        .then(() => {
          this.file = null;
          this.showCropImage = false;
          this.$showSnackbar({ color: 'success', message: this.$t('success.updated') });
        }).catch(logCatch(() => {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
        }));
    },
    triggerInput() {
      this.$refs.fileinput.click();
    },
    fileChanged(event) {
      if (event.target.files.length === 0) {
        return;
      }

      this.file = event.target.files[0];
      this.showCropImage = true;
      this.$refs.fileinput.value = '';
    },
    deleteImage() {
      const deleteProfileImage = () => {
        this.deleteProfileImage().catch(() => {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
        });
      };
      this.$confirm({
        title: this.$t('profileSettings.deleteImagePrompt.content'),
        okText: this.$t('profileSettings.deleteImagePrompt.okText'),
        okType: 'danger',
        maskClosable: true,
        cancelText: this.$t('general.cancel'),
        onOk() {
          deleteProfileImage();
        },
      });
    },
    deleteMicrosoftEmail(email) {
      const deleteMethod = this.deleteMicrosoftEmailMethod;

      this.$confirm({
        title: this.$t('profileSettings.deleteMicrosoftEmailPrompt', { email }),
        okText: this.$t('general.yesDelete'),
        okType: 'danger',
        maskClosable: true,
        cancelText: this.$t('general.cancel'),
        onOk() {
          deleteMethod(email);
        },
      });
    },
    deleteMicrosoftEmailMethod(email) {
      const emails = this.user.appIntegration.microsoftEmails.filter((e) => e !== email);
      this.appIntegrationRepo.updateSingle({
        uid: this.user.appIntegration.uid,
        microsoftEmails: emails,
      }).catch(logCatch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      }));
    },
    handleMicrosoftSyncStatus() {
      if (typeof this.$route.query.microsoft_sync_status === 'undefined') {
        return;
      }

      switch (this.$route.query.microsoft_sync_status) {
        case microsoftSyncStatus.consentUrlError:
        case microsoftSyncStatus.syncError:
        case microsoftSyncStatus.readStateError:
          this.$showSnackbar({
            color: 'error',
            message: this.$t(`profileSettings.microsoftSyncStatus.${camelCase(this.$route.query.microsoft_sync_status)}`),
          });
          break;
        default:
          this.$showSnackbar({
            color: 'success',
            message: this.$t(`profileSettings.microsoftSyncStatus.${camelCase(microsoftSyncStatus.success)}`),
          });
      }
    },
  },
  watch: {
    showCropImage(newValue) {
      if (!newValue) {
        this.file = null;
      }
    },
  },
  mounted() {
    this.handleMicrosoftSyncStatus();
    this.timezone = this.user.personalAppSettings.timezone;
    this.firstName = this.user.firstName;
    this.lastName = this.user.lastName;
    this.email = this.user.email;
    this.language = this.user.language;
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .profile-settings {
    ._header {
      margin-bottom: 2rem;
    }

    ._content {
      padding-bottom: 4rem;
    }

    ._profile-image {
      ._avatar {
        display: block;
        margin-bottom: 2rem;
      }

      ._buttons {
        display: flex;
        gap: .8rem;
      }
    }

    ._settings {
      max-width: 35rem;
    }

    ._connect-microsoft {
      display: flex;
      flex-direction: column;

      ._emails {
        margin: 1.2rem 0;
      }

      ._row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1.2rem;
      }
    }

    ._section {
      margin-bottom: 4rem;
    }

    ._notification-channel {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1.6rem;
    }
  }
</style>
