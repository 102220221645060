import moonSVG from '@/assets/img/moon.svg';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import usePersonalAppSettings from '@/composables/logged-in-user/personal-app-settings';
import useRepo from '@/nebula/repo';
import {
  EVENTS,
  USER_DEMO,
  USER_STATUS_ACTIVE,
  USER_STATUS_INVITE_PENDING,
  USER_STATUS_NO_INVITATION,
} from '@/lib/constants';
import { EventBus } from '@/lib/event-bus';
import { useI18n } from 'vue-i18n';
import { user as userConfig } from 'shared/api/query/configs.json';

export default function useUsers() {
  const repo = useRepo(userConfig.model);

  const { loggedInUser } = useLoggedInUser();
  const { loggedInUserAccount } = useLoggedInUserAccount();
  const personalAppSettingsSvc = usePersonalAppSettings(loggedInUser);

  const i18n = useI18n();
  const mooncampBotUser = {
    firstName: i18n.t('user.bot.firstName'),
    lastName: i18n.t('user.bot.lastName'),
    src: moonSVG,
  };
  const deletedUser = {
    firstName: i18n.t('user.deleted.firstName'),
    lastName: i18n.t('user.deleted.lastName'),
  };

  const getInvitationStatus = (user) => {
    if (user.isDemo === true) {
      return USER_DEMO;
    }

    if (user.firstSessionCreatedAt !== null) {
      return USER_STATUS_ACTIVE;
    }

    if (user.invitation === undefined || user.invitation === null) {
      return USER_STATUS_NO_INVITATION;
    }

    return USER_STATUS_INVITE_PENDING;
  };

  const createUser = (user) => repo.createSingle({ ...user, account: { uid: loggedInUserAccount.value.uid } }).then((user) => {
    EventBus.$emit(EVENTS.USER.USERS_CREATED, { users: [user] });
    return user;
  });

  const createUsers = (users) => repo.createMultiple(users.map((user) => ({ ...user, account: { uid: loggedInUserAccount.value.uid } }))).then((users) => {
    EventBus.$emit(EVENTS.USER.USERS_CREATED, { users });
    return users;
  });

  const updateUser = (user, personalAppSettings = null) => repo.updateSingle(user).then((user) => {
    if (personalAppSettings !== null) {
      return personalAppSettingsSvc.updateSingle(personalAppSettings).then((personalAppSettings) => ({
        ...user,
        personalAppSettings,
      }));
    }
    return user;
  });

  const deleteUser = (user) => {
    const toDelete = user;
    return repo.deleteSingle(user.uid).then(() => {
      EventBus.$emit(EVENTS.USER.USERS_DELETED, { users: [toDelete] });
    });
  };

  const getUsersByName = (search) => {
    const s = search.split(' ').map((q) => q.toLowerCase());
    return repo.entityList.value.filter((u) => s.reduce((res, next) => {
      if (!res) {
        return res;
      }
      return u.firstName.toLowerCase().indexOf(next) > -1 || u.lastName.toLowerCase().indexOf(next) > -1;
    }, true));
  };

  return {
    mooncampBotUser,
    deletedUser,

    users: repo.entityList,
    selectSingle: repo.selectSingle,
    selectMultiple: repo.selectMultiple,
    getInvitationStatus,
    createUserLoading: repo.createLoading,
    createUser,
    createUsersLoading: repo.createLoading,
    createUsers,
    updateUserLoading: repo.updateLoading,
    updateUser,
    updateUsers: repo.updateMultiple,
    deleteUserLoading: repo.deleteLoading,
    deleteUser,
    fetchUsersLoading: repo.getListLoading,
    fetchUsers: repo.getList,

    getUsersByName,
  };
}
