import { DateTime } from 'luxon';

export const pageSize = 5;

export const pagination = (pages) => ({ first: (pages * pageSize).toString() });

export const filter = (searchTerm) => {
  if (searchTerm === '') {
    return { func: { name: 'ge', attr: 'start.dateTime', args: [{ value: DateTime.local().toISO() }] } };
  }

  return { func: { name: 'allofterms', attr: 'subject', args: [{ value: searchTerm }] } };
};
