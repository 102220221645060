import useManualSort from '@/composables/sort/manual-sort';
import { goal as goalConfig } from 'shared/api/query/configs.json';

export default function useGoalDetailSort(goalsSvc) {
  const updateChildrenSort = ({ entity, sortOperation }) => goalsSvc.updateSingle(
    { uid: entity.uid, childrenSort: entity.childrenSort },
    {},
    { sortOperation },
  );
  const { listBefore, listAfter } = useManualSort(goalConfig.edges.childrenSort, updateChildrenSort);
  return { listBefore, listAfter };
}
