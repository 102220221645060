<template>
  <item-wrapper
    v-if="text !== null"
    class="url-prop"
    :show-tooltip="showTooltip"
    :tooltip="prop.label"
  >
    <div
      :class="['_text', wrap ? '-wrap' : '']"
    >
      {{ text }}
      <m-tooltip>
        <div
          class="_btn"
          @click.stop
        >
          <m-btn
            v-if="value !== ''"
            icon="link"
            fab
            :href="value"
            target="_blank"
            small
          />
        </div>
        <template #title>
          {{ $t('mUrlField.openLink') }}
        </template>
      </m-tooltip>
    </div>
  </item-wrapper>
</template>

<script>
import ItemWrapper from '@/components/list/ItemWrapper.vue';
import { getValueFromEntity } from '@/lib/props';
import { shortenText } from '@/lib/text';

export default {
  name: 'UrlProp',
  props: {
    prop: {
      type: Object,
      required: true,
    },
    entity: {
      type: Object,
      required: true,
    },
    propertyValuesKey: {
      type: String,
      default: 'properties',
    },
    maxTextLength: {
      type: Number,
      default: 40,
    },
    showTooltip: {
      type: Boolean,
      default: false,
    },
    wrap: {
      type: Boolean,
      default: false,
    },
  },
  components: { ItemWrapper },
  computed: {
    value() {
      return getValueFromEntity(this.prop, this.entity, this.propertyValuesKey);
    },
    text() {
      const val = getValueFromEntity(this.prop, this.entity, this.propertyValuesKey);
      return shortenText(val, this.maxTextLength);
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .url-prop {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;

    ._text {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &.-wrap {
        white-space: normal;
      }
    }

    ._btn {
      position: absolute;
      top: -.4rem;
      right: .4rem;
      z-index: 9;
      display: none;
      background-color: white;
      border-radius: $input-field-border-radius;
    }

    &:hover {
      ._btn {
        display: block;
      }
    }
  }
</style>
