<template>
  <dashboard-card
    class="single-metric-card"
    :title="title"
    :info="info"
    :loading="loading"
    :clickable="clickable"
    @click="handleClick"
  >
    <div class="_content">
      <div :class="['_metric', metricPositive ? '-positive' : '', metricNegative ? '-negative' : '']">
        <template v-if="metric == null">
          -
        </template>
        <template v-else>
          {{ getRoundedValue(metric, minimumFractionDigits) }}{{ unit }}
        </template>
      </div>
      <div
        :class="['_delta', deltaPositive ? '-positive' : '', deltaNegative ? '-negative' : '']"
      >
        <span v-if="previousMetric === null">--</span>
        <span v-else-if="previousMetric !== undefined">
          {{ deltaAbsolute > 0 ? '+' : deltaAbsolute === 0 ? '±': '' }}{{ getRoundedValue(deltaAbsolute, minimumFractionDigits) }}{{ unit }} ({{ deltaRelative > 0 ? '+' : deltaRelative === 0 ? '±': '' }}{{ isFinite(deltaRelative) ? roundedDeltaRelative : "∞" }}%)
        </span>
      </div>
      <div
        class="_expected-metric"
      >
        <span v-if="expectedMetric !== undefined">
          {{ $t('singleMetricCard.expectedValue', { expectedValue: `${expectedMetric}${unit}` }) }}
        </span>
        <span v-else-if="expectedMetricFrom !== undefined && expectedMetricTo !== undefined">
          {{ $t('singleMetricCard.expectedValueRange', { from: `${expectedMetricFrom}${unit}`, to: `${expectedMetricTo}${unit}` }) }}
        </span>
      </div>
    </div>
  </dashboard-card>
</template>

<script>
import DashboardCard from '@/components/dashboard/DashboardCard.vue';
import { getRoundedValue } from '@/lib/charts/format';

export default {
  name: 'SingleMetricCard',
  props: {
    title: {
      type: String,
      required: true,
    },
    info: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    metric: {
      type: Number,
      required: true,
    },
    minimumFractionDigits: {
      type: Number,
      default: 0,
    },
    unit: {
      type: String,
      default: '',
    },
    metricNegativeThreshold: {
      type: Number,
      default: undefined,
    },
    metricPositiveThreshold: {
      type: Number,
      default: undefined,
    },
    previousMetric: {
      type: Number,
      default: undefined,
    },
    deltaNegativeThreshold: {
      type: Number,
      default: 0,
    },
    deltaPositiveThreshold: {
      type: Number,
      default: 0,
    },
    invertNegativePositive: {
      type: Boolean,
      default: false,
    },
    expectedMetric: {
      type: Number,
      default: undefined,
    },
    expectedMetricFrom: {
      type: Number,
      default: undefined,
    },
    expectedMetricTo: {
      type: Number,
      default: undefined,
    },
  },
  components: { DashboardCard },
  data() {
    return { getRoundedValue };
  },
  emits: ['click'],
  computed: {
    metricPositive() {
      if (this.metric === null || this.metricPositiveThreshold === undefined) {
        return false;
      }
      const res = this.metric >= this.metricPositiveThreshold;
      return this.invertNegativePositive ? !res : res;
    },
    metricNegative() {
      if (this.metric === null || this.metricNegativeThreshold === undefined) {
        return false;
      }
      const res = this.metric < this.metricNegativeThreshold;
      return this.invertNegativePositive ? !res : res;
    },
    deltaAbsolute() {
      return this.metric - this.previousMetric;
    },
    deltaRelative() {
      return (this.deltaAbsolute * 100) / this.previousMetric;
    },
    roundedDeltaRelative() {
      return getRoundedValue(this.deltaRelative, 0);
    },
    deltaPositive() {
      if (this.previousMetric === undefined || this.previousMetric === null || this.deltaPositiveThreshold === undefined) {
        return false;
      }
      const res = this.deltaAbsolute >= this.deltaPositiveThreshold;
      return this.invertNegativePositive ? !res : res;
    },
    deltaNegative() {
      if (this.previousMetric === undefined || this.previousMetric === null || this.deltaNegativeThreshold === undefined) {
        return false;
      }
      const res = this.deltaAbsolute < this.deltaNegativeThreshold;
      return this.invertNegativePositive ? !res : res;
    },
  },
  methods: {
    handleClick(event) {
      if (!this.clickable) {
        return;
      }

      this.$emit('click', event);
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .single-metric-card {
    height: 19.4rem;
    overflow: auto;

    ._content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      height: 100%;

      ._metric {
        font-size: $font-size-h1;
        font-weight: $font-weight-semibold;
        line-height: 1;
      }

      ._delta {
        min-height: 2.1rem;
        color: $font-color-secondary;
      }

      ._expected-metric {
        min-height: 2.1rem;
        margin-top: .4rem;
        color: $font-color-secondary;
      }

      .-negative {
        color: $error-color;
      }

      .-positive {
        color: $success-color;
      }
    }
  }
</style>
