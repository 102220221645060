import useAccessGroupsLoggedInUser from '@/composables/logged-in-user/access-groups-logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import {
  AND,
  accountCanActivateFeature as accountCanActivateFeatureLib,
  accountHasFeature as accountHasFeatureLib,
  userHasRight as userHasRightLib,
} from 'shared/lib/access';

export default function useAccess() {
  const { loggedInUserAccount } = useLoggedInUserAccount();
  const { accessGroups } = useAccessGroupsLoggedInUser();

  const accountCanActivateFeature = (flag) => accountCanActivateFeatureLib(flag, loggedInUserAccount.value.accountSettings?.planId);
  const userHasRight = (neededFlags, operator = AND) => userHasRightLib(neededFlags, accessGroups.value, operator);
  const accountHasFeature = (neededFlags) => accountHasFeatureLib(neededFlags, loggedInUserAccount.value);

  return {
    accountCanActivateFeature,
    userHasRight,
    accountHasFeature,
  };
}
