export const shortenText = (value, maxTextLength) => {
  if (value === null) {
    return '';
  }
  if (value.length < maxTextLength) {
    return value;
  }
  return `${value.substring(0, maxTextLength - 3)}...`;
};

export const obfuscateText = (value, afterN, beforeN) => {
  if (value === null) {
    return '';
  }
  return [...value].reduce((anon, c, i) => {
    if (i >= afterN && i < value.length - beforeN) {
      anon += '*';
      return anon;
    }
    anon += c;
    return anon;
  }, '');
};
