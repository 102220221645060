import { BaseHandler } from '@/lib/filter/base-translator/handler';
import { goal } from 'shared/api/query/configs.json';
import { reverseEdge } from 'shared/api/query/edges';

const childrenFn = (varName) => ([
  {
    attr: reverseEdge(goal.edges.properties),
    model: goal.model,
    var: varName,
  },
]);

export class GoalFilterHandler {
  constructor() {
    this.baseHandler = new BaseHandler({
      model: goal.model,
      childrenFn,
      isFilterMode: true,
    });
  }

  Translate(tree) {
    return this.baseHandler.Translate(tree);
  }
}
