<template>
  <update-feed
    show-views-selector
    :show-create-view="hasPublicViewAccess"
  />
</template>

<script setup>
import UpdateFeed from '@/components/update-feed/UpdateFeed.vue';
import useAccess from '@/composables/access/access';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useUpdateFeedDefaultProps from '@/composables/saved-views/update-feed-defaut-props';
import useViewServiceInit from '@/composables/saved-views/view-service-init';
import { AND } from '@/lib/filter/scope-tree';
import { CREATED_AT } from 'shared/api/query/constants';
import { VIEWS_SERVICE } from '@/lib/constants';
import { accessGroupFlag, viewApplication, viewType } from 'shared/constants.json';
import { computed, provide } from 'vue';
import { useI18n } from 'vue-i18n';

const viewApp = viewApplication.updateFeedAccount;

const i18n = useI18n();
const { loggedInUserAccount } = useLoggedInUserAccount();

const defaultPropsSvc = useUpdateFeedDefaultProps();
const viewServiceInit = useViewServiceInit();

const defaultView = {
  title: i18n.t('list.feed'),
  viewType: viewType.feed,
  viewApplication: viewApp,
  params: {
    filter: { account: { uid: loggedInUserAccount.value.uid }, children: [], op: AND },
    order: [{ attr: CREATED_AT, desc: true }],
    props: [],
  },
};

let viewConfig = viewServiceInit.defaultConfig();
viewConfig = viewServiceInit.withDefaultProps(viewConfig, defaultPropsSvc.defaultProps);
viewConfig = viewServiceInit.withDefaultView(viewConfig, defaultView);
viewConfig = viewServiceInit.withViewApplication(viewConfig, viewApp);

const viewSvc = viewServiceInit.routeAwareViewService(viewConfig);
provide(VIEWS_SERVICE, viewSvc);

const { userHasRight } = useAccess();
const hasPublicViewAccess = computed(() => userHasRight([accessGroupFlag.publicSavedViews]));

</script>
