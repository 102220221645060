<template>
  <div class="personal-details">
    <div class="_avatar">
      <user-avatar
        :user="user"
        :size="avatarSize"
        class="_img"
      />
    </div>
    <div class="_details">
      <div class="_name">
        <router-link
          v-if="hasProfileLink"
          :to="editProfileLink"
          class="_link"
        >
          {{ user.firstName }} {{ user.lastName }}
        </router-link>
        <template v-else>
          {{ user.firstName }} {{ user.lastName }}
        </template>
      </div>
      <div class="_email">
        {{ user.email }}
      </div>
    </div>
  </div>
</template>

<script>
import UserAvatar from 'shared/components/UserAvatar.vue';
import { PROFILE_SETTINGS } from '@/route-names';

export default {
  name: 'PersonalDetails',
  props: {
    user: {
      type: Object,
      required: true,
    },
    showEditButton: {
      type: Boolean,
      default: false,
    },
    hasProfileLink: {
      type: Boolean,
      default: false,
    },
  },
  components: { UserAvatar },
  data() {
    return { editProfileLink: { name: PROFILE_SETTINGS } };
  },
  computed: {
    avatarSize() {
      if (this.$store.state.breakpoint.smAndDown) {
        return 100;
      }
      return 120;
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .personal-details {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    ._avatar {
      margin-right: 4rem;
      margin-bottom: 1.2rem;
    }

    ._details {
      max-width: calc(100% - 7.5rem);
      margin-bottom: 1.2rem;

      ._name {
        margin: 0;
        overflow: hidden;
        font-size: $font-size-h2;
        font-weight: bold;
        color: $font-color-primary;
        text-overflow: ellipsis;

        ._link {
          color: $font-color-primary;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      ._email {
        overflow: hidden;
        color: $font-color-secondary;
        text-overflow: ellipsis;
      }
    }
  }
</style>
