<template>
  <div class="mobile-top-bar">
    <div class="_action">
      <m-btn
        icon="hamburger"
        fab
        hide-border
        large
        icon-size="24"
        @click="$emit('toggle')"
      />
    </div>
  </div>
</template>

<script>
export default { name: 'MobileTopBar', emits: ['toggle'] };
</script>

<style scoped lang="scss" type="text/scss">
  .mobile-top-bar {
    display: flex;
    align-items: center;
    height: $mobile-top-bar-height;
    padding: 0 .8rem;
  }
</style>
