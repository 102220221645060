import useGoalCycle from '@/composables/goal-cycle/goal-cycle';
import useGoalProperty from '@/composables/property/goal-property';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import { createPropsList } from '@/lib/props';
import { directProperties } from '@/lib/goal/properties';
import { goal as goalConfig } from 'shared/api/query/configs.json';

import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

export default function useGoalDefaultProps() {
  const { userLang } = useLoggedInUser();
  const i18n = useI18n();
  const { goalCycles } = useGoalCycle();

  const { properties: goalProperties } = useGoalProperty();

  const defaultProps = computed(() => createPropsList({
    properties: goalProperties.value,
    directProperties: directProperties(i18n.t, goalCycles.value),
    userLang: userLang.value,
    model: goalConfig.model,
  }));

  return { defaultProps };
}
