import { DEFAULT_ATTRIBUTES } from 'shared/api/query/constants';
import { accessPolicy, goalSettings } from 'shared/api/query/configs.json';
import { accessPolicyChildren } from '@/api/query/nebula/access-policy';
import { propertyVisibility, statusChartType } from 'shared/constants.json';

export const children = [
  ...DEFAULT_ATTRIBUTES,
  { attr: goalSettings.edges.redThreshold, default: 0 },
  { attr: goalSettings.edges.yellowThreshold, default: 0 },
  { attr: goalSettings.edges.featureNameSingular, default: '' },
  { attr: goalSettings.edges.featureNamePlural, default: '' },
  { attr: goalSettings.edges.goalCycleVisibility, default: propertyVisibility.alwaysVisible },
  { attr: goalSettings.edges.goalCycleChildrenVisibility, default: propertyVisibility.alwaysHidden },
  { attr: goalSettings.edges.goalParentVisibility, default: propertyVisibility.alwaysVisible },
  { attr: goalSettings.edges.goalProgressVisibility, default: propertyVisibility.alwaysVisible },
  { attr: goalSettings.edges.createdAtChildrenVisibility, default: propertyVisibility.alwaysHidden },
  { attr: goalSettings.edges.lastUpdatedAtChildrenVisibility, default: propertyVisibility.alwaysVisible },
  { attr: goalSettings.edges.descriptionVisibility, default: propertyVisibility.hideWhenEmpty },
  { attr: goalSettings.edges.propertyOrder, default: [] },
  { attr: goalSettings.edges.progressDashboardStatusChart, default: statusChartType.distribution },
  { attr: goalSettings.edges.childrenPropertyOrder, default: [] },
  {
    attr: goalSettings.edges.defaultAccessPolicy,
    default: null,
    model: accessPolicy.model,
    children: accessPolicyChildren,
  },
];
