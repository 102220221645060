<template>
  <div class="goal-type-list">
    <list-header divider>
      <template #right>
        <m-btn
          color="primary"
          :loading="createLoading"
          @click="create"
        >
          {{ $t('general.new') }}
        </m-btn>
      </template>
    </list-header>
    <div class="_list-content">
      <m-selectable
        v-model:value="selectedIds"
        selector-class="m-card-item"
        scroll-container-class="scroll-container"
        select-area-class="goal-types"
      >
        <m-draggable
          draggable-item-class="_handle"
          ghost-item-class="_item-inner"
          dragover-item-class="_item-inner"
          scroll-container-class="scroll-container"
          can-drag-over-top
          can-drag-over-bottom
          :recreate-key="goalTypes.length"
          @set-drag-item="setDragItem"
          @over-top="setOverTop"
          @over-bottom="setOverBottom"
          @drag-drop="handleDrop"
          @cancel="cancelDragging"
        >
          <template
            v-for="(item, index) in goalTypes"
            :key="item.uid"
          >
            <div
              class="_item"
              @contextmenu="handleContextMenu($event, item)"
            >
              <div
                v-if="index === 0 && draggingOverTop.includes(item.uid)"
                class="_drag-over-top"
              />
              <m-card-item
                class="_item-inner"
                :data-id="item.uid"
                :large="true"
                has-handle
                :selected="selectedIds.includes(item.uid)"
                :style="selectedIds.includes(item.uid) ? { borderRadius: '0rem' } : {}"
                @click="edit(item)"
              >
                <div class="_handle">
                  <m-icon
                    type="drag"
                    :color="$colors.grey.base"
                    size="16"
                  />
                </div>
                <div class="_tag">
                  <m-tag
                    :title="textByLang(item.label, userLang)"
                    :icon="buildIconFromEntity(item)"
                    :color="item.color"
                    automatic-color-fallback
                  />
                </div>
                <template #right>
                  <m-dropdown
                    :title="$t('general.actions')"
                    close-on-click
                    @click.stop
                  >
                    <m-btn
                      icon="ellipsis"
                      small
                      light
                      hide-border
                      fab
                    />
                    <template #overlay>
                      <m-card list>
                        <m-card-item
                          icon="edit"
                          @click="edit(item)"
                        >
                          {{ $t('general.edit') }}
                        </m-card-item>
                        <m-card-item
                          icon="copy"
                          :loading="duplicateLoading"
                          @click="duplicate([item])"
                        >
                          {{ $t('general.duplicate') }}
                        </m-card-item>
                        <m-tooltip
                          :disabled="!isNotDeletable([item])"
                          placement="bottom"
                        >
                          <m-card-item
                            icon="delete"
                            :disabled="isNotDeletable([item])"
                            @click="deleteEntities([item])"
                          >
                            {{ $t('general.delete') }}
                          </m-card-item>
                          <template #title>
                            {{ $t('goalType.singleRequired') }}
                          </template>
                        </m-tooltip>
                      </m-card>
                    </template>
                  </m-dropdown>
                </template>
              </m-card-item>
              <div
                v-if="draggingOverBottom.includes(item.uid)"
                class="_drag-over-bottom"
              />
            </div>
          </template>
        </m-draggable>
      </m-selectable>
      <m-btn
        icon="plus"
        hide-border
        large
        light
        block
        :style="{ justifyContent: 'flex-start', paddingLeft: '0.4rem' }"
        @click="create"
        @loading="createLoading"
      >
        {{ $t('general.new') }}
      </m-btn>
    </div>
  </div>
  <m-context-menu
    ref="contextmenu"
  >
    <m-card list>
      <m-card-item
        icon="copy"
        :loading="duplicateLoading"
        @click="contextDuplicate"
      >
        {{ $t('general.duplicate') }}
      </m-card-item>
      <m-tooltip
        :disabled="!isNotDeletable(selectedGoalTypes)"
        placement="bottom"
      >
        <m-card-item
          icon="delete"
          :disabled="isNotDeletable(selectedGoalTypes)"
          @click="deleteEntities(selectedGoalTypes)"
        >
          {{ $t('general.delete') }}
        </m-card-item>
        <template #title>
          {{ $t('goalType.oneOrMoreRequired') }}
        </template>
      </m-tooltip>
    </m-card>
  </m-context-menu>
  <m-drawer
    :value="showDetails"
    :title="$t('goalType.editTitle')"
    width="50rem"
    class="_drawer"
    :body-style="{ display: 'flex', flexDirection: 'column' }"
    @close="$refs.goalTypeForm.close()"
  >
    <goal-type-form
      ref="goalTypeForm"
      :option="selectedOption"
      :rule="routerRule"
      @close="hideDetails"
    />
  </m-drawer>
</template>

<script>
import GoalTypeForm from '@/components/goal-type/GoalTypeForm.vue';
import ListHeader from '@/components/list/ListHeader.vue';
import useGoalTypes from '@/composables/property/goal-type';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useSort from '@/composables/draggable/sort';
import { automaticColor } from 'shared/lib/color';
import { buildIconFromEntity } from 'shared/lib/icon';
import { guid } from 'shared/lib/uuid';
import { optionColor, routeName } from 'shared/constants.json';
import { textByLang } from 'shared/lib/language';

export default {
  name: 'GoalTypeList',
  components: { ListHeader, GoalTypeForm },
  data() {
    return {
      textByLang,
      showDetails: false,
      selectedOption: null,
      selectedIds: [],
    };
  },
  setup() {
    const {
      goalTypes,
      goalTypeProperty,
      updateOrder,
      deleteMultiple,
      createEntities,
      duplicateLoading,
      duplicateEntities,
      createLoading,
    } = useGoalTypes();
    const {
      setDragItem,
      setOverBottom,
      setOverTop,
      draggingOverBottom,
      draggingOverTop,
      dropItem,
      cancelDragging,
      dragItemId,
    } = useSort();
    const { userLang } = useLoggedInUser();
    return {
      dragItemId,
      setDragItem,
      setOverBottom,
      setOverTop,
      draggingOverTop,
      draggingOverBottom,
      dropItem,
      cancelDragging,
      goalTypes,
      goalTypeProperty,
      userLang,
      updateOrder,
      deleteMultiple,
      createEntities,
      duplicateLoading,
      duplicateEntities,
      createLoading,
    };
  },
  computed: {
    selectedGoalTypes() {
      return this.goalTypes.filter((t) => this.selectedIds.includes(t.uid));
    },
    routerOptionId() {
      if (this.$route.params.optionId === undefined) {
        return null;
      }
      return parseInt(this.$route.params.optionId, 10);
    },
    routerOption() {
      if (this.routerOptionId === null) {
        return null;
      }
      return this.goalTypes.find((t) => t.uid === this.routerOptionId);
    },
    routerRule() {
      if (this.$route.query.propKey === undefined) {
        return null;
      }
      return this.$route.query.propKey;
    },
  },
  methods: {
    buildIconFromEntity,
    hideDetails() {
      this.showDetails = false;
      this.selectedOption = null;
      this.$router.replace({ name: routeName.goalTypes });
    },
    contextDuplicate() {
      return this.duplicate(this.selectedGoalTypes);
    },
    handleContextMenu(event, item) {
      event.preventDefault();
      if (this.selectedIds.length === 0 || !this.selectedIds.includes(item.uid)) {
        this.selectedIds = [item.uid];
      }
      this.$refs.contextmenu.show(event);
    },
    create() {
      this.createEntities([{
        label: { de: '', en: '' },
        color: automaticColor(guid(), optionColor.all),
        property: { uid: this.goalTypeProperty.uid },
      }]).then((goalTypes) => {
        this.selectedOption = goalTypes[0];
        this.showDetails = true;
        this.$router.push({ ...this.$route });
      });
    },
    duplicate(items) {
      this.duplicateEntities(items.map((item) => ({ ...item, property: { uid: this.goalTypeProperty.uid } })))
        .then((goalTypes) => {
          this.$refs.contextmenu.hide();
          this.selectedIds = goalTypes.map((t) => t.uid);
          if (goalTypes.length > 1) {
            return;
          }

          this.selectedOption = goalTypes[0];
          this.showDetails = true;
        });
    },
    edit(item) {
      this.selectedOption = item;
      this.showDetails = true;
    },
    isNotDeletable(items) {
      return items.some((item) => item.isObjective === true || item.isKeyResult === true);
    },
    deleteEntities(items) {
      const title = this.$t('goalTypeList.deletePrompt');
      const okText = this.$t('goalTypeList.yes');
      const cancelText = this.$t('general.cancel');
      const { deleteMultiple, $showSnackbar } = this;
      this.$refs.contextmenu.hide();

      this.$confirm({
        title,
        okText,
        okType: 'danger',
        cancelText,
        maskClosable: true,
        onOk(setLoading) {
          setLoading(true);
          deleteMultiple(items.map((i) => i.uid)).then(() => {
            setLoading(false);
            $showSnackbar({ color: 'success', message: this.$t('success.deleted') });
          });
        },
      });
    },
    handleDrop() {
      const newOrder = this.dropItem(this.goalTypes).map(({ uid }) => ({ uid }));
      this.updateOrder(newOrder);
    },
  },
  watch: {
    routerOption: {
      handler(val) {
        if (val !== undefined && val !== null && this.selectedOption === null) {
          this.edit(val);
        }
      },
      immediate: true,
    },
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
.goal-type-list {
  ._item {
    position: relative;

    ._item-inner {
      border-radius: $default-border-radius;
    }

    ._handle {
      position: absolute;
      top: 50%;
      left: 1.8rem;
      cursor: grab;
      transform: translate(-50%, -50%);
    }

    ._tag {
      margin-left: 1.2rem;
    }

    ._drag-over-top {
      position: absolute;
      top: -2px;
      right: 0;
      left: 0;
      z-index: 88;
      width: 100%;
      height: 4px;
      pointer-events: none;
      background: $highlighted-color-dark;
      opacity: 1;
    }

    ._drag-over-bottom {
      position: absolute;
      right: 0;
      bottom: -2px;
      left: 0;
      z-index: 88;
      width: 100%;
      height: 4px;
      pointer-events: none;
      background: $highlighted-color-dark;
      opacity: 1;
    }
  }
}
</style>
