<template>
  <div
    :class="['m-chip', small ? '-small' : '']"
    :style="style"
  >
    <div
      v-if="!!$slots.left"
      class="_left"
    >
      <slot name="left" />
    </div>
    <div class="_content">
      <slot />
    </div>
  </div>
</template>

<script>
import colors from 'shared/colors';

export default {
  name: 'MChip',
  props: {
    small: {
      type: Boolean,
      default: false,
    },
    backgroundColor: {
      type: String,
      default: colors.grey.lighten2,
    },
    color: {
      type: String,
      default: colors.grey.darken3,
    },
  },
  computed: {
    style() {
      return {
        'background-color': this.backgroundColor,
        color: this.color,
      };
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .m-chip {
    display: flex;
    align-items: center;
    width: fit-content;
    padding: .1rem 1.2rem;
    font-size: $font-size-base;
    border-radius: 1.2rem;

    ._left {
      margin-right: .5rem;
    }

    &.-small {
      font-size: $font-size-2;
    }
  }
</style>
