<template>
  <m-dialog
    v-model:value="showModal"
    :max-width="$modalSizes.xl"
    no-padding
    keep-height
    hide-header
    top="7rem"
    hide-footer
  >
    <company-info-editor
      :company-info="companyInfo"
      fullscreen
      show-footer
      :ok-text="$t('general.close')"
      padding-top
      disabled
      :ok-handler="hide"
    />
  </m-dialog>
</template>

<script>
import useCompanyInfo from '@/composables/logged-in-user-account/company-info';
import { EventBus } from '@/lib/event-bus';
import { defineAsyncComponent } from 'vue';

export default {
  name: 'CompanyInfoDialog',
  components: { CompanyInfoEditor: defineAsyncComponent(() => import('@/components/CompanyInfoEditor.vue')) },
  setup() {
    const { companyInfo } = useCompanyInfo();
    return { companyInfo };
  },
  data() {
    return { showModal: false };
  },
  methods: {
    hide() {
      this.showModal = false;
    },
    show() {
      this.showModal = true;
    },
  },
  created() {
    EventBus.$on('show-company-info', this.show);
  },
  beforeUnmount() {
    EventBus.$off('show-company-info', this.show);
  },
};
</script>

<style scoped lang="scss" type="text/scss">

</style>
