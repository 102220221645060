const hasApplication = (child, model) => {
  if (child.children === undefined) {
    return false;
  }

  for (let i = 0; i < child.children.length; i++) {
    if (child.children[i].scope.property === undefined && child.children[i].scope.directProperty === undefined) {
      return false;
    }
    if (child.children[i].scope.property !== undefined && child.children[i].scope.property.model === model) {
      return true;
    }
    if (child.children[i].scope.directProperty !== undefined && child.children[i].scope.directProperty.model === model) {
      return true;
    }
  }
  return false;
};

export const extractFilter = (filter, model) => {
  if (filter === undefined || filter === null || !Array.isArray(filter.children)) {
    return null;
  }

  const children = filter.children.filter((child) => hasApplication(child, model));
  if (children.length === 0) {
    return null;
  }

  return {
    ...filter,
    children,
  };
};
