<template>
  <div class="notification-comment">
    <div class="_title">
      {{ feedEntry.actor.firstName }} {{ feedEntry.actor.lastName }}
    </div>
    <m-editor
      :initial-value="feedEntry.comment.message"
      :allowed-content="allowedContent"
      disabled
    />
    <m-dropdown
      v-model:value="show"
      class="_reply"
      :title="$t('notificationComment.reply')"
      :relocate-key="relocateKey"
      content-may-resize
    >
      <m-btn @click="handleClick">
        {{ $t('notificationFeed.reply') }}
      </m-btn>
      <template #overlay>
        <m-card
          class="_overlay"
          no-padding
        >
          <m-content
            :padding-x="10"
            :padding-y="8"
          >
            <comment-list
              ref="comments"
              :comments="comments"
              :update="feedEntry.update"
              :meeting="feedEntry.meeting"
              emit-content-change
              @update-content="relocate"
            />
          </m-content>
        </m-card>
      </template>
    </m-dropdown>
  </div>
</template>

<script>
import CommentList from '@/components/comment/CommentList.vue';
import MEditor from '@/components/editor/MEditor.vue';
import useAccess from '@/composables/access/access';
import useComments from '@/composables/comments/comments';
import { editorNodeType, featureFlag } from 'shared/constants.json';
import { guid } from 'shared/lib/uuid';

export default {
  name: 'NotificationComment',
  props: {
    feedEntry: {
      type: Object,
      required: true,
    },
  },
  components: {
    CommentList,
    MEditor,
  },
  data() {
    return {
      show: false,
      relocateKey: guid(),
    };
  },
  setup(props) {
    const allowedContent = [
      editorNodeType.codeBlock,
      editorNodeType.blockquote,
      editorNodeType.bulletList,
      editorNodeType.orderedList,
      editorNodeType.mention,
      editorNodeType.goalMention,

    ];

    const { accountHasFeature } = useAccess();
    if (accountHasFeature([featureFlag.fileUpload])) {
      allowedContent.push(editorNodeType.image, editorNodeType.file);
    }

    const { comments } = useComments();
    const c = () => {
      if (props.feedEntry.update !== null) {
        return comments({ updateId: props.feedEntry.update.uid });
      }

      if (props.feedEntry.meeting !== null) {
        return comments({ meetingId: props.feedEntry.meeting.uid });
      }

      return [];
    };

    return { comments: c(), allowedContent };
  },
  methods: {
    relocate() {
      this.relocateKey = guid();
    },
    handleClick() {
      this.show = true;
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.$refs.comments.add();
        });
      });
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .notification-comment {
    ._title {
      font-size: $font-size-2;
      color: $font-color-secondary;
    }

    ._reply {
      margin-top: 1.2rem;
    }
  }

  ._overlay {
    min-width: 30rem;
    max-width: 60rem;
  }
</style>
