import { getSchema, getType } from '@/nebula/type';

export const references = {};
export const resetReferences = () => {
  Object.keys(references).forEach((uid) => {
    delete references[uid];
  });
};

const referenceTable = {};

const mergeEdges = (target, key, value, edge, type) => {
  if (target[key][value] === undefined) {
    target[key][value] = { uid: value, edges: [{ edge, type }] };
    return;
  }
  const edges = target[key][value].edges.map((e) => `${e.type}.${e.edge}`);
  if (edges.includes(`${type}.${edge}`)) {
    return;
  }
  target[key][value].edges = [...target[key][value].edges, { edge, type }];
};

const merge = (target, key, value, edge, type) => {
  if (target[key] === undefined) {
    target[key] = {};
  }
  if (Array.isArray(value)) {
    for (let i = 0; i < value.length; i++) {
      mergeEdges(target, key, value[i], edge, type);
    }
    return;
  }
  mergeEdges(target, key, value, edge, type);
};

const addObject = (references, input, schema) => {
  const edges = Object.keys(schema.schema);
  const type = getType(schema);
  let edge;
  for (let i = 0; i < edges.length; i++) {
    edge = edges[i];
    if (input[edge] === undefined || input[edge] === null) {
      continue;
    }
    merge(references, input.uid, input[edge], edge, type);
    if (Array.isArray(input[edge])) {
      for (let j = 0; j < input[edge].length; j++) {
        merge(references, input[edge][j], input.uid, `~${edge}`, type);
      }
      continue;
    }
    merge(references, input[edge], input.uid, `~${edge}`, type);
  }
};
export const createReferenceIndex = (reference, schema, input) => {
  const a = Object.values(input);
  for (let i = 0; i < a.length; i++) {
    addObject(reference, a[i], schema);
  }
  return reference;
};

export const deleteReferenceEdge = (reference, idP, idC, edge) => {
  const removeFromReferences = (p, c, e) => {
    if (reference[p] === undefined || reference[p][c] === undefined) {
      return;
    }
    const ref = reference[p][c];
    const i = ref.edges.findIndex((refEdge) => refEdge.edge === e.edge && refEdge.type === e.type);
    if (i === -1) {
      return;
    }
    ref.edges.splice(i, 1);
    if (ref.edges.length === 0) {
      delete reference[p][c];
    }
  };

  removeFromReferences(idP, idC, edge);
  removeFromReferences(idC, idP, { ...edge, edge: `~${edge.edge}` });
};

export const deleteReference = (reference, ids) => {
  for (let i = 0; i < ids.length; i++) {
    deleteReferenceSingle(reference, ids[i]);
  }
};

const deleteReferenceSingle = (reference, id) => {
  if (reference[id] === undefined) {
    return;
  }
  const refs = Object.values(reference[id]);
  for (let i = 0; i < refs.length; i++) {
    if (reference[refs[i].uid][id] === undefined) {
      continue;
    }
    delete reference[refs[i].uid][id];
  }

  delete reference[id];
};

export const buildReferenceTable = (schema) => {
  Object.values(schema).forEach((s) => {
    referenceTable[s.key] = {};
    Object.values(schema).forEach((r) => {
      if (Object.keys(r.schema).length === 0) {
        return;
      }
      Object.keys(r.schema).forEach((edge) => {
        const refSchema = getSchema(r.schema[edge]);
        if (refSchema.key === s.key) {
          referenceTable[s.key][r.key] = true;
        }
      });
    });
  });
};

export const isReferenced = (model) => Object.keys(referenceTable[model]).length > 0;
