import { FUNC_GE, FUNC_LT } from 'shared/api/query/filter';
import { ref } from 'vue';

export default function useProgressDistribution() {
  const init = () => {
    const distribution = [
      { numberRange: { min: 80, minType: FUNC_GE } },
      { numberRange: { min: 60, max: 80, minType: FUNC_GE, maxType: FUNC_LT } },
      { numberRange: { min: 40, max: 60, minType: FUNC_GE, maxType: FUNC_LT } },
      { numberRange: { min: 20, max: 40, minType: FUNC_GE, maxType: FUNC_LT } },
      { numberRange: { min: 0, max: 20, minType: FUNC_GE, maxType: FUNC_LT } },
    ];
    distribution.forEach((e) => {
      e.x = `${e.numberRange.min} - ${e.numberRange.max}%`;
      if (typeof e.numberRange.max === 'undefined' || e.numberRange.max === undefined) {
        e.x = `≥ ${e.numberRange.min}%`;
      }
      e.y = 0;
      e.diffY = undefined;
    });
    return distribution;
  };
  const distribution = ref(init());

  const updateDiff = (list) => {
    distribution.value.forEach((bucket) => {
      bucket.diffY = undefined;
    });
    if (list.length === 0) {
      return;
    }
    distribution.value.forEach((bucket) => {
      bucket.diffY = bucket.y;
    });
    list.forEach((e) => {
      distribution.value.forEach((bucket) => {
        if (e >= bucket.numberRange.min && (bucket.numberRange.max === undefined || e < bucket.numberRange.max)) {
          bucket.diffY -= 1;
        }
      });
    });
  };

  return {
    distribution,
    updateDiff,
  };
}
