import { uniq } from 'lodash-es';

export const orderEntitiesEqual = (a, b) => {
  if (a.attr !== b.attr) {
    return false;
  }

  let aLangs = [];
  if (typeof a.langs !== 'undefined') {
    aLangs = a.langs;
  }

  let bLangs = [];
  if (typeof b.langs !== 'undefined') {
    bLangs = b.langs;
  }

  if (aLangs.length !== bLangs.length) {
    return false;
  }

  aLangs = aLangs.sort();
  bLangs = bLangs.sort();

  return aLangs.every((al, index) => bLangs[index] === al);
};

/* eslint-disable default-param-last */
export const initIdList = (sort = [], ids) => {
  if (sort.length === 0) {
    return ids;
  }

  const min = sort.reduce((res, next) => {
    if (next < res) {
      return next;
    }

    return res;
  }, Number.MAX_SAFE_INTEGER);

  const res = [...sort];
  ids.forEach((id) => {
    if (id < min) {
      res.unshift(id);
      return;
    }

    res.push(id);
  });

  return uniq(res);
};
/* eslint-enable default-param-last */
