import {
  HAS,
  RESULT,
  UID,
} from 'shared/api/query/constants';
import { UserFilterHandler } from '@/lib/filter/user/handler';
import {
  user,
} from 'shared/api/query/configs.json';

export const userAmountByScope = (userScopeTree) => {
  const handler = new UserFilterHandler(true);
  const { filterTree, varBlocks } = handler.Translate(userScopeTree);
  return [
    {
      model: user.model,
      alias: RESULT,
      func: { name: HAS, attr: 'createdAt' },
      filter: filterTree,
      children: [
        { attr: UID, isCount: true },
      ],
    },
    ...varBlocks,
  ];
};
