export const reverseFn = ({ entity, scope }) => {
  let key = scope.reverseProperty.edgeName;
  if (scope.reverseProperty.edgeAlias !== undefined && scope.reverseProperty.edgeAlias !== '') {
    key = scope.reverseProperty.edgeAlias;
  }
  const selectedValue = entity[key];
  if (selectedValue === undefined) {
    return false;
  }

  return selectedValue !== null;
};
