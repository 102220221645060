import { ref } from 'vue';

export default function useScroll(scroll) {
  const stopScroll = ref(false);
  const scrollSpeedLow = 0.8;
  const scrollSpeedMedium = 3;
  const scrollSpeedFast = 10;
  const distanceThresholdScrollSlow = 40;
  const distanceThresholdScrollMedium = 25;
  const distanceThresholdScrollFast = 10;

  const stop = () => {
    stopScroll.value = true;
  };

  const getScrollSpeed = (distance) => {
    if (distance > distanceThresholdScrollSlow) {
      return 0;
    }
    if (distance < distanceThresholdScrollFast) {
      return scrollSpeedFast;
    }
    if (distance < distanceThresholdScrollMedium) {
      return scrollSpeedMedium;
    }
    return scrollSpeedLow;
  };
  const handleScroll = ({ distanceTop, distanceRight, distanceBottom, distanceLeft }) => {
    let deltaX = 0;
    let deltaY = 0;
    if (distanceRight < distanceThresholdScrollSlow) {
      deltaX = getScrollSpeed(distanceRight);
    }
    if (distanceBottom < distanceThresholdScrollSlow) {
      deltaY = getScrollSpeed(distanceBottom);
    }
    if (distanceTop < distanceThresholdScrollSlow) {
      deltaY = -getScrollSpeed(distanceTop);
    }
    if (distanceLeft < distanceThresholdScrollSlow) {
      deltaX = -getScrollSpeed(distanceLeft);
    }
    if (deltaX === 0 && deltaY === 0) {
      stopScroll.value = true;
      return;
    }

    stopScroll.value = false;
    const update = () => {
      if (stopScroll.value) {
        return;
      }
      scroll({ deltaX, deltaY });
      setTimeout(() => {
        update();
      }, 50);
    };
    update();
  };

  return {
    handleScroll,
    stop,
  };
}
