<template>
  <div class="meeting-props-editor">
    <editable-page-header
      ref="pageHeader"
      :title="title"
      :icon="icon"
      :show-icon="!isEmptyIcon(icon) || emojiAdded"
      :placeholder="$t('list.noTitle')"
      :disabled="!canEdit"
      show-icon-on-top
      class="_header"
      :auto-focus="!$store.state.breakpoint.smAndDown"
      @arrow-down="$emit('go-down')"
      @enter.prevent="$emit('go-down')"
      @change-title="updateTitle"
      @change-icon="updateIcon"
    >
      <template #actions>
        <div class="_header-actions">
          <m-btn
            v-if="isEmptyIcon(icon) && !emojiAdded && canEdit"
            hide-border
            class="_item"
            icon="smile"
            light
            small
            @click="addEmoji"
          >
            {{ $t('page.addEmoji') }}
          </m-btn>
          <m-btn
            v-if="agenda === null && canEdit"
            hide-border
            class="_item"
            icon="unordered-list"
            light
            small
            @click="addAgenda"
          >
            {{ $t('meetingPage.addAgenda') }}
          </m-btn>
          <m-btn
            v-if="privateNote === null && canEdit && !meeting.isTemplate"
            hide-border
            class="_item"
            icon="eye-invisible"
            light
            small
            @click="addPrivateNote"
          >
            {{ $t('meetingPage.addPrivateNote') }}
          </m-btn>
          <m-btn
            v-if="!hasCommentsSection && canEdit && !meeting.isTemplate"
            hide-border
            class="_item"
            icon="message"
            light
            small
            @click="addCommentsSection"
          >
            {{ $t('meetingPage.addComment') }}
          </m-btn>
        </div>
      </template>
    </editable-page-header>
    <div class="_properties">
      <property-label
        :label="$t('meeting.participants')"
        icon="user"
        class="_prop"
      >
        <user-picker
          :value="meeting.participants"
          full-width
          :disabled="!canEdit"
          :view-mode="!canEdit"
          hide-border
          hide-arrow
          match-trigger-width
          popup
          :placeholder="$t('general.empty')"
          @input="updateParticipants"
        />
      </property-label>
      <property-label
        :label="$t('meeting.date')"
        icon="schedule"
        class="_prop"
      >
        <m-date-picker
          v-if="!calendarIntegrationActivated"
          :value="fromISO(meeting.date)"
          full-width
          :disabled="!canEdit"
          hide-border
          :date-time="DateTime"
          clearable
          :view-mode="!canEdit"
          :placeholder="$t('general.empty')"
          :locale="userLang"
          include-time
          @input="updateDate"
        />
        <m-s-calendar-date
          v-else
          :meeting="meeting"
        />
      </property-label>
      <property-values
        :properties="meetingProperties"
        :value="meeting.propertyValues"
        :disabled="!canEdit"
        @input="updateProperties"
      />
    </div>
    <m-divider small />
  </div>
</template>

<script>
import EditablePageHeader from '@/components/page/EditablePageHeader.vue';
import MSCalendarDate from '@/components/meeting/MSCalendarDate.vue';
import PropertyLabel from '@/components/PropertyLabel.vue';
import PropertyValues from '@/components/PropertyValues.vue';
import UserPicker from '@/components/UserPicker.vue';
import camelCase from 'lodash-es/camelCase';
import useAccountSettings from '@/composables/logged-in-user-account/account-settings';
import useDebounce from '@/composables/debounce';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useProperties from '@/composables/property/property';
import { DateTime } from 'luxon';
import { buildIconFromEntity, isEmptyIcon } from 'shared/lib/icon';
import { children as collectionChildren } from '@/api/query/collection';
import {
  collection as collectionConfig,
  meeting as meetingConfig,
  privateNote as privateNoteConfig,
} from 'shared/api/query/configs.json';
import { collectionItemType, collectionType } from 'shared/constants.json';
import { dogma } from '@/api';
import { fromISO, toISO } from 'shared/lib/time';
import { mapActions } from 'vuex';
import { children as meetingChildren } from '@/api/query/meeting';
import { children as privateNoteChildren } from '@/api/query/private-note';

export default {
  name: 'MeetingPropsEditor',
  props: {
    meeting: {
      type: Object,
      required: true,
    },
    privateNote: {
      type: Object,
      default: () => null,
    },
    agenda: {
      type: Object,
      default: () => null,
    },
    openInModal: {
      type: Boolean,
      default: false,
    },
    hasCommentsSection: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['go-down', 'add-comments-section'],
  components: {
    UserPicker,
    EditablePageHeader,
    PropertyValues,
    PropertyLabel,
    MSCalendarDate,
  },
  setup() {
    const { debounce, cancelAll } = useDebounce({ onBeforeUnmountFlush: true });
    const { meetingProperties } = useProperties();
    const { loggedInUser, userLang } = useLoggedInUser();
    const { accountSettings } = useAccountSettings();
    return {
      userLang,
      debounce,
      debounceCancelAll: cancelAll,
      meetingProperties,
      loggedInUser,
      accountSettings,
    };
  },
  data() {
    return {
      descriptionAdded: false,
      emojiAdded: false,
      title: this.meeting.title,
      camelCase,
      participantsUpdated: false,
      DateTime,
      fromISO,
    };
  },
  computed: {
    icon() {
      return buildIconFromEntity(this.meeting);
    },
    sha() {
      return this.meeting.sha;
    },
    calendarIntegrationActivated() {
      if (this.meeting.msCalendarEvent === null) {
        return false;
      }

      return this.meeting.msCalendarEvent.msEvent !== null;
    },
  },
  methods: {
    isEmptyIcon,
    ...mapActions([
      'getEntities',
      'createEntity',
    ]),
    focusTitle() {
      this.$refs.pageHeader.focusTitle();
    },
    addAgenda() {
      this.createEntity({
        entity: {
          type: collectionType.checkList,
          meeting: { uid: this.meeting.uid },
          title: this.$t('meetingPage.agendaTitle'),
          hidden: false,
          items: [
            {
              type: collectionItemType.checkListItem,
              checked: false,
              content: { title: '' },
            },
          ],
        },
        model: collectionConfig.model,
        entityById: () => [{ children: collectionChildren }],
        mutation: 'MEETING_AGENDA_CREATED',
      }).then((response) => {
        if (response.status !== 201) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
        }
      });
    },
    addCommentsSection() {
      this.$emit('add-comments-section');
    },
    addPrivateNote() {
      this.createEntity({
        entity: {
          uid: 0,
          meeting: { uid: this.meeting.uid },
          creator: { uid: this.loggedInUser.uid },
          hidden: false,
        },
        model: privateNoteConfig.model,
        entityById: () => [{ children: privateNoteChildren }],
      }).then((response) => {
        if (response.status !== 201) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
        }
      });
    },
    addEmoji() {
      this.emojiAdded = true;
    },
    updateTitle(value) {
      this.title = value;

      const update = () => {
        this.$store.commit('MEETING_UPDATED', { entity: { ...this.meeting, title: this.title } });
      };

      this.debounce(update, 500, 'updateTitle');
    },
    updateIcon(icon) {
      this.$store.commit('MEETING_UPDATED', { entity: { ...this.meeting, icon: icon.value } });
      if (isEmptyIcon(icon)) {
        this.emojiAdded = false;
      }
    },
    updateDate(value) {
      this.$store.commit('MEETING_UPDATED', { entity: { ...this.meeting, date: toISO(value) } });
    },
    updateParticipants(value) {
      this.$store.commit('MEETING_UPDATED', { entity: { ...this.meeting, participants: value } });
      this.participantsUpdated = true;
    },
    updateProperties(propertyValues) {
      this.$store.commit('MEETING_UPDATED', { entity: { ...this.meeting, propertyValues } });
    },
    save(oldValue, newValue) {
      if (oldValue === newValue) {
        return;
      }
      this.update(this.meeting);
    },
    update(meeting) {
      dogma.updateSingle(
        meeting,
        meetingConfig.model,
        meetingChildren,
      ).then((response) => {
        if (response.status !== 200) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
        }

        this.$store.commit('MEETING_ACCESS_POLICY_UPDATED', { entity: response.data });
      });
    },
  },
  watch: {
    sha(newValue, oldValue) {
      if (!this.canEdit) {
        return;
      }

      const update = () => this.save(newValue, oldValue);

      this.debounceCancelAll();
      this.debounce(update, 1000, 'sha');
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .meeting-props-editor {
    ._header {
      margin-bottom: 2rem;

      ._header-actions {
        display: flex;
      }
    }

    ._properties {
      ._prop {
        margin-bottom: 0;
      }
    }
  }
</style>
