<template>
  <div class="lookup-form">
    <m-dropdown
      block
      match-trigger-width
      :title="$t('lookupForm.title')"
    >
      <div class="_input">
        <div
          v-if="property === null || relation === null || propertyTitle === '' || relationTitle === ''"
          class="_empty"
        >
          {{ $t('general.empty') }}
        </div>
        <template v-else>
          {{ propertyTitle }} <span :style="{ color: $colors.grey.base }">{{ $t('lookupForm.from') }}</span> {{ relationTitle }}
        </template>
      </div>
      <template #overlay>
        <m-card
          class="_overlay"
          no-padding
          list
        >
          <div class="_item">
            <div class="_label">
              {{ $t('lookupForm.relationTitle') }}
              <m-tooltip>
                <span>
                  <m-icon
                    type="question-circle"
                    class="_icon"
                    size="12"
                  />
                </span>
                <template #title>
                  {{ $t('lookupForm.relationInfo') }}
                </template>
              </m-tooltip>
            </div>
            <m-select
              :value="relationId"
              :trigger-style="{ borderRadius: 0 }"
              :items="relations"
              hide-border
              full-width
              match-trigger-width
              @input="updateRelation"
            />
          </div>
          <m-divider xxs />
          <div class="_item">
            <div class="_label">
              {{ $t('lookupForm.propertyTitle') }}
              <m-tooltip>
                <span>
                  <m-icon
                    type="question-circle"
                    class="_icon"
                    size="12"
                  />
                </span>
                <template #title>
                  {{ $t('lookupForm.propertyInfo') }}
                </template>
              </m-tooltip>
            </div>
            <m-select
              :value="propertyId"
              :trigger-style="{ borderRadius: 0 }"
              :items="properties"
              hide-border
              full-width
              match-trigger-width
              @input="updateProperty"
            />
          </div>
        </m-card>
      </template>
    </m-dropdown>
  </div>
</template>

<script>
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useProperties from '@/composables/property/property';
import { findInArray } from 'shared/lib/array/array';
import { intersectionBy } from 'lodash-es';
import { propertyApplication, propertyType } from 'shared/constants.json';
import { textByLang } from 'shared/lib/language';

export default {
  name: 'LookupForm',
  props: {
    usedFor: {
      type: Array,
      required: true,
    },
    relation: {
      type: Object,
      default: () => null,
    },
    property: {
      type: Object,
      default: () => null,
    },
  },
  setup() {
    const { goalProperties, userProperties, updateProperties, meetingProperties } = useProperties();
    const { userLang } = useLoggedInUser();

    return {
      goalProperties,
      userProperties,
      updateProperties,
      meetingProperties,
      userLang,
    };
  },
  emits: ['input'],
  data() {
    return {
      relationId: null,
      propertyId: null,
    };
  },
  computed: {
    relationTitle() {
      const relation = findInArray({ haystack: this.allProperties, needle: this.relationId });
      if (relation === null) {
        return '';
      }

      return textByLang(relation.label, this.userLang);
    },
    propertyTitle() {
      const property = findInArray({ haystack: this.userProperties, needle: this.propertyId });
      if (property === null) {
        return '';
      }

      return textByLang(property.label, this.userLang);
    },
    allProperties() {
      const res = [];
      if (this.usedFor.includes(propertyApplication.user)) {
        res.push(...this.userProperties);
      }
      if (this.usedFor.includes(propertyApplication.goal)) {
        res.push(...this.goalProperties);
      }
      if (this.usedFor.includes(propertyApplication.update)) {
        res.push(...this.updateProperties);
      }
      if (this.usedFor.includes(propertyApplication.meeting)) {
        res.push(...this.meetingProperties);
      }

      return res;
    },
    relations() {
      return intersectionBy(this.allProperties, 'uid').filter((p) => p.type === propertyType.user).map((p) => ({
        text: textByLang(p.label, this.userLang),
        value: p.uid,
      }));
    },
    properties() {
      const relation = findInArray({ haystack: this.allProperties, needle: this.relationId });
      if (relation === null) {
        return [];
      }

      if (relation.type === propertyType.user) {
        return this.userProperties.filter((p) => [propertyType.user, propertyType.options, propertyType.singleSelect, propertyType.space].includes(p.type)).map((p) => ({
          text: textByLang(p.label, this.userLang),
          value: p.uid,
        }));
      }

      return [];
    },
  },
  methods: {
    updateRelation(value) {
      this.relationId = value;
      this.emitInput();
    },
    updateProperty(value) {
      this.propertyId = value;
      this.emitInput();
    },
    emitInput() {
      this.$emit('input', { relation: { uid: this.relationId }, property: { uid: this.propertyId } });
    },
  },
  watch: {
    relation(val) {
      if (val !== null && typeof val.uid !== 'undefined') {
        this.relationId = val.uid;
      }
    },
    property(val) {
      if (val !== null && typeof val.uid !== 'undefined') {
        this.propertyId = val.uid;
      }
    },
  },
  mounted() {
    if (this.property !== null) {
      this.propertyId = this.property.uid;
    }
    if (this.relation !== null) {
      this.relationId = this.relation.uid;
    }
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .lookup-form {
    ._input {
      width: 100%;
      padding: .4rem 1.1rem;
      cursor: pointer;
      border: 1px solid $input-border-color;
      border-radius: $default-border-radius;

      ._empty {
        color: $font-color-tertiary;
      }

      &:hover {
        border-color: $primary-color;
      }
    }
  }

  ._overlay {
    ._item {
      ._label {
        display: flex;
        align-items: center;
        padding: .4rem 1.1rem;
        font-size: $font-size-2;
        color: $font-color-secondary;
        text-transform: uppercase;

        ._icon {
          margin-bottom: .2rem;
          margin-left: .6rem;
        }
      }
    }
  }
</style>
