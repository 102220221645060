export const savedViewProps = {
  readOnly: {
    type: Boolean,
    default: false,
  },
  defaultView: {
    type: Object,
    required: true,
  },
  viewApplication: {
    type: String,
    required: true,
  },
  space: {
    type: Object,
    default: () => null,
  },
  planning: {
    type: Object,
    default: () => null,
  },
};
