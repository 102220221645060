<template>
  <div class="onboarding-explanation-form">
    <div class="_title">
      <h4>
        {{ title }}
      </h4>
    </div>
    <div class="_description">
      <template v-if="!!$slots.description">
        <slot name="description" />
      </template>
      <template v-else>
        {{ description }}
      </template>
    </div>
    <div class="_actions">
      <m-btn
        hide-border
        light
        class="_btn"
        @click="$emit('close')"
      >
        <template v-if="secondaryButtonText !== ''">
          {{ secondaryButtonText }}
        </template>
        <template v-else>
          {{ $t('general.close') }}
        </template>
      </m-btn>
      <m-btn
        :disabled="$store.state.breakpoint.smAndDown"
        color="primary"
        class="_btn"
        @click="$emit('ok')"
      >
        {{ primaryText }}
      </m-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OnboardingExplanationForm',
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: '',
    },
    primaryText: {
      type: String,
      required: true,
    },
    secondaryButtonText: {
      type: String,
      default: '',
    },
  },
  emits: ['close', 'ok'],
};
</script>

<style scoped lang="scss" type="text/scss">
  .onboarding-explanation-form {
    ._actions {
      display: flex;
      justify-content: flex-end;
      margin-top: 2rem;

      ._btn {
        margin-left: .8rem;
      }
    }
  }
</style>
