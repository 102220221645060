<template>
  <m-dropdown
    ref="dropdown"
    v-model:value="showMenu"
    :class="['goal-activity-picker', readOnly ? '' : '-clickable']"
    :placement="placement"
    :disabled="disabled"
    :title="$t('goalActivityPicker.title')"
    :match-trigger-width="matchTriggerWidth"
    :relocate-key="viewKey"
    :block="block"
    keep-open-on-mask-click
    @input="val => showMenu = val"
    @overlay-clicked="hide"
  >
    <slot
      v-if="!!$slots.trigger"
      name="trigger"
    />
    <template #overlay>
      <m-card
        no-padding
        :class="['_overlay', $store.state.breakpoint.smAndDown ? '-mobile' : '']"
      >
        <div
          v-if="description !== ''"
          class="_description"
        >
          {{ description }}
        </div>
        <m-content
          padding-xs
          class="_inner"
        >
          <updates-list-header
            class="_list-header"
            :slots="slots"
            :filter-props="defaultProps"
            :show-create-view="showCreateView"
            :show-views-selector="showViewsSelector"
            :allowed-view-types="allowedViewTypes"
            :data-loading="listLoading"
          />
        </m-content>
        <m-divider none />
        <m-content
          padding-small
          class="_list"
        >
          <m-spinner
            v-if="initiallyLoading"
            class="_spinner"
          />
          <div
            v-if="!initiallyLoading"
            class="_inner"
          >
            <div
              v-if="activities.length === 0"
              class="_empty"
            >
              {{ $t('general.noEntries') }}
            </div>
            <m-endless-scroll-list
              :key="`${listLoading}`"
              @visible="loadMore"
            >
              <div
                v-for="activity in activities"
                :key="activity.uid"
                class="_item"
                @click="onClick(activity)"
              >
                <div
                  class="_left"
                >
                  <m-checkbox :value="selectedIds.includes(activity.uid)" />
                </div>
                <div
                  class="_right"
                >
                  <goal-activity-form
                    :activity="activity"
                    :wrap-titles="currentView.params.wrapTitles"
                    disabled
                    read-only
                    searchable
                    class="_activity"
                  >
                    <template #pre>
                      <div
                        class="_avatar"
                      >
                        <user-avatar
                          :user="activityCreator(activity)"
                          :size="18"
                          has-tooltip
                        />
                      </div>
                    </template>
                  </goal-activity-form>
                </div>
              </div>
            </m-endless-scroll-list>
          </div>
        </m-content>
        <m-content
          v-if="showFooter && okText !== ''"
          padding-xs
          class="_footer"
        >
          <div class="_footer-inner">
            <div class="_actions">
              <m-btn
                color="primary"
                :disabled="value.length === 0"
                @click="select"
              >
                {{ okText(value.length) }}
              </m-btn>
            </div>
          </div>
        </m-content>
      </m-card>
    </template>
  </m-dropdown>
</template>

<script>
import GoalActivityForm from '@/components/goal/GoalActivityForm.vue';
import UpdatesListHeader from '@/components/updates/UpdatesListHeader.vue';
import UserAvatar from 'shared/components/UserAvatar.vue';
import useDebounce from '@/composables/debounce';
import useGoalActivity from '@/composables/goal-activity/goal-activity';
import useGoalProperty from '@/composables/property/goal-property';
import useInMemoryViews from '@/composables/saved-views/in-memory-views';
import useUpdates from '@/composables/updates/updates';
import useUsers from '@/composables/user/users';
import { COUNT, RESULT } from 'shared/api/query/constants';
import { GoalFilterHandler } from '@/lib/filter/goal/handler';
import { OR, combine } from 'shared/api/query/filter';
import { SLOTS, VIEWS_SERVICE } from '@/lib/constants';
import { UpdatesFilterHandler } from '@/lib/filter/updates/handler';
import { computed, provide } from 'vue';
import { copy } from 'shared/lib/copy';
import { createPropsList } from '@/lib/props';
import { directProperties } from '@/lib/updates/properties';
import { equalP } from 'shared/lib/array/array';
import { extendPath } from '@/lib/filter/filter-relocation';
import { extractFilter } from '@/lib/filter/extract';
import {
  goalActivity as goalActivityConfig, goal as goalConfig,
  update as updateConfig,
} from 'shared/api/query/configs.json';
import { goalActivityList } from '@/api/query/nebula/goal-activity';
import { guid } from 'shared/lib/uuid';
import { intersection } from 'lodash-es';
import { reverseEdge } from 'shared/api/query/edges';
import { savedViewProps } from '@/lib/saved-view/props';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { viewType } from 'shared/constants.json';

export default {
  name: 'GoalActivityPicker',
  props: {
    ...savedViewProps,
    value: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    autoFocus: {
      type: Boolean,
      default: false,
    },
    matchTriggerWidth: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
    showFooter: {
      type: Boolean,
      default: false,
    },
    okText: {
      type: Function,
      default: () => {
      },
    },
    description: {
      type: String,
      default: '',
    },
    placement: {
      type: String,
      default: 'bottomCenter',
    },
    showCreateView: {
      type: Boolean,
      default: false,
    },
    showViewsSelector: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const { properties: goalProperties } = useGoalProperty();
    const userLang = computed(() => store.getters.userLang);

    const updateSvc = useUpdates();

    const goalActivitySvc = useGoalActivity();

    const defaultProps = computed(() => [
      ...createPropsList({
        properties: goalProperties.value,
        directProperties: [],
        userLang: userLang.value,
        model: goalConfig.model,
      }),
      ...createPropsList({
        properties: [],
        directProperties: directProperties((key) => t(key)),
        userLang: userLang.value,
        model: updateConfig.model,
      }),
    ]);
    const inMemoryViewsSvc = useInMemoryViews({
      defaultProps,
      defaultView: props.defaultView,
      application: props.viewApplication,
      readOnly: props.readOnly,
    });

    provide(VIEWS_SERVICE, inMemoryViewsSvc);

    const { mooncampBotUser } = useUsers();
    const { debounce, cancel: debounceCancel } = useDebounce();
    return {
      mooncampBotUser,
      defaultProps,

      debounce,
      debounceCancel,

      currentView: inMemoryViewsSvc.currentView,
      initCurrentView: inMemoryViewsSvc.initCurrentView,
      goalActivitySvc,
      updateSvc,
    };
  },
  emits: ['input', 'update:value', 'select', 'hide', 'change'],
  components: { UpdatesListHeader, GoalActivityForm, UserAvatar },
  data() {
    return {
      showMenu: false,
      selected: [],
      headerWidth: 0,
      viewKey: guid(),
      guid,
      itemsPerPage: 20,
      loadMoreAmount: 10,
      page: 1,
      listLoading: true,
      initiallyLoading: true,
      activities: [],
      totalAmount: 0,
      slots: [{ name: SLOTS.FILTER }, { name: SLOTS.SORTER }],
    };
  },
  computed: {
    selectedIds() {
      return this.selected.map((s) => s.uid);
    },
    op() {
      if (this.currentView.params.filter === null) {
        return OR;
      }

      return this.currentView.params.filter.op;
    },
    order() {
      return this.currentView.params.order;
    },
    updateFilter() {
      return extractFilter(this.currentView.params.filter, updateConfig.model);
    },
    goalFilter() {
      return extractFilter(this.currentView.params.filter, goalConfig.model);
    },
    updateGqlFilterObject() {
      const handler = new UpdatesFilterHandler();
      const f = handler.Translate(this.updateFilter);
      const { filterTree, varBlocks } = extendPath({
        varBlocks: f.varBlocks,
        from: updateConfig.model,
        path: [
          {
            attr: updateConfig.edges.goalActivities,
            model: goalActivityConfig.model,
          },
        ],
        filterTree: f.filterTree,
      });

      return {
        filterTree,
        varBlocks: [...varBlocks, ...f.varBlocks],
      };
    },
    goalGqlFilterObject() {
      const handler = new GoalFilterHandler();
      const f = handler.Translate(this.goalFilter);
      const { filterTree, varBlocks } = extendPath({
        varBlocks: f.varBlocks,
        from: goalConfig.model,
        path: [
          {
            attr: reverseEdge(goalActivityConfig.edges.goal),
            model: goalActivityConfig.model,
          },
        ],
        filterTree: f.filterTree,
      });
      return {
        filterTree,
        varBlocks: [...varBlocks, ...f.varBlocks],
      };
    },
    goalAndUpdateFilter() {
      return combine(
        this.op,
        [
          this.goalGqlFilterObject.filterTree,
          this.updateGqlFilterObject.filterTree,
        ],
      );
    },
    filter() {
      return this.goalAndUpdateFilter;
    },
    varBlocks() {
      return [
        ...this.goalGqlFilterObject.varBlocks,
        ...this.updateGqlFilterObject.varBlocks,
      ];
    },
    allowedViewTypes() {
      return [viewType.list];
    },
    valueIsEmpty() {
      if (this.selected === null) {
        return true;
      }

      return this.selected.length === 0;
    },
    selectedGoalIds() {
      return this.value.map((g) => g.uid);
    },
  },
  methods: {
    loadMore() {
      if (this.totalAmount <= this.itemsPerPage) {
        return;
      }

      this.itemsPerPage += this.loadMoreAmount;
      this.retrieveGoalActivities();
    },
    retrieveGoalActivities() {
      this.listLoading = true;

      this.goalActivitySvc.query(
        goalActivityList({
          pagination: {
            page: this.page,
            itemsPerPage: this.itemsPerPage,
            countAlias: COUNT,
          },
          filter: this.filter,
          varBlocks: this.varBlocks,
          order: this.order,
          alias: RESULT,
        }),
      ).then((data) => {
        this.listLoading = false;
        this.initiallyLoading = false;
        this.totalAmount = data[COUNT][0].count;
        this.activities = this.goalActivitySvc.selectMultiple(data[RESULT].map((e) => e.uid)).map((a) => ({
          ...a,
          update: this.updateSvc.selectSingle(a.updates[0].uid),
        }));
      });
    },
    apply(wait) {
      const { retrieveGoalActivities } = this;
      this.debounce(retrieveGoalActivities, wait);
    },
    select() {
      this.$emit('select');
      this.showMenu = false;
      this.selected = [];
    },
    handleDataLoaded() {
      this.viewKey = guid();
    },
    hide() {
      this.showMenu = false;
      this.$emit('hide');
    },
    activityCreator(activity) {
      if (activity.update.generated === true) {
        return this.mooncampBotUser;
      }
      return activity.update.creator;
    },
    onClick(activity) {
      if (this.selectedIds.includes(activity.uid)) {
        this.selected = this.selected.filter((g) => g.uid !== activity.uid);
        return;
      }

      this.selected = [...this.selected, activity];
    },
    focusDropdown() {
      if (typeof this.$refs.dropdown === 'undefined') {
        return;
      }

      this.$refs.dropdown.focus();
    },
  },
  watch: {
    order(val, oldVal) {
      if (JSON.stringify(val) === JSON.stringify(oldVal)) {
        return;
      }
      this.apply(700);
    },
    goalAndUpdateFilter: {
      handler(val, oldVal) {
        if (JSON.stringify(val) === JSON.stringify(oldVal)) {
          return;
        }
        this.apply(700);
      },
      deep: true,
    },
    varBlocks: {
      handler(val, oldVal) {
        if (JSON.stringify(val) === JSON.stringify(oldVal)) {
          return;
        }
        this.apply(700);
      },
      deep: true,
    },
    selected: {
      handler(newVal, oldVal) {
        if (equalP({ a: newVal, b: oldVal })) {
          return;
        }

        this.$emit('input', newVal);
        this.$emit('update:value', newVal);
        this.$emit('change', newVal);
      },
      deep: true,
    },
    value: {
      handler(newVal) {
        const newIds = newVal.map((g) => g.uid);
        const existingIds = this.selected.map((g) => g.uid);
        if (intersection(newIds, existingIds).length === newIds.length) {
          return;
        }

        this.selected = newVal;
      },
      deep: true,
    },
  },
  created() {
    this.initCurrentView();
    this.apply(0);

    if (this.value === null) {
      return;
    }

    if (Array.isArray(this.value)) {
      this.selected = copy(this.value);
      return;
    }

    this.selected = [copy(this.value)];
  },
  mounted() {
    this.headerWidth = this.$refs.dropdown.$el.clientWidth;
    if (this.autoFocus) {
      this.showMenu = true;
    }
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  .goal-activity-picker {
    width: 100%;
    min-height: 3.2rem;

    &.-clickable {
      cursor: pointer;

      &:hover {
        ._trigger {
          background-color: $hover-color;
          border-radius: $border-radius-sm;
        }
      }
    }

    ._inner {
      max-width: 100vw;
    }
  }

  ._overlay {
    ._list {
      max-width: 100vw;
      max-height: 35rem;
      overflow: auto;

      ._item {
        display: flex;
        margin-bottom: 1.2rem;

        ._right {
          flex: 1 1 auto;
          overflow: hidden;

          ._avatar {
            padding: 1.2rem 0 0 1.2rem;
          }

          ._activity {
            width: 100%;
            cursor: pointer;

            &:hover {
              background-color: $hover-color;
            }
          }
        }
      }
    }

    &.-mobile {
      max-height: 100vh;
    }

    ._footer {
      max-width: 100vw;
      border-top: 1px solid $border-color;

      ._footer-inner {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
</style>
