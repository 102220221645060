export default {
  computed: {
    isOkta() {
      return this.idpOrigin.includes('okta.com');
    },
    idpOrigin() {
      if (typeof this.$route.query.idp_origin === 'undefined') {
        return '';
      }

      return this.$route.query.idp_origin;
    },
  },
};
