<template>
  <div class="goal-properties">
    <div
      v-for="p in properties"
      :key="p.key"
    >
      <property-label
        :key="p.uid"
        :label="textByLang(p.label, loggedInUser.language)"
        :errors="validationErrorByProperty(p)"
        :icon="iconByType({ edgeName: p.type, type: p.type })"
        :has-menu="canEditProperties"
        class="_prop"
      >
        <goal-property-form-item
          :property="p"
          :goal="goal"
          :goal-children="goalChildren"
          :placeholder="$t('general.empty')"
          :read-only="readOnly || !canEdit"
          wrap
          full-width
          :hide-border="true"
          :popup="true"
          :update-properties="(val) => updateProperty(val, p, goal)"
          :goal-picker-service="goalPickerService"
          match-trigger-width
        />
        <template #labelMenu>
          <property-menu
            v-if="canEditProperties"
            :can-customize="hasGoalType"
            :property="p"
            :goal="goal"
            @set-visibility="updateVisibility"
          />
        </template>
      </property-label>
    </div>
  </div>
</template>

<script>
import GoalPropertyFormItem from '@/components/goal/GoalPropertyFormItem.vue';
import PropertyLabel from '@/components/PropertyLabel.vue';
import PropertyMenu from '@/components/goal/PropertyMenu.vue';
import useAccess from '@/composables/access/access';
import useAccountSettings from '@/composables/logged-in-user-account/account-settings';
import useGoalDetailProperties from '@/composables/customize-page/goal-detail-page-properties';
import useGoalPicker from '@/composables/goal/goal-picker';
import useGoalTypeProperty from '@/composables/customize-page/goal-type-property';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useParentSelectorRules from '@/composables/goal/validator/parent-selector-rules';
import useValidator from '@/composables/goal/validator/validator';
import { editorNodeType, featureFlag, viewApplication } from 'shared/constants.json';
import { iconByType } from '@/lib/property';
import { textByLang } from 'shared/lib/language';
import { toRef } from 'vue';

export default {
  name: 'GoalProperties',
  props: {
    goal: {
      type: Object,
      required: true,
    },
    goalChildren: {
      type: Array,
      default: () => [],
    },
    updateProperty: {
      type: Function,
      required: true,
    },
    goalCycles: {
      type: Array,
      required: true,
    },
    properties: {
      type: Array,
      required: true,
    },
    canEditProperties: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    validationErrors: {
      type: Object,
      default: () => {
      },
    },
  },
  components: {
    GoalPropertyFormItem,
    PropertyMenu,
    PropertyLabel,
  },
  setup(props) {
    const { goalTypeProperty } = useGoalTypeProperty();
    const goal = toRef(props, 'goal');
    const { accountSettings } = useAccountSettings();
    const { loggedInUserAccount } = useLoggedInUserAccount();
    const { loggedInUser } = useLoggedInUser();
    const { updateVisibility } = useGoalDetailProperties({
      goal,
      userLang: loggedInUser.value.language,
      goalTypeProperty,
    });
    const { rules: parentRules } = useParentSelectorRules(goalTypeProperty.value.options);
    const { validate: validateParent } = useValidator({
      rules: parentRules,
      usesOKRRules: true,
    });

    const goalPickerService = useGoalPicker(goal, loggedInUserAccount, validateParent, accountSettings);

    const allowedDescriptionContent = [
      editorNodeType.bulletList,
      editorNodeType.orderedList,
    ];

    const { accountHasFeature } = useAccess();
    if (accountHasFeature([featureFlag.fileUpload])) {
      allowedDescriptionContent.push(editorNodeType.image, editorNodeType.file);
    }

    return {
      loggedInUser,
      updateVisibility,
      goalPickerService,
      allowedDescriptionContent,
    };
  },
  emits: ['change-parent'],
  data() {
    return { viewApplication: viewApplication.goalParentPicker };
  },
  computed: {
    hasGoalType() {
      const prop = this.goal.properties.find((p) => p.property.isGoalType);
      if (typeof prop === 'undefined') {
        return false;
      }

      return prop.selectedOptions.length > 0;
    },
  },
  methods: {
    textByLang,
    iconByType,
    validationErrorByProperty(p) {
      if (p.uid !== undefined && this.validationErrors[p.uid] !== undefined) {
        return this.validationErrors[p.uid];
      }
      return this.validationErrors[p.type];
    },
  },
};
</script>

<style lang="scss" type="text/scss">
</style>
