import { UID, VAR } from 'shared/api/query/constants';
import { goal } from 'shared/api/query/configs.json';
import { notHasFilter } from 'shared/api/query/filter';
import { reverseEdge } from 'shared/api/query/edges';

// FIXME: This translation does not exclude goal.parents and ~goal.parents in which those goals are deleted
export const directPropGoalFn = (model, varIdFn, isFilterMode = false) => (scope, index) => {
  const isReverseEdge = scope.directProperty.edgeName.startsWith('~');
  if (scope.isEmpty === true) {
    let edge = scope.directProperty.edgeName;
    if (isReverseEdge) {
      edge = `~${model}.${edge.split('~')[1]}`;
    }
    return {
      filterTrees: [notHasFilter(edge)],
      varBlocks: [],
    };
  }

  if ((scope.relation === undefined || scope.relation === null) && isFilterMode) {
    return { filterTrees: [], varBlocks: [] };
  }

  const varName = varIdFn(index);

  let attribute = reverseEdge(scope.directProperty.edgeName);
  if (isReverseEdge) {
    attribute = scope.directProperty.edgeName.split('~')[1];
  }
  const varBlocks = [
    {
      alias: VAR,
      uid: [scope.relation.uid],
      model: goal.model,
      func: { name: UID },
      children: [
        {
          attr: attribute,
          var: varName,
          model,
        },
      ],
    },
  ];

  const filterTree = {
    func: {
      name: UID,
      needsVar: [{ name: varName, typ: 1 }],
    },
  };
  return { filterTrees: [filterTree], varBlocks };
};
