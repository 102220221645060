import useCustomGridRepo from '@/composables/grid-page/grid-page-repo';
import useSubscription from '@/composables/subscription/subscription';
import {
  gridPage as gridPageConfig,
  gridPageRow as gridPageRowConfig,
  gridPageTile as gridPageTileConfig,
  gridPageTileSingleGoal as gridPageTileSingleGoalConfig,
  gridPageTileText as gridPageTileTextConfig,
} from 'shared/api/query/configs.json';
import { subscriptionAction } from 'shared/constants.json';
import { useStore } from 'vuex';

export default function useGridPageSubscription() {
  const repo = useCustomGridRepo();
  const subscriptionSvc = useSubscription();
  const store = useStore();

  const subscribeSingle = (entity) => {
    subscriptionSvc.subscribeSingle({
      model: gridPageConfig.model,
      id: entity.uid,
      syncFn: ({ action }) => {
        switch (action) {
          case subscriptionAction.update:
            repo.queryGridPage(entity.uid);
            break;
          case subscriptionAction.delete:
            store.commit('ENTITY_DELETED', { entity, model: gridPageConfig.model });
            break;
          default:
            break;
        }
      },
    });

    subscriptionSvc.subscribe({
      model: gridPageRowConfig.model,
      syncFn: ({ action, ids }) => {
        switch (action) {
          case subscriptionAction.update:
          case subscriptionAction.create:
            repo.queryGridPageRows(entity.uid, ids);
            break;
          case subscriptionAction.delete:
            store.commit('ENTITIES_DELETED', { entities: ids.map((id) => ({ uid: id })), model: gridPageRowConfig.model });
            break;
          default:
            break;
        }
      },
    });

    subscriptionSvc.subscribe({
      model: gridPageTileConfig.model,
      syncFn: ({ action, ids }) => {
        switch (action) {
          case subscriptionAction.update:
          case subscriptionAction.create:
            repo.queryGridPageTiles(entity.uid, ids);
            break;
          case subscriptionAction.delete:
            store.commit('ENTITIES_DELETED', { entities: ids.map((id) => ({ uid: id })), model: gridPageTileConfig.model });
            break;
          default:
            break;
        }
      },
    });

    subscriptionSvc.subscribe({
      model: gridPageTileTextConfig.model,
      syncFn: ({ action, ids }) => {
        switch (action) {
          case subscriptionAction.update:
          case subscriptionAction.create:
            repo.queryGridPageTileTextByIds(ids);
            break;
          case subscriptionAction.delete:
            store.commit('ENTITIES_DELETED', { entities: ids.map((id) => ({ uid: id })), model: gridPageTileTextConfig.model });
            break;
          default:
            break;
        }
      },
    });

    subscriptionSvc.subscribe({
      model: gridPageTileSingleGoalConfig.model,
      syncFn: ({ action, ids }) => {
        switch (action) {
          case subscriptionAction.update:
          case subscriptionAction.create:
            repo.queryGridPageTileSingleGoalByIds(ids);
            break;
          case subscriptionAction.delete:
            store.commit('ENTITIES_DELETED', { entities: ids.map((id) => ({ uid: id })), model: gridPageTileTextConfig.model });
            break;
          default:
            break;
        }
      },
    });
  };

  const subscribe = () => {
    subscriptionSvc.subscribe({
      model: gridPageConfig.model,
      syncFn({ ids, action }) {
        switch (action) {
          case subscriptionAction.create:
          case subscriptionAction.update:
            repo.queryGridPages(ids);
            break;
          case subscriptionAction.delete:
            store.commit('ENTITIES_DELETED', { entities: ids.map((id) => ({ uid: id })), model: gridPageConfig.model });
            break;
          default:
            break;
        }
      },
    });
  };

  return { subscribe, subscribeSingle };
}
