<template>
  <div class="dashboard-group">
    <div class="_title_container">
      <div
        v-if="expandable"
        class="_expand"
      >
        <m-btn
          :icon="expandIcon"
          fab
          xs
          hide-border
          @click.stop.prevent="toggleExpanded"
        />
      </div>
      <slot name="title">
        <div class="_title">
          {{ title }}
        </div>
      </slot>
    </div>

    <m-transition-expand v-if="expandable">
      <div v-show="localExpanded">
        <slot />
      </div>
    </m-transition-expand>
    <slot v-else />
  </div>
</template>

<script>

export default {
  name: 'DashboardGroup',
  props: {
    title: {
      type: String,
      default: '',
    },
    expandable: {
      type: Boolean,
      default: false,
    },
    expanded: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['update:expanded'],
  data() {
    return { localExpanded: false };
  },
  computed: {
    expandIcon() {
      if (this.localExpanded) {
        return 'chevron-down';
      }

      return 'chevron-right';
    },
  },
  methods: {
    toggleExpanded() {
      if (!this.expandable) {
        return;
      }

      this.localExpanded = !this.localExpanded;
      this.$emit('update:expanded', this.localExpanded);
    },
  },
  watch: {
    expanded: {
      handler(newVal) {
        this.localExpanded = newVal;
      },
      immediate: true,
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  @import "shared/assets/scss/loading";

  .dashboard-group {
    margin-bottom: 3.2rem;

    ._title_container {
      display: flex;
      align-items: center;
      margin-bottom: 1.2rem;
      color: $font-color-primary;

      ._expand {
        padding-right: .4rem;
        margin-left: -2.6rem;
      }

      ._title {
        font-size: $font-size-7;
        font-weight: $font-weight-semibold;
      }
    }
  }
</style>
