<template>
  <m-card-item
    v-for="item in items"
    :key="item.value"
    class="_item"
    @click="select(item)"
  >
    <template #left>
      <color-item :color="item.value" />
    </template>
    <div class="_text">
      {{ item.text }}
    </div>
    <template #right>
      <m-icon
        v-if="item.value === value"
        type="check"
      />
    </template>
  </m-card-item>
</template>

<script setup>
import ColorItem from '@/components/ColorItem.vue';

defineProps({
  items: {
    type: Array,
    required: true,
  },
  value: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['select']);

const select = (item) => {
  emit('select', item);
};
</script>

<style scoped lang="scss" type="text/scss">

</style>
