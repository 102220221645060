<template>
  <comment-layout
    class="comment-item"
    :creator="comment.creator"
    :show-meta="comment.showMeta"
    :timestamp="timestamp"
  >
    <m-editor
      ref="editor"
      :initial-value="message"
      :allowed-content="allowedContent"
      :disabled="disabled"
      :class="['_editor', $store.state.breakpoint.smAndDown ? '-mobile' : '']"
      :ctrl-enter-handlers="[onCmdOrModEnter]"
      :mod-enter-handlers="[onCmdOrModEnter]"
      @input="updateMessage"
    />
    <m-btn
      v-if="!disabled"
      class="_submit-btn"
      color="primary"
      small
      @click="submit"
    >
      {{ $t('general.save') }}
    </m-btn>
    <template #actions>
      <div
        v-if="canEdit"
        class="_actions"
      >
        <m-dropdown
          :title="$t('general.actions')"
          close-on-click
        >
          <m-btn
            :hide-border="!$store.state.breakpoint.smAndDown"
            icon="ellipsis"
            fab
            light
            small
          />
          <template #overlay>
            <m-card
              list
              no-padding
            >
              <m-card-item
                icon="edit"
                @click="edit"
              >
                {{ $t('general.edit') }}
              </m-card-item>
              <m-card-item
                icon="delete"
                @click="confirmDelete"
              >
                {{ $t('general.delete') }}
              </m-card-item>
            </m-card>
          </template>
        </m-dropdown>
      </div>
    </template>
  </comment-layout>
</template>

<script>
import CommentLayout from '@/components/comment/CommentLayout.vue';
import MEditor from '@/components/editor/MEditor.vue';
import useAccess from '@/composables/access/access';
import useComments from '@/composables/comments/comments';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import { DateTime } from 'luxon';
import { editorNodeType, featureFlag } from 'shared/constants.json';
import { trimEmptyLines } from '@/lib/editor/editor';

export default {
  name: 'CommentItem',
  props: {
    comment: {
      type: Object,
      required: true,
    },
  },
  components: {
    CommentLayout,
    MEditor,
  },
  setup() {
    const allowedContent = [
      editorNodeType.codeBlock,
      editorNodeType.blockquote,
      editorNodeType.bulletList,
      editorNodeType.orderedList,
      editorNodeType.mention,
      editorNodeType.goalMention,
    ];

    const { accountHasFeature } = useAccess();
    if (accountHasFeature([featureFlag.fileUpload])) {
      allowedContent.push(editorNodeType.image, editorNodeType.file);
    }

    const { updateComment, deleteComment } = useComments();
    const { loggedInUser } = useLoggedInUser();
    return {
      updateComment,
      deleteComment,
      loggedInUser,
      allowedContent,
    };
  },
  data() {
    return {
      disabled: true,
      message: '',
    };
  },
  computed: {
    canEdit() {
      if (this.comment.creator === null) {
        return false;
      }

      return this.loggedInUser.uid === this.comment.creator.uid;
    },
    timestamp() {
      return DateTime.fromISO(this.comment.createdAt).toLocaleString(DateTime.DATETIME_MED);
    },
  },
  methods: {
    onCmdOrModEnter() {
      this.submit();
      return true;
    },
    updateMessage(value) {
      this.message = value;
    },
    edit() {
      this.disabled = false;
      this.$nextTick(() => {
        this.$refs.editor.focus();
      });
    },
    submit() {
      this.message = trimEmptyLines(this.message);
      if (typeof this.$refs.editor !== 'undefined') {
        this.$refs.editor.setContent(this.message);
      }

      this.updateComment({
        ...this.comment,
        message: this.message,
      }).then(() => {
        this.disabled = true;
      });
    },
    confirmDelete() {
      const deleteMethod = () => {
        this.deleteComment(this.comment.uid).catch(() => {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
        });
      };

      this.$confirm({
        title: this.$t('commentItem.deletePrompt'),
        okText: this.$t('general.yesDelete'),
        okType: 'danger',
        maskClosable: true,
        cancelText: this.$t('general.cancel'),
        onOk() {
          deleteMethod();
        },
      });
    },
  },
  watch: {
    comment: {
      handler(val) {
        this.updateMessage(val.message);
      },
      deep: true,
    },
  },
  created() {
    this.message = this.comment.message;
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .comment-item {
    ._editor {
      &.-mobile {
        margin-right: 3rem;
      }
    }
  }
</style>
