import { BaseHandler } from '@/lib/filter/base-translator/handler';
import { reverseEdge } from 'shared/api/query/edges';
import { update } from 'shared/api/query/configs.json';

const childrenFn = (varName) => ([
  {
    attr: reverseEdge(update.edges.properties),
    model: update.model,
    var: varName,
  },
]);

export class UpdatesFilterHandler {
  constructor() {
    this.baseHandler = new BaseHandler({
      model: update.model,
      childrenFn,
      isFilterMode: true,
    });
  }

  Translate(tree) {
    return this.baseHandler.Translate(tree);
  }
}
