import useAccess from '@/composables/access/access';
import useCurrentView from '@/composables/saved-views/current-view';
import useDefaultView from '@/composables/saved-views/default-view';
import useEnrichParams from '@/composables/saved-views/enrich-params';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useReadOnly from '@/composables/saved-views/read-only';
import useSelectedViews from '@/composables/saved-views/selected-views';
import useViews from '@/composables/saved-views/views';
import useViewsRepo from '@/composables/saved-views/views-repo';
import useViewsRepoLocal from '@/composables/saved-views/views-repo-local';
import { accessGroupFlag } from 'shared/constants.json';

export default function useInMemoryViews({
  defaultProps,
  defaultView,
  application,
  space = null,
  planning = null,
  gridPageTile = null,
  user = null,
  readOnly,
  includeGoalCycle = false,
}) {
  const selectedViewsSvc = useSelectedViews();
  const { loggedInUserAccount } = useLoggedInUserAccount();
  const { loggedInUser } = useLoggedInUser();

  const viewsRepo = useViewsRepo({ application, space, planning, gridPageTile });
  const defaultViewRepo = useDefaultView(
    defaultView,
    defaultProps,
    application,
    space,
    planning,
    user,
    loggedInUser,
    loggedInUserAccount,
    gridPageTile,
    viewsRepo,
  );
  const viewsRepoLocal = useViewsRepoLocal(defaultViewRepo, loggedInUserAccount);
  const enrichParamsRepo = useEnrichParams(viewsRepoLocal);
  const readOnlyRepo = useReadOnly(readOnly, enrichParamsRepo);

  const { userHasRight } = useAccess();

  const selectedViewForApplication = selectedViewsSvc.getSelectedViewForApplication({
    application,
    space,
    planning,
    user,
    gridPageTile,
  });

  const changeSelectedView = (view) => {
    selectedViewsSvc.saveOrUpdateSelectedView(selectedViewForApplication.value, view.uid);
  };

  const viewsSvc = useViews({
    application,
    space,
    planning,
    user,
    gridPageTile,
    selectedViewsSvc,
    changeSelectedView,
    repo: readOnlyRepo,
  });

  const currentViewSvc = useCurrentView({
    defaultProps,
    views: viewsSvc.views,
    hasPublicViewAccess: userHasRight([accessGroupFlag.publicSavedViews]),
    selectedView: selectedViewForApplication,
    readOnly,
  });

  const createViews = (views, viewOrder) => {
    if (includeGoalCycle) {
      return viewsSvc.createViews(views.map((v) => ({
        ...v,
        goalCycles: currentViewSvc.currentView.value.goalCycles,
      })), viewOrder);
    }
    return viewsSvc.createViews(views, viewOrder);
  };

  return {
    createLoading: viewsSvc.createLoading,
    createViews,

    loadingUpdateView: viewsSvc.loadingUpdateView,
    updateView: viewsSvc.updateView,

    loadingUpdateMetaData: viewsSvc.loadingUpdateMetaData,
    updateMetaData: viewsSvc.updateMetaData,

    loadingDeleteView: viewsSvc.loadingDeleteView,
    deleteView: viewsSvc.deleteView,

    setParams: viewsSvc.setParams,
    updateSort: viewsSvc.updateSort,
    updateCycles: viewsSvc.updateCycles,

    hasUnsavedChanges: viewsSvc.hasUnsavedChanges,

    resetView: viewsSvc.resetView,

    views: viewsSvc.views,
    viewCollection: viewsSvc.viewCollection,

    currentView: currentViewSvc.currentView,
    canEditCurrentView: currentViewSvc.canEditCurrentView,

    changeSelectedView,

    initCurrentView: viewsSvc.initCurrentView,
  };
}
