<template>
  <component :is="$store.state.breakpoint.smAndDown ? 'm-card-item' : 'div'">
    <m-select
      :items="filteredProps"
      :value="value"
      :label="$t('groupBy.groupBy')"
      :properties="properties"
      :hide-border="!$store.state.breakpoint.smAndDown"
      btn
      light
      small
      item-text="label.de"
      value-key="uid"
      return-object
      @input="change"
      @dropdown-visible-change="dropdownVisibleChange"
    />
  </component>
</template>

<script>
import { propertyType } from 'shared/constants.json';

export default {
  name: 'GroupBy',
  props: {
    properties: {
      type: Array,
      required: true,
    },
    value: {
      type: Object,
      default: null,
    },
  },
  emits: ['input', 'update:value', 'dropdown-visible-change'],
  computed: {
    filteredProps() {
      return this.properties.filter((p) => [propertyType.options, propertyType.singleSelect, propertyType.space].includes(p.type));
    },
  },
  methods: {
    dropdownVisibleChange(value) {
      this.$emit('dropdown-visible-change', value);
    },
    change(value) {
      this.$emit('input', value);
      this.$emit('update:value', value);
    },
  },
  created() {
    if (this.value === null && this.filteredProps.length > 0) {
      this.$emit('input', this.filteredProps[0]);
      this.$emit('update:value', this.filteredProps[0]);
    }
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .group-by {
    display: inline-flex;
  }
</style>
