<template>
  <m-content
    :class="['list-item', tiny ? '-tiny' : '', selected ? '-selected' : '', selectedSecondary ? '-selected-secondary' : '', $store.state.breakpoint.smAndDown ? '-mobile' : '']"
    :padding-y="4"
    @click.stop="select"
  >
    <div class="_content">
      <slot
        name="title"
      >
        <div class="_title">
          <item-title
            :title="title"
            :icons="[{ value: icon }]"
          />
        </div>
      </slot>
      <slot
        v-if="!!$slots.beforeProps"
        name="beforeProps"
      />
      <div
        v-for="prop in visibleProps"
        :key="prop.key"
        class="_item"
      >
        <component-prop
          v-if="prop.property.component !== undefined"
          :entity="entity"
          :prop="prop"
          :property-values-key="propertyValuesKey"
          :read-only="readOnly"
          :show-progress-diff="showProgressDiff"
          progress-bar-width="5rem"
          show-tooltip
          show-icon
          @click="emitClick(prop, entity)"
        />
        <template v-else>
          <user-prop
            v-if="prop.property.type === propertyType.user"
            :users="getUsers(prop)"
            :label="prop.label"
            show-tooltip
          />
          <space-prop
            v-if="prop.property.type === propertyType.space"
            :spaces="getSpaces(prop)"
            :label="prop.label"
            show-tooltip
            @click="emitClick(prop, entity)"
            @space-clicked="$emit('space-clicked', $event)"
          />
          <option-prop
            v-if="[propertyType.options, propertyType.singleSelect].includes(prop.property.type)"
            :options="getOptions(prop)"
            :label="prop.label"
            show-tooltip
            @click="emitClick(prop, entity)"
            @option-clicked="$emit('option-clicked', $event)"
          />
          <div
            v-if="prop.property.type === propertyType.status"
            :style="{ display: 'flex' }"
          >
            <status-prop
              :goal="entity"
              :label="prop.label"
              show-tooltip
              @click="emitClick(prop, entity)"
            />
            <status-auto-update-hint
              v-if="entity.disableStatusAutoUpdate && entity.progressMeasurement === alignedItems"
              :goal="entity"
              :style="{ marginLeft: '.4rem' }"
            />
          </div>
          <date-prop
            v-if="prop.property.type === propertyType.date"
            :entity="entity"
            :prop="prop"
            :property-values-key="propertyValuesKey"
            show-tooltip
          />
          <rich-text-prop
            v-if="prop.property.type === propertyType.richText"
            :entity="entity"
            :prop="prop"
            :property-values-key="propertyValuesKey"
            show-tooltip
          />
          <text-prop
            v-if="prop.property.type === propertyType.text"
            :entity="entity"
            :property-values-key="propertyValuesKey"
            :prop="prop"
            show-tooltip
          />
          <url-prop
            v-if="prop.property.type === propertyType.url"
            :entity="entity"
            :property-values-key="propertyValuesKey"
            :prop="prop"
            show-tooltip
          />
          <number-prop
            v-if="prop.property.type === propertyType.number"
            :entity="entity"
            :prop="prop"
            :property-values-key="propertyValuesKey"
            show-tooltip
          />
          <lookup-value
            v-else-if="prop.property.type === propertyType.lookup"
            :lookup-property="prop.property"
            :style="{ marginLeft: '.8rem' }"
            :values="entity[propertyValuesKey]"
            hide-placeholder
          />
        </template>
      </div>
      <slot
        v-if="!!$slots.afterProps"
        name="afterProps"
      />
    </div>
  </m-content>
</template>

<script>
import ComponentProp from '@/components/list/ComponentProp.vue';
import DateProp from '@/components/list/DateProp.vue';
import ItemTitle from '@/components/ItemTitle.vue';
import LookupValue from '@/components/property/LookupValue.vue';
import NumberProp from '@/components/list/NumberProp.vue';
import OptionProp from '@/components/list/OptionProp.vue';
import RichTextProp from '@/components/list/RichTextProp.vue';
import SpaceProp from '@/components/list/SpaceProp.vue';
import StatusAutoUpdateHint from '@/components/goal/StatusAutoUpdateHint.vue';
import StatusProp from '@/components/property/StatusProp.vue';
import TextProp from '@/components/list/TextProp.vue';
import UrlProp from '@/components/list/UrlProp.vue';
import UserProp from '@/components/list/UserProp.vue';
import useResourceSettings from '@/composables/logged-in-user-account/resource-settings';
import useUsers from '@/composables/user/users';
import { buildIconFromEntity } from 'shared/lib/icon';
import { getValueFromEntity, sortedPropertyElements } from '@/lib/props';
import { goalProgressMeasurement, propertyType } from 'shared/constants.json';

export default {
  name: 'ListItem',
  props: {
    entity: {
      type: Object,
      required: true,
    },
    props: {
      type: Array,
      required: true,
    },
    tiny: {
      type: Boolean,
      default: false,
    },
    propertyValuesKey: {
      type: String,
      default: 'properties',
    },
    hasChildren: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    selectedSecondary: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    showProgressDiff: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['option-clicked', 'select', 'prop-clicked', 'space-clicked'],
  components: {
    SpaceProp,
    StatusAutoUpdateHint,
    RichTextProp,
    ItemTitle,
    UrlProp,
    TextProp,
    ComponentProp,
    NumberProp,
    OptionProp,
    DateProp,
    UserProp,
    LookupValue,
    StatusProp,
  },
  setup() {
    const { resourceSettings } = useResourceSettings();
    const userSvc = useUsers();
    return { resourceSettings, userSvc };
  },
  data() {
    return {
      propertyType,
      alignedItems: goalProgressMeasurement.alignedItems,
    };
  },
  computed: {
    visibleProps() {
      return this.props.filter((p) => !p.hideInProps && p.show && !p.isTitle);
    },
    titleProp() {
      const filtered = this.props.filter((p) => p.isTitle);
      if (filtered.length === 0) {
        return null;
      }
      return filtered[0];
    },
    icon() {
      return buildIconFromEntity(this.entity);
    },
    title() {
      if (this.titleProp === null) {
        return this.$t('list.noTitle');
      }
      const title = getValueFromEntity(this.titleProp, this.entity, this.propertyValuesKey);
      if (title === null || title === '') {
        return this.$t('list.noTitle');
      }
      return title;
    },
  },
  methods: {
    isDirectEdgeValue(value) {
      if (!Array.isArray(value) && typeof value !== 'object') {
        return true;
      }

      if (value.length === 0) {
        return typeof value.uid === 'undefined';
      }

      return typeof value[0].uid === 'undefined';
    },
    getOptions(prop) {
      const val = getValueFromEntity(prop, this.entity, this.propertyValuesKey);
      if (val === null) {
        return [];
      }
      if (prop.isDirect && !this.isDirectEdgeValue(val)) {
        return prop.property.options.filter((o) => val.map((v) => v.uid).includes(o.value));
      }
      if (prop.isDirect) {
        return prop.property.options.filter((o) => o.value === val);
      }
      const sortedVal = sortedPropertyElements(val, prop.property.propertyOptionOrder);
      if (prop.property.type === propertyType.singleSelect && sortedVal.length > 1) {
        return [sortedVal[0]];
      }
      return sortedVal;
    },
    getSpaces(prop) {
      const spaces = getValueFromEntity(prop, this.entity, this.propertyValuesKey);
      if (spaces === null) {
        return [];
      }
      if (!Array.isArray(spaces)) {
        return [spaces];
      }
      return sortedPropertyElements(spaces, this.resourceSettings.spaceOrder.map(({ uid }) => uid));
    },
    getUsers(prop) {
      const users = getValueFromEntity(prop, this.entity, this.propertyValuesKey);
      if (users === null) {
        return [];
      }

      return this.userSvc.selectMultiple(users.map((u) => u.uid));
    },
    select() {
      this.$emit('select', this.entity);
    },
    emitClick(prop, entity) {
      this.$emit('prop-clicked', prop, entity);
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .list-item {
    overflow: hidden;
    cursor: pointer;
    border-radius: $border-radius-sm;

    &:hover {
      background-color: $hover-color;
    }

    &.-selected {
      background-color: $highlighted-color;
    }

    &.-selected-secondary {
      background-color: $highlighted-color-light;
    }

    ._content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 0.8rem;

      ._title {
        min-width: 24rem;
        margin-right: auto;
        margin-left: .8rem;
        overflow: hidden;
        font-size: $font-size-5;
        font-weight: $font-weight-medium;
        text-overflow: ellipsis;
        white-space: nowrap;

        ._icon {
          margin-right: .2rem;
          font-size: $font-size-5;
        }
      }

      ._item {
        max-height: 2.4rem;
        flex: 0 0 auto;

        ._prop {
          margin-left: .8rem;
        }

        &:first-of-type {
          margin-left: 0;
        }
      }
    }

    &.-mobile,
    &.-tiny {
      ._content {
        flex-wrap: wrap;
        height: 100%;

        ._title {
          width: 100%;
          margin-bottom: .4rem;
        }

        ._item {
          margin-top: .8rem;
        }
      }
    }
  }
</style>
