<template>
  <m-content
    padding
    class="factor-list"
  >
    <m-draggable
      draggable-item-class="factor-item"
      dragover-item-class="factor-item"
      can-drag-over-top
      can-drag-over-bottom
      :drag-between-height="16"
      :recreate-key="factors.length"
      @set-drag-item="setDragItem"
      @over-top="setOverTop"
      @over-bottom="setOverBottom"
      @drag-drop="drop"
      @cancel="cancelDragging"
    >
      <transition-group
        name="list-left"
        mode="out-in"
      >
        <factor-item
          v-for="(item, index) in factors"
          :key="item.uid"
          :item="item"
          :selected="selected"
          :lang="lang"
          :dragging-over-bottom="dragItemId !== '' && draggingOverBottom.includes(item.uid)"
          :dragging-over-top="index === 0 && dragItemId !== '' && draggingOverTop.includes(item.uid)"
          @click="emit(item)"
          @edit="edit(item)"
          @delete-item="deleteItem(item)"
        />
      </transition-group>
    </m-draggable>
  </m-content>
</template>

<script>
import FactorItem from '@/components/FactorItem.vue';
import useSort from '@/composables/draggable/sort';

export default {
  name: 'FactorList',
  props: {
    factors: {
      type: Array,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
    selected: {
      type: Object,
      default: () => null,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
    model: {
      type: String,
      required: true,
    },
  },
  emits: ['select', 'edit', 'delete'],
  setup() {
    const {
      setDragItem,
      setOverBottom,
      setOverTop,
      draggingOverBottom,
      draggingOverTop,
      dropItem,
      cancelDragging,
      dragItemId,
    } = useSort();
    return { dragItemId, setDragItem, setOverBottom, setOverTop, draggingOverBottom, draggingOverTop, dropItem, cancelDragging };
  },
  components: { FactorItem },
  computed: {
    orderedFactors: {
      get() {
        return this.factors;
      },
      set(value) {
        this.$store.commit('FORM_FACTORS_ORDER_CHANGED', { factors: value, model: this.model });
      },
    },
  },
  methods: {
    emit(item) {
      this.$emit('select', item);
    },
    edit(item) {
      this.$emit('edit', item);
    },
    deleteItem(item) {
      this.$emit('delete', item);
    },
    drop() {
      this.orderedFactors = this.dropItem(this.orderedFactors);
    },
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
</style>
