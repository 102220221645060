<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.0293 20.2621L20.0293 18.8558C20.0293 18.7527 19.9449 18.6683 19.8418 18.6683L7.70117 18.6683L7.70117 7.27769L9.41211 7.27769C9.56914 7.27769 9.6582 7.09488 9.55977 6.973L6.93477 3.64722C6.91723 3.62481 6.89482 3.60669 6.86923 3.59422C6.84365 3.58176 6.81557 3.57528 6.78711 3.57528C6.75865 3.57528 6.73057 3.58176 6.70499 3.59422C6.6794 3.60669 6.65699 3.62481 6.63945 3.64722L4.01445 6.973C3.91602 7.09722 4.00508 7.27769 4.16211 7.27769L5.91992 7.27769L5.91992 18.9496C5.91992 19.7769 6.59258 20.4496 7.41992 20.4496L19.8418 20.4496C19.9449 20.4496 20.0293 20.3652 20.0293 20.2621Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default { name: 'Aligned' };
</script>

<style scoped lang="scss" type="text/scss">

</style>
