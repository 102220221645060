<template>
  <div
    :class="['m-toolbar', small ? '-small' : '']"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'MToolbar',
  props: {
    small: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" type="text/scss">
  @import 'shared/assets/scss/padding';

  .m-toolbar {
    display: flex;
    align-items: center;

    @include padding;

    &.-small {
      @include padding('small');
    }
  }
</style>
