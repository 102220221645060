<template>
  <div
    class="form-settings"
  >
    <form-language-settings-card
      :entity="entity"
      :languages="languages"
      :create-url="createUrl"
      :disabled="disabled"
      @add-language="addLanguage"
      @delete-language="deleteLang"
    />
  </div>
</template>

<script>
import FormLanguageSettingsCard from '@/components/FormLanguageSettingsCard.vue';
import useAddFormEditor from '@/composables/form/add-form-editor';
import { mapActions } from 'vuex';

export default {
  name: 'FormSettings',
  props: {
    model: {
      type: String,
      required: true,
    },
    entity: {
      type: Object,
      required: true,
    },
    entityById: {
      type: Function,
      required: true,
    },
    createUrl: {
      type: Object,
      required: true,
    },
    languages: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const { appendEditor } = useAddFormEditor();
    return { appendEditor };
  },
  components: { FormLanguageSettingsCard },
  methods: {
    ...mapActions([
      'updateEntity',
      'deleteLanguage',
    ]),
    addLanguage({ lang }) {
      this.$store.commit('FIELD_TITLE_LANGUAGE_ADDED', { lang, model: this.model });
    },
    update() {
      this.updateEntity({
        entity: this.appendEditor(this.entity),
        model: this.model,
        entityById: this.entityById,
      }).then((response) => {
        if (response.status !== 200) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
          return;
        }
        this.$showSnackbar({ color: 'success', message: this.$t('success.saved') });
      });
    },
    deleteLang({ lang }) {
      this.$router.push({ ...this.$route, query: {} });
      this.deleteLanguage({ ids: [this.entity.uid], languages: [lang], model: this.model }).then((response) => {
        if (response.status !== 200) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
          return;
        }
        this.$showSnackbar({ color: 'success', message: this.$t('success.deleted') });
      });
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .form-settings {
    width: 100%;
  }
</style>
