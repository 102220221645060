<template>
  <div
    :class="['m-radio', selected ? '-selected' : '']"
    @click="$emit('select', value)"
  >
    <div class="_button">
      <div
        v-if="selected"
        class="_inner-circle"
      />
    </div>
    <div class="_label">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'MRadio',
  props: {
    selected: {
      type: Boolean,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
  },
  emits: ['select'],
};
</script>

<style scoped lang="scss" type="text/scss">
  .m-radio {
    display: flex;
    align-items: center;
    cursor: pointer;

    ._button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1.8rem;
      height: 1.8rem;
      margin-right: .8rem;
      border: 1.5px solid map_get($grey, 'darken-1');
      border-radius: .9rem;
      transition: border-color .3s ease-in-out;

      ._inner-circle {
        width: 1rem;
        height: 1rem;
        background-color: $primary-color;
        border-radius: .6rem;
      }
    }

    &:hover {
      ._button {
        border-color: $primary-color;
      }
    }

    &.-selected {
      ._button {
        border-color: $primary-color;
      }
    }
  }
</style>
