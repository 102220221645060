export default function useReadOnly(readOnly, repo) {
  const updateView = (view) => {
    if (readOnly) {
      return new Promise((resolve) => { resolve(view); });
    }

    return repo.updateView(view);
  };

  const updateMetaData = (view, sortOperation) => {
    if (readOnly) {
      return new Promise((resolve) => { resolve(view); });
    }

    return repo.updateView(view, {}, { sortOperation });
  };

  const deleteView = (view) => {
    if (readOnly) {
      return new Promise((resolve) => { resolve(view); });
    }

    return repo.deleteView(view);
  };

  const createViews = (views, viewOrder) => {
    if (readOnly) {
      return new Promise((resolve) => { resolve(views); });
    }

    return repo.createViews(views, viewOrder);
  };

  const setParams = (view, params) => {
    if (readOnly) {
      return new Promise((resolve) => { resolve(view); });
    }

    return repo.setParams(view, params);
  };

  return {
    createLoading: repo.createLoading,
    createViews,

    loadingUpdateView: repo.loadingUpdateView,
    updateView,

    loadingUpdateMetaData: repo.loadingUpdateMetaData,
    updateMetaData,

    loadingDeleteView: repo.loadingDeleteView,
    deleteView,

    setParams,
    updateSort: repo.updateSort,
    updateCycles: repo.updateCycles,

    resetView: repo.resetView,

    hasUnsavedChanges: repo.hasUnsavedChanges,

    views: repo.views,
  };
}
