import useRepo from '@/nebula/repo';
import { RESULT, UID } from 'shared/api/query/constants';
import { customDatasourceProperty } from 'shared/constants.json';
import { dataSource as dataSourceConfig } from 'shared/api/query/configs.json';
import { ref } from 'vue';

export default function useAsanaDatasourceRepo() {
  const repo = useRepo(dataSourceConfig.model);

  const getSingleLoading = ref(false);
  const getSingle = (entity) => {
    getSingleLoading.value = true;
    return repo.query([{
      alias: RESULT,
      func: { name: UID },
      model: dataSourceConfig.model,
      uid: [entity.uid],
      children: [
        { attr: UID },
        {
          attr: customDatasourceProperty.asanaProjects,
          filter: {
            func: {
              name: 'eq',
              attr: 'workspace',
              args: [{ value: entity[customDatasourceProperty.asanaProjects][0].workspace }],
            },
          },
        },
      ],
    }]).then(() => repo.selectSingle(entity.uid)).finally(() => {
      getSingleLoading.value = false;
    });
  };

  const getSingleDetailsLoading = ref(false);
  const getSingleDetails = (entity) => {
    getSingleDetailsLoading.value = true;
    return repo.query([{
      alias: RESULT,
      func: { name: UID },
      model: dataSourceConfig.model,
      uid: [entity.uid],
      children: [
        { attr: 'uid' },
        {
          attr: customDatasourceProperty.asanaProjects,
          filter: { func: { name: 'eq', attr: 'gid', args: [{ value: entity[customDatasourceProperty.asanaProjects][0].gid }] } },
          default: [],
        },
      ],
    }]).then(() => repo.selectSingle(entity.uid)).finally(() => {
      getSingleDetailsLoading.value = false;
    });
  };

  return {
    getSingleLoading,
    getSingle,
    getSingleDetailsLoading,
    getSingleDetails,
  };
}
