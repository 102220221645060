import { UID } from 'shared/api/query/constants';
import { collectionItem as collectionItemConfig, page as pageConfig } from 'shared/api/query/configs.json';
import { children as pageChildren } from '@/api/query/page';

export const children = [
  { attr: UID },
  { attr: collectionItemConfig.edges.type },
  {
    attr: collectionItemConfig.edges.checked,
    default: false,
  },
  {
    attr: collectionItemConfig.edges.content,
    default: null,
    model: pageConfig.model,
    children: pageChildren,
  },
];
