import { addKeyResultOption, addObjectiveOption } from '@/lib/property';
import { goalType, keyResultOption, objectiveOption } from '@/lib/goal/goal-type';
import { goalType as type } from '@/constants.json';

export const addObjectiveType = (goalProperties) => (goal) => {
  if (!Array.isArray(goal.parents) || goal.parents.length === 0) {
    goal.properties = addObjectiveOption({
      properties: goal.properties,
      objectiveOption: objectiveOption(goalProperties.value),
    });
    return goal;
  }

  if (goalType(goal.parents[0]) === type.keyResult) {
    goal.properties = addObjectiveOption({
      properties: goal.properties,
      objectiveOption: objectiveOption(goalProperties.value),
    });
    return goal;
  }

  return goal;
};

export const addKeyResultType = (goalProperties) => (goal) => {
  if (!Array.isArray(goal.parents) || goal.parents.length === 0 || goalType(goal.parents[0]) !== type.objective) {
    return goal;
  }

  goal.properties = addKeyResultOption({
    properties: goal.properties,
    keyResultOption: keyResultOption(goalProperties.value),
  });
  return goal;
};
