<template>
  <div :class="['goal-progress-table-cell', alignDiffRight ? '-align-diff-right' : '']">
    <goal-progress
      :goal="entity"
      :m-style="mStyle"
      hide-details
      hide-status
      no-padding
      :hover="canComment"
      :clickable="canComment"
      :progress-bar-width="progressBarWidth"
      @click.stop="$emit('click', $event)"
    />
    <div
      v-if="showProgressDiff && typeof entity.progressCourseDiff !== 'undefined' && entity.progressCourseDiff.length > 0"
      :class="['_delta', diff === 0 ? '-neutral' : '', diff > 0 ? '-positive' : '', diff < 0 ? '-negative' : '']"
    >
      <span v-if="diff > 0">+</span><span v-else-if="diff < 0" /><span v-else>±</span>{{ diff }} %
    </div>
  </div>
</template>

<script>
import GoalProgress from '@/components/goal/GoalProgress.vue';
import { accessPolicyType } from 'shared/constants.json';
import { mStyleProps } from 'shared/lib/m-style-props';

export default {
  name: 'GoalProgressTableCell',
  props: {
    ...mStyleProps,
    entity: {
      type: Object,
      required: true,
    },
    showProgressDiff: {
      type: Boolean,
      default: false,
    },
    alignDiffRight: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    progressBarWidth: {
      type: String,
      default: '100%',
    },
  },
  emits: ['click'],
  components: { GoalProgress },
  computed: {
    canComment() {
      return !this.readOnly && this.entity.publishedAt !== null && [accessPolicyType.full, accessPolicyType.write, accessPolicyType.comment].includes(this.entity.accessRight);
    },
    diff() {
      if (typeof this.entity.progressCourseDiff === 'undefined' || this.entity.progressCourseDiff.length === 0) {
        return 0;
      }

      return Math.round(this.entity.cachedCalculatedCurrent - this.entity.progressCourseDiff[0].progress);
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .goal-progress-table-cell {
    display: flex;
    min-width: 100%;

    ._delta {
      display: flex;
      align-items: center;
      margin-left: .4rem;
      font-size: $font-size-2;
      white-space: nowrap;

      &.-negative {
        color: $error-color;
      }

      &.-positive {
        color: $success-color;
      }

      &.-neutral {
        color: $font-color-secondary;
      }
    }

    &.-align-diff-right {
      ._delta {
        justify-content: flex-end;
        min-width: 5rem;
        margin-left: auto;
        text-align: right;
      }
    }
  }

</style>
