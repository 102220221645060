<template>
  <div class="account-space">
    <account-space-template :tabs="tabs" />
    <product-tour
      v-if="adminProductTourActive && !$store.state.breakpoint.smAndDown"
      :steps="productTourSteps"
      @finish="setAdminProductTourDone"
      @close="closeAdminProductTour"
    />
    <after-account-space-demo-modal
      :value="showAfterDemoModal"
      @created="showAfterDemoModal = false"
      @close="showAfterDemoModal = false"
    />
    <demo-data-modal />
  </div>
</template>

<script>

import AccountSpaceTemplate from '@/views/AccountSpaceTemplate.vue';
import AfterAccountSpaceDemoModal from '@/components/onboarding/AfterAccountSpaceDemoModal.vue';
import DemoDataModal from '@/components/onboarding/DemoDataModal.vue';
import ProductTour from '@/components/onboarding/ProductTour.vue';
import useAccess from '@/composables/access/access';
import useAccountSettings from '@/composables/logged-in-user-account/account-settings';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useOnboardingMarker from '@/composables/onboarding/onboarding-marker';
import useViewNavigator from '@/composables/saved-views/navigator';
import { GOAL_FEED_VIEW, GOAL_INSIGHT_VIEW, GOAL_VIEW, UPDATE_VIEW, USER_VIEW } from '@/route-params';
import { accessGroupFlag, moduleFlag, onboardingGuideSteps, viewApplication } from 'shared/constants.json';
import { mapActions } from 'vuex';

export default {
  name: 'AccountSpace',
  setup() {
    const {
      accountHasFeature,
      userHasRight,
    } = useAccess();
    const { loggedInUserAccount } = useLoggedInUserAccount();
    const { loggedInUser } = useLoggedInUser();
    const { linkToView } = useViewNavigator();
    const accountSettingsSvc = useAccountSettings();
    const { markAsDone } = useOnboardingMarker(loggedInUserAccount, accountSettingsSvc);
    return {
      accountHasFeature,
      userHasRight,
      account: loggedInUserAccount,
      loggedInUser,
      linkToView,
      markAsDone,
    };
  },
  components: { AccountSpaceTemplate, ProductTour, AfterAccountSpaceDemoModal, DemoDataModal },
  data() {
    return { showAfterDemoModal: false };
  },
  computed: {
    adminProductTourActive() {
      return this.$route.query.showTour === true || this.$route.query.showTour === 'true';
    },
    productTourSteps() {
      const steps = [
        {
          highlightedElement: 'help-and-support',
          heading: this.$t('productTour.home.masteringMooncampStep.heading'),
          content: this.$t('productTour.home.masteringMooncampStep.content'),
          placement: 'rightBottom',
        },
        {
          highlightedElement: 'home',
          heading: this.$t('productTour.home.personalHomePageStep.heading'),
          content: this.$t('productTour.home.personalHomePageStep.content'),
          placement: 'rightCenter',
        },
        {
          highlightedElement: 'goal-cycle-selector',
          heading: this.$t('productTour.home.goalCycleStep.heading'),
          content: this.$t('productTour.home.goalCycleStep.content'),
          placement: 'bottomRight',
        },
        {
          highlightedElement: 'goals',
          heading: this.$t('productTour.home.goalExplorerStep.heading'),
          content: this.$t('productTour.home.goalExplorerStep.content'),
          placement: 'rightCenter',
        },
        {
          highlightedElement: 'updates',
          heading: this.$t('productTour.home.checkInStep.heading'),
          content: this.$t('productTour.home.checkInStep.content'),
          placement: 'rightCenter',
        },
      ];
      if (this.userHasRight([accessGroupFlag.goalDashboardView])) {
        steps.push(
          {
            highlightedElement: 'dashboard',
            heading: this.$t('productTour.home.dashboardStep.heading'),
            content: this.$t('productTour.home.dashboardStep.content'),
            placement: 'rightCenter',
          },
        );
      }
      if (this.loggedInUser.isAccountAdmin) {
        steps.push(
          {
            highlightedElement: 'settings',
            heading: this.$t('productTour.home.settingsStep.heading'),
            content: this.$t('productTour.home.settingsStep.content'),
            placement: 'rightTop',
          },
        );
      }
      return steps;
    },
    tabs() {
      const tabs = [];
      if (this.accountHasFeature([moduleFlag.goals])) {
        tabs.push({
          title: this.account.goalSettings.featureNamePlural,
          to: this.linkToView(GOAL_VIEW, viewApplication.goalAccount),
          view: GOAL_VIEW,
        });
      }
      if (this.accountHasFeature([moduleFlag.goals])) {
        tabs.push({
          title: `${this.$t('navigation.feed')}`,
          to: this.linkToView(GOAL_FEED_VIEW, viewApplication.updateFeedAccount),
          view: GOAL_FEED_VIEW,
        });
      }
      if (this.accountHasFeature([moduleFlag.updates])) {
        tabs.push({
          title: this.$t('navigation.updatesExplorer'),
          to: this.linkToView(UPDATE_VIEW, viewApplication.updateAccount),
          view: UPDATE_VIEW,
        });
      }
      if (this.accountHasFeature([moduleFlag.goals]) && this.userHasRight([accessGroupFlag.goalDashboardView])) {
        tabs.push({
          title: this.$t('navigation.goalInsights'),
          to: this.linkToView(GOAL_INSIGHT_VIEW, viewApplication.insightAccount),
          view: GOAL_INSIGHT_VIEW,
        });
      }
      tabs.push({
        title: this.$t('navigation.users'),
        to: this.linkToView(USER_VIEW),
        view: USER_VIEW,
      });
      return tabs;
    },
  },
  methods: {
    ...mapActions(['updateEntityV2']),
    closeAdminProductTour() {
      this.$router.push({ query: { ...this.$route.query, showTour: false } });
    },
    setAdminProductTourDone() {
      this.closeAdminProductTour();
      this.showAfterDemoModal = true;
      this.markAsDone([onboardingGuideSteps.productTour]);
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .account-space {
    flex: 1 1 auto;
  }
</style>
