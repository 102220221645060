<template>
  <div
    class="number-select-input"
    :style="style"
  >
    <div
      v-if="!showCustom"
      class="_items"
    >
      <div
        v-for="(i, index) in items"
        :key="i"
        :class="['_item', i === value ? '-selected' : '', index === (selectedIndex - 1) ? '-next' : '']"
        @click="update(i)"
      >
        {{ i }}
      </div>
      <div
        v-if="custom"
        class="_item"
        @click="addCustom"
      >
        <m-icon type="edit" />
      </div>
    </div>
    <div
      v-else
      class="_custom"
    >
      <m-form-item
        class="_form-item"
        :validate-status="customNumber >= start ? 'success' : 'error'"
      >
        <m-input-number
          v-model:value="customNumber"
          class="_input"
          :min="min"
          :max="max"
          @change="validate"
        />
        <m-btn
          color="primary"
          class="_btn"
          @click="set"
        >
          {{ $t('numberSelectInput.set') }}
        </m-btn>
        <m-btn
          class="_btn"
          @click="showCustom = false"
        >
          {{ $t('general.cancel') }}
        </m-btn>
      </m-form-item>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NumberSelectInput',
  props: {
    value: {
      type: Number,
      default: 0,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 0,
    },
    start: {
      type: Number,
      required: true,
    },
    end: {
      type: Number,
      required: true,
    },
    custom: {
      type: Boolean,
      default: true,
    },
    width: {
      type: String,
      default: '50rem',
    },
  },
  emits: ['change'],
  data() {
    return {
      showCustom: false,
      customNumber: 1,
      customItems: [],
    };
  },
  computed: {
    selectedIndex() {
      let index = -1;
      this.items.forEach((item, i) => {
        if (item === this.value) {
          index = i;
        }
      });

      return index;
    },
    items() {
      const res = [];
      for (let i = this.start; i <= this.end; i++) {
        res.push(i);
      }

      return [...res, ...this.customItems];
    },
    style() {
      return { width: this.width };
    },
  },
  methods: {
    set() {
      if (this.customNumber < this.start) {
        return;
      }
      if (this.items.filter((c) => c === this.customNumber).length > 0) {
        this.update(this.customNumber);
        this.showCustom = false;
        return;
      }
      this.customItems.push(this.customNumber);
      this.update(this.customNumber);
      this.showCustom = false;
    },
    addCustom() {
      this.showCustom = true;
    },
    update(i) {
      this.$emit('change', i);
    },
    validate(value) {
      if (value < this.min) {
        this.customNumber = this.min;
      }
      if (value > this.max) {
        this.customNumber = this.max;
      }
    },
  },
  watch: {
    value(val) {
      if (val > this.end) {
        this.customNumber = val;
        this.set();
      }
    },
  },
  mounted() {
    if (this.value > this.end) {
      this.customNumber = this.value;
      this.set();
    }
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .number-select-input {
    max-width: 100%;

    ._items {
      display: flex;
      align-items: center;
      height: 5rem;
      overflow: hidden;
      border-radius: $default-border-radius;

      ._item {
        display: flex;
        flex: 1 1 auto;
        align-items: center;
        justify-content: center;
        height: 100%;
        cursor: pointer;
        border: 1px solid $input-border-color;
        border-left: none;

        &:first-of-type {
          border-left: 1px solid $input-border-color;
          border-top-left-radius: $default-border-radius;
          border-bottom-left-radius: $default-border-radius;
        }

        &:last-of-type {
          border-top-right-radius: $default-border-radius;
          border-bottom-right-radius: $default-border-radius;
        }

        &:hover {
          background-color: map_get($grey, 'lighten-4');
        }

        &.-selected {
          color: $primary-color;
          background-color: map_get($blue, 'lighten-5');
          border-color: $primary-color;
        }

        &.-next {
          border-right-color: $primary-color;
        }
      }
    }

    ._custom {
      ._form-item {
        display: inline;
      }

      ._input {
        max-width: 6rem;
      }

      ._btn {
        margin-left: .8rem;
      }
    }
  }
</style>
