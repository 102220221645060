import {
  AND, CREATED_AT,
  RESULT,
  UID,
  VAR,
} from 'shared/api/query/constants';
import { buildCustomFuncGoalInsightsPred, buildCustomFuncProgressCourseListPred } from '@/api/query/custom-func-helper';
import { combine, eqFilter, gteFilter, notFilter, rangeFilter } from 'shared/api/query/filter';
import {
  goal,
  propertyOption,
  propertyValue,
} from 'shared/api/query/configs.json';
import { goalProgressMeasurement } from 'shared/constants.json';
import { goalsInCycleVarBlock } from '@/api/query/nebula/goal';
import { reverseEdge } from 'shared/api/query/edges';

const goalsByProgressFilter = (filter, range) => combine(AND, [
  rangeFilter(goal.edges.cachedCalculatedCurrent, range),
  notFilter(eqFilter(goal.edges.progressMeasurement, goalProgressMeasurement.none)),
  filter,
]);

const goalsCreatedAfterFilter = (filter, after) => combine(AND, [
  gteFilter(CREATED_AT, after),
  filter,
]);

const optionGoalsFilter = (filter, varName) => combine(AND, [
  {
    func: {
      name: UID,
      needsVar: [
        { name: varName, typ: 1 },
      ],
    },
  },
  filter,
]);

const notOptionGoalsFilter = (filter, varName) => combine(AND, [
  notFilter([
    {
      func: {
        name: UID,
        needsVar: [
          { name: varName, typ: 1 },
        ],
      },
    },
  ]),
  filter,
]);

const goalsCount = (alias, cycleGoalsVar, filter) => ({
  alias,
  model: goal.model,
  needsVar: [
    { name: cycleGoalsVar, typ: 1 },
  ],
  func: {
    name: UID,
    needsVar: [
      { name: cycleGoalsVar, typ: 1 },
    ],
  },
  filter,
  default: [],
  children: [
    {
      attr: UID,
      isCount: true,
    },
  ],
});

const getOptionVar = (uids, name) => ({
  alias: VAR,
  func: { name: UID },
  uid: uids,
  model: propertyOption.model,
  children: [
    {
      attr: reverseEdge(propertyValue.edges.selectedOptions),
      model: propertyValue.model,
      children: [
        {
          attr: reverseEdge(goal.edges.properties),
          model: goal.model,
          children: [
            {
              attr: UID,
              var: name,
            },
          ],
        },
      ],
    },
  ],
});

const getOptionValue = (alias, cycleGoalsVar, filter) => ({
  alias,
  model: goal.model,
  needsVar: [
    { name: cycleGoalsVar, typ: 1 },
  ],
  func: {
    name: UID,
    needsVar: [
      { name: cycleGoalsVar, typ: 1 },
    ],
  },
  filter,
  default: [],
  children: [
    {
      attr: UID,
      isCount: true,
    },
  ],
});

export const goalsByType = (prefix, goalTypeOptions, cycleGoalsVar, filter) => {
  const res = goalTypeOptions.reduce((res, { uid }) => {
    res.push(
      getOptionVar([uid], `${prefix}${uid}`),
      getOptionValue(`${prefix}${uid}`, cycleGoalsVar, optionGoalsFilter(filter, `${prefix}${uid}`)),
    );
    return res;
  }, []);
  res.push(
    getOptionVar(goalTypeOptions.map((o) => o.uid), prefix),
    getOptionValue(`${prefix}NoType`, cycleGoalsVar, notOptionGoalsFilter(filter, prefix)),
  );
  return res;
};

export const goalsByStatusOptions = (alias, goalStatusOptions, cycleGoalsVar, filter) => [
  getOptionVar(goalStatusOptions, alias),
  getOptionValue(alias, cycleGoalsVar, optionGoalsFilter(filter, alias)),
];

export const querySingleMetrics = ({
  goalCycles = [],
  filter = null,
  varBlocks = [],
  goalTypeOptions = [],
  atRiskStatusOptions = [],
  compareTo = [],
}) => {
  const cycleGoalsVar = 'cycle_goals';
  const queries = [
    ...goalsInCycleVarBlock({ goalCycles, filter, varName: cycleGoalsVar }),
    ...goalsByStatusOptions('goalsAtRisk', atRiskStatusOptions, cycleGoalsVar, filter),
    goalsCount('goalsTotalCount', cycleGoalsVar, filter),
    ...goalsByType('goalsByTypeCount', goalTypeOptions, cycleGoalsVar, filter),
    ...varBlocks,
  ];

  if (compareTo.length > 0) {
    const createdAfterFilter = goalsCreatedAfterFilter(filter, compareTo[0]);
    queries.push(
      goalsCount('goalsTotalCountDiff', cycleGoalsVar, createdAfterFilter),
      ...goalsByType('goalsByTypeCountDiff', goalTypeOptions, cycleGoalsVar, createdAfterFilter),
    );
  }
  return queries;
};

export const queryGoalsTypeDiff = ({
  goalCycles = [],
  filter = null,
  varBlocks = [],
  goalTypeOptions = [],
  compareTo = [],
}) => {
  const cycleGoalsVar = 'cycle_goals';
  const createdAfterFilter = goalsCreatedAfterFilter(filter, compareTo[0]);
  return [
    ...goalsInCycleVarBlock({ goalCycles, filter, varName: cycleGoalsVar }),
    goalsCount('goalsTotalCountDiff', cycleGoalsVar, createdAfterFilter),
    ...goalsByType('goalsByTypeCountDiff', goalTypeOptions, cycleGoalsVar, createdAfterFilter),
    ...varBlocks,
  ];
};

export const queryProgress = ({
  goalCycles = [],
  filter = null,
  varBlocks = [],
  timeSeries = [],
  compareTo = [],
}) => {
  const cycleGoalsVar = 'cycle_goals';
  return [
    ...goalsInCycleVarBlock({ goalCycles, filter, varName: cycleGoalsVar }),
    {
      alias: RESULT,
      model: goal.model,
      needsVar: [{ name: cycleGoalsVar, typ: 1 }],
      func: { name: UID, needsVar: [{ name: cycleGoalsVar, typ: 1 }] },
      filter,
      default: [],
      children: [
        buildCustomFuncProgressCourseListPred({ timeSeries, attr: UID }),
        buildCustomFuncProgressCourseListPred({ alias: 'compareTo', timeSeries: compareTo, attr: UID }),
      ],
    },
    ...varBlocks,
  ];
};

export const queryGoalsByProgress = ({
  goalCycles = [],
  filter = null,
  varBlocks = [],
  numberRanges = [],
}) => {
  const cycleGoalsVar = 'cycle_goals';
  return [
    ...goalsInCycleVarBlock({ goalCycles, filter, varName: cycleGoalsVar }),
    ...numberRanges.map((range) => (
      {
        alias: `progress_min${range.min}_max${range.max}`,
        model: goal.model,
        needsVar: [{ name: cycleGoalsVar, typ: 1 }],
        func: {
          name: UID,
          needsVar: [{ name: cycleGoalsVar, typ: 1 }],
        },
        filter: goalsByProgressFilter(filter, range),
        default: [],
        children: [
          {
            attr: UID,
            isCount: true,
          },
        ],
      }
    )),
    ...varBlocks,
  ];
};

export const queryGoalsByStatusOptions = ({
  goalCycles = [],
  filter = null,
  varBlocks = [],
  statesGroups = [],
}) => {
  const cycleGoalsVar = 'cycle_goals';
  return [
    ...goalsInCycleVarBlock({ goalCycles, filter, varName: cycleGoalsVar }),
    ...statesGroups.reduce((res, states) => {
      res.push(
        ...goalsByStatusOptions(states.reduce((res, s) => `${res}_${s}`, 'states'), states, cycleGoalsVar, filter),
      );
      return res;
    }, []),
    ...varBlocks,
  ];
};

export const queryAdminGoalInsights = ({
  goalCycles = [],
  filter = null,
  varBlocks = [],
  groupBy,
  compareTo = [],
}) => {
  const cycleGoalsVar = 'cycle_goals';
  return [
    ...goalsInCycleVarBlock({ goalCycles, filter, varName: cycleGoalsVar }),
    {
      alias: RESULT,
      model: goal.model,
      needsVar: [{ name: cycleGoalsVar, typ: 1 }],
      func: { name: UID, needsVar: [{ name: cycleGoalsVar, typ: 1 }] },
      filter,
      default: [],
      children: [
        buildCustomFuncGoalInsightsPred({ groupBy, timeSeries: compareTo, attr: UID }),
      ],
    },
    ...varBlocks,
  ];
};
