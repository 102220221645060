import { goalValidationError } from 'shared/constants.json';

export default function useValidator({ rules, usesOKRRules }) {
  const validate = ({ toValidate, selectRulesFrom }) => {
    if (!usesOKRRules) {
      return {};
    }

    const goalTypePropValue = selectRulesFrom.properties.find((p) => p.property.isGoalType);
    const goalTypeProperty = goalTypePropValue.property;
    const goalTypeOptions = goalTypePropValue.selectedOptions;
    if (goalTypeOptions.length === 0) {
      return {
        [goalTypeProperty.uid]: [
          {
            key: goalValidationError.required,
            property: { isDirect: false, uid: goalTypeProperty.uid, key: goalTypeProperty.uid },
            payload: { goal: toValidate },
          },
        ],
      };
    }

    const errors = {};
    const r = rules[`${goalTypeOptions[0].uid}`];
    if (r === undefined) {
      return errors;
    }

    r.forEach((validate) => {
      const errs = validate(toValidate);
      errs.forEach((err) => {
        if (errors[`${err.property.key}`] === undefined) {
          errors[`${err.property.key}`] = [err];
          return;
        }

        errors[`${err.property.key}`].push(err);
      });
    });

    return errors;
  };

  return { validate };
}
