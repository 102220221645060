<template>
  <div
    :style="backgroundStyle"
    class="progress-bar"
  >
    <div
      :style="style"
      class="_progress"
    />
  </div>
</template>

<script>
import colors from 'shared/colors';

export default {
  name: 'ProgressBar',
  props: {
    color: {
      type: String,
      default: colors.blue.darken1,
    },
    height: {
      type: String,
      default: '.8rem',
    },
    value: {
      type: Number,
      required: true,
    },
  },
  computed: {
    borderRadius() {
      const height = parseFloat(this.height);
      return `${height / 2}rem`;
    },
    backgroundStyle() {
      return {
        height: this.height,
        borderRadius: this.borderRadius,
      };
    },
    style() {
      return {
        width: `${this.value}%`,
        backgroundColor: this.color,
        height: this.height,
      };
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  $height: .4rem;

  .progress-bar {
    height: $height;
    overflow: hidden;
    background-color: map_get($grey, 'lighten-3');

    ._progress {
      height: $height;
      transition: width .3s;
    }
  }
</style>
