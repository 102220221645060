<template>
  <full-screen-spinner v-if="data === null" />
  <template v-else>
    <m-content
      padding-x="layout"
      :padding-y="0"
      class="_header"
    >
      <meetings-list-header
        ref="header"
        :slots="slots"
        :allowed-view-types="allowedViewTypes"
        show-views-selector
        :show-create-view="showCreateView"
        :filter-props="defaultProps"
        show-saved-view-info
        class="_list-header"
      />
    </m-content>
    <div class="meeting-insights">
      <div class="_dashboard">
        <div class="_inner">
          <meeting-insights-table
            v-if="data !== null"
            :loading="dataLoading"
            :data="data"
            class="_table"
          />
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import FullScreenSpinner from 'shared/components/FullScreenSpinner.vue';
import MeetingInsightsTable from '@/components/meeting/MeetingInsightsTable.vue';
import MeetingsListHeader from '@/components/meeting/MeetingsListHeader.vue';
import useDebounce from '@/composables/debounce';
import useProperties from '@/composables/property/property';
import useRouteAwareViews from '@/composables/saved-views/route-aware-views';
import { MeetingFilterHandler } from '@/lib/filter/meeting/handler';
import { SLOTS, VIEWS_SERVICE } from '@/lib/constants';
import { computed, provide } from 'vue';
import { createPropsList } from '@/lib/props';
import { directProperties } from '@/lib/meeting/properties';
import { dogma } from '@/api';
import { meetingInsights } from '@/api/query/meeting';
import { savedViewProps } from '@/lib/saved-view/props';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { viewType } from 'shared/constants.json';

export default {
  name: 'MeetingInsights',
  props: {
    ...savedViewProps,
    viewApplication: {
      type: String,
      default: '',
    },
    showViewsSelector: {
      type: Boolean,
      default: false,
    },
    showCreateView: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const userLang = computed(() => store.getters.userLang);
    const { meetingProperties } = useProperties();

    const defaultProps = computed(() => createPropsList({
      properties: meetingProperties.value,
      directProperties: directProperties((key) => t(key)),
      userLang: userLang.value,
    }));

    const routeAwareViewsSvc = useRouteAwareViews({
      defaultProps,
      defaultView: props.defaultView,
      application: props.viewApplication,
      space: props.space,
      readOnly: props.readOnly,
    });

    const { debounce } = useDebounce();

    provide(VIEWS_SERVICE, routeAwareViewsSvc);

    return {
      debounce,

      defaultProps,

      currentView: routeAwareViewsSvc.currentView,
      initCurrentView: routeAwareViewsSvc.initCurrentView,
    };
  },
  components: {
    FullScreenSpinner,
    MeetingsListHeader,
    MeetingInsightsTable,
  },
  data() {
    return {
      data: null,
      dataLoading: false,
      slots: [{ name: SLOTS.FILTER }],
    };
  },
  computed: {
    queries() {
      return meetingInsights(this.gqlFilter, this.gqlFilterObject.varBlocks);
    },
    gqlFilterObject() {
      const handler = new MeetingFilterHandler();
      return handler.Translate(this.currentView.params.filter);
    },
    gqlFilter() {
      return this.gqlFilterObject.filterTree;
    },
    allowedViewTypes() {
      return [
        viewType.default,
      ];
    },
  },
  methods: {
    getData() {
      this.dataLoading = true;
      dogma.query(this.queries).then((response) => {
        if (response.status !== 200) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
          return;
        }

        this.dataLoading = false;
        this.data = response.data.result;
      });
    },
    apply(wait) {
      this.debounce(this.getData, wait);
    },
  },
  watch: {
    gqlFilterObject: {
      handler(val, newVal) {
        if (JSON.stringify(val) === JSON.stringify(newVal)) {
          return;
        }
        this.apply(200);
      },
      deep: true,
    },
  },
  created() {
    this.initCurrentView();
    this.apply(0);
    this.getData();
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  ._header {
    position: sticky;
    left: 0;
  }

  .meeting-insights {
    ._dashboard {
      margin-top: 1.2rem;
    }
  }
</style>
