<template>
  <div class="ms-teams-switch">
    <settings-switch-item
      :title="$t('integrationSettings.msTeams')"
      icon="ms-teams"
      :sub-title="$t('msTeamsSwitch.description')"
      :value="accountSettings.usesMicrosoftBot"
      :loading="loading"
      :disabled="disabled"
      @click="toggleMicrosoft"
    />
    <m-dialog
      v-model:value="showMicrosoftHint"
      :title="$t('profileSettings.microsoftHintTitle')"
      hide-footer
    >
      <m-s-teams-install-hint />
    </m-dialog>
  </div>
</template>

<script>
import MSTeamsInstallHint from '@/components/integrations/MSTeamsInstallHint.vue';
import SettingsSwitchItem from '@/components/SettingsSwitchItem.vue';
import useAccountSettings from '@/composables/logged-in-user-account/account-settings';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import { logCatch } from '@/lib/logger/logger';

export default {
  name: 'MsTeamsSwitch',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    SettingsSwitchItem,
    MSTeamsInstallHint,
  },
  setup() {
    const { loggedInUser } = useLoggedInUser();
    const { accountSettings, updateAccountSettings } = useAccountSettings();
    return { user: loggedInUser, accountSettings, updateAccountSettings };
  },
  data() {
    return {
      showMicrosoftHint: false,
      loading: false,
    };
  },
  computed: {
    microsoftConnected() {
      if (this.user.appIntegration === null) {
        return false;
      }

      return this.user.appIntegration.microsoftBotConversationID !== '';
    },
  },
  methods: {
    toggleMicrosoft() {
      this.loading = true;
      this.updateAccountSettings({
        ...this.accountSettings,
        usesMicrosoftBot: !this.accountSettings.usesMicrosoftBot,
      }).then(() => {
        this.loading = false;
        if (this.accountSettings.usesMicrosoftBot && !this.microsoftConnected) {
          this.showMicrosoftHint = true;
        }
      }).catch(logCatch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      }));
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">

</style>
