import { UID } from 'shared/api/query/constants';
import { accessPolicyChildren } from '@/api/query/nebula/access-policy';
import {
  accessPolicy as accessPolicyConfig,
  propertyValue as propertyValueConfig,
  space as spaceConfig,
  storageObject as storageObjectConfig,
} from 'shared/api/query/configs.json';
import { accessPolicyType } from 'shared/constants.json';
import { propertyValueEdges } from '@/api/query/nebula/property-value';
import { reverseEdge } from 'shared/api/query/edges';
import { storageObjectEdges } from '@/api/query/nebula/storage-object';

export const spaceEdges = [
  { attr: UID },
  { attr: spaceConfig.edges.archivedAt, default: null },
  {
    attr: spaceConfig.edges.accessPolicy,
    model: accessPolicyConfig.model,
    default: null,
    children: accessPolicyChildren,
  },
  { attr: spaceConfig.edges.accessRight, default: accessPolicyType.read },
  { attr: spaceConfig.edges.isNotDeletable, default: false },
  { attr: spaceConfig.edges.title, default: '' },
  { attr: spaceConfig.edges.icon, default: '' },
  {
    attr: spaceConfig.edges.image,
    model: storageObjectConfig.model,
    children: storageObjectEdges,
    default: null,
  },
  { attr: spaceConfig.edges.color, default: null },
  { attr: spaceConfig.edges.description, default: null },
  {
    attr: spaceConfig.edges.properties,
    default: [],
    model: propertyValueConfig.model,
    children: propertyValueEdges,
  },
  {
    attr: spaceConfig.edges.parents,
    model: spaceConfig.model,
    children: [{ attr: UID }],
    default: [],
  },
  {
    attr: reverseEdge(spaceConfig.edges.parents),
    alias: 'children',
    model: spaceConfig.model,
    children: [{ attr: UID }],
    default: [],
  },
];
