<template>
  <single-metric-card
    :title="$t('goalInsightsHealth.overview.measurementTypes')"
    :metric="hasInvalid ? counter : null"
    :loading="loading"
    :clickable="counter > 0"
    @click="handler"
  />
</template>
<script>
import SingleMetricCard from '@/components/dashboard/SingleMetricCard.vue';
import useDebounce from '@/composables/debounce';
import useGoalTypeProperty from '@/composables/customize-page/goal-type-property';
import usePublishedAtFilter from '@/composables/goal/published-at-filter';
import useSubscription from '@/composables/subscription/subscription';
import { AND } from 'shared/api/query/filter';
import { GoalFilterHandler } from '@/lib/filter/goal/handler';
import { allGoalTypesMeasurementTypesFilter } from '@/lib/filter/goal/measurement-types-filter';
import { applyBaseFilter } from '@/lib/filter/base-filter';
import { dogma } from '@/api';
import { hasRuleInvalidMeasurementTypes, queryOverviewMeasurementTypes } from '@/api/query/goal-insights-health';

export default {
  name: 'OverviewMeasurementTypesCard',
  props: {
    selectedCycles: {
      type: Array,
      required: true,
    },
    viewFilter: {
      type: Object,
      required: true,
    },
    goalTypes: {
      type: Array,
      required: true,
    },
    goalIdsToObserve: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['show-goal-list'],
  setup() {
    const { debounce } = useDebounce();

    const { subscribe } = useSubscription();

    const { goalTypeProperty } = useGoalTypeProperty();
    const { publishedAtFilter } = usePublishedAtFilter();
    return {
      debounce,
      subscribe,

      goalTypeProperty,
      publishedAtFilter,
    };
  },
  components: { SingleMetricCard },
  data() {
    return {
      series: [],
      loading: false,
    };
  },
  computed: {
    counter() {
      return this.series.length;
    },
    hasInvalid() {
      return this.goalTypes.some((goalType) => hasRuleInvalidMeasurementTypes(goalType));
    },
    gqlFilterObject() {
      const handler = new GoalFilterHandler(true);
      return handler.Translate(this.viewFilter);
    },
    gqlFilter() {
      return this.gqlFilterObject.filterTree;
    },
  },
  methods: {
    handler() {
      const filteredGoalTypes = this.goalTypes.filter((goalType) => hasRuleInvalidMeasurementTypes(goalType));
      this.$emit('show-goal-list', {
        filter: {
          op: AND,
          children: [
            this.viewFilter,
            allGoalTypesMeasurementTypesFilter(this.goalTypeProperty, filteredGoalTypes),
          ],
        },
        title: this.$t('goalInsightsHealth.overview.measurementTypes'),
      });
    },
    fetch(wait) {
      if (!this.hasInvalid) {
        return;
      }
      const retrieve = () => {
        this.loading = true;
        dogma.query(applyBaseFilter({
          queries: [
            ...queryOverviewMeasurementTypes({
              goalCycles: this.selectedCycles,
              filter: this.gqlFilter,
              varBlocks: this.gqlFilterObject.varBlocks,
              goalTypeOptions: this.goalTypes,
            }),
          ],
          operator: AND,
          baseFilter: this.publishedAtFilter,
        })).then((response) => {
          this.loading = false;
          if (response.status !== 200) {
            this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
            return;
          }

          this.series = Object.values(response.data).flat().map((e) => e.uid);
        });
      };
      this.debounce(retrieve, wait);
    },
  },
  watch: {
    selectedCycles(val, oldVal) {
      if (JSON.stringify(val) === JSON.stringify(oldVal)) {
        return;
      }
      this.fetch(1000);
    },
    gqlFilterObject: {
      handler(val, oldVal) {
        if (JSON.stringify(val) === JSON.stringify(oldVal)) {
          return;
        }
        this.fetch(1000);
      },
      deep: true,
    },
  },
  created() {
    this.fetch(0);

    const fn = this.fetch;
    this.subscribe({
      queryFn: Promise.resolve(),
      syncFn({ isSelfEvent }) {
        const delay = isSelfEvent ? 1000 : 30000;
        fn(delay);
      },
      model: 'goal',
      includeSelfEvents: true,
    });
  },
};
</script>
