<template>
  <div class="goal-types">
    <page-top-bar>
      <template #actions>
        <app-feedback-dropdown topic="goalStatus" />
      </template>
    </page-top-bar>
    <page-header
      :title="$t('goalStatus.title')"
      :sub-title="$t('goalStatus.subTitle')"
      boxed-xs
      heading="h3"
      underlined
      class="_header"
    />
    <m-content
      boxed-xs
      no-padding
    >
      <m-content :padding-y="8" />
      <m-btn
        small
        super-light
        :href="$t('goalStatus.helpCenterLink')"
        hide-border
        icon="question-circle"
        target="_blank"
      >
        {{ $t('goalStatus.learnMore') }}
      </m-btn>
    </m-content>
    <m-content
      boxed-xs
      padding
      class="_content"
    >
      <m-form-item :label="$t('goalStatusSettings.name')">
        <div :style="{ display: 'flex' }">
          <m-text-field
            :value="label"
            @update:value="updatePropertyLabel"
          />
          <m-btn
            :disabled="!labelDirty"
            :loading="updateLoading"
            color="primary"
            :style="{ marginLeft: '1rem' }"
            @click="save"
          >
            {{ $t('general.save') }}
          </m-btn>
        </div>
      </m-form-item>
    </m-content>
    <m-content
      boxed-xs
      padding
      class="_content"
    >
      <div
        v-for="group in groups"
        :key="group.uid"
      >
        <m-divider />
        <div class="_group-header">
          <sub-heading
            :title="group.label"
            class="_heading"
          />
          <m-dropdown
            :value="createDropdown === group.label"
            class="_add"
            :title="$t('general.actions')"
            keep-open-on-mask-click
            @overlay-clicked="hide"
          >
            <m-btn
              icon="plus"
              fab
              light
              hide-border
              @click="createDropdown = group.label"
            />
            <template #overlay>
              <status-option-editor
                :group="group"
                :groups="groups"
                :status-property="statusProperty"
                @close="handleClose"
              />
            </template>
          </m-dropdown>
        </div>
        <div class="_list-content">
          <m-draggable
            draggable-item-class="_handle"
            ghost-item-class="_item-inner"
            dragover-item-class="_item-inner"
            scroll-container-class="scroll-container"
            can-drag-over-top
            can-drag-over-bottom
            :recreate-key="group.children.length"
            :drag-between-height="24"
            @set-drag-item="setDragItem"
            @over-top="setOverTop"
            @over-bottom="setOverBottom"
            @drag-drop="handleDrop"
            @cancel="cancelDragging"
          >
            <template
              v-for="(item, index) in group.children"
              :key="item.uid"
            >
              <div
                class="_item"
              >
                <div
                  v-if="index === 0 && draggingOverTop.includes(item.uid)"
                  class="_drag-over-top"
                />
                <m-card-item
                  class="_item-inner"
                  :data-id="item.uid"
                  large
                  :no-hover="dragging"
                  @click="edit(item)"
                >
                  <div class="_handle">
                    <m-icon
                      type="drag"
                      :color="$colors.grey.base"
                      size="16"
                    />
                  </div>
                  <div class="_label">
                    <status-item :selected-option="item" />
                  </div>
                  <template #right>
                    <m-tooltip
                      v-if="isDefault(item.uid)"
                    >
                      <m-tag
                        :title="$t('general.default')"
                        class="_tag"
                        :custom-color="{ color: $colors.grey.base, backgroundColor: 'transparent' }"
                        :style="{ textTransform: 'uppercase' }"
                        :m-style="{ fontStyle: { fontSize: 'small' } }"
                      />
                      <template #title>
                        {{ $t('goalStatusSettings.defaultFlagToolTip') }}
                      </template>
                    </m-tooltip>
                    <m-tooltip>
                      <m-tag
                        v-if="item.score !== null"
                        :title="`${item.score * 10} %`"
                        class="_tag"
                        color="light"
                      />
                      <template #title>
                        {{ $t('goalStatusSettings.scoreTooltip') }}
                      </template>
                    </m-tooltip>
                    <m-dropdown
                      :value="optionToEdit === item.uid"
                      :title="$t('general.actions')"
                      keep-open-on-mask-click
                      @overlay-clicked="hide"
                    >
                      <m-icon
                        :type="optionToEdit === item.uid ? 'down' : 'right'"
                        :color="$colors.grey.lighten2"
                      />
                      <template #overlay>
                        <status-option-editor
                          :option="item"
                          :group="group"
                          :groups="groups"
                          :is-default="isDefault(item.uid)"
                          :status-property="statusProperty"
                          @close="handleClose"
                        />
                      </template>
                    </m-dropdown>
                  </template>
                </m-card-item>
                <div
                  v-if="draggingOverBottom.includes(item.uid)"
                  class="_drag-over-bottom"
                />
              </div>
            </template>
          </m-draggable>
        </div>
      </div>
      <div
        v-if="archivedGroup.children.length > 0"
        class="_archived"
      >
        <m-divider />
        <m-card-item
          class="_group-header"
          icon="delete"
          light
          large
          :style="{ paddingLeft: '0', marginBottom: '1rem' }"
          @click.stop.prevent="archivedExpanded = !archivedExpanded"
        >
          {{ $t('goalStatusSettings.archived', archivedGroup.children.length, { count: archivedGroup.children.length }) }}
          <template #right>
            <div :style="{ color: $colors.grey.lighten2, display: 'flex', borderRadius: '.4rem' }">
              {{ archivedGroup.children.length }}
              <m-icon
                :type="archivedExpanded ? 'down' : 'right'"
                :style="{ marginLeft: '1rem' }"
              />
            </div>
          </template>
        </m-card-item>
        <div
          v-show="archivedExpanded"
          class="_list-content"
        >
          <div
            v-for="item in archivedGroup.children"
            :key="item.uid"
            class="_item"
          >
            <m-card-item
              class="_item-inner"
              :data-id="item.uid"
              large
              :clickable="false"
            >
              <div class="_label">
                <status-item :selected-option="item" />
              </div>
              <template #right>
                <m-tooltip>
                  <m-btn
                    icon="delete"
                    fab
                    hide-border
                    light
                    small
                    :disabled="canHardDeleteOption[item.uid] !== true"
                    :loading="deleteLoading === item.uid"
                    @click="hardDeleteOption(item)"
                  />
                  <template #title>
                    {{ deleteTooltip(item) }}
                  </template>
                </m-tooltip>
                <m-tooltip>
                  <m-btn
                    icon="restore"
                    fab
                    hide-border
                    light
                    small
                    :loading="restoreLoading === item.uid"
                    @click="restoreOption(item)"
                  />
                  <template #title>
                    {{ $t('general.restore') }}
                  </template>
                </m-tooltip>
              </template>
            </m-card-item>
          </div>
        </div>
      </div>
    </m-content>
  </div>
</template>

<script setup>
import AppFeedbackDropdown from '@/components/app-feedback/AppFeedbackDropdown.vue';
import PageHeader from 'shared/components/PageHeader.vue';
import PageTopBar from '@/components/page/PageTopBar.vue';
import StatusItem from '@/components/goal/StatusItem.vue';
import StatusOptionEditor from '@/components/goal-status/StatusOptionEditor.vue';
import SubHeading from 'shared/components/SubHeading.vue';
import useGoalProperty from '@/composables/property/goal-property';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useProperties from '@/composables/property/property';
import usePropertyOptions from '@/composables/property-option/property-option';
import useSnackBar from '@/composables/snackbar';
import useSort from '@/composables/draggable/sort';
import { EventBus } from '@/lib/event-bus';
import { computed, ref, watch } from 'vue';
import { copy } from 'shared/lib/copy';
import { sortByArray } from 'shared/lib/sort';
import { textByLang } from 'shared/lib/language';
import { useI18n } from 'vue-i18n';

const { userLang } = useLoggedInUser();
const propertySvc = useProperties();
const propertyOptionSvc = usePropertyOptions();
const { statusProperty } = useGoalProperty();

const label = ref(statusProperty.value.label.en);
const labelDirty = ref(false);
const updatePropertyLabel = (value) => {
  labelDirty.value = true;
  label.value = value;
};

const i18n = useI18n();
const snackbar = useSnackBar();
const updateLoading = ref(false);
const save = () => {
  updateLoading.value = true;
  propertySvc.updateSingle({ uid: statusProperty.value.uid, label: { de: label.value, en: label.value } }).then(() => {
    labelDirty.value = false;
    snackbar.success(i18n.t('success.saved'));
  }).finally(() => {
    updateLoading.value = false;
  });
};

const groups = computed(() => {
  const parents = statusProperty.value.options.filter((o) => o.isNotDeletable === true);
  return parents.map((next) => {
    const children = copy(statusProperty.value.options
      .filter((o) => o.archivedAt === null)
      .filter((o) => o.parents.map((p) => p.uid).includes(next.uid)));
    children.sort(sortByArray(statusProperty.value.propertyOptionOrder));
    return {
      uid: next.uid,
      label: textByLang(next.label, userLang.value),
      children,
    };
  });
});

const hide = () => {
  EventBus.$emit('close-status-editor');
};

const optionToEdit = ref(null);
const createDropdown = ref('');
const handleClose = () => {
  optionToEdit.value = null;
  createDropdown.value = '';
};

const {
  setDragItem,
  dragging,
  setOverBottom,
  setOverTop,
  draggingOverBottom,
  draggingOverTop,
  dropItem,
  cancelDragging,
} = useSort();

const handleDrop = () => {
  const newOrder = dropItem(statusProperty.value.options).map(({ uid }) => ({ uid }));
  updateOrder(newOrder);
};

const updateOrder = (order) => {
  propertySvc.updateSingle({ uid: statusProperty.value.uid, propertyOptionOrder: order, options: statusProperty.value.options });
};

const edit = (item) => {
  optionToEdit.value = item.uid;
};

const isDefault = (uid) => statusProperty.value.defaultPropertyOptions.find((o) => o.uid === uid) !== undefined;

const archivedGroup = computed(() => {
  const children = copy(statusProperty.value.options.filter((o) => o.archivedAt !== null));
  return { children };
});

const archivedExpanded = ref(false);

const canHardDeleteOption = ref({});
watch(archivedGroup, (options) => {
  options.children.forEach((option) => {
    if (canHardDeleteOption.value[option.uid] !== undefined) {
      return;
    }
    propertyOptionSvc.getUsedBy({ uid: option.uid }).then((usedBy) => {
      if (usedBy === 0) {
        canHardDeleteOption.value[option.uid] = true;
        return;
      }
      canHardDeleteOption.value[option.uid] = false;
    });
  });
}, { immediate: true });

const deleteLoading = ref(null);
const hardDeleteOption = (item) => {
  deleteLoading.value = item.uid;
  propertyOptionSvc.deleteSingle({ uid: item.uid }).then(() => {
    snackbar.success(i18n.t('success.deleted'));
  }).finally(() => {
    deleteLoading.value = null;
  });
};
const deleteTooltip = (option) => {
  if (canHardDeleteOption.value[option.uid] === true) {
    return i18n.t('general.deletePermanently');
  }
  return i18n.t('goalStatusSettings.cannotDeletePermanently');
};
const restoreLoading = ref(null);
const restoreOption = (item) => {
  restoreLoading.value = item.uid;
  propertyOptionSvc.updateSingle({ uid: item.uid, archivedAt: null }).then(() => {
    snackbar.success(i18n.t('success.restored'));
  }).finally(() => {
    restoreLoading.value = null;
    optionToEdit.value = null;
  });
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
._group-header {
  display: flex;
  align-items: center;
  border-radius: $default-border-radius;

  ._heading {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0.6rem;
  }

  ._add {
    margin-left: auto;
    margin-right: 1.2rem;
  }
}

._item {
  position: relative;

  ._item-inner {
    border-radius: $default-border-radius;
  }

  ._handle {
    position: absolute;
    top: 50%;
    left: 1.8rem;
    cursor: grab;
    transform: translate(-50%, -50%);
  }

  ._label {
    margin-left: 1.2rem;
  }

  ._right {
    ._tag {
      margin-right: 1.2rem;
    }
  }

  ._drag-over-top {
    position: absolute;
    top: -2px;
    right: 0;
    left: 0;
    z-index: 88;
    width: 100%;
    height: 4px;
    pointer-events: none;
    background: $highlighted-color-dark;
    opacity: 1;
  }

  ._drag-over-bottom {
    position: absolute;
    right: 0;
    bottom: -2px;
    left: 0;
    z-index: 88;
    width: 100%;
    height: 4px;
    pointer-events: none;
    background: $highlighted-color-dark;
    opacity: 1;
  }
}
</style>
