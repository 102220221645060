<template>
  <card-list-item
    :title="form.title"
    :menu-items="menuItems"
    :to="btnLink"
    :hide-footer="!writeAccess"
    @delete="deleteConfirm"
  />
</template>

<script>
import CardListItem from '@/components/CardListItem.vue';
import { CREATE_FORM, FORM_DETAILS, FORM_RESULTS, FORM_RESULT_QUESTIONS } from '@/route-names';
import { DateTime } from 'luxon';
import { mapActions } from 'vuex';
import { writeAccess } from '@/lib/form';

export default {
  name: 'FormCard',
  props: {
    form: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  components: { CardListItem },
  data() {
    return { loading: false };
  },
  computed: {
    writeAccess() {
      return writeAccess(this.user, this.form);
    },
    menuItems() {
      if (!writeAccess(this.user, this.form)) {
        return [];
      }
      return [
        {
          name: this.$t('general.edit'),
          to: this.editLink,
        },
        {
          name: this.$t('general.preview'),
          to: this.previewLink,
          target: '_blank',
        },
        {
          name: this.$t('formCard.results'),
          to: this.resultsLink,
        },
        {
          name: this.$t('general.delete'),
          emit: 'delete',
          style: { color: this.$colors.red.base },
          hasDivider: true,
        },
      ];
    },
    btnLink() {
      if (writeAccess(this.user, this.form)) {
        return this.editLink;
      }

      return {
        name: FORM_RESULT_QUESTIONS,
        params: { formId: this.form.uid },
      };
    },
    editLink() {
      return {
        name: CREATE_FORM,
        params: { formId: this.form.uid },
      };
    },
    previewLink() {
      return {
        name: FORM_DETAILS,
        params: { id: this.form.uid },
      };
    },
    resultsLink() {
      return {
        name: FORM_RESULTS,
        params: { formId: this.form.uid },
      };
    },
  },
  methods: {
    ...mapActions(['deleteForm']),
    deleteConfirm() {
      const deleteFn = this.handleDeleteForm;
      this.$confirm({
        title: this.$t('formCard.deleteTitle'),
        okText: this.$t('general.yesDelete'),
        cancelText: this.$t('general.cancel'),
        maskClosable: true,
        okType: 'danger',
        onOk() {
          deleteFn();
        },
      });
    },
    handleDeleteForm() {
      this.loading = true;
      this.deleteForm({ form: { uid: this.form.uid, deletedAt: DateTime.local().toISO() } })
        .then((response) => {
          this.loading = false;
          if (response.status !== 200) {
            this.$showSnackbar({
              color: 'error',
              message: this.$t('error.default'),
            });
          }
        });
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
</style>
