import { feedEntryType } from 'shared/constants.json';

export default {
  computed: {
    isTask() {
      return [feedEntryType.survey, feedEntryType.updateNotification].includes(this.feedEntry.type);
    },
    isSurvey() {
      return [feedEntryType.survey].includes(this.feedEntry.type);
    },
    isUpdateNotification() {
      return [feedEntryType.updateNotification].includes(this.feedEntry.type);
    },
  },
};
