import { deleteReference } from '@/nebula/references';
import { getType } from '@/nebula/type';

const deleteData = (state, dn, references, ids, model) => {
  for (let i = 0; i < ids.length; i++) {
    delete state[model][ids[i]];
    if (dn.value[model] !== undefined) {
      delete dn.value[model][ids[i]];
    }
    if (Object.keys(references).length === 0 || references[ids[i]] === undefined) {
      continue;
    }
    const keys = Object.keys(references[ids[i]]);
    if (keys.length === 0) {
      continue;
    }
    keys.forEach((key) => {
      const r = references[ids[i]][key];
      for (let k = 0; k < r.edges.length; k++) {
        const e = r.edges[k];
        if (e.edge.indexOf('~') === -1) {
          continue;
        }
        if (state[e.type][r.uid] === undefined) {
          continue;
        }
        const edge = e.edge.replace('~', '');
        if (Array.isArray(state[e.type][r.uid][edge])) {
          state[e.type][r.uid][edge] = state[e.type][r.uid][edge].filter((uid) => uid !== ids[i]);
          if (dn.value[e.type] !== undefined) {
            dn.value[e.type][r.uid][edge] = dn.value[e.type][r.uid][edge].filter((uid) => uid !== ids[i]);
          }
          continue;
        }

        state[e.type][r.uid][edge] = null;
        if (dn.value[e.type] !== undefined) {
          dn.value[e.type][r.uid][edge] = null;
        }
      }
    });
  }
};

const checkCondition = (config, entity) => {
  if (typeof config.condition !== 'function') {
    return true;
  }
  return config.condition(entity);
};

const checkEdge = (config, edge) => {
  if (config.edge === undefined) {
    return true;
  }
  return config.edge === edge;
};
const checkType = (config, type) => {
  if (config.type === undefined) {
    return true;
  }
  return config.type === type;
};
const findInConfig = (config, edge, type, entity) => {
  for (let i = 0; i < config.length; i++) {
    if (checkCondition(config[i], entity) && checkEdge(config[i], edge) && checkType(config[i], type)) {
      return true;
    }
  }

  return false;
};

export const findToDelete = (references, ids, model, schema, config, state) => {
  if (Object.keys(references).length === 0) {
    return [];
  }
  if (config[model] === undefined) {
    return [];
  }
  return ids.reduce((res, id) => {
    if (references[id] === undefined) {
      return res;
    }

    const toDelete = Object.values(references[id]).reduce((r, n) => {
      n.edges.forEach((e) => {
        let type = e.type;
        if (e.type === model && e.edge.indexOf('~') === -1) {
          type = getType(schema[model].schema[e.edge]);
        }

        if (!findInConfig(config[model], e.edge, type, state[type][n.uid])) {
          return;
        }

        r.push({ uid: n.uid, type });
        r.push(...findToDelete(references, [n.uid], type, schema, config, state));
      });
      return r;
    }, []);
    res.push(...toDelete);
    return res;
  }, []);
};
export const deleteEntities = (toDelete, state, dn, references) => {
  for (let i = 0; i < toDelete.length; i++) {
    deleteData(state, dn, references, [toDelete[i].uid], toDelete[i].type);
  }
  deleteReference(references, toDelete.map((item) => item.uid));
};
