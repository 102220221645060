import { createClient } from '@sanity/client';
import { environmentVariable, resolveEnvironmentVariable } from '@/lib/env';

const newClient = () => {
  if (resolveEnvironmentVariable(environmentVariable.SANITY_ACTIVE) === 'true') {
    const projectId = resolveEnvironmentVariable(environmentVariable.SANITY_PROJECT_ID);
    const dataset = resolveEnvironmentVariable(environmentVariable.SANITY_DATASET);
    const apiVersion = resolveEnvironmentVariable(environmentVariable.SANITY_API_VERSION);
    return createClient({
      projectId,
      dataset,
      apiVersion,
      useCdn: true,
    });
  }

  return {
    fetch: () => new Promise((resolve) => {
      resolve([]);
    }),
  };
};

const client = newClient();
export { client };
