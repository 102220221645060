<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.3438 12.7861L12.5648 3.8213C12.4945 3.74018 12.4076 3.67512 12.3099 3.63053C12.2123 3.58594 12.1062 3.56287 11.9988 3.56287C11.8915 3.56287 11.7854 3.58594 11.6877 3.63053C11.5901 3.67512 11.5031 3.74018 11.4328 3.8213L3.65626 12.7861C3.63276 12.8132 3.61752 12.8464 3.61236 12.8818C3.6072 12.9172 3.61234 12.9534 3.62716 12.986C3.64197 13.0186 3.66585 13.0463 3.69594 13.0657C3.72603 13.0851 3.76107 13.0955 3.79688 13.0955H5.69532C5.80313 13.0955 5.90626 13.0486 5.97891 12.9666L11.1094 7.05333V20.251C11.1094 20.3541 11.1938 20.4385 11.2969 20.4385H12.7031C12.8063 20.4385 12.8906 20.3541 12.8906 20.251V7.05333L18.0211 12.9666C18.0914 13.0486 18.1945 13.0955 18.3047 13.0955H20.2031C20.3625 13.0955 20.4492 12.908 20.3438 12.7861Z"
      fill="black"
    />
  </svg>
</template>

<script>
export default { name: 'ArrowUpThin' };
</script>
