<template>
  <m-card
    :class="['system-dashboard-card', hover ? '-hover':'']"
    :level="1"
    padding-small
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <div class="_content">
      <m-emoji
        class="_icon"
        :emoji="props.icon.value"
        :size="30"
        :show-background="props.icon.showBackground"
      />
      <div class="_text">
        <div class="_title-container">
          <div class="_title">
            {{ props.title }}
          </div>
          <plan-tag
            v-if="props.planTag !== null"
            class="_tag"
            :feature-flag="props.planTag.featureFlag"
            disabled
          />
        </div>
        <div class="_sub-title">
          {{ props.subTitle }}
        </div>
      </div>
    </div>
  </m-card>
</template>

<script setup>
import PlanTag from '@/components/plans/PlanTag.vue';
import { ref } from 'vue';

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  subTitle: {
    type: String,
    required: true,
  },
  icon: {
    type: Object,
    required: true,
  },
  planTag: {
    type: Object,
    default: () => null,
  },
});

const hover = ref(false);
</script>

<style scoped lang="scss">
.system-dashboard-card {
  &:hover {
    cursor: pointer;
  }

  ._content {
    display: flex;

    ._icon {
      flex: 0 0 auto;
    }

    ._text {
      padding-left: 1.6rem;

      ._title-container {
        margin-bottom: 0.8rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        ._title {
          font-weight: $font-weight-semibold;
        }
      }

      ._sub-title {
        color: $font-color-secondary;
      }
    }
  }
}
</style>
