<template>
  <scroll-container
    class="profile-body"
    :no-padding-bottom="isGoalView"
  >
    <m-content
      class="_content"
      padding-x="layout"
    >
      <personal-details
        class="_details"
        :has-profile-link="isLoggedInUser"
        :user="user"
      />
      <sub-menu-tabs
        :items="tabs"
        small
        light
        class="_tabs"
      />
    </m-content>
    <profile-goals
      v-if="isGoalView"
      :is-logged-in-user="isLoggedInUser"
      :user="user"
    />
    <m-content padding-x="layout">
      <profile-updates
        v-if="isUpdateView"
        :is-logged-in-user="isLoggedInUser"
        :user="user"
      />
      <profile-update-feed
        v-if="isUpdateFeedView"
        :is-logged-in-user="isLoggedInUser"
        :user="user"
      />
      <profile-about
        v-if="isAboutView"
        :user="user"
      />
    </m-content>
  </scroll-container>
</template>

<script>
import PersonalDetails from '@/components/profile/PersonalDetails.vue';
import ProfileAbout from '@/components/profile/ProfileAbout.vue';
import ProfileGoals from '@/components/profile/ProfileGoals.vue';
import ProfileUpdateFeed from '@/components/profile/ProfileUpdateFeed.vue';
import ProfileUpdates from '@/components/profile/ProfileUpdates.vue';
import ScrollContainer from '@/components/page/ScrollContainer.vue';
import SubMenuTabs from '@/components/SubMenuTabs.vue';
import useAccess from '@/composables/access/access';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useViewNavigator from '@/composables/saved-views/navigator';
import { ABOUT_VIEW, GOAL_FEED_VIEW, GOAL_INSIGHT_VIEW, GOAL_VIEW, UPDATE_VIEW, USER_VIEW, VIEW } from '@/route-params';
import { getQueryParam } from '@/lib/route';
import { mapActions } from 'vuex';
import { moduleFlag, viewApplication } from 'shared/constants.json';

export default {
  name: 'ProfileBody',
  props: {
    user: {
      type: Object,
      default: () => null,
    },
    showGreeting: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { loggedInUserAccount } = useLoggedInUserAccount();
    const { loggedInUser } = useLoggedInUser();
    const { accountHasFeature } = useAccess();
    const { linkToView } = useViewNavigator();
    return {
      linkToView,
      accountHasFeature,
      account: loggedInUserAccount,
      loggedInUser,
    };
  },
  components: {
    ScrollContainer,
    ProfileUpdateFeed,
    ProfileUpdates,
    PersonalDetails,
    ProfileAbout,
    ProfileGoals,
    SubMenuTabs,
  },
  data() {
    return {
      dataLoading: false,
      debouncedTitle: null,
      emojiAdded: false,
      titleCache: '',
      GOAL_VIEW,
      GOAL_INSIGHT_VIEW,
      USER_VIEW,
      UPDATE_VIEW,
    };
  },
  computed: {
    isLoggedInUser() {
      return this.loggedInUser.uid === this.user.uid;
    },
    tabs() {
      const tabs = [];
      if (this.user === null) {
        return tabs;
      }
      if (this.accountHasFeature([moduleFlag.goals])) {
        const application = this.isLoggedInUser ? viewApplication.goalHome : viewApplication.goalUserProfile;
        tabs.push({
          title: this.account.goalSettings.featureNamePlural,
          to: this.linkToView(GOAL_VIEW, application),
          view: GOAL_VIEW,
          active: this.currentView === GOAL_VIEW,
        });
      }
      if (this.accountHasFeature([moduleFlag.goals])) {
        const application = this.isLoggedInUser ? viewApplication.updateFeedHome : viewApplication.updateFeedProfile;
        tabs.push({
          title: this.$t('navigation.feed'),
          to: this.linkToView(GOAL_FEED_VIEW, application),
          view: GOAL_FEED_VIEW,
          active: this.currentView === GOAL_FEED_VIEW,
        });
      }
      if (this.accountHasFeature([moduleFlag.updates])) {
        const application = this.isLoggedInUser ? viewApplication.updateHome : viewApplication.updateUserProfile;
        tabs.push({
          title: this.$t('navigation.updatesExplorer'),
          to: this.linkToView(UPDATE_VIEW, application),
          view: UPDATE_VIEW,
          active: this.currentView === UPDATE_VIEW,
        });
      }
      tabs.push({
        title: this.$t('navigation.about'),
        to: this.linkToView(ABOUT_VIEW),
        view: ABOUT_VIEW,
        active: this.currentView === ABOUT_VIEW,
      });

      return tabs;
    },
    currentView() {
      return getQueryParam(this.$route, VIEW);
    },
    isGoalView() {
      return this.currentView === GOAL_VIEW;
    },
    isUpdateFeedView() {
      return this.currentView === GOAL_FEED_VIEW;
    },
    isUpdateView() {
      return this.currentView === UPDATE_VIEW;
    },
    isAboutView() {
      return this.currentView === ABOUT_VIEW;
    },
  },
  methods: {
    ...mapActions([
      'updateEntity',
      'deleteEntity',
    ]),
    initRoute() {
      if (this.tabs.length > 0) {
        this.$router.replace(this.tabs[0].to);
      }
    },
  },
  watch: {
    currentView(newVal) {
      if (newVal === '') {
        this.initRoute();
      }
    },
  },
  created() {
    if (this.currentView === '') {
      this.initRoute();
    }
  },
};
</script>

<style
  scoped
  lang="scss"
  type="text/scss"
>
  .profile-body {
    ._content {
      position: sticky;
      left: 0;

      ._details {
        margin-top: 2rem;
      }

      ._tabs {
        margin: 2rem 0 2rem;
      }
    }
  }
</style>
