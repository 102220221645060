export default function useMDraggable(props, clientX, clientY) {
  const isOver = (node) => {
    if (!props.canDragOver) {
      return false;
    }
    const el = node.getBoundingClientRect();
    return (
      clientX.value >= el.left
      && clientX.value <= el.left + el.width
      && clientY.value > el.top + (props.dragBetweenHeight / 2)
      && clientY.value < el.top + (el.height - (props.dragBetweenHeight / 2))
    );
  };
  const isOverTop = (node) => {
    if (!props.canDragOverTop) {
      return false;
    }
    const el = node.getBoundingClientRect();
    const x = clientX.value - el.left;
    const y = el.height - (clientY.value - el.top);
    const checkXLeft = () => {
      if (!props.canDragOverLeft) {
        return true;
      }

      return ((el.height - y) * el.width) / el.height < x;
    };
    const checkXRight = () => {
      if (!props.canDragOverRight) {
        return true;
      }

      return (y * el.width) / el.height > x;
    };
    const checkYLeft = () => {
      if (!props.canDragOverLeft) {
        return true;
      }

      return (el.height - ((el.height / el.width) * x)) < y;
    };
    const checkYRight = () => {
      if (!props.canDragOverRight) {
        return true;
      }

      return (el.height / el.width) * x < y;
    };

    return (
      clientX.value >= el.left
      && clientX.value <= el.left + el.width
      && clientY.value >= el.top - (props.dragBetweenHeight / 2)
      && clientY.value <= el.top + (props.dragBetweenHeight / 2)
      && checkXLeft()
      && checkXRight()
      && checkYLeft()
      && checkYRight()
    );
  };
  const isOverBottom = (node) => {
    if (!props.canDragOverBottom) {
      return false;
    }
    const el = node.getBoundingClientRect();
    const x = clientX.value - el.left;
    const y = el.height - (clientY.value - el.top);
    const checkXRight = () => {
      if (!props.canDragOverTop) {
        return true;
      }

      return ((el.height - y) * el.width) / el.height > x;
    };
    const checkXLeft = () => {
      if (!props.canDragOverBottom) {
        return true;
      }

      return (y * el.width) / el.height < x;
    };
    const checkYRight = () => {
      if (!props.canDragOverTop) {
        return true;
      }

      return (el.height - ((el.height / el.width) * x)) > y;
    };
    const checkYLeft = () => {
      if (!props.canDragOverBottom) {
        return true;
      }

      return (el.height / el.width) * x > y;
    };

    return (
      clientX.value >= el.left
      && clientX.value <= el.left + el.width
      && clientY.value >= el.top + el.height - (props.dragBetweenHeight / 2)
      && clientY.value <= el.top + el.height + (props.dragBetweenHeight / 2)
      && checkXLeft()
      && checkXRight()
      && checkYLeft()
      && checkYRight()
    );
  };
  const isOverRight = (node) => {
    if (!props.canDragOverRight) {
      return false;
    }
    const el = node.getBoundingClientRect();
    const x = clientX.value - el.left;
    const y = el.height - (clientY.value - el.top);
    const checkXTop = () => {
      if (!props.canDragOverTop) {
        return true;
      }

      return ((el.height - y) * el.width) / el.height < x;
    };
    const checkXBottom = () => {
      if (!props.canDragOverBottom) {
        return true;
      }

      return (y * el.width) / el.height < x;
    };
    const checkYTop = () => {
      if (!props.canDragOverTop) {
        return true;
      }

      return (el.height - (el.height / el.width)) > y;
    };
    const checkYBottom = () => {
      if (!props.canDragOverBottom) {
        return true;
      }

      return (el.height / el.width) < y;
    };
    return (
      clientX.value >= el.right - (props.dragBetweenHeight / 2)
      && clientX.value <= el.right + (props.dragBetweenHeight / 2)
      && clientY.value >= el.top
      && clientY.value <= el.top + el.height
      && checkXTop()
      && checkXBottom()
      && checkYTop()
      && checkYBottom()
    );
  };
  const isOverLeft = (node) => {
    if (!props.canDragOverLeft) {
      return false;
    }
    const el = node.getBoundingClientRect();
    const x = clientX.value - el.left;
    const y = el.height - (clientY.value - el.top);
    const checkXTop = () => {
      if (!props.canDragOverTop) {
        return true;
      }

      return ((el.height - y) * el.width) / el.height > x;
    };
    const checkXBottom = () => {
      if (!props.canDragOverBottom) {
        return true;
      }

      return (y * el.width) / el.height > x;
    };
    const checkYTop = () => {
      if (!props.canDragOverTop) {
        return true;
      }

      return (el.height - (el.height / el.width)) > y;
    };
    const checkYBottom = () => {
      if (!props.canDragOverBottom) {
        return true;
      }

      return (el.height / el.width) < y;
    };

    return (
      clientX.value >= el.left - (props.dragBetweenHeight / 2)
      && clientX.value <= el.left + (props.dragBetweenHeight / 2)
      && clientY.value >= el.top
      && clientY.value <= el.top + el.height
      && checkXTop()
      && checkXBottom()
      && checkYTop()
      && checkYBottom()
    );
  };

  return {
    isOver,
    isOverTop,
    isOverBottom,
    isOverRight,
    isOverLeft,
  };
}
