import { UID } from 'shared/api/query/constants';
import {
  asanaEntity as asanaEntityConfig,
  asanaQuery as asanaQueryConfig,
  dataSource as dataSourceConfig,
  goal as goalConfig,
} from 'shared/api/query/configs.json';

export const asanaQueryChildren = [
  { attr: UID },
  { attr: asanaQueryConfig.edges.workspaceID, default: null },
  { attr: asanaQueryConfig.edges.workspaceName, default: null },
  { attr: asanaQueryConfig.edges.projectID, default: null },
  { attr: asanaQueryConfig.edges.projectName },
  { attr: asanaQueryConfig.edges.alwaysSync, default: false },
  {
    attr: asanaQueryConfig.edges.dataSource,
    model: dataSourceConfig.model,
    default: null,
    children: [{ attr: UID }],
  },
  {
    attr: asanaQueryConfig.edges.goal,
    model: goalConfig.model,
    children: [{ attr: UID }],
  },
  {
    attr: asanaQueryConfig.edges.assignedTo,
    model: asanaEntityConfig.model,
    default: [],
    children: [
      { attr: UID },
      { attr: asanaEntityConfig.edges.gid },
      { attr: asanaEntityConfig.edges.name },
    ],
  },
  {
    attr: asanaQueryConfig.edges.sections,
    model: asanaEntityConfig.model,
    default: [],
    children: [
      { attr: UID },
      { attr: asanaEntityConfig.edges.gid },
      { attr: asanaEntityConfig.edges.name },
      { attr: asanaEntityConfig.edges.status },
    ],
  },
  {
    attr: asanaQueryConfig.edges.overallAssignedTo,
    model: asanaEntityConfig.model,
    default: [],
    children: [
      { attr: UID },
      { attr: asanaEntityConfig.edges.gid },
      { attr: asanaEntityConfig.edges.name },
    ],
  },
  {
    attr: asanaQueryConfig.edges.overallSections,
    model: asanaEntityConfig.model,
    default: [],
    children: [
      { attr: UID },
      { attr: asanaEntityConfig.edges.gid },
      { attr: asanaEntityConfig.edges.name },
      { attr: asanaEntityConfig.edges.status },
    ],
  },
  {
    attr: asanaQueryConfig.edges.countAssignedTo,
    model: asanaEntityConfig.model,
    default: [],
    children: [
      { attr: UID },
      { attr: asanaEntityConfig.edges.gid },
      { attr: asanaEntityConfig.edges.name },
    ],
  },
  {
    attr: asanaQueryConfig.edges.countSections,
    model: asanaEntityConfig.model,
    default: [],
    children: [
      { attr: UID },
      { attr: asanaEntityConfig.edges.gid },
      { attr: asanaEntityConfig.edges.name },
      { attr: asanaEntityConfig.edges.status },
    ],
  },
  {
    attr: asanaQueryConfig.edges.task,
    model: asanaEntityConfig.model,
    default: null,
    children: [
      { attr: UID },
      { attr: asanaEntityConfig.edges.gid },
      { attr: asanaEntityConfig.edges.name },
    ],
  },
  {
    attr: asanaQueryConfig.edges.taskCompletionSections,
    model: asanaEntityConfig.model,
    default: [],
    children: [
      { attr: UID },
      { attr: asanaEntityConfig.edges.gid },
      { attr: asanaEntityConfig.edges.name },
      { attr: asanaEntityConfig.edges.status },
    ],
  },
  { attr: asanaQueryConfig.edges.operationMode },
];
