<template>
  <div class="grid-page-tile-update-feed">
    <update-feed
      show-views-selector
      show-create-view
      :read-only="props.readOnly"
    />
  </div>
</template>

<script setup>
import UpdateFeed from '@/components/update-feed/UpdateFeed.vue';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useUpdateFeedDefaultProps from '@/composables/saved-views/update-feed-defaut-props';
import useViewServiceInit from '@/composables/saved-views/view-service-init';
import { AND } from '@/lib/filter/scope-tree';
import { CREATED_AT } from 'shared/api/query/constants';
import { VIEWS_SERVICE } from '@/lib/constants';
import { provide } from 'vue';
import { useI18n } from 'vue-i18n';
import { viewApplication, viewType } from 'shared/constants.json';

const i18n = useI18n();

const props = defineProps({
  gridPageTile: { type: Object, required: true },
  readOnly: { type: Boolean, default: false },
});

const { loggedInUserAccount } = useLoggedInUserAccount();

const defaultPropsSvc = useUpdateFeedDefaultProps();
const viewServiceInit = useViewServiceInit();

const viewApp = viewApplication.gridPageTileUpdateFeed;

const defaultView = {
  title: i18n.t('list.feed'),
  viewType: viewType.feed,
  viewApplication: viewApp,
  params: {
    filter: { account: { uid: loggedInUserAccount.value.uid }, children: [], op: AND },
    order: [{ attr: CREATED_AT, desc: true }],
    props: [],
    wrapTitles: false,
  },
};

let viewConfig = viewServiceInit.defaultConfig();
viewConfig = viewServiceInit.withDefaultProps(viewConfig, defaultPropsSvc.defaultProps);
viewConfig = viewServiceInit.withDefaultView(viewConfig, defaultView);
viewConfig = viewServiceInit.withViewApplication(viewConfig, viewApp);
viewConfig = viewServiceInit.withReadOnly(viewConfig, props.readOnly);
viewConfig = viewServiceInit.withGridPageTile(viewConfig, props.gridPageTile);

const viewSvc = viewServiceInit.gridPageViewService(viewConfig);
provide(VIEWS_SERVICE, viewSvc);

</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  .grid-page-tile-update-feed {
    padding: 0 1.6rem .8rem 1.6rem;
    height: inherit;
    overflow: auto;
  }
</style>
