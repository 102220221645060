import { AND, NOT } from '@/lib/filter/scope-tree';
import { GOAL_RELATION } from '@/lib/props/custom-types';
import { OR } from 'shared/lib/access';
import { goal as goalConfig } from 'shared/api/query/configs.json';
import { goalTypesFilter } from '@/lib/filter/goal/goals-type-filter';
import { guid } from 'shared/lib/uuid';
import { userScopeType } from 'shared/constants.json';

export const parentExistsFilter = (negated = false) => {
  const op = negated === true ? AND : NOT;
  return {
    key: guid(),
    op,
    children: [{
      key: guid(),
      scope: {
        type: userScopeType.directProperty,
        directProperty: {
          edgeName: goalConfig.edges.parents,
          type: GOAL_RELATION,
        },
        isEmpty: true,
      },
    }],
  };
};

export const parentInTypesFilter = (goalTypeProperty, parentTypes, negated = false) => {
  const op = negated === true ? NOT : AND;
  return {
    key: guid(),
    op,
    children: [{
      key: guid(),
      scope: {
        type: userScopeType.indirectProperty,
        indirectProperty: {
          edgeName: goalConfig.edges.parents,
          model: goalConfig.model,
          scope: {
            type: userScopeType.property,
            edgeName: goalConfig.edges.properties,
            property: goalTypeProperty,
            selectedOptions: parentTypes,
            isEmpty: parentTypes.length === 0,
          },
        },
      },
    }],
  };
};

export const parentTypeFilter = (goalTypeProperty, goalType, negated = false) => {
  const op = negated === true ? NOT : AND;

  if (goalType.canBeChildOf.length === 0) {
    return {
      key: guid(),
      op,
      children: [{
        key: guid(),
        op: OR,
        children: [
          parentExistsFilter(true),
        ],
      }],
    };
  }

  if (goalType.parentRequired === true) {
    return {
      key: guid(),
      op,
      children: [{
        key: guid(),
        op: AND,
        children: [
          parentExistsFilter(),
          parentInTypesFilter(goalTypeProperty, goalType.canBeChildOf),
        ],
      }],
    };
  }

  return {
    key: guid(),
    op,
    children: [{
      key: guid(),
      op: OR,
      children: [
        parentExistsFilter(true),
        parentInTypesFilter(goalTypeProperty, goalType.canBeChildOf),
      ],
    }],
  };
};

export const allGoalTypesParentTypeFilter = (goalTypeProperty, goalTypes) => {
  const children = goalTypes.map((goalType) => ({
    op: AND,
    key: guid(),
    children: [
      goalTypesFilter(goalTypeProperty, goalType),
      parentTypeFilter(goalTypeProperty, goalType, true),
    ],
  }));
  return {
    key: guid(),
    op: OR,
    children,
  };
};
