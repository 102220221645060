<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.1875 12H19.875C19.7719 12 19.6875 12.0844 19.6875 12.1875V19.6875H4.3125V4.3125H11.8125C11.9156 4.3125 12 4.22812 12 4.125V2.8125C12 2.70937 11.9156 2.625 11.8125 2.625H3.375C2.96016 2.625 2.625 2.96016 2.625 3.375V20.625C2.625 21.0398 2.96016 21.375 3.375 21.375H20.625C21.0398 21.375 21.375 21.0398 21.375 20.625V12.1875C21.375 12.0844 21.2906 12 21.1875 12Z"
      fill="currentColor"
    />
    <path
      d="M8.34135 12.5367L8.29682 15.3234C8.29447 15.532 8.46322 15.7031 8.67182 15.7031H8.68119L11.4468 15.6352C11.4937 15.6328 11.5406 15.6141 11.5734 15.5812L21.321 5.85469C21.3937 5.78203 21.3937 5.6625 21.321 5.58984L18.4078 2.67891C18.3703 2.64141 18.3234 2.625 18.2742 2.625C18.2249 2.625 18.1781 2.64375 18.1406 2.67891L8.39525 12.4055C8.3614 12.4409 8.34215 12.4877 8.34135 12.5367ZM9.82963 13.0898L18.2742 4.66406L19.3335 5.72109L10.8843 14.1516L9.81322 14.1773L9.82963 13.0898Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default { name: 'EditSquare' };
</script>

<style scoped lang="scss" type="text/scss">

</style>
