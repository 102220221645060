import useTrashRepo from '@/composables/trash/trash-repo';
import { computed } from 'vue';

export default function useTrash({ model, searchTerm, itemsPerPage }) {
  const repo = useTrashRepo(model);
  repo.subscribe();

  const modelTrash = computed(() => model.value);
  const searchTermTrash = computed(() => searchTerm.value);
  const itemsPerPageTrash = computed(() => itemsPerPage.value);
  const loadMore = () => {
    const page = repo.getNextPage(modelTrash.value, itemsPerPageTrash.value);
    return repo.getSoftDeleted({ page, itemsPerPage: itemsPerPageTrash.value, searchTerm: searchTermTrash.value });
  };

  const hasMore = () => repo.hasMore(modelTrash.value);

  const cleanCache = () => {
    repo.cleanCacheForModel(modelTrash.value);
  };

  const deleteSoftDeleted = (uid) => repo.deleteSoftDeleted(uid, model.value);
  const restoreSoftDeleted = (uid) => repo.restoreSoftDeleted(uid, model.value);

  return {
    cleanCache,
    deleteSoftDeleted,
    getSoftDeletedLoading: repo.getSoftDeletedLoading,
    trashItems: repo.trashItems,
    loadMore,
    hasMore,
    restoreSoftDeleted,
  };
}
