<template>
  <div
    class="_color"
    :style="colorStyle"
  />
</template>

<script setup>
import { computed } from 'vue';
import { getColor } from 'shared/lib/color-map';
import { shadeColor } from 'shared/lib/style';

const props = defineProps({
  color: {
    type: String,
    required: true,
  },
});
const colorStyle = computed(() => ({
  backgroundColor: getColor(props.color),
  height: '1.5rem',
  width: '1.5rem',
  borderRadius: '.3rem',
  border: `1px solid ${shadeColor(getColor(props.color), -10)}`,
}));
</script>

<style scoped lang="scss">

</style>
