import { goalsById } from '@/api/query/nebula/goal';
import { useStore } from 'vuex';

export default function useParamEnricher(propertySvc, goalsSvc) {
  const store = useStore();

  const fetchGoals = (relations) => {
    const relationIds = relations.map((g) => g.uid).filter((id) => !goalsSvc.ids.value.includes(id));

    if (relationIds.length === 0) {
      return;
    }

    const query = goalsById({ ids: relationIds });
    goalsSvc.getGoals({ queries: query });
  };

  const goalRelations = (userScopeTree) => {
    const res = [];

    if (userScopeTree.children !== undefined && userScopeTree.children !== null && Array.isArray(userScopeTree.children)) {
      res.push(...userScopeTree.children.map((tree) => goalRelations(tree)).flat());
    }

    if (userScopeTree.scope === undefined || userScopeTree.scope === null) {
      return res;
    }

    if (userScopeTree.scope.relation === undefined || userScopeTree.scope.relation === null) {
      return res;
    }

    if (Object.keys(userScopeTree.scope.relation).length > 1) {
      return res;
    }

    if (userScopeTree.scope.relation.uid === undefined) {
      return res;
    }

    res.push(userScopeTree.scope.relation);
    return res;
  };

  const mergeProperty = (actual, property) => {
    let res = actual;

    // property.model is used by the 'multiModel' feature used on the LeafNode
    // component
    if (property.model !== undefined) {
      res = {
        ...res,
        model: property.model,
      };
    }

    return res;
  };

  const enrich = (userScopeTree) => {
    if (Array.isArray(userScopeTree.children) && userScopeTree.children.length > 0) {
      userScopeTree.children = userScopeTree.children.map((c) => enrich(c));
    }

    if (userScopeTree.scope === undefined || userScopeTree.scope === null) {
      return userScopeTree;
    }

    if (userScopeTree.scope.property === undefined || userScopeTree.scope.property.uid === undefined) {
      return userScopeTree;
    }

    if (userScopeTree.scope.relation !== null && userScopeTree.scope.relation !== undefined) {
      const actual = store.state.goal.find((g) => g.uid === userScopeTree.scope.relation.uid);
      if (actual !== undefined) {
        userScopeTree.scope.relation = actual;
      }
    }

    const p = propertySvc.properties.value.find((p) => p.uid === userScopeTree.scope.property.uid);
    // no need to enrich deleted properties
    if (p !== undefined) {
      userScopeTree.scope.property = mergeProperty(p, userScopeTree.scope.property);
    }

    return userScopeTree;
  };

  return { enrich, goalRelations, fetchGoals };
}
