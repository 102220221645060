import { computed } from 'vue';
import { normalizeTree } from '@/lib/goal/normalize-tree';

export function useCascadeData(goalTreeService, amountOfItems) {
  const normalized = computed(() => {
    const data = goalTreeService.goalTree;
    return [normalizeTree('_children')].reduce((res, m) => {
      res = m(res);
      return res;
    }, data.value);
  });

  const goalTree = computed(() => normalized.value.slice(0, amountOfItems.value));

  const loadedItems = computed(() => normalized.value.length);

  return { goalTree, loadedItems };
}
