import Extension from '@/tiptap/tiptap/Utils/Extension';

export default class EnterExtension extends Extension {
  constructor(options = {}) {
    super(options);
    this.enterHandlers = options.enterHandlers;
    this.ctrlEnterHandlers = options.ctrlEnterHandlers;
    this.modEnterHandlers = options.modEnterHandlers;
    this.shiftEnterHandlers = options.shiftEnterHandlers;
  }

  keys() {
    const handleFn = (state, dispatch, view, handlers) => {
      if (!Array.isArray(handlers)) {
        return false;
      }

      let preventDefault = false;
      for (let i = 0; i < handlers.length; i++) {
        preventDefault = handlers[i](state, dispatch, view);
      }

      return preventDefault;
    };
    return {
      Enter: (state, dispatch, view) => handleFn(state, dispatch, view, this.enterHandlers),
      'Ctrl-Enter': (state, dispatch, view) => handleFn(state, dispatch, view, this.ctrlEnterHandlers),
      'Mod-Enter': (state, dispatch, view) => handleFn(state, dispatch, view, this.modEnterHandlers),
      'Shift-Enter': (state, dispatch, view) => handleFn(state, dispatch, view, this.shiftEnterHandlers),
    };
  }
}
