<template>
  <m-card
    class="chart-table-container"
    no-padding
  >
    <m-content
      class="_header"
      padding-small
    >
      <div class="_left">
        {{ titleLeft }}
      </div>
      <div class="_middle">
        {{ titleMiddle }}
      </div>
      <div class="_right">
        {{ titleRight }}
      </div>
    </m-content>
    <m-divider none />
    <slot />
  </m-card>
</template>

<script>
export default {
  name: 'ChartTableContainer',
  props: {
    titleLeft: {
      type: String,
      default: '',
    },
    titleMiddle: {
      type: String,
      default: '',
    },
    titleRight: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .chart-table-container {
    ._header {
      display: flex;
      font-size: $font-size-4;

      ._left {
        flex: 0 0 25rem;
      }

      ._middle {
        flex: 1 1 auto;
      }

      ._right {
        flex: 0 1 auto;
        min-width: 7rem;
        text-align: center;
      }
    }
  }

</style>
