<template>
  <update-feed
    show-create-view
    show-views-selector
  />
</template>

<script setup>
import UpdateFeed from '@/components/update-feed/UpdateFeed.vue';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useUpdateFeedDefaultProps from '@/composables/saved-views/update-feed-defaut-props';
import useViewServiceInit from '@/composables/saved-views/view-service-init';
import { CREATED_AT } from 'shared/api/query/constants';
import { VIEWS_SERVICE } from '@/lib/constants';
import { goal as goalConfig } from 'shared/api/query/configs.json';
import { provide } from 'vue';
import { filter as spaceFilter } from '@/lib/filter/space/filter';
import { useI18n } from 'vue-i18n';
import { viewApplication, viewType } from 'shared/constants.json';

const i18n = useI18n();
const defaultPropsSvc = useUpdateFeedDefaultProps();
const viewServiceInit = useViewServiceInit();
const { loggedInUserAccount } = useLoggedInUserAccount();

const props = defineProps({
  property: {
    type: Object,
    required: true,
  },
  space: {
    type: Object,
    required: true,
  },
});

const viewApp = viewApplication.updateFeedSpace;

const initialFilter = spaceFilter({
  property: {
    ...props.property,
    model: goalConfig.model,
  },
  space: props.space,
  account: { uid: loggedInUserAccount.value.uid },
});

const defaultView = {
  title: i18n.t('list.feed'),
  viewType: viewType.feed,
  viewApplication: viewApp,
  space: props.space,
  params: {
    filter: initialFilter,
    order: [{ attr: CREATED_AT, desc: true }],
    props: [],
  },
};

let viewConfig = viewServiceInit.defaultConfig();
viewConfig = viewServiceInit.withDefaultProps(viewConfig, defaultPropsSvc.defaultProps);
viewConfig = viewServiceInit.withDefaultView(viewConfig, defaultView);
viewConfig = viewServiceInit.withViewApplication(viewConfig, viewApp);
viewConfig = viewServiceInit.withSpace(viewConfig, props.space);

const viewSvc = viewServiceInit.routeAwareViewService(viewConfig);
provide(VIEWS_SERVICE, viewSvc);

</script>

<style scoped lang="scss" type="text/scss">
</style>
