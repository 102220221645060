import { DEFAULT_ATTRIBUTES } from 'shared/api/query/constants';
import { favorite as favoriteConfig, storageObject as storageObjectConfig } from 'shared/api/query/configs.json';
import { storageObjectEdges } from '@/api/query/nebula/storage-object';

export const children = [
  ...DEFAULT_ATTRIBUTES,
  { attr: favoriteConfig.edges.title, default: '' },
  { attr: favoriteConfig.edges.icon, default: '' },
  {
    attr: favoriteConfig.edges.image,
    model: storageObjectConfig.model,
    children: storageObjectEdges,
    default: null,
  },
  { attr: favoriteConfig.edges.routeName },
  { attr: favoriteConfig.edges.query, default: null },
  { attr: favoriteConfig.edges.params, default: null },
];
