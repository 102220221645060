import { iconList } from 'shared/lib/icon-picker/icon-list';

export const DARK_GREY = 'dark_grey';
export const LIGHT_GREY = 'light_grey';
export const BROWN = 'brown';
export const YELLOW = 'yellow';
export const ORANGE = 'orange';
export const GREEN = 'green';
export const BLUE = 'blue';
export const PURPLE = 'purple';
export const PINK = 'pink';
export const RED = 'red';

export const ICON_VARIANTS = [
  DARK_GREY,
  LIGHT_GREY,
  BROWN,
  YELLOW,
  ORANGE,
  GREEN,
  BLUE,
  PURPLE,
  PINK,
  RED,
];

export const getVariant = (icon) => {
  for (let i = 0; i < ICON_VARIANTS.length; i++) {
    if (icon.indexOf(ICON_VARIANTS[i]) !== -1) {
      return ICON_VARIANTS[i];
    }
  }

  return '';
};

export const removeVariant = (icon) => {
  let res = icon;
  ICON_VARIANTS.forEach((variant) => {
    res = res.replace(`_${variant}`, '');
  });
  return res;
};

export const iconFromIconPicker = (icon) => {
  if (typeof icon !== 'string') {
    return false;
  }

  return iconList.icons[removeVariant(icon)] !== undefined;
};
