import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useRepo from '@/nebula/repo';
import { DateTime } from 'luxon';
import { EVENTS } from '@/lib/constants';
import { EventBus } from '@/lib/event-bus';
import { RESULT } from 'shared/api/query/constants';
import { accessPolicyType, notificationType, userScopeType } from 'shared/constants.json';
import { computed, ref } from 'vue';
import { defaultRRule } from '@/lib/rrule';
import { notificationChildren, notificationList } from '@/api/query/nebula/notification';
import { notification as notificationConfig } from 'shared/api/query/configs.json';

export default function useUpdateSchedules() {
  const repo = useRepo(notificationConfig.model);

  const { loggedInUser } = useLoggedInUser();
  const { loggedInUserAccount } = useLoggedInUserAccount();

  const createSchedule = () => repo.createSingle(
    {
      creator: { uid: loggedInUser.value.uid },
      title: '',
      icon: '',
      typ: notificationType.writeUpdate,
      schedule: defaultRRule(),
      recipients: {
        account: { uid: loggedInUserAccount.value.uid },
        children: [{
          children: [{
            op: 'and',
            scope: {
              type: userScopeType.staticUsers,
              selectedOptions: [],
              spaces: [],
              staticUsers: [{ uid: loggedInUser.value.uid }],
              users: [],
              property: null,
              timeRange: null,
              numberRange: null,
            },
          }],
          op: 'and',
        }],
        op: 'and',
      },
      accessPolicy: {
        account: { uid: loggedInUserAccount.value.uid },
        accountAccess: accessPolicyType.disabled,
      },
    },
  ).then((data) => {
    EventBus.$emit(EVENTS.CHECKIN.SCHEDULE_CREATED);
    return data;
  });
  const schedulesLoading = ref(false);
  const fetchSchedules = () => {
    schedulesLoading.value = true;
    return repo.query(
      notificationList({
        type: notificationType.writeUpdate,
        timeSeries: [DateTime.local().toISO()],
      }),
    )
      .then((data) => {
        const scheduleIDs = data[RESULT].map((e) => e.uid);
        return repo.selectMultiple(scheduleIDs);
      })
      .finally(() => {
        schedulesLoading.value = false;
      });
  };

  const reset = (schedule) => {
    repo.updateSingle(schedule, { commitToRemote: false });
  };

  const deleteSchedule = (notification) => repo.deleteSingle(
    notification.uid,
  ).then(() => {
    EventBus.$emit(EVENTS.CHECKIN.SCHEDULE_DELETED);
  });

  const activate = (schedule) => updateSchedule({ ...schedule, activatedAt: DateTime.local().toISO() });
  const pause = (schedule) => updateSchedule({ ...schedule, activatedAt: null });

  const updateLoading = ref(false);
  const updateSchedule = (schedule, timeSeries = [DateTime.local().toISO()]) => {
    updateLoading.value = true;
    return repo.updateSingle(
      schedule,
      { optimistic: false, attributes: notificationChildren(timeSeries) },
    ).finally(() => {
      updateLoading.value = false;
    });
  };

  const updateScheduleAccessPolicy = (accessPolicy, notification) => repo.updateSingle(
    { uid: notification.uid, accessPolicy },
  )
    .then((data) => repo.selectSingle(data.uid))
    .finally(() => {
      updateLoading.value = false;
    });

  return {
    fetchSchedules,
    schedulesLoading,
    schedules: computed(() => repo.entityList.value.filter((s) => s.isDirty !== undefined)),
    createSchedule,
    createLoading: repo.createLoading,
    selectSingle: repo.selectSingle,

    updateSchedule,
    updateSingle: repo.updateSingle,
    reset,
    updateScheduleAccessPolicy,
    pause,
    activate,
    updateLoading,
    deleteLoading: repo.deleteLoading,
    deleteSchedule,
  };
}
