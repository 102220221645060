import { OR, UID_IN } from 'shared/api/query/constants';
import { notHasFilter } from 'shared/api/query/filter';

export const directPropPlanningFn = (isFilterMode = false) => (scope) => {
  if (scope.isEmpty === true) {
    return {
      filterTrees: [notHasFilter(scope.directProperty.edgeName)],
      varBlocks: [],
    };
  }

  const plannings = scope.plannings;
  if (plannings.length === 0 && isFilterMode) {
    return { filterTrees: [], varBlocks: [] };
  }

  return {
    filterTrees: [
      {
        op: OR,
        child: [{
          func: {
            attr: scope.directProperty.edgeName,
            name: UID_IN,
            args: plannings.map((p) => ({ value: `${p.value}` })),
          },
        }],
      },
    ],
    varBlocks: [],
  };
};
