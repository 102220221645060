import ISO6391 from 'shared/lib/languages';

export const DEFAULT_LANGUAGE = 'en';

export const languageCodes = () => ISO6391.getAllCodes();

export const platformLanguages = () => ISO6391.getLanguages(languageCodes());

export const isValidLanguageCode = (code) => languageCodes().includes(code);

export const nameByCode = (code) => ISO6391.getName(code);

export const AVAILABLE_SYSTEM_LANGUAGE_CODES = ['en', 'de'];

export const defaultLanguageField = (languages) => {
  let defaultField = {};
  languages.forEach((lang) => {
    defaultField = {
      ...defaultField,
      [lang]: '',
    };
  });
  return defaultField;
};

export const browserLanguage = () => {
  const lang = navigator.language;
  if (lang.startsWith('de')) {
    return 'de';
  }
  return DEFAULT_LANGUAGE;
};
