<template>
  <svg
    width="294"
    height="187"
    viewBox="0 0 294 187"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="294"
      height="187"
      rx="10"
      fill="white"
    />
    <!--eslint-disable-->
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 0C4.47715 0 0 4.47715 0 10V177C0 182.523 4.47716 187 10 187H35V58.8344C35 53.3116 39.4772 48.8344 45 48.8344H250C255.523 48.8344 260 53.3116 260 58.8344V187H284C289.523 187 294 182.523 294 177V10C294 4.47715 289.523 0 284 0H10ZM259 187V58.8344C259 53.8638 254.971 49.8344 250 49.8344H45C40.0294 49.8344 36 53.8638 36 58.8344V187H259Z"
      fill="#9DD8E7"
    />
    <!--eslint-enable-->
    <rect
      x="52"
      y="77"
      width="18"
      height="5"
      rx="2.5"
      fill="white"
    />
    <rect
      x="79"
      y="77"
      width="62"
      height="5"
      rx="2.5"
      fill="white"
    />
    <rect
      x="52"
      y="86"
      width="18"
      height="5"
      rx="2.5"
      fill="white"
    />
    <rect
      x="79"
      y="86"
      width="79"
      height="5"
      rx="2.5"
      fill="white"
    />
    <line
      x1="53"
      y1="102.5"
      x2="242"
      y2="102.5"
      stroke="white"
    />
    <rect
      x="53"
      y="122"
      width="24"
      height="27"
      rx="4"
      fill="white"
    />
    <rect
      x="86"
      y="122"
      width="24"
      height="27"
      rx="4"
      fill="white"
    />
    <rect
      x="119"
      y="122"
      width="24"
      height="27"
      rx="4"
      fill="white"
    />
    <rect
      x="152"
      y="122"
      width="24"
      height="27"
      rx="4"
      fill="white"
    />
    <rect
      x="185"
      y="122"
      width="24"
      height="27"
      rx="4"
      fill="white"
    />
  </svg>
</template>

<script>
export default { name: 'GraphicProcess' };
</script>
