import globalLang from 'shared/lang/en';
import { PRIVACY_URL, TERMS_URL } from '@/lib/config';
import { asanaError, salesforceError } from 'shared/constants.json';
import { importValidationFailure } from '@/constants.json';
import { mergeLangObjects } from 'shared/lang/lang';
/* eslint-disable max-len */

const lang = {
  views: {
    survey: 'Survey',
    home: 'Home',
    profile: 'Profile',
    profileSettings: 'Profile - Settings',
    myNotifications: 'Notifications',
    accountSettings: 'Account - Settings',
    login: 'Log in',
    register: 'Signup',
    userList: 'Members',
    spaceList: 'Teams',
    userDetail: 'Employees',
    userAdd: 'Add Employee',
    surveyTemplateList: 'Survey Templates',
    surveyList: 'Surveys',
    surveySuccess: 'Thank you for sharing!',
    createForm: 'Create',
    formSettings: 'Settings',
    formSend: 'Schedule',
    formResults: 'Results',
    fieldFactors: 'Factors',
    properties: 'Properties',
    documentList: 'Dashboards',
    accessGroupDetails: 'Access groups',
    confirmInvitationTitle: 'Register',
    setPasswordTitle: 'Reset your password',
    passwordForgetTitle: 'Forgot password',
    formShare: 'Send Survey',
    createFormTemplate: 'Create Survey Template',
    newForm: 'New Survey',
    newFormTemplatePreview: 'Preview Survey',
    questionBank: 'Question Bank',
    createFormTemplatesList: 'Survey Templates',
    goalCycles: 'Cycles',
    goalDetails: 'Goal Details',
    goalSettings: 'Goal Settings',
    settings: 'Settings',
    notFound: '404',
    goalsAtlas: 'Network',
    profileInbox: 'Inbox',
    optionMembers: 'Members',
    dashboards: 'Dashboards',
    goalInsights: 'Dashboard',
    dashboardDetails: 'Dashboard',
    accountSpace: 'Company',
    spaceDetails: 'Team',
    onboarding: 'Onboarding',
    formTemplateTags: 'Categories',
    publishedView: 'Goals',
    integrationSettings: 'Integrations',
    manageDataSource: 'Manage integration',
    goalsUpdateFeed: 'Feed',
    plans: 'Plans',
    goalTypes: 'Goal types',
    goalStatus: 'Status',
    authorizationCodeGrantRedirect: 'Sign in',
  },
  highcharts: {
    decimalPoint: '.',
    thousandsSep: ',',
    months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    actual: 'Actual',
    expected: 'Expected',
    trend: 'Trend',
    noData: 'No data available',
  },
  navigation: {
    home: 'Home',
    surveys: 'Surveys',
    settings: 'Settings',
    logout: 'Log out',
    feedback: 'Feedback',
    users: 'Members',
    about: 'About',
    explorer: 'Explorer',
    goalInsights: 'Dashboard',
    goalInsightsPerformance: 'Progress',
    goalInsightsHealth: 'Health',
    dashboards: 'Dashboards',
    goalsExplorerCascade: 'Cascade',
    goalsExplorerList: 'List',
    goalsAtlas: 'Network',
    updatesExplorer: 'Check-ins',
    search: 'Search',
    feed: 'Feed',
    updatesExplorerList: 'List',
    meetings: 'Meetings',
    meetingDetails: 'Meeting Details',
    instantFeedback: 'Feedback',
    updateSchedules: 'Check-in schedules',
    updateScheduleDetails: 'Check-in Schedule Details',
    updateTemplates: 'Check-in Templates',
    plannings: 'Planning',
    planningDetails: 'Planning space',
    newDashboard: 'New Dashboard',
    dashboard: 'Dashboards',
  },
  mainNavigation: {
    tools: 'Tools',
    macSearchKeyBinding: '⌘K',
    searchKeyBinding: 'Ctrl + K',
    inbox: 'Inbox',
    favorites: 'Favorites',
    spaceDropdownTitle: 'Space',
    invitePeople: 'Invite people',
    onboarding: 'Onboarding',
    helpAndSupport: 'Help & Support',
    myWorkspace: 'Spaces',
    insights: 'Insights',
    trash: 'Trash',
  },
  trashContent: {
    title: 'Deleted items',
    tabGoals: 'Goals',
    noItems: { goal: 'No deleted goals', filter: 'No filter results' },
    action: {
      confirmDeletePermanently: 'Are you sure you want to delete the goal "{title}" permanently?',
      yesDelete: 'Yes. Delete this goal',
    },
    openGoal: 'Open goal',
  },
  myWorkspace: {
    home: 'Home',
    viewAll: 'View all',
  },
  settingsNavigation: {
    title: 'Settings',
    preferences: 'Settings',
    account: 'Account',
    general: 'General',
    workspace: 'Workspace',
    member: 'User',
    features: 'Tools',
    profile: 'Profile',
    userList: 'Members',
    spaceList: 'Teams',
    formTemplateList: 'Surveys',
    userFields: 'Properties',
    accessControl: 'Access groups',
    goals: 'Goals',
    myNotifications: 'Notifications',
    instantFeedbackTemplateList: 'Feedback Templates',
    identityAndProvisioning: 'Identity and provisioning',
    plans: 'Plans and billing',
    goalSettings: 'Settings',
    goalCycles: 'Cycles',
    goalTypes: 'Types and rules',
    goalStatusSettings: 'Status',
  },
  edges: {
    status: 'Status',
    title: 'Title',
    createdAt: 'Date created',
    cachedLastUpdatedAt: 'Last updated',
    cachedCalculatedCurrent: 'Progress',
    cachedStatus: 'Status',
    creator: 'Creator',
    date: 'Date',
    firstName: 'First name',
    lastName: 'Last name',
    email: 'Email',
  },
  pagination: {
    rowsPerPage: 'Rows per Page',
    from: 'of',
  },
  surveyList: { addSurvey: 'New Survey' },
  createFormCard: {
    heading: 'Create a new survey',
    nameLabel: 'How is your new survey called?',
  },
  charts: {
    answer: 'Response',
    proportion: 'Distribution',
    amountOfAnswers: 'Number of responses',
    avg: 'Ø',
    top2: 'Top 2',
    top3: 'Top 3',
    count: 'Count',
    positiveAnswers: 'Positive',
    negativeAnswers: 'Negative',
    neutralAnswers: 'Neutral',
    favorability: 'Favorability',
  },
  editPropertyForm: {
    editTitle: 'Edit property',
    createTitle: 'Create a new property',
    label: 'Name',
    description: 'Description',
    labelHint: 'The name should not be too long.',
    deletePrompt: 'Do you really want to delete this property?',
    deletePromptButton: 'Yes, delete',
    type: 'Type',
    usedFor: 'Usage',
    usedForDescription: 'Set here for which objects this property should be used. It is also possible to use properties for several objects, e.g. the property "Department" for Goals and Users.',
    numberFormat: 'Formatting options',
    segments: 'Options',
    segmentsDescription: 'Options are the concrete values of a property, e.g. the departments of your company.',
    addSegment: 'Add Option',
    deleteText: 'Are you sure that you want to delete this option?',
    number: 'Number',
    commaSeparated: 'Comma separated number',
    percent: 'Percent',
    euro: 'Euro',
    dollar: 'Dollar',
    pound: 'Pound',
    lookupValue: 'Lookup value',
    lookupDescription: 'Specify the goal and user property which are used to retrieve the lookup value.',
    lookupUsedForHint: 'Lookup properties can only be used for goals.',
    lookupRelationsNotSet: 'The lookup value needs to be set.',
    spaceManagementHint: 'Looking for teams? They can now be found on their own page under {link}.',
    spaceManagementLink: '@:{\'settingsNavigation.workspace\'} / @:{\'settingsNavigation.spaceList\'}',
  },
  properties: {
    pageTitle: 'Properties',
    pageSubtitle: 'Properties enrich your objects like goals or users with additional information. It allows you to customize the platform to best fit your company and processes.',
    addButtonLabel: 'Add a property',
    labelTitle: 'Name',
    labelType: 'Type',
    labelStatus: 'Status',
    labelUsedFor: 'Usage',
    createNewTitle: 'Create a new property',
    editTitle: 'Edit Property',
    helpLink: '@:{\'general.helpCenterBaseUrl\'}/properties-in-mooncamp',
    help: 'Learn more about how you can use properties.',
  },
  propertyConstants: {
    space: 'Space',
    options: 'Multi-Select',
    singleSelect: 'Select',
    text: 'Text',
    date: 'Date',
    number: 'Number',
    user: 'Person',
    url: 'Link',
    lookup: 'Lookup',
    goal: 'Goals',
    update: 'Check-ins',
    meeting: 'Meeting',
    inactive: 'Inactive',
    active: 'Active',
    spaceDescription: 'Select multiple teams',
    optionsDescription: 'Select multiple options, e.g. departments',
    singleSelectDescription: 'Select a single option, e.g. a status',
    textDescription: 'A custom text, e.g. the user\'s position',
    dateDescription: 'A concrete date, e.g. a user\'s birthday',
    userDescription: 'A relation between persons, e.g. manager',
    urlDescription: 'A link to a page on the web',
    numberDescription: 'A number, e.g. salary or performance score',
    lookupDescription: 'Returns the value of a property from another object (e.g. User)',
  },
  formFieldSettings: {
    opinionScale: 'Opinion Scale',
    longText: 'Free-Text Question',
    questionTypeLabel: 'Question Type',
    stepsLabel: 'Steps',
    requiredLabel: 'Required',
    allowComment: 'Allow Comments',
    startsFromZero: 'Start at 0',
    labelLabel: 'Labels',
    labelLeftLabel: 'Left Label',
    labelCenterLabel: 'Center Label',
    labelRightLabel: 'Right Label',
  },
  formFieldList: {
    addField: 'Add new Question',
    noItems: 'No questions added yet',
    welcomeScreen: 'Welcome Text',
    opinionScale: 'Opinion Scale',
    longText: 'Free-Text Question',
  },
  createForm: {
    allSaved: 'All changes have been saved.',
    needsSaving: 'There are unsaved changes.',
  },
  formField: {
    prompt: 'Are you sure that you want to delete this question?',
    submitLabel: 'Yes, delete',
    titlePlaceholder: 'Type question here',
  },
  welcomeScreenField: {
    titleLabel: 'Title',
    descriptionLabel: 'Description',
  },
  fieldTranslator: { heading: 'Translations' },
  formSettingsCard: {
    languageHeading: 'Language Settings',
    addLanguage: 'Add language',
  },
  createFormTopBar: {
    analyticAccess: 'Share',
    accessManagement: 'Share',
    preview: 'Preview',
    activateProcess: 'Activate',
    deactivateProcess: 'Deactivate',
    deactivatePrompt: 'By deactivating the survey, automatic emails or notifications will no longer be sent.',
    deactivated: 'The survey was successfully deactivated.',
    deactivateNow: 'Deactivate now',
    activatePrompt: 'By activating, the survey will be open for responses and automatic emails will be sent.',
    activateNow: 'Activate now',
    activated: 'The survey was successfully activated.',
    deactivateNotice: 'Stops automatic notifications of the survey.',
    errorNotice: 'Please fix the existing errors.',
    notActive: 'Inactive',
    active: 'Active',
  },
  formLanguageItem: {
    deleteLabel: 'Remove Translation',
    prompt: 'Are you sure that you want to delete this translation?',
  },
  formLanguageSettingsCard: { addLanguage: 'Add Language' },
  formCard: {
    results: 'Results',
    deleteTitle: 'Are you sure that you want to delete this survey? You are going to lose all responses and won\'t be able to restore them.',
  },
  editUserForm: {
    firstName: 'First name',
    lastName: 'Last name',
    email: 'Email',
    language: 'Language',
    createTitle: 'Add user',
    editTitle: 'Edit user',
    groups: {
      personal: 'Personal data',
      other: 'Other properties',
    },
    sendInvitationMail: 'Send invitation email',
    timezone: 'Time zone',
  },
  userAdd: { title: 'Add user' },
  userList: {
    title: 'Members ({total})',
    tableTitle: 'Members',
    active: 'Active',
    invite_pending: 'Invite pending',
    no_invitation: 'Not invited yet',
    status: 'Status',
    accessGroups: 'Access groups',
    activatedAt: 'Activated on',
    invitedByAt: 'Invited by {name} on',
    invitedAt: 'Invited at',
    invitedByYouAt: 'Invited by you on',
    add: 'Add user',
    deleteUserTitle: 'Are you sure that you want to delete this user?',
    importModalHeader: 'Import from CSV',
    importModalButton: 'Import CSV',
    importModalText: 'Import a CSV file to add employees to Mooncamp. Your CSV should include a header row with field names that match your property names. You can download an example {link}.',
    here: 'here',
    link: '/api/v1/csv-example?lang=en',
    importModalFileSelect: 'Choose file',
    importSubmit: 'Upload',
    deleteSubmitLabel: 'Delete',
    exportUsers: 'Export CSV',
    bulkInvitationBox: {
      text: 'There are {users} which have not been invited yet. They will not receive any emails from Mooncamp until they log in at least once to activate their account.',
      userCount: '{userCount} users',
      bulkAction: 'Send invitation to {userCount} users',
      goToMembers: 'Go to @:{\'settingsNavigation.userList\'}',
    },
    bulkInvitationDialog: {
      text: 'Please confirm that you want to send an invitation email to {userCount} users with the status "Not invited yet".',
      confirmAction: 'Yes, send invitations to {userCount} users',
    },
    demo: 'Demo user',
  },
  userListCard: {
    name: 'Name',
    editUser: 'Edit User',
    sendInvitationMailConfirm: 'Send an invitation to {firstname} {lastname}?',
    sendInvitationMail: 'Send invitation',
    invitationSuccess: 'Invitation successfully sent',
    pending: 'Invitation pending',
  },
  spaceStatus: {
    active: 'Active',
    archived: 'Archived',
    joined: 'Joined',
  },
  spaceList: {
    learnMore: 'Learn more about teams.',
    helpCenterLink: '@:{\'general.helpCenterBaseUrl\'}/teams',
    title: 'Teams ({total})',
    tableTitle: 'Teams',
    create: 'Create team',
    edit: 'Edit team',
    filters: {
      active: 'Active',
      archived: 'Archived',
      joined: 'My Teams',
      all: 'All',
    },
  },
  spaceListTable: {
    header: {
      name: 'Name',
      members: 'Members',
      status: 'Status',
    },
    archivedAt: 'Archived at',
  },
  spaceTopBarMenu: { members: '{count} member | {count} members' },
  spacesContextMenu: {
    openSpace: 'Open team',
    createSubSpace: 'Create subteam',
    cannotCreate: 'You are not allowed to create teams.',
    cannotCreateMultiple: 'You are not allowed to create teams with multiple parents.',
    cannotCreateLevel: 'Only {maxLevel} levels of teams can be created.',
    cannotDelete: 'You are not allowed to delete the selected item.',
    cannotDeleteMultiple: 'You are not allowed to delete multiple items.',
    archiveSomeHint: 'Some teams are already archived.',
  },
  spaceEditor: {
    create: 'Create team',
    edit: 'Team settings',
    dangerZoneLabel: 'Actions',
    generalTab: 'General',
    membersTab: 'Members {count}',
    archiveText: 'Archive team',
    archiveSubtext: 'Remove team from sidebar, goals will not be deleted',
    restoreText: 'Restore team',
    restoreSubtext: 'Restore team to sidebar',
    deleteText: 'Delete team',
    deleteSubtext: 'Remove team and all its goals for all members',
    deleteModal: {
      title: 'Are you sure that you want to delete "{optionTitle}"?',
      message: 'Goals exclusively associated to this team will be deleted.\nGoals shared with other teams will be preserved.\n\nThe data cannot be restored, be careful!',
      securityQuestion: 'Type in the team name to proceed',
      okText: 'Delete team',
    },
    validation: {
      titleIsEmpty: 'The team name cannot be empty.',
      titleAlreadyExists: 'This team name already exists.',
    },
  },
  spaceGeneralForm: {
    generalLabel: 'Icon, color and name',
    parentsLabel: 'Parent team',
    descriptionLabel: 'Description',
    titlePlaceholder: 'Enter name...',
    parentsPlaceholder: 'No team selected',
  },
  spaceMembers: {
    addMembers: 'Add members',
    memberRole: 'Member',
    header: {
      name: 'Name',
      role: 'Role',
    },
    removeMemberPrompt: 'Are you sure you want to remove the member from the team?',
    removeRolePrompt: 'Are you sure you want to remove yourself from this role? You might lose permissions needed to edit this team.',
  },
  spaceMembershipSelector: {
    addMembersTo: 'Add members to',
    addMore: 'Add more',
  },
  documentList: { createDocument: 'Create Document' },
  createDocumentCard: {
    nameLabel: 'Name',
    heading: 'Create a new document',
  },
  gridItemContent: {
    turnInto: 'Turn into',
    h1: 'Heading 1',
    h2: 'Heading 2',
    h3: 'Heading 3',
    text: 'Text',
    handleHint: '<strong>Drag</strong> in order to move',
  },
  accessGroupDetails: {
    addGroup: 'Add access group',
    noAccessGroups: 'No access groups have been created yet.',
    helpLink: '@:{\'general.helpCenterBaseUrl\'}/rights-and-access-control',
    help: 'Learn more about rights and access control.',
  },
  passwordForget: { header: 'Forgot Password' },
  passwordForgetForm: {
    hint1: 'Please type in the email address, that you used for your registration.',
    hint2: 'You will receive a message with instructions to set a new password.',
    buttonText: 'Next',
    success: 'Help is on the way! Please have a look in your email inbox.',
    error: 'Oops, an error occurred. Please check your email and try again.',
  },
  setPassword: { header: 'Choose a new password' },
  setPasswordForm: {
    passwordLabel: 'New Password',
    tip: 'Tip',
    hint: 'Your password should consist of at least eight characters and should include special characters like *, %, $, ? or &.',
    buttonText: 'Set new password',
    success: 'Your password has successfully been set. Please log in again.',
    noLongerValid: 'This link is no longer valid.',
  },
  confirmInvitation: { header: 'Register' },
  confirmInvitationForm: {
    register: 'Register',
    passwordLabel: 'Password (min. 8 characters)',
    firstName: 'Name',
    lastName: 'Last Name',
    emailLabel: 'Email',
    terms: `I agree  to the <a href="${TERMS_URL}" target="_blank" style="color: inherit; text-decoration: underline">Terms of Service</a> and the <a href="${PRIVACY_URL}" target="_blank" style="color: inherit; text-decoration: underline">Privacy Policy</a> of Mooncamp.`,
    minLengthEightChars: 'At least 8 characters.',
    registerFailed: 'You are already registered. Please log in to your account.',
    registerViaGoogle: 'Register via Google',
    registerViaMicrosoft: 'Register via Microsoft',
    logIn: 'Already registered? Log in',
  },
  rRulePicker: {
    first: '1st',
    second: '2nd',
    third: '3rd',
    fourth: '4th',
    last: 'last',
    schedule: 'Schedule',
    occurrence: 'Next execution | Next {amount} executions',
    once: 'One-time',
    startDateDescription: 'Start date of the reminder',
    daily: 'Daily',
    weeklyOn: 'Every week on',
    monthlyOn: 'Monthly on',
    yearlyOn: 'Yearly on',
    custom: 'Custom...',
    customConfig: 'Custom settings',
    repeatEvery: 'Repeat every ',
    day: 'Day | Days',
    week: 'Week | Weeks',
    month: 'Month | Months',
    year: 'Year | Years',
    repeatAt: 'Repeat on',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
    end: 'End',
    never: 'Never',
    at: 'On',
    after: 'After',
    executions: 'Executions',
    startTimeHint: 'The start time needs to be in the future.',
    startDate: 'Start date',
    recurrencePattern: 'Recurrence',
  },
  formShare: {
    title: 'Automatic Notification Process',
    description: 'You need to create an automatic notification process that allows to send this survey to a specific set of users.',
    noSurveyProcessYet: 'You did not create a survey process yet.',
    createProcess: 'Create Survey Process',
  },
  formSend: {
    reminderDescription: 'Create reminders for employees who have not taken the survey yet.',
    addReminder: 'Add a Reminder',
    timeDifferenceHeader: 'Choose the timeframe between the reminders.',
    previewTitle: 'Executions',
    reminder: 'Reminder',
    noReminders: 'There are no reminders yet.',
    sendingOptions: 'Sending options',
    participants: 'Participants',
    verify: 'Verify',
    reminders: 'Reminders',
    optional: '(optional)',
    timing: 'Start Date',
    saveAndContinue: 'Save and continue',
    configuration: 'Settings',
    channels: 'Channels',
    channelSubTitle: 'Choose how you want to distribute the survey.',
    emailSettings: 'Email Settings',
    errorsExist: 'Please fix the errors in your configuration.',
    sendToAll: 'Send to all users',
    sendToSegments: 'Choose specific segment',
  },
  userScopeTreeUserList: { userList: 'Selected users' },
  durationPicker: {
    minute: 'Minute | Minutes',
    hour: 'Hour | Hours',
    day: 'Day | Days',
  },
  timeDifference: {
    minute: 'Minute | Minutes',
    hour: 'Hour | Hours',
    day: 'Day | Days',
    afterInitialMail: 'after the initial notification',
  },
  mDatePickerInput: { afterToday: 'The date needs to be in the future.' },
  answerCard: {
    noResult: 'No response',
    outOf: 'of',
    comment: 'Comment',
  },
  fieldChartCard: { comments: 'Comments' },
  chartComments: { valueTooltip: 'The question has been answered with the value: „{value}“.' },
  formEditor: {
    editForm: 'Edit Survey',
    languageSettings: 'Languages',
    fieldFactors: 'Factors',
    questionBank: 'Question Bank',
    translationNotAvailable: 'The translation for the selected language is not available.',
    tags: 'Categories',
  },
  cascadeHeaderMenu: {
    filter: 'Filter',
    sortDescending: 'Sort descending',
    sortAscending: 'Sort ascending',
  },
  userScopeFilter: {
    addFilter: 'Add a filter',
    empty: 'There is no active filter',
  },
  accountScopeFilter: {
    all: 'All users',
    segments: 'Specific segments',
  },
  leafNode: {
    placeholder: 'Choose...',
    deleteTooltip: 'Remove Filter',
  },
  dateScope: {
    isBefore: 'Is before',
    isAfter: 'Is after',
    isBetween: 'Is within',
    isEmpty: 'Is empty',
    isNotEmpty: 'Is not empty',
    today: 'Today',
    exactDate: 'Exact date',
    nextXDays: 'Next X days',
    lastXDays: 'Last X days',
    nextNDays: 'Next {value} days',
    lastNDays: 'Last {value} days',
    thisWeek: 'This week',
    lastWeek: 'Last week',
    thisMonth: 'This month',
    lastMonth: 'Last month',
    thisQuarter: 'This quarter',
    lastQuarter: 'Last quarter',
    thisYear: 'This year',
    lastYear: 'Last year',
    allTime: 'All time',
    dateRange: 'Date range',
    xDaysFromNow: 'X days from now',
    xDaysAgo: 'X days ago',
    nDaysFromNow: '{value} days from now',
    nDaysAgo: '{value} days ago',
    beginningThisWeek: 'Beginning of this week',
    beginningLastWeek: 'Beginning of last week',
    beginningThisMonth: 'Beginning of this month',
    beginningLastMonth: 'Beginning of last month',
  },
  optionScope: {
    not: 'Is not',
    is: 'Is',
    contains: 'Contains',
    notContains: 'Does not contain',
    isEmpty: 'Is empty',
    isNotEmpty: 'Is not empty',
    placeholder: 'Choose...',
  },
  userScope: {
    staticUserLabel: 'User',
    placeholder: 'Select one or several persons',
  },
  profileLayout: {
    hoohoo: 'Hohoo! Hohoo!',
    goodEvening: 'Good evening',
    goodMorning: 'Good morning',
    hello: 'Hello',
    users: 'Employees',
  },
  profilePageContent: {
    emptySurveyTasks: 'Your inbox is empty.',
    surveyTaskHeader: 'Inbox',
  },
  surveyTaskItem: { questions: 'Questions' },
  taskItem: { actionTitle: 'Go to Survey' },
  formSendSummary: {
    questions: 'Questions',
    numberOfQuestions: '# Questions',
    privacyThreshold: 'Anonymity Threshold',
    startDate: 'Start Date',
    frequency: 'Frequency',
    firstOccurence: 'First Execution',
    reminder: 'Reminder',
    reminders: 'Reminders',
    noReminders: 'No reminders selected',
    once: 'One-Time',
    participants: 'Participants',
    userList: 'Participant List',
    questionsPerPulse: 'Questions per pulse',
    pulseSurvey: 'Pulse Survey',
    standardSurvey: 'Standard Survey',
    surveyType: 'Survey Type',
    initialEmail: 'Initial Email',
    previewEmail: 'Preview Email',
    startDateNotValid: 'The start date needs to be in the future.',
  },
  formSendSettings: {
    privacyThreshold: 'Anonymity Threshold',
    privacyThresholdDescription: 'Minimum number of users required to be in a group before scores or comments can be displayed.',
    surveyTypeHeading: 'Survey Type',
    surveyTypeSubHeading: 'Choose between standard survey or pulse survey.',
    standardSurveyTitle: 'Standard Survey',
    standardSurveyDescription: 'Surveys that are sent out once or recurrently, asking <strong>all</strong> question items at once.',
    pulseSurveyTitle: 'Pulse Survey',
    pulseSurveyDescription: 'Short, frequent pulses that ask a <strong>subset</strong> of your question items each time.',
    questionsPerPulseHeading: 'Questions per Pulse',
    questionsPerPulseDescription: 'Limit the number of questions to keep each pulse short and simple.',
    pulseCalculationHeading: 'Pulse Score Calculation',
    pulseCalculationDescription: 'Ignore old responses of a user and include questions of previous surveys (recommended).',
    pulseCalculationInfo: '<p>When using Standard Survey calculation, only the questions asked in a specific period (e.g. a month) are used to calculate  factor scores. This can lead to confusing results, because only a subset of a factor’s questions may have been asked during the last survey. In consequence, the factor calculation can depend on different questions each period, which is not the intended behaviour of a factor.</p><p>This problem is solved when Pulse Score calculation is enabled. It includes older surveys, but only uses the latest response of every user for the score calculation.</p>',
  },
  numberSelectInput: { set: 'Set' },
  fieldFactors: {
    addFactor: 'Add Factor',
    empty: 'No factors have been created yet.',
    addTitle: 'Add Factor',
    nonSelected: 'No factor is selected',
    prompt: 'Are you sure that you want to delete this factor?',
    editTitle: 'Edit Factor',
  },
  factorForm: { factorTitleLabel: 'Name ({lang})' },
  factorDetails: {
    empty: 'You did not choose any questions for this factor yet.',
    addQuestion: 'Add Question',
    addQuestionPlaceholder: 'Select question',
  },
  formSendChannels: {
    subject: 'Subject',
    body: 'Content',
    previewEmail: 'Preview Email',
    previewEmailTitle: 'Preview Email',
  },
  analyticAccess: {
    description: 'Select which users or groups have access to this dashboard.',
    notify: 'Email Notification',
    personalMessage: 'Optional: add a personal message',
    link: 'Link to this dashboard',
    linkInfo: 'Only users with access to this dashboard can use this link.',
    copyBtn: 'Copy',
    copySuccess: 'Copy successful',
    copyError: 'An error occurred during copying.',
  },
  emailPreview: { takeSurvey: 'Start Survey' },
  formResults: {
    overView: 'Overview',
    heatmap: 'Heatmap',
    timeRange: 'Time Range',
    notEnoughData: 'Not enough data to display results.',
    employees: 'Users',
    responses: 'Responses',
    participation: 'Participation',
    factors: 'Factors',
    questions: 'Questions',
    factor: 'Factor',
    question: 'Question',
    distribution: 'Distribution',
    score: 'Score',
    delta: 'Delta',
    absolute: 'Absolute',
    show: 'Show',
    factorsAndItems: 'Factors and items',
    items: 'Items',
  },
  fieldChartRow: {
    // 0, 1, n
    responses: 'Responses | Response | Responses',
  },
  formTemplateList: {
    title: 'Templates',
    createTitle: 'Create new Template',
    modalHeading: 'Create new Template',
    deleteTitle: 'Are you sure that you want to delete this template?',
  },
  createFormTemplateForm: { nameLabel: 'Choose a name for your template' },
  createFormTemplateTopBar: {
    notReleased: 'Draft',
    released: 'Released',
    release: 'Release',
    setToDraft: 'Cancel release',
    releasePrompt: 'Are you sure that you want to release this template? Afterwards it can be used within your whole organization.',
    unReleasePrompt: 'Are you sure that you want to remove the release status of this template?',
    releaseTooltip: 'When a template is released, it is visible to the whole organization.',
    draftTooltip: 'Templates in draft status can not be seen by other people within your organization. You have to release it first.',
    buttonLabel: 'Release',
  },
  newForm: {
    templatesFrom: '%s Templates',
    all: 'All',
    startFromScratch: 'Start from Scratch',
    allSurveyTemplates: 'All Survey Templates',
  },
  newFormSidebar: {
    surveyTemplates: 'Templates',
    subTitle: 'Choose a template or click <i>Start from scratch</i> to create a brand-new survey.',
  },
  newFormSidebarTemplatePreview: {
    back: 'Back to Template Library',
    useThisTemplate: 'Use this template',
    startFromScratch: 'Or start from Scratch',
  },
  newFormTemplatePreviewContent: { templatePreview: 'Template Preview' },
  questionsBank: {
    title: 'Question Bank',
    subTitle: 'Choose from all questions or pick questions from template.',
    allQuestions: 'All Questions',
    templates: 'Templates',
    questions: 'Question | Questions',
    allTemplates: 'All templates',
    addAll: 'Add All',
  },
  goalCycleEditor: {
    setup: 'Setup',
    notifications: 'Notifications',
    verify: 'Verify',
    subTitle: 'A goal cycle is used for grouping your company goals within a set timeframe. Please select the name that will be displayed to your employees.',
    verifySubTitle: 'You\'re all set! Double check the summary of your configurations and review your settings before creating the new goal cycle.',
    notificationSubTitle: 'Notify your colleagues about the start of the new goal cycle.',
    deactivate: 'Deactivate',
  },
  cycleSetup: {
    cycleNameLabel: 'Name of the cycle',
    datesHeading: 'Start and end date',
    subHeading: 'Set the start and end date of this cycle.',
  },
  goalsExplorer: {
    list: 'List',
    goalsAtlas: 'Network',
    cascade: 'Cascade',
    properties: 'Properties',
    sort: 'Sort',
    allCycles: 'All Cycles',
    explorer: 'Explorer',
    feed: 'Feed',
  },
  goal: {
    status: { unpublished: 'Draft' },
    detailHeader: {
      allGoals: 'All {title}',
      shareTitle: 'Visibility',
      custom: 'Custom',
      deletePrompt: 'Are you sure that you want to delete the selected {title}?',
      share: 'Share',
      notification: 'Notifications',
      addToDashboard: 'Add to dashboard',
      disableStatusAutoUpdate: 'Automated status update',
      statusUpdateTooltip: 'Automatically update the status based on this goal’s subitems.',
    },
    goalEditor: {
      progressMeasurement: 'Progress',
      description: 'Description',
      parent: 'Parent Alignment',
      goalCycle: 'Cycle',
      binary: 'Open/Done',
      metric: 'Metric',
      start: 'Start',
      end: 'End',
      addDescription: 'Add Description',
    },
  },
  pageHeader: { openAsPage: 'Open', share: 'Share' },
  goalDetails: {
    keyResults: 'Key Results',
    addKeyResult: 'Add Key Result',
    updates: 'Activity',
    deleteEmptyPrompt: 'This {title} is still empty. Would you like to discard it?',
    deleteOkText: 'Yes, discard it',
    deleteCancelText: 'No, keep it',
  },
  goalsFilter: {
    title: 'Name',
    description: 'Description',
    progress: 'Progress',
    parent: 'Parent alignment',
    children: 'Aligned item',
    status: 'Status',
    current: 'Current value',
    cycle: 'Cycle',
    lastUpdatedAt: 'Last updated',
  },
  goalsSorter: { sort: 'Sort' },
  sorterBuilder: {
    add: 'Add sort',
    empty: 'There is no active sorting',
  },
  sortOrder: {
    descending: 'Descending',
    ascending: 'Ascending',
  },
  metricForm: {
    binary: 'Open/Done',
    continuous: 'Start and target value',
    threshold: 'Threshold',
    alignedItems: 'Aggregation',
    none: 'None',
    alignedItemsDescription: 'Calculate progress using subordinate elements.',
    dataSourceDisabledHint: 'You need to publish the goal before you can connect to a datasource.',
    start: 'Start',
    end: 'Target',
    metric: 'Unit',
    custom: 'Custom',
    coloring: 'Type',
    notEqual: 'The start and target value must not be the same.',
    notEmpty: 'Value is required.',
    metricMaxLen: 'Length must be less than {length} characters.',
    tooltip: {
      continuous: 'Goal progress is measured based on a start and target value.',
      alignedItems: 'For goals whose progress should be measured based on the progress of sub-goals. Shown as a percentage from 0-100.',
      threshold: 'For goals that should not exceed or fall below a certain threshold.',
      binary: 'For goals that are either achieved or not achieved.',
      none: 'For goals where no progress should be measured.',
    },
  },
  goalThresholdTargetArea: {
    above: 'Stay above',
    below: 'Stay below',
    aboveOrEqual: 'Stay above or equal',
    belowOrEqual: 'Stay below or equal',
    description: 'Target area is {placement} the line',
    placement: {
      above: 'above',
      below: 'below',
      aboveOrEqual: 'above or on',
      belowOrEqual: 'below or on',
    },
  },
  profileSettings: {
    microsoftSyncStatus: {
      consentUrlError: 'Unfortunately, there was an error during communication with Microsoft.',
      readStateError: 'Unfortunately, there was an error during communication with Microsoft.',
      syncError: 'Unfortunately, there was an error during communication with Microsoft.',
      success: 'Connection was established.',
    },
    connectMicrosoftDescription: 'If you wish to login via any (additional) Microsoft account, you can connect it here. Removing or adding any connection does not affect the login behavior with {email}.',
    deleteMicrosoftEmailPrompt: 'Do you really want to remove the Microsoft connection {email}?',
    title: 'Settings',
    sectionHeading: 'Personal Info',
    setProfilePicture: 'Position and size your photo',
    photo: 'Photo',
    uploadImage: 'Upload',
    deleteImage: 'Delete',
    deleteImagePrompt: {
      content: 'Are you sure that you want to delete your photo?',
      okText: 'Yes, delete',
    },
    password: {
      sectionHeading: 'Password',
      button: 'Set a password',
      promptTitle: 'Set a new password',
      content: 'We will send an email to "{email}", that will allow you to set a new password.',
      okText: 'Send email now',
      success: 'Help is on the way! Have a look in your email inbox',
    },
    connectMicrosoft: 'Connect Microsoft Accounts',
    connectMicrosoftAction: 'Connect Microsoft Account',
    notificationsHeading: 'Notifications',
    deleteMicrosoftEmail: 'Remove',
    slackChannel: 'Channel',
    changeSlackChannel: 'Change Channel',
    activateSlack: 'Activate',
    deactivateSlack: 'Deactivate',
    activateMicrosoft: 'Activate',
    deactivateMicrosoft: 'Deactivate',
    microsoftHintTitle: 'Use Microsoft Teams integration',
    microsoftHint: 'In order to use the Microsoft Teams integration please install the Mooncamp Bot via the Microsoft Teams app store. Further information are available here:',
    slackHintTitle: 'Use Slack integration',
    slackHint: 'In order to use the Slack integration, try talking to the Slack bot! You can start a conversation with it, like with any other user.',
    msCalendar: {
      header: 'Microsoft Outlook',
      disconnectConfirm: 'Do you really want to disconnect Mooncamp from your Microsoft Outlook Calendar? All calendar events will be disconnected as well.',
      description: 'Connection established',
      action: 'Disconnect',
    },
  },
  accountSettings: {
    setCompanyPicture: 'Set a company logo',
    setProfilePicture: 'Choose a profile picture',
    nameTaken: 'The domain is already taken. Please use a different one.',
    clickToCopy: 'Click to copy',
    appIntegration: {
      heading: 'Integrations',
      subHeading: 'Connect Mooncamp with other software services',
      connectSlack: 'Connect with Slack',
      slackConnected: 'Slack connected',
      slackDisconnect: 'Disconnect Slack',
      slackDisconnectText: 'Do you really want to disconnect Slack from Mooncamp?',
      slackConnectionError: 'Error while connecting with Slack',
      notActivated: 'Slack is not installed for this account, please contact an account admin.',
    },
    title: 'Account Settings',
    addLogo: 'Add logo',
    deleteImagePrompt: {
      content: 'Are you sure that you want to delete the logo?',
      okText: 'Yes, delete',
    },
    companyInfo: {
      heading: 'Organization Info',
      nameLabel: 'Name of the Organization',
      visionLabel: 'Vision',
      signUpPathName: 'Invite link',
      signUpPathNameDescription: 'Share the {link} link to invite people to your workspace.',
      gotIt: 'Got it',
      inviteHint: 'Please share this link only after all users are created within Mooncamp. Otherwise, invited users may unintentionally create new workspaces instead of joining yours.',
    },
    features: {
      heading: 'Tools',
      subHeading: 'Please select the tools that should be available for your account.',
      surveysHeading: 'Surveys',
      surveysDescription: 'Surveys are a great tool to receive anonymous feedback from your employees.',
      activateSurveys: {
        title: 'Activate Surveys',
        content: 'Are you sure that you want to activate Surveys for all your employees?',
      },
      deactivateSurveys: {
        title: 'Deactivate Surveys',
        content: 'Are you sure that you want to deactivate Surveys for all your employees?',
      },
      activateGoals: {
        title: 'Activate Goals',
        content: 'Are you sure that you want to activate Goals for all your employees?',
      },
      deactivateGoals: {
        title: 'Deactivate Goals',
        content: 'Are you sure that you want to deactivate Goals for all your employees?',
      },
      activateUpdates: {
        title: 'Activate check-ins',
        content: 'Are you sure that you want to activate check-ins for all your employees?',
      },
      deactivateUpdates: {
        title: 'Deactivate check-ins',
        content: 'Are you sure that you want to deactivate check-ins for all your employees?',
      },
      goalsHeading: 'Goals',
      goalsDescription: 'Goals are a great way to drive transparency and alignment within your organization.',
      updatesHeading: 'Check-ins',
      updatesDescription: 'Check-ins allow you to communicate status and progress updates of your teams.',
      activate: 'Activate',
      deactivate: 'Deactivate',
    },
    reset: {
      heading: 'Reset account',
      subHeading: 'You can clear your account from existing data, e.g. the demo or testing data.',
      button: 'Open reset menu',
    },
    import: {
      heading: 'Import users',
      subheading: 'You can import users via the CSV format. The example shows you how the data needs to be encoded.',
      buttonExample: 'Download example',
      buttonSelectFile: 'Select csv file',
      buttonUpload: 'Upload',
    },
  },
  notification: {
    subject: 'Subject',
    body: 'Message',
    date: 'Sendout date',
    time: 'Time',
    receivers: 'Receivers',
    showParticipants: 'Show Receivers',
    userList: 'Receivers',
    runAt: 'Date',
  },
  goalNotification: {
    addNotification: 'Add Notification',
    deleteNotification: 'Are you sure that you want to delete this notification?',
  },
  app: {
    newVersion: 'A new version of Mooncamp is available. Please reload the page or click here to update the application.',
    foreignLogin: 'You are logged in via foreign login, be careful!',
    noAccess: {
      heading: 'No Access',
      explanation: 'You are not allowed to view this content',
      button: 'Back to main page',
    },
    featureNotAvailable: {
      heading: 'Feature not available',
      explanation: 'This feature is not available within this account. Please contact your account administrator or our customer support team for help.',
      button: 'Back to main page',
    },
    accountExpired: {
      heading: 'Your account is expired',
      explanation: 'Your account is currently deactivated. Please contact your account administrator or our customer support team (support{\'@\'}mooncamp.com) for help.',
    },
    accountInactive: {
      heading: 'Account deactivated',
      explanation: 'This account is currently deactivated. Please contact your account administrator or our customer support team (support{\'@\'}mmooncamp.com) for help.',
    },
    reload: 'Reload page',
  },
  goalUpdateEditor: {
    writeComment: 'Add a comment...',
    edited: 'edited',
    goalUpdates: 'Updates',
    viewCheckIn: 'View check-in',
    deleteUpdatePrompt: 'Are you sure you want to delete this update?',
    addReaction: 'Add reaction',
    replyInThread: 'Reply in thread',
    createdAtExplanation: 'This update was posted on {date}.',
  },
  mEditorMenuBubble: {
    turnInto: 'Turn into',
    text: 'Text',
    heading1: 'Heading 1',
    heading2: 'Heading 2',
    heading3: 'Heading 3',
    bulletedList: 'Bulleted List',
    orderedList: 'Numbered List',
    quote: 'Quote',
    code: 'Code',
    editLinkTitle: 'Edit link',
    image: 'Insert an image',
    file: 'Add a file',
  },
  networkLegend: {
    legend: 'Legend',
    belongsTo: 'Relationship',
  },
  networkControls: {
    colorByStatus: 'Color by {statusLabel}',
    progress: 'Color by progress',
  },
  propsFilter: { button: 'Properties', title: 'Properties' },
  list: {
    filter: 'Filter',
    noTitle: 'Untitled',
    ungrouped: 'Ungrouped',
    list: 'List',
    atlas: 'Network',
    cascade: 'Cascade',
    feed: 'Feed',
    tree: 'Tree',
    dashboard: 'Dashboard',
    default: 'Default View',
    addTitle: 'Add a title...',
  },
  register: {
    title: 'Get started with Mooncamp',
    landingPageTitle: 'Welcome to {companyName} on Mooncamp.',
    emailNotFound: 'We could not find the email you entered. Please contact your Mooncamp admin to make sure your email address is already registered. In case you have several work emails, you may want to try alternatives.',
    accountNotFound: 'Sorry, we could not find the specified account.',
    perk1: '14 days free trial',
    perk2: 'No credit card required',
    perk3: 'Cancel anytime',
    viaGoogle: 'Continue with Google',
    viaMicrosoft: 'Continue with Microsoft',
    continueWithEmail: 'Continue with email',
    continueWithCode: 'Continue with code',
    enterEmail: 'Enter your email address',
    codePlaceholder: 'Paste your code here',
    terms: 'By clicking \'Continue with email / Google / Microsoft\' you acknowledge and accept Mooncamp\'s {tos} and {privacyPolicy}.',
    tos: 'Terms of Service',
    privacyPolicy: 'Privacy Policy',
    hint1: 'We just sent a confirmation code to your email.',
    hint2: 'Please check your inbox.',
    codeInvalid: 'The provided code is either invalid or expired. Please try again!',
  },
  login: {
    header: {
      password: 'Log in',
      sso: 'Log in via SSO',
      ssoWithProvider: 'Log in via',
    },
    documentHeader: 'Welcome back 👋',
    callToAction: 'Log in',
    samlCallToAction: 'Continue',
    emailPasswordCombinationWrong: 'The username or password you have entered is invalid.',
    passwordLoginRestricted: 'Login via password is forbidden for this account. Please use SSO-Login or ask your Mooncamp admin how to log in.',
    viaGoogle: 'Log in via Google',
    viaMicrosoft: 'Log in via Microsoft',
    viaSaml: 'Log in via SSO',
    back: 'back',
    samlLoginStatus: {
      unableToStart: 'Unfortunately, something went wrong. The user may not exist or SSO is not configured correctly.',
      unableToVerify: 'Unfortunatly, we couldn\'t validate the SSO request. Please check your SSO configuration.',
      cookieNotPresent: 'Unfortunatly, something went wrong during the SSO log in process. Please try to login again.',
    },
  },
  loginForm: {
    passwordForget: 'Forgot password?',
    expired: 'This link is expired',
    enterPassword: 'Enter password',
    enterEmail: 'Enter your email address...',
    notAValidEmail: 'Please enter a valid email',
    googleLoginNotAllowed: 'Log in via Google is not allowed for this account',
    microsoftLoginNotAllowed: 'Log in via Microsoft is not allowed for this account',
  },
  oauthError: {
    emailNotRegistered: {
      microsoft: '😧 We couldn\'t find a Mooncamp user for the Microsoft account {email}. Why don\'t you visit {signup} to get started with Mooncamp?',
      google: '😧 We couldn\'t find a Mooncamp user for the Google account {email}. Why don\'t you visit {signup} to get started with Mooncamp?',
    },
  },
  signIn: {
    imprint: 'Imprint',
    privacy: 'Privacy Policy',
    terms: 'Terms of Service',
  },
  notFoundPage: {
    title: '{type} could not be found',
    description: 'The {type} was either deleted or the {type} ID entered does not exist',
    buttonText: 'Back to all {types}',
    buttonTextGoto: 'Go to {name}',
    checkInSchedule: 'Check-in schedule',
    profile: 'Profile',
    dashboard: 'Dashboard',
  },
  notFound: {
    title: '404',
    errorText: 'Error Code: 404',
    heading: 'Oops!',
    explanation: 'We can\'t seem to find the page you are looking for.',
    goBack: 'To main page',
  },
  goalChildrenList: {
    alignedItems: 'Aligned items',
    adaptProps: 'Properties',
  },
  groupBy: { groupBy: 'Group by' },
  props: {
    user: {
      name: 'Name',
      firstName: 'First name',
      lastName: 'Last name',
      email: 'Email',
    },
  },
  navItems: { noPageInside: 'No page inside' },
  profileInbox: { tasks: 'Tasks' },
  propertyOption: {
    nothingToSee: 'Nothing to see here.',
    separatorNotAllowed: 'Separator \'{separator}\' is not allowed.',
  },
  stackedBarChart: {
    total: 'Total',
    distribution: 'Share',
  },
  editGoalCycleModal: {
    createTitle: 'Create cycle',
    editTitle: 'Edit cycle',
    titleLabel: 'Name',
    titlePlaceholder: 'Enter a name...',
    rangeLabel: 'Date range',
    deletePromptLabel: 'Are you sure you want to delete the goal cycle?',
  },
  goalCycleSelector: {
    withoutCycle: 'Without cycle',
    all: 'All Cycles',
    noneSelected: 'No cycle selected',
    title: 'Select cycle',
    current: 'Active cycles',
    upcoming: 'Upcoming cycles',
    past: 'Past cycles',
    unassigned: 'Without cycle',
    more: 'more',
    selectPlaceholder: 'Select cycle...',
    searchPlaceholder: 'Search for cycle...',
    manageCycles: 'Manage cycles',
    emptyGroup: 'No cycles available',
  },
  accessPolicy: {
    organizationAccess: 'Organization',
    organizationDescription: 'Every user of this organization',
    creatorDescription: 'Creator of this goal',
    inviteButton: 'Add people',
    copyLink: 'Copy page link',
    changeText: 'Are you sure you want to edit your own access?',
  },
  accessPolicyForAccessGroups: {
    inviteButton: 'Add group or user',
    confirmRemoveSelfText: 'Are you sure that you want to remove yourself?',
  },
  accessPolicyScopeSelector: {
    radioScopeLabel: 'Segments',
    radioUsersLabel: 'Users',
    buttonLabel: 'Invite',
    userPlaceholder: 'Pick a user',
  },
  accessPolicyTypeSelector: {
    full: 'Full access',
    write: 'Can edit',
    comment: 'Can comment',
    read: 'Can view',
    disabled: 'No access',
    remove: 'Remove',
    fullDescription: 'Can edit and change access.',
    writeDescription: 'Can edit, but not change access.',
    commentDescription: 'Can comment and update, but not edit.',
    readDescription: 'Cannot edit, comment, or change access.',
    title: 'Select right',
  },
  updateEditor: {
    startTyping: 'Start typing...',
    writeCheckIn: 'Create check-in',
    editCheckIn: 'Edit check-in',
    viewCheckIn: 'View check-in',
    emptyTemplate: 'Empty check-in',
    placeholderHeading: 'Hit Enter to continue with an empty check-in, or choose a template.',
    addGoalsFromLastWeek: 'Add goals from your last check-in',
    addGoalsFromUserProp: 'Add goals with me as "{label}"',
  },
  resetAccountCard: {
    heading: 'Reset account',
    description: 'You can clear your account from existing data, e.g. the demo or testing data. This is recommended before you roll out Mooncamp to your organisation. </br></br>The data cannot be restored, be careful!',
    goalLabel: 'Remove all goals from this account.',
    updateLabel: 'Remove all check-ins and updates from this account.',
    demoUserLabel: 'Remove all demo users, your own users will remain.',
    submitButton: 'Reset',
    confirmText: 'Are you sure that you want to delete the selected data? The data cannot be restored.',
  },
  deletePropertyCard: {
    heading: 'Delete Property',
    description: 'Properties are used in various cases, e.g. on goals or users, but also to define survey participants or access management. If you delete a property, the data will not be recoverable. You should use this function very cautiously.',
    propertyLabel: 'Name',
    usedForLabel: 'Usage',
  },
  updateTemplate: {
    placeholder: 'Start typing...',
    prefillGoalActivities: 'Automatically add goals from check-in recipients',
    prefillGoalActivitiesHint: 'Enable this to dynamically filter and add the goals that recipients should update at the end of their check-in form.',
    where: 'Where',
    and: 'And',
    is: 'Is',
    recipient: 'Recipient',
  },
  createUpdateBtn: {
    selectTemplateTitle: 'Select schedule',
    addTemplate: 'Create template',
    deletePrompt: 'Are you sure you want to delete this template?',
    newFromScratch: 'New without schedule',
  },
  updateTemplateEditor: {
    alert: 'You are editing a template for Check-ins',
    access: 'Access',
    addUser: 'Add a person',
    creatorDescription: 'Creator of this template',
  },
  addGoalActivityBtn: {
    addUpdate: 'Add goal updates',
    newUpdate: 'New goal updates',
    referenceUpdate: 'Link past goal updates',
    description: 'Select one or more items to update.',
    title: 'Add update',
    add: 'Add {amount} selected',
  },
  importError: {
    [importValidationFailure.usersNotUnique]: 'User is not unique in file',
    [importValidationFailure.noSuchProperty]: 'No such property',
    [importValidationFailure.noSuchPropertyOption]: 'No such property option',
    [importValidationFailure.noSuchUser]: 'No such user',
    [importValidationFailure.numberParse]: 'Error while reading the number',
    [importValidationFailure.timeParse]: 'Error while reading the date',
    [importValidationFailure.userExists]: 'User already exists',
    [importValidationFailure.csvMalformed]: 'The csv is malformed',
    [importValidationFailure.invalidEmail]: 'Email not valid',
    [importValidationFailure.invalidLanguage]: 'Language is not valid',
    [importValidationFailure.nameIncomplete]: 'First or last name is missing',
    [importValidationFailure.unauthorized]: 'User belongs to a different account',
  },
  updatesList: {
    schedules: 'Schedules',
    explorer: 'Explorer',
    templates: 'Templates',
  },
  updateDetails: {
    update: 'Check-in',
    updates: 'Check-ins',
  },
  notificationEditorCard: {
    titleLabel: 'Name',
    recipients: 'Recipients',
    template: 'Template',
    pause: 'Pause',
    activate: 'Activate',
    deletePrompt: 'Are you sure, you want to delete this schedule?',
    notEditableNote: 'The schedule is only editable by {userName}.',
    reminders: 'Reminders',
    reminderHint: 'Reminders will only be sent to persons who did not submit their check-in yet.',
    templateIsRequired: 'Template is required',
  },
  userScopeTreeUserField: { persons: 'person | persons' },
  updateTemplateSelector: {
    noTemplateSelected: 'Select a template',
    title: 'Select template',
  },
  notificationItem: { paused: 'Paused' },
  heatmap: {
    groupBy: 'Group by',
    all: 'Overall Score',
    ungrouped: 'Ungrouped questions',
    showAll: 'Show all',
  },
  searchDialog: {
    results: 'results',
    recentlyVisited: 'Recently visited',
  },
  timeRangeSelector: {
    thisMonth: 'This month',
    lastMonth: 'Last month',
    lastXMonth: 'Last {value} months',
    custom: 'Custom',
    title: 'Pick time range',
    customTitle: 'Custom',
  },
  updateFeedItem: {
    deletePrompt: 'Are you sure you want to delete your check-in?',
    edited: 'edited',
    addReaction: 'Add reaction',
    addComment: 'Comment',
    viaCheckin: 'via check-in',
  },
  reactionItem: {
    title: '{names} {have} reacted with {emoji}',
    have: 'has | have',
    you: 'You',
    have2ndFormSingular: 'have',
  },
  listFilter: {
    title: 'Filter',
    limitFilterToFirstLevel: 'Limit filter to first level',
    filterOptions: 'Filter options',
    allLevels: 'Apply to all levels',
    firstLevel: 'Apply to first level',
  },
  listSorter: { title: 'Sort' },
  shareDropdown: { shareTitle: 'Share' },
  reactionList: { addEmoji: 'Add emoji' },
  commentList: {
    writeComment: 'Add a comment...',
    showMore: 'Show more comments',
    comments: 'Comment | Comments',
    latestComment: 'Last comment {latestComment}',
    viewThread: 'View comments',
  },
  commentItem: { deletePrompt: 'Are you sure you want to delete your comment?' },
  mLangPicker: { selectLanguage: 'Select language' },
  notificationFeed: {
    goalPropertyValueReferenced: '{actor} assigned you to {propertyTitle} of {goalTitle}',
    goalComment: '{actor} commented on {goalTitle}',
    goalUpdateMention: '{actor} mentioned you in {goalTitle}',
    goalUpdate: '{actor} updated {goalTitle}',
    updateMention: '{actor} mentioned you in a {checkInTitle}',
    updateComment: '{actor} commented on {checkInTitle}',
    goalUpdateComment: '{actor} commented on {goalTitle}',
    survey: 'New survey {formTitle}',
    updateNotification: 'Check-In {checkinTitle}',
    meetingComment: '{actor} commented on {title}',
    meetingMention: '{actor} mentioned you in {title}',
    feedbackInquiry: '{actor} requested feedback from you',
    feedbackDeclined: '{actor} declined your feedback request',
    feedbackReceived: '{actor} gave you feedback',
    reply: 'Reply',
    view: 'View',
    noItems: {
      notifications: 'No updates available.',
      tasks: '🎉 No open tasks',
      all: 'No entries.',
    },
    action: {
      survey: 'Fill out survey',
      updateNotification: 'Perform Check-In',
    },
    actionCompleted: {
      survey: 'Survey completed',
      updateNotification: 'Check-In completed',
      feedbackInquiry: 'Feedback given',
    },
    tabNotifications: 'Notifications',
    tabTasks: 'Tasks',
    declineFeedbackInquiry: 'Decline request',
    giveFeedback: 'Give feedback',
    feedbackStatusDeclined: 'Request declined',
  },
  spaceDetails: {
    owners: 'Owners',
    description: 'Description',
    addDescription: 'Add description',
    addADescription: 'Add a description...',
    deleteMessage: 'Are you sure that you want to delete "{optionTitle}"?',
  },
  savedViewSelector: {
    addView: 'Add a view',
    addViewPlaceholder: 'View name',
    cascadeDescription: 'A table view to quickly display and edit hierarchical data',
    listDescription: 'A simple flat list view for easy data browsing',
    feedDescription: 'Ideal for screening the content directly',
    atlasDescription: 'A visual web of interrelated data like teams, goals and initiatives',
    treeDescription: 'A tree view to explore your goals and data in a hierarchical layout',
    selectView: 'Select view',
    createView: 'Create a view',
    editView: 'Edit view',
    deletePrompt: 'Are you sure that you want to delete this view?',
    setPublicPrompt: 'Are you sure that you want to make this view public? It will be visible to the whole organisation.',
    setPrivatePrompt: 'Are you sure that you want to make this view private? It will only be visible to you afterwards.',
    setPrivatePromptNoAccess: 'Are you sure that you want to make this view private? It will only be visible to {user} afterwards.',
    publish: 'Visible to all users',
    defaultDescription: 'Default view of dashboards',
    saveForEveryone: 'Save for everyone',
    canNotSaveNote: 'The changes to this view can not be saved for everyone, because you do not have enough rights within this space.',
    canNotSave: 'Not saved',
    saveButtonTooltipPublic: 'Saves changes to view for everyone',
    saveButtonTooltipPrivate: 'Save changes to view',
    reset: 'Reset',
    more: 'more...',
  },
  favoriteButton: {
    favorite: 'Favorite',
    favorited: 'Favorited',
    title: 'Favorites',
  },
  favoriteEditor: {
    namePlaceholder: 'Name',
    delete: 'Remove from favorites',
    create: 'Add to favorites',
  },
  mention: {
    subheading: 'Pick a person',
    noUsers: 'No users found',
  },
  feedEntryTitle: {
    checkIn: '{creator}\'s Check-In',
    genericPropertyLabel: 'a property',
  },
  goalListGroup: { disabledDragging: 'You cannot edit the order since you are missing edit rights for: {goals}' },
  createSpaceCard: {
    placeholder: 'Enter name...',
    dropdownTitle: 'Create space',
  },
  dataSource: {
    nonSelected: 'None selected',
    untitled: 'Untitled',
    empty: 'There is no established connection yet. Create a new connection to auto-update your {title}.',
    connectToDataSource: 'Connect to data source',
    selectDataSource: 'Select data connection',
    connectedTo: 'Connected to {source}',
    sheets: 'Google Sheets',
    asana: 'Asana',
    salesforce: 'Salesforce',
    jira: 'Jira',
    excel: 'Excel Online',
    hubspot: 'Hubspot',
    syncNow: 'Sync now',
    deleteConnectionPrompt: 'Are you sure you want to delete the sync connection?',
    successfullySynced: 'Data successfully synced',
    title: 'Data source',
    alreadySet: 'The {title} is already connected to a non-shared data source. You can however choose to overwrite this data source.',
    overwriteAction: 'Overwrite data source',
    deleteAction: 'Delete connection',
    alwaysSync: 'Always sync',
    alwaysSyncHint: 'When activated, updates are also created if the value has not changed since the last update.',
  },
  jiraEditor: {
    serverLabel: 'Server',
    clientId: 'Client ID',
    clientSecret: 'Client secret',
    tokenLabel: 'Token',
    tokenHint: 'You can create API tokens from {account}, as described {description}.',
    accountHint: 'your Atlassian account',
    descriptionHint: 'here',
    authenticationType: {
      apiToken: 'Cloud',
      oauth: 'Self-managed (OAuth)',
      oauthv2: 'Self-managed (OAuth 2.0)',
    },
    signIn: 'Sign in with Jira',
    signInAgain: 'Refresh Sign-in',
    tutorial: 'Learn more about connecting to Jira',
    helpLink: '@:{\'general.helpCenterBaseUrl\'}/jira-integration',
    faultyCredentials: 'The credentials are not correct',
    emailHint: 'The email you use to sign into your jira account',
    serverHint: 'The account URL of your Jira account, for example "acme.atlassian.net"',
    serverPlaceholder: 'e.g. acme.atlassian.net',
    oauthServerPlaceholder: 'e.g. https://jira.example.com',
    oauthv2HelpLink: 'https://confluence.atlassian.com/adminjiraserver/link-to-other-applications-938846918.html',
    oauthv2Help: 'Learn more about connecting via OAuth 2.0',
  },
  dataSourceEditor: {
    connect: 'Sign in with {provider}',
    connectHint: 'Create a new connection to {provider} to automatically sync data between Mooncamp and {provider}.',
    refreshHint: 'Refresh your connection to {provider} to automatically sync data between Mooncamp and {provider}.',
    successfullyConnected: 'Successfully connected',
    shareHintDocs: 'You can share this connection. Be aware that doing so allows all members of this Mooncamp account to see all documents connected to the data source, even if the user doesn\'t naturally have access to the document.',
    shareHint: 'You can share this connection. Be aware that doing so allows all members of this Mooncamp account to access the available metrics.',
    nameLabel: 'Connection name',
    sharedLabel: 'Share connection with all users',
  },
  dataSourceItem: {
    deletePrompt: 'Are you sure you want to delete this data connection? There might be {title}, which use this connection and will consequently no longer be synchronized.',
    editTitle: {
      sheets: 'Connect to Google Sheets',
      jira: 'Connect to Jira',
      excel: 'Connect to Excel Online',
      hubspot: 'Connect to Hubspot',
      asana: 'Connect to Asana',
      msCalendar: 'Connect to Outlook Calendar',
      salesforce: 'Connect to Salesforce',
    },
    refreshAction: 'Refresh connection',
    refreshDescription: 'Expired connections require renewed consent. Click the button to refresh the connection.',
    connectionExpired: 'Connection expired',
    incompleteDescription: 'The connection has not been established yet.',
    establishAction: 'Complete connection',
    creatorDescription: 'Creator of this data connection',
  },
  spreadsheetForm: {
    help: 'Learn about the Google Sheets / Excel Online integration.',
    helpLink: '@:{\'general.helpCenterBaseUrl\'}/google-sheetsexcel-online-integration',
    selectDocumentLabel: 'Document',
    selectSheetLabel: 'Sheet',
    selectColumnLabel: 'Column',
    selectRowLabel: 'Row',
    notANumberHint: 'The selected cell does not contain a number.',
    emptyHint: 'The selected cell is empty.',
    viewFile: 'View file',
    error: {
      excel: 'There was an error during the communication with Excel Online. Please try connecting using a different data source.',
      sheets: 'There was an error during the communication with Google Sheets. Please try connecting using a different data source.',
    },
    selectFile: 'Select document',
    changeFile: 'Change selection',
    excelHint: 'Data successfully synced. Be aware, that changes in Excel sometimes take a few seconds until they are available to Mooncamp.',
    gapiError: 'There was an error while loading the Google API extension.',
  },
  jiraForm: {
    help: 'Learn about the Jira integration.',
    helpLink: '@:{\'general.helpCenterBaseUrl\'}/jira-integration',
    onlyDoneLabel: 'Count only done tickets',
    jqlLabel: 'JQL',
    trackMetricLabel: 'Track Progress by',
    openInJira: 'Open in Jira',
    jqlMalformed: 'the JQL-Query has errors.',
    connectionNotOk: 'We couldn\'t establish a connection to Jira.',
    operationModes: {
      overallProgress: {
        label: 'Overall progress',
        description: 'Measure overall progress (%) of certain issues vs. total issues as specified below.',
      },
      countOfIssues: { label: 'Count of issues', description: 'Returns the count of the issues specified below.' },
    },
    learnJql: 'Learn more about JQL',
    learnJqlLink: 'https://www.atlassian.com/software/jira/guides/expand-jira/jql',
  },
  jiraJqlEditor: { placeholder: 'Insert JQL query...' },
  jiraCountOfIssues: { label: 'JQL query' },
  jiraOverallProgress: {
    countIssues: 'Count issues',
    totalIssues: 'Total issues',
  },
  goalProgress: {
    dataConnectionTitle: 'Data connection',
    autoUpdateDescription: 'Auto-updated through {dataSourceType}',
  },
  dataSourceSummary: {
    connectedBy: 'Connected by',
    private: 'Private data source',
    syncError: 'Connection error',
  },
  googleSheetsSummary: {
    column: 'Column',
    row: 'Row',
    sheetName: 'Sheet',
  },
  jiraSummary: {
    openInJira: 'Open in Jira',
    operationMode: 'Operation Mode',
    empty: 'any Jira Issue',
  },
  hubspotSummary: {
    trackMetric: 'Track Metric',
    startDate: 'Start',
    endDate: 'End',
  },
  asanaSummary: {
    project: 'Project',
    assignedTo: 'Assigned To',
    sections: 'Task Status',
    task: 'Task',
    operationMode: 'Operation Mode',
  },
  salesforceSummary: {
    report: 'Report',
    field: 'Field',
  },
  dataSourcePicker: {
    title: 'Data sources',
    integrations: 'Integrations',
    defaultName: {
      sheets: 'Google Sheets Data Source',
      jira: 'Jira Data Source',
      excel: 'Excel Online Data Source',
      hubspot: 'Hubspot Data Source',
      asana: 'Asana Data Source',
      msCalendar: 'Outlook Calendar Source',
      salesforce: 'Salesforce Data Source',
    },
  },
  salesforce: {
    selectReportLabel: 'Report',
    selectFieldLabel: 'Field',
    error: {
      [salesforceError.unknown]: 'There was an error during the communication with Salesforce.',
      [salesforceError.reportNotFound]: 'The Salesforce report could not be found.',
      [salesforceError.connectionError]: 'There was an error during the communication with Salesforce. Please try connecting using a different data source.',
    },
    syncError: 'An error occurred while communicating with Salesforce.',
  },
  asanaForm: {
    help: 'Learn about the Asana integration.',
    helpLink: '@:{\'general.helpCenterBaseUrl\'}/asana-integration',
    selectProjectLabel: 'Project',
    selectWorkspaceLabel: 'Workspace',
    selectAssignedTo: 'Assigned to (optional)',
    selectSection: 'Progress measured by',
    sectionOrStatus: 'Section or status (optional)',
    completed: 'Completed',
    countMeasuredBy: 'Count measured by',
    completionMeasuredBy: 'Completed if',
    singleTask: 'Connect single task',
    operationModes: {
      overallProgress: {
        label: 'Overall progress',
        description: 'Measure overall progress (%) of certain tasks vs. total tasks as specified below.',
      },
      countOfTasks: { label: 'Count of tasks', description: 'Returns the count of the tasks specified below.' },
      singleTask: { label: 'Single task', description: 'Track the completion of a single task.' },
    },
    overallLabel: 'Total tasks as measured by',
    emptyPlaceholder: 'Any',
    selectTask: 'Select a task',
    task: 'Task',
    all: 'All',
    and: 'And',
    asanaCompletionStatus: {
      completed: 'Completed',
      incomplete: 'Incomplete',
      any: 'Any',
    },
    countTasks: 'Selected tasks',
    countTasksHint: 'Number of tasks that determine progress.',
    totalTasks: 'Total tasks',
    error: {
      [asanaError.projectNotFound]: 'The selected asana project could not be found.',
      [asanaError.unknown]: 'There was an error during the communication with Asana.',
      taskNotFound: 'The selected asana task could not be found.',
      userNotFound: 'The selected asana user could not be found.',
      sectionNotFound: 'The selected asana section could not be found.',
    },
  },
  asanaSectionForm: {
    section: 'Section',
    status: 'Status',
    or: 'Or',
    addAction: 'Add Section',
    any: 'Any',
  },
  asanaTaskSelectionTrigger: {
    assignedTo: 'Assigned to',
    allTasks: 'All tasks',
    any: 'Any',
    criteriaSelected: 'criteria selected',
  },
  hubspotForm: {
    help: 'Learn about the Hubspot integration.',
    helpLink: '@:{\'general.helpCenterBaseUrl\'}/hubspot-integration',
    trackMetric: {
      numberOfRawViews: 'Number of Raw Views',
      numberOfVisits: 'Number of Visits',
      numberOfVisitors: 'Number of Visitors',
      numberOfLeads: 'Number of Leads',
      numberOfContacts: 'Number of Contacts',
      numberOfSubscribers: 'Number of Subscribers',
      numberOfMqls: 'Number of MQLs',
      numberOfSqls: 'Number of SQLs',
      numberOfOpportunities: 'Number of Opportunities',
      numberOfCustomers: 'Number of Customers',
      averagePageViewsPerSession: 'Average Page Views per Session',
      bounceRate: '% Bounce Rate',
      timePerSession: 'Time Per Session (in Seconds)',
      newSessions: '% New Sessions',
      sessionToContact: '% Session to Contact',
      contactToCustomer: '% Contact to Customer',
    },
    selectTrackMetric: 'Track Metric',
    selectTimeRange: 'Select Time Range (optional)',
    startPlaceholder: 'Start',
    endPlaceholder: 'End',
  },
  onboarding: {
    personalInfosTitle: 'Welcome to Mooncamp',
    personalInfosSubTitle: 'Please tell us a bit about yourself.',
    accountInfosTitle: 'Create your company workspace',
    accountInfosSubTitle: 'Please fill in some details about your company.',
    moduleSelectionTitle: 'Which tools are you planning to use?',
    moduleSelectionSubTitle: 'We’ll adjust your onboarding guide accordingly.',
    continue: 'Continue',
    loggedInAs: 'You\'re currently logged in as {email}. If you don\'t intend to set up a new account, you can {link}',
    logInAsDifferentUser: 'log in as a different user',
    redeemCouponTitle: 'Apply coupon',
    redeemCouponSubTitle: 'Please fill in your coupon code',
    launchMooncamp: 'Launch Mooncamp',
  },
  personalInfos: {
    uploadImage: 'Add a photo',
    firstNameLabel: 'First name',
    lastNameLabel: 'Last name',
    passwordLabel: 'Password',
  },
  accountInfos: {
    uploadImage: 'Add a logo',
    companyNameLabel: 'Company name',
    companySize: 'Number of employees',
  },
  moduleSelectionInfos: {
    okrTitle: 'OKRs',
    okrDescription: 'Create alignment, transparency and focus.',
    surveysTitle: 'Surveys',
    surveysDescription: 'Get feedback from your employees and act.',
    creatingYourAccount: 'Creating your account...',
  },
  msTeamsTeamDetails: {
    hint: 'You are currently not in any team.',
    employeePage: 'You can change your team settings on the {employeePage}.',
    employeePageLinkDesc: 'Employee Page',
    adminRequest: 'Please ask a Mooncamp Admin to assign you to your team.',
    myTeams: 'Teams',
  },
  msTeams: {
    connectionEstablished: 'Connection to the Teams-Bot established.',
    connectionError: 'Error connecting to the Teams-Bot.',
  },
  onboardingGuide: {
    inviteUserModal: 'Invite users',

    gettingStarted: {
      title: 'Getting Started',
      introVideo: { title: 'Mooncamp 101 video' },
      appTour: { title: 'Take a quick app tour' },
      whyMooncampIsUnique: { title: 'Why Mooncamp is unique' },
      videoTutorials: { title: 'Watch our video tutorials' },
      okrResources: { title: 'Check out our OKR resources' },
    },
    accountSetupNew: {
      title: 'Account Setup',
      removeDemoData: { title: 'Remove demo data' },
      messengers: { title: 'Connect to Slack or MS Teams' },
      inviteTeamMembers: { title: 'Invite your team members' },
      checkins: { title: 'Set up check-ins for your teams' },
    },
    markAllAsDone: 'Mark all as done',
  },
  inviteUsers: {
    inviteUsersLabel: 'Email addresses',
    inviteUsersPlaceholder: 'Enter email addresses separated by comma, semicolon or space.',
    sendInvite: 'Send invites',
    recognizedEmailsLabel: 'Recognized email addresses',
    inviteNow: 'Send invites',
    oneEmailAlreadyExists: 'One or more of the entered email addresses have already been registered in our system. Contact our customer support to resolve this issue.',
    success: 'Invites successfully sent',
  },
  trialBox: {
    // 0,1,n
    expired: 'Your trial period has expired.',
    today: 'Your trial ends today!',
    tomorrow: 'Your trial ends tomorrow!',
    remaining: 'There are {amount} remaining in your free trial.',
    days: '{amount} days',
    button: 'Upgrade',
    bookDemo: 'Book a demo',
    demoLink: '@:{\'general.websiteBaseUrl\'}/contact/',
  },
  subscriptionInfo: {
    heading: 'Start subscription',
    headingExpired: 'Looks like your trial period has expired 😱',
    text1: 'If you want to subscribe to one of our plans, extend your trial period or have any question regarding Mooncamp\'s software, please contact us at',
    text2: 'The Mooncamp Team',
  },
  time: {
    weeksAgo: 'one week ago | {count} weeks ago',
    date: '{dateTime}',
    daysAgo: 'yesterday | {count} days ago',
    hoursAgo: 'one hour ago | {count} hours ago',
    minutesAgo: 'a minute ago | {count} minutes ago',
  },
  notificationComment: { reply: 'Reply' },
  goalSettings: {
    title: 'Goal Settings',
    advanced: {
      title: 'Advanced settings',
      multiGoalAlignment: {
        title: 'Multi-alignment of goals',
        subTitle: 'Align a goal with two or more parent goals.',
      },
      publishedViews: {
        title: 'Public links to views',
        subTitle: 'Create public views that are visible to everyone.',
      },
      weightedGoals: {
        title: 'Weighted goals',
        subTitle: 'Give weight to goals to reflect importance in progress calculation',
      },
      canGroupByUser: {
        title: 'Employee grouping for Insights',
        subTitle: 'Enable or disable individual user breakdowns (e.g., Progress Dashboard)',
      },
    },
    accessRight: {
      title: 'Default access',
      description: 'You can set a default access rule that is applied when users create new goals.',
      addUserText: 'Add group or user',
    },
    insightRanges: {
      title: 'Progress Indicator Thresholds',
      description: 'Adjust the thresholds to define what constitutes good, medium, and bad progress on your OKRs. These settings will influence how progress is visually represented on the progress dashboard and the goal network view.',
    },
    featureName: {
      title: 'Display name',
      subTitle: 'If your organization uses a different term for goals, you can change it here',
      defaultSingular: 'Goal',
      defaultPlural: 'Goals',
      labelSingular: 'Singular',
      labelPlural: 'Plural',
    },
    confidence: {
      title: 'Confidence level',
      subTitle: 'You can choose to display the status of a goal based on a traffic light system or a confidence level from 0 to 10. The confidence level describes how confident the owner of the goal is that it will be achieved at the end of a cycle.',
    },
  },
  spaceNavMenu: {
    createTitle: 'Create {title}',
    editTitle: 'Edit {title}',
    createSubspace: 'Create subgroup',
    move: 'Move',
    pin: 'Pin to sidebar',
    unpin: 'Unpin from sidebar',
    settings: '{title} settings',
  },
  moveSpaceCard: {
    dropdownTitle: 'Move group',
    move: 'Move',
    placeholder: 'No parent selected',
  },
  goalInsights: {
    user: 'Employee',
    groupBy: 'Group by',
  },
  goalInsightsTable: {
    user: 'Employee',
    publicGoals: 'Public {title}',
    lastUpdate: 'Last update',
    goalsByStatus: '{title} by {statusType}',
    progress: 'Progress',
    confidence: 'Avg. score',
    confidenceDistribution: '{title} distribution',
    progressExpectedValueAbbreviation: 'EV',
    progressExpectedValueTooltip: 'Expected value',
    subOfSubGoals: 'Sum of direct goals and subgoals',
  },
  goalInsightsPerformance: {
    statusActive: 'Active',
    statusClosed: 'Closed',
    overview: 'Overview',
    overallProgress: 'Overall progress',
    averageScore: 'Average score',
    goalsAtRisk: '{title} at risk',
    totalNumberOfGoals: 'Total number of {title}',
    goalsTotal: 'Total:',
    goalsByType: '{title} by type',
    goalsByProgress: '{title} by progress: {label}',
    goalsByStatus: '{title} by {statusLabel}: {label}',
    noGoalType: 'No type',
    progress: 'Progress',
    progressChart: 'Average progress',
    progressDistribution: 'Progress distribution',
    statusChart: '{title} over time',
    statusDistribution: '{title} distribution',
    confidenceChart: '{title} over time',
    confidenceChartHint: 'In the progress dashboard chart, your customized progress thresholds (ranging from 0% to 100%) are condensed into a simplified scale from 0 to 10.',
    breakdown: 'Breakdown',
    breakdownTable: '{title} progress grouped by {groupBy}',
  },
  goalInsightsHealth: {
    goToSettings: 'Go to settings',
    infobox: {
      text: 'The Health dashboard is based on the rules defined in the settings area under “Types and rules”. The dashboard allows you to quickly identify and solve any issues with your goals, like missing property values, invalid parent goals, or measurement types.',
      learnMore: 'Learn more',
      helpCenterLink: '@:{\'general.helpCenterBaseUrl\'}/health-dashboard',
    },
    groups: {
      overview: 'Overview',
      byGoalType: 'Health check by goal type',
      breakdown: 'Breakdown',
    },
    overview: {
      parentType: 'Goals with invalid parent goal',
      propertiesRequired: 'Goals with missing property values',
      measurementTypes: 'Goals with invalid measurement types',
    },
    measurementTypes: {
      cardTitle: 'Goals with invalid vs. valid measurement types',
      cardHelp: 'This report is based on the allowed measurement types which can be configured under {settingsTitle}.',
      dialogTitle: '{goalType} - Measurement type - {label}',
    },
    automated: {
      cardTitle: 'Automated vs. manual',
      cardHelp: 'Distribution of goals that are updated manually vs. automatically via data integrations.',
      labelAutomated: 'Automated',
      labelManual: 'Manual',
      dialogTitle: '{goalType} - Updates - {label}',
    },
    parentType: {
      cardTitle: 'Goals with invalid vs. valid parent goal',
      cardHelp: 'This report is based on the rules defined for the parent alignment property which can be configured under {settingsTitle}.',
      labelWrong: 'Invalid parent goal',
      labelRight: 'Valid parent goal',
      dialogTitle: '{goalType} - Alignment - {label}',
    },
    propertiesRequired: {
      cardTitle: 'Goals with missing vs. existing property values',
      cardHelp: 'This report shows the number of goals with missing vs. existing property values. The report is based on the properties that are marked as required. They can be configured under {settingsTitle}.',
      labelWrong: 'Missing',
      labelRight: 'Existing',
      dialogTitle: '{goalType} - Property values - {label}',
    },
    breakdown: {
      cardTitle: 'Health check grouped by {groupBy}',
      table: {
        publicGoals: 'Number of goals',
        invalidParents: 'Invalid parent goal',
        invalidProperties: 'Missing property values',
        invalidMeasurementTypes: 'Invalid measurement types',
      },
    },
  },
  singleMetricCard: {
    expectedValue: 'expected value: {expectedValue}',
    expectedValueRange: 'Values between {from} and {to}',
  },
  accessGroupForm: {
    defaultGroupTitle: 'Member',
    defaultGroupDescription: 'This access group includes all users of this account.',
    accountTitle: 'Account administration',
    memberTitle: 'Group members',
    accessGroupsTitle: 'Access rights',
    goalsTitle: 'Goals',
    updatesTitle: 'Check-ins',
    usersTitle: 'Users',
    formsTitle: 'Surveys',
    groupNameLabel: 'Name',
    accountWriteAccess: 'Management of  account settings (Admin)',
    accessGroupWriteAccessLabel: 'Management of access groups (Admin)',
    formWriteAccessLabel: 'Management of surveys',
    formTemplateWriteAccessLabel: 'Management of survey templates',
    userWriteAccessLabel: 'Management of users',
    propertyWriteAccessLabel: 'Management of properties',
    spaceWriteAccessLabel: 'Management of teams',
    publicSavedViewAccessLabel: 'Management of public views',
    referenceForeignEntityLabel: {
      tooltip: 'For team and user properties on goals, allow users to choose from all workspace teams and users (not just their teams and teams\' members)',
      label: 'Can choose from all teams and their members',
    },
    createNewAccessGroup: 'Create new access group',
    updateAccessGroupTitle: 'Edit access group',
    accessGroupsHeading: 'Access rights',
    formsHeading: 'Surveys',
    userHeading: 'Users',
    selectUserHeading: 'Select users',
    userLabel: 'Select users',
    deletePrompt: 'Are you sure that you want to delete this access group?',
    goalCycleWriteAccessLabel: 'Management of goal settings and cycles',
    createGoalAccessLabel: 'Can create goals',
    goalSuperAccessLabel: 'Unrestricted access to all goals',
    goalDashboardViewAccessLabel: 'Can view progress and health dashboards',
    adminWarning: 'The admin group needs at least one group member.',
    meetingsAccess: 'Create and view meetings',
    meetingsSuperUserAccess: 'Unrestricted read access to all meetings',
    meetingDashboardAccess: 'Can view dashboards',
    instantFeedbackTemplateAccess: 'Management of feedback templates',
    updateAdminAccess: 'Management of check-in templates and settings',
    createDashboardAccess: 'Can create dashboards',
    dashboardSuperAccess: 'Unrestricted access to all dashboards',
  },
  accessGroupList: {
    nameLabel: 'Name',
    numberOfMembersLabel: 'Group members',
    actions: 'Actions',
    editGroup: 'Edit group',
  },
  breadcrumbs: { items: 'Items' },
  goalContextMenu: {
    cannotDelete: 'You don\'t have the rights to edit this {title}.',
    duplicateToCycle: 'Duplicate to cycle',
    addBeneath: 'Create subgoal',
    cannotAddToDashboard: 'Only goals with continuous progress measurement can be added to a dashboard.',
  },
  contextMenu: { editProperty: 'Edit property' },
  goalsContextMenu: {
    cannotDeleteMultiple: 'You don\'t have the rights to edit these {title}.',
    deleteHint: 'For some {title} you have no permissions to edit them.',
    changeAccess: 'Edit access rights',
    changeAccessHint: 'You are not allowed to edit the access rights of all selected {title}.',
    addToDashboard: 'Add to dashboard',
    addToDashboardHint: 'Not all selected {title} are allowed on dashboards.',
  },
  goalPickerV2: {
    title: 'Pick a {title}',
    selected: 'selected',
  },
  goalActivityPicker: { title: 'Pick updates' },
  compareToPrevious: {
    title: 'Compare to',
    customDate: 'Custom date',
    pickADate: 'Pick a date',
    oneDayAgo: 'One day ago',
    oneWeekAgo: 'One week ago',
    oneMonthAgo: 'One month ago',
    none: 'None',
  },
  colorPicker: {
    title: 'Pick a color',
    default: 'Light Grey',
    grey: 'Grey',
    brown: 'Brown',
    orange: 'Orange',
    yellow: 'Yellow',
    green: 'Green',
    blue: 'Blue',
    purple: 'Purple',
    pink: 'Pink',
    red: 'Red',
  },
  savedViewInfo: {
    filterLabel: 'Filters for',
    propertyLabel: 'Properties for',
    sortLabel: 'Sort for',
    privateTooltip: 'This is a private view that is only visible to you.',
    disabledInfo: 'You don\'t have sufficient rights to edit public views.',
  },
  goalsCascadeTable: {
    emptyGoalList: 'No {title} available',
    addTooltip: 'Click to create a subgoal',
    moreTooltip: 'Click to see actions',
  },
  editorImage: { original: 'Show original', addAnImage: 'Select an image' },
  editorFile: { addAFile: 'Select a file' },
  fileUpload: {
    fileTooBig: 'File is too big (max. 20 MB)',
    featureDisabled: 'File upload is disabled for this account.',
  },
  myNotifications: {
    title: 'Notifications',
    channelHeading: 'Channels',
    msTeamsHint: 'Receive notifications via Microsoft Teams.',
    slackHint: 'Receive notifications via Slack.',
    emailHint: 'Receive notifications via email.',
    emailTitle: 'Email',
    notificationTypes: 'Messages',
    directMessageTitle: 'Direct messages',
    directMessageSubTitle: 'Get a notification when someone replies to your comments or updates, or when you are mentioned with {\'@\'}username.',
    checkinReminderTitle: 'Check-in reminders',
    checkinReminderSubTitle: 'Get a notification when it is time to make a check-in.',
    goalSubscriptionTitle: 'Subscribed goals',
    goalSubscriptionSubTitle: 'Get a notification when there are new updates for your subscribed goals',
    goalReferenceTitle: 'Mention in a goal',
    goalReferenceSubTitle: 'Get a notification when you are mentioned in a goal',
    disconnect: 'Disconnect',
    confirmMicrosoftDisconnect: 'Do you really want to disconnect Mooncamp from Microsoft Teams?',
  },
  checkinOnboardingModal: {
    onboardingTitle: 'What are check-ins?',
    onboardingDescription: 'A regular check-in is the most effective factor of every OKR process. It is a simple tool to keep OKRs top of mind, let everyone see what others are working on, discuss progress and new insights and to uncover where mutual support is needed. It ensures continuous feedback and learning as well as a high level of participation and responsibility in teams.',
    createCheckin: 'Take a quick tour',
    explore: 'Explore on your own',
  },
  demoDataModal: {
    title: 'Welcome to Mooncamp 👋',
    description: 'Get to know Mooncamp in just <b>5 minutes</b>:',
    takeTour: 'Take a quick tour',
    skipTour: 'Skip tour',
    introVideo: 'https://www.loom.com/embed/05d0d570bca4465e82d711f7e2ac4ca6',
  },
  exportButton: { buttonText: 'Export CSV' },
  meeting: {
    date: 'Date',
    participants: 'Participants',
  },
  meetingPropsEditor: {
    calendarIntegration: {
      msConnected: 'Connected with Outlook Calendar',
      open: 'Open in Microsoft Outlook',
      disconnect: 'Disconnect',
      deleteConnectionPrompt: 'Are you sure you want to delete the connection?',
    },
  },
  propertyApplication: {
    meeting: 'Meetings',
    user: 'Users',
    goal: 'Goals',
    update: 'Check-ins',
    space: 'Spaces',
  },
  meetingPage: {
    startTyping: 'Start typing...',
    addPrivateNote: 'Add private note',
    agendaTitle: 'Agenda',
    addAgenda: 'Add agenda',
    addComment: 'Add a comment',
    templateHint: 'You are editing a template for',
    placeholderHeading: 'Hit Enter to continue with an empty page, or choose a template.',
    emptyPage: 'This document is empty',
  },
  privateNote: {
    hint: 'Only visible to you',
    hide: 'Hide',
    title: 'Show private note',
  },
  collection: { show: 'Show {title}' },
  mContextMenu: {
    paragraph: {
      title: 'Text',
      subTitle: 'Just start writing with plain text',
    },
    heading1: {
      title: 'Heading 1',
      subTitle: 'Big heading.',
    },
    heading2: {
      title: 'Heading 2',
      subTitle: 'Medium heading.',
    },
    heading3: {
      title: 'Heading 3',
      subTitle: 'Small heading.',
    },
    link: {
      title: 'Link website',
      subTitle: 'Save a link to a website.',
    },
    blockquote: {
      title: 'Quote',
      subTitle: 'Insert a quote block.',
    },
    image: {
      title: 'Image',
      subTitle: 'Upload an image.',
    },
    file: {
      title: 'File',
      subTitle: 'Upload a file.',
    },
    bulletList: {
      title: 'Bulleted List',
      subTitle: 'Insert a list with bullet points.',
    },
    orderedList: {
      title: 'Ordered List',
      subTitle: 'Insert a numbered list.',
    },
    codeBlock: {
      title: 'Code',
      subTitle: 'Insert a code block.',
    },
    mention: {
      title: 'Mention',
      subTitle: 'Mention a workspace member.',
    },
    goalMention: {
      title: 'Link to {plural}',
      subTitle: 'Create a link to an existing {singular}.',
    },
    todoList: {
      title: 'To-Do List',
      subTitle: 'Track tasks with to-do lists.',
    },
    noItems: 'No block found.',
  },
  mFloatingMenu: { backslashHint: 'Type \'/\' for actions' },
  type: {
    meeting: 'Meeting',
    goal: 'Goal',
    user: 'Employee',
    propertyOption: 'Team',
  },
  meetingPageHeader: {
    creatorDescription: 'Creator of this meeting',
    editorDescription: 'Participant of this meeting',
  },
  meetingsContextMenu: {
    cannotDeleteMultiple: 'You are not allowed to edit the selected items.',
    editHint: 'You are not allowed to delete all of the selected items.',
  },
  meetingTemplateList: {
    newTemplate: 'New Template',
    templatesFor: 'Templates for',
    emptyList: 'Create templates to provide guidance for your employees',
    empty: 'Empty document',
  },
  meetingsListHeader: { templates: 'Templates' },
  mobileMenuComponent: { turnInto: 'Turn into', add: 'Add block' },
  meetingsExplorer: {
    explorer: 'Explorer',
    dashboard: 'Dashboard',
  },
  meetingInsights: {
    groupBy: 'Group by',
    user: 'Employees',
  },
  meetingInsightsTable: { count: 'Count' },
  okta: {
    email: 'Okta Username',
    enterEmail: 'Enter your Okta username',
  },
  globalNewBtn: {
    update: 'Check-in',
    meeting: 'Meeting',
    requestFeedback: 'Request feedback',
    noItemFound: 'No item found',
  },
  publishButton: {
    header: 'Share to web',
    description: 'Publish and share link with anyone',
    copyLink: 'Copy link',
  },
  publishedView: {
    notFound: {
      title: 'Page not found.',
      description: 'The page you\'re looking for doesn\'t exist or has been removed.',
      anchorText: 'Back to main page',
    },
  },
  meetingsCalendar: {
    connectAction: {
      create: 'Create calendar event',
      connect: 'Connect to calendar event',
    },
    connectDialogTitle: 'Connect to calendar',
    openAction: 'Open',
    noEvents: 'No events available',
    search: 'Search for events by name',
    loadMore: 'Load more events',
  },
  calendarConnect: {
    description: 'Create a new connection in your calendar to create and sync calendar events with Mooncamp',
    msCalendar: 'Outlook Calendar',
    connectMsCalendar: 'Connect with Outlook Calendar',
    finishSetupDescription: 'We couldn\'t find an established connection. Please click the button to connect with your Outlook Calendar',
    alreadySet: 'The meeting was already connected to a calendar event by {user}. Please contact {user} if you want to connect a different event.',
  },
  calendarEventForm: {
    title: 'Event title',
    date: 'Date',
    timezone: 'Your configured time zone is {timezone}. You can change your time zone in your {settings}.',
    settings: 'Settings',
  },
  msCalendarDate: { error: { eventMissing: 'The calendar event is not available anymore. Maybe it was removed.' } },
  helpButton: {
    contactSupport: 'Contact support',
    chatWithSales: 'Chat with sales',
    productFeedback: 'Give product feedback',
    placeholderFeedback: 'Do you have any feedback or want to request a feature? Please describe your feedback or your feature request in as much detail as possible.',
    whatsNew: 'What\'s new',
    companyInfo: { emptyTitle: 'Company Info' },
    helpCenterLink: '@:{\'general.helpCenterBaseUrl\'}',
  },
  formTags: {
    heading: 'Categories',
    description: 'Categories are used to structure your templates.',
  },
  feedbackInquiry: {
    title: 'Request feedback',
    recipients: 'From',
    messageLabel: 'Message',
    typeYourMessage: 'Start typing...',
    addQuestions: 'Add question',
    emptyQuestion: 'Custom question',
    questionFromTemplate: 'From template',
    questionsLabel: 'Questions',
    created: 'Feedback request successfully submitted',
  },
  giveFeedbackForm: {
    title: 'Give feedback',
    viewFeedback: 'View feedback',
    feedbackFrom: 'Feedback from {actor}',
    recipient: 'Recipient',
    furtherRemarks: 'Further notes (optional)',
    messageLabel: 'Your feedback',
    typeYourMessage: 'Type your answer here...',
    from: 'From',
    answer: 'Answer',
    yourMessage: 'Your initial message',
  },
  feedbackAnswerItem: { placeholder: 'Type your answer here...' },
  identityAndProvisioning: {
    security: { heading: 'Access' },
    restrictLogin: {
      passwordLoginTitle: 'Allow login via password',
      passwordLoginSubTitle: 'Allow users to log in using a password',
      deactivatePasswordTitle: 'Are you sure you want to prohibit users from logging in via password?',
      activatePasswordTitle: 'Are you sure you want to allow users to log in using a password?',
      googleLoginTitle: 'Allow login via Google',
      googleLoginSubTitle: 'Allow users to log in using their Google account',
      deactivateGoogleLoginTitle: 'Are you sure you want to prohibit users from being able to log in using their Google account',
      activateGoogleLoginTitle: 'Are you sure you want to allow users to log in using their Google account?',
      microsoftLoginTitle: 'Allow login via Microsoft',
      microsoftLoginSubTitle: 'Allow users to log in using their Microsoft account',
      deactivateMicrosoftLoginTitle: 'Are you sure you want to prohibit users from being able to log in using a Microsoft account',
      activateMicrosoftLoginTitle: 'Are you sure you want to allow users to log in using a Microsoft account?',
    },
    userProvisioning: {
      enable: 'Are you sure you want to enable user provisioning?',
      enableContent: 'User provisioning allows you to synchronize users between Mooncamp and a third-party identity provider (such as Okta, Microsoft Azure, ...)',
      disable: 'Are you sure to want to disable user provisioning',
      disableContent: 'User synchronization will be turned off and will need to be reconfigured afterwards.',
    },
    saml: {
      heading: 'SAML',
      ssoUrl: 'SSO-URL',
      entityId: 'Entity-ID',
      certificate: 'Certificate',
      save: 'Save',
    },
    contactLink: '@:{\'general.websiteBaseUrl\'}/contact/',
  },
  integrationSettings: {
    title: 'Integrations',
    subTitle: 'Enable or disable integrations for your workspace',
    helpLink: '@:{\'general.helpCenterBaseUrl\'}/connect-data-integrations-to-goals-and-okrs',
    help: 'Learn more about how to set up integrations.',
    messaging: { title: 'Messaging' },
    dataIntegrations: { title: 'Data integrations' },
    calendarIntegrations: { title: 'Calendar integrations' },
    slack: 'Slack',
    slackSubTitle: 'Receive personal notifications in Slack.',
    manage: 'Manage',
    msTeams: 'Microsoft Teams',
    msTeamsSubTitle: 'Receive personal notifications in MS Teams.',
    excel: 'Excel Online',
    excelSubTitle: 'Connect your goals to a cell in an Excel Online document.',
    sheets: 'Google Sheets',
    sheetsSubTitle: 'Connect your goals to a cell in a Google Sheets document.',
    hubspot: 'Hubspot',
    hubspotSubTitle: 'Connect your goals with a Hubspot metric.',
    asana: 'Asana',
    asanaSubTitle: 'Connect your goals with Asana Tasks.',
    jira: 'Jira',
    jiraSubTitle: 'Connect your goals with Jira Tasks.',
    outlookCalendar: 'Outlook Calendar',
    outlookCalendarSubTitle: 'Connect meetings with your Outlook Calendar.',
    install: 'Install',
    installed: 'Installed',
    syncDays: 'Synchronization',
    syncDaysTitle: 'Days of the week',
    syncDaysDescription: 'Select the days of the week on which the data should be synchronized.',
    salesforce: 'Salesforce',
    salesforceSubTitle: 'Connect your goals to Salesforce reports.',
  },
  msTeamsInstallHint: {
    link: '@:{\'general.helpCenterBaseUrl\'}/microsoft-teams-integration',
    msTeamsGuide: 'How to connect Mooncamp to MS Teams',
  },
  msTeamsSwitch: {
    uninstallHint: 'To disconnect Mooncamp from MS Teams, you need to uninstall the Mooncamp app from MS Teams.',
    description: 'Enable the MS Teams integration so users can connect MS Teams and Mooncamp.',
  },
  slackSwitch: { description: 'Connect Slack with your Mooncamp account.' },
  dataSourceDetails: {
    back: 'Back to integrations',
    connections: 'Connections',
    connectionsDescription: 'Configure {type} connections that can be used by Mooncamp users to link their goals and update progress.',
    outlookCalendarDescription: 'Configure Outlook Calendar connections to link them to meetings in Mooncamp.',
    jira: {
      help: 'Learn more about the Jira integration.',
      helpLink: '@:{\'general.helpCenterBaseUrl\'}/jira-integration',
      title: 'Jira',
      description: 'Connect your goals with Jira Tasks.',
    },
    sheets: {
      help: 'Learn more about the integration.',
      helpLink: '@:{\'general.helpCenterBaseUrl\'}/connect-data-integrations-to-goals-and-okrs',
      title: 'Google Sheets',
      description: 'Connect your goals with cells of Google Sheet documents.',
    },
    excel: {
      help: 'Learn more about the integration.',
      helpLink: '@:{\'general.helpCenterBaseUrl\'}/connect-data-integrations-to-goals-and-okrs',
      title: 'Excel Online',
      description: 'Connect your goals with cells of a Excel Online documents.',
    },
    asana: {
      help: 'Learn more about the integration.',
      helpLink: '@:{\'general.helpCenterBaseUrl\'}/connect-data-integrations-to-goals-and-okrs',
      title: 'Asana',
      description: 'Connect your goals with Asana Tasks.',
    },
    hubspot: {
      help: 'Learn more about the integration.',
      helpLink: '@:{\'general.helpCenterBaseUrl\'}/connect-data-integrations-to-goals-and-okrs',
      title: 'Hubspot',
      description: 'Connect your goals with metrics from Hubspot.',
    },
    msCalendar: {
      help: '',
      title: 'Outlook Calendar',
      description: 'Connect your meetings to your MS Outlook calendar.',
    },
    salesforce: {
      help: 'Learn more about the integration.',
      helpLink: '@:{\'general.helpCenterBaseUrl\'}/connect-data-integrations-to-goals-and-okrs',
      title: 'Salesforce',
      description: 'Connect your goals with Saleforce Reports.',
    },
  },
  instantFeedbackTemplateGallery: {
    selected: 'Add Selected',
    template: 'Templates',
    uncategorized: 'Uncategorized',
  },
  templateDetails: { noQuestions: 'No questions available' },
  oauth: { missingOpener: 'Look\'s like something went wrong.', retry: 'Retry' },
  fileExplorer: { empty: 'This folder is empty', root: 'My drive' },
  accessEditor: { hint: 'The creator always has full access.' },
  goalCascadeParents: { more: 'more' },
  userProvisioning: {
    heading: 'User provisioning',
    subheading: 'Synchronize Mooncamp users with a third party identity provider',
    token: 'API token',
    activate: 'Activate',
    actionTitle: 'Activate user provisioning',
    actionSubtitle: 'Activate user provisioning via the SCIM protocol',
    learnAbout: 'Learn about SCIM',
    attributeMapping: 'Attribute Mapping',
    attributeMappingDescription: 'Map SCIM Attributes to properties inside Mooncamp',
    addAttributeMapping: 'Add attribute mapping',
    property: 'Mooncamp property',
    space: 'Space',
    option: 'Option',
    attribute: 'SCIM attribute',
    update: 'Update',
    confirmAttributeDelete: 'Are you sure you want to remove this attribute mapping?',
    helpCenterLink: '@:{\'general.helpCenterBaseUrl\'}/azure-user-provisioning-via-scim-setup',
    scimAttributeValues: 'SCIM attribute values',
    scimAttributeType: 'SCIM Attribute type',
    moreItems: 'more',
    firstName: 'Name',
    lastName: 'Last Name',
    language: 'Language',
    email: 'Email',
    immutableField: 'This field is required and is therefore not modifiable.',
    syncSuccess: 'Users successfully synced',
    sync: 'Synchronize',
    syncLoading: 'Synchronization is running...',
    tooManySyncRequests: 'Only one syncronization operation is allowed per five minutes. Try again later.',
  },
  savedViewPrompt: { prompt: 'You have unsaved changes, do you want to discard them?' },
  alignedItem: { weightDescription: 'Select weight' },
  productTour: {
    author: 'from Mooncamp',
    previous: 'Back',
    next: 'Next',
    done: 'Done',
    of: 'of',
    home: {
      masteringMooncampStep: {
        heading: 'Mastering Mooncamp and OKRs',
        content: `The <b>resource center</b> is your one-stop-shop to
            <ul>
            <li>access your onboarding guide</li>
            <li>watch Mooncamp tutorials</li>
            <li>get help from our support team</li>
            <li>access our help center</li>
            <li>learn more about OKRs</li>
            </ul>`,
      },

      goalExplorerStep: {
        heading: 'Goal Explorer and Planning Spaces',
        content: 'In the Goal Explorer, you can browse all goals and create custom views with individual filters applied.\n\nIn Planning Spaces, you can create goal drafts with your team before you publish them for everyone to see.',
      },

      goalCycleStep: {
        heading: 'Goals belong to a goal cycle',
        content: 'Goals or OKRs always belong to at least one goal cycle. You can switch between cycles and add new ones here.',
      },
      checkInStep: {
        heading: 'Regular Check-ins',
        content: 'Scheduling recurring check-ins and reminders to update your goals is important to keep everyone engaged in the process. They let everyone see what others are working on, discuss progress and where mutual support is needed.',
      },
      dashboardStep: {
        heading: 'Dashboards',
        content: 'Use dashboards to get deeper insights into the progress of your goals, the health of your goal setting process, or to evaluate the overall development at the end of each cycle.',
      },
      personalHomePageStep: {
        heading: 'Your personal homepage',
        content: 'View your goals, check-ins, recent updates, or create your own custom views here.',
      },
      settingsStep: {
        heading: 'Settings',
        content: 'Enable integrations, customize your OKR system, invite co-workers, set permissions or access advanced features here.',
      },
    },
    checkins: {
      step1: {
        heading: 'This is a check-in',
        content: 'Check-ins from all teams will appear in this feed by default. Check-ins belonging to a team can also be found on your team pages.',
      },
      step2: {
        heading: 'Create new check-ins here',
        content: 'You can create a new check-in here from scratch or by choosing a custom template.',
      },
      step3: {
        heading: 'Create and edit schedules',
        content: 'You can create new or edit existing check-in schedules here. Depending on their notification preferences, recipients will receive a notification via email, Slack, or MS Teams.',
      },
    },
  },
  discoverMooncamp: {
    title: 'Learn why Mooncamp is unique',
    learnMore: 'Learn more',
    properties: {
      heading: 'Customize anything with Properties',
      description: 'Properties are one of the most exciting features of Mooncamp, allowing your organization to completely customize the Mooncamp platform to fit your needs, internal processes, and company language.',
    },
    powerful: {
      heading: 'Light-weight but powerful',
      description: 'Simple and intuitive design that drives adoption among all teams.',
    },
    integrations: {
      heading: 'Microsoft Teams and Slack Integration',
      description: 'Mooncamp can be embedded into MS Teams or Slack, allowing you to get notifications and manage OKRs directly from your collaboration tools.',
    },
    okrRules: {
      heading: 'Use rules to customize the goal system to your processes',
      description: 'In Mooncamp, you can not only create your own goal types (such as {tag1}, {tag2}, or {tag3}) but also customize them with rules you define yourself. Rules can be, for example:',
      bp1: '{tag1} may only be aligned to {tag2}',
      bp2: '{tag1} may only be aligned to {tag2}',
      bp3: '{tag1} must always have an {tag2}',
      bp4: '{tag1} may only be measured by {tag2}',
      keyResults: 'Key Results',
      objectives: 'Objectives',
      strategicPillars: 'Strategic Pillars',
      owner: 'Owner',
      initiatives: 'Initiatives',
      openDone: 'Open/Done',
    },
  },
  afterAccountSpaceDemoModal: {
    congratulations: 'Congratulations',
    description: 'You successfully completed Mooncamp’s product tour!',
    goToOnboarding: 'Go to onboarding guide',
    explore: 'Explore on your own',
  },
  afterCheckinTourModal: {
    congratulations: 'Congratulations',
    description: 'You successfully completed Mooncamp’s check-ins tour!',
    cta: 'Next, create your first check-in:',
    create: 'Create first check-in',
    explore: 'Skip and explore on your own',
  },
  updateDivider: {
    lastWeek: 'Last week',
    weekOf: 'Week of {date}',
  },
  mMentionMenu: {
    users: 'Persons',
    noItems: 'No persons or {title} found.',
    noUsers: 'No persons found',
    noGoals: 'No {title} found.',
    goalsTitle: 'Link to {title}',
  },
  goalMentionComponent: { hint: '{title} has either been deleted or you do not have read permission.' },
  goalActivityForm: {
    referencedHint: 'Reference to a past update',
    done: 'Done',
    disableStatusAutoUpdate: 'Disable automated status update',
  },
  browserNotSupported: {
    title: 'Browser not supported',
    hint: 'You are using an outdated browser that is not supported by Mooncamp. You can use any of the following: Chrome, Edge, Firefox, or Safari.',
  },
  durationUnit: {
    minute: 'minute | minutes',
    hour: 'hour | hours',
    day: 'day | days',
  },
  afterUnit: {
    minute: 'minute | minutes',
    hour: 'hour | hours',
    day: 'day | days',
  },
  reminderEditor: { after: 'After' },
  reminderList: {
    noReminders: 'No reminders available',
    add: 'Add reminder',
  },
  plans: {
    title: 'Plans and billing',
    enterprise: 'Enterprise',
    pro: 'Professional',
    business: 'Essential',
    short: {
      enterprise: 'Enterprise',
      pro: 'Pro',
      business: 'Essential',
    },
    learnMore: 'Learn more about our plans.',
    helpLink: '@:{\'general.helpCenterBaseUrl\'}/differences-between-essential-and-pro-plan',
    portalButton: 'Manage billing and invoices',
  },
  pricingTable: {
    downgrade: 'Downgrade',
    upgrade: 'Upgrade',
    perMonthFlat: 'per month (flat)',
    perUserPerMonth: 'per user per month',
    currentPlan: 'Current plan',
    talkToSales: 'Contact Sales',
    letsTalk: 'Let\'s talk',
    workspace: 'Workspace',
    numberOfUsers: 'Maximum number of users',
    currentPlanHint: 'This is your current plan.',
    planDowngradeHint: 'If you want to downgrade your plan, please contact our sales team.',
    unlimited: 'Unlimited',
    goalsAndOkrs: 'Goals and OKRs',
    unlimited_okrs: 'Unlimited goals, initiatives, and hierarchy levels',
    customizability: 'Completely customizable goal system',
    private_goals: 'Private goals and OKRs',
    messengers: 'Slack and Microsoft Teams integration',
    integrations: 'Data integrations (Jira, Asana, Hubspot, ...)',
    planningSpaces: 'Planning spaces',
    planningSpacesTooltip: 'Dedicated spaces for your teams to create goal drafts for the next cycle.',
    goalTree: 'Goal tree',
    goalTreeTooltip: 'Give everyone a high-level overview of your company’s strategy and the alignment of your goals and OKRs.',
    okrRules: 'Goal and OKR rules',
    okrRulesTooltip: 'Set rules to configure your own OKR system and hierarchy, mark fields as required, and other settings.',
    analytics: 'Dashboards & Analytics',
    dashboards: 'Progress Dashboard',
    premiumDashboards: 'Health Dashboard',
    premiumDashboardsTooltip: 'A comprehensive health and quality check of all your organization\'s goals',
    privateCustomDashboards: 'Private Dashboards',
    privateCustomDashboardsTooltip: 'Dashboards offer a versatile range of applications to support your strategy or OKR process, like Quarterly Business Reviews, Weekly Management Reports, Vision/Mission/Values boards, Company KPIs, and many more.',
    publicCustomDashboards: 'Shared Dashboards',
    publicCustomDashboardsTooltip: 'Dashboards offer a versatile range of applications to support your strategy or OKR process, like Quarterly Business Reviews, Weekly Management Reports, Vision/Mission/Values boards, Company KPIs, and many more.',
    multiAlignment: 'Multi-alignment of OKRs',
    multiAlignmentTooltip: 'Align a goal with two or more parent goals.',
    premiumIntegrations: 'Premium data integrations (Salesforce, ...)',
    premiumIntegrationsTooltip: 'Custom integrations can be developed after consultation.',
    tvMode: 'Share public links to views (TV mode)',
    tvModeTooltip: 'Share a public link to a goals view with anyone who is not in your Mooncamp workspace.',
    goalWeights: 'Goal weighting',
    goalWeightsTooltip: 'Assign weights to your key results or subgoals.',
    checkIns: 'Check-ins',
    reminders: 'Create check-ins and reminders',
    checkInTemplates: 'Customizable templates',
    checkInMessengers: 'Check-in reminders in Slack and Microsoft Teams',
    admin: 'Administration and Security',
    adminGoogleSso: 'Google Single Sign-On',
    adminMsSso: 'Microsoft Single Sign-On',
    customSaml: 'Custom SAML-based SSO',
    scimProvisioning: 'SCIM Provisioning',
    support: {
      title: 'Support',
      helpcenter: 'Self-serve knowledge base',
      level: 'Support Level',
      levelStandard: 'Standard',
      levelPriority: 'Priority',
      levelPriorityTooltip: 'We offer Priority support for all accounts with an annual contract value of €10,000 or above.',
      onboarding: 'Personalized onboarding',
      rollout: 'Personal guidance during implementation and rollout',
      sla: 'Service Level Agreement',
      account_manager: 'Dedicated account manager',
      contract: 'Custom contract and invoicing',
      dpa: 'Custom DPA',
    },
    upgradeModal: {
      title: 'Upgrade your workspace',
      text: 'If you want to subscribe to a plan, or have any questions regarding pricing, please contact our sales team: {mailTo}',
    },
  },
  couponCodeAlert: {
    text: 'Do you have a partner code for a pre-booked plan? If so, you can enter the code here to unlock your prepaid plan.',
    applyCode: 'Enter partner code',
    codeApplied: 'Partner code successfully applied',
    dialog: {
      header: 'Enter partner code',
      placeholder: 'Enter partner code...',
      submit: 'Apply code',
      hint: 'Have trouble with your partner code?',
      contactSupport: 'Contact customer support',
    },
    notFound: 'We couldn\'t find the partner code you entered or it has already been redeemed.',
  },
  planHint: {
    currentPlan: 'You workspace is currently on {currentPlan}.',
    expiresAt: 'Your subscription expires on {expirationDate}.',
    renewsAt: 'Your subscription will be renewed on {expirationDate}.',
    trial: 'the Trial plan',
    pure: 'the Pure Partner Deal',
    starter: 'the Starter plan',
    business: 'the Essential plan',
    pro: 'the Pro plan',
    enterprise: 'the Enterprise plan',
    trialExpiresAt: 'Your trial expires on {expirationDate}.',
  },
  redeemCouponCode: {
    placeholder: 'Enter your coupon code...',
    continueWithoutCoupon: 'Continue without coupon code',
  },
  updateSchedules: { allSchedules: 'All Schedules' },
  schedulesTable: {
    name: 'Name',
    schedule: 'Cadence',
    participants: 'Participants',
    template: 'Template',
    participation: 'Participation',
    participationHint: 'Participation rate of the current execution.',
    createHeading: 'Create schedule',
    editHeading: 'Edit schedule',
    recipientsList: 'Participants',
    status: 'Status',
  },
  scheduleDetailPageHeader: {
    access: 'Access',
    creatorDescription: 'Creator of this schedule',
  },
  participationList: {
    missing: 'Pending',
    done: 'Done',
  },
  scheduleDetailPage: {
    reminders: 'Reminders',
    noReminders: 'None',
    participationRate: 'Participation rate',
    backToAllSchedules: 'Back to all schedules',
    notFound: 'Schedule not found',
    noSendOut: 'Scheduled notifications lie in the past.',
    nextSendOut: 'Next notification scheduled on: {date}.',
    sendOutHint: 'It is likely that this notification will not be sent, as it takes about 20 minutes for the notification to be fully set up.',
  },
  participantsTable: {
    title: 'Participants',
    user: 'Name',
    participated: 'Status',
    actions: 'Actions',
    view: 'View',
  },
  participationChart: { participation: 'Participation' },
  templateItem: {
    privateHint: 'This template is only visible to you.',
    sharedHint: 'This template is visible to you and others.',
    publicHint: 'This template is visible to everyone in your workspace.',
  },
  updateTemplates: { all: 'All Templates' },
  scheduleDetailTop: {
    allRecipients: 'All participants',
    allRecipientsDescription: 'All participants of this schedule',
    read: 'Read',
  },
  spaceSelector: {
    mySpaces: 'My {title}',
    activeSpaces: 'Active {title}',
    archivedSpaces: 'Archived {title}',
    selectSpace: 'Select {title}',
    further: '+{amount} more',
    empty: 'No {title} found',
    searchPlaceholder: 'Search for {title}...',
    selectPlaceholder: 'Select {title}...',
  },
  spaceSelectorItem: {
    selectAll: 'Select all',
    deselectAll: 'Deselect all',
  },
  supportForm: {
    heading: 'Get in touch with our support team',
    description: 'We are happy to assist you with any issues you may have. Please send us an email at {supportEmail}',
    helpcenter: {
      tip: 'Small tip: Many questions can be quickly solved by taking a look at our Help Center, why not check it out 😊',
      action: 'Go to Helpcenter',
    },
  },
  upgradeForm: {
    heading: 'Get in touch with sales',
    description: 'We will help you select the right plan and set up your account.',
    firstNameLabel: 'First name',
    lastNameLabel: 'Last name',
    emailLabel: 'Your work email',
    licencesLabel: 'Number of licenses',
    plansLabel: 'Plan',
    messageLabel: 'Your message',
    optional: '(optional)',
    questionLabel: 'Request',
    submittedTitle: 'Sent successfully',
    submittedSubTitle: 'Your message was sent successfully. A sales representative will contact you shortly via email.',
    subscriptionExpired: 'Trial period expired',
    subscriptionExpiredText: 'If you want to subscribe to one of our plans, extend your trial period or have any question regarding Mooncamp\'s software, please contact us.',
    required: 'This is a required field',
    scheduleDemo: 'Schedule a demo',
    bookPlan: 'Upgrade plan',
    other: 'Other',
    address: 'Company address',
    vatID: 'VAT ID (optional)',
    addressPlaceholder: 'Full company name and address',
    bookPlanExplanation: 'Please select a plan and provide your billing information. We will then prepare a quote for you to e-sign. Once signed, your subscription will start and you will receive an invoice.',
    scheduleDemoExplanation: 'We would be glad to guide you through Mooncamp in a personalized demo. Please fill out the demo form on our website and select a timeslot that suits you best.',
    scheduleDemoButton: 'Select a demo slot',
    scheduleDemoLink: '@:{\'general.websiteBaseUrl\'}/contact/',
    invalid: 'Please fill in all required fields.',
  },
  spaceItem: {
    unpinHelp: 'Remove from sidebar',
    pinHelp: 'Add to sidebar',
    disabledHelp: 'You cannot remove {name} from the sidebar because you are a member',
  },
  spaceNavigation: { searchPlaceholder: 'Search for {title}...' },
  msError: {
    header: 'Error during connection with Microsoft Teams',
    message: 'Unfortunately, this Microsoft account is already connected to the Mooncamp user {email}.',
    message2: 'If you are not the owner of this Mooncamp account, please let us know via {supportmail}. Otherwise you can choose to disconnect {email} from your Microsoft account by switching to your {email} account and navigate to Settings -> Notifications. Here yo can click on \'Disconnect\'.',
    goBack: 'Go back',
  },
  propertyLabel: {
    editProperty: 'Edit property',
    hideProperty: 'Hide property',
    customizePage: 'Customize page',
    alwaysVisible: 'Always visible',
    hideWhenEmpty: 'Hide when empty',
    alwaysHide: 'Always hide',
  },
  propertyValues: {
    hiddenProperties: 'more property | more properties',
    hideProperties: 'Hide 1 property | Hide {count} properties',
  },
  customizePage: {
    header: 'Detail page for',
    properties: 'Properties',
    goalChildrenProperties: 'Aligned items properties',
  },
  cascadeTableRow: {
    editTitle: 'Edit title',
    errorTitle: 'Validation errors',
  },
  documentListHeader: {
    wrapCells: 'Wrap cells',
    wrapTitles: 'Wrap goal titles',
    wrapTitlesTooltip: 'Wraps goal titles mentioned in updates.',
    expandNextLevel: 'Expand next level',
    expandAll: 'Expand all',
    collapseNextLevel: 'Collapse next level',
    collapseAll: 'Collapse all',
    locked: 'Locked',
    lockedHint: 'You don\'t have sufficient rights to edit this view.',
  },
  lookupForm: {
    relationTitle: 'Goal property',
    propertyTitle: 'User property',
    from: 'from',
    title: 'Configure property',
    relationInfo: 'The goal property of type Person (e.g., "Owner") through which the value is looked up.',
    propertyInfo: 'The user property whose value or content is returned.',
  },
  foreignSpaceReference: { insufficientPermissions: 'You are only allowed to create goals for your own teams.' },
  companyInfo: {
    title: 'Info page',
    help: 'Learn more about info pages.',
    helpLink: '@:{\'general.helpCenterBaseUrl\'}/info-page',
    actions: {
      activate: {
        title: 'Show in help area',
        subTitle: 'The page is displayed to all employees in the help area.',
      },
      onboarding: {
        title: 'Show during Onboarding',
        subTitle: 'The page is displayed to all employees when they log in for the first time.',
      },
      open: 'Open',
    },
    contentHeader: 'Content',
    prompt: 'You have unsaved changes, do you want to discard them?',
    saveChanges: 'Save changes',
  },
  goalStatusTag: {
    disabledAutoStatusUpdateDescription: 'Automated status update is currently disabled. Enabling this setting will update the status based on this goal\'s subitems.',
    enableAutomaticStatusUpdate: 'Enable automated status update',
    successfullyActivated: 'Automated status update enabled.',
    successfullyDeactivated: 'Automated status update disabled.',
  },
  releaseNotesWrapper: {
    title: 'What\'s new',
    open: 'Open changelog',
    readMore: 'Show more',
    linkedInLabel: 'For more updates, follow us on:',
    readMoreLink: '{baseURL}/new/page/2/',
    releaseNotesLink: '{baseURL}/new/',
  },
  planningList: {
    pageTitle: 'Planning spaces',
    helpText: 'Learn more about planning spaces',
    helpLink: 'https://help.mooncamp.com/en/planning-spaces',
    title: 'Name',
    creator: 'Created by',
    createdAt: 'Created on',
    goalCycle: 'Cycle',
    modifiedAt: 'Last updated',
    onboarding: {
      title: 'What is a planning space?',
      drafts: 'goal drafts',
      description1: 'It is a dedicated space where you can create {title} with your team before they are published for everyone to see.',
      description2: 'Planning is a core element of the OKR process and usually takes place before the next cycle starts.',
      description3: 'Learn more about planning spaces in our {helpCenter}.',
      helpCenterLink: '@:{\'general.helpCenterBaseUrl\'}/planning-spaces',
      helpCenter: 'help center',
      createPlanning: 'Create a planning space',
      notNow: 'Not right now',
    },
  },
  plannings: {
    open: 'Open',
    closed: 'Closed',
    all: 'All',
  },
  planningForm: {
    createTitle: 'Create a planning space',
    titleLabel: 'Name',
    titlePlaceholder: 'Enter a title...',
    cyclePlaceholder: 'Select a cycle...',
  },
  planningDetails: {
    planning: 'Planning',
    plannings: 'Planning spaces',
    publishGoals: 'Publish {title}',
    publish: 'Publish',
    publishSelected: 'Publish {amount} items',
    closePlanning: 'Close planning space',
    markClosed: 'Mark as closed',
    reopen: 'Reopen',
    closed: 'Closed',
    published: 'Your {title} have been published.',
    nextStepsHint: 'Click to see where your {title} have been published:',
    shareCreatorDescription: 'The creator of this Planning',
    importGoals: {
      initialModal: {
        title: 'Start from scratch or import {title}',
        scratch: 'Start from scratch',
        import: 'Import {title}',
      },
      button: 'Import {title}',
      modal: {
        title: 'Import {title} as duplicates',
        button: 'Import {amount} items',
      },
    },
    productTour: {
      step1: {
        heading: 'Planning space properties',
        content: 'A planning space is usually associated with a cycle and a team.\n\nGoal drafts created in this planning space will have these values applied per default.',
      },
      step2: {
        heading: 'Sharing settings',
        content: 'Per default, a planning space is shared with the team(s) it is associated with. You can always change with whom it is shared here.',
      },
      step3: {
        heading: 'Publish goal drafts',
        content: 'Once you are done with the planning session, you can select which goals to publish here.',
      },
    },
  },
  goalPage: {
    notPublishedHint: 'This {title} is not published yet.',
    openPlanning: 'Open planning space',
  },
  slack: {
    connectionError: {
      goBack: 'Go back',
      header: 'Error during connection with Slack',
      missingAuth: 'You do not seem to have the required permissions to connect Mooncamp to a Slack workspace. If you just want to connect your Mooncamp account to Slack, go to your Slack workspace and click on the Mooncamp Slack app.',
      defaultError: 'Unfortunately there has been an error during connecting with Slack. Please contact us via {supportmail}',
    },
  },
  appFeedback: {
    avatarName: 'Laura from Mooncamp',
    greeting: 'Hey there, {name} 👋',
    npsQuestion: 'On a scale of 0-10, how likely are you to recommend Mooncamp to another business or professional contact?',
    npsScale: {
      left: 'Not likely',
      center: 'Neutral',
      right: 'Very likely',
    },
    followUp: 'What is the most important reason for your score?',
    hint: '(Optional) Type something...',
    referral: {
      first: 'Thank you ️🙏❤',
      second: 'Maybe this is asking too much, but...',
      third: 'would you help the team at Mooncamp with a review on {name}?',
      noButton: 'No, thanks.',
      yesButton: 'Yes, sure.',
    },
    thankYou: {
      first: 'Thank you for your honest feedback ️🙏',
      second: 'This means the world to us. (really)',
    },
  },
  setParents: {
    prompt: 'Should this goal contribute to the progress of {goals}? | Should these goals contribute to the progress of {goals}?',
    notSetPaysOnto: 'No',
    setPaysOnto: 'Yes',
  },
  goalCycles: {
    title: 'Goal cycles',
    learnMore: 'Learn more about Goal cycles.',
    helpCenterLink: '@:{\'general.helpCenterBaseUrl\'}/cycles',
  },
  goalCyclesTable: {
    title: 'Title',
    start: 'Start date',
    end: 'End date',
  },
  goalType: {
    title: 'Goal types and rules',
    learnMore: 'Learn more about Goal and OKR rules.',
    helpCenterLink: '@:{\'general.helpCenterBaseUrl\'}/goal-and-okr-rules',
    listTitle: 'Any',
    editTitle: 'Edit type',
    singleRequired: 'This goal type is required and cannot be deleted.',
    oneOrMoreRequired: 'One or more selected goal types are required and cannot be deleted.',
  },
  goalStatus: {
    title: 'Status',
    learnMore: 'Learn more about Statuses.',
    helpCenterLink: '@:{\'general.helpCenterBaseUrl\'}/custom-goal-statuses',
    subTitle: 'Configure the statuses of your goals.',
  },
  propertyMenu: { goalTypeRequired: 'Editing only possible if a goal type is set' },
  goalTypeForm: {
    properties: 'Properties',
    deleteEmptyPrompt: 'The goal type is emtpy. Would you like to delete it?',
  },
  propertySettingsForm: {
    edit: 'Edit {title}',
    visibility: 'Visibility',
    canBeChildOf: 'Can be child of',
    required: 'Required',
    progressMeasurement: 'Measurement',
  },
  propertyValidation: { invalidParentType: 'Allowed type is: {types} | Allowed types are: {types}' },
  validationErrors: {
    header: 'There are {amount} validation errors:',
    canBeChildOf: 'The type of the parent "{title}" should be {expectedTypes}, but is {actualTypes}.',
  },
  measurementTypeError: { allowedTypes: 'Allowed measurement types:' },
  canBeChildOfError: { allowedTypes: 'Allowed goal type: | Allowed goal types:' },
  requiredError: { title: 'Required field' },
  mustBeEmptyError: { title: 'Must be empty.' },
  goalTypeList: {
    deletePrompt: 'Are you sure, you want to delete this goal type?',
    yes: 'Delete goal type',
  },
  goalCreateDropdown: { title: 'Create a goal' },
  rGoalsCascadeTable: { cannotChangeOrder: 'Order cannot be changed because write permissions are missing.' },
  goalMutationErrors: {
    circularReference: 'The goals you are trying to align form a circular reference, which is not permitted.',
    missingAccessRightsGoal: 'Missing access rights for goal "{title}"',
    missingAccessRightsView: 'Missing access rights for view "{title}"',
  },
  goalProperties: { noParentAllowed: 'Parent alignment must be empty.' },
  iconList: {
    icons: 'Icons',
    recent: 'Recent',
    people: 'People',
    nature: 'Animals and Nature',
    food_drink: 'Food and Drink',
    activity: 'Activity',
    travel: 'Travel and Places',
    objects: 'Objects',
    symbols: 'Symbols',
    flags: 'Flags',
    pickAColor: 'Pick a color',
  },
  iconPicker: {
    search: 'Type to search',
    types: {
      emoji: 'Emojis',
      icon: 'Icons',
      custom: 'Custom',
    },
    remove: 'Remove',
    title: 'Select emoji',
    placeholderTooltip: 'Select an icon',
    placeholderTooltipEmoji: 'Select an emoji',
    custom: {
      upload: 'Upload image',
      recommended: 'Recommended size is 280 x 280 pixels',
      cropImage: 'Crop selected image',
    },
  },
  userProvisioningStatus: {
    provisionedUserCount: 'Users available',
    userCount: 'Users synchronized to Mooncamp',
    updatesAvailable: 'Updates available',
    errors: 'Synchronization errors',
    showErrors: 'Show errors',
  },
  userProvisioningErrors: {
    givenName: 'givenName',
    familyName: 'familyName',
    email: 'email',
    error: 'Synchronization error',
    title: 'Synchronization errors',
  },
  oauthCodeGrant: {
    actionTitle: 'Activate OAuth authentication',
    actionSubtitle: 'Activate OAuth authentication for Azure based user provisioning applications ',
    clientId: 'Client ID',
    clientSecret: 'Client Secret',
    redirectUrls: 'Redirect URLs',
    addRedirectUrl: 'Add Redirect URL',
    save: 'Save',
  },
  oauthCodeGrantRedirect: {
    header: 'Sign in',
    message: 'Sorry, but we\'re having trouble signing you in.',
    message2: 'If the problem persists, please contact us via {supportmail} and include the current time and the error below.',
    internalError: 'Internal error',
  },
  planTag: {
    activateHint: 'Activate this feature. Click to learn more.',
    upgradeHint: 'Upgrade to use this feature. Click to learn more.',
  },
  featureGateDialogs: {
    healthDashboard: {
      subTitle: 'Health dashboard: A comprehensive quality check of all your goals',
      description: 'With the Health dashboard, you can do a comprehensive health and quality check on all of your organization’s goals. Consider it a cockpit from where you can quickly identify and solve any issues with your goals. This helps to ensure that your whole goal management process is running smoothly and that everyone follows the Goal and OKR rules that you configured in the settings area.',
      actions: { learnMoreLink: '@:{\'general.helpCenterBaseUrl\'}/health-dashboard' },
      image: { alt: 'Health dashboard' },
    },
    customDashboards: {
      subTitle: 'Custom dashboards',
      description: 'Build custom dashboards for a variety of use cases, such as:',
      bullet1: 'Company Cockpit: Executive summary of your company\'s vision, mission, and values, as well as top-level KPIs and overall OKR progress.',
      bullet2: 'Quarterly Business Review: An in-depth analysis of achievements, challenges, and strategic initiatives.',
      bullet3: 'Personal Dashboard: A tailored view featuring your OKRs, check-ins, personal notes and file attachments.',
      bullet4: 'Team Overview: A snapshot of a teams purpose, mission, key metrics, goals, and team members.',
      bullet5: 'Plus many more use cases...',
      actions: { learnMoreLink: '@:{\'general.helpCenterBaseUrl\'}/custom-dashboards' },
      image: { alt: 'Custom dashboards' },
    },
    goalTree: {
      subTitle: 'Goal Tree: Get a birds-eye view of your strategy',
      bullet1: 'Use the goal tree view as a drawing board to map out all your strategic pillars, focus areas, goals, and initiatives in a hierarchical view.',
      bullet2: 'Give everyone a high-level overview of your company\'s strategy and the alignment of your goals and OKRs.',
      bullet3: 'Zoom in on OKR sets of certain departments or focus areas.',
      bullet4: 'Quickly discover isolated goals and align them under the correct parent goal.',
      image: { alt: 'Goal tree' },
    },
  },
  planGateDialogs: {
    shared: {
      title: 'You need to upgrade to use this feature',
      actions: {
        upgradePlan: 'Upgrade to the {plan} plan',
        comparePlans: 'Compare all plans',
        comparePlansWebsiteLink: '@:{\'general.websiteBaseUrl\'}/pricing/',
        activateFeature: 'Activate this feature',
        contactAdminToUpgrade: 'Interested? Ask your account administrator to upgrade your plan.',
        contactAdminToActivate: 'Interested? Ask your account administrator to activate this feature.',
        learnMore: 'Learn more about this feature',
      },
    },
    pro: {
      subTitle: 'Discover the benefits of the Pro plan',
      description: {
        bulletIntro: 'Here’s what you get with Mooncamp Pro.',
        bulletHealth: 'Health dashboard: A comprehensive quality check of all your goals',
        bulletOKRRule: 'Goal rules: Set rules to configure your own goal system, define the hierarchy of goals, and mark properties as required',
        bulletGoalWeight: 'Goal weighting: Assign weights to your subgoals or Key Results',
        bulletMultiAlignment: 'Multi-alignment of goals: Let a goal contribute to two or more parent goals',
        bulletGoalTree: 'Goal tree: Display your goals in a hierarchical tree view and get a birds-eye view of your strategy.',
        bulletMore: 'And much more.',
      },
      image: { alt: 'Pro plan' },
    },
    enterprise: {
      subTitle: 'Discover the benefits of the Enterprise plan',
      description: {
        bulletIntro: 'Here’s what you get with Mooncamp Enterprise. Everything in the Pro Plan, plus:',
        bulletPublishedViews: 'Public Links: Share public links with people outside of your workspace',
        bulletScim: 'SCIM Provisioning: Access the Mooncamp SCIM API to provision and manage users and groups',
        bulletSaml: 'SAML SSO: Manage employee access at scale with secure single sign-on',
        bulletSupport: 'Dedicated Account Manager and Priority Support',
        bulletMore: 'And much more.',
      },
      image: { alt: 'Enterprise plan' },
    },
  },
  maintenance: {
    header: 'We\'ll be back soon!',
    message: 'Sorry for the inconvience. We\'re performing some maintenance at the moment.',
  },
  resourceCenter: { title: 'Resources' },
  progressTag: { completed: 'completed' },
  rcOverview: {
    videos: {
      title: 'Video Tutorials',
      subTitle: 'Getting started with Mooncamp',
      watchDemoLink: 'https://mooncamp.com/watch-demo/',
    },
    videoFAQs: {
      title: 'Video FAQs',
      subTitle: 'FAQs answered in short videos',
      link: 'https://help.mooncamp.com/en#block-b7d35ebbad0b4ed390836fb7d0d98b5f',
    },
    onboardingGuide: { title: 'In-App Onboarding', subTitle: 'First steps & account setup' },
    resources: {
      title: 'OKR Resources',
      subTitle: 'OKR infos & best practices',
    },
    helpCenter: { title: 'Help Center', subTitle: 'Detailed infos about features and account settings' },
    gettingStarted: {
      title: 'Getting started guide',
      subTitle: 'Become a Mooncamp expert',
    },
    legalInformation: 'Legal information',
    legalInformationLink: '@:{\'general.websiteBaseUrl\'}/legal-information',
  },
  okrResources: {
    hint: 'Please note: Every company is different and uses their own OKR model, with different rules and vocabulary. Therefore, while reading the resources below, keep in mind that the content might differ from your team’s or organization’s understanding of OKRs and how they are implemented.',
    successStories: {
      title: 'Success Stories',
      subTitle: 'How our customers use Mooncamp successfully',
      href: '@:{\'general.websiteBaseUrl\'}/customers/success-stories/',
    },
    okrGuide: {
      title: 'The complete OKR guide',
      subTitle: 'A guide on the OKR method',
      href: '@:{\'general.websiteBaseUrl\'}/okr/',
    },
    howtoWriteOkrs: {
      title: 'How to write OKRs',
      subTitle: 'Tips for writing great OKRs',
      href: '@:{\'general.websiteBaseUrl\'}/blog/how-to-write-okrs/',
    },
    okrRolloutGuide: {
      title: 'The OKR rollout guide',
      subTitle: 'Best practices to roll out OKRs',
      href: '@:{\'general.websiteBaseUrl\'}/blog/okr-implementation/',
    },
    okrCycle: {
      title: 'The OKR Cycle',
      subTitle: 'An overview of all OKR events',
      href: '@:{\'general.websiteBaseUrl\'}/blog/okr-cycle/',
    },
    okrPlanning: {
      title: 'The OKR Planning',
      subTitle: 'How to run an OKR planning session',
      href: '@:{\'general.websiteBaseUrl\'}/blog/okr-planning/',
    },
    okrCheckin: {
      title: 'The OKR Check-in',
      subTitle: 'How to run an OKR check-in',
      href: '@:{\'general.websiteBaseUrl\'}/blog/okr-check-in/',
    },
    okrRetrospective: {
      title: 'The OKR Retrospective',
      subTitle: 'How to run an OKR retrospective',
      href: '@:{\'general.websiteBaseUrl\'}/blog/okr-retrospective/',
    },
    review: {
      title: 'The OKR Review',
      subTitle: 'How to conduct an OKR review',
      href: '@:{\'general.websiteBaseUrl\'}/blog/okr-review/',
    },
    scoreOkrs: {
      title: 'How to score OKRs',
      subTitle: 'Scoring and grading OKRs',
      href: '@:{\'general.websiteBaseUrl\'}/blog/okr-scoring/',
    },
    benefits: {
      title: 'The 9 benefits of using OKR',
      subTitle: 'Why OKRs are so popular',
      href: '@:{\'general.websiteBaseUrl\'}/blog/okr-benefits/',
    },
    okrVsKpi: {
      title: 'OKR vs KPI',
      subTitle: 'What is the difference?',
      href: '@:{\'general.websiteBaseUrl\'}/blog/okr-vs-kpi/',
    },
    outcomeVsOutput: {
      title: 'Outcome vs Output',
      subTitle: 'What is the difference?',
      href: '@:{\'general.websiteBaseUrl\'}/blog/output-vs-outcome/',
    },
    examples: {
      title: 'OKR examples',
      subTitle: 'A collection of real-world OKRs',
      href: '@:{\'general.websiteBaseUrl\'}/okr-examples/',
    },
  },
  videos: { title: 'Videos' },
  productSurveyDialog: {
    icons: '🎄 🎁 ☃️',
    title: 'Yearly Product Feedback Survey',
    content: 'Tell us what features you want to see next in Mooncamp 🚀',
    callToAction: 'Take survey',
    hint: 'Takes less than 5 minutes.',
    surveyLink: 'https://mooncamp.typeform.com/to/z7PP1W2v',
    cancel: 'I don\'t want to take part',
  },
  onboardingSlideShow: {
    sorting: {
      heading: 'Sorting and aligning of goals via drag and drop',
      description: 'Manually sort and realign goals or whole OKR sets by clicking the drag handle to the left and moving it to the desired position.',
    },
    properties: {
      heading: 'Customize anything with properties',
      description: 'Properties are one of the most powerful features of Mooncamp, allowing your organization to completely customize the Mooncamp platform to fit your needs, internal processes, and company language.',
      link: '@:{\'general.helpCenterBaseUrl\'}/properties-in-mooncamp',
    },
    bulkEdit: {
      heading: 'Bulk-edit goals',
      description: 'Easily edit multiple goals by selecting them with your mouse and performing a right-click.',
    },
    expand: {
      heading: 'Collapse or expand all goals',
      description: 'Use “right-click” and select “Expand/collapse all” to quickly collapse or expand all goals on the table view.',
    },
    okrRules: {
      heading: 'Use rules to adapt the goal system to your processes',
      description: 'You can not only create your own goal types (such as Objectives, Key Results, or Initiatives) but also customize them with rules you define yourself (e.g., “Key Results may only be aligned to Objectives and need to have an owner”).',
      link: '@:{\'general.helpCenterBaseUrl\'}/goal-and-okr-rules',
    },
  },
  goalCard: {
    addProperty: 'Add {label}',
    summary: {
      comments: 'No comments | One comment | {count} comments',
      lastCommentDate: 'Last comment: {date}',
      updates: 'No updates | One update | {count} updates',
      lastUpdateDate: 'Last update: {date}',
    },
  },
  gridPage: {
    error: { loading: 'Error while loading content.' },
    header: {
      addDescription: 'Add description',
      addADescription: 'Add a description...',
    },
    empty: {
      title: 'Add content to get started.',
      readOnly: 'This dashboard is empty and you don\'t have access to add content.',
      helpLink: '@:{\'general.helpCenterBaseUrl\'}/custom-dashboards',
      learnMore: 'Learn more about dashboards and use cases',
    },
    gridActions: {
      singleGoalProgress: 'Goal progress',
      text: 'Text',
      goalList: 'Goal list',
      checkinList: 'Check-in list',
      updateFeedList: 'Update feed list',
      memberList: 'Member list',
      addTooltip: 'Add content to row',
    },
    addRowBtn: { addRow: 'Add content' },
    tile: {
      header: {
        editTitle: 'Rename',
        editDescription: 'Edit description',
        addDescription: 'Add description',
        showBorder: 'Show border',
        duplicate: 'Duplicate',
        compareTo: {
          base: 'Compared to {relativeDate}',
          one_day_ago: 'yesterday',
          previous_week: 'last week',
          previous_month: 'last month',
        },
        singleGoal: {
          settings: 'Settings',
          openGoal: 'Open goal',
        },
      },
    },
    duplicate: { titlePrefix: 'Duplicate of' },
  },
  gridPageSlideShow: {
    general: {
      heading: 'Dashboards',
      description: 'Custom dashboards can have various purposes, like providing an executive summary encapsulating a company\'s core principles, offering in-depth quarterly analyses, highlighting weekly management details, or giving a comprehensive snapshot of a department\'s key functions and goals.',
    },
    addContentActions: {
      heading: 'Adding content',
      description: 'Content is organized into <b>rows</b>. To add a <b>card</b> to a row, hover over it and click the "+" button on the left. To add a new row, click the "+ Add content" button below the last  row in the dashboard. Cards can be anything: a text or image, a list of goals, check-ins, updates, or members, and even goal progress metrics or KPIs that you defined.',
    },
    draggingActions: {
      heading: 'Moving and resizing',
      description: 'To <b>move or resize</b> cards within a row, simply click and hold the mouse in the top part of a card, and drag it to its new position. Cards will automatically fill the width of a row. You can adjust their width and height by clicking and dragging the control between them. Simply hover between the cards to access the drag control.',
    },
  },
  treeItem: { noTypeAllowed: 'No allowed goal type found' },
  cardWidthSelector: {
    title: 'Card size',
    small: 'Small',
    medium: 'Medium',
    large: 'Large',
  },
  appFeedbackDropdown: {
    feedback: 'Feedback',
    tree: { label: 'How can we improve the Goal Tree view?' },
    customPages: { label: 'How can we improve the Custom Dashboards feature?' },
    goalStatus: { label: 'How can we improve the Goal status feature?' },
    general: { label: 'Your feedback' },
    placeholder: 'What do you like or dislike...',
    success: 'Thank you for your feedback!',
  },
  gridPageSingleGoal: {
    goal: { expected: 'Expected value:' },
    empty: { noGoal: 'No goal selected' },
  },
  gridPageTileSingleGoalEditor: {
    title: 'Progress from goal',
    goalPicker: 'Selected goal',
    goalPickerPlaceholder: 'Select a goal',
    visualizeAs: 'Display as',
    compareTo: 'Compare to',
    dateRange: 'Time frame',
  },
  gridPageTileSingleGoalType: {
    number: 'Metric',
    line: 'Line chart',
  },
  gridPageList: {
    systemDashboards: '{title} progress and health',
    progress: {
      title: 'Progress dashboard',
      subtitle: 'An overview of your progress towards your goals.',
    },
    health: {
      title: 'Health dashboard',
      subtitle: 'A complete health and quality check on all of your organization’s goals.',
    },
    customDashboards: 'Custom dashboards',
    columns: {
      title: 'Name',
      access: 'Access',
      creator: 'Created by',
      createdAt: 'Created on',
    },
    privacy: {
      private: 'Private',
      public: 'Shared',
      all: 'All',
    },
    noAllowanceToCreate: 'You are not allowed to create more private items.',
  },
  allowanceAlert: {
    messagePrivate: 'Your account has created {pagesCount} out of {pagesAllowance} private dashboards.',
    messagePublic: 'Your account has created {pagesCount} out of {pagesAllowance} shared dashboards.',
    subMessage: 'Unlock more by upgrading your plan.',
    action: 'Unlock more',
  },
  gridPagesContextMenu: {
    cannotDeleteMultiple: 'You are not allowed to delete the selected items.',
    deleteHint: 'You are not allowed to delete all of the selected items.',
    cannotEditMultiple: 'You are not allowed to edit the selected items.',
    cannotMakePublicMultiple: 'You are not allowed to have more shared items.',
    duplicateHint: 'You are not allowed to duplicate all of the selected items because some are shared.',
    cannotDuplicateAll: 'You are not allowed to duplicate all of the selected items.',
    changeAccess: 'Edit access rights',
    makePublicHint: 'You are not allowed to share all selected items.',
    changeAccessHint: 'You are not allowed to edit the access rights of all selected items.',
  },
  gridPagesShareDropdown: {
    creatorDescription: 'Creator of this dashboard',
    cannotShareNoAllowance: 'You are not allowed to have more shared items.',
  },
  addToGridEditor: {
    selectPage: 'Select Dashboard',
    successAction: 'Go to dashboard',
  },
  goalTree: {
    fitToScreen: 'Fit to screen',
    disabledView: 'This view is disabled.',
    empty: {
      hasFilter: 'No filter results.',
      noFilter: 'Empty.',
      cta: 'Click to add a goal.',
    },
  },
  treeViewSlideShow: {
    helpCenterLink: '@:{\'general.helpCenterBaseUrl\'}/goal-tree',
    navigation: {
      heading: 'Scrolling and zooming',
      description: `<ul>
<li>To scroll vertically, simply use your <b>scroll wheel</b> on the mouse.</li>
<li>To scroll horizontally, hold the <b>Shift key</b> on your keyboard and then use the <b>scroll wheel</b>.</li>
<li>To zoom in or out, press and hold the <b>CMD key</b> or <b>Ctrl key</b>, then use the <b>scroll wheel</b>.</li>
        </ul>`,
    },
    edit: {
      heading: 'Edit and realign goals',
      description: `<ul>
<li>To edit goals without leaving this page click the edit icon on the top right corner of a goal card.</li>
<li>To realign a goal, simply drag and drop the desired goal to a new location.</li>
        </ul>`,
    },
    viewControls: {
      heading: 'View controls',
      description: `<ul><li>Show and hide properties</li>
        <li>Change the card width to make the tree view more condense or extensive</li></ul>`,
    },
    handAndSelectTool: {
      heading: 'Select and hand tool',
      description: `<ul><li>Use the select tool to realign or to perform bulk actions.</li>
        <li>Use the hand tool to navigate content by pressing the left mouse button and moving the mouse.</li></ul>`,
    },
  },
  trashItem: { deletedAt: 'Deleted on' },
  goalStatusSettings: {
    name: 'Name',
    archived: 'Archived options',
    cannotDeletePermanently: 'Cannot delete option as it is still in use.',
    scoreTooltip: 'The threshold is used to automatically select a status based on a goal\'s time-weighted progress. Time-weighted progress measures how far a goal has advanced relative to the time that has elapsed. If the time-weighted progress surpasses the threshold, the system recommends the status closest to that progress. Closed statuses are never recommended.',
    defaultFlagToolTip: 'This flag indicates that the status will be automatically selected if the goal has not been updated yet.',
    title: 'Create a new status...',
  },
  statusOptionEditor: {
    color: 'Color',
    setAsDefault: 'Set as default',
    group: 'Group',
    score: 'Threshold',
    cannotArchiveDefaultOption: 'Cannot archive default options.',
  },
  statusChartSettingsDropdown: {
    title: 'Chart type',
    average: 'Average',
    distribution: 'Distribution',
  },
  dashboards: {
    progressDashboard: {
      helpText: 'Learn more about the Progress Dashboard',
      helpLink: 'https://help.mooncamp.com/en/goals-dashboard',
    },
    healthDashboard: {
      helpText: 'Learn more about the Health Dashboard',
      helpLink: 'https://help.mooncamp.com/en/health-dashboard',
    },
  },
};

export default mergeLangObjects(globalLang, lang);
