<template>
  <teleport
    v-if="value"
    to="#overlay"
  >
    <div
      v-if="value"
      ref="overlay"
      :class="overlayClasses"
      :style="overlayStyle"
      @mousedown.stop
    >
      <m-card
        :class="classes"
        :style="style"
        :sharp-corners="$store.state.breakpoint.smAndDown && fullscreenOnMobile"
        role="document"
        no-padding
      >
        <div
          ref="body"
          class="__content-wrapper"
          tabindex="0"
          @keydown.esc="hide"
        >
          <m-content
            v-if="!hideHeader && title !== ''"
            padding
            :style="headerStyle"
            class="__header"
          >
            <slot name="header" />
            <template v-if="!$slots.header">
              <div class="__title">
                {{ title }}
              </div>
              <div class="__actions">
                <m-btn
                  v-if="!hideCloseBtn"
                  icon="close"
                  fab
                  small
                  light
                  hide-border
                  @click="hide"
                />
              </div>
            </template>
          </m-content>
          <m-content
            :padding="!noPadding"
            :style="localBodyStyle"
            :class="['__body', bodyClass]"
          >
            <slot />
          </m-content>
          <template v-if="!hideFooter">
            <slot name="footer" />
            <template v-if="!$slots.footer">
              <m-dialog-actions
                :disabled="disabled"
                :ok-text="okText"
                :cancel-text="cancelText"
                @cancel="$emit('cancel')"
                @ok="$emit('ok')"
              />
            </template>
          </template>
        </div>
      </m-card>
    </div>
  </teleport>
</template>

<script>
import useClickOutside from 'shared/composables/click-outside';
import { guid } from 'shared/lib/uuid';
import { modalSizes } from 'shared/modal-sizes';
import { ref, toRef } from 'vue';

export default {
  name: 'MDialog',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    hideCloseBtn: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: String,
      default: modalSizes.base,
    },
    maxHeight: {
      type: String,
      default: 'calc(100vh - 14.4rem)',
    },
    top: {
      type: String,
      default: '15vh',
    },
    keepHeight: {
      type: Boolean,
      default: false,
    },
    okText: {
      type: String,
      default: '',
    },
    cancelText: {
      type: String,
      default: '',
    },
    keepOpenOnMaskClick: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    bottomMenu: {
      type: Boolean,
      default: false,
    },
    fadeIn: {
      type: Boolean,
      default: false,
    },
    fullscreenOnMobile: {
      type: Boolean,
      default: true,
    },
    center: {
      type: Boolean,
      default: false,
    },
    headerStyle: {
      type: Object,
      default: () => {},
    },
    bodyClass: {
      type: String,
      default: '',
    },
    bodyStyle: {
      type: Object,
      default: () => {},
    },
    cardStyle: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['input', 'update:value', 'close', 'cancel', 'ok', 'overlay-clicked'],
  setup(props, { emit }) {
    const handleOverlayClick = (event) => {
      event.stopPropagation();
      emit('overlay-clicked');
      if (props.keepOpenOnMaskClick) {
        return;
      }
      hide();
    };

    const hide = () => {
      emit('close');
      emit('input', false);
      emit('update:value', false);
    };
    const element = ref(null);
    useClickOutside(handleOverlayClick, toRef(props, 'value'), element);
    return { hide, element };
  },
  data() {
    return { bodyHeight: 0 };
  },
  computed: {
    overlayClasses() {
      const classes = ['m-dialog', '__overlay-container', '__overlay', guid()];
      if (this.fadeIn) {
        classes.push('-fade-in');
      }
      return classes;
    },
    localBodyStyle() {
      return { ...this.bodyStyle, maxHeight: this.$store.state.breakpoint.smAndDown ? '100vh' : this.maxHeight };
    },
    classes() {
      return [
        '__dialog-inner',
        this.noPadding ? '-no-padding' : '',
        this.hideFooter ? '-hide-footer' : '',
        this.hideHeader ? '-hide-header' : '',
        this.$slots.customHeader ? '-custom-header' : '',
        this.keepHeight ? '-keep-height' : '',
      ];
    },
    overlayStyle() {
      const s = {
        zIndex: 1050,
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        overflow: 'hidden',
      };

      if (this.center) {
        s.display = 'flex';
        s.justifyContent = 'center';
        s.alignItems = 'center';
      }

      return s;
    },
    style() {
      if (this.$store.state.breakpoint.smAndDown && this.bottomMenu) {
        return {
          width: this.mw,
          top: `calc(100vh - ${this.bodyHeight}px)`,
          height: `${this.bodyHeight}px`,
          marginLeft: 'auto',
          marginRight: 'auto',
          ...this.cardStyle,
        };
      }

      if (this.center) {
        return {
          width: this.mw,
          marginLeft: 'auto',
          height: this.$store.state.breakpoint.smAndDown && this.fullscreenOnMobile ? '100vh' : 'auto',
          marginRight: 'auto',
          ...this.cardStyle,
        };
      }

      return {
        width: this.mw,
        top: this.$store.state.breakpoint.smAndDown && this.fullscreenOnMobile ? 0 : this.top,
        height: this.$store.state.breakpoint.smAndDown && this.fullscreenOnMobile ? '100vh' : 'auto',
        marginLeft: 'auto',
        marginRight: 'auto',
        ...this.cardStyle,
      };
    },
    mw() {
      if (this.small) {
        return '400px';
      }

      if (this.$store.state.breakpoint.smAndDown && this.fullscreenOnMobile) {
        return '100vw';
      }

      if (this.$store.state.breakpoint.smAndDown && !this.fullscreenOnMobile) {
        return '90vw';
      }

      return this.maxWidth;
    },
  },
  methods: {
    focus() {
      if (this.$refs.body === null) {
        return;
      }
      this.$refs.body.focus();
    },
    init() {
      this.focus();
      if (this.$refs.body === null) {
        return;
      }
      this.bodyHeight = this.$refs.body.clientHeight;
      this.element = this.$refs.body;
    },
  },
  watch: {
    value(val) {
      if (!val) {
        this.bodyHeight = 0;
        return;
      }

      this.$nextTick(() => {
        this.$nextTick(() => {
          this.init();
        });
      });
    },
  },
  mounted() {
    if (this.value) {
      this.init();
    }
  },
};
</script>

<style lang="scss" type="text/scss">
  .m-dialog {
    &.__overlay {
      background-color: rgb(0 0 0 / 40%);

      .__dialog-inner {
        max-width: 100vw;
        overflow: auto;

        @media (max-width: $screen-size-sm) {
          top: 0;
          width: 100vw;
          height: 100vh;
          margin: 0;
        }

        .__content-wrapper {
          &:focus {
            outline-width: 0;
          }
        }

        .__header {
          display: flex;
          align-items: center;
          padding-bottom: 1.2rem;
          font-weight: $font-weight-medium;

          .__title {
            font-size: $font-size-6;
          }

          .__actions {
            margin-left: auto;
          }
        }

        .__body {
          overflow: auto;

          @media (max-width: $screen-size-sm) {
            max-height: 100vh;
          }
        }

        &.-keep-height {
          .__body {
            min-height: 85vh;
          }

          &:not(.-hide-header) {
            .__body {
              min-height: calc(85vh - 5.9rem);
            }
          }
        }
      }

      &.-fade-in {
        @keyframes fadeIn {
          0% {
            opacity: .5;
          }

          100% {
            opacity: 1;
          }
        }

        animation: fadeIn .2s ease-in-out;

        .__dialog-inner {
          @keyframes bounceIn {
            0% {
              opacity: 0;
              transform: scale(.99);
            }

            50% {
              opacity: 1;
              transform: scale(1.005);
            }

            100% {
              opacity: 1;
              transform: scale(1);
            }
          }

          animation: bounceIn .3s ease-in-out;
        }
      }
    }
  }
</style>
