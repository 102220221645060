<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.19237 9.38202H3.38686C3.21797 9.38202 3.08112 9.51893 3.08112 9.68777V21.9132C3.08112 22.0821 3.21797 22.219 3.38686 22.219H7.19237C7.36127 22.219 7.49812 22.0821 7.49812 21.9132V9.68777C7.49812 9.51893 7.36127 9.38202 7.19237 9.38202Z"
      fill="currentColor"
    />
    <path
      d="M5.29116 2.80212C3.90649 2.80212 2.78 3.9274 2.78 5.31053C2.78 6.69428 3.90649 7.81998 5.29116 7.81998C6.67473 7.81998 7.8003 6.69422 7.8003 5.31053C7.80036 3.9274 6.67473 2.80212 5.29116 2.80212Z"
      fill="currentColor"
    />
    <path
      d="M16.3558 9.07812C14.8274 9.07812 13.6975 9.73517 13.0122 10.4817V9.68772C13.0122 9.51889 12.8754 9.38198 12.7065 9.38198H9.06203C8.89314 9.38198 8.75629 9.51889 8.75629 9.68772V21.9132C8.75629 22.0821 8.89314 22.2189 9.06203 22.2189H12.8592C13.0281 22.2189 13.165 22.0821 13.165 21.9132V15.8644C13.165 13.8261 13.7186 13.032 15.1395 13.032C16.6869 13.032 16.8099 14.3051 16.8099 15.9693V21.9133C16.8099 22.0822 16.9468 22.219 17.1157 22.219H20.9143C21.0831 22.219 21.22 22.0822 21.22 21.9133V15.2074C21.22 12.1765 20.6421 9.07812 16.3558 9.07812Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default { name: 'LinkedIn' };
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>

</style>
