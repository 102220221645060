<template>
  <div>
    <m-content padding-small>
      <account-scope-filter
        v-if="writeScope !== null"
        v-model:value="writeScope"
        :props="userProps"
        :account="loggedInUserAccount"
        show-static-user-selection
        :static-users-max-tag-text-length="10"
        class="_filter"
      />
      <m-divider />
      <user-scope-tree-user-list
        v-show="writeScope !== null"
        :user-scope-tree="writeScope"
      />
    </m-content>
    <m-default-form-actions
      :clickable="scopeChanged"
      :loading="loading"
      @cancel="cancel"
      @submit="update"
    />
  </div>
</template>

<script>
import AccountScopeFilter from '@/components/filter/AccountScopeFilter.vue';
import UserScopeTreeUserList from '@/components/UserScopeTreeUserList.vue';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useProperties from '@/composables/property/property';
import { copy } from 'shared/lib/copy';
import { createProp } from '@/lib/props';
import { isEqual } from 'lodash-es';
import { mapActions } from 'vuex';
import { user as userConfig } from 'shared/api/query/configs.json';

export default {
  name: 'ResourcePolicy',
  props: {
    entity: {
      type: Object,
      required: true,
    },
    model: {
      type: String,
      required: true,
    },
    entityById: {
      type: Function,
      required: true,
    },
  },
  setup() {
    const { userProperties } = useProperties();
    const { loggedInUser } = useLoggedInUser();
    const { loggedInUserAccount } = useLoggedInUserAccount();
    return { userProperties, loggedInUser, loggedInUserAccount };
  },
  emits: ['cancel', 'submit'],
  components: { AccountScopeFilter, UserScopeTreeUserList },
  data() {
    return { writeScope: null, loading: false };
  },
  computed: {
    scopeChanged() {
      return !isEqual(this.writeScope, this.entity.resourcePolicy.writeScope);
    },
    userProps() {
      return this.userProperties.map((p) => createProp(p, false, true, true, this.userLang, userConfig.model));
    },
  },
  methods: {
    ...mapActions(['updateEntityResourcePolicy']),
    copy(obj) {
      return copy(obj);
    },
    cancel() {
      this.writeScope = this.copy(this.entity.resourcePolicy.writeScope);
      this.$emit('cancel');
    },
    update() {
      this.loading = true;
      this.updateEntityResourcePolicy({
        entity: {
          uid: this.entity.uid,
          resourcePolicy: { ...this.entity.resourcePolicy, writeScope: this.writeScope },
        },
        entityById: this.entityById,
        model: this.model,
      }).then((response) => {
        this.loading = false;
        if (response.status !== 200) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
          return;
        }
        this.writeScope = this.copy(this.entity.resourcePolicy.writeScope);
        this.$showSnackbar({ color: 'success', message: this.$t('success.updated') });
        this.$emit('submit');
      });
    },
  },
  mounted() {
    this.writeScope = this.copy((this.entity.resourcePolicy.writeScope));
  },
};
</script>

<style scoped lang="scss" type="text/scss">
</style>
