import useGoalSettings from '@/composables/logged-in-user-account/goal-settings';
import useProperties from '@/composables/property/property';
import usePropertyOptions from '@/composables/property-option/property-option';
import useRepo from '@/nebula/repo';
import {
  DIRECT_PROPERTY_CYCLE_KEY,
  DIRECT_PROPERTY_DESCRIPTION_KEY, DIRECT_PROPERTY_PARENT_KEY,
  DIRECT_PROPERTY_PROGRESS_KEY,
} from '@/lib/constants';
import { computed } from 'vue';
import { getVisibilityEdge } from '@/lib/property-settings';
import {
  propertySettings as propertySettingsConfig,
} from 'shared/api/query/configs.json';
import { propertyVisibility } from 'shared/constants.json';
import { useI18n } from 'vue-i18n';

export default function usePropertySettings({ goalType, userLang }) {
  const { t } = useI18n();
  const { goalProperties } = useProperties();
  const repo = useRepo(propertySettingsConfig.model);
  const propertyOptionSvc = usePropertyOptions();
  const { goalSettings } = useGoalSettings();

  const directProperties = computed(() => [
    {
      label: { [userLang]: t('goal.goalEditor.description') },
      goalVisibility: goalType.value === null ? propertyVisibility.alwaysVisible : goalType.value.descriptionVisibility,
      key: DIRECT_PROPERTY_DESCRIPTION_KEY,
      icon: 'align-left',
      isDirect: true,
      type: 'description',
    },
    {
      label: { [userLang]: t('goal.goalEditor.progressMeasurement') },
      goalVisibility: goalType.value === null ? propertyVisibility.alwaysVisible : goalType.value.goalProgressVisibility,
      key: DIRECT_PROPERTY_PROGRESS_KEY,
      icon: 'line-chart',
      isDirect: true,
      type: 'progressMeasurement',
    },
    {
      label: { [userLang]: t('goal.goalEditor.goalCycle') },
      goalVisibility: goalType.value === null ? propertyVisibility.alwaysVisible : goalType.value.goalCycleVisibility,
      key: DIRECT_PROPERTY_CYCLE_KEY,
      icon: 'retweet',
      isDirect: true,
      type: 'goalCycle',
    },
    {
      label: { [userLang]: t('goal.goalEditor.parent', { title: goalSettings.value.featureNameSingular }) },
      goalVisibility: goalType.value === null ? propertyVisibility.alwaysVisible : goalType.value.goalParentVisibility,
      key: DIRECT_PROPERTY_PARENT_KEY,
      icon: 'cluster',
      isDirect: true,
      type: 'parents',
    },
  ]);

  const getVisibility = (property, type) => {
    if (type.value === null) {
      return propertyVisibility.alwaysVisible;
    }

    // TODO: This will only work after reverse edges PR is merged.
    try {
      return type.value.propertySettings.find((ps) => ps.property.uid === property.uid).visibility;
    } catch (err) {
      throw new Error(`error getting property settings, property: ${property.uid}, propertySettings: ${JSON.stringify(type.value.propertySettings)}, err: ${err}`);
    }
  };

  const allGoalProperties = computed(() => [...directProperties.value, ...goalProperties.value.map((p) => ({
    ...p,
    key: p.uid,
    goalVisibility: getVisibility(p, goalType),
  }))]);

  const updateVisibility = (property, value) => {
    if (property.isDirect) {
      return updateDirectPropVisibility(property, value);
    }

    return updatePropVisibility(property, value);
  };

  const updateDirectPropVisibility = (property, value) => propertyOptionSvc.updateSingle(
    {
      uid: goalType.value.uid,
      [getVisibilityEdge(property.key)]: value,
    },
  ).then(() => goalType);

  const updatePropVisibility = (property, value) => repo.updateSingle({
    uid: goalType.value.propertySettings.find((ps) => ps.property.uid === property.uid).uid,
    visibility: value,
  }).then((data) => data);

  return {
    goalProperties: allGoalProperties,
    updateVisibility,
  };
}
