<template>
  <svg
    class="SVGInline-svg"
    width="14"
    height="10"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <!--eslint-disable-->
    <path d="M8 8h6c0 1.10457-.8954 2-2 2H8V8zM0 8h6v2H0V8zM5 4h9v2H5V4zM0 4h3v2H0V4zM10 0h4v2h-4V0zM0 0h8v2H0V0z"/>
    <!--eslint-enable-->
  </svg>
</template>

<script>
export default { name: 'LongText' };
</script>

<style scoped lang="scss" type="text/scss">

</style>
