<template>
  <m-dropdown :title="$t('statusChartSettingsDropdown.title')">
    <m-btn
      fab
      small
      hide-border
      icon="settings"
      light
    />
    <template #overlay>
      <m-card list>
        <m-card-item
          @click="showMenu = true"
        >
          <div class="_left">
            {{ $t('statusChartSettingsDropdown.title') }}
          </div>
          <template #right>
            <m-dropdown
              v-model:value="showMenu"
            >
              <div
                class="_right"
              >
                <div class="_text">
                  {{ $t(`statusChartSettingsDropdown.${goalSettings.progressDashboardStatusChart}`) }}
                </div>
                <div class="_icon">
                  <m-icon
                    type="down"
                    :color="$colors.grey.lighten1"
                    size="11"
                  />
                </div>
              </div>
              <template #overlay>
                <m-card list>
                  <m-card-item
                    v-for="type in statusChartType.all"
                    :key="type"
                    @click="changeType(type)"
                  >
                    {{ $t(`statusChartSettingsDropdown.${type}`) }}
                    <template
                      v-if="goalSettings.progressDashboardStatusChart === type"
                      #right
                    >
                      <m-icon
                        type="check"
                        :color="$colors.grey.lighten2"
                      />
                    </template>
                  </m-card-item>
                </m-card>
              </template>
            </m-dropdown>
          </template>
        </m-card-item>
      </m-card>
    </template>
  </m-dropdown>
</template>

<script setup>
import useGoalSettings from '@/composables/logged-in-user-account/goal-settings';
import { ref } from 'vue';
import { statusChartType } from 'shared/constants.json';

const showMenu = ref(false);

const {
  goalSettings,
  updateGoalSettings,
} = useGoalSettings();

const changeType = (chartType) => {
  updateGoalSettings({ uid: goalSettings.value.uid, progressDashboardStatusChart: chartType });
};
</script>

<style scoped lang="scss">
._right {
  display: flex;
  justify-content: center;
  align-items: center;

  ._text {
    color: $font-color-secondary;
    margin-right: .4rem;
  }
}
</style>
