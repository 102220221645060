import { DEFAULT_ATTRIBUTES, UID } from 'shared/api/query/constants';
import { comment, meeting as meetingConfig, update, user } from 'shared/api/query/configs.json';
import { userEdgesSlim } from 'shared/api/query/edges';

export const children = [
  ...DEFAULT_ATTRIBUTES,
  { attr: comment.edges.message, default: null },
  {
    attr: comment.edges.creator,
    model: user.model,
    default: null,
    children: userEdgesSlim,
  },
  {
    attr: comment.edges.update,
    model: update.model,
    children: [{ attr: UID }],
    default: null,
  },
  {
    attr: comment.edges.meeting,
    model: meetingConfig.model,
    children: [{ attr: UID }],
    default: null,
  },
];
