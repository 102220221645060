import { computed } from 'vue';

export default function useUser(user) {
  const displayName = computed(() => {
    const name = `${user.value.firstName} ${user.value.lastName}`.trim();
    if (name === '') {
      return user.value.email;
    }
    return name;
  });

  return { displayName };
}
