<template>
  <div class="m-form-item">
    <div
      v-if="label !== ''"
      class="_label"
    >
      {{ label }}
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'MFormItem',
  props: {
    label: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .m-form-item {
    margin-bottom: 2.4rem;

    ._label {
      margin-bottom: .4rem;
      color: $font-color-secondary;
    }
  }
</style>
