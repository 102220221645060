import { AND } from '@/lib/filter/scope-tree';
import { guid } from 'shared/lib/uuid';
import { userScopeType } from 'shared/constants.json';

export const goalTypesFilter = (goalTypeProperty, goalType) => ({
  op: AND,
  key: guid(),
  children: [
    {
      key: guid(),
      scope: {
        type: userScopeType.property,
        property: goalTypeProperty,
        selectedOptions: [goalType],
      },
    },
  ],
});
