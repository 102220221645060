<template>
  <collection
    class="collection-component"
    contenteditable="false"
    :data-collection-id="node.attrs.id"
    :data-collection-title="title"
    :collection="collection"
    @focus-below="focusBelow"
  >
    <m-spinner v-if="loading" />
  </collection>
</template>

<script>
import Collection from '@/components/Collection.vue';
import { TextSelection } from 'prosemirror-state';
import { children as collectionChildren } from '@/api/query/collection';
import {
  collection as collectionConfig,
} from 'shared/api/query/configs.json';
import { findInArray } from 'shared/lib/array/array';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'CollectionComponent',
  props: {
    node: {
      type: Object,
      required: true,
    },
    updateAttrs: {
      type: Function,
      required: true,
    },
    view: {
      type: Object,
      required: true,
    },
    getPos: {
      type: Function,
      required: true,
    },
  },
  components: { Collection },
  data() {
    return { loading: false };
  },
  computed: {
    ...mapState({ collections: (state) => state.collection }),
    id() {
      return parseInt(`${this.node.attrs.id}`, 10);
    },
    title: {
      get() {
        return this.node.attrs.title;
      },
      set(val) {
        this.updateAttrs({ title: val });
      },
    },
    collection() {
      return findInArray({ haystack: this.collections, needle: this.id, defaultValue: {} });
    },
  },
  methods: {
    ...mapActions(['getEntityV2', 'updateEntityV2']),
    focusBelow() {
      const view = this.view;
      const { doc, tr } = view.state;
      const $start = doc.resolve(this.getPos() + 1);
      const transaction = tr.setSelection(new TextSelection($start));
      view.dispatch(transaction);
      view.dom.focus();
    },
    get() {
      this.getEntityV2({
        id: parseInt(`${this.node.attrs.id}`, 10),
        model: collectionConfig.model,
        attributes: collectionChildren,
      }).then((response) => {
        if (response.status !== 200) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
        }
      });
    },
  },
  mounted() {
    this.get();
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .collection-component {
    margin-bottom: 1rem;
    white-space: normal;

    ._title {
      input {
        width: 100%;
        font-size: $font-size-7;
        font-weight: $font-weight-semibold;
        border: none;
        outline: none;
      }
    }
  }
</style>
