<template>
  <m-dropdown
    v-model:value="show"
    class="app-feedback-dropdown"
    :title="$t('appFeedbackDropdown.feedback')"
    block
    @hide="handleHide"
  >
    <component
      :is="triggerComponent"
      :small="small"
      icon="message"
      hide-border
    >
      {{ label === '' ? $t('appFeedbackDropdown.feedback') : label }}
    </component>
    <template #overlay>
      <m-card
        small-padding
        class="_overlay"
        :style="$store.state.breakpoint.smAndDown ? {} : { height: '23.5rem', width: '33rem'}"
      >
        <template v-if="!submitted">
          <m-form-item :label="$t(`appFeedbackDropdown.${topic}.label`)">
            <m-focusable
              show-border
              :style="{ width: $store.state.breakpoint.smAndDown ? '100%' : '30rem', height: '10rem', display: 'flex', alignItems: 'flex-start', overflow: 'auto' }"
            >
              <simple-editor
                ref="editor"
                :style="{ minHeight: '9.2rem' }"
                :initial-value="''"
                :read-only="false"
                :placeholder="placeholder === '' ? $t('appFeedbackDropdown.placeholder') : placeholder"
                :no-title-text="$t('appFeedbackDropdown.placeholder')"
                full-width
                auto-focus
                @ctrl-enter="submit"
                @update:value="updateComment"
              />
            </m-focusable>
          </m-form-item>
          <m-btn
            color="primary"
            :style="{ marginLeft: 'auto' }"
            :loading="submitLoading"
            @click="submit"
          >
            {{ $t('general.send') }}
          </m-btn>
        </template>
        <div
          v-else
          class="_success"
        >
          <m-icon
            type="check-circle"
            :color="$colors.blue.base"
            size="24"
            class="_icon"
          />
          <div class="_text">
            {{ $t('appFeedbackDropdown.success') }}
          </div>
          <m-btn @click="handleHide">
            {{ $t('general.close') }}
          </m-btn>
        </div>
      </m-card>
    </template>
  </m-dropdown>
</template>

<script setup>
import SimpleEditor from '@/components/SimpleEditor.vue';
import useAppFeedback from '@/composables/app-feedback/app-feedback';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useSnackBar from '@/composables/snackbar';
import { appFeedbackType } from 'shared/constants.json';
import { logCatch } from '@/lib/logger/logger';
import { ref } from 'vue';

const props = defineProps({
  label: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: '',
  },
  topic: {
    type: String,
    required: true,
  },
  small: {
    type: Boolean,
    default: false,
  },
  triggerComponent: {
    type: String,
    default: 'm-btn',
  },
});
const submitted = ref(false);
const comment = ref(null);
const show = ref(false);
const updateComment = (val) => {
  comment.value = val;
};

const { mutateAppFeedback } = useAppFeedback();
const { loggedInUser } = useLoggedInUser();
const { loggedInUserAccount } = useLoggedInUserAccount();

const snackbar = useSnackBar();

const submitLoading = ref(false);
const submit = () => {
  submitLoading.value = true;
  mutateAppFeedback({
    user: { uid: loggedInUser.value.uid },
    account: { uid: loggedInUserAccount.value.uid },
    comment: comment.value,
    topic: props.topic,
    ignored: false,
    type: appFeedbackType.featureFeedback,
  }).then(() => {
    submitted.value = true;
  }).catch(logCatch(() => {
    snackbar.error();
  })).finally(() => {
    submitLoading.value = false;
  });
};
const handleHide = () => {
  show.value = false;
  submitted.value = false;
};
</script>

<style scoped lang="scss" type="text/scss">
  ._overlay {
    ._success {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      align-items: center;

      ._text {
        margin: 1rem 0 3rem;
      }
    }
  }

</style>
