import { AND } from '@/lib/filter/scope-tree';
import { OR } from 'shared/lib/access';
import { goal as goalConfig } from 'shared/api/query/configs.json';
import { goalProgressMeasurement, propertyType } from 'shared/constants.json';
import { goalTypesFilter } from '@/lib/filter/goal/goals-type-filter';
import { guid } from 'shared/lib/uuid';

export const measurementTypesFilter = (measurementTypes) => ({
  key: guid(),
  op: AND,
  children: [{
    key: guid(),
    scope: {
      directProperty: {
        edgeName: goalConfig.edges.progressMeasurement,
        type: propertyType.options,
      },
      isEmpty: false,
      selectedOptions: measurementTypes.map((m) => ({ value: m })),
    },
  }],
});

export const allGoalTypesMeasurementTypesFilter = (goalTypeProperty, goalTypes) => {
  const children = goalTypes.reduce((res, goalType) => {
    let wrongMeasurementTypes = [];
    if (goalType.allowedMeasurementTypes.length > 0
      && goalType.allowedMeasurementTypes.length < goalProgressMeasurement.all.length) {
      wrongMeasurementTypes = goalProgressMeasurement.all.filter((mType) => !goalType.allowedMeasurementTypes.includes(mType));
    }
    if (wrongMeasurementTypes.length > 0) {
      res.push({
        op: AND,
        key: guid(),
        children: [
          goalTypesFilter(goalTypeProperty, goalType),
          measurementTypesFilter(wrongMeasurementTypes),
        ],
      });
    }
    return res;
  }, []);
  return {
    key: guid(),
    op: OR,
    children,
  };
};
