import CollectionComponent from '@/components/editor/CollectionComponent.vue';
import Node from '@/tiptap/tiptap/Utils/Node';

export default class Collection extends Node {
  constructor(options = {}) {
    super(options);
  }

  get name() {
    return 'collection';
  }

  get schema() {
    return {
      inline: false,
      attrs: {
        id: {},
        title: {},
      },
      group: 'block',
      draggable: true,
      atom: true,
      toDOM: (node) => [
        'div',
        {
          class: this.options.fileUploadClass,
          'data-collection-id': node.attrs.id,
          'data-collection-title': node.attrs.title,
        },
        node.attrs.title,
      ],
      parseDOM: [
        {
          tag: 'div[data-collection-id]',
          getAttrs: (dom) => {
            const id = dom.getAttribute('data-collection-id');
            const title = dom.getAttribute('data-collection-title');
            return { id, title };
          },
        },
      ],
    };
  }

  get view() {
    return CollectionComponent;
  }

  commands({ type }) {
    return (attrs) => (state, dispatch) => {
      const { selection } = state;
      const position = selection.$cursor ? selection.$cursor.pos : selection.$to.pos;
      const node = type.create(attrs);
      const transaction = state.tr.insert(position - 1, node);
      dispatch(transaction);
    };
  }
}
