// used for determining what flags a route demands for giving access
import { accessGroupFlag, routeName } from 'shared/constants.json';

const routeAccessMap = {
  [routeName.goalInsights]: [accessGroupFlag.goalDashboardView],
  [routeName.userList]: [accessGroupFlag.userWriteAccess],
  [routeName.spaceList]: [accessGroupFlag.spaceWriteAccess],
  [routeName.accessGroupList]: [accessGroupFlag.accessGroupWriteAccess],
  [routeName.propertysNoSelection]: [accessGroupFlag.propertyWriteAccess],
  [routeName.identityAndProvisioning]: [accessGroupFlag.accountWriteAccess],
  [routeName.accountSettings]: [accessGroupFlag.accountWriteAccess],
  [routeName.plans]: [accessGroupFlag.accountWriteAccess],
  [routeName.goalSettings]: [accessGroupFlag.goalCycleWriteAccess],
  [routeName.goalCycles]: [accessGroupFlag.goalCycleWriteAccess],
  [routeName.goalTypes]: [accessGroupFlag.goalCycleWriteAccess],
};

export const routeNameToAccess = (route) => routeAccessMap[route];
