<template>
  <div
    class="tree-item"
    :style="style"
  >
    <goal-card
      class="_card"
      :entity="item.entity"
      :identifier="item.index"
      :data-id="item.entity.uid"
      :data-identifier="item.index"
      :props="props"
      title-layout="column"
      :component-key="item.entity.uid"
      :selected="selected"
      :clickable="!grabbing"
      :selected-goal-ids="selectedGoalIds"
      :can-create="canCreate"
      :read-only="readOnly"
      prevent-scroll-on-focus
      hide-hover
      show-expand-buttons
      :wrap="wrapCells"
      :max-body-height="maxBodyHeight"
      :update-property="updateProperty"
      :dragging-over-bottom="draggingOverBottom"
      :dragging-over-top="draggingOverTop"
      :dragging-over-right="draggingOverRight"
      :dragging-over-left="draggingOverLeft"
      :style="grabbing ? { cursor: 'grabbing' } : {}"
      @create-beneath="({ option }) => handleCreate({ option }, 'bottom')"
      @select="$emit('select', $event)"
      @open="$emit('open', $event)"
      @selection-right-click="$emit('selection-right-click', $event)"
      @progress-clicked="$emit('progress-clicked', $event)"
      @expand-all="$emit('expand-all', $event)"
      @collapse-all="$emit('collapse-all', $event)"
    >
      <template
        #footer
      >
        <m-content
          v-if="item.hasChildren || countComments > 0 || countUpdates > 0 || Object.keys(errorMessages).length > 0"
          :padding-bottom="9"
          :padding-x="9"
          :padding-top="6"
          class="_summary"
        >
          <m-tooltip
            v-if="countComments > 0"
            placement="bottomCenter"
          >
            <div class="_summary-item">
              <m-icon
                type="message"
                :color="$colors.grey.lighten1"
                size="16"
              />
              <span class="_text">{{ countComments < summaryLimit ? countComments : `${summaryLimit-1}+` }}</span>
            </div>
            <template #title>
              <div style="color: white;">
                {{ $t('goalCard.summary.comments', countComments, {count: countComments}) }}
              </div>
              <div
                v-if="lastComment !== undefined"
                :style="{ color: tooltipGrey }"
              >
                {{ $t('goalCard.summary.lastCommentDate', { date: formatDate(lastComment.createdAt) }) }}
              </div>
            </template>
          </m-tooltip>
          <m-tooltip
            v-if="countUpdates > 0"
            placement="bottomCenter"
          >
            <div class="_summary-item">
              <m-icon
                type="history"
                :color="$colors.grey.lighten1"
                size="16"
              />
              <span class="_text">{{ countUpdates < summaryLimit ? countUpdates : `${summaryLimit-1}+` }}</span>
            </div>
            <template #title>
              <div style="color: white;">
                {{ $t('goalCard.summary.updates', countUpdates, {count: countUpdates}) }}
              </div>
              <div
                v-if="lastUpdate !== undefined"
                :style="{ color: tooltipGrey }"
              >
                {{ $t('goalCard.summary.lastUpdateDate', { date: formatDate(lastUpdate.createdAt) }) }}
              </div>
            </template>
          </m-tooltip>
          <error-indicator-dropdown
            class="_error-btn"
            :can-edit="!readOnly"
            :error-messages="errorMessages"
            :has-errors="Object.keys(errorMessages).length > 0"
            :goal="item.entity"
            :update-property="updateProperty"
            :style="{ fontSize: $fontSizes[2] }"
            @select="$emit('select', { goal: item.entity })"
          />
          <m-btn
            v-if="item.hasChildren"
            data-cy="expand-btn"
            class="_btn"
            small
            light
            :icon="expanded ? 'up' : 'down'"
            icon-size="14"
            :loading="loading"
            :icon-color="$colors.grey.lighten1"
            @mouseover="scheduleExpand"
            @mouseleave="cancelExpand"
            @click.stop="$emit('toggle-expand', item)"
          >
            {{ countChildren < childrenLimit ? countChildren : `${childrenLimit-1}+` }}
          </m-btn>
        </m-content>
        <template v-if="canCreate && !dragging">
          <goal-create-dropdown
            v-for="position in ['top', 'right', 'bottom', 'left']"
            :key="position"
            :class="['_add', `-${position}`]"
            :insert-position="position"
            :goal="item.entity"
            :index="item.index"
            @create-beneath="handleCreate($event, position)"
            @click.stop
          >
            <template
              #default="goalCreateDropdownProps"
            >
              <m-tooltip :disabled="!goalCreateDropdownProps.disabled">
                <m-btn
                  xs
                  icon="plus"
                  fab
                  light
                  :disabled="goalCreateDropdownProps.disabled"
                  :loading="createLoading && createLoadingPosition === position"
                />
                <template #title>
                  {{ $t('treeItem.noTypeAllowed') }}
                </template>
              </m-tooltip>
            </template>
          </goal-create-dropdown>
        </template>
      </template>
    </goal-card>
  </div>
</template>

<script>
import ErrorIndicatorDropdown from '@/components/goal/ErrorIndicatorDropdown.vue';
import GoalCard from '@/components/goal/GoalCard.vue';
import GoalCreateDropdown from '@/components/goal/GoalCreateDropdown.vue';
import { DateTime } from 'luxon';
import { rgbaToHex } from 'shared/lib/color';

export default {
  name: 'TreeItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
    width: {
      type: Number,
      required: true,
    },
    props: {
      type: Array,
      default: () => [],
    },
    canCreate: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    grabbing: {
      type: Boolean,
      default: false,
    },
    height: {
      type: Number,
      required: true,
    },
    maxBodyHeight: {
      type: Number,
      required: true,
    },
    wrapCells: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    dragging: {
      type: Boolean,
      default: false,
    },
    draggingOverTop: {
      type: Boolean,
      default: false,
    },
    draggingOverBottom: {
      type: Boolean,
      default: false,
    },
    draggingOverRight: {
      type: Boolean,
      default: false,
    },
    draggingOverLeft: {
      type: Boolean,
      default: false,
    },
    selectedGoalIds: {
      type: Array,
      default: () => ([]),
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    updateProperty: {
      type: Function,
      default: () => {},
    },
    cardPadding: {
      type: Number,
      default: 0,
    },
    createLoading: {
      type: Boolean,
      default: false,
    },
    showErrors: {
      type: Boolean,
      default: false,
    },
    validate: {
      type: Function,
      default: () => ({}),
    },
  },
  components: { GoalCard, GoalCreateDropdown, ErrorIndicatorDropdown },
  emits: ['open', 'select', 'toggle-expand', 'create', 'selection-right-click', 'progress-clicked', 'expand-all', 'collapse-all', 'schedule-expand', 'cancel-expand'],
  data() {
    return {
      summaryLimit: 1000,
      childrenLimit: 100,
      createLoadingPosition: '',
    };
  },
  computed: {
    errorMessages() {
      if (!this.showErrors) {
        return {};
      }
      return this.validate({ toValidate: this.item.entity, selectRulesFrom: this.item.entity });
    },
    style() {
      return {
        width: `${this.width}px`,
        position: 'absolute',
        top: `${this.item.y}px`,
        left: `${this.item.x}px`,
        padding: `${this.cardPadding}px`,
      };
    },
    tooltipGrey() {
      return rgbaToHex(this.$colors.grey.lighten2);
    },
    countComments() {
      return this.item.entity.commentCount;
    },
    lastComment() {
      return this.countComments > 0 ? this.item.entity.lastComment[0] : undefined;
    },
    countUpdates() {
      return this.item.entity.goalActivityCount;
    },
    lastUpdate() {
      return this.countUpdates > 0 ? this.item.entity.lastGoalActivity[0] : undefined;
    },
    countChildren() {
      return this.item.entity.children.length;
    },
  },
  methods: {
    scheduleExpand() {
      if (this.dragging) {
        this.$emit('schedule-expand', this.item);
      }
    },
    cancelExpand() {
      this.$emit('cancel-expand', this.item);
    },
    formatDate(date) {
      return DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_MED);
    },
    handleCreate({ option }, position) {
      this.createLoadingPosition = position;
      this.$emit('create', { node: this.item, option, position });
    },
  },
  watch: {
    createLoading(val) {
      if (val) {
        return;
      }
      this.createLoadingPosition = '';
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
.tree-item {
  transition: top $goal-tree-transition-length, left $goal-tree-transition-length;
  position: relative;

  ._add {
    display: none;
    position: absolute;
    background-color: white;
    border-radius: $default-border-radius;

    &.-top {
      left: 50%;
      top: -1.2rem;
      transform: translate(-50%, 0%);
    }

    &.-right {
      right: -1.2rem;
      top: 50%;
      transform: translate(0%, -50%);
    }

    &.-bottom {
      left: 50%;
      bottom: -1.2rem;
      transform: translate(-50%, 0%);
    }

    &.-left {
      left: -1.2rem;
      top: 50%;
      transform: translate(0%, -50%);
    }
  }

  &:hover {
    ._add {
      display: block;
    }
  }
}

._card {
  ._summary {
    display: flex;
    align-items: center;

    ._summary-item {
      display: flex;
      align-items: center;
      color: $font-color-secondary;
      margin-right: .4rem;

      ._text {
        padding: 0 .4rem;
        font-size: $font-size-2;
        font-weight: $font-weight-medium;
      }
    }

    ._btn {
      margin-left: auto;
      font-size: $font-size-2;

      ._icon {
        padding-right: 0.4rem;
      }
    }
  }
}
</style>
