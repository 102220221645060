<template>
  <div class="user-provisioning-status">
    <full-screen-spinner
      v-if="provisioningStatus === null"
      :style="{minHeight: '10rem'}"
    />
    <div v-if="provisioningStatus !== null">
      <m-alert
        v-if="notActiveUsers.length > 1"
        class="_bulk-invitation-box"
        type="warning"
        hide-icon
      >
        <i18n-t
          tag="div"
          class="_text"
          keypath="userList.bulkInvitationBox.text"
        >
          <template #users>
            <span class="_text-bold">
              {{ $t('userList.bulkInvitationBox.userCount', { userCount: notActiveUsers.length }) }}
            </span>
          </template>
        </i18n-t>
        <m-btn
          color="secondary"
          :to="{ name: routeName.userList }"
        >
          {{ $t('userList.bulkInvitationBox.goToMembers') }}
        </m-btn>
      </m-alert>
      <div class="_item">
        <div class="_label">
          {{ $t('userProvisioningStatus.provisionedUserCount') }}:
        </div>
        <div class="_content">
          {{ localProvisioningStatus.provisionedUserCount }}
        </div>
      </div>
      <div class="_item">
        <div class="_label">
          {{ $t('userProvisioningStatus.userCount') }}:
        </div>
        <div class="_content">
          {{ localProvisioningStatus.userCount }}
        </div>
      </div>
      <div class="_item">
        <div class="_label">
          {{ $t('userProvisioningStatus.updatesAvailable') }}:
        </div>
        <div class="_content">
          {{ localProvisioningStatus.updatesAvailable }}
        </div>
      </div>
      <div class="_item">
        <div class="_label">
          {{ $t('userProvisioningStatus.errors') }}:
        </div>
        <div class="_content">
          <div>{{ errors }}</div>
          <div :style="{ paddingLeft: '2.4rem', marginTop: '-0.3rem'}">
            <m-btn
              v-if="errors > 0"
              small

              @click="showErrors = true"
            >
              {{ $t('userProvisioningStatus.showErrors') }}
            </m-btn>
          </div>
        </div>
      </div>
    </div>

    <m-dialog
      v-model:value="showErrors"
      :max-width="$modalSizes.xl"
      hide-footer
    >
      <user-provisioning-errors />
    </m-dialog>
  </div>
</template>

<script>
import FullScreenSpinner from 'shared/components/FullScreenSpinner.vue';
import UserProvisioningErrors from '@/components/security/UserProvisioningErrors.vue';
import useUserProvisioning from '@/composables/user-provisioning/user-provisioning';
import useUsers from '@/composables/user/users';
import { USER_STATUS_ACTIVE, USER_STATUS_INVITE_PENDING, USER_STATUS_NO_INVITATION } from '@/lib/constants';
import { mapState } from 'vuex';
import { routeName } from 'shared/constants.json';

export default {
  name: 'UserProvisioningStatus',
  components: { UserProvisioningErrors, FullScreenSpinner },
  setup() {
    const { getInvitationStatus, users } = useUsers();
    const { userProvisioning } = useUserProvisioning();
    return { getInvitationStatus, users, userProvisioning };
  },
  data() {
    return {
      routeName,
      showErrors: false,
    };
  },
  computed: {
    ...mapState({ provisioningStatus: (state) => state.provisioningStatus }),
    notActiveUsers() {
      return this.users.filter(this.needsInvitation);
    },
    localProvisioningStatus() {
      if (this.provisioningStatus === null) {
        return { provisionedUserCount: 0, userCount: 0, updatesAvailable: 0 };
      }

      return this.provisioningStatus;
    },
    errors() {
      return this.userProvisioning.provisionedUsers.filter((pu) => pu.provisioningError !== null).length;
    },
  },
  methods: {
    needsInvitation(user) {
      return this.getInvitationStatus(user) === USER_STATUS_NO_INVITATION;
    },
    getStatus(user) {
      if (user.personalInfosCompleted === null) {
        return USER_STATUS_INVITE_PENDING;
      }
      return USER_STATUS_ACTIVE;
    },
  },
};
</script>

<style lang="scss" text="text/scss" scoped>
  .user-provisioning-status {
    ._bulk-invitation-box {
      display: flex;
      align-items: center;

      ._text {
        padding-right: 2.4rem;
        margin-right: auto;
        white-space: pre-wrap;
      }

      ._text-bold {
        font-weight: $font-weight-semibold;
      }
    }

    ._item {
      display: flex;
      margin-bottom: .4rem;

      ._label {
        flex: 0 0 26rem;
        color: $font-color-secondary;
      }

      ._content {
        display: flex;
      }
    }

    ._overlay {
      position: fixed;

      /* top: 0;
      left: 0; */
      width: 100%;
      height: 6rem;
      background-color: rgba(0, 0, 0, .4);

      ._spinner {
        position: relative;
        top: 1.2rem;
        left: 50%;
      }
    }
  }

</style>
