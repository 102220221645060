import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useRepo from '@/nebula/repo';
import { DateTime } from 'luxon';
import { computed } from 'vue';
import { customDatasourceProperty, dataSourceType } from 'shared/constants.json';
import { dataSource as dataSourceConfig, msCalendarEvent as msCalendarEventConfig, user as userConfig } from 'shared/api/query/configs.json';
import { msCalendarDataSourceQuery } from '@/api/query/ms-calendar';

export default function useMSCalendarDataSource() {
  const repo = useRepo(dataSourceConfig.model);
  const userRepo = useRepo(userConfig.model);
  const calendarEventRepo = useRepo(msCalendarEventConfig.model);
  const { loggedInUser } = useLoggedInUser();

  const msCalendarDataSource = computed(() => repo.entityList.value.find((ds) => ds.type === dataSourceType.msCalendar));

  const updateCalendarEvents = ({ filter, pagination }) => repo.query(msCalendarDataSourceQuery({ user: loggedInUser.value, pagination, filter }))
    .then(({ result }) => {
      if (result.length === 0) {
        return;
      }
      repo.updateSingle(
        msCalendarDataSource.value[customDatasourceProperty.msCalendarEvents].map((event) => ({
          ...event,
          start: {
            ...event.start,
            dateTime: DateTime.fromISO(event.start.dateTime),
          },
        })),
        { commitToRemote: false },
      );
    });

  const createCalendarDataSource = (entity) => repo.createSingle(entity).then((entity) => userRepo.updateSingle({ uid: entity.creator.uid, msCalendarDataSource: entity }, { commitToRemote: false }));

  const getCalendarDataSource = () => {
    const q = msCalendarDataSourceQuery({ user: { uid: loggedInUser.value.uid } });
    return repo.query(q);
  };

  return {
    createCalendarDataSource,
    msCalendarDataSource,
    updateCalendarEvents,
    getCalendarDataSource,
    createCalendarEvent: calendarEventRepo.createSingle,
    updateCalendarEvent: calendarEventRepo.updateSingle,
  };
}
