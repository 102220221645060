import colors from 'shared/colors';
import {
  BLUE,
  BROWN,
  DARK_GREY,
  GREEN,
  LIGHT_GREY,
  ORANGE,
  PINK,
  PURPLE,
  RED,
  YELLOW,
} from 'shared/lib/icon-picker';
import { optionColor } from 'shared/constants.json';
import { rgbaToHex } from 'shared/lib/color';

export const getColor = (color) => {
  switch (color) {
    case optionColor.default:
      return rgbaToHex(colors.grey.lighten4);
    case optionColor.grey:
      return rgbaToHex(colors.grey.lighten3);
    case optionColor.brown:
      return colors.brown.lighten4;
    case optionColor.blue:
      return colors.blue.lighten3;
    case optionColor.green:
      return colors.green.lighten4;
    case optionColor.red:
      return colors.red.lighten4;
    case optionColor.yellow:
      return colors.yellow.lighten3;
    case optionColor.purple:
      return colors.purple.lighten3;
    case optionColor.pink:
      return colors.pink.lighten3;
    case optionColor.orange:
      return colors.orange.lighten4;
    default:
      return '';
  }
};

export const mapIconBackgroundColor = (color) => {
  switch (color) {
    case DARK_GREY:
    case LIGHT_GREY:
      return rgbaToHex(colors.grey.lighten3);
    case BROWN:
      return colors.brown.lighten5;
    case YELLOW:
      return colors.yellow.lighten4;
    case ORANGE:
      return colors.orange.lighten4;
    case GREEN:
      return colors.green.lighten4;
    case BLUE:
      return colors.blue.lighten3;
    case PURPLE:
      return colors.purple.lighten5;
    case PINK:
      return colors.pink.lighten4;
    case RED:
      return colors.red.lighten5;
    default:
      return rgbaToHex(colors.grey.lighten3);
  }
};

export const mapIconBackgroundColorHover = (color) => {
  switch (color) {
    case DARK_GREY:
    case LIGHT_GREY:
      return rgbaToHex(colors.grey.lighten2);
    case BROWN:
      return colors.brown.lighten3;
    case YELLOW:
      return colors.yellow.lighten2;
    case ORANGE:
      return colors.orange.lighten3;
    case GREEN:
      return colors.green.lighten3;
    case BLUE:
      return colors.blue.lighten2;
    case PURPLE:
      return colors.purple.lighten4;
    case PINK:
      return colors.pink.lighten3;
    case RED:
      return colors.red.lighten4;
    default:
      return rgbaToHex(colors.grey.lighten2);
  }
};

export const mapOptionToIconColor = (color) => {
  switch (color) {
    case optionColor.default:
      return LIGHT_GREY;
    case optionColor.grey:
      return DARK_GREY;
    default:
      return color;
  }
};

export const getColoredIcon = (option) => {
  if (option.icon === '') {
    return '';
  }
  if (option.color === null) {
    return option.icon;
  }
  return `${option.icon}_${mapOptionToIconColor(option.color)}`;
};
