import { NodeSelection } from 'prosemirror-state';

export const equalNodeType = (nodeType, node) => (Array.isArray(nodeType) && nodeType.indexOf(node.type) > -1) || node.type === nodeType;

export const findBlockNodes = (node, descend) => findChildren(node, (child) => child.isBlock, descend);

export const findChildren = (node, predicate, descend) => {
  if (!node) {
    throw new Error('Invalid "node" parameter');
  } else if (!predicate) {
    throw new Error('Invalid "predicate" parameter');
  }
  return flatten(node, descend).filter((child) => predicate(child.node));
};

// eslint-disable-next-line
export const findParentNodeClosestToPos = ($pos, predicate) => {
  for (let i = $pos.depth; i > 0; i -= 1) {
    const node = $pos.node(i);

    if (predicate(node)) {
      return {
        pos: i > 0 ? $pos.before(i) : 0,
        start: $pos.start(i),
        depth: i,
        node,
      };
    }
  }
};

export const findParentNode = (predicate) => (selection) => findParentNodeClosestToPos(selection.$from, predicate);

export const findSelectedNodeOfType = (nodeType) =>
  // eslint-disable-next-line
   ((selection) => {
    if (isNodeSelection(selection)) {
      const { node } = selection;
      const { $from } = selection;

      if (equalNodeType(nodeType, node)) {
        return { node, pos: $from.pos, depth: $from.depth };
      }
    }
  });

export const flatten = (node) => {
  // eslint-disable-next-line
  const descend = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true

  if (!node) {
    throw new Error('Invalid "node" parameter');
  }
  const result = [];
  // eslint-disable-next-line
  node.descendants((child, pos) => {
    result.push({ node: child, pos });
    if (!descend) {
      return false;
    }
  });
  return result;
};

export const getMarkAttrs = (state, type) => {
  const { from, to } = state.selection;
  let marks = [];

  state.doc.nodesBetween(from, to, (node) => {
    marks = [...marks, ...node.marks];
  });

  const mark = marks.find((markItem) => markItem.type.name === type.name);

  if (mark) {
    return mark.attrs;
  }

  return {};
};
export const getMarkRange = ($pos = null, type = null) => {
  if (!$pos || !type) {
    return false;
  }

  const start = $pos.parent.childAfter($pos.parentOffset);

  if (!start.node) {
    return false;
  }

  const link = start.node.marks.find((mark) => mark.type === type);
  if (!link) {
    return false;
  }

  let startIndex = $pos.index();
  let startPos = $pos.start() + start.offset;
  let endIndex = startIndex + 1;
  let endPos = startPos + start.node.nodeSize;

  while (startIndex > 0 && link.isInSet($pos.parent.child(startIndex - 1).marks)) {
    startIndex -= 1;
    startPos -= $pos.parent.child(startIndex).nodeSize;
  }

  while (endIndex < $pos.parent.childCount && link.isInSet($pos.parent.child(endIndex).marks)) {
    endPos += $pos.parent.child(endIndex).nodeSize;
    endIndex += 1;
  }

  return { from: startPos, to: endPos };
};
export const getNodeAttrs = (state, type) => {
  const { from, to } = state.selection;
  let nodes = [];

  state.doc.nodesBetween(from, to, (node) => {
    nodes = [...nodes, node];
  });

  const node = nodes
    .reverse()
    .find((nodeItem) => nodeItem.type.name === type.name);

  if (node) {
    return node.attrs;
  }

  return {};
};

export const isNodeSelection = (selection) => selection instanceof NodeSelection;

export const markIsActive = (state, type) => {
  const {
    from,
    $from,
    to,
    empty,
  } = state.selection;

  if (empty) {
    return !!type.isInSet(state.storedMarks || $from.marks());
  }

  return !!state.doc.rangeHasMark(from, to, type);
};
export const nodeEqualsType = ({ types, node }) => (Array.isArray(types) && types.includes(node.type)) || node.type === types;

export const nodeIsActive = (state, type, attrs = {}) => {
  const predicate = (node) => node.type === type;
  const node = findSelectedNodeOfType(type)(state.selection)
    || findParentNode(predicate)(state.selection);

  if (!Object.keys(attrs).length || !node) {
    return !!node;
  }

  return node.node.hasMarkup(type, { ...node.node.attrs, ...attrs });
};
