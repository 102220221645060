<template>
  <div class="edit-goal-cycle">
    <m-content padding>
      <m-form-item
        :label="$t('editGoalCycleModal.titleLabel')"
        class="_title"
      >
        <div class="_title-row">
          <div class="_color">
            <color-picker
              :value="goalCycle.color"
              @input="updateColor"
            />
          </div>
          <m-input
            :value="goalCycle.title"
            :placeholder="$t('editGoalCycleModal.titlePlaceholder')"
            full-width
            auto-focus
            class="_input"
            @input="updateTitle"
          />
        </div>
      </m-form-item>
      <m-form-item
        :label="$t('editGoalCycleModal.rangeLabel')"
        class="_range-picker"
      >
        <m-date-picker
          :value="range"
          :date-time="DateTime"
          full-width
          range
          @input="updateRange"
        />
      </m-form-item>
    </m-content>
    <m-dialog-actions
      :disabled="disabled"
      :submit-disabled="invalid"
      :ok-text="$t('general.save')"
      @cancel="$emit('cancel')"
      @ok="submit"
    />
  </div>
</template>

<script>
import ColorPicker from '@/components/ColorPicker.vue';
import { DateTime } from 'luxon';
import { automaticColor } from 'shared/lib/color';
import { copy } from 'shared/lib/copy';
import { guid } from 'shared/lib/uuid';
import { optionColor } from 'shared/constants.json';

export default {
  name: 'EditGoalCycle',
  props: {
    initialValue: {
      type: Object,
      default: () => null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: { ColorPicker },
  emits: ['cancel', 'ok'],
  data() {
    return {
      DateTime,
      goalCycle: {
        title: '',
        color: automaticColor(guid(), optionColor.all),
      },
      range: {
        startDate: null,
        endDate: null,
      },
      loading: false,
    };
  },
  computed: {
    invalid() {
      return this.range.startDate === null || this.range.endDate === null || this.goalCycle.title === '';
    },
  },
  methods: {
    updateRange({ startDate, endDate }) {
      this.goalCycle = { ...this.goalCycle };
      this.range.startDate = startDate;
      this.range.endDate = endDate;
    },
    updateTitle(title) {
      this.goalCycle.title = title;
    },
    updateColor(color) {
      this.goalCycle.color = color;
    },
    submit() {
      if (this.invalid) {
        return;
      }

      this.goalCycle.start = DateTime.fromISO(this.range.startDate).toISO();
      this.goalCycle.end = DateTime.fromISO(this.range.endDate).toISO();

      this.$emit('ok', this.goalCycle);
    },
  },
  created() {
    if (this.initialValue !== null) {
      this.goalCycle = copy(this.initialValue);
      this.range.startDate = DateTime.fromISO(this.initialValue.start).toISODate();
      this.range.endDate = DateTime.fromISO(this.initialValue.end).toISODate();
    }
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .edit-goal-cycle {
    ._title-row {
      display: flex;
    }

    ._color {
      margin-right: .8rem;
    }
  }
</style>
