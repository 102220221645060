<template>
  <div class="discussion">
    <comment-list
      :comments="comments"
      :meeting="meeting"
      horizontal
      :disabled="disabled"
    />
  </div>
</template>

<script>
import CommentList from '@/components/comment/CommentList.vue';

export default {
  name: 'Discussion',
  props: {
    meeting: {
      type: Object,
      default: () => null,
    },
    comments: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: { CommentList },
};
</script>

<style scoped lang="scss" type="text/scss">

</style>
