<template>
  <div :class="['company-info-editor', disabled ? '-disabled' : '', showFooter ? '-show-footer' : '']">
    <m-content
      class="_body"
      :padding="fullscreen"
      :padding-x="fullscreen ? 'layout' : 10"
    >
      <editable-page-header
        ref="pageHeader"
        :disabled="disabled"
        :title="title"
        :placeholder="$t('list.noTitle')"
        :auto-focus="!$store.state.breakpoint.smAndDown && !disabled"
        @arrow-down="$refs.editor.focus()"
        @enter.prevent="$refs.editor.focus()"
        @change-title="updateTitle"
      />
      <m-content>
        <m-editor
          ref="editor"
          :disabled="disabled"
          :initial-value="initialContent"
          :allowed-content="allowedContent"
          default-font-size="1.6rem"
          light-placeholder
          :placeholder="$t('general.empty')"
          :ctrl-enter-handlers="[onCmdOrModEnter]"
          :mod-enter-handlers="[onCmdOrModEnter]"
          @input="setContent"
        />
      </m-content>
    </m-content>
    <m-dialog-actions
      v-if="showFooter"
      class="_actions"
      :ok-text="submitText"
      :show-cancel="showCancel"
      @ok="handleOk"
      @cancel="closeForced"
    />
  </div>
</template>

<script>
import EditablePageHeader from '@/components/page/EditablePageHeader.vue';
import MEditor from '@/components/editor/MEditor.vue';
import useAccess from '@/composables/access/access';
import useCompanyInfo from '@/composables/logged-in-user-account/company-info';
import { editorNodeType, featureFlag } from 'shared/constants.json';
import { logCatch } from '@/lib/logger/logger';

export default {
  name: 'CompanyInfoEditor',
  props: {
    companyInfo: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    paddingTop: {
      type: Boolean,
      default: false,
    },
    showFooter: {
      type: Boolean,
      default: false,
    },
    showCancel: {
      type: Boolean,
      default: false,
    },
    okText: {
      type: String,
      default: '',
    },
    okHandler: {
      type: Function,
      default: null,
    },
  },
  emits: ['saved', 'close'],
  setup() {
    const { accountHasFeature } = useAccess();

    const allowedContent = [
      editorNodeType.codeBlock,
      editorNodeType.blockquote,
      editorNodeType.bulletList,
      editorNodeType.orderedList,

      editorNodeType.heading,
    ];

    if (accountHasFeature([featureFlag.fileUpload])) {
      allowedContent.push(editorNodeType.image, editorNodeType.file);
    }

    const { updateCompanyInfo } = useCompanyInfo();
    return { updateCompanyInfo, allowedContent };
  },
  components: {
    EditablePageHeader,
    MEditor,
  },
  data() {
    return { title: '', content: null, dirty: false };
  },
  computed: {
    submitText() {
      if (this.okText === '') {
        return this.$t('general.save');
      }

      return this.okText;
    },
    initialContent() {
      return this.content;
    },
  },
  methods: {
    onCmdOrModEnter() {
      this.handleOk();
      return true;
    },
    handleOk() {
      if (this.okHandler === null) {
        this.save();
        return;
      }

      this.okHandler();
    },
    updateTitle(val) {
      this.title = val;
      this.dirty = true;
    },
    save() {
      this.updateCompanyInfo(
        { ...this.companyInfo, title: this.title, content: this.content },
      ).then((response) => {
        this.$emit('saved');
        this.$emit('close');
        return response;
      }).catch(logCatch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      }));
    },
    updateContent() {
      this.$refs.editor.setContent(this.companyInfo.content);
      this.title = this.companyInfo.title;
    },
    closeForced() {
      if (!this.dirty) {
        this.$emit('close');
        return;
      }

      const close = () => this.$emit('close');
      const { save } = this;

      this.$confirm({
        title: this.$t('companyInfo.prompt'),
        cancelText: this.$t('companyInfo.saveChanges'),
        okText: this.$t('general.discardChanges'),
        okType: 'warning',
        cancelType: 'default',
        maskClosable: true,
        onOk() {
          close();
        },
        onCancel() {
          save();
        },
      });
    },
    setContent(content) {
      if (JSON.stringify(this.content) !== JSON.stringify(content)) {
        this.dirty = true;
      }

      this.content = content;
    },
  },
  created() {
    this.title = this.companyInfo.title;
    this.content = this.companyInfo.content;
  },
};
</script>

<style lang="scss" scoped type="text/scss">
  $footer-height: 6.5rem;

  .company-info-editor {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    height: 85vh;

    &.-show-footer {
      grid-template-rows: 1fr $footer-height;
    }

    ._body {
      height: calc(85vh - #{$footer-height});
      padding-bottom: 5rem;
      overflow: auto;

      ._title {
        max-width: 80%;
        overflow: hidden;
        font-size: $font-size-h1;
        font-weight: $font-weight-bold;
        text-overflow: ellipsis;
        white-space: nowrap;

        &.-empty {
          color: $font-color-secondary;
        }
      }

      ._placeholder {
        color: $font-color-secondary;
      }
    }
  }
</style>
