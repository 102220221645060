import { combine } from 'shared/api/query/filter';
import { copy } from 'shared/lib/copy';

const getFilter = ({ operator, existingFilter, baseFilter }) => {
  if (existingFilter === undefined) {
    return baseFilter;
  }

  return combine(operator, [baseFilter, existingFilter]);
};

const applyFilter = ({ query, operator, model, baseFilter, excludedEdges = [] }) => {
  query.forEach((q) => {
    if (q.children !== undefined && q.children.length > 0) {
      applyFilter({ query: q.children, operator, model, baseFilter, excludedEdges });
    }
    if (q.model === model && !excludedEdges.includes(q.attr)) {
      q.filter = getFilter({ operator, existingFilter: q.filter, baseFilter });
    }
  });
};

export const applyBaseFilter = ({ queries, operator, baseFilter = {} }) => {
  const queryCpy = copy(queries);
  Object.keys(baseFilter).forEach((model) => {
    applyFilter({ query: queryCpy, operator, model, baseFilter: baseFilter[model].filter, excludedEdges: baseFilter[model].excludedEdges });
  });
  return queryCpy;
};
