import { DEFAULT_ATTRIBUTES, UID } from 'shared/api/query/constants';
import {
  property as propertyConfig,
  propertyOption as propertyOptionConfig,
  propertySettings as propertySettingsConfig,
} from 'shared/api/query/configs.json';
import { propertyVisibility } from 'shared/constants.json';

export const propertySettingsEdges = [
  ...DEFAULT_ATTRIBUTES,
  { attr: propertySettingsConfig.edges.visibility, default: propertyVisibility.alwaysVisible },
  { attr: propertySettingsConfig.edges.property, model: propertyConfig.model, children: [{ attr: UID }] },
  { attr: propertySettingsConfig.edges.propertyOption, model: propertyOptionConfig.model, children: [{ attr: UID }] },
];
