import { computed, ref } from 'vue';

export default function useExpand(identifier, store = ref({ items: {} })) {
  const initStore = () => {
    if (store.value.items === undefined) {
      store.value.items = {};
    }
  };
  initStore();

  const setExpand = (items) => {
    items.forEach((item) => {
      store.value.items[item.id] = item.value;
    });
  };

  const expandItem = (item) => setExpand([{ id: identifier.getId(item), value: true }]);
  const expandItems = (items) => setExpand(items.map((item) => ({ id: identifier.getId(item), value: true })));
  const collapseItem = (item) => setExpand([{ id: identifier.getId(item), value: false }]);
  const collapseItems = (items) => setExpand(items.map((item) => ({ id: identifier.getId(item), value: false })));

  const getExpand = (item) => computed(() => store.value.items[identifier.getId(item)] === true);

  const has = (item) => store.value.items[identifier.getId(item)] !== undefined;

  const toggleExpand = (item) => setExpand([{ id: identifier.getId(item), value: !getExpand(item).value }]);

  const realignAndRestoreExpandState = (from, to) => {
    Object.keys(store.value.items).forEach((key) => {
      if (key.indexOf(from) > -1) {
        const newKey = key.replace(from, to);
        store.value.items[newKey] = store.value.items[key];
        delete store.value.items[key];
      }
    });
  };

  return {
    toggleExpand,
    expandItem,
    expandItems,
    has,
    collapseItems,
    collapseItem,
    getExpand,
    realignAndRestoreExpandState,
  };
}
