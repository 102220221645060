<template>
  <div class="meeting-page-header">
    <page-top-bar :open-in-modal="modal">
      <template #left>
        <m-btn
          v-if="modal"
          hide-border
          small
          icon="open-as-page"
          @click="detailsPage"
        >
          {{ $t('pageHeader.openAsPage') }}
        </m-btn>
        <breadcrumbs
          v-else
          :breadcrumbs="breadcrumbs"
        />
      </template>
      <template #actions>
        <div

          class="_actions"
        >
          <share-dropdown
            v-if="!$store.state.breakpoint.smAndDown"
            :access-policy="meeting.accessPolicy"
            :creator="meeting.creator"
            :icon="isPublic ? 'global': 'lock'"
            :button-text="$t('pageHeader.share')"
            :page-link="pageLink"
            :disabled="meeting.accessRight !== accessPolicyType.full"
            :on-update="updateAccessPolicy"
            :creator-description="$t('meetingPageHeader.creatorDescription')"
          />
          <favorite-button
            v-if="!$store.state.breakpoint.smAndDown && !meeting.isTemplate"
            :title-suggestion="meeting.title"
            :icon-suggestion="buildIconFromEntity(meeting)"
            :entity-id="meeting.uid"
            :route-name="routeName.meetingDetails"
            :route-params="{meetingId: meeting.uid}"
            :route-query="{}"
          />
          <m-dropdown
            v-model:value="furtherActions"
            :trigger="[]"
            :title="$t('general.actions')"
            placement="bottomRight"
          >
            <m-btn
              hide-border
              class="_action"
              small
              icon="ellipsis"
              fab
            />
            <template #overlay>
              <m-card
                list
                no-padding
                class="_overlay"
              >
                <share-dropdown
                  v-if="$store.state.breakpoint.smAndDown"
                  :access-policy="meeting.accessPolicy"
                  :creator="meeting.creator"
                  :icon="isPublic ? 'global': 'lock'"
                  :button-text="$t('pageHeader.share')"
                  :page-link="pageLink"
                  :disabled="meeting.accessRight !== accessPolicyType.full"
                  :on-update="updateAccessPolicy"
                  :creator-description="$t('meetingPageHeader.creatorDescription')"
                />
                <favorite-button
                  v-if="$store.state.breakpoint.smAndDown && !meeting.isTemplate"
                  :title-suggestion="meeting.title"
                  :icon-suggestion="buildIconFromEntity(meeting)"
                  :entity-id="meeting.uid"
                  :route-name="routeName.meetingDetails"
                  :route-params="{meetingId: meeting.uid}"
                  :route-query="{}"
                />
                <calendar
                  :meeting="meeting"
                  @close="furtherActions = false"
                />
                <m-card-item
                  v-for="item in menuItems"
                  :key="item.name"
                  :icon="item.icon"
                  :disabled="item.disabled"
                  @click="item.onClick"
                >
                  {{ item.name }}
                </m-card-item>
              </m-card>
            </template>
          </m-dropdown>
        </div>
      </template>
    </page-top-bar>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/breadcrumbs/Breadcrumbs.vue';
import Calendar from '@/components/meeting/Calendar.vue';
import FavoriteButton from '@/components/favorite/FavoriteButton.vue';
import PageTopBar from '@/components/page/PageTopBar.vue';
import ShareDropdown from '@/components/access-policy/ShareDropdown.vue';
import deleteMixin from '@/mixins/delete';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import { accessPolicyType, routeName } from 'shared/constants.json';
import { buildIconFromEntity } from 'shared/lib/icon';
import { mapActions } from 'vuex';
import { children as meetingChildren } from '@/api/query/meeting';
import { meeting as meetingConfig } from 'shared/api/query/configs.json';

export default {
  name: 'MeetingPageHeader',
  props: {
    meeting: {
      type: Object,
      required: true,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    modal: {
      type: Boolean,
      default: false,
    },
    routeAfterDelete: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { loggedInUser } = useLoggedInUser();
    return { loggedInUser };
  },
  emits: ['deleted', 'go-to-details'],
  components: { Breadcrumbs, PageTopBar, FavoriteButton, ShareDropdown, Calendar },
  data() {
    return {
      accessPolicyType,
      showVisibilityModal: false,
      pageLink: `${window.location.origin}/#/meetings/${this.meeting.uid}`,
      shareDropdownVisible: false,
      loading: false,
      furtherActions: false,
      routeName,
    };
  },
  computed: {
    isPublic() {
      return this.meeting.accessPolicy.accountAccess !== accessPolicyType.disabled;
    },
    breadcrumbs() {
      return [
        {
          title: this.$t('navigation.meetings'),
          disabled: false,
          to: { name: routeName.meetingsExplorer },
        },
        {
          title: this.meeting.title === '' ? '-' : this.meeting.title,
          disabled: true,
          icons: [{ value: buildIconFromEntity(this.meeting) }],
        },
      ];
    },
    menuItems() {
      return [
        {
          name: this.$t('general.delete'),
          onClick: this.showDeleteModal({
            entities: [this.meeting],
            redirect: { name: this.routeAfterDelete },
            model: meetingConfig.model,
            mutation: 'MEETINGS_DELETED',
          }),
          disabled: this.disabled,
          icon: 'delete',
        },
      ];
    },
  },
  methods: {
    buildIconFromEntity,
    ...mapActions(['updateEntityV2']),
    updateAccessPolicy(val) {
      const entity = {
        ...this.meeting,
        accessPolicy: val,
      };
      this.update(entity);
    },
    update(entity) {
      this.loading = true;
      this.updateEntityV2({
        entity,
        model: meetingConfig.model,
        attributes: meetingChildren,
        mutation: 'MEETING_ACCESS_POLICY_UPDATED',
      }).then((response) => {
        this.loading = false;
        if (response.status !== 200) {
          return;
        }

        // case: user has no access after update
        if (response.data === null) {
          this.$store.commit('MEETING_ACCESS_POLICY_UPDATED', { entity: { ...entity, accessRight: accessPolicyType.disabled } });
        }
      });
    },
    closeModal() {
      this.$emit('deleted');
    },
    detailsPage() {
      this.$emit('go-to-details', {
        to: {
          name: routeName.meetingDetails,
          params: { meetingId: this.meeting.uid },
        },
      });
    },
  },
  mixins: [deleteMixin],
};
</script>

<style
  scoped
  lang="scss"
  type="text/scss"
>
  ._overlay {
    min-width: 25rem;
  }

  ._actions {
    display: flex;
    align-items: center;
  }
</style>
