<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.5845 9.18984L14.8095 3.41484C14.6572 3.2625 14.458 3.1875 14.2588 3.1875C14.0595 3.1875 13.8603 3.2625 13.708 3.41484L9.93219 7.19297C9.64625 7.16016 9.35797 7.14609 9.06969 7.14609C7.35406 7.14609 5.63844 7.71094 4.22984 8.84063C3.86891 9.12891 3.84078 9.67031 4.16656 9.99844L8.42516 14.257L3.37672 19.3008C3.31486 19.3623 3.27668 19.4436 3.26891 19.5305L3.18922 20.4023C3.16813 20.6227 3.34391 20.8102 3.56188 20.8102C3.57359 20.8102 3.58531 20.8102 3.59703 20.8078L4.46891 20.7281C4.55563 20.7211 4.63766 20.6813 4.69859 20.6203L9.74703 15.5719L14.0056 19.8305C14.158 19.9828 14.3572 20.0578 14.5564 20.0578C14.7838 20.0578 15.0088 19.9594 15.1634 19.7672C16.483 18.1195 17.0314 16.057 16.8088 14.0625L20.5845 10.2867C20.8869 9.98672 20.8869 9.49453 20.5845 9.18984V9.18984Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default { name: 'PushpinFilled' };
</script>

<style scoped lang="scss" type="text/scss">

</style>
