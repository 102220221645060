<template>
  <div class="export-button">
    <a
      :href="url"
      download
    >
      <m-card-item
        hide-border
        small
        icon="download"
      >
        {{ $t('exportButton.buttonText') }}
      </m-card-item>
    </a>
  </div>
</template>

<script>
import { GoalFilterHandler } from '@/lib/filter/goal/handler';
import { encodeURLValues } from '@/lib/url';

export default {
  name: 'ExportButton',
  props: {
    selectedView: {
      type: Object,
      required: true,
    },
  },
  computed: {
    url() {
      let filter = null;

      if (this.selectedView !== null && this.selectedView.params !== null) {
        filter = this.selectedView.params.filter;
      }
      const handler = new GoalFilterHandler();
      const filterObject = handler.Translate(filter);

      return `/api/v1/csv-export?${encodeURLValues({ filter: JSON.stringify(filterObject) })}`;
    },
  },
};
</script>
