<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.16742 2.44C6.16742 1.92085 6.58827 1.5 7.10742 1.5C7.62657 1.5 8.04742 1.92085 8.04742 2.44V17.56C8.04742 18.0791 7.62657 18.5 7.10742 18.5C6.58827 18.5 6.16742 18.0791 6.16742 17.56V2.44Z"
      fill="currentColor"
    />
    <path
      d="M17.56 6.16748C18.0791 6.16748 18.5 6.58833 18.5 7.10748C18.5 7.62663 18.0791 8.04748 17.56 8.04748L2.44 8.04748C1.92085 8.04748 1.5 7.62663 1.5 7.10748C1.5 6.58833 1.92085 6.16748 2.44 6.16748L17.56 6.16748Z"
      fill="currentColor"
    />
    <path
      d="M11.9526 2.44C11.9526 1.92085 12.3734 1.5 12.8926 1.5C13.4117 1.5 13.8326 1.92085 13.8326 2.44V17.56C13.8326 18.0791 13.4117 18.5 12.8926 18.5C12.3734 18.5 11.9526 18.0791 11.9526 17.56V2.44Z"
      fill="currentColor"
    />
    <path
      d="M17.56 11.9526C18.0791 11.9526 18.5 12.3735 18.5 12.8926C18.5 13.4118 18.0791 13.8326 17.56 13.8326H2.44C1.92085 13.8326 1.5 13.4118 1.5 12.8926C1.5 12.3735 1.92085 11.9526 2.44 11.9526H17.56Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default { name: 'Number' };
</script>

<style scoped lang="scss" type="text/scss">

</style>
