<template>
  <m-draggable
    class="favorites-nav-items"
    :recreate-key="favorites.length"
    draggable-item-class="favorite-nav-item"
    dragover-item-class="favorite-nav-item"
    can-drag-over-top
    can-drag-over-bottom
    @set-drag-item="setDragItem"
    @over-top="setOverTop"
    @over-bottom="setOverBottom"
    @drag-drop="handleDrop"
    @cancel="cancelDragging"
  >
    <favorite-nav-item
      v-for="(item, index) in sortedFavorites"
      :key="item.uid"
      :data-id="item.uid"
      :item="item"
      :dragging-over-bottom="dragItemId !== '' && draggingOverBottom.includes(item.uid) && !draggingOverBottom.includes(dragItemId)"
      :dragging-over-top="index === 0 && dragItemId !== '' && draggingOverTop.includes(item.uid)"
    />
  </m-draggable>
</template>

<script>
import FavoriteNavItem from '@/components/navigation/FavoriteNavItem.vue';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import usePersonalAppSettings from '@/composables/logged-in-user/personal-app-settings';
import useSort from '@/composables/draggable/sort';
import { computed } from 'vue';
import { logCatch } from '@/lib/logger/logger';
import { shallowCopy } from 'shared/lib/copy';
import { sortByArray } from 'shared/lib/sort';

export default {
  name: 'FavoritesNavItems',
  props: {
    favorites: {
      type: Array,
      required: true,
    },
  },
  components: { FavoriteNavItem },
  setup() {
    const { loggedInUser } = useLoggedInUser();
    const { personalAppSettings, updateSingle } = usePersonalAppSettings(loggedInUser);
    const {
      setDragItem,
      setOverBottom,
      setOverTop,
      draggingOverBottom,
      draggingOverTop,
      dropItem,
      cancelDragging,
      dragItemId,
    } = useSort();
    return {
      user: loggedInUser,
      dragItemId,
      setDragItem,
      setOverBottom,
      setOverTop,
      draggingOverBottom,
      draggingOverTop,
      dropItem,
      cancelDragging,
      personalAppSettings,
      favoritesOrder: computed(() => personalAppSettings.value.favoritesOrder),
      updatePersonalAppSettings: updateSingle,
    };
  },
  computed: {
    sortedFavorites() {
      const favorites = shallowCopy(this.favorites);
      return favorites.sort(sortByArray(this.favoritesOrder.map((el) => ({ uid: el }))));
    },
  },
  methods: {
    handleDrop() {
      const newOrder = this.dropItem(this.sortedFavorites).map((o) => o.uid);
      this.saveFavoritesOrder(newOrder);
    },
    saveFavoritesOrder(order) {
      this.updatePersonalAppSettings(
        { uid: this.personalAppSettings.uid, favoritesOrder: order },
      ).catch(logCatch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      }));
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">

</style>
