<template>
  <div class="account-menu">
    <m-dropdown
      class="_menu"
      title=" "
    >
      <div
        :class="['_trigger', showClose ? '-show-close' : '']"
      >
        <div class="_user">
          <m-avatar
            class="_avatar"
            :src="companyImage"
            :text="account.companyName"
            :amount-chars="1"
            :size="20"
          />
          <div class="_name">
            {{ account.companyName }}
          </div>
          <m-icon
            type="down"
            :color="$colors.grey.lighten1"
            size="10"
          />
        </div>
        <div
          v-if="showClose && !$store.state.breakpoint.smAndDown"
          class="_close"
        >
          <m-btn
            small
            :icon="desktopSidebarHidden ? 'double-right' : 'double-left'"
            hide-border
            fab
            light
            @click.stop="$emit('toggle-sidebar')"
          />
        </div>
      </div>
      <template #overlay>
        <m-card
          no-padding
          class="_overlay"
          list
        >
          <m-card-item
            icon="logout"
            large
            @click="EventBus.$emit('logout')"
          >
            {{ $t('navigation.logout') }}
          </m-card-item>
        </m-card>
      </template>
    </m-dropdown>
  </div>
</template>

<script>
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import usePersonalAppSettings from '@/composables/logged-in-user/personal-app-settings';
import { EventBus } from '@/lib/event-bus';
import { computed } from 'vue';
import { mapActions } from 'vuex';

export default {
  name: 'AccountMenu',
  props: {
    collapsed: {
      type: Boolean,
      default: true,
    },
    showClose: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { loggedInUser } = useLoggedInUser();
    const { personalAppSettings } = usePersonalAppSettings(loggedInUser);
    const { loggedInUserAccount } = useLoggedInUserAccount();
    return {
      user: loggedInUser,
      desktopSidebarHidden: computed(() => personalAppSettings.value.desktopSidebarHidden),
      account: loggedInUserAccount,
    };
  },
  data() {
    return { EventBus };
  },
  emits: ['toggle-sidebar'],
  computed: {
    companyImage() {
      if (this.account.companyImage === null) {
        return '';
      }
      return this.account.companyImage.getURL;
    },
  },
  methods: { ...mapActions(['logout']) },
};
</script>

<style scoped lang="scss" type="text/scss">
  .account-menu {
    &:hover {
      background-color: $sidebar-hover-color;
    }
  }

  ._menu {
    width: 100%;
  }

  ._trigger {
    display: flex;
    align-items: center;

    ._user {
      display: flex;
      align-items: center;
      padding: 1.2rem 1.2rem 1.2rem 1.25rem;
      cursor: pointer;

      ._avatar {
        margin-right: .65rem;
      }

      ._name {
        max-width: 17rem;
        margin-right: .4rem;
        overflow: hidden;
        font-weight: $font-weight-medium;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    ._close {
      padding-right: 1.2rem;
      margin-left: auto;
    }

    &.-show-close {
      ._user {
        ._name {
          max-width: 13rem;
        }
      }
    }
  }

  ._overlay {
    min-width: 25rem;
  }
</style>
