import useStateSelector from '@/nebula/state-selectors';
import { useStore } from 'vuex';

export default function useSelector(model) {
  const store = useStore();
  const {
    entityList,
    entityMap,
    selectSingle,
    selectMultiple,
  } = useStateSelector(store.state, model);

  return {
    entityList,
    entityMap,
    selectSingle,
    selectMultiple,
  };
}
