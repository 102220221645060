<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.44195 22.5051C1.92103 22.5051 1.5 22.084 1.5 21.5631V2.44702C1.5 1.9261 1.92103 1.50507 2.44195 1.50507H8.57794C9.15555 1.50507 9.62379 1.97331 9.62379 2.55091C9.62379 3.12852 9.15555 3.59676 8.57794 3.59676H3.59169V20.4134H20.4083V15.3355C20.4083 14.7578 20.8766 14.2896 21.4542 14.2896C22.0318 14.2897 22.5 14.7579 22.5 15.3355V21.5631C22.5 22.084 22.079 22.5051 21.558 22.5051H2.44195Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.4181 5.06305L18.473 6.99052L14.2946 11.1689C13.894 11.5696 13.2445 11.5696 12.8438 11.1691C12.443 10.7685 12.4429 10.1188 12.8436 9.71804L17.0152 5.54604L18.9433 3.6224H14.6615C14.6454 3.6224 14.6294 3.62224 14.6135 3.62194C14.4924 3.6196 14.3781 3.60832 14.2717 3.588C13.7312 3.48478 13.3943 3.1484 13.3943 2.56725C13.3943 1.87195 13.8632 1.5 14.6135 1.5H21.1239C21.9997 1.5 22.4963 1.77727 22.4963 2.75183V9.21854C22.4963 9.25169 22.4955 9.28453 22.4941 9.31702C22.4911 9.38337 22.4851 9.44829 22.476 9.51145C22.4756 9.51442 22.4752 9.51738 22.4747 9.52034C22.386 10.1212 22.0249 10.5614 21.4302 10.5651L21.4264 10.5651L21.4226 10.5652L21.4264 10.569L21.4226 10.5689L21.4188 10.5689C20.8577 10.5661 20.5474 10.2444 20.4511 9.71408C20.4289 9.59229 20.4181 9.45949 20.4181 9.31702V5.06305Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
export default { name: 'ExternalLink' };
</script>

<style scoped lang="scss" type="text/scss">

</style>
