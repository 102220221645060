import { GOAL_PLANNING, GOAL_RELATION } from '@/lib/props/custom-types';
import { dateFn, directDateFn } from '@/lib/filter/base-frontend-filter/date';
import { directNumberFn, numberFn } from '@/lib/filter/base-frontend-filter/number';
import { directOptionsFn, optionsFn } from '@/lib/filter/base-frontend-filter/options';
import { directPlanningsFn } from '@/lib/filter/base-frontend-filter/plannings';
import { directRelationFn } from '@/lib/filter/base-frontend-filter/relations';
import { directSpaceFn, spaceFn } from '@/lib/filter/base-frontend-filter/space';
import { directTextFn, textFn } from '@/lib/filter/base-frontend-filter/text';
import { directUserFn, staticUserFn, userFn } from '@/lib/filter/base-frontend-filter/user';
import { isNullOrUndefined } from 'shared/lib/object/object';
import { lookupFn } from '@/lib/filter/base-frontend-filter/lookup';
import { propertyType, userScopeOperator, userScopeType } from 'shared/constants.json';
import { reverseFn } from '@/lib/filter/base-frontend-filter/reverse';

const AND = userScopeOperator.and;
const OR = userScopeOperator.or;
const NOT = userScopeOperator.not;

const translateStaticUsersScope = ({ isFilterMode = false }) => ({ entity, scope }) => {
  if (!isNullOrUndefined(scope.staticUsers)) {
    return staticUserFn({ isFilterMode })({ entity, scope });
  }
  return false;
};

const translateIndirectPropertyScope = ({ isFilterMode = false }) => ({ entity, scope }) => {
  const indirectEntity = entity[scope.indirectProperty.edgeName];

  if (Array.isArray(indirectEntity)) {
    return indirectEntity.some((e) => translateScope({ isFilterMode })({ entity: e, scope: scope.indirectProperty.scope, propertyValues: e[scope.indirectProperty.scope.edgeName] }));
  }

  return translateScope({ isFilterMode })({ entity: indirectEntity, scope: scope.indirectProperty.scope, propertyValues: indirectEntity[scope.indirectProperty.scope.edgeName] });
};

const translateDirectPropertyScope = ({ isFilterMode = false }) => ({ entity, scope }) => {
  switch (scope.directProperty.type) {
    case propertyType.text:
      return directTextFn({ entity, scope });
    case propertyType.number:
      return directNumberFn({ entity, scope });
    case propertyType.date:
      return directDateFn({ entity, scope });
    case propertyType.user:
      return directUserFn({ isFilterMode })({ entity, scope });
    case propertyType.space:
      return directSpaceFn({ isFilterMode })({ entity, scope });
    case propertyType.options:
    case propertyType.singleSelect:
      return directOptionsFn({ isFilterMode })({ entity, scope });
    case GOAL_PLANNING:
      return directPlanningsFn({ isFilterMode })({ entity, scope });
    case GOAL_RELATION:
      return directRelationFn({ isFilterMode })({ entity, scope });
    default:
      return false;
  }
};

const translatePropertyScope = ({ isFilterMode = false }) => ({ entity, scope, propertyValues, lookupPropertySvc }) => {
  if (isNullOrUndefined(propertyValues)) {
    return false;
  }
  switch (scope.property.type) {
    case propertyType.text:
      return textFn({ propertyValues, scope });
    case propertyType.number:
      return numberFn({ propertyValues, scope });
    case propertyType.date:
      return dateFn({ propertyValues, scope });
    case propertyType.user:
      return userFn({ isFilterMode })({ propertyValues, scope });
    case propertyType.space:
      return spaceFn({ isFilterMode })({ propertyValues, scope });
    case propertyType.options:
    case propertyType.status:
    case propertyType.singleSelect:
      return optionsFn({ isFilterMode })({ propertyValues, scope });
    case propertyType.lookup:
      return lookupFn({ isFilterMode })({ entity, scope, propertyValues, lookupPropertySvc });
    default:
      return false;
  }
};

const translateScope = ({ isFilterMode = false }) => ({ entity, scope, propertyValues, lookupPropertySvc }) => {
  if (isNullOrUndefined(entity)) {
    return false;
  }

  if (scope.type !== undefined) {
    switch (scope.type) {
      case userScopeType.staticUsers:
        return translateStaticUsersScope({ isFilterMode })({ entity, scope });
      case userScopeType.indirectProperty:
        return translateIndirectPropertyScope({ isFilterMode })({ entity, scope });
      case userScopeType.directProperty:
        return translateDirectPropertyScope({ isFilterMode })({ entity, scope });
      case userScopeType.property:
        return translatePropertyScope({ isFilterMode })({ entity, scope, propertyValues, lookupPropertySvc });
      case userScopeType.reverseProperty:
        return reverseFn({ entity, scope });
      default:
        throw new Error('user scope type not supported');
    }
  }

  if (!isNullOrUndefined(scope.staticUsers)) {
    return translateStaticUsersScope({ isFilterMode })({ entity, scope });
  }

  if (!isNullOrUndefined(scope.reverseProperty)) {
    return reverseFn({ entity, scope });
  }

  if (!isNullOrUndefined(scope.indirectProperty)) {
    return translateIndirectPropertyScope({ isFilterMode })({ entity, scope });
  }

  if (!isNullOrUndefined(scope.directProperty)) {
    return translateDirectPropertyScope({ isFilterMode })({ entity, scope });
  }

  if (!isNullOrUndefined(scope.property)) {
    return translatePropertyScope({ isFilterMode })({ entity, scope, propertyValues, lookupPropertySvc });
  }

  return false;
};

const translateTree = ({ isFilterMode = false }) => ({ entity, tree, propertyValues, lookupPropertySvc }) => {
  if ((isNullOrUndefined(tree.children) || tree.children.length === 0) && isNullOrUndefined(tree.scope)) {
    return isFilterMode;
  }

  let op = tree.op;
  if (isNullOrUndefined(tree.op) || tree.op === '') {
    op = OR;
  }

  if ((isNullOrUndefined(tree.children) || tree.children.length === 0)) {
    return translateScope({ isFilterMode })({ entity, scope: tree.scope, propertyValues, lookupPropertySvc });
  }

  for (let i = 0; i < tree.children.length; i++) {
    const valid = translateTree({ isFilterMode })({ entity, tree: tree.children[i], propertyValues, lookupPropertySvc });
    if (op === NOT) {
      return !valid;
    }
    if (op === OR && valid) {
      return true;
    }
    if (op === AND && !valid) {
      return false;
    }
  }
  return op === AND;
};

export const isInFilter = ({ isFilterMode = false }) => ({ entity, scopeTree = null, propertyValues = undefined, lookupPropertySvc }) => {
  if (scopeTree === null) {
    return true;
  }

  if (isNullOrUndefined(entity)) {
    return false;
  }

  const pvs = propertyValues === undefined ? entity.properties : propertyValues;
  return translateTree({ isFilterMode })({ entity, tree: scopeTree, propertyValues: pvs, lookupPropertySvc });
};
