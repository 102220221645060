import useGoals from '@/composables/goal/goals';
import { EVENTS } from '@/lib/constants';
import { EventBus } from '@/lib/event-bus';
import { computed, ref } from 'vue';

export default function usePublishGoals(planningId) {
  const { entityList, updateMultiple } = useGoals();

  const publishLoading = ref(false);
  const publish = ({ goalIds }) => {
    publishLoading.value = true;
    const now = (new Date()).toISOString();
    const entities = goalIds.map((id) => ({ uid: id, publishedAt: now }));
    return updateMultiple(entities).then((goals) => {
      EventBus.$emit(EVENTS.PLANNING.GOALS_PUBLISHED, { goals });
      return goals;
    }).finally(() => {
      publishLoading.value = false;
    });
  };
  const initialSelectedGoalIds = computed(() => entityList.value.filter(
    (g) => g.publishedAt === null && g.planning !== null
            && g.planning.uid === planningId,
  ).map((g) => g.uid));

  return {
    publish,
    publishLoading,
    initialSelectedGoalIds,
  };
}
