<template>
  <div
    ref="selector"
    class="saved-view-tabs"
  >
    <m-spinner
      v-if="loading || headerWidth === 0"
      size="xs"
      class="_spinner"
    />
    <template v-else>
      <m-draggable
        v-if="!tight"
        class="_saved-view-selector"
        draggable-item-class="saved-view-tab"
        ghost-item-class="saved-view-tab"
        dragover-item-class="saved-view-tab"
        can-drag-over-right
        can-drag-over-left
        ghost-item-movement-axes="x"
        :recreate-key="tabs.map(v => v.uid)"
        :drag-between-height="38"
        @set-drag-item="setDragItem"
        @over-right="setOverRight"
        @over-left="setOverLeft"
        @drag-drop="handleDrop"
        @cancel="cancelDragging"
      >
        <saved-view-tab
          v-for="view in sortedViewsToDisplay"
          :key="view.uid"
          :view="view"
          :dragging="dragItemId !== ''"
          :active="view.uid === selectedView.uid"
          :allowed-view-types="allowedViewTypes"
          :view-application="viewApplication"
          :has-unsaved-changes="viewsService.hasUnsavedChanges(view)"
          :disable-delete="viewsService.views.value.length === 1"
          :read-only="!canEditView(view, readOnly, hasPublicViewAccess, myTeamsIds) || tempViewOnly"
          :dragging-over-left="dragItemId !== '' && draggingOverLeft.includes(view.uid)"
          :dragging-over-right=" dragItemId !== '' && draggingOverRight.includes(view.uid)"
          :auto-open="autoOpen === view.uid"
          :show-visibility-switch="showVisibilitySwitch"
          :show-visibility-hint="showVisibilityHint"
          @contextmenu="handleContextMenuClick"
          @select="selectView"
          @update="(val) => updateView(val, false, true)"
          @delete="deleteView"
          @close-editor="autoOpen = -1"
        />
      </m-draggable>
      <m-dropdown
        v-if="tabsAreWrapped || tight || showViewList"
        v-model:value="showViewList"
        :title="$t('savedViewSelector.selectView')"
        :disabled="!canShowViewList"
      >
        <m-btn
          id="more"
          hide-border
          small
          :light="!tight"
          class="_btn"
          :disabled="!canShowViewList"
          @click="showViewList = true"
        >
          <div
            v-if="!tight"
            class="_inner"
          >
            {{ unshownTabNumber }} {{ $t('savedViewSelector.more') }}
          </div>
          <div
            v-else
            class="_selector"
          >
            <saved-view-title
              :view="selectedView"
              :show-visibility-hint="showVisibilityHint"
            />
            <m-icon
              type="down"
              size="11"
              :color="$colors.grey.lighten1"
              :style="{ 'margin-left': '0.6rem'}"
            />
          </div>
        </m-btn>
        <template #overlay>
          <m-card
            list
            no-padding
          >
            <m-draggable
              draggable-item-class="saved-view-item"
              ghost-item-class="saved-view-item"
              dragover-item-class="saved-view-item"
              can-drag-over-top
              can-drag-over-bottom
              :recreate-key="tabOrder"
              :drag-between-height="24"
              @set-drag-item="setDragItem"
              @over-top="setOverTop"
              @over-bottom="setOverBottom"
              @drag-drop="handleDrop"
              @cancel="cancelDragging"
            >
              <div
                v-for="view in viewsService.views.value"
                :key="view.uid"
                class="_saved-view-item"
              >
                <div
                  v-if="draggingOverTop.includes(view.uid)"
                  class="_drag-over-top"
                />
                <saved-view-item
                  :view="view"
                  show-drag-handle
                  :active="view.uid === selectedView.uid"
                  :allowed-view-types="allowedViewTypes"
                  :view-application="viewApplication"
                  :has-unsaved-changes="viewsService.hasUnsavedChanges(view)"
                  :read-only="readOnly"
                  :show-visibility-switch="showVisibilitySwitch"
                  :show-visibility-hint="showVisibilityHint"
                  :auto-open="autoOpen === view.uid"
                  @close-editor="autoOpen = -1"
                  @select="selectView"
                  @update="val => updateView(val, false, true)"
                  @delete="deleteView"
                />
                <div
                  v-if="draggingOverBottom.includes(view.uid)"
                  class="_drag-over-bottom"
                />
              </div>
            </m-draggable>
            <template
              v-if="showCreateBtn"
            >
              <m-divider xxs />
              <m-card-item
                icon="plus"
                :loading="createLoading"
                @click="createView"
              >
                {{ $t('savedViewSelector.addView') }}
              </m-card-item>
            </template>
          </m-card>
        </template>
      </m-dropdown>
      <div
        v-else-if="showCreateBtn"
        id="addBtn"
        ref="addBtn"
      >
        <m-btn
          icon="plus"
          data-cy="create-view"
          hide-border
          small
          light
          fab
          :loading="createLoading"
          @click="createView"
        />
      </div>
    </template>
    <template
      v-if="!readOnly && hasUnsavedChanges && !tempViewOnly"
    >
      <div
        id="savedViewBtn"
        class="save-view-btn"
      >
        <m-btn
          v-if="canEditCurrentView"
          small
          outlined
          color="warning"
          class="_save-btn"
          :loading="viewsService.loadingUpdateView.value"
          @click="updateView(selectedView, true, false)"
        >
          <template v-if="tight">
            {{ $t('general.save') }}
          </template>
          <template v-else>
            <m-tooltip>
              <template v-if="selectedView.isPublic">
                {{ $t('savedViewSelector.saveForEveryone') }}
              </template>
              <template v-else>
                {{ $t('general.save') }}
              </template>
              <template #title>
                <template v-if="selectedView.isPublic">
                  {{ $t('savedViewSelector.saveButtonTooltipPublic') }}
                </template>
                <template v-else>
                  {{ $t('savedViewSelector.saveButtonTooltipPrivate') }}
                </template>
              </template>
            </m-tooltip>
          </template>
        </m-btn>
        <template v-else>
          <m-tooltip>
            <m-btn
              icon="exclamation-circle-filled"
              small
              light
              hide-border
              disabled
            >
              {{ $t('savedViewSelector.canNotSave') }}
            </m-btn>
            <template #title>
              {{ $t('savedViewSelector.canNotSaveNote') }}
            </template>
          </m-tooltip>
        </template>
        <m-btn
          small
          hide-border
          light
          class="_reset-btn"
          data-cy="reset-btn"
          @click="viewsService.resetView(selectedView.uid)"
        >
          {{ $t('savedViewSelector.reset') }}
        </m-btn>
      </div>
    </template>
  </div>
</template>

<script setup>
import SavedViewItem from '@/components/list/SavedViewItem.vue';
import SavedViewTab from '@/components/list/SavedViewTab.vue';
import SavedViewTitle from '@/components/list/SavedViewTitle.vue';
import useAccess from '@/composables/access/access';
import useDebounce from '@/composables/debounce';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useSort from '@/composables/draggable/sort';
import useSortViews from '@/composables/saved-views/view-collections';
import { EventBus } from '@/lib/event-bus';
import { VIEWS_SERVICE } from '@/lib/constants';
import { accessGroupFlag } from 'shared/constants.json';
import { canEditView } from '@/lib/saved-view/saved-view';
import { computed, inject, nextTick, onMounted, ref, watch } from 'vue';
import { logCatch } from '@/lib/logger/logger';
import { shallowCopy } from 'shared/lib/copy';
import { sortByArray } from 'shared/lib/sort';
import { uniq } from 'lodash-es';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  showCreate: {
    type: Boolean,
    default: false,
  },
  allowedViewTypes: {
    type: Array,
    default: () => [],
  },
  viewApplication: {
    type: String,
    default: '',
  },
  propertyOption: {
    type: Object,
    default: () => null,
  },
  planning: {
    type: Object,
    default: () => null,
  },
  showVisibilitySwitch: {
    type: Boolean,
    default: false,
  },
  isPublicDefault: {
    type: Boolean,
    required: true,
  },
  sharedViewOrder: {
    type: Boolean,
    required: true,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
  rightElementWidth: {
    type: Number,
    default: 0,
  },
  headerWidth: {
    type: Number,
    default: 0,
  },
  headerTight: {
    type: Boolean,
    default: false,
  },
});

const { t } = useI18n();
const { userHasRight } = useAccess();
const { myTeamsIds } = useLoggedInUser();
const viewsService = inject(VIEWS_SERVICE);
const views = ref(viewsService.views);
const selectedView = ref(viewsService.currentView);

const createLoading = ref(false);

const tight = computed(() => props.headerWidth < 800);

const emit = defineEmits(['tabs-shown-updated']);

const maxShowTabIndex = ref(1);
const showViewList = ref(false);

const loading = ref(true);

const autoOpen = ref(-1);

const sortViews = useSortViews();

const hasPublicViewAccess = computed(() => userHasRight([accessGroupFlag.publicSavedViews]));

const showCreateBtn = computed(() => props.showCreate
    && !props.readOnly
    && (props.isPublicDefault === false || hasPublicViewAccess.value
        || myTeamsIds.includes(viewsService.currentView.value.space?.uid)
        || (viewsService.currentView.value.planning !== undefined && viewsService.currentView.value.planning !== null)
        || (viewsService.currentView.value.gridPageTile !== undefined && viewsService.currentView.value.gridPageTile !== null)
    ));

const hasUnsavedChanges = computed(() => viewsService.hasUnsavedChanges(selectedView.value));
const sortedViewsToDisplay = computed(() => tabs.value.slice(0, maxShowTabIndex.value));
const canShowViewList = computed(() => !props.readOnly || views.value.length > 1);
const tabsAreWrapped = computed(() => views.value.length > maxShowTabIndex.value);
const unshownTabNumber = computed(() => views.value.length - maxShowTabIndex.value);
const canEditCurrentView = viewsService.canEditCurrentView;

const {
  setDragItem,
  setOverLeft,
  setOverRight,
  setOverBottom,
  setOverTop,
  draggingOverRight,
  draggingOverLeft,
  draggingOverTop,
  draggingOverBottom,
  dragItemId,
  dropItem,
  cancelDragging,
} = useSort();

const init = () => {
  if (viewsService.viewCollection.value !== null || !showCreateBtn.value) {
    return new Promise((resolve) => { resolve(); });
  }

  return mutateViewOrder(views.value.filter((v) => v.uid !== 0).map((view) => ({ uid: view.uid })));
};

const tabs = computed(() => {
  const viewsCopy = shallowCopy(viewsService.views.value);

  const order = shallowCopy(viewsCopy.map((v) => ({ uid: v.uid })));
  let index = order.findIndex((item) => item.uid === selectedView.value.uid);
  if (index === -1) {
    order.push({ uid: selectedView.value.uid });
    index = order.length - 1;
  }

  if (index >= maxShowTabIndex.value) {
    const insertIndex = Math.min(maxShowTabIndex.value - 1, order.length);
    order.splice(index, 1);
    order.splice(insertIndex, 0, { uid: selectedView.value.uid });
  }

  viewsCopy.sort(sortByArray(order));
  return viewsCopy;
});

const tabOrder = computed(() => {
  if (viewsService.viewCollection.value === null) {
    return [];
  }
  return viewsService.viewCollection.value.viewOrder;
});

const deleteViewFromOrder = (view) => {
  mutateViewOrder(tabOrder.value.filter((v) => v.uid !== view.uid));
};

const mutateViewOrder = (viewOrder) => {
  if (viewsService.viewCollection.value !== null) {
    return sortViews.updateViewCollection({ uid: viewsService.viewCollection.value.uid, viewOrder });
  }

  return sortViews.createViewCollection({ ...selectedView.value, viewOrder }, { shared: props.sharedViewOrder });
};

const handleDrop = () => {
  // This will lead to problems when users have different private views. However,
  // we discussed that and agreed that we accept this for now and might handle
  // that better in the future by splitting up the orders in two: public and private
  const newOrder = dropItem(viewsService.views.value.map((v) => ({ uid: v.uid })));
  mutateViewOrder(newOrder);
};

const distanceToRight = 75;

const calculateMaxTabIndex = () => {
  if (tight.value || props.headerTight) {
    return 1;
  }

  let totalWidth = 0;
  const moreBtn = document.getElementById('more');
  if (moreBtn !== null) {
    totalWidth += moreBtn.clientWidth;
  }

  const addBtn = document.getElementById('addBtn');
  if (addBtn !== null) {
    totalWidth += addBtn.clientWidth;
  }

  if (document.getElementById('savedViewBtn') != null) {
    totalWidth += document.getElementById('savedViewBtn').clientWidth;
  }

  let tabIndex = 0;
  const tabs = Array.from(document.getElementsByClassName('saved-view-tab'));
  if (tabs.length === 0) {
    return maxShowTabIndex.value;
  }

  // The selected view is always shown, so it has to be considered independent of other tabs
  tabs.sort((a, b) => {
    if (a.dataset.id === `${selectedView.value.uid}`) {
      return -1;
    }
    if (b.dataset.id === `${selectedView.value.uid}`) {
      return 1;
    }
    return 0;
  });
  for (let i = 0; i < tabs.length; i++) {
    totalWidth += tabs[i].clientWidth;
    if (totalWidth < (props.headerWidth - props.rightElementWidth - distanceToRight)) {
      tabIndex += 1;
    }
  }
  return Math.max(1, tabIndex);
};

const reOrderTabs = () => {
  if (views.value.length === 1) {
    maxShowTabIndex.value = 1;
    return;
  }
  maxShowTabIndex.value = views.value.length;
  nextTick(() => {
    maxShowTabIndex.value = calculateMaxTabIndex();
    emit('tabs-shown-updated', maxShowTabIndex.value);
  });
};

const handleContextMenuClick = (event) => {
  event.preventDefault();
};

const createView = () => {
  createLoading.value = true;
  const toCreate = [];
  if (views.value[0].isTemp) {
    toCreate.push({ ...views.value[0], isPublic: props.isPublicDefault });
  }
  toCreate.push({
    title: '',
    emoji: '',
    viewType: props.allowedViewTypes[0],
    params: selectedView.value.params,
    viewApplication: props.viewApplication,
    isPublic: props.isPublicDefault,
    goalSort: [],
  });
  viewsService.createViews(toCreate, viewsService.viewCollection.value).then((views) => {
    reOrderTabs();
    if (!tight.value) {
      showViewList.value = false;
    }
    if (views.length === 2) {
      const regularView = views.find((v) => v.title === '');
      viewsService.changeSelectedView(regularView);
      autoOpen.value = regularView.uid;
      return;
    }
    viewsService.changeSelectedView(views[0]);
    autoOpen.value = views[0].uid;
  }).catch(logCatch(() => {
    EventBus.$emit('show-snackbar', { color: 'error', message: t('error.default') });
  })).finally(() => {
    createLoading.value = false;
  });
};

const showVisibilityHint = computed(() => uniq(viewsService.views.value.map((v) => v.isPublic)).length > 1);

const tempViewOnly = computed(() => showCreateBtn.value === false && views.value.length === 1 && viewsService.currentView.value.isTemp);

const updateView = (view, showToast = false, openEditor = false) => {
  if (tempViewOnly.value) {
    return;
  }
  if (view.isTemp) {
    viewsService.createViews([view], viewsService.viewCollection.value).then((views) => {
      reOrderTabs();
      viewsService.changeSelectedView(views[0]);
      if (openEditor) {
        autoOpen.value = views[0].uid;
      }
      if (showToast) {
        EventBus.$emit('show-snackbar', { color: 'success', message: t('success.saved') });
      }
    })
      .catch(logCatch(() => {
        EventBus.$emit('show-snackbar', { color: 'error', message: t('error.default') });
      }));
    return;
  }
  viewsService.updateView(view)
    .then(() => {
      if (showToast) {
        EventBus.$emit('show-snackbar', { color: 'success', message: t('success.saved') });
      }
    })
    .catch(logCatch(() => {
      EventBus.$emit('show-snackbar', { color: 'error', message: t('error.default') });
    }));
  reOrderTabs();
};
const deleteView = ({ view }) => {
  viewsService.deleteView(view).then(() => {
    viewsService.changeSelectedView(viewsService.views.value[0]);
    deleteViewFromOrder(view);
    reOrderTabs();
    EventBus.$emit('show-snackbar', { color: 'success', message: t('success.deleted') });
  }).catch(logCatch(() => {
    EventBus.$emit('show-snackbar', { color: 'error', message: t('error.default') });
  }));
};

const { debounce } = useDebounce();

const selectView = ({ view }) => {
  viewsService.changeSelectedView(view);
  reOrderTabs();
  showViewList.value = false;
};

watch(() => props.headerWidth, () => {
  debounce(reOrderTabs, 100);
});

watch(tabOrder, () => {
  debounce(reOrderTabs, 100);
});

watch(hasUnsavedChanges, () => {
  reOrderTabs();
});

const selector = ref(null);
onMounted(() => {
  init().then(() => {
    reOrderTabs();
    loading.value = false;
  });
});
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>

.saved-view-tabs {
  display: flex;
  align-items: center;

  ._spinner {
    height: 3.2rem;
  }

  ._saved-view-selector {
    display: flex;
    align-items: center;

    ._btn {
      ._inner {
        display: flex;
        align-items: center;

        ._text {
          max-width: 15rem;
          margin-right: .4rem;
          margin-left: .6rem;
          font-weight: $font-weight-semibold;
        }
      }
    }
  }

  ._selector {
    display: flex;
  }

  .save-view-btn {
    display: flex;
  }

  ._save-btn {
    margin-left: .8rem;
  }

  ._reset-btn {
    margin-left: .8rem;
  }

  ._item {
    position: relative;

    ._item-inner {
      border-radius: $default-border-radius;
    }

    ._handle {
      position: absolute;
      top: 50%;
      left: 1.8rem;
      cursor: grab;
      transform: translate(-50%, -50%);
    }

    ._label {
      margin-left: 1.2rem;
    }

    ._right {
      ._tag {
        margin-right: 1.2rem;
      }
    }
  }
}

  ._saved-view-item {
    position: relative;

    ._drag-over-top {
      position: absolute;
      top: -2px;
      right: 0;
      left: 0;
      z-index: 88;
      width: 100%;
      height: 4px;
      pointer-events: none;
      background: $highlighted-color-dark;
      opacity: 1;
    }

    ._drag-over-bottom {
      position: absolute;
      right: 0;
      bottom: -2px;
      left: 0;
      z-index: 88;
      width: 100%;
      height: 4px;
      pointer-events: none;
      background: $highlighted-color-dark;
      opacity: 1;
    }
  }
</style>
