<template>
  <updates-explorer
    :show-create-view="hasPublicViewAccess"
    show-views-selector
    show-onboarding
  />
</template>

<script setup>
import UpdatesExplorer from '@/components/updates/UpdatesExplorer.vue';
import useAccess from '@/composables/access/access';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useUpdateDefaultProps from '@/composables/saved-views/update-default-props';
import useViewServiceInit from '@/composables/saved-views/view-service-init';
import { AND } from '@/lib/filter/scope-tree';
import { CREATED_AT } from 'shared/api/query/constants';
import { VIEWS_SERVICE } from '@/lib/constants';
import { accessGroupFlag, viewApplication, viewType } from 'shared/constants.json';
import { computed, provide, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

const isFeedRef = ref(false);

const defaultPropsSvc = useUpdateDefaultProps({ isFeed: isFeedRef });
const viewServiceInit = useViewServiceInit();
const loggedInUserAccountSvc = useLoggedInUserAccount();

const i18n = useI18n();

const viewApp = viewApplication.updateAccount;

const defaultView = {
  title: i18n.t('list.feed'),
  viewType: viewType.feed,
  viewApplication: viewApp,
  params: {
    filter: { account: { uid: loggedInUserAccountSvc.loggedInUserAccount.value.uid }, children: [], op: AND },
    order: [{ attr: CREATED_AT, desc: true }],
    props: [],
  },
};

let viewConfig = viewServiceInit.defaultConfig();
viewConfig = viewServiceInit.withDefaultProps(viewConfig, defaultPropsSvc.defaultProps);
viewConfig = viewServiceInit.withDefaultView(viewConfig, defaultView);
viewConfig = viewServiceInit.withViewApplication(viewConfig, viewApp);

const viewSvc = viewServiceInit.routeAwareViewService(viewConfig);
provide(VIEWS_SERVICE, viewSvc);

watch(viewSvc.currentView, (currentView) => {
  if (currentView.viewType === viewType.feed) {
    isFeedRef.value = true;
    return;
  }
  isFeedRef.value = false;
}, { immediate: true });

const { userHasRight } = useAccess();
const hasPublicViewAccess = computed(() => userHasRight([accessGroupFlag.publicSavedViews]));

</script>
