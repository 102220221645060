<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 25L16 8L26 25L6 25Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default { name: 'ChevronUp' };
</script>

<style scoped lang="scss" type="text/scss">

</style>
