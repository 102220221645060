<template>
  <m-dropdown
    v-model:value="showTrashContent"
    class="trash"
    :title="$t('mainNavigation.trash')"
    block
    :style="{ display: 'block' }"
    placement="topRight"
  >
    <nav-item
      data-cy="trash-nav-item"
      :title="$t('mainNavigation.trash')"
      icon="delete"
      :active="showTrashContent"
      hover-color="beige"
      @click="showTrashContent = true"
    />
    <template #overlay>
      <m-card
        no-padding
        :class="['_trash-content-card', $store.state.breakpoint.smAndDown ? '-mobile' : '']"
      >
        <trash-content
          @close="showTrashContent = false"
        />
      </m-card>
    </template>
  </m-dropdown>
</template>
<script>
import NavItem from '@/components/navigation/NavItem.vue';
import TrashContent from '@/components/trash/TrashContent.vue';

export default {
  name: 'Trash',
  components: { TrashContent, NavItem },
  data() {
    return { showTrashContent: false };
  },
  watch: {
    $route(newVal, oldVal) {
      if (newVal.name === oldVal.name) {
        return;
      }
      this.showTrashContent = false;
    },
  },
};

</script>
<style scoped lang="scss" type="text/scss">
  ._trash-content-card {
    width: 50rem;
  }
</style>
