<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.5714 2.57143H3.42857C2.95519 2.57143 2.57143 2.95519 2.57143 3.42857V20.5714C2.57143 21.0447 2.95519 21.4286 3.42857 21.4286H20.5714C21.0447 21.4286 21.4286 21.0447 21.4286 20.5714V3.42857C21.4286 2.95519 21.0447 2.57143 20.5714 2.57143ZM3.42857 0H20.5714C22.465 0 24 1.53502 24 3.42857V20.5714C24 22.465 22.465 24 20.5714 24H3.42857C1.53502 24 0 22.465 0 20.5714V3.42857C0 1.53502 1.53502 0 3.42857 0Z"
      fill="currentColor"
    />
    <path
      d="M18.8564 10.7143H8.56641V13.2857H18.8564V10.7143Z"
      fill="currentColor"
    />
    <path
      d="M18.8571 15H8.56641V17.5714H18.8571V15Z"
      fill="currentColor"
    />
    <path
      d="M7.70602 6.42856H5.13965V8.99856H7.70602V6.42856Z"
      fill="currentColor"
    />
    <path
      d="M7.70602 10.7143H5.13965V13.2843H7.70602V10.7143Z"
      fill="currentColor"
    />
    <path
      d="M7.70602 15H5.13965V17.57H7.70602V15Z"
      fill="currentColor"
    />
    <path
      d="M18.8569 6.42856H8.56641V8.99999H18.8569V6.42856Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default { name: 'ListIcon' };
</script>

<style scoped lang="scss" type="text/scss">

</style>
