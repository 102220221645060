import { ref } from 'vue';
import { textByLang } from 'shared/lib/language';
import { translatePropertyOptionColor } from '@/lib/goal/status';

export default function useStatusDistribution(statusProperty, options, goalSettings, userLang) {
  const init = (options) => [
    ...options.map((o) => ({
      x: textByLang(o.label, userLang),
      uid: o.uid,
      selectedOptions: [o],
      color: translatePropertyOptionColor(o),
    }))].map((bucket) => ({
    ...bucket,
    y: 0,
    diffY: undefined,
  }));
  const distribution = ref(init(options));

  const update = (list) => {
    distribution.value.forEach((bucket) => {
      bucket.y = 0;
    });
    if (list.length === 0) {
      return;
    }
    list.forEach((e) => {
      distribution.value.forEach((bucket) => {
        if (bucket.selectedOptions.find((o) => o.uid === e) !== undefined) {
          bucket.y += 1;
        }
      });
    });
  };

  const updateDiff = (list) => {
    distribution.value.forEach((bucket) => {
      bucket.diffY = undefined;
    });
    if (list.length === 0) {
      return;
    }
    distribution.value.forEach((bucket) => {
      bucket.diffY = bucket.y;
    });
    list.forEach((e) => {
      distribution.value.forEach((bucket) => {
        if (bucket.selectedOptions.find((o) => o.uid === e) !== undefined) {
          bucket.diffY -= 1;
        }
      });
    });
  };

  return {
    distribution,
    update,
    updateDiff,
  };
}
