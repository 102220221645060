<template>
  <div
    class="update-feed"
  >
    <div class="_header">
      <updates-list-header
        :slots="slots"
        :show-create-view="showCreateView"
        :show-views-selector="showViewsSelector"
        :read-only="readOnly"
        :allowed-view-types="allowedViewTypes"
        :filter-props="defaultProps"
        :data-loading="initialDataLoaded && listLoading"
        multi-model
        :style="{ paddingBottom: '.6rem' }"
      />
      <m-divider none />
    </div>
    <div :class="['_list', bordered ? '-bordered' : '']">
      <m-spinner
        v-if="!initialDataLoaded"
        class="_spinner"
      />
      <div
        v-else
        class="_inner -feed"
      >
        <div
          v-if="filteredUpdates.length === 0"
          class="_empty"
        >
          {{ $t('general.noEntries') }}
        </div>
        <m-endless-scroll-list
          v-else
          :key="`${listLoading}`"
          @visible="loadMore"
        >
          <div
            v-for="(group, key) in groupedUpdates"
            :key="key"
            class="_sticky-container"
            :style="{ position: 'relative' }"
          >
            <template v-if="!isCurrentWeek(key)">
              <update-divider
                :date="key"
                class="_date-indicator"
              />
              <div class="_divider">
                <m-divider />
              </div>
            </template>
            <update-feed-item
              v-for="item in group"
              :key="item.uid"
              :update="item"
              :wrap-titles="currentView.params.wrapTitles"
              class="_item"
              hide-props
              padding
              :hide-checkin-details="item.goal === null"
            />
          </div>
        </m-endless-scroll-list>
      </div>
    </div>
  </div>
</template>

<script>
import UpdateDivider from '@/components/update-feed/UpdateDivider.vue';
import UpdateFeedItem from '@/components/updates/UpdateFeedItem.vue';
import UpdatesListHeader from '@/components/updates/UpdatesListHeader.vue';
import updateDivider from '@/mixins/update-divider';
import useDebounce from '@/composables/debounce';
import useSnackBar from '@/composables/snackbar';
import useUpdateFeedDefaultProps from '@/composables/saved-views/update-feed-defaut-props';
import useUpdateProperty from '@/composables/property/update-property';
import useUpdates from '@/composables/updates/updates';
import useUpdatesSorting from '@/composables/updates/sort';
import useUsers from '@/composables/user/users';
import { AND } from 'shared/api/query/constants';
import { DateTime } from 'luxon';
import { GoalFilterHandler } from '@/lib/filter/goal/handler';
import { SLOTS, VIEWS_SERVICE } from '@/lib/constants';
import { UpdatesFilterHandler } from '@/lib/filter/updates/handler';
import { computed, inject, ref } from 'vue';
import { extendPath } from '@/lib/filter/filter-relocation';
import { extractFilter } from '@/lib/filter/extract';
import {
  goalActivity as goalActivityConfig,
  goal as goalConfig,
  update as updateConfig,
} from 'shared/api/query/configs.json';
import { isInFilter } from '@/lib/filter/base-frontend-filter/handler';
import { logCatch } from '@/lib/logger/logger';
import { reverseEdge } from 'shared/api/query/edges';
import { updateType, viewType } from 'shared/constants.json';

export default {
  name: 'UpdateFeed',
  props: {
    showViewsSelector: {
      type: Boolean,
      default: false,
    },
    showCreateView: {
      type: Boolean,
      default: false,
    },
    bordered: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const defaultPropsSvc = useUpdateFeedDefaultProps();

    const { debounce } = useDebounce();
    const snackBar = useSnackBar();

    const updatesSvc = useUpdates();
    const viewSvc = inject(VIEWS_SERVICE);

    const page = ref(1);
    const itemsPerPage = ref(20);
    const totalAmount = ref(0);

    const viewGoalActivityFilterObject = computed(() => {
      const goalFilter = extractFilter(viewSvc.currentView.value.params.filter, goalConfig.model);
      const handler = new GoalFilterHandler();
      const f = handler.Translate(goalFilter);
      const { filterTree, varBlocks } = extendPath({
        varBlocks: f.varBlocks,
        from: goalConfig.model,
        path: [
          {
            attr: reverseEdge(goalActivityConfig.edges.goal),
            model: goalActivityConfig.model,
          },
        ],
        filterTree: f.filterTree,
      });
      return {
        children: { filterTree, model: goalActivityConfig.model },
        varBlocks: [...varBlocks, ...f.varBlocks],
      };
    });

    const viewUpdateFilterObject = computed(() => {
      const updateFilter = extractFilter(viewSvc.currentView.value.params.filter, updateConfig.model);
      const handler = new UpdatesFilterHandler();
      return handler.Translate(updateFilter);
    });

    const retrieveUpdates = () => updatesSvc.fetchUpdates({
      filterObjects: [
        viewGoalActivityFilterObject.value,
        viewUpdateFilterObject.value,
        updatesSvc.updateTypesFilterObject([updateType.goal, updateType.checkin]),
        updatesSvc.updateGeneratedFilterObject(),
      ],
      order: viewSvc.currentView.value.params.order,
      pagination: {
        page: page.value,
        itemsPerPage: itemsPerPage.value,
      },
    }).then((data) => {
      totalAmount.value = data.count;
    });

    const initialDataLoaded = ref(false);
    const loadFirst = () => {
      itemsPerPage.value = 20;
      retrieveUpdates().catch(logCatch(() => {
        snackBar.error();
      })).finally(() => {
        initialDataLoaded.value = true;
      });
    };

    loadFirst();

    const propertySvc = useUpdateProperty();
    const userSvc = useUsers();
    const { sort } = useUpdatesSorting(propertySvc.properties, userSvc);
    const filteredUpdates = computed(() => {
      const { filter } = viewSvc.currentView.value.params;

      let res = updatesSvc.updates.value;
      res = res.filter((u) => {
        if (filter.children.length === 0) {
          return true;
        }

        const filterMap = {
          [goalConfig.model]: u.goalActivities.map((ga) => ga.goal),
          [updateConfig.model]: [u],
        };

        const branchResults = Object.entries(filterMap).map(([model, entities]) => {
          const scopeTree = extractFilter(filter, model);
          if (scopeTree === null) {
            return null;
          }

          return entities.find((entity) => isInFilter({ isFilterMode: true })({ entity, scopeTree })) !== undefined;
        }).filter((e) => e !== null);

        if (filter.op === AND) {
          return branchResults.every((p) => p);
        }
        return branchResults.some((p) => p);
      });
      res = res.filter((u) => u.goalActivities.length > 0);

      let order = viewSvc.currentView.value.params.order;
      if (order === undefined) {
        order = [];
      }

      return sort(res, order).slice(0, itemsPerPage.value);
    });

    return {
      debounce,
      defaultProps: defaultPropsSvc.defaultProps,
      currentView: viewSvc.currentView,
      initialDataLoaded,

      updates: updatesSvc.updates,
      updatesLoading: updatesSvc.fetchUpdatesLoading,

      page,
      itemsPerPage,
      totalAmount,
      viewUpdateFilterObject,
      viewGoalActivityFilterObject,

      filteredUpdates,
      loadFirst,
      retrieveUpdates,
    };
  },
  components: { UpdatesListHeader, UpdateFeedItem, UpdateDivider },
  data() {
    return {
      loadMoreLoading: false,
      slots: [{ name: SLOTS.FILTER }, { name: SLOTS.SORTER }],
    };
  },
  computed: {
    allowedViewTypes() {
      return [
        viewType.feed,
      ];
    },
    listLoading() {
      return this.updatesLoading && !this.loadMoreLoading;
    },
    order() {
      return this.currentView.params.order;
    },
  },
  methods: {
    isCurrentWeek(date) {
      return DateTime.local().diff(DateTime.fromISO(date)).as('week') < 1;
    },
    loadMore() {
      if (this.totalAmount === 0) {
        return;
      }

      if (this.totalAmount <= this.itemsPerPage * this.page) {
        return;
      }

      this.itemsPerPage += 20;
      this.loadMoreLoading = true;
      this.retrieveUpdates().finally(() => {
        this.loadMoreLoading = false;
      });
    },
  },
  watch: {
    order(val, oldVal) {
      if (JSON.stringify(val) === JSON.stringify(oldVal)) {
        return;
      }
      this.debounce(this.loadFirst, 700);
    },
    viewUpdateFilterObject: {
      handler(val, oldVal) {
        if (JSON.stringify(val) === JSON.stringify(oldVal)) {
          return;
        }
        this.debounce(this.loadFirst, 700);
      },
      deep: true,
    },
    viewGoalActivityFilterObject: {
      handler(val, oldVal) {
        if (JSON.stringify(val) === JSON.stringify(oldVal)) {
          return;
        }
        this.debounce(this.loadFirst, 700);
      },
      deep: true,
    },
  },
  mixins: [updateDivider],
};
</script>

<style scoped lang="scss" type="text/scss">
  .update-feed {
    padding-bottom: 18rem;
    min-width: 300px;

    ._header {
      position: sticky;
      left: 0;
      top: 0;
      background-color: white;
      z-index: 2;
    }

    ._list {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;

      ._spinner {
        position: absolute;
        top: 2rem;
        left: 46%;
      }

      ._inner {
        position: relative;
        display: block;
        width: 100%;

        ._empty {
          color: $font-color-secondary;
          text-align: center;
        }

        ._sticky-container {
          ._date-indicator {
            position: sticky;
            top: 4rem;
            z-index: 1;
            padding: .1rem 0;
            margin: 6rem 0;

            @media (max-width: $screen-size-md) {
              top: 6rem;
            }
          }

          ._divider {
            position: absolute;
            top: -.8rem;
            left: 0;
            width: 100%;
          }
        }

        &.-feed {
          max-width: 600px;
        }

        ._add {
          width: 100%;
        }
      }

      ._item {
        margin: 2rem 0;

        &.-list {
          margin: .4rem 0;
        }
      }

      &.-bordered {
        ._item {
          margin: 0;
        }
      }
    }

    ._pagination {
      margin: 3rem 0;
    }
  }
</style>
