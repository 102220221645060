/* eslint-disable no-console */
import { datadogRum } from '@datadog/browser-rum';

const debugEnv = import.meta.env.DEV;
const DEBUG_ENABLED = false;
export const debug = (...data) => {
  if (DEBUG_ENABLED) console.debug(...data);
};

export const logError = (err, ctx = undefined) => {
  if (debugEnv) {
    console.error(err, ctx);
    return;
  }
  datadogRum.addError(err, ctx);
};

export const logCatch = (fn = () => {}) => (err) => {
  logError(err);
  return fn(err);
};
