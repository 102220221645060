import { goalType as type } from '@/constants.json';

export const goalType = (goal) => {
  let res = type.unknown;

  for (let i = 0; i < goal.properties.length; i++) {
    if (!goal.properties[i].property.isGoalType) {
      continue;
    }

    if (goal.properties[i].selectedOptions.length === 0) {
      res = type.unknown;
      break;
    }

    if (goal.properties[i].selectedOptions[0].isKeyResult) {
      res = type.keyResult;
      break;
    }

    if (goal.properties[i].selectedOptions[0].isObjective) {
      res = type.objective;
      break;
    }
  }

  return res;
};

export const keyResultOption = (properties) => {
  if (typeof properties === 'undefined') {
    return null;
  }

  const filtered = properties.filter((prop) => prop.isGoalType);
  if (filtered.length === 0) {
    return null;
  }

  const options = filtered[0].options.filter((o) => o.isKeyResult);
  if (options.length === 0) {
    return null;
  }

  return options[0];
};

export const objectiveOption = (properties) => {
  if (typeof properties === 'undefined') {
    return null;
  }

  const filtered = properties.filter((prop) => prop.isGoalType);
  if (filtered.length === 0) {
    return null;
  }

  const options = filtered[0].options.filter((o) => o.isObjective);
  if (options.length === 0) {
    return null;
  }

  return options[0];
};
