import useAccess from '@/composables/access/access';
import useGoals from '@/composables/goal/goals';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import { accessGroupFlag } from 'shared/constants.json';
import { computed } from 'vue';
import { copyAccessPolicy } from '@/lib/access-policy';
import { initChildrenSort } from '@/lib/goal/sort';
import {
  restrictForeignEntityProperties,
  restrictNonWritableProperties,
} from '@/lib/goal/modifier/restrict-properties';
import { stripGoal } from '@/lib/bulk-actions';
import { useI18n } from 'vue-i18n';

export default function useDuplicateGoal() {
  const { t } = useI18n();
  const { loggedInUser, peers, myTeamsIds } = useLoggedInUser();
  const { loggedInUserAccount } = useLoggedInUserAccount();
  const { userHasRight } = useAccess();

  const goalsSvc = useGoals();

  const prepareDuplicate = (input) => {
    const hasForeignEntityReference = computed(() => userHasRight([accessGroupFlag.foreignEntityReference]));
    const { rids, goals } = input.reduce((res, g) => {
      res.rids[g.uid] = res.counter;

      let goal = {
        ...g,
        rid: res.counter,
        goalCycle: g.goalCycle.map((c) => ({ uid: c.uid })),
        account: { uid: loggedInUserAccount.value.uid },
        uid: undefined,
        accessPolicy: copyAccessPolicy(g.accessPolicy),
        properties: g.properties.map((p) => ({
          ...p,
          uid: undefined,
        })),
        creator: { uid: loggedInUser.value.uid },
        subscriptions: [{ active: true, user: { uid: loggedInUser.value.uid } }],
      };

      // TODO: There must be a way to merge this file with goal modifiers (i.e. use goal modifiers to duplicate instead of this one)
      //  Dupe already pass by goal modifiers when importing goals.
      goal = [
        restrictNonWritableProperties,
        restrictForeignEntityProperties({ peers, myTeamsIds, hasForeignEntityReference }),
      ].reduce((res, m) => {
        res = m(res);
        return res;
      }, goal);

      res.goals.push(goal);
      res.counter += 1;
      return res;
    }, { rids: {}, goals: [], counter: 1 });

    const getParents = (goal) => goal.parents.reduce((res, next) => {
      if (typeof rids[next.uid] === 'undefined') {
        res.push({ ...next });
        return res;
      }

      res.push({ uid: undefined, rid: rids[next.uid] });
      return res;
    }, []);

    const getPaysOnto = (goal) => {
      if (typeof goal.paysOnto === 'undefined') {
        return goal.paysOnto;
      }

      return goal.paysOnto.map((g) => {
        if (typeof rids[g.uid] === 'undefined') {
          if (typeof g.uid !== 'undefined') {
            return { uid: g.uid, 'paysOnto|weight': g['paysOnto|weight'] };
          }
          return g;
        }

        return { uid: undefined, rid: rids[g.uid], 'paysOnto|weight': g['paysOnto|weight'] };
      });
    };

    return goals.map((g) => {
      const next = {
        ...g,
        childrenSort: initChildrenSort(g, rids),
        paysOnto: getPaysOnto(g),
      };

      if (next.parents.length === 0) {
        return stripGoal(next);
      }

      return stripGoal({
        ...next,
        parents: getParents(next),
      });
    });
  };

  const duplicateSingle = (goal) => duplicate([goal]);
  const duplicate = (goals) => {
    const preparedGoals = prepareDuplicate(goals).map((g) => {
      g.title = `${g.title} - ${t('general.copy')}`;
      return g;
    });

    return goalsSvc.createMultiple(preparedGoals);
  };

  return {
    duplicateLoading: goalsSvc.createLoading,
    duplicateSingle,
    duplicate,
    prepareDuplicate,
  };
}
