<template>
  <div class="goal-dashboard-settings">
    <div class="_row">
      <div class="_bubble -red" />
      <div class="_start">
        0
      </div>
      <div class="_till">
        -
      </div>
      <div class="_input">
        <m-input-number
          v-model:value="redThreshold"
          :min="0"
          :max="100"
          class="_input"
        />
      </div>
      <div class="_suffix">
        %
      </div>
    </div>
    <div class="_row">
      <div class="_bubble -yellow" />
      <div class="_start">
        {{ redThreshold }}
      </div>
      <div class="_till">
        -
      </div>
      <div>
        <m-input-number
          v-model:value="yellowThreshold"
          :min="redThreshold"
          :max="100"
          class="_input"
        />
      </div>
      <div class="_suffix">
        %
      </div>
    </div>
    <div class="_row">
      <div class="_bubble -green" />
      <div class="_start">
        {{ yellowThreshold }}
      </div>
      <div class="_till">
        -
      </div>
      <div class="_start">
        100
      </div>
      <div class="_suffix">
        %
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'GoalDashboardSettings',

  props: {
    value: {
      type: Object,
      default: () => null,
    },
  },
  emits: ['input', 'update:value'],
  data() {
    return {
      redThreshold: 0,
      yellowThreshold: 0,
    };
  },
  methods: {
    emitUpdate() {
      this.$emit('input', {
        redThreshold: this.redThreshold,
        yellowThreshold: this.yellowThreshold,
      });
      this.$emit('update:value', {
        redThreshold: this.redThreshold,
        yellowThreshold: this.yellowThreshold,
      });
    },
  },
  watch: {
    redThreshold() {
      this.emitUpdate();
    },
    yellowThreshold() {
      this.emitUpdate();
    },
  },
  created() {
    this.redThreshold = this.value.redThreshold;
    this.yellowThreshold = this.value.yellowThreshold;
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .goal-dashboard-settings {
    ._description {
      color: $font-color-secondary;
    }

    ._row {
      display: flex;
      align-items: center;
      height: 3.2rem;
      margin-bottom: .8rem;

      &:last-child {
        margin-bottom: 0;
      }

      ._bubble {
        width: 2rem;
        height: 2rem;
        margin-right: 1rem;
        border-radius: 50%;

        &.-red {
          background-color: map_get($red, 'base');
        }

        &.-yellow {
          background-color: map_get($yellow, 'base');
        }

        &.-green {
          background-color: map_get($green, 'base');
        }

        &.-blue {
          background-color: map_get($blue, 'base');
        }
      }

      ._start {
        width: 2.4rem;
        text-align: right;
      }

      ._till {
        width: 2rem;
        text-align: center;
      }

      ._input {
        max-width: 6rem;
      }

      ._suffix {
        margin-left: .4rem;
      }
    }
  }
</style>
