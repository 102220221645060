<template>
  <div class="sign-in-content">
    <slot />
  </div>
</template>

<script>
export default { name: 'SignInContent' };
</script>

<style scoped lang="scss" type="text/scss">
  .sign-in-content {
    display: flex;
    flex-direction: column;
    justify-content: center;

    ._action {
      display: flex;
      justify-content: center;
      margin-top: 1rem;
    }
  }
</style>
