<template>
  <div class="saml-login">
    <div class="_item">
      <div
        v-if="isOkta"
        class="_label"
      >
        {{ $t('okta.email') }}
      </div>
      <div
        v-else
        class="_label"
      >
        {{ $t('general.email') }}
      </div>
      <m-text-field
        v-model:value="email"
        auto-focus
        :placeholder="isOkta ? $t('okta.enterEmail') : $t('loginForm.enterEmail')"
        name="username"
        input-type="text"
        @keydown.enter="submit"
      >
        <template #prefix>
          <m-icon
            type="mail"
            :color="$colors.grey.lighten2"
          />
        </template>
      </m-text-field>
    </div>
    <div v-if="error !== ''">
      <m-alert type="error">
        {{ error }}
      </m-alert>
    </div>
    <div class="_action">
      <m-btn
        color="primary"
        :button-style="{ width: '100%' }"
        :style="{ width: '100%' }"
        :href="loginURL"
        @click="$emit('submit', email)"
      >
        {{ $t('login.samlCallToAction') }}
      </m-btn>
    </div>
  </div>
</template>

<script>
import okta from '@/mixins/okta';
import { camelCase } from 'lodash-es';
import { encodeURLValues } from '@/lib/url';

export default {
  name: 'SamlLogin',
  props: {
    followLink: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['submit'],
  data() {
    return { email: '' };
  },
  computed: {
    loginURL() {
      if (!this.followLink) {
        return null;
      }
      return `/api/v1/saml/login?${encodeURLValues({ email: this.email })}`;
    },
    error() {
      if (typeof this.$route.query.idp_origin !== 'undefined') {
        return '';
      }

      if (typeof this.$route.query.saml_login_status === 'undefined') {
        return '';
      }

      return this.$t(`login.samlLoginStatus.${camelCase(this.$route.query.saml_login_status)}`);
    },
  },
  methods: {
    submit() {
      this.$emit('submit', this.email);

      if (this.loginURL !== null) {
        window.location.href = this.loginURL;
      }
    },
  },
  mixins: [okta],
};
</script>

<style scoped lang="scss" type="text/scss">
  .saml-login {
    ._item {
      margin-bottom: 1rem;

      ._label {
        font-size: $font-size-3;
        color: $font-color-secondary;
      }
    }
  }
</style>
