import { iconFromIconPicker } from 'shared/lib/icon-picker';
import { iconType } from 'shared/constants.json';
import { isEmpty, isNullOrUndefined } from 'shared/lib/object/object';

export class Icon {
  constructor({ value, type = iconType.emoji } = { value: undefined, type: iconType.emoji }) {
    this.value = value;
    this.type = type;

    switch (typeof value) {
      case 'string':
        if (iconFromIconPicker(value)) {
          this.type = iconType.icon;
        }
        break;
      case 'object':
        this.type = iconType.custom;
        break;
      default:
    }

    if (isNullOrUndefined(value) && [iconType.emoji, iconType.icon].includes(this.type)) {
      this.value = '';
    }
  }

  IsEmpty() {
    return isEmpty(this.value);
  }
}

export const buildIcon = (value, type) => {
  if (value === null && type === undefined) {
    return null;
  }

  return new Icon({ value, type });
};

export const isEmptyIcon = (icon) => isNullOrUndefined(icon) || icon.IsEmpty();

export const buildIconFromEntity = (entity) => {
  if (isNullOrUndefined(entity)) {
    return null;
  }
  if (!isNullOrUndefined(entity.image)) {
    return buildIcon(entity.image);
  }
  if (!isEmpty(entity.icon)) {
    return buildIcon(entity.icon);
  }
  return null;
};

export const replaceIconOnEntity = (entity, icon) => {
  const removeIcon = (entity) => {
    entity.icon = '';
  };
  const removeImage = (entity) => {
    if (!isNullOrUndefined(entity.image)) {
      entity.image = null;
    }
  };
  if (isEmptyIcon(icon)) {
    removeIcon(entity);
    removeImage(entity);
    return entity;
  }
  switch (icon.type) {
    case iconType.custom:
      removeIcon(entity);
      entity.image = icon.value;
      break;
    default:
      removeImage(entity);
      entity.icon = icon.value;
  }
  return entity;
};
