<template>
  <m-dialog
    v-model:value="showModal"
    :title="$t('fieldTranslator.heading')"
    hide-footer
  >
    <div
      class="_list"
    >
      <div>
        <translator-item
          v-for="item in items"
          :key="item.lang"
          :item="item"
          @change="change"
        />
      </div>
    </div>
  </m-dialog>
</template>

<script>
import TranslatorItem from '@/components/TranslatorItem.vue';

export default {
  name: 'FieldTranslator',
  props: {
    input: {
      type: Object,
      required: true,
    },
  },
  emits: ['change-translation'],
  components: { TranslatorItem },
  data() {
    return { showModal: false };
  },
  computed: {
    items() {
      return Object.keys(this.input).map((key) => ({ lang: key, value: this.input[key] }));
    },
  },
  methods: {
    show() {
      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    },
    change({ item }) {
      this.$emit('change-translation', { title: { ...this.input, ...item } });
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
</style>
