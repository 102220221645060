<template>
  <item-wrapper
    v-if="users.length > 0"
    :show-tooltip="showTooltip"
    :tooltip="label"
    tooltip-placement="bottom"
  >
    <div :class="['user-prop', wrap ? '-wrap' : '']">
      <div
        v-for="user in users"
        :key="user.uid"
        class="_user"
      >
        <user-display
          :user="user"
          :small="small"
          :m-style="mStyle"
          :hide-name="!showUserName"
        />
      </div>
    </div>
  </item-wrapper>
</template>

<script>
import ItemWrapper from '@/components/list/ItemWrapper.vue';
import UserDisplay from 'shared/components/UserDisplay.vue';
import { mStyleProps, resolveStyles } from 'shared/lib/m-style-props';

export default {
  name: 'UserProp',
  props: {
    ...mStyleProps,
    label: {
      type: String,
      default: '',
    },
    users: {
      type: Array,
      default: () => [],
    },
    showTooltip: {
      type: Boolean,
      default: false,
    },
    showUserName: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    wrap: {
      type: Boolean,
      default: false,
    },
  },
  components: { UserDisplay, ItemWrapper },
  methods: { resolveStyles },
};
</script>

<style scoped lang="scss" type="text/scss">
  .user-prop {
    display: flex;
    align-items: center;

    ._user {
      display: flex;
      margin: .2rem .2rem;

      ._user-avatar {
        &:not(:first-of-type) {
          margin-left: -.4rem;
        }
      }

      ._user-name {
        margin-left: .4rem;
        white-space: nowrap;
      }
    }

    &.-wrap {
      flex-wrap: wrap;
    }
  }
</style>
