<template>
  <m-dialog
    v-model:value="showDialog"
    :title="dialog.title"
    hide-header
    hide-footer
    :max-width="$modalSizes.xl"
    no-padding
    center
    @close="hide"
  >
    <div class="feature-gate">
      <m-content
        class="_left"
        :padding-y="12"
      >
        <div class="_close-btn">
          <m-btn
            icon="close"
            fab
            small
            light
            hide-border
            @click="hide"
          />
        </div>
        <div class="_body">
          <m-content
            class="_title"
            :padding-x="13"
          >
            <m-icon
              v-if="dialog.titleIcon !== undefined"
              :type="dialog.titleIcon"
              class="_icon"
              size="18"
            />
            {{ dialog.title }}
            <h3 v-if="dialog.subTitle !== undefined">
              {{ dialog.subTitle }}
            </h3>
          </m-content>
          <m-content
            class="_description"
            :padding-x="13"
          >
            <div
              v-for="(item, index) in dialog.description"
              :key="index"
              class="_paragraph"
            >
              <m-emoji
                v-if="item.type === 'bullet'"
                class="_bullet-icon"
                :emoji="item.icon"
                :size="14"
              />
              {{ item.content }}
            </div>
          </m-content>
        </div>
        <m-content
          class="_actions"
          :padding-x="13"
        >
          <component
            :is="action.tooltip !== undefined ? 'm-tooltip' : 'div'"
            v-for="action in dialog.actions"
            :key="action.title"
            class="_action"
          >
            <m-btn
              :color="action.color"
              :light="action.light"
              block
              :to="actionTo(action)"
              :href="action.href"
              :target="action.target"
              :disabled="action.disabled"
              @click="actionClick(action)"
            >
              {{ action.title }}
              <m-icon
                v-if="actionIsExternalLink(action)"
                type="external-link"
                :style="{ marginLeft: '.8rem' }"
              />
            </m-btn>
            <template #title>
              {{ action.tooltip }}
            </template>
          </component>
        </m-content>
      </m-content>
      <div
        v-if="dialog.image !== undefined"
        class="_right"
      >
        <img
          class="_image"
          :style="dialog.image.style"
          :alt="dialog.image.alt"
          :src="resolveFileName(dialog.image.src)"
        >
      </div>
    </div>
  </m-dialog>
</template>

<script>

export default {
  name: 'MFeatureGate',
  props: {
    eventBus: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showDialog: false,
      dialog: {
        title: '',
        description: '',
        actions: [],
      },
    };
  },
  methods: {
    resolveFileName(filepath) {
      return import.meta.env.VITE_PUBLIC_PATH + filepath;
    },
    actionTo(action) {
      if (action.route !== undefined) {
        return this.$router.resolve({ name: action.route }).fullPath;
      }
      return '';
    },
    actionClick(action) {
      if (action.handleClick !== undefined) {
        action.handleClick();
      }
      this.hide();
    },
    actionIsExternalLink(action) {
      return action.href !== undefined;
    },
    show(dialog) {
      this.showDialog = true;
      this.dialog = dialog;
    },
    hide() {
      this.showDialog = false;
    },
  },
  created() {
    this.eventBus.$on('show-feature-gate', this.show);
    this.eventBus.$on('hide-feature-gate', this.hide);
  },
  beforeUnmount() {
    this.eventBus.$off('show-feature-gate', this.show);
    this.eventBus.$off('hide-feature-gate', this.hide);
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .feature-gate {
    display: grid;
    grid-template-columns: 3fr 4fr;
    height: 100%;

    ._left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      ._close-btn {
        position: absolute;
        top: 1rem;
        right: 1rem;
        display: none;
      }

      ._body {
        ._title {
          ._icon {
            display: inline;
            vertical-align: baseline;
          }
        }

        ._description {
          display: flex;
          flex-direction: column;
          max-height: 40vh;
          overflow: auto;

          ._paragraph {
            display: flex;
            margin-bottom: 1em;

            ._bullet-icon {
              margin-top: .3rem;
              margin-right: .6rem;
            }
          }
        }
      }

      ._actions {
        ._action {
          margin-top: 1rem;
        }
      }
    }

    ._right {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 6rem;
      overflow: hidden;
      background: map_get($beige, 'base');

      ._image {
        width: 100%;
        height: auto;
      }
    }
  }

  @media only screen and (max-width: $screen-size-md) {
    .feature-gate {
      display: block;

      ._left {
        ._close-btn {
          display: block;
        }

        ._body {
          ._description {
            max-height: 70vh;
          }
        }
      }

      ._right {
        display: none;
      }
    }
  }
</style>
