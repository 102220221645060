<template>
  <div class="form-language-settings-card">
    <page-header
      color="white"
      :title="$t('formSettingsCard.languageHeading')"
      heading="h3"
    >
      <template #actions>
        <div

          class="_actions"
        >
          <m-btn
            icon="close"
            hide-border
            fab
            :to="createUrl"
          />
        </div>
      </template>
    </page-header>
    <m-content padding>
      <m-divider xxs />
      <transition-group
        mode="out-in"
        name="list-left"
      >
        <div
          v-for="l in languages"
          :key="l"
        >
          <form-language-item
            :lang="l"
            :disabled="disabled || languages.length === 1"
            @delete-language="deleteLanguage"
          />
          <m-divider
            xxs
          />
        </div>
      </transition-group>
    </m-content>
    <m-content
      v-if="!disabled"
      padding
    >
      <div />
      <div
        class="_lang-picker"
      >
        <m-form-item
          :label="$t('formLanguageSettingsCard.addLanguage')"
        >
          <m-lang-picker
            v-model:value="lang"
            :placeholder="$t('formSettingsCard.addLanguage')"
            :excluded-language-codes="languages"
            class="_lang"
          />
        </m-form-item>
        <m-btn
          :style="{ marginLeft: '1.2rem' }"
          @click="addLanguage"
        >
          {{ $t('general.add') }}
        </m-btn>
      </div>
    </m-content>
  </div>
</template>

<script>
import FormLanguageItem from '@/components/FormLanguageItem.vue';
import MLangPicker from '@/components/MLangPicker.vue';
import PageHeader from 'shared/components/PageHeader.vue';

export default {
  name: 'FormLanguageSettingsCard',
  props: {
    languages: {
      type: Array,
      required: true,
    },
    createUrl: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['add-language', 'delete-language'],
  components: { PageHeader, MLangPicker, FormLanguageItem },
  data() {
    return { lang: '' };
  },
  methods: {
    addLanguage() {
      this.$emit('add-language', { lang: this.lang });
    },
    deleteLanguage({ lang }) {
      this.$emit('delete-language', { lang });
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .form-language-settings-card {
    ._lang-picker {
      display: flex;
      align-items: center;
      width: 100%;

      ._lang {
        min-width: 15rem;
      }
    }
  }
</style>
