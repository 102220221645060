import { RRule } from 'rrule';

export const germanStrings = (freq, byweekday, interval, bysetpos) => {
  const germanTranslations = {
    every: 'Jede',
    until: 'bis zum',
    day: 'Tag',
    days: 'Tage',
    week: 'Woche',
    weeks: 'Wochen',
    on: 'am',
    in: 'in',
    'on the': 'am',
    for: 'für',
    and: 'und',
    or: 'oder',
    at: 'am',
    last: 'zuletzt',
    '(~ approximate)': '(~ ungefähr)',
    times: 'Mal',
    time: 'Mal',
    minutes: 'Minuten',
    hours: 'Stunden',
    weekdays: 'Wochentage',
    weekday: 'Wochentag',
    months: 'Monate',
    month: 'Monat',
    years: 'Jahre',
    year: 'Jahr',
    dayNames: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
    monthNames: [
      'Januar',
      'Februar',
      'März',
      'April',
      'Mai',
      'Juni',
      'Juli',
      'August',
      'September',
      'Oktober',
      'November',
      'Dezember',
    ],
  };
  if (freq === RRule.YEARLY) {
    germanTranslations.every = 'Jedes';
    if (interval > 1) {
      germanTranslations.every = 'Alle';
    }
  }
  if (freq === RRule.MONTHLY) {
    germanTranslations.every = 'Jeden';
    if (interval > 1) {
      germanTranslations.every = 'Alle';
    }
    if (bysetpos !== null && bysetpos !== 'undefined') {
      germanTranslations.on = `am ${bysetpos.sort((a, b) => {
        if (a === -1) {
          return 1;
        }
        if (b === -1) {
          return -1;
        }
        return a > b;
      }).join('., ')}.`;
      germanTranslations.on = germanTranslations.on.replace('-1.', 'letzten');
    }
  }
  if (freq === RRule.WEEKLY) {
    germanTranslations.every = 'Jede';
    if (interval > 1) {
      germanTranslations.every = 'Alle';
    }

    if (JSON.stringify(byweekday.sort((a, b) => a.weekday - b.weekday))
      === JSON.stringify([RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR])) {
      germanTranslations.every = 'Jeden';
    }
  }
  if (freq === RRule.DAILY) {
    germanTranslations.every = 'Jeden';
    if (interval > 1) {
      germanTranslations.every = 'Alle';
    }
  }

  return germanTranslations;
};
