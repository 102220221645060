<template>
  <item-wrapper
    v-if="props.spaces.length > 0"
    :tooltip="props.label"
    :show-tooltip="props.showTooltip"
    @click="emitClick"
  >
    <div
      :class="['space-prop', wrap ? '-wrap' : '']"
    >
      <m-tag
        v-for="(space, index) in props.spaces"
        :key="index"
        :type="space.type"
        :icon="buildIconFromEntity(space)"
        :color="space.color"
        :m-style="mStyle"
        automatic-color-fallback
        small
        class="_tag"
        :title="space.title"
        @click="spaceClick(space)"
      />
    </div>
  </item-wrapper>
</template>

<script setup>
import ItemWrapper from '@/components/list/ItemWrapper.vue';
import { buildIconFromEntity } from 'shared/lib/icon';
import { mStyleProps } from 'shared/lib/m-style-props';

const props = defineProps({
  ...mStyleProps,
  label: {
    type: String,
    default: '',
  },
  spaces: {
    type: Array,
    required: true,
  },
  showTooltip: {
    type: Boolean,
    default: false,
  },
  wrap: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['space-clicked', 'click']);

const spaceClick = (space) => {
  emit('space-clicked', space);
};

const emitClick = (e) => {
  e.stopPropagation();
  emit('click');
};
</script>

<style scoped lang="scss" type="text/scss">
  .space-prop {
    display: flex;
    align-items: center;

    ._tag {
      margin: .2rem .2rem;
    }

    &.-wrap {
      flex-wrap: wrap;
    }
  }
</style>
