<template>
  <base-chart
    class="m-chart"
    :options="chartOptions"
  />
</template>

<script>
import BaseChart from '@/components/dashboard/BaseChart.vue';
import { chartType } from 'shared/constants.json';
import { colorizeFlatMultiSeriesFromBadToGood, colorizeFromBadToGood } from '@/lib/charts/colors';
import {
  fillEmptyValuesInMultiSeries,
  flattenSeriesByIndex,
  getCategoriesFromSeriesNames,
  sortSeriesByName,
  transformDistributionToSeries,
  transformMultipleRawSeries,
} from '@/lib/charts/transform';
import { getBaseColumnChartOptions, getDistributionColumnChart } from '@/lib/charts/column-chart';
import { getBaseLineChartOptions } from '@/lib/charts/line-chart';
import { getDistributionBarChart } from '@/lib/charts/distribution-bar-chart';

export default {
  name: 'MChart',
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    chart: {
      type: Object,
      required: true,
    },
    maxValue: {
      type: Number,
      default: 0,
    },
    startsFromZero: {
      type: Boolean,
      default: false,
    },
  },
  components: { BaseChart },
  computed: {
    minValue() {
      if (this.startsFromZero) {
        return 0;
      }
      return 1;
    },
    isFactor() {
      return this.chart.factor !== null && typeof this.chart.factor !== 'undefined';
    },
    chartOptions() {
      const multiSeries = this.getMultiSeries();

      switch (this.chart.type) {
        case chartType.lineChart: {
          return getBaseLineChartOptions([], multiSeries);
        }
        case chartType.columnChart: {
          const categories = getCategoriesFromSeriesNames(multiSeries);
          const latestIndex = this.chartData.intervals.length - 1;
          const flatMultiSeries = flattenSeriesByIndex(multiSeries, latestIndex);

          if (this.chart.calculateDistribution) {
            const colorizedSeries = colorizeFlatMultiSeriesFromBadToGood(
              flatMultiSeries,
              this.maxValue,
              this.startsFromZero,
            );
            return getDistributionColumnChart(categories, colorizedSeries);
          }
          return getBaseColumnChartOptions(categories, flatMultiSeries);
        }
        case chartType.barChart: {
          const categories = getCategoriesFromSeriesNames(multiSeries);
          const latestIndex = this.chartData.intervals.length - 1;
          const flatMultiSeries = flattenSeriesByIndex(multiSeries, latestIndex);

          return getBaseColumnChartOptions(categories, flatMultiSeries);
        }
        case chartType.stackedBarChart: {
          if (this.chart.calculateDistribution) {
            const colorizedSeries = colorizeFromBadToGood(multiSeries, this.maxValue, this.startsFromZero);
            return getDistributionBarChart(colorizedSeries, { isFactor: this.isFactor });
          }
          break;
        }
        default:
          return { series: [] };
      }

      return { series: [] };
    },
  },
  methods: {
    getMultiSeries() {
      if (this.chart.calculateDistribution && this.chart.type !== chartType.lineChart) {
        const latestIndex = this.chartData.intervals.length - 1;
        const multiSeries = transformDistributionToSeries(this.chartData.series[0].data[latestIndex].distribution);
        fillEmptyValuesInMultiSeries(multiSeries, this.minValue, this.maxValue);
        return sortSeriesByName(multiSeries);
      }
      return transformMultipleRawSeries(this.chartData.series);
    },
  },
};
</script>

<style lang="scss" type="text/scss">

</style>
