<template>
  <div class="oauth-code-grant">
    <div
      class="_settings-switch"
    >
      <settings-switch-item
        :title="$t('oauthCodeGrant.actionTitle')"
        :sub-title="$t('oauthCodeGrant.actionSubtitle')"
        :value="enabled"
        icon="login"
        @click="handleSwitch"
      />
    </div>
    <m-content
      v-if="enabled"
      :padding-y="7"
    >
      <div class="_item">
        <div class="_label">
          {{ $t('oauthCodeGrant.clientId') }}
        </div>
        <m-text-field
          :value="localCodeGrantClient.clientId"
          read-only
        />
      </div>
      <div class="_item">
        <div class="_label">
          {{ $t('oauthCodeGrant.clientSecret') }}
        </div>
        <m-input-password
          read-only
          :value="localCodeGrantClient.clientSecret"
        />
      </div>
      <div class="_item">
        <div class="_label">
          {{ $t('oauthCodeGrant.redirectUrls') }}
        </div>
        <div
          v-for="(redirectUrl, index) in localCodeGrantClient.redirectUrls"
          ref="items"
          :key="index"
          class="_redirect-url"
        >
          <m-text-field
            v-model:value="localCodeGrantClient.redirectUrls[index]"
            :auto-focus="index === autoFocusId"
            @input="dirty=true"
          />
          <m-btn
            class="_action"
            icon="delete"
            fab
            hide-border
            @click="removeRedirectUrl(index)"
          />
        </div>
        <m-content
          :padding-y="7"
          class="_actions"
        >
          <m-btn
            icon="plus"
            small
            hide-border
            :style="{marginRight: '.8rem'}"
            @click="addRedirectUrl"
          >
            {{ $t('oauthCodeGrant.addRedirectUrl') }}
          </m-btn>
          <m-btn
            color="primary"
            small
            :loading="updateLoading"
            :disabled="!dirty"
            @click="update"
          >
            {{ $t('oauthCodeGrant.save') }}
          </m-btn>
        </m-content>
      </div>
    </m-content>
  </div>
</template>

<script>
import SettingsSwitchItem from '@/components/SettingsSwitchItem.vue';
import useOauthCodeGrantClient from '@/composables/user-provisioning/oauth-code-grand-client';
import useUserProvisioning from '@/composables/user-provisioning/user-provisioning';
import { copy } from 'shared/lib/copy';
import { logCatch } from '@/lib/logger/logger';
import { ref } from 'vue';

export default {
  name: 'OauthCodeGrant',
  components: { SettingsSwitchItem },
  setup() {
    const { userProvisioning } = useUserProvisioning();
    const { updateSingle, updateLoading, createSingle, oauthCodeGrantClient } = useOauthCodeGrantClient();
    return {
      localCodeGrantClient: ref(null),
      dirty: ref(false),
      updateLoading,
      autoFocusId: ref(-1),
      userProvisioning,
      updateOauthCodeGrantClient: updateSingle,
      createOauthCodeGrantClient: createSingle,
      oauthCodeGrantClient,
    };
  },
  computed: {
    enabled() {
      if (this.localCodeGrantClient === undefined) {
        return false;
      }

      return this.localCodeGrantClient.pausedAt === null;
    },
  },
  methods: {
    handleSwitch() {
      if (this.enabled) {
        this.disable();
        return;
      }

      this.enable();
    },
    enable() {
      if (this.oauthCodeGrantClient !== undefined) {
        this.updateEntity({ uid: this.oauthCodeGrantClient.uid, pausedAt: null });
        return;
      }

      this.createOauthCodeGrantClient(
        { userProvisioning: { uid: this.userProvisioning.uid } },
      ).then((entity) => {
        this.localCodeGrantClient = copy(entity);
      }).catch(logCatch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      }));
    },
    disable() {
      this.updateEntity({ uid: this.localCodeGrantClient.uid, pausedAt: (new Date()) });
    },
    update() {
      this.updateEntity({ uid: this.localCodeGrantClient.uid, redirectUrls: this.localCodeGrantClient.redirectUrls });
    },
    updateEntity(entity) {
      this.updateOauthCodeGrantClient(entity).then((data) => {
        this.dirty = false;
        this.localCodeGrantClient = copy(data);
      }).catch(logCatch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      }));
    },
    removeRedirectUrl(index) {
      this.localCodeGrantClient.redirectUrls.splice(index, 1);
      this.dirty = true;
    },
    addRedirectUrl() {
      this.autoFocusId = this.localCodeGrantClient.redirectUrls.length;
      this.localCodeGrantClient.redirectUrls.push('');
      this.dirty = true;
    },
  },
  created() {
    this.localCodeGrantClient = copy(this.oauthCodeGrantClient);
  },
};
</script>

<style lang="scss" type="text/scss" scoped>
  .oauth-code-grant {
    ._item {
      margin-bottom: 1.4rem;

      ._label {
        margin-top: .8rem;
        margin-bottom: .4rem;
        color: $font-color-secondary;
      }

      ._redirect-url {
        display: flex;

        ._action {
          margin-left: .6rem;
        }

        margin-bottom: .6rem;
      }

      ._actions {
        display: flex;
      }
    }
  }
</style>
