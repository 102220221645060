<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.0858 18.336C17.5626 18.7356 17.5626 19.469 17.0858 19.8687L16.3787 20.4615C16.007 20.773 15.4654 20.773 15.0938 20.4615L5.91419 12.7664C5.43742 12.3667 5.43742 11.6333 5.91419 11.2336L15.0938 3.53853C15.4654 3.227 16.007 3.227 16.3787 3.53853L17.0858 4.13132C17.5626 4.531 17.5626 5.26436 17.0858 5.66403L9.52753 12L17.0858 18.336Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default { name: 'Left' };
</script>

<style scoped lang="scss" type="text/scss">

</style>
