<template>
  <m-text-field
    v-model:value="val"
    class="m-input-password"
    :input-type="show ? 'text' : 'password'"
    :read-only="readOnly"
    @input="input"
  >
    <template #suffix>
      <div
        class="_action"
        @click="show = !show"
      >
        <m-icon
          v-if="show"
          :color="$colors.grey.lighten2"
          type="eye"
        />
        <m-icon
          v-else
          :color="$colors.grey.lighten2"
          type="eye-invisible"
        />
      </div>
    </template>
  </m-text-field>
</template>

<script>
export default {
  name: 'MInputPassword',
  props: {
    value: {
      type: String || Object,
      default: '',
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input', 'update:value', 'change'],
  data() {
    return {
      show: false,
      val: '',
    };
  },
  methods: {
    input(value) {
      this.$emit('input', value);
      this.$emit('update:value', value);
      this.$emit('change', value);
    },
  },
  watch: {
    value(val) {
      this.val = val;
    },
  },
  mounted() {
    this.val = this.value;
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .m-input-password {
    ._action {
      cursor: pointer;
    }
  }
</style>
