import { DEFAULT_LANGUAGE } from '@/lib/language';
import { goal as goalConfig, propertyValue } from 'shared/api/query/configs.json';
import { iconByType } from '@/lib/property';
import { propertyType } from 'shared/constants.json';
import { shallowCopy } from 'shared/lib/copy';
import { sortByArray, sortDatetimeInterval } from 'shared/lib/sort';
import { textByLang } from 'shared/lib/language';

const propWidth = (prop) => {
  if (prop.property.isGoalType) {
    return 120;
  }

  switch (prop.property.type) {
    case propertyType.date:
      return 120;
    case propertyType.number:
      return 120;
    case propertyType.user:
      return 180;
    default:
      return 150;
  }
};

/* eslint-disable default-param-last */
export const createProp = (property, isDirect, useShowByDefault = true, show = false, lang = DEFAULT_LANGUAGE, model) => {
  const prop = {
    isDirect,
    property: {
      ...property,
      model,
      icon: iconByType({ edgeName: property.edgeName, type: property.type }),
    },
    show,
    isTitle: property.isTitle,
    hideInProps: property.hideInProps,
    disableInProps: property.disableInProps,
    noSortInProps: property.noSortInProps,
    noIsEmptyFilter: property.noIsEmptyFilter,
    width: property.width,
  };

  if (typeof prop.width === 'undefined') {
    prop.width = propWidth(prop);
  }

  if (!isDirect) {
    prop.key = property.uid;
    prop.label = textByLang(property.label, lang);
    if (property.showInDefaultView) {
      prop.show = property.showInDefaultView;
    }
    return prop;
  }

  prop.key = property.edgeName;
  prop.label = property.label;
  if (useShowByDefault) {
    prop.show = property.showByDefault;
  }

  return prop;
};
/* eslint-enable default-param-last */

export const sortedPropertyElements = (elements, orderByArray) => {
  const cp = shallowCopy(elements);
  cp.sort(sortByArray(orderByArray.map((uid) => ({ uid }))));
  return cp;
};

/* eslint-disable default-param-last */
export const getValueFromEntity = (prop, entity, propertyValuesKey = 'properties') => {
  if (prop.isDirect) {
    let val = entity[prop.property.edgeName];
    if (prop.key === goalConfig.edges.goalCycle) {
      val = shallowCopy(val).sort(sortDatetimeInterval('start', 'end'));
    }
    return val;
  }
  const filtered = entity[propertyValuesKey].filter((pv) => pv.property.uid === prop.property.uid);
  if (filtered.length === 0) {
    return null;
  }

  const pv = filtered[0];
  switch (prop.property.type) {
    case propertyType.singleSelect:
    case propertyType.options:
    case propertyType.status:
      return pv[propertyValue.edges.selectedOptions];
    case propertyType.space:
      return pv[propertyValue.edges.spaces];
    case propertyType.user:
      return pv[propertyValue.edges.users];
    case propertyType.date:
      return pv[propertyValue.edges.timestamp];
    case propertyType.number:
      return pv[propertyValue.edges.number];
    case propertyType.text:
      return pv[propertyValue.edges.text];
    case propertyType.url:
      return pv[propertyValue.edges.text];
    default:
      return null;
  }
};
/* eslint-enable default-param-last */

export const createPropsList = ({ properties, directProperties, userLang, model }) => [
  ...properties.map((p) => createProp(p, false, true, false, userLang, model)),
  ...directProperties.map((p) => createProp(p, true, true, true, userLang, model)),
].sort((a, b) => {
  if (a.property.type === 'status' && b.property.type !== 'status') {
    return 1;
  }
  if (a.property.type !== 'status' && b.property.type === 'status') {
    return -1;
  }
  return 0;
});
