import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useRepo from '@/nebula/repo';
import { accessPolicyType } from 'shared/constants.json';
import { computed } from 'vue';
import { copyAccessPolicy } from '@/lib/access-policy';
import { goalSettings as goalSettingsConfig } from 'shared/api/query/configs.json';
import { useI18n } from 'vue-i18n';

export default function useGoalSettings() {
  const { t } = useI18n();
  const { loggedInUserAccount } = useLoggedInUserAccount();
  const repo = useRepo(goalSettingsConfig.model);

  const goalSettings = computed(() => repo.selectSingle(loggedInUserAccount.value.goalSettings.uid));

  const newGoalDefaultAccessPolicy = computed(() => {
    if (goalSettings.value === undefined || goalSettings.value.defaultAccessPolicy === null) {
      return {
        account: loggedInUserAccount.value,
        accountAccess: accessPolicyType.write,
      };
    }
    return copyAccessPolicy(goalSettings.value.defaultAccessPolicy);
  });

  const setDefaultValueFeatureName = () => {
    const setDefaultVal = (val, defaultVal) => {
      if (val === '') {
        return defaultVal;
      }
      return val;
    };

    repo.updateSingle({
      uid: goalSettings.value.uid,
      featureNameSingular: setDefaultVal(goalSettings.value.featureNameSingular, t('goalSettings.featureName.defaultSingular')),
      featureNamePlural: setDefaultVal(goalSettings.value.featureNamePlural, t('goalSettings.featureName.defaultPlural')),
    }, { commitToRemote: false });
  };

  return {
    goalSettings,
    updateGoalSettings: repo.updateSingle,
    newGoalDefaultAccessPolicy,
    setDefaultValueFeatureName,
  };
}
