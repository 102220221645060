<template>
  <div class="access-group-list">
    <m-table
      :columns="columns"
      :data-source="data"
      :custom-row="handleRowClick"
      :pagination="false"
      rows-clickable
    >
      <template #name="{ row }">
        <span>
          {{ row.name }}
        </span>
      </template>
      <template #members="{ row }">
        <div
          class="_members"
        >
          {{ row.members }}
          <div class="_actions">
            <m-btn
              v-if="!row.isNotDeletable"
              hide-border
              class="_action"
              icon="delete"
              small
              fab
              @click.stop="confirmDelete(row.key)"
            />
          </div>
        </div>
      </template>
    </m-table>
    <m-drawer
      :key="selectedAccessGroup.uid"
      :title="$t('accessGroupList.editGroup')"
      :value="showCreateModal"
      width="55rem"
      @close="showCreateModal = false"
    >
      <access-group-form
        :entity="selectedAccessGroup"
        @cancel="showCreateModal = false"
        @created="showCreateModal = false"
        @updated="showCreateModal = false"
      />
    </m-drawer>
  </div>
</template>

<script>
import AccessGroupForm from '@/components/access-group/AccessGroupForm.vue';
import useAccessGroup from '@/composables/access/access-group';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useUsers from '@/composables/user/users';
import { arrayToMap } from 'shared/lib/array/array';
import { computed } from 'vue';
import { logCatch } from '@/lib/logger/logger';

export default {
  name: 'AccessGroupList',
  props: {
    accessGroups: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const { loggedInUserAccount } = useLoggedInUserAccount();
    const { deleteSingle, deleteLoading } = useAccessGroup();
    const { users } = useUsers();
    return {
      account: loggedInUserAccount,
      userCount: computed(() => users.value.length),
      deleteSingle,
      deleteLoading,
    };
  },
  emits: ['deleted'],
  components: { AccessGroupForm },
  data() {
    return {
      showCreateModal: false,
      selectedAccessGroup: { uid: 0 },
    };
  },
  computed: {
    columns() {
      return [
        {
          title: this.$t('accessGroupList.nameLabel'),
          dataIndex: 'name',
          key: 'name',
          scopedSlots: { customRender: 'name' },
        },
        {
          title: this.$t('accessGroupList.numberOfMembersLabel'),
          dataIndex: 'members',
          key: 'members',
          scopedSlots: { customRender: 'members' },
        },
      ];
    },
    data() {
      return this.accessGroups.map((group) => ({
        ...group,
        key: group.uid,
        name: group.isDefaultGroup ? this.$t('accessGroupForm.defaultGroupTitle') : group.name,
        members: this.amountOfMembers(group),
      })).sort((a, b) => {
        if (a.isDefaultGroup && !b.isDefaultGroup) {
          return -1;
        }
        if (!a.isDefaultGroup && b.isDefaultGroup) {
          return 1;
        }
        return 0;
      });
    },
  },
  methods: {
    amountOfMembers(accessGroup) {
      if (accessGroup.isDefaultGroup) {
        return this.userCount;
      }
      const users = accessGroup.accessPolicy.scopes.reduce((acc, next) => [...acc, ...next.scopeUsers, ...next.users], []);
      return Object.keys(arrayToMap(users)).length;
    },
    handleRowClick(record) {
      const onClickMethod = this.openEditor;
      return {
        on: {
          click: () => {
            onClickMethod(record.uid);
          },
        },
      };
    },
    deleteGroup(entity) {
      this.deleteSingle(entity.uid).then(() => {
        this.$showSnackbar({ color: 'success', message: this.$t('success.deleted') });
        this.$emit('deleted');
      }).catch(logCatch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      }));
    },
    confirmDelete(uid) {
      const group = this.accessGroups.filter((g) => g.uid === uid)[0];
      const deleteMethod = this.deleteGroup;
      this.$confirm({
        title: this.$t('accessGroupForm.deletePrompt'),
        okText: this.$t('general.yesDelete'),
        okType: 'danger',
        cancelText: this.$t('general.cancel'),
        maskClosable: true,
        okLoading: this.deleteLoading,
        onOk() {
          deleteMethod(group);
        },
      });
    },
    openEditor(uid) {
      this.selectedAccessGroup = this.accessGroups.filter((g) => g.uid === uid)[0];
      this.showCreateModal = true;
    },
  },
};
</script>

<style lang="scss" type="text/scss">
  .access-group-list {
    .m-table-row {
      ._actions {
        position: absolute;
        top: -.8rem;
        right: 0;
        display: none;

        ._action {
          margin: .5rem;
        }
      }

      &:hover {
        ._actions {
          display: flex;
        }
      }

      ._members {
        position: relative;
      }
    }
  }
</style>
