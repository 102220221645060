<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3 3.315C1.89543 3.315 1 4.21043 1 5.315V18.6834C1 19.788 1.89543 20.6834 3 20.6834H21C22.1046 20.6834 23 19.788 23 18.6834V5.315C23 4.21043 22.1046 3.315 21 3.315H3ZM4.31579 5.63079C3.7635 5.63079 3.31579 6.07851 3.31579 6.63079V17.3676C3.31579 17.9199 3.76351 18.3676 4.31579 18.3676H19.6842C20.2365 18.3676 20.6842 17.9199 20.6842 17.3676V6.63079C20.6842 6.07851 20.2365 5.63079 19.6842 5.63079H4.31579Z"
      fill="currentColor"
    />
    <path
      d="M4.47363 8.9466C4.47363 8.39431 4.92135 7.94659 5.47363 7.94659H18.5263C19.0785 7.94659 19.5263 8.39431 19.5263 8.94659V15.0519C19.5263 15.6041 19.0785 16.0519 18.5263 16.0519H5.47363C4.92135 16.0519 4.47363 15.6041 4.47363 15.0519V8.9466Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default { name: 'CentralPreview' };
</script>

<style scoped lang="scss" type="text/scss">

</style>
