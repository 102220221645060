<template>
  <div class="m-pagination">
    <div class="_left">
      <m-btn
        :hide-border="hideBorder"
        :small="small"
        :disabled="value === 1"
        icon="left"
        fab
        light
        icon-size="12"
        @click="change(value - 1)"
      />
    </div>
    <div
      v-for="p in pages"
      :key="p"
      class="_page"
    >
      <m-btn
        :hide-border="hideBorder"
        :small="small"
        :light="value !== p"
        fab
        outlined
        @click="change(p)"
      >
        <span :style="{ color: value === p ? $colors.blue.base : 'inherit' }">
          {{ p }}
        </span>
      </m-btn>
    </div>
    <div class="_right">
      <m-btn
        :hide-border="hideBorder"
        :small="small"
        :disabled="value === pages"
        icon="right"
        fab
        light
        icon-size="12"
        @click="change(value + 1)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'MPagination',
  props: {
    value: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      required: true,
    },
    simple: {
      type: Boolean,
      default: false,
    },
    hideBorder: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input', 'update:value', 'update'],
  computed: {
    pages() {
      return Math.ceil(this.total / this.itemsPerPage);
    },
    page: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
        this.$emit('update:value', value);
      },
    },
    name() {
      return this.data;
    },
  },
  methods: {
    change(val) {
      this.$emit('input', val);
      this.$emit('update:value', val);
      this.$emit('update', val);
    },
  },
};
</script>

<style lang="scss" type="text/scss">
  .m-pagination {
    display: flex;
    align-items: center;

    ._left {
      margin-right: .2rem;
    }

    ._page {
      margin: 0 .2rem;
    }

    ._right {
      margin-left: .2rem;
    }
  }
</style>
