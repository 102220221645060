<template>
  <svg
    viewBox="64 64 896 896"
    data-icon="stop"
    width="1em"
    height="1em"
    fill="currentColor"
    aria-hidden="true"
    focusable="false"
    class=""
  >
    <path
      d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372 0-89 31.3-170.8 83.5-234.8l523.3 523.3C682.8 852.7 601 884 512 884zm288.5-137.2L277.2 223.5C341.2 171.3 423 140 512 140c205.4 0 372 166.6 372 372 0 89-31.3 170.8-83.5 234.8z"
    />
  </svg>
</template>

<script>
/* eslint-disable vue/no-reserved-component-names */
export default { name: 'Stop' };
/* eslint-enable vue/no-reserved-component-names */
</script>

<style scoped lang="scss" type="text/scss">

</style>
