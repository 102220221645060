<template>
  <m-dialog
    v-model:value="showSlideShow"
    class="slide-show"
    :max-width="$modalSizes.lg"
    center
  >
    <single-slide
      :title="steps[currentStep].title"
      :media="steps[currentStep].media"
      :description="steps[currentStep].description"
      :link="steps[currentStep].link"
    />
    <template #footer>
      <div class="_bottom">
        <m-spacer />
        <div class="_indicator">
          <m-icon
            v-for="n in steps.length"
            :key="n"
            size="16"
            type="dot"
            :color="n === currentStep + 1 ? $colors.grey.darken2 : $colors.grey.lighten2"
          />
        </div>
        <div class="_buttons">
          <m-btn
            v-if="currentStep !== 0"
            @click="goBack"
          >
            {{ $t('general.back') }}
          </m-btn>
          <m-btn
            color="primary"
            @click="goForward"
          >
            <template v-if="currentStep === steps.length - 1">
              {{ $t('general.close') }}
            </template>
            <template v-else>
              {{ $t('onboarding.continue') }}
            </template>
          </m-btn>
        </div>
      </div>
    </template>
  </m-dialog>
</template>

<script>

import SingleSlide from '@/components/onboarding/SingleSlide.vue';
import { EventBus } from '@/lib/event-bus';

export default {
  name: 'SlideShow',
  components: { SingleSlide },
  data() {
    return { showSlideShow: false, currentStep: 0, steps: [] };
  },
  methods: {
    goBack() {
      if (this.currentStep === 0) {
        return;
      }
      this.currentStep -= 1;
    },
    goForward() {
      if (this.currentStep === this.steps.length - 1) {
        this.showSlideShow = false;
        this.currentStep = 0;
        return;
      }
      this.currentStep += 1;
    },
    show(steps = []) {
      this.currentStep = 0;
      if (steps.length === 0) {
        return;
      }
      this.steps = steps;
      this.showSlideShow = true;
    },
  },
  created() {
    EventBus.$on('show-slide-show', this.show);
  },
  beforeUnmount() {
    EventBus.$off('show-slide-show', this.show);
  },
};
</script>

<style scoped lang="scss" type="text/scss">
._bottom {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 1rem;

  ._indicator {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ._buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: .8rem;
  }
}
</style>
