import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useRepo from '@/nebula/repo';
import { computed } from 'vue';
import { saml as samlConfig } from 'shared/api/query/configs.json';

export default function useSaml() {
  const repo = useRepo(samlConfig.model);
  const { loggedInUser } = useLoggedInUser();
  const { loggedInUserAccount } = useLoggedInUserAccount();

  const saml = computed(() => repo.selectSingle(loggedInUserAccount.value.saml?.uid));

  const createSingle = (entity) => repo.createSingle({
    ...entity,
    creator: { uid: loggedInUser.value.uid },
    account: { uid: loggedInUserAccount.value.uid },
  });

  return { ...repo, createSingle, saml };
}
