<template>
  <page-layout
    :loading="loading"
    class="form-list"
  >
    <template #topBar>
      <page-top-bar
        :breadcrumbs="breadcrumbs"
        class="_header"
      />
    </template>
    <m-content
      padding-x="layout"
      class="_header"
    >
      <editable-page-header
        :title="$t('views.surveyList')"
        disabled
      />
    </m-content>
    <m-content
      class="_content"
      padding-x="layout"
    >
      <div class="_list">
        <card-button
          v-if="canCreateForm"
          :title="$t('surveyList.addSurvey')"
          icon="plus"
          class="_item"
          @click="goToNewForm"
        />
        <form-card
          v-for="form in forms"
          :key="form.uid"
          :form="form"
          :user="user"
          class="_item"
        />
      </div>
    </m-content>
  </page-layout>
</template>

<script>
import CardButton from '@/components/CardButton.vue';
import EditablePageHeader from '@/components/page/EditablePageHeader.vue';
import FormCard from '@/components/FormCard.vue';
import PageLayout from '@/components/page/PageLayout.vue';
import PageTopBar from '@/components/page/PageTopBar.vue';
import useAccess from '@/composables/access/access';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import { NEW_FORM } from '@/route-names';
import { accessGroupFlag } from 'shared/constants.json';
import { buildIcon } from 'shared/lib/icon';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'SurveyList',
  components: { PageLayout, EditablePageHeader, CardButton, FormCard, PageTopBar },
  setup() {
    const { userHasRight } = useAccess();
    const { loggedInUser } = useLoggedInUser();
    return {
      userHasRight,
      user: loggedInUser,
    };
  },
  data() {
    return {
      showModal: false,
      loading: true,
    };
  },
  computed: {
    ...mapState({ forms: (state) => state.forms }),
    title() {
      return this.breadcrumbs[this.breadcrumbs.length - 1].title;
    },
    breadcrumbs() {
      return [
        {
          title: this.$t('navigation.surveys'),
          icons: [{ value: buildIcon('Solution_dark_grey') }],
          disabled: true,
        },
      ];
    },
    canCreateForm() {
      return this.userHasRight([accessGroupFlag.formWriteAccess]);
    },
  },
  methods: {
    ...mapActions([
      'getForms',
    ]),
    goToNewForm() {
      this.$router.push({ name: NEW_FORM });
    },
    retrieveForms() {
      this.loading = true;
      this.getForms({ loggedInUser: this.user }).then((response) => {
        this.loading = false;
        if (response.status !== 200) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
        }
      });
    },
  },
  mounted() {
    this.retrieveForms();
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .form-list {
    ._list {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -1rem;

      ._item {
        flex: 0 1 17.5rem;
        height: 21rem;
        margin: 1rem;
      }
    }
  }
</style>
