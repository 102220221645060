import { asanaCompletionStatus } from 'shared/constants.json';

const anySectionP = (sections) => {
  if (sections.length > 1) {
    return false;
  }

  if (sections[0].gid !== 'global') {
    return false;
  }

  return sections[0].status === asanaCompletionStatus.any;
};

export const criteriaCount = (sections, assignedTo) => {
  if (anySectionP(sections)) {
    return assignedTo.length;
  }

  return sections.length + assignedTo.length;
};

export const anyP = (sections, assignedTo) => anySectionP(sections, assignedTo) && assignedTo.length === 0;

export const assignedToP = (sections, assignedTo) => anySectionP(sections) && assignedTo.length === 1;

export const singleSectionP = (sections, assignedTo) => {
  if (anySectionP(sections)) {
    return false;
  }

  if (sections.length !== 1) {
    return false;
  }

  if (assignedTo.length > 0) {
    return false;
  }

  return true;
};

export const criteriaP = (sections, assignedTo) => {
  if (!anySectionP(sections) && assignedTo.length > 0) {
    return true;
  }

  if (assignedTo.length === 0 && sections.length > 1) {
    return true;
  }

  if (assignedTo.length > 1) {
    return true;
  }

  return false;
};
