import Mark from '@/tiptap/tiptap/Utils/Mark';
import { toggleMark } from 'prosemirror-commands';

export default class Underline extends Mark {
  get name() {
    return 'underline';
  }

  get schema() {
    return {
      parseDOM: [
        { tag: 'u:not(a)' },
        {
          style: 'text-decoration',
          getAttrs: (value) => value === 'underline',
        },
      ],
      toDOM: () => ['u', 0],
    };
  }

  keys({ type }) {
    return { 'Mod-u': toggleMark(type) };
  }

  commands({ type }) {
    return () => toggleMark(type);
  }
}
