<template>
  <div
    v-show="showSuggestions && !disabled"
    class="_overlay"
    @click="reset"
    @mousemove="navigatingWith = 'mouse'"
  >
    <m-card
      ref="card"
      class="m-context-menu"
      :style="style"
      no-padding
      list
      placement="bottomLeft"
    >
      <m-card-item
        v-if="filteredUsers.length > 0"
        class="_sub-heading"
        :clickable="false"
        light
      >
        {{ $t('mMentionMenu.users') }}
        <m-spinner
          v-if="loading"
          size="xxs"
        />
      </m-card-item>
      <m-card-item
        v-for="(item, index) in filteredUsers"
        :key="item.key"
        :ref="`item_${index}`"
        :focused="itemIndex === index"
        class="_item"
        no-hover
        @mouseover="handleMouseover(index)"
        @click="handleClick(item, $event)"
      >
        <user-display
          :user="item"
          small
        />
      </m-card-item>
      <template v-if="account.accountSettings.usesGoals">
        <m-divider
          v-if="filteredGoals.length > 0 && filteredUsers.length > 0"
          xs
        />
        <m-card-item
          v-if="filteredGoals.length > 0"
          class="_sub-heading"
          :clickable="false"
          light
        >
          {{ $t('mMentionMenu.goalsTitle', { title: account.goalSettings.featureNamePlural }) }}
          <m-spinner
            v-if="loading && filteredUsers.length === 0"
            size="xxs"
          />
        </m-card-item>
        <m-card-item
          v-for="(item, index) in filteredGoals"
          :key="item.key"
          :ref="`item_${index + filteredUsers.length}`"
          :focused="itemIndex === index + filteredUsers.length"
          class="_item"
          no-hover
          :emoji="item.icon"
          @mouseover="handleMouseover(index + filteredUsers.length)"
          @click="handleClick(item, $event)"
        >
          {{ item.title === '' ? $t('general.untitled') : item.title }}
        </m-card-item>
      </template>
      <m-card-item
        v-if="filteredItems.length === 0"
        :clickable="false"
        no-hover
        class="_empty"
      >
        <m-spinner
          v-if="loading"
          size="xxs"
        />
        <template v-else>
          {{ noItemsText }}
        </template>
      </m-card-item>
    </m-card>
  </div>
</template>

<script>
import ComponentList from '@/components/editor/ComponentList';
import UserDisplay from 'shared/components/UserDisplay.vue';
import useDebounce from '@/composables/debounce';
import useGoals from '@/composables/goal/goals';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import usePageVisits from '@/composables/page-visits';
import useUsers from '@/composables/user/users';
import { ContextMenuExtension } from '@/components/editor/context-menu-extension';
import { dogma } from '@/api';
import { editorNodeType } from 'shared/constants.json';
import { findInArray } from 'shared/lib/array/array';
import { insertText } from '@/tiptap/commands/commands';
import { markRaw } from 'vue';
import { mentionQuery } from '@/api/query/mention';

export default {
  name: 'MMentionMenu',
  props: {
    editor: {
      type: Object,
      default: () => ({}),
    },
    allowedContent: {
      type: Array,
      default: () => [],
    },
    customItems: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const { debounce } = useDebounce();
    const { goals, selectSingle } = useGoals();
    const { users, getUsersByName } = useUsers();
    const { loggedInUserAccount } = useLoggedInUserAccount();
    const { getEnrichedPageVisits } = usePageVisits();
    return {
      debounce,
      goals,
      selectSingle,
      users,
      account: loggedInUserAccount,
      getEnrichedPageVisits,
      getUsersByName,
    };
  },
  components: { UserDisplay },
  data() {
    return {
      navigatingWith: 'mouse',
      loading: false,
      query: null,
      schema: null,
      view: null,
      suggestionRange: null,
      filteredItems: [],
      itemIndex: 0,
      disabled: false,
      command: () => {
      },
      x: 0,
      y: 0,
      bottom: 0,
      recentlyVisitedItems: [],
    };
  },
  computed: {
    noItemsText() {
      if (this.allowedContent.includes(editorNodeType.goalMention) && this.allowedContent.includes(editorNodeType.mention)) {
        return this.$t('mMentionMenu.noItems', { title: this.account.goalSettings.featureNamePlural });
      }
      if (this.allowedContent.includes(editorNodeType.goalMention)) {
        return this.$t('mMentionMenu.noGoals', { title: this.account.goalSettings.featureNamePlural });
      }
      return this.$t('mMentionMenu.noUsers');
    },
    filteredUsers() {
      return this.filteredItems.filter((i) => i.type === 'user');
    },
    filteredGoals() {
      return this.filteredItems.filter((i) => i.type === 'goal');
    },
    style() {
      if (this.bottom !== 0) {
        return {
          width: '32rem',
          position: 'fixed',
          left: `${this.x}px`,
          bottom: `${this.bottom}px`,
          zIndex: 1070,
          maxHeight: this.$store.state.breakpoint.smAndDown ? '100vh' : '41.4rem',
          overflow: 'auto',
        };
      }
      return {
        width: '32rem',
        position: 'fixed',
        left: `${this.x}px`,
        top: `${this.y + 20}px`,
        zIndex: 1070,
        maxHeight: this.$store.state.breakpoint.smAndDown ? '100vh' : '41.4rem',
        overflow: 'auto',
      };
    },
    initialGoals() {
      const recentlyVisitedGoals = this.recentlyVisitedItems
        .filter((i) => i.type === 'goal')
        .map((g) => {
          const storeGoal = this.selectSingle(g.uid);
          if (storeGoal !== null) {
            return storeGoal;
          }
          return g;
        });
      return [
        ...recentlyVisitedGoals,
        ...this.goals.filter((g) => !recentlyVisitedGoals.map((item) => item.uid).includes(g.uid)),
      ];
    },
    items() {
      const res = [];
      if (this.allowedContent.includes(editorNodeType.mention)) {
        res.push(
          ...this.users.map((u) => ({
            ...u,
            type: 'user',
            title: `${u.firstName} ${u.lastName}`,
          })).slice(0, 3),
        );
      }
      if (this.allowedContent.includes(editorNodeType.goalMention)) {
        res.push(
          ...this.initialGoals.map((g) => ({
            ...g,
            type: 'goal',
          })).slice(0, 3),
        );
      }
      return res;
    },
    showSuggestions() {
      return this.query !== null;
    },
  },
  methods: {
    doGetMentions({ query, accountId, includeGoals }) {
      if (includeGoals === false) {
        return new Promise((resolve) => {
          resolve({ query });
        });
      }
      return dogma.query(
        mentionQuery({ query, accountId, includeGoals }),
      ).then((response) => {
        if (response.status !== 200) {
          throw new Error('failed to query mentions');
        }
        return { response, query };
      });
    },
    buildRecentlyVisitedItems() {
      const targets = ['goal'];
      this.getEnrichedPageVisits().then((pageVisits) => {
        this.recentlyVisitedItems = pageVisits.reduce((res, next) => {
          const target = targets.find((t) => next.node && next.node.type === t);
          if (target === undefined) {
            return res;
          }
          if (findInArray({ haystack: res, needle: next.node.uid }) !== null) {
            return res;
          }

          res.push({
            title: next.node.title,
            icon: next.node.icon,
            uid: next.node.uid,
            type: next.node.type,
          });
          return res;
        }, []);
      });
    },
    handleMouseover(index) {
      if (this.navigatingWith !== 'mouse') {
        return;
      }
      this.itemIndex = index;
    },
    blur() {
      this.disabled = true;
      this.reset();
    },
    reset() {
      this.query = null;
      this.filteredItems = [];
      this.suggestionRange = null;
      this.itemIndex = 0;
    },
    newMenu() {
      return new ContextMenuExtension({
        matcher: {
          char: '@',
          allowSpaces: true,
          startOfLine: false,
        },
        appendText: ' ',
        suggestionClass: 'mention-menu',
        command: this.command,
        items: this.items,
        element: this.$el,
        onEnter: ({ items, query, range, virtualNode, view }) => {
          if (view.editable === false) {
            return;
          }
          this.disabled = false;
          this.schema = markRaw(view.state.schema);
          this.view = markRaw(view);
          this.query = query.trim();
          this.filteredItems = items;
          this.suggestionRange = range;
          this.renderPopup(virtualNode);
        },
        onChange: ({ items, query, range, virtualNode }) => {
          this.query = query.trim();
          this.filteredItems = items;
          this.suggestionRange = range;
          this.itemIndex = 0;
          this.renderPopup(virtualNode);
        },
        onExit: () => {
          this.reset();
        },
        onKeyDown: ({ event }) => {
          if (event.key === 'ArrowUp') {
            this.upHandler();
            return true;
          }
          if (event.key === 'ArrowDown') {
            this.downHandler();
            return true;
          }
          if (event.key === 'Enter') {
            this.enterHandler();
            return true;
          }
          return false;
        },
        onFilter: (items, query) => {
          this.query = query.trim();
          if (this.query === null || this.query === '') {
            this.adjustPosition();
            return this.items;
          }

          const retrieveData = () => {
            this.loading = true;
            this.doGetMentions({
              query: this.query,
              accountId: this.account.uid,
              includeGoals: this.allowedContent.includes(editorNodeType.goalMention),
            }).then(({ response, query }) => {
              if (this.query !== query) {
                return;
              }

              if (this.allowedContent.includes(editorNodeType.mention)) {
                this.pushFilteredItems(
                  this.getUsersByName(query).map((u) => ({
                    ...u,
                    title: `${u.firstName} ${u.lastName}`,
                    type: 'user',
                  })),
                );
              }
              if (this.allowedContent.includes(editorNodeType.goalMention)) {
                this.pushFilteredItems(
                  response.data.goals.map((g) => ({
                    ...g,
                    type: 'goal',
                  })),
                );
              }
              this.adjustPosition();
            }).catch(() => {
              this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
            }).finally(() => {
              this.loading = false;
            });
          };

          this.debounce(retrieveData, 300);

          this.adjustPosition();
          return this.items.filter((i) => i.title.includes(this.query.toLowerCase()));
        },
      });
    },
    upHandler() {
      if (this.itemIndex - 1 <= 0) {
        this.itemIndex = 0;
        return;
      }
      this.navigatingWith = 'keyboard';
      const oldVal = this.itemIndex;
      this.itemIndex -= 1;
      this.setScrollPosition(this.itemIndex, oldVal);
    },
    downHandler() {
      if (this.itemIndex + 1 >= this.filteredItems.length) {
        return;
      }
      this.navigatingWith = 'keyboard';
      const oldVal = this.itemIndex;
      this.itemIndex += 1;
      this.setScrollPosition(this.itemIndex, oldVal);
    },
    enterHandler() {
      const item = this.filteredItems[this.itemIndex];
      if (typeof item === 'undefined') {
        return;
      }

      this.addBlock(item, this.suggestionRange.from, this.suggestionRange.to);
      this.query = null;
    },
    handleClick(item, event) {
      event.stopPropagation();
      event.preventDefault();
      this.addBlock(item, this.suggestionRange.from, this.suggestionRange.to);
    },
    addBlock(item, from, to) {
      switch (item.type) {
        case 'user':
          this.addUser(item, from, to);
          break;
        case 'goal':
          this.addGoal(item, from, to);
          break;
        default:
          return;
      }

      this.editor.focus();
    },
    addUser(item, from, to) {
      const node = this.editor.schema.nodes.mention.create({ id: item.uid, label: item.title });
      const transaction = this.editor.view.state.tr.replaceRangeWith(from, to, node);
      this.editor.view.dispatch(transaction);
      insertText(' ')(this.view.state, this.view.dispatch);
    },
    addGoal(item, from, to) {
      const node = this.editor.schema.nodes.goalMention.create({ id: item.uid, icon: item.icon, title: item.title });
      const transaction = this.editor.view.state.tr.replaceRangeWith(from, to, node);
      this.editor.view.dispatch(transaction);
      insertText(' ')(this.editor.view.state, this.editor.view.dispatch);
    },
    renderPopup(node) {
      if (this.popup) {
        return;
      }

      const dimensions = node.getBoundingClientRect();
      this.x = dimensions.x;
      this.y = dimensions.y;
    },
    setScrollPosition(val, oldVal) {
      if (typeof this.$refs[`item_${val}`] === 'undefined' || typeof this.$refs[`item_${val}`][0] === 'undefined' || typeof this.$refs.card === 'undefined') {
        return;
      }

      const currentItem = this.$refs[`item_${val}`][0].$el;
      const card = this.$refs.card.$el;

      if (oldVal < val && currentItem.clientHeight + currentItem.offsetTop > card.clientHeight) {
        card.scrollTop = currentItem.clientHeight * (val + 1) - card.clientHeight + this.headingHeights(val) + 12;
        return;
      }
      if (currentItem.offsetTop < card.scrollTop) {
        card.scrollTop = currentItem.clientHeight * val;
      }
    },
    headingHeights(val) {
      const item = this.filteredItems[val];
      if (item.type === 'user') {
        return 31;
      }
      return 31 * 2;
    },
    adjustPosition() {
      this.$nextTick(() => {
        if (typeof this.$refs.card !== 'undefined' && this.y + this.$refs.card.$el.clientHeight > document.body.clientHeight) {
          this.bottom = document.body.clientHeight - this.y + 5;
        }
      });
    },
    pushFilteredItems(items) {
      items.forEach((item) => {
        const index = this.filteredItems.findIndex((i) => i.uid === item.uid);
        if (index === -1) {
          this.filteredItems.push(item);
          return;
        }

        this.filteredItems[index] = item;
      });
    },
  },
  watch: {
    showSuggestions(show) {
      if (show) {
        if (this.recentlyVisitedItems.length === 0) {
          this.buildRecentlyVisitedItems();
        }
        this.call += 1;
        this.adjustPosition();
        return;
      }

      this.bottom = 0;
    },
  },
  mixins: [ComponentList],
};
</script>

<style scoped lang="scss" type="text/scss">
  .m-context-menu {
    z-index: 1;

    ._sub-heading {
      height: 3.1rem;
      font-size: $font-size-2;
      color: $font-color-tertiary;
    }

    ._empty {
      color: $font-color-secondary;
    }
  }

  ._overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100vw;
    height: 100vh;
  }
</style>
