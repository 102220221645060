<template>
  <goal-insights-performance
    :view-application="viewApplication"
    :default-view="defaultView"
    show-views-selector
    show-create-view
  />
</template>

<script>
import GoalInsightsPerformance from '@/components/goal-insights/performance/GoalInsightsPerformance.vue';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import { AND } from 'shared/api/query/constants';
import { goalInsightsGroupBy, viewApplication, viewType } from 'shared/constants.json';

export default {
  name: 'GoalInsightsPerformanceTab',
  components: { GoalInsightsPerformance },
  data() {
    return { viewApplication: viewApplication.insight };
  },
  setup() {
    const { loggedInUserAccount } = useLoggedInUserAccount();
    return { account: loggedInUserAccount };
  },
  computed: {
    defaultView() {
      return {
        title: this.$t('list.dashboard'),
        viewType: viewType.default,
        viewApplication: this.viewApplication,
        params: {
          filter: { account: { uid: this.account.uid }, children: [], op: AND },
          order: [],
          props: [],
          goalInsightsGroupBy: { value: goalInsightsGroupBy.user, uid: [], id: goalInsightsGroupBy.user },
          wrapCells: true,
        },
      };
    },
  },
};
</script>

<style
  scoped
  lang="scss"
  type="text/scss"
>
</style>
