<template>
  <div
    class="property-option"
    :data-id="option.uid"
  >
    <div
      v-if="draggingOverTop"
      class="_drag-over-top"
    />
    <div
      class="_handle"
    >
      <m-icon
        type="drag"
        size="16"
        :color="$colors.grey.base"
      />
    </div>
    <div class="_color">
      <color-picker v-model:value="color" />
    </div>
    <div
      class="_form-item"
    >
      <m-form-item
        class="_option-item"
      >
        <m-text-field
          :value="label"
          :style="{ marginTop: 0 }"
          :has-error="label.includes(csvValueSeparator)"
          @input="inputLabel"
        />
        <div
          v-if="label.includes(csvValueSeparator)"
          class="_error-msg"
        >
          {{ $t('propertyOption.separatorNotAllowed', { separator: csvValueSeparator }) }}
        </div>
      </m-form-item>
      <m-btn
        v-if="deletable"
        color="danger"
        @click="$emit('delete-segment', option)"
      >
        {{ $t('general.delete') }}
      </m-btn>
    </div>
    <div
      v-if="draggingOverBottom"
      class="_drag-over-bottom"
    />
  </div>
</template>

<script>
import ColorPicker from '@/components/ColorPicker.vue';

export default {
  name: 'PropertyOption',
  props: {
    option: {
      type: Object,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    draggingOverTop: {
      type: Boolean,
      default: false,
    },
    draggingOverBottom: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update-label', 'delete-segment', 'update-color'],
  components: { ColorPicker },
  data() {
    return {
      color: null,
      // TODO: receive csvValueSeparator via configuration
      csvValueSeparator: ';',
    };
  },
  computed: {
    deletable() {
      return !(this.option.isKeyResult || this.option.isObjective);
    },
  },
  methods: {
    inputLabel(value) {
      this.$emit('update-label', value);
    },
  },
  watch: {
    color(val) {
      this.$emit('update-color', val);
    },
  },
  created() {
    this.color = this.option.color;
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .property-option {
    position: relative;
    display: flex;
    align-items: flex-start;
    padding-top: .3rem;
    padding-bottom: .3rem;

    ._handle {
      display: flex;
      flex: 0 0 .5rem;
      align-items: center;
      height: 3.2rem;
      margin-right: .4rem;
      cursor: grab;
      border-radius: $input-field-border-radius;

      &:hover {
        background-color: $hover-color;
      }
    }

    ._drag-over-top {
      position: absolute;
      top: -2px;
      right: 0;
      left: 0;
      z-index: 88;
      width: 100%;
      height: 4px;
      pointer-events: none;
      background: $highlighted-color-dark;
      opacity: 1;
    }

    ._drag-over-bottom {
      position: absolute;
      right: 0;
      bottom: -2px;
      left: 0;
      z-index: 88;
      width: 100%;
      height: 4px;
      pointer-events: none;
      background: $highlighted-color-dark;
      opacity: 1;
    }

    ._color {
      margin-right: .8rem;
    }

    ._form-item {
      display: flex;
      flex: 1 1 auto;
      align-items: flex-start;

      ._option-item {
        flex: 1 1 auto;
        margin-right: .8rem;
        margin-bottom: 0;

        ._error-msg {
          color: $error-color;
        }
      }
    }
  }
</style>
