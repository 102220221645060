<template>
  <div class="measurement-type-error">
    {{ $t('measurementTypeError.allowedTypes') }}
    <ul>
      <li
        v-for="(type, index) in allowedTypes"
        :key="index"
      >
        {{ type.text }}
      </li>
    </ul>
  </div>
</template>

<script>
import useGoalTypeProperty from '@/composables/customize-page/goal-type-property';
import useProgressMeasurement from '@/composables/goal/progress-measurement';

export default {
  name: 'MeasurementTypeError',
  props: {
    err: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { allowedProgressMeasurementOptions } = useProgressMeasurement();
    const { goalTypeOption } = useGoalTypeProperty();
    return {
      allowedProgressMeasurementOptions,
      goalTypeOption,
    };
  },
  computed: {
    allowedTypes() {
      return this.allowedProgressMeasurementOptions(this.goalTypeOption(this.err.payload.goal)).filter((t) => !t.disabled);
    },
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>

  .measurement-type-error {
    ul {
      margin-block-start: .8rem;
      padding-inline-start: 1.4rem;
      margin: .2rem 0;

      li {
        margin-bottom: .2rem;
      }
    }
  }
</style>
