<template>
  <m-content
    padding-x="layout"
    class="goal-insights-performance"
  >
    <div class="_header">
      <goals-list-header
        :slots="slots"
        :filter-props="defaultProps"
        :allowed-view-types="allowedViewTypes"
        :show-create-view="showCreateView"
        :show-views-selector="showViewsSelector"
        :change-selected-goal-cycles="changeSelectedGoalCycles"
        :selected-goal-cycles="selectedCycles"
        show-saved-view-info
        class="_list-header"
      />
      <m-divider none />
    </div>
    <dashboard-group
      class="_overall"
      :title="$t('goalInsightsPerformance.overview')"
    >
      <div class="_container">
        <single-metric-card
          :loading="progressChartLoading || compareToLoading"
          :title="$t('goalInsightsPerformance.overallProgress')"
          :metric="overallProgress"
          unit="%"
          :previous-metric="overallProgressPrevious"
          :expected-metric="overallProgressExpected"
        />
        <single-metric-card
          v-if="goalSettings.progressDashboardStatusChart === statusChartType.distribution"
          :loading="goalsAtRiskLoading || compareToLoading"
          :title="$t('goalInsightsPerformance.goalsAtRisk', { title: goalSettings.featureNamePlural } )"
          :metric="goalsAtRisk"
          :previous-metric="goalsAtRiskPrevious"
          :invert-negative-positive="true"
          clickable
          @click="handleMetricClick({ type: 'goalsAtRisk', title: $t('goalInsightsPerformance.goalsAtRisk', { title: goalSettings.featureNamePlural } ) })"
        />
        <single-metric-card
          v-if="goalSettings.progressDashboardStatusChart === statusChartType.average"
          :loading="confidenceChartLoading || compareToLoading"
          :title="$t('goalInsightsPerformance.averageScore')"
          :info="$t('goalInsightsPerformance.confidenceChartHint')"
          :metric="averageScore"
          :minimum-fraction-digits="1"
          :previous-metric="averageScorePrevious"
          :expected-metric-from="0"
          :expected-metric-to="10"
        />
        <goal-type-card
          class="_single-metric-card"
          :title="$t('goalInsightsPerformance.goalsByType', { title: goalSettings.featureNamePlural } )"
          :loading="goalTypeCountsLoading || goalTypeCountsDiffLoading"
          :goals-total-count="goalsTotalCount"
          :goals-total-count-diff="goalsTotalCountDiff"
          :goal-type-counts="goalTypeCounts"
          @click-goal-type="handleGoalTypeClick"
          @click-goal-total="handleMetricClick({ type: 'totalNumberOfGoals', title: $t('goalInsightsPerformance.totalNumberOfGoals', { title: goalSettings.featureNamePlural } ) })"
        />
      </div>
    </dashboard-group>
    <dashboard-group
      class="_progress-chart"
      :title="$t('goalInsightsPerformance.progress')"
    >
      <div class="_container">
        <dashboard-card
          class="_progress-chart-card"
          :title="$t('goalInsightsPerformance.progressChart')"
          :loading="progressChartLoading"
        >
          <goal-list-progress-chart
            :start-date="cycleStart.toJSDate()"
            :end-date="cycleEnd.toJSDate()"
            :chart-data="progressChartData"
          />
        </dashboard-card>
        <dashboard-card
          class="_progress-distribution-card"
          :title="$t('goalInsightsPerformance.progressDistribution')"
          :loading="progressDistributionLoading || compareToLoading"
        >
          <div class="_content">
            <distribution-chart
              :chart-data="progressDistribution"
              clickable
              @click="handleProgressDistributionClick({ numberRange: $event.numberRange, title: $t('goalInsightsPerformance.goalsByProgress', { title: goalSettings.featureNamePlural, label: $event.x } ) })"
            />
          </div>
        </dashboard-card>
      </div>
    </dashboard-group>
    <dashboard-group
      class="_confidence-chart"
      :title="textByLang(goalStatusProperty.label, userLang)"
    >
      <div class="_container">
        <dashboard-card
          v-if="goalSettings.progressDashboardStatusChart === statusChartType.average"
          class="_confidence-chart-card"
          :title="$t('goalInsightsPerformance.confidenceChart', { title: textByLang(goalStatusProperty.label, userLang) })"
          :info="$t('goalInsightsPerformance.confidenceChartHint')"
          :loading="confidenceChartLoading"
        >
          <goal-list-confidence-chart
            :start-date="cycleStart.toJSDate()"
            :end-date="cycleEnd.toJSDate()"
            :chart-data="hideChart ? [] : confidenceChartData"
          />
          <template
            v-if="canEditGoalSettings"
            #topRight
          >
            <status-chart-settings-dropdown />
          </template>
        </dashboard-card>
        <dashboard-card
          v-if="goalSettings.progressDashboardStatusChart === statusChartType.distribution"
          class="_confidence-chart-card"
          :title="$t('goalInsightsPerformance.statusChart', { title: textByLang(goalStatusProperty.label, userLang) })"
          :loading="confidenceChartLoading"
        >
          <goal-list-status-chart
            :start-date="cycleStart.toJSDate()"
            :end-date="cycleEnd.toJSDate()"
            :chart-data="hideChart ? [] : statusChartData"
          />
          <template
            v-if="canEditGoalSettings"
            #topRight
          >
            <status-chart-settings-dropdown />
          </template>
        </dashboard-card>
        <dashboard-card
          class="_confidence-distribution-card"
          :title="$t('goalInsightsPerformance.statusDistribution', { title: textByLang(goalStatusProperty.label, userLang) })"
          :loading="confidenceDistributionLoading || compareToLoading"
        >
          <div class="_content">
            <distribution-chart
              :chart-data="activeGoalsDistribution"
              :title="$t('goalInsightsPerformance.statusActive')"
              clickable
              @click="handleConfidenceDistributionClick({ states: $event.selectedOptions, title: $t('goalInsightsPerformance.goalsByStatus', { title: goalSettings.featureNamePlural, label: $event.x } ) })"
            >
              <template #label="{ item }">
                <status-item :selected-option="item.selectedOptions[0]" />
              </template>
            </distribution-chart>
            <distribution-chart
              :chart-data="closedGoalsDistribution"
              :title="$t('goalInsightsPerformance.statusClosed')"
              clickable
              @click="handleConfidenceDistributionClick({ states: $event.selectedOptions, title: $t('goalInsightsPerformance.goalsByStatus', { title: goalSettings.featureNamePlural, label: $event.x } ) })"
            >
              <template #label="{ item }">
                <status-item :selected-option="item.selectedOptions[0]" />
              </template>
            </distribution-chart>
          </div>
        </dashboard-card>
      </div>
    </dashboard-group>
    <dashboard-group
      v-if="groupByItems.length > 0"
      class="_breakdown"
      :title="$t('goalInsightsPerformance.breakdown')"
    >
      <div class="_container">
        <dashboard-card
          class="_card"
          :title="groupBy.text !== '' ? $t('goalInsightsPerformance.breakdownTable', { title: goalSettings.featureNamePlural, groupBy: groupBy.text }) : ''"
          :loading="breakdownLoading"
          :bottom-style="{ padding: 0 }"
        >
          <div class="_header">
            <m-tooltip
              v-if="groupByItems.length > 1"
              placement="top"
              :disabled="canEditView"
            >
              <m-select
                :items="groupByItems"
                :value="currentGroupBy"
                :label="$store.state.breakpoint.smAndDown ? '' : $t('goalInsights.groupBy')"
                hide-border
                btn
                light
                small
                value-key="id"
                return-object
                hide-arrow
                :disabled="breakdownLoading && !canEditView"
                @change="updateGroupBy"
              />
              <template #title>
                {{ $t('savedViewInfo.disabledInfo') }}
              </template>
            </m-tooltip>
            <list-string-filter
              small
              :disabled="breakdownLoading"
              @input="val => searchTerm = val"
            />
          </div>
          <goal-insights-performance-table
            class="_table"
            :loading="breakdownLoading"
            :insights-data="goalInsights"
            :group-by-property="groupByProperty"
            :current-filter="currentFilter"
            :group-by="groupBy.value"
            :search="searchTerm"
            :overall-progress-expected="overallProgressExpected"
            @show-goal-list="handleBreakdownClick"
          />
        </dashboard-card>
      </div>
    </dashboard-group>
    <m-dialog
      v-model:value="showGoalList"
      :max-width="maxDialogWidth"
      max-height="calc(100vh - 20rem)"
      hide-footer
      no-padding
      keep-height
      top="7rem"
      :title="goalListTitle"
    >
      <temp-goal-list
        :id="tempGoalListId"
        :filter="goalListFilter"
        inline-editable
        show-right-click-menu
        show-side-menu
      />
    </m-dialog>
  </m-content>
</template>

<script>
import DashboardCard from '@/components/dashboard/DashboardCard.vue';
import DashboardGroup from '@/components/dashboard/DashboardGroup.vue';
import DistributionChart from '@/components/dashboard/DistributionChart.vue';
import GoalInsightsPerformanceTable from '@/components/goal-insights/performance/GoalInsightsPerformanceTable.vue';
import GoalListConfidenceChart from '@/components/goal-insights/performance/GoalListConfidenceChart.vue';
import GoalListProgressChart from '@/components/goal-insights/performance/GoalListProgressChart.vue';
import GoalListStatusChart from '@/components/goal-insights/performance/GoalListStatusChart.vue';
import GoalTypeCard from '@/components/goal-insights/performance/GoalTypeCard.vue';
import GoalsListHeader from '@/components/goal/GoalsListHeader.vue';
import SingleMetricCard from '@/components/dashboard/SingleMetricCard.vue';
import TempGoalList from '@/components/TempGoalList.vue';
import { AND } from 'shared/api/query/filter';
import { DateTime } from 'luxon';
import { GoalFilterHandler } from '@/lib/filter/goal/handler';
import { RESULT } from 'shared/api/query/constants';
import { SLOTS, VIEWS_SERVICE } from '@/lib/constants';
import { UserFilterHandler } from '@/lib/filter/user/handler';
import {
  accessGroupFlag,
  goalInsightsGroupBy,
  goalProgressMeasurement,
  propertyType,
  statusChartType,
  viewType,
} from 'shared/constants.json';
import { createPropsList } from '@/lib/props';
import { directProperties } from '@/lib/goal/properties';
import { dogma } from '@/api';
import { extractPropertyOptionIds } from '@/lib/filter/property-option/filter-ids';
import { findInArray } from 'shared/lib/array/array';
import {
  goal as goalConfig,
  propertyOption as propertyOptionConfig,
  space as spaceConfig,
  update as updateConfig,
  user as userConfig,
} from 'shared/api/query/configs.json';
import {
  queryAdminGoalInsights,
  queryGoalsByProgress,
  queryGoalsByStatusOptions,
  queryGoalsTypeDiff,
  queryProgress,
  querySingleMetrics,
} from '@/api/query/goal-insights-performance';

import ListStringFilter from '@/components/list/ListStringFilter.vue';
import StatusChartSettingsDropdown from '@/components/goal-insights/performance/StatusChartSettingsDropdown.vue';
import StatusItem from '@/components/goal/StatusItem.vue';
import statusProperty from '@/composables/goal/status-property';
import useAccess from '@/composables/access/access';
import useAccountSettings from '@/composables/logged-in-user-account/account-settings';
import useDebounce from '@/composables/debounce';
import useGoalCycle from '@/composables/goal-cycle/goal-cycle';
import useGoalProperty from '@/composables/property/goal-property';
import useGoalSettings from '@/composables/logged-in-user-account/goal-settings';
import useGoalTypeCounts from '@/composables/goal-insights/goal-type-count';
import useGoalTypeProperty from '@/composables/customize-page/goal-type-property';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import usePersistedGoalCycle from '@/composables/goal-cycle/persisted-goal-cycle';
import usePersonalAppSettings from '@/composables/logged-in-user/personal-app-settings';
import useProgressDistribution from '@/composables/goal-insights/progress-distribution';
import usePublishedAtFilter from '@/composables/goal/published-at-filter';
import useRouteAwareViews from '@/composables/saved-views/route-aware-views';
import useStatusDistribution from '@/composables/goal-insights/status-distribution';
import useSubscription from '@/composables/subscription/subscription';
import { NOT } from '@/lib/filter/scope-tree';
import { applyBaseFilter } from '@/lib/filter/base-filter';
import { compareToDateTime } from '@/lib/compare-to';
import { computed, provide } from 'vue';
import { extractSpaceIds } from '@/lib/filter/space/filter-ids';
import { getPointInLineBounded } from '@/lib/charts/line-chart';
import { getRoundedValue } from '@/lib/charts/format';
import { getStatusChartColor } from '@/lib/goal/status';
import { guid } from 'shared/lib/uuid';
import { savedViewProps } from '@/lib/saved-view/props';
import { textByLang } from 'shared/lib/language';
import { transformToUserFilter } from '@/lib/filter/user/transform';
import { useI18n } from 'vue-i18n';
import { userEdgesSlim } from 'shared/api/query/edges';

export default {
  name: 'GoalInsightsPerformance',
  props: {
    ...savedViewProps,
    showViewsSelector: {
      type: Boolean,
      default: false,
    },
    showCreateView: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const { goalCycles } = useGoalCycle();
    const { goalSettings } = useGoalSettings();

    const { loggedInUser, userLang } = useLoggedInUser();
    const { personalAppSettings } = usePersonalAppSettings(loggedInUser);
    const { properties: goalProperties } = useGoalProperty();

    const { subscribe } = useSubscription();

    const { composedSelectedCycles, changeSelectedGoalCycles } = usePersistedGoalCycle();

    const defaultProps = computed(() => createPropsList({
      properties: goalProperties.value,
      directProperties: directProperties((key) => t(key), goalCycles.value),
      userLang: userLang.value,
    }));

    const routeAwareViewsSvc = useRouteAwareViews({
      defaultProps,
      defaultView: props.defaultView,
      application: props.viewApplication,
      space: props.space,
      readOnly: props.readOnly,
    });

    const { debounce } = useDebounce();

    const { publishedAtFilter } = usePublishedAtFilter();
    const { userHasRight } = useAccess();
    provide(VIEWS_SERVICE, routeAwareViewsSvc);

    const { goalTypeProperty } = useGoalTypeProperty();
    const { goalTypeCounts, updateGoalTypeCounts, updateGoalTypeCountsDiff } = useGoalTypeCounts(
      goalTypeProperty.value.options,
      { [userLang.value]: t('goalInsightsPerformance.noGoalType') },
    );

    const { statusProperty: goalStatusProperty } = useGoalProperty();

    const { options: statusOptions, activeStatusOption, closedStatusOption, offTrackStatusOptions } = statusProperty(goalStatusProperty, userLang.value);

    const { distribution: progressDistribution, updateDiff: updateProgressDistributionDiff } = useProgressDistribution();
    const { distribution: confidenceDistribution, updateDiff: updateConfidenceDistributionDiff } = useStatusDistribution(goalStatusProperty, statusOptions.value, userLang.value);

    const { accountSettings } = useAccountSettings();
    const { loggedInUserAccount } = useLoggedInUserAccount();

    return {
      account: loggedInUserAccount,
      goalProperties,

      publishedAtFilter,
      defaultProps,
      userLang,
      goalCycles,
      goalSettings,
      selectedCycles: composedSelectedCycles,
      changeSelectedGoalCycles,
      goalStatusProperty,
      goalTypeProperty,
      goalTypeCounts,
      updateGoalTypeCounts,
      updateGoalTypeCountsDiff,
      activeStatusOption,
      closedStatusOption,
      offTrackStatusOptions,
      statusOptions,

      progressDistribution,
      updateProgressDistributionDiff,
      confidenceDistribution,
      updateConfidenceDistributionDiff,

      debounce,

      subscribe,

      userHasRight,
      currentView: routeAwareViewsSvc.currentView,
      viewSetParams: routeAwareViewsSvc.setParams,
      initCurrentView: routeAwareViewsSvc.initCurrentView,

      accountSettings,

      personalAppSettings,
    };
  },
  components: {
    StatusChartSettingsDropdown,
    StatusItem,
    ListStringFilter,
    DashboardGroup,
    DashboardCard,
    DistributionChart,
    TempGoalList,
    GoalTypeCard,
    SingleMetricCard,
    GoalListConfidenceChart,
    GoalListProgressChart,
    GoalListStatusChart,
    GoalsListHeader,
    GoalInsightsPerformanceTable,
  },
  data() {
    return {
      statusChartType,
      groupBy: { value: '', text: '' },
      searchTerm: '',

      goalListFilter: null,
      showGoalList: false,
      goalListTitle: '',
      selectedStatusOptions: [],

      goalsTotalCount: 0,
      goalsTotalCountDiff: undefined,
      goalTypeCountsDiffLoading: true,
      goalTypeCountsLoading: true,
      goalsAtRiskLoading: true,
      goalsAtRisk: 0,
      progressChartLoading: true,
      confidenceChartLoading: true,
      progressCourseList: [],
      compareToLoading: true,
      progressCourseListCompareTo: [],
      breakdownLoading: true,
      goalInsights: [],
      progressDistributionLoading: true,
      confidenceDistributionLoading: true,
    };
  },
  computed: {
    tempGoalListId() {
      if (this.space !== null) {
        return `${this.space.uid} ${this.goalListTitle}`;
      }
      return this.goalListTitle;
    },
    canEditGoalSettings() {
      return this.userHasRight([accessGroupFlag.goalCycleWriteAccess]);
    },
    activeGoalsDistribution() {
      return this.confidenceDistribution.filter((c) => this.activeStatusOption.children.map((o) => o.uid).includes(c.uid));
    },
    closedGoalsDistribution() {
      return this.confidenceDistribution.filter((c) => this.closedStatusOption.children.map((o) => o.uid).includes(c.uid));
    },
    slots() {
      const slots = [
        { name: SLOTS.CYCLE_SELECTOR, inMenu: false },
        { name: SLOTS.FILTER, inMenu: false },
        { name: SLOTS.COMPARE_TO, inMenu: false },
      ];
      if (this.$store.state.breakpoint.smAndDown) {
        slots.forEach((s) => { s.inMenu = true; });
      }
      return slots;
    },
    allowedViewTypes() {
      return [viewType.default];
    },
    groupByItems() {
      const groupBy = [];
      if (this.accountSettings.canGroupByUser) {
        const groupByUser = {
          id: goalInsightsGroupBy.user,
          value: goalInsightsGroupBy.user,
          text: this.$t('goalInsights.user'),
          uid: [],
        };
        groupBy.push(groupByUser);
      }

      groupBy.push(...this.goalProperties.reduce((acc, prop) => {
        if (prop.type === propertyType.space) {
          const groupBySpace = {
            id: prop.uid,
            value: goalInsightsGroupBy.space,
            text: textByLang(prop.label, this.userLang),
            uid: [prop.uid],
          };
          acc.push(groupBySpace);
          return acc;
        }

        // const groupByProperty = {
        //   id: prop.uid,
        //   value: goalInsightsGroupBy.propertyOption,
        //   text: textByLang(prop.label, this.userLang),
        //   uid: [prop.uid],
        // };
        // acc.push(groupByProperty);
        return acc;
      }, []));

      return groupBy;
    },
    groupByProperty() {
      if (this.groupBy.value === goalInsightsGroupBy.user) {
        return this.goalProperties.find((p) => p.type === propertyType.space);
      }
      return this.goalProperties.find((p) => p.uid === this.groupBy.id);
    },
    canEditView() {
      const hasRight = this.userHasRight([accessGroupFlag.publicSavedViews]);
      return !this.currentView.isPublic || hasRight;
    },
    maxDialogWidth() {
      if (window.innerWidth > 1400) {
        return '1400px';
      }

      return 'calc(100vw - 15rem)';
    },
    currentFilter() {
      if (this.currentView.params.filter === null) {
        return [];
      }
      return [this.currentView.params.filter];
    },
    currentCompareTo() {
      if (this.currentView.params.compare === undefined || this.currentView.params.compare === null) {
        return null;
      }
      return this.currentView.params.compare;
    },
    currentGroupBy() {
      if (this.currentView.params.goalInsightsGroupBy === undefined) {
        return this.groupByItems[0];
      }

      const res = this.groupByItems.find((item) => item.value === this.currentView.params.goalInsightsGroupBy.value);
      if (res === undefined) {
        return this.groupByItems[0];
      }

      return res;
    },
    goalTypeOptions() {
      if (this.goalTypeProperty === undefined) {
        return [];
      }
      return this.goalTypeProperty.options;
    },
    gqlFilterObject() {
      const handler = new GoalFilterHandler();
      return handler.Translate(this.currentView.params.filter);
    },
    gqlFilter() {
      return this.gqlFilterObject.filterTree;
    },

    totalNumberOfGoalsFilter() {
      return {
        account: { uid: this.account.uid },
        op: AND,
        children: [
          ...this.currentFilter,
        ],
      };
    },
    goalsAtRiskFilter() {
      return this.goalsByStatusOptionsQuery(this.offTrackStatusOptions);
    },

    overallProgress() {
      if (this.progressChartData.length === 0) {
        return 0;
      }

      return this.progressChartData[this.progressChartData.length - 1].y;
    },
    overallProgressPrevious() {
      const compareTo = compareToDateTime(this.currentCompareTo);
      if (compareTo === null) {
        return undefined;
      }
      if (this.progressCourseListCompareTo.length === 0) {
        return null;
      }

      return this.averageProgress(this.progressCourseListCompareTo[0].list);
    },
    progressBaseLine() {
      return [
        { x: Number(this.cycleStart.toJSDate()), y: 0 },
        { x: Number(this.cycleEnd.toJSDate()), y: this.goalSettings.yellowThreshold },
      ];
    },
    overallProgressExpected() {
      if (this.progressCourseList.length === 0) {
        return this.progressBaseLine[0].y;
      }

      const timestamp = Date.parse(this.progressCourseList[this.progressCourseList.length - 1].date);
      return Number.parseInt(getRoundedValue(getPointInLineBounded(this.progressBaseLine, timestamp).y, 0), 10);
    },

    averageScore() {
      if (this.confidenceChartData.length === 0) {
        return 0;
      }

      return this.confidenceChartData[this.confidenceChartData.length - 1].y;
    },
    averageScorePrevious() {
      const compareTo = compareToDateTime(this.currentCompareTo);
      if (compareTo === null) {
        return undefined;
      }
      if (this.progressCourseListCompareTo.length === 0) {
        return null;
      }

      return this.calculateAverageScore(this.progressCourseListCompareTo[0].list);
    },
    goalsAtRiskPrevious() {
      const compareTo = compareToDateTime(this.currentCompareTo);
      if (compareTo === null) {
        return undefined;
      }
      if (this.progressCourseListCompareTo.length === 0) {
        return null;
      }

      return this.countOfftrack(this.progressCourseListCompareTo[0].list);
    },
    spaceQuery() {
      if (this.currentGroupBy.value !== goalInsightsGroupBy.space) {
        return [];
      }

      const spaceIds = extractSpaceIds({
        filter: this.currentView.params.filter,
        properties: this.goalProperties.filter((p) => this.currentGroupBy.uid.includes(p.uid)),
      });

      if (spaceIds.length === 0) {
        return [
          {
            alias: 'spaces',
            func: { name: 'type', args: [{ value: spaceConfig.model }] },
            model: spaceConfig.model,
            children: [{ attr: 'uid' }],
          },
        ];
      }
      return [
        {
          alias: 'spaces',
          func: { name: 'uid' },
          model: spaceConfig.model,
          uid: spaceIds,
          children: [{ attr: 'uid' }],
        },
      ];
    },
    propertyOptionsQuery() {
      if (this.currentGroupBy.value !== goalInsightsGroupBy.propertyOption) {
        return [];
      }

      const propertyOptionIds = extractPropertyOptionIds({
        filter: this.currentView.params.filter,
        properties: this.goalProperties.filter((p) => this.currentGroupBy.uid.includes(p.uid)),
      });

      if (propertyOptionIds.length === 0) {
        return [
          {
            alias: 'propertyOptions',
            func: { name: 'type', args: [{ value: propertyOptionConfig.model }] },
            model: propertyOptionConfig.model,
            children: [{ attr: 'uid' }],
          },
        ];
      }
      return [
        {
          alias: 'propertyOptions',
          func: { name: 'uid' },
          model: propertyOptionConfig.model,
          uid: propertyOptionIds,
          children: [{ attr: 'uid' }],
        },
      ];
    },
    userQuery() {
      if (this.currentGroupBy.value !== goalInsightsGroupBy.user) {
        return [];
      }

      const filter = transformToUserFilter({ filter: this.currentView.params.filter });
      const handler = new UserFilterHandler(true, (index) => `uservar___${index}`);
      const filterObject = handler.Translate(filter);

      return [
        ...filterObject.varBlocks,
        {
          alias: 'users',
          func: { name: 'type', args: [{ value: userConfig.model }] },
          model: userConfig.model,
          filter: filterObject.filterTree,
          children: userEdgesSlim,
        },
      ];
    },
    hideChart() {
      return this.personalAppSettings.selectedGoalCycles.length === 0 && this.personalAppSettings.unassignedGoalCycleSelected;
    },
    progressChartData() {
      return this.progressCourseList.map((item) => ({
        x: Date.parse(item.date),
        y: this.averageProgress(item.list),
      }));
    },
    statusChartData() {
      if (this.goalSettings.progressDashboardStatusChart !== statusChartType.distribution) {
        return [];
      }

      return this.progressCourseList.reduce(
        (res, item) => {
          this.statusOptions.forEach((option, index) => {
            let value = 0;
            if (item.list.length > 0) {
              item.list.forEach((e) => {
                if (e.statusOption.uid === option.uid) {
                  value += 1;
                }
              });
            }
            res[index].data.push({
              x: Date.parse(item.date),
              y: value,
            });
          });

          return res;
        },
        this.statusOptions.reduce((r, n) => {
          r.push({ color: getStatusChartColor(n), name: textByLang(n.label, this.userLang), data: [] });
          return r;
        }, []),
      );
    },
    confidenceChartData() {
      if (this.goalSettings.progressDashboardStatusChart !== statusChartType.average) {
        return [];
      }

      return this.progressCourseList.map((item) => ({
        x: Date.parse(item.date),
        y: this.calculateAverageScore(item.list),
      }));
    },
    cycleStart() {
      let cycles = this.selectedCycles;
      if (cycles.length === 0) {
        cycles = this.goalCycles;
      }

      return cycles.reduce((res, cycle) => {
        const c = findInArray({ haystack: this.goalCycles, needle: cycle.uid });
        if (c === null) {
          return res;
        }

        const start = DateTime.fromISO(c.start);
        if (res.diff(start) > 0) {
          return start;
        }

        return res;
      }, DateTime.local());
    },
    cycleEnd() {
      let cycles = this.selectedCycles;
      if (cycles.length === 0) {
        cycles = this.goalCycles;
      }
      return cycles.reduce((res, cycle) => {
        const c = findInArray({ haystack: this.goalCycles, needle: cycle.uid });
        if (c === null) {
          return res;
        }

        const end = DateTime.fromISO(c.end);
        if (res.diff(end) < 0) {
          return end;
        }

        return res;
      }, DateTime.local(2000));
    },
    timeSeries() {
      const now = DateTime.local();
      const interval = { weeks: 0, months: 0 };
      const cycleMonthsDiff = this.cycleEnd.diff(this.cycleStart, 'months').toObject().months;
      switch (true) {
        case cycleMonthsDiff > 12:
          interval.months = 1;
          break;
        case cycleMonthsDiff > 6:
          interval.weeks = 2;
          break;
        default:
          interval.weeks = 1;
      }
      const timeSeries = [];
      let t = this.cycleStart;
      while (t.diff(this.cycleEnd) < 0 && t.diff(now) < 0) {
        timeSeries.push(t);
        t = t.plus(interval);
      }

      if (now.diff(this.cycleEnd) < 0) {
        timeSeries.push(now);
      }

      if (now.diff(this.cycleEnd) > 0) {
        timeSeries.push(this.cycleEnd);
      }

      return timeSeries;
    },
    timeSeriesCompareTo() {
      const now = DateTime.local().set({ milliseconds: 0 });
      const timeSeriesToCompare = [];
      const compareTo = compareToDateTime(this.currentCompareTo, now);
      if (compareTo !== null && compareTo.diff(this.cycleStart) > 0 && compareTo.diff(now) < 0) {
        timeSeriesToCompare.push(compareTo);
      }
      return timeSeriesToCompare;
    },
  },
  methods: {
    textByLang,
    updateGroupBy(value) {
      this.viewSetParams(
        this.currentView,
        {
          ...this.currentView.params,
          goalInsightsGroupBy: value,
        },
      );
    },
    averageProgress(list) {
      let value = 0.0;
      if (list.length > 0) {
        value = list.reduce((acc, e) => acc + e.progress, 0.0) / list.length;
      }
      return Math.round(value);
    },
    calculateAverageScore(list) {
      if (list.length === 0) {
        return 0;
      }
      const avg = list.reduce((acc, e) => acc + e.statusOption.score, 0.0) / list.length;
      return Math.round(avg * 10) / 10;
    },
    countOfftrack(list) {
      return list.filter((e) => this.offTrackStatusOptions.map((o) => o.uid).includes(e.confidence)).length;
    },
    goalsByStatusOptionsQuery(selectedOptions) {
      return {
        account: { uid: this.account.uid },
        op: AND,
        children: [
          ...this.currentFilter,
          {
            key: guid(),
            op: AND,
            children: [],
            scope: {
              property: this.goalStatusProperty,
              selectedOptions,
            },
          },
        ],
      };
    },
    goalsByTypeFilter(option) {
      return {
        account: { uid: this.account.uid },
        op: AND,
        children: [
          ...this.currentFilter,
          {
            key: guid(),
            op: AND,
            children: [],
            scope: {
              property: this.goalTypeProperty,
              isEmpty: option.uid === 'none',
              selectedOptions: option.uid === 'none' ? [] : [option],
            },
          },
        ],
      };
    },
    goalsByProgressFilter(numberRange) {
      return {
        account: { uid: this.account.uid },
        op: AND,
        children: [
          ...this.currentFilter,
          {
            key: guid(),
            op: AND,
            children: [],
            scope: {
              directProperty: {
                edgeName: goalConfig.edges.cachedCalculatedCurrent,
                type: propertyType.number,
              },
              isEmpty: false,
              numberRange,
            },
          },
          {
            key: guid(),
            op: NOT,
            children: [{
              key: guid(),
              op: AND,
              children: [],
              scope: {
                directProperty: {
                  edgeName: goalConfig.edges.progressMeasurement,
                  type: propertyType.options,
                },
                isEmpty: false,
                selectedOptions: [goalProgressMeasurement.none].map((m) => ({ value: m })),
              },
            }],
          },
        ],
      };
    },
    handleMetricClick({ type, title }) {
      this.showGoalList = true;
      this.goalListTitle = title;
      switch (type) {
        case 'goalsAtRisk':
          this.goalListFilter = this.goalsAtRiskFilter;
          return;
        case 'totalNumberOfGoals':
          this.goalListFilter = this.totalNumberOfGoalsFilter;
          return;
        default:
          this.goalListFilter = null;
      }
    },
    handleGoalTypeClick(option) {
      this.showGoalList = true;
      this.goalListTitle = textByLang(option.label, this.userLang);
      this.goalListFilter = this.goalsByTypeFilter(option);
    },
    handleBreakdownClick({ filter, title }) {
      this.showGoalList = true;
      this.goalListTitle = title;
      this.goalListFilter = filter;
    },
    handleProgressDistributionClick({ numberRange, title }) {
      this.showGoalList = true;
      this.goalListTitle = title;
      this.goalListFilter = this.goalsByProgressFilter(numberRange);
    },
    handleConfidenceDistributionClick({ states, title }) {
      this.showGoalList = true;
      this.goalListTitle = title;
      this.goalListFilter = this.goalsByStatusOptionsQuery(states);
    },
    retrieveInsightsPerformance() {
      this.goalTypeCountsLoading = true;
      this.goalsAtRiskLoading = true;
      this.goalTypeCountsDiffLoading = true;
      dogma.query(applyBaseFilter({
        queries: [
          ...querySingleMetrics({
            goalCycles: this.selectedCycles,
            filter: this.gqlFilter,
            varBlocks: this.gqlFilterObject.varBlocks,
            goalTypeOptions: this.goalTypeOptions,
            atRiskStatusOptions: this.offTrackStatusOptions.map((o) => o.uid),
            compareTo: this.timeSeriesCompareTo,
          }),
        ],
        operator: AND,
        baseFilter: this.publishedAtFilter,
      })).then((response) => {
        this.goalTypeCountsLoading = false;
        this.goalsAtRiskLoading = false;
        this.goalTypeCountsDiffLoading = false;
        if (response.status !== 200) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
          return;
        }

        this.goalsTotalCount = response.data.goalsTotalCount[0].count;
        this.updateGoalTypeCounts('goalsByTypeCount', response.data);
        if (this.timeSeriesCompareTo.length > 0) {
          this.goalsTotalCountDiff = response.data.goalsTotalCountDiff[0].count;
          this.updateGoalTypeCountsDiff('goalsByTypeCountDiff', response.data);
        }

        this.goalsAtRisk = response.data.goalsAtRisk[0].count;
      });

      this.progressChartLoading = true;
      this.confidenceChartLoading = true;
      this.compareToLoading = true;
      const queries = [
        ...queryProgress({
          goalCycles: this.selectedCycles,
          filter: this.gqlFilter,
          varBlocks: this.gqlFilterObject.varBlocks,
          timeSeries: this.timeSeries,
          compareTo: this.timeSeriesCompareTo,
        }),
      ];
      dogma.query(applyBaseFilter({
        queries,
        operator: AND,
        baseFilter: this.publishedAtFilter,
      })).then((response) => {
        this.progressChartLoading = false;
        this.confidenceChartLoading = false;
        this.compareToLoading = false;
        if (response.status !== 200) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
          return;
        }

        const findData = (data) => {
          const res = { progress: [], compareTo: [] };

          for (let i = 0; i < data.length; i++) {
            if (data[i].progressCourseList !== undefined && data[i].progressCourseList !== null) {
              res.progress = data[i].progressCourseList;
            }
            if (data[i].compareTo !== undefined && data[i].compareTo !== null) {
              res.compareTo = data[i].compareTo;
            }
          }

          return res;
        };

        const { progress, compareTo } = findData(response.data[RESULT]);
        this.progressCourseList = progress;
        this.progressCourseListCompareTo = compareTo;
        this.updateProgressDistributionDiff(compareTo && compareTo.length > 0 ? compareTo[0].list.map((e) => e.progress) : []);
        this.updateConfidenceDistributionDiff(compareTo && compareTo.length > 0 ? compareTo[0].list.map((e) => e.statusOption.uid) : []);
      });

      this.progressDistributionLoading = true;
      dogma.query(applyBaseFilter({
        queries: [
          ...queryGoalsByProgress({
            goalCycles: this.selectedCycles,
            filter: this.gqlFilter,
            varBlocks: this.gqlFilterObject.varBlocks,
            numberRanges: this.progressDistribution.map((e) => e.numberRange),
          }),
        ],
        operator: AND,
        baseFilter: this.publishedAtFilter,
      })).then(async (response) => {
        this.progressDistributionLoading = false;
        if (response.status !== 200) {
          return;
        }

        this.progressDistribution.forEach((e) => {
          const key = `progress_min${e.numberRange.min}_max${e.numberRange.max}`;
          e.y = response.data[key][0].count;
        });
      });

      dogma.query(applyBaseFilter({
        queries: [
          ...queryGoalsByStatusOptions({
            goalCycles: this.selectedCycles,
            filter: this.gqlFilter,
            varBlocks: this.gqlFilterObject.varBlocks,
            statesGroups: this.confidenceDistribution.map((e) => e.selectedOptions.map((o) => o.uid)),
          }),
        ],
        operator: AND,
        baseFilter: this.publishedAtFilter,
      })).then(async (response) => {
        this.confidenceDistributionLoading = false;
        if (response.status !== 200) {
          return;
        }

        this.confidenceDistribution.forEach((e) => {
          const key = e.selectedOptions.reduce((res, s) => `${res}_${s.uid}`, 'states');
          e.y = response.data[key][0].count;
        });
      });
    },
    retrieveInsightsPerformanceCompare() {
      if (this.timeSeriesCompareTo.length === 0) {
        this.updateGoalTypeCountsDiff('', {});
        this.goalsTotalCountDiff = undefined;
        this.progressCourseListCompareTo = [];
        this.updateProgressDistributionDiff(this.progressCourseListCompareTo);
        this.updateConfidenceDistributionDiff(this.progressCourseListCompareTo);
        this.goalInsights.forEach((e) => delete e.compareToProgress);
        return;
      }

      this.goalTypeCountsDiffLoading = true;
      dogma.query(applyBaseFilter({
        queries: [
          ...queryGoalsTypeDiff({
            goalCycles: this.selectedCycles,
            filter: this.gqlFilter,
            goalTypeOptions: this.goalTypeOptions,
            varBlocks: this.gqlFilterObject.varBlocks,
            compareTo: this.timeSeriesCompareTo,
          }),
        ],
        operator: AND,
        baseFilter: this.publishedAtFilter,
      })).then(async (response) => {
        this.goalTypeCountsDiffLoading = false;
        if (response.status !== 200) {
          return;
        }
        this.updateGoalTypeCountsDiff('goalsByTypeCountDiff', response.data);
        this.goalsTotalCountDiff = response.data.goalsTotalCountDiff[0].count;
      });

      this.compareToLoading = true;
      dogma.query(applyBaseFilter({
        queries: [
          ...queryProgress({
            goalCycles: this.selectedCycles,
            filter: this.gqlFilter,
            varBlocks: this.gqlFilterObject.varBlocks,
            compareTo: this.timeSeriesCompareTo,
          }),
        ],
        operator: AND,
        baseFilter: this.publishedAtFilter,
      })).then((response) => {
        this.compareToLoading = false;
        if (response.status !== 200) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
          return;
        }

        const findData = (data) => {
          const res = { progress: [], compareTo: [] };

          for (let i = 0; i < data.length; i++) {
            if (data[i].progressCourseList !== undefined && data[i].progressCourseList !== null) {
              res.progress = data[i].progressCourseList;
            }
            if (data[i].compareTo !== undefined && data[i].compareTo !== null) {
              res.compareTo = data[i].compareTo;
            }
          }

          return res;
        };

        const { compareTo } = findData(response.data[RESULT]);
        this.progressCourseListCompareTo = compareTo;
        this.updateProgressDistributionDiff(compareTo && compareTo.length > 0 ? compareTo[0].list.map((e) => e.progress) : []);
        this.updateConfidenceDistributionDiff(compareTo && compareTo.length > 0 ? compareTo[0].list.map((e) => e.statusOption.uid) : []);
      });

      this.debounce(this.retrieveInsightsPerformanceGroupBy, 100, 'retrieveInsightsPerformanceGroupBy');
    },
    retrieveInsightsPerformanceGroupBy() {
      this.breakdownLoading = true;
      dogma.query(applyBaseFilter({
        queries: [
          ...this.userQuery,
          ...this.spaceQuery,
          ...this.propertyOptionsQuery,
          ...queryAdminGoalInsights({
            goalCycles: this.selectedCycles,
            filter: this.gqlFilter,
            varBlocks: this.gqlFilterObject.varBlocks,
            groupBy: this.currentGroupBy,
            compareTo: this.timeSeriesCompareTo,
          }),
        ],
        operator: AND,
        baseFilter: this.publishedAtFilter,
      })).then((response) => {
        this.breakdownLoading = false;
        this.groupBy = this.currentGroupBy;
        if (response.status !== 200) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
          return;
        }

        let goalInsights = [];
        if (response.data[RESULT].length > 0) {
          goalInsights = response.data[RESULT][0].goalInsights;
        }

        if (this.currentGroupBy.value === goalInsightsGroupBy.user) {
          const userIds = response.data.users.map((u) => u.uid);

          this.goalInsights = goalInsights.filter((i) => userIds.includes(i.user.uid));

          this.goalInsights = this.goalInsights.map((i) => {
            i.user = response.data.users.find((u) => u.uid === i.user.uid);
            return i;
          });
        }
        if (this.currentGroupBy.value === goalInsightsGroupBy.propertyOption) {
          const propertyOptionIds = response.data.propertyOptions.map((u) => u.uid);
          this.goalInsights = goalInsights.filter((i) => propertyOptionIds.includes(i.propertyOption.uid));
        }
        if (this.currentGroupBy.value === goalInsightsGroupBy.space) {
          const spaceIds = response.data.spaces.map((u) => u.uid);
          this.goalInsights = goalInsights.filter((i) => spaceIds.includes(i.propertyOption.uid));
        }
      });
    },
    apply(wait) {
      this.debounce(this.retrieveInsightsPerformance, wait, 'retrieveInsightsPerformance');
      this.debounce(this.retrieveInsightsPerformanceGroupBy, 100, 'retrieveInsightsPerformanceGroupBy');
    },
  },
  watch: {
    selectedCycles(val, oldVal) {
      if (JSON.stringify(val) === JSON.stringify(oldVal)) {
        return;
      }
      this.apply(200);
    },
    gqlFilterObject: {
      handler(val, oldVal) {
        if (JSON.stringify(val) === JSON.stringify(oldVal)) {
          return;
        }
        this.apply(200);
      },
      deep: true,
    },
    currentCompareTo(val, oldVal) {
      if (oldVal === null || JSON.stringify(val) === JSON.stringify(oldVal)) {
        return;
      }
      this.debounce(this.retrieveInsightsPerformanceCompare, 100, 'retrieveInsightsPerformanceCompare');
    },
    currentGroupBy(val, oldVal) {
      if (JSON.stringify(val) === JSON.stringify(oldVal)) {
        return;
      }
      this.debounce(this.retrieveInsightsPerformanceGroupBy, 100, 'retrieveInsightsPerformanceGroupBy');
    },
  },
  created() {
    const fn = this.apply;
    const syncFn = ({ isSelfEvent }) => {
      const delay = isSelfEvent ? 1000 : 30000;
      fn(delay);
    };
    this.subscribe({
      queryFn: Promise.resolve(),
      syncFn,
      model: goalConfig.model,
      includeSelfEvents: true,
    });
    this.subscribe({
      queryFn: Promise.resolve(),
      syncFn,
      model: updateConfig.model,
      includeSelfEvents: true,
    });

    this.initCurrentView();
    this.apply(0);
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  @import 'shared/assets/scss/padding';

  .goal-insights-performance {
    ._header {
      position: sticky;
      left: 0;
      top: 0;
      background-color: white;
      z-index: 2;

      ._list-header {
        flex: 1 1 auto;
        padding-bottom: .6rem;
      }
    }

    ._overall {
      padding-top: 2rem;

      ._container {
        display: grid;
        grid-template-columns: repeat(3, minmax(15rem, 1fr));
        grid-gap: 2.4rem;

        ._single-metric-card {
          height: 19.4rem;
          overflow: auto;
        }

        ._card {
          height: 19.4rem;
          overflow: auto;

          @media (max-width: $screen-size-xl) {
            height: 16.4rem;
          }
        }

        @media (max-width: $screen-size-lg) {
          grid-template-columns: repeat(2, minmax(23.6rem, 1fr));
        }

        @media (max-width: $screen-size-sm) {
          grid-template-columns: repeat(auto-fit, minmax(23.6rem, 1fr));
        }
      }
    }

    ._progress-chart {
      ._container {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(24rem, 1fr));
        grid-gap: 2.4rem;
      }

      ._progress-chart-card {
        grid-column: span 2;
      }

      ._progress-distribution-card {
        @media (max-width: $screen-size-lg) {
          grid-column: span 2;
        }

        ._content {
          margin-left: -.6rem;
        }
      }

      ._no-chart {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 25rem;
        color: $font-color-secondary;
      }
    }

    ._confidence-chart {
      ._container {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(24rem, 1fr));
        grid-gap: 2.4rem;
      }

      ._confidence-chart-card {
        grid-column: span 2;
      }

      ._confidence-distribution-card {
        @media (max-width: $screen-size-lg) {
          grid-column: span 2;
        }

        max-height: 34rem;

        ._content {
          margin-left: -.6rem;
        }
      }
    }

    ._breakdown {
      ._container {
        ._header {
          position: sticky;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding: 0 2.4rem;
          margin-bottom: .4rem;
        }

        ._table {
          padding-bottom: 2.4rem;
        }
      }
    }
  }
</style>
