<template>
  <div class="spreadsheet-form">
    <m-content
      v-if="!documentsLoading && hasError"
      :padding-top="0"
      :padding-x="11"
      :padding-bottom="2"
    >
      <m-alert
        class="_error-message"
        type="error"
        :message="$t(`spreadsheetForm.error.${camelCase(dataSource.type)}`)"
      />
    </m-content>
    <m-content
      v-else
      :padding-top="0"
      :padding-x="11"
      :padding-bottom="2"
    >
      <div class="_item">
        <div class="_label">
          {{ $t('spreadsheetForm.selectDocumentLabel') }}
        </div>
        <template v-if="dataSource.type === dataSourceType.excel">
          <m-dialog
            v-model:value="showFileExplorer"
            :hide-footer="true"
            no-padding
          >
            <file-explorer
              :files="dataSource[customDatasourceProperty.spreadsheetDocuments]"
              :loading="navigateSpreadsheetFolderLoading"
              :path="fileExplorerPath"
              @stepinto="navigateFolder"
              @selected="selectDocument"
              @close="showFileExplorer = false"
            />
          </m-dialog>
          <div v-if="localSpreadsheetCell.documentId === null">
            <m-btn
              block
              :style="{ justifyContent: 'flex-start' }"
              @click="openFileExplorer"
            >
              {{ $t('spreadsheetForm.selectFile') }}
            </m-btn>
          </div>
          <m-card-item
            v-else
            icon="excel"
            class="_selected-item"
            :padding-x="0"
            @click="openFileExplorer"
          >
            <div class="_document-select">
              <div class="_document-name">
                {{ selectedDocumentTitle }}
                <m-btn
                  hide-border
                  xs
                  fab
                  class="_link"
                  light
                  :href="selectedDocumentLink"
                  target="_blank"
                >
                  <m-icon type="external-link" />
                </m-btn>
              </div>
            </div>
          </m-card-item>
        </template>
        <google-file-picker
          v-if="dataSource.type === dataSourceType.sheets"
          :spreadsheet-cell="localSpreadsheetCell"
          @select="selectGoogleSheetsDocument"
          @gapi-error="gapiError = true"
        />
      </div>
      <div
        v-if="localSpreadsheetCell.documentId !== null && dataSource[customDatasourceProperty.spreadsheetDocuments].length > 0"
        class="_item"
      >
        <div class="_label">
          {{ $t('spreadsheetForm.selectSheetLabel') }}
        </div>
        <m-select
          :value="localSpreadsheetCell.sheetId"
          :items="sheets"
          value-key="id"
          item-text="title"
          full-width
          match-trigger-width
          show-search
          :loading="documentsLoading"
          @input="updateSheet"
        />
      </div>
      <div
        v-if="errorMessage !== ''"
        class="_error-messages"
      >
        <m-alert
          :message="errorMessage"
          type="error"
          show-icon
        />
      </div>
      <div
        v-if="localSpreadsheetCell.sheetId !== null"
        class="_item"
      >
        <div class="_label">
          {{ $t('spreadsheetForm.selectColumnLabel') }}
        </div>
        <m-select
          v-model:value="localSpreadsheetCell.column"
          :items="columns"
          value-key="value"
          item-text="title"
          full-width
          match-trigger-width
          show-search
        />
      </div>
      <div
        v-if="localSpreadsheetCell.sheetId !== null"
        class="_item"
      >
        <div class="_label">
          {{ $t('spreadsheetForm.selectRowLabel') }}
        </div>
        <m-input-number
          v-model:value="localSpreadsheetCell.row"
          :max="rows"
          full-width
        />
      </div>
      <div
        v-if="showViewFile"
        class="_item"
      >
        <m-link
          :href="localSpreadsheetCell.url"
          target="_blank"
          class="_link"
          underline-on-hover
        >
          {{ $t('spreadsheetForm.viewFile') }}
          <m-icon
            type="external-link"
            class="_icon"
          />
        </m-link>
      </div>
      <div
        v-if="localSpreadsheetCell.sheetId !== null"
        class="_item -sync"
      >
        <always-sync-checkbox v-model:value="localSpreadsheetCell.alwaysSync" />
      </div>
    </m-content>
    <template v-if="typeof localSpreadsheetCell.uid === 'undefined'">
      <m-divider xs />
      <m-content padding-small>
        <div class="_actions">
          <div class="_btns">
            <m-btn
              class="_btn"
              @click="$emit('close')"
            >
              {{ $t('general.cancel') }}
            </m-btn>
            <m-btn
              color="primary"
              class="_btn"
              :disabled="!isValidLocalSpreadsheetCell"
              :loading="mutateLoading"
              @click="save"
            >
              {{ $t('general.save') }}
            </m-btn>
          </div>
        </div>
      </m-content>
    </template>
    <template v-if="typeof localSpreadsheetCell.uid !== 'undefined'">
      <m-divider xs />
      <m-card-item
        icon="save"
        :loading="mutateLoading"
        :padding-x="8"
        @click="save"
      >
        {{ $t('general.save') }}
      </m-card-item>
      <m-card-item
        icon="sync"
        :loading="syncLoading"
        :padding-x="8"
        @click="syncNow"
      >
        {{ $t('dataSource.syncNow') }}
      </m-card-item>
      <m-card-item
        icon="delete"
        :padding-x="8"
        :loading="deleteLoading"
        @click="deleteSpreadsheetCell"
      >
        {{ $t('general.delete') }}
      </m-card-item>
    </template>
  </div>
</template>

<script>
import AlwaysSyncCheckbox from '@/components/datasource/AlwaysSyncCheckbox.vue';
import FileExplorer from '@/components/FileExplorer.vue';
import GoogleFilePicker from '@/components/datasource/GoogleFilePicker.vue';
import useSpreadsheet from '@/composables/integrations/spreadsheet/spreadsheet';
import { camelCase, isEqual } from 'lodash-es';
import { computed } from 'vue';
import { copy } from 'shared/lib/copy';
import { customDatasourceProperty, dataSourceType, spreadsheetError } from 'shared/constants.json';
import { encodeColumnIndex } from '@/lib/sheets';
import { logCatch } from '@/lib/logger/logger';

export default {
  name: 'SpreadsheetForm',
  props: {
    dataSource: {
      type: Object,
      required: true,
    },
    spreadsheetCell: {
      type: Object,
      default: () => null,
    },
    goal: {
      type: Object,
      required: true,
    },
  },
  emits: ['close', 'deleted'],
  components: { AlwaysSyncCheckbox, FileExplorer, GoogleFilePicker },
  setup() {
    const {
      createQuery,
      createQueryLoading,
      updateQuery,
      updateQueryLoading,
      deleteQuery,
      deleteQueryLoading,
      getSpreadsheetDocument,
      getSpreadsheetDocumentLoading,
      navigateSpreadsheetFolder,
      navigateSpreadsheetFolderLoading,
    } = useSpreadsheet();
    return {
      createQuery,
      updateQuery,
      deleteQuery,
      mutateLoading: computed(() => createQueryLoading.value || updateQueryLoading.value),
      deleteLoading: deleteQueryLoading,
      getSpreadsheetDocument,
      documentsLoading: getSpreadsheetDocumentLoading,
      navigateSpreadsheetFolder,
      navigateSpreadsheetFolderLoading,
    };
  },
  data() {
    return {
      localSpreadsheetCell: {
        documentId: null,
        sheetId: null,
        column: null,
        row: null,
        url: null,
        alwaysSync: false,
      },
      camelCase,
      syncLoading: false,
      dataSourceType,
      showFileExplorer: false,
      fileExplorerPath: null,
      customDatasourceProperty,
      gapiError: false,
    };
  },
  computed: {
    hasError() {
      return this.dataSource[customDatasourceProperty.spreadsheetError] !== '';
    },
    dataSourceId() {
      return this.dataSource.uid;
    },
    selectedDocument() {
      return this.localSpreadsheetCell?.documentId;
    },
    selectedDocumentLink() {
      const filtered = this.dataSource[customDatasourceProperty.spreadsheetDocuments].filter((d) => d.id === this.selectedDocument);
      if (filtered.length > 0) {
        return filtered[0].link;
      }

      if (this.localSpreadsheetCell.url !== null) {
        return this.localSpreadsheetCell.url;
      }

      return '';
    },
    selectedDocumentTitle() {
      const filtered = this.dataSource[customDatasourceProperty.spreadsheetDocuments].filter((d) => d.id === this.selectedDocument);
      if (filtered.length > 0) {
        return filtered[0].title;
      }

      if (this.localSpreadsheetCell.documentTitle !== null) {
        return this.localSpreadsheetCell.documentTitle;
      }

      return '';
    },
    errorMessage() {
      if (this.localSpreadsheetCell.error === spreadsheetError.notANumber) {
        return this.$t('spreadsheetForm.notANumberHint');
      }

      if (this.localSpreadsheetCell.error === spreadsheetError.empty) {
        return this.$t('spreadsheetForm.emptyHint');
      }

      if (this.gapiError) {
        return this.$t('spreadsheetForm.gapiError');
      }

      return '';
    },
    sheets() {
      if (this.localSpreadsheetCell.documentId === null) {
        return [];
      }

      const doc = this.dataSource[customDatasourceProperty.spreadsheetDocuments].filter((d) => d.id === this.localSpreadsheetCell.documentId);
      if (doc.length === 0 || doc[0].sheets === undefined) {
        return [];
      }

      return doc[0].sheets;
    },
    columns() {
      const filtered = this.sheets.filter((sh) => sh.id === this.localSpreadsheetCell.sheetId);
      if (filtered.length === 0) {
        return [];
      }

      const sheet = filtered[0];

      let res = [];
      for (let i = 0; i < sheet.columns; i++) {
        res = [...res, {
          value: encodeColumnIndex(i + 1),
          title: `${encodeColumnIndex(i + 1)}`,
        }];
      }
      return res;
    },
    rows() {
      const filtered = this.sheets.filter((sh) => sh.id === this.localSpreadsheetCell.sheetId);
      if (filtered.length === 0) {
        return 0;
      }

      const sheet = filtered[0];
      return sheet.rows;
    },
    selectedSheetName() {
      if (this.localSpreadsheetCell.sheetId === null) {
        return null;
      }
      return this.sheets.filter((sheet) => sheet.id === this.localSpreadsheetCell.sheetId)[0].title;
    },
    showViewFile() {
      if (this.localSpreadsheetCell.url === null) {
        return false;
      }
      if (this.localSpreadsheetCell.url === undefined) {
        return false;
      }
      if (this.localSpreadsheetCell.url === '') {
        return false;
      }

      return true;
    },
    isValidLocalSpreadsheetCell() {
      return !(this.localSpreadsheetCell.documentId === null
          || this.localSpreadsheetCell.sheetId === null
          || this.localSpreadsheetCell.column === null
          || this.localSpreadsheetCell.row === null);
    },
  },
  methods: {
    updateSheet(sheetId) {
      this.localSpreadsheetCell.sheetId = sheetId;
      this.localSpreadsheetCell.sheetName = this.sheets.filter((sheet) => sheet.id === sheetId)[0].title;
    },
    save() {
      if (typeof this.localSpreadsheetCell.uid === 'undefined') {
        this.createSpreadsheetCell(this.localSpreadsheetCell);
        return;
      }

      this.updateSpreadsheetCell(this.localSpreadsheetCell);
    },
    createSpreadsheetCell(cell) {
      this.createQuery({
        ...cell,
        documentTitle: this.selectedDocumentTitle,
        dataSource: { uid: this.dataSource.uid },
        goal: { uid: this.goal.uid },
      }).then((query) => {
        this.localSpreadsheetCell = copy(query);
      }).catch(logCatch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      }));
    },
    updateSpreadsheetCell(cell) {
      this.updateQuery({
        ...cell,
        documentTitle: this.selectedDocumentTitle,
        dataSource: { uid: this.dataSource.uid },
      }, { ignoreResponse: false }).then((query) => {
        this.localSpreadsheetCell = copy(query);
      }).catch(logCatch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      }));
    },
    syncNow() {
      this.syncLoading = true;
      this.updateQuery({
        ...this.localSpreadsheetCell,
        documentTitle: this.selectedDocumentTitle,
        dataSource: { uid: this.dataSource.uid },
      }, { ignoreResponse: false }).then((query) => {
        this.localSpreadsheetCell = copy(query);
        switch (this.localSpreadsheetCell.dataSource.type) {
          case dataSourceType.excel:
            this.$showSnackbar({ color: 'success', message: this.$t('spreadsheetForm.excelHint') });
            break;
          default:
            this.$showSnackbar({ color: 'success', message: this.$t('dataSource.successfullySynced') });
            break;
        }
      }).catch(logCatch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      })).finally(() => {
        this.syncLoading = false;
      });
    },
    deleteSpreadsheetCell() {
      const deleteMethod = () => this.deleteQuery(this.goal.spreadsheetCell.uid).then(() => {
        this.localSpreadsheetCell = {
          documentId: null,
          sheetId: null,
          column: null,
          row: null,
        };
        this.$showSnackbar({ color: 'success', message: this.$t('success.deleted') });
        this.$emit('deleted');
        this.$emit('close');
      }).catch(logCatch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      }));

      this.$confirm({
        title: this.$t('dataSource.deleteConnectionPrompt'),
        okText: this.$t('general.yesDelete'),
        okType: 'danger',
        maskClosable: true,
        cancelText: this.$t('general.cancel'),
        onOk() {
          deleteMethod();
        },
      });
    },
    reset() {
      this.localSpreadsheetCell.column = null;
      this.localSpreadsheetCell.row = null;
      this.localSpreadsheetCell.sheetId = null;

      const actual = this.dataSource[customDatasourceProperty.spreadsheetDocuments].filter((d) => d.id === this.localSpreadsheetCell.documentId);
      if (actual.length === 0) {
        return;
      }
      this.localSpreadsheetCell.url = actual[0].link;
    },
    getSheetsDetails(document) {
      this.getSpreadsheetDocument(this.dataSource, document)
        .catch(() => {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
        });
    },
    navigateFolder(input) {
      const { item, path } = input;
      this.fileExplorerPath = path;

      this.navigateSpreadsheetFolder(this.dataSource, item).catch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      });
    },
    selectDocument({ item, path }) {
      this.fileExplorerPath = path;
      this.reset();
      this.localSpreadsheetCell = { documentId: item.id };
      this.showFileExplorer = false;

      const actual = this.dataSource[customDatasourceProperty.spreadsheetDocuments].filter((d) => d.id === item.id);
      if (actual.length === 0) {
        return;
      }
      this.localSpreadsheetCell.url = actual[0].link;
    },
    openFileExplorer() {
      this.showFileExplorer = true;
    },
    selectGoogleSheetsDocument(document) {
      this.localSpreadsheetCell = {
        ...this.localSpreadsheetCell,
        ...document,
      };
    },
  },
  watch: {
    dataSourceId(val) {
      if (this.spreadsheetCell !== null && val === this.spreadsheetCell.dataSource.uid) {
        this.localSpreadsheetCell = copy(this.spreadsheetCell);
        return;
      }

      this.localSpreadsheetCell = {
        documentId: null,
        sheetId: null,
        column: null,
        row: null,
      };
    },
    selectedDocument(newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      }
      if (newVal !== null) {
        this.getSheetsDetails({ id: newVal });
      }
    },
    spreadsheetCell: {
      handler(newVal, oldVal) {
        if (isEqual(newVal, oldVal) || newVal === null) {
          return;
        }
        this.localSpreadsheetCell = copy(newVal);
      },
      deep: true,
    },
  },
  created() {
    (() => {
      if (this.spreadsheetCell === null) {
        return;
      }

      if (this.spreadsheetCell.dataSource === null) {
        return;
      }

      if (this.spreadsheetCell.dataSource.uid !== this.dataSource.uid) {
        return;
      }

      this.localSpreadsheetCell = copy(this.spreadsheetCell);
    })();

    if (this.localSpreadsheetCell.documentId === null) {
      return;
    }

    // excel file not in root
    if (this.dataSource[customDatasourceProperty.spreadsheetDocuments].filter((d) => d.id === this.localSpreadsheetCell.documentId).length === 0) {
      this.getSheetsDetails({ id: this.localSpreadsheetCell.documentId });
    }
  },
};
</script>

<style scoped lang="scss" type="text/scss">
.spreadsheet-form {
  margin-bottom: .6rem;

  ._error-messages {
    margin-bottom: 2rem;
  }

  ._item {
    margin-bottom: 1.4rem;

    ._label {
      margin-bottom: .4rem;
      color: $font-color-secondary;
    }

    ._link {
      display: flex;

      ._icon {
        margin-left: .4rem;
      }
    }
  }

  ._actions {
    display: flex;

    ._btns {
      display: flex;
      margin-left: auto;

      ._btn {
        margin-left: .4rem;
      }
    }
  }

  ._selected-item {
    border: 1px solid $input-border-color;
    border-radius: $default-border-radius;
  }

  ._document-select {
    display: flex;
    align-items: center;
    justify-content: space-between;

    ._document-name {
      display: flex;
      align-items: center;

      ._link {
        margin-left: .4rem;
        color: $font-color-primary;
      }
    }
  }
}
</style>
