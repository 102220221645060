const stripPropertyValue = (p) => ({
  ...p,
  property: { uid: p.property.uid },
  users: p.users.map((u) => ({ uid: u.uid })),
  spaces: p.spaces.map((o) => ({ uid: o.uid })),
  selectedOptions: p.selectedOptions.map((o) => ({ uid: o.uid })),
});

const stripEntity = (entity) => {
  if (typeof entity === 'undefined' || entity === null || typeof entity.uid === 'undefined') {
    return entity;
  }

  return { uid: entity.uid, rid: entity.rid };
};

export const stripGoal = (goal) => ({
  ...goal,
  goalCycle: goal.goalCycle.map((c) => ({ uid: c.uid })),
  parents: goal.parents.map((g) => stripEntity(g)),
  properties: goal.properties.map((p) => stripPropertyValue(p)),
  creator: stripEntity(goal.creator),
  paysOnto: goal.paysOnto.map((g) => ({ ...stripEntity(g), 'paysOnto|weight': g['paysOnto|weight'] })),
  subscriptions: goal.subscriptions.map((s) => ({
    ...s,
    user: { uid: s.user.uid },
  })),
});
