<template>
  <m-card-item
    :clickable="false"
    align-top
    @click="showDetailPage"
  >
    <template #left>
      <m-icon-display
        :icon="goalTypeIcon"
        :size="18"
        show-background
      />
    </template>
    <div class="trash-item">
      <div
        class="_title"
      >
        <item-title
          :title="item.title"
          :icons="[{ value: icon }]"
          class="_top"
        />
        <div class="_sub-title">
          {{ $t('trashItem.deletedAt') }} {{ formatDate(item.softDeletedAt) }}
        </div>
      </div>

      <div class="_actions">
        <m-tooltip
          class="_action -restore"
        >
          <m-btn
            data-cy="trash-item-restore-btn"
            small
            fab
            light
            hide-border
            icon="restore"
            @click.stop="restore"
          />
          <template #title>
            {{ $t('general.restore') }}
          </template>
        </m-tooltip>
        <m-tooltip
          class="_action -delete"
        >
          <m-btn
            data-cy="trash-item-delete-btn"
            small
            fab
            light
            hide-border
            icon="delete"
            @click.stop="deletePermanently"
          />
          <template #title>
            {{ $t('general.deletePermanently') }}
          </template>
        </m-tooltip>
      </div>
    </div>
  </m-card-item>
</template>

<script>

import ItemTitle from '@/components/ItemTitle.vue';
import useGoalTypeProperty from '@/composables/customize-page/goal-type-property';
import { DateTime } from 'luxon';
import { buildIconFromEntity } from 'shared/lib/icon';

export default {
  name: 'TrashItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { goalTypeIcon } = useGoalTypeProperty();
    return { goalTypeIconFn: goalTypeIcon };
  },
  components: { ItemTitle },
  emits: ['restore', 'delete-permanently', 'show-detail-page'],
  computed: {
    goalTypeIcon() {
      return this.goalTypeIconFn(this.item);
    },
    icon() {
      return buildIconFromEntity(this.item);
    },
  },
  methods: {
    formatDate(date) {
      return DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_MED);
    },
    restore(event) {
      event.preventDefault();
      this.$emit('restore', { event, item: this.item, action: 'restore' });
    },
    deletePermanently(event) {
      event.preventDefault();
      this.$emit('delete-permanently', { event, item: this.item, action: 'delete-permanently' });
    },
    showDetailPage(event) {
      if (event.defaultPrevented) {
        return;
      }
      this.$emit('show-detail-page', { event, item: this.item, action: 'show-detail-page' });
    },
  },
};
</script>

<style scoped lang="scss">
.trash-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  ._title {
    max-width: calc(100% - 6rem);

    ._sub-title {
      margin-top: .4rem;
      font-size: $font-size-2;
      color: $font-color-secondary;
    }
  }

  ._actions {
    display: flex;
    justify-content: flex-end;

    ._action {
      font-size: $font-size-4;
      color: $font-color-tertiary;
    }
  }
}
</style>
