<template>
  <div class="turn-into-list">
    <component-list-item
      v-for="(item, index) in items"
      :key="index"
      :item="item"
      @click="handleClick(item)"
    />
  </div>
</template>

<script>
import ComponentListItem from '@/components/editor/ComponentListItem.vue';
import { camelCase, kebabCase } from 'lodash-es';
import { editorNodeType } from 'shared/constants.json';

export default {
  name: 'TurnIntoList',
  props: {
    allowedContent: {
      type: Array,
      default: () => [],
    },
    commands: {
      type: Object,
      required: true,
    },
    isActive: {
      type: Object,
      required: true,
    },
  },
  emits: ['close'],
  components: { ComponentListItem },
  computed: {
    turnIntoTypes() {
      return [
        editorNodeType.blockquote,
        editorNodeType.codeBlock,
        editorNodeType.heading,
        editorNodeType.orderedList,
        editorNodeType.bulletList,
        editorNodeType.link,
        editorNodeType.todoList,
      ];
    },
    items() {
      return this.allowedContent.reduce((res, t) => {
        if (!this.turnIntoTypes.includes(t)) {
          return res;
        }

        if (t === editorNodeType.heading) {
          return [
            ...res,
            ...[1, 2, 3].map((n) => {
              const imageName = `${kebabCase(t)}${n}`;

              return {
                title: this.$t(`mContextMenu.${camelCase(t)}${n}.title`),
                subTitle: this.$t(`mContextMenu.${camelCase(t)}${n}.subTitle`),
                level: n,
                type: t,
                src: new URL(`@/assets/img/editor/${imageName}.png`, import.meta.url).href,
              };
            }),
          ];
        }

        const imageName = `${kebabCase(t)}`;
        return [
          ...res,
          {
            title: this.$t(`mContextMenu.${camelCase(t)}.title`),
            subTitle: this.$t(`mContextMenu.${camelCase(t)}.subTitle`),
            // eslint-disable-next-line global-require,import/no-dynamic-require
            src: new URL(`@/assets/img/editor/${imageName}.png`, import.meta.url).href,
            type: t,
          },
        ];
      }, []);
    },
  },
  methods: {
    handleClick(item) {
      if (item.type === editorNodeType.heading) {
        if (this.isActive[item.type]({ level: item.level })) {
          this.$emit('close');
          return;
        }

        this.commands[item.type]({ level: item.level });
        this.$emit('close');
        return;
      }

      if (this.isActive[item.type]()) {
        this.$emit('close');
        return;
      }

      this.commands[item.type]();
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">

</style>
