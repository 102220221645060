export const HAS = 'has';
export const NOT = 'not';
export const AND = 'and';
export const OR = 'or';
export const VAR = 'var';
export const TYPE = 'type';
export const UID = 'uid';
export const UID_IN = 'uid_in';
export const DGRAPH_TYPE = 'dgraph.type';
export const GT = 'gt';
export const GE = 'ge';
export const EQ = 'eq';
export const LT = 'lt';
export const LE = 'le';
export const ALL_OF_TEXT = 'alloftext';
export const ONE_TO_ONE = '1-to-1';
export const CREATED_AT = 'createdAt';
export const MODIFIED_AT = 'modifiedAt';
export const SOFT_DELETED_AT = 'softDeletedAt';
export const DELETED_AT = 'deletedAt';

export const DEFAULT_ATTRIBUTES = [
  { attr: UID },
  { attr: CREATED_AT },
  { attr: MODIFIED_AT },
];

// alias
export const RESULT = 'result';
export const ALL_ALIAS = 'all';
export const COUNT = 'count';
