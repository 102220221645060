<template>
  <div
    :class="[
      'editable-page-header',
      showIconOnTop ? '-show-icon-on-top' : '',
      showIcon || !!$slots['pre-top-icon'] ? '-has-icon' : '',
      $store.state.breakpoint.smAndDown ? '-mobile' : '',
      !!$slots.actions ? '-has-actions' : '',
      `-${size}`,
    ]
    "
  >
    <div
      v-if="showIconOnTop || !!$slots['pre-top-icon']"
      class="_top-icon-wrapper"
    >
      <div
        v-if="!!$slots['pre-top-icon']"
        class="_pre-top-icon"
      >
        <slot name="pre-top-icon" />
      </div>
      <icon-picker
        v-if="showIcon"
        class="_top-icon"
        :icon-types="iconTypes"
        :icon="icon"
        :size="`${iconSize}px`"
        :icon-size="iconSize"
        :disabled="disabled"
        @change="updateIcon"
      />
    </div>
    <div class="_actions">
      <slot name="actions" />
    </div>
    <div class="_heading">
      <icon-picker
        v-if="showIcon && !showIconOnTop"
        class="_icon"
        :icon-types="iconTypes"
        :icon="icon"
        :size="`${iconSize}px`"
        :icon-size="iconSize"
        :disabled="disabled"
        @change="updateIcon"
      />
      <div
        :class="['_input-field', disabled ? '-disabled' : '']"
      >
        <simple-editor
          ref="title"
          class="_title"
          :initial-value="title"
          :read-only="disabled"
          :placeholder="placeholder"
          tag="h1"
          tabindex="0"
          full-width
          :auto-focus="autoFocus"
          :style="inputStyle"
          @update:value="updateTitle"
        />
      </div>
      <div
        v-if="!!$slots.right"
        class="_right"
      >
        <slot name="right" />
      </div>
    </div>
  </div>
</template>

<script>
import IconPicker from '@/components/IconPicker.vue';
import SimpleEditor from '@/components/SimpleEditor.vue';
import { Icon } from 'shared/lib/icon';
import { iconType } from 'shared/constants.json';

export default {
  name: 'EditablePageHeader',
  props: {
    title: {
      type: String,
      default: '',
    },
    icon: {
      type: Icon,
      default: null,
    },
    iconTypes: {
      type: Array,
      default: () => [iconType.emoji, iconType.icon],
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
    showIconOnTop: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    autoFocus: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    inputStyle: {
      type: Object,
      default: () => {
      },
    },
    size: {
      type: String,
      default: 'default',
    },
  },
  emits: ['arrow-down', 'enter', 'change-icon', 'change-title'],
  components: { SimpleEditor, IconPicker },
  computed: {
    iconSize() {
      if (!this.showIconOnTop) {
        if (this.size === 'small') {
          if (this.$store.state.breakpoint.smAndDown) {
            return 20;
          }

          return 30;
        }
        if (this.$store.state.breakpoint.smAndDown) {
          return 24;
        }

        return 36;
      }

      if (this.$store.state.breakpoint.smAndDown) {
        return 40;
      }

      return 60;
    },
  },
  methods: {
    updateIcon(value) {
      this.$emit('change-icon', value);
    },
    updateTitle(val) {
      this.$emit('change-title', val);
    },
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  .editable-page-header {
    position: relative;
    width: 100%;
    max-width: 120rem;
    padding-top: 2rem;

    &.-has-actions {
      padding-top: 3.4rem;
    }

    ._top-icon-wrapper {
      position: absolute;
      top: 1.5rem;
      display: flex;
      align-items: center;
    }

    ._heading {
      display: flex;
      width: 100%;

      ._input-field {
        position: relative;
        flex: 1 1 auto;
        margin-right: auto;
        border-radius: $border-radius-sm;
      }

      ._icon {
        padding-top: .1rem;
        margin: .3rem 1.1rem 0 0;
      }
    }

    ._actions {
      position: absolute;
      top: .6rem;
      display: none;
    }

    &:hover {
      ._actions {
        display: block;
      }
    }

    &.-mobile {
      ._actions {
        display: flex;
        max-width: 100%;
        padding: .4rem 0;
        margin: .4rem 0;
        overflow: auto;
      }
    }

    &.-show-icon-on-top {
      padding-top: 7rem;

      ._actions {
        top: 3.5rem;
      }

      &.-mobile {
        ._actions {
          top: 2rem;
        }
      }

      &.-has-icon {
        padding-top: 12rem;

        ._actions {
          top: 8.5rem;
        }

        &.-mobile {
          ._actions {
            top: 7.8rem;
          }
        }
      }
    }

    h1 {
      z-index: 1;
      flex: 1 1 100%;
      margin-top: 0;
      word-break: break-word;
      white-space: pre-wrap;

      &:focus {
        outline: none;
      }

      &:hover {
        border-color: $hover-color;
      }
    }

    ._placeholder {
      position: absolute;
      top: -.6rem;
      left: 0;
      font-size: $font-size-h1;
      font-weight: $font-weight-bold;
      color: $font-color-tertiary;
      pointer-events: none;
    }

    &.-small {
      h1 {
        font-size: $font-size-h2;
      }

      ._placeholder {
        font-size: $font-size-h2;
      }
    }
  }
</style>
