<template>
  <div
    :class="['welcome-screen-field', isSelected ? '-selected' : '']"
    @click="clicked"
  >
    <div class="_type">
      <form-field-icon

        type="welcome_screen"
        :show-index="false"
      />
    </div>
    <div class="_input">
      <textarea
        :rows="1"
        type="text"
        class="_textarea -title"
        :placeholder="$t('welcomeScreenField.titleLabel')"
        :value="welcomeScreen.title[lang]"
        :readonly="disabled"
        @input="updateTitle"
      />
      <textarea
        :rows="3"
        type="text"
        :placeholder="$t('welcomeScreenField.descriptionLabel')"
        class="_textarea -description"
        :value="welcomeScreen.description[lang]"
        :readonly="disabled"
        @input="updateDescription"
      />
    </div>
    <div
      v-if="!disabled"
      class="_actions"
    >
      <m-btn
        icon="delete"
        hide-border
        fab
        small
        class="_btn"
        @click="showDeleteConfirm"
      />
    </div>
  </div>
</template>

<script>
import FormFieldIcon from '@/components/FormFieldIcon.vue';

export default {
  name: 'WelcomeScreenField',
  props: {
    welcomeScreen: {
      type: Object,
      required: true,
    },
    isSelected: {
      type: Boolean,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
    model: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['welcome-screen-selected'],
  components: { FormFieldIcon },
  methods: {
    showDeleteConfirm() {
      const deletedMethod = this.setDeleted;
      this.$confirm({
        title: this.$t('formField.prompt'),
        okText: this.$t('formField.submitLabel'),
        maskClosable: true,
        okType: 'danger',
        cancelText: this.$t('general.cancel'),
        onOk() {
          deletedMethod();
        },
      });
    },
    clicked() {
      this.$emit('welcome-screen-selected');
    },
    updateTitle(event) {
      this.$store.commit('WELCOME_SCREEN_TITLE_UPDATED', {
        value: event.srcElement.value,
        lang: this.lang,
        model: this.model,
      });
    },
    updateDescription(event) {
      this.$store.commit('WELCOME_SCREEN_DESCRIPTION_UPDATED', {
        value: event.srcElement.value,
        lang: this.lang,
        model: this.model,
      });
    },
    setDeleted() {
      this.$store.commit('WELCOME_SCREEN_DELETED', { model: this.model });
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .welcome-screen-field {
    position: relative;
    display: flex;
    min-height: 11rem;
    padding: 2.4rem;

    ._actions {
      position: absolute;
      right: 0;
      bottom: 0;
      display: none;
      padding: .2rem;

      ._btn {
        margin: .4rem .2rem;
      }
    }

    &:hover {
      background-color: map_get($grey, 'lighten-4');

      ._actions {
        display: block;
      }
    }

    &.-selected {
      background-color: map_get($grey, 'lighten-4');
    }

    ._type {
      margin-right: 2rem;
    }

    ._input {
      width: 100%;

      ._textarea {
        width: 95%;

        &:focus {
          outline: none;
        }

        &.-title {
          resize: none;
        }

        &.-description {
          color: map_get($grey, 'darken-1');
        }
      }
    }
  }
</style>
