<template>
  <div class="form-send-channels">
    <m-form-item :label="$t('formSendChannels.subject')">
      <m-text-field
        :value="subject"
        @change="setSubject"
      />
    </m-form-item>
    <m-form-item :label="$t('formSendChannels.body')">
      <m-textarea
        :rows="10"
        :value="body"
        @change="setBody"
      />
    </m-form-item>
    <div class="_actions">
      <m-spacer />
      <m-btn
        icon="eye"
        @click="previewVisible = true"
      >
        {{ $t('formSendChannels.previewEmail') }}
      </m-btn>
      <m-dialog
        v-model:value="previewVisible"
        :title="$t('formSendChannels.previewEmailTitle')"
        no-padding
        hide-footer
      >
        <m-content
          padding
          :style="contentStyle"
        >
          <detail-item
            :label="`${$t('formSendChannels.subject')}:`"
            :value="subject"
          />
        </m-content>
        <email-preview :body="body">
          <template #actions>
            <div
              class="_actions"
            >
              <m-btn
                color="primary"
              >
                {{ $t('emailPreview.takeSurvey') }}
              </m-btn>
            </div>
          </template>
        </email-preview>
      </m-dialog>
    </div>
  </div>
</template>

<script>
import DetailItem from '@/components/DetailItem.vue';
import EmailPreview from '@/components/EmailPreview.vue';
import { distributionChannelType } from 'shared/constants.json';

export default {
  name: 'FormSendChannels',
  props: {
    distributionChannel: {
      type: Object,
      default: () => null,
    },
    lang: {
      type: String,
      default: 'en',
    },
  },
  components: { DetailItem, EmailPreview },
  data() {
    return { previewVisible: false };
  },
  computed: {
    contentStyle() {
      return { 'border-bottom': `1px solid ${this.$colors.grey.lighten3}` };
    },
    subject: {
      get() {
        if (this.distributionChannel === null || this.distributionChannel.mailProperties === null) {
          return '';
        }

        return this.distributionChannel.mailProperties.subject[this.lang];
      },
      set(value) {
        this.$store.commit('UPDATE_DISTRIBUTION_CHANNEL', {
          ...this.distributionChannel,
          mailProperties: {
            ...this.distributionChannel.mailProperties,
            subject: { [this.lang]: value },
          },
        });
      },
    },
    body: {
      get() {
        if (this.distributionChannel === null || this.distributionChannel.mailProperties === null) {
          return '';
        }

        return this.distributionChannel.mailProperties.body[this.lang];
      },
      set(value) {
        this.$store.commit('UPDATE_DISTRIBUTION_CHANNEL', {
          ...this.distributionChannel,
          mailProperties: {
            ...this.distributionChannel.mailProperties,
            body: { [this.lang]: value },
          },
        });
      },
    },
  },
  methods: {
    setSubject(value) {
      this.subject = value;
    },
    setBody(value) {
      this.body = value;
    },
  },
  mounted() {
    if (this.distributionChannel === null) {
      this.$store.commit('UPDATE_DISTRIBUTION_CHANNEL', {
        type: distributionChannelType.mail,
        mailProperties: { subject: { [this.lang]: '' }, body: { [this.lang]: '' } },
      });
    }
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .form-send-channels {
    ._content {
      border-bottom: 1px solid $border-color;
    }

    ._actions {
      display: flex;
    }
  }
</style>
