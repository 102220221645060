<template>
  <question-field
    :question-index="questionIndex"
    :field="field"
    :active="active"
    :lang="lang"
    class="long-text"
  >
    <div class="_field">
      <m-textarea
        v-model:value="answer"
        :rules="rules"
        :rows="3"
        @change="answerChanged"
      />
    </div>
    <div class="_action-wrapper">
      <m-btn
        v-if="answer !== ''"
        color="primary"
        @click="answered"
      >
        {{ $t('form.continue') }}
      </m-btn>
    </div>
  </question-field>
</template>

<script>
import QuestionField from 'shared/components/internal/form/QuestionField.vue';

export default {
  name: 'LongText',
  props: {
    questionIndex: {
      type: Number,
      required: true,
    },
    active: {
      type: Boolean,
      required: true,
    },
    field: {
      type: Object,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
  },
  emits: ['question-selected', 'answer-changed'],
  components: { QuestionField },
  data() {
    return { answer: '' };
  },
  computed: {
    rules() {
      if (!this.field.validations.required) {
        return [];
      }

      return [
        (value) => !!value || this.$t('validation.required'),
      ];
    },
  },
  methods: {
    answered() {
      this.$emit('question-selected', {
        questionIndex: this.questionIndex,
        answer: {
          field: { uid: this.field.uid },
          text: this.answer,
        },
        timeout: 0,
      });
    },
    answerChanged() {
      this.$emit('answer-changed', {
        questionIndex: this.questionIndex,
        answer: {
          field: { uid: this.field.uid },
          text: this.answer,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .long-text {
    ._field {
      padding-bottom: $base-container-padding-y;
    }

    ._action-wrapper {
      height: 3.5rem;
    }
  }
</style>
