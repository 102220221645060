<template>
  <h4
    :class="['m-toolbar-title']"
  >
    <slot />
  </h4>
</template>

<script>
export default { name: 'MToolbarTitle' };
</script>

<style lang="scss" type="text/scss">
  .m-toolbar-title {
    margin: 0;
  }
</style>
