<template>
  <div class="calendar-event-form">
    <div class="_label">
      {{ $t('calendarEventForm.title') }}
    </div>
    <m-input
      v-model:value="title"
      :disabled="timezoneLoading"
    />
    <div class="_label">
      {{ $t('calendarEventForm.date') }}
    </div>
    <m-date-picker
      :value="fromISO(date)"
      full-width
      :date-time="DateTime"
      clearable
      :placeholder="$t('general.empty')"
      :locale="userLang"
      include-time
      :disabled="timezoneLoading"
      @input="updateDate"
    />
    <i18n-t
      tag="div"
      keypath="calendarEventForm.timezone"
      class="_timezone-hint"
    >
      <template #timezone>
        <span class="_timezone"> {{ personalAppSettings.timezone }}</span>
      </template>
      <template #settings>
        <m-link :to="{ path: '/profile-settings' }">
          {{ $t('calendarEventForm.settings') }}
        </m-link>
      </template>
    </i18n-t>
  </div>
</template>

<script>
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import usePersonalAppSettings from '@/composables/logged-in-user/personal-app-settings';
import { DateTime } from 'luxon';
import { fromISO, toISO } from 'shared/lib/time';

export default {
  name: 'CalendarEventForm',
  props: {
    value: {
      type: Object,
      default: () => ({ title: '', date: null }),
    },
    meeting: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { loggedInUser, userLang } = useLoggedInUser();
    const { personalAppSettings } = usePersonalAppSettings(loggedInUser);
    return { userLang, personalAppSettings };
  },
  emits: ['input', 'update:value'],
  data() {
    return {
      DateTime,
      title: '',
      date: null,
      fromISO,
      timezoneLoading: false,
    };
  },
  methods: {
    updateDate(date) {
      this.date = toISO(date);
    },
  },
  watch: {
    title(val) {
      this.$emit('input', { ...this.value, title: val });
      this.$emit('update:value', { ...this.value, title: val });
    },
    date(val) {
      this.$emit('input', { ...this.value, date: val });
      this.$emit('update:value', { ...this.value, date: val });
    },
  },
  mounted() {
    this.title = this.meeting.title;
    this.date = this.meeting.date;
  },
};
</script>

<style
    lang="scss"
    type="text/scss"
    scoped
>
  .calendar-event-form {
    ._label {
      padding-top: 1.2rem;
      color: $font-color-secondary;
    }

    ._timezone-hint {
      padding-top: 1.2rem;
      color: $font-color-secondary;

      ._timezone {
        color: map_get($grey, 'darken-1');
      }
    }
  }
</style>
