import { UID } from 'shared/api/query/constants';
import {
  pin as pinConfig,
  space as spaceConfig,
} from 'shared/api/query/configs.json';

export const pinEdges = [
  { attr: UID },
  { attr: pinConfig.edges.type },
  {
    attr: pinConfig.edges.space,
    model: spaceConfig.model,
    children: [
      { attr: UID },
    ],
  },
];
