<template>
  <m-tooltip
    class="google-file-picker"
    :disabled="!isTitleEllipsisActive"
    :mouse-enter-delay=".5"
  >
    <m-btn
      block
      :disabled="!pickerApiLoaded"
      @click="open"
    >
      <template v-if="props.spreadsheetCell.documentId === null">
        {{ $t('spreadsheetForm.selectFile') }}
      </template>
      <template v-else>
        <div
          ref="titleRef"
          class="_title"
        >
          {{ props.spreadsheetCell.documentTitle }}
        </div>
      </template>
    </m-btn>
    <template #title>
      {{ props.spreadsheetCell.documentTitle }}
    </template>
  </m-tooltip>
</template>

<script setup>
import useElementsTruncate from 'shared/composables/element-truncate';
import useLocalStorage from '@/composables/local-storage/local-storage';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import { environmentVariable, resolveEnvironmentVariable } from '@/lib/env';
import { onBeforeMount, ref } from 'vue';

/* eslint-disable no-undef */
const props = defineProps({
  spreadsheetCell: {
    type: Object,
    required: true,
  },
});

const { userLang, loggedInUser } = useLoggedInUser();

const { data: credentials, load } = useLocalStorage(window.localStorage, { getKey: () => ref(`gapi_drive_file_credentials_${loggedInUser.value.uid}`) }, { accessToken: null, expiresAt: null });
load();

const pickerApiLoaded = ref(false);
const picker = ref(null);
const titleRef = ref(null);

const { isTruncated: isTitleEllipsisActive } = useElementsTruncate([titleRef]);

const retrieveAccessToken = () => new Promise((resolve) => {
  const client = google.accounts.oauth2.initTokenClient({
    client_id: resolveEnvironmentVariable(environmentVariable.GOOGLE_CLIENT_ID),
    scope: 'https://www.googleapis.com/auth/drive.file',
    callback: (response) => {
      credentials.value.accessToken = response.access_token;
      credentials.value.expiresAt = (new Date()).getTime() + (response.expires_in * 1000);
      resolve();
    },
  });
  client.requestAccessToken();
});

const createPicker = () => {
  const docsView = new google.picker.DocsView(google.picker.ViewId.SPREADSHEETS)
    .setMode(google.picker.DocsViewMode.LIST);
  const pickerBuilder = new google.picker.PickerBuilder()
    .addView(docsView)
    .setOAuthToken(credentials.value.accessToken)
    .setDeveloperKey(resolveEnvironmentVariable(environmentVariable.GOOGLE_PICKER_API_KEY))
    .setLocale(userLang)
    .setCallback(pickerCallback);

  picker.value = pickerBuilder.build();
};

const close = () => {
  picker.value.setVisible(false);
};
const open = () => {
  if (credentials.value.accessToken === null || (new Date()) > (new Date(credentials.value.expiresAt))) {
    retrieveAccessToken().then(() => {
      createPicker();
      picker.value.setVisible(true);
    });
    return;
  }

  if (picker.value === null) {
    createPicker();
  }

  picker.value.setVisible(true);
};

const emit = defineEmits(['select', 'gapi-error']);
const pickerCallback = (data) => {
  if (data[google.picker.Response.ACTION] === google.picker.Action.PICKED) {
    const d = data[google.picker.Response.DOCUMENTS][0];

    emit(
      'select',
      {
        documentId: d.id,
        documentTitle: d.name,
        url: d.url,
      },
    );

    close();
  }
  if (data[google.picker.Response.ACTION] === google.picker.Action.CANCEL) {
    close();
  }
};

onBeforeMount(() => {
  if (!window.gapi) {
    emit('gapi-error');
    return;
  }

  window.gapi.load('picker', () => {
    pickerApiLoaded.value = true;
  });
});

</script>

<style>
  .picker-dialog-bg {
    z-index: 20000 !important;
  }

  .picker-dialog {
    z-index: 20001 !important;
  }
</style>

<style
  scoped
  lang="scss"
  type="text/scss"
>
  .google-file-picker {
    ._title {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
</style>
