import { buildIconFromEntity } from 'shared/lib/icon';

const breadcrumb = (level, selectSingle, goalTypeIcon) => (res, next) => {
  const g = selectSingle(next.uid);
  if (g === undefined) {
    return res;
  }

  const r = g.parents.reduce(breadcrumb(level + 1, selectSingle, goalTypeIcon), res);

  if (typeof r[level] === 'undefined') {
    r[level] = [];
  }

  r[level].push({
    title: g.title,
    icons: [{ value: goalTypeIcon(g), showBackground: true }, { value: buildIconFromEntity(g) }],
    uid: g.uid,
  });
  return r;
};

export const breadcrumbs = (goal, selectSingle, goalTypeIcon) => goal.parents.reduce(breadcrumb(1, selectSingle, goalTypeIcon), [[{
  title: goal.title,
  uid: goal.uid,
  icons: [{ value: goalTypeIcon(goal), showBackground: true }, { value: buildIconFromEntity(goal) }],
}]]).reverse();
