<template>
  <div
    :class="classes"
  >
    <div
      v-if="!isBoxed"
      class="_inner"
    >
      <div class="_title">
        <page-title
          v-if="!$slots.title && title !== ''"
          :title="title"
          :sub-title="subTitle"
          :heading="heading"
        />
        <slot name="title" />
      </div>
      <div class="_actions">
        <slot name="actions" />
      </div>
    </div>
    <m-content
      v-else
      :boxed="boxed"
      :boxed-small="boxedSmall"
      :boxed-xs="boxedXs"
      :boxed-large="boxedLarge"
    >
      <div class="_inner">
        <div class="_title">
          <page-title
            v-if="!$slots.title && title !== ''"
            :title="title"
            :sub-title="subTitle"
            :heading="heading"
          />
          <slot name="title" />
        </div>
        <div class="_actions">
          <slot name="actions" />
        </div>
      </div>
    </m-content>
  </div>
</template>

<script>
import PageTitle from 'shared/components/PageTitle.vue';

export default {
  name: 'PageHeader',
  props: {
    title: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
    heading: {
      type: String,
      default: 'h1',
    },
    boxed: {
      type: Boolean,
      default: false,
    },
    boxedXs: {
      type: Boolean,
      default: false,
    },
    boxedSmall: {
      type: Boolean,
      default: false,
    },
    boxedLarge: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    noPaddingX: {
      type: Boolean,
      default: false,
    },
    paddingTopSmall: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    underlined: {
      type: Boolean,
      default: false,
    },
  },
  components: { PageTitle },
  computed: {
    isBoxed() {
      return this.boxed || this.boxedSmall || this.boxedLarge || this.boxedXs;
    },
    classes() {
      return [
        'page-header',
        this.isBoxed ? '-boxed' : '',
        this.small ? '-small' : '',
        this.noPadding ? '-no-padding' : '',
        this.noPaddingX ? '-no-padding-x' : '',
        this.paddingTopSmall ? '-padding-top-small' : '',
        this.underlined ? '-underlined' : '',
      ];
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  @import "shared/assets/scss/_padding";

  .page-header {
    display: flex;
    border-radius: inherit;

    ._inner {
      display: flex;
      align-items: center;
      width: 100%;
      min-height: 2.5rem;
    }

    &.-underlined {
      ._inner {
        padding-bottom: 1rem;
        border-bottom: 1px solid $border-color;
      }
    }

    @include paddingPageHeader;

    &.-no-padding {
      padding: 0;
    }

    &.-no-padding-x {
      padding-right: 0;
      padding-left: 0;
    }

    &.-padding-top-small {
      padding-top: 2rem;
    }

    ._actions {
      display: flex;
      margin-bottom: .1rem;
      margin-left: auto;
    }

    &.-small {
      @include paddingPageHeader('small');
    }
  }
</style>
