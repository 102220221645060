<template>
  <div
    v-if="!published"
    class="publish-goals"
  >
    <div class="_table">
      <div class="_header">
        <div :ref="(el) => headerPortalTarget = el" />
      </div>
      <r-goals-cascade-table
        v-if="headerPortalTarget !== null"
        :header-portal-target="headerPortalTarget"
        :header-width="tableWidth"
        :default-filter="defaultFilter"
        :selected-goal-ids="selectedGoalIds"
        :disabled-condition="disabledCondition"
        :on-item-click="selectGoal"
        show-checkbox
        :force-expand-mode="EXPAND_ALL_MODE"
        :expand-local-storage-key-maker="expandLocalStorageKeyMaker"
        show-errors
        @data-loaded="handleDataLoaded"
      />
    </div>
    <m-content
      padding-small
      class="_footer"
    >
      <div class="_left">
        <m-checkbox
          v-model:value="closePlanning"
          class="_checkbox"
          :label="$t('planningDetails.closePlanning')"
        />
        <m-btn
          class="_btn"
          color="primary"
          :loading="publishLoading"
          :disabled="selectedGoalIds.length === 0"
          @click="publish"
        >
          {{ $t('planningDetails.publishSelected', {amount: selectedGoalIds.length}) }}
        </m-btn>
      </div>
    </m-content>
  </div>
  <after-publish-goals
    v-else
    :planning="planning"
    :selected-goal-ids="selectedGoalIds"
  />
</template>

<script>
import AfterPublishGoals from '@/components/goal/plannings/AfterPublishGoals.vue';
import useGoalCycle from '@/composables/goal-cycle/goal-cycle';
import useGoalProperty from '@/composables/property/goal-property';
import useInMemoryViews from '@/composables/saved-views/in-memory-views';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import usePlannings from '@/composables/planning/plannings';
import usePublishGoals from '@/composables/goal/publish-goals';
import useViewCascadeExpandKeyMaker from '@/composables/local-storage/view-cascade-expand-key-maker';
import { EXPAND_ALL_MODE } from '@/composables/goal/cascade/expand';
import { VIEWS_SERVICE } from '@/lib/constants';
import { computed, provide } from 'vue';
import { createPropsList } from '@/lib/props';
import { directProperties } from '@/lib/goal/properties';
import { planningStatus } from 'shared/constants.json';
import { useI18n } from 'vue-i18n';

export default {
  name: 'PublishGoals',
  props: {
    currentView: {
      type: Object,
      required: true,
    },
    tableWidth: {
      type: Number,
      required: true,
    },
    planningId: {
      type: Number,
      required: true,
    },
    defaultFilter: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { goalCycles } = useGoalCycle();
    const { userLang } = useLoggedInUser();
    const { t } = useI18n();
    const { properties: goalProperties } = useGoalProperty();

    const defaultProps = computed(() => createPropsList({
      properties: goalProperties.value,
      directProperties: directProperties((key) => t(key), goalCycles.value),
      userLang: userLang.value,
    }));

    const defaultView = {
      isTemp: true,
      params: props.currentView.params,
    };

    const inMemoryViewsSvc = useInMemoryViews({
      defaultProps,
      defaultView,
      application: 'publish_goals',
    });

    provide(VIEWS_SERVICE, inMemoryViewsSvc);

    const expandLocalStorageKeyMaker = useViewCascadeExpandKeyMaker(inMemoryViewsSvc.currentView);

    const { selectSingle, updatePlanning } = usePlannings();
    const planning = selectSingle(props.planningId);
    const { publish, publishLoading, initialSelectedGoalIds } = usePublishGoals(props.planningId);
    return {
      publishGoals: publish,
      publishLoading,
      planning,
      updatePlanning,
      initialSelectedGoalIds,

      expandLocalStorageKeyMaker,
    };
  },
  components: { AfterPublishGoals },
  data() {
    return {
      published: false,
      closePlanning: true,
      selectedGoalIds: [],
      EXPAND_ALL_MODE,
      headerPortalTarget: null,
    };
  },
  emits: ['published'],
  methods: {
    handleDataLoaded() {
      this.selectedGoalIds = this.initialSelectedGoalIds;
    },
    disabledCondition(goal) {
      return goal.publishedAt !== null;
    },
    publish() {
      if (this.closePlanning) {
        this.updatePlanning({
          ...this.planning,
          status: planningStatus.closed,
        }).catch(() => {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
        });
      }
      this.publishGoals({ goalIds: this.selectedGoalIds }).then(() => {
        this.published = true;
        this.$emit('published');
      })
        .catch(() => {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
        });
    },
    selectGoal({ goalId }) {
      if (this.selectedGoalIds.includes(goalId)) {
        this.selectedGoalIds = this.selectedGoalIds.filter((id) => id !== goalId);
        return;
      }
      this.selectedGoalIds.push(goalId);
    },
  },
  created() {
    this.selectedGoalIds = this.initialSelectedGoalIds;
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  .publish-goals {
    ._table {
      height: 60vh;
      overflow: auto;
    }

    ._footer {
      display: flex;

      ._left {
        display: flex;
        align-items: center;
        margin-left: auto;

        ._checkbox {
          margin: 0 .8rem;
        }

        ._btn {
          margin-left: .8rem;
        }
      }
    }
  }
</style>
