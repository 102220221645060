/* eslint-disable max-classes-per-file */
import Node from '@/tiptap/tiptap/Utils/Node';
import SuggestionsPlugin from '@/tiptap/extensions/plugins/Suggestions';
import { Fragment } from 'prosemirror-model';
import { Plugin } from 'prosemirror-state';
import { chainCommands, exitCode, setBlockType } from 'prosemirror-commands';
import { liftListItem, sinkListItem, splitListItem } from 'prosemirror-schema-list';
import {
  nodeInputRule,
  replaceText,
  splitToDefaultListItem,
  toggleBlockType,
  toggleList,
  toggleWrap,
} from '@/tiptap/commands/commands';
import { textblockTypeInputRule, wrappingInputRule } from 'prosemirror-inputrules';

export default class HardBreak extends Node {
  get name() {
    return 'hard_break';
  }

  get schema() {
    return {
      inline: true,
      group: 'inline',
      selectable: false,
      parseDOM: [
        { tag: 'br' },
      ],
      toDOM: () => ['br'],
    };
  }

  commands({ type }) {
    return () => chainCommands(exitCode, (state, dispatch) => {
      dispatch(state.tr.replaceSelectionWith(type.create()).scrollIntoView());
      return true;
    });
  }

  keys({ type }) {
    const command = chainCommands(exitCode, (state, dispatch) => {
      dispatch(state.tr.replaceSelectionWith(type.create()).scrollIntoView());
      return true;
    });
    return {
      'Mod-Enter': command,
      'Shift-Enter': command,
    };
  }
}

export class Blockquote extends Node {
  get name() {
    return 'blockquote';
  }

  get schema() {
    return {
      content: 'block*',
      group: 'block',
      defining: true,
      draggable: false,
      parseDOM: [
        { tag: 'blockquote' },
      ],
      toDOM: () => ['blockquote', 0],
    };
  }

  commands({ type }) {
    return () => toggleWrap(type);
  }

  keys({ type }) {
    return { 'Ctrl->': toggleWrap(type) };
  }

  inputRules({ type }) {
    return [
      wrappingInputRule(/^\s*>\s$/, type),
    ];
  }
}

export class BulletList extends Node {
  get name() {
    return 'bullet_list';
  }

  get schema() {
    return {
      content: 'list_item+',
      group: 'block',
      parseDOM: [
        { tag: 'ul' },
      ],
      toDOM: () => ['ul', 0],
    };
  }

  commands({ type, schema }) {
    return () => toggleList(type, schema.nodes.list_item);
  }

  keys({ type, schema }) {
    return { 'Shift-Ctrl-8': toggleList(type, schema.nodes.list_item) };
  }

  inputRules({ type }) {
    return [
      wrappingInputRule(/^\s*([-+*])\s$/, type),
    ];
  }
}

export class CodeBlock extends Node {
  get name() {
    return 'code_block';
  }

  get schema() {
    return {
      content: 'text*',
      marks: '',
      group: 'block',
      code: true,
      defining: true,
      draggable: false,
      parseDOM: [
        { tag: 'pre', preserveWhitespace: 'full' },
      ],
      toDOM: () => ['pre', ['code', 0]],
    };
  }

  commands({ type, schema }) {
    return () => toggleBlockType(type, schema.nodes.paragraph);
  }

  keys({ type }) {
    return { 'Shift-Ctrl-\\': setBlockType(type) };
  }

  inputRules({ type }) {
    return [
      textblockTypeInputRule(/^```$/, type),
    ];
  }
}

export class Heading extends Node {
  get name() {
    return 'heading';
  }

  get defaultOptions() {
    return { levels: [1, 2, 3, 4, 5, 6] };
  }

  get schema() {
    return {
      attrs: { level: { default: 1 } },
      content: 'inline*',
      group: 'block',
      defining: true,
      draggable: false,
      parseDOM: this.options.levels
        .map((level) => ({
          tag: `h${level}`,
          attrs: { level },
        })),
      toDOM: (node) => [`h${node.attrs.level}`, 0],
    };
  }

  commands({ type, schema }) {
    return (attrs) => toggleBlockType(type, schema.nodes.paragraph, attrs);
  }

  keys({ type }) {
    return this.options.levels.reduce((items, level) => ({
      ...items,
      ...{ [`Shift-Ctrl-${level}`]: setBlockType(type, { level }) },
    }), {});
  }

  inputRules({ type }) {
    return this.options.levels.map((level) => textblockTypeInputRule(
      new RegExp(`^(#{1,${level}})\\s$`),
      type,
      () => ({ level }),
    ));
  }
}

/**
 * Matches following attributes in Markdown-typed image: [, alt, src, title]
 *
 * Example:
 * ![Lorem](image.jpg) -> [, "Lorem", "image.jpg"]
 * ![](image.jpg "Ipsum") -> [, "", "image.jpg", "Ipsum"]
 * ![Lorem](image.jpg "Ipsum") -> [, "Lorem", "image.jpg", "Ipsum"]
 */
const IMAGE_INPUT_REGEX = /!\[(.+|:?)]\((\S+)(?:(?:\s+)["'](\S+)["'])?\)/;

export class Image extends Node {
  get name() {
    return 'image';
  }

  get schema() {
    return {
      inline: true,
      attrs: {
        src: {},
        alt: { default: null },
        title: { default: null },
      },
      group: 'inline',
      draggable: true,
      parseDOM: [
        {
          tag: 'img[src]',
          getAttrs: (dom) => ({
            src: dom.getAttribute('src'),
            title: dom.getAttribute('title'),
            alt: dom.getAttribute('alt'),
          }),
        },
      ],
      toDOM: (node) => ['img', node.attrs],
    };
  }

  commands({ type }) {
    return (attrs) => (state, dispatch) => {
      const { selection } = state;
      const position = selection.$cursor ? selection.$cursor.pos : selection.$to.pos;
      const node = type.create(attrs);
      const transaction = state.tr.insert(position, node);
      dispatch(transaction);
    };
  }

  inputRules({ type }) {
    return [
      nodeInputRule(IMAGE_INPUT_REGEX, type, (match) => {
        const [, alt, src, title] = match;
        return {
          src,
          alt,
          title,
        };
      }),
    ];
  }

  get plugins() {
    return [
      new Plugin({
        props: {
          handleDOMEvents: {
            drop(view, event) {
              const hasFiles = event.dataTransfer
                                && event.dataTransfer.files
                                && event.dataTransfer.files.length;

              if (!hasFiles) {
                return;
              }

              const images = Array
                .from(event.dataTransfer.files)
                .filter((file) => (/image/i).test(file.type));

              if (images.length === 0) {
                return;
              }

              event.preventDefault();

              const { schema } = view.state;
              const coordinates = view.posAtCoords({ left: event.clientX, top: event.clientY });

              images.forEach((image) => {
                const reader = new FileReader();

                reader.onload = (readerEvent) => {
                  const node = schema.nodes.image.create({ src: readerEvent.target.result });
                  const transaction = view.state.tr.insert(coordinates.pos, node);
                  view.dispatch(transaction);
                };
                reader.readAsDataURL(image);
              });
            },
          },
        },
      }),
    ];
  }
}

export class ListItem extends Node {
  get name() {
    return 'list_item';
  }

  get schema() {
    return {
      content: 'paragraph block*',
      defining: true,
      draggable: false,
      parseDOM: [
        { tag: 'li' },
      ],
      toDOM: () => ['li', 0],
    };
  }

  keys({ type }) {
    return {
      Enter: splitListItem(type),
      Tab: sinkListItem(type),
      'Shift-Tab': liftListItem(type),
    };
  }
}

export class Mention extends Node {
  get name() {
    return 'mention';
  }

  get defaultOptions() {
    return {
      matcher: {
        char: '@',
        allowSpaces: false,
        startOfLine: false,
      },
      mentionClass: 'mention',
      suggestionClass: 'mention-suggestion',
    };
  }

  getLabel(dom) {
    return dom.innerText.split(this.options.matcher.char).join('');
  }

  createFragment(schema, label) {
    return Fragment.fromJSON(schema, [{ type: 'text', text: `${this.options.matcher.char}${label}` }]);
  }

  insertMention(range, attrs, schema) {
    const nodeType = schema.nodes[this.name];
    const nodeFragment = this.createFragment(schema, attrs.label);
    return replaceText(range, nodeType, attrs, nodeFragment);
  }

  get schema() {
    return {
      attrs: {
        id: {},
        label: {},
      },
      group: 'inline',
      inline: true,
      content: 'text*',
      selectable: false,
      atom: true,
      toDOM: (node) => [
        'span',
        {
          class: this.options.mentionClass,
          'data-mention-id': node.attrs.id,
        },
        `${this.options.matcher.char}${node.attrs.label}`,
      ],
      parseDOM: [
        {
          tag: 'span[data-mention-id]',
          getAttrs: (dom) => {
            const id = parseInt(dom.getAttribute('data-mention-id'), 10);
            const label = this.getLabel(dom);
            return { id, label };
          },
          getContent: (dom, schema) => {
            const label = this.getLabel(dom);
            return this.createFragment(schema, label);
          },
        },
      ],
    };
  }

  commands({ schema }) {
    return (attrs) => this.insertMention(null, attrs, schema);
  }

  get plugins() {
    return [
      SuggestionsPlugin({
        command: ({ range, attrs, schema }) => this.insertMention(range, attrs, schema),
        appendText: ' ',
        matcher: this.options.matcher,
        items: this.options.items,
        onEnter: this.options.onEnter,
        onChange: this.options.onChange,
        onExit: this.options.onExit,
        onKeyDown: this.options.onKeyDown,
        onFilter: this.options.onFilter,
        suggestionClass: this.options.suggestionClass,
      }),
    ];
  }
}

export class OrderedList extends Node {
  get name() {
    return 'ordered_list';
  }

  get schema() {
    return {
      attrs: { order: { default: 1 } },
      content: 'list_item+',
      group: 'block',
      parseDOM: [
        {
          tag: 'ol',
          getAttrs: (dom) => ({ order: dom.hasAttribute('start') ? +dom.getAttribute('start') : 1 }),
        },
      ],
      toDOM: (node) => (node.attrs.order === 1 ? ['ol', 0] : ['ol', { start: node.attrs.order }, 0]),
    };
  }

  commands({ type, schema }) {
    return () => toggleList(type, schema.nodes.list_item);
  }

  keys({ type, schema }) {
    return { 'Shift-Ctrl-9': toggleList(type, schema.nodes.list_item) };
  }

  inputRules({ type }) {
    return [
      wrappingInputRule(
        /^(\d+)\.\s$/,
        type,
        (match) => ({ order: +match[1] }),
        (match, node) => node.childCount + node.attrs.order === +match[1],
      ),
    ];
  }
}

export class TodoItem extends Node {
  get name() {
    return 'todo_item';
  }

  get defaultOptions() {
    return { nested: false };
  }

  get view() {
    return {
      props: ['node', 'updateAttrs', 'view'],
      methods: {
        onChange() {
          this.updateAttrs({ done: !this.node.attrs.done });
        },
      },
      template: `
              <li
                  :data-type="node.type.name"
                  :data-done="node.attrs.done.toString()"
                  data-drag-handle
              >
              <span
                  class="todo-checkbox"
                  contenteditable="false"
                  @click="onChange"
              ></span>
              <div
                  class="todo-content"
                  ref="content"
                  :contenteditable="view.editable.toString()"
              ></div>
              </li>
            `,
    };
  }

  get schema() {
    return {
      attrs: { done: { default: false } },
      draggable: true,
      content: this.options.nested ? '(paragraph|todo_list)+' : 'paragraph+',
      toDOM: (node) => {
        const { done } = node.attrs;

        return [
          'li',
          {
            'data-type': this.name,
            'data-done': done.toString(),
          },
          ['span', { class: 'todo-checkbox', contenteditable: 'false' }],
          ['div', { class: 'todo-content' }, 0],
        ];
      },
      parseDOM: [{
        priority: 51,
        tag: `[data-type="${this.name}"]`,
        getAttrs: (dom) => ({ done: dom.getAttribute('data-done') === 'true' }),
      }],
    };
  }

  keys({ type }) {
    return {
      Enter: splitToDefaultListItem(type),
      Tab: this.options.nested ? sinkListItem(type) : () => {
      },
      'Shift-Tab': liftListItem(type),
    };
  }
}

export class TodoList extends Node {
  get name() {
    return 'todo_list';
  }

  get schema() {
    return {
      group: 'block',
      content: 'todo_item+',
      toDOM: () => ['ul', { 'data-type': this.name }, 0],
      parseDOM: [{
        priority: 51,
        tag: `[data-type="${this.name}"]`,
      }],
    };
  }

  commands({ type, schema }) {
    return () => toggleList(type, schema.nodes.todo_item);
  }

  inputRules({ type }) {
    return [
      wrappingInputRule(/^\s*(\[ \])\s$/, type),
    ];
  }
}
