<template>
  <span
    contenteditable="false"
    @mouseover="showTooltip = true"
    @mouseleave="showTooltip = false"
  >
    <router-link
      :class="['goal-mention-component', notFound ? '-not-clickable' : '']"
      contenteditable="false"
      :data-goal-id="id"
      :data-goal-title="title"
      :data-goal-icon="icon"
      :to="`/goals/${id}`"
      :disabled="notFound"
    >
      <span
        class="_icon-wrapper"
        contenteditable="false"
      >
        <m-icon
          size="calc(1em - 1px)"
          type="compass"
          class="_icon"
          contenteditable="false"
        />
      </span>
      <span
        class="_title"
        contenteditable="false"
      >
        <div
          v-if="notFound"
          v-show="showTooltip"
          class="_tooltip"
          contenteditable="false"
        >
          {{ $t('goalMentionComponent.hint', { title: goalSettings.featureNameSingular }) }}
        </div>
        <span
          v-if="!isEmptyIcon(iconDisplay)"
          contenteditable="false"
          class="_emoji"
        >
          <m-icon-display
            class="_emoji-inner"
            contenteditable="false"
            :icon="iconDisplay"
          /></span>{{ title === '' ? $t('general.untitled') : title }}
      </span>
      <span style="white-space: nowrap;" />
    </router-link>
  </span>
</template>

<script>
import useGoalSettings from '@/composables/logged-in-user-account/goal-settings';
import { UID } from 'shared/api/query/constants';
import { buildIconFromEntity, isEmptyIcon } from 'shared/lib/icon';
import { dogma } from '@/api';
import { goal as goalConfig } from 'shared/api/query/configs.json';

export default {
  name: 'GoalMentionComponent',
  props: {
    node: {
      type: Object,
      required: true,
    },
    updateAttrs: {
      type: Function,
      required: true,
    },
    view: {
      type: Object,
      required: true,
    },
    getPos: {
      type: Function,
      required: true,
    },
    editor: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { goalSettings } = useGoalSettings();
    return { goalSettings };
  },
  data() {
    return {
      notFound: false,
      showTooltip: false,
    };
  },
  computed: {
    iconDisplay() {
      return buildIconFromEntity({ icon: this.icon });
    },
    id: {
      get() {
        if (typeof this.node.attrs.id === 'string') {
          return parseInt(this.node.attrs.id, 10);
        }
        return this.node.attrs.id;
      },
    },
    icon: {
      get() {
        return this.node.attrs.icon;
      },
      set(icon) {
        this.updateAttrs({ icon });
      },
    },
    title: {
      get() {
        return this.node.attrs.title;
      },
      set(title) {
        this.updateAttrs({ title });
      },
    },
  },
  methods: { isEmptyIcon },
  created() {
    dogma.selectSingle(this.id, goalConfig.model, [{ attr: UID }, { attr: goalConfig.edges.title, default: '' }, { attr: goalConfig.edges.icon, default: '' }]).then((response) => {
      if (response.status !== 200) {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
        return;
      }

      if (response.data === null) {
        this.notFound = true;
        return;
      }

      if (this.title !== response.data.title) {
        this.title = response.data.title;
      }

      if (this.icon !== response.data.icon) {
        this.icon = response.data.icon;
      }
    });
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .goal-mention-component {
    position: relative;
    padding-left: 1.2em;
    color: inherit;
    text-decoration: inherit;
    vertical-align: top;
    cursor: pointer;
    border-radius: $border-radius-xs;
    transition: all 100ms ease-in 0s;

    &:hover {
      background: $hover-color;
      box-shadow: 0 0 0 4px $hover-color;
    }

    ._icon-wrapper {
      position: absolute;
      top: -.15em;
      left: .1em;
    }

    ._icon {
      display: inline-block;
      white-space: nowrap;
    }

    ._title {
      position: relative;
      font-weight: $font-weight-medium;
      border-bottom: .06em solid $font-color-primary;

      ._tooltip {
        position: absolute;
        top: -5rem;
        left: 50%;
        width: 20rem;
        padding: .4rem;
        font-size: $font-size-2;
        color: white;
        white-space: initial;
        background-color: map_get($grey, 'darken-4');
        border-radius: $default-border-radius;
        transform: translateX(-50%);
      }

      ._emoji {
        display: inline-block;
        margin-right: .4rem;
        font-size: 1em;
        vertical-align: baseline;
      }
    }

    &.-not-clickable {
      color: $font-color-tertiary;

      ._title {
        border-color: $font-color-tertiary;
      }
    }
  }
</style>
