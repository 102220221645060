<template>
  <div class="detail-header">
    <page-top-bar :open-in-modal="modal">
      <template #left>
        <div
          v-if="isLoggedIn && modal"
          class="_btn"
        >
          <m-btn
            hide-border
            small
            icon="open-as-page"
            :icon-color="$colors.grey.base"
            @click="detailsPage"
          >
            {{ $t('pageHeader.openAsPage') }}
          </m-btn>
        </div>
        <goal-breadcrumbs
          :goal="goal"
          :modal="modal"
          :slice-amount="goal.parents.length > 1 ? 1 : 2"
          :read-only="readOnly"
        />
      </template>
      <template #actions>
        <div
          v-if="isLoggedIn"
          class="_actions"
        >
          <share-dropdown
            v-if="!$store.state.breakpoint.smAndDown"
            :access-policy="goal.accessPolicy"
            :creator="selectSingleUser(goal.creator?.uid)"
            :icon="isPublic ? 'global': 'lock'"
            :button-text="$t('goal.detailHeader.share')"
            :access-types="[accessPolicyType.read, accessPolicyType.comment, accessPolicyType.write, accessPolicyType.full]"
            :page-link="pageLink"
            :disabled="goal.accessRight !== accessPolicyType.full"
            :on-update="updateAccessPolicy"
          />
          <favorite-button
            v-if="!$store.state.breakpoint.smAndDown"
            :title-suggestion="goal.title"
            :icon-suggestion="buildIconFromEntity(goal)"
            :entity-id="goal.uid"
            :route-name="GOAL_DETAILS"
            :route-params="{goalId: goal.uid}"
            :route-query="{}"
          />
          <m-dropdown
            v-model:value="furtherActions"
            :title="$t('general.actions')"
            placement="bottomRight"
          >
            <m-btn
              data-cy="goal-header-more-btn"
              hide-border
              class="_action"
              small
              icon="ellipsis"
              fab
            />
            <template #overlay>
              <m-card
                list
                no-padding
                class="_overlay"
              >
                <share-dropdown
                  v-if="$store.state.breakpoint.smAndDown"
                  :access-policy="goal.accessPolicy"
                  :creator="selectSingleUser(goal.creator?.uid)"
                  :icon="isPublic ? 'global': 'lock'"
                  :button-text="$t('goal.detailHeader.share')"
                  :access-types="[accessPolicyType.read, accessPolicyType.comment, accessPolicyType.write, accessPolicyType.full]"
                  :page-link="pageLink"
                  :disabled="goal.accessRight !== accessPolicyType.full"
                  :on-update="updateAccessPolicy"
                />
                <favorite-button
                  v-if="$store.state.breakpoint.smAndDown"
                  :title-suggestion="goal.title"
                  :icon-suggestion="buildIconFromEntity(goal)"
                  :entity-id="goal.uid"
                  :route-name="GOAL_DETAILS"
                  :route-params="{goalId: goal.uid}"
                  :route-query="{}"
                />
                <m-card-item
                  v-for="item in menuItems"
                  :key="item.name"
                  :icon="item.icon"
                  :emoji="item.emoji"
                  :tooltip="item.tooltip"
                  :tooltip-placement="item.tooltipPlacement"
                  :loading="item.loading"
                  :disabled="item.disabled"
                  @click="item.onClick"
                >
                  <m-switch
                    v-if="item.isSwitch"
                    :value="item.checked"
                    :disabled="item.disabled"
                    small
                    :label="item.name"
                    class="_switch"
                  />
                  <div
                    v-else
                  >
                    {{ item.name }}
                  </div>
                </m-card-item>
              </m-card>
              <m-card
                v-if="showMenu === 'add-to-grid-editor'"
                padding-xs
              >
                <add-to-grid-editor
                  :items="[goal]"
                  @close="hideMenu"
                />
              </m-card>
            </template>
          </m-dropdown>
        </div>
      </template>
    </page-top-bar>
  </div>
</template>

<script>
import AddToGridEditor from '@/components/custom-grid/AddToGridEditor.vue';
import FavoriteButton from '@/components/favorite/FavoriteButton.vue';
import GoalBreadcrumbs from '@/components/breadcrumbs/GoalBreadcrumbs.vue';
import PageTopBar from '@/components/page/PageTopBar.vue';
import ShareDropdown from '@/components/access-policy/ShareDropdown.vue';
import useAccess from '@/composables/access/access';
import useDeleteGoal from '@/composables/goal/delete-goal';
import useGoalSubscription from '@/composables/goal/goal-subscription';
import useGoals from '@/composables/goal/goals';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useStatusAutoUpdate from '@/composables/goal/status-auto-update';
import useUsers from '@/composables/user/users';
import { GOAL_DETAILS } from '@/route-names';
import { accessPolicyType, goalProgressMeasurement } from 'shared/constants.json';
import { buildIconFromEntity } from 'shared/lib/icon';
import { logCatch } from '@/lib/logger/logger';
import { mapState } from 'vuex';
import { rgbaToHex } from 'shared/lib/color';

export default {
  name: 'DetailHeader',
  props: {
    goal: {
      type: Object,
      required: true,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    modal: {
      type: Boolean,
      default: false,
    },
    routeAfterDelete: {
      type: Object,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['deleted', 'go-to-details'],
  components: { AddToGridEditor, GoalBreadcrumbs, PageTopBar, FavoriteButton, ShareDropdown },
  setup() {
    const goalSubscriptionSvc = useGoalSubscription();
    const { deleteLoading, showDeleteGoalModal } = useDeleteGoal();
    const { accountHasFeature } = useAccess();
    const { updateSingle, deleteSingle } = useGoals();
    const { loggedInUser } = useLoggedInUser();
    const { automaticStatusUpdateLoading, toggleStatusUpdate } = useStatusAutoUpdate();
    const { selectSingle: selectSingleUser } = useUsers();
    return {
      accountHasFeature,
      updateSingle,
      deleteSingle,
      goalSubscriptionSvc,
      subscriptionLoading: goalSubscriptionSvc.createLoading,
      loggedInUser,
      automaticStatusUpdateLoading,
      toggleStatusUpdate,
      deleteLoading,
      showDeleteGoalModal,
      selectSingleUser,
    };
  },
  data() {
    return {
      rgbaToHex,
      accessPolicyType,
      showVisibilityModal: false,
      pageLink: `${window.location.origin}/#/goals/${this.goal.uid}`,
      shareDropdownVisible: false,
      furtherActions: false,
      showMenu: '',
      GOAL_DETAILS,
    };
  },
  computed: {
    ...mapState({ isLoggedIn: (state) => state.isLoggedIn }),
    isPublic() {
      return this.goal.accessPolicy.accountAccess !== accessPolicyType.disabled;
    },
    menuItems() {
      const res = [];
      if (this.goal.progressMeasurement === goalProgressMeasurement.alignedItems) {
        res.push(
          {
            name: this.$t('goal.detailHeader.disableStatusAutoUpdate'),
            icon: 'sync',
            tooltip: this.$t('goal.detailHeader.statusUpdateTooltip'),
            tooltipPlacement: 'left',
            loading: this.automaticStatusUpdateLoading,
            isSwitch: true,
            disabled: !this.canEdit,
            checked: !this.goal.disableStatusAutoUpdate,
            onClick: () => this.toggleStatusUpdate(this.goal),
          },
        );
      }

      res.push({
        name: this.$t('goal.detailHeader.notification'),
        icon: 'notification',
        loading: this.subscriptionLoading,
        isSwitch: true,
        checked: this.notificationsEnabled,
        onClick: this.triggerSubscription,
      });

      res.push({
        name: this.$t('goal.detailHeader.addToDashboard'),
        emoji: 'ReconciliationOutlined',
        onClick: this.triggerAddToDashboard,
      });

      if (this.canEdit) {
        res.push({
          name: this.$t('general.delete'),
          icon: 'delete',
          loading: this.deleteLoading,
          onClick: () => this.showDeleteGoalModal({ goals: [this.goal], redirect: this.routeAfterDelete })
            .then(() => {
              this.closeModal();
            }).catch(() => {}),
        });
      }

      return res;
    },
    notificationsEnabled() {
      const sub = this.goal.subscriptions.filter((s) => s.user.uid === this.loggedInUser.uid);
      if (sub.length === 0) {
        return false;
      }

      return sub[0].active;
    },
    canEdit() {
      if (this.readOnly) {
        return false;
      }

      return [accessPolicyType.full, accessPolicyType.write].includes(this.goal.accessRight);
    },
  },
  methods: {
    buildIconFromEntity,
    hideMenu() {
      this.showMenu = '';
      this.furtherActions = false;
    },
    triggerAddToDashboard() {
      this.showMenu = 'add-to-grid-editor';
    },
    triggerSubscription() {
      const mySubscription = this.goal.subscriptions.filter((sub) => sub.user.uid === this.loggedInUser.uid);
      if (mySubscription.length === 0) {
        this.createSubscription({
          active: true,
          user: { uid: this.loggedInUser.uid },
        });
        return;
      }

      this.updateSubscription({ uid: mySubscription[0].uid, active: !mySubscription[0].active });
    },
    closeModal() {
      this.$emit('deleted');
    },
    detailsPage() {
      this.$emit('go-to-details', {
        to: {
          name: GOAL_DETAILS,
          params: { goalId: this.goal.uid },
        },
      });
    },
    updateAccessPolicy(val) {
      const entity = {
        ...this.goal,
        accessPolicy: val,
      };
      this.updateSingle(entity, { optimistic: false }).then((data) => {
        if (data === undefined) {
          this.deleteSingle(entity.uid, { commitToRemote: false });
          this.$router.push('/goals');
        }
      });
    },
    createSubscription(entity) {
      this.goalSubscriptionSvc.createSubscription({ ...entity, goal: { uid: this.goal.uid } }).catch(logCatch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('general.error') });
      }));
    },
    updateSubscription(entity) {
      this.goalSubscriptionSvc.updateSubscription(entity).catch(logCatch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('general.error') });
      }));
    },
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  .detail-header {
    ._btn {
      padding-right: .4rem;
      border-right: 1px solid $border-color;
    }
  }

  ._overlay {
    min-width: 30rem;

    ._switch {
      width: 100%;
      margin: 0;
    }
  }

  ._actions {
    display: flex;
    align-items: center;
  }
</style>
