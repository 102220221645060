<template>
  <div :class="['detail-item', inline ? '-inline' : '']">
    <div class="_label">
      {{ label }}
    </div>
    <m-tooltip
      :disabled="!(showError && errorText !== '')"
    >
      <div
        class="_value-container"
      >
        <m-icon
          v-if="showError"
          :color="$colors.red.base"
          class="_icon"
          type="warning"
        />
        <div class="_value">
          <template v-if="!$slots.value">
            {{ value }}
          </template>
          <slot name="value" />
        </div>
      </div>
      <template #title>
        {{ errorText }}
      </template>
    </m-tooltip>
  </div>
</template>

<script>
export default {
  name: 'DetailItem',
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    label: {
      type: String,
      required: true,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    showError: {
      type: Boolean,
      default: false,
    },
    errorText: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .detail-item {
    ._value {
      font-weight: $font-weight-medium;
      color: $font-color-primary;
    }

    ._label {
      color: $font-color-secondary;
    }

    &.-inline {
      display: flex;
      justify-content: space-between;

      ._value-container {
        display: flex;
        align-items: center;
        margin-left: auto;

        ._icon {
          margin-right: 1.2rem;
        }
      }
    }
  }
</style>
