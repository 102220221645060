import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import { DEFAULT_LANGUAGE, isValidLanguageCode } from '@/lib/language';
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default function useFormTemplate() {
  const store = useStore();
  const route = useRoute();
  const router = useRouter();
  const { userLang } = useLoggedInUser();
  const formTemplate = computed(() => store.state.formTemplate);
  const orderItems = computed(() => store.state.formTemplate.orderItems);

  const lang = computed(() => {
    if (typeof route.query.lang !== 'undefined' && isValidLanguageCode(route.query.lang)) {
      return route.query.lang;
    }
    if (languages.value.includes(userLang.value)) {
      return userLang.value;
    }
    if (languages.value.includes(DEFAULT_LANGUAGE)) {
      return DEFAULT_LANGUAGE;
    }
    return languages.value[0];
  });
  const formTemplateId = computed(() => parseInt(route.params.formTemplateId, 10));
  const formTemplateAvailable = computed(() => formTemplate.value !== null && formTemplateId.value === formTemplate.value.uid);
  const languages = computed(() => {
    if (formTemplate.value === null || (formTemplate.value.orderItems.length === 0 && formTemplate.value.welcomeScreen === null)) {
      return [userLang.value];
    }
    if (formTemplate.value.orderItems.length > 0) {
      return Object.keys(formTemplate.value.orderItems[0].field.title);
    }
    return Object.keys(formTemplate.value.welcomeScreen.title);
  });
  const changeLanguage = ({ lang }) => {
    router.push({ ...route, query: { lang } });
  };

  return {
    formTemplateId,
    formTemplateAvailable,
    formTemplate,
    changeLanguage,
    lang,
    orderItems,
  };
}
