export const eventer = () => {
  const eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
  return window[eventMethod];
};

export const eventRemover = () => {
  const method = window.removeEventListener ? 'removeEventListener' : 'detachEvent';
  return window[method];
};

export const messageEvent = () => {
  const eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
  return eventMethod === 'attachEvent' ? 'onmessage' : 'message';
};

export const messageCloser = (win) => new Promise((resolve) => {
  let intervalId = 0;

  const poller = () => {
    if (win.closed) {
      clearInterval(intervalId);
      resolve();
    }
  };

  intervalId = setInterval(poller, 1000);
});
