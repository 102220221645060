<template>
  <m-dropdown
    :class="['color-picker', clickable ? '-clickable' : '']"
    :title="$t('colorPicker.title')"
    :disabled="readOnly || disabled"
    close-on-click
  >
    <m-btn
      class="_btn"
      :disabled="disabled"
      fab
    >
      <color-item :color="value" />
    </m-btn>
    <template #overlay>
      <m-card
        list
        no-padding
        :style="{ minWidth: '20rem' }"
      >
        <color-list
          :items="items"
          :value="value"
          @select="select"
        />
      </m-card>
    </template>
  </m-dropdown>
</template>

<script>
import ColorItem from '@/components/ColorItem.vue';
import ColorList from '@/components/ColorList.vue';
import { camelCase } from 'lodash-es';
import { optionColor } from 'shared/constants.json';

export default {
  name: 'ColorPicker',
  props: {
    value: {
      type: String,
      default: '',
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: { ColorList, ColorItem },
  emits: ['input', 'update:value'],
  computed: {
    clickable() {
      return !this.readOnly && !this.disabled;
    },
    title() {
      return this.$t(`colorPicker.${camelCase(this.value)}`);
    },
    items() {
      return optionColor.all.map((c) => ({
        text: this.$t(`colorPicker.${camelCase(c)}`),
        value: c,
      }));
    },
  },
  methods: {
    select(item) {
      if (!this.clickable) {
        return;
      }
      this.$emit('input', item.value);
      this.$emit('update:value', item.value);
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .color-picker {
    ._btn {
      display: flex;
      align-items: center;
      justify-content: center;
      pointer-events: none;
      cursor: default;
    }

    &.-clickable {
      ._btn {
        pointer-events: auto;
        cursor: pointer;

        &:hover {
          background-color: $hover-color;
        }
      }
    }
  }
</style>
