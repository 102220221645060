import useBulkMutate from '@/nebula/bulk-mutate';
import useProperties from '@/composables/property/property';

import usePropertyOptions from '@/composables/property-option/property-option';
import usePropertyRepo from '@/composables/property/repo';
import { computed, ref } from 'vue';
import { getColoredIcon } from 'shared/lib/color-map';
import {
  propertyOption as propertyOptionConfig,
  propertySettings as propertySettingsConfig,
} from 'shared/api/query/configs.json';
import { propertyOptionEdges } from '@/api/query/nebula/property-option';
import { propertySettingsEdges } from '@/api/query/nebula/property-settings';
import { replaceAll } from 'shared/lib/object/replace';
import { useI18n } from 'vue-i18n';

export default function useGoalTypes() {
  const propertyOptionSvc = usePropertyOptions();
  const propertyRepo = usePropertyRepo();
  const bulkMutator = useBulkMutate();
  const { t } = useI18n();
  const { properties } = useProperties();
  const goalTypeProperty = computed(() => properties.value.find((p) => p.isGoalType));

  const goalTypes = computed(() => goalTypeProperty.value.options
    .map((o) => ({
      ...o,
      coloredIcon: getColoredIcon(o),
    })));

  const updateOrder = (order) => {
    const entity = {
      uid: goalTypeProperty.value.uid,
      propertyOptionOrder: order,
      options: goalTypes.value,
    };
    return propertyRepo.updateSingle(entity);
  };

  const updateLoading = ref(false);
  const updateEntity = (goalType) => {
    updateLoading.value = true;
    const payloads = [
      {
        alias: 'updatePropertyOption',
        model: propertyOptionConfig.model,
        nodes: [goalType],
        attributes: propertyOptionEdges,
      },
      {
        alias: 'updatePropertySettings',
        nodes: goalType.propertySettings,
        model: propertySettingsConfig.model,
        attributes: propertySettingsEdges,
      },
    ];
    return bulkMutator.bulkMutate(payloads).then(() => propertyOptionSvc.selectSingle(goalType.uid));
  };

  const _createEntities = (entities) => propertyOptionSvc.createMultiple(
    entities.map((goalType, index) => ({
      ...goalType,
      rid: index + 1,
      canBeChildOf: [{ rid: index + 1 }, ...goalTypeProperty.value.options.map((o) => ({ uid: o.uid }))],
      options: { uid: goalTypeProperty.value.uid },
    })),
  ).then((entities) => entities);

  const createLoading = ref(false);
  const createEntities = (entities) => {
    createLoading.value = true;
    return _createEntities(entities).finally(() => {
      createLoading.value = false;
    });
  };

  const duplicateLoading = ref(false);
  const duplicateEntities = (entities) => {
    duplicateLoading.value = true;
    return _createEntities(entities
      .map((item) => ({
        ...replaceAll(item, 'uid', 0, [['canBeChildOf', 'uid'], ['requiredProperties', 'uid']]),
        label: { de: `${item.label.de} - ${t('general.copy')}`, en: `${item.label.en} - ${t('general.copy')}` },
        property: item.property,
      }))).finally(() => {
      duplicateLoading.value = false;
    });
  };

  return {
    ...propertyOptionSvc,
    updateOrder,
    updateEntity,
    createEntities,
    duplicateEntities,
    duplicateLoading,
    goalTypeProperty,
    goalTypes,
  };
}
