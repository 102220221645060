<template>
  <h4
    :class="['m-form']"
  >
    <slot />
  </h4>
</template>

<script>
export default { name: 'MSubheader' };
</script>

<style lang="scss" type="text/scss">
</style>
