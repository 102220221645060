<template>
  <m-card
    class="template-item"
    :level="1"
    no-padding
    @click="$emit('edit', template)"
  >
    <div :class="['_actions', showMenu ? '-show' : '']">
      <m-dropdown
        v-model:value="showMenu"
        :title="$t('general.actions')"
      >
        <div class="_btn">
          <m-btn
            v-if="[full, write].includes(template.accessRight)"
            icon="ellipsis"
            fab
            small
            @click.stop="showMenu = true"
          />
        </div>
        <template #overlay>
          <m-card
            no-padding
            list
          >
            <m-card-item
              icon="edit"
              @click="$emit('edit', template)"
            >
              {{ $t('general.edit') }}
            </m-card-item>
            <m-card-item
              icon="delete"
              @click="deleteTemplate(template)"
            >
              {{ $t('general.delete') }}
            </m-card-item>
          </m-card>
        </template>
      </m-dropdown>
    </div>
    <m-content
      padding
      class="_preview"
    >
      <m-editor
        :key="template.template"
        ref="editor"
        :initial-value="template.template"
        disabled
        class="_m-editor"
        :allowed-content="allowedContent"
        default-font-size="1.2rem"
      />
    </m-content>
    <m-content
      padding-small
      class="_bottom"
    >
      <div class="_title">
        <item-title
          :title="template.title"
          :icons="[{ value: buildIconFromEntity(template) }]"
        />
        <div class="_access">
          <m-tooltip>
            <span>
              <m-icon :type="accessIcon" />
            </span>
            <template #title>
              <template v-if="access === 'private'">
                {{ $t('templateItem.privateHint') }}
              </template>
              <template v-else-if="access === 'shared'">
                {{ $t('templateItem.sharedHint') }}
              </template>
              <template v-else>
                {{ $t('templateItem.publicHint') }}
              </template>
            </template>
          </m-tooltip>
        </div>
      </div>
      <div class="_props">
        <user-display
          :user="template.creator"
          xs
        />
      </div>
    </m-content>
  </m-card>
</template>

<script>
import ItemTitle from '@/components/ItemTitle.vue';
import MEditor from '@/components/editor/MEditor.vue';
import UserDisplay from 'shared/components/UserDisplay.vue';
import useAccess from '@/composables/access/access';
import useUpdateTemplates from '@/composables/update-templates/update-templates';
import { accessPolicyType, editorNodeType, featureFlag } from 'shared/constants.json';
import { buildIconFromEntity } from 'shared/lib/icon';

export default {
  name: 'TemplateItem',
  props: {
    template: {
      type: Object,
      required: true,
    },
  },
  emits: ['edit', 'delete-template'],
  components: { UserDisplay, MEditor, ItemTitle },
  setup() {
    const allowedContent = [
      editorNodeType.codeBlock,
      editorNodeType.blockquote,
      editorNodeType.bulletList,
      editorNodeType.orderedList,
      editorNodeType.mention,
      editorNodeType.goalMention,
    ];

    const { accountHasFeature } = useAccess();
    if (accountHasFeature([featureFlag.fileUpload])) {
      allowedContent.push(editorNodeType.image, editorNodeType.file);
    }

    const updateTemplatesSvc = useUpdateTemplates();
    return { deleteUpdateTemplate: updateTemplatesSvc.deleteUpdateTemplate, allowedContent };
  },
  data() {
    return {
      write: accessPolicyType.write,
      full: accessPolicyType.full,
      showMenu: false,
      showModal: false,
    };
  },
  computed: {
    accessIcon() {
      switch (this.access) {
        case 'private':
          return 'lock';
        case 'shared':
          return 'share-alt';
        case 'public':
          return 'global';
        default:
          return '';
      }
    },
    access() {
      if ([accessPolicyType.read, accessPolicyType.write, accessPolicyType.full].includes(this.template.accessPolicy.accountAccess)) {
        return 'public';
      }
      if (this.template.accessPolicy.scopes.length > 0) {
        return 'shared';
      }
      return 'private';
    },
    canEdit() {
      return [accessPolicyType.full, accessPolicyType.write].includes(this.template.accessRight);
    },
  },
  methods: {
    buildIconFromEntity,
    deleteTemplate(template) {
      const deleteMethod = () => this.deleteUpdateTemplate(template).then(() => {
        this.$emit('delete-template', template);
      }).catch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      });

      this.$confirm({
        title: this.$t('createUpdateBtn.deletePrompt'),
        okText: this.$t('general.yesDelete'),
        okType: 'danger',
        cancelText: this.$t('general.cancel'),
        maskClosable: true,
        onOk() {
          deleteMethod();
        },
      });
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .template-item {
    position: relative;
    cursor: pointer;

    ._actions {
      position: absolute;
      top: 1.6rem;
      right: 1.6rem;
      z-index: 1;
      display: none;

      ._btn {
        background-color: white;
        border-radius: $default-border-radius;
      }

      &.-show {
        display: flex;
      }
    }

    &:hover {
      background-color: $hover-color-light;

      ._actions {
        display: flex;
      }
    }

    ._preview {
      height: 18.5rem;
      overflow: hidden;
      background-color: map_get($grey, 'lighten-7');
      border-top-left-radius: $default-border-radius;
      border-top-right-radius: $default-border-radius;
    }

    ._bottom {
      border-top: 1px solid $border-color;

      ._title {
        display: flex;
        align-items: center;
        font-size: $font-size-4;
        font-weight: $font-weight-medium;

        ._name {
          max-width: 80%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        ._access {
          margin-left: 1rem;
        }
      }

      ._props {
        margin-top: 1rem;
      }
    }
  }
</style>
