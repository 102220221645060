<template>
  <div class="profile-about">
    <property-values
      :properties="userProperties"
      :value="user.values"
      read-only
    />
  </div>
</template>

<script>
import PropertyValues from '@/components/PropertyValues.vue';
import useProperties from '@/composables/property/property';

export default {
  name: 'ProfileAbout',
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  components: { PropertyValues },
  setup() {
    const { userProperties } = useProperties();
    return { userProperties };
  },
};
</script>

<style scoped lang="scss" type="text/scss">

</style>
