<template>
  <m-context-menu
    ref="contextMenu"
    class="meetings-context-menu"
    @hide="reset"
  >
    <m-card
      v-if="showMenu"
      list
      no-padding
    >
      <m-card-item
        icon="delete"
        :tooltip="tooltip"
        :disabled="editableItems.length === 0"
        :loading="deleteLoading"
        @click="deleteItems"
      >
        {{ $t('general.delete') }}
        <template #right>
          <div
            v-if="cannotEditAllItems && editableItems.length > 0"

            class="_right"
          >
            <m-tooltip>
              <span>
                <m-icon
                  type="warning"
                  class="_icon"
                />
              </span>
              <template #title>
                {{ $t('meetingsContextMenu.editHint') }}
              </template>
            </m-tooltip>
          </div>
        </template>
      </m-card-item>
      <m-card-item
        icon="copy"
        :loading="duplicateLoading"
        @click="duplicateItems"
      >
        {{ $t('general.duplicate') }}
      </m-card-item>
      <edit-meeting-property-item
        :tooltip="tooltip"
        :disabled="editableItems.length === 0"
        @edit-property="editProperty"
      />
    </m-card>
    <m-card
      v-else
      padding-xs
    >
      <property-editor
        :prop="prop"
        :entities="toEdit"
        :success-message="successMessage"
        @close="hideMenu"
        @edited="$emit('prop-edited', prop)"
      />
    </m-card>
  </m-context-menu>
</template>

<script>
import EditMeetingPropertyItem from '@/components/meeting/EditMeetingPropertyItem.vue';
import PropertyEditor from '@/components/meeting/PropertyEditor.vue';
import deleteMixin from '@/mixins/delete';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import { accessPolicyType } from 'shared/constants.json';
import { mapActions, mapState } from 'vuex';
import { children as meetingChildren } from '@/api/query/meeting';
import { meeting as meetingConfig } from 'shared/api/query/configs.json';

export default {
  name: 'MeetingsContextMenu',
  props: {
    ids: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const { loggedInUserAccount } = useLoggedInUserAccount();
    const { loggedInUser } = useLoggedInUser();
    return {
      loggedInUser,
      loggedInUserAccount,
    };
  },
  emits: ['prop-edited', 'deleted', 'duplicated'],
  components: {
    PropertyEditor,
    EditMeetingPropertyItem,
  },
  data() {
    return {
      showMenu: true,
      prop: null,
      toEdit: [],
      successMessage: '',
      duplicateLoading: false,
    };
  },
  computed: {
    ...mapState({ meetings: (state) => state.meeting }),
    items() {
      return this.meetings.filter((m) => this.ids.includes(m.uid));
    },
    editableItems() {
      return this.items.filter((m) => [accessPolicyType.write, accessPolicyType.full].includes(m.accessRight));
    },
    cannotEditAllItems() {
      return this.editableItems.length !== this.items.length;
    },
    tooltip() {
      if (this.editableItems.length > 0) {
        return '';
      }

      return this.$t('meetingsContextMenu.cannotDeleteMultiple');
    },
  },
  methods: {
    ...mapActions(['createEntities']),
    duplicateSingle({ entity, model, attributes, mutation }) {
      this.duplicate({ entities: [entity], model, attributes, mutation });
    },
    duplicate({ entities, model, attributes, mutation }) {
      this.duplicateLoading = true;
      this.createEntities({
        entities,
        model,
        mutation,
        attributes,
      }).then((response) => {
        this.duplicateLoading = false;
        if (response.status !== 200) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
          return;
        }

        this.$showSnackbar({ color: 'success', message: this.$t('success.duplicated') });
        this.$emit('duplicated');
        this.onDuplicate();
      });
    },
    deleteItems() {
      this.showDeleteModal({ entities: this.editableItems, model: meetingConfig.model, mutation: 'MEETINGS_DELETED' })();
    },
    prepareToDuplicate(items) {
      return items.reduce((res, i) => {
        res.push({
          ...i,
          uid: 0,
          account: { uid: this.loggedInUserAccount.uid },
          creator: { uid: this.loggedInUser.uid },
          propertyValues: i.propertyValues.map((v) => ({
            ...v,
            uid: 0,
          })),
        });

        return res;
      }, []);
    },
    duplicateItems() {
      this.duplicate({
        entities: this.prepareToDuplicate(this.editableItems),
        model: meetingConfig.model,
        attributes: meetingChildren,
        mutation: 'MEETINGS_CREATED',
      });
    },
    hideMenu() {
      this.$refs.contextMenu.hide();
    },
    show(event) {
      this.$refs.contextMenu.show(event);
    },
    onDelete() {
      this.$emit('deleted');
      this.$refs.contextMenu.hide();
    },
    onDuplicate() {
      this.$refs.contextMenu.hide();
    },
    reset() {
      this.showMenu = true;
    },
    editProperty({ prop }) {
      this.prop = prop;
      this.toEdit = this.editableItems;
      this.showMenu = false;
      this.successMessage = this.$t('success.updated');
    },
  },
  mixins: [deleteMixin],
};
</script>

<style scoped lang="scss" type="text/scss">
</style>
