<template>
  <div
    :style="style"
    class="full-screen-spinner"
  >
    <m-spinner :color="color" />
  </div>
</template>

<script>
export default {
  name: 'FullScreenSpinner',
  props: {
    height: {
      type: Number,
      default: 0,
    },
    color: {
      type: String,
      default: '',
    },
  },
  data() {
    return { h: window.innerHeight };
  },
  computed: {
    style() {
      return { minHeight: `${this.h}px` };
    },
  },
  mounted() {
    if (this.height !== 0) {
      this.h = this.height;
      return;
    }

    const dimensions = this.$el.parentNode.getBoundingClientRect();
    this.h = Math.min(window.innerHeight, dimensions.height);
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .full-screen-spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
</style>
