<template>
  <m-card
    :class="['dashboard-card', loading ? '-loading' : '', !loading && clickable ? '-clickable' : '']"
    :level="1"
    no-padding
    @click="handleClick"
  >
    <div class="_content">
      <div class="_top">
        <slot name="header">
          <m-tooltip
            :style="{ width: '100%' }"
            :mouse-enter-delay="1"
          >
            <div class="_title">
              {{ title }}
              <m-dropdown
                v-if="info !== ''"
                :title="title"
              >
                <m-btn
                  fab
                  xs
                  hide-border
                  light
                  icon="info-circle"
                />
                <template #overlay>
                  <m-card
                    padding-xs
                    :style="{ maxWidth: '30rem' }"
                  >
                    {{ info }}
                  </m-card>
                </template>
              </m-dropdown>
            </div>
            <template #title>
              {{ title }}
            </template>
          </m-tooltip>
        </slot>
        <div
          v-if="!!$slots.topRight"
          class="_right"
        >
          <slot name="topRight" />
        </div>
      </div>
      <div
        :class="['_bottom', initializing ? '-initializing': '']"
        :style="bottomStyle"
      >
        <div
          v-if="initializing"
          class="_spinner"
        >
          <m-spinner />
        </div>
        <slot
          v-else
        />
      </div>
    </div>
  </m-card>
</template>

<script>
export default {
  name: 'DashboardCard',
  props: {
    title: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    bottomStyle: {
      type: Object,
      default: () => {},
    },
    info: {
      type: String,
      default: '',
    },
  },
  emits: ['click'],
  data() {
    return { initializing: true };
  },
  methods: {
    handleClick(event) {
      if (this.loading || !this.clickable) {
        return;
      }

      this.$emit('click', event);
    },
  },
  watch: {
    loading: {
      handler(loadingVal) {
        if (loadingVal === false) {
          this.initializing = false;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  @import "shared/assets/scss/loading";

  .dashboard-card {
    &.-loading {
      @include loading();
    }

    &.-clickable {
      cursor: pointer;

      &:hover {
        background-color: $hover-color;
      }
    }

    ._content {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    ._top {
      padding: 1.6rem 2.4rem 0 2.4rem;
      display: flex;
      margin-bottom: 2rem;

      ._title {
        overflow: hidden;
        font-size: $font-size-5;
        font-weight: $font-weight-medium;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: flex;
        align-items: center;
        gap: .4rem;
      }

      ._right {
        margin-left: auto;
      }
    }

    ._bottom {
      flex: auto;
      padding: 0 2.4rem 1.6rem 2.4rem;
      overflow: auto;
    }

    ._bottom.-initializing {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;

      ._spinner {
        align-self: center;
      }
    }
  }
</style>
