<template>
  <m-dropdown
    v-model:value="visible"
    class="list-filter"
    :title="$t('listFilter.title')"
    :block="inCardMenu"
  >
    <component
      :is="inCardMenu ? 'm-card-item' : 'm-btn'"
      ref="button"
      small
      hide-border
      class="_btn"
      :icon="inCardMenu ? 'filter' : ''"
      :color="color"
      :light="color === '' && !inCardMenu"
      @click="openMenu"
    >
      {{ $t('list.filter') }}
    </component>
    <template #overlay>
      <m-card
        ref="card"
        class="_overlay"
        no-padding
        border-radius-small
      >
        <m-content
          padding-small
          :padding-bottom="0"
        >
          <div class="_top">
            <saved-view-info
              v-if="showSavedViewInfo && savedView !== null"
              :view="savedView"
              :label="$t('savedViewInfo.filterLabel')"
              class="_info"
            />
            <template
              v-if="showApplyFilterOnFirstLevelOnly && !inCardMenu"
            >
              <m-select
                hide-border
                small
                btn
                light
                :read-only="disabled"
                :items="levelItems"
                :value="applyFilterOnFirstLevelOnly"
                class="_select"
                @input="updateApplyFilterOnFirstLevelOnly"
              />
            </template>
            <m-dropdown
              v-else-if="showApplyFilterOnFirstLevelOnly && inCardMenu"
              :style="{ marginLeft: 'auto' }"
              :title="$t('listFilter.filterOptions')"
            >
              <m-btn
                icon="ellipsis"
                fab
                small
              />
              <template #overlay>
                <m-card

                  list
                  no-padding
                >
                  <m-card-item>
                    <m-select
                      hide-border
                      small
                      btn
                      light
                      :items="levelItems"
                      :value="applyFilterOnFirstLevelOnly"
                      class="_select"
                      @input="updateApplyFilterOnFirstLevelOnly"
                    />
                  </m-card-item>
                </m-card>
              </template>
            </m-dropdown>
          </div>
        </m-content>
        <m-content padding-small>
          <m-tooltip
            placement="left"
            :disabled="!disabled"
            :mouse-enter-delay=".5"
          >
            <div>
              <scope-filter
                :value="value"
                :props="allowedProps"
                :account="loggedInUserAccount"
                :max-tag-text-length="15"
                :has-dynamic-dates="hasDynamicDates"
                :disabled="disabled"
                :multi-model="multiModel"
                :open-with-prop="filterToAdd"
                show-empty-info
                can-remove-all
                @input="update"
              />
            </div>
            <template #title>
              {{ $t('savedViewInfo.disabledInfo') }}
            </template>
          </m-tooltip>
        </m-content>
      </m-card>
    </template>
  </m-dropdown>
</template>

<script>
import SavedViewInfo from '@/components/list/SavedViewInfo.vue';
import ScopeFilter from '@/components/filter/ScopeFilter.vue';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import { EventBus } from '@/lib/event-bus';

export default {
  name: 'ListFilter',
  props: {
    inCardMenu: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default: () => null,
    },
    props: {
      type: Array,
      required: true,
    },
    hasDynamicDates: {
      type: Boolean,
      default: false,
    },
    showSavedViewInfo: {
      type: Boolean,
      default: false,
    },
    savedView: {
      type: Object,
      default: () => null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    multiModel: {
      type: Boolean,
      default: false,
    },
    applyFilterOnFirstLevelOnly: {
      type: Boolean,
      default: false,
    },
    showApplyFilterOnFirstLevelOnly: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { loggedInUser } = useLoggedInUser();
    const { loggedInUserAccount } = useLoggedInUserAccount();
    return { loggedInUser, loggedInUserAccount };
  },
  emits: ['input', 'update:value', 'change-apply-filter-on-first-level-only'],
  components: { SavedViewInfo, ScopeFilter },
  data() {
    return { visible: false, filterToAdd: null };
  },
  computed: {
    allowedProps() {
      return this.props.filter((p) => !p.property.noFilter);
    },
    levelItems() {
      return [
        {
          text: this.$t('listFilter.allLevels'),
          value: false,
        },
        {
          text: this.$t('listFilter.firstLevel'),
          value: true,
        },
      ];
    },
    color() {
      if (this.value !== null && this.value.children.length > 0) {
        return this.inCardMenu ? this.$colors.blue.base : 'primary';
      }

      return '';
    },
  },
  methods: {
    update(value) {
      this.$emit('input', value);
      this.$emit('update:value', value);
    },
    updateApplyFilterOnFirstLevelOnly(value) {
      this.$emit('change-apply-filter-on-first-level-only', value);
    },
    openMenu(prop = null) {
      if (prop !== null) {
        this.filterToAdd = prop;
      }
      this.visible = true;
    },
  },
  mounted() {
    EventBus.$on('applyFilter', this.openMenu);
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  ._overlay {
    min-width: 35rem;
    max-width: 64rem;

    ._top {
      display: flex;

      ._select {
        margin-left: auto;
      }
    }
  }

  ._info {
    margin-right: 2rem;
  }
</style>
