import schema from '@/nebula/schema';
import useStateSelector from '@/nebula/state-selectors';
import { DateTime } from 'luxon';
import { RESULT } from 'shared/api/query/constants';
import { dogma } from '@/api';

/**
 * @typedef {Object} options
 * @property {Boolean} optimistic - the response is expected to be successful and the data
 * is stored prior API call. However, in comparison e.g. to Apollo, we default to ignore
 * the server response due to performance gains via `ignoreResponse`.
 * @property {Boolean} ignoreResponse - when ignoring response, we don't query any attributes with a write operation
 * @property {Boolean} commitToRemote - controls if we propagate the operation to the API (i.e. only execute locally)
 * @property {Boolean} softDelete - controls the predicate that would be written during a delete operation
 * @property {Array} interceptors - an array of interceptors, which implement `intercept(data)` allowing for manipulation of the response prior to storage
 *
 */

export const deleteEntities = ({ state, commit }, { ids, model }, { optimistic = false, commitToRemote = true, softDelete = false } = { optimistic: false, commitToRemote: true, softDelete: false }) => {
  if (ids.length === 0) {
    return new Promise((resolve) => { resolve(); });
  }

  const beforeCommit = useStateSelector(state, model).selectMultiple(ids);
  const mutate = () => {
    commit('nebula/DELETE', { ids, model, schema });
  };
  if (!commitToRemote) {
    mutate();
    return new Promise((resolve) => { resolve(); });
  }
  if (optimistic) {
    mutate();
  }

  const now = DateTime.local().toISO();
  const edge = softDelete ? 'softDeletedAt' : 'deletedAt';
  return dogma.update(
    ids.map((uid) => ({ uid, [edge]: now })),
    model,
    [],
  )
    .then((response) => {
      if (response.status !== 200) {
        if (optimistic) {
          commit('nebula/MUTATE', { entities: beforeCommit, model, schema });
        }
        throw new Error(`error deleting entities: ${model}; ${response.response?.data}`);
      }

      if (!optimistic) {
        mutate();
      }
      return response;
    });
};

export const updateEntities = ({ state, commit }, { entities, model, attributes, hookParameter }, { optimistic = true, ignoreResponse = true, commitToRemote = true, interceptors = [] } = { optimistic: true, ignoreResponse: true, commitToRemote: true, interceptors: [] }) => {
  if (entities.length === 0) {
    return new Promise((resolve) => { resolve([]); });
  }

  const beforeCommit = useStateSelector(state, model).selectMultiple(entities.map(({ uid }) => uid));
  const mutate = (toCommit) => {
    commit('nebula/MUTATE', { entities: toCommit, model, schema });
  };
  if (!optimistic) {
    ignoreResponse = false;
  }

  if (!commitToRemote) {
    mutate(entities);
    return new Promise((resolve) => { resolve(entities); });
  }
  if (optimistic) {
    mutate(entities);
  }

  return dogma.update(
    entities,
    model,
    ignoreResponse ? [] : attributes,
    hookParameter,
  ).then((response) => {
    if (response.status !== 200) {
      if (optimistic) {
        mutate(beforeCommit);
      }
      throw new Error(`error updating entities: ${model}; ${response.response?.data}`);
    }

    if (ignoreResponse) {
      return response.data;
    }

    interceptors.forEach((i) => {
      i.intercept(response.data);
    });
    mutate(response.data);
    return response.data;
  });
};

export const select = ({ commit }, { ids, model, attributes }) => {
  if (ids.length === 0) {
    return new Promise((resolve) => { resolve([]); });
  }

  return dogma.select(ids, model, attributes).then((response) => {
    if (response.status !== 200) {
      throw new Error(`error selecting ids: ${ids}; ${response.data}`);
    }
    commit('nebula/MUTATE', { entities: response.data[RESULT], model, schema });
    return response.data[RESULT];
  });
};

export const queryMultiple = ({ commit }, { queries }, { interceptors = [] } = { interceptors: [] }) => {
  if (queries.length === 0) {
    return new Promise((resolve) => { resolve({ data: {} }); });
  }

  const validQuery = (query) => {
    if (query.alias === 'var') {
      return true;
    }
    if (query.func?.name !== 'uid') {
      return true;
    }
    if (query.func?.args?.length > 0) {
      return true;
    }
    if (query.needsVar?.length > 0) {
      return true;
    }
    return query.uid?.length > 0;
  };

  if (!queries.some(validQuery)) {
    const emptyResponse = queries.map((query) => query.alias).filter((alias) => alias !== 'var').reduce((res, cur) => ({ ...res, [cur]: [] }), {});
    return new Promise((resolve) => { resolve({ data: emptyResponse }); });
  }

  return dogma.query(
    queries,
  )
    .then((response) => {
      if (response.status !== 200) {
        throw new Error(`error querying data: ${queries}; ${response.data}`);
      }

      interceptors.forEach((i) => {
        i.intercept(response.data);
      });

      commit('nebula/QUERY', { queries, response: response.data, schema });

      return response;
    });
};

// TODO: We cannot yet do optimistic creates because, we need to give a temporary ID which
// is returned from the backend, so we can replace the temp uid with the real uid.
export const createEntities = ({ commit }, { entities, model, attributes, hookParameter }, { interceptors = [] } = { interceptors: [] }) => {
  if (entities.length === 0) {
    return new Promise((resolve) => { resolve([]); });
  }

  return dogma.create(
    entities,
    model,
    attributes,
    hookParameter,
  )
    .then((response) => {
      if (response.status !== 201) {
        throw new Error(`error creating entities ${model}; ${response.response?.data}`);
      }

      interceptors.forEach((i) => {
        i.intercept(response.data);
      });

      commit('nebula/MUTATE', { entities: response.data, model, schema });
      return response.data;
    });
};

// TODO: We cannot yet do optimistic creates because, we need to give a temporary ID which
// is returned from the backend, so we can replace the temp uid with the real uid.
export const mutateEntities = ({ commit }, { entities, model, attributes, hookParameter }, { interceptors = [] } = { interceptors: [] }) => {
  if (entities.length === 0) {
    return new Promise((resolve) => { resolve([]); });
  }

  return dogma.mutate(
    entities,
    model,
    attributes,
    hookParameter,
  )
    .then((response) => {
      if (response.status !== 200) {
        throw new Error(`error mutating entities ${model}; ${response.response.data}`);
      }

      interceptors.forEach((i) => {
        i.intercept(response.data);
      });

      commit('nebula/MUTATE', { entities: response.data, model, schema });
      return response.data;
    });
};

export const bulkMutate = ({ commit }, bulkPayloads) => {
  if (bulkPayloads.length === 0) {
    return new Promise((resolve) => { resolve({ data: {} }); });
  }

  if (!bulkPayloads.some((bp) => bp.nodes.length > 0)) {
    const emptyResponse = bulkPayloads.map((bp) => bp.alias).reduce((res, cur) => ({ ...res, [cur]: [] }), {});
    return new Promise((resolve) => { resolve({ data: emptyResponse }); });
  }

  return dogma.bulkMutate(bulkPayloads).then((bulkResponse) => {
    if (bulkResponse.status !== 200) {
      throw new Error(`error bulk mutating payloads ${bulkPayloads.map((bp) => bp.alias).join(' ')}`);
    }

    bulkPayloads.forEach(({ model, nodes, alias }) => {
      const deletedNodes = nodes.filter((n) => n.deletedAt !== undefined);
      if (deletedNodes.length > 0) {
        commit('nebula/DELETE', { ids: deletedNodes.map((e) => e.uid), model, schema });
      }

      commit('nebula/MUTATE', { entities: bulkResponse.data[alias], model, schema });
    });

    return bulkResponse;
  });
};
