<template>
  <connection-error
    v-if="slackConnectionError !== null"
    :error="slackConnectionError"
    @exit="slackConnectionError = null"
  />
  <div
    v-else
    class="my-notifications"
  >
    <page-header
      :title="$t('myNotifications.title')"
      boxed-xs
      heading="h3"
      underlined
      class="_header"
    />
    <m-content
      boxed-xs
      padding
    >
      <m-section
        heading-size="h4"
        :title="$t('myNotifications.channelHeading')"
        class="_section"
      >
        <settings-switch-item
          :title="$t('myNotifications.emailTitle')"
          :sub-title="$t('myNotifications.emailHint')"
          :value="emailActivated"
          :loading="emailLoading"
          icon="mail"
          @click="toggleEmail"
        />
        <m-divider small />

        <m-dialog
          v-model:value="showMicrosoftHint"
          :title="$t('profileSettings.microsoftHintTitle')"
          hide-footer
        >
          <m-s-teams-install-hint />
        </m-dialog>
        <template
          v-if="showMicrosoftBot"
        >
          <settings-switch-item
            title="Microsoft Teams"
            :sub-title="$t('myNotifications.msTeamsHint')"
            :value="microsoftActivated"
            :loading="msTeamsLoading"
            icon="ms-teams"
            :clickable="false"
            @click="toggleMicrosoft"
          >
            <template #before-switch>
              <m-btn
                v-if="microsoftConnected"

                hide-border
                small
                light
                @click="disconnectMicrosoft"
              >
                {{ $t('myNotifications.disconnect') }}
              </m-btn>
            </template>
          </settings-switch-item>
          <m-divider small />
        </template>

        <m-dialog
          v-model:value="showSlackHint"
          :title="$t('profileSettings.slackHintTitle')"
          hide-footer
        >
          <p>{{ $t('profileSettings.slackHint') }}</p>
        </m-dialog>

        <template
          v-if="showSlack"
        >
          <settings-switch-item
            title="Slack"
            :sub-title="$t('myNotifications.slackHint')"
            :value="slackActivated"
            :loading="slackLoading"
            icon="slack"
            @click="toggleSlack"
          />
          <m-divider small />
        </template>
      </m-section>
      <m-section
        v-if="showTopics"
        heading-size="h4"
        :title="$t('myNotifications.notificationTypes')"
        class="_section"
      >
        <template
          v-for="type in notificationTypes"
          :key="type.key"
        >
          <notification-type-item
            :notification="type"
            :notification-setting="user.notificationSetting"
            :value="user.notificationSetting[type.key]"
          />
          <m-divider
            small
          />
        </template>
      </m-section>
    </m-content>
  </div>
</template>

<script>
import ConnectionError from '@/components/slack/ConnectionError.vue';
import MSTeamsInstallHint from '@/components/integrations/MSTeamsInstallHint.vue';
import NotificationTypeItem from '@/components/NotificationTypeItem.vue';
import PageHeader from 'shared/components/PageHeader.vue';
import SettingsSwitchItem from '@/components/SettingsSwitchItem.vue';
import useAccess from '@/composables/access/access';
import useAppIntegration from '@/composables/app-integration';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useNotificationSettings from '@/composables/notification-settings';
import { camelCase } from 'lodash-es';
import { featureFlag, moduleFlag, notificationGroup } from 'shared/constants.json';
import { logCatch } from '@/lib/logger/logger';
import { notificationSetting as notificationSettingConfig } from 'shared/api/query/configs.json';
import { slackBotInstallStatus } from '@/constants.json';

export default {
  name: 'MyNotifications',
  components: { NotificationTypeItem, PageHeader, SettingsSwitchItem, MSTeamsInstallHint, ConnectionError },
  setup() {
    const { accountHasFeature } = useAccess();
    const { loggedInUserAccount } = useLoggedInUserAccount();
    const { loggedInUser } = useLoggedInUser();
    const notificationSettingsSvc = useNotificationSettings();
    const appIntegrationRepo = useAppIntegration();
    return {
      accountHasFeature,
      account: loggedInUserAccount,
      user: loggedInUser,
      notificationSettingsSvc,
      appIntegrationRepo,
    };
  },
  data() {
    return {
      showMicrosoftHint: false,
      showSlackHint: false,
      slackLoading: false,
      msTeamsLoading: false,
      emailLoading: false,
      slackConnectionError: null,
    };
  },
  computed: {
    showTopics() {
      return this.accountHasFeature([moduleFlag.goals]);
    },
    mapNotificationGroup() {
      return {
        [notificationGroup.directMessage]: notificationSettingConfig.edges.suppressDirectMessageNotifications,
        [notificationGroup.goalSubscription]: notificationSettingConfig.edges.suppressGoalSubscriptionNotifications,
        [notificationGroup.goalReference]: notificationSettingConfig.edges.suppressGoalReferenceNotifications,
        [notificationGroup.checkinReminder]: notificationSettingConfig.edges.suppressCheckinReminderNotifications,
      };
    },
    notificationTypes() {
      return [
        notificationGroup.directMessage,
        notificationGroup.goalSubscription,
        notificationGroup.goalReference,
        notificationGroup.checkinReminder,
      ].map((g) => ({
        key: this.mapNotificationGroup[g],
        title: this.$t(`myNotifications.${camelCase(g)}Title`),
        subTitle: this.$t(`myNotifications.${camelCase(g)}SubTitle`),
      }));
    },
    showSlack() {
      if (!this.accountHasFeature([featureFlag.slack])) {
        return false;
      }

      if (typeof this.account.appIntegration === 'undefined' || this.account.appIntegration === null) {
        return false;
      }

      return this.account.appIntegration.slackBotAccessToken !== null;
    },
    slackConnected() {
      if (this.user.appIntegration === null) {
        return false;
      }

      return this.user.appIntegration.slackBotUser !== '';
    },
    showMicrosoftBot() {
      return this.accountHasFeature([featureFlag.microsoftBot]);
    },
    microsoftConnected() {
      if (this.user.appIntegration === null) {
        return false;
      }

      return this.user.appIntegration.microsoftBotConversationID !== '';
    },
    emailActivated() {
      return this.user.notificationSetting.email;
    },
    microsoftActivated() {
      if (this.user.appIntegration === null) {
        return false;
      }

      return this.user.notificationSetting.microsoft && this.microsoftConnected;
    },
    slackActivated() {
      return this.user.notificationSetting.slack && this.slackConnected;
    },
  },
  methods: {
    disconnectMicrosoft() {
      const deleteMethod = () => {
        this.appIntegrationRepo.updateSingle(
          {
            uid: this.user.appIntegration.uid,
            microsoftBotFromID: null,
            microsoftBotFromName: null,
            microsoftBotRecipientID: null,
            microsoftBotRecipientName: null,
            microsoftBotActivityID: null,
            microsoftBotConversationID: null,
            microsoftBotServiceURL: null,
          },
        ).then((response) => {
          this.msTeamsLoading = false;
          if (response.status !== 200) {
            this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
          }
        });
      };
      this.msTeamsLoading = true;

      this.$confirm({
        title: this.$t('myNotifications.confirmMicrosoftDisconnect'),
        okText: this.$t('general.yesDisconnect'),
        okType: 'danger',
        maskClosable: true,
        cancelText: this.$t('general.cancel'),
        onOk() {
          deleteMethod();
        },
      });
    },
    update(entity) {
      this.notificationSettingsSvc.updateSingle(entity).then(() => {
        this.slackLoading = false;
        this.msTeamsLoading = false;
        this.emailLoading = false;
      })
        .catch(logCatch(() => {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
        }));
    },
    toggleSlack() {
      if (!this.slackConnected) {
        this.showSlackHint = true;
        return;
      }

      this.slackLoading = true;
      const update = {
        uid: this.user.notificationSetting.uid,
        slack: !this.user.notificationSetting.slack,
      };

      this.update(update);
    },
    toggleEmail() {
      this.emailLoading = true;
      const update = {
        uid: this.user.notificationSetting.uid,
        email: !this.user.notificationSetting.email,
      };

      this.update(update);
    },
    toggleMicrosoft() {
      if (!this.microsoftConnected) {
        this.showMicrosoftHint = true;
        return;
      }

      this.msTeamsLoading = true;
      const update = {
        uid: this.user.notificationSetting.uid,
        microsoft: !this.user.notificationSetting.microsoft,
      };

      this.update(update);
    },
    handleSlackInteraction() {
      if (this.$route.query.slack_connect_request !== undefined) {
        window.location.href = `/api/v1/slack-bot/connect?s=${this.$route.query.slack_connect_request}`;
        return;
      }

      if (typeof this.$route.query.slack_install_error !== 'undefined') {
        this.slackConnectionError = this.$route.query.slack_install_error;
        return;
      }

      if (typeof this.$route.query.slack_install_status === 'undefined') {
        return;
      }

      switch (this.$route.query.slack_install_status) {
        case slackBotInstallStatus.success:
          this.$showSnackbar({ color: 'success', message: this.$t('accountSettings.appIntegration.slackConnected') });
          break;
        case slackBotInstallStatus.error:
          this.$showSnackbar({
            color: 'error',
            message: this.$t('accountSettings.appIntegration.slackConnectionError'),
          });
          break;
        case slackBotInstallStatus.notActivated:
          this.$showSnackbar({ color: 'error', message: this.$t('accountSettings.appIntegration.notActivated') });
          break;
        case slackBotInstallStatus.unauthorized:
          this.slackConnectionError = true;
          break;
        default:
      }
    },
  },
  mounted() {
    this.handleSlackInteraction();
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .my-notifications {
    ._header {
      margin-bottom: 2rem;
    }

    ._section {
      margin-bottom: 4rem;
    }
  }
</style>
