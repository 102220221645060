<template>
  <div class="pricing-table">
    <div class="_row -header">
      <div class="_left" />
      <div
        v-for="(plan, index) in pricingTableHeader"
        :key="index"
        :class="['_column',`-${plan.id}`]"
      >
        <div class="_header">
          {{ $t(`plans.${plan.id}`) }}
        </div>
        <div class="_price">
          <div class="_amount">
            {{ planPrice(plan) }}
          </div>
          <div
            v-if="plan.priceHint"
            class="_hint"
          >
            {{ plan.priceHint }}
          </div>
        </div>
        <div class="_action">
          <m-tooltip
            v-if="currentPlan === plan.id"
          >
            <div>
              <m-btn
                disabled
                block
              >
                {{ $t('pricingTable.currentPlan') }}
              </m-btn>
            </div>
            <template #title>
              {{ $t('pricingTable.currentPlanHint') }}
            </template>
          </m-tooltip>
          <m-btn
            v-else-if="plan.canUpgradeFrom.includes(currentPlan)"
            block
            color="primary"
            @click="contactSales(plan.id)"
          >
            {{ $t('pricingTable.talkToSales') }}
          </m-btn>
          <m-tooltip v-else>
            <div>
              <m-btn
                block
                :disabled="!(plan.canDowngradeFrom.includes(currentPlan))"
              >
                {{ $t('pricingTable.downgrade') }}
              </m-btn>
            </div>
            <template
              v-if="!(plan.canDowngradeFrom.includes(currentPlan))"
              #title
            >
              {{ $t('pricingTable.planDowngradeHint') }}
            </template>
          </m-tooltip>
        </div>
      </div>
    </div>
    <div
      v-for="(row, index) in rows"
      :key="index"
      :class="['_row', row.isTitle ? '-title' : '']"
    >
      <div class="_left">
        <m-tooltip placement="right">
          <div :class="['_text', typeof row.tooltip !== 'undefined' ? '-underlined' : '']">
            {{ row.text }}
          </div>
          <template
            v-if="typeof row.tooltip !== 'undefined'"
            #title
          >
            {{ row.tooltip }}
          </template>
        </m-tooltip>
      </div>
      <div
        v-for="(col, i) in row.columns"
        :key="i"
        class="_column"
      >
        <m-tooltip v-if="typeof col.text !== 'undefined'">
          <div :class="['_text', typeof col.tooltip !== 'undefined' ? '-underlined' : '']">
            {{ col.text }}
          </div>
          <template
            v-if="typeof col.tooltip !== 'undefined'"
            #title
          >
            {{ col.tooltip }}
          </template>
        </m-tooltip>
        <template v-else-if="typeof col.number !== 'undefined'">
          {{ col.number }}
        </template>
        <template v-else>
          <div class="_icon">
            <m-icon :type="col.icon" />
          </div>
        </template>
      </div>
    </div>
    <m-dialog
      v-model:value="showUpgradeModal"
      hide-header
      hide-footer
      center
      max-width="40rem"
    >
      <upgrade-form
        :initial-plan="initialPlan"
        @close="showUpgradeModal = false"
      />
    </m-dialog>
  </div>
</template>

<script>
import UpgradeForm from '@/components/plans/UpgradeForm.vue';
import useAccountSettings from '@/composables/logged-in-user-account/account-settings';
import { DateTime } from 'luxon';
import { pricingTableConfig, pricingTableHeaderConfig } from '@/components/plans/pricing-table-config';
import { productPlan } from 'shared/constants.json';

export default {
  name: 'PricingTable',
  components: { UpgradeForm },
  setup() {
    const { accountSettings } = useAccountSettings();
    return { accountSettings };
  },
  data() {
    const pricingTableHeader = pricingTableHeaderConfig((key) => this.$t(key));
    return {
      pricingTableHeader,
      productPlan,
      showUpgradeModal: false,
      initialPlan: '',
    };
  },
  computed: {
    showPricesInEuro() {
      return DateTime.local().zoneName.indexOf('Europe') > -1;
    },
    currentPlan() {
      return this.accountSettings.planId;
    },
    rows() {
      return pricingTableConfig((key) => this.$t(key));
    },
  },
  methods: {
    planPrice(plan) {
      if (plan.priceEUR === undefined) {
        return this.$t('pricingTable.letsTalk');
      }
      if (this.showPricesInEuro) {
        return `${plan.priceEUR} €`;
      }
      return `$${plan.priceUSD}`;
    },
    contactSales(plan) {
      this.initialPlan = plan;
      this.showUpgradeModal = true;
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  @import "shared/assets/scss/box-shadow";

  .pricing-table {
    min-width: 110rem;
    padding-bottom: 5rem;
    line-height: 1.5;

    ._row {
      display: flex;
      max-width: 1070px;
      margin: 0 auto;
      border-radius: .4rem;

      ._text {
        display: inline-flex;
        cursor: default;

        &.-underlined {
          position: relative;
          text-decoration: underline;
          text-decoration-style: dotted;
          text-decoration-color: $font-color-secondary;
          text-underline-offset: .4rem;
          cursor: help;
        }
      }

      ._column {
        display: flex;
        flex: 0 0 17.2rem;
        align-items: center;
        justify-content: center;
        padding: .8rem 1.2rem .8rem;
        margin: 0 1rem;
        text-align: center;
        background-color: map_get($grey, 'lighten-7');
      }

      ._left {
        display: flex;
        flex: 1 1 auto;
        align-items: center;
        padding: .8rem 1.2rem .8rem;
      }

      &.-footer {
        height: 1rem;
      }

      &.-header {
        position: sticky;
        top: 0;
        z-index: 1;

        ._column {
          flex-direction: column;
          padding: 2rem 0;
          font-weight: $font-weight-bold;
          text-align: left;
          background-color: white;

          ._header {
            width: 100%;
            margin-bottom: 1.2rem;
            text-align: left;
          }

          ._price {
            display: flex;
            width: 100%;
            height: 4.5rem;
            margin-bottom: 2rem;

            ._amount {
              flex: 0 0 auto;
              font-size: $font-size-9;
              font-weight: $font-weight-bold;
            }

            ._hint {
              flex: 0 1 7rem;
              margin-top: .7rem;
              margin-left: .6rem;
              font-size: $font-size-1;
              font-weight: normal;
              line-height: 1.2;
              color: $font-color-tertiary;
            }
          }

          &.-business {
            padding: 2rem 0;
          }

          &.-enterprise {
            ._price {
              display: flex;
              align-items: center;

              ._amount {
                font-size: $font-size-7;
              }
            }
          }

          ._action {
            width: 100%;
          }
        }
      }

      &.-title {
        ._left {
          ._text {
            display: flex;
            align-items: center;
            padding-top: 2rem;
            font-size: $font-size-5;
            font-weight: $font-weight-semibold;
          }
        }
      }

      &:hover {
        &:not(.-title) {
          &:not(.-header) {
            &:not(.-footer) {
              background-color: map_get($grey, 'lighten-7');

              ._column {
                background-color: map_get($grey, 'lighten-7');
              }
            }
          }
        }
      }
    }
  }

  ._modal {
    ._emoji {
      font-size: 3.8rem;
      text-align: center;
    }

    ._title {
      margin-top: 0;
      margin-bottom: .6rem;
    }

    ._description {
      margin-bottom: 1rem;
    }
  }
</style>
