<template>
  <m-content
    class="access-policy-scope-selector"
    padding
  >
    <m-radio-group
      v-model:value="selectedOption"
      :options="radioOptions"
      default-value="account"
      :disabled="disabled"
      inline
      class="_radio"
    />
    <div class="_content">
      <div v-if="isScope">
        <scope-filter
          v-model:value="selectedScope"
          :props="userProps"
          :disabled="disabled"
          :view-mode="disabled"
          :account="account"
          show-static-user-selection
          init-with-first-prop
          class="_filter"
        />
        <user-scope-tree-user-list
          :user-scope-tree="selectedScope"
        />
      </div>
      <div v-else>
        <user-picker
          v-model:value="selectedUsers"
          :disabled="disabled"
          :excluded-users="excludedUsers"
          :placeholder="$t('accessPolicyScopeSelector.userPlaceholder')"
          full-width
        />
      </div>
    </div>
    <div class="_actions">
      <access-policy-type-selector
        v-if="!hideTypeSelector"
        v-model:value="selectedAccessType"
        :disabled="disabled"
        :access-types="accessTypes"
      />
      <m-btn
        color="primary"
        class="_invite"
        :disabled="disabled"
        @click="submit"
      >
        <template
          v-if="buttonLabel === ''"
        >
          {{ $t('accessPolicyScopeSelector.buttonLabel') }}
        </template>
        <template v-else>
          {{ buttonLabel }}
        </template>
      </m-btn>
    </div>
  </m-content>
</template>

<script>
import AccessPolicyTypeSelector from '@/components/access-policy/AccessPolicyTypeSelector.vue';
import ScopeFilter from '@/components/filter/ScopeFilter.vue';
import UserPicker from '@/components/UserPicker.vue';
import UserScopeTreeUserList from '@/components/UserScopeTreeUserList.vue';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useProperties from '@/composables/property/property';
import { accessPolicyScope, user as userConfig } from 'shared/api/query/configs.json';
import { accessPolicyType } from 'shared/constants.json';
import { createProp } from '@/lib/props';

const SCOPE = 'scope';
const DIRECT_USERS = 'direct_users';

export default {
  name: 'AccessPolicyScopeSelector',
  props: {
    accessPolicyScope: {
      type: Object,
      default: () => null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    excludedUsers: {
      type: Array,
      default: () => [],
    },
    buttonLabel: {
      type: String,
      default: '',
    },
    hideTypeSelector: {
      type: Boolean,
      default: false,
    },
    accessTypes: {
      type: Array,
      default: () => [accessPolicyType.read, accessPolicyType.write, accessPolicyType.full],
    },
  },
  setup() {
    const { userProperties } = useProperties();
    const { userLang } = useLoggedInUser();
    const { loggedInUserAccount } = useLoggedInUserAccount();
    return { userProperties, account: loggedInUserAccount, userLang };
  },
  emits: ['submit'],
  components: { AccessPolicyTypeSelector, UserScopeTreeUserList, ScopeFilter, UserPicker },
  data() {
    return {
      scope: null,
      selectedOption: SCOPE,
      selectedAccessType: accessPolicyType.read,
      selectedUsers: [],
      selectedScope: null,
      radioOptions: [
        { label: this.$t('accessPolicyScopeSelector.radioScopeLabel'), value: SCOPE },
        { label: this.$t('accessPolicyScopeSelector.radioUsersLabel'), value: DIRECT_USERS },
      ],
    };
  },
  computed: {
    isScope() {
      return this.selectedOption === SCOPE;
    },
    userProps() {
      return this.userProperties.map((p) => createProp(p, false, true, true, this.userLang, userConfig.model));
    },
  },
  methods: {
    init() {
      if (this.accessPolicyScope === null) {
        this.selectedOption = SCOPE;
        this.selectedAccessType = accessPolicyType.read;
        this.selectedUsers = [];
        this.selectedScope = null;
        return;
      }

      this.selectedAccessType = this.accessPolicyScope[accessPolicyScope.edges.accessType];
      this.selectedUsers = this.accessPolicyScope[accessPolicyScope.edges.users];
      this.selectedScope = this.accessPolicyScope[accessPolicyScope.edges.scope];

      this.selectedOption = SCOPE;
      if (this.accessPolicyScope[accessPolicyScope.edges.users].length > 0) {
        this.selectedOption = DIRECT_USERS;
      }
    },
    getSubmitScope() {
      const scope = {
        ...this.accessPolicyScope,
        [accessPolicyScope.edges.accessType]: this.selectedAccessType,
      };
      if (this.selectedOption === SCOPE) {
        scope[accessPolicyScope.edges.scope] = this.selectedScope;
        scope[accessPolicyScope.edges.users] = [];
        return scope;
      }
      scope[accessPolicyScope.edges.scope] = null;
      scope[accessPolicyScope.edges.users] = this.selectedUsers;
      return scope;
    },
    submit() {
      this.$emit('submit', this.getSubmitScope());
      this.init();
    },
  },
  watch: {
    accessPolicyScope() {
      this.init();
    },
  },
  created() {
    this.init();
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .access-policy-scope-selector {
    ._content {
      margin-top: 2rem;

      ._filter {
        margin-bottom: 2rem;
      }
    }

    ._actions {
      display: flex;
      justify-content: flex-end;
      margin-top: 2rem;

      ._invite {
        margin-left: .8rem;
      }
    }
  }

</style>
