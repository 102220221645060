const generateVarName = (path, model) => path.reduce((res, next) => `${res}_${next.model}`, `var_${model}`);

const getChildren = (path, varName) => {
  if (path.length === 0) {
    return [];
  }

  if (path.length === 1) {
    return [
      {
        attr: path[0].attr,
        model: path[0].model,
        var: varName,
      },
    ];
  }

  return [
    {
      attr: path[0].attr,
      model: path[0].model,
      children: getChildren(path.slice(1), varName),
    },
  ];
};

export const extendPath = ({ varBlocks, from, path, filterTree }) => {
  const varName = generateVarName(path, from);

  if (varBlocks.length === 0) {
    return {
      filterTree: null,
      varBlocks: [],
    };
  }

  return {
    filterTree: {
      func: {
        name: 'uid',
        needsVar: [{ name: varName, typ: 1 }],
      },
    },
    varBlocks: [
      {
        alias: 'var',
        func: {
          name: 'type',
          args: [{ value: from }],
        },
        filter: filterTree,
        model: from,
        children: getChildren(path, varName),
      },
    ],
  };
};
