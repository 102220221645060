import addContentActions from '@/assets/media/slide-show-dashboards/adding-cards.mp4';
import dashboards from '@/assets/img/slide-show-dashboards/dashboards.png';
import draggingActions from '@/assets/media/slide-show-dashboards/moving-resizing.mp4';
import { EventBus } from '@/lib/event-bus';
import { onboardingSteps } from 'shared/constants.json';
import { useI18n } from 'vue-i18n';

export default function useGridPageSlideShow(onboardingMarker) {
  const { t } = useI18n();
  const slides = [
    {
      title: t('gridPageSlideShow.general.heading'),
      // eslint-disable-next-line global-require
      media: { type: 'image', src: dashboards },
      description: t('gridPageSlideShow.general.description'),
    },
    {
      title: t('gridPageSlideShow.addContentActions.heading'),
      // eslint-disable-next-line global-require
      media: { type: 'video', src: addContentActions },
      description: t('gridPageSlideShow.addContentActions.description'),
    },
    {
      title: t('gridPageSlideShow.draggingActions.heading'),
      // eslint-disable-next-line global-require
      media: { type: 'video', src: draggingActions },
      description: t('gridPageSlideShow.draggingActions.description'),
    },
  ];

  const showGridPageSlideShow = () => {
    EventBus.$emit('show-slide-show', slides);
    onboardingMarker.markAsDone([onboardingSteps.gridPageSlideShow]);
  };

  return { showGridPageSlideShow };
}
