import { computed, ref } from 'vue';
import { iconFromIconPicker } from 'shared/lib/icon-picker';

export default function useIconList(rawList, localStorage, localStorageKey, search, validator) {
  const recent = ref([]);
  const initRecent = () => {
    try {
      const stored = JSON.parse(localStorage.getItem(localStorageKey));
      if (stored === null || stored === undefined) {
        return;
      }
      recent.value = stored.filter((e) => !(e.name === undefined || e.name === null || e.value === undefined || e.value === null)
          && validator.isValid(e));
    } catch (e) {
      // dismiss data from localStorage.
    }
  };
  initRecent();

  const storeRecent = (val) => {
    let toStore = val;
    if (iconFromIconPicker(val.value)) {
      toStore = {
        name: val.name.split('_')[0],
        value: val.value.split('_')[0],
      };
    }

    recent.value.unshift(toStore);
    if (recent.value.length > 20) {
      recent.value.pop();
    }
    localStorage.setItem(localStorageKey, JSON.stringify(recent.value));
  };

  const all = computed(() => {
    if (recent.value.length === 0) {
      return rawList;
    }
    return {
      recent: recent.value.reduce((res, next) => {
        res[next.name] = next.value;
        return res;
      }, {}),
      ...rawList,
    };
  });

  const list = computed(() => {
    if (search.value === '') {
      return all.value;
    }

    return Object.keys(all.value).reduce((acc, category) => {
      Object.keys(all.value[category]).forEach((name) => {
        if (name.toLowerCase().indexOf(search.value.toLowerCase()) > -1 && typeof acc[category] === 'undefined') {
          acc[category] = { [name]: all.value[category][name] };
          return;
        }
        if (name.toLowerCase().indexOf(search.value.toLowerCase()) > -1) {
          acc[category] = {
            ...acc[category],
            [name]: all.value[category][name],
          };
        }
      });

      return acc;
    }, {});
  });

  const random = computed(() => {
    const categories = Object.keys(all.value);
    const category = all.value[categories[Math.floor(Math.random() * categories.length)]];
    const e = Object.keys(category);
    return category[e[Math.floor(Math.random() * e.length) + 1]];
  });

  return {
    initRecent,
    list,
    random,
    storeRecent,
  };
}
