import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useRepo from '@/nebula/repo';
import { computed } from 'vue';
import { resourceSettings as resourceSettingsConfig } from 'shared/api/query/configs.json';

export default function useResourceSettings() {
  const { updateSingle, selectSingle } = useRepo(resourceSettingsConfig.model);

  const { loggedInUserAccount } = useLoggedInUserAccount();

  const resourceSettings = computed(() => selectSingle(loggedInUserAccount.value.resourceSettings?.uid));

  return {
    resourceSettings,
    updateResourceSettings: updateSingle,
  };
}
