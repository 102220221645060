export const optionsFn = ({ isFilterMode = false }) => ({ propertyValues, scope }) => {
  const pvs = propertyValues.filter((pv) => pv.property.uid === scope.property.uid);
  if (pvs.length === 0) {
    return false;
  }

  const selectedIds = pvs[0].selectedOptions.map((o) => o.uid);
  if (scope.isEmpty) {
    return selectedIds.length === 0;
  }

  const neededIds = scope.selectedOptions.map((o) => o.uid);
  if (neededIds.length === 0 && isFilterMode) {
    return true;
  }

  return neededIds.some((el) => selectedIds.indexOf(el) > -1);
};

export const directOptionsFn = ({ isFilterMode = false }) => ({ entity, scope }) => {
  let selectedValues = entity[scope.directProperty.edgeName];
  if (selectedValues === undefined) {
    return false;
  }
  if (selectedValues === null) {
    return scope.isEmpty === true;
  }
  if (!Array.isArray(selectedValues)) {
    selectedValues = [selectedValues];
  }

  if (scope.isEmpty) {
    return selectedValues.length === 0;
  }

  const neededValues = scope.selectedOptions.map((o) => o.value);
  if (neededValues.length === 0 && isFilterMode) {
    return true;
  }
  return neededValues.some((el) => selectedValues.indexOf(el) > -1);
};
