import EditorFile from '@/components/editor/EditorFile.vue';
import Node from '@/tiptap/tiptap/Utils/Node';
import { FILE_UPLOAD_LIMIT } from '@/lib/constants';
import { Plugin } from 'prosemirror-state';

export default class FileUpload extends Node {
  constructor(options = {}) {
    super(options);
    this.uploader = options.uploader;
    this.fileUploadClass = options.fileUploadClass;
    this.excludedTypes = options.excludedTypes;
    this.snackbar = options.snackbar;
    this.translator = options.translator;
  }

  get name() {
    return 'fileUpload';
  }

  get schema() {
    return {
      inline: false,
      attrs: {
        id: {},
        size: { default: null },
        title: { default: null },
        file: { default: null },
        uploader: { default: null },
        autoOpen: { default: null },
      },
      group: 'block',
      draggable: true,
      toDOM: (node) => [
        'div',
        {
          class: this.options.fileUploadClass,
          'data-file-id': node.attrs.id,
        },
        node.attrs.title,
      ],
      parseDOM: [
        {
          tag: 'div[data-file-id]',
          getAttrs: (dom) => {
            const id = dom.getAttribute('data-file-id');
            const title = this.getLabel(dom);
            const autoOpen = dom.getAttribute('autoOpen');
            return { id, title, autoOpen };
          },
        },
      ],
    };
  }

  getLabel(dom) {
    return dom.innerText;
  }

  commands({ type }) {
    return (attrs) => (state, dispatch) => {
      const { selection } = state;
      const position = selection.$cursor ? selection.$cursor.pos : selection.$to.pos;
      const node = type.create(attrs);
      const transaction = state.tr.insert(position - 1, node);
      dispatch(transaction);
    };
  }

  get view() {
    return EditorFile;
  }

  get plugins() {
    const uploader = this.uploader;
    const excludedTypes = this.excludedTypes;
    const snackbar = this.snackbar;
    const translator = this.translator;

    return [
      new Plugin({
        props: {
          handleDOMEvents: {
            paste(view, event) {
              const items = event.clipboardData.items;
              if (typeof uploader === 'undefined') {
                return;
              }
              Array.from(items).forEach((item) => {
                const { schema } = view.state;
                const file = item.getAsFile();
                if (file === null) {
                  return;
                }
                if (excludedTypes.includes(file.type)) {
                  return;
                }
                if (file.size > FILE_UPLOAD_LIMIT) {
                  snackbar({ color: 'error', message: translator('fileUpload.fileTooBig') });
                  return;
                }

                event.preventDefault();
                const node = schema.nodes.fileUpload.create({ id: '', size: file.size, title: file.name, uploader, file });
                view.dispatch(view.state.tr.replaceSelectionWith(node).scrollIntoView());
              });
            },
            drop(view, event) {
              const hasFiles = event.dataTransfer.files.length > 0;
              if (!hasFiles) {
                return;
              }

              event.preventDefault();
              const files = event.dataTransfer.files;
              const { schema } = view.state;
              const coordinates = view.posAtCoords({ left: event.clientX, top: event.clientY });
              let pos = coordinates.pos - 1;
              if (pos === -1) {
                pos = 0;
              }
              Array.from(files).forEach((file) => {
                if (excludedTypes.includes(file.type)) {
                  return;
                }
                if (file.size > FILE_UPLOAD_LIMIT) {
                  snackbar({ color: 'error', message: translator('fileUpload.fileTooBig') });
                  return;
                }
                if (typeof uploader === 'undefined') {
                  return;
                }
                const node = schema.nodes.fileUpload.create({ id: '', size: file.size, title: file.name, uploader, file });
                const txn = view.state.tr.insert(pos, node);
                view.dispatch(txn);

                pos += 1;
              });
            },
          },
        },
      }),
    ];
  }
}
