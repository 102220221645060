<template>
  <div :class="['updates-list-header']">
    <document-list-header
      :slots="slotsWithDefaults"
      :read-only="readOnly"
      :allowed-view-types="allowedViewTypes"
      :filter-props="filterProps"
      :show-create-view="showCreateView"
      :show-views-selector="showViewsSelector"
      :sort-options="sortOptions"
      :multi-model="multiModel"
      :data-loading="dataLoading"
      show-saved-view-info
      has-dynamic-date-filters
    >
      <template #create>
        <create-update-btn
          id="create-update-btn"
          :disabled="foreignSpaceReferenceRestricted"
          :create-loading="createLoading"
        />
      </template>
    </document-list-header>
  </div>
</template>

<script>
import CreateUpdateBtn from '@/components/updates/CreateUpdateBtn.vue';
import DocumentListHeader from '@/components/list/DocumentListHeader.vue';
import useAccess from '@/composables/access/access';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useUpdateProperty from '@/composables/property/update-property';
import useUpdatesSorting from '@/composables/updates/sort';
import useUsers from '@/composables/user/users';
import { SLOTS, VIEWS_SERVICE } from '@/lib/constants';
import { accessGroupFlag, propertyType, viewType } from 'shared/constants.json';
import { inject } from 'vue';
import { referencesForeignSpace } from '@/lib/goal/properties';

export default {
  name: 'UpdatesListHeader',
  props: {
    showViewsSelector: {
      type: Boolean,
      default: false,
    },
    showCreateView: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    createLoading: {
      type: Boolean,
      default: false,
    },
    filterProps: {
      type: Array,
      required: true,
    },
    allowedViewTypes: {
      type: Array,
      default: () => [
        viewType.feed,
        viewType.list,
      ],
    },
    multiModel: {
      type: Boolean,
      default: false,
    },
    slots: {
      type: Array,
      default: () => [],
      validator(slots) {
        return slots.every((slot) => [SLOTS.FILTER, SLOTS.PROPERTIES, SLOTS.SORTER, SLOTS.CREATE].includes(slot.name));
      },
    },
    dataLoading: {
      type: Boolean,
      default: false,
    },
  },
  components: { CreateUpdateBtn, DocumentListHeader },
  setup() {
    const { userHasRight } = useAccess();
    const { loggedInUser } = useLoggedInUser();
    const { properties: updateProperties } = useUpdateProperty();
    const userSvc = useUsers();
    const { sortOptions } = useUpdatesSorting(updateProperties, userSvc);
    const viewSvc = inject(VIEWS_SERVICE);
    return {
      userHasRight,
      loggedInUser,
      sortOptions,
      currentView: viewSvc.currentView,
    };
  },
  data() {
    return { viewType };
  },
  computed: {
    slotsWithDefaults() {
      const slots = [];
      slots.push(...this.slots);
      let index = slots.findIndex((s) => s.name === SLOTS.CREATE);
      if (index === -1) {
        index = slots.length;
      }
      slots.splice(index, 0, { name: SLOTS.WRAP_GOAL_TITLES });
      return slots;
    },
    foreignSpaceReferenceRestricted() {
      if (this.userHasRight([accessGroupFlag.foreignEntityReference])) {
        return false;
      }

      if (this.currentView.space === null || this.currentView.space === undefined) {
        return false;
      }

      return referencesForeignSpace({ propertyValues: [{ property: { type: propertyType.space }, spaces: [this.currentView.space] }], user: this.loggedInUser });
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .updates-list-header {
    ._new {
      display: flex;
      margin-left: .4rem;

      ._templates {
        border-left: 1px solid map_get($blue, 'darken-1');
      }
    }
  }
</style>
