<template>
  <div class="validation-errors">
    <div
      v-if="errors.length > 1"
      class="_header"
    >
      {{ $t('validationErrors.header', {amount: errors.length}) }}
    </div>
    <template
      v-for="(err, index) in errors"
      :key="index"
    >
      <required-error
        v-if="err.key === goalValidationError.required"
        :err="err"
      />
      <can-be-child-of-error
        v-else-if="err.key === goalValidationError.canBeChildOf"
        :err="err"
      />
      <must-be-empty-error
        v-else-if="err.key === goalValidationError.canBeChildOfMustBeEmpty"
        :err="err"
      />
      <measurement-type-error
        v-else-if="err.key === goalValidationError.measurementType"
        :err="err"
      />
    </template>
  </div>
</template>

<script>
import CanBeChildOfError from '@/components/rules/CanBeChildOfError.vue';
import MeasurementTypeError from '@/components/rules/MeasurementTypeError.vue';
import MustBeEmptyError from '@/components/rules/MustBeEmptyError.vue';
import RequiredError from '@/components/rules/RequiredError.vue';
import useGoalTypeProperty from '@/composables/customize-page/goal-type-property';
import { camelCase } from 'lodash-es';
import { goalValidationError } from 'shared/constants.json';
import { textByLang } from 'shared/lib/language';

export default {
  name: 'ValidationErrorContent',
  props: {
    errors: {
      type: Array,
      required: true,
    },
  },
  components: { MustBeEmptyError, RequiredError, MeasurementTypeError, CanBeChildOfError },
  setup() {
    const { goalTypeProperty } = useGoalTypeProperty();
    return { goalTypes: goalTypeProperty.value.options };
  },
  data() {
    return { goalValidationError };
  },
  methods: {
    getErrMsg(err) {
      switch (err.key) {
        case goalValidationError.canBeChildOf: {
          const expectedTypes = this.goalTypes.filter((t) => err.payload.expected.includes(t.uid));
          const actualTypes = this.goalTypes.filter((t) => err.payload.actual.uid === t.uid);
          return this.$t(`validationErrors.${camelCase(goalValidationError.canBeChildOf)}`, {
            title: err.payload.goal.title,
            expectedTypes: expectedTypes.map((t) => textByLang(t.label, 'de')).join(', '),
            actualTypes: actualTypes.map((t) => textByLang(t.label, 'de')).join(', '),
          });
        }
        case goalValidationError.required: {
          return `${err.property.key} is a required value.`;
        }
        case goalValidationError.measurementType: {
          return `Measurement type not allowed. Actual: ${err.payload.actual}, expected: ${err.payload.expected}`;
        }
        default:
          return '';
      }
    },
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  .validation-errors {
    ._content {
      ul {
        margin-block-start: .8rem;
        padding-inline-start: 1.4rem;

        li {
          margin-bottom: .8rem;
        }
      }
    }
  }
</style>
