export const userFn = ({ isFilterMode = false }) => ({ propertyValues, scope }) => {
  const pvs = propertyValues.filter((pv) => pv.property.uid === scope.property.uid);
  if (pvs.length === 0) {
    return false;
  }

  const selectedIds = pvs[0].users.map((o) => o.uid);
  if (scope.isEmpty) {
    return selectedIds.length === 0;
  }

  const neededIds = scope.users.map((o) => o.uid);
  if (neededIds.length === 0 && isFilterMode) {
    return true;
  }

  return neededIds.some((el) => selectedIds.indexOf(el) > -1);
};

export const directUserFn = ({ isFilterMode = false }) => ({ entity, scope }) => {
  let selectedUsers = entity[scope.directProperty.edgeName];
  if (selectedUsers === undefined) {
    return false;
  }
  if (selectedUsers === null) {
    return scope.isEmpty === true;
  }
  if (!Array.isArray(selectedUsers)) {
    selectedUsers = [selectedUsers];
  }

  if (scope.isEmpty) {
    return selectedUsers.length === 0;
  }

  const selectedIds = selectedUsers.map((o) => o.uid);
  const neededIds = scope.users.map((o) => o.uid);
  if (neededIds.length === 0 && isFilterMode) {
    return true;
  }

  return neededIds.some((el) => selectedIds.indexOf(el) > -1);
};

export const staticUserFn = ({ isFilterMode = false }) => ({ entity, scope }) => {
  const selectedUsers = [entity];

  const selectedIds = selectedUsers.map((o) => o.uid);
  const neededIds = scope.staticUsers.map((o) => o.uid);
  if (neededIds.length === 0 && isFilterMode) {
    return true;
  }

  return neededIds.some((el) => selectedIds.indexOf(el) > -1);
};
