<template>
  <div class="m-spinner">
    <m-icon
      :color="iconColor"
      :size="fontSize"
      type="loading"
      spin
    />
  </div>
</template>

<script>
export default {
  name: 'MSpinner',
  props: {
    size: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
  },
  computed: {
    iconColor() {
      if (this.color !== '') {
        return this.color;
      }

      return this.$colors.grey.lighten2;
    },
    fontSize() {
      switch (this.size) {
        case 'xl':
          return '60';
        case 'lg':
          return '45';
        case 'sm':
          return '24';
        case 'xs':
          return '16';
        case 'xxs':
          return '12';
        default:
          return '35';
      }
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">

</style>
