import blockQuote from '@/assets/img/editor/blockquote.png';
import bulletList from '@/assets/img/editor/bullet-list.png';
import codeBlock from '@/assets/img/editor/code-block.png';
import file from '@/assets/img/editor/file.png';
import goalMention from '@/assets/img/editor/goal-mention.png';
import heading1 from '@/assets/img/editor/heading1.png';
import image from '@/assets/img/editor/image.png';
import mention from '@/assets/img/editor/mention.png';
import orderedList from '@/assets/img/editor/ordered-list.png';
import todoList from '@/assets/img/editor/todo-list.png';
import { TextSelection } from 'prosemirror-state';
import { camelCase } from 'lodash-es';
import { editorNodeType } from 'shared/constants.json';
import { insertText, toggleBlockType, toggleList, toggleWrap } from '@/tiptap/commands/commands';
import { mapGetters } from 'vuex';
import { uploadFile } from '@/lib/image/image';

export default {
  computed: {
    ...mapGetters(['loggedInUserAccount']),
    goalSettings() {
      return this.loggedInUserAccount.goalSettings;
    },
    accountSettings() {
      return this.loggedInUserAccount.accountSettings;
    },
    mappedTypes() {
      return {
        [editorNodeType.blockquote]: 'blockquote',
        [editorNodeType.codeBlock]: 'code_block',
        [editorNodeType.heading]: 'heading',
        [editorNodeType.orderedList]: 'ordered_list',
        [editorNodeType.bulletList]: 'bullet_list',
        [editorNodeType.mention]: 'mention',
        [editorNodeType.goalMention]: 'goal_mention',
        [editorNodeType.link]: 'link',
        [editorNodeType.todoList]: 'todo_list',
      };
    },
    icons() {
      return {
        [editorNodeType.blockquote]: blockQuote,
        [editorNodeType.codeBlock]: codeBlock,
        [editorNodeType.heading]: heading1,
        [editorNodeType.orderedList]: orderedList,
        [editorNodeType.bulletList]: bulletList,
        [editorNodeType.mention]: mention,
        [editorNodeType.goalMention]: goalMention,
        [editorNodeType.todoList]: todoList,
        [editorNodeType.image]: image,
        [editorNodeType.file]: file,
      };
    },
    items() {
      return this.allowedContent.reduce((res, t) => {
        if (t === editorNodeType.heading) {
          return [
            ...res,
            ...[1, 2, 3].map((n) => ({
              title: this.$t(`mContextMenu.${camelCase(t)}${n}.title`),
              subTitle: this.$t(`mContextMenu.${camelCase(t)}${n}.subTitle`),
              attrs: { level: n },
              type: t,
              src: this.icons[t],
            })),
          ];
        }

        if (t === editorNodeType.goalMention && !this.accountSettings.usesGoals) {
          return res;
        }

        return [
          ...res,
          {
            title: this.$t(`mContextMenu.${camelCase(t)}.title`, { plural: this.goalSettings.featureNamePlural }),
            subTitle: this.$t(`mContextMenu.${camelCase(t)}.subTitle`, { singular: this.goalSettings.featureNameSingular }),
            src: this.icons[t],
            attrs: this.getAttrs(t),
            type: t,
          },
        ];
      }, this.customItems);
    },
  },
  methods: {
    parentTypes(pos) {
      return this.addParentType([], pos.depth, pos);
    },
    addParentType(res, depth, pos) {
      const parent = pos.node(depth);
      if (typeof parent.type !== 'undefined' && typeof parent.type.name !== 'undefined') {
        res.push(parent.type.name);
      }

      if (depth > 0 && parent) {
        res = this.addParentType(res, depth - 1, pos);
      }

      return res;
    },
    getAttrs(type) {
      switch (type) {
        case editorNodeType.orderedList:
          return { order: 1 };
        case editorNodeType.bulletList:
          return {};
        case editorNodeType.codeBlock:
          return {};
        case editorNodeType.blockquote:
          return {};
        case editorNodeType.image:
          return {
            src: '',
            id: '',
            alt: '',
          };
        default:
          return {};
      }
    },
    heading(item, from, to, inline) {
      if (inline) {
        const tr = this.view.state.tr.delete(from, to);
        this.view.dispatch(tr);
        toggleBlockType(this.schema.nodes[this.mappedTypes[item.type]], this.schema.nodes.paragraph, item.attrs)(this.view.state, this.view.dispatch, this.view);
        return;
      }

      this.insertBlock(item, from, to);
    },
    codeBlock(item, from, to, inline) {
      if (inline) {
        const tr = this.view.state.tr.delete(from, to);
        this.view.dispatch(tr);
        toggleBlockType(this.schema.nodes[this.mappedTypes[item.type]], this.schema.nodes.paragraph, item.attrs)(this.view.state, this.view.dispatch, this.view);
        return;
      }

      this.insertBlock(item, from, to);
    },
    blockquote(item, from, to, inline, parentTypes) {
      if (inline) {
        const tr = this.view.state.tr.delete(from, to);
        this.view.dispatch(tr);
        toggleWrap(this.schema.nodes[this.mappedTypes[item.type]])(this.view.state, this.view.dispatch, this.view);
        return;
      }

      this.insertBlock(item, from, to);
      if (parentTypes.includes(editorNodeType.heading)) {
        toggleBlockType(this.schema.nodes[this.mappedTypes[editorNodeType.heading]], this.schema.nodes.paragraph, item.attrs)(this.view.state, this.view.dispatch, this.view);
      }
      toggleWrap(this.schema.nodes[this.mappedTypes[item.type]])(this.view.state, this.view.dispatch, this.view);
    },
    bulletList(item, from, to, inline) {
      if (inline) {
        const tr = this.view.state.tr.delete(from, to);
        this.view.dispatch(tr);
        toggleList(this.schema.nodes[this.mappedTypes[item.type]], this.schema.nodes.list_item)(this.view.state, this.view.dispatch, this.view);
        return;
      }

      this.insertBlock(item, from, to);
    },
    toDoList(item, from, to, inline) {
      if (inline) {
        const tr = this.view.state.tr.delete(from, to);
        this.view.dispatch(tr);
        toggleList(this.schema.nodes.todo_list, this.schema.nodes.todo_item)(this.view.state, this.view.dispatch, this.view);
        return;
      }

      this.insertBlock(item, from, to);
    },
    orderedList(item, from, to, inline) {
      if (inline) {
        const tr = this.view.state.tr.delete(from, to);
        this.view.dispatch(tr);
        toggleList(this.schema.nodes[this.mappedTypes[item.type]], this.schema.nodes.list_item)(this.view.state, this.view.dispatch, this.view);
        return;
      }

      this.insertBlock(item, from, to);
    },
    image(_, from, to) {
      const node = this.schema.nodes.image.create({ src: '', size: 0, name: '', uploader: uploadFile, autoOpen: true });
      const transaction = this.view.state.tr.replaceRangeWith(from, to, node);
      this.view.dispatch(transaction);
    },
    file(_, from, to) {
      const node = this.schema.nodes.fileUpload.create({ id: '', size: 0, title: '', uploader: uploadFile, autoOpen: true });
      const transaction = this.view.state.tr.replaceRangeWith(from, to, node);
      this.view.dispatch(transaction);
    },
    mention(_, from, to) {
      const tr = this.view.state.tr.delete(from, to);
      this.view.dispatch(tr);
      insertText('@')(this.view.state, this.view.dispatch);
    },
    goalMention(_, from, to) {
      const tr = this.view.state.tr.delete(from, to);
      this.view.dispatch(tr);
      insertText('@')(this.view.state, this.view.dispatch);
    },
    insertBlock(item, from, to) {
      const tr = this.view.state.tr.delete(from, to);
      const node = this.schema.nodes[this.mappedTypes[item.type]].create(item.attrs);
      tr.insert(from, node);
      const start = tr.doc.resolve(from + 1);
      tr.setSelection(new TextSelection(start));
      tr.delete(
        from + 2,
        from + 3,
      );
      this.view.dispatch(tr.scrollIntoView());
    },
    addCustomBlock(item, from, to) {
      const tr = this.view.state.tr.delete(from, to);
      this.view.dispatch(tr.scrollIntoView());

      item.onClick();
    },
    addBlock(item, from, to) {
      const inline = this.view.state.selection.$cursor.parent.content.size === to - from;
      const parentTypes = this.parentTypes(this.view.state.selection.$cursor);

      switch (item.type) {
        case undefined:
          this.addCustomBlock(item, from, to);
          break;
        case editorNodeType.todoList:
          this.toDoList(item, from, to, inline);
          break;
        case editorNodeType.heading:
          this.heading(item, from, to, inline);
          break;
        case editorNodeType.blockquote:
          this.blockquote(item, from, to, inline, parentTypes);
          break;
        case editorNodeType.codeBlock:
          this.codeBlock(item, from, to, inline);
          break;
        case editorNodeType.bulletList:
          this.bulletList(item, from, to, inline);
          break;
        case editorNodeType.orderedList:
          this.orderedList(item, from, to, inline);
          break;
        case editorNodeType.image:
          this.image(item, from, to, inline);
          break;
        case editorNodeType.file:
          this.file(item, from, to, inline);
          break;
        case editorNodeType.mention:
          this.mention(item, from, to, inline);
          break;
        case editorNodeType.goalMention:
          this.goalMention(item, from, to, inline);
          break;
        default:
          return;
      }

      this.editor.focus();
    },
  },
};
