import { onBeforeUnmount, ref, watch } from 'vue';
import { upToContainerWithClass } from 'shared/lib/dom';

export default function useClickOutside(callback, active, element, options = { callCallbackBeforeUnmount: false }) {
  const overlayClass = '__overlay-container';

  let container;
  const register = () => {
    if (element.value === null) {
      return;
    }

    container = ref(upToContainerWithClass(element.value, overlayClass));

    if (!active.value) {
      removeEventListener();
      return;
    }

    addEventListener();
  };

  watch(active, () => {
    register();
  });
  watch(element, () => {
    register();
  });

  const detectOutsideClick = (event) => {
    const outsideClick = !event.composedPath().includes(element.value);
    if (outsideClick) {
      callback(event);
    }
  };

  const listenerActive = ref(false);
  const addEventListener = () => {
    container.value.addEventListener('mousedown', detectOutsideClick);
    listenerActive.value = true;
  };

  const removeEventListener = () => {
    if (!listenerActive.value) {
      return;
    }
    container.value.removeEventListener('mousedown', detectOutsideClick);
    listenerActive.value = false;
  };

  onBeforeUnmount(() => {
    if (options.callCallbackBeforeUnmount) {
      callback();
    }
    removeEventListener();
  });

  register();
}
