import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import { DEFAULT_LANGUAGE, isValidLanguageCode } from '@/lib/language';
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default function useForm() {
  const store = useStore();
  const route = useRoute();
  const router = useRouter();
  const { userLang } = useLoggedInUser();
  const form = computed(() => store.state.form);
  const lang = computed(() => {
    if (typeof this.$route.query.lang !== 'undefined' && isValidLanguageCode(this.$route.query.lang)) {
      return route.query.lang;
    }
    if (languages.value.includes(userLang.value)) {
      return userLang.value;
    }
    if (languages.value.includes(DEFAULT_LANGUAGE)) {
      return DEFAULT_LANGUAGE;
    }
    return languages.value[0];
  });
  const formId = computed(() => parseInt(route.params.formId, 10));
  const formAvailable = computed(() => form.value !== null && formId.value === form.value.uid);
  const languages = computed(() => {
    if (form.value === null || (form.value.orderItems.length === 0 && form.value.welcomeScreen === null)) {
      return [userLang.value];
    }
    if (form.value.orderItems.length > 0) {
      return Object.keys(form.value.orderItems[0].field.title);
    }
    return Object.keys(form.value.welcomeScreen.title);
  });
  const changeLanguage = ({ lang }) => {
    router.push({ ...route, query: { lang } });
  };

  return { changeLanguage, form, formAvailable, languages, lang, formId };
}
