<template>
  <div class="rc-main">
    <r-c-overview
      v-if="page === RESOURCE_CENTER_OVERVIEW"
      :resource-center-router="resourceCenterRouter"
    />
    <r-c-onboarding-guide
      v-else-if="page === RESOURCE_CENTER_ONBOARDING_GUIDE"
      :resource-center-router="resourceCenterRouter"
      @close="$emit('close')"
    />
    <r-c-resources
      v-else-if="page === RESOURCE_CENTER_OKR_RESOURCES"
      :resource-center-router="resourceCenterRouter"
    />
  </div>
</template>

<script>
import RCOnboardingGuide from '@/components/resource-center/RCOnboardingGuide.vue';
import RCOverview from '@/components/resource-center/RCOverview.vue';
import RCResources from '@/components/resource-center/RCResources.vue';
import useResourceCenterRouter from '@/composables/onboarding/resource-center-router';
import {
  RESOURCE_CENTER_OKR_RESOURCES,
  RESOURCE_CENTER_ONBOARDING_GUIDE,
  RESOURCE_CENTER_OVERVIEW,
} from '@/lib/constants';

export default {
  name: 'RcMain',
  props: {
    initialPage: {
      type: String,
      default: RESOURCE_CENTER_OVERVIEW,
    },
  },
  setup(props) {
    const resourceCenterRouter = useResourceCenterRouter(props.initialPage);
    return { resourceCenterRouter, page: resourceCenterRouter.page };
  },
  emits: ['close'],
  components: { RCResources, RCOnboardingGuide, RCOverview },
  data() {
    return {
      RESOURCE_CENTER_OVERVIEW,
      RESOURCE_CENTER_ONBOARDING_GUIDE,
      RESOURCE_CENTER_OKR_RESOURCES,
    };
  },
  watch: {
    $route() {
      this.$emit('close');
    },
  },
};

</script>

<style scoped lang="scss" type="text/scss">
  .rc-main {
    height: calc(100% - 6.5rem);
    overflow: auto;
  }
</style>
