import { UID } from 'shared/api/query/constants';
import { appIntegration as appIntegrationConfig } from 'shared/api/query/configs.json';

export const appIntegrationChildren = [
  { attr: UID },
  { attr: appIntegrationConfig.edges.slackBotAccessToken, default: null },
  { attr: appIntegrationConfig.edges.slackBotUser, default: '' },
  { attr: appIntegrationConfig.edges.microsoftBotFromID, default: '' },
  { attr: appIntegrationConfig.edges.microsoftBotFromName, default: '' },
  { attr: appIntegrationConfig.edges.microsoftBotRecipientID, default: '' },
  { attr: appIntegrationConfig.edges.microsoftBotRecipientName, default: '' },
  { attr: appIntegrationConfig.edges.microsoftBotActivityID, default: '' },
  { attr: appIntegrationConfig.edges.microsoftBotConversationID, default: '' },
  { attr: appIntegrationConfig.edges.microsoftBotServiceURL, default: '' },
  { attr: appIntegrationConfig.edges.microsoftEmails, default: [] },
];
