// bumblebee specific const can be placed in here

export const GRID_ITEM_TYPE_H1 = 'h1';
export const GRID_ITEM_TYPE_H2 = 'h2';
export const GRID_ITEM_TYPE_H3 = 'h3';
export const GRID_ITEM_TYPE_TEXT = 'text';

export const formSendSteps = {
  settings: 'settings',
  sendingOptions: 'sending_options',
  participants: 'participants',
  channels: 'channels',
  verify: 'verify',
};

export const goalCycleSteps = {
  setup: 'setup',
  verify: 'verify',
  notifications: 'notifications',
};
