<template>
  <div>
    <template v-if="updateTemplates.length === 0">
      <m-card-item
        class="_item"
        :clickable="false"
        light
      >
        {{ $t('createUpdateBtn.emptyExplanation') }}
      </m-card-item>
      <m-card-item
        class="_item"
        :clickable="false"
        light
      >
        {{ $t('createUpdateBtn.weeklyCheckIn') }}
      </m-card-item>
      <m-card-item
        class="_item"
        :clickable="false"
        light
      >
        {{ $t('createUpdateBtn.standUp') }}
      </m-card-item>
    </template>
    <update-template-item
      v-for="template in updateTemplates"
      :key="template.uid"
      :template="template"
      @edit="edit(template)"
      @delete="deleteTemplate(template)"
      @select="$emit('select', template)"
    />
    <template v-if="canCreate">
      <m-divider xxs />
      <m-card-item
        icon="plus"
        light
        @click.stop="createTemplate"
      >
        {{ $t('createUpdateBtn.addTemplate') }}
      </m-card-item>
    </template>
    <m-dialog
      v-model:value="showModal"
      :max-width="$modalSizes.xl"
      hide-footer
      no-padding
      keep-height
      hide-header
      top="7rem"
      @close="selectLastTemplate"
    >
      <update-template-editor
        :id="selectedUpdateTemplateId"
        :key="selectedUpdateTemplateId"
        @close="showModal = false"
      />
    </m-dialog>
  </div>
</template>

<script>
import UpdateTemplateEditor from '@/components/updates/UpdateTemplateEditor.vue';
import UpdateTemplateItem from '@/components/updates/UpdateTemplateItem.vue';
import useAccess from '@/composables/access/access';
import useUpdateTemplates from '@/composables/update-templates/update-templates';
import { accessGroupFlag } from 'shared/constants.json';

export default {
  name: 'TemplateList',
  props: {
    createLoading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['select', 'close', 'delete-template'],
  components: {
    UpdateTemplateEditor,
    UpdateTemplateItem,
  },
  setup() {
    const updateTemplatesSvc = useUpdateTemplates();
    const { userHasRight } = useAccess();
    return {
      userHasRight,
      updateTemplates: updateTemplatesSvc.updateTemplates,
      createUpdateTemplate: updateTemplatesSvc.createUpdateTemplate,
      deleteUpdateTemplate: updateTemplatesSvc.deleteUpdateTemplate,
    };
  },
  data() {
    return {
      showModal: false,
      selectedUpdateTemplateId: 0,
    };
  },
  computed: {
    canCreate() {
      return this.userHasRight([accessGroupFlag.updateAdminAccess]);
    },
  },
  methods: {
    selectLastTemplate() {
      this.$emit('close', this.updateTemplates[this.updateTemplates.length - 1]);
    },
    edit(template) {
      this.selectedUpdateTemplateId = template.uid;
      this.showModal = true;
    },
    deleteTemplate(template) {
      const deleteMethod = () => this.deleteUpdateTemplate(template).then(() => {
        this.$emit('delete-template', template);
      }).catch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      });

      this.$confirm({
        title: this.$t('createUpdateBtn.deletePrompt'),
        okText: this.$t('general.yesDelete'),
        okType: 'danger',
        cancelText: this.$t('general.cancel'),
        maskClosable: true,
        onOk() {
          deleteMethod();
        },
      });
    },
    createTemplate() {
      this.createUpdateTemplate({
        title: '',
        icon: '',
        template: null,
      }).then((updateTemplate) => {
        this.selectedUpdateTemplateId = updateTemplate.uid;
        this.showModal = true;
      }).catch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      });
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">

</style>
