<template>
  <div
    v-if="loading !== null"
    class="calendar"
  >
    <m-card-item
      icon="calendar"
      :loading="createLoading"
      @click="handleCreateClick"
    >
      {{ $t('meetingsCalendar.connectAction.create') }}
    </m-card-item>
    <m-dropdown
      v-model:value="connectEventDialog"
      :trigger="['hover']"
      :title="$t('general.actions')"
      placement="topLeft"
      block
    >
      <m-card-item
        icon="calendar"
        @click="handleConnectClick"
      >
        {{ $t('meetingsCalendar.connectAction.connect') }}
      </m-card-item>
      <template #overlay>
        <m-card
          v-if="msCalendarConnected"
          list
          no-padding
          class="_overlay"
        >
          <m-card-item
            no-hover
            :disabled="eventsLoading"
          >
            <m-input
              :value="searchTerm"
              :placeholder="$t('meetingsCalendar.search')"
              :disabled="eventsLoading"
              @input="updateSearchTerm"
            />
          </m-card-item>
          <m-card-item
            v-if="msCalendarDataSource[customDatasourceProperty.msCalendarEvents].length ===0"
            no-hover
            :clickable="false"
          >
            {{ $t('meetingsCalendar.noEvents') }}
          </m-card-item>
          <m-card-item
            v-for="event in msCalendarDataSource[customDatasourceProperty.msCalendarEvents]"
            :key="event.id"
            :disabled="eventsLoading"
            @click="select(event)"
          >
            <div class="_event-item">
              <div class="_left">
                <div class="_title">
                  <div class="_text">
                    {{ event.subject }}
                  </div>
                  <m-btn
                    hide-border
                    xs
                    fab
                    target="_blank"
                    :href="event.webLink"
                    icon="external-link"
                    @click.stop
                  />
                </div>
                <div class="_event-time">
                  {{ event.start.dateTime.toLocaleString() }}
                </div>
              </div>
            </div>
            <template
              v-if="loading[event.id]"
              #right
            >
              <m-icon
                type="loading"
              />
            </template>
            <template
              v-else-if="(meeting.msCalendarEvent !== null && meeting.msCalendarEvent.eventId === event.id)"
              #right
            >
              <m-icon
                type="check"
              />
            </template>
          </m-card-item>
          <m-card-item
            v-if="!eventsLoading && ((eventPageSize * eventPages) < msCalendarDataSource[customDatasourceProperty.msCalendarEventsCount])"
            no-hover
          >
            <m-link @click="loadMore">
              {{ $t('meetingsCalendar.loadMore') }}
            </m-link>
          </m-card-item>
          <m-card-item
            v-if="eventsLoading"
            no-hover
            :clickable="false"
          >
            <div class="_loading-spinner">
              <m-spinner size="xs" />
            </div>
          </m-card-item>
        </m-card>
      </template>
    </m-dropdown>
    <m-dialog
      v-model:value="createDialog"
      :title="$t('meetingsCalendar.connectAction.create')"
      :ok-text="$t('general.create')"
      :disabled="createLoading"
      @ok="createCalendarEvent"
      @cancel="createDialog = false"
    >
      <calendar-event-form
        v-model:value="newEvent"
        :meeting="meeting"
      />
    </m-dialog>
    <m-dialog
      v-model:value="connectDialog"
      :title="$t('meetingsCalendar.connectDialogTitle')"
      hide-footer
    >
      <calendar-connect
        :meeting="meeting"
        @close="handleCalendarConnectClose"
      />
    </m-dialog>
  </div>
</template>

<script>
import CalendarConnect from '@/components/meeting/CalendarConnect.vue';
import CalendarEventForm from '@/components/meeting/CalendarEventForm.vue';
import useDebounce from '@/composables/debounce';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useMSCalendarDataSource from '@/composables/ms-calendar/ms-calendar';
import usePersonalAppSettings from '@/composables/logged-in-user/personal-app-settings';
import { customDatasourceProperty } from 'shared/constants.json';
import { filter as eventFilter, pageSize as eventPageSize, pagination } from '@/lib/mscalendar';
import { logCatch } from '@/lib/logger/logger';
import { mapActions } from 'vuex';

export default {
  name: 'Calendar',
  props: {
    meeting: {
      type: Object,
      required: true,
    },
  },
  emits: ['close'],
  setup() {
    const { debounce } = useDebounce({ onBeforeUnmountCancel: true });
    const msCalendarSvc = useMSCalendarDataSource();
    const { loggedInUser } = useLoggedInUser();
    const { personalAppSettings } = usePersonalAppSettings(loggedInUser);
    return { debounce, loggedInUser, personalAppSettings, msCalendarSvc, msCalendarDataSource: msCalendarSvc.msCalendarDataSource };
  },
  components: { CalendarConnect, CalendarEventForm },
  data() {
    return {
      connectDialog: false,
      createDialog: false,
      connectEventDialog: false,
      loading: null,
      createLoading: false,
      newEvent: { title: '', date: null },
      calendarConnectIntention: 'create',
      eventSearch: '',
      eventPages: 1,
      searchTerm: '',
      eventsLoading: false,
      eventPageSize,
      customDatasourceProperty,
    };
  },
  computed: {
    msCalendarConnected() {
      if (this.msCalendarDataSource === undefined) {
        return false;
      }

      if (this.msCalendarDataSource.accessToken === '') {
        return false;
      }

      return true;
    },
  },
  methods: {
    ...mapActions(['getEntitiesV2', 'createEntityV2', 'updateEntityV2']),
    handleCalendarConnectClose() {
      this.connectDialog = false;
      if (this.calendarConnectIntention === 'create') {
        this.createDialog = true;
        return;
      }
      this.connectEventDialog = true;
    },
    handleCreateClick() {
      if (!this.msCalendarConnected) {
        this.calendarConnectIntention = 'create';
        this.connectDialog = true;
        return;
      }

      this.createDialog = true;
    },
    createCalendarEvent() {
      this.createLoading = true;
      if (this.meeting.msCalendarEvent === null) {
        this.createEvent({
          event: { id: '' },
          hookParameter: { ...this.newEvent, timezone: this.personalAppSettings.timezone },
        }).then(() => {
          this.createLoading = false;
          this.createDialog = false;
          this.connectEventDialog = false;
          this.updateCalendarEvents({ filter: eventFilter(this.searchTerm), pagination: pagination(this.eventPages) });
          this.$emit('close');
        });
        return;
      }
      this.updateEvent({
        event: { id: '' },
        hookParameter: { ...this.newEvent, timezone: this.personalAppSettings.timezone },
      }).then(() => {
        this.createLoading = false;
        this.createDialog = false;
        this.connectEventDialog = false;
        this.updateCalendarEvents({ filter: eventFilter(this.searchTerm), pagination: pagination(this.eventPages) });
        this.$emit('close');
      });
    },
    handleConnectClick() {
      if (!this.msCalendarConnected) {
        this.connectDialog = true;
        this.calendarConnectIntention = 'connect';
        return;
      }

      this.connectEventDialog = true;
    },
    createEvent({ event, hookParameter = null }) {
      return this.msCalendarSvc.createCalendarEvent(
        {
          meeting: { uid: this.meeting.uid },
          dataSource: { uid: this.msCalendarDataSource.uid },
          creator: { uid: this.loggedInUser.uid },
          eventId: event.id,
        },
        undefined,
        hookParameter,
      )
        .then(() => {
          this.$showSnackbar({ color: 'success', message: this.$t('success.created') });
        })
        .catch(logCatch(() => {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
        }));
    },
    updateEvent({ event, hookParameter = null }) {
      return this.msCalendarSvc.updateCalendarEvent(
        {
          uid: this.meeting.msCalendarEvent.uid,
          eventId: event.id,
        },
        undefined,
        hookParameter,
      ).then(() => {
        this.$showSnackbar({ color: 'success', message: this.$t('success.updated') });
      }).catch(logCatch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      }));
    },
    select(event) {
      this.loading[event.id] = true;

      if (this.meeting.msCalendarEvent === null) {
        this.createEvent({ event }).then(() => {
          this.loading[event.id] = false;
          this.connectEventDialog = false;
          this.$emit('close');
        });
        return;
      }

      this.updateEvent({ event }).then(() => {
        this.loading[event.id] = false;
        this.connectEventDialog = false;
        this.$emit('close');
      });
    },
    loadMore() {
      this.eventPages += 1;
      this.eventsLoading = true;
      this.updateCalendarEvents({ filter: eventFilter(this.searchTerm), pagination: pagination(this.eventPages) });
    },
    updateCalendarEvents({ filter, pagination }) {
      this.msCalendarSvc.updateCalendarEvents({ filter, pagination }).then(() => {
        this.setupLoading();
      }).catch(logCatch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      })).finally(() => {
        this.eventsLoading = false;
      });
    },
    setupLoading() {
      if (this.msCalendarDataSource === undefined) {
        this.loading = {};
        return;
      }

      this.loading = this.msCalendarDataSource[customDatasourceProperty.msCalendarEvents].reduce((res, cur) => ({
        ...res,
        [cur.id]: false,
      }), {});
    },
    updateSearchTerm(searchTerm) {
      this.searchTerm = searchTerm;
      this.eventPages = 1;
      const update = () => this.updateCalendarEvents({
        filter: eventFilter(this.searchTerm),
        pagination: pagination(this.eventPages),
      });

      this.debounce(update, 1000);
    },
  },
  mounted() {
    if (this.msCalendarDataSource !== undefined && typeof this.msCalendarDataSource[customDatasourceProperty.msCalendarEvents] !== 'undefined') {
      this.setupLoading();
      return;
    }

    this.eventPages = 1;
    this.updateCalendarEvents({ filter: eventFilter(this.searchTerm), pagination: pagination(this.eventPages) });
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  ._overlay {
    min-width: 25rem;
  }

  ._event-item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    ._event-time {
      color: $font-color-secondary;
    }

    ._left {
      ._title {
        display: flex;
        align-items: center;

        ._text {
          margin-right: .6rem;
        }
      }
    }
  }

  ._loading-spinner {
    display: flex;
    justify-content: center;
  }
</style>
