<template>
  <properties />
</template>

<script>
import Properties from '@/components/Properties.vue';

export default {
  name: 'PropertiesView',
  components: { Properties },
};
</script>

<style scoped lang="scss" type="text/scss">

</style>
