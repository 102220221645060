import useSavedViewCleaner from '@/composables/saved-views/saved-view-cleaner';
import { computed, ref } from 'vue';
import { copy } from 'shared/lib/copy';

export default function useDefaultView(defaultView, defaultProps, viewApplication, space, planning, user, loggedInUser, loggedInUserAccount, gridPageTile, repo) {
  const { clearView } = useSavedViewCleaner();

  const buildDefaultView = (view) => clearView({
    view: {
      uid: 0,
      goalSort: [],
      emoji: '',
      ...copy(view),
      isTemp: true,
      creator: { uid: loggedInUser.value.uid },
      account: { uid: loggedInUserAccount.value.uid },
      viewApplication,
      space,
      planning,
      user,
      gridPageTile,
    },
    props: defaultProps.value,
  });

  const defaultViewCopy = buildDefaultView(defaultView);
  const defaultViewLocal = ref(copy(defaultViewCopy));

  const views = computed(() => {
    if (repo.views.value.length === 0) {
      return [defaultViewLocal.value];
    }

    return repo.views.value;
  });

  const resetView = (id) => {
    const view = views.value.find((v) => v.uid === id);
    if (view === undefined) {
      throw new Error(`view not found with id: ${id}`);
    }

    defaultViewLocal.value = copy(defaultViewCopy);
    return views.value.find((v) => v.uid === id);
  };

  const createViews = (views, viewOrder) => repo.createViews(views.map((v) => buildDefaultView(v)), viewOrder).then((createdViews) => createdViews);

  const updateMetaData = (view) => {
    if (view.isTemp) {
      return repo.createViews([view]);
    }
    return repo.updateMetaData(view);
  };

  return {
    createLoading: repo.createLoading,
    createViews,

    loadingUpdateView: repo.loadingUpdateView,
    updateView: repo.updateView,

    loadingUpdateMetaData: repo.loadingUpdateMetaData,
    updateMetaData,

    loadingDeleteView: repo.loadingDeleteView,
    deleteView: repo.deleteView,

    setParams: repo.setParams,

    resetView,

    hasUnsavedChanges: repo.hasUnsavedChanges,

    views,
  };
}
