<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.228 19.6468C10.8367 20.0415 10.2009 20.0436 9.80964 19.6489L4.2795 14.0698L4.27944 14.0699L2.2 11.9719L4.27944 9.87405L4.27945 9.87406L9.80964 4.29501C10.2009 3.90033 10.8367 3.90245 11.228 4.29713L11.8994 4.9745C12.2858 5.36429 12.2858 5.99267 11.8994 6.38246L7.74488 10.5737L21.7803 10.5737L21.7803 13.3709L7.74557 13.3709L11.8994 17.5614C12.2858 17.9512 12.2858 18.5796 11.8994 18.9694L11.228 19.6468Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default { name: 'LeftArrowLong' };
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>

</style>
