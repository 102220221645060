<template>
  <div>
    <editor-floating-menu
      v-slot="{ menu }"
      :editor="editor"
    >
      <div
        class="_floating-menu"
        :class="{ '-active': menu.isActive && editor.focused }"
        :style="`top: ${menu.top}px; left: ${menu.left}px`"
      >
        {{ $t('mFloatingMenu.backslashHint') }}
      </div>
    </editor-floating-menu>
  </div>
</template>

<script>
import EditorFloatingMenu from '@/tiptap/tiptap/Components/EditorFloatingMenu';

export default {
  name: 'MFloatingMenu',
  props: {
    editor: {
      type: Object,
      required: true,
    },
  },
  components: { EditorFloatingMenu },
};
</script>

<style scoped lang="scss" type="text/scss">
  ._floating-menu {
    position: absolute;
    z-index: 1;
    display: flex;
    margin-top: -.3rem;
    margin-left: 0;
    color: $font-color-tertiary;
    visibility: hidden;
    background-color: inherit;
    opacity: 0;
    transition: opacity .2s, visibility .2s;

    &.-active {
      visibility: visible;
      opacity: 1;
    }
  }
</style>
