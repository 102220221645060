<template>
  <div class="thumbs-up">
    <div class="icon-wrapper-2 anim">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 24 24"
      >
        <!--eslint-disable max-len-->
        <path
          d="M1 21h4V9H1v12zm22-11c0-1.1-.9-2-2-2h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L14.17 1 7.59 7.59C7.22 7.95 7 8.45 7 9v10c0 1.1.9 2 2 2h9c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73v-2z"
        />
        <!--eslint-enable max-len-->
      </svg>
      <div class="border">
        <span />
      </div>
      <div class="spark">
        <span /><span /><span /><span />
        <span /><span /><span /><span />
        <span /><span /><span /><span />
        <span /><span /><span /><span />
        <span /><span /><span /><span />
      </div>
    </div>
  </div>
</template>

<script>

export default { name: 'ThumbsUp' };
</script>

<style scoped lang="scss" type="text/scss">
  $satellite-size: 30px;
  $satellite-move: $satellite-size*7;

  @mixin on-circle($item-count, $circle-size, $item-width, $item-height) {
    position: relative;
    width: $circle-size;
    height: $circle-size;
    border-radius: 50%;

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      width: $item-width;
      height: $item-height;
      margin-top: calc(-1*$item-height/2);
      margin-left: calc(-1*$item-width / 2);
    }

    $angle: calc(360 / $item-count);
    $inc: 0;

    @for $i from 1 through $item-count {
      span:nth-of-type(#{$i}) {
        transform: rotate($inc*-1deg) translate(calc($circle-size / 2)) scale(0);
      }

      $inc: $inc + $angle;
    }
  }

  $spark-width: 25px;
  $spark-height: 4px;
  $item-count: 20;
  $circle-size: 80px;

  .thumbs-up {
    position: relative;
  }

  .icon-wrapper-2 {
    position: relative;
    display: inline-block;
    text-align: center;
    cursor: pointer;

    .icon {
      color: #90a4ae;

      i {
        transform: scale(1);
      }
    }

    .spark {
      @include on-circle($item-count, $circle-size, $spark-width, $spark-height);

      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -40px;
      margin-left: -40px;

      span {
        background: $primary-color;
        border-radius: calc($spark-height / 2);
      }
    }

    &.anim svg {
      fill: $primary-color;
      animation: icon-animation cubic-bezier(.165, .84, .44, 1) 1.5s;
    }

    .border {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 0;
      width: 8.6rem;
      height: 8.6rem;
      margin-top: -4.3rem;
      margin-left: -4.3rem;
      transition: all ease .8s;
      transform-origin: 0 0;

      span {
        position: absolute;
        top: -.3rem;
        left: -.3rem;
        width: 100%;
        height: 100%;
        border: .3rem solid $primary-color;
        border-radius: 50%;
      }
    }

    &.anim .border span {
      animation: border-animation cubic-bezier(.165, .84, .44, 1) 1.9s;
      animation-fill-mode: forwards;
    }
  }

  @keyframes border-animation {
    0% {
      transform: scale(0);
    }

    100% {
      transform: scale(1);
    }
  }

  @keyframes satellite-top {
    0% {
      transform: scale(1) translate(0, 0);
    }

    100% {
      transform: scale(0) translate(0, -$satellite-move);
    }
  }

  @keyframes satellite-bottom {
    0% {
      transform: scale(1) translate(0, 0);
    }

    100% {
      transform: scale(0) translate(0, $satellite-move);
    }
  }

  @keyframes satellite-top-right {
    0% {
      transform: scale(1) translate(0, 0);
    }

    100% {
      transform: scale(0) translate(calc(2*$satellite-move/2.236), calc(-1*$satellite-move/2.236));
    }
  }

  @keyframes satellite-bottom-right {
    0% {
      transform: scale(1) translate(0, 0);
    }

    100% {
      transform: scale(0) translate(calc(2*$satellite-move/2.236), calc($satellite-move/2.236));
    }
  }

  @keyframes satellite-bottom-left {
    0% {
      transform: scale(1) translate(0, 0);
    }

    100% {
      transform: scale(0) translate(calc(-2*$satellite-move/2.236), calc($satellite-move/2.236));
    }
  }

  @keyframes satellite-top-left {
    0% {
      transform: scale(1) translate(0, 0);
    }

    100% {
      transform: scale(0) translate(calc(-2*$satellite-move/2.236), calc(-1*$satellite-move/2.236));
    }
  }

  @keyframes icon-animation {
    0% {
      transform: scale(0);
    }

    100% {
      transform: scale(1);
    }
  }

  .icon-wrapper-2.anim {
    .spark {
      $inc: 0;
      $angle: calc(360 / $item-count);

      @for $i from 1 through $item-count {
        span:nth-of-type(#{$i}) {
          animation: spark-animation-#{$i} cubic-bezier(.075, .82, .165, 1) 2.5s;
        }

        $inc: $inc + $angle;
      }
    }
  }

  $angle: calc(360 / $item-count);
  $inc: 0;

  @for $i from 1 through $item-count {
    @keyframes spark-animation-#{$i} {
      0% {
        opacity: 1;
        transform: rotate($inc*-1deg) translate(calc($circle-size / 2)) scale(1);
      }

      80% {
        opacity: 1;
      }

      100% {
        opacity: 0;
        transform: rotate($inc*-1deg) translate($circle-size*1.2) scale(0);
      }
    }

    $inc: $inc + $angle;
  }
</style>
