<template>
  <m-card
    class="card-button"
    :level="hover ? 2 : 1"
    :style="{ backgroundColor: $colors.blue.base }"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <div class="_inner">
      <div>
        <div class="_icon">
          <m-icon
            color="white"
            size="20"
            :type="icon"
          />
        </div>
        <h5 class="_title">
          {{ title }}
        </h5>
      </div>
    </div>
  </m-card>
</template>

<script>
export default {
  name: 'CardButton',
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: 'plus',
    },
  },
  data() {
    return { hover: false };
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  @import "shared/assets/scss/box-shadow";
  @import "shared/assets/scss/padding";

  .card-button {
    width: 17.6rem;
    height: 21rem;
    cursor: pointer;

    @include box_shadow(1);

    &:hover {
      @include box_shadow(2);
    }

    ._inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    ._icon {
      margin-bottom: 1.2rem;
      text-align: center;
    }

    ._title {
      color: white;
      text-align: center;
    }
  }
</style>
