import { RRule } from 'rrule';

export const englishStrings = (freq, byweekday, interval, bysetpos) => {
  const translations = {
    every: 'Every',
    until: 'until',
    day: 'day',
    days: 'days',
    week: 'week',
    weeks: 'weeks',
    on: 'on',
    in: 'in',
    'on the': 'on the',
    for: 'for',
    and: 'and',
    or: 'or',
    at: 'at',
    last: 'last',
    '(~ approximate)': '(~ approximate)',
    times: 'times',
    time: 'time',
    minutes: 'minutes',
    hours: 'hours',
    weekdays: 'weekdays',
    weekday: 'weekday',
    months: 'months',
    month: 'month',
    years: 'years',
    year: 'year',
    dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    monthNames: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
  };
  if (freq === RRule.MONTHLY) {
    if (bysetpos !== null && bysetpos !== 'undefined') {
      translations.on = `on ${bysetpos.sort((a, b) => {
        if (a === -1) {
          return 1;
        }
        if (b === -1) {
          return -1;
        }
        return a > b;
      }).join('., ')}.`;
      translations.on = translations.on.replace('-1.', 'last');
      translations.on = translations.on.replace('1.', '1st');
      translations.on = translations.on.replace('2.', '2nd');
      translations.on = translations.on.replace('3.', '3rd');
      translations.on = translations.on.replace('4.', '4th');
    }
  }

  return translations;
};
