<template>
  <svg
    viewBox="0 0 16 16"
    data-icon="restore"
    width="1em"
    height="1em"
    fill="currentColor"
    aria-hidden="true"
    focusable="false"
    class=""
  >
    <path d="M6.4704 1.0693C6.8095 1.38047 6.83216 1.90762 6.52099 2.24673L3.89567 5.10782H10.093C11.6867 5.10782 12.9252 5.64487 13.7424 6.6607C14.5341 7.64498 14.8333 8.96 14.8333 10.3409C14.8333 11.6725 14.6234 12.9269 13.8234 13.8363C13.002 14.77 11.7355 15.1482 10.093 15.1482H7.72093C7.2607 15.1482 6.8876 14.7751 6.8876 14.3149C6.8876 13.8547 7.2607 13.4816 7.72093 13.4816H10.093C11.5203 13.4816 12.2073 13.1501 12.572 12.7355C12.958 12.2966 13.1667 11.564 13.1667 10.3409C13.1667 9.16713 12.9078 8.28226 12.4437 7.70533C12.005 7.15996 11.2901 6.77448 10.093 6.77448H3.85447L6.5301 9.78759C6.83569 10.1317 6.80445 10.6584 6.46031 10.964C6.11617 11.2696 5.58946 11.2384 5.28386 10.8942L1.37689 6.49448C1.09315 6.17495 1.09709 5.69259 1.38599 5.37773L5.29297 1.1199C5.60414 0.780791 6.13129 0.758138 6.4704 1.0693Z" />
  </svg>
</template>

<script>
export default { name: 'Restore' };
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>

</style>
