<template>
  <m-checkbox
    :value="selected"
    :label="textByLang(question.field.title, userLang)"
    wrap-label
    class="template-question-item"
    :label-style="{ whiteSpace: 'normal' }"
    @input="$emit('select')"
  />
</template>

<script>
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import { textByLang } from 'shared/lib/language';

export default {
  name: 'TemplateQuestionItem',
  props: {
    question: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { userLang } = useLoggedInUser();
    return { userLang };
  },
  emits: ['select'],
  data() {
    return { textByLang };
  },
};
</script>

<style scoped lang="scss" type="text/scss">

</style>
