const distance = 1;

export default function useBuchheim(childrenKey, subTreeDistance) {
  const initialize = (node, depth, parent, childIndex) => {
    if (node[childrenKey] === undefined) {
      node[childrenKey] = [];
    }
    for (let i = 0; i < node[childrenKey].length; i++) {
      node[childrenKey][i] = initialize(node[childrenKey][i], depth + 1, node, i);
    }
    node.x = 0;
    node.y = depth;
    node.mod = 0;
    node.change = 0;
    node.shift = 0;
    node.thread = null;
    node.ancestor = node;
    node.childIndex = childIndex;
    node.parent = parent;
    return node;
  };

  const executeShifts = (v) => {
    let shift = 0;
    let change = 0;
    for (let i = v[childrenKey].length - 1; i >= 0; i--) {
      v[childrenKey][i].x += shift;
      v[childrenKey][i].mod += shift;
      change += v[childrenKey][i].change;
      shift += v[childrenKey][i].shift + change;
    }
  };

  const leftSibling = (node) => {
    if (node.parent === null) {
      return null;
    }
    if (node.childIndex === 0) {
      return null;
    }
    return node.parent[childrenKey][node.childIndex - 1];
  };

  const leftMostSibling = (node) => {
    if (node.parent === null) {
      return null;
    }
    if (node.childIndex === 0) {
      return null;
    }
    return node.parent[childrenKey][0];
  };

  const isLeftMostSibling = (node) => {
    if (node.parent === null) {
      return false;
    }
    return node.childIndex === 0;
  };

  const nextRight = (node) => {
    if (node[childrenKey].length > 0) {
      return node[childrenKey][node[childrenKey].length - 1];
    }

    return node.thread;
  };

  const nextLeft = (node) => {
    if (node[childrenKey].length > 0) {
      return node[childrenKey][0];
    }

    return node.thread;
  };

  const ancestor = (vil, v, defaultAncestor) => {
    if (vil.ancestor.parent.index === v.parent.index) {
      return vil.ancestor;
    }
    return defaultAncestor;
  };

  const moveSubTree = (wl, wr, shift) => {
    const subTrees = wr.childIndex - wl.childIndex;
    const shiftBySubTrees = shift / subTrees;
    wr.change -= shiftBySubTrees;
    wl.change += shiftBySubTrees;
    wr.shift += shift;
    wr.x += shift;
    wr.mod += shift;
  };

  const apportion = (v, defaultAncestor, subTreeDistance = 0) => {
    const w = leftSibling(v);
    if (w !== null) {
      let vir = v;
      let vor = v;
      let vil = w;
      let vol = leftMostSibling(vir);
      let sir = vir.mod;
      let sor = vor.mod;
      let sil = vil.mod;
      let sol = vol.mod;

      while (nextRight(vil) !== null && nextLeft(vir) !== null) {
        vil = nextRight(vil);
        vir = nextLeft(vir);
        vol = nextLeft(vol);
        vor = nextRight(vor);
        vor.ancestor = v;

        const shift = (vil.x + sil) - (vir.x + sir) + (distance + subTreeDistance);
        if (shift > 0) {
          const a = ancestor(vil, v, defaultAncestor);
          moveSubTree(a, v, shift);
          sir += shift;
          sor += shift;
        }
        sil += vil.mod;
        sir += vir.mod;
        sol += vol.mod;
        sor += vor.mod;
      }
      if (nextRight(vil) !== null && nextRight(vor) === null) {
        vor.thread = nextRight(vil);
        vor.mod += sil - sor;
      }
      if (nextLeft(vir) !== null && nextLeft(vol) === null) {
        vol.thread = nextLeft(vir);
        vol.mod += sir - sol;
        defaultAncestor = v;
      }
    }
    return defaultAncestor;
  };

  const firstWalk = (v, subTreeDistance) => {
    if (v[childrenKey].length === 0) {
      if (isLeftMostSibling(v)) {
        v.x = 0;
        return;
      }
      v.x = leftSibling(v).x + distance;
      return;
    }

    let defaultAncestor = v[childrenKey][0];
    for (let i = 0; i < v[childrenKey].length; i++) {
      firstWalk(v[childrenKey][i], subTreeDistance);
      defaultAncestor = apportion(v[childrenKey][i], defaultAncestor, subTreeDistance);
    }

    executeShifts(v);

    const midpoint = (v[childrenKey][0].x + v[childrenKey][v[childrenKey].length - 1].x) / 2;
    const w = leftSibling(v);
    if (w !== null) {
      v.x = w.x + distance;
      v.mod = v.x - midpoint;
    } else {
      v.x = midpoint;
    }
  };

  const secondWalk = (v, modifier = 0) => {
    for (let i = 0; i < v[childrenKey].length; i++) {
      secondWalk(v[childrenKey][i], modifier + v.mod);
    }
    v.x += modifier;
  };

  const buchheimLayout = (root) => {
    const res = initialize(root, 0, null, 0);
    firstWalk(res, subTreeDistance);
    secondWalk(res);
    return res;
  };

  return { buchheimLayout };
}
