<template>
  <div :class="['goal-list-status-chart']">
    <no-data v-if="chartData.length === 0" />
    <base-chart
      v-else
      :options="chartOptions"
    />
  </div>
</template>

<script>

import BaseChart from '@/components/dashboard/BaseChart.vue';
import NoData from '@/components/dashboard/NoData.vue';
import { AREA, DASH, DATETIME, DOT } from '@/lib/constants';
import { linearRegression } from '@/lib/charts/line-chart';
import { rgbaToHex } from 'shared/lib/color';

export default {
  name: 'GoalListStatusChart',
  props: {
    startDate: {
      type: Date,
      required: true,
    },
    endDate: {
      type: Date,
      required: true,
    },
    chartData: {
      type: Array,
      default: () => [],
    },
  },
  components: { BaseChart, NoData },
  computed: {
    chartOptions() {
      return {
        plotOptions: {
          area: {
            stacking: 'percent',
            lineWidth: 2,
          },
        },
        series: [
          ...this.chartData.map((c) => ({
            states: { inactive: { opacity: 1 } },
            type: AREA,
            lineColor: c.color,
            lineWidth: 0,
            data: this.calculateTrend(c.data),
            color: c.color,
            fillOpacity: 0.4,
            dashStyle: DASH,
            marker: {
              enabled: false,
              lineWidth: 2,
            },
            enableMouseTracking: false,
          })),
          ...this.chartData.map((c) => ({
            states: { inactive: { opacity: 1 } },
            type: AREA,
            data: c.data,
            lineWidth: 0,
            color: c.color,
            marker: {
              enabled: false,
              fillColor: this.$colors.shades.white,
              lineWidth: 2,
              lineColor: null, // inherit from series
              symbol: 'circle',
            },
            name: c.name,
          })),
        ],
        xAxis: {
          lineColor: rgbaToHex(this.$colors.grey.lighten3),
          lineWidth: 1,
          type: DATETIME,
          minTickInterval: 28 * 24 * 3600 * 1000,
          minRange: 28 * 24 * 3600 * 1000,
          min: Number(this.startDate),
          max: Number(this.endDate),
        },
        yAxis: {
          min: 0,
          max: 100,
          lineWidth: 0,
          gridLineColor: rgbaToHex(this.$colors.grey.lighten2),
          gridLineDashStyle: DOT,
          title: { text: '' },
          labels: {
            formatter() {
              return `${this.value} %`;
            },
          },
        },
      };
    },
  },
  methods: {
    calculateTrend(line) {
      const lineData = line.filter((e) => e.x >= Number(this.startDate) && e.x <= Number(this.endDate))
        .map((e) => ({
          x: e.x,
          y: e.y,
        }))
        .sort((a, b) => (a.x <= b.x ? -1 : 1));
      if (lineData.length === 0
          || lineData[lineData.length - 1].x >= Number(this.endDate)) {
        return [];
      }

      const lastPoint = line[line.length - 1];
      const { slope, intercept } = linearRegression(line);
      const lastY = Math.max(0, intercept + (slope * Number(this.endDate)));
      return [
        { x: lastPoint.x + 1, y: lastPoint.y },
        { x: Number(this.endDate), y: lastY },
      ];
    },
  },
};
</script>
