<template>
  <m-dropdown
    v-model:value="show"
    :title="$t('createUpdateBtn.selectTemplateTitle')"
    class="create-update-btn"
  >
    <m-btn
      :loading="createLoading"
      color="primary"
      class="_btn -create"
      small
      :disabled="disabled"
      @click="show = true"
    >
      {{ $t('general.new') }}
    </m-btn>
    <template #overlay>
      <m-card
        no-padding
        list
        class="_overlay"
      >
        <m-card-item
          v-for="item in activeSchedules"
          :key="item.uid"
          :emoji="item.icon"
          @click="createUpdate(item)"
        >
          {{ item.title === '' ? $t('general.untitled') : item.title }}
        </m-card-item>
        <m-divider
          v-if="activeSchedules.length > 0"
          xxs
        />
        <m-card-item
          icon="plus"
          light
          @click="createFromScratch"
        >
          {{ $t('createUpdateBtn.newFromScratch') }}
        </m-card-item>
      </m-card>
    </template>
  </m-dropdown>
</template>

<script>
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useUpdateSchedules from '@/composables/update-schedules/update-schedules';

export default {
  name: 'CreateUpdateBtn',
  props: {
    createLoading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return { show: false };
  },
  setup() {
    const { schedules } = useUpdateSchedules();
    const { loggedInUser } = useLoggedInUser();
    return { schedules, loggedInUser };
  },
  computed: {
    activeSchedules() {
      return this.schedules.filter((n) => n.activatedAt !== null
        && n.recipientsList.filter((u) => u.uid === this.loggedInUser.uid).length > 0);
    },
  },
  methods: {
    createUpdate(schedule) {
      let templateId = 0;
      if (schedule.updateTemplate !== null) {
        templateId = schedule.updateTemplate.uid;
      }

      this.$router.push({ query: { ...this.$route.query, create: '', templateId, notificationId: schedule.uid } });
      this.show = false;
    },
    createFromScratch() {
      this.$router.push({ query: { ...this.$route.query, create: '' } });
      this.show = false;
    },
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  ._overlay {
    ._empty {
      max-width: 30rem;
      color: $font-color-secondary;
    }
  }
</style>
