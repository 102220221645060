<template>
  <div class="publish-button">
    <m-card-item
      hide-border
      full-width
      :disabled="disabled"
      @click="published = !published"
    >
      <settings-switch-item
        class="_action"
        icon="global"
        :title="$t('publishButton.header')"
        :sub-title="$t('publishButton.description')"
        :value="hasPublishedViews && published"
        :disabled="disabled"
      >
        <template #after-title>
          <plan-tag
            class="_plan-tag"
            :feature-flag="featureFlag.publishedViews"
          />
        </template>
      </settings-switch-item>
    </m-card-item>
    <m-card-item
      v-if="hasPublishedViews && published"
      no-hover
      full-width
    >
      <div class="_url">
        <m-input
          v-model:value="url"
          class="_input"
          disabled
          full-width
          :style="{borderTopRightRadius: 0, borderBottomRightRadius: 0, cursor: 'default' }"
        />
        <m-btn
          v-clipboard="url"
          v-clipboard:error="onError"
          v-clipboard:success="handleClose"
          class="_btn"
          block
          :style="{borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }"
        >
          {{ $t('publishButton.copyLink') }}
        </m-btn>
      </div>
    </m-card-item>
  </div>
</template>

<script>
import PlanTag from '@/components/plans/PlanTag.vue';
import SettingsSwitchItem from '@/components/SettingsSwitchItem.vue';
import useAccess from '@/composables/access/access';
import useSavedViewRepo from '@/composables/saved-views/saved-view-repo';
import { EVENTS } from '@/lib/constants';
import { EventBus } from '@/lib/event-bus';
import { encodeURLValues } from '@/lib/url';
import { featureFlag } from 'shared/constants.json';

export default {
  name: 'PublishButton',
  props: {
    selectedView: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
  },
  components: { SettingsSwitchItem, PlanTag },
  emits: ['close'],
  setup() {
    const { accountHasFeature } = useAccess();
    const { updateSingle } = useSavedViewRepo();
    return { accountHasFeature, updateSingle };
  },
  data() {
    return {
      published: false,
      featureFlag,
    };
  },
  computed: {
    hasPublishedViews() {
      return this.accountHasFeature([featureFlag.publishedViews]);
    },
    url() {
      const values = encodeURLValues({ s: btoa(this.selectedView.passphrase) });
      return `${window.location.origin}/#/public/${this.selectedView.uid}?${values}`;
    },
  },
  methods: {
    handleClose() {
      this.$showSnackbar({
        color: 'success',
        message: this.$t('analyticAccess.copySuccess'),
      });
      this.$emit('close');
    },
    onError() {
      this.$showSnackbar({ color: 'error', message: this.$t('error.duringCopying') });
    },
  },
  watch: {
    published(newVal) {
      if (newVal === this.selectedView.isPublished) {
        return;
      }
      const view = {
        ...this.selectedView,
        isPublished: newVal,
      };
      this.updateSingle(view).then(() => {
        if (view.isPublished === true) {
          EventBus.$emit(EVENTS.VIEW.VIEW_PUBLISHED);
          return;
        }
        EventBus.$emit(EVENTS.VIEW.VIEW_UNPUBLISHED);
      }).catch(() => this.$showSnackbar({ color: 'error', message: this.$t('error.default') }));
    },
  },
  created() {
    this.published = this.selectedView.isPublished;
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .publish-button {
    min-width: 40rem;

    ._action {
      display: flex;
      align-items: center;
      width: 100%;

      ._plan-tag {
        margin-left: .8rem;
      }
    }

    ._url {
      display: flex;
      width: 100%;
      margin-top: .4rem;

      ._input {
        flex: 1 1 25rem;
      }

      ._btn {
        flex: 0 0 10rem;
      }
    }
  }
</style>
