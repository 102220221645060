<template>
  <div class="form-tags">
    <page-header
      color="white"
      :title="$t('formTags.heading')"
      heading="h3"
    >
      <template #actions>
        <div
          class="_actions"
        >
          <m-btn
            icon="close"
            hide-border
            fab
            :to="createUrl"
          />
        </div>
      </template>
    </page-header>
    <div class="_inner">
      <m-content padding>
        <div class="_description">
          {{ $t('formTags.description') }}
        </div>
        <m-select
          v-model:value="value"
          class="_select"
          popup
          show-search
          :items="items"
          keep-open-on-click
          :disabled="disabled"
          match-trigger-width
          item-text="name"
          value-key="uid"
          tags
          multiple
          :empty-item="{ uid: 0, name: '' }"
          show-description
          return-object
        />
      </m-content>
    </div>
  </div>
</template>

<script>
import PageHeader from 'shared/components/PageHeader.vue';
import useDebounce from '@/composables/debounce';
import { TYPE, UID } from 'shared/api/query/constants';
import { dogma } from '@/api';
import { formTemplateTag as formTemplateTagConfig } from 'shared/api/query/configs.json';
import { uniqBy } from 'lodash-es';

export default {
  name: 'FormTags',
  props: {
    createUrl: {
      type: Object,
      required: true,
    },
    entity: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: { PageHeader },
  data() {
    return { allItems: [] };
  },
  setup() {
    const { debounce } = useDebounce();
    return { debounce };
  },
  computed: {
    items() {
      return uniqBy([...this.entity.tags, ...this.allItems], 'name');
    },
    value: {
      get() {
        return this.entity.tags;
      },
      set(val) {
        this.$store.commit('TEMPLATE_TAGS_CHANGED', { tags: val });
        this.updateItems(val);
      },
    },
  },
  methods: {
    retrieveAllTags() {
      dogma.query([
        {
          alias: 'items',
          func: { name: TYPE, args: [{ value: formTemplateTagConfig.model }] },
          model: formTemplateTagConfig.model,
          children: [
            { attr: UID },
            { attr: formTemplateTagConfig.edges.name },
          ],
        },
      ]).then((response) => {
        if (response.status !== 200) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
          return;
        }
        this.allItems = response.data.items;
      });
    },
    updateItems(tags) {
      const nodes = tags.filter((t) => typeof t.uid === 'number');
      this.allItems = tags.filter((t) => typeof t.deletedAt === 'undefined');

      const update = () => dogma.update(nodes, formTemplateTagConfig.model, [
        { attr: UID },
        { attr: formTemplateTagConfig.edges.name },
      ]).then((response) => {
        if (response.status !== 200) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
        }
      });

      this.debounce(update, 500);
    },
  },
  mounted() {
    this.retrieveAllTags();
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .form-tags {
    width: 100%;

    ._inner {
      ._description {
        margin-bottom: 2rem;
        color: $font-color-secondary;
      }

      ._select {
        width: 100%;
      }
    }
  }
</style>
