import { guid } from 'shared/lib/uuid';
import { userScopeType } from 'shared/constants.json';

const baseFilter = ({ account, op = 'and' }) => ({
  account,
  op,
  children: [],
});

export const filter = ({ property, space, account }) => {
  if (property === null) {
    return null;
  }
  return {
    account,
    op: 'or',
    children: [
      {
        key: guid(),
        op: 'and',
        children: [
          {
            op: 'and',
            scope: {
              isEmpty: false,
              property,
              type: userScopeType.property,
              spaces: [{ uid: space.uid }],
            },
          },
        ],
      },
    ],
  };
};

export const appendOptions = ({ filterTree, property = null, spaces, account }) => {
  if (property === null) {
    return filterTree;
  }
  let base = filterTree;
  if (base === null) {
    base = baseFilter({ account });
  }

  return {
    ...base,
    children: [
      ...base.children,
      {
        key: guid(),
        op: 'and',
        children: [
          {
            op: 'and',
            scope: {
              type: userScopeType.property,
              property,
              spaces: spaces.map((o) => ({ uid: o.uid })),
            },
          },
        ],
      },
    ],
  };
};
