<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.1757 7.54289C15.7852 7.15237 15.152 7.15237 14.7615 7.54289L14.3704 7.93398C13.9799 8.3245 13.9799 8.95767 14.3704 9.34819L15.7393 10.7171H8.22289L9.59176 9.34819C9.98229 8.95767 9.98229 8.32451 9.59176 7.93398L9.20068 7.54289C8.81015 7.15237 8.17699 7.15237 7.78646 7.54289L4.04289 11.2865C3.65237 11.677 3.65237 12.3102 4.04289 12.7007L7.78646 16.4442C8.17699 16.8348 8.81015 16.8348 9.20068 16.4442L9.59176 16.0532C9.98229 15.6626 9.98229 15.0295 9.59176 14.6389L8.22296 13.2701H15.7392L14.3704 14.6389C13.9799 15.0295 13.9799 15.6626 14.3704 16.0532L14.7615 16.4442C15.152 16.8348 15.7852 16.8348 16.1757 16.4442L19.9193 12.7007C20.3098 12.3102 20.3098 11.677 19.9193 11.2865L16.1757 7.54289Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 5.75C2.69036 5.75 3.25 6.30964 3.25 7L3.25 17C3.25 17.6904 2.69036 18.25 2 18.25C1.30964 18.25 0.75 17.6904 0.75 17L0.75 7C0.75 6.30964 1.30964 5.75 2 5.75Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22 5.75C22.6904 5.75 23.25 6.30964 23.25 7V17C23.25 17.6904 22.6904 18.25 22 18.25C21.3096 18.25 20.75 17.6904 20.75 17V7C20.75 6.30964 21.3096 5.75 22 5.75Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default { name: 'FitToScreen' };
</script>

<style scoped lang="scss" type="text/scss">

</style>
