<template>
  <div class="base-chart">
    <highcharts
      ref="chart"
      class="_chart"
      :options="optionsWithDefaults"
      :update-args="updateArgs"
    />
  </div>
</template>

<script>
import { Chart } from 'highcharts-vue';

export default {
  name: 'BaseChart',
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  components: { highcharts: Chart },
  data() {
    return {
      updateArgs: [true, true, true],
      resizeObserver: null,
    };
  },
  computed: {
    optionsWithDefaults() {
      return {
        chart: {
          height: 250,
          marginBottom: 22,
          marginRight: 8,
        },
        ...this.options,
      };
    },
  },
  mounted() {
    this.resizeObserver = new ResizeObserver(() => this.$refs.chart.chart.reflow());
    this.resizeObserver.observe(this.$refs.chart.$el);
  },
  unmounted() {
    this.resizeObserver.disconnect();
  },
};
</script>

<style lang="scss" type="text/scss">
  .base-chart {
    ._chart {
      overflow: visible !important;

      .highcharts-container {
        overflow: visible !important;

        svg {
          overflow: visible !important;
        }
      }
    }
  }
</style>
