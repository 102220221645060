import { INDENTATION_WIDTH } from 'shared/constants';
import { computed, ref } from 'vue';
import { getCascadeOperation } from '@/lib/sort-operation';

export default function useCascadeDragAndDrop(
  collection,
  elReader,
  actionExecutor,
  actionValidator,
) {
  const dragOverBottom = ref([]);
  const dragOverTop = ref([]);
  const dragOver = ref([]);
  const dragItem = ref(null);

  const initialLeftPosition = ref(0);

  const dragDepth = ref(0);

  const dragItemFull = computed(() => collection.value.find((el) => elReader.getId(el) === dragItem.value));

  const setDragDepth = (anchor, event, dragTarget) => {
    if (dragItemFull.value === undefined) {
      return;
    }

    if (anchor === undefined) {
      return;
    }

    if (dragTarget.dragOver === true) {
      dragDepth.value = elReader.getDepth(anchor) + 1;
      return;
    }

    if (dragTarget.dragOverTop === true) {
      dragDepth.value = elReader.getDepth(anchor);
      return;
    }

    const d = Math.floor((event.clientX - initialLeftPosition.value) / INDENTATION_WIDTH);
    if (!elReader.isLastSibling(anchor)) {
      dragDepth.value = elReader.getDepth(anchor);
      return;
    }

    dragDepth.value = Math.min(d, elReader.getDepth(anchor));
  };

  const resetDragging = () => {
    initialLeftPosition.value = 0;
    dragDepth.value = 0;
    dragOverBottom.value = [];
    dragOverTop.value = [];
    dragOver.value = [];
    dragItem.value = null;
  };

  const setDragItem = (itemId, left) => {
    dragItem.value = itemId;
    initialLeftPosition.value = left;
  };

  const dropItem = () => {
    const anchorIndex = [...dragOverBottom.value, ...dragOverTop.value, ...dragOver.value][0];
    const anchor = collection.value.find((el) => elReader.getId(el) === anchorIndex);
    if (anchor === undefined) {
      resetDragging();
      return new Promise((resolve) => { resolve(); });
    }

    let dragTarget = { dragOver: true };
    if (dragOverTop.value.length > 0) {
      dragTarget = { dragOverTop: true };
    }
    if (dragOverBottom.value.length > 0) {
      dragTarget = { dragOverBottom: true };
    }

    const operation = getCascadeOperation([dragItem.value], anchorIndex, dragTarget, dragDepth.value);
    return actionExecutor.executeDrag(operation)
      .finally(() => resetDragging());
  };

  const setDragOverBottom = (keys, event) => {
    if (keys.length === 0) {
      dragOverBottom.value = [];
      return;
    }

    const newAnchor = collection.value.find((el) => elReader.getId(el) === keys[0]);
    // if is not expanded or does not have children
    if (newAnchor === undefined || (elReader.isExpanded(newAnchor) && elReader.hasChildren(newAnchor))) {
      dragOverBottom.value = [];
      return;
    }

    if (dragItemFull.value === undefined) {
      dragOverBottom.value = [];
      return;
    }

    const dragTarget = { dragOverBottom: true };
    setDragDepth(newAnchor, event, dragTarget);
    const operation = getCascadeOperation([dragItem.value], keys[0], dragTarget, dragDepth.value);
    const { isValid } = actionValidator.isValidDrag(operation);
    if (!isValid) {
      dragOverBottom.value = [];
      return;
    }

    dragOverBottom.value = keys;
  };

  const setDragOverTop = (keys, event) => {
    if (keys.length === 0) {
      dragOverTop.value = [];
      return;
    }

    const newAnchor = collection.value.find((el) => elReader.getId(el) === keys[0]);
    if (newAnchor === undefined || !elReader.isFirstSibling(newAnchor)) {
      dragOverTop.value = [];
      return;
    }

    if (dragItemFull.value === undefined) {
      dragOverTop.value = [];
      return;
    }

    const dragTarget = { dragOverTop: true };
    setDragDepth(newAnchor, event, dragTarget);
    const operation = getCascadeOperation([dragItem.value], keys[0], dragTarget, dragDepth.value);
    const { isValid } = actionValidator.isValidDrag(operation);
    if (!isValid) {
      dragOverTop.value = [];
      return;
    }

    dragOverTop.value = keys;
  };

  const setDragOver = (keys, event) => {
    if (keys.length === 0) {
      dragOver.value = [];
      return;
    }
    const newAnchor = collection.value.find((el) => elReader.getId(el) === keys[0]);
    if (newAnchor === undefined) {
      dragOver.value = [];
      return;
    }
    if (dragItemFull.value === undefined) {
      dragOver.value = [];
      return;
    }

    const dragTarget = { dragOver: true };
    setDragDepth(newAnchor, event, dragTarget);
    const operation = getCascadeOperation([dragItem.value], keys[0], dragTarget, dragDepth.value);
    const { isValid } = actionValidator.isValidDrag(operation);
    if (!isValid) {
      dragOver.value = [];
      return;
    }

    dragOver.value = keys;
  };

  return {
    dragItem,
    dropItem,
    dragDepth,
    dragOverBottom,
    dragOverTop,
    setDragItem,
    setDragOver,
    dragOver,
    setDragOverBottom,
    setDragOverTop,
    resetDragging,
  };
}
