import useGoalProperty from '@/composables/property/goal-property';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import { computed } from 'vue';
import { createPropsList } from '@/lib/props';
import { directProperties } from '@/lib/updates/properties';
import { goal as goalConfig, update as updateConfig } from 'shared/api/query/configs.json';
import { useI18n } from 'vue-i18n';

export default function useUpdateFeedDefaultProps() {
  const i18n = useI18n();

  const { userLang } = useLoggedInUser();

  const goalPropertySvc = useGoalProperty();

  const defaultProps = computed(() => [
    ...createPropsList({
      properties: goalPropertySvc.properties.value,
      directProperties: [],
      userLang: userLang.value,
      model: goalConfig.model,
    }),
    ...createPropsList({
      properties: [],
      directProperties: directProperties(i18n.t),
      userLang: userLang.value,
      model: updateConfig.model,
    }),
  ]);

  return { defaultProps };
}
