import { UID, VAR } from 'shared/api/query/constants';
import { notHasFilter } from 'shared/api/query/filter';
import { property as propertyConfig, propertyValue as propertyValueConfig, space as spaceConfig } from 'shared/api/query/configs.json';
import { reverseEdge } from 'shared/api/query/edges';

export const makeSpaceFn = (childrenFn, varIdFn, isFilterMode = false) => (scope, index) => {
  const varName = varIdFn(index);

  if (scope.isEmpty === true) {
    const varBlocks = [
      {
        alias: VAR,
        uid: [scope.property.uid],
        model: propertyConfig.model,
        func: { name: UID },
        children: [
          {
            attr: reverseEdge(propertyValueConfig.edges.property),
            model: propertyValueConfig.model,
            filter: notHasFilter(propertyValueConfig.edges.spaces),
            children: childrenFn(varName),
          },
        ],
      },
    ];
    const filterTree = {
      func: {
        name: UID,
        needsVar: [{ name: varName, typ: 1 }],
      },
    };
    return { filterTrees: [filterTree], varBlocks };
  }

  if ((scope.spaces === undefined || scope.spaces.length === 0) && isFilterMode) {
    return { filterTrees: [], varBlocks: [] };
  }

  const spaceIds = scope.spaces.map((u) => u.uid);
  const varBlocks = [
    {
      alias: VAR,
      uid: spaceIds,
      model: spaceConfig.model,
      func: { name: UID },
      children: [
        {
          attr: reverseEdge(propertyValueConfig.edges.spaces),
          model: propertyValueConfig.model,
          children: childrenFn(varName),
        },
      ],
    },
  ];

  const filterTree = {
    func: {
      name: UID,
      needsVar: [{ name: varName, typ: 1 }],
    },
  };
  return { filterTrees: [filterTree], varBlocks };
};

export const directPropSpaceFn = (model, varIdFn, isFilterMode = false) => (scope, index) => {
  let spaceIds = scope.spaces.map((u) => u.uid);
  if (scope.isEmpty === true) {
    spaceIds = [];
  }

  if (spaceIds.length === 0 && isFilterMode && !scope.isEmpty) {
    return { filterTrees: [], varBlocks: [] };
  }

  const varName = varIdFn(index);
  const varBlocks = [
    {
      alias: VAR,
      uid: spaceIds,
      model: spaceConfig.model,
      func: { name: UID },
      children: [
        {
          attr: reverseEdge(scope.directProperty.edgeName),
          var: varName,
          model,
        },
      ],
    },
  ];

  const filterTree = {
    func: {
      name: UID,
      needsVar: [{ name: varName, typ: 1 }],
    },
  };
  return { filterTrees: [filterTree], varBlocks };
};
