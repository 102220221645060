import { applyGoalModifiers } from '@/lib/goal/create';

import { EVENTS } from '@/lib/constants';
import { EventBus } from '@/lib/event-bus';

export default function useGoalCreator(
  store,
  goalsSvc,
  viewSorter,
  goalDetailSorter,
  goalTypeProperty,
  goalModifiers,
  currentViewService,
  selectedCycles,
) {
  const splitNode = (toSplit, parentIds, nodes) => {
    const parents = goalsSvc.selectMultiple(parentIds);
    const childrenLists = parents.map((p) => p.children.map((c) => c.uid));
    return addChild(parentIds)
      .then((newGoal) => {
        const p1 = goalsSvc.changeParents({ goals: [toSplit], parents: [newGoal] }, { showPrompt: false })
          .then(() => newGoal);

        if (parents.length > 0) {
          const promises = [];
          parents.forEach((p, i) => {
            promises.push(goalDetailSorter.listBefore(p)([...childrenLists[i], newGoal.uid], toSplit.uid, [newGoal.uid]));
          });
          return Promise.all([p1, ...promises]).then(() => newGoal);
        }

        const rootList = nodes.filter((g) => g.isRoot).map((g) => g.entity.uid);
        const p2 = viewSorter.listBefore(currentViewService.currentView.value)([...rootList, newGoal.uid], toSplit.uid, [newGoal.uid]);
        return Promise.all([p1, p2]).then(() => newGoal);
      });
  };

  const addLeftSibling = (anchor, parentId, nodes) => addChild([parentId]).then((newGoal) => {
    if (parentId === 0) {
      const list = nodes.filter((g) => g.isRoot).map((g) => g.entity.uid);
      return viewSorter.listBefore(currentViewService.currentView.value)([...list, newGoal.uid], anchor.uid, [newGoal.uid])
        .then(() => newGoal);
    }
    const parent = goalsSvc.selectSingle(parentId);
    const list = parent.children.map((c) => c.uid);
    return goalDetailSorter.listBefore(parent)(list, anchor.uid, [newGoal.uid]).then(() => newGoal);
  });

  const addRightSibling = (anchor, parentId, nodes) => addChild([parentId]).then((newGoal) => {
    if (parentId === 0) {
      const list = nodes.filter((g) => g.isRoot)
        .map((g) => g.entity.uid);
      return viewSorter.listAfter(currentViewService.currentView.value)([...list, newGoal.uid], anchor.uid, [newGoal.uid])
        .then(() => newGoal);
    }
    const parent = goalsSvc.selectSingle(parentId);
    const list = parent.children.map((c) => c.uid);
    return goalDetailSorter.listAfter(parent)(list, anchor.uid, [newGoal.uid])
      .then(() => newGoal);
  });

  const addChild = async (parentIds = []) => {
    if (currentViewService.currentView !== undefined) {
      goalModifiers.setFilter(currentViewService.currentView.value.params.filter);
    }
    goalModifiers.setSelectedCycles(selectedCycles.value);
    const parents = goalsSvc.selectMultiple(parentIds);
    if (parents.length > 0) {
      goalModifiers.setParents(parents);
    }

    return goalsSvc.createSingle(applyGoalModifiers({ modifiers: goalModifiers.getModifiers() }))
      .then((goal) => {
        EventBus.$emit(EVENTS.GOAL.GOALS_CREATED, { goals: [goal] });
        return goal;
      })
      .finally(() => {
        goalModifiers.setParents([]);
      });
  };

  return {
    createLoading: goalsSvc.createLoading,
    modifiers: goalModifiers,
    addChild,
    splitNode,
    addLeftSibling,
    addRightSibling,
  };
}
