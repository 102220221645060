<template>
  <div class="factor-details">
    <page-header
      :title="factor.title[lang]"
      small
      heading="h3"
    />
    <m-content padding>
      <m-alert
        v-if="factor.factorFields.length === 0"
        type="info"
        :message="$t('factorDetails.empty')"
      />
      <div
        v-else
        class="_item-list"
      >
        <transition-group
          name="list-left"
          mode="out-in"
        >
          <div
            v-for="item in factor.factorFields"
            :key="item.field.uid"
          >
            <div
              class="_item"
            >
              <div class="_icon">
                <form-field-icon
                  :type="item.field.type"
                  square
                  :show-index="false"
                  small
                />
              </div>
              <div class="_title">
                {{ item.field.title[lang] }}
              </div>
              <div
                v-if="!disabled"
                class="_action-items"
              >
                <m-btn
                  icon="close"
                  fab
                  hide-border
                  @click="remove(item)"
                />
              </div>
            </div>
            <m-divider />
          </div>
        </transition-group>
      </div>
      <div
        v-if="!disabled"
        class="_action"
      >
        <div>{{ $t('factorDetails.addQuestion') }}</div>
        <div>
          <div
            class="_inner"
          >
            <m-select
              v-model:value="selectedFields"
              class="_input-field"
              :items="fields"
              value-key="uid"
              :item-text="`title.${lang}`"
              :max-tag-text-length="$store.state.breakpoint.xl ? 50 : 30"
              multiple
              return-object
              no-tags
              show-search
              show-description
              :placeholder="$t('factorDetails.addQuestionPlaceholder')"
              keep-open-on-click
            />
            <m-btn
              @click="add"
            >
              {{ $t('general.add') }}
            </m-btn>
          </div>
        </div>
      </div>
    </m-content>
  </div>
</template>

<script>
import FormFieldIcon from '@/components/FormFieldIcon.vue';
import PageHeader from 'shared/components/PageHeader.vue';

export default {
  name: 'FactorDetails',
  props: {
    factor: {
      type: Object,
      required: true,
    },
    model: {
      type: String,
      required: true,
    },
    entity: {
      type: Object,
      required: true,
    },
    entityById: {
      type: Function,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  components: { FormFieldIcon, PageHeader },
  data() {
    return { selectedFields: [] };
  },
  computed: {
    fields() {
      return this.entity.orderItems.map((item) => item.field)
        .filter((f) => this.factor.factorFields.map((field) => field.field.uid).indexOf(f.uid) === -1)
        .filter((f) => f.title[this.lang] !== '' && typeof f.title[this.lang] !== 'undefined');
    },
  },
  methods: {
    add() {
      if (this.selectedFields.length === 0) {
        return;
      }

      this.$store.commit('FORM_FACTOR_UPDATED', {
        factor: {
          ...this.factor,
          factorFields: [
            ...this.factor.factorFields,
            ...this.selectedFields.map((f) => ({ field: f })),
          ],
        },
        model: this.model,
      });

      this.selectedFields = [];
    },
    remove(item) {
      this.$store.commit('FORM_FACTOR_UPDATED', {
        factor: {
          ...this.factor,
          factorFields: this.factor.factorFields.filter((f) => f.field.uid !== item.field.uid),
        },
        model: this.model,
      });
    },
    reset() {
      this.selectedFields = [];
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .factor-details {
    ._action {
      margin-top: 2rem;

      ._inner {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;

        ._input-field {
          flex: 1 1 15rem;
          width: 100%;
          margin-right: .4rem;
        }

        ._btn {
          margin-right: 0;
        }
      }
    }

    ._item-list {
      ._item {
        display: flex;
        align-items: center;

        ._icon {
          margin-right: 1.6rem;
        }

        ._action-items {
          margin-left: auto;
        }
      }
    }
  }
</style>
