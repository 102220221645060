<template>
  <div class="image-crop">
    <m-content padding-small>
      <div class="_image">
        <img ref="image">
      </div>
    </m-content>

    <m-default-form-actions
      :clickable="true"
      :loading="loading"
      @cancel="cancel"
      @submit="upload"
    />
  </div>
</template>

<script>
import 'cropperjs/dist/cropper.css';
import Cropper from 'cropperjs';

export default {
  Name: 'Image Crop',
  props: {
    file: {
      type: File,
      required: true,
    },
  },
  emits: ['cancel', 'cropped', 'wrong-file-type'],
  data() {
    return {
      loading: false,
      cropper: null,
    };
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    upload() {
      this.loading = true;
      const canvas = this.cropper.getCroppedCanvas({ maxHeight: 600, maxWidth: 600 });
      if (canvas === null) {
        this.$emit('cancel');
        return;
      }
      canvas.toBlob((blob) => this.$emit('cropped', blob));
    },
  },
  mounted() {
    const reader = new FileReader();
    reader.onload = (readerEvent) => {
      this.$refs.image.src = readerEvent.target.result;
      const { image } = this.$refs;
      this.cropper = new Cropper(image, { aspectRatio: 1, initialAspectRatio: 1, autoCropArea: 1 });
    };

    if (![
      'image/webp',
      'image/png',
      'image/jpg',
      'image/jpeg',
      'image/bmp',
      'image/gif',
    ].includes(this.file.type)) {
      this.$emit('wrong-file-type');
      return;
    }
    reader.readAsDataURL(this.file);
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .image-crop {
    ._image {
      img {
        max-width: 100%;
        max-height: 40rem;
      }
    }
  }
</style>
