import useRepo from '@/nebula/repo';
import { EVENTS } from '@/lib/constants';
import { EventBus } from '@/lib/event-bus';
import { pin as pinConfig } from 'shared/api/query/configs.json';

export default function usePins() {
  const repo = useRepo(pinConfig.model);

  const createPin = (pin) => repo.createSingle(pin).then((pin) => {
    EventBus.$emit(EVENTS.NAVIGATION.PIN_CREATED);
    return pin;
  });

  const deletePin = (pin) => repo.deleteSingle(pin.uid).then(() => {
    EventBus.$emit(EVENTS.NAVIGATION.PIN_DELETED);
  });

  return {
    createPinLoading: repo.createSingleLoading,
    createPin,
    deletePinLoading: repo.deleteSingleLoading,
    deletePin,

    pins: repo.entityList,
  };
}
