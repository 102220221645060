<template>
  <div class="data-source">
    <template v-if="!hasConnection">
      <m-tooltip
        :disabled="!disabled || disabledHint === ''"
        :style="{ display: 'inline-block' }"
      >
        <m-btn
          :disabled="disabled"
          @click="visible = true"
        >
          {{ $t('dataSource.connectToDataSource') }}
        </m-btn>
        <template #title>
          {{ disabledHint }}
        </template>
      </m-tooltip>
    </template>
    <div
      v-else-if="hasPrivateConnection"
      class="_overwrite"
    >
      <m-alert type="warning">
        {{ $t('dataSource.alreadySet', { title: goalSettings.featureNameSingular }) }}
      </m-alert>
      <div class="-overwrite-actions">
        <m-btn
          :disabled="disabled"
          @click="visible = true"
        >
          {{ $t('dataSource.overwriteAction') }}
        </m-btn>
        <m-btn
          :disabled="disabled"
          @click="handleDelete"
        >
          {{ $t('dataSource.deleteAction') }}
        </m-btn>
      </div>
    </div>
    <template v-else>
      <m-btn
        :disabled="disabled"
        @click="visible = true"
      >
        <m-icon
          :type="goalDataSourceIcon"
          class="_icon"
        />
        {{ $t('dataSource.connectedTo', { source: goalDataSourceTitle }) }}
      </m-btn>
    </template>
    <m-dialog
      v-model:value="visible"
      :title="$t('dataSource.title')"
      hide-footer
      no-padding
      center
    >
      <m-content padding>
        <div class="_item">
          <div class="_label">
            {{ $t('dataSource.selectDataSource') }}
          </div>
          <data-source-picker
            :selected-data-source="dataSource"
            :style="{ width: '100%' }"
            :data-source-id-to-edit="dataSourceIdToEdit"
            max-height="calc(100vh - 36.1rem)"
            @select="selectDataSource"
          />
          <div
            v-if="learnAbout !== null"
            class="_learn-about"
          >
            <m-btn
              small
              super-light
              :href="learnAbout.helpLink"
              hide-border
              icon="question-circle"
              target="_blank"
              class="_help-btn"
            >
              {{ learnAbout.help }}
            </m-btn>
          </div>
        </div>
      </m-content>
      <full-screen-spinner
        v-if="loading"
        :height="100"
      />
      <m-content
        v-else
        :padding-y="3"
      >
        <spreadsheet-form
          v-if="dataSource !== undefined && [dataSourceType.sheets, dataSourceType.excel].includes(dataSource.type)"
          :data-source="dataSource"
          :goal="goal"
          :spreadsheet-cell="goalDataSourceQuery"
          @close="visible = false"
          @deleted="selectedDataSourceId = -1"
        />
        <jira-form
          v-if="dataSource !== undefined && dataSource.type === dataSourceType.jira"
          :data-source="dataSource"
          :goal="goal"
          :jira-query="goalDataSourceQuery"
          @close="visible = false"
          @deleted="selectedDataSourceId = -1"
        />
        <hubspot-form
          v-if="dataSource !== undefined && dataSource.type === dataSourceType.hubspot"
          :data-source="dataSource"
          :goal="goal"
          :hubspot-query="goalDataSourceQuery"
          @close="visible = false"
          @deleted="selectedDataSourceId = -1"
        />
        <asana-form
          v-if="dataSource !== undefined && dataSource.type === dataSourceType.asana"
          :data-source="dataSource"
          :goal="goal"
          :asana-query="goalDataSourceQuery"
          @close="visible = false"
          @deleted="selectedDataSourceId = -1"
        />
        <salesforce-form
          v-if="dataSource !== undefined && dataSource.type === dataSourceType.salesforce"
          :data-source="dataSource"
          :goal="goal"
          :salesforce-query="goalDataSourceQuery"
          @close="visible = false"
          @deleted="selectedDataSourceId = -1"
        />
      </m-content>
    </m-dialog>
  </div>
</template>

<script>
import AsanaForm from '@/components/datasource/AsanaForm.vue';
import DataSourcePicker from '@/components/datasource/DataSourcePicker.vue';
import FullScreenSpinner from 'shared/components/FullScreenSpinner.vue';
import HubspotForm from '@/components/datasource/HubspotForm.vue';
import JiraForm from '@/components/datasource/JiraForm.vue';
import SalesforceForm from '@/components/datasource/SalesforceForm.vue';
import SpreadsheetForm from '@/components/datasource/SpreadsheetForm.vue';
import useAsana from '@/composables/integrations/asana/asana';
import useDataSources from '@/composables/integrations/datasources';
import useGoalDatasource from '@/composables/goal/goal-datasource';
import useGoalSettings from '@/composables/logged-in-user-account/goal-settings';
import useHubspot from '@/composables/integrations/hubspot/hubspot';
import useJira from '@/composables/integrations/jira/jira';
import useSalesforce from '@/composables/integrations/salesforce/salesforce';
import useSpreadsheet from '@/composables/integrations/spreadsheet/spreadsheet';
import { computed, toRef } from 'vue';
import { dataSourceType } from 'shared/constants.json';

export default {
  name: 'DataSource',
  props: {
    goal: {
      type: Object,
      required: true,
    },
    dataSourceIdToEdit: {
      type: Number,
      default: -1,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledHint: {
      type: String,
      default: '',
    },
  },
  components: { AsanaForm, JiraForm, DataSourcePicker, SpreadsheetForm, HubspotForm, FullScreenSpinner, SalesforceForm },
  setup(props) {
    const { selectSingle, dataSources, fetchDataSource, fetchDataSourceLoading } = useDataSources();
    const goalDataSources = computed(() => dataSources.value.filter((ds) => ([
      dataSourceType.excel,
      dataSourceType.sheets,
      dataSourceType.asana,
      dataSourceType.jira,
      dataSourceType.hubspot,
      dataSourceType.salesforce,
    ]).includes(ds.type)));
    const goal = toRef(props, 'goal');
    const { dataSource: goalDataSource, type: goalDataSourceType, title: goalDataSourceTitle, icon: goalDataSourceIcon } = useGoalDatasource(goal);
    const { goalSettings } = useGoalSettings();

    const { deleteQuery: deleteQuerySpreadsheet } = useSpreadsheet();
    const { deleteQuery: deleteQueryJira } = useJira();
    const { deleteQuery: deleteQueryAsana } = useAsana();
    const { deleteQuery: deleteQueryHubspot } = useHubspot();
    const { deleteQuery: deleteQuerySalesforce } = useSalesforce();
    let deleteQuery;
    switch (goalDataSourceType.value) {
      case dataSourceType.excel:
      case dataSourceType.sheets:
        deleteQuery = deleteQuerySpreadsheet;
        break;
      case dataSourceType.jira:
        deleteQuery = deleteQueryJira;
        break;
      case dataSourceType.hubspot:
        deleteQuery = deleteQueryHubspot;
        break;
      case dataSourceType.asana:
        deleteQuery = deleteQueryAsana;
        break;
      case dataSourceType.salesforce:
        deleteQuery = deleteQuerySalesforce;
        break;
      default:
        break;
    }

    return {
      dataSources: goalDataSources,
      goalDataSource,
      goalDataSourceType,
      goalDataSourceTitle,
      goalDataSourceIcon,
      deleteQuery,
      deleteQuerySpreadsheet,
      deleteQueryJira,
      deleteQueryHubspot,
      deleteQueryAsana,
      deleteQuerySalesforce,
      selectSingle,
      goalSettings,
      fetchDataSource,
      loading: fetchDataSourceLoading,
    };
  },
  data() {
    return {
      visible: false,
      selectedDataSourceId: -1,
      dataSourceType,
    };
  },
  computed: {
    goalDataSourceQuery() {
      switch (this.dataSource?.type) {
        case dataSourceType.jira:
          return this.goal.jiraQuery;
        case dataSourceType.asana:
          return this.goal.asanaQuery;
        case dataSourceType.hubspot:
          return this.goal.hubspotQuery;
        case dataSourceType.salesforce:
          return this.goal.salesforceQuery;
        case dataSourceType.sheets:
        case dataSourceType.excel:
          return this.goal.spreadsheetCell;
        default:
          return null;
      }
    },
    hasConnection() {
      return this.goalDataSourceQuery !== null;
    },
    hasPrivateConnection() {
      return this.goalDataSource === null;
    },
    learnAbout() {
      if (this.dataSource !== undefined) {
        switch (this.dataSource.type) {
          case dataSourceType.asana:
            return {
              helpLink: this.$t('asanaForm.helpLink'),
              help: this.$t('asanaForm.help'),
            };
          case dataSourceType.jira:
            return {
              helpLink: this.$t('jiraForm.helpLink'),
              help: this.$t('jiraForm.help'),
            };
          case dataSourceType.hubspot:
            return {
              helpLink: this.$t('hubspotForm.helpLink'),
              help: this.$t('hubspotForm.help'),
            };
          case dataSourceType.sheets:
          case dataSourceType.excel:
            return {
              helpLink: this.$t('spreadsheetForm.helpLink'),
              help: this.$t('spreadsheetForm.help'),
            };
          default:
            return null;
        }
      }
      return null;
    },
    dataSource() {
      return this.selectSingle(this.selectedDataSourceId);
    },
    alwaysSync() {
      if (this.dataSource === null) {
        return false;
      }

      return this.dataSource.alwaysSync;
    },
  },
  methods: {
    selectDataSource(ds) {
      this.selectedDataSourceId = ds.uid;
      this.fetchDataSource(ds.uid);
    },
    fetch() {
      if (this.hasPrivateConnection) {
        return;
      }

      const selectedDataSource = this.dataSources.find((ds) => {
        if (this.dataSourceIdToEdit !== -1) {
          return ds.uid === this.dataSourceIdToEdit;
        }

        return this.goalDataSource.uid === ds.uid;
      });
      if (selectedDataSource === undefined) {
        return;
      }
      this.selectDataSource(selectedDataSource);
    },
    handleDelete() {
      const deleteMethod = () => this.deleteQuery(this.goalDataSourceQuery).catch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      });

      this.$confirm({
        title: this.$t('dataSource.deleteConnectionPrompt'),
        okText: this.$t('general.yesDelete'),
        okType: 'danger',
        maskClosable: true,
        cancelText: this.$t('general.cancel'),
        onOk() {
          deleteMethod();
        },
      });
    },
  },
  created() {
    this.fetch();
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .data-source {
    ._overwrite {
      .-overwrite-actions {
        display: flex;
        gap: .8rem;
      }
    }
  }

  ._learn-about {
    margin-top: .6rem;
  }

  ._item {
    width: 100%;

    ._label {
      color: $font-color-secondary;
    }
  }

  ._icon {
    margin-right: .4rem;
  }
</style>
