<template>
  <m-card-item
    class="update-template-item"
    @click="$emit('select')"
  >
    <item-title
      :title="template.title"
      :icons="[{ value: buildIconFromEntity(template) }]"
      :style="{ fontWeight: '500' }"
    />
    <template
      v-if="canEdit"
      #right
    >
      <m-dropdown
        v-model:value="showActions"
        :title="$t('general.actions')"
      >
        <m-btn
          icon="ellipsis"
          xs
          fab
          hide-border
          @click.stop="showActions = true"
        />
        <template #overlay>
          <m-card

            no-padding
            list
          >
            <m-card-item
              v-if="canEdit"
              icon="edit"
              @click="edit"
            >
              {{ $t('general.edit') }}
            </m-card-item>
            <m-card-item
              v-if="canEdit"
              icon="delete"
              @click="$emit('delete')"
            >
              {{ $t('general.delete') }}
            </m-card-item>
          </m-card>
        </template>
      </m-dropdown>
    </template>
  </m-card-item>
</template>

<script>
import ItemTitle from '@/components/ItemTitle.vue';
import { accessPolicyType } from 'shared/constants.json';
import { buildIconFromEntity } from 'shared/lib/icon';

export default {
  name: 'UpdateTemplateItem',
  props: {
    template: {
      type: Object,
      required: true,
    },
  },
  components: { ItemTitle },
  emits: ['select', 'delete', 'edit'],
  data() {
    return { showActions: false };
  },
  computed: {
    canEdit() {
      return [accessPolicyType.full, accessPolicyType.write].includes(this.template.accessRight);
    },
  },
  methods: {
    buildIconFromEntity,
    edit() {
      this.showActions = false;
      this.$emit('edit');
    },
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  .update-template-item {
    ._icon {
      font-size: $font-size-4;
    }
  }
</style>
