<template>
  <div :class="['goal-list-confidence-chart']">
    <no-data v-if="chartData.length === 0" />
    <base-chart
      v-else
      :options="chartOptions"
    />
  </div>
</template>

<script>

import BaseChart from '@/components/dashboard/BaseChart.vue';
import NoData from '@/components/dashboard/NoData.vue';
import useGoalSettings from '@/composables/logged-in-user-account/goal-settings';
import { AREA, DASH, DATETIME, DOT, LINE } from '@/lib/constants';
import { DateTime } from 'luxon';
import { getRoundedValue } from '@/lib/charts/format';
import { linearRegression } from '@/lib/charts/line-chart';
import { rgbaToHex } from 'shared/lib/color';

export default {
  name: 'GoalListConfidenceChart',
  props: {
    startDate: {
      type: Date,
      required: true,
    },
    endDate: {
      type: Date,
      required: true,
    },
    chartData: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const { goalSettings } = useGoalSettings();
    return { goalSettings };
  },
  components: { BaseChart, NoData },
  computed: {
    progressData() {
      return this.chartData
        .filter((e) => e.x >= Number(this.startDate) && e.x <= Number(this.endDate))
        .map((e) => ({
          x: e.x,
          y: e.y,
        }))
        .sort((a, b) => (a.x <= b.x ? -1 : 1));
    },
    trendData() {
      const { slope, intercept } = linearRegression(this.progressData);
      return [
        { x: Number(this.startDate), y: intercept + (slope * Number(this.startDate)) },
        { x: Number(this.endDate), y: intercept + (slope * Number(this.endDate)) },
      ];
    },
    redArea() {
      return [
        { x: Number(this.startDate), y: this.goalSettings.redThreshold / 10 },
        { x: Number(this.endDate), y: this.goalSettings.redThreshold / 10 },
      ];
    },
    yellowArea() {
      return [
        { x: Number(this.startDate), y: this.goalSettings.yellowThreshold / 10 - this.goalSettings.redThreshold / 10 },
        { x: Number(this.endDate), y: this.goalSettings.yellowThreshold / 10 - this.goalSettings.redThreshold / 10 },
      ];
    },
    greenArea() {
      return [
        { x: Number(this.startDate), y: 100 - this.goalSettings.yellowThreshold / 10 }, // 100 matches 100%
        { x: Number(this.endDate), y: 100 - this.goalSettings.yellowThreshold / 10 },
      ];
    },
    chartOptions() {
      const t = this.$t;
      return {
        legend: {
          enabled: true,
          align: 'left',
          verticalAlign: 'top',
          x: 50,
          floating: true,
        },
        plotOptions: {
          area: {
            stacking: 'percent',
            lineWidth: 0,
          },
        },
        series: [
          {
            states: { inactive: { opacity: 1 } },
            type: AREA,
            data: this.greenArea,
            color: this.$colors.green.base,
            fillOpacity: 0.2,
            marker: { enabled: false },
            enableMouseTracking: false,
            showInLegend: false,
          },
          {
            states: { inactive: { opacity: 1 } },
            type: AREA,
            data: this.yellowArea,
            color: this.$colors.yellow.base,
            fillOpacity: 0.2,
            marker: { enabled: false },
            enableMouseTracking: false,
            showInLegend: false,
          },
          {
            states: { inactive: { opacity: 1 } },
            type: AREA,
            data: this.redArea,
            color: this.$colors.red.base,
            fillOpacity: 0.2,
            marker: { enabled: false },
            enableMouseTracking: false,
            showInLegend: false,
          },
          {
            states: { inactive: { opacity: 1 } },
            type: LINE,
            dashStyle: DASH,
            data: this.trendData,
            color: this.$colors.grey.lighten3,
            marker: { enabled: false },
            enableMouseTracking: false,
            name: this.$t('highcharts.trend'),
          },
          {
            type: LINE,
            data: this.progressData,
            clip: false,
            color: rgbaToHex(this.$colors.grey.darken2),
            marker: {
              enabled: true,
              fillColor: this.$colors.shades.white,
              lineWidth: 2,
              lineColor: null, // inherit from series
              symbol: 'circle',
            },
            connectNulls: true,
            showInLegend: false,
          },
        ],
        xAxis: {
          lineColor: rgbaToHex(this.$colors.grey.lighten3),
          lineWidth: 1,
          type: DATETIME,
          minTickInterval: 28 * 24 * 3600 * 1000,
          minRange: 28 * 24 * 3600 * 1000,
          min: Number(this.startDate),
          max: Number(this.endDate),
        },
        yAxis: {
          min: 0,
          max: 10,
          lineWidth: 0,
          gridLineColor: rgbaToHex(this.$colors.grey.lighten2),
          gridLineDashStyle: DOT,
          title: { text: '' },
        },
        tooltip: {
          shared: true,
          borderColor: rgbaToHex(this.$colors.grey.lighten3),
          headerFormat: '',
          pointFormatter() {
            return `
<span style="font-size:10px">${DateTime.fromMillis(this.x).toLocaleString(DateTime.DATETIME_MED)}</span></br><br/>
${t('highcharts.actual')}: <span style="font-weight: 700">${getRoundedValue(this.y, 1)}</span>`;
          },
        },
      };
    },
  },
};
</script>
