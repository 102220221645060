<template>
  <goal-insights-performance
    :view-application="viewApp"
    :default-view="defaultView"
    :space="space"
    show-views-selector
    show-create-view
  />
</template>

<script>
import GoalInsightsPerformance from '@/components/goal-insights/performance/GoalInsightsPerformance.vue';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import { goalInsightsGroupBy, viewApplication, viewType } from 'shared/constants.json';
import { filter as spaceFilter } from '@/lib/filter/space/filter';

export default {
  name: 'SpaceGoalInsights',
  props: {
    property: {
      type: Object,
      required: true,
    },
    space: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { loggedInUserAccount } = useLoggedInUserAccount();
    return { account: loggedInUserAccount };
  },
  components: { GoalInsightsPerformance },
  data() {
    return { viewApp: viewApplication.insightSpace };
  },
  computed: {
    initialFilter() {
      return spaceFilter({
        property: this.property,
        space: this.space,
        account: { uid: this.account.uid },
      });
    },
    defaultView() {
      return {
        title: this.$t('list.dashboard'),
        viewType: viewType.default,
        viewApplication: this.viewApp,
        space: this.space,
        params: {
          filter: this.initialFilter,
          order: [],
          props: [],
          goalInsightsGroupBy: { value: goalInsightsGroupBy.user, uid: [], id: goalInsightsGroupBy.user },
        },
      };
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">

</style>
