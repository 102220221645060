<template>
  <div
    class="feedback-question-item"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <div
      v-if="draggingOverTop"
      class="_drag-over-top"
    />
    <div
      v-show="(hover || showMenu) && !$store.state.breakpoint.smAndDown"
      class="_handle"
      @mousedown="focus"
      @mouseup="focus"
      @click="focus"
    >
      <m-dropdown
        v-if="!dragging"
        v-model:value="showMenu"
        :title="$t('general.actions')"
      >
        <m-btn
          icon="drag"
          hide-border
          small
          light
          fab
          :style="{ width: '2rem', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'grab' }"
          @click="showMenu = true"
        />
        <template #overlay>
          <m-card
            list
            no-padding
          >
            <m-card-item
              icon="delete"
              @click="$emit('delete')"
            >
              {{ $t('general.delete') }}
            </m-card-item>
          </m-card>
        </template>
      </m-dropdown>
    </div>
    <div class="_content">
      <div class="_number">
        {{ index }}.
      </div>
      <div class="_text">
        <m-textarea
          ref="input"
          :value="textByLang(item.title, lang)"
          :rows="1"
          auto-size
          hide-border
          @input="changeText"
          @keydown="handleKeydown"
        />
      </div>
    </div>
    <div
      v-if="draggingOverBottom"
      class="_drag-over-bottom"
    />
  </div>
</template>

<script>
import { textByLang } from 'shared/lib/language';

export default {
  name: 'FeedbackQuestionItem',
  props: {
    index: {
      type: Number,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    focused: {
      type: Boolean,
      default: false,
    },
    dragging: {
      type: Boolean,
      default: false,
    },
    lang: {
      type: String,
      required: true,
    },
    draggingOverTop: {
      type: Boolean,
      default: false,
    },
    draggingOverBottom: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['delete', 'down', 'up', 'enter', 'update'],
  data() {
    return {
      showMenu: false,
      hover: false,
      textByLang,
    };
  },
  methods: {
    handleKeydown(event) {
      if (event.key === 'ArrowDown') {
        this.$emit('down');
        return;
      }
      if (event.key === 'ArrowUp') {
        this.$emit('up');
        return;
      }
      if (event.key === 'Enter') {
        event.preventDefault();
        event.stopPropagation();
        this.$emit('enter');
        return;
      }
      if (event.key === 'Backspace' && textByLang(this.item.title, this.lang) === '') {
        event.preventDefault();
        event.stopPropagation();
        this.$emit('delete');
      }
    },
    changeText(val) {
      this.$emit('update', { item: { ...this.item, title: { [this.lang]: val } } });
    },
    focus() {
      this.$refs.input.focus();
    },
  },
  watch: {
    focused(val) {
      if (val) {
        this.focus();
      }
    },
  },
  mounted() {
    if (this.focused) {
      this.focus();
    }
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .feedback-question-item {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 3rem;
    margin-left: -3rem;

    ._handle {
      position: absolute;
      left: .5rem;
    }

    ._content {
      display: flex;
      align-items: center;
      width: 100%;

      ._number {
        margin-right: 1rem;
        color: $font-color-secondary;
      }

      ._text {
        display: flex;
        flex: 1 1 auto;
        align-items: center;

        textarea {
          width: 100%;
          resize: none;
          border: none;
          outline: none;
        }
      }
    }

    ._drag-over-top {
      position: absolute;
      top: -2px;
      right: 0;
      left: 0;
      z-index: 88;
      width: 100%;
      height: 4px;
      pointer-events: none;
      background: $highlighted-color-dark;
      opacity: 1;
    }

    ._drag-over-bottom {
      position: absolute;
      right: 0;
      bottom: -2px;
      left: 0;
      z-index: 88;
      width: 100%;
      height: 4px;
      pointer-events: none;
      background: $highlighted-color-dark;
      opacity: 1;
    }
  }
</style>
