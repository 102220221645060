import { HAS, UID_IN } from 'shared/api/query/constants';
import { OR } from '@/lib/filter/scope-tree';
import { computed } from 'vue';
import { goal as goalConfig } from 'shared/api/query/configs.json';
import { routeName } from 'shared/constants.json';
import { useRoute } from 'vue-router';

export default function useGoalPickerFilter(goal = null) {
  const route = useRoute();
  const id = computed(() => {
    if (goal !== null) {
      if (goal.publishedAt !== null) {
        return null;
      }
      if (goal.planning === null) {
        return null;
      }
      return goal.planning.uid;
    }

    if (route.name !== routeName.planningDetails) {
      return null;
    }
    return parseInt(route.params.id, 10);
  });

  if (id.value === null) {
    return { goalPickerFilter: { [goalConfig.model]: { filter: { func: { name: HAS, attr: goalConfig.edges.publishedAt } } } } };
  }

  return {
    goalPickerFilter: {
      [goalConfig.model]: {
        filter: {
          op: OR,
          child: [
            { func: { name: HAS, attr: goalConfig.edges.publishedAt } },
            { func: { name: UID_IN, attr: goalConfig.edges.planning, args: [{ value: `${id.value}` }] } },
          ],
        },
      },
    },
  };
}
