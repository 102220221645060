<template>
  <div class="feedback-answer-item">
    <div class="_top">
      <div class="_question">
        {{ textByLang(item.field.title, lang) }}
      </div>
    </div>
    <div class="_answer">
      <m-editor
        :initial-value="item.text"
        :placeholder="$t('feedbackAnswerItem.placeholder')"
        :allowed-content="allowedContent"
        :disabled="disabled"
        @input="updateAnswer"
      />
    </div>
  </div>
</template>

<script>
import useAccess from '@/composables/access/access';
import { defineAsyncComponent } from 'vue';
import { editorNodeType, featureFlag } from 'shared/constants.json';
import { textByLang } from 'shared/lib/language';

export default {
  name: 'FeedbackAnswerItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update'],
  components: { MEditor: defineAsyncComponent(() => import('@/components/editor/MEditor.vue')) },
  setup() {
    const allowedContent = [
      editorNodeType.codeBlock,
      editorNodeType.blockquote,
      editorNodeType.bulletList,
      editorNodeType.orderedList,
    ];

    const { accountHasFeature } = useAccess();
    if (accountHasFeature([featureFlag.fileUpload])) {
      allowedContent.push(editorNodeType.image, editorNodeType.file);
    }

    return { allowedContent };
  },
  data() {
    return { textByLang };
  },
  methods: {
    updateAnswer(val) {
      this.$emit('update', { ...this.item, text: val });
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .feedback-answer-item {
    ._top {
      display: flex;

      ._question {
        font-weight: $font-weight-semibold;
      }
    }

    ._answer {
      margin-left: -.2rem;
    }
  }
</style>
