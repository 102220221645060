<template>
  <settings-switch-item
    :title="$t('integrationSettings.slack')"
    icon="slack"
    :sub-title="$t('slackSwitch.description')"
    :loading="slackLoading"
    :value="slackConnected"
    class="_switch"
    :disabled="disabled"
    @click="handleClick"
  />
</template>

<script>
import SettingsSwitchItem from '@/components/SettingsSwitchItem.vue';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useSlackInstaller from '@/composables/integrations/slack/slack-installer';
import { logCatch } from '@/lib/logger/logger';
import { routeName } from 'shared/constants.json';

export default {
  name: 'SlackSwitch',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: { SettingsSwitchItem },
  setup() {
    const { loggedInUser } = useLoggedInUser();
    const accountSvc = useLoggedInUserAccount();
    const { goToSlack, subscribeWindowEvents, getSlackConsentUrl, showSlackStatus, status, slackLoading } = useSlackInstaller();
    return {
      loggedInUser,
      account: accountSvc.loggedInUserAccount,
      accountSvc,
      status,
      slackLoading,
      goToSlack,
      subscribeWindowEvents,
      getSlackConsentUrl,
      showSlackStatus,
    };
  },
  data() {
    return { routeName };
  },
  computed: {
    slackConnected() {
      if (this.account.appIntegration === null) {
        return false;
      }

      return this.account.appIntegration.slackBotAccessToken != null;
    },
  },
  methods: {
    handleClick() {
      if (this.slackConnected) {
        this.showConfirm('slack', this.slackConnected);
        return;
      }

      this.goToSlack();
    },
    buttonText(isActive) {
      if (isActive) {
        return this.$t('accountSettings.features.deactivate');
      }
      return this.$t('accountSettings.features.activate');
    },
    buttonType(isActive) {
      if (isActive) {
        return 'danger';
      }
      return 'primary';
    },
    updateSettings(entity) {
      this.accountSvc.updateSingle(entity).then(() => {
        this.$showSnackbar({ color: 'success', message: this.$t('success.updated') });
      }).catch(logCatch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      }));
    },
    showConfirm(type, isActive) {
      const update = this.updateSettings;
      let title = '';
      let content = '';
      const account = { uid: this.account.uid };
      switch (type) {
        case 'slack': {
          title = this.$t('accountSettings.appIntegration.slackDisconnect');
          content = this.$t('accountSettings.appIntegration.slackDisconnectText');
          account.appIntegration = {
            uid: this.account.appIntegration.uid,
            slackBotAccessToken: null,
          };
          break;
        }
        default:
      }
      this.$confirm({
        title,
        content,
        okText: isActive ? this.$t('accountSettings.features.deactivate') : this.$t('accountSettings.features.activate'),
        okType: isActive ? 'danger' : 'primary',
        maskClosable: true,
        cancelText: this.$t('general.cancel'),
        onOk() {
          update(account);
        },
      });
    },
  },
  watch: {
    status(newVal) {
      this.$showSnackbar(this.showSlackStatus(newVal));
    },
  },
  created() {
    this.getSlackConsentUrl().catch(logCatch(() => {
      this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
    }));
    this.subscribeWindowEvents();
  },
};
</script>

<style scoped lang="scss" type="text/scss">
</style>
