<template>
  <div class="new-form-content">
    <m-content
      padding
      class="new-form-content"
    >
      <div class="_top">
        <div class="_from-scratch">
          <m-btn
            color="primary"
            large
            @click="showModal = true"
          >
            {{ $t('newForm.startFromScratch') }}
          </m-btn>
          <m-dialog
            v-model:value="showModal"
            :title="$t('createFormCard.heading')"
            :ok-text="$t('general.save')"
            :cancel-text="$t('general.cancel')"
            @ok="$refs.createFormCard.create()"
            @cancel="showModal = false"
          >
            <create-form-card
              ref="createFormCard"
              @created="created"
            />
          </m-dialog>
        </div>
        <h2 class="_title">
          {{ title }}
        </h2>
      </div>
    </m-content>
    <div class="_content">
      <card-list-item
        v-for="item in formTemplates"
        :key="item.uid"
        :title="item.title"
        :to="previewTemplate(item)"
        class="_item"
        hide-footer
      />
    </div>
  </div>
</template>

<script>
import CardListItem from '@/components/CardListItem.vue';
import CreateFormCard from '@/components/CreateFormCard.vue';
import { CREATE_FORM, NEW_FORM_TEMPLATE_PREVIEW } from '@/route-names';

export default {
  name: 'NewFormContent',
  props: {
    title: {
      type: String,
      required: true,
    },
    formTemplates: {
      type: Array,
      required: true,
    },
  },
  components: { CardListItem, CreateFormCard },
  data() {
    return { showModal: false };
  },
  methods: {
    previewTemplate(item) {
      return {
        name: NEW_FORM_TEMPLATE_PREVIEW,
        params: { formTemplateId: item.uid },
      };
    },
    created({ form }) {
      this.$router.push({ name: CREATE_FORM, params: { formId: form.uid } });
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .new-form-content {
    ._from-scratch {
      margin-top: -2.4rem;
    }

    ._title {
      margin-top: 4rem;
    }

    ._content {
      display: flex;
      flex-wrap: wrap;
      padding: 0 calc(#{$base-container-padding-x} - 1rem);

      ._item {
        flex: 0 1 17.5rem;
        height: 21rem;
        margin: 1rem;
      }
    }
  }
</style>
