<template>
  <div class="user-provisioning">
    <m-section
      heading-size="h4"
      :title="$t('userProvisioning.heading')"
    >
      <template #after-title>
        <plan-tag
          class="_plan-tag"
          :feature-flag="featureFlag.userProvisioning"
        />
      </template>
    </m-section>
    <div class="_help">
      <m-btn
        icon="question-circle"
        super-light
        hide-border
        small
        :href="$t('userProvisioning.helpCenterLink')"
        target="_blank"
      >
        {{ $t('userProvisioning.learnAbout') }}
      </m-btn>
    </div>
    <div
      class="_settings-switch"
    >
      <settings-switch-item
        :disabled="!featureEnabled"
        :title="$t('userProvisioning.actionTitle')"
        :sub-title="$t('userProvisioning.actionSubtitle')"
        :value="userProvisioningEnabled"
        icon="login"
        @click="showConfirm"
      />
    </div>

    <m-divider />

    <m-content
      v-if="userProvisioningEnabled"
      :padding-y="7"
    >
      <user-provisioning-status />
    </m-content>
    <m-content
      v-if="userProvisioning !== undefined"
      :padding-y="7"
    >
      <m-btn
        :loading="syncLoading"
        @click="sync"
      >
        {{ $t('userProvisioning.sync') }}
      </m-btn>
      <m-dialog
        max-width="25rem"
        :value="syncLoading"
        hide-footer
        hide-header
        center
      >
        <div class="_sync-loading">
          <m-spinner size="sm" />
          <div class="_sync-loading-text">
            {{ $t('userProvisioning.syncLoading') }}
          </div>
        </div>
      </m-dialog>
    </m-content>

    <m-content :padding-y="7">
      <div
        v-if="userProvisioningEnabled"
        class="_item"
      >
        <div class="_label">
          {{ $t('userProvisioning.token') }}
        </div>
        <m-input-password
          :disabled="!featureEnabled"
          :value="token(userProvisioning.uid, userProvisioning.token)"
          :read-only="true"
        />
      </div>
    </m-content>
    <div
      v-if="userProvisioningEnabled"
      class="_item"
    >
      <m-content>
        <m-tooltip>
          <div class="_label">
            {{ $t('userProvisioning.attributeMapping') }}
          </div>
          <template #title>
            {{ $t('userProvisioning.attributeMappingDescription') }}
          </template>
        </m-tooltip>
        <m-table
          :columns="columns"
          :data-source="mappings"
          class="_mappings -small"
          row-key="uid"
          :show-new-button="featureEnabled"
          :new-button-title="$t('userProvisioning.addAttributeMapping')"
          @new="createAttributeMapping"
        >
          <template #property="{ row }">
            <m-tooltip>
              <template
                v-if="row.immutable"
                #title
              >
                {{ $t('userProvisioning.immutableField') }}
              </template>
              <div class="_attribute">
                <m-tag
                  small
                  class="_text"
                  :title="row.property.label"
                />
                <div
                  v-if="row.space !== null"
                  :style="{marginLeft: '.8rem'}"
                >
                  {{ row.space.title }}
                </div>
                <div
                  v-if="row.propertyOption !== null"
                  :style="{marginLeft: '.8rem'}"
                >
                  {{ row.propertyOption.title }}
                </div>
                <div
                  v-if="row.immutable"
                  class="_lock-icon"
                >
                  <m-icon
                    :color="$colors.grey.base"
                    type="lock"
                  />
                </div>
              </div>
            </m-tooltip>
          </template>
          <template #scimAttribute="{ row }">
            <div
              class="_attribute"
            >
              <m-tag
                small
                class="_text"
                :title="row.scimAttribute"
              />
              <div class="_provisionedGroups">
                <div
                  v-for="(group, index) in row.provisionedGroups.slice(0, 2)"
                  :key="group.uid"
                  class="_provisionedGroup"
                >
                  {{ group.displayName }}<span v-if="index !== row.provisionedGroups.slice(0, 2).length-1">,</span>
                </div>
                <span v-if="row.provisionedGroups.length > 2">{{ `, +${row.provisionedGroups.length-2} ${$t('userProvisioning.moreItems')}` }}</span>
              </div>
              <div
                v-if="!row.immutable"
                class="_actions"
              >
                <m-btn
                  icon="edit"
                  fab
                  xs
                  hide-border
                  :disabled="!featureEnabled"
                  @click="updateAttributeMapping(row)"
                />
                <div :style="{marginLeft: '.4rem'}">
                  <m-btn
                    icon="delete"
                    fab
                    xs
                    hide-border
                    :disabled="!featureEnabled"
                    @click="removeAttributeMapping(row)"
                  />
                </div>
              </div>
            </div>
          </template>
        </m-table>
      </m-content>
      <m-dialog
        v-model:value="showCreateAttributeMapping"
        hide-footer
        no-padding
        :title="$t('userProvisioning.attributeMapping')"
      >
        <user-provisioning-attribute-dialog
          :user-provisioning-mapping="attributeMappingEditItem"
          @selected="mutateAttributeMapping"
          @cancel="showCreateAttributeMapping = false"
        />
      </m-dialog>
    </div>
    <div v-if="userProvisioningEnabled">
      <m-content :padding-y="12">
        <o-auth-code-grant />
      </m-content>
    </div>
  </div>
</template>

<script>
import OAuthCodeGrant from '@/components/security/OAuthCodeGrant.vue';
import PlanTag from '@/components/plans/PlanTag.vue';
import SettingsSwitchItem from '@/components/SettingsSwitchItem.vue';
import UserProvisioningAttributeDialog from '@/components/security/UserProvisioningAttributeDialog.vue';
import UserProvisioningStatus from '@/components/security/UserProvisioningStatus.vue';
import useAccess from '@/composables/access/access';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useUserProvisioning from '@/composables/user-provisioning/user-provisioning';
import useUserProvisioningMapping from '@/composables/user-provisioning/user-provisioning-mapping';
import { camelCase } from 'lodash-es';
import { copy, shallowCopy } from 'shared/lib/copy';
import { doProvisioningSync } from '@/api';
import { featureFlag } from 'shared/constants.json';
import { logCatch } from '@/lib/logger/logger';
import { passphrase, token } from '@/lib/secret';
import { textByLang } from 'shared/lib/language';

export default {
  name: 'UserProvisioning',
  components: { SettingsSwitchItem, UserProvisioningAttributeDialog, UserProvisioningStatus, OAuthCodeGrant, PlanTag },
  setup() {
    const { userProvisioning, getProvisioningStatus, createSingle: createSingleUserProvisioning, updateSingle: updateSingleUserProvisioning } = useUserProvisioning();
    const { updateSingle, createSingle, deleteSingle } = useUserProvisioningMapping();
    const { accountHasFeature } = useAccess();
    const { userLang, loggedInUser } = useLoggedInUser();

    return {
      userLang,
      userProvisioning,
      updateUserProvisioningMapping: updateSingle,
      createUserProvisioningMapping: createSingle,
      deleteUserProvisioningMapping: deleteSingle,
      getProvisioningStatus,
      accountHasFeature,
      createSingleUserProvisioning,
      updateSingleUserProvisioning,
      loggedInUser,
    };
  },
  data() {
    return {
      featureFlag,
      token,
      localLanguage: 'en',
      showCreateAttributeMapping: false,
      attributeMappingEditItem: null,
      syncLoading: false,
    };
  },
  emits: ['toggle'],
  computed: {
    columns() {
      return [
        {
          key: 'property',
          title: this.$t('userProvisioning.property'),
          scopedSlots: { customRender: 'property' },
          width: 250,
        },
        {
          key: 'scimAttribute',
          title: this.$t('userProvisioning.attribute'),
          scopedSlots: { customRender: 'scimAttribute' },
        },
      ];
    },
    mappings() {
      if (this.userProvisioning === undefined) {
        return [];
      }

      const defaultMappings = [
        { uid: 0, scimAttribute: 'provisionedNames.givenName', property: { label: this.$t('userProvisioning.firstName') }, provisionedGroups: [], immutable: true, space: null, propertyOption: null },
        { uid: 0, scimAttribute: 'provisionedNames.familyName', property: { label: this.$t('userProvisioning.lastName') }, provisionedGroups: [], immutable: true, space: null, propertyOption: null },
        { uid: 0, scimAttribute: 'preferredLanguage', property: { label: this.$t('userProvisioning.language') }, provisionedGroups: [], immutable: true, space: null, propertyOption: null },
        { uid: 0, scimAttribute: 'emails', property: { label: this.$t('userProvisioning.email') }, provisionedGroups: [], immutable: true, space: null, propertyOption: null },
      ];

      return [
        ...defaultMappings,
        ...this.userProvisioning.mappings.map((m) => ({
          uid: m.uid,
          scimAttribute: camelCase(m.scimAttribute),
          scimAttributeType: camelCase(m.scimAttributeType),
          provisionedGroups: m.provisionedGroups,

          property: { uid: m.property.uid, label: textByLang(m.property.label, this.userLang) },

          space: m.space !== null ? { uid: m.space.uid, title: m.space.title } : null,
          propertyOption: m.propertyOption !== null ? { uid: m.propertyOption.uid, title: textByLang(m.propertyOption.label, this.userLang) } : null,

          immutable: false,
        })),
      ];
    },
    featureEnabled() {
      return this.accountHasFeature([featureFlag.userProvisioning]);
    },
    userProvisioningEnabled() {
      if (this.userProvisioning === undefined) {
        return false;
      }

      return this.userProvisioning.pausedAt === null;
    },
  },
  methods: {
    mutateAttributeMapping(entity) {
      if (this.attributeMappingEditItem !== null) {
        this.updateUserProvisioningMapping({
          uid: this.attributeMappingEditItem.uid,
          ...shallowCopy(entity),
        }).then(() => {
          this.getProvisioningStatus();
          this.showCreateAttributeMapping = false;
        })
          .catch(logCatch(() => {
            this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
          }));
        return;
      }

      this.createUserProvisioningMapping(copy(entity)).then(() => {
        this.getProvisioningStatus();
        this.showCreateAttributeMapping = false;
      })
        .catch(logCatch(() => {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
        }));
    },
    removeAttributeMapping(entity) {
      const deleteFn = () => this.deleteUserProvisioningMapping(entity.uid)
        .then(() => {
          this.getProvisioningStatus();
        }).catch(logCatch(() => {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
        }));

      this.$confirm({
        title: this.$t('userProvisioning.confirmAttributeDelete'),
        okText: this.$t('general.yesDelete'),
        okType: 'danger',
        maskClosable: true,
        cancelText: this.$t('general.cancel'),
        onOk() {
          deleteFn();
        },
      });
    },
    updateAttributeMapping(mapping) {
      this.attributeMappingEditItem = this.userProvisioning.mappings.find((m) => m.uid === mapping.uid);
      this.showCreateAttributeMapping = true;
    },
    createAttributeMapping() {
      this.attributeMappingEditItem = null;
      this.showCreateAttributeMapping = true;
    },
    sync() {
      this.syncLoading = true;
      doProvisioningSync(this.userProvisioning).then((response) => {
        if (response.status !== 200) {
          this.syncLoading = false;
          switch (response.status) {
            case 429:
              this.$showSnackbar({ color: 'error', message: this.$t('userProvisioning.tooManySyncRequests') });
              break;
            default:
              this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
          }
          return;
        }

        this.getProvisioningStatus().then(() => {
          this.syncLoading = false;
          this.$showSnackbar({ color: 'success', message: this.$t('userProvisioning.syncSuccess') });
        });
      });
    },
    showConfirm() {
      const isActive = this.userProvisioning !== undefined && this.userProvisioning.pausedAt === null;
      const title = isActive ? this.$t('identityAndProvisioning.userProvisioning.disable') : this.$t('identityAndProvisioning.userProvisioning.enable');

      const update = this.toggleUserProvisioning;

      this.$confirm({
        title,
        okText: isActive ? this.$t('accountSettings.features.deactivate') : this.$t('accountSettings.features.activate'),
        okType: isActive ? 'danger' : 'primary',
        maskClosable: true,
        cancelText: this.$t('general.cancel'),
        onOk() {
          update();
        },
      });
    },
    toggleUserProvisioning() {
      if (this.userProvisioning === undefined) {
        const entity = {
          account: { uid: this.loggedInUser.account.uid },
          token: passphrase(32),
          language: this.userLang,
        };

        return this.createSingleUserProvisioning(entity).catch(logCatch(() => {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
        }));
      }

      if (this.userProvisioning.pausedAt === null) {
        return this.updateSingleUserProvisioning({ uid: this.userProvisioning.uid, pausedAt: (new Date()) }).catch(logCatch(() => {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
        }));
      }

      return this.updateSingleUserProvisioning({ uid: this.userProvisioning.uid, pausedAt: null }).catch(logCatch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      }));
    },
  },
  created() {
    if (this.userProvisioning !== undefined) {
      this.getProvisioningStatus();
    }
  },
};
</script>

<style lang="scss" type="text/scss" scoped>
  .user-provisioning {
    ._help {
      margin-bottom: 1.2rem;
    }

    ._plan-tag {
      margin-left: .8rem;
    }

    ._item {
      margin-bottom: 1.4rem;

      ._label {
        margin-top: .8rem;
        margin-bottom: .4rem;
        color: $font-color-secondary;
      }
    }

    ._settings-switch {
      margin-bottom: 1.4rem;
    }

    ._mappings {
      .m-table-tbody {
        .m-table-row {
          ._attribute {
            position: relative;
            display: flex;

            ._lock-icon {
              margin-top: .2rem;
              margin-left: .4rem;
            }

            ._actions {
              position: absolute;
              top: 0;
              right: .4rem;
              display: none;
            }

            ._provisionedGroups {
              display: flex;

              ._provisionedGroup {
                margin-left: .4rem;
              }
            }
          }

          &:hover {
            ._attribute {
              ._actions {
                display: flex;
              }
            }
          }
        }
      }
    }
  }

  ._sync-loading {
    ._sync-loading-text {
      margin-top: 1rem;
      text-align: center;
    }
  }
</style>
