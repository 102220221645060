<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.5718 16.3362L6.27577 12.0778C5.88597 11.6914 5.2576 11.6914 4.8678 12.0778L4.14548 12.7938C3.75079 13.185 3.74904 13.8212 4.14372 14.2125L9.85726 19.876L12.0001 22L14.143 19.876L14.1429 19.8759L19.8565 14.2125C20.2512 13.8213 20.2494 13.185 19.8547 12.7938L19.1324 12.0778C18.7426 11.6914 18.1143 11.6914 17.7245 12.0778L13.4289 16.3357L13.4289 2.00012H10.5718L10.5718 16.3362Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default { name: 'ArrowDown' };
</script>

<style scoped lang="scss" type="text/scss">

</style>
