<template>
  <div class="password-login">
    <div class="_item">
      <div class="_label">
        {{ $t('general.email') }}
      </div>
      <m-text-field
        v-model:value="email"
        auto-focus
        :placeholder="$t('loginForm.enterEmail')"
        @keydown.enter="submit"
      >
        <template #prefix>
          <m-icon
            type="mail"
            :color="$colors.grey.lighten2"
          />
        </template>
      </m-text-field>
      <div
        v-if="errorMessage !== ''"
        class="_error-msg"
      >
        {{ errorMessage }}
      </div>
    </div>
    <div class="_item">
      <div class="_label">
        {{ $t('general.password') }}
      </div>
      <m-input-password
        v-model:value="password"
        :placeholder="$t('loginForm.enterPassword')"
        @keydown.enter="submit"
      />
    </div>
    <div class="_action">
      <m-btn
        :loading="loading"
        color="primary"
        :button-style="{ width: '100%' }"
        :style="{ width: '100%' }"
        @click.prevent="submit"
      >
        {{ $t('login.callToAction') }}
      </m-btn>
    </div>
    <div class="_links">
      <div>
        <m-link
          :to="forgetPasswordLink"
          inherit-color
        >
          {{ $t('loginForm.passwordForget') }}
        </m-link>
      </div>
      <div>
        <m-link
          inherit-color
          @click="$emit('ssologin')"
        >
          {{ $t('login.viaSaml') }}
        </m-link>
      </div>
    </div>
  </div>
</template>

<script>
import { emailRegex } from 'shared/lib/email-regex';
import { routeName } from 'shared/constants.json';

export default {
  name: 'PasswordLogin',
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['ssologin', 'submit'],
  data() {
    return {
      email: '',
      password: '',
      routeName,
      submitted: false,
    };
  },
  computed: {
    errorMessage() {
      if (this.email === '' || !this.submitted) {
        return '';
      }

      const reg = new RegExp(emailRegex());
      if (reg.test(this.email)) {
        return '';
      }

      return this.$t('loginForm.notAValidEmail');
    },
    forgetPasswordLink() {
      return {
        name: routeName.forgetPassword,
        query: { email: this.email },
      };
    },
  },
  methods: {
    submit() {
      this.submitted = true;
      if (this.errorMessage !== '') {
        return;
      }
      this.$emit('submit', { email: this.email, password: this.password });
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .password-login {
    ._links {
      display: flex;
      justify-content: space-between;
      margin-top: 2rem;
      font-size: $font-size-3;
      color: $font-color-secondary;
      text-align: center;
      text-decoration: underline;
    }

    ._item {
      margin-bottom: 1rem;

      ._label {
        font-size: $font-size-3;
        color: $font-color-secondary;
      }

      ._error-msg {
        margin-top: .4rem;
        color: $error-color;
      }
    }
  }
</style>
