<template>
  <div class="saved-view-editor">
    <div class="_top">
      <icon-picker
        :icon="buildIcon(emoji, iconType.icon)"
        show-placeholder
        size="3.2rem"
        :icon-size="16"
        bordered
        :show-icon-variants="false"
        :icon-types="[iconType.icon]"
        class="_emoji-picker"
        @change="setEmoji"
      />
      <m-text-field
        v-model:value="title"
        auto-focus
        :placeholder="$t('savedViewSelector.addViewPlaceholder')"
        @keydown.enter="submit"
      />
    </div>
    <m-card-item
      v-for="v in allowedViewTypes"
      :key="v"
      :icon="getViewIcon(v)"
      :disabled="v === viewType.tree && !canActivateGoalTree"
      align-top
      @click="type = v"
    >
      <div class="_inner">
        <div class="_title">
          {{ getViewName(v) }}
          <plan-tag
            v-if="v === viewType.tree"
            :feature-flag="featureFlag.goalTree"
            :style="{ display: 'inline-flex' }"
          />
        </div>
        <div class="_sub-title">
          {{ getDescription(v) }}
        </div>
      </div>
      <template #right>
        <m-icon
          v-if="type === v"
          type="check"
        />
      </template>
    </m-card-item>
    <template
      v-if="showVisibilitySwitch"
    >
      <m-divider xxs />
      <m-card-item
        icon="global"
        class="_public-selector"
        @click="toggleIsPublic"
      >
        {{ $t('savedViewSelector.publish') }}
        <div class="_switch-wrapper">
          <m-switch
            :value="isPublic"
            small
            class="_switch"
          />
        </div>
      </m-card-item>
    </template>
    <template v-if="entity !== null && !disableDelete">
      <m-divider xxs />
      <m-card-item
        icon="delete"
        @click="promptDeleteConfirmation"
      >
        {{ $t('general.delete') }}
      </m-card-item>
    </template>
  </div>
</template>

<script>
import IconPicker from '@/components/IconPicker.vue';
import PlanTag from '@/components/plans/PlanTag.vue';
import useAccess from '@/composables/access/access';
import useDebounce from '@/composables/debounce';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import { buildIcon } from 'shared/lib/icon';
import { featureFlag, iconType, viewType } from 'shared/constants.json';
import { iconByType } from '@/lib/saved-view/saved-view';

export default {
  name: 'SavedViewEditor',
  props: {
    params: {
      type: Object,
      required: true,
    },
    entity: {
      type: Object,
      required: true,
    },
    goalSort: {
      type: Array,
      default: () => [],
    },
    allowedViewTypes: {
      type: Array,
      default: () => [viewType.default],
    },
    viewApplication: {
      type: String,
      default: '',
    },
    showVisibilitySwitch: {
      type: Boolean,
      default: false,
    },
    disableDelete: {
      type: Boolean,
      default: false,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['create', 'update', 'delete'],
  components: { PlanTag, IconPicker },
  setup() {
    const { accountHasFeature } = useAccess();
    const { debounce } = useDebounce({ onBeforeUnmountFlush: true });
    const { loggedInUser } = useLoggedInUser();
    return {
      debounce,
      featureFlag,
      accountHasFeature,
      loggedInUser,
    };
  },
  data() {
    return {
      title: '',
      emoji: null,
      type: null,
      isPublic: false,
      viewType,
      iconType,
    };
  },
  computed: {
    canActivateGoalTree() {
      return this.accountHasFeature([featureFlag.goalTree]);
    },
  },
  methods: {
    buildIcon,
    getViewIcon(value) {
      return iconByType(value);
    },
    getViewName(value) {
      return this.$t(`list.${value}`);
    },
    getDescription(value) {
      return this.$t(`savedViewSelector.${value}Description`);
    },
    setEmoji(emoji) {
      this.emoji = emoji.value;
    },
    toggleIsPublic() {
      const promptConfirmation = (title) => {
        const { emitUpdate, isPublic } = this;

        this.$confirm({
          title,
          okText: this.$t('general.okay'),
          okType: 'primary',
          maskClosable: true,
          cancelText: this.$t('general.cancel'),
          onOk() {
            emitUpdate({ isPublic: !isPublic });
          },
        });
      };

      if (this.isPublic) {
        let title = this.$t('savedViewSelector.setPrivatePrompt');
        if (this.entity.creator.uid !== this.loggedInUser.uid) {
          title = this.$t('savedViewSelector.setPrivatePromptNoAccess', { user: `${this.entity.creator.firstName} ${this.entity.creator.lastName}` });
        }
        promptConfirmation(title);
        return;
      }

      promptConfirmation(this.$t('savedViewSelector.setPublicPrompt'));
    },

    emitUpdate({ isPublic = false, emitClose = false }) {
      const view = {
        ...this.entity,
        title: this.title,
        emoji: this.emoji,
        isPublic,
        viewType: this.type,
      };

      this.$emit('update', { view, emitClose });
    },
    updateDebounced(wait = 0) {
      const fn = () => this.emitUpdate({ isPublic: this.isPublic });
      this.debounce(fn, wait);
    },

    promptDeleteConfirmation() {
      const emitDelete = () => {
        this.$emit('delete', { view: this.entity });
      };
      this.$confirm({
        title: this.$t('savedViewSelector.deletePrompt'),
        okText: this.$t('general.yesDelete'),
        okType: 'danger',
        maskClosable: true,
        cancelText: this.$t('general.cancel'),
        onOk() {
          emitDelete();
        },
      });
    },
    submit() {
      this.emitUpdate({ isPublic: this.isPublic, emitClose: true });
    },
  },
  watch: {
    title(newVal, oldVal) {
      if (newVal === oldVal || oldVal === '') {
        return;
      }
      this.updateDebounced(800);
    },
    emoji(newVal, oldVal) {
      if (newVal === oldVal || oldVal === null) {
        return;
      }
      this.updateDebounced();
    },
    type(newVal, oldVal) {
      if (newVal === oldVal || oldVal === null) {
        return;
      }
      this.updateDebounced();
    },
    entity(newVal, oldVal) {
      if (newVal === oldVal || oldVal === null) {
        return;
      }
      this.isPublic = newVal.isPublic;
    },
  },
  created() {
    this.title = this.entity.title;
    this.emoji = this.entity.emoji;
    this.type = this.entity.viewType;
    this.isPublic = this.entity.isPublic;
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  .saved-view-editor {
    ._top {
      display: flex;
      padding: 1.2rem;

      ._emoji-picker {
        margin-right: .8rem;
      }
    }

    ._inner {
      ._title {
        height: 2.1rem;
      }

      ._sub-title {
        max-width: 21rem;
        font-size: $font-size-2;
        color: $font-color-tertiary;
        white-space: normal;
      }
    }

    ._actions {
      padding: 1.2rem;
    }

    ._public-selector {
      ._switch-wrapper {
        width: 100%;
        pointer-events: none;
      }
    }
  }
</style>
