<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.5 10.9072H2.5V13.0969H21.5V10.9072Z"
      fill="currentColor"
    />
    <path
      d="M21.5 16.8444H2.5V19.0341H21.5V16.8444Z"
      fill="currentColor"
    />
    <path
      d="M21.499 4.97H2.5V7.15971H21.499V4.97Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default { name: 'Hamburger' };
</script>

<style scoped lang="scss" type="text/scss">

</style>
