<template>
  <m-tooltip
    :disabled="hasDefaultSlot || !isTitleEllipsisActive"
    :mouse-enter-delay=".5"
  >
    <div
      :style="style"
      :class="['m-tag', clickable ? '-clickable' : '']"
      @click="$emit('click', $event)"
      @mouseover="hover = true"
      @mouseleave="hover = false"
    >
      <div class="_content">
        <m-icon-display
          v-if="typeof icon === 'object' && !isEmptyIcon(icon)"
          class="_emoji"
          :icon="icon"
          :size="localIconSize"
        />
        <m-icon
          v-else-if="typeof icon === 'string' && !isEmpty(icon)"
          class="_icon"
          :type="icon"
          :size="`${localIconSize}`"
          :color="localIconColor"
        />
        <div
          v-if="!hasDefaultSlot"
          ref="titleRef"
          class="_title"
          :style="{ ...titleStyle }"
        >
          {{ localTitle }}
        </div>
        <template v-if="hasDefaultSlot">
          <slot />
        </template>
      </div>
      <div
        v-if="closeable"
        class="_action"
        @click="$emit('close')"
      >
        <m-icon
          type="close"
          size="11"
        />
      </div>
    </div>
    <template #title>
      {{ localTitle }}
    </template>
  </m-tooltip>
</template>

<script>
import useElementsTruncate from 'shared/composables/element-truncate';
import { COLOR_NONE } from 'shared/constants';
import { Icon, isEmptyIcon } from 'shared/lib/icon';
import { automaticColor, rgbaToHex } from 'shared/lib/color';
import { getColor } from 'shared/lib/color-map';
import { isEmpty } from 'shared/lib/object/object';
import { mStyleProps, resolveStyles } from 'shared/lib/m-style-props';
import { optionColor } from 'shared/constants.json';
import { ref } from 'vue';
import { shadeColor } from 'shared/lib/style';

export default {
  name: 'MTag',
  props: {
    ...mStyleProps,
    title: {
      type: String,
      default: '',
    },
    titleStyle: {
      type: Object,
      default: () => {
      },
    },
    icon: {
      type: [Icon, String],
      default: null,
    },
    iconSize: {
      type: Number,
      default: null,
    },
    iconColor: {
      type: String,
      default: '',
    },
    large: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    xs: {
      type: Boolean,
      default: false,
    },
    xxs: {
      type: Boolean,
      default: false,
    },
    strong: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    automaticColorFallback: {
      type: Boolean,
      default: false,
    },
    closeable: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: '',
      validator(val) {
        if (val === '') {
          return true;
        }
        return [COLOR_NONE, 'success', 'warning', 'error', 'light', 'super-light', ...optionColor.all].includes(val);
      },
    },
    customColor: {
      type: Object,
      default: () => null,
    },
    hasDefaultSlot: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click', 'close'],
  setup() {
    const titleRef = ref(null);
    const { isTruncated: isTitleEllipsisActive } = useElementsTruncate([titleRef]);
    return { titleRef, isTitleEllipsisActive };
  },
  data() {
    return { hover: false };
  },
  computed: {
    localTitle() {
      return this.title === '' ? this.$t('general.untitled') : this.title;
    },
    colorStyle() {
      if (!this.hover || !this.clickable || this.color === COLOR_NONE) {
        return this.cs;
      }

      if (this.cs.backgroundColor.indexOf('rgb') > -1) {
        return {
          ...this.cs,
          backgroundColor: shadeColor(rgbaToHex(this.cs.backgroundColor), -3),
        };
      }

      return {
        ...this.cs,
        backgroundColor: shadeColor(this.cs.backgroundColor, -3),
      };
    },
    cs() {
      if (this.customColor !== null) {
        return {
          color: this.customColor.color,
          backgroundColor: this.customColor.backgroundColor,
        };
      }

      if (this.color === COLOR_NONE) {
        return { color: this.$colors.grey.darken4, background: 'transparent' };
      }

      if (this.automaticColorFallback && this.color === '') {
        const color = automaticColor(this.title, optionColor.all.map((c) => getColor(c)));
        return {
          color: this.$colors.grey.darken4,
          backgroundColor: color,
        };
      }
      switch (this.color) {
        case 'success':
          return {
            color: this.$colors.green.darken1,
            backgroundColor: this.$colors.green.lighten5,
          };
        case 'warning':
          return {
            color: this.$colors.yellow.darken1,
            backgroundColor: this.$colors.yellow.lighten4,
          };
        case 'error':
          return {
            color: this.$colors.red.base,
            backgroundColor: this.$colors.red.lighten5,
          };
        case 'primary':
          return {
            color: this.$colors.blue.base,
            backgroundColor: this.$colors.blue.lighten5,
          };
        case 'light':
          return {
            color: this.$colors.grey.base,
            backgroundColor: this.$colors.grey.lighten5,
          };
        case 'super-light':
          return {
            color: this.$colors.grey.base,
            backgroundColor: this.$colors.grey.lighten6,
          };
        case optionColor.grey:
          return {
            color: this.$colors.grey.darken4,
            backgroundColor: this.$colors.grey.lighten3,
          };
        case optionColor.blue:
          return {
            color: this.$colors.blue.darken3,
            backgroundColor: this.$colors.blue.lighten3,
          };
        case optionColor.yellow:
          return {
            color: this.$colors.yellow.darken3,
            backgroundColor: this.$colors.yellow.lighten3,
          };
        case optionColor.red:
          return {
            color: this.$colors.red.darken4,
            backgroundColor: this.$colors.red.lighten5,
          };
        case optionColor.orange:
          return {
            color: this.$colors.orange.darken4,
            backgroundColor: this.$colors.orange.lighten4,
          };
        case optionColor.brown:
          return {
            color: this.$colors.brown.darken3,
            backgroundColor: this.$colors.brown.lighten4,
          };
        case optionColor.pink:
          return {
            color: this.$colors.pink.darken3,
            backgroundColor: this.$colors.pink.lighten4,
          };
        case optionColor.green:
          return {
            color: this.$colors.green.darken3,
            backgroundColor: this.$colors.green.lighten4,
          };
        case optionColor.purple:
          return {
            color: this.$colors.purple.darken4,
            backgroundColor: this.$colors.purple.lighten4,
          };
        case optionColor.default:
        default:
          return {
            color: this.$colors.grey.darken3,
            backgroundColor: this.$colors.grey.lighten5,
          };
      }
    },
    sizeStyle() {
      switch (true) {
        case this.large:
          return { height: '3.2rem' };
        case this.small:
          return { height: '2rem' };
        case this.xs:
          return { height: '1.8rem' };
        case this.xxs:
          return { height: '1.4rem' };
        default:
          return { height: '2.4rem' };
      }
    },
    localIconSize() {
      if (this.iconSize !== null) {
        return this.iconSize;
      }

      switch (true) {
        case this.xxs:
          return 10;
        case this.xs:
          return 12;
        default:
          return 13;
      }
    },
    localIconColor() {
      if (this.iconColor !== '') {
        return this.iconColor;
      }

      if (this.color !== '') {
        switch (this.color) {
          case 'success':
            return this.$colors.green.lighten1;
          case 'warning':
            return this.$colors.yellow.lighten1;
          case 'error':
            return this.$colors.red.lighten1;
          case 'primary':
            return this.$colors.blue.lighten1;
          case 'light':
            return this.$colors.grey.lighten2;
          case 'super-light':
            return this.$colors.grey.lighten3;
          case optionColor.grey:
            return this.$colors.grey.base;
          case optionColor.blue:
            return this.$colors.blue.base;
          case optionColor.yellow:
            return this.$colors.yellow.darken1;
          case optionColor.red:
            return this.$colors.red.lighten1;
          case optionColor.orange:
            return this.$colors.orange.base;
          case optionColor.brown:
            return this.$colors.brown.base;
          case optionColor.pink:
            return this.$colors.pink.base;
          case optionColor.green:
            return this.$colors.green.base;
          case optionColor.purple:
            return this.$colors.purple.base;
          case optionColor.default:
          default:
            return this.$colors.grey.base;
        }
      }
      return '';
    },
    makePadding() {
      if (this.color === COLOR_NONE) {
        return { padding: 0 };
      }
      if (this.rounded) {
        return { padding: '0 .9rem 0 .7rem' };
      }

      return { padding: '0 .4rem' };
    },
    borderRadius() {
      if (this.rounded) {
        const size = parseFloat(this.sizeStyle.height);
        return { borderRadius: `${size / 2}rem` };
      }

      return {};
    },
    fontStyle() {
      const style = {};
      switch (true) {
        case this.xxs:
          style.fontSize = this.$fontSizes[0];
          break;
        case this.xs:
          style.fontSize = this.$fontSizes[4];
          break;
        default:
          style.fontSize = this.$fontSizes[4];
          break;
      }
      switch (true) {
        case this.strong:
          style.fontWeight = 600;
          style.textTransform = 'uppercase';
          break;
        default:
          break;
      }
      return style;
    },
    style() {
      const style = {
        ...this.colorStyle,
        ...this.sizeStyle,
        ...this.fontStyle,
        ...this.borderRadius,
        ...this.makePadding,
      };

      return {
        ...style,
        ...resolveStyles(this.mStyle),
        cursor: 'normal',
      };
    },
  },
  methods: { isEmpty, isEmptyIcon },
};
</script>

<style scoped lang="scss" type="text/scss">
  .m-tag {
    display: flex;
    align-items: center;
    font-size: $font-size-4;
    line-height: 1.15;
    pointer-events: inherit;
    cursor: inherit;
    border: none;
    border-radius: $tag-border-radius;

    &.-clickable {
      cursor: pointer;
    }

    ._content {
      display: flex;
      align-items: center;
      float: left;
      max-width: 100%;
      height: inherit;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      ._emoji {
        margin-right: .4rem;

        // Chrome bug: https://bugs.chromium.org/p/chromium/issues/detail?id=596223
        // remove the following lines when fixed
        @media not screen and (min-device-pixel-ratio: 2),
          not screen and (min-resolution: 192dpi),
          not screen and (min-resolution: 2dppx) {
          margin-right: .6rem;
        }
      }

      ._title {
        max-width: 100%;
        overflow: hidden;
        line-height: 1.6;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      ._icon {
        margin-right: .4rem;
      }
    }

    ._action {
      margin-left: .4rem;
      color: map_get($grey, 'darken-2');
      cursor: pointer;

      &:hover {
        color: map_get($grey, 'base');
      }
    }
  }
</style>
