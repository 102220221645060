import useRepo from '@/nebula/repo';
import { CREATED_AT, RESULT, TYPE } from 'shared/api/query/constants';
import { appFeedbackChildren } from '@/api/query/nebula/app-feedback';
import { appFeedback as appFeedbackConfig } from 'shared/api/query/configs.json';

export default function useAppFeedbackRepo() {
  const repo = useRepo(appFeedbackConfig.model);

  const getList = () => repo.getList({
    alias: RESULT,
    func: { name: TYPE, args: [{ value: appFeedbackConfig.model }] },
    model: appFeedbackConfig.model,
    default: [],
    order: [
      {
        attr: CREATED_AT,
        desc: true,
      },
    ],
    args: { first: '2' },
    children: appFeedbackChildren,
  });

  return {
    ...repo,
    getList,
  };
}
