<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.66403 17.0858C5.26436 17.5626 4.53099 17.5626 4.13132 17.0858L3.53853 16.3787C3.227 16.007 3.227 15.4654 3.53853 15.0938L11.2336 5.91419C11.6333 5.43742 12.3667 5.43742 12.7664 5.91419L20.4615 15.0938C20.773 15.4654 20.773 16.007 20.4615 16.3787L19.8687 17.0858C19.469 17.5626 18.7356 17.5626 18.336 17.0858L12 9.52753L5.66403 17.0858Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default { name: 'Up' };
</script>

<style scoped lang="scss" type="text/scss">

</style>
