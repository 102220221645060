import { EVENTS } from '@/lib/constants';
import { EventBus } from '@/lib/event-bus';
import { environmentVariable, resolveEnvironmentVariable } from '@/lib/env';

const registerEventListeners = (custify) => {
  EventBus.$on(EVENTS.SESSION.LOGIN, ({ user, account }) => {
    custify.identify({
      userId: user.uid,
      email: user.email,
      company_id: account.uid,
    });
    custify.track(EVENTS.SESSION.LOGIN, {});
  });

  EventBus.$on(EVENTS.VIEW.PUBLISHED_VIEW_VISITED, ({ account }) => {
    custify.identify({ company_id: account.uid });
    custify.track(EVENTS.VIEW.PUBLISHED_VIEW_VISITED, {});
  });

  const noPayloadEvents = [
    EVENTS.SESSION.LOGOUT,
    EVENTS.NAVIGATION.FAVORITE_CREATED,
    EVENTS.NAVIGATION.FAVORITE_DELETED,
    EVENTS.NAVIGATION.PIN_CREATED,
    EVENTS.NAVIGATION.PIN_DELETED,
    EVENTS.PLANNING.PLANNING_CREATED,
    EVENTS.CHECKIN.UPDATE_CREATED,
    EVENTS.CHECKIN.UPDATE_DELETED,
    EVENTS.CHECKIN.UPDATE_TEMPLATE_CREATED,
    EVENTS.CHECKIN.UPDATE_TEMPLATE_DELETED,
    EVENTS.CHECKIN.SCHEDULE_CREATED,
    EVENTS.CHECKIN.SCHEDULE_DELETED,
    EVENTS.VIEW.VIEW_CREATED,
    EVENTS.VIEW.VIEW_DELETED,
    EVENTS.VIEW.ATLAS_VIEW_VISITED,
    EVENTS.VIEW.GOAL_TREE_VIEW_VISITED,
    EVENTS.VIEW.VIEW_PUBLISHED,
    EVENTS.VIEW.VIEW_UNPUBLISHED,
    EVENTS.PROPERTY.GOAL_CYCLE_CREATED,
    EVENTS.PROPERTY.GOAL_CYCLE_DELETED,
    EVENTS.INTERACTION.REACTION_CREATED,
    EVENTS.INTERACTION.COMMENT_CREATED,
    EVENTS.INSIGHTS.DASHBOARD_VISITED,
    EVENTS.INSIGHTS.CUSTOM_DASHBOARD_VISITED,
    EVENTS.RESOURCE_CENTER.RESOURCE_CENTER_VIEWED,
    EVENTS.SETTINGS.PROFILE_SETTINGS_VISITED,
  ];
  noPayloadEvents.forEach((eventName) => EventBus.$on(eventName, () => {
    custify.track(eventName, {});
  }));

  // User
  EventBus.$on(EVENTS.USER.USERS_CREATED, ({ users }) => {
    custify.track(EVENTS.USER.USERS_CREATED, { count: users.length });
  });
  EventBus.$on(EVENTS.USER.USERS_DELETED, ({ users }) => {
    custify.track(EVENTS.USER.USERS_DELETED, { count: users.length });
  });
  EventBus.$on(EVENTS.USER.USERS_INVITED, ({ users }) => {
    custify.track(EVENTS.USER.USERS_INVITED, { count: users.length });
  });
  // Goal
  EventBus.$on(EVENTS.GOAL.GOALS_CREATED, ({ goals }) => {
    custify.track(EVENTS.GOAL.GOALS_CREATED, { count: goals.length });
  });
  EventBus.$on(EVENTS.GOAL.GOALS_DELETED, ({ goals }) => {
    custify.track(EVENTS.GOAL.GOALS_DELETED, { count: goals.length });
  });
  EventBus.$on(EVENTS.GOAL.GOAL_ACTIVITIES_CREATED, ({ goalActivities }) => {
    custify.track(EVENTS.GOAL.GOAL_ACTIVITIES_CREATED, { count: goalActivities.length });
  });
  // Planning
  EventBus.$on(EVENTS.PLANNING.GOALS_CREATED, ({ goals }) => {
    custify.track(EVENTS.PLANNING.GOALS_CREATED, { count: goals.length });
  });
  EventBus.$on(EVENTS.PLANNING.GOALS_DELETED, ({ goals }) => {
    custify.track(EVENTS.PLANNING.GOALS_DELETED, { count: goals.length });
  });
  EventBus.$on(EVENTS.PLANNING.GOALS_PUBLISHED, ({ goals }) => {
    custify.track(EVENTS.PLANNING.GOALS_PUBLISHED, { count: goals.length });
  });
  // Grid Page
  EventBus.$on(EVENTS.GRID_PAGE.GRID_PAGES_CREATED, ({ gridPages }) => {
    custify.track(EVENTS.GRID_PAGE.GRID_PAGES_CREATED, { count: gridPages.length });
  });
  EventBus.$on(EVENTS.GRID_PAGE.GRID_PAGES_DELETED, ({ gridPages }) => {
    custify.track(EVENTS.GRID_PAGE.GRID_PAGES_DELETED, { count: gridPages.length });
  });
  EventBus.$on(EVENTS.GRID_PAGE.GRID_PAGE_TILES_CREATED, ({ gridPageTiles, via }) => {
    const payload = { count: gridPageTiles.length };
    if (via !== undefined) {
      payload.via = via;
    }
    custify.track(EVENTS.GRID_PAGE.GRID_PAGE_TILES_CREATED, payload);
  });
  EventBus.$on(EVENTS.GRID_PAGE.GRID_PAGE_TILES_DELETED, ({ gridPageTiles }) => {
    custify.track(EVENTS.GRID_PAGE.GRID_PAGE_TILES_DELETED, { count: gridPageTiles.length });
  });
  // Upsell
  EventBus.$on(EVENTS.UPSELL.FEATURE_GATE_VIEWED, ({ flag, plan }) => {
    custify.track(EVENTS.UPSELL.FEATURE_GATE_VIEWED, { flag, plan });
  });
  EventBus.$on(EVENTS.UPSELL.CONTACT_SALES_VIEWED, ({ currentPlan }) => {
    custify.track(EVENTS.UPSELL.CONTACT_SALES_VIEWED, { currentPlan });
  });
  EventBus.$on(EVENTS.UPSELL.CONTACT_SUPPORT_VIEWED, ({ currentPlan }) => {
    custify.track(EVENTS.UPSELL.CONTACT_SUPPORT_VIEWED, { currentPlan });
  });
  // Integration
  EventBus.$on(EVENTS.INTEGRATION.DATASOURCE_CREATED, ({ dataSource }) => {
    custify.track(EVENTS.INTEGRATION.DATASOURCE_CREATED, { type: dataSource.type });
  });
  EventBus.$on(EVENTS.INTEGRATION.DATASOURCE_DELETED, ({ dataSource }) => {
    custify.track(EVENTS.INTEGRATION.DATASOURCE_DELETED, { type: dataSource.type });
  });
};

const custifyClient = {
  install(app, options) {
    if (resolveEnvironmentVariable(environmentVariable.CUSTIFY_ACTIVE) === 'true' && options.isForeignLogin === false) {
      app.loadScript('https://assets.custify.com/assets/track.min.js').then(() => {
        /* eslint-disable no-undef */
        app.config.globalProperties.$custify = _ctrack;
        _ctrack.setAccount(resolveEnvironmentVariable(environmentVariable.CUSTIFY_ACCOUNT_ID));
        registerEventListeners(_ctrack);
        /* eslint-enable no-undef */
      });
    }
  },
};

export default custifyClient;
