import { DEFAULT_ATTRIBUTES, ONE_TO_ONE, RESULT, TYPE, UID, VAR } from 'shared/api/query/constants';
import { NONE } from '@/lib/constants';
import { accessPolicyChildren, reverseEdge, savedViewChildren, selectedViewChildren, userEdgesSlim } from 'shared/api/query/edges';
import {
  accessPolicy as accessPolicyConfig,
  account as accountConfig,
  goal as goalConfig,
  gridPage as gridPageConfig,
  gridPageRow as gridPageRowConfig,
  gridPageTile as gridPageTileConfig,
  gridPageTileSingleGoal as gridPageTileSingleGoalConfig,
  gridPageTileText as gridPageTileTextConfig,
  savedView as savedViewConfig,
  selectedView as selectedViewConfig,
  storageObject as storageObjectConfig,
  user as userConfig,
} from 'shared/api/query/configs.json';
import { accessPolicyType, dateScopeDynamicType, dateScopeType, gridPageTileSingleGoalType, gridPageType } from 'shared/constants.json';
import {
  bulkMutate,
  createEntityV2,
  deleteEntities,
  updateEntities,
  updateEntityV2,
} from '@/store/actions';
import { computed } from 'vue';
import { dogma } from '@/api';
import { spreadQueries } from '@/lib/query';
import { storageObjectEdges } from '@/api/query/nebula/storage-object';
import { useStore } from 'vuex';

export default function useCustomGridRepo() {
  const store = useStore();

  const gridPageTileTextChildren = [
    { attr: UID },
    { attr: gridPageTileTextConfig.edges.content },
    { attr: gridPageTileTextConfig.edges.tile, model: gridPageTileConfig.model, children: [{ attr: UID }] },
  ];

  const gridPageTileSingleGoalChildren = [
    { attr: UID },
    { attr: gridPageTileSingleGoalConfig.edges.goal, model: goalConfig.model, default: null, children: [{ attr: UID }] },
    { attr: gridPageTileSingleGoalConfig.edges.tile, model: gridPageTileConfig.model, children: [{ attr: UID }] },
    { attr: gridPageTileSingleGoalConfig.edges.type, default: gridPageTileSingleGoalType.number },
    { attr: gridPageTileSingleGoalConfig.edges.compareTo, default: { value: NONE } },
    { attr: gridPageTileSingleGoalConfig.edges.timeRange, default: { type: dateScopeType.between, dynamicType: dateScopeDynamicType.allTime } },
  ];

  const gridPageChildren = [
    ...DEFAULT_ATTRIBUTES,
    {
      attr: gridPageConfig.edges.creator,
      model: userConfig.model,
      default: null,
      children: userEdgesSlim,
    },
    { attr: gridPageConfig.edges.title, default: '' },
    { attr: gridPageConfig.edges.icon, default: '' },
    {
      attr: gridPageConfig.edges.image,
      model: storageObjectConfig.model,
      children: storageObjectEdges,
      default: null,
    },
    { attr: gridPageConfig.edges.description, default: null },
    { attr: gridPageConfig.edges.rowOrder, default: [] },
    {
      attr: reverseEdge(gridPageRowConfig.edges.gridPage),
      alias: 'rows',
      model: gridPageRowConfig.model,
      default: [],
      children: [{ attr: UID }],
    },
    {
      attr: gridPageConfig.edges.accessPolicy,
      model: accessPolicyConfig.model,
      children: accessPolicyChildren,
    },
    { attr: gridPageConfig.edges.isPublic },
    { attr: gridPageConfig.edges.accessRight, default: accessPolicyType.read },
  ];

  const gridPageTileChildren = [
    { attr: UID },
    { attr: gridPageTileConfig.edges.columns },
    { attr: gridPageTileConfig.edges.type },
    { attr: gridPageTileConfig.edges.title, default: '' },
    { attr: gridPageTileConfig.edges.description, default: '' },
    { attr: gridPageTileConfig.edges.hideBorder },
    { attr: gridPageTileConfig.edges.gridPageRow, model: gridPageRowConfig.model, children: [{ attr: UID }] },
  ];

  const gridPageRowChildren = [
    { attr: UID },
    { attr: gridPageRowConfig.edges.height, default: null },
    { attr: gridPageRowConfig.edges.tileOrder, default: [] },
    {
      attr: reverseEdge(gridPageTileConfig.edges.gridPageRow),
      alias: 'tiles',
      model: gridPageTileConfig.model,
      children: [{ attr: UID }],
      default: [],
    },
    {
      attr: gridPageRowConfig.edges.gridPage,
      model: gridPageConfig.model,
      children: [{ attr: UID }],
    },
  ];

  const gridPage = (id) => computed(() => {
    const res = store.state.gridPage.find((page) => page.uid === id);
    if (res === undefined) {
      return null;
    }

    return res;
  });

  const gridPages = computed(() => store.state.gridPage);

  const gridPageRows = computed(() => store.state.gridPageRow);

  const gridPageTiles = computed(() => store.state.gridPageTile);

  const gridPageTileText = computed(() => store.state.gridPageTileText);

  const gridPageTileSingleGoal = computed(() => store.state.gridPageTileSingleGoal);

  const createGridPage = (entity) => createEntityV2(store, {
    entity,
    model: gridPageConfig.model,
    attributes: gridPageChildren,
  }).then((response) => {
    if (response.status !== 201) {
      throw new Error('failed to create grid page');
    }

    return gridPage(response.data.uid).value;
  });

  const updateGridPage = (entity) => updateEntityV2(store, {
    entity,
    model: gridPageConfig.model,
    attributes: gridPageChildren,
  }).then((response) => {
    if (response.status !== 200) {
      throw new Error('error while updating grid page');
    }

    return gridPage(response.data.uid).value;
  });

  const updateGridPageAccessPolicy = (entity) => updateEntityV2(store, {
    entity,
    model: gridPageConfig.model,
    attributes: [
      { attr: UID },
      {
        attr: gridPageConfig.edges.accessPolicy,
        model: accessPolicyConfig.model,
        children: accessPolicyChildren,
      },
      { attr: gridPageConfig.edges.isPublic },
      { attr: gridPageConfig.edges.accessRight, default: accessPolicyType.read },
    ],
    mutation: 'GRID_PAGE_ACCESS_POLICY_UPDATED',
  }).then((response) => {
    if (response.status !== 200) {
      throw new Error('error while updating grid page access policy');
    }

    if (response.data.uid !== entity.uid) {
      store.commit('ENTITIES_DELETED', { entities: [{ uid: entity.uid }], model: gridPageConfig.model });
    }

    return gridPage(entity.uid).value;
  });

  const updateGridPagesAccessPolicy = (entities) => updateEntities(store, {
    entities,
    model: gridPageConfig.model,
    attributes: [
      { attr: UID },
      {
        attr: gridPageConfig.edges.accessPolicy,
        model: accessPolicyConfig.model,
        children: accessPolicyChildren,
      },
      { attr: gridPageConfig.edges.isPublic },
      { attr: gridPageConfig.edges.accessRight, default: accessPolicyType.read },
    ],
    mutation: 'GRID_PAGES_ACCESS_POLICY_UPDATED',
  }).then((response) => {
    if (response.status !== 200) {
      throw new Error('error while updating grid pages access policy');
    }

    if (response.data.length !== entities.length) {
      const ids = response.data.map((g) => g.uid);
      const filtered = entities.filter((e) => !ids.includes(e.uid));
      store.commit('ENTITIES_DELETED', { entities: filtered, model: gridPageConfig.model });
    }

    return response.data.map((page) => gridPages.value.find((fromStore) => fromStore.uid === page.uid));
  });

  const deleteGridPage = (entity) => deleteEntities(store, {
    entities: [{ uid: entity.uid }],
    model: gridPageConfig.model,
  }).then((response) => {
    if (response.status !== 200) {
      throw new Error('error while deleting grid page');
    }
  });

  const deleteGridPages = (entities) => deleteEntities(store, {
    entities: entities.map((entity) => ({ uid: entity.uid })),
    model: gridPageConfig.model,
  }).then((response) => {
    if (response.status !== 200) {
      throw new Error('error while deleting grid pages');
    }
  });

  const queryGridPage = (id) => dogma.query([
    {
      alias: 'gridPages',
      func: { name: UID },
      uid: [id],
      model: gridPageConfig.model,
      children: gridPageChildren,
    },
    {
      alias: 'var',
      func: { name: UID },
      uid: [id],
      model: gridPageConfig.model,
      children: [
        {
          attr: reverseEdge(gridPageRowConfig.edges.gridPage),
          model: gridPageRowConfig.model,
          children: [
            { attr: UID, var: 'rows' },
            {
              attr: reverseEdge(gridPageTileConfig.edges.gridPageRow),
              model: gridPageTileConfig.model,
              children: [{ attr: UID, var: 'tiles' }],
            },
          ],
        },
      ],
    },
    {
      alias: 'gridPageRows',
      needsVar: [{ name: 'rows', typ: 1 }],
      func: { name: UID, needsVar: [{ name: 'rows', typ: 1 }] },
      model: gridPageRowConfig.model,
      children: gridPageRowChildren,
    },
    {
      alias: 'gridPageTiles',
      needsVar: [{ name: 'tiles', typ: 1 }],
      func: { name: UID, needsVar: [{ name: 'tiles', typ: 1 }] },
      model: gridPageTileConfig.model,
      children: gridPageTileChildren,
    },
  ]).then((response) => {
    if (response.status !== 200) {
      throw new Error('error querying for grid page');
    }

    store.commit('ENTITIES_RETRIEVED_V2', { entities: response.data.gridPages, model: gridPageConfig.model });
    store.commit('ENTITIES_RETRIEVED_V2', { entities: response.data.gridPageRows, model: gridPageRowConfig.model });
    store.commit('ENTITIES_RETRIEVED_V2', { entities: response.data.gridPageTiles, model: gridPageTileConfig.model });

    return {
      gridPages: response.data.gridPages.map((page) => gridPages.value.find((fromStore) => fromStore.uid === page.uid)),
      gridPageRows: response.data.gridPageRows.map((row) => gridPageRows.value.find((fromStore) => fromStore.uid === row.uid)),
      gridPageTiles: response.data.gridPageTiles.map((tile) => gridPageTiles.value.find((fromStore) => fromStore.uid === tile.uid)),
    };
  });

  const queryGridPages = (ids = undefined) => {
    const query = {
      alias: 'gridPages',
      func: { name: TYPE, args: [{ value: gridPageConfig.model }] },
      model: gridPageConfig.model,
      children: gridPageChildren,
    };
    if (ids !== undefined) {
      query.func = { name: UID };
      query.uid = ids;
    }
    return dogma.query([query]).then((response) => {
      if (response.status !== 200) {
        throw new Error('error querying for grid pages');
      }

      store.commit('ENTITIES_RETRIEVED_V2', { entities: response.data.gridPages, model: gridPageConfig.model });
      return { gridPages: response.data.gridPages.map((page) => gridPages.value.find((fromStore) => fromStore.uid === page.uid)) };
    });
  };

  const queryGridPageRows = (gridPageId, ids) => dogma.query([
    {
      alias: 'var',
      func: { name: UID },
      UID: [gridPageId],
      model: gridPageConfig.model,
      children: [{
        attr: reverseEdge(gridPageRowConfig.edges.gridPage),
        model: gridPageRowConfig.model,
        children: [{ attr: UID, var: 'rows' }],
      }],
    },
    {
      alias: 'gridPageRows',
      uid: ids,
      func: { name: UID },
      filter: { func: { name: UID, needsVar: [{ name: 'rows', typ: 1 }] } },
      model: gridPageRowConfig.model,
      children: gridPageRowChildren,
    },
  ]).then((response) => {
    if (response.status !== 200) {
      throw new Error('error querying grid page rows');
    }

    store.commit('ENTITIES_RETRIEVED_V2', { entities: response.data.gridPageRows, model: gridPageRowConfig.model });
    return response.data.gridPageRows.map((row) => gridPageRows.value.find((fromStore) => fromStore.uid === row.uid));
  });

  const queryGridPageTiles = (gridPageId, ids) => dogma.query([
    {
      alias: 'var',
      func: { name: UID },
      UID: [gridPageId],
      model: gridPageConfig.model,
      children: [{
        attr: reverseEdge(gridPageRowConfig.edges.gridPage),
        model: gridPageRowConfig.model,
        children: [{
          attr: reverseEdge(gridPageTileConfig.edges.gridPageRow),
          model: gridPageTileConfig.model,
          children: [{ attr: UID, var: 'tiles' }],
        }],
      }],
    },
    {
      alias: 'gridPageTiles',
      uid: ids,
      func: { name: UID },
      filter: { func: { name: UID, needsVar: [{ name: 'tiles', typ: 1 }] } },
      model: gridPageTileConfig.model,
      children: gridPageTileChildren,
    },
  ]).then((response) => {
    if (response.status !== 200) {
      throw new Error('error querying grid page tiles');
    }

    store.commit('ENTITIES_RETRIEVED_V2', { entities: response.data.gridPageTiles, model: gridPageTileConfig.model });
    return response.data.gridPageTiles.map((tile) => gridPageTiles.value.find((fromStore) => fromStore.uid === tile.uid));
  });

  const models = {
    [gridPageType.text]: gridPageTileTextConfig.model,
    [gridPageType.singleGoal]: gridPageTileSingleGoalConfig.model,
  };

  const attributes = {
    [gridPageType.text]: gridPageTileTextChildren,
    [gridPageType.singleGoal]: gridPageTileSingleGoalChildren,
  };

  const storeObjects = {
    [gridPageType.text]: gridPageTileText,
    [gridPageType.singleGoal]: gridPageTileSingleGoal,
  };

  const queryGridPageTileByIds = (type, ids) => dogma.query([
    {
      alias: RESULT,
      func: { name: UID },
      uid: ids,
      model: models[type],
      children: attributes[type],
    },
  ]).then((response) => {
    if (response.status !== 200) {
      throw new Error(`error querying by ids for grid page tile of type ${type}`);
    }

    store.commit('ENTITIES_RETRIEVED_V2', { entities: response.data[RESULT], model: models[type] });

    return response.data[RESULT].map((t) => storeObjects[type].value.find((fromStore) => fromStore.uid === t.uid));
  });

  const queryGridPageTileByTile = (type, gridPageTile) => dogma.query([
    {
      alias: VAR,
      func: { name: UID },
      uid: [gridPageTile.uid],
      model: gridPageTileConfig.model,
      children: [{
        model: models[type],
        attr: reverseEdge('tile'),
        children: [{ attr: UID, var: 'tile' }],
      }],
    },
    {
      alias: RESULT,
      func: { name: UID, needsVar: [{ name: 'tile', typ: 1 }] },
      needsVar: [{ name: 'tile', typ: 1 }],
      model: models[type],
      children: attributes[type],
    },
  ]).then((response) => {
    if (response.status !== 200) {
      throw new Error(`error querying by tile for grid page tile of type ${type}`);
    }

    store.commit('ENTITIES_RETRIEVED_V2', { entities: response.data[RESULT], model: models[type] });
    return storeObjects[type].value.find((tile) => tile.uid === response.data[RESULT][0].uid);
  });

  const createGridPageTile = (tile, otherTiles = []) => {
    const payloads = {};

    if (tile.gridPageRow.uid === undefined) {
      payloads.createRow = {
        nodes: [tile.gridPageRow],
        model: gridPageRowConfig.model,
        attributes: gridPageRowChildren,
      };
    }

    payloads.createTile = {
      nodes: [tile],
      model: gridPageTileConfig.model,
      attributes: gridPageTileChildren,
    };

    payloads.updateRow = {
      nodes: [tile.gridPageRow],
      model: gridPageRowConfig.model,
      attributes: gridPageRowChildren,
    };

    payloads.updatePage = {
      nodes: [tile.gridPageRow.gridPage],
      model: gridPageConfig.model,
      attributes: gridPageChildren,
    };

    if (otherTiles.length > 0) {
      payloads.updateOtherTiles = {
        nodes: otherTiles,
        model: gridPageTileConfig.model,
        attributes: gridPageTileChildren,
      };
    }

    if (tile.singleGoalTile !== null) {
      payloads.createSingleGoalTile = {
        nodes: [tile.singleGoalTile],
        model: gridPageTileSingleGoalConfig.model,
        attributes: gridPageTileSingleGoalChildren,
      };
    }

    if (tile.textTile !== null) {
      payloads.createTextTile = {
        nodes: [tile.textTile],
        model: gridPageTileTextConfig.model,
        attributes: gridPageTileTextChildren,
      };
    }

    Object.keys(payloads).forEach((alias) => {
      payloads[alias] = { alias, ...payloads[alias] };
    });

    const bulkPayloads = [
      payloads.createRow,
      payloads.createTile,
      payloads.updateRow,
      payloads.updatePage,
      payloads.updateOtherTiles,
      payloads.createSingleGoalTile,
      payloads.createTextTile,
    ].filter((p) => p !== undefined);

    return bulkMutate(store, bulkPayloads).then((response) => {
      if (response.status !== 200) {
        throw new Error(`error creating grid page tile of type ${tile.type}`);
      }

      return gridPageTiles.value.find((t) => t.uid === response.data.createTile[0].uid);
    });
  };

  const createSingleGoalTiles = (singleGoalTiles, gridPage) => {
    const payload = {};

    payload.createRows = {
      nodes: singleGoalTiles.map((sgt) => sgt.tile.gridPageRow),
      model: gridPageRowConfig.model,
      attributes: gridPageRowChildren,
    };

    payload.createTiles = {
      nodes: singleGoalTiles.map((sgt) => sgt.tile),
      model: gridPageTileConfig.model,
      attributes: gridPageTileChildren,
    };

    payload.updateRows = {
      nodes: singleGoalTiles.map((sgt) => sgt.tile.gridPageRow),
      model: gridPageRowConfig.model,
      attributes: gridPageRowChildren,
    };

    payload.createSingleGoalTiles = {
      nodes: singleGoalTiles,
      model: gridPageTileSingleGoalConfig.model,
      attributes: attributes[gridPageType.singleGoal],
    };

    payload.updateGridPage = {
      nodes: [gridPage],
      model: gridPageConfig.model,
      attributes: gridPageChildren,
    };

    const bulkPayloads = Object.keys(payload).map((alias) => ({ ...payload[alias], alias }));

    return bulkMutate(store, bulkPayloads).then((response) => {
      if (response.status !== 200) {
        throw new Error('error creating single goal tiles');
      }

      return response.data.createSingleGoalTiles.map((sgt) => gridPageTileSingleGoal.value.find((t) => t.uid === sgt.uid));
    });
  };

  const updateGridPageTile = (type, entity) => dogma.updateSingle(
    entity,
    models[type],
    attributes[type],
  ).then((response) => {
    if (response.status !== 200) {
      throw new Error(`error updating grid page of type ${type}`);
    }

    store.commit('ENTITIES_RETRIEVED_V2', { entities: [response.data], model: models[type] });
    return storeObjects[type].value.find((tile) => tile.uid === response.data.uid);
  });

  const removeGridPageTile = (tile, otherTiles = []) => {
    const payloads = {};

    payloads.removeTile = {
      nodes: [tile],
      model: gridPageTileConfig.model,
      attributes: gridPageTileChildren,
    };

    payloads.updateRow = {
      nodes: [tile.gridPageRow],
      model: gridPageRowConfig.model,
      attributes: gridPageRowChildren,
    };

    if (otherTiles.length > 0) {
      payloads.updateOtherTiles = {
        nodes: otherTiles,
        model: gridPageTileConfig.model,
        attributes: gridPageTileChildren,
      };
    }

    if (tile.gridPageRow.gridPage !== undefined) {
      payloads.updatePage = {
        nodes: [tile.gridPageRow.gridPage],
        model: gridPageConfig.model,
        attributes: gridPageChildren,
      };
    }

    const bulkPayloads = Object.keys(payloads).map((alias) => ({ ...payloads[alias], alias }));
    return bulkMutate(store, bulkPayloads).then((response) => {
      if (response.status !== 200) {
        throw new Error('error removing grid page tile');
      }

      return null;
    });
  };

  const moveTile = (tile, otherTiles, otherRows) => {
    const payloads = {};

    if (tile.gridPageRow.uid === undefined) {
      payloads.createRow = {
        nodes: [tile.gridPageRow],
        model: gridPageRowConfig.model,
        attributes: gridPageRowChildren,
      };
    }

    payloads.moveTile = {
      nodes: [tile],
      model: gridPageTileConfig.model,
      attributes: gridPageTileChildren,
    };

    payloads.updateRow = {
      nodes: [tile.gridPageRow],
      model: gridPageRowConfig.model,
      attributes: gridPageRowChildren,
    };

    if (tile.gridPageRow.gridPage !== undefined) {
      payloads.updateGridPage = {
        nodes: [tile.gridPageRow.gridPage],
        model: gridPageConfig.model,
        attributes: gridPageChildren,
      };
    }

    payloads.updateOtherTiles = {
      nodes: otherTiles,
      model: gridPageTileConfig.model,
      attributes: gridPageTileChildren,
    };

    payloads.updateOtherRows = {
      nodes: otherRows,
      model: gridPageRowConfig.model,
      attributes: gridPageRowChildren,
    };

    Object.keys(payloads).forEach((alias) => {
      payloads[alias] = { alias, ...payloads[alias] };
    });

    const bulkPayloads = [
      payloads.createRow,
      payloads.moveTile,
      payloads.updateRow,
      payloads.updateGridPage,
      payloads.updateOtherTiles,
      payloads.updateOtherRows,
    ].filter((p) => p !== undefined);

    return bulkMutate(store, bulkPayloads).then((response) => {
      if (response.status !== 200) {
        throw new Error('error bulk mutating gridPageTile and gridPageRow');
      }

      return gridPageTiles.value.find((t) => t.uid === response.data.moveTile[0].uid);
    });
  };

  const updateGridPageTiles = (tiles) => updateEntities(store, {
    entities: tiles,
    model: gridPageTileConfig.model,
    attributes: gridPageTileChildren,
    mutation: 'ENTITIES_RETRIEVED_V2',
  }).then((response) => {
    if (response.status !== 200) {
      throw new Error('error bulk mutating gridPageTile and gridPageRow');
    }

    return response.data.map((tile) => gridPageTiles.value.find((t) => t.uid === tile.uid));
  });

  const updateGridPageRow = (entity) => updateEntityV2(store, {
    entity,
    model: gridPageRowConfig.model,
    attributes: gridPageRowChildren,
  }).then((response) => {
    if (response.status !== 200) {
      throw new Error('error updating grid page row');
    }

    return gridPageRows.value.find((row) => row.uid === response.data.uid);
  });

  const deepGridPageTileChildren = spreadQueries([
    ...gridPageTileChildren,
    {
      attr: gridPageTileConfig.edges.gridPageRow,
      model: gridPageRowConfig.model,
      children: [
        { attr: UID },
        { attr: gridPageRowConfig.edges.tileOrder },
        { attr: gridPageRowConfig.edges.height, default: null },
        {
          attr: gridPageRowConfig.edges.gridPage,
          model: gridPageConfig.model,
          children: [{ attr: UID }, { attr: gridPageConfig.edges.rowOrder }],
        },
      ],
    },
    {
      alias: 'singleGoalTile',
      default: null,
      assoc: ONE_TO_ONE,
      attr: reverseEdge(gridPageTileSingleGoalConfig.edges.tile),
      model: gridPageTileSingleGoalConfig.model,
      children: spreadQueries([
        ...gridPageTileSingleGoalChildren,
        { attr: gridPageTileSingleGoalConfig.edges.tile, model: gridPageTileConfig.model, children: [{ attr: UID }] },
        { attr: gridPageTileSingleGoalConfig.edges.goal, model: goalConfig.model, children: [{ attr: UID }] },
      ]),
    },
    {
      alias: 'textTile',
      default: null,
      assoc: ONE_TO_ONE,
      attr: reverseEdge(gridPageTileTextConfig.edges.tile),
      model: gridPageTileTextConfig.model,
      children: spreadQueries([
        ...gridPageTileTextChildren,
        { attr: gridPageTileTextConfig.edges.tile, model: gridPageTileConfig.model, children: [{ attr: UID }] },
      ]),
    },
    {
      alias: 'savedViews',
      default: [],
      attr: reverseEdge(savedViewConfig.edges.gridPageTile),
      model: savedViewConfig.model,
      children: spreadQueries([
        ...savedViewChildren,
        { attr: savedViewConfig.edges.account, model: accountConfig.model, children: [{ attr: UID }] },
      ]),
    },
    {
      alias: 'selectedView',
      default: null,
      assoc: ONE_TO_ONE,
      attr: reverseEdge(selectedViewConfig.edges.gridPageTile),
      model: selectedViewConfig.model,
      children: spreadQueries([
        ...selectedViewChildren,
        { attr: selectedViewConfig.edges.user, model: userConfig.model, children: [{ attr: UID }] },
      ]),
    },
  ]);

  const queryGridPagesDeep = (gridPages) => dogma.query([
    {
      alias: 'gridPages',
      func: { name: UID },
      UID: gridPages.map((gridPage) => gridPage.uid),
      model: gridPageConfig.model,
      children: spreadQueries([
        ...gridPageChildren,
        { attr: gridPageConfig.edges.account, model: accountConfig.model, children: [{ attr: UID }] },
        { attr: gridPageConfig.edges.creator, model: userConfig.model, children: [{ attr: UID }] },
        {
          attr: reverseEdge(gridPageRowConfig.edges.gridPage),
          alias: 'rows',
          model: gridPageRowConfig.model,
          children: spreadQueries([
            ...gridPageRowChildren,
            {
              attr: reverseEdge(gridPageTileConfig.edges.gridPageRow),
              alias: 'tiles',
              model: gridPageTileConfig.model,
              children: deepGridPageTileChildren,
              default: [],
            },
          ]),
        },
      ]),
    },
  ]).then((response) => {
    if (response.status !== 200) {
      throw new Error('error querying grid page tile');
    }

    return response.data.gridPages;
  });

  const queryGridPageTileDeep = (tile) => dogma.query([
    {
      alias: 'tile',
      func: { name: UID },
      UID: [tile.uid],
      model: gridPageTileConfig.model,
      children: deepGridPageTileChildren,
    },
  ]).then((response) => {
    if (response.status !== 200) {
      throw new Error('error querying grid page tile');
    }

    return response.data.tile[0];
  });

  const createGridPagesDeep = ({ entities }) => {
    const payload = {};

    payload.createGridPages = {
      nodes: entities,
      model: gridPageConfig.model,
      attributes: gridPageChildren,
    };

    const rows = entities.map((gridPage) => {
      if (Array.isArray(gridPage.rows)) {
        return gridPage.rows;
      }

      return [];
    }).flat();
    let tiles = [];

    if (rows.length > 0) {
      payload.createRows = {
        nodes: rows,
        model: gridPageRowConfig.model,
        attributes: gridPageRowChildren,
      };

      payload.updateGridpage = {
        nodes: entities.map((e) => ({ ...e, accessPolicy: undefined })),
        model: gridPageConfig.model,
        attributes: gridPageChildren,
      };

      tiles = rows.map((row) => row.tiles).flat();
    }

    if (tiles.length > 0) {
      payload.createTiles = {
        nodes: tiles,
        model: gridPageTileConfig.model,
        attributes: gridPageTileChildren,
      };

      payload.updateRows = {
        nodes: rows,
        model: gridPageRowConfig.model,
        attributes: gridPageRowChildren,
      };

      const textTiles = tiles.filter((t) => t.textTile !== null).map((t) => t.textTile);
      const singleGoalTiles = tiles.filter((t) => t.singleGoalTile !== null).map((t) => t.singleGoalTile);
      const savedViews = tiles.map((t) => t.savedViews).flat();
      const selectedViews = tiles.filter((t) => t.selectedView !== null).map((t) => t.selectedView);

      if (textTiles.length > 0) {
        payload.createTextTiles = {
          nodes: textTiles,
          model: gridPageTileTextConfig.model,
          attributes: gridPageTileTextChildren,
        };
      }

      if (singleGoalTiles.length > 0) {
        payload.createSingleGoalTiles = {
          nodes: singleGoalTiles,
          model: gridPageTileSingleGoalConfig.model,
          attributes: gridPageTileSingleGoalChildren,
        };
      }

      if (savedViews.length > 0) {
        payload.createSavedViews = {
          nodes: savedViews,
          model: savedViewConfig.model,
          attributes: savedViewChildren,
          mutation: 'SAVED_VIEW_MUTATE',
        };
      }

      if (selectedViews.length > 0) {
        payload.createSelectedViews = {
          nodes: selectedViews,
          model: selectedViewConfig.model,
          attributes: selectedViewChildren,
          mutation: 'SELECTED_VIEW_MUTATE',
        };
      }
    }

    Object.keys(payload).forEach((alias) => {
      payload[alias].alias = alias;
    });

    const bulkPayloads = [
      payload.createGridPages,
      payload.createRows,
      payload.updateGridpage,
      payload.createTiles,
      payload.updateRows,
      payload.createTextTiles,
      payload.createSingleGoalTiles,
      payload.createSavedViews,
      payload.createSelectedViews,
    ].filter((p) => p !== undefined);

    return bulkMutate(store, bulkPayloads).then((response) => {
      if (response.status !== 200) {
        throw new Error('error duplcating tile');
      }

      return response.data.createGridPages.map((gridPage) => gridPages.value.find((fromStore) => fromStore.uid === gridPage.uid));
    });
  };

  const createGridPageTileDeep = ({ tile, otherTiles }) => {
    const payload = {};

    if (tile.gridPageRow.uid === undefined) {
      payload.createRow = {
        nodes: [tile.gridPageRow],
        model: gridPageRowConfig.model,
        attributes: gridPageRowChildren,
      };
    }

    if (tile.gridPageRow.gridPage !== undefined) {
      payload.updateGridPage = {
        nodes: [tile.gridPageRow.gridPage],
        model: gridPageConfig.model,
        attributes: gridPageChildren,
      };
    }

    payload.createTile = {
      nodes: [tile],
      model: gridPageTileConfig.model,
      attributes: gridPageTileChildren,
    };

    payload.updateRow = {
      nodes: [tile.gridPageRow],
      model: gridPageRowConfig.model,
      attributes: gridPageRowChildren,
    };

    if (otherTiles.length > 0) {
      payload.updateOtherTiles = {
        nodes: otherTiles,
        model: gridPageTileConfig.model,
        attributes: gridPageTileChildren,
      };
    }

    if (tile.singleGoalTile !== null) {
      payload.singleGoalTile = {
        nodes: [tile.singleGoalTile],
        model: gridPageTileSingleGoalConfig.model,
        attributes: gridPageTileSingleGoalChildren,
      };
    }

    if (tile.textTile !== null) {
      payload.textTile = {
        nodes: [tile.textTile],
        model: gridPageTileTextConfig.model,
        attributes: gridPageTileTextChildren,
      };
    }

    if (tile.savedViews.length > 0) {
      payload.savedViews = {
        nodes: tile.savedViews,
        model: savedViewConfig.model,
        attributes: savedViewChildren,
        mutation: 'SAVED_VIEW_MUTATE',
      };
    }

    if (tile.selectedView !== null) {
      payload.selectedView = {
        nodes: [tile.selectedView],
        model: selectedViewConfig.model,
        attributes: selectedViewChildren,
        mutation: 'SELECTED_VIEW_MUTATE',
      };
    }

    Object.keys(payload).forEach((alias) => {
      payload[alias].alias = alias;
    });

    const bulkPayloads = [
      payload.createRow,
      payload.updateGridPage,
      payload.createTile,
      payload.updateRow,
      payload.updateOtherTiles,
      payload.singleGoalTile,
      payload.textTile,
      payload.savedViews,
      payload.selectedView,
    ].filter((p) => p !== undefined);

    return bulkMutate(store, bulkPayloads).then((response) => {
      if (response.status !== 200) {
        throw new Error('error duplcating tile');
      }

      return gridPageTiles.value.find((t) => t.uid === response.data.createTile[0].uid);
    });
  };

  return {
    gridPage,
    gridPages,
    createGridPage,
    updateGridPage,
    updateGridPageAccessPolicy,
    updateGridPagesAccessPolicy,
    deleteGridPage,
    deleteGridPages,
    queryGridPage,
    queryGridPages,
    queryGridPagesDeep,
    createGridPagesDeep,

    gridPageRows,
    queryGridPageRows,
    updateGridPageRow,

    gridPageTiles,
    queryGridPageTiles,
    createGridPageTile,
    createSingleGoalTiles,
    moveTile,
    removeGridPageTile,
    updateGridPageTiles,
    queryGridPageTileDeep,
    createGridPageTileDeep,

    gridPageTileText,
    queryGridPageTileTextByIds: (ids) => queryGridPageTileByIds(gridPageType.text, ids),
    queryGridPageTileTextByTile: (gridPageTile) => queryGridPageTileByTile(gridPageType.text, gridPageTile),
    updateGridPageTileText: (gridPageTileText) => updateGridPageTile(gridPageType.text, gridPageTileText),

    gridPageTileSingleGoal,
    queryGridPageTileSingleGoalByIds: (ids) => queryGridPageTileByIds(gridPageType.singleGoal, ids),
    queryGridPageTileSingleGoalByTile: (gridPageTile) => queryGridPageTileByTile(gridPageType.singleGoal, gridPageTile),
    updateGridPageTileSingleGoal: (gridPageTileSingleGoal) => updateGridPageTile(gridPageType.singleGoal, gridPageTileSingleGoal),
  };
}
