<template>
  <div class="favorite-editor">
    <div class="_top">
      <icon-picker
        :icon="icon"
        :icon-types="iconTypes"
        show-placeholder
        size="3.2rem"
        :icon-size="16"
        bordered
        class="_emoji-picker"
        @change="setIcon"
      />
      <m-text-field
        v-model:value="title"
        auto-focus
        :placeholder="$t('favoriteEditor.namePlaceholder')"
      />
    </div>
    <m-divider xxs />
    <template v-if="entity !== null">
      <m-card-item
        icon="delete"
        @click="deleteItem"
      >
        {{ $t('favoriteEditor.delete') }}
      </m-card-item>
    </template>
    <div
      v-else
      class="_actions"
    >
      <m-btn
        block
        color="primary"
        :loading="loading"
        @click="create"
      >
        {{ $t('favoriteEditor.create') }}
      </m-btn>
    </div>
  </div>
</template>

<script>
import IconPicker from '@/components/IconPicker.vue';
import useDebounce from '@/composables/debounce';
import useFavorites from '@/composables/favorite/favorite';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import { Icon, buildIconFromEntity, replaceIconOnEntity } from 'shared/lib/icon';
import { iconType } from 'shared/constants.json';

export default {
  name: 'FavoriteEditor',
  props: {
    entity: {
      type: Object,
      default: () => null,
    },
    titleSuggestion: {
      type: String,
      default: '',
    },
    iconSuggestion: {
      type: Icon,
      default: null,
    },
    routeName: {
      type: String,
      default: '',
    },
    routeParams: {
      type: Object,
      default: () => null,
    },
    routeQuery: {
      type: Object,
      default: () => null,
    },
  },
  emits: ['update', 'deleted', 'created'],
  components: { IconPicker },
  data() {
    return {
      title: null,
      icon: null,
      iconTypes: [iconType.emoji, iconType.icon, iconType.custom],
    };
  },
  setup() {
    const { debounce } = useDebounce({ onBeforeUnmountFlush: true });
    const { loggedInUser } = useLoggedInUser();
    const { loggedInUserAccount } = useLoggedInUserAccount();
    const favoritesSvc = useFavorites();
    return {
      debounce,
      loggedInUser,
      loggedInUserAccount,
      favorites: favoritesSvc.favorites,
      loading: favoritesSvc.createFavoriteLoading,
      createFavorite: favoritesSvc.createFavorite,
      updateFavorite: favoritesSvc.updateFavorite,
      deleteFavorite: favoritesSvc.deleteFavorite,
    };
  },
  methods: {
    saveEntity() {
      const iconPreds = { icon: undefined, image: undefined };
      replaceIconOnEntity(iconPreds, this.icon);
      this.updateFavorite({
        ...this.entity,
        title: this.title,
        icon: iconPreds.icon,
        image: iconPreds.image,
      }, { ignoreResponse: false }).then(() => {
        this.$emit('update');
      }).catch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      });
    },
    save() {
      if (this.entity === null) {
        return;
      }
      this.debounce(this.saveEntity, 1000);
    },
    deleteItem() {
      this.deleteFavorite(this.entity).then(() => {
        this.$emit('deleted');
      }).catch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      });
    },
    setIcon(icon) {
      this.icon = icon;
    },
    create() {
      const iconPreds = { icon: undefined, image: undefined };
      replaceIconOnEntity(iconPreds, this.icon);
      this.createFavorite({
        title: this.title,
        icon: iconPreds.icon,
        image: iconPreds.image,
        creator: this.loggedInUser,
        account: { uid: this.loggedInUserAccount.uid },
        routeName: this.routeName === '' ? this.$route.name : this.routeName,
        query: this.routeQuery === null ? this.$route.query : this.routeQuery,
        params: this.routeParams === null ? this.$route.params : this.routeParams,
      }).then((favorite) => {
        this.$emit('created', favorite);
      }).catch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      });
    },
  },
  watch: {
    title(newVal, oldVal) {
      if (newVal === oldVal || oldVal === null) {
        return;
      }
      this.save();
    },
    icon(newVal, oldVal) {
      if (newVal === oldVal || oldVal === null) {
        return;
      }
      this.save();
    },
  },
  created() {
    if (this.entity !== null) {
      this.title = this.entity.title;
      this.icon = buildIconFromEntity(this.entity);
      return;
    }
    this.title = this.titleSuggestion;
    this.icon = this.iconSuggestion;
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .favorite-editor {
    ._top {
      display: flex;
      padding: .6rem 1.2rem .6rem 1.2rem;

      ._emoji-picker {
        margin-right: .8rem;
      }
    }

    ._actions {
      padding: .6rem 1.2rem .8rem 1.2rem;
    }
  }
</style>
