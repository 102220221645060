import { UID } from 'shared/api/query/constants';
import { answerItem as answerItemConfig, feedbackAnswer as feedbackAnswerConfig, field as fieldConfig, user as userConfig } from 'shared/api/query/configs.json';
import { children as fieldChildren } from '@/api/query/field';
import { userEdgesSlim } from 'shared/api/query/edges';

export const children = [
  { attr: UID },
  { attr: feedbackAnswerConfig.edges.status },
  {
    attr: feedbackAnswerConfig.edges.message,
    default: null,
  },
  {
    attr: feedbackAnswerConfig.edges.respondent,
    model: userConfig.model,
    children: userEdgesSlim,
  },
  {
    attr: feedbackAnswerConfig.edges.answerItems,
    model: answerItemConfig.model,
    default: [],
    children: [
      { attr: UID },
      { attr: answerItemConfig.edges.text },
      {
        attr: answerItemConfig.edges.field,
        model: fieldConfig.model,
        children: fieldChildren,
      },
    ],
  },
];
