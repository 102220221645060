<template>
  <div class="breadcrumb-details">
    <goal-breadcrumb-item :item="goal" />
    <div class="_column">
      <breadcrumb-details
        v-for="(p, i) in goal.parents"
        :key="i"
        :item="p"
      />
    </div>
  </div>
</template>

<script>
import GoalBreadcrumbItem from '@/components/breadcrumbs/GoalBreadcrumbItem.vue';
import useGoals from '@/composables/goal/goals';

export default {
  name: 'BreadcrumbDetails',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { selectSingle } = useGoals();
    return { selectSingle };
  },
  components: { GoalBreadcrumbItem },
  computed: {
    goal() {
      return this.selectSingle(this.item.uid);
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .breadcrumb-details {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;

    ._column {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
</style>
