<template>
  <item-wrapper
    v-if="options.length > 0"
    :tooltip="label"
    :show-tooltip="showTooltip"
    @click="emit"
  >
    <div
      :class="['option-prop', wrap ? '-wrap' : '']"
    >
      <m-tag
        v-for="(option, index) in options"
        :key="index"
        :type="option.type"
        :icon="buildIconFromEntity(option)"
        :color="option.color"
        :m-style="mStyle"
        automatic-color-fallback
        small
        class="_tag"
        :title="getLabel(option)"
        @click="optionClick(option)"
      />
    </div>
  </item-wrapper>
</template>

<script>
import ItemWrapper from '@/components/list/ItemWrapper.vue';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import { buildIconFromEntity } from 'shared/lib/icon';
import { mStyleProps } from 'shared/lib/m-style-props';
import { textByLang } from 'shared/lib/language';

export default {
  name: 'OptionProp',
  props: {
    ...mStyleProps,
    label: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      required: true,
    },
    showTooltip: {
      type: Boolean,
      default: false,
    },
    wrap: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { userLang } = useLoggedInUser();
    return { userLang };
  },
  emits: ['option-clicked', 'click'],
  components: { ItemWrapper },
  methods: {
    buildIconFromEntity,
    getLabel(option) {
      if (typeof option.text !== 'undefined') {
        return option.text;
      }
      return textByLang(option.label, this.userLang);
    },
    optionClick(option) {
      this.$emit('option-clicked', option);
    },
    emit(e) {
      e.stopPropagation();
      this.$emit('click', this.prop, this.entity);
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .option-prop {
    display: flex;
    align-items: center;

    ._tag {
      margin: .2rem .2rem;
    }

    &.-wrap {
      flex-wrap: wrap;
    }
  }
</style>
