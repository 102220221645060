<template>
  <div class="give-feedback">
    <full-screen-spinner v-if="loading" />
    <template v-else-if="$route.query.feedbackAnswerId !== ''">
      <div class="_inner">
        <give-feedback-form
          :key="feedbackAnswerId"
          :feedback-answer="feedbackAnswer"
          @close="$emit('close')"
          @cancel="$emit('cancel')"
        />
      </div>
    </template>
  </div>
</template>

<script>
import FullScreenSpinner from 'shared/components/FullScreenSpinner.vue';
import GiveFeedbackForm from '@/components/feedback/GiveFeedbackForm.vue';
import { ONE_TO_ONE, RESULT, UID } from 'shared/api/query/constants';
import { children as feedbackAnswerChildren } from '@/api/query/feedback-answer';
import {
  feedbackAnswer as feedbackAnswerConfig,
  feedbackInquiry as feedbackInquiryConfig,
} from 'shared/api/query/configs.json';
import { children as feedbackInquiryChildren } from '@/api/query/feedback-inquiry';
import { findInArray } from 'shared/lib/array/array';
import { mapActions, mapState } from 'vuex';
import { reverseEdge } from 'shared/api/query/edges';

export default {
  name: 'GiveFeedback',
  components: {
    GiveFeedbackForm,
    FullScreenSpinner,
  },
  emits: ['close', 'cancel'],
  data() {
    return { loading: true };
  },
  computed: {
    ...mapState({ feedbackAnswers: (state) => state.feedbackAnswer }),
    feedbackAnswerId() {
      return parseInt(this.$route.query.feedbackAnswerId, 10);
    },
    feedbackAnswer() {
      return findInArray({ haystack: this.feedbackAnswers, needle: this.feedbackAnswerId });
    },
  },
  methods: {
    ...mapActions(['getEntity']),
    retrieveFeedbackAnswer() {
      this.loading = true;
      this.getEntity({
        entityById: (id) => [{
          alias: RESULT,
          func: { name: UID },
          uid: [id],
          model: feedbackAnswerConfig.model,
          children: [
            ...feedbackAnswerChildren,
            {
              alias: 'feedbackInquiry',
              assoc: ONE_TO_ONE,
              model: feedbackInquiryConfig.model,
              attr: reverseEdge(feedbackInquiryConfig.edges.answers),
              children: feedbackInquiryChildren,
            },
          ],
        }],
        id: this.feedbackAnswerId,
        model: feedbackAnswerConfig.model,
      }).then((response) => {
        this.loading = false;

        if (response.status !== 200) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
        }
      });
    },
  },
  watch: {
    feedbackAnswerId(val) {
      if (Number.isNaN(val)) {
        return;
      }
      this.retrieveFeedbackAnswer();
    },
  },
  created() {
    this.retrieveFeedbackAnswer();
  },
};
</script>

<style scoped lang="scss" type="text/scss">
</style>
