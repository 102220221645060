import config from '@/nebula/delete-graph-config';
import useFacets from '@/nebula/facets';
import { addToDenormalize, denormalize, dn, findReverseReferences, mergeToDenormalize } from '@/nebula/denormalize';
import { createReferenceIndex, references, resetReferences } from '@/nebula/references';
import { deleteEntities, findToDelete } from '@/nebula/delete-graph';
import { facetConfig } from '@/nebula/facet-config';
import { isEqual } from 'lodash-es';
import { normalize } from 'normalizr';
import { resetState } from '@/nebula/initial-state';
import { syncReverseEdges } from '@/nebula/reverse-edges';

const { applyFacets, extractFacets } = useFacets(references, facetConfig);

export default {
  MUTATE(state, { entities, model, schema }) {
    const toCreate = {};
    const toDenormalize = {};
    const nd = normalize(entities, [schema[model]]);

    const models = Object.keys(nd.entities);
    models.forEach((m) => {
      createReferenceIndex(references, schema[m], nd.entities[m]);
      Object.values(nd.entities[m]).forEach((e) => {
        if (schema[m].simple) {
          state[m][e.uid] = e;
          return;
        }

        if (Object.keys(e).length === 1 || e.uid === undefined) {
          return;
        }

        const stateExists = state[m] !== undefined && state[m][e.uid] !== undefined;
        if (stateExists) {
          const commonKeys = Object.keys(e).filter((key) => state[m][e.uid][key] !== undefined);
          if (commonKeys.length === Object.keys(e).length) {
            // isEqual assumes same order within arrays in order to show equality.
            // This check is mainly here fore performance improvements in VueX's strict mode
            // to minimize writes to the store.
            const notEqual = commonKeys.some((key) => !isEqual(state[m][e.uid][key], e[key]));
            if (!notEqual) {
              // if state exists and is same
              return;
            }
          }
        }

        const localToDenormalize = {};
        addToDenormalize(e.uid, m, localToDenormalize);
        findReverseReferences(localToDenormalize, references, schema);
        mergeToDenormalize(toDenormalize, localToDenormalize);

        syncReverseEdges(state, references, schema, e, m);

        if (state[m] === undefined || state[m][e.uid] === undefined) {
          if (toCreate[m] === undefined) {
            toCreate[m] = {};
          }
          toCreate[m][e.uid] = e;
          return;
        }

        if (schema[m].mergeStrategy !== undefined) {
          state[m][e.uid] = schema[m].mergeStrategy(state[m][e.uid], e);
          return;
        }

        state[m][e.uid] = {
          ...state[m][e.uid],
          ...e,
        };
      });
    });
    Object.keys(toCreate).forEach((m) => {
      state[m] = {
        ...state[m],
        ...toCreate[m],
      };
    });

    extractFacets(entities, model);

    findReverseReferences(toDenormalize, references, schema);
    denormalize(toDenormalize, state, schema, applyFacets);
  },
  MUTATE_SINGLE(state, { entity, model, schema }) {
    this.commit('nebula/MUTATE', { entities: [entity], model, schema });
  },
  QUERY(state, { queries, response, schema }) {
    queries.forEach((q) => {
      if (response[q.alias] === undefined) {
        return;
      }
      this.commit('nebula/MUTATE', { entities: response[q.alias], model: q.model, schema });
    });
  },
  DELETE(state, { ids, model, schema }) {
    const toDenormalize = {};
    const toDelete = [...ids.map((uid) => ({ uid, type: model })), ...findToDelete(references, ids, model, schema, config, state)];

    toDelete.forEach((delCmd) => {
      addToDenormalize(delCmd.uid, delCmd.type, toDenormalize);
    });
    findReverseReferences(toDenormalize, references, schema);

    deleteEntities(toDelete, state, dn, references);
    denormalize(toDenormalize, state, schema, applyFacets);
  },
  DELETE_SINGLE(state, { id, model }) {
    this.commit('nebula/DELETE', { ids: [id], model });
  },
  RESET(state) {
    resetState(state);
    resetReferences();
  },
};
