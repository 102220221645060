<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.8336 3.49256L10.8336 18.3438H13.167V3.49256H10.8336Z"
      fill="currentColor"
    />
    <path
      d="M5.71614 8.23875C5.32146 8.62998 5.32178 9.26764 5.71646 9.65886L6.04599 9.9855C6.43579 10.3719 7.06416 10.3719 7.45396 9.9855L13.75 3.74467C13.75 3.74467 12.6834 2.68744 12 2.01001C9.95326 4.0388 7.04734 6.91923 5.71614 8.23875Z"
      fill="currentColor"
    />
    <path
      d="M16.546 9.9855C16.9358 10.3719 17.5641 10.3719 17.9539 9.9855L18.2835 9.65886C18.6782 9.26764 18.6785 8.62998 18.2838 8.23875C16.9526 6.91923 14.0467 4.0388 12 2.01001C11.3166 2.68744 10.2499 3.74467 10.2499 3.74467L16.546 9.9855Z"
      fill="currentColor"
    />
    <circle
      cx="6.25"
      cy="21.25"
      r="1.25"
      fill="currentColor"
    />
    <circle
      cx="10.25"
      cy="21.25"
      r="1.25"
      fill="currentColor"
    />
    <circle
      cx="14.25"
      cy="21.25"
      r="1.25"
      fill="currentColor"
    />
    <circle
      cx="18.25"
      cy="21.25"
      r="1.25"
      fill="currentColor"
    />
    <circle
      cx="6.25"
      cy="21.25"
      r="1.25"
      fill="currentColor"
    />
    <circle
      cx="10.25"
      cy="21.25"
      r="1.25"
      fill="currentColor"
    />
    <circle
      cx="14.25"
      cy="21.25"
      r="1.25"
      fill="currentColor"
    />
    <circle
      cx="18.25"
      cy="21.25"
      r="1.25"
      fill="currentColor"
    />
    <circle
      cx="6.25"
      cy="21.25"
      r="1.25"
      fill="currentColor"
    />
    <circle
      cx="10.25"
      cy="21.25"
      r="1.25"
      fill="currentColor"
    />
    <circle
      cx="14.25"
      cy="21.25"
      r="1.25"
      fill="currentColor"
    />
    <circle
      cx="18.25"
      cy="21.25"
      r="1.25"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default { name: 'AboveOrEqual' };
</script>

<style scoped lang="scss" type="text/scss">

</style>
