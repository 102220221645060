<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 11.1522L9.77778 18.5L22 5.5"
      stroke="currentColor"
      stroke-width="3.3"
    />
  </svg>
</template>

<script>
export default { name: 'CheckMark' };
</script>

<style scoped lang="scss" type="text/scss">

</style>
