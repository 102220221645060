<template>
  <div class="give-feedback-form">
    <m-content
      :padding="!$store.state.breakpoint.smAndDown"
      :no-padding="$store.state.breakpoint.smAndDown"
      :padding-x="!$store.state.breakpoint.smAndDown ? '13' : ''"
    >
      <div class="_header">
        <h1 v-if="!$store.state.breakpoint.smAndDown">
          {{ title }}
        </h1>
        <top-bar v-else>
          <template #left>
            <div

              class="_left"
            >
              <m-btn
                hide-border
                small
                color="primary"
                @click="$emit('cancel')"
              >
                <template v-if="feedbackAnswer.status !== feedbackAnswerStatus.done">
                  {{ $t('general.cancel') }}
                </template>
                <template v-else>
                  {{ $t('general.close') }}
                </template>
              </m-btn>
            </div>
          </template>
          <template #center>
            <div

              class="_center"
            >
              {{ title }}
            </div>
          </template>
          <template #right>
            <div
              v-if="feedbackAnswer.status !== feedbackAnswerStatus.done"

              class="_right"
            >
              <m-btn
                hide-border
                small
                color="primary"
                @click="save"
              >
                {{ $t('general.send') }}
              </m-btn>
            </div>
          </template>
        </top-bar>
      </div>
    </m-content>
    <m-content
      :padding="!$store.state.breakpoint.smAndDown"
      :padding-small="$store.state.breakpoint.smAndDown"
      :padding-x="!$store.state.breakpoint.smAndDown ? '13' : ''"
    >
      <div class="_props">
        <property-label
          v-if="loggedInUser.uid === feedbackAnswer.feedbackInquiry.creator.uid"
          :label="$t('giveFeedbackForm.from')"
          icon="user"
          class="_prop"
        >
          <user-picker
            :value="[feedbackAnswer.respondent]"
            full-width
            hide-border
            hide-arrow
            match-trigger-width
            popup
            disabled
            :placeholder="$t('general.empty')"
          />
        </property-label>
        <property-label
          v-else
          :label="$t('giveFeedbackForm.recipient')"
          icon="user"
          class="_prop"
        >
          <user-picker
            :value="[feedbackAnswer.feedbackInquiry.creator]"
            full-width
            hide-border
            hide-arrow
            match-trigger-width
            popup
            disabled
            :placeholder="$t('general.empty')"
          />
        </property-label>
      </div>
      <m-divider />
      <div class="_body">
        <div
          class="_message"
        >
          <div class="_name">
            <template v-if="loggedInUser.uid !== feedbackAnswer.feedbackInquiry.creator.uid">
              {{ feedbackAnswer.feedbackInquiry.creator.firstName }} {{
                feedbackAnswer.feedbackInquiry.creator.lastName
              }}
            </template>
            <template v-else>
              {{ $t('giveFeedbackForm.yourMessage') }}
            </template>
          </div>
          <m-editor
            :initial-value="feedbackAnswer.feedbackInquiry.message"
            :allowed-content="allowedContent"
            disabled
            class="_msg"
          />
        </div>
        <div class="_questions">
          <div
            v-if="loggedInUser.uid === feedbackAnswer.feedbackInquiry.creator.uid"
            class="_title"
          >
            {{ $t('giveFeedbackForm.answer') }}
          </div>
          <feedback-answer-item
            v-for="(item, index) in answers"
            :key="index"
            :item="item"
            :index="index + 1"
            :lang="userLang"
            :disabled="feedbackAnswer.status === feedbackAnswerStatus.done"
            class="_item"
            @update="updateAnswer($event, index)"
          />
        </div>
        <div class="_input">
          <div class="_additional-message">
            <template v-if="answers.length > 0">
              {{ $t('giveFeedbackForm.furtherRemarks') }}
            </template>
            <template v-else-if="loggedInUser.uid !== feedbackAnswer.feedbackInquiry.creator.uid">
              {{ $t('giveFeedbackForm.messageLabel') }}
            </template>
          </div>
          <m-editor
            ref="editor"
            v-model:value="message"
            class="_msg"
            :disabled="feedbackAnswer.status === feedbackAnswerStatus.done"
            :initial-value="feedbackAnswer.message"
            :placeholder="$t('giveFeedbackForm.typeYourMessage')"
            :allowed-content="allowedContent"
          />
        </div>
      </div>
    </m-content>
    <m-divider
      v-if="!$store.state.breakpoint.smAndDown"
      :none="feedbackAnswer.status !== feedbackAnswerStatus.done"
      :no-border="feedbackAnswer.status === feedbackAnswerStatus.done"
    />
    <template v-if="feedbackAnswer.status !== feedbackAnswerStatus.done && !$store.state.breakpoint.smAndDown">
      <m-content
        padding
      >
        <div class="_footer">
          <div class="_actions">
            <m-btn
              hide-border
              light
              @click="$emit('cancel')"
            >
              {{ $t('general.cancel') }}
            </m-btn>
            <m-btn
              color="primary"
              class="_btn"
              :loading="loading"
              @click="save"
            >
              {{ $t('general.send') }}
            </m-btn>
          </div>
        </div>
      </m-content>
    </template>
  </div>
</template>

<script>
import FeedbackAnswerItem from '@/components/feedback/FeedbackAnswerItem.vue';
import PropertyLabel from '@/components/PropertyLabel.vue';
import UserPicker from '@/components/UserPicker.vue';
import { editorNodeType, featureFlag, feedbackAnswerStatus } from 'shared/constants.json';
import { mapActions } from 'vuex';

import MEditor from '@/components/editor/MEditor.vue';
import TopBar from '@/components/navigation/TopBar.vue';
import useAccess from '@/composables/access/access';
import useInbox from '@/composables/inbox/inbox';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import { children as feedbackAnswerChildren } from '@/api/query/feedback-answer';
import { feedbackAnswer as feedbackAnswerConfig } from 'shared/api/query/configs.json';

export default {
  name: 'GiveFeedbackForm',
  props: {
    feedbackAnswer: {
      type: Object,
      required: true,
    },
  },
  emits: ['cancel', 'close'],
  components: {
    MEditor,
    UserPicker,
    PropertyLabel,
    FeedbackAnswerItem,
    TopBar,
  },
  setup() {
    const allowedContent = [
      editorNodeType.heading,
      editorNodeType.codeBlock,
      editorNodeType.blockquote,
      editorNodeType.bulletList,
      editorNodeType.orderedList,
    ];

    const { accountHasFeature } = useAccess();
    if (accountHasFeature([featureFlag.fileUpload])) {
      allowedContent.push(editorNodeType.image, editorNodeType.file);
    }

    const { loggedInUser, userLang } = useLoggedInUser();
    const { updateFeedbackAnswerFeedEntry } = useInbox();
    return { loggedInUser, userLang, updateFeedbackAnswerFeedEntry, allowedContent };
  },
  data() {
    return {
      message: null,
      loading: false,
      feedbackAnswerStatus,
      answers: [],
    };
  },
  computed: {
    title() {
      if (this.loggedInUser.uid === this.feedbackAnswer.feedbackInquiry.creator.uid) {
        return this.$t('giveFeedbackForm.viewFeedback');
      }
      return this.$t('giveFeedbackForm.title');
    },
  },
  methods: {
    ...mapActions(['updateEntityV2']),
    save() {
      this.updateEntityV2({
        entity: {
          ...this.feedbackAnswer,
          answerItems: this.answers.map((a) => ({
            ...a,
            text: JSON.stringify(a.text),
          })),
          message: this.message,
          status: feedbackAnswerStatus.done,
        },
        model: feedbackAnswerConfig.model,
        attributes: feedbackAnswerChildren,
        mutation: 'FEEDBACK_ANSWER_UPDATED',
      }).then((response) => {
        if (response.status !== 200) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
          return;
        }

        this.updateFeedbackAnswerFeedEntry(this.feedbackAnswer);

        this.$showSnackbar({ color: 'success', message: this.$t('success.created') });
        this.$emit('close');
      });
    },
    updateAnswer(answer, index) {
      this.answers.splice(index, 1, answer);
    },
  },
  created() {
    if (this.feedbackAnswer.answerItems.length > 0) {
      this.answers = this.feedbackAnswer.answerItems.map((a) => ({
        ...a,
        text: JSON.parse(a.text),
      }));
      return;
    }
    this.answers = this.feedbackAnswer.feedbackInquiry.questions.map((q) => ({
      field: q,
      text: null,
    }));
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .give-feedback-form {
    ._header {
      ._center {
        max-width: 12rem;
        overflow: hidden;
        font-weight: $font-weight-semibold;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      ._left {
        margin-left: 1.2rem;
      }
    }

    ._body {
      ._message {
        ._name {
          font-size: $font-size-2;
          color: $font-color-secondary;
        }
      }

      ._label {
        color: $font-color-tertiary;
      }

      ._additional-message {
        font-weight: $font-weight-semibold;
      }

      ._msg {
        margin-left: -.2rem;
      }

      ._questions {
        margin-top: 2rem;

        ._title {
          font-size: $font-size-2;
          color: $font-color-secondary;
        }

        ._item {
          margin-bottom: 2rem;
        }
      }
    }

    ._footer {
      display: flex;

      ._actions {
        display: flex;
        margin-left: auto;

        ._btn {
          margin-left: .8rem;
        }
      }
    }
  }
</style>
