<template>
  <div class="form-field-list">
    <welcome-screen-field
      v-if="hasWelcomeScreen"
      :welcome-screen="entity.welcomeScreen"
      :is-selected="welcomeScreenSelected"
      :model="model"
      :disabled="disabled"
      :lang="lang"
      @welcome-screen-selected="selectWelcomeScreen"
    />
    <div
      v-else-if="typeof entity.orderItems === 'undefined' || entity.orderItems.length === 0"
      class="_empty-list"
    >
      {{ $t('formFieldList.noItems') }}
    </div>
    <m-draggable
      draggable-item-class="_handle"
      ghost-item-class="form-field"
      dragover-item-class="form-field"
      can-drag-over-top
      can-drag-over-bottom
      :recreate-key="entity.orderItems.length"
      :drag-between-height="20"
      @set-drag-item="setDragItem"
      @over-top="setOverTop"
      @over-bottom="setOverBottom"
      @drag-drop="handleDrop"
      @cancel="cancelDragging"
    >
      <transition-group
        name="list-left"
        mode="out-in"
      >
        <form-field
          v-for="(item, index) in entity.orderItems"
          :key="item.uid"
          :item="item"
          :field-index="index"
          :disabled="disabled"
          :is-selected="selectedQuestion !== null && selectedQuestion.uid === item.uid"
          :is-dragged="drag"
          :lang="lang"
          :model="model"
          :has-translations="languages.length > 1"
          :dragging-over-bottom="dragItemId !== '' && draggingOverBottom.includes(item.uid)"
          :dragging-over-top="index === 0 && dragItemId !== '' && draggingOverTop.includes(item.uid)"
          @selected="selected"
          @open-settings="openSettings"
        />
      </transition-group>
    </m-draggable>
    <div
      v-if="!disabled"
      class="_add"
    >
      <m-dropdown
        v-model:value="visible"
        :title="$t('formFieldList.addField')"
      >
        <m-btn
          icon="plus"
          class="_btn"
          hide-border
          light
        >
          {{ $t('formFieldList.addField') }}
        </m-btn>
        <template #overlay>
          <m-card
            list
            no-padding
          >
            <m-card-item
              v-for="(type, i) in fieldTypes"
              :key="i"
              :disabled="isDisabled(type)"
              @click="addField(type.value)"
            >
              <div class="_title">
                <form-field-icon
                  :type="type.value"
                  :show-index="false"
                />
                <div class="_description">
                  {{ type.title }}
                </div>
              </div>
            </m-card-item>
          </m-card>
        </template>
      </m-dropdown>
    </div>
  </div>
</template>

<script>
import FormField from '@/components/FormField.vue';
import FormFieldIcon from '@/components/FormFieldIcon.vue';
import WelcomeScreenField from '@/components/WelcomeScreenField.vue';
import useSort from '@/composables/draggable/sort';
import {
  LONG_TEXT_ICON, OPINION_SCALE_ICON, WELCOME_SCREEN_ICON,
} from 'shared/transformers';
import { defaultLanguageField } from '@/lib/language';
import { fieldType } from 'shared/constants.json';

export default {
  name: 'FormFieldList',
  props: {
    entity: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
    model: {
      type: String,
      required: true,
    },
    selectedQuestion: {
      type: Object,
      default: null,
    },
    welcomeScreenSelected: {
      type: Boolean,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
    languages: {
      type: Array,
      required: true,
    },
  },
  emits: ['selected', 'open-settings', 'welcome-screen-selected', 'field-added', 'welcome-screen-added'],
  components: { FormFieldIcon, FormField, WelcomeScreenField },
  setup() {
    const {
      setDragItem,
      setOverBottom,
      setOverTop,
      draggingOverBottom,
      draggingOverTop,
      dropItem,
      cancelDragging,
      dragItemId,
    } = useSort();
    return { dragItemId, setDragItem, setOverBottom, setOverTop, draggingOverBottom, draggingOverTop, dropItem, cancelDragging };
  },
  data() {
    return {
      visible: false,
      drag: false,
    };
  },
  computed: {
    fieldTypes() {
      const types = [
        {
          title: this.$t('formFieldList.opinionScale'),
          value: fieldType.opinionScale,
          icon: OPINION_SCALE_ICON,
        },
        {
          title: this.$t('formFieldList.longText'),
          value: fieldType.longText,
          icon: LONG_TEXT_ICON,
        },
      ];
      if (!this.hasWelcomeScreen) {
        types.unshift(
          {
            title: this.$t('formFieldList.welcomeScreen'),
            value: fieldType.welcomeScreen,
            icon: WELCOME_SCREEN_ICON,
          },
        );
      }
      return types;
    },
    items: {
      get() {
        return this.entity.orderItems;
      },
      set(value) {
        this.$store.commit('ORDER_ITEMS_ORDER_CHANGED', { orderItems: value, model: this.model });
      },
    },
    hasWelcomeScreen() {
      return this.entity.welcomeScreen !== null
        && typeof this.entity.welcomeScreen.deletedAt === 'undefined';
    },
    defaultLanguageField() {
      return defaultLanguageField(this.languages);
    },
  },
  methods: {
    handleDrop() {
      this.items = this.dropItem(this.items);
    },
    selected({ fieldIndex, item }) {
      this.$emit('selected', { fieldIndex, item });
    },
    openSettings({ fieldIndex, item }) {
      this.$emit('open-settings', { fieldIndex, item });
    },
    isDisabled(type) {
      if ([fieldType.longText, fieldType.opinionScale].indexOf(type.value) > -1) {
        return false;
      }

      return this.hasWelcomeScreen;
    },
    selectWelcomeScreen() {
      this.$emit('welcome-screen-selected');
    },
    addField(type) {
      this.visible = false;
      switch (type) {
        case fieldType.opinionScale:
          this.$emit('field-added', {
            type: fieldType.opinionScale,
            title: this.defaultLanguageField,
            opinionScaleProperties: {
              steps: 7,
              labelLeft: this.defaultLanguageField,
              labelCenter: this.defaultLanguageField,
              labelRight: this.defaultLanguageField,
            },
            validations: { required: false },
          });
          return;
        case fieldType.longText:
          this.$emit('field-added', {
            type: fieldType.longText,
            title: this.defaultLanguageField,
            opinionScaleProperties: {
              steps: 7,
              labelLeft: this.defaultLanguageField,
              labelCenter: this.defaultLanguageField,
              labelRight: this.defaultLanguageField,
            },
            validations: { required: false },
          });
          return;
        case fieldType.welcomeScreen:
          this.$emit('welcome-screen-added', {
            title: this.defaultLanguageField,
            description: this.defaultLanguageField,
          });
          return;
        default:
          throw Error('type not supported');
      }
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .form-field-list {
    width: 100%;

    ._empty-list {
      padding: 2.4rem;
    }
  }

  ._title {
    display: flex;
    align-items: center;

    ._description {
      margin-left: 1rem;
    }
  }

  ._add {
    display: inline-block;
    padding-bottom: 3rem;
    margin-top: .8rem;

    ._btn {
      margin-left: .8rem;
    }
  }
</style>
