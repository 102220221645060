// isZero this is a copy of pkg/prosemirror/prosemirror.go:25
export const isZero = (node) => {
  if (node.content !== null && node.content !== undefined) {
    for (let i = 0; i < node.content.length; i++) {
      if (isZero(node.content[i])) {
        continue;
      }

      return false;
    }
  }

  if (node.text === undefined || node.text === null) {
    return true;
  }

  if (node.text.trim() === '') {
    return true;
  }

  return false;
};

export const trimEmptyLines = (message) => {
  if (message === null) {
    return message;
  }

  let trimmedContent = [...message.content];

  trimmedContent = trimLeft(trimmedContent);
  if (trimmedContent === null) {
    return trimmedContent;
  }
  trimmedContent = trimLeft(reverse(trimmedContent));
  if (trimmedContent === null) {
    return trimmedContent;
  }
  reverse(trimmedContent);

  return {
    ...message,
    content: trimmedContent,
  };
};

const reverse = (content) => {
  content.forEach((next) => {
    if (next.content) {
      next.content.reverse();
    }
  });
  return content.reverse();
};

const trimLeft = (content) => {
  if (content === null || content.length === 0) {
    return content;
  }

  let trim = true;
  for (let i = 0; i < content.length; i++) {
    if (!trim) {
      continue;
    }

    if (!Array.isArray(content[i].content)) {
      continue;
    }

    content[i].content = trimLeft(content[i].content);
    if (Array.isArray(content[i].content) && content[i].content.length > 0) {
      trim = false;
    }
  }

  const emptyLines = numberOfEmptyLinesBeginning(content);
  if (emptyLines === content.length) {
    return null;
  }

  return content.slice(emptyLines);
};

const numberOfEmptyLinesBeginning = (content) => {
  let emptyLines = 0;
  for (let i = 0; i < content.length; i++) {
    if (content[i].type === 'hard_break') {
      emptyLines += 1;
      continue;
    }

    if (content[i].type !== 'paragraph') {
      return emptyLines;
    }

    if (content[i].content === undefined || content[i].content === null) {
      emptyLines += 1;
      continue;
    }

    if (content[i].content.length > 0) {
      return emptyLines;
    }
  }

  return emptyLines;
};
