<template>
  <div :class="['m-section', marginTop ? '-margin-top' : '']">
    <page-title
      :title="title"
      :sub-title="subTitle"
      :heading="headingSize"
      :font-weight="titleFontWeight"
    >
      <template #after-title>
        <slot name="after-title" />
      </template>
    </page-title>
    <div>
      <slot />
    </div>
  </div>
</template>

<script>
import PageTitle from 'shared/components/PageTitle.vue';

export default {
  name: 'MSection',
  props: {
    headingSize: {
      type: String,
      default: 'h2',
    },
    title: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
    marginTop: {
      type: Boolean,
      default: false,
    },
    titleFontWeight: {
      type: String,
      default: 'normal',
    },
  },
  components: { PageTitle },
};
</script>

<style scoped lang="scss" type="text/scss">
  .m-section {
    ._sub-title {
      color: $font-color-secondary;
    }
  }
</style>
