<template>
  <div
    v-if="!disabled"
    :class="['m-dragzone', vertical ? '-vertical' : '-horizontal']"
    :style="style"
  >
    <div class="_indicator" />
  </div>
</template>
<script setup>
import { INDENTATION_WIDTH } from 'shared/constants';
import { computed } from 'vue';

const props = defineProps({
  level: {
    type: Number,
    default: 0,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  indentationWidth: {
    type: Number,
    default: INDENTATION_WIDTH,
  },
  vertical: {
    type: Boolean,
    default: false,
  },
});

const style = computed(() => {
  if (!props.vertical) {
    return {
      width: `calc(100% - ${props.level * props.indentationWidth}px)`,
      marginLeft: `${props.level * props.indentationWidth}px`,
    };
  }
  return {};
});
</script>

<style
    scoped
    lang="scss"
>
  .m-dragzone {
    position: absolute;

    &.-horizontal {
      left: 0;
      width: 100%;
      height: 4px;
      margin-left: 0;
    }

    &.-vertical {
      top: 0;
      width: 4px;
      height: 100%;
      margin-top: 0;
    }

    ._indicator {
      width: 100%;
      height: 100%;
      background-color: map_get($blue, 'lighten-1');
    }
  }
</style>
