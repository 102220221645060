import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import { computed } from 'vue';
import { createViewId } from '@/lib/saved-view/saved-view';

export default function useViewCascadeExpandKeyMaker(view) {
  const { loggedInUserAccount } = useLoggedInUserAccount();
  const viewId = computed(() => createViewId(view.value));
  return {
    getKey() {
      return computed(() => `${loggedInUserAccount.value.uid}_cascade_view_${viewId.value}`);
    },
  };
}
