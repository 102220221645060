import useGridPageConfig from '@/composables/grid-page/grid-page-config';
import useGridPageRepo from '@/composables/grid-page/grid-page-repo';
import { computed, ref } from 'vue';
import { insertAfter, insertBefore } from 'shared/lib/array/array';

const dragItem = ref(null);
const dragTarget = ref(null);

export function useGridPageDrag() {
  const gridPageRepo = useGridPageRepo();
  const gridPageConfig = useGridPageConfig();

  function initDragging(tileId) {
    dragItem.value = gridPageRepo.gridPageRows.value.map((row) => row.tiles).flat().find((tile) => tile.uid === tileId);
  }

  function cancelDragging() {
    dragItem.value = null;
    dragTarget.value = null;
  }

  function setDragTarget(target) {
    dragTarget.value = target;
  }

  function findRowByTile(tile) {
    const actualTile = gridPageRepo.gridPageTiles.value.find((t) => t.uid === tile.uid);
    return gridPageRepo.gridPageRows.value.find((row) => row.uid === actualTile.gridPageRow.uid);
  }

  function isNoopCommitAction() {
    if (dragTarget.value.uid === dragItem.value.uid) {
      return true;
    }

    const sourceRow = findRowByTile(dragItem.value);
    const targetRow = findRowByTile(dragTarget.value);

    if (sourceRow.uid !== targetRow.uid) {
      return false;
    }

    const sourceIndex = sourceRow.tileOrder.findIndex(({ uid }) => uid === dragItem.value.uid);
    const targetIndex = targetRow.tileOrder.findIndex(({ uid }) => uid === dragTarget.value.uid);

    switch (dragTarget.value.position) {
      case 'left':
        if (sourceIndex + 1 === targetIndex) {
          return true;
        }
        break;
      case 'right':
        if (targetIndex + 1 === sourceIndex) {
          return true;
        }
        break;
      default:
        break;
    }

    return false;
  }

  const updateOrder = (order, source, target, position) => {
    const orderTarget = order.find((e) => e.uid === target.uid);
    switch (position) {
      case 'left':
        return insertBefore(order, orderTarget, source);
      case 'right':
        return insertAfter(order, orderTarget, source);
      default:
        return order;
    }
  };

  async function commitDragTarget() {
    if (dragTarget.value === null) {
      dragItem.value = null;
      dragTarget.value = null;
      return new Promise((resolve) => { resolve(); });
    }

    // noop
    if (isNoopCommitAction()) {
      dragItem.value = null;
      dragTarget.value = null;
      return new Promise((resolve) => { resolve(); });
    }

    const sourceRow = findRowByTile(dragItem.value);
    const targetRow = findRowByTile(dragTarget.value);

    if (sourceRow.uid === targetRow.uid) {
      let tileOrder = sourceRow.tileOrder.filter(({ uid }) => uid !== dragItem.value.uid);
      tileOrder = updateOrder(tileOrder, { uid: dragItem.value.uid }, { uid: dragTarget.value.uid }, dragTarget.value.position);

      return gridPageRepo.updateGridPageRow({ uid: sourceRow.uid, tileOrder }).then(() => gridPageRepo.gridPageTiles.value.find((t) => t.uid === dragItem.value.uid)).finally(() => {
        dragItem.value = null;
        dragTarget.value = null;
      });
    }

    // moving from one row to another
    const actualGridPage = gridPageRepo.gridPages.value.find((gp) => gp.uid === sourceRow.gridPage.uid);
    const sourceTile = gridPageRepo.gridPageTiles.value.find((t) => t.uid === dragItem.value.uid);

    let deletedAt;
    let rowOrder;
    if (sourceRow.tiles.length === 1) {
      deletedAt = new Date();
      rowOrder = actualGridPage.rowOrder.filter(({ uid }) => uid !== sourceTile.uid);
    }

    const tileOrder = updateOrder([...targetRow.tileOrder], { uid: sourceTile.uid }, { uid: dragTarget.value.uid }, dragTarget.value.position);
    const targetColumns = gridPageConfig.maxColumns / (targetRow.tiles.length + 1);

    const tileToUpdate = {
      uid: sourceTile.uid,
      columns: targetColumns,
      gridPageRow: {
        uid: targetRow.uid,
        tileOrder,
        gridPage: { uid: actualGridPage.uid, rowOrder },
      },
    };

    const otherRows = [
      { uid: sourceRow.uid, tileOrder: sourceRow.tileOrder.filter(({ uid }) => uid !== sourceTile.uid), deletedAt },
    ];

    const otherTiles = [
      ...targetRow.tiles.map((t) => ({ uid: t.uid, columns: targetColumns })),
    ];
    if (deletedAt === undefined) {
      const sourceColumns = gridPageConfig.maxColumns / (sourceRow.tiles.length - 1);
      otherTiles.push(
        ...sourceRow.tiles.filter((t) => t.uid !== sourceTile.uid).map((t) => ({ uid: t.uid, columns: sourceColumns })),
      );
    }

    return gridPageRepo.moveTile(tileToUpdate, otherTiles, otherRows).finally(() => {
      dragItem.value = null;
      dragTarget.value = null;
    });
  }

  function isNoopVerticalCommitAction(gridPage) {
    const sourceRow = findRowByTile(dragItem.value);
    if (sourceRow.tiles.length !== 1) {
      return false;
    }

    if (dragTarget.value.uid === sourceRow.uid) {
      return true;
    }

    const getTargetRowIndex = () => {
      const index = gridPage.rowOrder.findIndex(({ uid }) => uid === dragTarget.value.uid);
      if (dragTarget.value.position === 'top') {
        return index;
      }

      return index + 1;
    };

    let targetRow;
    const targetRowIndex = getTargetRowIndex();
    if (targetRowIndex < gridPage.rowOrder.length) {
      targetRow = gridPage.rowOrder[targetRowIndex];
    }

    return targetRow !== undefined && targetRow.uid === sourceRow.uid;
  }

  async function commitDragBelow(gridPage) {
    return commitDragEmptyRow(gridPage, 'right');
  }

  async function commitDragTop(gridPage) {
    return commitDragEmptyRow(gridPage, 'left');
  }

  async function commitDragEmptyRow(gridPage, position) {
    const actualGridPage = gridPageRepo.gridPages.value.find((gp) => gp.uid === gridPage.uid);
    if (isNoopVerticalCommitAction(actualGridPage)) {
      dragItem.value = null;
      dragTarget.value = null;
      return new Promise((resolve) => { resolve(); });
    }

    const sourceRow = gridPageRepo.gridPageRows.value.find((row) => row.tiles.map((t) => t.uid).includes(dragItem.value.uid));

    if (sourceRow.tiles.length === 1) {
      let rowOrder = actualGridPage.rowOrder.filter(({ uid }) => uid !== sourceRow.uid);
      rowOrder = updateOrder(rowOrder, { uid: sourceRow.uid }, { uid: dragTarget.value.uid }, position);
      return gridPageRepo.updateGridPage({ uid: gridPage.uid, rowOrder }).finally(() => {
        dragItem.value = null;
        dragTarget.value = null;
      });
    }

    const rowRid = 2;
    const rowOrder = updateOrder([...actualGridPage.rowOrder], { rid: rowRid }, { uid: dragTarget.value.uid }, position);

    const sourceTile = gridPageRepo.gridPageTiles.value.find((t) => t.uid === dragItem.value.uid);
    const tileToUpdate = {
      uid: sourceTile.uid,
      columns: gridPageConfig.maxColumns,
      gridPageRow: { rid: rowRid, height: gridPageConfig.defaultHeight, tileOrder: [{ uid: sourceTile.uid }], gridPage: { uid: actualGridPage.uid, rowOrder } },
    };

    const neighbours = sourceRow.tiles.filter((t) => t.uid !== sourceTile.uid).map((t) => ({ uid: t.uid, columns: gridPageConfig.maxColumns / (sourceRow.tiles.length - 1) }));

    const oldRow = { uid: sourceRow.uid, tileOrder: sourceRow.tileOrder.filter(({ uid }) => uid !== sourceTile.uid) };

    return gridPageRepo.moveTile(tileToUpdate, neighbours, [oldRow]).finally(() => {
      dragItem.value = null;
      dragTarget.value = null;
    });
  }

  const isDragging = computed(() => dragItem.value !== null);

  return {
    isDragging,
    initDragging,
    cancelDragging,
    dragItem,
    dragTarget,
    setDragTarget,
    commitDragTarget,
    commitDragBelow,
    commitDragTop,
  };
}
