<template>
  <span class="emoji">
    {{ node.attrs.emoji }}
  </span>
</template>

<script>

export default {
  name: 'MEditorEmoji',
  props: {
    node: {
      type: Object,
      required: true,
    },
    updateAttrs: {
      type: Function,
      required: true,
    },
    view: {
      type: Object,
      required: true,
    },
    getPos: {
      type: Function,
      required: true,
    },
    editor: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .emoji {
    font-family: "Apple Color Emoji", "Segoe UI Emoji", NotoColorEmoji, "Noto Color Emoji", "Segoe UI Symbol", "Android Emoji", EmojiSymbols, sans-serif;
  }
</style>
