import { UID } from 'shared/api/query/constants';
import { companyInfo as companyInfoConfig } from 'shared/api/query/configs.json';

export const children = [
  { attr: UID },
  { attr: companyInfoConfig.edges.content, default: null },
  { attr: companyInfoConfig.edges.title, default: '' },
  { attr: companyInfoConfig.edges.showInHelpArea, default: false },
  { attr: companyInfoConfig.edges.onboardingStep, default: false },
];
