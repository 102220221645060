<template>
  <info-page
    :heading="`${$t('notFound.heading')} ${$t('notFound.explanation')}`"
    :button-text="$t('notFound.goBack')"
  />
</template>

<script>
import InfoPage from '@/components/InfoPage.vue';

export default {
  name: 'NotFound',
  components: { InfoPage },
};
</script>

<style scoped lang="scss" type="text/scss">

</style>
