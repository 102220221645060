import { fontSizes } from 'shared/font-sizes';

export const mStyleProps = {
  mStyle: {
    /*
      {
        fontStyle: {
          fontSize: 'small',
          fontWeight: 'bold',
          textTransform: 'uppercase',
        },
      }
     */
    type: Object,
    default: () => ({}),
  },
};

export const resolveStyles = (mStyle) => [fwdFontStyle(mStyle), makeFontSize(mStyle)].reduce((res, next) => next(res), {});

const fwdFontStyle = (mStyle) => (style) => {
  if (mStyle.fontStyle === undefined) {
    return style;
  }

  if (mStyle.fontStyle.textTransform) {
    style.textTransform = mStyle.fontStyle.textTransform;
  }

  return style;
};

const makeFontSize = (mStyle) => (style) => {
  if (mStyle.fontStyle === undefined || mStyle.fontStyle.fontSize === undefined) {
    return style;
  }
  switch (mStyle.fontStyle.fontSize) {
    case 'xs':
      return {
        ...style,
        fontSize: fontSizes[1],
      };
    case 'small':
      return {
        ...style,
        fontSize: fontSizes[2],
      };
    default:
      return {
        ...style,
        fontSize: fontSizes[4],
      };
  }
};
