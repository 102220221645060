<template>
  <div
    :class="['m-emoji', isIcon ? '-icon' : '-emoji', showBackground ? '-background':'']"
    :style="wrapperStyle"
  >
    <span
      v-if="isEmoji"
      role="img"
      :aria-label="emoji"
      class="_inner"
    >{{ emoji }}</span>
    <img
      v-else-if="isIcon"
      :style="imgStyle"
      :src="resolveFileName(`img/icons/ad/${emoji}.svg`)"
    >
  </div>
</template>

<script>
import { emojiRegex } from 'shared/lib/emoji-regex';
import { getVariant, iconFromIconPicker } from 'shared/lib/icon-picker';
import { mapIconBackgroundColor } from 'shared/lib/color-map';

export default {
  name: 'MEmoji',
  props: {
    emoji: {
      type: String,
      required: true,
    },
    size: {
      type: Number,
      default: 16,
    },
    showBackground: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isEmoji() {
      const regex = new RegExp(emojiRegex);
      return this.emoji !== '' && regex.test(this.emoji);
    },
    isIcon() {
      return this.emoji !== '' && iconFromIconPicker(this.emoji);
    },
    wrapperStyle() {
      const style = { width: `${this.size / 10}rem`, height: `${this.size / 10}rem` };
      if (this.isEmoji) {
        style.fontSize = `${this.size / 10}rem`;
      }
      if (this.showBackground) {
        if (this.isEmoji) {
          style.fontSize = `${(this.size / 10) * 0.66}rem`;
        }
        style.backgroundColor = mapIconBackgroundColor(getVariant(this.emoji));
        return style;
      }
      return style;
    },
    imgStyle() {
      if (this.showBackground) {
        return { width: `${(this.size / 10) * 0.66}rem` };
      }
      return { width: 'inherit', height: 'inherit' };
    },
  },
  methods: {
    resolveFileName(filepath) {
      return import.meta.env.VITE_PUBLIC_PATH + filepath;
    },
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  .m-emoji {
    border-radius: $border-radius-sm;

    &.-emoji {
      line-height: 1;

      ._inner {
        font-family: "Apple Color Emoji", "Segoe UI Emoji", NotoColorEmoji, "Noto Color Emoji", "Segoe UI Symbol", "Android Emoji", EmojiSymbols, sans-serif;
      }

      &.-background {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &.-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: inherit;
      height: inherit;
    }

    &.-background {
      background-color: map_get($grey, 'lighten-5');
    }
  }
</style>
