<template>
  <div :class="['snackbars', $store.state.breakpoint.smAndDown ? '-mobile' : '']">
    <transition-group
      name="snackbar"
    >
      <m-card
        v-for="notification in notifications"
        :key="notification.id"
        class="m-snackbar"
        padding-xs
      >
        <div class="_inner">
          <div class="_left">
            <div class="_icon">
              <m-icon
                :type="icon(notification)"
                :color="iconColor(notification)"
                size="18"
              />
            </div>
            <div class="_message">
              <div class="_title">
                {{ notification.message }}
              </div>
              <div
                v-if="notification.description !== ''"
                class="_description"
              >
                {{ notification.description }}
              </div>
            </div>
          </div>
          <div class="_right">
            <div
              v-if="notification.action !== undefined"
              class="_action"
            >
              <m-btn
                small
                hide-border
                light
                @click="handleClick(notification)"
              >
                {{ notification.action.title }}
              </m-btn>
            </div>
            <m-btn
              fab
              small
              icon="close"
              hide-border
              light
              @click="hide(notification.id)"
            />
          </div>
        </div>
      </m-card>
    </transition-group>
  </div>
</template>

<script>
import { guid } from 'shared/lib/uuid';

export default {
  name: 'MSnackbar',
  props: {
    eventBus: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      duration: 4.5,
      notifications: [],
    };
  },
  methods: {
    show({
      color = 'success',
      message = '',
      description = '',
      action = undefined, // { title, handler}
      duration = this.duration,
    }) {
      const id = guid();
      this.notifications.push({
        id,
        color,
        message,
        description,
        action,
        duration,
      });
      if (this.$store.state.breakpoint.smAndDown && this.notifications.length > 2) {
        setTimeout(() => this.notifications.shift(), 100);
      }
      setTimeout(this.hideClosure(id), duration * 1000);
    },
    hideClosure(id) {
      return () => {
        this.hide(id);
      };
    },
    hide(id) {
      this.notifications = this.notifications.filter((n) => n.id !== id);
    },
    handleClick(notification) {
      notification.action.handler();
      this.hide(notification.id);
    },
    icon(notification) {
      switch (notification.color) {
        case 'success':
          return 'check-circle';
        case 'error':
          return 'close-circle';
        case 'info':
          return 'info-circle';
        default:
          return '';
      }
    },
    iconColor(notification) {
      switch (notification.color) {
        case 'success':
          return this.$colors.green.base;
        case 'error':
          return this.$colors.red.base;
        case 'info':
          return this.$colors.blue.base;
        default:
          return '';
      }
    },
  },
  created() {
    this.eventBus.$on('show-snackbar', this.show);
    this.eventBus.$on('hide-snackbar', this.hide);
  },
  beforeUnmount() {
    this.eventBus.$off('show-snackbar', this.show);
    this.eventBus.$off('hide-snackbar', this.hide);
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .snackbars {
    position: fixed;
    top: 1rem;
    left: 50%;
    z-index: 1050;
    transform: translateX(-50%);

    &.-mobile {
      width: calc(100vw - 4rem);
    }

    .m-snackbar {
      margin-bottom: 2rem;

      @media (max-width: $screen-size-sm) {
        max-width: 100%;
      }

      ._inner {
        display: flex;
        justify-content: space-between;

        ._left {
          display: flex;
          align-items: center;
          padding-left: .7rem;
          font-size: $font-size-5;

          ._icon {
            margin-right: 1.2rem;
          }
        }

        ._right {
          display: flex;
          align-items: center;
          margin-left: 3rem;

          @media (max-width: $screen-size-sm) {
            margin-left: auto;
          }

          ._action {
            margin-right: .8rem;
          }
        }
      }
    }
  }
</style>
