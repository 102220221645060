import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useRepo from '@/nebula/repo';
import { account as accountConfig } from 'shared/api/query/configs.json';
import { computed } from 'vue';
import { uploadFile } from '@/lib/image/image';

export default function useLoggedInUserAccount() {
  const accountRepo = useRepo(accountConfig.model);
  const { loggedInUser } = useLoggedInUser();

  const loggedInUserAccount = computed(() => {
    if (loggedInUser.value === null || loggedInUser.value.account === undefined) {
      return { accountSettings: {}, companyImage: {}, goalSettings: {} };
    }

    const account = accountRepo.selectSingle(loggedInUser.value.account.uid);
    if (account === undefined) {
      return { accountSettings: {}, companyImage: {}, goalSettings: {} };
    }

    return account;
  });

  const uploadImage = (blob) => uploadFile(blob, () => {}).then((data) => {
    const companyImage = () => {
      if (loggedInUserAccount.value.companyImage === null) {
        return { blob: data.fileId, getURL: data.getURL };
      }
      return {
        uid: loggedInUserAccount.value.companyImage.uid,
        blob: data.fileId,
        getURL: data.getURL,
      };
    };
    return accountRepo.updateSingle({
      uid: loggedInUserAccount.value.uid,
      companyImage: companyImage(),
    }, { optimistic: false });
  });

  const deleteImage = () => {
    if (loggedInUserAccount.value.companyImage === null) {
      return new Promise((resolve) => {
        resolve();
      });
    }
    return accountRepo.updateSingle({
      uid: loggedInUserAccount.value.uid,
      companyImage: null,
    }, { optimistic: false });
  };

  return {
    loggedInUserAccount,
    uploadImage,
    deleteImage,
    updateSingle: accountRepo.updateSingle,
    getList: accountRepo.getList,
  };
}
