import RGoalsCascadeTable from '@/components/goal/RGoalsCascadeTable.vue';
import camelCase from 'lodash-es/camelCase';
import upperFirst from 'lodash-es/upperFirst';

export const initRecursiveComponents = (app) => {
  const components = { RGoalsCascadeTable };
  for (let i = 0; i < Object.keys(components).length; i++) {
    const component = components[Object.keys(components)[i]];
    const componentName = upperFirst(camelCase(Object.keys(components)[i]));
    app.component(componentName, component);
  }
};
