<template>
  <full-screen-spinner v-if="loading" />
  <published-view v-else />
</template>

<script>
import FullScreenSpinner from 'shared/components/FullScreenSpinner.vue';
import PublishedView from '@/views/PublishedView.vue';
import useGoalCycle from '@/composables/goal-cycle/goal-cycle';
import useGoalSettings from '@/composables/logged-in-user-account/goal-settings';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import usePersonalAppSettings from '@/composables/logged-in-user/personal-app-settings';
import useProperties from '@/composables/property/property';
import usePropertyOptions from '@/composables/property-option/property-option';
import useRepo from '@/nebula/repo';
import useSavedViewRepo from '@/composables/saved-views/saved-view-repo';
import useSpaces from '@/composables/space/spaces';
import useUsers from '@/composables/user/users';
import { EVENTS } from '@/lib/constants';
import { EventBus } from '@/lib/event-bus';
import { currentGoalCycle } from '@/lib/goal/cycles';
import { logError } from '@/lib/logger/logger';
import { routeName } from 'shared/constants.json';
import {
  selectedView as selectedViewConfig,
} from 'shared/api/query/configs.json';

export default {
  name: 'PublishedViewWrapper',
  components: { PublishedView, FullScreenSpinner },
  setup() {
    const { getList: getGoalCycles, goalCycles } = useGoalCycle();
    const selectedViewsRepo = useRepo(selectedViewConfig.model);
    const { loggedInUserAccount, getList: getAccount } = useLoggedInUserAccount();
    const { fetchUsers } = useUsers();
    const { personalAppSettings, updateSingle: updatePersonalAppSettings } = usePersonalAppSettings();
    const { getList: getProperties } = useProperties();
    const { getList: getPropertyOptions } = usePropertyOptions();
    const { getList: getSpaces } = useSpaces();
    const { selectSingle: getView } = useSavedViewRepo();
    const { setDefaultValueFeatureName, goalSettings } = useGoalSettings();
    return {
      loggedInUserAccount,
      fetchUsers,
      getGoalCycles,
      goalCycles,
      getProperties,
      getPropertyOptions,
      getSpaces,
      personalAppSettings,
      updatePersonalAppSettings,
      goalSettings,
      getAccount,
      getView,
      setDefaultValueFeatureName,
      selectedViewsRepo,
    };
  },
  data() {
    return { loading: true };
  },
  computed: {
    viewId() {
      return parseInt(this.$route.params.savedViewId, 10);
    },
  },
  methods: {
    authenticate() {
      return this.getView(this.viewId, { commitToRemote: true }).then((view) => {
        if (view === undefined) {
          throw new Error('view not found');
        }

        this.selectedViewsRepo.updateSingle({
          uid: 0,
          view: { uid: view.uid },
          viewApplication: view.viewApplication,
        }, { commitToRemote: false });
      });
    },
    fetchData() {
      return Promise.all([
        this.getGoalCycles(),
        this.fetchUsers(),
        this.getProperties(),
        this.getPropertyOptions(),
        this.getSpaces(),
        this.getAccount(),
      ]).then(() => {
        this.$store.commit('PUBLISHED_VIEW_DATA');
        this.loading = false;
        this.setDefaultValueFeatureName();
        this.updatePersonalAppSettings({
          uid: this.personalAppSettings.uid,
          selectedGoalCycles: currentGoalCycle(this.goalCycles),
        }, { commitToRemote: false });

        document.title = this.goalSettings.featureNamePlural;
        EventBus.$emit(EVENTS.VIEW.PUBLISHED_VIEW_VISITED, { account: this.loggedInUserAccount, view: this.getView(this.viewId) });
      });
    },
  },
  created() {
    this.authenticate()
      .then(() => {
        this.fetchData();
      })
      .catch((e) => {
        if (e.message.indexOf('view not found') > -1) {
          this.$router.push({ name: routeName.notFound });
          return;
        }
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
        logError(e);
      });
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
</style>
