import { propertyType } from 'shared/constants.json';

export const lookupFn = ({ isFilterMode = false }) => ({ scope, propertyValues, lookupPropertySvc }) => {
  if (lookupPropertySvc === undefined) {
    return false;
  }
  const selectedUsers = lookupPropertySvc.getSelectedUsers(propertyValues, scope.property);
  const selectedOptions = lookupPropertySvc.getSelectedOptions(propertyValues, scope.property);
  const selectedSpaces = lookupPropertySvc.getSpaces(propertyValues, scope.property);

  let selectedItems = [];
  let neededItems = [];
  switch (scope.property.lookupProperty.type) {
    case propertyType.user:
      selectedItems = selectedUsers;
      neededItems = scope.users;
      break;
    case propertyType.space:
      selectedItems = selectedSpaces;
      neededItems = scope.spaces;
      break;
    case propertyType.singleSelect:
    case propertyType.options:
      selectedItems = selectedOptions;
      neededItems = scope.selectedOptions;
      break;
    default:
      return true;
  }

  if (selectedItems.length === 0) {
    return false;
  }
  const selectedIds = selectedItems.map((u) => u.uid);
  if (scope.isEmpty) {
    return selectedIds.length === 0;
  }
  const neededIds = neededItems.map((o) => o.uid);
  if (neededIds.length === 0 && isFilterMode) {
    return true;
  }
  return neededIds.some((el) => selectedIds.indexOf(el) > -1);
};
