import { computed, ref } from 'vue';
import { routeName } from 'shared/constants.json';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default function useOpenPeekMode(readOnly = ref(false)) {
  const store = useStore();
  const smAndDown = computed(() => store.state.breakpoint.smAndDown);
  const route = useRoute();
  const router = useRouter();

  const asModal = (modal = true) => {
    if (readOnly.value) return true;
    if (smAndDown.value) return false;
    return modal;
  };
  const openGoal = ({ goalId, modal = true }) => {
    const name = route.name;
    if (!asModal(modal)) {
      router.push({ name: routeName.goalDetails, params: { goalId } });
      return;
    }
    const query = { ...route.query, goalId };
    router.push({ name, query });
  };

  return { openGoal };
}
