import { UID } from 'shared/api/query/constants';
import { page as pageConfig } from 'shared/api/query/configs.json';

export const children = [
  { attr: UID },
  {
    attr: pageConfig.edges.title,
    default: '',
  },
  {
    attr: pageConfig.edges.icon,
    default: '',
  },
  {
    attr: pageConfig.edges.content,
    default: null,
  },
];
