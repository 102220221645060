import useUnassignedGoalCycle from '@/composables/goal-cycle/unassigned-goal-cycle';
import { computed } from 'vue';

export default function useViewGoalCycle(viewSvc) {
  const { unassignedCycle } = useUnassignedGoalCycle();

  const unassignedGoalCycleSelected = computed(() => viewSvc.currentView.value.unassignedGoalCycleSelected);
  const selectedGoalCycles = computed(() => viewSvc.currentView.value.goalCycles);
  const composedSelectedCycles = computed(() => {
    const res = [...selectedGoalCycles.value];
    if (unassignedGoalCycleSelected.value) {
      res.push(unassignedCycle);
    }
    return res;
  });

  const changeSelectedGoalCycles = (items) => {
    viewSvc.updateCycles(viewSvc.currentView.value, items);
  };

  return {
    composedSelectedCycles,
    selectedGoalCycles,
    unassignedGoalCycleSelected,

    changeSelectedGoalCycles,
  };
}
