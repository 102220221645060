<template>
  <div :class="['goal-progress-chart', updatesLoading ? '-loading': '']">
    <base-chart :options="chartOptions" />
  </div>
</template>

<script>

import BaseChart from '@/components/dashboard/BaseChart.vue';
import maxBy from 'lodash-es/maxBy';
import minBy from 'lodash-es/minBy';
import useGoalSettings from '@/composables/logged-in-user-account/goal-settings';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import { AREASPLINE, DASH, DATETIME, DOT, LINE } from '@/lib/constants';
import { DateTime } from 'luxon';
import { formatNumber } from '@/lib/goal/progress';
import { getPointInLineBounded } from '@/lib/charts/line-chart';
import { getRoundedValue } from '@/lib/charts/format';
import { getStatusColor } from '@/lib/goal/status';
import { goalProgressMeasurement, optionColor, propertyType } from 'shared/constants.json';

export default {
  name: 'GoalProgressChart',
  props: {
    goal: {
      type: Object,
      required: true,
    },
    goalUpdates: {
      type: Array,
      required: true,
    },
    xAxis: {
      type: Object,
      default: () => ({ minTickInterval: undefined, min: undefined, max: undefined }),
    },
    height: {
      type: [Number, String],
      default: undefined,
    },
  },
  setup() {
    const { userLang } = useLoggedInUser();
    const { goalSettings } = useGoalSettings();
    return { goalSettings, userLang };
  },
  components: { BaseChart },
  computed: {
    xMinTickInterval() {
      if (this.xAxis.minTickInterval !== undefined) {
        return this.xAxis.minTickInterval;
      }
      return 28 * 24 * 3600 * 1000; // default: 1 month (28 days)
    },
    xMin() {
      if (this.progressData.length === 0) {
        return null;
      }

      if (this.xAxis.min !== undefined) {
        return this.xAxis.min;
      }

      return this.cycleStart;
    },
    xMax() {
      if (this.progressData.length === 0) {
        return null;
      }

      if (this.xAxis.max !== undefined) {
        return this.xAxis.max;
      }

      return this.cycleEnd;
    },
    yMin() {
      if ([goalProgressMeasurement.binary, goalProgressMeasurement.alignedItems].includes(this.goal.progressMeasurement)) {
        return 0;
      }
      return this.chartData.reduce((res, next) => {
        if (next.y < res) {
          return next.y;
        }

        return res;
      }, Math.min(this.baseLine[0].y, this.baseLine[1].y));
    },
    yMax() {
      if ([goalProgressMeasurement.binary, goalProgressMeasurement.alignedItems].includes(this.goal.progressMeasurement)) {
        return 100;
      }

      return this.chartData.reduce((res, next) => {
        if (next.y > res) {
          return next.y;
        }

        return res;
      }, Math.max(this.baseLine[0].y, this.baseLine[1].y));
    },
    updatesLoading() {
      return this.goal.goalActivityCount > 0 && this.chartData.length === 0;
    },
    hasCycle() {
      return this.goal.goalCycle.length > 0;
    },
    cycleStart() {
      if (this.goal.goalCycle.length === 0) {
        return Date.now();
      }
      const min = minBy(this.goal.goalCycle, (c) => Date.parse(c.start));
      return Date.parse(min.start);
    },
    cycleEnd() {
      if (this.goal.goalCycle.length === 0) {
        return Date.now();
      }
      const max = maxBy(this.goal.goalCycle, (c) => Date.parse(c.end));
      return Date.parse(max.end);
    },
    progressData() {
      const first = {
        ...this.baseLine[0],
        expectedValue: this.baseLine[0].y,
      };

      if (this.chartData.length === 0 && goalProgressMeasurement.threshold === this.goal.progressMeasurement) {
        return [];
      }

      if (this.chartData.length === 0) {
        return [first];
      }
      const data = this.chartData.map((e) => ({
        x: e.x,
        y: e.y,
        color: getStatusColor(e.statusOption),
        expectedValue: this.expectedValue(e.x),
      }));
      data.sort((a, b) => (a.x <= b.x ? -1 : 1));
      if (data[0].x > this.baseLine[0].x && goalProgressMeasurement.threshold !== this.goal.progressMeasurement) {
        data.unshift(first);
      }
      return data;
    },
    colorByStatus() {
      const statusPropertyValue = this.goal.properties.find((propertyValue) => propertyValue.property.type === propertyType.status);
      if (statusPropertyValue.selectedOptions.length === 0) {
        return [[0, this.$colors.grey.lighten3], [1, this.$colors.grey.lighten5]];
      }

      const propertyOption = statusPropertyValue.selectedOptions[0];
      switch (propertyOption.color) {
        case optionColor.grey:
          return [[0, this.$colors.grey.lighten3], [1, this.$colors.grey.lighten5]];
        case optionColor.brown:
          return [[0, this.$colors.brown.lighten3], [1, this.$colors.brown.lighten5]];
        case optionColor.orange:
          return [[0, this.$colors.orange.lighten3], [1, this.$colors.orange.lighten5]];
        case optionColor.yellow:
          return [[0, this.$colors.yellow.lighten3], [1, this.$colors.yellow.lighten5]];
        case optionColor.green:
          return [[0, this.$colors.green.lighten3], [1, this.$colors.green.lighten5]];
        case optionColor.blue:
          return [[0, this.$colors.blue.lighten3], [1, this.$colors.blue.lighten5]];
        case optionColor.purple:
          return [[0, this.$colors.purple.lighten3], [1, this.$colors.purple.lighten5]];
        case optionColor.pink:
          return [[0, this.$colors.pink.lighten3], [1, this.$colors.pink.lighten5]];
        case optionColor.red:
          return [[0, this.$colors.red.lighten3], [1, this.$colors.red.lighten5]];
        default:
          return [[0, this.$colors.grey.lighten3], [1, this.$colors.grey.lighten5]];
      }
    },
    baseLine() {
      if (!this.hasCycle) {
        return [
          { x: Date.parse(this.goal.createdAt), y: 0 },
          { x: Date.parse(this.goal.createdAt), y: 0 },
        ];
      }
      if (this.goal.progressMeasurement === goalProgressMeasurement.binary) {
        return [
          { x: this.cycleStart, y: 0 },
          { x: this.cycleEnd, y: 100 },
        ];
      }
      if (this.goal.progressMeasurement === goalProgressMeasurement.alignedItems) {
        return [
          { x: this.cycleStart, y: 0 },
          { x: this.cycleEnd, y: 100 },
        ];
      }
      if (this.goal.progressMeasurement === goalProgressMeasurement.threshold) {
        return [
          { x: this.cycleStart, y: this.goal.threshold },
          { x: this.cycleEnd, y: this.goal.threshold },
        ];
      }
      return [
        { x: this.cycleStart, y: this.goal.start },
        { x: this.cycleEnd, y: this.goal.end },
      ];
    },
    chartOptions() {
      const t = this.$t;
      let metric = '%';
      if ([goalProgressMeasurement.continuous, goalProgressMeasurement.threshold].includes(this.goal.progressMeasurement)) {
        metric = this.goal.metric;
      }

      const language = this.userLang;
      return {
        chart: {
          height: this.height !== undefined ? this.height : 150,
          marginBottom: 22,
          marginRight: 8,
        },
        lang: { noData: this.$t('highcharts.noData') },
        noData: {
          style: {
            fontWeight: 'medium',
            fontSize: this.$fontSizes[5],
            color: this.$colors.grey.lighten3,
          },
        },
        series: [
          {
            type: AREASPLINE,
            data: this.hasCycle ? this.progressData : [],
            marker: {
              enabled: true,
              fillColor: '#FFFFFF',
              lineWidth: 2,
              lineColor: null, // inherit from series
              symbol: 'circle',
            },
            connectNulls: true,
            color: getStatusColor(this.goal),
            dashStyle: DASH,
            fillColor: {
              linearGradient: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 1,
              },
              stops: this.colorByStatus,
            },
          },
          {
            states: { inactive: { opacity: 1 } },
            type: LINE,
            data: this.hasCycle ? this.baseLine : [],
            color: this.$colors.grey.lighten3,
            marker: { enabled: false },
            dashStyle: DASH,
            enableMouseTracking: false,
          },
        ],
        xAxis: {
          type: DATETIME,
          minTickInterval: this.xMinTickInterval,
          minRange: this.xMinTickInterval,
          min: this.xMin,
          max: this.xMax,
        },
        yAxis: {
          gridLineDashStyle: DOT,
          title: { text: '' },
          labels: {
            formatter() {
              return `${formatNumber(this.value, language)} ${metric}`;
            },
          },
          min: this.yMin,
          max: this.yMax,
          endOnTick: false,
          startOnTick: false,
          tickAmount: 5,
        },
        tooltip: {
          shared: true,
          headerFormat: '',
          pointFormatter() {
            return `
<span style="font-size:10px">${DateTime.fromMillis(this.x).toLocaleString(DateTime.DATETIME_MED)}</span></br><br/>
${t('highcharts.actual')}: <span style="font-weight: 700">${formatNumber(this.y, language)} ${metric}</span> </br><br/>
${t('highcharts.expected')}: <span style="font-weight: 700">${formatNumber(this.expectedValue, language)} ${metric}</span>`;
          },
        },
      };
    },
    filteredGoalActivities() {
      const goalId = this.goal.uid;
      return this.goalUpdates.map((u) => u.goalActivities)
        .flat()
        .filter((ac) => ac !== null && ac.goal.uid === goalId);
    },
    chartData() {
      if (this.goal.progressMeasurement === goalProgressMeasurement.alignedItems) {
        if (typeof this.goal.progressCourse === 'undefined') {
          return [];
        }
        return this.goal.progressCourse.map((e) => ({
          x: Date.parse(e.date),
          y: Math.round(e.progress),
          statusOption: e.statusOption,
        }));
      }

      const cycleStart = DateTime.fromMillis(this.cycleStart);
      const cycleEnd = DateTime.fromMillis(this.cycleEnd);
      return this.filteredGoalActivities.filter((ac) => {
        if (this.goal.goalCycle.length === 0) {
          return true;
        }

        const createdAt = DateTime.fromISO(ac.customCreatedAt);
        return createdAt.diff(cycleStart) > 0 && createdAt.diff(cycleEnd) < 0;
      }).map((a) => {
        const statusOption = a.properties.find((propertyValue) => propertyValue.property.type === propertyType.status).selectedOptions[0];
        if (this.goal.progressMeasurement === goalProgressMeasurement.binary) {
          if (a.current > 0) {
            return {
              x: Date.parse(a.customCreatedAt),
              y: 100,
              statusOption,
            };
          }
          return {
            x: Date.parse(a.customCreatedAt),
            y: 0,
            statusOption,
          };
        }
        return {
          x: Date.parse(a.customCreatedAt),
          y: a.current,
          statusOption,
        };
      });
    },
  },
  methods: {
    expectedValue(timestamp) {
      return getRoundedValue(getPointInLineBounded(this.baseLine, timestamp).y, 2);
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .goal-progress-chart {
    &.-loading {
      opacity: .5;
    }
  }
</style>
