<template>
  <div :class="classes">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'MTagList',
  props: {
    wrap: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    xs: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return [
        'm-tag-list',
        this.small ? '-small' : '',
        this.xs ? '-xs' : '',
        this.wrap ? '-wrap' : '',
      ];
    },
  },
};
</script>

<style lang="scss" type="text/scss">
  .m-tag-list {
    display: flex;
    flex-grow: 1;
    min-width: 0;

    &.-wrap {
      flex-wrap: wrap;
    }

    .m-tag {
      flex-shrink: 0;
      margin: 0 .6rem .5rem 0;

      .m-tag {
        margin: 0;
      }

      &.-xs {
        margin: 0 .3rem .4rem 0;
      }
    }

    &.-small {
      .m-tag {
        margin: 0 .4rem .2rem 0;
      }
    }

    &.-xs {
      .m-tag {
        margin: 0 .3rem .4rem 0;
      }
    }
  }
</style>
