<template>
  <full-screen-spinner
    v-if="initialLoading"
    :height="150"
  />
  <div
    v-else
    class="grid-page-list"
  >
    <div class="_table">
      <m-selectable
        v-model:value="selectedIds"
        selector-class="m-table-row"
      >
        <m-table
          :columns="columns"
          :data-source="gridPages"
          :order="{ createdAt: 'down' }"
          :loading="loading"
          :selected-rows="selectedIds"
          row-key="uid"
          rows-clickable
          layout-padding
          show-count
          :show-new-button="canCreate"
          :custom-row="handleRowClick"
          @contextmenu="handleContextMenu"
          @new="$emit('new')"
        >
          <template #title="{ title, row }">
            <m-dropdown
              :value="inlineEditingSvc.isInlineEditing(row.uid).value"
              placement="onTopLeft"
              match-trigger-width
              block
              :relocate-key="localTitle"
              :title="$t('cascadeTableRow.editTitle')"
              @hide="blur(row)"
            >
              <div class="_title">
                <item-title
                  :title="title"
                  :icons="[{ value: row.icon }]"
                  wrap-title
                />
                <div
                  class="_title-actions"
                >
                  <m-tooltip
                    v-if="canEdit(row)"
                    class="_edit-btn"
                  >
                    <m-btn
                      small
                      fab
                      light
                      hide-border
                      icon="edit"
                      @click.stop="editTitle(row)"
                    />
                    <template #title>
                      {{ $t('cascadeTableRow.editTitle') }}
                    </template>
                  </m-tooltip>
                </div>
              </div>
              <template #overlay>
                <m-card
                  no-padding
                  class="_overlay"
                >
                  <div
                    class="_inner"
                  >
                    <simple-editor
                      :initial-value="title"
                      :placeholder="$t('list.addTitle')"
                      full-width
                      auto-focus
                      :read-only="false"
                      @enter="blur(row)"
                      @escape="blur(row)"
                      @blur="blur(row)"
                      @update:value="updateTitle"
                    />
                  </div>
                </m-card>
              </template>
            </m-dropdown>
          </template>
          <template #access="{ access }">
            <div :style="{ display: 'flex' }">
              <m-tag
                no-color
                :icon="access === false ? 'lock' : 'global'"
                :title="access === false ? $t('gridPageList.privacy.private') : $t('gridPageList.privacy.public')"
              />
            </div>
          </template>
          <template #user="{ user }">
            <user-display
              :user="user"
              small
            />
          </template>
          <template #createdAt="{ createdAt }">
            {{ formatDate(createdAt) }}
          </template>
        </m-table>
        <grid-pages-context-menu
          ref="contextmenu"
          :ids="selectedIds"
          :has-public-allowance="hasPublicAllowance"
          :private-allowance-available="privateAllowanceAvailable"
          :public-allowance-available="publicAllowanceAvailable"
        />
      </m-selectable>
    </div>
  </div>
</template>

<script>
import FullScreenSpinner from 'shared/components/FullScreenSpinner.vue';
import GridPagesContextMenu from '@/components/custom-grid/grid-page-list/GridPagesContextMenu.vue';
import ItemTitle from '@/components/ItemTitle.vue';
import SimpleEditor from '@/components/SimpleEditor.vue';
import UserDisplay from 'shared/components/UserDisplay.vue';
import useDebounce from '@/composables/debounce';
import useGridPage from '@/composables/grid-page/grid-page';
import useInlineEditing from '@/composables/inline-editing';
import useSelectedViews from '@/composables/saved-views/selected-views';
import useSnackBar from '@/composables/snackbar';
import useViewNavigator from '@/composables/saved-views/navigator';
import { DateTime } from 'luxon';
import { accessPolicyType, routeName } from 'shared/constants.json';
import { buildIconFromEntity } from 'shared/lib/icon';
import { logCatch } from '@/lib/logger/logger';

export default {
  name: 'GridPagesList',
  props: {
    ids: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    canCreate: {
      type: Boolean,
      default: false,
    },
    hasPublicAllowance: {
      type: Boolean,
      default: false,
    },
    privateAllowanceAvailable: {
      type: Number,
      default: -1,
    },
    publicAllowanceAvailable: {
      type: Number,
      default: -1,
    },
  },
  components: { UserDisplay, ItemTitle, SimpleEditor, GridPagesContextMenu, FullScreenSpinner },
  data() {
    return {
      initialLoading: true,
      selectedIds: [],
      localTitle: '',
      accessPolicyType,
    };
  },
  emits: ['new'],
  setup() {
    const snackbar = useSnackBar();
    const { linkToRoute } = useViewNavigator();
    const { getSelectedViewViewIdForApplication } = useSelectedViews();
    const { gridPages, updateGridPage } = useGridPage();
    const inlineEditingSvc = useInlineEditing();
    const { debounce } = useDebounce();
    return {
      snackbar,
      linkToRoute,
      debounce,
      inlineEditingSvc,
      getSelectedViewViewIdForApplication,
      allGridPages: gridPages,
      updateGridPage,
    };
  },
  computed: {
    gridPages() {
      return this.allGridPages.filter((p) => this.ids.includes(p.uid)).map((p) => ({ ...p, icon: buildIconFromEntity(p) }));
    },
    columns() {
      return [
        {
          key: 'title',
          dataIndex: 'title',
          title: this.$t('gridPageList.columns.title'),
          scopedSlots: { customRender: 'title' },
        },
        {
          key: 'access',
          dataIndex: 'isPublic',
          title: this.$t('gridPageList.columns.access'),
          scopedSlots: { customRender: 'access' },
        },
        {
          key: 'creator',
          dataIndex: 'creator',
          title: this.$t('gridPageList.columns.creator'),
          scopedSlots: { customRender: 'user' },
        },
        {
          key: 'createdAt',
          dataIndex: 'createdAt',
          title: this.$t('gridPageList.columns.createdAt'),
          scopedSlots: { customRender: 'createdAt' },
        },
      ];
    },
  },
  methods: {
    formatDate(date) {
      return DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED);
    },
    handleRowClick(row) {
      const { $router, linkToRoute } = this;
      return {
        on: {
          click() {
            $router.push(linkToRoute('', routeName.dashboardDetails, undefined, undefined, row.uid).to);
          },
        },
      };
    },
    handleContextMenu(event, row) {
      event.preventDefault();
      if (this.selectedIds.length === 0 || !this.selectedIds.includes(row.uid)) {
        this.selectedIds = [row.uid];
      }
      this.$refs.contextmenu.show(event);
    },
    canEdit(entity) {
      return [accessPolicyType.full, accessPolicyType.write].includes(entity.accessRight);
    },
    editTitle(entity) {
      this.localTitle = entity.title;
      this.inlineEditingSvc.set(entity.uid);
    },
    updateTitle(val) {
      this.localTitle = val;
    },
    blur(entity) {
      const update = () => this.updateGridPage({
        uid: entity.uid,
        title: this.localTitle,
      }).catch(logCatch(() => {
        this.snackbar.error();
      })).finally(() => {
        this.localTitle = '';
      });
      this.inlineEditingSvc.reset();
      this.selectedIds.push(entity.uid);
      this.debounce(update, 50);
    },
  },
  watch: {
    loading(newVal) {
      if (newVal === false) {
        this.initialLoading = false;
      }
    },
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  @import "shared/assets/scss/box-shadow";

  .grid-page-list {
    ._title {
      display: inline-flex;

      ._title-actions {
        position: absolute;
        top: -0.2rem;
        right: 0;
        display: flex;
        align-items: center;

        ._edit-btn {
          display: none;
          background-color: white;
          border-radius: $default-border-radius;

          @include box_shadow(1);
        }
      }
    }

    ._table {
      ._cell {
        &:hover {
          ._title-actions {
            @media (min-width: $screen-size-md) {
              ._edit-btn {
                display: flex;
              }
            }
          }
        }
      }
    }
  }

  ._overlay {
    display: flex;
    align-items: center;
    min-height: 4rem;
    margin: -0.8rem -1.2rem;

    ._inner {
      display: flex;
      align-items: center;
      padding: .8rem;
      line-height: 1.6;

      ._overlay-text {
        &:empty::before {
          color: $font-color-tertiary;
          content: attr(data-placeholder);
        }

        width: 100%;

        &:focus {
          outline: none;
        }
      }

      ._placeholder {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        height: 4rem;
        color: $font-color-tertiary;
        pointer-events: none;
      }
    }
  }
</style>
