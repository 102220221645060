import useProperties from '@/composables/property/property';
import useSpaces from '@/composables/space/spaces';
import useUsers from '@/composables/user/users';
import { buildIconFromEntity } from 'shared/lib/icon';
import { computed } from 'vue';
import { findInArray } from 'shared/lib/array/array';
import { propertyType } from 'shared/constants.json';

export default function useSpace(space) {
  const { spaceProperty, spaceProperties } = useProperties();
  const usersSvc = useUsers();
  const spacesSvc = useSpaces();

  const icon = computed(() => buildIconFromEntity(space.value));

  const members = computed(() => usersSvc.users.value.filter((user) => {
    if (user.values !== undefined) {
      const userSpaces = user.values.filter((v) => v.property.uid === spaceProperty.value.uid)
        .map((pv) => pv.spaces)
        .flat();
      return findInArray({ haystack: userSpaces, needle: space.value.uid }) !== null;
    }
    return false;
  }).map((u) => ({ user: u, role: role({ user: u }) })));

  const roles = computed(() => spaceProperties.value.filter((p) => p.type === propertyType.user));

  const subspaces = computed(() => spacesSvc.allSpacesTreeFlat.value.find((s) => s.uid === space.value.uid).children);

  const addMembers = (members) => {
    const toUpdate = members.map(({ user }) => ({
      uid: user.uid,
      values: user.values.filter((v) => v.property.uid === spaceProperty.value.uid)
        .map((pv) => ({
          ...pv,
          spaces: [...pv.spaces, { uid: space.value.uid }],
        })),
    }));
    return usersSvc.updateUsers(toUpdate);
  };

  const removeMember = (member) => {
    const user = member.user;
    return usersSvc.updateUser({
      uid: user.uid,
      values: user.values.filter((v) => v.property.uid === spaceProperty.value.uid)
        .map((pv) => {
          pv.spaces = pv.spaces.filter((s) => s.uid !== space.value.uid);
          return pv;
        }),
    });
  };

  const role = (member) => {
    const user = member.user;
    const rolesIDs = roles.value.map((p) => p.uid);
    const pvs = space.value.properties.filter((pv) => rolesIDs.includes(pv.property.uid) && pv.users.map((u) => u.uid).includes(user.uid));
    if (pvs.length > 0) {
      return findInArray({ haystack: roles.value, needle: pvs[0].property.uid });
    }
    return null;
  };
  const toggleRole = (member, property) => {
    const user = member.user;
    const pv = space.value.properties.find((pv) => pv.property.uid === property.uid);
    const users = pv.users.filter((u) => u.uid !== user.uid);
    if (pv.users.length === users.length) {
      users.push({ uid: user.uid });
    }
    return spacesSvc.updateSpace({
      uid: space.value.uid,
      properties: [{ ...pv, users }],
    }, { ignoreResponse: false });
  };

  return {
    icon,

    members,
    addMembers,
    removeMember,
    roles,
    toggleRole,
    subspaces,
  };
}
