<template>
  <m-form-item
    :label="langName(item.lang)"
    class="translator-item"
  >
    <m-textarea
      class="translator-item"
      :value="item.value"
      auto-size
      clearable
      :has-feedback="false"
      @change="change"
    />
  </m-form-item>
</template>

<script>
import { nameByCode } from '@/lib/language';

export default {
  name: 'TranslatorItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  emits: ['change', 'delete-translation'],
  methods: {
    langName(key) {
      return nameByCode(key);
    },
    change(value) {
      this.$emit('change', { item: { [this.item.lang]: value } });
    },
    deleteTranslation() {
      this.$emit('delete-translation', { lang: this.item.lang });
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
</style>
