<template>
  <m-card-item
    class="component-list-item"
    @click="$emit('click')"
  >
    <template #left>
      <div
        class="_left"
      >
        <div class="_img">
          <m-icon
            v-if="typeof item.icon !== 'undefined'"
            :type="item.icon"
            size="30"
            :colors="$colors.grey.darken1"
          />
          <img
            v-else
            :src="item.src"
            :alt="item.title"
          >
        </div>
        <div class="_text">
          <div class="_title">
            {{ item.title }}
          </div>
          <div class="_sub-title">
            {{ item.subTitle }}
          </div>
        </div>
      </div>
    </template>
  </m-card-item>
</template>

<script>
export default {
  name: 'ComponentListItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  emits: ['click'],
};
</script>

<style scoped lang="scss" type="text/scss">
  .component-list-item {
    ._left {
      display: flex;
      align-items: center;

      ._img {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 5rem;
        height: 5rem;
        margin-right: 1.2rem;
        background-color: white;
        border: 1px solid $border-color;
        border-radius: $border-radius-sm;

        img {
          width: 100%;
          height: 100%;
        }
      }

      ._text {
        ._sub-title {
          font-size: $font-size-2;
          color: $font-color-secondary;
        }
      }
    }
  }
</style>
