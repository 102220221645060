import { UNASSIGNED } from '@/lib/constants';
import { useI18n } from 'vue-i18n';

export default function useUnassignedGoalCycle() {
  const { t } = useI18n();

  const unassignedCycle = {
    title: t('goalCycleSelector.withoutCycle'),
    uid: UNASSIGNED,
    editable: false,
    children: [],
  };

  return { unassignedCycle };
}
