import Highcharts from 'highcharts';
import de from '@/lang/de';
import en from '@/lang/en';
import highchartsTheme from '@/lib/charts/theme';
import { DEFAULT_LANGUAGE } from '@/lib/language';
import { Settings } from 'luxon';
import { createI18n } from 'vue-i18n';

const messages = { de, en };

const i18n = createI18n({
  legacy: false,
  locale: DEFAULT_LANGUAGE,
  fallbackLocale: DEFAULT_LANGUAGE,
  messages,
});
i18n.t = i18n.global.t;
i18n.tm = i18n.global.tm;
export default i18n;

export const changeLocale = (locale) => {
  let l = locale;
  if (typeof l === 'undefined' || l === null || l === '') {
    l = DEFAULT_LANGUAGE;
  }
  Settings.defaultLocale = l;
  i18n.global.locale.value = l;

  Highcharts.setOptions(highchartsTheme(i18n.tm));
};
