<template>
  <div class="goal-editor">
    <editable-page-header
      :title="title"
      :icon="goalIcon"
      :show-icon="!isEmptyIcon(goalIcon) || emojiAdded"
      :placeholder="$t('list.noTitle')"
      :disabled="!canEdit"
      :auto-focus="goal.title === ''"
      show-icon-on-top
      :size="openInModal ? 'small' : 'default'"
      class="_header"
      @change-title="updateTitle"
      @change-icon="updateIcon"
    >
      <template #pre-top-icon>
        <m-tooltip
          v-if="!isEmptyIcon(goalTypeIcon)"
          :mouse-enter-delay="0.5"
        >
          <m-icon-display
            class="_goal-type-icon"
            :icon="goalTypeIcon"
            :size="$store.state.breakpoint.smAndDown ? 40 : 60"
            show-background
          />
          <template #title>
            {{ goalTypeIconTooltip }}
          </template>
        </m-tooltip>
      </template>
      <template #actions>
        <div class="_header-actions">
          <m-btn
            v-if="isEmptyIcon(goalIcon) && !emojiAdded && canEdit"
            hide-border
            class="_item"
            icon="smile"
            light
            small
            @click="addEmoji"
          >
            {{ $t('page.addEmoji') }}
          </m-btn>
        </div>
      </template>
    </editable-page-header>
    <template
      v-if="goal.progressMeasurement !== goalProgressMeasurement.none"
    >
      <m-content
        padding-xxs
        :class="['_progress', canComment ? '-clickable': '']"
        @click="progressClick"
      >
        <goal-progress
          :goal="goal"
          class="_progress-bar"
          :clickable="canComment"
        />
        <div
          class="_status"
        >
          <status-prop
            :goal="goal"
          />
          <status-auto-update-hint
            v-if="goal.disableStatusAutoUpdate && goal.progressMeasurement === alignedItems"
            :goal="goal"
            :style="{ marginLeft: '.4rem' }"
          />
        </div>
      </m-content>
      <div
        v-if="dataSourceQuery !== null"
        class="_indicator"
      >
        <m-dropdown
          v-model:value="showIndicator"
          :title="$t('goalProgress.dataConnectionTitle')"
        >
          <m-btn
            class="_icon"
            hide-border
            small
            @click.stop="showIndicator = true"
          >
            <m-icon
              :type="dataSourceIcon"
              class="_icon"
            />
            {{
              $t('goalProgress.autoUpdateDescription', { dataSourceType: dataSourceTitle })
            }}
            <m-icon
              v-if="hasDataSourceError"
              type="warning"
              class="_warning-icon"
              :color="$colors.yellow.base"
            />
          </m-btn>
          <template #overlay>
            <m-card
              no-padding
              list
              class="_overlay"
            >
              <data-source-summary
                :goal="goal"
              />
            </m-card>
          </template>
        </m-dropdown>
      </div>
    </template>
    <div
      v-if="showProgressChart"
      class="_progress-chart"
    >
      <goal-progress-chart
        :goal="goal"
        :goal-updates="goalUpdates"
      />
      <m-divider no-border />
    </div>
    <div class="_properties">
      <goal-properties
        :properties="properties"
        :goal="goal"
        :can-edit="canEdit"
        :goal-children="goalChildren"
        :read-only="readOnly"
        :goal-cycles="goalCycles"
        :update-property="updateProperty"
        :can-edit-properties="canEditProperties"
        :validation-errors="validationErrors"
      />
      <div class="_hidden-props">
        <div
          v-if="!showAllProps && invisiblePropsAmount > 0"
          class="_hidden-props-btn"
          @click="showAllProps = true"
        >
          <div
            v-if="invisiblePropsHaveErrors"
            class="_error-icon"
          >
            <m-icon
              type="exclamation-circle-filled"
              :color="$colors.red.base"
            />
          </div>
          <div class="_toggle-icon">
            <m-icon
              type="down"
              :color="$colors.grey.lighten3"
            />
          </div>
          {{ invisiblePropsAmount }} {{ $t('propertyValues.hiddenProperties', invisiblePropsAmount) }}
        </div>
        <div
          v-else-if="showAllProps && invisiblePropsAmount > 0"
          class="_hidden-props-btn"
          @click="showAllProps = false"
        >
          <div class="_toggle-icon">
            <m-icon
              type="up"
              :color="$colors.grey.lighten3"
            />
          </div>
          {{ $t('propertyValues.hideProperties', invisiblePropsAmount) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataSourceSummary from '@/components/datasource/DataSourceSummary.vue';
import EditablePageHeader from '@/components/page/EditablePageHeader.vue';
import GoalProgress from '@/components/goal/GoalProgress.vue';
import GoalProgressChart from '@/components/goal/GoalProgressChart.vue';
import GoalProperties from '@/components/goal/GoalProperties.vue';
import StatusAutoUpdateHint from '@/components/goal/StatusAutoUpdateHint.vue';
import StatusProp from '@/components/property/StatusProp.vue';
import camelCase from 'lodash-es/camelCase';
import useAccess from '@/composables/access/access';
import useAccountSettings from '@/composables/logged-in-user-account/account-settings';
import useDebounce from '@/composables/debounce';
import useGoalDatasource from '@/composables/goal/goal-datasource';
import useGoalDetailProperties from '@/composables/customize-page/goal-detail-page-properties';
import useGoalDetailRules from '@/composables/goal/validator/goal-detail-rules';
import useGoalPickerFilter from '@/composables/goal/goal-picker-filter';
import useGoalTypeProperty from '@/composables/customize-page/goal-type-property';
import useGoals from '@/composables/goal/goals';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useValidator from '@/composables/goal/validator/validator';
import {
  accessGroupFlag,
  accessPolicyType,
  featureFlag,
  goalProgressMeasurement,
  propertyVisibility,
} from 'shared/constants.json';
import { buildIconFromEntity, isEmptyIcon } from 'shared/lib/icon';
import { computed, toRef } from 'vue';
import { dataSourceError } from '@/lib/data-source';
import { textByLang } from 'shared/lib/language';

export default {
  name: 'GoalEditor',
  props: {
    goal: {
      type: Object,
      required: true,
    },
    updateProperty: {
      type: Function,
      required: true,
    },
    goalCycles: {
      type: Array,
      required: true,
    },
    goalChildren: {
      type: Array,
      required: true,
    },
    goalUpdates: {
      type: Array,
      default: () => [],
    },
    openInModal: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['progress-click', 'change-parent'],
  setup(props) {
    const { goalPickerFilter } = useGoalPickerFilter(props.goal);
    const { debounce } = useDebounce({ onBeforeUnmountFlush: true });
    const goalsSvc = useGoals();
    const { loggedInUser } = useLoggedInUser();
    const { goalTypeProperty, goalTypeOption, goalTypeIcon } = useGoalTypeProperty();
    const goal = toRef(props, 'goal');
    const { sortedGoalDetailPageProperties, updateVisibility } = useGoalDetailProperties({
      goal,
      userLang: loggedInUser.value.language,
      goalTypeProperty,
    });

    const { userHasRight } = useAccess();
    const { accountSettings } = useAccountSettings();
    const { loggedInUserAccount } = useLoggedInUserAccount();

    const { accountHasFeature } = useAccess();
    const { rules: goalDetailRules } = useGoalDetailRules(
      goalTypeProperty.value.options,
      goalsSvc,
      accountHasFeature([featureFlag.advancedOkrRules]),
    );
    const { validate: validateGoal } = useValidator({
      rules: goalDetailRules,
      usesOKRRules: true,
    });

    const { query: dataSourceQuery, title: dataSourceTitle, icon: dataSourceIcon } = useGoalDatasource(goal);

    return {
      sortedGoalDetailPageProperties,
      updateVisibility,
      goalPickerFilter,
      userHasRight,
      account: loggedInUserAccount,
      userLang: loggedInUser.value.language,
      accountSettings,
      validateGoal,
      debounce,
      goalsSvc,
      goalType: computed(() => goalTypeOption(props.goal)),
      goalTypeIcon: computed(() => goalTypeIcon(props.goal)),
      dataSourceQuery,
      dataSourceTitle,
      dataSourceIcon,
    };
  },
  components: {
    StatusAutoUpdateHint,
    StatusProp,
    GoalProperties,
    DataSourceSummary,
    GoalProgressChart,
    EditablePageHeader,
    GoalProgress,
  },
  data() {
    return {
      emojiAdded: false,
      title: this.goal.title,
      showIndicator: false,
      goalProgressMeasurement,
      camelCase,
      propertyVisibility,
      showAllProps: false,
      alignedItems: goalProgressMeasurement.alignedItems,
    };
  },
  computed: {
    goalTypeIconTooltip() {
      if (this.goalType === null) {
        return '';
      }
      return textByLang(this.goalType.label, this.userLang);
    },
    validationErrors() {
      return this.validateGoal({ toValidate: this.goal, selectRulesFrom: this.goal });
    },
    properties() {
      if (this.showAllProps) {
        return this.sortedGoalDetailPageProperties;
      }
      return this.sortedGoalDetailPageProperties.filter((p) => p.visible);
    },
    canEditProperties() {
      return this.userHasRight([accessGroupFlag.propertyWriteAccess]);
    },
    invisiblePropsHaveErrors() {
      for (let i = 0; i < this.invisibleProps.length; i++) {
        if (this.validationErrors[this.invisibleProps[i].uid] !== undefined || this.validationErrors[this.invisibleProps[i].type] !== undefined) {
          return true;
        }
      }

      return false;
    },
    invisibleProps() {
      return this.sortedGoalDetailPageProperties.filter((p) => !p.visible);
    },
    invisiblePropsAmount() {
      return this.invisibleProps.length;
    },
    showProgressChart() {
      return this.goal.progressMeasurement !== goalProgressMeasurement.none;
    },
    canEdit() {
      return !this.readOnly && [accessPolicyType.full, accessPolicyType.write].includes(this.goal.accessRight);
    },
    canComment() {
      return !this.readOnly && this.goal.publishedAt !== null && [accessPolicyType.full, accessPolicyType.write, accessPolicyType.comment].includes(this.goal.accessRight);
    },
    hasDataSourceError() {
      return dataSourceError({ salesforceQuery: this.goal.salesforceQuery }) !== null;
    },
    goalTitle() {
      return this.goal.title;
    },
    goalIcon() {
      return buildIconFromEntity(this.goal);
    },
  },
  methods: {
    isEmptyIcon,
    progressClick() {
      if (!this.canComment) {
        return;
      }

      this.$emit('progress-click', this.goal);
    },
    addEmoji() {
      this.emojiAdded = true;
    },
    updateTitle(value) {
      this.title = value;
      const update = () => {
        this.goalsSvc.updateSingle({ uid: this.goal.uid, title: value });
      };
      this.debounce(update, 500);
    },
    updateIcon(icon) {
      this.goalsSvc.updateSingle({ uid: this.goal.uid, icon: icon.value });
      if (isEmptyIcon(icon)) {
        this.emojiAdded = false;
      }
    },
  },
  watch: {
    goalTitle(newVal) {
      if (this.title !== newVal) {
        this.title = newVal;
      }
    },
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  ._overlay {
    width: 40rem;
  }

  .goal-editor {
    ._header {
      margin-top: 2rem;
      margin-bottom: 2rem;
      margin-left: .4rem;

      ._goal-type-icon {
        margin-right: 1rem;
      }

      ._header-actions {
        display: flex;
        margin-left: -.4rem;
      }
    }

    ._status {
      display: flex;
      flex: 0 1 auto;
      justify-content: flex-end;

      ._tag {
        margin: 0 .4rem .5rem 0;
      }
    }

    ._progress {
      display: flex;

      &.-clickable {
        cursor: pointer;

        &:hover {
          background-color: $hover-color;
          border-radius: $border-radius-sm;
        }
      }

      ._progress-bar {
        flex: 1 1 auto;
        margin-right: 5rem;
      }
    }

    ._progress-chart {
      margin: 3rem -.8rem 0;
    }

    ._properties {
      ._prop {
        margin-bottom: 0;
      }

      ._hidden-props {
        position: relative;
        display: inline-flex;
        padding: .4rem 0;

        ._hidden-props-btn {
          display: inline-flex;
          align-items: center;
          height: 3.2rem;
          padding: 0 .4rem;
          color: $font-color-tertiary;
          cursor: pointer;
          border-radius: $default-border-radius;

          &:hover {
            background-color: $hover-color;
          }

          ._error-icon {
            position: absolute;
            top: 1.3rem;
            left: -1.7rem;

            @media (max-width: $screen-size-sm) {
              left: -1.4rem;
            }
          }

          ._toggle-icon {
            margin-right: .4rem;
          }
        }
      }
    }

    ._indicator {
      margin-top: 1rem;
      margin-left: -.4rem;

      ._icon {
        margin-right: .4rem;
      }

      ._warning-icon {
        margin-left: .4rem;
      }
    }
  }
</style>
