import { TYPE, UID, VAR } from 'shared/api/query/constants';
import { indirectVarId } from '@/lib/filter/base-translator/varid';
import { reverseEdge } from 'shared/api/query/edges';
import { searchReplaceAll } from 'shared/lib/object/search-replace';

export const indirectChildrenFn = (model, varIdFn) => (childFT, childVB) => (scope, index) => {
  const varName = varIdFn(index);
  const indirectVarName = indirectVarId(varIdFn, scope.indirectProperty.model)(index);

  if (scope.indirectProperty.scope.directProperty !== undefined && scope.indirectProperty.scope.directProperty !== null) {
    childVB.push({
      alias: VAR,
      func: { name: TYPE, args: [{ value: scope.indirectProperty.model, typ: 1 }] },
      model: scope.indirectProperty.model,
      filter: childFT,
      var: indirectVarName,
    });
  } else {
    const varNameToReplace = childFT.func.needsVar[0].name;
    childVB = searchReplaceAll(childVB, 'var', varNameToReplace, indirectVarName);
  }

  const varBlocks = [
    {
      alias: VAR,
      func: { name: UID, needsVar: [{ name: indirectVarName, typ: 1 }] },
      needsVar: [{ name: indirectVarName, typ: 1 }],
      model: scope.indirectProperty.model,
      children: [
        {
          attr: reverseEdge(scope.indirectProperty.edgeName),
          model,
          var: varName,
        },
      ],
    },
  ];

  const filterTree = {
    func: {
      name: UID,
      needsVar: [{ name: varName, typ: 1 }],
    },
  };

  varBlocks.unshift(...childVB);

  return { filterTrees: [filterTree], varBlocks };
};
