import useGoals from '@/composables/goal/goals';
import { AND } from 'shared/api/query/filter';
import { COUNT, RESULT } from 'shared/api/query/constants';
import { applyBaseFilter } from '@/lib/filter/base-filter';
import { goalCascadeList } from '@/api/query/nebula/goal-cascade';
import { goalsById } from '@/api/query/nebula/goal';

export default function useGoalFetcher(
  goalProperties,
  viewParamsService,
  customFuncCtx,
  options = { includeChildren: true, includeParents: true, includeGrandparents: false },
) {
  const goalsSvc = useGoals();

  const getGoalsByIds = (
    ids,
    pagination = { page: 1, itemsPerPage: 20 },
  ) => {
    let progressCourseAlias;
    let customFuncHandler;
    if (viewParamsService.compareTo.value.length !== 0) {
      progressCourseAlias = customFuncCtx.alias.value;
      customFuncHandler = [customFuncCtx];
    }
    return goalsSvc.getGoals({
      queries: goalsById({
        ids,
        alias: RESULT,
        pagination: {
          page: pagination.page,
          itemsPerPage: pagination.itemsPerPage,
          countAlias: COUNT,
        },
        order: viewParamsService.order.value,
        progressCourse: viewParamsService.compareTo.value,
        progressCourseAlias,
        includeChildren: false,
        includeParents: false,
      }),
      countKey: 'childrenCount',
      customFuncHandler,
    });
  };

  const getGoals = (
    filter = {
      searchTerm: '',
      selectedCycles: [],
      disabledGoalIds: [],
    },
    baseFilter = {},
    pagination = { page: 1, itemsPerPage: 20, countAlias: COUNT },
  ) => {
    let progressCourseAlias;
    let customFuncHandler;
    if (viewParamsService.compareTo.value.length !== 0) {
      progressCourseAlias = customFuncCtx.alias.value;
      customFuncHandler = [customFuncCtx];
    }
    return goalsSvc.getGoals({
      queries: applyBaseFilter({
        queries: goalCascadeList({
          pagination: {
            page: pagination.page,
            itemsPerPage: pagination.itemsPerPage,
            countAlias: COUNT,
          },
          progressCourse: viewParamsService.compareTo.value,
          progressCourseAlias,
          goalCycles: filter.selectedCycles,
          searchTerm: filter.searchTerm,
          filter: viewParamsService.gqlFilterObject.value.filterTree,
          varBlocks: viewParamsService.gqlFilterObject.value.varBlocks,
          excludedGoalIds: filter.disabledGoalIds,
          childrenPagination: { itemsPerPage: 30, page: 1 },
          order: viewParamsService.order.value,
          alias: RESULT,
          applyFilterOnFirstLevelOnly: viewParamsService.applyFilterOnFirstLevelOnly.value,
          includeChildren: options.includeChildren,
          includeParents: options.includeParents,
          includeGrandparents: options.includeGrandparents,
        }),
        operator: AND,
        baseFilter,
      }),
      customFuncHandler,
    });
  };

  return {
    getGoalsByIds,
    getGoals,
  };
}
