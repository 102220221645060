import useAppFeedbackRepo from '@/composables/app-feedback/app-feedback-repo';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';

import { canDisplayAppFeedback } from '@/composables/app-feedback/show-app-feedback';

export default function useAppFeedback() {
  const { loggedInUser } = useLoggedInUser();
  const { loggedInUserAccount } = useLoggedInUserAccount();
  const repo = useAppFeedbackRepo();

  return {
    canDisplayAppFeedback: () => canDisplayAppFeedback(loggedInUserAccount.value, loggedInUser.value, repo.entityList.value),
    mutateAppFeedback: repo.mutateSingle,
  };
}
