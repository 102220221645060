<template>
  <div class="goal-details">
    <goal-page
      :key="goalId"
      ref="goalPage"
      class="_goal-page"
      :goal-id="goalId"
      :goal-modifiers="goalModifiers"
      :route-after-delete="{ name: routeName.goalsExplorer }"
      @open="open"
      @deleted="onDeleted"
    />
  </div>
</template>

<script>
import GoalPage from '@/components/goal/GoalPage.vue';
import useGoalDetailsCtx from '@/composables/goal/goal-details-context';
import useOpenPeekMode from '@/composables/goal/open-peek-mode';
import { routeName } from 'shared/constants.json';

export default {
  name: 'GoalDetails',
  components: { GoalPage },
  data() {
    return {
      emptyCheckDone: false,
      checkingToDelete: false,
      routeName,
    };
  },
  setup() {
    const peekModeSvc = useOpenPeekMode();
    const { paramId: goalId, goalModifiers } = useGoalDetailsCtx();
    return {
      peekModeSvc,
      goalId,
      goalModifiers,
    };
  },
  methods: {
    open(goal, openInModal = true) {
      this.peekModeSvc.openGoal({ goalId: goal.uid, modal: openInModal });
    },
    onDeleted() {
      this.emptyCheckDone = true;
    },
  },
  beforeRouteLeave(to, from, next) {
    if (!this.emptyCheckDone) {
      const callback = () => {
        this.emptyCheckDone = true;
        next();
      };

      this.$refs.goalPage.checkToDeleteEmpty(callback, to);
      return;
    }

    next();
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  @import "shared/assets/scss/box-shadow";

  .goal-details {
    flex: 1 1 auto;
    background-color: white;
  }
</style>
