<template>
  <user-list
    class="_section-content"
  />
</template>

<script setup>
import UserList from '@/components/users/UserList.vue';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useUserDefaultProps from '@/composables/saved-views/user-default-props';
import useViewServiceInit from '@/composables/saved-views/view-service-init';
import { AND } from '@/lib/filter/scope-tree';
import { VIEWS_SERVICE } from '@/lib/constants';
import { provide } from 'vue';
import { useI18n } from 'vue-i18n';
import { viewApplication, viewType } from 'shared/constants.json';

const { loggedInUserAccount } = useLoggedInUserAccount();

const viewServiceInitService = useViewServiceInit();
const i18n = useI18n();

const { defaultProps } = useUserDefaultProps();

const viewApp = viewApplication.memberAccount;

const defaultView = {
  viewType: viewType.list,
  title: i18n.t('list.list'),
  viewApplication: viewApp,
  params: {
    filter: { account: { uid: loggedInUserAccount.value.uid }, children: [], op: AND },
    order: [],
    props: [],
  },
};

let config = viewServiceInitService.defaultConfig();

config = viewServiceInitService.withDefaultView(config, defaultView);
config = viewServiceInitService.withViewApplication(config, viewApp);
config = viewServiceInitService.withDefaultProps(config, defaultProps);

const viewSvc = viewServiceInitService.routeAwareViewService(config);
provide(VIEWS_SERVICE, viewSvc);

</script>

<style scoped lang="scss" type="text/scss">

</style>
