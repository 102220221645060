<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.91419 5.66403C6.43742 5.26436 6.43742 4.53099 6.91419 4.13132L7.62135 3.53853C7.99297 3.227 8.53456 3.227 8.90619 3.53853L18.0858 11.2336C18.5626 11.6333 18.5626 12.3667 18.0858 12.7664L8.90619 20.4615C8.53456 20.773 7.99298 20.773 7.62135 20.4615L6.91419 19.8687C6.43742 19.469 6.43742 18.7356 6.91419 18.336L14.4725 12L6.91419 5.66403Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default { name: 'Right' };
</script>

<style scoped lang="scss" type="text/scss">

</style>
