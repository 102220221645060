import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useUpdateProperty from '@/composables/property/update-property';
import { computed } from 'vue';
import { createPropsList } from '@/lib/props';
import { directFeedProperties, directListProperties } from '@/lib/updates/properties';
import { update as updateConfig } from 'shared/api/query/configs.json';
import { useI18n } from 'vue-i18n';

export default function useUpdateDefaultProps({ isFeed }) {
  const i18n = useI18n();

  const { userLang } = useLoggedInUser();

  const updatePropertySvc = useUpdateProperty();

  const defaultProps = computed(() => {
    let props = directFeedProperties(i18n.t);
    if (!isFeed.value) {
      props = directListProperties(i18n.t);
    }

    return createPropsList({
      properties: updatePropertySvc.properties.value,
      directProperties: props,
      userLang: userLang.value,
      model: updateConfig.model,
    });
  });

  return { defaultProps };
}
