import { copy, shallowCopy } from 'shared/lib/copy';
import { propertyType } from 'shared/constants.json';
import { sortByArray } from 'shared/lib/sort';

export const UNGROUPED = -1;

export const mergePropertyValues = (newValues, existingValues) => {
  const res = copy(existingValues);
  const cpNewValues = copy(newValues);
  for (let i = 0; i < res.length; i++) {
    for (let j = 0; j < cpNewValues.length; j++) {
      if (cpNewValues[j].property.uid === existingValues[i].property.uid) {
        res[i] = cpNewValues[j];
      }
    }
  }
  return res;
};

const optionsOfGoal = (goal, property) => {
  if (property === null) {
    return [];
  }

  const filtered = goal.properties.filter((p) => p.property.uid === property.uid);
  if (filtered.length !== 1) {
    return [];
  }

  if (property.type === propertyType.space) {
    return filtered[0].spaces;
  }
  return filtered[0].selectedOptions;
};

export const groupGoalsByProperty = (goals, property, options, sortedIds = []) => {
  const cp = shallowCopy(goals);
  cp.sort(sortByArray(sortedIds.map((uid) => ({ uid }))));
  if (property === null) {
    return [{ uid: undefined, option: null, items: cp }];
  }
  const itemMap = { [UNGROUPED]: [] };
  const optionMap = { [UNGROUPED]: null };

  options.forEach((o) => {
    itemMap[o.uid] = [];
    optionMap[o.uid] = {
      ...o,
      property: { uid: property.uid },
    };
  });

  cp.forEach((goal) => {
    const options = optionsOfGoal(goal, property);
    if (options.length === 0) {
      itemMap[UNGROUPED].push(goal);
      return;
    }
    options.forEach((o) => {
      itemMap[o.uid].push(goal);
    });
  });

  const res = [];
  Object.keys(itemMap).forEach((optionId) => {
    const items = itemMap[optionId];
    if (items.length === 0) {
      return;
    }
    res.push({
      uid: optionMap[optionId]?.uid,
      option: optionMap[optionId],
      items: itemMap[optionId],
    });
  });

  res.sort(sortByArray(options, 'uid'));
  return res;
};
