import { UID } from 'shared/api/query/constants';
import {
  account,
  numberRange,
  property,
  propertyOption,
  space,
  timeRange,
  user,
  userScope,
  userScopeTree,
} from 'shared/api/query/configs.json';

export const userScopeChildren = [
  { attr: UID },
  { attr: userScope.edges.isEmpty, default: false },
  { attr: userScope.edges.type },
  {
    attr: userScope.edges.property,
    model: property.model,
    default: null,
    children: [
      { attr: UID },
      { attr: property.edges.type, default: '' },
    ],
  },
  {
    attr: userScope.edges.timeRange,
    model: timeRange.model,
    default: null,
    children: [
      { attr: UID },
      { attr: timeRange.edges.start, default: null },
      { attr: timeRange.edges.end, default: null },
    ],
  },
  {
    attr: userScope.edges.numberRange,
    model: numberRange.model,
    default: null,
    children: [
      { attr: UID },
      { attr: numberRange.edges.min },
      { attr: numberRange.edges.minType },
      { attr: numberRange.edges.max },
      { attr: numberRange.edges.maxType },
    ],
  },
  {
    attr: userScope.edges.users,
    model: user.model,
    default: [],
    children: [
      { attr: UID },
      { attr: user.edges.firstName },
      { attr: user.edges.lastName },
    ],
  },
  {
    attr: userScope.edges.selectedOptions,
    model: propertyOption.model,
    default: [],
    children: [{ attr: UID }],
  },
  {
    attr: userScope.edges.spaces,
    model: space.model,
    default: [],
    children: [{ attr: UID }],
  },
  {
    attr: userScope.edges.staticUsers,
    model: user.model,
    default: [],
    children: [
      { attr: UID },
      { attr: user.edges.firstName },
      { attr: user.edges.lastName },
    ],
  },
];

export const userScopeTreeChildren = [
  { attr: UID },
  { attr: userScopeTree.edges.op },
  { attr: userScopeTree.edges.treeHash },
  {
    attr: userScopeTree.edges.account,
    model: account.model,
    default: null,
    children: [{ attr: UID }],
  },
  {
    attr: userScopeTree.edges.scope,
    model: userScope.model,
    default: null,
    children: userScopeChildren,
  },
  {
    attr: userScopeTree.edges.children,
    model: userScopeTree.model,
    default: [],
    children: [
      { attr: UID },
      { attr: userScopeTree.edges.op },
      {
        attr: userScopeTree.edges.scope,
        model: userScope.model,
        default: null,
        children: userScopeChildren,
      },
      {
        attr: userScopeTree.edges.children,
        model: userScopeTree.model,
        default: [],
        children: [
          { attr: UID },
          { attr: userScopeTree.edges.op },
          {
            attr: userScopeTree.edges.scope,
            model: userScope.model,
            default: null,
            children: userScopeChildren,
          },
        ],
      },
    ],
  },
];
