import i18n from '@/lang';
import { COLUMN } from '@/lib/constants';
import { FONT_COLOR } from '@/lib/charts/colors';
import { getBaseChart } from '@/lib/charts/base-options';
import { getValueAsPercent } from '@/lib/charts/format';

export const getBaseColumnChartOptions = (categories, multiSeries) => getBaseChart(categories, multiSeries, COLUMN);

export const getDistributionColumnChart = (categories, multiSeries) => ({
  chart: {
    type: COLUMN,
    height: 200,
    margin: [0, 0, 20, 0],
  },
  xAxis: {
    categories,
    visible: true,
    tickWidth: 0,
    lineWidth: 0,
  },
  yAxis: { visible: false },
  plotOptions: {
    series: {
      pointPadding: -0.2,
      dataLabels: {
        enabled: true,
        style: {
          textOutline: 0,
          textAlign: 'center',
          color: FONT_COLOR,
          fontWeight: 400,
        },
        formatter() {
          return `${this.y} / ${getValueAsPercent(this.point.raw.proportion, 0)}`;
        },
      },
    },
  },
  tooltip: {
    shared: false,
    useHTML: true,
    formatter() {
      return `
      ${i18n.t('charts.answer')}: ${this.x}</br>
${i18n.t('charts.amountOfAnswers')}: ${this.y}</br>
${i18n.t('charts.proportion')}: ${getValueAsPercent(this.point.raw.proportion, 0)}
`;
    },
  },
  series: multiSeries,
});
