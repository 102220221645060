import { AND, GE, LE, UID } from 'shared/api/query/constants';
import { hasFilter, notHasFilter } from 'shared/api/query/filter';
import { isEmptyType, isNotEmptyType, translateDynamicTimeRange } from '@/lib/filter/dynamic-date';
import { property, propertyValue } from 'shared/api/query/configs.json';
import { reverseEdge } from 'shared/api/query/edges';

export const makeDateFn = (childrenFn, varIdFn) => (scope, index) => {
  const filters = [];
  const varName = varIdFn(index);

  if (isEmptyType(scope.timeRange) || isNotEmptyType(scope.timeRange)) {
    const filterChildren = [];
    if (isEmptyType(scope.timeRange)) {
      filterChildren.push(notHasFilter(propertyValue.edges.timestamp));
    }
    if (isNotEmptyType(scope.timeRange)) {
      filterChildren.push(hasFilter(propertyValue.edges.timestamp));
    }
    const varBlocks = [
      {
        alias: 'var',
        uid: [scope.property.uid],
        model: property.model,
        func: { name: UID },
        children: [
          {
            attr: reverseEdge(propertyValue.edges.property),
            model: propertyValue.model,
            filter: {
              op: AND,
              child: filterChildren,
            },
            children: childrenFn(varName),
          },
        ],
      },
    ];
    const filterTree = {
      func: {
        name: UID,
        needsVar: [{ name: varName, typ: 1 }],
      },
    };
    return { filterTrees: [filterTree], varBlocks };
  }

  const { start, end } = translateDynamicTimeRange(scope.timeRange);
  if (start !== null) {
    filters.push({
      func: {
        name: GE,
        attr: propertyValue.edges.timestamp,
        args: [{ value: start }],
      },
    });
  }

  if (end !== null) {
    filters.push({
      func: {
        name: LE,
        attr: propertyValue.edges.timestamp,
        args: [{ value: end }],
      },
    });
  }

  const varBlocks = [
    {
      alias: 'var',
      func: { name: UID },
      uid: [scope.property.uid],
      model: property.model,
      children: [
        {
          attr: reverseEdge(propertyValue.edges.property),
          model: propertyValue.model,
          filter: {
            op: AND,
            child: filters,
          },
          children: childrenFn(varName),
        },
      ],
    },
  ];

  const filterTree = {
    func: {
      name: UID,
      needsVar: [{ name: varName, typ: 1 }],
    },
  };
  return { filterTrees: [filterTree], varBlocks };
};

export const directPropDateFn = (scope) => {
  if (isEmptyType(scope.timeRange)) {
    return {
      filterTrees: [notHasFilter(scope.directProperty.edgeName)],
      varBlocks: [],
    };
  }

  if (isNotEmptyType(scope.timeRange)) {
    return {
      filterTrees: [hasFilter(scope.directProperty.edgeName)],
      varBlocks: [],
    };
  }

  const filters = [];
  const { start, end } = translateDynamicTimeRange(scope.timeRange);
  if (start !== null) {
    filters.push({
      func: {
        attr: scope.directProperty.edgeName,
        name: GE,
        args: [{ value: start }],
      },
    });
  }

  if (end !== null) {
    filters.push({
      func: {
        attr: scope.directProperty.edgeName,
        name: LE,
        args: [{ value: end }],
      },
    });
  }

  if (filters.length === 0) {
    return { filterTrees: [], varBlocks: [] };
  }

  return {
    filterTrees: [{
      op: AND,
      child: filters,
    }],
    varBlocks: [],
  };
};
