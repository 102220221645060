<template>
  <m-content
    padding
    class="delete-property-card"
  >
    <m-section
      :title="$t('deletePropertyCard.heading')"
    />
    <m-alert
      type="info"
      class="_alert"
    >
      <div
        class="_description"
      >
        {{ $t('deletePropertyCard.description') }}
      </div>
    </m-alert>
    <div class="_item">
      <span class="_label">{{ $t('deletePropertyCard.propertyLabel') }}: </span>
      <div class="_property-label">
        <div class="_icon">
          <m-icon :type="iconByType({ type: property.type })" />
        </div>
        <div>{{ textByLang(property.label) }}</div>
      </div>
    </div>
    <div class="_item">
      <span class="_label">{{ $t('deletePropertyCard.usedForLabel') }}: </span>
      <span>{{ property.usedFor.map(el => usedForLabelByType(el)()).join(', ') }}</span>
    </div>
    <div class="_actions">
      <m-btn
        @click="emitClose"
      >
        {{ $t('general.cancel') }}
      </m-btn>
      <m-btn
        color="danger"
        class="_submit"
        :loading="loading"
        @click="submit"
      >
        {{ $t('general.delete') }}
      </m-btn>
    </div>
  </m-content>
</template>

<script>

import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useProperties from '@/composables/property/property';
import { iconByType, usedForLabelByType } from '@/lib/property';
import { logCatch } from '@/lib/logger/logger';
import { textByLang } from 'shared/lib/language';

export default {
  name: 'DeletePropertyCard',
  props: {
    property: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { userLang } = useLoggedInUser();
    const { deleteSingle } = useProperties();
    return { deleteSingle, userLang };
  },
  emits: ['close', 'deleted'],
  data() {
    return { loading: false, usedForLabelByType, textByLang, iconByType };
  },
  methods: {
    emitClose() {
      this.$emit('close');
    },
    submit() {
      this.loading = true;
      this.deleteSingle(this.property.uid).then(() => {
        this.$showSnackbar({ color: 'success', message: this.$t('success.deleted') });
        this.$emit('deleted');
      })
        .catch(logCatch(() => {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
        }))
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .delete-property-card {
    ._item {
      display: flex;
      align-items: center;
      margin-bottom: .8rem;

      ._label {
        margin-right: .8rem;
        color: $secondary-color;
      }

      ._property-label {
        display: flex;
        align-items: center;
      }

      ._icon {
        margin-right: .4rem;
      }
    }

    ._actions {
      display: flex;
      justify-content: flex-end;
      margin-top: 2rem;

      ._submit {
        margin-left: .8rem;
      }
    }
  }

</style>
