<template>
  <div class="user-scope-tree-user-list">
    <div class="_heading">
      <h6>
        {{ $t('userScopeTreeUserList.userList') }} ({{ count }})
      </h6>
    </div>
    <div class="_list">
      <transition
        mode="out-in"
        name="list-left"
      >
        <div :key="page">
          <user-display
            v-for="user in userList"
            :key="user.uid"
            class="_user"
            :user="user"
          />
        </div>
      </transition>
    </div>
    <div
      v-if="count > itemsPerPage"
      class="_pagination"
    >
      <m-pagination
        v-model:value="page"
        :total="count"
        :items-per-page="itemsPerPage"
        small
        hide-border
      />
    </div>
  </div>
</template>

<script>
import UserDisplay from 'shared/components/UserDisplay.vue';
import useUsers from '@/composables/user/users';
import { UserFilterHandler } from '@/lib/filter/user/handler';
import { computed, ref } from 'vue';
import { isInFilter } from '@/lib/filter/base-frontend-filter/handler';

export default {
  name: 'UserScopeTreeUserList',
  props: {
    userScopeTree: {
      type: Object,
      required: true,
    },
    excludedUsers: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['update-count'],
  components: { UserDisplay },
  setup(props) {
    const userSvc = useUsers();

    const filterObject = computed(() => {
      const handler = new UserFilterHandler();
      return handler.Translate(props.userScopeTree);
    });

    const page = ref(1);
    const itemsPerPage = ref(5);

    const userList = computed(() => userSvc.users.value.filter((user) => !props.excludedUsers.map(({ uid }) => uid).includes(user.uid))
      .filter((entity) => isInFilter({ isFilterMode: false })({ entity, scopeTree: props.userScopeTree, propertyValues: entity.values })));
    const totalAmount = computed(() => userList.value.length);

    const pagedUserList = computed(() => {
      const start = (page.value - 1) * itemsPerPage.value;
      return userList.value.slice(start, start + itemsPerPage.value);
    });

    const loadFirst = () => {
      itemsPerPage.value = 5;
    };
    loadFirst();

    return {
      count: totalAmount,
      page,
      itemsPerPage,
      filterObject,
      userList: pagedUserList,

      loadFirst,
    };
  },
  watch: {
    filterObject: {
      handler(val, newVal) {
        if (JSON.stringify(val) === JSON.stringify(newVal)) {
          return;
        }
        this.loadFirst();
      },
      deep: true,
    },
    count: {
      handler(val) {
        this.$emit('update-count', val);
      },
      immediate: true,
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">

  .user-scope-tree-user-list {
    ._heading {
      display: flex;

      ._spinner {
        margin-left: 1rem;
      }
    }

    ._user {
      padding: .4rem;
    }

    ._pagination {
      display: flex;
      justify-content: center;
      margin-top: 1rem;
    }
  }
</style>
