<template>
  <div class="status-scope">
    <m-select
      v-if="!hideOp"
      v-model:value="selectedType"
      :items="operatorTypes"
      :read-only="readOnly"
      :disabled="disabled"
      class="_op"
    />

    <m-select
      v-model:value="selectedOptions"
      class="_select"
      full-width
      :items="options"
      :grouped-items="groupedOptions"
      :read-only="readOnly"
      :disabled="disabled"
      :value-key="valueKey"
      :item-text="itemText"
      :max-tag-text-length="maxTagTextLength"
      multiple
      :placeholder="$t('optionScope.placeholder')"
      keep-open-on-click
      return-object
      has-custom-item
    >
      <template #item="item">
        <status-item
          small
          :selected-option="getSelectedOptionFromItem(item)"
        />
      </template>
    </m-select>
  </div>
</template>

<script>
import StatusItem from '@/components/goal/StatusItem.vue';
import useGoalProperty from '@/composables/property/goal-property';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useStatusProperty from '@/composables/goal/status-property';
import { AND, NOT } from '@/lib/filter/scope-tree';
import { copy } from 'shared/lib/copy';

export default {
  name: 'StatusScope',
  props: {
    value: {
      type: Object,
      required: true,
    },
    isNot: {
      type: Boolean,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideOp: {
      type: Boolean,
      default: false,
    },
    valueKey: {
      type: String,
      default: 'uid',
    },
    itemText: {
      type: String,
      default: 'label.en',
    },
    maxTagTextLength: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    const { userLang } = useLoggedInUser();
    const { statusProperty: statusProp } = useGoalProperty();
    const { leafOptions, groupedOptions } = useStatusProperty(statusProp, userLang);
    return { userLang, options: leafOptions, groupedOptions };
  },
  emits: ['input', 'update:value', 'change-not', 'change'],
  components: { StatusItem },
  data() {
    return { selectedOptions: [] };
  },
  computed: {
    operatorTypes() {
      return [
        { text: this.$t('optionScope.contains'), value: AND },
        { text: this.$t('optionScope.notContains'), value: NOT },
      ];
    },
    selectedType: {
      get() {
        if (this.isNot) {
          return NOT;
        }
        return AND;
      },
      set(val) {
        if (val === NOT) {
          this.$emit('change-not', NOT);
          return;
        }
        this.$emit('change-not', AND);
      },
    },
    scope() {
      return {
        ...this.value,
        selectedOptions: this.selectedOptions,
        isEmpty: false,
      };
    },
  },
  methods: {
    getSelectedOptionFromItem(item) {
      if (item.isTrigger) {
        return this.options.find((o) => o.uid === item.item.uid);
      }
      return this.options.find((o) => o.uid === item.item);
    },
    updateValues() {
      if (this.value === null
        || this.value.selectedOptions === null
        || typeof this.value.selectedOptions === 'undefined'
      ) {
        this.selectedOptions = [];
        return;
      }
      this.selectedOptions = this.value.selectedOptions;
    },
    emitUpdate(val) {
      this.$emit('input', val);
      this.$emit('update:value', val);
      this.$emit('change', val);
    },
  },
  watch: {
    scope: {
      handler(newVal, oldVal) {
        if (JSON.stringify(oldVal) === JSON.stringify(newVal)) {
          return;
        }

        const cp = copy(newVal);
        this.emitUpdate(cp);
      },
      deep: true,
    },
    value: {
      handler() {
        this.updateValues();
      },
      deep: true,
    },
  },
  created() {
    this.updateValues();
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .status-scope {
    display: flex;

    ._op {
      display: block;
      margin-right: .4rem;
    }

    ._select {
      min-width: 15rem;
    }
  }
</style>
