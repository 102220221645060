<template>
  <div class="schedules-table">
    <m-table
      :columns="columns"
      :data-source="schedules"
      class="_schedules-table"
      row-class-name="schedules-row"
      row-key="uid"
      :custom-row="handleRowClick"
      :show-new-button="showNewButton"
      :new-button-loading="createLoading"
      @new="create"
    >
      <template #participationHeader>
        <div

          class="_name-header"
        >
          <m-tooltip>
            {{ $t('schedulesTable.participation') }}
            <template #title>
              {{ $t('schedulesTable.participationHint') }}
            </template>
          </m-tooltip>
        </div>
      </template>
      <template #name="{ name, row }">
        <div

          class="_name"
        >
          <div class="_text">
            <item-title
              :title="name"
              :icons="[{ value: buildIconFromEntity(row) }]"
            />
          </div>
          <div class="_status">
            <m-tag
              :color="getStatusType(row)"
              class="_tag"
              :title="getStatus(row)"
            />
          </div>
          <div
            v-if="[write, full].includes(row.accessRight)"
            :class="['_actions', edit === row.uid ? '-show' : '']"
          >
            <m-dropdown
              :value="edit === row.uid"
              :title="$t('general.actions')"
              @hide="edit = null"
            >
              <m-btn
                hide-border
                small
                light
                fab
                icon="ellipsis"
                @click.stop="edit = row.uid"
              />
              <template #overlay>
                <m-card

                  :key="row.uid"
                  list
                  no-padding
                >
                  <m-card-item
                    icon="edit"
                    @click.stop="handleEdit(row)"
                  >
                    {{ $t('general.edit') }}
                  </m-card-item>
                  <m-card-item
                    v-if="row.activatedAt !== null"
                    icon="pause"
                    :loading="updateLoading"
                    @click.stop="pause(row)"
                  >
                    {{ $t('notificationEditorCard.pause') }}
                  </m-card-item>
                  <m-card-item
                    v-else
                    icon="caret-right"
                    :loading="updateLoading"
                    @click.stop="activate(row)"
                  >
                    {{ $t('notificationEditorCard.activate') }}
                  </m-card-item>
                  <m-card-item
                    icon="delete"
                    :loading="deleteLoading"
                    @click.stop="deleteItem(row)"
                  >
                    {{ $t('general.delete') }}
                  </m-card-item>
                </m-card>
              </template>
            </m-dropdown>
          </div>
        </div>
      </template>
      <template #schedule="{ schedule }">
        <div

          class="_schedule"
        >
          {{ getRRuleText(schedule) }}
        </div>
      </template>
      <template #template="{ template }">
        <div
          class="_template"
        >
          <template v-if="template === null">
            -
          </template>
          <item-title
            v-else
            :title="template.title"
            :icons="[{ value: buildIconFromEntity(template) }]"
          />
        </div>
      </template>
      <template #participation="{ participation, row }">
        <div
          class="_participation"
        >
          <m-dropdown
            :title="$t('schedulesTable.recipientsList')"
            :value="showRecipientList === row.uid"
            :disabled="participation[0].numberOfRecipients === 0"
            @hide="showRecipientList = null"
          >
            <m-btn
              class="_trigger"
              hide-border
              small
              :disabled="participation[0].numberOfRecipients === 0"
              @click.stop="showRecipientList = row.uid"
            >
              <span class="_total-amount">{{ amount(participation) }}</span> <span
                class="_percentage"
              > ({{ percentage(participation) }})</span>
            </m-btn>
            <template #overlay>
              <m-card

                list
                no-padding
                class="_overlay"
              >
                <participation-list :participation="participation[0]" />
              </m-card>
            </template>
          </m-dropdown>
        </div>
      </template>
    </m-table>
    <m-dialog
      :value="showDetailPage"
      :max-width="$modalSizes.xl"
      hide-footer
      no-padding
      keep-height
      hide-header
      top="7rem"
      @close="closeDetailPage"
    >
      <schedule-detail-page
        :key="selectedNotificationId"
        :schedule-id="selectedNotificationId"
        modal
      />
    </m-dialog>
  </div>
</template>

<script>
import ItemTitle from '@/components/ItemTitle.vue';
import ParticipationList from '@/components/update-schedules/ParticipationList.vue';
import ScheduleDetailPage from '@/components/update-schedules/ScheduleDetailPage.vue';
import useAccess from '@/composables/access/access';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useUpdateSchedules from '@/composables/update-schedules/update-schedules';
import useUpdateSchedulesPromptOnUnsaved from '@/composables/update-schedules/prompt-on-unsaved';
import { accessGroupFlag, accessPolicyType, customFunc, routeName } from 'shared/constants.json';
import { buildIconFromEntity } from 'shared/lib/icon';
import { copy } from 'shared/lib/copy';
import { findInArray } from 'shared/lib/array/array';
import { getStatus, getStatusType } from '@/lib/notification/notification';
import { rruleToText } from '@/lib/rrule-translations/translations';
import { rrulestr } from 'rrule';

export default {
  name: 'SchedulesTable',
  props: {
    schedules: {
      type: Array,
      required: true,
    },
    showNewButton: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ItemTitle,
    ScheduleDetailPage,
    ParticipationList,
  },
  setup() {
    const { userHasRight } = useAccess();
    const { createLoading, createSchedule, deleteSchedule, deleteLoading, updateLoading, activate, pause, selectSingle } = useUpdateSchedules();
    const { saveOrDiscard } = useUpdateSchedulesPromptOnUnsaved();
    const { userLang } = useLoggedInUser();

    return {
      userLang,
      createLoading,
      createSchedule,
      userHasRight,
      saveOrDiscard,
      deleteSchedule,
      selectSingle,
      activate,
      pause,
      deleteLoading,
      updateLoading,
    };
  },
  data() {
    return {
      scheduleSnapShot: null,
      showCreate: false,
      showEdit: false,
      selectedNotification: null,
      edit: null,
      full: accessPolicyType.full,
      write: accessPolicyType.write,
      showRecipientList: null,
    };
  },
  computed: {
    canCreate() {
      return this.userHasRight([accessGroupFlag.updateAdminAccess]);
    },
    selectedNotificationId() {
      return parseInt(this.$route.query.notificationId, 10);
    },
    showDetailPage() {
      return typeof this.$route.query.notificationId !== 'undefined';
    },
    columns() {
      return [
        {
          key: 'title',
          dataIndex: 'title',
          title: this.$t('schedulesTable.name'),
          width: '30%',
          scopedSlots: { customRender: 'name' },
        },
        {
          key: 'schedule',
          dataIndex: 'schedule',
          title: this.$t('schedulesTable.schedule'),
          width: '25%',
          scopedSlots: { customRender: 'schedule' },
        },
        {
          key: 'template',
          dataIndex: 'updateTemplate',
          title: this.$t('schedulesTable.template'),
          width: '25%',
          scopedSlots: { customRender: 'template' },
        },
        {
          key: 'participation',
          dataIndex: customFunc.updateParticipationList,
          width: '20%',
          scopedSlots: { customRender: 'participation', title: 'participationHeader' },
        },
      ];
    },
  },
  methods: {
    buildIconFromEntity,
    deleteItem(notification) {
      const deleteMethod = () => this.deleteSchedule(notification).then(() => {
        this.$showSnackbar({ color: 'success', message: this.$t('success.deleted') });
      }).catch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      });

      this.$confirm({
        title: this.$t('notificationEditorCard.deletePrompt'),
        okText: this.$t('general.yesDelete'),
        okType: 'danger',
        maskClosable: true,
        cancelText: this.$t('general.cancel'),
        onOk() {
          deleteMethod();
        },
      });
    },
    amount(participation) {
      if (participation[0].numberOfRecipients === 0) {
        return '0 / 0';
      }
      return `${participation[0].numberOfParticipants} / ${participation[0].numberOfRecipients}`;
    },
    percentage(participation) {
      if (participation[0].numberOfRecipients === 0) {
        return '0%';
      }
      return `${Math.round((participation[0].numberOfParticipants / participation[0].numberOfRecipients) * 100)}%`;
    },
    create() {
      this.createSchedule()
        .then((schedule) => {
          this.$router.push({ query: { notificationId: schedule.uid } });
        })
        .catch(() => {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
        });
    },
    closeDetailPage() {
      const next = () => {
        const query = { ...this.$route.query };
        delete query.notificationId;
        this.$router.push({ query });
      };

      const notification = this.selectSingle(this.selectedNotificationId);
      if (notification === undefined) {
        next();
        return;
      }
      this.saveOrDiscard(next, notification, this.scheduleSnapShot);
    },
    handleRowClick(notification) {
      const { $store, $router } = this;
      return {
        on: {
          click: () => {
            if ($store.state.breakpoint.smAndDown) {
              $router.push({ name: routeName.updateScheduleDetails, params: { notificationId: notification.uid } });
              return;
            }

            $router.push({ query: { notificationId: notification.uid } });
          },
        },
      };
    },
    handleActivated(notification) {
      this.selectedNotification = notification;
    },
    closeEditModal() {
      this.showEdit = false;
      this.selectedNotification = null;
    },
    handleEdit(notification) {
      this.$router.push({ query: { notificationId: notification.uid } });
    },
    getRRuleText(s) {
      const rule = rrulestr(s);
      return rruleToText(rule, this.userLang);
    },
    getStatusType({ activatedAt, cancelledAt }) {
      return getStatusType({ activatedAt, cancelledAt, $t: (key) => this.$t(key) });
    },
    getStatus({ activatedAt, cancelledAt }) {
      return getStatus({ activatedAt, cancelledAt, $t: (key) => this.$t(key) });
    },
  },
  watch: {
    showDetailPage(val) {
      if (!val) {
        this.scheduleSnapShot = null;
        return;
      }

      this.scheduleSnapShot = copy(findInArray({
        haystack: this.schedules,
        needle: this.selectedNotificationId,
      }));
    },
  },
  created() {
    if (Number.isNaN(this.selectedNotificationId)) {
      return;
    }
    this.scheduleSnapShot = copy(findInArray({
      haystack: this.schedules,
      needle: this.selectedNotificationId,
    }));
  },
};
</script>

<style
  scoped
  lang="scss"
  type="text/scss"
>
  .schedules-table {
    overflow: unset;

    ._name-header {
      cursor: help;
    }
  }

  ._name {
    position: relative;
    display: flex;
    align-items: center;
    padding: .6rem 1.2rem .6rem 7.2rem;
    margin-left: -6rem;

    ._text {
      display: flex;
      align-items: center;
      margin-right: 1.2rem;
      white-space: nowrap;

      ._title-emoji {
        margin-right: .4rem;
      }
    }

    ._status {
      margin-left: auto;
    }
  }

  ._schedule {
    padding: .6rem 1.2rem;
    white-space: nowrap;
  }

  ._template {
    padding: 1rem 1.2rem;
  }

  ._participation {
    padding: .6rem 1.2rem;

    ._percentage {
      margin-left: .4rem;
      color: $font-color-tertiary;
    }
  }

  ._overlay {
    max-height: 50rem;
    overflow: auto;

    @media (max-width: $screen-size-sm) {
      max-height: 100vh;
    }
  }
</style>
