<template>
  <div class="form-header">
    <div class="_lang-option">
      <m-select
        :items="languages"
        :value="lang"
        @change="change"
      />
    </div>
  </div>
</template>

<script>
import ISO6391 from 'shared/lib/languages';

export default {
  name: 'FormHeader',
  props: {
    lang: {
      type: String,
      required: true,
    },
    langOptions: {
      type: Array,
      required: true,
    },
  },
  emits: ['change-language'],
  computed: {
    languages() {
      return this.langOptions.map((lang) => ({
        text: ISO6391.getName(lang),
        value: lang,
      }));
    },
  },
  methods: {
    change(value) {
      this.$emit('change-language', { lang: value });
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .form-header {
    display: flex;
    justify-content: flex-end;
    padding: 2.4rem;
    background-color: inherit;
  }
</style>
