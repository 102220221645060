import { DEFAULT_ATTRIBUTES } from 'shared/api/query/constants';
import { field, formFieldValidation, opinionScaleProperties } from 'shared/api/query/configs.json';

export const children = [
  ...DEFAULT_ATTRIBUTES,
  { attr: field.edges.title },
  { attr: field.edges.type },
  { attr: field.edges.allowComment },
  {
    attr: field.edges.opinionScaleProperties,
    model: opinionScaleProperties.model,
    children: [
      ...DEFAULT_ATTRIBUTES,
      { attr: opinionScaleProperties.edges.steps },
      { attr: opinionScaleProperties.edges.startsFromZero },
      { attr: opinionScaleProperties.edges.labelLeft, default: { en: '' } },
      { attr: opinionScaleProperties.edges.labelCenter, default: { en: '' } },
      { attr: opinionScaleProperties.edges.labelRight, default: { en: '' } },
    ],
  },
  {
    attr: field.edges.validations,
    model: formFieldValidation.model,
    children: [
      ...DEFAULT_ATTRIBUTES,
      { attr: formFieldValidation.edges.required },
    ],
  },
];
