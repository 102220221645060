<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.75072 3.98438H5.15697C5.26009 3.98438 5.34447 4.06875 5.34447 4.17188V16.3125H16.7351V14.6016C16.7351 14.4445 16.9179 14.3555 17.0398 14.4539L20.3656 17.0789C20.388 17.0964 20.4061 17.1189 20.4186 17.1444C20.431 17.17 20.4375 17.1981 20.4375 17.2266C20.4375 17.255 20.431 17.2831 20.4186 17.3087C20.4061 17.3343 20.388 17.3567 20.3656 17.3742L17.0398 19.9992C16.9156 20.0977 16.7351 20.0086 16.7351 19.8516V18.0938H5.06322C4.23587 18.0938 3.56322 17.4211 3.56322 16.5938V4.17188C3.56322 4.06875 3.64759 3.98438 3.75072 3.98438Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default { name: 'Parent' };
</script>

<style scoped lang="scss" type="text/scss">

</style>
