import { DEFAULT_ATTRIBUTES, UID } from 'shared/api/query/constants';
import { reaction, update, user } from 'shared/api/query/configs.json';

export const reactionEdges = [
  ...DEFAULT_ATTRIBUTES,
  { attr: reaction.edges.emoji },
  {
    attr: reaction.edges.creator,
    model: user.model,
    children: [{ attr: UID }],
    default: null,
  },
  {
    attr: reaction.edges.update,
    model: update.model,
    children: [{ attr: UID }],
  },
];
