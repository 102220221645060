<template>
  <m-dialog
    v-model:value="showUpgradeModal"
    hide-footer
    center
    max-width="35rem"
  >
    <upgrade-form
      @close="showUpgradeModal = false"
    />
  </m-dialog>
</template>

<script>
import UpgradeForm from '@/components/plans/UpgradeForm.vue';
import useAccountSettings from '@/composables/logged-in-user-account/account-settings';
import { EVENTS } from '@/lib/constants';
import { EventBus } from '@/lib/event-bus';

export default {
  name: 'SalesFormDialog',
  components: { UpgradeForm },
  setup() {
    const { accountSettings } = useAccountSettings();
    return { plan: accountSettings.planId };
  },
  data() {
    return { showUpgradeModal: false };
  },
  methods: {
    show() {
      this.showUpgradeModal = true;
      EventBus.$emit(EVENTS.UPSELL.CONTACT_SALES_VIEWED, { currentPlan: this.plan });
    },
  },
  created() {
    EventBus.$on('show-sales-form', this.show);
  },
  beforeUnmount() {
    EventBus.$off('show-sales-form', this.show);
  },
};
</script>

<style scoped lang="scss" type="text/scss">

</style>
