import { copy } from 'shared/lib/copy';
import { propertyApplication, propertyType, userScopeType } from 'shared/constants.json';

const filterFunc = () => (current) => current.children.reduce((acc, c) => {
  if (c.scope.property !== undefined
    && (c.scope.property.usedFor.includes(propertyApplication.user) || c.scope.users.length > 0 || c.scope.property.type === propertyType.lookup)
  ) {
    return true;
  }

  return acc;
}, false);

const toStaticUsers = (current) => ({
  ...current,
  children: current.children.map((c) => {
    if (c.scope.property.type === propertyType.lookup) {
      return {
        ...c,
        scope: {
          ...c.scope,
          property: c.scope.property.lookupProperty,
          type: userScopeType.property,
          users: c.scope.users,
          spaces: c.scope.spaces,
          selectedOptions: c.scope.selectedOptions,
        },
      };
    }

    if (c.scope.users === undefined || c.scope.users.length === 0) {
      return c;
    }

    return {
      ...c,
      scope: {
        ...c.scope,
        users: [],
        property: null,
        staticUsers: c.scope.users,
        type: userScopeType.staticUsers,
      },
    };
  }),
});

export const transformToUserFilter = ({ filter }) => {
  if (filter === null) {
    return null;
  }

  const res = copy(filter);
  res.children = filter.children.filter(filterFunc()).map(toStaticUsers);

  return res;
};
