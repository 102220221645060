import { USER_NAME } from '@/lib/props/custom-types';
import { propertyType } from 'shared/constants.json';
import { user } from 'shared/api/query/configs.json';

export const directProperties = ($t) => ([
  {
    label: $t('props.user.name'),
    type: USER_NAME,
    edgeName: 'component_name',
    noFilter: true,
    isTitle: true,
    hideInProps: true,
    component: USER_NAME,
    showByDefault: true,
  },
  {
    label: $t('props.user.firstName'),
    type: propertyType.text,
    edgeName: user.edges.firstName,
    noFilter: true,
    hideInProps: true,
    showByDefault: false,
  },
  {
    label: $t('props.user.lastName'),
    type: propertyType.text,
    edgeName: user.edges.lastName,
    noFilter: true,
    hideInProps: true,
    showByDefault: false,
  },
  {
    label: $t('props.user.email'),
    type: propertyType.text,
    edgeName: user.edges.email,
    noFilter: true,
  },
]);
