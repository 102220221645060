import {
  DIRECT_PROPERTY_CYCLE_KEY,
  DIRECT_PROPERTY_DESCRIPTION_KEY,
  DIRECT_PROPERTY_PARENT_KEY,
  DIRECT_PROPERTY_PROGRESS_KEY,
} from '@/lib/constants';
import { goal as goalConfig } from 'shared/api/query/configs.json';
import { isZero } from '@/lib/editor/editor';
import { propertyType, propertyVisibility } from 'shared/constants.json';

export const isEmpty = (p, value) => {
  switch (p.type) {
    case propertyType.date:
      return value.timestamp === null;
    case propertyType.space:
      return value.spaces.length === 0;
    case propertyType.status:
    case propertyType.options:
    case propertyType.singleSelect:
      return value.selectedOptions.length === 0;
    case propertyType.number:
      return value.number === null;
    case propertyType.text:
    case propertyType.url:
      return value.text === null || value.text === '';
    case propertyType.user:
      return value.users.length === 0;
    case propertyType.lookup:
      switch (p.lookupProperty.type) {
        case propertyType.user:
          return value.users.length === 0;
        case propertyType.space:
          return value.spaces.length === 0;
        case propertyType.options:
        case propertyType.singleSelect:
          return value.selectedOptions.length === 0;
        default:
          return true;
      }
    default:
      return true;
  }
};

export const directPropertyEmpty = ({ attr, goal }) => {
  switch (attr) {
    case goalConfig.edges.parents:
      return goal.parents.length === 0;
    case goalConfig.edges.goalCycle:
      return goal.goalCycle.length === 0;
    case goalConfig.edges.description:
      return goal.description === null || goal.description === undefined || isZero(goal.description);
    default:
      return false;
  }
};

export const isVisible = ({ property, goal }) => {
  if (property.goalVisibility === propertyVisibility.alwaysVisible) {
    return true;
  }

  if (property.goalVisibility === propertyVisibility.alwaysHidden) {
    return false;
  }

  if (property.isDirect) {
    switch (property.key) {
      case DIRECT_PROPERTY_PARENT_KEY:
        return goal.parents.length > 0;
      case DIRECT_PROPERTY_CYCLE_KEY:
        return goal.goalCycle.length > 0;
      case DIRECT_PROPERTY_DESCRIPTION_KEY:
        return goal.description !== null;
      case DIRECT_PROPERTY_PROGRESS_KEY:
      default:
        return false;
    }
  }

  return !isEmpty(property, goal.properties.find((p) => p.property.uid === property.uid));
};
