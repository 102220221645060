<template>
  <div>
    <div v-for="releaseNote in releaseNotes"
         :class="['release-note', unreadReleaseNotes.includes(releaseNote._id) ? '-unread' : '']">
      <div class="_dot"></div>
      <div class="_text">
        <div class="_date">{{ format(releaseNote.releaseDate) }}</div>
        <h2 class="_title">{{ releaseNote.title }}</h2>
        <div class="_tags">
          <m-tag v-for="tag in releaseNote.tags" class="_tag" :title="tag" color="light" :key="tag">
          </m-tag>
        </div>
        <div class="_content">
          <PortableText
              :value="releaseNote.content"
              :components="myPortableTextComponents"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {PortableText} from '@portabletext/vue';
import ImageComponent from "@/release-notes/ImageComponent.vue";
import VideoComponent from "@/release-notes/VideoComponent.vue";
import LinkComponent from "@/release-notes/LinkComponent.vue";

const props = defineProps({
  unreadReleaseNotes: {
    type: Array,
    required: true,
  },
  releaseNotes: {
    type: Array,
    required: true,
  },
  canEditGoalSettings: {
    type: Boolean,
    default: false,
  },
  treeViewLink: {
    type: String,
    default: null,
  },
});

const myPortableTextComponents = {
  types: {
    imageEmbed: ImageComponent,
    videoEmbed: VideoComponent,
  },
  marks: {link: LinkComponent},
};
const format = (date) => {
  const d = new Date(date);
  return d.toDateString();
};
</script>

<style lang="scss" scoped type="text/scss">
@import "shared/assets/scss/box-shadow";

.release-note {
  position: relative;
  margin-bottom: 8rem;

  &.-unread {
    ._dot {
      position: absolute;
      top: 3.8rem;
      left: -3rem;
      width: 1rem;
      height: 1rem;
      background-color: map_get($blue, 'base');
      border-radius: .5rem;
    }
  }

  h2 {
    margin: 0;
  }

  img {
    width: 100%;
    height: auto;
  }

  ._text {
    font-size: $font-size-6;

    ._date {
      font-size: $font-size-2;
      font-weight: $font-weight-semibold;
      color: $font-color-secondary;
      text-transform: uppercase;
      letter-spacing: .05rem;
    }

    ._title {
      padding-top: .4rem;
      font-size: $font-size-9;
      color: $font-color-primary;
    }

    ._content {
      color: $font-color-primary;

      p {
        font-size: $font-size-6;
      }
    }

    ._tags {
      display: flex;
      flex-flow: wrap;
      margin-bottom: 1.4rem;

      ._tag {
        margin-top: 1rem;
        margin-right: 1rem;
      }
    }
  }
}
</style>
