import { NOT } from '@/lib/filter/scope-tree';
import { TYPE, UID } from 'shared/api/query/constants';
import {
  goal as goalConfig,
  property as propertyConfig,
  propertyOption as propertyOptionConfig,
  propertyValue as propertyValueConfig,
  space as spaceConfig,
  user as userConfig,
} from 'shared/api/query/configs.json';
import { hasFilter } from 'shared/api/query/filter';
import { propertyType } from 'shared/constants.json';
import { reverseEdge } from 'shared/api/query/edges';

const userFilter = (childrenFn, varIdFn, isFilterMode, scope, index) => {
  const varName = varIdFn(index);

  if (scope.isEmpty === true) {
    const varBlocks = [
      {
        alias: 'var',
        uid: [scope.property.lookupProperty.uid],
        model: propertyConfig.model,
        func: { name: UID },
        children: [
          {
            attr: reverseEdge(propertyValueConfig.edges.property),
            model: propertyValueConfig.model,
            filter: hasFilter(propertyValueConfig.edges.users),
            children: [
              {
                attr: reverseEdge(userConfig.edges.values),
                model: userConfig.model,
                children: [
                  {
                    attr: reverseEdge(propertyValueConfig.edges.users),
                    model: propertyValueConfig.model,
                    children: [
                      {
                        attr: reverseEdge(goalConfig.edges.properties),
                        model: goalConfig.model,
                        children: [{ attr: UID, var: `excludedGoals_${index}` }],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        alias: 'var',
        model: goalConfig.model,
        func: { name: TYPE, args: [{ value: goalConfig.model }] },
        filter: {
          op: NOT,
          child: [{
            func: {
              name: UID,
              needsVar: [{ name: `excludedGoals_${index}`, typ: 1 }],
            },
          }],
        },
        children: [{
          attr: UID,
          var: varName,
        }],
      },
    ];
    const filterTree = {
      func: {
        name: UID,
        needsVar: [{ name: varName, typ: 1 }],
      },
    };
    return { filterTrees: [filterTree], varBlocks };
  }

  const userIds = scope.users.map((u) => u.uid);
  if (userIds.length === 0 && isFilterMode) {
    return { filterTrees: [], varBlocks: [] };
  }

  const lookupPropertyVarName = `lookupPropertyValues_${index}`;
  const lookupRelationVarName = `lookupRelationValues_${index}`;
  const varBlocks = [
    {
      alias: 'var',
      func: { name: UID },
      uid: [scope.property.lookupProperty.uid],
      model: propertyConfig.model,
      children: [
        {
          attr: reverseEdge(propertyValueConfig.edges.property),
          model: propertyValueConfig.model,
          children: [
            {
              attr: UID,
              var: lookupPropertyVarName,
            },
          ],
        },
      ],
    },
    {
      alias: 'var',
      func: { name: UID },
      uid: [scope.property.lookupRelation.uid],
      model: propertyConfig.model,
      children: [
        {
          attr: reverseEdge(propertyValueConfig.edges.property),
          model: propertyValueConfig.model,
          children: [
            {
              attr: UID,
              var: lookupRelationVarName,
            },
          ],
        },
      ],
    },
    {
      alias: 'var',
      uid: userIds,
      model: userConfig.model,
      func: { name: UID },
      children: [
        {
          attr: reverseEdge(propertyValueConfig.edges.users),
          model: propertyValueConfig.model,
          filter: { func: { name: UID, needsVar: [{ name: lookupPropertyVarName, typ: 1 }] } },
          children: [
            {
              attr: reverseEdge(userConfig.edges.values),
              model: userConfig.model,
              children: [
                {
                  attr: reverseEdge(propertyValueConfig.edges.users),
                  model: propertyValueConfig.model,
                  filter: { func: { name: UID, needsVar: [{ name: lookupRelationVarName, typ: 1 }] } },
                  children: [
                    {
                      attr: reverseEdge(goalConfig.edges.properties),
                      model: goalConfig.model,
                      children: [{
                        attr: UID,
                        var: varName,
                      }],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  const filterTree = {
    func: {
      name: UID,
      needsVar: [{ name: varName, typ: 1 }],
    },
  };
  return { filterTrees: [filterTree], varBlocks };
};

const optionFilter = (childrenFn, varIdFn, isFilterMode, scope, index) => {
  const varName = varIdFn(index);

  if (scope.isEmpty === true) {
    const varBlocks = [
      {
        alias: 'var',
        uid: [scope.property.lookupProperty.uid],
        model: propertyConfig.model,
        func: { name: UID },
        children: [
          {
            attr: reverseEdge(propertyValueConfig.edges.property),
            model: propertyValueConfig.model,
            filter: hasFilter(propertyValueConfig.edges.selectedOptions),
            children: [
              {
                attr: reverseEdge(userConfig.edges.values),
                model: userConfig.model,
                children: [
                  {
                    attr: reverseEdge(propertyValueConfig.edges.users),
                    model: propertyValueConfig.model,
                    children: [
                      {
                        attr: reverseEdge(goalConfig.edges.properties),
                        model: goalConfig.model,
                        children: [{ attr: UID, var: `excludedGoals_${index}` }],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        alias: 'var',
        model: goalConfig.model,
        func: { name: TYPE, args: [{ value: goalConfig.model }] },
        filter: {
          op: NOT,
          child: [{
            func: {
              name: UID,
              needsVar: [{ name: `excludedGoals_${index}`, typ: 1 }],
            },
          }],
        },
        children: [{
          attr: UID,
          var: varName,
        }],
      },
    ];
    const filterTree = {
      func: {
        name: UID,
        needsVar: [{ name: varName, typ: 1 }],
      },
    };
    return { filterTrees: [filterTree], varBlocks };
  }

  const optionIds = scope.selectedOptions.map((u) => u.uid);
  if (optionIds.length === 0 && isFilterMode) {
    return { filterTrees: [], varBlocks: [] };
  }

  const lookupRelationVarName = `lookupRelationValues_${index}`;
  const varBlocks = [
    {
      alias: 'var',
      func: { name: UID },
      uid: [scope.property.lookupRelation.uid],
      model: propertyConfig.model,
      children: [
        {
          attr: reverseEdge(propertyValueConfig.edges.property),
          model: propertyValueConfig.model,
          children: [
            {
              attr: UID,
              var: lookupRelationVarName,
            },
          ],
        },
      ],
    },
    {
      alias: 'var',
      uid: optionIds,
      model: propertyOptionConfig.model,
      func: { name: UID },
      children: [
        {
          attr: reverseEdge(propertyValueConfig.edges.selectedOptions),
          model: propertyValueConfig.model,
          children: [
            {
              attr: reverseEdge(userConfig.edges.values),
              model: userConfig.model,
              children: [
                {
                  attr: reverseEdge(propertyValueConfig.edges.users),
                  model: propertyValueConfig.model,
                  filter: { func: { name: UID, needsVar: [{ name: lookupRelationVarName, typ: 1 }] } },
                  children: [
                    {
                      attr: reverseEdge(goalConfig.edges.properties),
                      model: goalConfig.model,
                      children: [{
                        attr: UID,
                        var: varName,
                      }],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  const filterTree = {
    func: {
      name: UID,
      needsVar: [{ name: varName, typ: 1 }],
    },
  };
  return { filterTrees: [filterTree], varBlocks };
};

const spaceFilter = (childrenFn, varIdFn, isFilterMode, scope, index) => {
  const varName = varIdFn(index);

  if (scope.isEmpty === true) {
    const varBlocks = [
      {
        alias: 'var',
        uid: [scope.property.lookupProperty.uid],
        model: propertyConfig.model,
        func: { name: UID },
        children: [
          {
            attr: reverseEdge(propertyValueConfig.edges.property),
            model: propertyValueConfig.model,
            filter: hasFilter(propertyValueConfig.edges.spaces),
            children: [
              {
                attr: reverseEdge(userConfig.edges.values),
                model: userConfig.model,
                children: [
                  {
                    attr: reverseEdge(propertyValueConfig.edges.users),
                    model: propertyValueConfig.model,
                    children: [
                      {
                        attr: reverseEdge(goalConfig.edges.properties),
                        model: goalConfig.model,
                        children: [{ attr: UID, var: `excludedGoals_${index}` }],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        alias: 'var',
        model: goalConfig.model,
        func: { name: TYPE, args: [{ value: goalConfig.model }] },
        filter: {
          op: NOT,
          child: [{
            func: {
              name: UID,
              needsVar: [{ name: `excludedGoals_${index}`, typ: 1 }],
            },
          }],
        },
        children: [{
          attr: UID,
          var: varName,
        }],
      },
    ];
    const filterTree = {
      func: {
        name: UID,
        needsVar: [{ name: varName, typ: 1 }],
      },
    };
    return { filterTrees: [filterTree], varBlocks };
  }

  const spaceIds = scope.spaces.map((u) => u.uid);
  if (spaceIds.length === 0 && isFilterMode) {
    return { filterTrees: [], varBlocks: [] };
  }

  const lookupRelationVarName = `lookupRelationValues_${index}`;
  const varBlocks = [
    {
      alias: 'var',
      func: { name: UID },
      uid: [scope.property.lookupRelation.uid],
      model: propertyConfig.model,
      children: [
        {
          attr: reverseEdge(propertyValueConfig.edges.property),
          model: propertyValueConfig.model,
          children: [
            {
              attr: UID,
              var: lookupRelationVarName,
            },
          ],
        },
      ],
    },
    {
      alias: 'var',
      uid: spaceIds,
      model: spaceConfig.model,
      func: { name: UID },
      children: [
        {
          attr: reverseEdge(propertyValueConfig.edges.spaces),
          model: propertyValueConfig.model,
          children: [
            {
              attr: reverseEdge(userConfig.edges.values),
              model: userConfig.model,
              children: [
                {
                  attr: reverseEdge(propertyValueConfig.edges.users),
                  model: propertyValueConfig.model,
                  filter: { func: { name: UID, needsVar: [{ name: lookupRelationVarName, typ: 1 }] } },
                  children: [
                    {
                      attr: reverseEdge(goalConfig.edges.properties),
                      model: goalConfig.model,
                      children: [{
                        attr: UID,
                        var: varName,
                      }],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  const filterTree = {
    func: {
      name: UID,
      needsVar: [{ name: varName, typ: 1 }],
    },
  };
  return { filterTrees: [filterTree], varBlocks };
};

export const lookupFn = (childrenFn, varIdFn, isFilterMode = false) => (scope, index) => {
  switch (scope.property.lookupProperty.type) {
    case propertyType.user:
      return userFilter(childrenFn, varIdFn, isFilterMode, scope, index);
    case propertyType.space:
      return spaceFilter(childrenFn, varIdFn, isFilterMode, scope, index);
    case propertyType.options:
    case propertyType.singleSelect:
      return optionFilter(childrenFn, varIdFn, isFilterMode, scope, index);
    default:
      return { filterTrees: [], varBlocks: [] };
  }
};
