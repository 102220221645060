<template>
  <div class="empty">
    <img
      class="_icons"
      src="@/assets/img/empty-dashboard.svg"
    >
    <div class="_title">
      <template v-if="props.readOnly">
        {{ $t('gridPage.empty.readOnly') }}
      </template>
      <template v-else>
        {{ $t('gridPage.empty.title') }}
      </template>
    </div>
    <m-btn
      class="_learn-more"
      small
      light
      :href="$t('gridPage.empty.helpLink')"
      hide-border
      icon="question-circle"
      target="_blank"
    >
      {{ $t('gridPage.empty.learnMore') }}
    </m-btn>
    <add-row-btn
      v-if="!props.readOnly"
      :grid-page="gridPage"
    >
      <m-btn
        icon="plus"
        block
      >
        {{ $t('gridPage.addRowBtn.addRow') }}
      </m-btn>
    </add-row-btn>
  </div>
</template>

<script setup>
import AddRowBtn from '@/components/custom-grid/AddRowBtn.vue';

const props = defineProps({
  gridPage: {
    type: Object,
    required: true,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
});
</script>

<style
  scoped
  lang="scss"
  type="text/scss"
>
  .empty {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.6rem;

    ._icons {
      height: 167px;
    }

    ._title {
      font-weight: $font-weight-medium;
    }
  }

</style>
