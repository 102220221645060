<template>
  <div
    class="grid-page-loading-error"
  >
    <div class="_top">
      <m-icon
        type="warning"
        :color="$colors.red.base"
        :style="{ marginRight: '.6rem' }"
      />
      {{ $t('gridPage.error.loading') }}
    </div>
    <m-btn @click="emit('retry')">
      {{ $t('general.retry') }}
    </m-btn>
  </div>
</template>
<script setup>
const emit = defineEmits(['retry']);
</script>
<style scoped lang="scss">
.grid-page-loading-error {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ._top {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
  }
}
</style>
