import { propertyType } from 'shared/constants.json';

export const setAlignedItemsGroupedBy = (goalProperties) => (goal) => {
  if (goal.alignedItemsGroupedBy !== null && typeof goal.alignedItemsGroupedBy !== 'undefined') {
    return goal;
  }

  if (goal.parents.length > 0 && goal.parents[0].alignedItemsGroupedBy !== null) {
    goal.alignedItemsGroupedBy = goal.parents[0].alignedItemsGroupedBy;
    return goal;
  }

  let filtered = goalProperties.value.filter((prop) => prop.isGoalType);
  if (filtered.length > 0) {
    goal.alignedItemsGroupedBy = filtered[0];
    return goal;
  }

  filtered = goalProperties.value.filter((p) => [propertyType.options, propertyType.singleSelect, propertyType.space].includes(p.type));
  if (filtered.length > 0) {
    goal.alignedItemsGroupedBy = filtered[0];
    return goal;
  }

  return goal;
};
