import { doUploadFile } from '@/api';

export const uploadFile = (file, onUploadProgress) => {
  const rejectError = new Error(`uploadFile: bad input ${typeof file}`);

  if (file instanceof Blob && file.size === 0) {
    return new Promise((resolve, reject) => { reject(rejectError); });
  }

  if (typeof file === 'string' && file.length === 0) {
    return new Promise((resolve, reject) => { reject(rejectError); });
  }

  if (!(file instanceof Blob) && typeof file !== 'string') {
    return new Promise((resolve, reject) => { reject(rejectError); });
  }

  const formData = new FormData();
  formData.append('file', file);
  return doUploadFile(formData, onUploadProgress).then((response) => {
    if (response.status !== 200) {
      throw new Error('uploading image failed');
    }
    return response.data;
  });
};
