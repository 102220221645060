import { customFunc } from 'shared/constants.json';

const buildCustomFuncPred = ({
  customFuncName,
  alias = undefined,
  attr = undefined,
  uid = undefined,
  args = [],
}) => ({
  alias,
  attr,
  customFunc: {
    name: customFuncName,
    uid,
    args,
  },
});
export const buildCustomFuncProgressCoursePred = ({
  alias = undefined,
  attr = undefined,
  timeSeries = [],
} = { alias: undefined, attr: undefined, timeSeries: [] }) => buildCustomFuncPred({
  customFuncName: customFunc.progressCourse,
  alias,
  attr,
  args: timeSeries.map((t) => ({ value: t })),
});

export const buildCustomFuncUpdateParticipationListPred = ({
  alias = undefined,
  attr = undefined,
  timeSeries = [],
}) => buildCustomFuncPred({
  customFuncName: customFunc.updateParticipationList,
  alias,
  attr,
  args: timeSeries.map((t) => ({ value: t })),
});

export const buildCustomFuncProgressCourseListPred = ({
  alias = undefined,
  attr = undefined,
  timeSeries = [],
}) => buildCustomFuncPred({
  customFuncName: customFunc.progressCourseList,
  alias,
  attr,
  args: timeSeries.map((t) => ({ value: t })),
});

export const buildCustomFuncGoalInsightsPred = ({
  alias = undefined,
  attr = undefined,
  groupBy,
  timeSeries = [],
}) => buildCustomFuncPred({
  customFuncName: customFunc.goalInsights,
  alias,
  attr,
  uid: groupBy.uid,
  args: [{ value: groupBy.value }, ...timeSeries.map((t) => ({ value: t }))],
});
