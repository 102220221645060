<template>
  <div class="onboarding">
    <m-dialog
      v-model:value="showCompanyInfo"
      :max-width="$modalSizes.xl"
      no-padding
      keep-height
      hide-header
      top="7rem"
      hide-footer
      keep-open-on-mask-click
    >
      <company-info-editor
        :company-info="companyInfo"
        fullscreen
        padding-top
        disabled
        show-footer
        :ok-text="$t('form.continue')"
        :ok-handler="hideCompanyInfo"
      />
    </m-dialog>
    <template v-if="currentStep !== null">
      <div class="_content">
        <div class="_header">
          <div class="_title">
            <h1>
              {{ currentStep.title }}
            </h1>
          </div>
          <div class="_sub-title">
            {{ currentStep.subTitle }}
          </div>
        </div>
        <div class="_steps">
          <personal-infos
            v-if="currentStep.number === 1"
            :is-last-step="steps.length === currentStep.number"
          />
          <account-infos
            v-if="currentStep.number === 2"
            :show-full-screen-spinner="steps[steps.length - 1].number === currentStep.number"
            :is-last-step="steps.length === currentStep.number"
            @done="handleAccountInfosDone"
          />
          <redeem-coupon-code
            v-if="currentStep.number === 3"
            :is-last-step="steps.length === currentStep.number"
            @continue="handleCouponCodeRedeemed"
            @continue-without-coupon-code="handleContinueWithoutCouponCode"
          />
        </div>
        <footer
          style="padding-bottom: 42px;"
          class="_footer"
        >
          <div class="_inner">
            <div>
              <i18n-t keypath="onboarding.loggedInAs">
                <template #email>
                  <span style="font-weight: 500;">{{ loggedInUser.email }}</span>
                </template>
                <template #link>
                  <m-link
                    class="_link"
                    @click="EventBus.$emit('logout')"
                  >
                    {{ $t('onboarding.logInAsDifferentUser') }}
                  </m-link>
                </template>
              </i18n-t>
            </div>
          </div>
        </footer>
        <template v-if="!$store.state.breakpoint.smAndDown">
          <img
            class="_plant-left"
            src="@/assets/img/illustrations/plants-2.png"
          >
          <img
            class="_plant-right"
            src="@/assets/img/illustrations/plants-1.png"
          >
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import AccountInfos from '@/components/onboarding/AccountInfos.vue';
import CompanyInfoEditor from '@/components/CompanyInfoEditor.vue';
import PersonalInfos from '@/components/onboarding/PersonalInfos.vue';
import RedeemCouponCode from '@/components/onboarding/RedeemCouponCode.vue';
import useAccess from '@/composables/access/access';
import useCompanyInfo from '@/composables/logged-in-user-account/company-info';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useLogin from '@/composables/user/login';
import usePageVisits from '@/composables/page-visits';
import usePersonalAppSettings from '@/composables/logged-in-user/personal-app-settings';
import { EventBus } from '@/lib/event-bus';
import { logCatch } from '@/lib/logger/logger';
import { productPlan, routeName } from 'shared/constants.json';
import { showOnboarding } from '@/lib/onboarding/onboarding';

export default {
  name: 'Onboarding',
  components: { AccountInfos, PersonalInfos, RedeemCouponCode, CompanyInfoEditor },
  setup() {
    const pageVisitsService = usePageVisits();
    const { initializeUser } = useLogin(pageVisitsService);
    const { accountHasFeature } = useAccess();
    const { loggedInUser } = useLoggedInUser();
    const { personalAppSettings } = usePersonalAppSettings(loggedInUser);
    const { loggedInUserAccount } = useLoggedInUserAccount();
    const { companyInfo, showCompanyInfoInOnboarding } = useCompanyInfo();

    return {
      accountHasFeature,
      loggedInUser,
      personalAppSettings,
      loggedInUserAccount,
      companyInfo,
      showCompanyInfoInOnboarding,
      initializeUser,
    };
  },
  data() {
    return { loading: false, EventBus, showCompanyInfo: false };
  },
  computed: {
    currentStep() {
      if (this.loggedInUser.personalInfosCompleted === null) {
        return this.steps[0];
      }

      if (this.loggedInUserAccount.accountSettings.accountInfosCompleted === null) {
        return this.steps[1];
      }

      if (typeof this.$route.query.partnerId !== 'undefined') {
        return this.steps[2];
      }

      return null;
    },
    steps() {
      const steps = [
        {
          title: this.$t('onboarding.personalInfosTitle'),
          subTitle: this.$t('onboarding.personalInfosSubTitle'),
          number: 1,
        },
      ];
      if (typeof this.$route.query.partnerId !== 'undefined') {
        steps.push(...[
          {
            title: this.$t('onboarding.accountInfosTitle'),
            subTitle: this.$t('onboarding.accountInfosSubTitle'),
            number: 2,
          },
          {
            title: this.$t('onboarding.redeemCouponTitle'),
            subTitle: this.$t('onboarding.redeemCouponSubTitle'),
            number: 3,
          },
        ]);
        return steps;
      }
      if (this.loggedInUserAccount.accountSettings.accountInfosCompleted === null) {
        steps.push(
          {
            title: this.$t('onboarding.accountInfosTitle'),
            subTitle: this.$t('onboarding.accountInfosSubTitle'),
            number: 2,
          },
        );
      }
      return steps;
    },
  },
  methods: {
    hideCompanyInfo() {
      this.goToApp();
    },
    handleCouponCodeRedeemed() {
      this.goToApp();
    },
    handleContinueWithoutCouponCode() {
      this.goToApp();
    },
    handleAccountInfosDone() {
      if (typeof this.$route.query.partnerId !== 'undefined') {
        return;
      }
      this.goToApp();
    },
    goToApp() {
      if (this.showCompanyInfoInOnboarding && !this.showCompanyInfo) {
        this.showCompanyInfo = true;
        return;
      }

      this.initializeUser().then(() => {
        this.$router.push({ name: routeName.accountSpace });
      })
        .catch(logCatch(() => {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
        }));
    },
  },
  watch: {
    currentStep(val) {
      if (val === null) {
        this.goToApp();
      }
    },
  },
  created() {
    if (this.$route.query.partnerId && this.loggedInUserAccount.accountSettings.planId === productPlan.trial) {
      return;
    }

    if (!showOnboarding({ user: this.loggedInUser, account: this.loggedInUserAccount })) {
      this.$router.replace({ name: routeName.accountSpace });
    }
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .onboarding {
    overflow: hidden;

    ._content {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      height: 100vh;
      background-color: $side-nav-background-color;

      ._header {
        padding-top: 7rem;

        ._title {
          h1 {
            margin: 0 0 1.6rem;
            text-align: center;
          }
        }

        ._sub-title {
          font-size: $font-size-5;
          color: $font-color-secondary;
          text-align: center;
        }
      }

      ._plant-left {
        position: absolute;
        bottom: -4rem;
        left: 0;
      }

      ._plant-right {
        position: absolute;
        right: 0;
        bottom: -4rem;
      }
    }

    ._footer {
      width: 50rem;
      max-width: 90%;
      margin: 0 auto;

      ._inner {
        font-size: $font-size-3;
        color: $font-color-tertiary;
        text-align: center;

        ._link {
          color: inherit;
          text-decoration: underline;
        }
      }
    }
  }
</style>
