<template>
  <div class="add-goal-activity-btn">
    <goal-picker-v2
      v-model:value="goalsToAdd"
      :default-view="defaultView"
      :view-application="viewApplication"
      :disabled-condition="disabledCondition"
      :initial-selected-cycles="selectedCycles"
      :base-filter="baseFilter"
      multiple
      show-checkbox
      show-footer
      show-create-view
      show-views-selector
      block
      placement="bottomCenter"
      :ok-text="okText"
      @select="addSelected"
    >
      <template #trigger>
        <m-btn
          icon="plus"
          super-light
          block
          hide-border
          :button-style="{ justifyContent: 'flex-start', fontSize: $fontSizes[5], paddingLeft: '.6rem', marginBottom: '.2rem' }"
        >
          <span :style="{ marginLeft: '1rem' }">{{ $t('addGoalActivityBtn.newUpdate') }}</span>
        </m-btn>
      </template>
    </goal-picker-v2>
    <goal-activity-picker
      v-model:value="referencedGoalActivities"
      class="_activity-picker"
      :default-view="goalActivityDefaultView"
      :view-application="activityViewApplication"
      show-footer
      show-create-view
      show-views-selector
      match-trigger-width
      block
      placement="bottomCenter"
      :ok-text="okText"
      @select="addReferenced"
    >
      <template #trigger>
        <m-btn
          icon="link"
          super-light
          block
          hide-border
          :button-style="{ justifyContent: 'flex-start', fontSize: $fontSizes[5], paddingLeft: '.6rem' }"
        >
          <span :style="{ marginLeft: '1rem' }">{{ $t('addGoalActivityBtn.referenceUpdate') }}</span>
        </m-btn>
      </template>
    </goal-activity-picker>
  </div>
</template>

<script>
import GoalActivityPicker from '@/components/goal/GoalActivityPicker.vue';
import GoalPickerV2 from '@/components/goal/GoalPickerV2.vue';
import useGoals from '@/composables/goal/goals';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import usePersistedGoalCycle from '@/composables/goal-cycle/persisted-goal-cycle';
import useProperties from '@/composables/property/property';
import usePublishedAtFilter from '@/composables/goal/published-at-filter';
import { accessPolicyType, goalProgressMeasurement, viewApplication, viewType } from 'shared/constants.json';
import { creatorProperty } from '@/lib/updates/properties';
import { guid } from 'shared/lib/uuid';
import { personalGoalsFilter } from '@/lib/filter/goal/personal-goals-filter';

export default {
  name: 'AddGoalActivityBtn',
  components: {
    GoalPickerV2,
    GoalActivityPicker,
  },
  setup() {
    const { goalProperties } = useProperties();
    const { selectMultiple } = useGoals();
    const { publishedAtFilter } = usePublishedAtFilter();
    const { composedSelectedCycles } = usePersistedGoalCycle();
    const { loggedInUser } = useLoggedInUser();
    const { loggedInUserAccount } = useLoggedInUserAccount();
    return {
      selectedCycles: composedSelectedCycles,
      baseFilter: publishedAtFilter,
      selectMultiple,
      goalProperties,
      user: loggedInUser,
      account: loggedInUserAccount,
    };
  },
  data() {
    return {
      goalsToAdd: [],
      referencedGoalActivities: [],
      viewApplication: viewApplication.updateGoalPicker,
      activityViewApplication: viewApplication.goalActivityPicker,
    };
  },
  emits: ['add', 'add-reference'],
  computed: {
    initialFilter() {
      return personalGoalsFilter({
        goalProperties: this.goalProperties,
        user: this.user,
        account: { uid: this.account.uid },
      });
    },
    defaultView() {
      return {
        title: this.$t('list.cascade'),
        viewType: viewType.cascade,
        viewApplication: this.viewApplication,
        params: {
          filter: this.initialFilter,
          order: [],
          props: [],
          wrapCells: true,
        },
      };
    },
    initialGoalActivityFilter() {
      return {
        account: { uid: this.account.uid },
        op: 'or',
        children: [
          {
            key: guid(),
            op: 'and',
            children: [
              {
                op: 'and',
                scope: {
                  isEmpty: false,
                  directProperty: creatorProperty((key) => this.$t(key)),
                  users: [this.user],
                },
              },
            ],
          },
        ],
      };
    },
    goalActivityDefaultView() {
      return {
        title: this.$t('list.list'),
        viewType: viewType.list,
        viewApplication: this.activityViewApplication,
        params: {
          filter: this.initialGoalActivityFilter,
          order: [{ attr: 'createdAt', desc: true }],
          props: [],
        },
      };
    },
  },
  methods: {
    disabledCondition(goal) {
      return goal.progressMeasurement === goalProgressMeasurement.none
        || accessPolicyType.read === goal.accessRight;
    },
    okText(amount) {
      return this.$t('addGoalActivityBtn.add', { amount });
    },
    addSelected() {
      this.$emit('add', this.selectMultiple(this.goalsToAdd));
      this.goalsToAdd = [];
    },
    addReferenced() {
      this.$emit('add-reference', this.referencedGoalActivities);
      this.referencedGoalActivities = [];
      this.showActivityPicker = false;
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  ._activity-picker {
    min-width: 60rem;

    @media (max-width: 60rem) {
      width: 100vw;
      max-width: 100vw;
    }
  }
</style>
