import { UID } from 'shared/api/query/constants';
import { dataSource as dataSourceConfig, goal as goalConfig, salesforceQuery as salesforceQueryConfig } from 'shared/api/query/configs.json';

export const salesforceQueryChildren = [
  { attr: UID },
  { attr: salesforceQueryConfig.edges.reportId, default: null },
  { attr: salesforceQueryConfig.edges.reportName, default: null },
  { attr: salesforceQueryConfig.edges.reportFieldId, default: null },
  { attr: salesforceQueryConfig.edges.reportFieldName, default: null },
  { attr: salesforceQueryConfig.edges.error, default: null },
  { attr: salesforceQueryConfig.edges.alwaysSync, default: false },
  {
    attr: salesforceQueryConfig.edges.dataSource,
    model: dataSourceConfig.model,
    default: null,
    children: [{ attr: UID }],
  },
  {
    attr: salesforceQueryConfig.edges.goal,
    model: goalConfig.model,
    children: [{ attr: UID }],
  },
];
