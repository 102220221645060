import useRepo from '@/nebula/repo';
import { CREATED_AT, TYPE } from 'shared/api/query/constants';
import { feedEntryChildren } from '@/api/query/nebula/feed-entry';
import { feedEntry as feedEntryConfig } from 'shared/api/query/configs.json';
import { feedEntryType } from 'shared/constants.json';

export default function useFeedEntryRepo() {
  const repo = useRepo(feedEntryConfig.model);
  const getList = (query) => {
    if (query !== undefined) {
      return repo.getList(query);
    }
    return repo.query([
      {
        alias: 'tasks',
        func: { name: TYPE, args: [{ value: feedEntryConfig.model }] },
        filter: {
          func: {
            name: 'eq',
            attr: feedEntryConfig.edges.type,
            args: [
              { value: feedEntryType.survey },
              { value: feedEntryType.updateNotification },
              { value: feedEntryType.feedbackInquiry },
            ],
          },
        },
        model: feedEntryConfig.model,
        order: [{ attr: CREATED_AT, desc: true }],
        children: feedEntryChildren,
        pagination: { page: 1, itemsPerPage: 10, countAlias: 'taskCount' },
        default: [],
      },
      {
        func: { name: TYPE, args: [{ value: feedEntryConfig.model }] },
        alias: 'notifications',
        filter: {
          op: 'not',
          child: [
            {
              func: {
                name: 'eq',
                attr: feedEntryConfig.edges.type,
                args: [
                  { value: feedEntryType.survey },
                  { value: feedEntryType.updateNotification },
                  { value: feedEntryType.feedbackInquiry },
                ],
              },
            },
          ],
        },
        model: feedEntryConfig.model,
        order: [{ attr: CREATED_AT, desc: true }],
        children: feedEntryChildren,
        pagination: { page: 1, itemsPerPage: 10, countAlias: 'notificationsCount' },
        default: [],
      },
    ]);
  };

  return { ...repo, getList };
}
