import { propertyType } from 'shared/constants.json';

const getUid = (current, key) => {
  if (typeof current[key] === 'undefined') {
    return [];
  }

  return current[key].map((u) => ({ uid: u.uid }));
};

const getSelectedOptions = (value) => {
  if (value.property.type === propertyType.singleSelect) {
    if (typeof value.selectedOptions === 'undefined' || value.selectedOptions.length === 0) {
      return [];
    }
    return [{ uid: value.selectedOptions[0].uid }];
  }

  return getUid(value, 'selectedOptions');
};

const getScope = (acc, c) => {
  if (c.op === 'not') {
    return acc;
  }

  if (c.scope === null || typeof c.scope === 'undefined') {
    c.children.forEach((child) => getScope(acc, child));
    return acc;
  }

  return acc.push(c.scope);
};

export const getPropertyValuesFromFilter = (filter, defaultProperties, loggedInUser = { uid: 0 }, propertyValuesKey = 'properties') => {
  if (filter === null) {
    return { [propertyValuesKey]: defaultProperties };
  }

  const scopes = filter.children.reduce((acc, current) => getScope(acc, current), []);

  const reducer = (acc, current) => {
    if (typeof current.directProperty !== 'undefined') {
      if (typeof current.relation !== 'undefined' && current.relation !== null) {
        acc[current.directProperty.edgeName] = [current.relation];
        return acc;
      }

      if (current.selectedOptions.length > 0) {
        acc[current.directProperty.edgeName] = current.selectedOptions[0].value;
        return acc;
      }

      return acc;
    }

    if ([propertyType.number, propertyType.date].includes(current.property.type)) {
      return acc;
    }

    if (acc[propertyValuesKey].map((p) => p.property.uid).includes(current.property.uid)) {
      let index = -1;
      acc[propertyValuesKey].forEach((p, i) => {
        if (p.property.uid === current.property.uid) {
          index = i;
        }
      });

      acc[propertyValuesKey][index] = {
        property: { uid: current.property.uid },
        selectedOptions: getSelectedOptions(current),
        spaces: getUid(current, 'spaces'),
        users: getUid(current, 'users').filter((u) => u.uid === loggedInUser.uid),
      };
      return acc;
    }

    acc[propertyValuesKey].push({
      property: { uid: current.property.uid },
      selectedOptions: getSelectedOptions(current),
      spaces: getUid(current, 'spaces'),
      users: getUid(current, 'users'),
    });
    return acc;
  };

  return scopes.reduce(reducer, { [propertyValuesKey]: defaultProperties });
};
