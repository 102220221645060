<template>
  <sign-in-content class="set-password-form">
    <m-alert
      v-if="isExpired"
      class="_alert"
      type="error"
    >
      {{ $t('loginForm.expired') }}
    </m-alert>
    <div class="_hint">
      <p>
        <strong>{{ $t('setPasswordForm.tip') }}:</strong> {{ $t('setPasswordForm.hint') }}
      </p>
    </div>
    <div>
      <m-form-item class="_item">
        <m-input-password
          v-model:value="password"
          auto-focus
          :placeholder="$t('setPasswordForm.passwordLabel')"
          @keydown.enter="submit"
        />
      </m-form-item>
    </div>
    <div class="_action">
      <m-btn
        color="primary"
        :loading="loading"
        :disabled="buttonDisabled"
        block
        @click="submit"
      >
        {{ $t('setPasswordForm.buttonText') }}
      </m-btn>
    </div>
  </sign-in-content>
</template>

<script>
import SignInContent from '@/components/SignInContent.vue';
import jsonwebtoken from '@/lib/jwt';
import { DateTime } from 'luxon';
import { doSetPassword } from '@/api';
import { mapActions } from 'vuex';

export default {
  name: 'SetPasswordForm',
  components: { SignInContent },
  data() {
    return {
      valid: true,
      show: false,
      loading: false,
      password: '',
    };
  },
  computed: {
    buttonDisabled() {
      return this.isExpired;
    },
    isExpired() {
      if (typeof this.$route.query.s === 'undefined') {
        return true;
      }
      let decodedToken;
      try {
        decodedToken = jsonwebtoken.decode(this.$route.query.s);
      } catch (e) {
        return true;
      }
      if (typeof decodedToken.exp === 'undefined') {
        return false;
      }
      const validTill = DateTime.fromMillis(decodedToken.exp * 1000);
      if (!validTill.isValid) {
        return false;
      }
      const now = DateTime.local();
      return validTill < now;
    },
    token() {
      if (typeof this.$route.query.s === 'undefined') {
        return '';
      }
      return this.$route.query.s;
    },
  },
  methods: {
    ...mapActions(['resetSession']),
    goToLogin() {
      this.resetSession().then(() => this.$router.push({ path: '/' }));
    },
    submit() {
      this.loading = true;
      doSetPassword({ password: this.password })
        .then((response) => {
          if (response.status === 401) {
            this.$showSnackbar({ color: 'error', message: this.$t('setPasswordForm.noLongerValid') });
            return;
          }
          if (response.status !== 200) {
            this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
            return;
          }

          this.$showSnackbar({ color: 'success', message: this.$t('setPasswordForm.success') });
          setTimeout(this.goToLogin, 1000);
        });
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .set-password-form {
    width: 100%;

    ._alert {
      display: flex;
      margin-bottom: 2rem;
    }

    ._item {
      margin-bottom: 1rem;
    }
  }
</style>
