<template>
  <m-dropdown
    :value="showEditPropertyMenu"
    placement="topRight"
    :title="$t('general.actions')"
    block
  >
    <m-card-item
      :disabled="disabled"
      icon="unordered-list"
      :tooltip="tooltip"
      :style="{ width: '100%' }"
      @click="showEditPropertyMenu = true"
    >
      {{ $t('contextMenu.editProperty') }}
      <template #right>
        <m-icon

          type="chevron-right"
          :color="$colors.grey.lighten1"
        />
      </template>
    </m-card-item>
    <template #overlay>
      <m-card

        list
        no-padding
      >
        <m-card-item
          v-for="prop in properties"
          :key="prop.edgeName"
          :style="{ width: '100%' }"
          @click="$emit('edit-property', { prop })"
        >
          {{ textByLang(prop.label, userLang) }}
        </m-card-item>
      </m-card>
    </template>
  </m-dropdown>
</template>

<script>
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useProperties from '@/composables/property/property';
import { mapState } from 'vuex';
import { meeting as meetingConfig } from 'shared/api/query/configs.json';
import { propertyType } from 'shared/constants.json';
import { textByLang } from 'shared/lib/language';

export default {
  name: 'EditMeetingPropertyItem',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: '',
    },
  },
  setup() {
    const { meetingProperties } = useProperties();
    const { userLang } = useLoggedInUser();

    return { meetingProperties, userLang };
  },
  emits: ['edit-property'],
  data() {
    return {
      showEditPropertyMenu: false,
      textByLang,
      showForm: false,
    };
  },
  computed: {
    ...mapState({ meetings: (state) => state.meeting }),
    properties() {
      return [
        {
          label: { [this.userLang]: this.$t('meeting.participants') },
          edgeName: meetingConfig.edges.participants,
          type: propertyType.user,
        },
        {
          label: { [this.userLang]: this.$t('meeting.date') },
          edgeName: meetingConfig.edges.date,
          type: propertyType.date,
        },
        ...this.meetingProperties,
      ];
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  ._input-overlay {
    min-width: 30rem;
  }
</style>
