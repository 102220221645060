<template>
  <div
    class="saved-view-tab"
    :data-id="view.uid"
  >
    <div
      v-if="draggingOverLeft"
      class="_drag-over-left"
    />
    <m-dropdown
      v-model:value="showRightClickMenu"
      placement="bottomLeft"
      :title="$t('general.actions')"
      block
    >
      <m-btn
        hide-border
        :light="!active"
        small
        :class="['_selector', !active ? '-inactive' : '']"
        :style="dragging ? { pointerEvents: 'none' } : {}"
        @contextmenu="handleContextMenuClick"
        @click="($event) => selectView($event, view)"
      >
        <saved-view-title
          :view="view"
          :show-visibility-hint="showVisibilityHint"
        />
      </m-btn>

      <template
        v-if="showRightClickMenu"
        #overlay
      >
        <m-card
          class="_view-editor"
          no-padding
          list
        >
          <saved-view-editor
            :entity="view"
            :params="view.params"
            :goal-sort="view.goalSort"
            :allowed-view-types="allowedViewTypes"
            :view-application="viewApplication"
            :disable-delete="disableDelete"
            :show-visibility-switch="showVisibilitySwitch"
            :is-selected="active"
            @update="updateView"
            @delete="deleteView"
          />
        </m-card>
      </template>
    </m-dropdown>
    <div
      v-if="active"
      class="underline"
    />
    <div
      v-if="draggingOverRight"
      class="_drag-over-right"
    />
  </div>
</template>
<script setup>
import SavedViewEditor from '@/components/list/SavedViewEditor.vue';
import SavedViewTitle from '@/components/list/SavedViewTitle.vue';
import { ref, toRef, watch } from 'vue';

const props = defineProps({
  view: {
    type: Object,
    required: true,
  },
  allowedViewTypes: {
    type: Array,
    default: () => [],
  },
  viewApplication: {
    type: String,
    default: '',
  },
  active: {
    type: Boolean,
    default: false,
  },
  dragging: {
    type: Boolean,
    default: false,
  },
  showVisibilityHint: {
    type: Boolean,
    required: true,
  },
  showVisibilitySwitch: {
    type: Boolean,
    default: false,
  },
  hasUnsavedChanges: {
    type: Boolean,
    default: false,
  },
  disableDelete: {
    type: Boolean,
    default: false,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
  autoOpen: {
    type: Boolean,
    default: false,
  },
  draggingOverLeft: {
    type: Boolean,
    default: false,
  },
  draggingOverRight: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update', 'delete', 'select', 'close-editor']);
const showRightClickMenu = ref(false);

const updateView = ({ view }) => {
  emit('update', view);
};
const deleteView = ({ view }) => {
  emit('delete', { view });
};

const openEditor = () => {
  showRightClickMenu.value = true;
};

const selectView = (event, view) => {
  event.stopPropagation();
  if (props.active) {
    if (props.readOnly) {
      return;
    }
    openEditor();
    return;
  }
  emit('select', { view });
};

defineExpose({ openEditor });

const handleContextMenuClick = (event) => {
  event.preventDefault();
  if (props.readOnly) {
    return;
  }
  openEditor();
};

watch(showRightClickMenu, (newVal, oldVal) => {
  if (newVal === false && oldVal === true) {
    emit('close-editor');
  }
});

watch(toRef(props, 'autoOpen'), (newVal) => {
  if (newVal === true) {
    openEditor();
  }
}, { immediate: true });
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>

 .saved-view-tab {
   position: relative;
   border-radius: $input-field-border-radius;

   ._selector {
     &.-inactive {
       :deep(.m-emoji) {
         opacity: .6;
       }
     }
   }

  .underline {
   position: absolute;
   bottom: -0.7rem;
   left: 0;
   width: 100%;
   height: 2px;
   background-color: map_get($grey, 'darken-4');
  }

   ._drag-over-left {
    position: absolute;
    top: 0;
    right: 0;
    left: -2px;
    z-index: 88;
    width:4px;
    height: 100%;
    pointer-events: none;
    background: $highlighted-color-dark;
    opacity: 1;
  }

    ._drag-over-right {
    position: absolute;
    right:-2px;
    top: 0;
    z-index: 88;
    width: 4px;
    height: 100%;
    pointer-events: none;
    background: $highlighted-color-dark;
    opacity: 1;
  }
 }

</style>
