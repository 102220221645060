<template>
  <div
    :class="classes"
    :data-id="dataId"
  >
    <template v-if="!isBoxed">
      <slot />
    </template>
    <div
      v-else
      :class="['_boxed', boxedLarge ? '-large' : '', boxedSmall ? '-small': '', boxedXs ? '-xs' : '']"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'MContent',
  props: {
    paddingSmall: {
      type: Boolean,
      default: false,
    },
    padding: {
      type: Boolean,
      default: false,
    },
    paddingLg: {
      type: Boolean,
      default: false,
    },
    paddingXs: {
      type: Boolean,
      default: false,
    },
    paddingXxs: {
      type: Boolean,
      default: false,
    },
    paddingXxxs: {
      type: Boolean,
      default: false,
    },
    paddingX: {
      type: [Number, String],
      default: -1,
    },
    paddingY: {
      type: Number,
      default: -1,
    },
    paddingTop: {
      type: Number,
      default: -1,
    },
    paddingRight: {
      type: Number,
      default: -1,
    },
    paddingBottom: {
      type: Number,
      default: -1,
    },
    paddingLeft: {
      type: Number,
      default: -1,
    },
    boxed: {
      type: Boolean,
      default: false,
    },
    boxedSmall: {
      type: Boolean,
      default: false,
    },
    boxedXs: {
      type: Boolean,
      default: false,
    },
    boxedLarge: {
      type: Boolean,
      default: false,
    },
    fullHeight: {
      type: Boolean,
      default: false,
    },
    dataId: {
      type: [String, Number],
      default: () => null,
    },
  },
  computed: {
    isBoxed() {
      return this.boxed || this.boxedSmall || this.boxedLarge || this.boxedXs;
    },
    classes() {
      const classes = [
        'm-content',
      ];
      if (this.padding) {
        classes.push('-padding');
      }
      if (this.paddingLg) {
        classes.push('-padding-lg');
      }
      if (this.paddingSmall) {
        classes.push('-padding-small');
      }
      if (this.paddingXs) {
        classes.push('-padding-xs');
      }
      if (this.paddingXxs) {
        classes.push('-padding-xxs');
      }
      if (this.paddingXxxs) {
        classes.push('-padding-xxxs');
      }
      if (this.isBoxed) {
        classes.push('-boxed');
      }
      if (this.fullHeight) {
        classes.push('-full-height');
      }
      classes.push(`-padding-x-${this.paddingX}`);
      classes.push(`-padding-y-${this.paddingY}`);
      classes.push(`-padding-top-${this.paddingTop}`);
      classes.push(`-padding-right-${this.paddingRight}`);
      classes.push(`-padding-bottom-${this.paddingBottom}`);
      classes.push(`-padding-left-${this.paddingLeft}`);
      return classes;
    },
  },
};
</script>

<style lang="scss" type="text/scss">
  @import 'shared/assets/scss/padding';

  .m-content {
    &.-padding-xxxs {
      @include padding('xxxs');
    }

    &.-padding-xxs {
      @include padding('xxs');
    }

    &.-padding-xs {
      @include padding('xs');
    }

    &.-padding-small {
      @include padding('small');
    }

    &.-padding-lg {
      @include padding('lg');
    }

    &.-padding {
      @include padding;
    }

    @for $i from 0 through 15 {
      &.-padding-x-#{$i} {
        padding-right: map_get($padding, #{$i});
        padding-left: map_get($padding, #{$i});
      }

      &.-padding-y-#{$i} {
        padding-top: map_get($padding, #{$i});
        padding-bottom: map_get($padding, #{$i});
      }

      &.-padding-top-#{$i} {
        padding-top: map_get($padding, #{$i});
      }

      &.-padding-right-#{$i} {
        padding-right: map_get($padding, #{$i});
      }

      &.-padding-bottom-#{$i} {
        padding-bottom: map_get($padding, #{$i});
      }

      &.-padding-left-#{$i} {
        padding-left: map_get($padding, #{$i});
      }
    }

    &.-padding-x-layout {
      @include layoutPaddingX;
    }

    &.-padding-x-layout-detail {
      @include layoutDetailPageX;
    }

    &.-full-height{
      height: 100%;
    }

    &.-boxed {
      display: flex;
      justify-content: center;
      width: 100%;

      ._boxed {
        width: 100%;
        max-width: 1070px;

        &.-xs {
          max-width: 600px;
        }

        &.-small {
          max-width: 760px;
        }

        &.-large {
          max-width: 1200px;
        }
      }
    }
  }
</style>
