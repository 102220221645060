import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useRepo from '@/nebula/repo';
import useSpreadsheetDatasourceRepo from '@/composables/integrations/spreadsheet/spreadsheet-datasource-repo';
import { customDatasourceProperty } from 'shared/constants.json';
import { spreadsheetCell as spreadsheetCellConfig } from 'shared/api/query/configs.json';

export default function useSpreadsheet() {
  const queryRepo = useRepo(spreadsheetCellConfig.model);
  const datasourceRepo = useSpreadsheetDatasourceRepo();

  const { loggedInUser } = useLoggedInUser();

  const createQuery = (entity) => queryRepo.createSingle({
    ...entity,
    creator: { uid: loggedInUser.value.uid },
  });

  const getSpreadsheetDocument = (datasource, document) => datasourceRepo.getSingle({
    ...datasource,
    [customDatasourceProperty.spreadsheetDocuments]: [document],
  });

  return {
    createQueryLoading: queryRepo.createLoading,
    createQuery,
    updateQueryLoading: queryRepo.updateLoading,
    updateQuery: queryRepo.updateSingle,
    deleteQueryLoading: queryRepo.deleteLoading,
    deleteQuery: queryRepo.deleteSingle,
    getSpreadsheetDocumentLoading: datasourceRepo.getSingleLoading,
    getSpreadsheetDocument,
    navigateSpreadsheetFolderLoading: datasourceRepo.navigateFolderLoading,
    navigateSpreadsheetFolder: datasourceRepo.navigateFolder,
  };
}
