import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import { copy } from 'shared/lib/copy';

export default function useAddFormEditor() {
  const { loggedInUser } = useLoggedInUser();

  const appendEditor = (entity) => {
    const { editors } = copy(entity);
    if (editors.filter((editor) => editor.uid === loggedInUser.value.uid).length === 0) {
      editors.push(loggedInUser.value);
    }
    return {
      ...entity,
      editors,
    };
  };

  return { appendEditor };
}
