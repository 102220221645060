<template>
  <div class="must-be-empty-error">
    {{ $t('mustBeEmptyError.title') }}
  </div>
</template>

<script>
export default { name: 'MustBeEmptyError' };
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>

</style>
