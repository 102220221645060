<template>
  <div class="jira-summary">
    <div class="_item">
      <div class="_label">
        {{ $t('jiraSummary.operationMode') }}
      </div>
      <div class="_content">
        {{ $t(`jiraForm.operationModes.${camelCase(jiraQuery.operationMode)}.label`) }}
      </div>
    </div>
    <div
      v-for="el in jqlElements"
      :key="el.label"
      class="_item"
    >
      <div class="_label">
        {{ el.label }}
      </div>

      <m-link
        target="_blank"
        :href="el.link"
        inline
        inherit-color
        underline-on-hover
        :disabled="isPrivateDataSource"
      >
        <div class="_content">
          <div class="_text">
            {{ el.jql }}
          </div>
          <m-icon
            v-if="!isPrivateDataSource"
            type="external-link"
          />
        </div>
      </m-link>
    </div>
  </div>
</template>

<script>
import { camelCase } from 'lodash-es';
import { jiraOperationMode } from 'shared/constants.json';
import { jqlLink } from '@/lib/jira';

export default {
  name: 'JiraSummary',
  props: {
    jiraQuery: {
      type: Object,
      required: true,
    },
  },
  data() {
    return { camelCase, jiraOperationMode };
  },
  computed: {
    isPrivateDataSource() {
      return this.jiraQuery.dataSource === null;
    },
    jqlElements() {
      const jqlDefault = (jql) => {
        if (jql === '') {
          return this.$t('jiraSummary.empty');
        }

        return jql;
      };

      switch (this.jiraQuery.operationMode) {
        case jiraOperationMode.overallProgress:
          return [
            {
              jql: jqlDefault(this.jiraQuery.overallCountJql),
              link: jqlLink(this.jiraQuery, this.jiraQuery.overallCountJql),
              label: this.$t('jiraOverallProgress.countIssues'),
            },
            {
              jql: jqlDefault(this.jiraQuery.totalJql),
              link: jqlLink(this.jiraQuery, this.jiraQuery.totalJql),
              label: this.$t('jiraOverallProgress.totalIssues'),
            },
          ];
        case jiraOperationMode.countOfIssues:
          return [
            {
              jql: this.jiraQuery.countJql,
              link: jqlLink(this.jiraQuery, this.jiraQuery.countJql),
              label: 'JQL',
            },
          ];
        default:
          return [];
      }
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .jira-summary {
    ._content {
      display: flex;

      ._text {
        margin: 0 .4rem 0 0;
      }
    }

    ._item {
      display: grid;
      grid-template-columns: 50% 50%;
      margin-bottom: .8rem;

      ._label {
        color: $font-color-secondary;
      }
    }
  }
</style>
