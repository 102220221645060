<template>
  <div class="lookup-value">
    <div
      v-if="isEmpty && !hidePlaceholder"
      class="_empty"
    >
      {{ $t('general.empty') }}
    </div>
    <div
      v-else
      class="_value"
    >
      <space-prop
        v-if="property.type === propertyType.space"
        :label="textByLang(lookupProperty.label, userLang)"
        :spaces="selectedSpaces"
        :m-style="mStyle"
        :wrap="wrap"
        :show-tooltip="showTooltip"
      />
      <option-prop
        v-if="[propertyType.options, propertyType.singleSelect].includes(property.type)"
        :label="textByLang(lookupProperty.label, userLang)"
        :options="selectedOptions"
        :m-style="mStyle"
        :wrap="wrap"
        :show-tooltip="showTooltip"
      />
      <user-prop
        v-else-if="property.type === propertyType.user"
        :users="selectedUsers"
        :m-style="mStyle"
        :label="textByLang(lookupProperty.label, userLang)"
        :show-tooltip="showTooltip"
        :show-user-name="showUserName"
        small
        :wrap="wrap"
      />
    </div>
  </div>
</template>

<script>
import OptionProp from '@/components/list/OptionProp.vue';
import SpaceProp from '@/components/list/SpaceProp.vue';
import UserProp from '@/components/list/UserProp.vue';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useLookupProperty from '@/composables/property/lookup-property/lookup-property';
import useProperties from '@/composables/property/property';
import { PROFILE } from '@/route-names';
import { mStyleProps } from 'shared/lib/m-style-props';
import { propertyType } from 'shared/constants.json';
import { textByLang } from 'shared/lib/language';
import { toRef } from 'vue';

export default {
  name: 'LookupValue',
  props: {
    ...mStyleProps,
    lookupProperty: {
      type: Object,
      required: true,
    },
    values: {
      type: Array,
      default: () => [],
    },
    hideBorder: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    showUserName: {
      type: Boolean,
      default: false,
    },
    showEmptyValue: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    hidePlaceholder: {
      type: Boolean,
      default: false,
    },
    showTooltip: {
      type: Boolean,
      default: false,
    },
    wrap: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { selectSingle } = useProperties();
    const { loggedInUserAccount } = useLoggedInUserAccount();
    const { selectedOptions, selectedSpaces, selectedUsers } = useLookupProperty(toRef(props, 'values'), toRef(props, 'lookupProperty'));
    return { account: loggedInUserAccount, selectedUsers, selectedOptions, selectedSpaces, selectSingle };
  },
  components: { SpaceProp, UserProp, OptionProp },
  data() {
    return {
      propertyType,
      PROFILE,
      textByLang,
    };
  },
  computed: {
    isEmpty() {
      switch (this.property.type) {
        case propertyType.user:
          return this.selectedUsers.length === 0;
        case propertyType.space:
          return this.selectedSpaces.length === 0;
        case propertyType.options:
        case propertyType.singleSelect:
          return this.selectedOptions.length === 0;
        default:
          return true;
      }
    },
    property() {
      return this.selectSingle(this.lookupProperty.lookupProperty.uid);
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .lookup-value {
    display: flex;
    align-items: center;
    min-height: inherit;
    padding: 0 0.6rem;

    ._empty {
      margin-left: 0.2rem;
      color: $font-color-tertiary;
    }
  }
</style>
