<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.2029 10.9055H18.3044C18.1966 10.9055 18.0935 10.9523 18.0208 11.0344L12.8904 16.9477V3.75C12.8904 3.64687 12.806 3.5625 12.7029 3.5625H11.2966C11.1935 3.5625 11.1091 3.64687 11.1091 3.75V16.9477L5.97866 11.0344C5.90835 10.9523 5.80522 10.9055 5.69507 10.9055H3.79663C3.63725 10.9055 3.55054 11.0953 3.656 11.2148L11.4349 20.1797C11.5052 20.2608 11.5922 20.3259 11.6898 20.3705C11.7875 20.415 11.8936 20.4381 12.0009 20.4381C12.1083 20.4381 12.2144 20.415 12.312 20.3705C12.4097 20.3259 12.4966 20.2608 12.5669 20.1797L20.3435 11.2148C20.449 11.093 20.3623 10.9055 20.2029 10.9055Z"
      fill="black"
    />
  </svg>
</template>
<script>
export default { name: 'ArrowDownThin' };
</script>
