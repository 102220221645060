<template>
  <m-dropdown
    v-model:value="show"
    class="m-date-picker"
    :title="$t('mDatePicker.title')"
    :placement="placement"
    :disabled="disabled || readOnly"
    :block="fullWidth"
    @hide="hideDropdown"
  >
    <slot
      v-if="!!$slots.trigger"
      name="trigger"
    />
    <m-focusable
      v-else
      :hide-border="hideBorder"
      :disabled="disabled"
      :read-only="readOnly"
      :small="small"
      :hide-hover="hideHover"
      :full-width="fullWidth"
      :placeholder-icon="placeholderIcon"
      :placeholder="placeholder"
      :m-style="mStyle"
      type="clickable"
      :show-placeholder="!hidePlaceholder && value === null"
      :class="[
        '_trigger-input',
        light ? '-light' : '',
      ]"
      :style="noPadding ? { padding: 0 } : {}"
      @click="show = true"
    >
      <div
        v-if="value !== null"
        :style="resolveStyles(mStyle)"
        :class="['_title']"
      >
        {{ formattedStartDate }}<span v-if="showTime">, {{ start.time }}</span>
        <template v-if="range">
          - {{ formattedEndDate }}<span v-if="showTime">, {{ end.time }}</span>
        </template>
      </div>
      <div
        v-if="clearable && !disabled && value !== null"
        class="_clear"
      >
        <m-btn
          xs
          fab
          icon="close-circle"
          hide-border
          light
          @click.stop="clear"
        />
      </div>
    </m-focusable>
    <template #overlay>
      <m-card

        no-padding
        class="_overlay"
        list
      >
        <component
          :is="$store.state.breakpoint.smAndDown ? 'div' : 'm-content'"
          padding-small
        >
          <component
            :is="$store.state.breakpoint.smAndDown ? 'm-card-item' : 'div'"
            :class="['_header', range ? '-range' : '', showTime ? '-show-time' : '']"
          >
            <div
              :class="['_input', range && key === 'start' ? '-focused' : '', invalidRangeClass]"
              @click="updateKey('start')"
            >
              <input
                ref="startDate"
                :value="formattedStartDate"
                :style="{ width: showTime ? '50%' : '100%' }"
                class="_date"
                @blur="setDate"
                @keydown.enter="setDate"
                @focus="updateKey('start')"
              >
              <input
                v-if="showTime"
                ref="startTime"
                :value="start.time"
                class="_time"
                @blur="setTimeEvent"
                @keydown.enter="setTimeEvent"
                @focus="updateKey('start')"
              >
            </div>
            <div
              v-if="range"
              :class="['_input', range && key === 'end' ? '-focused' : '', invalidRangeClass]"
              :style="{ marginTop: showTime ? '.8rem' : 0 }"
              @click="updateKey('end')"
            >
              <input
                ref="endDate"
                :value="formattedEndDate"
                :style="{ width: showTime ? '50%' : '100%' }"
                class="_date"
                @blur="setDate"
                @keydown.enter="setDate"
                @focus="updateKey('end')"
              >
              <input
                v-if="showTime"
                ref="endTime"
                :value="end.time"
                class="_time"
                @blur="setTimeEvent"
                @keydown.enter="setTimeEvent"
                @focus="updateKey('end')"
              >
            </div>
          </component>
          <div
            :class="['_calendar', $store.state.breakpoint.smAndDown ? '-mobile' : '']"
          >
            <div class="_caption">
              <div
                :style="{ marginLeft: '.8rem' }"
                class="_left"
              >
                {{ $t(`mDatePicker.month.${month}`) }} {{ year }}
              </div>
              <div class="_right">
                <m-btn
                  icon="left"
                  light
                  fab
                  small
                  hide-border
                  @click="previousMonth"
                />
                <m-btn
                  icon="right"
                  light
                  fab
                  small
                  hide-border
                  @click="nextMonth"
                />
              </div>
            </div>
            <div class="_inner">
              <div class="_week-days">
                <div
                  v-for="n in 7"
                  :key="n"
                  class="_day"
                >
                  {{ $t(`mDatePicker.weekdays.${n}`).substring(0, 2) }}
                </div>
              </div>
              <div class="_body">
                <div
                  v-for="(week, index) in weeks"
                  :key="index"
                  class="_week"
                >
                  <div
                    v-for="(day, i) in week"
                    :key="i"
                    class="_day"
                  >
                    <m-btn
                      :light="day.month !== month"
                      :hide-border="date !== day.toISODate()"
                      :color="day.toISODate() === date ? 'primary' : ''"
                      :button-style="buttonStyle(day)"
                      :disabled="isDisabledDate(day)"
                      :style="{ height: $store.state.breakpoint.smAndDown ? '4rem' : '3rem', flex: '1 1 auto' }"
                      small
                      fab
                      @click="setDay(day)"
                    >
                      <div
                        :class="['_day-btn-inner', today.toISODate() === day.toISODate() && date !== day.toISODate() ? '-today' : '']"
                      >
                        {{ day.day }}
                      </div>
                    </m-btn>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </component>
        <template v-if="showTimePicker">
          <m-divider xxs />
          <m-card-item @click="showTime = !showTime">
            <m-switch
              :label="$t('mDatePicker.timePicker')"
              :value="showTime"
              small
            />
          </m-card-item>
        </template>
        <template v-if="showTimeZonePicker">
          <m-divider xxs />
          <m-time-zone-picker
            :value="timeZone"
            block
            @input="setTimeZone"
          />
        </template>
        <template v-if="clearable">
          <m-divider xxs />
          <m-card-item
            v-if="clearable"
            icon="delete"
            @click="clear"
          >
            {{ $t('general.delete') }}
          </m-card-item>
        </template>
      </m-card>
    </template>
  </m-dropdown>
</template>

<script>
import MCardItem from 'shared/components/base/MCardItem.vue';
import MSwitch from 'shared/components/base/MSwitch.vue';
import MTimeZonePicker from 'shared/components/base/MTimeZonePicker.vue';
import { generateTime } from 'shared/lib/time';
import { mStyleProps, resolveStyles } from 'shared/lib/m-style-props';

export default {
  name: 'MDatePicker',
  props: {
    ...mStyleProps,
    value: {
      type: Object,
      default: () => ({
        startDate: this.dateTime.local().toISODate(),
        startTime: this.dateTime.local().toLocaleString(this.dateTime.TIME_24_SIMPLE),
        endDate: this.dateTime.local().toISODate(),
        endTime: this.dateTime.local().toLocaleString(this.dateTime.TIME_24_SIMPLE),
        timeZone: this.dateTime.local().zoneName,
      }),
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    hideHover: {
      type: Boolean,
      default: false,
    },
    range: {
      type: Boolean,
      default: false,
    },
    format: {
      type: String,
      default: '',
    },
    placement: {
      type: String,
      default: 'bottomCenter',
    },
    small: {
      type: Boolean,
      default: false,
    },
    closeOnClick: {
      type: Boolean,
      default: false,
    },
    dateTime: {
      type: Function,
      required: true,
    },
    min: {
      type: [String, Object],
      default: () => null,
    },
    max: {
      type: [String, Object],
      default: () => null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    autoFocus: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    startOfDay: {
      type: Boolean,
      default: false,
    },
    endOfDay: {
      type: Boolean,
      default: false,
    },
    hideBorder: {
      type: Boolean,
      default: false,
    },
    hidePlaceholder: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    placeholderIcon: {
      type: String,
      default: '',
    },
    includeTime: {
      type: Boolean,
      default: false,
    },
    includeTimeZone: {
      type: Boolean,
      default: false,
    },
    showTimeZonePicker: {
      type: Boolean,
      default: false,
    },
    showTimePicker: {
      type: Boolean,
      default: false,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    light: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input', 'update:value', 'change', 'close'],
  components: { MTimeZonePicker, MSwitch, MCardItem },
  data() {
    const now = this.dateTime.local();
    return {
      key: 'start',
      timeZone: now.zoneName,
      rangeSelectFirst: undefined,
      current: {
        month: now.month,
        year: now.year,
      },
      start: {
        hour: now.hour,
        minute: now.minute,
        month: now.month,
        year: now.year,
        date: now.toISODate(),
        time: now.toLocaleString(this.dateTime.TIME_24_SIMPLE),
      },
      end: {
        hour: now.hour,
        minute: now.minute,
        month: now.month,
        year: now.year,
        date: now.toISODate(),
        time: now.toLocaleString(this.dateTime.TIME_24_SIMPLE),
      },
      show: false,
      showTime: false,
    };
  },
  computed: {
    invalidRangeClass() {
      if (this.isValidRange === false) {
        return '-invalid-range';
      }
      return '';
    },
    isValidRange() {
      if (!this.range) {
        return true;
      }
      const startDate = this.dateTime.fromISO(this.start.date);
      const endDate = this.dateTime.fromISO(this.end.date);
      if (endDate.diff(startDate) < 0) {
        return false;
      }
      return true;
    },
    month() {
      return this.current.month;
    },
    year() {
      return this.current.year;
    },
    date() {
      return this[this.key].date;
    },
    formattedStartDate() {
      return this.formatDate(this.start.date, this.language);
    },
    formattedEndDate() {
      return this.formatDate(this.end.date, this.language);
    },
    language() {
      return this.dateTime.local().locale;
    },
    today() {
      return this.dateTime.local();
    },
    minimum() {
      if (this.min === null) {
        return null;
      }
      return this.dateTime.fromISO(this.min);
    },
    maximum() {
      if (this.max === null) {
        return null;
      }
      return this.dateTime.fromISO(this.max);
    },
    weeks() {
      let date = this.getStartDate(this.current.month, this.current.year);
      const res = [];

      for (let w = 0; w < 6; w++) {
        const week = [];
        for (let i = 0; i < 7; i++) {
          week.push(date);
          date = date.plus({ days: 1 });
        }

        res.push(week);
      }

      return res;
    },
  },
  methods: {
    resolveStyles,
    hideDropdown() {
      if (this.value === null) {
        this.$emit('close');
        return;
      }
      if (this.showTime && this.value !== null) {
        this.setTime(this.$refs.startTime.value, 'start');
        if (this.range) {
          this.setTime(this.$refs.endTime.value, 'end');
        }
      }
      this.$emit('close');
    },
    fromISO(date) {
      const d = date.split('-');
      if (d.length !== 3) {
        throw new Error('invalid date format');
      }

      return {
        year: parseInt(d[0], 10),
        month: parseInt(d[1], 10),
        day: parseInt(d[2], 10),
      };
    },
    addLeadingZero(n) {
      if (n < 10) {
        return `0${n}`;
      }

      return `${n}`;
    },
    formatDate(date, language) {
      const d = this.fromISO(date);
      switch (language) {
        case 'de':
          return `${this.addLeadingZero(d.day)}.${this.addLeadingZero(d.month)}.${d.year}`;
        default:
          return `${this.$t(`mDatePicker.monthShort.${d.month}`)} ${d.day}, ${d.year}`;
      }
    },
    buttonStyle(day) {
      const s = {};
      if (this.date === day.toISODate()) {
        s.backgroundColor = this.$colors.blue.base;
        s.color = 'white';
      }

      if (!this.range) {
        return s;
      }

      if (this.start.date === this.end.date) {
        if (day.toISODate() === this.start.date) {
          if (this.key === 'start') {
            s.backgroundColor = this.$colors.blue.base;
          } else {
            s.backgroundColor = this.$colors.blue.lighten2;
          }
        }
        return s;
      }

      if (day.toISODate() === this.start.date) {
        s.borderTopRightRadius = 0;
        s.borderBottomRightRadius = 0;
        if (this.key === 'start') {
          s.backgroundColor = this.$colors.blue.base;
        } else {
          s.backgroundColor = this.$colors.blue.lighten2;
        }
      }

      if (day.toISODate() === this.end.date) {
        s.borderTopLeftRadius = 0;
        s.borderBottomLeftRadius = 0;
        if (this.key === 'end') {
          s.backgroundColor = this.$colors.blue.base;
        } else {
          s.backgroundColor = this.$colors.blue.lighten2;
        }
      }

      if (this.isBetween(day)) {
        s.backgroundColor = this.$colors.blue.lighten3;
        s.borderRadius = 0;
      }

      return s;
    },
    isBetween(day) {
      const startDate = this.dateTime.fromISO(this.start.date);
      const endDate = this.dateTime.fromISO(this.end.date);
      return day.diff(startDate) > 0 && day.diff(endDate) < 0;
    },
    isDisabledDate(day) {
      if (this.minimum === null && this.maximum === null) {
        return false;
      }

      if (this.minimum !== null && this.minimum.startOf('day').diff(day) > 0) {
        return true;
      }

      if (this.maximum !== null && this.maximum.endOf('day').diff(day) < 0) {
        return true;
      }

      return false;
    },
    isDisabled(day) {
      if (this.minimum !== null && this.minimum.diff(day) >= 0) {
        return true;
      }
      return this.maximum !== null && this.maximum.diff(day) <= 0;
    },
    setTimeZone(tz) {
      this.timeZone = tz;
      this.select();
    },
    setDate(event) {
      const val = event.target.value;
      const formats = ['dd. MMM yyyy', 'd. MMM yyyy', 'dd.MM.yyyy', 'd.M.yyyy', 'd.M.yy', 'dd/MM/yyyy', 'd/M/yyyy', 'MMM dd yyyy', 'MMM d yyyy', 'MMM d, yyyy', 'MMM dd, yyyy', 'MMMM dd yyyy', 'MMMM d yyyy'];
      for (let i = 0; i < formats.length; i++) {
        const d = this.dateTime.fromFormat(val, formats[i]);
        if (d.invalid !== null) {
          continue;
        }
        if (this.isDisabled(d)) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.invalidDateFormat') });
          return;
        }
        this[this.key].date = d.toISODate();
        this.current.month = d.month;
        this.current.year = d.year;
        this.select();
        return;
      }

      this.$showSnackbar({ color: 'error', message: this.$t('error.invalidDateFormat') });
    },
    setTimeEvent(event) {
      this.setTime(event.target.value, this.key);
    },
    setTime(val, key) {
      let n = val.match(/\d/g);
      if (n !== null) {
        n = n.join('');
      } else {
        n = '';
      }

      let additional = 0;
      if (val.toLowerCase().indexOf('pm') > -1) {
        additional = 12;
      }

      switch (n.length) {
        case 0:
          this[key].hour = 12;
          this[key].minute = 0;
          break;
        case 1:
        case 2:
          this[key].hour = this.getHour(parseInt(n, 10) + additional);
          this[key].minute = 0;
          break;
        case 3:
          this[key].hour = this.getHour(parseInt(n.substring(0, 1), 10) + additional);
          this[key].minute = this.getMinute(parseInt(n.substring(1, 3), 10));
          break;
        default:
          this[key].hour = this.getHour(parseInt(n.substring(0, 2), 10) + additional);
          this[key].minute = this.getMinute(parseInt(n.substring(2, 4), 10));
      }

      const date = this.dateTime.fromISO(this[key].date);
      const d = this.dateTime.local(date.year, date.month, date.day, this[key].hour, this[key].minute).setZone(this.timeZone, { keepLocalTime: true });
      if (this.isDisabled(d)) {
        return;
      }
      this[key].time = d.toLocaleString(this.dateTime.TIME_24_SIMPLE);
      this.select();
    },
    getMinute(m) {
      return m % 60;
    },
    getHour(h) {
      return h % 24;
    },
    previousMonth() {
      if (this.current.month === 1) {
        this.current.month = 12;
        this.current.year -= 1;
        return;
      }

      this.current.month -= 1;
    },
    nextMonth() {
      if (this.current.month === 12) {
        this.current.month = 1;
        this.current.year += 1;
        return;
      }

      this.current.month += 1;
    },
    getStartDate(month, year) {
      const d = this.dateTime.local(year, month, 1).setZone(this.timeZone);
      const weekday = d.weekday;
      return d.minus({ days: weekday - 1 });
    },
    setup() {
      [
        { valueDateKey: 'startDate', valueTimeKey: 'startTime', internalKey: 'start' },
        { valueDateKey: 'endDate', valueTimeKey: 'endTime', internalKey: 'end' },
      ].forEach((key) => {
        if (!this.showTime) {
          this[key.internalKey].hour = 0;
          this[key.internalKey].minute = 0;
          this[key.internalKey].time = '00:00';
        }

        if (this.value === null || typeof this.value[key.valueDateKey] === 'undefined') {
          return;
        }

        if (this.value.timeZone !== null) {
          this.timeZone = this.value.timeZone;
        }

        if (this.value[key.valueDateKey] === null) {
          return;
        }

        this[key.internalKey].date = this.value[key.valueDateKey];
        const d = this.dateTime.fromISO(this.value[key.valueDateKey]);
        this[key.internalKey].month = d.month;
        this[key.internalKey].year = d.year;

        if (this.value[key.valueTimeKey] === null || typeof this.value[key.valueTimeKey] === 'undefined' || !this.showTime) {
          this[key.internalKey].hour = 0;
          this[key.internalKey].minute = 0;
          this[key.internalKey].time = '00:00';
          return;
        }

        this[key.internalKey].hour = parseInt(this.value[key.valueTimeKey].split(':')[0], 10);
        this[key.internalKey].minute = parseInt(this.value[key.valueTimeKey].split(':')[1], 10);

        this[key.internalKey].time = generateTime(this[key.internalKey].hour, this[key.internalKey].minute);
      });
    },
    output() {
      if (!this.showTime) {
        return {
          startDate: this.start.date,
          startTime: null,
          endDate: this.end.date,
          endTime: null,
          timeZone: this.timeZone,
        };
      }

      return {
        startDate: this.start.date,
        startTime: this.start.time,
        endDate: this.end.date,
        endTime: this.end.time,
        timeZone: this.timeZone,
      };
    },
    clear() {
      this.$emit('input', null);
      this.$emit('update:value', null);
      this.$emit('change', null);
      if (this.closeOnClick) {
        this.show = false;
      }
    },
    setDay(date) {
      const key = this.key;
      const setVal = () => {
        if (this.isDisabled(date)) {
          return;
        }

        if (!this.range) {
          this.start.date = date.toISODate();
          this.end.date = date.toISODate();
          this.updateKey('start');
          this.select();
          return;
        }

        if (this.rangeSelectFirst === undefined) {
          this.rangeSelectFirst = date;
          this.start.date = date.toISODate();
          this.end.date = date.toISODate();
          this.$refs.endDate.focus();
          return;
        }

        if (date.diff(this.rangeSelectFirst) > 0) {
          this.end.date = date.toISODate();
        } else {
          this.end.date = this.start.date;
          this.start.date = date.toISODate();
          this.$refs.startDate.focus();
        }
        this.rangeSelectFirst = undefined;
        this.select();
      };
      setVal();
      if (this.closeOnClick && (!this.range || (this.range && key === 'end'))) {
        this.show = false;
      }
    },
    select() {
      if (this.isValidRange === false) {
        this.$showSnackbar({ color: 'error', message: this.$t('error.invalidDateRange') });
        return;
      }
      const d = this.output();
      this.$emit('input', d);
      this.$emit('update:value', d);
      this.$emit('change', d);
    },
    updateKey(key) {
      this.key = key;
      const date = this.fromISO(this[this.key].date);
      this.current.month = date.month;
      this.current.year = date.year;
    },
    open() {
      this.show = true;
    },
  },
  watch: {
    value() {
      this.setup();
    },
  },
  created() {
    this.showTime = this.includeTime;
    this.setup();
    if (this.autoFocus) {
      this.show = true;
    }
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  .m-date-picker {
    ._trigger-input {
      display: flex;
      align-items: center;
      white-space: nowrap;
      cursor: pointer;

      &.-light {
        color: $font-color-secondary;
      }

      ._clear {
        display: none;
        margin-left: auto;
      }

      ._title {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 1.6rem;
        margin: .2rem .4rem;
        line-height: 1.6rem;
      }
    }
  }

  ._overlay {
    width: 26rem;

    ._header {
      background-color: white;

      ._input {
        display: flex;
        width: 100%;
        min-height: 2.8rem;
        padding: .2rem .6rem;
        background-color: map_get($grey, 'lighten-5');
        border-radius: $border-radius-sm;
        box-shadow: $border-color 0 0 0 1px inset;

        &.-focused {
          background-color: map_get($blue, 'lighten-4');
          box-shadow: $primary-color 0 0 0 2px inset;
        }

        &.-invalid-range {
          border: 1px solid map_get($red, 'base');
        }

        input {
          background-color: transparent;
          border: none;

          &._time {
            width: 50%;
            padding-left: .6rem;
            border-left: 1px solid $border-color;
          }

          &:focus {
            outline: none;
          }
        }
      }

      &.-range {
        display: flex;

        ._input {
          width: calc(50% - .5rem);

          &:first-of-type {
            margin-right: 1rem;
          }
        }

        &.-show-time {
          flex-direction: column;

          ._input {
            width: 100%;
          }
        }
      }
    }

    ._calendar {
      margin-top: .8rem;
      background-color: white;

      &.-mobile {
        padding: 1.6rem;
      }

      ._caption {
        display: flex;
        align-items: center;
        margin-bottom: .8rem;

        ._right {
          display: flex;
          margin-left: auto;
        }
      }

      ._day {
        display: flex;
        flex: 1 1 auto;
        align-items: center;
        justify-content: center;

        ._day-btn-inner {
          &.-today {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 2.6rem;
            height: 2.6rem;
            line-height: 1;
            color: map_get($red, 'base');
          }
        }
      }

      ._week-days {
        display: flex;
        justify-content: space-between;
        font-size: $font-size-2;
        color: $font-color-tertiary;
      }

      ._body {
        ._week {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
</style>
