<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="12"
      cy="4"
      r="2.5"
      transform="rotate(90 12 4)"
      fill="currentColor"
    />
    <circle
      cx="12"
      cy="12"
      r="2.5"
      transform="rotate(90 12 12)"
      fill="currentColor"
    />
    <circle
      cx="12"
      cy="20"
      r="2.5"
      transform="rotate(90 12 20)"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default { name: 'More' };
</script>

<style scoped lang="scss" type="text/scss">

</style>
