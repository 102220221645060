<template>
  <div :class="['meetings-list-header']">
    <document-list-header
      :slots="slots"
      :allowed-view-types="allowedViewTypes"
      :filter-props="filterProps"
      :show-create-view="showCreateView"
      :show-views-selector="showViewsSelector"
      :sort-options="sortOptions"
      :show-saved-view-info="showSavedViewInfo"
      has-dynamic-date-filters
      @string-filter-changed="$emit('string-filter-changed', $event)"
    >
      <template #create>
        <m-btn-group>
          <m-btn
            :loading="createLoading"
            color="primary"
            class="_btn -create"
            small
            :button-style="createButtonStyle"
            @click="$emit('create')"
          >
            {{ $t('general.new') }}
          </m-btn>
          <m-dropdown
            v-model:value="showTemplates"
            :title="$t('meetingsListHeader.templates')"
          >
            <m-btn
              icon="chevron-down"
              fab
              small
              color="primary"
              :button-style="dropdownButtonStyle"
              @click="showTemplates = true"
            />
            <template #overlay>
              <m-card

                list
                no-padding
              >
                <meeting-template-list
                  :templates="templates"
                  @created="handleTemplateCreated"
                />
              </m-card>
            </template>
          </m-dropdown>
        </m-btn-group>
      </template>
    </document-list-header>
  </div>
</template>

<script>
import DocumentListHeader from '@/components/list/DocumentListHeader.vue';
import MeetingTemplateList from '@/components/meeting/MeetingTemplateList.vue';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import { CREATED_AT } from 'shared/api/query/constants';
import { SLOTS } from '@/lib/constants';
import { mapGetters } from 'vuex';
import { meeting } from 'shared/api/query/configs.json';
import { viewType } from 'shared/constants.json';

export default {
  name: 'MeetingsListHeader',
  props: {
    showViewsSelector: {
      type: Boolean,
      default: false,
    },
    showCreateView: {
      type: Boolean,
      default: false,
    },
    createLoading: {
      type: Boolean,
      default: false,
    },
    showSavedViewInfo: {
      type: Boolean,
      default: false,
    },
    filterProps: {
      type: Array,
      required: true,
    },
    allowedViewTypes: {
      type: Array,
      default: () => [
        viewType.list,
      ],
    },
    slots: {
      type: Array,
      default: () => [],
      validator(slots) {
        return slots.every((slot) => [SLOTS.STRING_FILTER, SLOTS.FILTER, SLOTS.PROPERTIES, SLOTS.SORTER,
          SLOTS.COMPARE_TO, SLOTS.CREATE].includes(slot.name));
      },
    },
  },
  setup() {
    const { userLang } = useLoggedInUser();
    return { userLang };
  },
  emits: ['string-filter-changed', 'create', 'reload'],
  components: { DocumentListHeader, MeetingTemplateList },
  data() {
    return { viewType, showTemplates: false };
  },
  computed: {
    ...mapGetters(['meetings']),
    templates() {
      return this.meetings.filter((m) => m.isTemplate);
    },
    sortOptions() {
      return [
        { attr: meeting.edges.date },
        { attr: meeting.edges.title },
        { attr: CREATED_AT },
      ];
    },
    createButtonStyle() {
      return {
        borderBottomLeftRadius: '4px',
        borderTopLeftRadius: '4px',
        borderBottomRightRadius: 0,
        borderTopRightRadius: 0,
      };
    },
    dropdownButtonStyle() {
      return {
        borderBottomRightRadius: '4px',
        borderTopRightRadius: '4px',
        borderBottomLeftRadius: 0,
        borderTopLeftRadius: 0,
      };
    },
  },
  methods: {
    handleTemplateCreated() {
      this.showTemplates = false;
      this.$emit('reload');
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .meetings-list-header {
    ._list-header {
      display: flex;

      ._right {
        display: flex;
        margin-left: auto;

        ._btn {
          margin-left: .4rem;
        }
      }
    }
  }
</style>
