<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.7403 8.21094H22.9395C23.5252 8.21094 23.9999 8.67653 23.9999 9.25093V14.7886C23.9999 16.8996 22.255 18.6108 20.1025 18.6108H20.0841C17.9315 18.6111 16.1864 16.9001 16.186 14.7891C16.186 14.7889 16.186 14.7888 16.186 14.7886V8.75447C16.1861 8.4543 16.4342 8.21094 16.7403 8.21094Z"
      fill="#5059C9"
    />
    <path
      d="M20.9296 7.11474C22.3167 7.11474 23.4412 6.01197 23.4412 4.65161C23.4412 3.29129 22.3167 2.18848 20.9296 2.18848C19.5425 2.18848 18.418 3.29129 18.418 4.65161C18.418 6.01197 19.5425 7.11474 20.9296 7.11474Z"
      fill="#5059C9"
    />
    <path
      d="M13.1162 7.11571C15.1198 7.11571 16.744 5.5228 16.744 3.55786C16.744 1.59291 15.1198 0 13.1162 0C11.1126 0 9.48828 1.59291 9.48828 3.55786C9.48828 5.5228 11.1126 7.11571 13.1162 7.11571Z"
      fill="#7B83EB"
    />
    <path
      d="M17.9539 8.21094H7.72109C7.1424 8.225 6.68459 8.69599 6.69803 9.26352V15.5795C6.61723 18.9853 9.36478 21.8117 12.8375 21.895C16.3103 21.8117 19.0578 18.9853 18.977 15.5795V9.26352C18.9904 8.69599 18.5326 8.225 17.9539 8.21094Z"
      fill="#7B83EB"
    />
    <path
      opacity="0.1"
      d="M13.3949 8.21094V17.0618C13.3921 17.4677 13.1414 17.8323 12.7587 17.9868C12.6368 18.0374 12.5058 18.0634 12.3735 18.0635H7.18846C7.11592 17.8828 7.04897 17.7022 6.99314 17.5161C6.79777 16.888 6.69807 16.2351 6.69731 15.5784V9.26187C6.68388 8.69523 7.14092 8.225 7.71869 8.21094H13.3949Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M12.8368 8.21094V17.6092C12.8368 17.7389 12.8102 17.8673 12.7587 17.9868C12.6011 18.3622 12.2293 18.6081 11.8154 18.6108H7.45082C7.35591 18.4302 7.26661 18.2496 7.18846 18.0635C7.11032 17.8774 7.04897 17.7022 6.99314 17.5161C6.79777 16.888 6.69807 16.2351 6.69731 15.5784V9.26187C6.68388 8.69523 7.14092 8.225 7.71869 8.21094H12.8368Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M12.8375 8.21094V16.5144C12.8333 17.0659 12.3784 17.5119 11.8161 17.5161H6.99387C6.7985 16.888 6.6988 16.2351 6.69804 15.5784V9.26187C6.68461 8.69523 7.14166 8.225 7.71942 8.21094H12.8375Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M12.2794 8.21094V16.5144C12.2751 17.0659 11.8203 17.5119 11.258 17.5161H6.99387C6.7985 16.888 6.6988 16.2351 6.69804 15.5784V9.26187C6.68461 8.69523 7.14166 8.225 7.71942 8.21094H12.2794Z"
      fill="black"
    />
    <path
      opacity="0.1"
      d="M13.3963 5.3796V7.10379C13.3014 7.10927 13.2121 7.11474 13.1173 7.11474C13.0224 7.11474 12.933 7.10927 12.8382 7.10379C12.6498 7.09154 12.4629 7.06224 12.28 7.01622C11.1498 6.75374 10.2161 5.9764 9.76845 4.92529C9.6914 4.74879 9.63161 4.56551 9.58984 4.37793H12.3749C12.9381 4.38003 13.3942 4.82727 13.3963 5.3796Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M12.8363 5.92746V7.10428C12.648 7.09203 12.4611 7.06273 12.2782 7.01671C11.148 6.75422 10.2142 5.97689 9.7666 4.92578H11.815C12.3782 4.92789 12.8342 5.37512 12.8363 5.92746Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M12.8363 5.92746V7.10428C12.648 7.09203 12.4611 7.06273 12.2782 7.01671C11.148 6.75422 10.2142 5.97689 9.7666 4.92578H11.815C12.3782 4.92789 12.8342 5.37512 12.8363 5.92746Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M12.2792 5.92746V7.01671C11.149 6.75422 10.2152 5.97689 9.76758 4.92578H11.2578C11.821 4.92789 12.2771 5.37512 12.2792 5.92746Z"
      fill="black"
    />
    <path
      d="M1.02306 4.92578H11.2559C11.8209 4.92578 12.2789 5.375 12.2789 5.9291V15.9644C12.2789 16.5185 11.8209 16.9678 11.2559 16.9678H1.02306C0.458015 16.9678 0 16.5185 0 15.9644V5.9291C0 5.375 0.458057 4.92578 1.02306 4.92578Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M8.83116 8.74524H6.78562V14.2079H5.48239V8.74524H3.44629V7.68555H8.83116V8.74524Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="2.13311"
        y1="4.14183"
        x2="9.91392"
        y2="17.8831"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#5A62C3" />
        <stop
          offset="0.5"
          stop-color="#4D55BD"
        />
        <stop
          offset="1"
          stop-color="#3940AB"
        />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default { name: 'MsTeams' };
</script>

<style scoped lang="scss" type="text/scss">

</style>
