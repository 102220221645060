const empty = (obj) => {
  if (typeof obj === 'undefined') {
    return true;
  }

  if (obj === null) {
    return true;
  }

  return false;
};

export const writeAccess = (user, form) => {
  if (!empty(form.creator) && form.creator.uid === user.uid) {
    return true;
  }

  if (empty(form.resourcePolicy)) {
    return false;
  }

  if (empty(form.resourcePolicy.write)) {
    return false;
  }

  return form.resourcePolicy.write.map((u) => u.uid).includes(user.uid);
};

export const fieldsOfFactor = (form, factor) => {
  if (form === null || factor === null) {
    return [];
  }
  const factorFieldIds = factor.factorFields.map((f) => f.field.uid);
  return form.orderItems.map((o) => o.field).filter((f) => factorFieldIds.includes(f.uid));
};

export const fieldsWithoutFactor = (form) => {
  if (form === null) {
    return [];
  }
  const factorFieldIds = form.formFactors.reduce((acc, next) => [
    ...acc,
    ...next.factorFields.map((f) => f.field.uid),
  ], []);
  return form.orderItems.map((o) => o.field).filter((f) => !factorFieldIds.includes(f.uid));
};
