import { DateTime } from 'luxon';
import { client } from '@/composables/sanity/client';
import { computed, ref } from 'vue';

const releaseNotes = ref([]);

const websiteReleaseDate = DateTime.fromISO('2024-08-07');
export default function useReleaseNotes(personalAppSettingsSvc, loggedInUser) {
  const RELEASENOTES_QUERY = (page, amountOfItems) => `
  *[_type == "releaseNote"]{
    _id, 
    title, 
    releaseDate, 
    automaticallyShowDialog,
    "tags": coalesce(tags, []),
    content[]{
      ...,
      _type == "imageEmbed" => {
          ...,
          alt,
          imageFile {
            "width": asset->metadata.dimensions.width,
            "height": asset->metadata.dimensions.height,
            "url": asset->url,
          }
      },
      _type == "videoEmbed" => {
          ...,
          "videoSrc": videoFile.asset->url
      },
    }
  } | order(dateTime(releaseDate) desc) [${(page - 1) * amountOfItems}...${page * amountOfItems}]
`;

  const fetchReleaseNotes = async () => {
    const res = await client.fetch(
      RELEASENOTES_QUERY(1, 20),
    );

    releaseNotes.value = [...releaseNotes.value, ...res];
    return releaseNotes.value;
  };

  const unreadReleaseNotes = computed(() => {
    if (!Array.isArray(personalAppSettingsSvc.personalAppSettings.value.readReleaseNotes)) {
      return [];
    }

    if (loggedInUser.value.personalInfosCompleted === null) {
      return [];
    }

    const firstLoginUser = DateTime.fromISO(loggedInUser.value.personalInfosCompleted);

    return releaseNotes.value.filter((d) => {
      const date = DateTime.fromISO(d.releaseDate);

      // We don't show any release notes as unread from before the website release
      if (date.diff(websiteReleaseDate) < 0) {
        return false;
      }

      if (date.diff(firstLoginUser) < 0) {
        return false;
      }

      return !personalAppSettingsSvc.personalAppSettings.value.readReleaseNotes.includes(d._id);
    });
  });

  const markAsRead = () => personalAppSettingsSvc.updateSingle(
    {
      uid: personalAppSettingsSvc.personalAppSettings.value.uid,
      readReleaseNotes: releaseNotes.value.map((n) => n._id),
    },
  );

  return { unreadReleaseNotes, markAsRead, fetchReleaseNotes, releaseNotes };
}
