import { UID } from 'shared/api/query/constants';
import { dataSource as dataSourceConfig, goal as goalConfig, jiraQuery as jiraQueryConfig } from 'shared/api/query/configs.json';

export const jiraQueryChildren = [
  { attr: UID },
  { attr: jiraQueryConfig.edges.totalJql, default: '' },
  { attr: jiraQueryConfig.edges.overallCountJql, default: '' },
  { attr: jiraQueryConfig.edges.countJql, default: '' },
  { attr: jiraQueryConfig.edges.alwaysSync, default: false },
  { attr: jiraQueryConfig.edges.operationMode, default: '' },

  { attr: jiraQueryConfig.edges.error, default: '' },
  {
    attr: jiraQueryConfig.edges.dataSource,
    model: dataSourceConfig.model,
    default: null,
    children: [{ attr: UID }],
  },
  {
    attr: jiraQueryConfig.edges.goal,
    model: goalConfig.model,
    children: [{ attr: UID }],
  },
];
