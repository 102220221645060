import { computed, ref } from 'vue';
import { findInArray, findIndexArray } from 'shared/lib/array/array';

export default function useSort(key = 'uid', validate = (input) => input) {
  const draggingOverTop = ref([]);
  const draggingOverBottom = ref([]);
  const draggingOverLeft = ref([]);
  const draggingOverRight = ref([]);
  const dragItemId = ref('');

  const cancelDragging = () => {
    dragItemId.value = '';
    draggingOverBottom.value = [];
    draggingOverTop.value = [];
    draggingOverRight.value = [];
    draggingOverLeft.value = [];
  };

  const resetData = () => {
    dragItemId.value = '';
    draggingOverBottom.value = [];
    draggingOverTop.value = [];
    draggingOverRight.value = [];
    draggingOverLeft.value = [];
  };
  const dropItem = (items) => {
    let newOrder = items;
    switch (true) {
      case draggingOverBottom.value.length > 0:
        newOrder = setNewOrder({ uid: dragItemId.value, anchor: draggingOverBottom.value[0], position: 'after', items });
        break;
      case draggingOverTop.value.length > 0:
        newOrder = setNewOrder({ uid: dragItemId.value, anchor: draggingOverTop.value[0], position: 'before', items });
        break;
      case draggingOverLeft.value.length > 0:
        newOrder = setNewOrder({ uid: dragItemId.value, anchor: draggingOverLeft.value[0], position: 'before', items });
        break;
      case draggingOverRight.value.length > 0:
        newOrder = setNewOrder({ uid: dragItemId.value, anchor: draggingOverRight.value[0], position: 'after', items });
        break;
      default:
    }
    resetData();
    return newOrder;
  };

  const setDragItem = (item) => {
    dragItemId.value = item;
  };

  const setOverTop = (keys) => {
    draggingOverTop.value = validate(keys, 'top');
  };
  const setOverBottom = (keys) => {
    draggingOverBottom.value = validate(keys, 'bottom');
  };
  const setOverRight = (keys) => {
    draggingOverRight.value = validate(keys, 'right');
  };
  const setOverLeft = (keys) => {
    draggingOverLeft.value = validate(keys, 'left');
  };

  const setNewOrder = ({ uid, anchor, position, items }) => {
    if (anchor === uid) {
      return items;
    }

    const newOrder = items.filter((p) => p[key] !== uid);
    const index = findIndexArray({ haystack: newOrder, needle: anchor, key });
    const item = findInArray({ haystack: items, needle: uid, key });
    if (item === null) {
      return items;
    }

    if (index === -1) {
      newOrder.unshift(item);
      return newOrder;
    }
    if (position === 'after') {
      newOrder.splice(index + 1, 0, item);
      return newOrder;
    }
    newOrder.splice(index, 0, item);
    return newOrder;
  };

  const dragging = computed(() => dragItemId.value !== '');

  return {
    dragItemId,
    dragging,
    setDragItem,
    setOverBottom,
    setOverTop,
    setOverLeft,
    setOverRight,
    draggingOverBottom,
    draggingOverTop,
    draggingOverRight,
    draggingOverLeft,
    dropItem,
    cancelDragging,
  };
}
