<template>
  <div class="questions-bank">
    <page-header
      :title="$t('questionsBank.title')"
      :sub-title="$t('questionsBank.subTitle')"
      heading="h3"
    >
      <template #actions>
        <div

          class="_actions"
        >
          <m-btn
            icon="close"
            fab
            :to="createUrl"
            hide-border
          />
        </div>
      </template>
    </page-header>
    <m-content padding>
      <div class="_tabs">
        <div
          :class="['_tab', $route.name === allQuestionsUrl.name ? '-active' : '']"
          @click="navigateToAllQuestions"
        >
          {{ $t('questionsBank.allQuestions') }}
        </div>
        <div
          :class="['_tab', templateListActive || templateDetailView ? '-active' : '']"
          @click="navigateToTemplateList"
        >
          <m-icon
            class="_icon"
            type="templates"
          />
          {{ $t('questionsBank.templates') }}
        </div>
      </div>
      <m-content
        v-if="templateListActive"
        class="_template-list"
      >
        <m-text-field
          v-model:value="searchTemplate"
          class="_search"
          size="large"
        >
          <template #suffix>
            <m-icon

              type="search"
            />
          </template>
        </m-text-field>
        <div class="_list">
          <card-list-item
            v-for="item in templates"
            :key="item.uid"
            class="_item"
            :title="item.title"
            :to="to(item)"
            :sub-title="`${amountOfQuestions(item)} ${$t('questionsBank.questions', amountOfQuestions(item))}`"
            hide-footer
          />
        </div>
      </m-content>
      <m-content
        v-else-if="templateDetailView"
        class="_question-list"
      >
        <breadcrumbs
          :breadcrumbs="breadcrumbs"
          class="_breadcrumbs"
        />
        <div class="_top-bar">
          <div class="_left">
            {{ currentTemplateItems.length }} {{ $t('questionsBank.questions', currentTemplateItems.length) }}
          </div>
          <div
            v-if="!disabled"
            class="_right"
          >
            <m-btn
              hide-border
              small
              class="_link"
              :loading="loading"
              @click="addQuestions(currentTemplateItems)"
            >
              {{ $t('questionsBank.addAll') }}
            </m-btn>
          </div>
        </div>
        <template-question-item
          v-for="item in currentTemplateItems"
          :key="item.uid"
          :item="item"
          :lang="lang"
          class="_item"
          :add-question-method="addQuestion"
          :disabled="disabled"
          @selected="selected"
        />
      </m-content>
      <m-content
        v-else
        class="_question-list"
      >
        <m-text-field
          v-model:value="search"
          class="_search"
          large
        >
          <template #suffix>
            <m-icon

              type="search"
            />
          </template>
        </m-text-field>
        <div class="_top-bar">
          <div class="_left">
            {{ items.length }} {{ $t('questionsBank.questions', items.length) }}
            <m-spinner
              v-if="templatesLoading"
              size="xs"
              class="_spinner"
            />
          </div>
          <div class="_right">
            <m-btn
              v-if="!disabled"
              hide-border
              small
              class="_link"
              :loading="loading"
              @click="addQuestions(items)"
            >
              {{ $t('questionsBank.addAll') }}
            </m-btn>
          </div>
        </div>
        <template-question-item
          v-for="item in items"
          :key="item.uid"
          :item="item"
          :lang="lang"
          class="_item"
          :add-question-method="addQuestion"
          :disabled="disabled"
          @selected="selected"
        />
      </m-content>
    </m-content>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/breadcrumbs/Breadcrumbs.vue';
import CardListItem from '@/components/CardListItem.vue';
import PageHeader from 'shared/components/PageHeader.vue';
import TemplateQuestionItem from '@/components/survey/TemplateQuestionItem.vue';
import { QUESTION_BANK_TEMPLATE_DETAILS } from '@/route-names';
import { fieldType, routeName } from 'shared/constants.json';
import { mapActions, mapState } from 'vuex';
import { releasedFormTemplates } from 'shared/api/query/form-template';

export default {
  name: 'QuestionsBank',
  props: {
    entityById: {
      type: Function,
      required: true,
    },
    entity: {
      type: Object,
      required: true,
    },
    model: {
      type: String,
      required: true,
    },
    createUrl: {
      type: Object,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
    allQuestionsUrl: {
      type: Object,
      required: true,
    },
    templateListUrl: {
      type: Object,
      required: true,
    },
    questionBankTemplateDetailUrl: {
      type: Function,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    templateType: {
      type: String,
      required: true,
    },
  },
  emits: ['welcome-screen-selected', 'selected'],
  components: { Breadcrumbs, TemplateQuestionItem, CardListItem, PageHeader },
  data() {
    return {
      search: '',
      searchTemplate: '',
      loading: false,
      templatesLoading: true,
    };
  },
  computed: {
    ...mapState({ formTemplates: (state) => state.formTemplates }),
    breadcrumbs() {
      if (this.selectedTemplate === null) {
        return [];
      }

      return [
        {
          title: this.$t('questionsBank.allTemplates'),
          to: this.templateListUrl,
        },
        {
          title: this.selectedTemplate.title,
          disabled: true,
        },
      ];
    },
    templates() {
      if (this.searchTemplate === '') {
        return this.formTemplates;
      }

      return this.formTemplates.filter((t) => t.title.toLowerCase().indexOf(this.searchTemplate) > -1);
    },
    selectedTemplate() {
      const filtered = this.formTemplates.filter((t) => t.uid === parseInt(this.$route.params.templateId, 10));
      if (filtered.length !== 1) {
        return null;
      }

      return filtered[0];
    },
    currentTemplateItems() {
      if (this.selectedTemplate === null) {
        return [];
      }

      const res = this.selectedTemplate.orderItems.map((item) => ({
        title: item.field.title,
        opinionScaleProperties: item.field.opinionScaleProperties,
        validations: item.field.validations,
        type: item.field.type,
        allowComment: item.field.allowComment,
        templateField: item.field,
      }));
      if (this.selectedTemplate.welcomeScreen !== null) {
        const ws = this.selectedTemplate.welcomeScreen;
        res.unshift({
          title: ws.title,
          description: ws.description,
          type: fieldType.welcomeScreen,
          welcomeScreen: ws,
        });
      }
      return res;
    },
    templateListActive() {
      return this.$route.name === this.templateListUrl.name;
    },
    templateDetailView() {
      return this.$route.name === QUESTION_BANK_TEMPLATE_DETAILS
        || this.$route.name === routeName.questionBankTemplateDetailsFormTemplates;
    },
    items() {
      const res = [];
      this.formTemplates.forEach((t) => {
        t.orderItems.forEach((item) => {
          res.push({
            title: item.field.title,
            opinionScaleProperties: item.field.opinionScaleProperties,
            validations: item.field.validations,
            type: item.field.type,
            allowComment: item.field.allowComment,
            templateField: item.field,
          });
        });
      });
      if (this.search === '') {
        return res;
      }

      return res.filter((item) => item.title[this.lang].toLowerCase().indexOf(this.search) > -1);
    },
  },
  methods: {
    ...mapActions([
      'getFormTemplates',
      'updateEntity',
    ]),
    to(item) {
      return this.questionBankTemplateDetailUrl({ entityId: this.entity.uid, templateId: item.uid });
    },
    amountOfQuestions(item) {
      return item.orderItems.length + (item.welcomeScreen !== null ? 1 : 0);
    },
    addQuestion(field) {
      return this.addQuestions([field]);
    },
    addQuestions(fields) {
      this.loading = true;
      const orderItems = [
        ...this.entity.orderItems,
        ...fields.filter((item) => item.type !== fieldType.welcomeScreen).map((item, index) => ({
          uid: 0,
          value: this.entity.orderItems.length + index,
          field: {
            ...item,
            uid: 0,
            validations: {
              ...item.validations,
              uid: 0,
            },
            opinionScaleProperties: {
              ...item.opinionScaleProperties,
              uid: 0,
            },
            templateField: item,
          },
        })),
      ];

      const updatedEntity = {
        ...this.entity,
        orderItems,
      };
      const welcomeScreens = fields.filter((item) => item.type === fieldType.welcomeScreen);
      if (welcomeScreens.length > 0) {
        const newWelcomeScreen = { ...welcomeScreens[0].welcomeScreen, uid: 0 };
        if (this.entity.welcomeScreen !== null) {
          newWelcomeScreen.uid = this.entity.welcomeScreen.uid;
        }
        updatedEntity.welcomeScreen = newWelcomeScreen;
      }

      const onlyWelcomeScreenAdded = (fields.length === 1 && fields[0].type === fieldType.welcomeScreen);

      return this.updateEntity({
        entity: updatedEntity,
        model: this.model,
        entityById: this.entityById,
      }).then((response) => {
        this.loading = false;
        if (response.status !== 200) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
          return response;
        }

        this.$showSnackbar({ color: 'success', message: this.$t('success.added') });

        if (onlyWelcomeScreenAdded) {
          this.selectWelcomeScreen();
        } else {
          this.selected({ fieldIndex: orderItems.length - 1, item: response.data.orderItems[orderItems.length - 1] });
        }

        return response;
      });
    },
    selectWelcomeScreen() {
      setTimeout(() => {
        this.$emit('welcome-screen-selected');
      }, 200);
    },
    selected({ fieldIndex, item }) {
      setTimeout(() => {
        this.$emit('selected', { fieldIndex, item });
      }, 200);
    },
    navigateToAllQuestions() {
      this.$router.push(this.allQuestionsUrl);
    },
    navigateToTemplateList() {
      this.$router.push(this.templateListUrl);
    },
  },
  mounted() {
    this.templatesLoading = true;
    this.getFormTemplates({ query: releasedFormTemplates({ type: this.templateType }) }).then(() => {
      this.templatesLoading = false;
    });
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  @import "shared/assets/scss/padding";

  .questions-bank {
    width: 100%;

    ._tabs {
      display: flex;
      width: 100%;

      ._tab {
        @include padding('small');

        display: flex;
        flex: 0 0 50%;
        justify-content: center;
        font-weight: $font-weight-semibold;
        color: $font-color-secondary;
        text-align: center;
        cursor: pointer;
        border-bottom: 1px solid $input-border-color;

        ._icon {
          margin-right: .8rem;
        }

        &.-active {
          color: $font-color-primary;
          border-bottom: 2px solid $font-color-primary;
        }
      }
    }

    ._question-list {
      ._breadcrumbs {
        margin-top: 2rem;
      }

      ._item {
        margin: 1.2rem 0;
      }
    }

    ._template-list {
      ._list {
        display: flex;
        flex-wrap: wrap;
        padding-top: 1.2rem;
        margin-right: -1.2rem;
        margin-left: -1.2rem;

        ._item {
          flex: 0 1 calc(33.33% - 2.4rem);
          height: 21rem;
          margin: 1.2rem;

          @media (min-width: $screen-size-xl) {
            flex: 0 1 calc(25% - 2.4rem);
          }
        }
      }
    }

    ._search {
      margin: 2rem 0 .4rem;
    }

    ._top-bar {
      display: flex;
      margin-top: 4rem;
      margin-bottom: 2rem;
      font-weight: $font-weight-medium;

      ._left {
        position: relative;

        ._spinner {
          position: absolute;
          right: -2.8rem;
        }
      }

      ._right {
        margin-left: auto;

        ._link {
          color: $font-color-primary;
        }
      }
    }
  }
</style>
