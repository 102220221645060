<template>
  <div
    :class="classes"
    :style="style"
  >
    <user-avatar
      v-if="!hideAvatar"
      :user="user"
      class="_avatar"
      :has-tooltip="hideName"
      :size="sizeOfAvatar"
    />
    <div
      v-if="!hideName || !!$slots.subName"
      class="_text"
    >
      <div
        v-if="!hideName"
        class="_name"
      >
        {{ name }}
      </div>
      <div
        v-if="!!$slots.subName"
        class="_sub-name"
      >
        <slot name="subName" />
      </div>
    </div>
  </div>
</template>

<script>
import UserAvatar from 'shared/components/UserAvatar.vue';
import useUser from 'shared/composables/user';
import { computed, toRef } from 'vue';
import { mStyleProps, resolveStyles } from 'shared/lib/m-style-props';
import { useI18n } from 'vue-i18n';

export default {
  name: 'UserDisplay',
  props: {
    ...mStyleProps,
    user: {
      type: Object,
      required: true,
    },
    hideAvatar: {
      type: Boolean,
      default: false,
    },
    hideName: {
      type: Boolean,
      default: false,
    },
    xs: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    xl: {
      type: Boolean,
      default: false,
    },
  },
  components: { UserAvatar },
  setup(props) {
    const i18n = useI18n();
    const name = computed(() => {
      if (props.user === null) {
        return i18n.t('user.deleted.name');
      }

      return useUser(toRef(props, 'user')).displayName.value;
    });

    return { name };
  },
  computed: {
    classes() {
      return [
        'user-display',
        this.xs ? '-xs' : '',
        this.small ? '-small' : '',
        this.large ? '-large' : '',
        this.xl ? '-xl' : '',
      ];
    },
    sizeOfAvatar() {
      if (this.xs) {
        return 16;
      }
      if (this.small) {
        return 20;
      }
      if (this.large) {
        return 30;
      }
      if (this.xl) {
        return 36;
      }
      return undefined;
    },
    fontStyle() {
      const style = {};
      switch (true) {
        case this.xs:
          style.fontSize = this.$fontSizes[2];
          break;
        default:
          style.fontSize = this.$fontSizes[4];
          break;
      }
      return style;
    },
    style() {
      return {
        ...this.fontStyle,
        ...resolveStyles(this.mStyle),
      };
    },
  },
  methods: { resolveStyles },
};
</script>

<style scoped lang="scss" type="text/scss">
  .user-display {
    display: flex;
    gap: .8rem;
    align-items: center;

    ._text {
      display: flex;
      flex-flow: column;
      white-space: nowrap;

      ._sub-name {
        font-size: $font-size-2;
        color: $font-color-tertiary;
      }
    }

    &.-xs {
      gap: .4rem;
    }

    &.-small {
      gap: .6rem;
    }

    &.-large {
      gap: 1.2rem;
    }

    &.-xl {
      gap: 1.6rem;
    }
  }

</style>
