<template>
  <div
    class="rc-layout"
  >
    <m-content
      v-if="showBackButton"
      :padding-x="10"
      :padding-y="10"
    >
      <m-btn
        icon="left-arrow-long"
        hide-border
        light
        small
        :style="{ marginBottom: '2rem' }"
        @click="resourceCenterRouter.navigate(RESOURCE_CENTER_OVERVIEW)"
      >
        {{ $t('general.back') }}
      </m-btn>
      <div :style="{ display: 'flex', alignItems: 'center' }">
        <template v-if="!!$slots.title">
          <slot name="title" />
        </template>
        <h5
          v-else
        >
          {{ title }}
        </h5>
      </div>
    </m-content>
    <slot />
  </div>
</template>

<script>
import { RESOURCE_CENTER_OVERVIEW } from '@/lib/constants';

export default {
  name: 'RcLayout',
  props: {
    showBackButton: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    resourceCenterRouter: {
      type: Object,
      required: true,
    },
  },
  data() {
    return { RESOURCE_CENTER_OVERVIEW };
  },
};
</script>

<style lang="scss" type="text/scss">
  .rc-layout {
    h5 {
      margin-bottom: 0;
    }
  }
</style>
