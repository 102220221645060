<template>
  <div class="meeting-insights-table">
    <m-table
      :columns="columns"
      :data-source="insightsData"
      :loading="loading"
      :pagination="pagination"
      row-key="uid"
    >
      <template #user="{ user }">
        <user-display
          class="_user"
          :user="user"
          small
          @click="handleUserClick(user)"
        />
      </template>
      <template #[spaceProperty.uid]="{ row }">
        <div
          class="_property-options"
        >
          <div
            v-for="value in row.user.values.filter(op => op.property.uid === spaceProperty.uid)"
            :key="value.uid"
            class="_value"
          >
            <m-tag
              v-for="space in value.spaces"
              :key="space.uid"
              :type="space.type"
              :icon="buildIconFromEntity(space)"
              :color="space.color"
              automatic-color-fallback
              small
              clickable
              class="_tag"
              :title="space.title"
              :style="{ fontWeight: 'normal' }"
              @click="optionClick(space)"
            />
          </div>
        </div>
      </template>
      <template #count="{ count }">
        <div

          class="_count"
        >
          {{ count }}
        </div>
      </template>
    </m-table>
  </div>
</template>

<script>
import UserDisplay from 'shared/components/UserDisplay.vue';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useProperties from '@/composables/property/property';
import { buildIconFromEntity } from 'shared/lib/icon';
import { routeName } from 'shared/constants.json';
import { textByLang } from 'shared/lib/language';

export default {
  name: 'MeetingInsightsTable',
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { spaceProperty } = useProperties();
    const { userLang } = useLoggedInUser();

    return { spaceProperty, userLang };
  },
  components: { UserDisplay },
  data() {
    return { textByLang };
  },
  computed: {
    columns() {
      return [
        {
          key: 'user',
          dataIndex: 'user',
          title: this.$t('goalInsightsTable.user'),
          scopedSlots: { customRender: 'user' },
        },
        {
          key: `${this.spaceProperty.uid}`,
          dataIndex: `${this.spaceProperty.uid}`,
          title: textByLang(this.spaceProperty.label, this.userLang),
          scopedSlots: { customRender: this.spaceProperty.uid },
        },
        {
          key: 'count',
          dataIndex: 'count',
          title: this.$t('meetingInsightsTable.count'),
          scopedSlots: { customRender: 'count' },
          sorter: this.sorter,
          sortDirections: ['descend', 'ascend'],
        },
      ];
    },
    insightsData() {
      return this.data.map((row) => {
        let count = 0;
        if (typeof row.meetings !== 'undefined') {
          count = row.meetings.count;
        }

        return {
          uid: row.uid,
          user: { ...row },
          count,
        };
      });
    },
    pagination() {
      if (this.insightsData.length < 40) {
        return false;
      }

      return { pageSize: 40 };
    },
  },
  methods: {
    buildIconFromEntity,
    sorter(a, b) {
      return a.count - b.count;
    },
    optionClick(option) {
      this.$router.push({ name: routeName.spaceDetails, params: { optionId: option.uid } });
    },
    handleUserClick(user) {
      this.$router.push({ name: routeName.profile, params: { userId: user.uid } });
    },
  },
};
</script>

<style lang="scss" type="text/scss">
  @import 'shared/assets/scss/padding';

  .meeting-insights-table {
    ._user {
      cursor: pointer;
    }

    ._property-options {
      display: flex;

      ._value {
        display: flex;

        ._tag {
          margin: .2rem .4rem;
        }
      }
    }

    .m-table {
      overflow: unset;

      @include layoutPaddingX();
    }
  }
</style>
