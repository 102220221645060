export const breakdownUserFilter = (array, searchTerm) => array.filter((e) => {
  const filterByUser = (value, record) => `${record.user.firstName} ${record.user.lastName} ${record.user.email}`.toLowerCase().includes(value.toLowerCase());

  const filterByTeam = (value, record) => record.userOptions.reduce((res, next) => {
    if (next.label.en.toLowerCase().includes(value.toLowerCase())) {
      return true;
    }

    return res;
  }, false);

  return filterByUser(searchTerm, e) || filterByTeam(searchTerm, e);
});

export const breakdownActiveFilter = (showAsHierarchy) => (res, node) => {
  if (showAsHierarchy && node.parentCollapsed) {
    return res;
  }

  if (!showAsHierarchy && (!node.match || node.data === undefined)) {
    return res;
  }

  if ((!node.match && !node.containsMatch)
    || (node.data === undefined && !node.containsData)
    || (!node.match && node.containsDataMatch === false)
  ) {
    return res;
  }

  if (!node.containsMatch && node.data === undefined) {
    return res;
  }

  return [
    ...res,
    node,
  ];
};
