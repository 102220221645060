<template>
  <m-dropdown
    :title="$t('goalInsightsTable.confidenceDistribution', { title: statusLabel })"
    :class="['confidence-bar-chart', clickable ? '-clickable' : '']"
    :disabled="!clickable"
  >
    <div
      class="_bar-container"
      :style="style"
    >
      <div
        v-for="(bucket, i) in sortedDistribution"
        :key="i"
        class="_bar"
        :style="getStyle(bucket)"
      >
        <div class="_inner">
          .
        </div>
      </div>
    </div>
    <template #overlay>
      <m-card
        class="_confidence-bar-chart-overlay"
        no-padding
      >
        <m-content
          :padding-x="8"
        >
          <distribution-chart
            :chart-data="activeGoalsDistribution"
            :clickable="!disabled"
            :title="$t('goalInsightsPerformance.statusActive')"
            @click="$emit('distribution-click', $event)"
          >
            <template #label="{ item }">
              <status-item :selected-option="item.selectedOptions[0]" />
            </template>
          </distribution-chart>
          <distribution-chart
            :chart-data="closedGoalsDistribution"
            :clickable="!disabled"
            :title="$t('goalInsightsPerformance.statusClosed')"
            @click="$emit('distribution-click', $event)"
          >
            <template #label="{ item }">
              <status-item :selected-option="item.selectedOptions[0]" />
            </template>
          </distribution-chart>
        </m-content>
      </m-card>
    </template>
  </m-dropdown>
</template>

<script>
import DistributionChart from '@/components/dashboard/DistributionChart.vue';
import StatusItem from '@/components/goal/StatusItem.vue';
import statusProperty from '@/composables/goal/status-property';
import useGoalProperty from '@/composables/property/goal-property';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import { shallowCopy } from 'shared/lib/copy';
import { textByLang } from 'shared/lib/language';

export default {
  name: 'ConfidenceBarChart',
  props: {
    distribution: {
      type: Array,
      required: true,
    },
    height: {
      type: String,
      default: '2.5rem',
    },
    round: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { statusProperty: goalStatusProperty } = useGoalProperty();
    const { userLang } = useLoggedInUser();
    const { activeStatusOption, closedStatusOption } = statusProperty(goalStatusProperty, userLang.value);
    return {
      activeStatusOption,
      closedStatusOption,
      statusLabel: textByLang(goalStatusProperty.value.label, userLang.value),
    };
  },
  components: { DistributionChart, StatusItem },
  emits: ['distribution-click'],
  computed: {
    sortedDistribution() {
      return shallowCopy(this.distribution).reverse();
    },
    total() {
      return this.distribution.reduce((acc, bucket) => acc + bucket.y, 0);
    },
    activeGoalsDistribution() {
      return this.distribution.filter((c) => this.activeStatusOption.children.map((o) => o.uid).includes(c.uid));
    },
    closedGoalsDistribution() {
      return this.distribution.filter((c) => this.closedStatusOption.children.map((o) => o.uid).includes(c.uid));
    },
    clickable() {
      return !this.disabled && this.total !== 0;
    },
    style() {
      if (this.round) {
        return {
          height: this.height,
          borderRadius: `${parseFloat(this.height) / 2}rem`,
        };
      }
      return { height: this.height };
    },
  },
  methods: {
    getStyle(bucket) {
      const percentage = this.total !== 0 ? (bucket.y * 100) / this.total : 0;
      return {
        width: `${percentage}%`,
        backgroundColor: bucket.color,
      };
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .confidence-bar-chart {
    padding: .4rem;

    &.-clickable {
      cursor: pointer;

      &:hover {
        background-color: $hover-color;
        border-radius: $border-radius-sm;
      }
    }

    ._bar-container {
      display: flex;
      width: 100%;
      overflow: hidden;
      background-color: map_get($grey, 'lighten-3');
      border-radius: $border-radius-sm;

      ._bar {
        height: inherit;
        transition: width .5s;

        ._inner {
          height: inherit;
          color: transparent;
        }
      }
    }
  }

  ._confidence-bar-chart-overlay {
    width: 40rem;
  }

</style>
