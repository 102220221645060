import useConfirmDialog from '@/composables/confirm-dialog';
import useGoalSettings from '@/composables/logged-in-user-account/goal-settings';
import useGoals from '@/composables/goal/goals';
import useSnackBar from '@/composables/snackbar';
import { EVENTS } from '@/lib/constants';
import { EventBus } from '@/lib/event-bus';
import { logCatch } from '@/lib/logger/logger';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

export default function useDeleteGoal() {
  const deleteLoading = ref(false);
  const { t } = useI18n();
  const { goalSettings } = useGoalSettings();
  const { deleteMultiple } = useGoals();
  const { confirm } = useConfirmDialog();
  const { success, error } = useSnackBar();
  const router = useRouter();
  const showDeleteGoalModal = ({
    goals,
    redirect,
    title = t('goal.detailHeader.deletePrompt', { title: goalSettings.value.featureNamePlural }),
    onCancel = () => {},
    okText = t('general.yesDelete'),
    cancelText = t('general.cancel'),
    hardDelete = false,
  }) => new Promise((resolve) => {
    confirm({
      title,
      okText,
      okType: 'danger',
      cancelText,
      maskClosable: true,
      onCancel: () => {
        onCancel();
        resolve();
      },
      async onOk() {
        await deleteGoalEntities({ goals, redirect, hardDelete });
        resolve({ success: true });
      },
    });
  });
  const deleteGoalEntities = ({ goals, redirect, hardDelete }) => {
    deleteLoading.value = true;
    const errorMessage = t('error.default');

    return deleteMultiple(
      goals.map((g) => g.uid),
      { softDelete: !hardDelete },
    ).then(async () => {
      EventBus.$emit(EVENTS.GOAL.GOALS_DELETED, { goals });
      const message = hardDelete ? t('success.deleted') : t('success.movedToTrash');
      success(message);
      await redirectFn(redirect);
    }).catch(logCatch(() => {
      error(errorMessage);
    })).finally(() => {
      deleteLoading.value = false;
    });
  };
  const redirectFn = async (redirect) => {
    if (redirect !== undefined && Object.keys(redirect).length > 0) {
      return router.push(redirect);
    }
    return undefined;
  };

  return {
    showDeleteGoalModal,
    deleteLoading,
  };
}
