<template>
  <m-dropdown
    :value="showModal"
    :title="$t('general.actions')"
    @input="val => showModal = val"
  >
    <m-btn
      fab
      icon="non-automatic"
      xs
      hide-border
      class="_btn"
      light
      :button-style="{ height: '2.4rem', width: '2.4rem' }"
      icon-size="16"
      @click.stop="showModal = true"
    />
    <template #overlay>
      <m-card
        class="_overlay"
        small-padding
      >
        <div class="_description">
          {{ $t('goalStatusTag.disabledAutoStatusUpdateDescription') }}
        </div>
        <m-btn
          outlined
          block
          :loading="automaticStatusUpdateLoading"
          @click="enable"
        >
          {{ $t('goalStatusTag.enableAutomaticStatusUpdate') }}
        </m-btn>
      </m-card>
    </template>
  </m-dropdown>
</template>

<script setup>
import useSnackBar from '@/composables/snackbar';
import useStatusAutoUpdate from '@/composables/goal/status-auto-update';
import { logCatch } from '@/lib/logger/logger';
import { ref } from 'vue';

const showModal = ref(false);

const { automaticStatusUpdateLoading, toggleStatusUpdate } = useStatusAutoUpdate();

const snackbar = useSnackBar();

const props = defineProps({
  goal: {
    type: Object,
    required: true,
  },
});

const enable = () => {
  toggleStatusUpdate(props.goal).then(() => {
    showModal.value = false;
  }).catch(logCatch(() => {
    snackbar.error();
  }));
};
</script>

<style scoped lang="scss">
  ._overlay {
    max-width: 32rem;

    ._description {
      margin-bottom: 1.2rem;
    }
  }
</style>
