<template>
  <item-wrapper
    v-if="showAsDraft || hasStatus"
    class="goal-status-tag"
    :show-tooltip="showTooltip"
    :tooltip="label"
    @click="emit"
  >
    <status-item
      v-if="selectedOption !== null"
      :small="small"
      :xs="xs"
      :selected-option="selectedOption"
      :show-as-draft="showAsDraft"
      :m-style="mStyle"
    />
  </item-wrapper>
</template>

<script>
import ItemWrapper from '@/components/list/ItemWrapper.vue';
import StatusItem from '@/components/goal/StatusItem.vue';
import useGoalProperty from '@/composables/property/goal-property';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';

import useStatusProperty from '@/composables/goal/status-property';
import { getStatusProperty } from '@/lib/goal/status';
import { goalProgressMeasurement } from 'shared/constants.json';
import { mStyleProps } from 'shared/lib/m-style-props';

export default {
  name: 'StatusProp',
  props: {
    ...mStyleProps,
    small: {
      type: Boolean,
      default: false,
    },
    xs: {
      type: Boolean,
      default: false,
    },
    goal: {
      type: Object,
      default: null,
    },
    showTooltip: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
  },
  setup() {
    const { userLang } = useLoggedInUser();

    const { statusProperty } = useGoalProperty();
    const { defaultOption } = useStatusProperty(statusProperty, userLang);

    return {
      userLang,
      defaultOption,
    };
  },
  emits: ['click'],
  components: { StatusItem, ItemWrapper },
  computed: {
    value() {
      return getStatusProperty(this.goal);
    },
    selectedOption() {
      if (this.value === null
        || typeof this.value.selectedOptions === 'undefined'
        || this.value.selectedOptions.length === 0
      ) {
        return this.defaultOption;
      }
      return this.value.selectedOptions[0];
    },
    hasStatus() {
      return this.goal.progressMeasurement !== goalProgressMeasurement.none;
    },
    showAsDraft() {
      return this.goal.publishedAt === null;
    },
  },
  methods: {
    emit() {
      this.$emit('click', this.value);
    },
  },
};
</script>
