import useLookupProperty from '@/composables/property/lookup-property/lookup-property';
import { filter } from '@/lib/dqlite/filter';
import { goal as goalConfig } from 'shared/api/query/configs.json';
import { goalIsInCycle, goalTitleContains } from '@/lib/filter/goal/filter';
import { isInFilter } from '@/lib/filter/base-frontend-filter/handler';

export default function useGoalFilter(baseFilter, viewParamsService) {
  const lookupPropertySvc = useLookupProperty();
  const inFilter = (goal) => {
    if (!inDefaultFilter(goal)) {
      return false;
    }

    if (!isInFilter({ isFilterMode: true })({ entity: goal, scopeTree: viewParamsService.queryFilter.value, lookupPropertySvc })) {
      return false;
    }

    return goalTitleContains(goal, viewParamsService.searchTerm.value);
  };

  const inDefaultFilter = (goal) => goalIsInCycle(goal, viewParamsService.selectedCycles.value)
  && (baseFilter.value[goalConfig.model] === undefined || filter(goal, baseFilter.value[goalConfig.model].filter));

  return { inFilter, inDefaultFilter };
}
