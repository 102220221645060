<template>
  <page-layout
    class="dashboards"
  >
    <template #topBar>
      <page-top-bar
        :breadcrumbs="breadcrumbs"
      >
        <template #actions>
          <div
            class="_actions"
          >
            <favorite-button
              v-if="!$store.state.breakpoint.smAndDown"
              :title-suggestion="$t('navigation.dashboards')"
            />
            <m-dropdown
              v-else
              v-model:value="showFurtherActions"
              :title="$t('general.actions')"
            >
              <m-btn
                icon="ellipsis"
                hide-border
                fab
                small
              />
              <template #overlay>
                <m-card
                  no-padding
                  list
                >
                  <favorite-button
                    :title-suggestion="$t('navigation.dashboards')"
                  />
                </m-card>
              </template>
            </m-dropdown>
          </div>
        </template>
      </page-top-bar>
    </template>
    <scroll-container>
      <m-content
        padding-x="layout"
        class="_content"
      >
        <m-section
          v-if="userHasRight([accessGroupFlag.goalDashboardView])"
          class="_section"
          heading-size="h4"
          :title="$t('gridPageList.systemDashboards', {title: goalSettings.featureNameSingular})"
        >
          <div class="_system-dashboards">
            <system-dashboard-card
              class="_card"
              :icon="{ value: 'LineChart_blue', showBackground: true }"
              :title="$t('gridPageList.progress.title')"
              :sub-title="$t('gridPageList.progress.subtitle')"
              @click="navigateToProgress"
            />
            <system-dashboard-card
              class="_card"
              :icon="{ value: 'HeartOutlined_green', showBackground: true }"
              :title="$t('gridPageList.health.title')"
              :sub-title="$t('gridPageList.health.subtitle')"
              :plan-tag="{ featureFlag: featureFlag.healthDashboard }"
              @click="navigateToHealth"
            />
          </div>
        </m-section>
        <m-section
          class="_section"
          heading-size="h4"
          :title="$t('gridPageList.customDashboards')"
        >
          <div
            v-if="!hasPrivateAllowance"
            class="_alert"
          >
            <allowance-alert
              :pages-count="privatePagesAllowance"
              :pages-allowance="privatePagesAllowance"
              main-message-key="allowanceAlert.messagePrivate"
            />
          </div>
          <div
            v-if="!hasPublicAllowance"
            class="_alert"
          >
            <allowance-alert
              :pages-count="publicPagesCount"
              :pages-allowance="publicPagesAllowance"
              main-message-key="allowanceAlert.messagePublic"
            />
          </div>
          <list-header>
            <template #left>
              <list-tab
                v-for="p in privacyList"
                :key="p.value"
                :active="privacy === p.value"
                @click="privacy = p.value"
              >
                <item-title :title="p.text" />
              </list-tab>
            </template>
            <template #right>
              <list-string-filter
                small
                class="_btn"
                @input="val => searchTerm = val"
              />
              <m-tooltip
                v-if="hasRightsToCreate"
                :disabled="hasPrivateAllowance"
              >
                <m-btn
                  color="primary"
                  small
                  class="_btn _create"
                  :loading="createLoading"
                  :disabled="!hasPrivateAllowance"
                  @click="create"
                >
                  {{ $t('general.new') }}
                </m-btn>
                <template #title>
                  {{ $t('gridPageList.noAllowanceToCreate') }}
                </template>
              </m-tooltip>
            </template>
          </list-header>
        </m-section>
      </m-content>
      <grid-pages-list
        :loading="loading"
        :ids="ids"
        :can-create="hasRightsToCreate && hasPrivateAllowance"
        :has-public-allowance="hasPublicAllowance"
        :private-allowance-available="privateAllowanceAvailable"
        :public-allowance-available="publicAllowanceAvailable"
        @new="create"
      />
    </scroll-container>
  </page-layout>
</template>

<script>
import AllowanceAlert from '@/components/custom-grid/AllowanceAlert.vue';
import FavoriteButton from '@/components/favorite/FavoriteButton.vue';
import GridPagesList from '@/components/custom-grid/grid-page-list/GridPagesList.vue';
import ItemTitle from '@/components/ItemTitle.vue';
import ListHeader from '@/components/list/ListHeader.vue';
import ListStringFilter from '@/components/list/ListStringFilter.vue';
import ListTab from '@/components/list/ListTab.vue';
import PageLayout from '@/components/page/PageLayout.vue';
import PageTopBar from '@/components/page/PageTopBar.vue';
import ScrollContainer from '@/components/page/ScrollContainer.vue';
import SystemDashboardCard from '@/components/custom-grid/grid-page-list/SystemDashboardCard.vue';
import useAccess from '@/composables/access/access';
import useGoalSettings from '@/composables/logged-in-user-account/goal-settings';
import useGridPage from '@/composables/grid-page/grid-page';
import useGridPageAllowance from '@/composables/grid-page/grid-page-allowance';
import useInlineEditing from '@/composables/inline-editing';
import useSnackBar from '@/composables/snackbar';
import useViewNavigator from '@/composables/saved-views/navigator';
import { GOAL_INSIGHTS_HEALTH_VIEW, GOAL_INSIGHTS_PERFORMANCE_VIEW } from '@/route-params';
import { accessGroupFlag, featureFlag, routeName } from 'shared/constants.json';
import { buildIcon } from 'shared/lib/icon';
import { logCatch } from '@/lib/logger/logger';

export default {
  name: 'Dashboards',
  components: {
    ItemTitle,
    ListTab,
    AllowanceAlert,
    SystemDashboardCard,
    GridPagesList,
    PageTopBar,
    ScrollContainer,
    PageLayout,
    ListStringFilter,
    FavoriteButton,
    ListHeader,
  },
  data() {
    const privacyList = [
      {
        text: this.$t('gridPageList.privacy.all'),
        value: 'all',
      },
      {
        text: this.$t('gridPageList.privacy.private'),
        value: false,
      },
      {
        text: this.$t('gridPageList.privacy.public'),
        value: true,
      },
    ];
    return {
      showFurtherActions: false,
      privacy: privacyList[0].value,
      privacyList,
      searchTerm: '',
      loading: false,
      createLoading: false,
      accessGroupFlag,
      featureFlag,
    };
  },
  setup() {
    const { userHasRight } = useAccess();
    const { goalSettings } = useGoalSettings();
    const snackBar = useSnackBar();
    const inlineEditingSvc = useInlineEditing();
    const { linkToRoute } = useViewNavigator();

    const { gridPages, createGridPage, queryGridPages } = useGridPage();
    const { publicPagesCount, privatePagesAllowance, publicPagesAllowance, hasPrivateAllowance, hasPublicAllowance, privateAllowanceAvailable, publicAllowanceAvailable } = useGridPageAllowance();

    return {
      userHasRight,
      goalSettings,
      snackBar,
      inlineEditingSvc,
      linkToRoute,

      gridPages,
      createGridPage,
      queryGridPages,
      hasPrivateAllowance,
      hasPublicAllowance,
      publicPagesCount,
      privatePagesAllowance,
      publicPagesAllowance,
      privateAllowanceAvailable,
      publicAllowanceAvailable,
    };
  },
  computed: {
    ids() {
      return this.gridPages.filter((gp) => {
        if (this.filter.privacy === 'all') {
          return true;
        }

        return this.filter.privacy === gp.isPublic;
      }).filter((gp) => {
        if (this.searchTerm === '') {
          return true;
        }

        return gp.title.toLowerCase().includes(this.searchTerm.toLowerCase());
      }).map((gp) => gp.uid);
    },
    breadcrumbs() {
      return [
        {
          title: this.$t('navigation.dashboards'),
          icons: [{ value: buildIcon('AppstoreOutlined') }],
          disabled: true,
        },
      ];
    },
    filter() {
      return {
        privacy: this.privacy,
        searchTerm: this.searchTerm,
      };
    },
    hasRightsToCreate() {
      return this.userHasRight([accessGroupFlag.createDashboard]);
    },
  },
  methods: {
    fetch() {
      this.loading = true;
      this.queryGridPages().catch(logCatch(() => {
        this.snackBar.error();
      })).finally(() => { this.loading = false; });
    },
    create() {
      this.createLoading = true;
      this.createGridPage().then((gridPage) => {
        this.$router.push(this.linkToRoute('', routeName.dashboardDetails, undefined, undefined, gridPage.uid).to);
        this.createLoading = false;
      });
    },
    navigateToProgress() {
      this.$router.push(this.linkToRoute('', routeName.goalInsights, GOAL_INSIGHTS_PERFORMANCE_VIEW).to);
    },
    navigateToHealth() {
      this.$router.push(this.linkToRoute('', routeName.goalInsights, GOAL_INSIGHTS_HEALTH_VIEW).to);
    },
  },
  created() {
    this.fetch();
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
.dashboards {
  ._content {
    margin-bottom: .6rem;

    ._section {
      padding-top: 5rem;
    }

    ._system-dashboards {
      margin: 1.6rem 0;
      display: flex;

      ._card {
        width: 32rem;
        margin-right: 1.6rem;
      }
    }

    ._alert {
      margin-bottom: .8rem;
    }

    ._btn {
      margin-left: .8rem;
    }
  }
}
</style>
