<template>
  <div class="release-notes-wrapper">
    <m-content
      :padding-x="7"
      :padding-y="8"
      class="_header-wrapper"
    >
      <div class="_header">
        <div class="_left">
          <div class="_title">
            {{ $t('releaseNotesWrapper.title') }}
          </div>
          <div class="_btn">
            <m-btn
              :href="releaseNotesLink"
              target="_blank"
            >
              {{ $t('releaseNotesWrapper.open') }}
              <m-icon
                class="_icon"
                type="external-link"
                :style="{ marginLeft: '.8rem' }"
              />
            </m-btn>
          </div>
        </div>
        <div class="_right">
          <div class="_close">
            <m-btn
              fab
              icon="close"
              hide-border
              @click="$emit('close')"
            />
          </div>
        </div>
      </div>
    </m-content>
    <div class="_release-notes">
      <release-notes
        :unread-release-notes="unreadReleaseNotes.map(rn => rn._id)"
        :release-notes="releaseNotes"
        :can-edit-goal-settings="canEditGoalSettings"
        :tree-view-link="treeViewLink"
      />
      <div class="_more">
        <m-btn
          :href="readMoreLink"
          target="_blank"
          block
          icon="external-link"
        >
          {{ $t('releaseNotesWrapper.readMore') }}
        </m-btn>
      </div>
    </div>
    <m-content
      padding
      class="_footer"
    >
      {{ $t('releaseNotesWrapper.linkedInLabel') }}
      <m-btn
        icon="linkedIn"
        href="https://www.linkedin.com/company/mooncamp/"
        target="_blank"
        fab
        hide-border
        small
        class="_linked-in-btn"
        light
      />
    </m-content>
  </div>
</template>

<script>
import ReleaseNotes from '@/release-notes/ReleaseNotes.vue';
import useAccess from '@/composables/access/access';
import useSavedViewRepo from '@/composables/saved-views/saved-view-repo';
import { accessGroupFlag, moduleFlag, viewType } from 'shared/constants.json';
import { environmentVariable, resolveEnvironmentVariable } from '@/lib/env';

export default {
  name: 'ReleaseNotesWrapper',
  props: {
    unreadReleaseNotes: {
      type: Array,
      required: true,
    },
    releaseNotes: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const {
      accountHasFeature,
      userHasRight,
    } = useAccess();
    const { entityList: savedViews } = useSavedViewRepo();
    return { accountHasFeature, userHasRight, savedViews };
  },
  components: { ReleaseNotes },
  emits: ['close'],
  computed: {
    treeViewLink() {
      const savedView = this.savedViews.find((v) => v.viewType === viewType.tree && v.creator === null);
      if (savedView === undefined) {
        return null;
      }
      return `/goals?viewId=${savedView.uid}`;
    },
    canEditGoalSettings() {
      return this.userHasRight([accessGroupFlag.goalCycleWriteAccess])
          && this.accountHasFeature([moduleFlag.goals]);
    },
    readMoreLink() {
      return this.$t('releaseNotesWrapper.readMoreLink', { baseURL: resolveEnvironmentVariable(environmentVariable.WEBSITE_URL) });
    },
    releaseNotesLink() {
      return this.$t('releaseNotesWrapper.releaseNotesLink', { baseURL: resolveEnvironmentVariable(environmentVariable.WEBSITE_URL) });
    },
  },
  watch: {
    $route() {
      this.$emit('close');
    },
  },
};
</script>
<style
    scoped
    lang="scss"
    type="text/scss"
>
  $header-height: 6rem;
  $footer-height: 6rem;

  .release-notes-wrapper {
    display: grid;
    grid-template-rows: $header-height 1fr $footer-height;
    grid-template-columns: 1fr;
    height: 80vh;

    ._release-notes {
      padding: 4rem map_get($padding, '15');
      overflow: auto;

      ._more {
        margin-bottom: 3rem;
      }
    }

    ._header-wrapper {
      border-bottom: 1px solid $input-border-color;

      ._header {
        display: flex;

        ._left {
          display: flex;
          align-items: center;
          margin-left: 1rem;

          ._title {
            margin-right: 1.2rem;
            font-size: $font-size-8;
            font-weight: $font-weight-medium;
          }
        }

        ._right {
          margin-left: auto;
        }
      }
    }

    ._footer {
      display: flex;
      align-items: center;
      font-weight: $font-weight-medium;
      color: $font-color-secondary;
      border-top: 1px solid $input-border-color;

      ._linked-in-btn {
        margin-left: .4rem;
      }
    }
  }
</style>
