<template>
  <div class="breadcrumbs">
    <template v-if="breadcrumbs.length > sliceAmount">
      <breadcrumb-item
        :item="breadcrumbs[0].items[0]"
        :amount="breadcrumbs.length"
        :max-width="modal ? '18rem' : '23rem'"
      />
      <span
        class="_divider"
      >/</span>
    </template>
    <template v-if="hiddenCrumbs.length > 0">
      <div
        class="_crumb"
      >
        <m-dropdown :title="$t('breadcrumbs.items')">
          <m-btn
            hide-border
            small
            class="_link"
            icon="ellipsis"
            fab
          />
          <template #overlay>
            <m-card
              class="_overlay"
              small-padding
            >
              <div class="_inner">
                <goal-breadcrumb-details :item="goal" />
              </div>
            </m-card>
          </template>
        </m-dropdown>
      </div>
      <span
        class="_divider"
      >/</span>
    </template>
    <template
      v-for="(item, i) in crumbs"
    >
      <template v-if="!item.hidden">
        <breadcrumb-item
          :key="i"
          :amount="breadcrumbs.length"
          :item="item.items[0]"
          :max-width="modal ? '18rem' : '23rem'"
          :class="['_crumb', i === (crumbs.length - 1) ? '-last' : '', i === 0]"
        />
        <span
          v-if="i !== (crumbs.length - 1)"
          :key="`divider_${i}`"
          class="_divider"
        >/</span>
      </template>
    </template>
  </div>
</template>

<script>

import BreadcrumbItem from '@/components/breadcrumbs/BreadcrumbItem.vue';
import GoalBreadcrumbDetails from '@/components/breadcrumbs/GoalBreadcrumbDetails.vue';
import useGoalSettings from '@/composables/logged-in-user-account/goal-settings';
import useGoalTypeProperty from '@/composables/customize-page/goal-type-property';
import useGoals from '@/composables/goal/goals';
import useOpenPeekMode from '@/composables/goal/open-peek-mode';
import { GOALS_EXPLORER } from '@/route-names';
import { breadcrumbs } from '@/lib/goal/breadcrumbs';
import { toRef } from 'vue';

export default {
  name: 'GoalBreadcrumbs',
  props: {
    goal: {
      type: Object,
      required: true,
    },
    sliceAmount: {
      type: Number,
      default: 2,
    },
    modal: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  components: { GoalBreadcrumbDetails, BreadcrumbItem },
  setup(props) {
    const { goalTypeIcon } = useGoalTypeProperty();
    const { goalSettings } = useGoalSettings();
    const { selectSingle } = useGoals();
    const peekModeSvc = useOpenPeekMode(toRef(props, 'readOnly'));
    return { selectSingle, goalTypeIcon, peekModeSvc, goalSettings };
  },
  computed: {
    breadcrumbs() {
      const bc = breadcrumbs(this.goal, this.selectSingle, this.goalTypeIcon).map((b) => b.map((i) => ({
        ...i,
        title: i.title === '' ? this.$t('general.untitled') : i.title,
        onClick: () => this.peekModeSvc.openGoal({ goalId: i.uid, modal: false }),
      })));
      if (!this.modal && this.goal.publishedAt !== null) {
        bc.unshift([{
          title: this.$t('goal.detailHeader.allGoals', { title: this.goalSettings.featureNamePlural }),
          disabled: false,
          to: { name: GOALS_EXPLORER },
        }]);
      }

      return bc.map((b, index) => {
        const hidden = index !== 0 && index < bc.length + this.sa;
        const disabled = bc.length - 1 === index;

        return {
          ...b,
          items: b,
          hidden,
          disabled,
        };
      });
    },
    sa() {
      if (this.$store.state.breakpoint.smAndDown) {
        return -1;
      }

      return -this.sliceAmount;
    },
    crumbs() {
      return this.breadcrumbs.filter((b) => !b.hidden).slice(this.sa);
    },
    hiddenCrumbs() {
      return this.breadcrumbs.filter((b) => b.hidden);
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .breadcrumbs {
    display: flex;
    align-items: center;
    max-width: 100%;

    ._crumb {
      display: flex;
      height: $breadcrumb-height;
      margin: 0 .4rem;
      border-radius: $border-radius-sm;

      ._link {
        padding: 0 .3rem;
      }

      &.-first {
        margin-left: 0;
      }

      &.-last {
        margin-right: 0;
      }
    }

    ._divider {
      display: flex;
      align-items: center;
      height: $breadcrumb-height;
    }
  }

  ._item-link {
    color: $font-color-primary;
  }

  ._overlay {
    height: inherit;

    ._inner {
      display: flex;
      max-width: 100%;
      overflow-x: auto;
    }
  }
</style>
