import useGoalActivity from '@/composables/goal-activity/goal-activity';
import useGoals from '@/composables/goal/goals';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useUpdatesRepo from '@/composables/updates/updates-repo';
import { EQ, NOT } from 'shared/api/query/constants';
import { EVENTS } from '@/lib/constants';
import { EventBus } from '@/lib/event-bus';
import { extractMentions } from '@/lib/mention/mention';
import { uniqBy } from 'lodash-es';
import { update as updateConfig } from 'shared/api/query/configs.json';
import { updateType } from 'shared/constants.json';

export default function useUpdates() {
  const repo = useUpdatesRepo();

  const { loggedInUser } = useLoggedInUser();
  const { loggedInUserAccount } = useLoggedInUserAccount();
  const goalsSvc = useGoals();
  const goalActivitySvc = useGoalActivity();

  const increaseCommentCount = (updates) => {
    const goals = goalsSvc.selectMultiple(updates.filter((update) => update.goal !== null && update.goalActivities.length === 0).map((update) => update.goal?.id));
    goals.forEach((g) => {
      goalsSvc.updateSingle({ uid: g.uid, commentCount: g.commentCount + 1 }, { commitToRemote: false });
    });
  };

  const decreaseCommentCount = (updates) => {
    const goals = goalsSvc.selectMultiple(updates.filter((update) => update.goal !== null && update.goalActivities.length === 0).map((update) => update.goal?.id));
    goals.forEach((g) => {
      goalsSvc.updateSingle({ uid: g.uid, commentCount: g.commentCount - 1 }, { commitToRemote: false });
    });
  };

  const updateTypesFilterObject = (types) => ({
    filterTree: { func: { name: EQ, attr: updateConfig.edges.type, args: types.map((t) => ({ value: t })) } },
    varBlocks: [],
  });

  const updateGeneratedFilterObject = () => ({
    filterTree: {
      op: NOT,
      child: [{
        func: {
          name: EQ,
          attr: updateConfig.edges.generated,
          args: [{ value: 'true' }],
        },
      }],
    },
    varBlocks: [],
  });

  const userFilterObject = (userId) => ({
    filterTree: {
      func: {
        name: 'uid_in',
        attr: updateConfig.edges.creator,
        args: [{ value: `${userId}` }],
      },
    },
    varBlocks: [],
  });

  const checkinTypeFilter = {
    filterTree: {
      func: {
        name: EQ,
        attr: updateConfig.edges.type,
        args: [{ value: updateType.checkin }],
      },
    },
    varBlocks: [],
  };

  const createUpdate = (update) => {
    const newGoalActivities = update.goalActivities.filter((ga) => ga.uid === undefined || ga.uid === 0);

    return repo.createSingle({
      ...update,
      creator: { uid: loggedInUser.value.uid },
      account: { uid: loggedInUserAccount.value.uid },
      mentions: uniqBy([
        ...extractMentions(update.message),
        ...update.goalActivities.reduce((res, next) => [...res, ...extractMentions(next.message)], []),
      ], 'uid'),
    }).then((update) => {
      increaseCommentCount([update]);
      if (update.goal === null) {
        EventBus.$emit(EVENTS.CHECKIN.UPDATE_CREATED, { update });
      }
      if (newGoalActivities.length > 0) {
        goalActivitySvc.increaseGoalActivitiesCount(newGoalActivities);
        EventBus.$emit(EVENTS.GOAL.GOAL_ACTIVITIES_CREATED, { goalActivities: newGoalActivities });
      }
      return update;
    });
  };

  const updateUpdate = (update) => {
    const newGoalActivities = update.goalActivities.filter((ga) => ga.uid === undefined || ga.uid === 0);
    const deletedGoalActivities = update.goalActivities.filter((ga) => ga.deletedAt !== undefined);
    return repo.updateSingle({
      ...update,
      mentions: uniqBy([
        ...extractMentions(update.message),
        ...update.goalActivities.reduce((res, next) => [...res, ...extractMentions(next.message)], []),
      ], 'uid'),
    }).then((update) => {
      if (newGoalActivities.length > 0) {
        goalActivitySvc.increaseGoalActivitiesCount(newGoalActivities);
        EventBus.$emit(EVENTS.GOAL.GOAL_ACTIVITIES_CREATED, { goalActivities: newGoalActivities });
      }
      if (deletedGoalActivities.length > 0) {
        goalActivitySvc.decreaseGoalActivitiesCount(deletedGoalActivities);
      }
      return update;
    });
  };

  const deleteUpdate = (update) => repo.deleteSingle(update.uid).then(() => {
    decreaseCommentCount([update]);
    if (update.goal === null) {
      EventBus.$emit(EVENTS.CHECKIN.UPDATE_DELETED);
    }
  });

  const fetchUpdatesForUser = ({ order, pagination } = { order: [], pagination: { itemsPerPage: 10, page: 1 } }) => repo.getList({ filterObjects: [userFilterObject(loggedInUser.value.uid), checkinTypeFilter], order, pagination }).then((res) => res.updates);

  return {
    updateTypesFilterObject,
    updateGeneratedFilterObject,

    ...repo,
    createUpdateLoading: repo.createSingleLoading,
    createUpdate,
    updateUpdateLoading: repo.updateSingleLoading,
    updateUpdate,
    deleteUpdateLoading: repo.deleteSingleLoading,
    deleteUpdate,
    fetchUpdateLoading: repo.getSingleLoading,
    fetchUpdate: repo.getSingle,
    fetchUpdatesLoading: repo.getListLoading,
    fetchUpdates: repo.getList,
    fetchUpdatesForGoal: repo.getListForGoal,
    fetchUpdatesForUser,

    increaseCommentCount,
    decreaseCommentCount,

    updates: repo.entityList,
  };
}
