import { DIRECT_PROPERTY_PROGRESS_KEY } from '@/lib/constants';
import { propertyVisibility } from 'shared/constants.json';
import { useI18n } from 'vue-i18n';

export default function useVisibilityOptions(property) {
  const { t } = useI18n();

  const directPropsVisibilityOptions = [
    {
      text: t('propertyLabel.alwaysVisible'),
      value: propertyVisibility.alwaysVisible,
    },
    {
      text: t('propertyLabel.alwaysHide'),
      value: propertyVisibility.alwaysHidden,
    },
  ];

  const visibilityOptions = [
    {
      text: t('propertyLabel.alwaysVisible'),
      value: propertyVisibility.alwaysVisible,
    },
    {
      text: t('propertyLabel.hideWhenEmpty'),
      value: propertyVisibility.hideWhenEmpty,
    },
    {
      text: t('propertyLabel.alwaysHide'),
      value: propertyVisibility.alwaysHidden,
    },
  ];

  const options = () => {
    if (property.key === DIRECT_PROPERTY_PROGRESS_KEY) {
      return directPropsVisibilityOptions;
    }
    return visibilityOptions;
  };

  return { options: options() };
}
