import { productPlan } from 'shared/constants.json';

export const pricingTableHeaderConfig = ($t) => [
  {
    id: productPlan.business,
    priceEUR: 6,
    priceUSD: 6,
    priceHint: $t('pricingTable.perUserPerMonth'),
    canUpgradeFrom: [productPlan.trial],
    canDowngradeFrom: [],
  },
  {
    id: productPlan.pro,
    priceEUR: 10,
    priceUSD: 10,
    priceHint: $t('pricingTable.perUserPerMonth'),
    canUpgradeFrom: [productPlan.trial, productPlan.business],
    canDowngradeFrom: [],
  },
  {
    id: productPlan.enterprise,
    priceEUR: undefined,
    priceUSD: undefined,
    canUpgradeFrom: productPlan.all,
    canDowngradeFrom: [],
  },
];
export const pricingTableConfig = ($t) => [
  {
    text: $t('pricingTable.goalsAndOkrs'),
    isTitle: true,
    columns: [
      { text: null },
      { text: null },
      { text: null },
    ],
  },
  {
    text: $t('pricingTable.unlimited_okrs'),
    columns: [
      { icon: 'check-mark' },
      { icon: 'check-mark' },
      { icon: 'check-mark' },
    ],
  },
  {
    text: $t('pricingTable.customizability'),
    columns: [
      { icon: 'check-mark' },
      { icon: 'check-mark' },
      { icon: 'check-mark' },
    ],
  },
  {
    text: $t('pricingTable.private_goals'),
    columns: [
      { icon: 'check-mark' },
      { icon: 'check-mark' },
      { icon: 'check-mark' },
    ],
  },
  {
    text: $t('pricingTable.messengers'),
    columns: [
      { icon: 'check-mark' },
      { icon: 'check-mark' },
      { icon: 'check-mark' },
    ],
  },
  {
    text: $t('pricingTable.integrations'),
    columns: [
      { icon: 'check-mark' },
      { icon: 'check-mark' },
      { icon: 'check-mark' },
    ],
  },
  {
    text: $t('pricingTable.planningSpaces'),
    tooltip: $t('pricingTable.planningSpacesTooltip'),
    columns: [
      { icon: 'check-mark' },
      { icon: 'check-mark' },
      { icon: 'check-mark' },
    ],
  },
  {
    text: $t('pricingTable.goalTree'),
    tooltip: $t('pricingTable.goalTreeTooltip'),
    columns: [
      { icon: null },
      { icon: 'check-mark' },
      { icon: 'check-mark' },
    ],
  },
  {
    text: $t('pricingTable.okrRules'),
    tooltip: $t('pricingTable.okrRulesTooltip'),
    columns: [
      { icon: null },
      { icon: 'check-mark' },
      { icon: 'check-mark' },
    ],
  },
  {
    text: $t('pricingTable.multiAlignment'),
    tooltip: $t('pricingTable.multiAlignmentTooltip'),
    columns: [
      { text: null },
      { icon: 'check-mark' },
      { icon: 'check-mark' },
    ],
  },
  {
    text: $t('pricingTable.premiumIntegrations'),
    tooltip: $t('pricingTable.premiumIntegrationsTooltip'),
    columns: [
      { text: null },
      { icon: 'check-mark' },
      { icon: 'check-mark' },
    ],
  },
  {
    text: $t('pricingTable.goalWeights'),
    tooltip: $t('pricingTable.goalWeightsTooltip'),
    columns: [
      { text: null },
      { icon: 'check-mark' },
      { icon: 'check-mark' },
    ],
  },
  {
    text: $t('pricingTable.tvMode'),
    tooltip: $t('pricingTable.tvModeTooltip'),
    columns: [
      { text: null },
      { text: null },
      { icon: 'check-mark' },
    ],
  },
  {
    text: $t('pricingTable.checkIns'),
    isTitle: true,
    columns: [
      { text: null },
      { text: null },
      { text: null },
    ],
  },
  {
    text: $t('pricingTable.reminders'),
    columns: [
      { icon: 'check-mark' },
      { icon: 'check-mark' },
      { icon: 'check-mark' },
    ],
  },
  {
    text: $t('pricingTable.checkInTemplates'),
    columns: [
      { icon: 'check-mark' },
      { icon: 'check-mark' },
      { icon: 'check-mark' },
    ],
  },
  {
    text: $t('pricingTable.checkInMessengers'),
    columns: [
      { icon: 'check-mark' },
      { icon: 'check-mark' },
      { icon: 'check-mark' },
    ],
  },
  {
    text: $t('pricingTable.analytics'),
    isTitle: true,
    columns: [
      { text: null },
      { text: null },
      { text: null },
    ],
  },
  {
    text: $t('pricingTable.dashboards'),
    columns: [
      { icon: 'check-mark' },
      { icon: 'check-mark' },
      { icon: 'check-mark' },
    ],
  },
  {
    text: $t('pricingTable.premiumDashboards'),
    tooltip: $t('pricingTable.premiumDashboardsTooltip'),
    columns: [
      { icon: null },
      { icon: 'check-mark' },
      { icon: 'check-mark' },
    ],
  },
  {
    text: $t('pricingTable.privateCustomDashboards'),
    tooltip: $t('pricingTable.privateCustomDashboardsTooltip'),
    columns: [
      { text: $t('pricingTable.unlimited') },
      { text: $t('pricingTable.unlimited') },
      { text: $t('pricingTable.unlimited') },
    ],
  },
  {
    text: $t('pricingTable.publicCustomDashboards'),
    tooltip: $t('pricingTable.publicCustomDashboardsTooltip'),
    columns: [
      { text: '1' },
      { text: '20' },
      { text: $t('pricingTable.unlimited') },
    ],
  },
  {
    text: $t('pricingTable.admin'),
    isTitle: true,
    columns: [
      { text: null },
      { text: null },
      { text: null },
    ],
  },
  {
    text: $t('pricingTable.adminGoogleSso'),
    columns: [
      { icon: 'check-mark' },
      { icon: 'check-mark' },
      { icon: 'check-mark' },
    ],
  },
  {
    text: $t('pricingTable.adminMsSso'),
    columns: [
      { icon: 'check-mark' },
      { icon: 'check-mark' },
      { icon: 'check-mark' },
    ],
  },
  {
    text: $t('pricingTable.customSaml'),
    columns: [
      { text: null },
      { text: null },
      { icon: 'check-mark' },
    ],
  },
  {
    text: $t('pricingTable.scimProvisioning'),
    columns: [
      { text: null },
      { text: null },
      { icon: 'check-mark' },
    ],
  },
  {
    text: $t('pricingTable.support.title'),
    isTitle: true,
    columns: [
      { text: null },
      { text: null },
      { text: null },
    ],
  },
  {
    text: $t('pricingTable.support.helpcenter'),
    columns: [
      { icon: 'check-mark' },
      { icon: 'check-mark' },
      { icon: 'check-mark' },
    ],
  },
  {
    text: $t('pricingTable.support.level'),
    columns: [
      {
        text: $t('pricingTable.support.levelStandard'),
        tooltip: $t('pricingTable.support.levelPriorityTooltip'),
      },
      {
        text: $t('pricingTable.support.levelStandard'),
        tooltip: $t('pricingTable.support.levelPriorityTooltip'),
      },
      { text: $t('pricingTable.support.levelPriority') },
    ],
  },
  {
    text: $t('pricingTable.support.onboarding'),
    columns: [
      { text: null },
      { icon: 'check-mark' },
      { icon: 'check-mark' },
    ],
  },
  {
    text: $t('pricingTable.support.rollout'),
    columns: [
      { text: null },
      { text: null },
      { icon: 'check-mark' },
    ],
  },
  {
    text: $t('pricingTable.support.sla'),
    columns: [
      { text: null },
      { text: null },
      { icon: 'check-mark' },
    ],
  },
  {
    text: $t('pricingTable.support.account_manager'),
    columns: [
      { text: null },
      { text: null },
      { icon: 'check-mark' },
    ],
  },
  {
    text: $t('pricingTable.support.contract'),
    columns: [
      { text: null },
      { text: null },
      { icon: 'check-mark' },
    ],
  },
  {
    text: $t('pricingTable.support.dpa'),
    columns: [
      { text: null },
      { text: null },
      { icon: 'check-mark' },
    ],
  },
];
