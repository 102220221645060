<template>
  <m-dropdown
    v-model:value="showMenu"
    :title="$t('reactionList.addEmoji')"
    @hide="$emit('hide')"
  >
    <m-btn
      icon="smile"
      :button-style="buttonStyle"
      :small="small"
      :hide-border="hideBorder"
      :light="light"
      :fab="fab"
      @click="show"
    >
      <template v-if="showText">
        {{ $t('updateFeedItem.addReaction') }}
      </template>
    </m-btn>
    <template #overlay>
      <icon-picker-card
        hide-actions
        @change="addOrRemove"
      />
    </template>
  </m-dropdown>
</template>

<script>
import IconPickerCard from '@/components/IconPickerCard.vue';
import useReactions from '@/composables/reactions/reactions';
import { toRef } from 'vue';

export default {
  name: 'AddReactionBtn',
  props: {
    update: {
      type: Object,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    showText: {
      type: Boolean,
      default: false,
    },
    hideBorder: {
      type: Boolean,
      default: false,
    },
    light: {
      type: Boolean,
      default: false,
    },
    fab: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    buttonStyle: {
      type: Object,
      default: () => null,
    },
  },
  components: { IconPickerCard },
  emits: ['hide', 'show'],
  setup(props) {
    const { reactions, toggleReaction } = useReactions(toRef(props, 'update'), props.readOnly);
    return {
      reactions,
      toggleReaction,
    };
  },
  data() {
    return { showMenu: false };
  },
  methods: {
    show() {
      this.showMenu = true;
      this.$emit('show');
    },
    addOrRemove(icon) {
      this.showMenu = false;
      this.toggleReaction(icon.value);
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">

</style>
