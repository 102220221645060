<template>
  <div class="plans">
    <m-content
      padding-x="layout"
      :padding-y="12"
      class="_top"
    >
      <div class="_content">
        <page-header
          :title="$t('plans.title')"
          no-padding
          heading="h3"
          class="_header"
        />
        <plan-hint />
        <m-btn
          v-if="showBillingPortal"
          class="_portal-button"
          color="secondary"
          @click="goToPortal"
        >
          {{ $t('plans.portalButton') }}
        </m-btn>
        <m-divider />
        <m-btn
          small
          super-light
          :href="$t('plans.helpLink')"
          hide-border
          icon="question-circle"
          target="_blank"
        >
          {{ $t('plans.learnMore') }}
        </m-btn>
      </div>
    </m-content>
    <product-plan-selection />
  </div>
</template>

<script>
import PageHeader from 'shared/components/PageHeader.vue';
import PlanHint from '@/components/plans/PlanHint.vue';
import ProductPlanSelection from '@/components/plans/ProductPlanSelection.vue';
import useAccess from '@/composables/access/access';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import { doGetStripePortal } from '@/api';
import { featureFlag } from 'shared/constants.json';

export default {
  name: 'Plans',
  components: { PageHeader, ProductPlanSelection, PlanHint },
  setup() {
    const { accountHasFeature } = useAccess();
    const { loggedInUserAccount } = useLoggedInUserAccount();
    return {
      accountHasFeature,
      account: loggedInUserAccount,
      featureFlag,
    };
  },
  computed: {
    showBillingPortal() {
      const stripeId = this.account.customerContract.stripeId;
      const hasFeature = this.accountHasFeature([featureFlag.usesBillingPortal]);
      return hasFeature && stripeId !== '';
    },
  },
  methods: {
    goToPortal() {
      doGetStripePortal({
        requestType: 'defaultPortal',
        returnUrl: window.location.href,
      }).then((response) => {
        if (response.status !== 200) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
          return;
        }
        window.location.href = response.data.url;
      });
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
@import 'shared/assets/scss/padding';

.plans {
  position: relative;

  ._top {
    position: sticky;
    left: 0;

    ._content {
      width: 100%;
      max-width: 1070px;
      padding-top: 2rem;
      margin: 0 auto;

      ._portal-button {
        margin-top: 2rem;
      }
    }

  }

  ._alert {
    position: sticky;
    left: 0;
    margin-top: 5rem;
    margin-bottom: 5rem;

    @include layoutPaddingX();
  }
}
</style>
