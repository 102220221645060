import { UID } from 'shared/api/query/constants';

export const makeStaticUserFn = () => (scope) => {
  const userIds = scope.staticUsers.map((u) => u.uid);

  const filterTree = {
    func: {
      name: UID,
      uid: userIds,
    },
  };
  return { filterTrees: [filterTree], varBlocks: [] };
};
