<template>
  <div class="info-hint">
    <m-tooltip>
      <div>
        <m-icon
          class="_icon"
          :size="size"
          type="info-circle"
        />
      </div>
      <!--eslint-disable vue/no-v-html-->
      <template #title>
        <div

          class="_description"
          v-html="description"
        />
      </template>
      <!--eslint-enable vue/no-v-html-->
    </m-tooltip>
  </div>
</template>

<script>
export default {
  name: 'InfoHint',
  props: {
    description: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '16',
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .info-hint {
    color: $font-color-secondary;
  }

</style>
