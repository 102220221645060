import { computed } from 'vue';
import { dn } from '@/nebula/denormalize';

export default function useStateSelector(state, model) {
  const entityMap = computed(() => {
    if (dn.value[model] === undefined) {
      return state.nebula[model];
    }
    return dn.value[model];
  });

  const entityList = computed(() => {
    if (dn.value[model] === undefined) {
      return Object.values(state.nebula[model]);
    }
    return Object.values(dn.value[model]);
  });

  const selectSingle = (id) => entityMap.value[id];
  const selectMultiple = (ids) => ids.reduce((res, uid) => {
    if (entityMap.value[uid] === undefined) {
      return res;
    }
    res.push(entityMap.value[uid]);
    return res;
  }, []);

  return {
    entityList,
    entityMap,
    selectSingle,
    selectMultiple,
  };
}
