<template>
  <div class="form-share">
    <template v-if="surveyProcess === null">
      <share-top-bar />
      <div class="_bottom">
        <div class="_container">
          <div
            class="_option"
          >
            <div class="_image">
              <process-graphic />
            </div>
            <div class="_description">
              {{ $t('formShare.noSurveyProcessYet') }}
            </div>
            <div class="_action">
              <m-btn
                color="primary"
                :loading="createBtnLoading"
                @click="create"
              >
                {{ $t('formShare.createProcess') }}
              </m-btn>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div
      v-else
      class="_process-form"
    >
      <form-send :survey-process="surveyProcess" />
    </div>
  </div>
</template>

<script>
import FormSend from '@/components/FormSend.vue';
import ProcessGraphic from '@/assets/img/graphic-process.vue';
import ShareTopBar from '@/components/survey/ShareTopBar.vue';
import useForm from '@/composables/form/form';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import { FORM_SEND_SETTINGS } from '@/route-names';
import { defaultSurveyProcess } from '@/lib/survey-process';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'FormShare',
  components: { FormSend, ShareTopBar, ProcessGraphic },
  setup() {
    const { loggedInUser } = useLoggedInUser();
    const { formId, languages } = useForm();
    const { loggedInUserAccount } = useLoggedInUserAccount();
    return { loggedInUser, account: loggedInUserAccount, formId, languages };
  },
  data() {
    return { createBtnLoading: false };
  },
  computed: {
    ...mapState({
      form: (state) => state.form,
      surveyProcess: (state) => state.surveyProcess,
    }),
  },
  methods: {
    ...mapActions([
      'createSurveyProcess',
    ]),
    create() {
      this.createBtnLoading = true;
      this.createSurveyProcess({
        surveyProcess: defaultSurveyProcess({
          account: { uid: this.account.uid },
          languages: this.languages,
          form: this.form,
        }),
      }).then((response) => {
        this.createBtnLoading = false;
        if (response.status !== 201) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
        }
        this.$router.push({ name: FORM_SEND_SETTINGS, params: { formId: this.formId } });
      });
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .form-share {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    height: inherit;

    ._bottom {
      display: flex;
      justify-content: center;
      padding: 4rem 1.6rem 2rem;

      ._option {
        cursor: pointer;
        border-radius: 1rem;

        ._image {
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 30rem;
            height: auto;
          }
        }

        ._description {
          margin: 2rem 0 3rem;
          font-size: $font-size-5;
          text-align: center;
        }

        ._action {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    ._container {
      width: 100%;
      max-width: 120rem;
      padding: 0 1.6rem;
    }

    ._process-form {
      height: inherit;
    }
  }
</style>
