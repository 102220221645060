// const multiSeries  = []series
// const series = {
//   name: '',
//   data: [] // simple values or {y: '', color: ''}
// }
export const getBaseChart = (categories, multiSeries, type) => ({
  chart: { type },
  xAxis: { categories },
  yAxis: {
    labels: {},
    title: { text: '' },
  },
  series: multiSeries,
});
