<template>
  <div :class="['page-title', subTitle !== '' ? '-has-sub-title' : '']">
    <h1
      v-if="heading === 'h1'"
      class="page-title"
    >
      {{ title }}
      <slot name="after-title" />
    </h1>
    <h2
      v-if="heading === 'h2'"
      class="page-title"
    >
      {{ title }}
      <slot name="after-title" />
    </h2>
    <h3
      v-if="heading === 'h3'"
      class="page-title"
    >
      {{ title }}
      <slot name="after-title" />
    </h3>
    <h4
      v-if="heading === 'h4'"
      class="page-title"
    >
      {{ title }}
      <slot name="after-title" />
    </h4>
    <h5
      v-if="heading === 'h5'"
      class="page-title"
    >
      {{ title }}
      <slot name="after-title" />
    </h5>
    <h6
      v-if="heading === 'h6'"
      :style="{fontWeight: fontWeight}"
      class="page-title"
    >
      {{ title }}
      <slot name="after-title" />
    </h6>
    <p
      v-if="subTitle !== ''"
      :style="['h1', 'h2'].includes(heading) ? { margin: '1.4rem 0' } : { margin: '0 0 .6rem' } "
      class="_sub-title"
    >
      {{ subTitle }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'PageTitle',
  props: {
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      default: '',
    },
    heading: {
      type: String,
      default: 'h1',
    },
    fontWeight: {
      type: String,
      default: 'normal',
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .page-title {
    h1,
    h2,
    h3,
    h4 {
      display: flex;
      align-items: center;
      margin-top: 0;
    }

    &.-has-sub-title {
      h1 {
        margin-bottom: 1rem;
      }

      h2,
      h3,
      h4 {
        margin-bottom: .4rem;
      }
    }

    ._sub-title {
      color: $font-color-secondary;
    }
  }
</style>
