<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      :stroke="c"
      cx="10.5"
      cy="10.5"
      r="9.5"
      stroke-width="2"
    />
  </svg>
</template>

<script>
export default {
  name: 'MCircle',
  props: {
    color: {
      type: String,
      default: '',
    },
  },
  computed: {
    c() {
      if (this.color === '') {
        return this.$colors.grey.darken2;
      }

      return this.color;
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">

</style>
