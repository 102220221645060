export const upToContainerWithClass = (el, className) => {
  if (className === '') {
    return document.body;
  }
  while (el !== null && el !== undefined && el.parentNode !== null && el.parentNode !== undefined) {
    el = el.parentNode;
    if (el.classList !== undefined && el.classList.contains(className)) {
      return el;
    }
  }
  return document.body;
};

export const isElementTruncated = (el) => el.offsetWidth < el.scrollWidth;
