import { UID } from 'shared/api/query/constants';
import { dataSource as dataSourceConfig, goal as goalConfig, hubspotQuery as hubspotQueryConfig } from 'shared/api/query/configs.json';

export const hubspotQueryChildren = [
  { attr: UID },
  { attr: hubspotQueryConfig.edges.trackMetric, default: '' },
  { attr: hubspotQueryConfig.edges.start, default: null },
  { attr: hubspotQueryConfig.edges.end, default: null },
  { attr: hubspotQueryConfig.edges.alwaysSync, default: false },
  {
    attr: hubspotQueryConfig.edges.dataSource,
    model: dataSourceConfig.model,
    default: null,
    children: [{ attr: UID }],
  },
  {
    attr: hubspotQueryConfig.edges.goal,
    model: goalConfig.model,
    children: [{ attr: UID }],
  },
];
