import { CREATED_AT, UID } from 'shared/api/query/constants';
import { mSTeamsConfiguration as mSTeamsConfigurationConfig, propertyOption as propertyOptionConfig } from 'shared/api/query/configs.json';

export const mSTeamsConfigurationChildren = [
  { attr: UID },
  { attr: CREATED_AT },
  {
    model: propertyOptionConfig.model,
    attr: mSTeamsConfigurationConfig.edges.selectedTeam,
    children: [{ attr: UID }],
    default: null,
  },
];
