/**
 * copy returns a copy of the object by stringifying and parsing o.
 * Keys with undefined values are removed.
 * @param {object} o
 */
export const copy = (o) => (o === null || o === undefined ? o : JSON.parse(JSON.stringify(o)));

export const shallowCopy = (o) => {
  if (o === null || o === undefined) {
    return o;
  }
  if (Array.isArray(o)) {
    return [...o];
  }
  if (typeof o === 'object') {
    return { ...o };
  }
  return o;
};
