<template>
  <m-dialog
    v-model:value="showOnboarding"
    :title="$t('planningList.onboarding.title')"
    :max-width="$modalSizes.md"
    fade-in
    hide-footer
  >
    <div
      class="list-onboarding-modal"
    >
      <p>
        <i18n-t keypath="planningList.onboarding.description1">
          <template #title>
            <span class="_bold">{{ $t('planningList.onboarding.drafts') }}</span>
          </template>
        </i18n-t>
      </p>
      <p>
        <i18n-t keypath="planningList.onboarding.description2" />
      </p>
      <p>
        <i18n-t keypath="planningList.onboarding.description3">
          <template #helpCenter>
            <m-link
              :href="$t('planningList.onboarding.helpCenterLink')"
              target="_blank"
              underlined
            >
              <span style="font-weight: 500;">{{ $t('planningList.onboarding.helpCenter') }}</span>
            </m-link>
          </template>
        </i18n-t>
      </p>
      <div class="_buttons">
        <m-btn
          color="primary"
          block
          class="_btn"
          @click="create"
        >
          {{ $t('planningList.onboarding.createPlanning') }}
        </m-btn>
        <m-btn
          block
          @click="showOnboarding = false"
        >
          {{ $t('planningList.onboarding.notNow') }}
        </m-btn>
      </div>
    </div>
  </m-dialog>
</template>

<script>
import useAccountSettings from '@/composables/logged-in-user-account/account-settings';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useOnboardingMarker from '@/composables/onboarding/onboarding-marker';
import {
  onboardingSteps,
} from 'shared/constants.json';

export default {
  name: 'ListOnboardingModal',
  setup() {
    const { loggedInUser } = useLoggedInUser();
    const { loggedInUserAccount } = useLoggedInUserAccount();
    const accountSettingsSvc = useAccountSettings();
    const { markAsDone, isDone } = useOnboardingMarker(loggedInUserAccount, accountSettingsSvc);
    return {
      productTourPlanningListCompleted: isDone([onboardingSteps.planningListTour]),
      loggedInUser,
      markAsDone,
    };
  },
  emits: ['create'],
  data() {
    return { showOnboarding: false };
  },
  methods: {
    create() {
      this.showOnboarding = false;
      this.$emit('create');
    },
  },
  watch: {
    showOnboarding(newVal, oldVal) {
      if (newVal !== false && oldVal !== true) {
        return;
      }

      this.markAsDone([onboardingSteps.planningListTour]).catch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      });
    },
  },
  created() {
    if (!this.productTourPlanningListCompleted) {
      this.showOnboarding = true;
    }
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  .list-onboarding-modal {
    ._bold {
      font-weight: $font-weight-bold;
    }

    ._buttons {
      margin-top: 2rem;

      ._btn {
        margin-bottom: .8rem;
      }
    }
  }
</style>
