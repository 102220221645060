<template>
  <div class="properties">
    <page-header
      :title="$t('properties.pageTitle')"
      :sub-title="$t('properties.pageSubtitle')"
      boxed-xs
      heading="h3"
      underlined
      class="_header"
    />
    <m-content
      boxed-xs
      no-padding
    >
      <m-btn
        small
        super-light
        :href="$t('properties.helpLink')"
        hide-border
        icon="question-circle"
        target="_blank"
        class="_help-btn"
      >
        {{ $t('properties.help') }}
      </m-btn>
    </m-content>
    <m-content
      boxed-xs
      padding
      class="_content"
    >
      <div
        class="_add-btn"
      >
        <m-btn
          color="primary"
          @click="showCreateDrawer = true"
        >
          {{ $t('properties.addButtonLabel') }}
        </m-btn>
        <m-drawer
          :value="showCreateDrawer"
          :title="$t('properties.createNewTitle')"
          width="69rem"
          @close="showCreateDrawer = false"
        >
          <edit-property-form
            :entity="defaultProperty"
            :account="loggedInUserAccount"
            @created="handleCreate"
            @cancel="showCreateDrawer = false"
          />
        </m-drawer>
      </div>
      <m-table
        :columns="columns"
        :data-source="rows"
        :custom-row="handleRowClick"
        :pagination="false"
        rows-clickable
      >
        <template #icon="{ icon }">
          <m-icon
            :type="icon"
          />
        </template>
        <template #status="{ status, row }">
          <div
            style="display: inline-flex;"
            class="_status"
          >
            <m-tag :title="status" />
            <div
              class="_actions"
            >
              <m-btn
                v-if="!row.isNotDeletable"
                icon="delete"
                class="_delete-btn"
                hide-border
                small
                fab
                @click.stop="deleteProperty(row)"
              />
            </div>
          </div>
        </template>
      </m-table>
      <m-drawer
        :value="showDrawer"
        :title="$t('properties.editTitle')"
        width="69rem"
        @close="handleClose"
      >
        <edit-property-form
          v-if="selectedProperty !== null"
          :key="selectedPropertyId"
          :entity="selectedProperty"
          :account="loggedInUserAccount"
          @cancel="showDrawer = false"
          @updated="updateProperty"
        />
      </m-drawer>
    </m-content>
    <m-dialog
      v-model:value="showDeleteModal"
      :footer="null"
      hide-footer
      no-padding
    >
      <delete-property-card
        :property="propertyToDelete"
        @close="showDeleteModal = false"
        @deleted="handleDelete"
      />
    </m-dialog>
  </div>
</template>

<script>
import DeletePropertyCard from '@/components/DeletePropertyCard.vue';
import EditPropertyForm from '@/components/EditPropertyForm.vue';
import PageHeader from 'shared/components/PageHeader.vue';
import useAccess from '@/composables/access/access';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useProperties from '@/composables/property/property';
import { accessGroupFlag, propertyStatus, propertyType, routeName } from 'shared/constants.json';
import { findInArray } from 'shared/lib/array/array';
import { iconByType, statusLabelbyStatus } from '@/lib/property';
import { textByLang } from 'shared/lib/language';

export default {
  name: 'Properties',
  components: {
    DeletePropertyCard,
    EditPropertyForm,
    PageHeader,
  },
  setup() {
    const { userHasRight } = useAccess();
    const { loggedInUser, userLang } = useLoggedInUser();
    const { loggedInUserAccount } = useLoggedInUserAccount();
    const { editableProperties } = useProperties();
    return {
      userHasRight,
      loggedInUser,
      userLang,
      loggedInUserAccount,
      properties: editableProperties,
    };
  },
  data() {
    return {
      showDrawer: false,
      selectedProperty: null,
      showCreateDrawer: false,
      showDeleteModal: false,
      propertyToDelete: null,
      routeName,
    };
  },
  computed: {
    selectedPropertyId() {
      if (this.selectedProperty === null) {
        return 0;
      }

      return this.selectedProperty.uid;
    },
    defaultProperty() {
      return {
        uid: 0,
        type: propertyType.options,
        label: { de: '' },
        description: { de: '' },
        status: propertyStatus.active,
        options: [],
        usedFor: [],
      };
    },
    columns() {
      return [
        {
          key: 'icon',
          dataIndex: 'icon',
          title: this.$t('properties.labelType'),
          scopedSlots: { customRender: 'icon' },
          width: 90,
        },
        {
          key: 'label',
          dataIndex: 'label',
          title: this.$t('properties.labelTitle'),
        },
        {
          key: 'tags',
          dataIndex: 'usedFor',
          title: this.$t('properties.labelUsedFor'),
        },
        {
          key: 'status',
          dataIndex: 'status',
          title: this.$t('properties.labelStatus'),
          scopedSlots: { customRender: 'status' },
        },
      ];
    },
    rows() {
      return this.properties.map((property) => ({
        ...property,
        key: property.uid,
        label: textByLang(property.label, this.userLang),
        icon: iconByType(property),
        status: statusLabelbyStatus(property.status)(),
        usedFor: property.usedFor.map((el) => this.$t(`propertyApplication.${el}`)).join(', '),
        isNotDeletable: property.isNotDeletable,
      }));
    },
    canCreate() {
      return this.userHasRight([accessGroupFlag.propertyWriteAccess]);
    },
  },
  methods: {
    handleDelete() {
      this.showDeleteModal = false;
    },
    handleCreate() {
      this.showCreateDrawer = false;
    },
    handleClose() {
      this.showDrawer = false;
      const query = this.$route.query;
      if (typeof query.propertyId === 'undefined') {
        return;
      }

      this.$router.push({ query: { ...query, propertyId: 0 } });
    },
    updateProperty({ property }) {
      this.selectedProperty = property;
    },
    deleteProperty(property) {
      this.propertyToDelete = this.properties.filter((p) => p.uid === property.uid)[0];
      this.showDeleteModal = true;
    },
    handleRowClick(record) {
      return {
        on: {
          click: () => {
            const filteredProperties = this.properties.filter((property) => property.uid === record.uid);
            if (filteredProperties.length !== 1) {
              return;
            }
            this.selectedProperty = filteredProperties[0];
            this.showDrawer = true;
          },
        },
      };
    },
  },
  created() {
    if (typeof this.$route.query.propertyId !== 'undefined') {
      const propId = parseInt(this.$route.query.propertyId, 10);
      if (propId === 0 || Number.isNaN(propId)) {
        return;
      }
      this.selectedProperty = findInArray({ haystack: this.properties, needle: propId });
      this.showDrawer = true;
    }
  },
};
</script>

<style lang="scss" type="text/scss">
  .properties {
    ._header {
      margin-bottom: 2rem;
    }

    ._alert {
      margin-top: 2rem;
    }

    ._content {
      ._add-btn {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 1.6rem;
      }
    }

    .m-table-row {
      ._actions {
        position: absolute;
        right: 0;
        display: none;

        ._action {
          margin: .5rem;
        }
      }

      &:hover {
        ._actions {
          display: flex;
        }
      }

      ._status {
        position: relative;
        width: 100%;
        min-width: 8rem;
      }
    }
  }

</style>
