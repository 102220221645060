<template>
  <svg
    id="Capa_1"
    enable-background="new 0 0 510.036 510.036"
    height="1em"
    viewBox="0 0 510.036 510.036"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <linearGradient
      id="SVGID_1_"
      gradientUnits="userSpaceOnUse"
      x1="157.206"
      x2="399.801"
      y1="198.835"
      y2="441.429"
    >
      <stop
        offset="0"
        stop-color="#28a265"
      />
      <stop
        offset="1"
        stop-color="#28895e"
      />
    </linearGradient>
    <linearGradient id="lg1">
      <stop
        offset="0"
        stop-color="#108372"
        stop-opacity="0"
      />
      <stop
        offset="1"
        stop-color="#108372"
      />
    </linearGradient>
    <linearGradient
      id="SVGID_2_"
      gradientUnits="userSpaceOnUse"
      x1="410.128"
      x2="371.628"
      xlink:href="#lg1"
      y1="173.746"
      y2="61.246"
    />
    <linearGradient
      id="SVGID_3_"
      gradientUnits="userSpaceOnUse"
      x1="343.295"
      x2="388.016"
      y1="58.746"
      y2="103.468"
    >
      <stop
        offset="0"
        stop-color="#91d1b2"
      />
      <stop
        offset="1"
        stop-color="#28a265"
      />
    </linearGradient>
    <linearGradient
      id="SVGID_4_"
      gradientTransform="matrix(-1 0 0 1 2638.046 0)"
      gradientUnits="userSpaceOnUse"
      x1="2383.023"
      x2="2383.023"
      xlink:href="#lg1"
      y1="463.718"
      y2="513.306"
    />
    <g>
      <path
        d="m68.219 31.876-.052 446.25c-.002 17.528 14.338 31.872 31.866 31.874l309.91.036c17.534.002 31.882-14.342 31.884-31.876l.042-360.725c.001-9.787-3.886-19.174-10.805-26.095l-80.484-80.503c-6.92-6.921-16.305-10.81-26.092-10.811l-224.386-.026c-17.534-.002-31.881 14.342-31.883 31.876z"
        fill="url(#SVGID_1_)"
      />
      <path
        d="m345.913 205.238h-181.78c-11.15 0-20.19 9.05-20.19 20.2v191.57c0 11.15 9.04 20.19 20.19 20.19h181.78c11.15 0 20.19-9.04 20.19-20.19v-191.57c0-11.15-9.04-20.2-20.19-20.2zm-17.098 68.498h-51.505c-4.025 0-7.288-3.263-7.288-7.288v-23.923c0-4.025 3.263-7.288 7.288-7.288h51.505c4.025 0 7.288 3.263 7.288 7.288v23.923c0 4.025-3.263 7.288-7.288 7.288zm-51.505 30h51.505c4.025 0 7.288 3.263 7.288 7.288v20.389c0 4.025-3.263 7.288-7.288 7.288h-51.505c-4.025 0-7.288-3.263-7.288-7.288v-20.389c.001-4.025 3.264-7.288 7.288-7.288zm-44.575 34.964h-51.505c-4.025 0-7.288-3.263-7.288-7.288v-20.389c0-4.025 3.263-7.288 7.288-7.288h51.505c4.025 0 7.288 3.263 7.288 7.288v20.389c0 4.025-3.263 7.288-7.288 7.288zm7.288-96.174v23.923c0 4.025-3.263 7.288-7.288 7.288h-51.505c-4.025 0-7.288-3.263-7.288-7.288v-23.923c0-4.025 3.263-7.288 7.288-7.288h51.505c4.025 0 7.288 3.263 7.288 7.288zm-58.793 126.174h51.505c4.025 0 7.288 3.263 7.288 7.288v23.923c0 4.025-3.263 7.288-7.288 7.288h-51.505c-4.025 0-7.288-3.263-7.288-7.288v-23.923c.001-4.025 3.264-7.288 7.288-7.288zm88.793 31.21v-23.923c0-4.025 3.263-7.288 7.288-7.288h51.505c4.025 0 7.288 3.263 7.288 7.288v23.923c0 4.025-3.263 7.288-7.288 7.288h-51.506c-4.024 0-7.287-3.263-7.287-7.288z"
        fill="#ebeff0"
      />
      <path
        d="m350.551 10.826c-4.74-4.74-10.638-8.056-17.028-9.676v103.922l108.33 108.33v-95.99c0-9.787-3.888-19.173-10.808-26.094z"
        fill="url(#SVGID_2_)"
      />
      <path
        d="m440.76 108.461c.118.513.227 1.011.326 1.492h-97.648c-6.914 0-12.52-5.605-12.52-12.52v-96.834c.763.136 1.565.295 2.392.478 7.279 1.61 13.916 5.353 19.188 10.624l77.655 77.655c5.251 5.251 8.938 11.87 10.607 19.105z"
        fill="url(#SVGID_3_)"
      />
      <path
        d="m441.853 447.219v30.919c0 17.534-14.346 31.88-31.88 31.88h-309.91c-17.529 0-31.87-14.342-31.87-31.87v-30.929z"
        fill="url(#SVGID_4_)"
      />
    </g>
  </svg>
</template>

<script>
export default { name: 'GoogleSheets' };
</script>

<style scoped lang="scss" type="text/scss">

</style>
