import { AND } from 'shared/api/query/constants';
import { guid } from 'shared/lib/uuid';

export const teamGoalsFilter = ({ spaceProperty, team }) => {
  if (spaceProperty === undefined || spaceProperty === null) {
    return null;
  }

  return {
    op: AND,
    key: guid(),
    children: [
      {
        op: AND,
        key: guid(),
        scope: {
          property: spaceProperty,
          spaces: [team],
        },
      },
    ],
  };
};
