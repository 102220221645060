import { DateTime } from 'luxon';

export const sortAlphaNumeric = (a, b) => {
  if (a === null && b === null) {
    return 0;
  }
  if (a === null) {
    return -1;
  }
  if (b === null) {
    return 1;
  }

  // convert to strings and force lowercase
  const c = typeof a === 'string' ? a.toLowerCase() : a.toString();
  const d = typeof b === 'string' ? b.toLowerCase() : b.toString();

  const aNum = parseFloat(c);
  const bNum = parseFloat(d);
  /* eslint-disable */
  if (!isNaN(aNum) && !isNaN(bNum)) {
    return aNum - bNum;
  }
  /* eslint-enable */

  return c.localeCompare(d);
};

export const sortByAttr = (attr, sorter) => (a, b) => {
  if (a === null && b === null) {
    return 0;
  }
  if (a === null) {
    return -1;
  }
  if (b === null) {
    return 1;
  }
  return sorter(a[attr], b[attr]);
};

export const sortDatetime = (a, b) => {
  if (a === null && b === null) {
    return 0;
  }
  if (a === null) {
    return -1;
  }
  if (b === null) {
    return 1;
  }
  return DateTime.fromISO(a).diff(DateTime.fromISO(b)).valueOf();
};

export const sortChain = (sorterChain) => (a, b) => {
  let diff = 0;
  for (let i = 0; i < sorterChain.length; i++) {
    diff = sorterChain[i](a, b);
    if (diff !== 0) {
      return diff;
    }
  }
  return diff;
};

/**
 * sortDatetimeInterval returns a sorter that take an array of objects in which 2 attributes are representations of a
 * start date and an end date. Knowing the key to these attributes it sorts the array objects by start date followed by
 * end date in reverse order. This puts bigger ranges on the beginning of the sorted array for elements with the same
 * start. This allows for intersting properties such as a yearly range to be before the individual quarters.
 * @return {function}
 * @param {string} startAttr
 * @param {string} endAttr
 */
export const sortDatetimeInterval = (startAttr, endAttr) => (a, b) => sortChain([
  sortByAttr(startAttr, sortDatetime),
  sortReverse(sortByAttr(endAttr, sortDatetime)),
])(a, b);

export const sortReverse = (sorter) => (a, b) => sorter(b, a);
export const sortReverseMdlwr = (shouldReverse) => (sorter) => {
  if (shouldReverse === true) {
    return sortReverse(sorter);
  }
  return sorter;
};

export const sortByArray = (array, key = 'uid') => {
  const indexMap = array.reduce((acc, next, index) => {
    acc[next[key]] = index;
    return acc;
  }, {});

  return (a, b) => {
    const aIndex = indexMap[a[key]];
    const bIndex = indexMap[b[key]];
    if (typeof aIndex === 'undefined' && typeof bIndex === 'undefined') {
      return 0;
    }
    if (typeof aIndex === 'undefined') {
      return 1;
    }
    if (typeof bIndex === 'undefined') {
      return -1;
    }
    return aIndex - bIndex;
  };
};
