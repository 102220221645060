import { ref } from 'vue';

export default function useGoalTypeCount(goalTypeOptions, noGoalTypeLabel) {
  const init = (goalTypeOptions, noGoalTypeLabel) => [
    ...goalTypeOptions.reduce((res, o) => {
      res.push(
        {
          ...o,
          count: 0,
          diff: undefined,
        },
      );

      return res;
    }, []),
    {
      label: noGoalTypeLabel,
      uid: 'NoType',
      count: 0,
      diff: undefined,
    },
  ];

  const goalTypeCounts = ref(init(goalTypeOptions, noGoalTypeLabel));

  const updateGoalTypeCounts = (prefix, data) => {
    goalTypeCounts.value.forEach((e) => {
      e.count = getOptionCount(`${prefix}${e.uid}`, data) || 0;
    });
  };

  const updateGoalTypeCountsDiff = (prefix, data) => {
    goalTypeCounts.value.forEach((e) => {
      e.diff = getOptionCount(`${prefix}${e.uid}`, data);
    });
  };

  const getOptionCount = (id, data) => Object.keys(data).reduce((res, key) => {
    if (key !== id) {
      return res;
    }
    return data[key][0].count;
  }, undefined);

  return {
    goalTypeCounts,
    updateGoalTypeCounts,
    updateGoalTypeCountsDiff,
  };
}
