<template>
  <m-card
    v-if="hasChart"
    class="factor-history-card"
  >
    <div
      v-if="initialLoading"
      class="_spinner"
    >
      <m-spinner />
    </div>
    <history-chart
      v-else
      :chart-data="chartData"
      :chart="chart"
      :max-value="2"
      :interval-type="intervalType"
      starts-from-zero
    />
  </m-card>
</template>

<script>
import HistoryChart from '@/components/dashboard/HistoryChart.vue';
import useForm from '@/composables/form/form';
import { UserFilterHandler } from '@/lib/filter/user/handler';
import { chartType } from 'shared/constants.json';
import { dataAggregation } from 'shared/num_constants.json';
import { doGetChartData } from '@/api';
import { getIntervalTypeByConfig } from '@/lib/charts/interval-config';
import { mapState } from 'vuex';

export default {
  name: 'FactorHistoryCard',
  props: {
    factor: {
      type: Object,
      required: true,
    },
    filterTree: {
      type: Object,
      default: () => null,
    },
    intervalConfig: {
      type: Object,
      default: () => null,
    },
    filterByNewestAnswer: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { formId, formAvailable } = useForm();
    return { formId, formAvailable };
  },
  components: { HistoryChart },
  data() {
    return {
      initialLoading: false,
      chartData: null,
    };
  },
  computed: {
    ...mapState({ resultData: (state) => state.formResultData }),
    gqlFilter() {
      const handler = new UserFilterHandler(true);
      return handler.Translate(this.filterTree).filterTree;
    },
    hasChart() {
      return this.factor !== null;
    },
    chart() {
      return {
        uid: this.factor.uid,
        factor: this.factor,
        aggregation: dataAggregation.favorability,
        calculateDistribution: true,
        type: chartType.lineChart,
        filterByNewestAnswer: this.filterByNewestAnswer,
      };
    },
    intervalType() {
      return getIntervalTypeByConfig(this.intervalConfig);
    },
  },
  methods: {
    getResultData() {
      if (!this.formAvailable) {
        return;
      }
      const intervalConfig = {
        ...this.intervalConfig,
        type: this.intervalType,
      };

      this.setLoading(true);
      doGetChartData({
        formId: this.formId,
        charts: [this.chart],
        userScopeTree: this.filterTree,
        intervalConfig,
      }).then((response) => {
        if (response.status !== 200) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
          return;
        }
        this.initialLoading = false;
        this.setLoading(false);
        this.chartData = response.data[this.factor.uid];
      });
    },
    setLoading(isLoading) {
      this.$store.commit('FORM_RESULT_SET_LOADING', isLoading);
    },
  },
  watch: {
    formId() {
      this.getResultData();
    },
    factor() {
      this.getResultData();
    },
    gqlFilter() {
      this.getResultData();
    },
    intervalConfig() {
      this.getResultData();
    },
  },
  created() {
    this.initialLoading = true;
    this.getResultData();
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .factor-history-card {
    ._spinner {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 22rem;
    }
  }

</style>
