import colors from 'shared/colors';
import { goalProgressMeasurement, optionColor, propertyType } from 'shared/constants.json';
import { ref } from 'vue';
import { rgbaToHex } from 'shared/lib/color';
import { textByLang } from 'shared/lib/language';

export const companyNodeId = 'company';
export const groupByStatus = 'status';
export const groupByProgress = 'progress';

const getGroup = (grouping, goalSettings, goal) => {
  if (grouping === groupByStatus) {
    const status = goal.properties.find((propertyValue) => propertyValue.property.type === propertyType.status).selectedOptions[0];
    if (status === undefined) {
      return '0';
    }
    return `${status.uid}`;
  }
  if (grouping === groupByProgress) {
    switch (true) {
      case (goal.cachedCalculatedCurrent >= goalSettings.yellowThreshold):
        return optionColor.green;
      case (goal.cachedCalculatedCurrent >= goalSettings.redThreshold):
        return optionColor.yellow;
      case (goal.progressMeasurement === goalProgressMeasurement.none):
        return optionColor.grey;
      default:
        return optionColor.red;
    }
  }
  return '';
};

const maxCircleSize = 80;
const minCircleSize = 20;

/**
 * @return {Number} size
 *
 * getSize returns the size of the circle. It uses the sorting of the goal types
 * to determine the size. The higher on the top of the goal type list, the bigger the
 * circle. The upper bound is 75px (80px - 5px) and the lower bound 20px.
 */
const getSize = (goal, goalTypes) => {
  const type = goal.properties.find((p) => p.property.isGoalType);
  const index = goalTypes.findIndex((t) => type.selectedOptions[0]?.uid === t.uid);
  return Math.max(minCircleSize, maxCircleSize - (5 * (index + 1) * (index + 1)));
};

const reducer = (grouping, goalSettings, goalTypes) => (res, goal) => {
  if (goal.entity.title === undefined) {
    return res;
  }

  if (res.nodes.value.map(({ id }) => id).includes(goal.entity.uid)) {
    return res;
  }

  res.nodes.value.push({
    title: goal.entity.title,
    label: goal.entity.title,
    id: goal.entity.uid,
    group: getGroup(grouping, goalSettings, goal.entity),
    mass: getSize(goal.entity, goalTypes) / 10,
    size: getSize(goal.entity, goalTypes),
    fixed: { x: false, y: false },
  });
  goal.entity.parents.forEach((p) => {
    res.edges.value.push({
      id: `${goal.entity.uid}:belongs_to:${p.uid}`,
      from: goal.entity.uid,
      to: p.uid,
      length: 10,
      color: { color: rgbaToHex(colors.grey.base) },
      title: 'belongs to',
      arrows: { to: { enabled: true } },
    });
  });
  if (goal.entity.parents.length === 0) {
    res.edges.value.push({
      id: `${goal.entity.uid}:belongs_to:${companyNodeId}`,
      from: goal.entity.uid,
      to: companyNodeId,
      length: 10,
      color: { color: rgbaToHex(colors.grey.base) },
      title: 'belongs to',
      arrows: { to: { enabled: true } },
    });
  }

  const propEdges = goal.entity.properties.filter((propertyValue) => propertyValue.property.type !== propertyType.status).reduce((acc, prop) => {
    if ([propertyType.options, propertyType.singleSelect].includes(prop.property.type)) {
      acc.push(...prop.selectedOptions.map((option) => ({
        id: `${goal.entity.uid}:belongs_to:${option.uid}`,
        from: goal.entity.uid,
        to: `option_${option.uid}`,
        length: 10,
        color: { color: rgbaToHex(colors.grey.lighten2) },
        title: 'belongs to',
        arrows: { to: { enabled: false } },
        dashes: true,
      })));
    }
    if ([propertyType.space].includes(prop.property.type)) {
      acc.push(...prop.spaces.map((space) => ({
        id: `${goal.entity.uid}:belongs_to:${space.uid}`,
        from: goal.entity.uid,
        to: `space_${space.uid}`,
        length: 10,
        color: { color: rgbaToHex(colors.grey.lighten2) },
        title: 'belongs to',
        arrows: { to: { enabled: false } },
        dashes: true,
      })));
    }
    return acc;
  }, []);
  res.edges.value.push(...propEdges);

  return res;
};

export const visifyGoals = (goals, account, grouping, goalSettings, props, spaces, userLang, goalTypes) => {
  const res = goals.reduce(reducer(grouping, goalSettings, goalTypes), { nodes: ref([]), edges: ref([]) });

  res.nodes.value.unshift({
    title: typeof account.accountSettings !== 'undefined' && account.accountSettings.vision !== '' ? account.accountSettings.vision : account.companyName,
    label: typeof account.accountSettings !== 'undefined' && account.accountSettings.vision !== '' ? account.accountSettings.vision : account.companyName,
    id: companyNodeId,
    group: 'company',
    mass: 5,
    x: 0,
    y: 0,
    size: 120,
    fixed: { x: true, y: true },
  });

  const propNodes = props.reduce((acc, prop) => {
    if (prop.isDirect) {
      return acc;
    }

    if (!prop.show) {
      return acc;
    }

    if ([propertyType.options, propertyType.singleSelect].includes(prop.property.type)) {
      acc.push(...prop.property.options.map((option) => ({
        title: textByLang(option.label, userLang),
        label: textByLang(option.label, userLang),
        id: `option_${option.uid}`,
        group: 'company',
        mass: 2,
        size: 40,
      })));
    }
    if ([propertyType.space].includes(prop.property.type)) {
      acc.push(...spaces.map((space) => ({
        title: space.title,
        label: space.title,
        id: `space_${space.uid}`,
        group: 'company',
        mass: 2,
        size: 40,
      })));
    }

    return acc;
  }, []);

  res.nodes.value.push(...propNodes);
  return res;
};
