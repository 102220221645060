import { DateTime } from 'luxon';
import { app, authentication, pages } from '@microsoft/teams-js';
import { browser, defaultClient, msTeams } from '@/lib/client-type';
import { computed } from 'vue';
import { doConnectBot, doMSTeamsLogin } from '@/api';
import { encodeURLValues } from '@/lib/url';
import { msTabAction, msTabEntityType, oAuthProvider, routeName } from 'shared/constants.json';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default function useMSTeams() {
  const router = useRouter();
  const store = useStore();

  const attemptLogin = (context) => {
    const query = { action: 'init', provider: oAuthProvider.microsoft, samlEmail: '', loginHint: context.user.loginHint, tenantId: context.user.tenant.id };
    const url = `${window.location.origin}/#/msteams/auth?${encodeURLValues(query)}`;

    return fetch('/api/v1/msteams/client-token', { method: 'GET' }).then(() => authentication.authenticate({
      url,
      width: 600,
      height: 600,
    }).then((result) => {
      const success = JSON.parse(result);
      return doMSTeamsLogin(success);
    }));
  };

  const msTeamsError = { otherEmail: 'otherEmail', connectionError: 'connectionError' };

  const connectBot = (activity) => doConnectBot(activity).then((response) => {
    if (response.status !== 200) {
      if (response.status === 400) {
        return {
          error: msTeamsError.otherEmail,
          data: response.data,
        };
      }

      return { error: msTeamsError.connectionError };
    }

    return { error: null };
  });

  const handleTabAction = (entityId) => new Promise((resolve) => {
    app.initialize().then(() => {
      pages.config.setConfig({ entityId });

      app.getContext().then((c) => {
        if (c.page.subPageId === undefined || c.page.subPageId === '') {
          return;
        }

        const subPageId = JSON.parse(c.page.subPageId);

        if (subPageId.action === msTabAction.connectBot) {
          resolve(connectBot(subPageId.activity));
          return;
        }

        if (typeof subPageId.action === 'undefined' || subPageId.action !== msTabAction.navigate) {
          return;
        }

        switch (subPageId.type) {
          case msTabEntityType.goal:
            if (typeof subPageId.subAction !== 'undefined') {
              router.replace({
                name: routeName.teamsTeamOkrs,
                query: { view: 'goal', goalId: subPageId.id, action: subPageId.subAction },
              });
              break;
            }

            router.replace({ name: routeName.teamsTeamOkrs, query: { view: 'goal', goalId: subPageId.id } });
            break;
          case msTabEntityType.update:
            router.replace({
              name: routeName.teamsTeamOkrs,
              query: { view: 'checkin', updateId: subPageId.id },
            });
            break;
          case msTabEntityType.checkin:
            router.replace({
              name: routeName.teamsTeamOkrs,
              query: { view: 'create-checkin', templateId: subPageId.id, notificationId: subPageId.metadata.notificationId },
            });
            break;
          default:
        }
      });
    });
  });

  const initializeMSTeams = async () => {
    const clientInfo = defaultClient();
    await app.initialize();

    const context = await app.getContext();
    const pageConfig = await pages.getConfig();

    clientInfo.clientType = msTeams;
    clientInfo.msTeamsContext = pageConfig.entityId;
    store.commit('SET_CLIENT_INFO', clientInfo);
    return context;
  };

  const encodeAuthenticationResult = ({ accessToken = '', provider = '', refreshToken = '', tokenExpiry = DateTime.local().toISO(), signedTrackedRequest = '', samlResponse = '' }) => JSON.stringify({ accessToken, tokenExpiry, refreshToken, provider, signedTrackedRequest, samlResponse });

  const clientType = computed(() => store.state.clientInfo.clientType);
  const isMsTeams = computed(() => clientType.value === msTeams);
  const isBrowser = computed(() => clientType.value === browser);

  return {
    attemptLogin,
    handleTabAction,
    msTeamsError,
    initializeMSTeams,

    encodeAuthenticationResult,

    isMsTeams,
    isBrowser,
  };
}
