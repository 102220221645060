import { debug } from '@/lib/logger/logger';
import { difference, union } from 'shared/lib/array/array';
import { onUnmounted } from 'vue';
import { vortex } from '@/plugins/vortex';

const serviceWorker = () => new Promise((resolve) => {
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    registrations.forEach((registration) => {
      resolve(registration.active);
    });
  });
});

const requests = [];
const debugEnv = import.meta.env.DEV;
let logging = false;

export const recordRequests = () => {
  serviceWorker().then(() => {
    navigator.serviceWorker.addEventListener('message', (event) => {
      debug(`sent [${event.data}]`);
      requests.push(event.data);
    });
  });
};

export default function useSubscription() {
  const toClose = [];

  const noopPromise = new Promise((resolve) => { resolve(); });

  const targetIDs = (data, isSelfEvent, includeSelfEvents) => {
    if (isSelfEvent && !includeSelfEvents) {
      return difference(data.systemIDs, data.ids);
    }
    return union(data.ids, data.systemIDs);
  };

  const subscribeSingle = ({ queryFn = noopPromise, syncFn, model, id, includeSelfEvents = false }) => queryFn.then((response) => {
    const listener = (msg) => {
      const data = JSON.parse(msg.data);

      const isSelfEvent = requests.includes(data.requestId);
      const ids = targetIDs(data, isSelfEvent, includeSelfEvents);
      if (ids.length === 0) {
        return;
      }

      if (data.model !== model) {
        return;
      }

      if (ids.includes(id)) {
        const { action } = data;
        syncFn({ action, model, ids: [id], isSelfEvent });
      }
    };

    toClose.push(listener);
    vortex().then((connection) => { connection.addEventListener('message', listener); });
    if (debugEnv && !logging) {
      logging = true;
      vortex().then((connection) => { connection.addEventListener('message', (msg) => { const data = JSON.parse(msg.data); debug(`received [${data.requestId}]:`, data); }); });
    }

    return response;
  });

  const subscribe = ({ queryFn = noopPromise, syncFn, model, includeSelfEvents = false }) => queryFn.then((response) => {
    debug(`subscribing to: ${model}`);
    const listener = (msg) => {
      const data = JSON.parse(msg.data);
      if (data.model !== model) {
        return;
      }

      const isSelfEvent = requests.includes(data.requestId);
      const ids = targetIDs(data, isSelfEvent, includeSelfEvents);
      if (ids.length === 0) {
        return;
      }

      debug(`consuming [${data.action}][${model}][${data.requestId}] - ${ids}`);
      const { action } = data;
      syncFn({ action, model, ids, isSelfEvent });
    };

    toClose.push(listener);
    vortex().then((connection) => { connection.addEventListener('message', listener); });
    if (debug && !logging) {
      logging = true;
      vortex().then((connection) => { connection.addEventListener('message', (msg) => { const data = JSON.parse(msg.data); debug(`received [${data.requestId}]:`, data); }); });
    }

    return response;
  });

  onUnmounted(() => {
    for (let i = 0; i < toClose.length; i++) {
      vortex().then((connection) => { connection.removeEventListener('message', toClose[i]); });
    }
  });

  return {
    subscribe,
    subscribeSingle,
  };
}
