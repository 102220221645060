<template>
  <access-group-page />
</template>

<script>
import AccessGroupPage from '@/components/AccessGroupPage.vue';

export default {
  name: 'AccessGroupDetails',
  components: { AccessGroupPage },
};
</script>

<style scoped lang="scss" type="text/scss">
</style>
