<template>
  <div class="meeting-details">
    <meeting-page
      :id="meetingId"
      :route-after-delete="routeAfterDelete"
    />
    <m-dialog
      :value="showModal"
      :max-width="$modalSizes.xl"
      hide-footer
      no-padding
      keep-height
      hide-header
      top="7rem"
      @close="closeModal"
    >
      <meeting-page
        :id="selectedMeetingId"
        :route-after-delete="''"
        open-in-modal
        @close="closeModal"
      />
    </m-dialog>
  </div>
</template>

<script>
import MeetingPage from '@/components/meeting/MeetingPage.vue';
import { routeName } from 'shared/constants.json';

export default {
  name: 'MeetingDetails',
  components: { MeetingPage },
  computed: {
    selectedMeetingId() {
      const id = parseInt(this.$route.query.meetingId, 10);
      if (Number.isNaN(id)) {
        return 0;
      }

      return id;
    },
    showModal() {
      return typeof this.$route.query.meetingId !== 'undefined';
    },
    meetingId() {
      const id = parseInt(this.$route.params.meetingId, 10);
      if (Number.isNaN(id)) {
        return 0;
      }

      return id;
    },
    routeAfterDelete() {
      return routeName.meetingsExplorer;
    },
  },
  methods: {
    closeModal() {
      const query = { ...this.$route.query };
      delete query.meetingId;
      this.$router.push({ query });
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .meeting-details {
    width: 100%;
  }
</style>
