import { TREE_VIEW_TOUCH_DEVICE_DETECTED } from '@/lib/constants';
import { computed, onBeforeUnmount, ref, watch } from 'vue';
import { datadogRum } from '@datadog/browser-rum';

export default function useViewPort(viewPortTransform, debounceSvc, translateExtend, disabled = ref(false), rightBorderReachedCallback = () => {}) {
  const viewPortElement = ref(null);
  const viewPortBoundingClientRect = ref({});
  const mode = ref('select');
  const minZoomValue = 0.1;
  const maxZoomValue = 1;

  const previousZoomLevel = ref(viewPortTransform.value.k);

  const isTouchDevice = () => (('ontouchstart' in window)
      || (navigator.maxTouchPoints > 0)
      || (navigator.msMaxTouchPoints > 0));

  const changeMode = (m) => {
    mode.value = m;
  };

  const getZoomFactor = (event) => {
    const zoomFactor = () => {
      switch (true) {
        case viewPortTransform.value.k < 0.1:
          return 0.005;
        case viewPortTransform.value.k < 0.2:
          return 0.0075;
        case viewPortTransform.value.k < 0.5:
          return 0.01;
        case viewPortTransform.value.k < 1.5:
          return 0.0125;
        case viewPortTransform.value.k < 2:
          return 0.02;
        case viewPortTransform.value.k < 3:
          return 0.03;
        default:
          return 0.05;
      }
    };

    const useDeltaY = (k) => k * Math.abs(event.deltaY / 2);
    return useDeltaY(zoomFactor(viewPortTransform.value.k));
  };
  const getNewZoomValue = (event) => {
    if (event.deltaY === 0) {
      return viewPortTransform.value.k;
    }
    if (event.deltaY > 0) {
      const k = viewPortTransform.value.k - getZoomFactor(event);
      return restrictValue(k, minZoomValue, maxZoomValue);
    }
    const k = viewPortTransform.value.k + getZoomFactor(event);
    return restrictValue(k, minZoomValue, maxZoomValue);
  };

  const restrictValue = (value, min, max) => Math.max(min, Math.min(max, value));

  const handleWheel = (event) => {
    event.preventDefault();

    const handle = () => {
      if (event.ctrlKey === true || event.metaKey === true) {
        const k = getNewZoomValue(event);
        if (k === viewPortTransform.value.k) {
          return;
        }
        const a = event.clientX - viewPortBoundingClientRect.value.left;
        const b = event.clientY - viewPortBoundingClientRect.value.top;
        zoomToPoint(a, b, k);
        return;
      }
      if (event.shiftKey && event.deltaX === 0 && event.deltaY !== 0) {
        pan({ deltaX: event.deltaY, deltaY: 0 });
        return;
      }
      pan(event);
    };
    debounceSvc.debounce(() => {
      handle();
    }, 10);
  };

  const zoomToCenter = (k, options = { transition: false }) => {
    const a = viewPortBoundingClientRect.value.width / 2;
    const b = viewPortBoundingClientRect.value.height / 2;
    zoomToPoint(a, b, k, options);
  };

  const bezierBlend = (t) => t * t * (3 - 2 * t);
  const transitionTime = 0.3;
  const transitionStepTime = 0.025;
  let timeoutId;
  const setValue = async (x, y, k, options = { transition: false, transitionTime }) => {
    if (disabled.value) {
      return;
    }
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    const t = options.transitionTime ? options.transitionTime : transitionTime;
    if (options.transition === true) {
      const steps = Math.round(t / transitionStepTime);
      const diffX = x - viewPortTransform.value.x;
      const diffY = y - viewPortTransform.value.y;
      const diffK = k - viewPortTransform.value.k;
      const initialValue = { ...viewPortTransform.value };
      for (let i = 1; i <= steps; i++) {
        const value = bezierBlend(i / steps);
        viewPortTransform.value = {
          x: initialValue.x + (diffX * value),
          y: initialValue.y + (diffY * value),
          k: initialValue.k + (diffK * value),
        };
        // eslint-disable-next-line no-await-in-loop,no-loop-func
        await new Promise((r) => {
          timeoutId = setTimeout(r, transitionStepTime * 1000);
        });
      }
      return;
    }
    viewPortTransform.value = { x, y, k };
  };

  const zoomToPoint = (a, b, k, options = { transition: false }) => {
    const oldX = -viewPortTransform.value.x + a;
    const oldY = -viewPortTransform.value.y + b;
    const newX = oldX * (k / viewPortTransform.value.k);
    const newY = oldY * (k / viewPortTransform.value.k);
    const diffX = oldX - newX;
    const diffY = oldY - newY;
    const x = viewPortTransform.value.x + diffX;
    const y = viewPortTransform.value.y + diffY;
    if (viewPortTransform.value.k !== 1) {
      previousZoomLevel.value = viewPortTransform.value.k;
    }
    setValue(x, y, k, options);
  };

  const pan = ({ deltaX, deltaY }, options = { transition: false }) => {
    const xBefore = viewPortTransform.value.x;
    const yBefore = viewPortTransform.value.y;
    const overlap = 100 * viewPortTransform.value.k;
    const x = restrictValue(viewPortTransform.value.x - deltaX, -(translateExtend.value.maxX * viewPortTransform.value.k) + overlap, viewPortBoundingClientRect.value.width + (translateExtend.value.minX * viewPortTransform.value.k) - overlap);
    const y = restrictValue(viewPortTransform.value.y - deltaY, -(translateExtend.value.maxY * viewPortTransform.value.k) + overlap, viewPortBoundingClientRect.value.height + (translateExtend.value.minY * viewPortTransform.value.k) - overlap);
    setValue(x, y, viewPortTransform.value.k, options);
    return { actualDeltaX: xBefore - x, actualDeltaY: yBefore - y };
  };

  const refPoint = ref({ x: 0, y: 0 });
  const startPoint = ref({ x: 0, y: 0 });

  const handleMouseMove = (event) => {
    if (!grabbing.value && Math.abs(event.clientX - startPoint.value.x) < 10 && Math.abs(event.clientY - startPoint.value.y) < 10) {
      return;
    }

    if (!grabbing.value) {
      grabbing.value = true;
    }
    debounceSvc.debounce(() => {
      pan({ deltaX: -(event.clientX - refPoint.value.x), deltaY: -(event.clientY - refPoint.value.y) });
      refPoint.value = { x: event.clientX, y: event.clientY };
    }, 5);
  };

  const grabbing = ref(false);
  const handleMouseUp = () => {
    window.removeEventListener('mousemove', handleMouseMove);
    window.removeEventListener('mouseup', handleMouseUp);
  };

  const handleClick = () => {
    if (grabbing.value) {
      grabbing.value = false;
    }
    window.removeEventListener('click', handleClick);
    window.removeEventListener('auxclick', handleClick);
  };

  const handleToucheMove = (event) => {
    handleMouseMove(event.touches[0]);
  };
  const handleTouchEnd = () => {
    grabbing.value = false;
    window.removeEventListener('touchmove', handleToucheMove);
    window.removeEventListener('touchend', handleTouchEnd);
  };
  const handleTouchStart = (event) => {
    if (event.touches.length >= 2) {
      // we don't support pinch zoom for now. A user will have to use the
      // zoom in and out buttons
      return;
    }

    startPoint.value = { x: event.touches[0].clientX, y: event.touches[0].clientY };
    refPoint.value = { x: event.touches[0].clientX, y: event.touches[0].clientY };
    window.addEventListener('touchmove', handleToucheMove);
    window.addEventListener('touchend', handleTouchEnd);
  };

  const handleMouseDown = (event) => {
    if ((mode.value === 'select' || event.which === 3) && event.which !== 2) {
      // In case a user clicks right click while dragging.
      if (grabbing.value) {
        handleMouseUp();
        handleClick();
      }
      return;
    }
    startPoint.value = { x: event.clientX, y: event.clientY };
    refPoint.value = { x: event.clientX, y: event.clientY };
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
    window.addEventListener('click', handleClick);
    // mouse wheel button does not emit click events, but auxclick events
    window.addEventListener('auxclick', handleClick);
  };

  let resizeHandlerRef;
  const resizeHandler = (element) => () => {
    viewPortBoundingClientRect.value = element.getBoundingClientRect();
  };
  const resizeObserver = ref(null);
  const init = (element) => {
    viewPortElement.value = element;
    resizeHandlerRef = resizeHandler(element);
    resizeHandlerRef();
    window.addEventListener('resize', resizeHandlerRef);
    resizeObserver.value = new window.ResizeObserver(resizeHandlerRef);
    resizeObserver.value.observe(element);
    if (isTouchDevice()) {
      datadogRum.addAction(TREE_VIEW_TOUCH_DEVICE_DETECTED, { window });
      viewPortElement.value.addEventListener('touchstart', handleTouchStart);
    }
    viewPortElement.value.addEventListener('wheel', handleWheel, { capture: true });
    viewPortElement.value.addEventListener('mousedown', handleMouseDown);
  };

  const zoomSteps = [0.05, 0.1, 0.15, 0.2, 0.325, 0.5, 0.75, 1, 1.25, 1.5, 2, 2.5, 3, 4];
  const getZoomStep = (currentZoom, direction) => {
    if (direction === 'zoom_in') {
      for (let i = 0; i < zoomSteps.length; i++) {
        if (zoomSteps[i] > viewPortTransform.value.k) {
          return zoomSteps[i];
        }
      }
      return zoomSteps[zoomSteps.length - 1];
    }
    let res = zoomSteps[0];
    for (let i = 0; i < zoomSteps.length; i++) {
      if (zoomSteps[i] >= viewPortTransform.value.k) {
        return res;
      }
      res = zoomSteps[i];
    }
    return res;
  };
  function zoomIn() {
    zoomToCenter(getZoomStep(viewPortTransform.value.k, 'zoom_in'), { transition: true, transitionTime: 0.2 });
  }
  function zoomOut() {
    zoomToCenter(getZoomStep(viewPortTransform.value.k, 'zoom_out'), { transition: true, transitionTime: 0.2 });
  }

  /*
  The callback should only be called once when the right border is reached.
  It can be called again if the data has changed which changes the
  translate extend.
  If there is no new data to load which means there is no change in the
  translate extend, then the callback is not called again.
   */
  const rightBorderReachedCalled = ref(false);
  watch(viewPortTransform, () => {
    if (!rightBorderReachedCalled.value && -(viewPortTransform.value.x - viewPortBoundingClientRect.value.width) >= translateExtend.value.maxX * viewPortTransform.value.k) {
      rightBorderReachedCalled.value = true;
      rightBorderReachedCallback();
    }
  });

  watch(translateExtend, () => {
    rightBorderReachedCalled.value = false;
  });

  onBeforeUnmount(() => {
    viewPortElement.value.removeEventListener('wheel', handleWheel);
    viewPortElement.value.removeEventListener('mousedown', handleMouseDown);
    window.removeEventListener('resize', resizeHandlerRef);
    resizeObserver.value.unobserve(viewPortElement.value);
  });

  const zoomLevel = computed(() => Math.round(viewPortTransform.value.k * 100));
  const setToPreviousZoomLevel = () => {
    if (viewPortTransform.value.k !== 1) {
      zoomToCenter(1, { transition: true });
      return;
    }
    zoomToCenter(previousZoomLevel.value, { transition: true });
  };

  const contentSize = computed(() => ({
    width: translateExtend.value.maxX - translateExtend.value.minX,
    height: translateExtend.value.maxY - translateExtend.value.minY,
  }));
  const getZoomValue = () => Math.min(viewPortBoundingClientRect.value.width / contentSize.value.width, viewPortBoundingClientRect.value.height / contentSize.value.height);

  const fitToScreen = () => {
    const k = restrictValue(getZoomValue(), minZoomValue, maxZoomValue);
    setValue(Math.max(0, (viewPortBoundingClientRect.value.width - contentSize.value.width * k) / 2), Math.max(0, (viewPortBoundingClientRect.value.height - contentSize.value.height * k) / 2), k, { transition: true });
  };

  const resetTransform = () => {
    setValue(0, 0, viewPortTransform.value.k, { transition: true });
  };

  const zoomToContentPoint = (x, y) => {
    const realX = x * viewPortTransform.value.k;
    const realY = y * viewPortTransform.value.k;
    const centerX = viewPortBoundingClientRect.value.width / 2;
    const centerY = viewPortBoundingClientRect.value.height / 2;
    setValue(centerX - realX, centerY - realY, viewPortTransform.value.k, { transition: true });
  };

  return {
    init,
    viewPortTransform,
    zoomIn,
    zoomOut,
    zoomLevel,
    zoomToContentPoint,
    setToPreviousZoomLevel,
    fitToScreen,
    mode,
    pan,
    resetTransform,
    changeMode,
    handleWheel,
    grabbing,
  };
}
