export const timeZones = [
  {
    gmtOffset: 'GMT-12:00',
    name: 'Etc/GMT+12',
    shortName: '-12',
    value: 'Etc/GMT+12',
  },
  {
    gmtOffset: 'GMT-11:00',
    name: 'Etc/GMT+11',
    shortName: '-11',
    value: 'Etc/GMT+11',
  },
  {
    gmtOffset: 'GMT-11:00',
    name: 'Pacific/Midway',
    shortName: 'SST',
    value: 'Pacific/Midway',
  },
  {
    gmtOffset: 'GMT-11:00',
    name: 'Pacific/Niue',
    shortName: '-11',
    value: 'Pacific/Niue',
  },
  {
    gmtOffset: 'GMT-11:00',
    name: 'Pacific/Pago Pago',
    shortName: 'SST',
    value: 'Pacific/Pago_Pago',
  },
  {
    gmtOffset: 'GMT-11:00',
    name: 'Pacific/Samoa',
    shortName: 'SST',
    value: 'Pacific/Samoa',
  },
  {
    gmtOffset: 'GMT-11:00',
    name: 'US/Samoa',
    shortName: 'SST',
    value: 'US/Samoa',
  },
  {
    gmtOffset: 'GMT-10:00',
    name: 'Etc/GMT+10',
    shortName: '-10',
    value: 'Etc/GMT+10',
  },
  { gmtOffset: 'GMT-10:00', name: 'HST', shortName: 'HST', value: 'HST' },
  {
    gmtOffset: 'GMT-10:00',
    name: 'Pacific/Honolulu',
    shortName: 'HST',
    value: 'Pacific/Honolulu',
  },
  {
    gmtOffset: 'GMT-10:00',
    name: 'Pacific/Johnston',
    shortName: 'HST',
    value: 'Pacific/Johnston',
  },
  {
    gmtOffset: 'GMT-10:00',
    name: 'Pacific/Rarotonga',
    shortName: '-10',
    value: 'Pacific/Rarotonga',
  },
  {
    gmtOffset: 'GMT-10:00',
    name: 'Pacific/Tahiti',
    shortName: '-10',
    value: 'Pacific/Tahiti',
  },
  {
    gmtOffset: 'GMT-10:00',
    name: 'US/Hawaii',
    shortName: 'HST',
    value: 'US/Hawaii',
  },
  {
    gmtOffset: 'GMT-09:30',
    name: 'Pacific/Marquesas',
    shortName: '-0930',
    value: 'Pacific/Marquesas',
  },
  {
    gmtOffset: 'GMT-09:00',
    name: 'America/Adak',
    shortName: 'HDT',
    value: 'America/Adak',
  },
  {
    gmtOffset: 'GMT-09:00',
    name: 'America/Atka',
    shortName: 'HDT',
    value: 'America/Atka',
  },
  {
    gmtOffset: 'GMT-09:00',
    name: 'Etc/GMT+9',
    shortName: '-09',
    value: 'Etc/GMT+9',
  },
  {
    gmtOffset: 'GMT-09:00',
    name: 'Pacific/Gambier',
    shortName: '-09',
    value: 'Pacific/Gambier',
  },
  {
    gmtOffset: 'GMT-09:00',
    name: 'US/Aleutian',
    shortName: 'HDT',
    value: 'US/Aleutian',
  },
  {
    gmtOffset: 'GMT-08:00',
    name: 'America/Anchorage',
    shortName: 'AKDT',
    value: 'America/Anchorage',
  },
  {
    gmtOffset: 'GMT-08:00',
    name: 'America/Juneau',
    shortName: 'AKDT',
    value: 'America/Juneau',
  },
  {
    gmtOffset: 'GMT-08:00',
    name: 'America/Metlakatla',
    shortName: 'AKDT',
    value: 'America/Metlakatla',
  },
  {
    gmtOffset: 'GMT-08:00',
    name: 'America/Nome',
    shortName: 'AKDT',
    value: 'America/Nome',
  },
  {
    gmtOffset: 'GMT-08:00',
    name: 'America/Sitka',
    shortName: 'AKDT',
    value: 'America/Sitka',
  },
  {
    gmtOffset: 'GMT-08:00',
    name: 'America/Yakutat',
    shortName: 'AKDT',
    value: 'America/Yakutat',
  },
  {
    gmtOffset: 'GMT-08:00',
    name: 'Etc/GMT+8',
    shortName: '-08',
    value: 'Etc/GMT+8',
  },
  {
    gmtOffset: 'GMT-08:00',
    name: 'Pacific/Pitcairn',
    shortName: '-08',
    value: 'Pacific/Pitcairn',
  },
  {
    gmtOffset: 'GMT-08:00',
    name: 'US/Alaska',
    shortName: 'AKDT',
    value: 'US/Alaska',
  },
  {
    gmtOffset: 'GMT-07:00',
    name: 'America/Chihuahua',
    shortName: 'MST',
    value: 'America/Chihuahua',
  },
  {
    gmtOffset: 'GMT-07:00',
    name: 'America/Creston',
    shortName: 'MST',
    value: 'America/Creston',
  },
  {
    gmtOffset: 'GMT-07:00',
    name: 'America/Dawson',
    shortName: 'PDT',
    value: 'America/Dawson',
  },
  {
    gmtOffset: 'GMT-07:00',
    name: 'America/Dawson Creek',
    shortName: 'MST',
    value: 'America/Dawson_Creek',
  },
  {
    gmtOffset: 'GMT-07:00',
    name: 'America/Ensenada',
    shortName: 'PDT',
    value: 'America/Ensenada',
  },
  {
    gmtOffset: 'GMT-07:00',
    name: 'America/Fort Nelson',
    shortName: 'MST',
    value: 'America/Fort_Nelson',
  },
  {
    gmtOffset: 'GMT-07:00',
    name: 'America/Hermosillo',
    shortName: 'MST',
    value: 'America/Hermosillo',
  },
  {
    gmtOffset: 'GMT-07:00',
    name: 'America/Los Angeles',
    shortName: 'PDT',
    value: 'America/Los_Angeles',
  },
  {
    gmtOffset: 'GMT-07:00',
    name: 'America/Mazatlan',
    shortName: 'MST',
    value: 'America/Mazatlan',
  },
  {
    gmtOffset: 'GMT-07:00',
    name: 'America/Phoenix',
    shortName: 'MST',
    value: 'America/Phoenix',
  },
  {
    gmtOffset: 'GMT-07:00',
    name: 'America/Santa Isabel',
    shortName: 'PDT',
    value: 'America/Santa_Isabel',
  },
  {
    gmtOffset: 'GMT-07:00',
    name: 'America/Tijuana',
    shortName: 'PDT',
    value: 'America/Tijuana',
  },
  {
    gmtOffset: 'GMT-07:00',
    name: 'America/Vancouver',
    shortName: 'PDT',
    value: 'America/Vancouver',
  },
  {
    gmtOffset: 'GMT-07:00',
    name: 'America/Whitehorse',
    shortName: 'PDT',
    value: 'America/Whitehorse',
  },
  {
    gmtOffset: 'GMT-07:00',
    name: 'Canada/Pacific',
    shortName: 'PDT',
    value: 'Canada/Pacific',
  },
  {
    gmtOffset: 'GMT-07:00',
    name: 'Canada/Yukon',
    shortName: 'PDT',
    value: 'Canada/Yukon',
  },
  {
    gmtOffset: 'GMT-07:00',
    name: 'Etc/GMT+7',
    shortName: '-07',
    value: 'Etc/GMT+7',
  },
  { gmtOffset: 'GMT-07:00', name: 'MST', shortName: 'MST', value: 'MST' },
  {
    gmtOffset: 'GMT-07:00',
    name: 'Mexico/BajaNorte',
    shortName: 'PDT',
    value: 'Mexico/BajaNorte',
  },
  {
    gmtOffset: 'GMT-07:00',
    name: 'Mexico/BajaSur',
    shortName: 'MST',
    value: 'Mexico/BajaSur',
  },
  { gmtOffset: 'GMT-07:00', name: 'PST8PDT', shortName: 'PDT', value: 'PST8PDT' },
  {
    gmtOffset: 'GMT-07:00',
    name: 'US/Arizona',
    shortName: 'MST',
    value: 'US/Arizona',
  },
  {
    gmtOffset: 'GMT-07:00',
    name: 'US/Pacific',
    shortName: 'PDT',
    value: 'US/Pacific',
  },
  {
    gmtOffset: 'GMT-07:00',
    name: 'US/Pacific-New',
    shortName: 'PDT',
    value: 'US/Pacific-New',
  },
  {
    gmtOffset: 'GMT-06:00',
    name: 'America/Bahia Banderas',
    shortName: 'CST',
    value: 'America/Bahia_Banderas',
  },
  {
    gmtOffset: 'GMT-06:00',
    name: 'America/Belize',
    shortName: 'CST',
    value: 'America/Belize',
  },
  {
    gmtOffset: 'GMT-06:00',
    name: 'America/Boise',
    shortName: 'MDT',
    value: 'America/Boise',
  },
  {
    gmtOffset: 'GMT-06:00',
    name: 'America/Cambridge Bay',
    shortName: 'MDT',
    value: 'America/Cambridge_Bay',
  },
  {
    gmtOffset: 'GMT-06:00',
    name: 'America/Costa Rica',
    shortName: 'CST',
    value: 'America/Costa_Rica',
  },
  {
    gmtOffset: 'GMT-06:00',
    name: 'America/Denver',
    shortName: 'MDT',
    value: 'America/Denver',
  },
  {
    gmtOffset: 'GMT-06:00',
    name: 'America/Edmonton',
    shortName: 'MDT',
    value: 'America/Edmonton',
  },
  {
    gmtOffset: 'GMT-06:00',
    name: 'America/El Salvador',
    shortName: 'CST',
    value: 'America/El_Salvador',
  },
  {
    gmtOffset: 'GMT-06:00',
    name: 'America/Guatemala',
    shortName: 'CST',
    value: 'America/Guatemala',
  },
  {
    gmtOffset: 'GMT-06:00',
    name: 'America/Inuvik',
    shortName: 'MDT',
    value: 'America/Inuvik',
  },
  {
    gmtOffset: 'GMT-06:00',
    name: 'America/Managua',
    shortName: 'CST',
    value: 'America/Managua',
  },
  {
    gmtOffset: 'GMT-06:00',
    name: 'America/Merida',
    shortName: 'CST',
    value: 'America/Merida',
  },
  {
    gmtOffset: 'GMT-06:00',
    name: 'America/Mexico City',
    shortName: 'CST',
    value: 'America/Mexico_City',
  },
  {
    gmtOffset: 'GMT-06:00',
    name: 'America/Monterrey',
    shortName: 'CST',
    value: 'America/Monterrey',
  },
  {
    gmtOffset: 'GMT-06:00',
    name: 'America/Ojinaga',
    shortName: 'MDT',
    value: 'America/Ojinaga',
  },
  {
    gmtOffset: 'GMT-06:00',
    name: 'America/Regina',
    shortName: 'CST',
    value: 'America/Regina',
  },
  {
    gmtOffset: 'GMT-06:00',
    name: 'America/Shiprock',
    shortName: 'MDT',
    value: 'America/Shiprock',
  },
  {
    gmtOffset: 'GMT-06:00',
    name: 'America/Swift Current',
    shortName: 'CST',
    value: 'America/Swift_Current',
  },
  {
    gmtOffset: 'GMT-06:00',
    name: 'America/Tegucigalpa',
    shortName: 'CST',
    value: 'America/Tegucigalpa',
  },
  {
    gmtOffset: 'GMT-06:00',
    name: 'America/Yellowknife',
    shortName: 'MDT',
    value: 'America/Yellowknife',
  },
  {
    gmtOffset: 'GMT-06:00',
    name: 'Canada/Mountain',
    shortName: 'MDT',
    value: 'Canada/Mountain',
  },
  {
    gmtOffset: 'GMT-06:00',
    name: 'Canada/Saskatchewan',
    shortName: 'CST',
    value: 'Canada/Saskatchewan',
  },
  {
    gmtOffset: 'GMT-06:00',
    name: 'Etc/GMT+6',
    shortName: '-06',
    value: 'Etc/GMT+6',
  },
  { gmtOffset: 'GMT-06:00', name: 'MST7MDT', shortName: 'MDT', value: 'MST7MDT' },
  {
    gmtOffset: 'GMT-06:00',
    name: 'Mexico/General',
    shortName: 'CST',
    value: 'Mexico/General',
  },
  { gmtOffset: 'GMT-06:00', name: 'Navajo', shortName: 'MDT', value: 'Navajo' },
  {
    gmtOffset: 'GMT-06:00',
    name: 'Pacific/Galapagos',
    shortName: '-06',
    value: 'Pacific/Galapagos',
  },
  {
    gmtOffset: 'GMT-06:00',
    name: 'US/Mountain',
    shortName: 'MDT',
    value: 'US/Mountain',
  },
  {
    gmtOffset: 'GMT-05:00',
    name: 'America/Atikokan',
    shortName: 'EST',
    value: 'America/Atikokan',
  },
  {
    gmtOffset: 'GMT-05:00',
    name: 'America/Bogota',
    shortName: '-05',
    value: 'America/Bogota',
  },
  {
    gmtOffset: 'GMT-05:00',
    name: 'America/Cancun',
    shortName: 'EST',
    value: 'America/Cancun',
  },
  {
    gmtOffset: 'GMT-05:00',
    name: 'America/Cayman',
    shortName: 'EST',
    value: 'America/Cayman',
  },
  {
    gmtOffset: 'GMT-05:00',
    name: 'America/Chicago',
    shortName: 'CDT',
    value: 'America/Chicago',
  },
  {
    gmtOffset: 'GMT-05:00',
    name: 'America/Coral Harbour',
    shortName: 'EST',
    value: 'America/Coral_Harbour',
  },
  {
    gmtOffset: 'GMT-05:00',
    name: 'America/Eirunepe',
    shortName: '-05',
    value: 'America/Eirunepe',
  },
  {
    gmtOffset: 'GMT-05:00',
    name: 'America/Guayaquil',
    shortName: '-05',
    value: 'America/Guayaquil',
  },
  {
    gmtOffset: 'GMT-05:00',
    name: 'America/Indiana/Knox',
    shortName: 'CDT',
    value: 'America/Indiana/Knox',
  },
  {
    gmtOffset: 'GMT-05:00',
    name: 'America/Indiana/Tell City',
    shortName: 'CDT',
    value: 'America/Indiana/Tell_City',
  },
  {
    gmtOffset: 'GMT-05:00',
    name: 'America/Jamaica',
    shortName: 'EST',
    value: 'America/Jamaica',
  },
  {
    gmtOffset: 'GMT-05:00',
    name: 'America/Knox IN',
    shortName: 'CDT',
    value: 'America/Knox_IN',
  },
  {
    gmtOffset: 'GMT-05:00',
    name: 'America/Lima',
    shortName: '-05',
    value: 'America/Lima',
  },
  {
    gmtOffset: 'GMT-05:00',
    name: 'America/Matamoros',
    shortName: 'CDT',
    value: 'America/Matamoros',
  },
  {
    gmtOffset: 'GMT-05:00',
    name: 'America/Menominee',
    shortName: 'CDT',
    value: 'America/Menominee',
  },
  {
    gmtOffset: 'GMT-05:00',
    name: 'America/North Dakota/Beulah',
    shortName: 'CDT',
    value: 'America/North_Dakota/Beulah',
  },
  {
    gmtOffset: 'GMT-05:00',
    name: 'America/North Dakota/Center',
    shortName: 'CDT',
    value: 'America/North_Dakota/Center',
  },
  {
    gmtOffset: 'GMT-05:00',
    name: 'America/North Dakota/New Salem',
    shortName: 'CDT',
    value: 'America/North_Dakota/New Salem',
  },
  {
    gmtOffset: 'GMT-05:00',
    name: 'America/Panama',
    shortName: 'EST',
    value: 'America/Panama',
  },
  {
    gmtOffset: 'GMT-05:00',
    name: 'America/Porto Acre',
    shortName: '-05',
    value: 'America/Porto_Acre',
  },
  {
    gmtOffset: 'GMT-05:00',
    name: 'America/Rainy River',
    shortName: 'CDT',
    value: 'America/Rainy_River',
  },
  {
    gmtOffset: 'GMT-05:00',
    name: 'America/Rankin Inlet',
    shortName: 'CDT',
    value: 'America/Rankin_Inlet',
  },
  {
    gmtOffset: 'GMT-05:00',
    name: 'America/Resolute',
    shortName: 'CDT',
    value: 'America/Resolute',
  },
  {
    gmtOffset: 'GMT-05:00',
    name: 'America/Rio Branco',
    shortName: '-05',
    value: 'America/Rio_Branco',
  },
  {
    gmtOffset: 'GMT-05:00',
    name: 'America/Winnipeg',
    shortName: 'CDT',
    value: 'America/Winnipeg',
  },
  {
    gmtOffset: 'GMT-05:00',
    name: 'Brazil/Acre',
    shortName: '-05',
    value: 'Brazil/Acre',
  },
  { gmtOffset: 'GMT-05:00', name: 'CST6CDT', shortName: 'CDT', value: 'CST6CDT' },
  {
    gmtOffset: 'GMT-05:00',
    name: 'Canada/Central',
    shortName: 'CDT',
    value: 'Canada/Central',
  },
  {
    gmtOffset: 'GMT-05:00',
    name: 'Chile/EasterIsland',
    shortName: '-05',
    value: 'Chile/EasterIsland',
  },
  { gmtOffset: 'GMT-05:00', name: 'EST', shortName: 'EST', value: 'EST' },
  {
    gmtOffset: 'GMT-05:00',
    name: 'Etc/GMT+5',
    shortName: '-05',
    value: 'Etc/GMT+5',
  },
  { gmtOffset: 'GMT-05:00', name: 'Jamaica', shortName: 'EST', value: 'Jamaica' },
  {
    gmtOffset: 'GMT-05:00',
    name: 'Pacific/Easter',
    shortName: '-05',
    value: 'Pacific/Easter',
  },
  {
    gmtOffset: 'GMT-05:00',
    name: 'US/Central',
    shortName: 'CDT',
    value: 'US/Central',
  },
  {
    gmtOffset: 'GMT-05:00',
    name: 'US/Indiana-Starke',
    shortName: 'CDT',
    value: 'US/Indiana-Starke',
  },
  {
    gmtOffset: 'GMT-04:00',
    name: 'America/Anguilla',
    shortName: 'AST',
    value: 'America/Anguilla',
  },
  {
    gmtOffset: 'GMT-04:00',
    name: 'America/Antigua',
    shortName: 'AST',
    value: 'America/Antigua',
  },
  {
    gmtOffset: 'GMT-04:00',
    name: 'America/Aruba',
    shortName: 'AST',
    value: 'America/Aruba',
  },
  {
    gmtOffset: 'GMT-04:00',
    name: 'America/Asuncion',
    shortName: '-04',
    value: 'America/Asuncion',
  },
  {
    gmtOffset: 'GMT-04:00',
    name: 'America/Barbados',
    shortName: 'AST',
    value: 'America/Barbados',
  },
  {
    gmtOffset: 'GMT-04:00',
    name: 'America/Blanc-Sablon',
    shortName: 'AST',
    value: 'America/Blanc-Sablon',
  },
  {
    gmtOffset: 'GMT-04:00',
    name: 'America/Boa Vista',
    shortName: '-04',
    value: 'America/Boa_Vista',
  },
  {
    gmtOffset: 'GMT-04:00',
    name: 'America/Campo Grande',
    shortName: '-04',
    value: 'America/Campo_Grande',
  },
  {
    gmtOffset: 'GMT-04:00',
    name: 'America/Caracas',
    shortName: '-04',
    value: 'America/Caracas',
  },
  {
    gmtOffset: 'GMT-04:00',
    name: 'America/Cuiaba',
    shortName: '-04',
    value: 'America/Cuiaba',
  },
  {
    gmtOffset: 'GMT-04:00',
    name: 'America/Curacao',
    shortName: 'AST',
    value: 'America/Curacao',
  },
  {
    gmtOffset: 'GMT-04:00',
    name: 'America/Detroit',
    shortName: 'EDT',
    value: 'America/Detroit',
  },
  {
    gmtOffset: 'GMT-04:00',
    name: 'America/Dominica',
    shortName: 'AST',
    value: 'America/Dominica',
  },
  {
    gmtOffset: 'GMT-04:00',
    name: 'America/Fort Wayne',
    shortName: 'EDT',
    value: 'America/Fort_Wayne',
  },
  {
    gmtOffset: 'GMT-04:00',
    name: 'America/Grand Turk',
    shortName: 'EDT',
    value: 'America/Grand_Turk',
  },
  {
    gmtOffset: 'GMT-04:00',
    name: 'America/Grenada',
    shortName: 'AST',
    value: 'America/Grenada',
  },
  {
    gmtOffset: 'GMT-04:00',
    name: 'America/Guadeloupe',
    shortName: 'AST',
    value: 'America/Guadeloupe',
  },
  {
    gmtOffset: 'GMT-04:00',
    name: 'America/Guyana',
    shortName: '-04',
    value: 'America/Guyana',
  },
  {
    gmtOffset: 'GMT-04:00',
    name: 'America/Havana',
    shortName: 'CDT',
    value: 'America/Havana',
  },
  {
    gmtOffset: 'GMT-04:00',
    name: 'America/Indiana/Indianapolis',
    shortName: 'EDT',
    value: 'America/Indiana/Indianapolis',
  },
  {
    gmtOffset: 'GMT-04:00',
    name: 'America/Indiana/Marengo',
    shortName: 'EDT',
    value: 'America/Indiana/Marengo',
  },
  {
    gmtOffset: 'GMT-04:00',
    name: 'America/Indiana/Petersburg',
    shortName: 'EDT',
    value: 'America/Indiana/Petersburg',
  },
  {
    gmtOffset: 'GMT-04:00',
    name: 'America/Indiana/Vevay',
    shortName: 'EDT',
    value: 'America/Indiana/Vevay',
  },
  {
    gmtOffset: 'GMT-04:00',
    name: 'America/Indiana/Vincennes',
    shortName: 'EDT',
    value: 'America/Indiana/Vincennes',
  },
  {
    gmtOffset: 'GMT-04:00',
    name: 'America/Indiana/Winamac',
    shortName: 'EDT',
    value: 'America/Indiana/Winamac',
  },
  {
    gmtOffset: 'GMT-04:00',
    name: 'America/Indianapolis',
    shortName: 'EDT',
    value: 'America/Indianapolis',
  },
  {
    gmtOffset: 'GMT-04:00',
    name: 'America/Iqaluit',
    shortName: 'EDT',
    value: 'America/Iqaluit',
  },
  {
    gmtOffset: 'GMT-04:00',
    name: 'America/Kentucky/Louisville',
    shortName: 'EDT',
    value: 'America/Kentucky/Louisville',
  },
  {
    gmtOffset: 'GMT-04:00',
    name: 'America/Kentucky/Monticello',
    shortName: 'EDT',
    value: 'America/Kentucky/Monticello',
  },
  {
    gmtOffset: 'GMT-04:00',
    name: 'America/Kralendijk',
    shortName: 'AST',
    value: 'America/Kralendijk',
  },
  {
    gmtOffset: 'GMT-04:00',
    name: 'America/La Paz',
    shortName: '-04',
    value: 'America/La_Paz',
  },
  {
    gmtOffset: 'GMT-04:00',
    name: 'America/Louisville',
    shortName: 'EDT',
    value: 'America/Louisville',
  },
  {
    gmtOffset: 'GMT-04:00',
    name: 'America/Lower Princes',
    shortName: 'AST',
    value: 'America/Lower_Princes',
  },
  {
    gmtOffset: 'GMT-04:00',
    name: 'America/Manaus',
    shortName: '-04',
    value: 'America/Manaus',
  },
  {
    gmtOffset: 'GMT-04:00',
    name: 'America/Marigot',
    shortName: 'AST',
    value: 'America/Marigot',
  },
  {
    gmtOffset: 'GMT-04:00',
    name: 'America/Martinique',
    shortName: 'AST',
    value: 'America/Martinique',
  },
  {
    gmtOffset: 'GMT-04:00',
    name: 'America/Montreal',
    shortName: 'EDT',
    value: 'America/Montreal',
  },
  {
    gmtOffset: 'GMT-04:00',
    name: 'America/Montserrat',
    shortName: 'AST',
    value: 'America/Montserrat',
  },
  {
    gmtOffset: 'GMT-04:00',
    name: 'America/Nassau',
    shortName: 'EDT',
    value: 'America/Nassau',
  },
  {
    gmtOffset: 'GMT-04:00',
    name: 'America/New York',
    shortName: 'EDT',
    value: 'America/New_York',
  },
  {
    gmtOffset: 'GMT-04:00',
    name: 'America/Nipigon',
    shortName: 'EDT',
    value: 'America/Nipigon',
  },
  {
    gmtOffset: 'GMT-04:00',
    name: 'America/Pangnirtung',
    shortName: 'EDT',
    value: 'America/Pangnirtung',
  },
  {
    gmtOffset: 'GMT-04:00',
    name: 'America/Port-au-Prince',
    shortName: 'EDT',
    value: 'America/Port-au-Prince',
  },
  {
    gmtOffset: 'GMT-04:00',
    name: 'America/Port of Spain',
    shortName: 'AST',
    value: 'America/Port_of Spain',
  },
  {
    gmtOffset: 'GMT-04:00',
    name: 'America/Porto Velho',
    shortName: '-04',
    value: 'America/Porto_Velho',
  },
  {
    gmtOffset: 'GMT-04:00',
    name: 'America/Puerto Rico',
    shortName: 'AST',
    value: 'America/Puerto_Rico',
  },
  {
    gmtOffset: 'GMT-04:00',
    name: 'America/Santo Domingo',
    shortName: 'AST',
    value: 'America/Santo_Domingo',
  },
  {
    gmtOffset: 'GMT-04:00',
    name: 'America/St Barthelemy',
    shortName: 'AST',
    value: 'America/St_Barthelemy',
  },
  {
    gmtOffset: 'GMT-04:00',
    name: 'America/St Kitts',
    shortName: 'AST',
    value: 'America/St_Kitts',
  },
  {
    gmtOffset: 'GMT-04:00',
    name: 'America/St Lucia',
    shortName: 'AST',
    value: 'America/St_Lucia',
  },
  {
    gmtOffset: 'GMT-04:00',
    name: 'America/St Thomas',
    shortName: 'AST',
    value: 'America/St_Thomas',
  },
  {
    gmtOffset: 'GMT-04:00',
    name: 'America/St Vincent',
    shortName: 'AST',
    value: 'America/St_Vincent',
  },
  {
    gmtOffset: 'GMT-04:00',
    name: 'America/Thunder Bay',
    shortName: 'EDT',
    value: 'America/Thunder_Bay',
  },
  {
    gmtOffset: 'GMT-04:00',
    name: 'America/Toronto',
    shortName: 'EDT',
    value: 'America/Toronto',
  },
  {
    gmtOffset: 'GMT-04:00',
    name: 'America/Tortola',
    shortName: 'AST',
    value: 'America/Tortola',
  },
  {
    gmtOffset: 'GMT-04:00',
    name: 'America/Virgin',
    shortName: 'AST',
    value: 'America/Virgin',
  },
  {
    gmtOffset: 'GMT-04:00',
    name: 'Brazil/West',
    shortName: '-04',
    value: 'Brazil/West',
  },
  {
    gmtOffset: 'GMT-04:00',
    name: 'Canada/Eastern',
    shortName: 'EDT',
    value: 'Canada/Eastern',
  },
  { gmtOffset: 'GMT-04:00', name: 'Cuba', shortName: 'CDT', value: 'Cuba' },
  {
    gmtOffset: 'GMT-04:00',
    name: 'EST5EDT',
    shortName: 'EDT',
    value: 'EST5EDT',
  },
  {
    gmtOffset: 'GMT-04:00',
    name: 'Etc/GMT+4',
    shortName: '-04',
    value: 'Etc/GMT+4',
  },
  {
    gmtOffset: 'GMT-04:00',
    name: 'US/East-Indiana',
    shortName: 'EDT',
    value: 'US/East-Indiana',
  },
  {
    gmtOffset: 'GMT-04:00',
    name: 'US/Eastern',
    shortName: 'EDT',
    value: 'US/Eastern',
  },
  {
    gmtOffset: 'GMT-04:00',
    name: 'US/Michigan',
    shortName: 'EDT',
    value: 'US/Michigan',
  },
  {
    gmtOffset: 'GMT-03:00',
    name: 'America/Araguaina',
    shortName: '-03',
    value: 'America/Araguaina',
  },
  {
    gmtOffset: 'GMT-03:00',
    name: 'America/Argentina/Buenos Aires',
    shortName: '-03',
    value: 'America/Argentina/Buenos_Aires',
  },
  {
    gmtOffset: 'GMT-03:00',
    name: 'America/Argentina/Catamarca',
    shortName: '-03',
    value: 'America/Argentina/Catamarca',
  },
  {
    gmtOffset: 'GMT-03:00',
    name: 'America/Argentina/ComodRivadavia',
    shortName: '-03',
    value: 'America/Argentina/ComodRivadavia',
  },
  {
    gmtOffset: 'GMT-03:00',
    name: 'America/Argentina/Cordoba',
    shortName: '-03',
    value: 'America/Argentina/Cordoba',
  },
  {
    gmtOffset: 'GMT-03:00',
    name: 'America/Argentina/Jujuy',
    shortName: '-03',
    value: 'America/Argentina/Jujuy',
  },
  {
    gmtOffset: 'GMT-03:00',
    name: 'America/Argentina/La Rioja',
    shortName: '-03',
    value: 'America/Argentina/La_Rioja',
  },
  {
    gmtOffset: 'GMT-03:00',
    name: 'America/Argentina/Mendoza',
    shortName: '-03',
    value: 'America/Argentina/Mendoza',
  },
  {
    gmtOffset: 'GMT-03:00',
    name: 'America/Argentina/Rio Gallegos',
    shortName: '-03',
    value: 'America/Argentina/Rio_Gallegos',
  },
  {
    gmtOffset: 'GMT-03:00',
    name: 'America/Argentina/Salta',
    shortName: '-03',
    value: 'America/Argentina/Salta',
  },
  {
    gmtOffset: 'GMT-03:00',
    name: 'America/Argentina/San Juan',
    shortName: '-03',
    value: 'America/Argentina/San_Juan',
  },
  {
    gmtOffset: 'GMT-03:00',
    name: 'America/Argentina/San Luis',
    shortName: '-03',
    value: 'America/Argentina/San_Luis',
  },
  {
    gmtOffset: 'GMT-03:00',
    name: 'America/Argentina/Tucuman',
    shortName: '-03',
    value: 'America/Argentina/Tucuman',
  },
  {
    gmtOffset: 'GMT-03:00',
    name: 'America/Argentina/Ushuaia',
    shortName: '-03',
    value: 'America/Argentina/Ushuaia',
  },
  {
    gmtOffset: 'GMT-03:00',
    name: 'America/Bahia',
    shortName: '-03',
    value: 'America/Bahia',
  },
  {
    gmtOffset: 'GMT-03:00',
    name: 'America/Belem',
    shortName: '-03',
    value: 'America/Belem',
  },
  {
    gmtOffset: 'GMT-03:00',
    name: 'America/Buenos Aires',
    shortName: '-03',
    value: 'America/Buenos_Aires',
  },
  {
    gmtOffset: 'GMT-03:00',
    name: 'America/Catamarca',
    shortName: '-03',
    value: 'America/Catamarca',
  },
  {
    gmtOffset: 'GMT-03:00',
    name: 'America/Cayenne',
    shortName: '-03',
    value: 'America/Cayenne',
  },
  {
    gmtOffset: 'GMT-03:00',
    name: 'America/Cordoba',
    shortName: '-03',
    value: 'America/Cordoba',
  },
  {
    gmtOffset: 'GMT-03:00',
    name: 'America/Fortaleza',
    shortName: '-03',
    value: 'America/Fortaleza',
  },
  {
    gmtOffset: 'GMT-03:00',
    name: 'America/Glace Bay',
    shortName: 'ADT',
    value: 'America/Glace_Bay',
  },
  {
    gmtOffset: 'GMT-03:00',
    name: 'America/Goose Bay',
    shortName: 'ADT',
    value: 'America/Goose_Bay',
  },
  {
    gmtOffset: 'GMT-03:00',
    name: 'America/Halifax',
    shortName: 'ADT',
    value: 'America/Halifax',
  },
  {
    gmtOffset: 'GMT-03:00',
    name: 'America/Jujuy',
    shortName: '-03',
    value: 'America/Jujuy',
  },
  {
    gmtOffset: 'GMT-03:00',
    name: 'America/Maceio',
    shortName: '-03',
    value: 'America/Maceio',
  },
  {
    gmtOffset: 'GMT-03:00',
    name: 'America/Mendoza',
    shortName: '-03',
    value: 'America/Mendoza',
  },
  {
    gmtOffset: 'GMT-03:00',
    name: 'America/Moncton',
    shortName: 'ADT',
    value: 'America/Moncton',
  },
  {
    gmtOffset: 'GMT-03:00',
    name: 'America/Montevideo',
    shortName: '-03',
    value: 'America/Montevideo',
  },
  {
    gmtOffset: 'GMT-03:00',
    name: 'America/Paramaribo',
    shortName: '-03',
    value: 'America/Paramaribo',
  },
  {
    gmtOffset: 'GMT-03:00',
    name: 'America/Punta Arenas',
    shortName: '-03',
    value: 'America/Punta_Arenas',
  },
  {
    gmtOffset: 'GMT-03:00',
    name: 'America/Recife',
    shortName: '-03',
    value: 'America/Recife',
  },
  {
    gmtOffset: 'GMT-03:00',
    name: 'America/Rosario',
    shortName: '-03',
    value: 'America/Rosario',
  },
  {
    gmtOffset: 'GMT-03:00',
    name: 'America/Santarem',
    shortName: '-03',
    value: 'America/Santarem',
  },
  {
    gmtOffset: 'GMT-03:00',
    name: 'America/Santiago',
    shortName: '-03',
    value: 'America/Santiago',
  },
  {
    gmtOffset: 'GMT-03:00',
    name: 'America/Sao Paulo',
    shortName: '-03',
    value: 'America/Sao_Paulo',
  },
  {
    gmtOffset: 'GMT-03:00',
    name: 'America/Thule',
    shortName: 'ADT',
    value: 'America/Thule',
  },
  {
    gmtOffset: 'GMT-03:00',
    name: 'Antarctica/Palmer',
    shortName: '-03',
    value: 'Antarctica/Palmer',
  },
  {
    gmtOffset: 'GMT-03:00',
    name: 'Antarctica/Rothera',
    shortName: '-03',
    value: 'Antarctica/Rothera',
  },
  {
    gmtOffset: 'GMT-03:00',
    name: 'Atlantic/Bermuda',
    shortName: 'ADT',
    value: 'Atlantic/Bermuda',
  },
  {
    gmtOffset: 'GMT-03:00',
    name: 'Atlantic/Stanley',
    shortName: '-03',
    value: 'Atlantic/Stanley',
  },
  {
    gmtOffset: 'GMT-03:00',
    name: 'Brazil/East',
    shortName: '-03',
    value: 'Brazil/East',
  },
  {
    gmtOffset: 'GMT-03:00',
    name: 'Canada/Atlantic',
    shortName: 'ADT',
    value: 'Canada/Atlantic',
  },
  {
    gmtOffset: 'GMT-03:00',
    name: 'Chile/Continental',
    shortName: '-03',
    value: 'Chile/Continental',
  },
  {
    gmtOffset: 'GMT-03:00',
    name: 'Etc/GMT+3',
    shortName: '-03',
    value: 'Etc/GMT+3',
  },
  {
    gmtOffset: 'GMT-02:30',
    name: 'America/St Johns',
    shortName: 'NDT',
    value: 'America/St_Johns',
  },
  {
    gmtOffset: 'GMT-02:30',
    name: 'Canada/Newfoundland',
    shortName: 'NDT',
    value: 'Canada/Newfoundland',
  },
  {
    gmtOffset: 'GMT-02:00',
    name: 'America/Godthab',
    shortName: '-02',
    value: 'America/Godthab',
  },
  {
    gmtOffset: 'GMT-02:00',
    name: 'America/Miquelon',
    shortName: '-02',
    value: 'America/Miquelon',
  },
  {
    gmtOffset: 'GMT-02:00',
    name: 'America/Noronha',
    shortName: '-02',
    value: 'America/Noronha',
  },
  {
    gmtOffset: 'GMT-02:00',
    name: 'Atlantic/South Georgia',
    shortName: '-02',
    value: 'Atlantic/South_Georgia',
  },
  {
    gmtOffset: 'GMT-02:00',
    name: 'Brazil/DeNoronha',
    shortName: '-02',
    value: 'Brazil/DeNoronha',
  },
  {
    gmtOffset: 'GMT-02:00',
    name: 'Etc/GMT+2',
    shortName: '-02',
    value: 'Etc/GMT+2',
  },
  {
    gmtOffset: 'GMT-01:00',
    name: 'Atlantic/Cape Verde',
    shortName: '-01',
    value: 'Atlantic/Cape_Verde',
  },
  {
    gmtOffset: 'GMT-01:00',
    name: 'Etc/GMT+1',
    shortName: '-01',
    value: 'Etc/GMT+1',
  },
  {
    gmtOffset: 'GMT+00:00',
    name: 'Africa/Abidjan',
    shortName: 'GMT',
    value: 'Africa/Abidjan',
  },
  {
    gmtOffset: 'GMT+00:00',
    name: 'Africa/Accra',
    shortName: 'GMT',
    value: 'Africa/Accra',
  },
  {
    gmtOffset: 'GMT+00:00',
    name: 'Africa/Bamako',
    shortName: 'GMT',
    value: 'Africa/Bamako',
  },
  {
    gmtOffset: 'GMT+00:00',
    name: 'Africa/Banjul',
    shortName: 'GMT',
    value: 'Africa/Banjul',
  },
  {
    gmtOffset: 'GMT+00:00',
    name: 'Africa/Bissau',
    shortName: 'GMT',
    value: 'Africa/Bissau',
  },
  {
    gmtOffset: 'GMT+00:00',
    name: 'Africa/Conakry',
    shortName: 'GMT',
    value: 'Africa/Conakry',
  },
  {
    gmtOffset: 'GMT+00:00',
    name: 'Africa/Dakar',
    shortName: 'GMT',
    value: 'Africa/Dakar',
  },
  {
    gmtOffset: 'GMT+00:00',
    name: 'Africa/Freetown',
    shortName: 'GMT',
    value: 'Africa/Freetown',
  },
  {
    gmtOffset: 'GMT+00:00',
    name: 'Africa/Lome',
    shortName: 'GMT',
    value: 'Africa/Lome',
  },
  {
    gmtOffset: 'GMT+00:00',
    name: 'Africa/Monrovia',
    shortName: 'GMT',
    value: 'Africa/Monrovia',
  },
  {
    gmtOffset: 'GMT+00:00',
    name: 'Africa/Nouakchott',
    shortName: 'GMT',
    value: 'Africa/Nouakchott',
  },
  {
    gmtOffset: 'GMT+00:00',
    name: 'Africa/Ouagadougou',
    shortName: 'GMT',
    value: 'Africa/Ouagadougou',
  },
  {
    gmtOffset: 'GMT+00:00',
    name: 'Africa/Sao Tome',
    shortName: 'GMT',
    value: 'Africa/Sao_Tome',
  },
  {
    gmtOffset: 'GMT+00:00',
    name: 'Africa/Timbuktu',
    shortName: 'GMT',
    value: 'Africa/Timbuktu',
  },
  {
    gmtOffset: 'GMT+00:00',
    name: 'America/Danmarkshavn',
    shortName: 'GMT',
    value: 'America/Danmarkshavn',
  },
  {
    gmtOffset: 'GMT+00:00',
    name: 'America/Scoresbysund',
    shortName: '+00',
    value: 'America/Scoresbysund',
  },
  {
    gmtOffset: 'GMT+00:00',
    name: 'Atlantic/Azores',
    shortName: '+00',
    value: 'Atlantic/Azores',
  },
  {
    gmtOffset: 'GMT+00:00',
    name: 'Atlantic/Reykjavik',
    shortName: 'GMT',
    value: 'Atlantic/Reykjavik',
  },
  {
    gmtOffset: 'GMT+00:00',
    name: 'Atlantic/St Helena',
    shortName: 'GMT',
    value: 'Atlantic/St_Helena',
  },
  { gmtOffset: 'GMT+00:00', name: 'Etc/GMT', shortName: 'GMT', value: 'Etc/GMT' },
  {
    gmtOffset: 'GMT+00:00',
    name: 'Etc/GMT+0',
    shortName: 'GMT',
    value: 'Etc/GMT+0',
  },
  {
    gmtOffset: 'GMT+00:00',
    name: 'Etc/GMT-0',
    shortName: 'GMT',
    value: 'Etc/GMT-0',
  },
  {
    gmtOffset: 'GMT+00:00',
    name: 'Etc/GMT0',
    shortName: 'GMT',
    value: 'Etc/GMT0',
  },
  {
    gmtOffset: 'GMT+00:00',
    name: 'Etc/Greenwich',
    shortName: 'GMT',
    value: 'Etc/Greenwich',
  },
  { gmtOffset: 'GMT+00:00', name: 'Etc/UCT', shortName: 'UTC', value: 'Etc/UCT' },
  {
    gmtOffset: 'GMT+00:00',
    name: 'Etc/UTC',
    shortName: 'UTC',
    value: 'Etc/UTC',
  },
  {
    gmtOffset: 'GMT+00:00',
    name: 'Etc/Universal',
    shortName: 'UTC',
    value: 'Etc/Universal',
  },
  {
    gmtOffset: 'GMT+00:00',
    name: 'Etc/Zulu',
    shortName: 'UTC',
    value: 'Etc/Zulu',
  },
  { gmtOffset: 'GMT+00:00', name: 'GMT', shortName: 'GMT', value: 'GMT' },
  {
    gmtOffset: 'GMT+00:00',
    name: 'GMT+0',
    shortName: 'GMT',
    value: 'GMT+0',
  },
  { gmtOffset: 'GMT+00:00', name: 'GMT-0', shortName: 'GMT', value: 'GMT-0' },
  {
    gmtOffset: 'GMT+00:00',
    name: 'GMT0',
    shortName: 'GMT',
    value: 'GMT0',
  },
  {
    gmtOffset: 'GMT+00:00',
    name: 'Greenwich',
    shortName: 'GMT',
    value: 'Greenwich',
  },
  { gmtOffset: 'GMT+00:00', name: 'Iceland', shortName: 'GMT', value: 'Iceland' },
  {
    gmtOffset: 'GMT+00:00',
    name: 'UCT',
    shortName: 'UTC',
    value: 'UCT',
  },
  { gmtOffset: 'GMT+00:00', name: 'UTC', shortName: 'UTC', value: 'UTC' },
  {
    gmtOffset: 'GMT+00:00',
    name: 'Universal',
    shortName: 'UTC',
    value: 'Universal',
  },
  { gmtOffset: 'GMT+00:00', name: 'Zulu', shortName: 'UTC', value: 'Zulu' },
  {
    gmtOffset: 'GMT+01:00',
    name: 'Africa/Algiers',
    shortName: 'CET',
    value: 'Africa/Algiers',
  },
  {
    gmtOffset: 'GMT+01:00',
    name: 'Africa/Bangui',
    shortName: 'WAT',
    value: 'Africa/Bangui',
  },
  {
    gmtOffset: 'GMT+01:00',
    name: 'Africa/Brazzaville',
    shortName: 'WAT',
    value: 'Africa/Brazzaville',
  },
  {
    gmtOffset: 'GMT+01:00',
    name: 'Africa/Casablanca',
    shortName: '+01',
    value: 'Africa/Casablanca',
  },
  {
    gmtOffset: 'GMT+01:00',
    name: 'Africa/Douala',
    shortName: 'WAT',
    value: 'Africa/Douala',
  },
  {
    gmtOffset: 'GMT+01:00',
    name: 'Africa/El Aaiun',
    shortName: '+01',
    value: 'Africa/El_Aaiun',
  },
  {
    gmtOffset: 'GMT+01:00',
    name: 'Africa/Kinshasa',
    shortName: 'WAT',
    value: 'Africa/Kinshasa',
  },
  {
    gmtOffset: 'GMT+01:00',
    name: 'Africa/Lagos',
    shortName: 'WAT',
    value: 'Africa/Lagos',
  },
  {
    gmtOffset: 'GMT+01:00',
    name: 'Africa/Libreville',
    shortName: 'WAT',
    value: 'Africa/Libreville',
  },
  {
    gmtOffset: 'GMT+01:00',
    name: 'Africa/Luanda',
    shortName: 'WAT',
    value: 'Africa/Luanda',
  },
  {
    gmtOffset: 'GMT+01:00',
    name: 'Africa/Malabo',
    shortName: 'WAT',
    value: 'Africa/Malabo',
  },
  {
    gmtOffset: 'GMT+01:00',
    name: 'Africa/Ndjamena',
    shortName: 'WAT',
    value: 'Africa/Ndjamena',
  },
  {
    gmtOffset: 'GMT+01:00',
    name: 'Africa/Niamey',
    shortName: 'WAT',
    value: 'Africa/Niamey',
  },
  {
    gmtOffset: 'GMT+01:00',
    name: 'Africa/Porto-Novo',
    shortName: 'WAT',
    value: 'Africa/Porto-Novo',
  },
  {
    gmtOffset: 'GMT+01:00',
    name: 'Africa/Tunis',
    shortName: 'CET',
    value: 'Africa/Tunis',
  },
  {
    gmtOffset: 'GMT+01:00',
    name: 'Atlantic/Canary',
    shortName: 'WEST',
    value: 'Atlantic/Canary',
  },
  {
    gmtOffset: 'GMT+01:00',
    name: 'Atlantic/Faeroe',
    shortName: 'WEST',
    value: 'Atlantic/Faeroe',
  },
  {
    gmtOffset: 'GMT+01:00',
    name: 'Atlantic/Faroe',
    shortName: 'WEST',
    value: 'Atlantic/Faroe',
  },
  {
    gmtOffset: 'GMT+01:00',
    name: 'Atlantic/Madeira',
    shortName: 'WEST',
    value: 'Atlantic/Madeira',
  },
  { gmtOffset: 'GMT+01:00', name: 'Eire', shortName: 'IST', value: 'Eire' },
  {
    gmtOffset: 'GMT+01:00',
    name: 'Etc/GMT-1',
    shortName: '+01',
    value: 'Etc/GMT-1',
  },
  {
    gmtOffset: 'GMT+01:00',
    name: 'Europe/Belfast',
    shortName: 'BST',
    value: 'Europe/Belfast',
  },
  {
    gmtOffset: 'GMT+01:00',
    name: 'Europe/Dublin',
    shortName: 'IST',
    value: 'Europe/Dublin',
  },
  {
    gmtOffset: 'GMT+01:00',
    name: 'Europe/Guernsey',
    shortName: 'BST',
    value: 'Europe/Guernsey',
  },
  {
    gmtOffset: 'GMT+01:00',
    name: 'Europe/Isle of Man',
    shortName: 'BST',
    value: 'Europe/Isle_of Man',
  },
  {
    gmtOffset: 'GMT+01:00',
    name: 'Europe/Jersey',
    shortName: 'BST',
    value: 'Europe/Jersey',
  },
  {
    gmtOffset: 'GMT+01:00',
    name: 'Europe/Lisbon',
    shortName: 'WEST',
    value: 'Europe/Lisbon',
  },
  {
    gmtOffset: 'GMT+01:00',
    name: 'Europe/London',
    shortName: 'BST',
    value: 'Europe/London',
  },
  { gmtOffset: 'GMT+01:00', name: 'GB', shortName: 'BST', value: 'GB' },
  {
    gmtOffset: 'GMT+01:00',
    name: 'GB-Eire',
    shortName: 'BST',
    value: 'GB-Eire',
  },
  {
    gmtOffset: 'GMT+01:00',
    name: 'Portugal',
    shortName: 'WEST',
    value: 'Portugal',
  },
  { gmtOffset: 'GMT+01:00', name: 'WET', shortName: 'WEST', value: 'WET' },
  {
    gmtOffset: 'GMT+02:00',
    name: 'Africa/Blantyre',
    shortName: 'CAT',
    value: 'Africa/Blantyre',
  },
  {
    gmtOffset: 'GMT+02:00',
    name: 'Africa/Bujumbura',
    shortName: 'CAT',
    value: 'Africa/Bujumbura',
  },
  {
    gmtOffset: 'GMT+02:00',
    name: 'Africa/Cairo',
    shortName: 'EET',
    value: 'Africa/Cairo',
  },
  {
    gmtOffset: 'GMT+02:00',
    name: 'Africa/Ceuta',
    shortName: 'CEST',
    value: 'Africa/Ceuta',
  },
  {
    gmtOffset: 'GMT+02:00',
    name: 'Africa/Gaborone',
    shortName: 'CAT',
    value: 'Africa/Gaborone',
  },
  {
    gmtOffset: 'GMT+02:00',
    name: 'Africa/Harare',
    shortName: 'CAT',
    value: 'Africa/Harare',
  },
  {
    gmtOffset: 'GMT+02:00',
    name: 'Africa/Johannesburg',
    shortName: 'SAST',
    value: 'Africa/Johannesburg',
  },
  {
    gmtOffset: 'GMT+02:00',
    name: 'Africa/Khartoum',
    shortName: 'CAT',
    value: 'Africa/Khartoum',
  },
  {
    gmtOffset: 'GMT+02:00',
    name: 'Africa/Kigali',
    shortName: 'CAT',
    value: 'Africa/Kigali',
  },
  {
    gmtOffset: 'GMT+02:00',
    name: 'Africa/Lubumbashi',
    shortName: 'CAT',
    value: 'Africa/Lubumbashi',
  },
  {
    gmtOffset: 'GMT+02:00',
    name: 'Africa/Lusaka',
    shortName: 'CAT',
    value: 'Africa/Lusaka',
  },
  {
    gmtOffset: 'GMT+02:00',
    name: 'Africa/Maputo',
    shortName: 'CAT',
    value: 'Africa/Maputo',
  },
  {
    gmtOffset: 'GMT+02:00',
    name: 'Africa/Maseru',
    shortName: 'SAST',
    value: 'Africa/Maseru',
  },
  {
    gmtOffset: 'GMT+02:00',
    name: 'Africa/Mbabane',
    shortName: 'SAST',
    value: 'Africa/Mbabane',
  },
  {
    gmtOffset: 'GMT+02:00',
    name: 'Africa/Tripoli',
    shortName: 'EET',
    value: 'Africa/Tripoli',
  },
  {
    gmtOffset: 'GMT+02:00',
    name: 'Africa/Windhoek',
    shortName: 'CAT',
    value: 'Africa/Windhoek',
  },
  {
    gmtOffset: 'GMT+02:00',
    name: 'Antarctica/Troll',
    shortName: '+02',
    value: 'Antarctica/Troll',
  },
  {
    gmtOffset: 'GMT+02:00',
    name: 'Arctic/Longyearbyen',
    shortName: 'CEST',
    value: 'Arctic/Longyearbyen',
  },
  {
    gmtOffset: 'GMT+02:00',
    name: 'Atlantic/Jan Mayen',
    shortName: 'CEST',
    value: 'Atlantic/Jan_Mayen',
  },
  { gmtOffset: 'GMT+02:00', name: 'CET', shortName: 'CEST', value: 'CET' },
  {
    gmtOffset: 'GMT+02:00',
    name: 'Egypt',
    shortName: 'EET',
    value: 'Egypt',
  },
  {
    gmtOffset: 'GMT+02:00',
    name: 'Etc/GMT-2',
    shortName: '+02',
    value: 'Etc/GMT-2',
  },
  {
    gmtOffset: 'GMT+02:00',
    name: 'Europe/Amsterdam',
    shortName: 'CEST',
    value: 'Europe/Amsterdam',
  },
  {
    gmtOffset: 'GMT+02:00',
    name: 'Europe/Andorra',
    shortName: 'CEST',
    value: 'Europe/Andorra',
  },
  {
    gmtOffset: 'GMT+02:00',
    name: 'Europe/Belgrade',
    shortName: 'CEST',
    value: 'Europe/Belgrade',
  },
  {
    gmtOffset: 'GMT+02:00',
    name: 'Europe/Berlin',
    shortName: 'CEST',
    value: 'Europe/Berlin',
  },
  {
    gmtOffset: 'GMT+02:00',
    name: 'Europe/Bratislava',
    shortName: 'CEST',
    value: 'Europe/Bratislava',
  },
  {
    gmtOffset: 'GMT+02:00',
    name: 'Europe/Brussels',
    shortName: 'CEST',
    value: 'Europe/Brussels',
  },
  {
    gmtOffset: 'GMT+02:00',
    name: 'Europe/Budapest',
    shortName: 'CEST',
    value: 'Europe/Budapest',
  },
  {
    gmtOffset: 'GMT+02:00',
    name: 'Europe/Busingen',
    shortName: 'CEST',
    value: 'Europe/Busingen',
  },
  {
    gmtOffset: 'GMT+02:00',
    name: 'Europe/Copenhagen',
    shortName: 'CEST',
    value: 'Europe/Copenhagen',
  },
  {
    gmtOffset: 'GMT+02:00',
    name: 'Europe/Gibraltar',
    shortName: 'CEST',
    value: 'Europe/Gibraltar',
  },
  {
    gmtOffset: 'GMT+02:00',
    name: 'Europe/Kaliningrad',
    shortName: 'EET',
    value: 'Europe/Kaliningrad',
  },
  {
    gmtOffset: 'GMT+02:00',
    name: 'Europe/Ljubljana',
    shortName: 'CEST',
    value: 'Europe/Ljubljana',
  },
  {
    gmtOffset: 'GMT+02:00',
    name: 'Europe/Luxembourg',
    shortName: 'CEST',
    value: 'Europe/Luxembourg',
  },
  {
    gmtOffset: 'GMT+02:00',
    name: 'Europe/Madrid',
    shortName: 'CEST',
    value: 'Europe/Madrid',
  },
  {
    gmtOffset: 'GMT+02:00',
    name: 'Europe/Malta',
    shortName: 'CEST',
    value: 'Europe/Malta',
  },
  {
    gmtOffset: 'GMT+02:00',
    name: 'Europe/Monaco',
    shortName: 'CEST',
    value: 'Europe/Monaco',
  },
  {
    gmtOffset: 'GMT+02:00',
    name: 'Europe/Oslo',
    shortName: 'CEST',
    value: 'Europe/Oslo',
  },
  {
    gmtOffset: 'GMT+02:00',
    name: 'Europe/Paris',
    shortName: 'CEST',
    value: 'Europe/Paris',
  },
  {
    gmtOffset: 'GMT+02:00',
    name: 'Europe/Podgorica',
    shortName: 'CEST',
    value: 'Europe/Podgorica',
  },
  {
    gmtOffset: 'GMT+02:00',
    name: 'Europe/Prague',
    shortName: 'CEST',
    value: 'Europe/Prague',
  },
  {
    gmtOffset: 'GMT+02:00',
    name: 'Europe/Rome',
    shortName: 'CEST',
    value: 'Europe/Rome',
  },
  {
    gmtOffset: 'GMT+02:00',
    name: 'Europe/San Marino',
    shortName: 'CEST',
    value: 'Europe/San_Marino',
  },
  {
    gmtOffset: 'GMT+02:00',
    name: 'Europe/Sarajevo',
    shortName: 'CEST',
    value: 'Europe/Sarajevo',
  },
  {
    gmtOffset: 'GMT+02:00',
    name: 'Europe/Skopje',
    shortName: 'CEST',
    value: 'Europe/Skopje',
  },
  {
    gmtOffset: 'GMT+02:00',
    name: 'Europe/Stockholm',
    shortName: 'CEST',
    value: 'Europe/Stockholm',
  },
  {
    gmtOffset: 'GMT+02:00',
    name: 'Europe/Tirane',
    shortName: 'CEST',
    value: 'Europe/Tirane',
  },
  {
    gmtOffset: 'GMT+02:00',
    name: 'Europe/Vaduz',
    shortName: 'CEST',
    value: 'Europe/Vaduz',
  },
  {
    gmtOffset: 'GMT+02:00',
    name: 'Europe/Vatican',
    shortName: 'CEST',
    value: 'Europe/Vatican',
  },
  {
    gmtOffset: 'GMT+02:00',
    name: 'Europe/Vienna',
    shortName: 'CEST',
    value: 'Europe/Vienna',
  },
  {
    gmtOffset: 'GMT+02:00',
    name: 'Europe/Warsaw',
    shortName: 'CEST',
    value: 'Europe/Warsaw',
  },
  {
    gmtOffset: 'GMT+02:00',
    name: 'Europe/Zagreb',
    shortName: 'CEST',
    value: 'Europe/Zagreb',
  },
  {
    gmtOffset: 'GMT+02:00',
    name: 'Europe/Zurich',
    shortName: 'CEST',
    value: 'Europe/Zurich',
  },
  { gmtOffset: 'GMT+02:00', name: 'Libya', shortName: 'EET', value: 'Libya' },
  {
    gmtOffset: 'GMT+02:00',
    name: 'MET',
    shortName: 'MEST',
    value: 'MET',
  },
  { gmtOffset: 'GMT+02:00', name: 'Poland', shortName: 'CEST', value: 'Poland' },
  {
    gmtOffset: 'GMT+03:00',
    name: 'Africa/Addis Ababa',
    shortName: 'EAT',
    value: 'Africa/Addis_Ababa',
  },
  {
    gmtOffset: 'GMT+03:00',
    name: 'Africa/Asmara',
    shortName: 'EAT',
    value: 'Africa/Asmara',
  },
  {
    gmtOffset: 'GMT+03:00',
    name: 'Africa/Asmera',
    shortName: 'EAT',
    value: 'Africa/Asmera',
  },
  {
    gmtOffset: 'GMT+03:00',
    name: 'Africa/Dar es Salaam',
    shortName: 'EAT',
    value: 'Africa/Dar_es Salaam',
  },
  {
    gmtOffset: 'GMT+03:00',
    name: 'Africa/Djibouti',
    shortName: 'EAT',
    value: 'Africa/Djibouti',
  },
  {
    gmtOffset: 'GMT+03:00',
    name: 'Africa/Juba',
    shortName: 'EAT',
    value: 'Africa/Juba',
  },
  {
    gmtOffset: 'GMT+03:00',
    name: 'Africa/Kampala',
    shortName: 'EAT',
    value: 'Africa/Kampala',
  },
  {
    gmtOffset: 'GMT+03:00',
    name: 'Africa/Mogadishu',
    shortName: 'EAT',
    value: 'Africa/Mogadishu',
  },
  {
    gmtOffset: 'GMT+03:00',
    name: 'Africa/Nairobi',
    shortName: 'EAT',
    value: 'Africa/Nairobi',
  },
  {
    gmtOffset: 'GMT+03:00',
    name: 'Antarctica/Syowa',
    shortName: '+03',
    value: 'Antarctica/Syowa',
  },
  {
    gmtOffset: 'GMT+03:00',
    name: 'Asia/Aden',
    shortName: '+03',
    value: 'Asia/Aden',
  },
  {
    gmtOffset: 'GMT+03:00',
    name: 'Asia/Amman',
    shortName: 'EEST',
    value: 'Asia/Amman',
  },
  {
    gmtOffset: 'GMT+03:00',
    name: 'Asia/Baghdad',
    shortName: '+03',
    value: 'Asia/Baghdad',
  },
  {
    gmtOffset: 'GMT+03:00',
    name: 'Asia/Bahrain',
    shortName: '+03',
    value: 'Asia/Bahrain',
  },
  {
    gmtOffset: 'GMT+03:00',
    name: 'Asia/Beirut',
    shortName: 'EEST',
    value: 'Asia/Beirut',
  },
  {
    gmtOffset: 'GMT+03:00',
    name: 'Asia/Damascus',
    shortName: 'EEST',
    value: 'Asia/Damascus',
  },
  {
    gmtOffset: 'GMT+03:00',
    name: 'Asia/Famagusta',
    shortName: 'EEST',
    value: 'Asia/Famagusta',
  },
  {
    gmtOffset: 'GMT+03:00',
    name: 'Asia/Gaza',
    shortName: 'EEST',
    value: 'Asia/Gaza',
  },
  {
    gmtOffset: 'GMT+03:00',
    name: 'Asia/Hebron',
    shortName: 'EEST',
    value: 'Asia/Hebron',
  },
  {
    gmtOffset: 'GMT+03:00',
    name: 'Asia/Istanbul',
    shortName: '+03',
    value: 'Asia/Istanbul',
  },
  {
    gmtOffset: 'GMT+03:00',
    name: 'Asia/Jerusalem',
    shortName: 'IDT',
    value: 'Asia/Jerusalem',
  },
  {
    gmtOffset: 'GMT+03:00',
    name: 'Asia/Kuwait',
    shortName: '+03',
    value: 'Asia/Kuwait',
  },
  {
    gmtOffset: 'GMT+03:00',
    name: 'Asia/Nicosia',
    shortName: 'EEST',
    value: 'Asia/Nicosia',
  },
  {
    gmtOffset: 'GMT+03:00',
    name: 'Asia/Qatar',
    shortName: '+03',
    value: 'Asia/Qatar',
  },
  {
    gmtOffset: 'GMT+03:00',
    name: 'Asia/Riyadh',
    shortName: '+03',
    value: 'Asia/Riyadh',
  },
  {
    gmtOffset: 'GMT+03:00',
    name: 'Asia/Tel Aviv',
    shortName: 'IDT',
    value: 'Asia/Tel_Aviv',
  },
  { gmtOffset: 'GMT+03:00', name: 'EET', shortName: 'EEST', value: 'EET' },
  {
    gmtOffset: 'GMT+03:00',
    name: 'Etc/GMT-3',
    shortName: '+03',
    value: 'Etc/GMT-3',
  },
  {
    gmtOffset: 'GMT+03:00',
    name: 'Europe/Athens',
    shortName: 'EEST',
    value: 'Europe/Athens',
  },
  {
    gmtOffset: 'GMT+03:00',
    name: 'Europe/Bucharest',
    shortName: 'EEST',
    value: 'Europe/Bucharest',
  },
  {
    gmtOffset: 'GMT+03:00',
    name: 'Europe/Chisinau',
    shortName: 'EEST',
    value: 'Europe/Chisinau',
  },
  {
    gmtOffset: 'GMT+03:00',
    name: 'Europe/Helsinki',
    shortName: 'EEST',
    value: 'Europe/Helsinki',
  },
  {
    gmtOffset: 'GMT+03:00',
    name: 'Europe/Istanbul',
    shortName: '+03',
    value: 'Europe/Istanbul',
  },
  {
    gmtOffset: 'GMT+03:00',
    name: 'Europe/Kiev',
    shortName: 'EEST',
    value: 'Europe/Kiev',
  },
  {
    gmtOffset: 'GMT+03:00',
    name: 'Europe/Kirov',
    shortName: '+03',
    value: 'Europe/Kirov',
  },
  {
    gmtOffset: 'GMT+03:00',
    name: 'Europe/Mariehamn',
    shortName: 'EEST',
    value: 'Europe/Mariehamn',
  },
  {
    gmtOffset: 'GMT+03:00',
    name: 'Europe/Minsk',
    shortName: '+03',
    value: 'Europe/Minsk',
  },
  {
    gmtOffset: 'GMT+03:00',
    name: 'Europe/Moscow',
    shortName: 'MSK',
    value: 'Europe/Moscow',
  },
  {
    gmtOffset: 'GMT+03:00',
    name: 'Europe/Nicosia',
    shortName: 'EEST',
    value: 'Europe/Nicosia',
  },
  {
    gmtOffset: 'GMT+03:00',
    name: 'Europe/Riga',
    shortName: 'EEST',
    value: 'Europe/Riga',
  },
  {
    gmtOffset: 'GMT+03:00',
    name: 'Europe/Simferopol',
    shortName: 'MSK',
    value: 'Europe/Simferopol',
  },
  {
    gmtOffset: 'GMT+03:00',
    name: 'Europe/Sofia',
    shortName: 'EEST',
    value: 'Europe/Sofia',
  },
  {
    gmtOffset: 'GMT+03:00',
    name: 'Europe/Tallinn',
    shortName: 'EEST',
    value: 'Europe/Tallinn',
  },
  {
    gmtOffset: 'GMT+03:00',
    name: 'Europe/Tiraspol',
    shortName: 'EEST',
    value: 'Europe/Tiraspol',
  },
  {
    gmtOffset: 'GMT+03:00',
    name: 'Europe/Uzhgorod',
    shortName: 'EEST',
    value: 'Europe/Uzhgorod',
  },
  {
    gmtOffset: 'GMT+03:00',
    name: 'Europe/Vilnius',
    shortName: 'EEST',
    value: 'Europe/Vilnius',
  },
  {
    gmtOffset: 'GMT+03:00',
    name: 'Europe/Zaporozhye',
    shortName: 'EEST',
    value: 'Europe/Zaporozhye',
  },
  {
    gmtOffset: 'GMT+03:00',
    name: 'Indian/Antananarivo',
    shortName: 'EAT',
    value: 'Indian/Antananarivo',
  },
  {
    gmtOffset: 'GMT+03:00',
    name: 'Indian/Comoro',
    shortName: 'EAT',
    value: 'Indian/Comoro',
  },
  {
    gmtOffset: 'GMT+03:00',
    name: 'Indian/Mayotte',
    shortName: 'EAT',
    value: 'Indian/Mayotte',
  },
  { gmtOffset: 'GMT+03:00', name: 'Israel', shortName: 'IDT', value: 'Israel' },
  {
    gmtOffset: 'GMT+03:00',
    name: 'Turkey',
    shortName: '+03',
    value: 'Turkey',
  },
  { gmtOffset: 'GMT+03:00', name: 'W-SU', shortName: 'MSK', value: 'W-SU' },
  {
    gmtOffset: 'GMT+04:00',
    name: 'Asia/Baku',
    shortName: '+04',
    value: 'Asia/Baku',
  },
  {
    gmtOffset: 'GMT+04:00',
    name: 'Asia/Dubai',
    shortName: '+04',
    value: 'Asia/Dubai',
  },
  {
    gmtOffset: 'GMT+04:00',
    name: 'Asia/Muscat',
    shortName: '+04',
    value: 'Asia/Muscat',
  },
  {
    gmtOffset: 'GMT+04:00',
    name: 'Asia/Tbilisi',
    shortName: '+04',
    value: 'Asia/Tbilisi',
  },
  {
    gmtOffset: 'GMT+04:00',
    name: 'Asia/Yerevan',
    shortName: '+04',
    value: 'Asia/Yerevan',
  },
  {
    gmtOffset: 'GMT+04:00',
    name: 'Etc/GMT-4',
    shortName: '+04',
    value: 'Etc/GMT-4',
  },
  {
    gmtOffset: 'GMT+04:00',
    name: 'Europe/Astrakhan',
    shortName: '+04',
    value: 'Europe/Astrakhan',
  },
  {
    gmtOffset: 'GMT+04:00',
    name: 'Europe/Samara',
    shortName: '+04',
    value: 'Europe/Samara',
  },
  {
    gmtOffset: 'GMT+04:00',
    name: 'Europe/Saratov',
    shortName: '+04',
    value: 'Europe/Saratov',
  },
  {
    gmtOffset: 'GMT+04:00',
    name: 'Europe/Ulyanovsk',
    shortName: '+04',
    value: 'Europe/Ulyanovsk',
  },
  {
    gmtOffset: 'GMT+04:00',
    name: 'Europe/Volgograd',
    shortName: '+04',
    value: 'Europe/Volgograd',
  },
  {
    gmtOffset: 'GMT+04:00',
    name: 'Indian/Mahe',
    shortName: '+04',
    value: 'Indian/Mahe',
  },
  {
    gmtOffset: 'GMT+04:00',
    name: 'Indian/Mauritius',
    shortName: '+04',
    value: 'Indian/Mauritius',
  },
  {
    gmtOffset: 'GMT+04:00',
    name: 'Indian/Reunion',
    shortName: '+04',
    value: 'Indian/Reunion',
  },
  {
    gmtOffset: 'GMT+04:30',
    name: 'Asia/Kabul',
    shortName: '+0430',
    value: 'Asia/Kabul',
  },
  {
    gmtOffset: 'GMT+04:30',
    name: 'Asia/Tehran',
    shortName: '+0430',
    value: 'Asia/Tehran',
  },
  { gmtOffset: 'GMT+04:30', name: 'Iran', shortName: '+0430', value: 'Iran' },
  {
    gmtOffset: 'GMT+05:00',
    name: 'Antarctica/Mawson',
    shortName: '+05',
    value: 'Antarctica/Mawson',
  },
  {
    gmtOffset: 'GMT+05:00',
    name: 'Asia/Aqtau',
    shortName: '+05',
    value: 'Asia/Aqtau',
  },
  {
    gmtOffset: 'GMT+05:00',
    name: 'Asia/Aqtobe',
    shortName: '+05',
    value: 'Asia/Aqtobe',
  },
  {
    gmtOffset: 'GMT+05:00',
    name: 'Asia/Ashgabat',
    shortName: '+05',
    value: 'Asia/Ashgabat',
  },
  {
    gmtOffset: 'GMT+05:00',
    name: 'Asia/Ashkhabad',
    shortName: '+05',
    value: 'Asia/Ashkhabad',
  },
  {
    gmtOffset: 'GMT+05:00',
    name: 'Asia/Atyrau',
    shortName: '+05',
    value: 'Asia/Atyrau',
  },
  {
    gmtOffset: 'GMT+05:00',
    name: 'Asia/Dushanbe',
    shortName: '+05',
    value: 'Asia/Dushanbe',
  },
  {
    gmtOffset: 'GMT+05:00',
    name: 'Asia/Karachi',
    shortName: 'PKT',
    value: 'Asia/Karachi',
  },
  {
    gmtOffset: 'GMT+05:00',
    name: 'Asia/Oral',
    shortName: '+05',
    value: 'Asia/Oral',
  },
  {
    gmtOffset: 'GMT+05:00',
    name: 'Asia/Qyzylorda',
    shortName: '+05',
    value: 'Asia/Qyzylorda',
  },
  {
    gmtOffset: 'GMT+05:00',
    name: 'Asia/Samarkand',
    shortName: '+05',
    value: 'Asia/Samarkand',
  },
  {
    gmtOffset: 'GMT+05:00',
    name: 'Asia/Tashkent',
    shortName: '+05',
    value: 'Asia/Tashkent',
  },
  {
    gmtOffset: 'GMT+05:00',
    name: 'Asia/Yekaterinburg',
    shortName: '+05',
    value: 'Asia/Yekaterinburg',
  },
  {
    gmtOffset: 'GMT+05:00',
    name: 'Etc/GMT-5',
    shortName: '+05',
    value: 'Etc/GMT-5',
  },
  {
    gmtOffset: 'GMT+05:00',
    name: 'Indian/Kerguelen',
    shortName: '+05',
    value: 'Indian/Kerguelen',
  },
  {
    gmtOffset: 'GMT+05:00',
    name: 'Indian/Maldives',
    shortName: '+05',
    value: 'Indian/Maldives',
  },
  {
    gmtOffset: 'GMT+05:30',
    name: 'Asia/Calcutta',
    shortName: 'IST',
    value: 'Asia/Calcutta',
  },
  {
    gmtOffset: 'GMT+05:30',
    name: 'Asia/Colombo',
    shortName: '+0530',
    value: 'Asia/Colombo',
  },
  {
    gmtOffset: 'GMT+05:30',
    name: 'Asia/Kolkata',
    shortName: 'IST',
    value: 'Asia/Kolkata',
  },
  {
    gmtOffset: 'GMT+05:45',
    name: 'Asia/Kathmandu',
    shortName: '+0545',
    value: 'Asia/Kathmandu',
  },
  {
    gmtOffset: 'GMT+05:45',
    name: 'Asia/Katmandu',
    shortName: '+0545',
    value: 'Asia/Katmandu',
  },
  {
    gmtOffset: 'GMT+06:00',
    name: 'Antarctica/Vostok',
    shortName: '+06',
    value: 'Antarctica/Vostok',
  },
  {
    gmtOffset: 'GMT+06:00',
    name: 'Asia/Almaty',
    shortName: '+06',
    value: 'Asia/Almaty',
  },
  {
    gmtOffset: 'GMT+06:00',
    name: 'Asia/Bishkek',
    shortName: '+06',
    value: 'Asia/Bishkek',
  },
  {
    gmtOffset: 'GMT+06:00',
    name: 'Asia/Dacca',
    shortName: '+06',
    value: 'Asia/Dacca',
  },
  {
    gmtOffset: 'GMT+06:00',
    name: 'Asia/Dhaka',
    shortName: '+06',
    value: 'Asia/Dhaka',
  },
  {
    gmtOffset: 'GMT+06:00',
    name: 'Asia/Kashgar',
    shortName: '+06',
    value: 'Asia/Kashgar',
  },
  {
    gmtOffset: 'GMT+06:00',
    name: 'Asia/Omsk',
    shortName: '+06',
    value: 'Asia/Omsk',
  },
  {
    gmtOffset: 'GMT+06:00',
    name: 'Asia/Qostanay',
    shortName: '+06',
    value: 'Asia/Qostanay',
  },
  {
    gmtOffset: 'GMT+06:00',
    name: 'Asia/Thimbu',
    shortName: '+06',
    value: 'Asia/Thimbu',
  },
  {
    gmtOffset: 'GMT+06:00',
    name: 'Asia/Thimphu',
    shortName: '+06',
    value: 'Asia/Thimphu',
  },
  {
    gmtOffset: 'GMT+06:00',
    name: 'Asia/Urumqi',
    shortName: '+06',
    value: 'Asia/Urumqi',
  },
  {
    gmtOffset: 'GMT+06:00',
    name: 'Etc/GMT-6',
    shortName: '+06',
    value: 'Etc/GMT-6',
  },
  {
    gmtOffset: 'GMT+06:00',
    name: 'Indian/Chagos',
    shortName: '+06',
    value: 'Indian/Chagos',
  },
  {
    gmtOffset: 'GMT+06:30',
    name: 'Asia/Rangoon',
    shortName: '+0630',
    value: 'Asia/Rangoon',
  },
  {
    gmtOffset: 'GMT+06:30',
    name: 'Asia/Yangon',
    shortName: '+0630',
    value: 'Asia/Yangon',
  },
  {
    gmtOffset: 'GMT+06:30',
    name: 'Indian/Cocos',
    shortName: '+0630',
    value: 'Indian/Cocos',
  },
  {
    gmtOffset: 'GMT+07:00',
    name: 'Antarctica/Davis',
    shortName: '+07',
    value: 'Antarctica/Davis',
  },
  {
    gmtOffset: 'GMT+07:00',
    name: 'Asia/Bangkok',
    shortName: '+07',
    value: 'Asia/Bangkok',
  },
  {
    gmtOffset: 'GMT+07:00',
    name: 'Asia/Barnaul',
    shortName: '+07',
    value: 'Asia/Barnaul',
  },
  {
    gmtOffset: 'GMT+07:00',
    name: 'Asia/Ho Chi Minh',
    shortName: '+07',
    value: 'Asia/Ho_Chi Minh',
  },
  {
    gmtOffset: 'GMT+07:00',
    name: 'Asia/Hovd',
    shortName: '+07',
    value: 'Asia/Hovd',
  },
  {
    gmtOffset: 'GMT+07:00',
    name: 'Asia/Jakarta',
    shortName: 'WIB',
    value: 'Asia/Jakarta',
  },
  {
    gmtOffset: 'GMT+07:00',
    name: 'Asia/Krasnoyarsk',
    shortName: '+07',
    value: 'Asia/Krasnoyarsk',
  },
  {
    gmtOffset: 'GMT+07:00',
    name: 'Asia/Novokuznetsk',
    shortName: '+07',
    value: 'Asia/Novokuznetsk',
  },
  {
    gmtOffset: 'GMT+07:00',
    name: 'Asia/Novosibirsk',
    shortName: '+07',
    value: 'Asia/Novosibirsk',
  },
  {
    gmtOffset: 'GMT+07:00',
    name: 'Asia/Phnom Penh',
    shortName: '+07',
    value: 'Asia/Phnom_Penh',
  },
  {
    gmtOffset: 'GMT+07:00',
    name: 'Asia/Pontianak',
    shortName: 'WIB',
    value: 'Asia/Pontianak',
  },
  {
    gmtOffset: 'GMT+07:00',
    name: 'Asia/Saigon',
    shortName: '+07',
    value: 'Asia/Saigon',
  },
  {
    gmtOffset: 'GMT+07:00',
    name: 'Asia/Tomsk',
    shortName: '+07',
    value: 'Asia/Tomsk',
  },
  {
    gmtOffset: 'GMT+07:00',
    name: 'Asia/Vientiane',
    shortName: '+07',
    value: 'Asia/Vientiane',
  },
  {
    gmtOffset: 'GMT+07:00',
    name: 'Etc/GMT-7',
    shortName: '+07',
    value: 'Etc/GMT-7',
  },
  {
    gmtOffset: 'GMT+07:00',
    name: 'Indian/Christmas',
    shortName: '+07',
    value: 'Indian/Christmas',
  },
  {
    gmtOffset: 'GMT+08:00',
    name: 'Antarctica/Casey',
    shortName: '+08',
    value: 'Antarctica/Casey',
  },
  {
    gmtOffset: 'GMT+08:00',
    name: 'Asia/Brunei',
    shortName: '+08',
    value: 'Asia/Brunei',
  },
  {
    gmtOffset: 'GMT+08:00',
    name: 'Asia/Choibalsan',
    shortName: '+08',
    value: 'Asia/Choibalsan',
  },
  {
    gmtOffset: 'GMT+08:00',
    name: 'Asia/Chongqing',
    shortName: 'CST',
    value: 'Asia/Chongqing',
  },
  {
    gmtOffset: 'GMT+08:00',
    name: 'Asia/Chungking',
    shortName: 'CST',
    value: 'Asia/Chungking',
  },
  {
    gmtOffset: 'GMT+08:00',
    name: 'Asia/Harbin',
    shortName: 'CST',
    value: 'Asia/Harbin',
  },
  {
    gmtOffset: 'GMT+08:00',
    name: 'Asia/Hong Kong',
    shortName: 'HKT',
    value: 'Asia/Hong_Kong',
  },
  {
    gmtOffset: 'GMT+08:00',
    name: 'Asia/Irkutsk',
    shortName: '+08',
    value: 'Asia/Irkutsk',
  },
  {
    gmtOffset: 'GMT+08:00',
    name: 'Asia/Kuala Lumpur',
    shortName: '+08',
    value: 'Asia/Kuala_Lumpur',
  },
  {
    gmtOffset: 'GMT+08:00',
    name: 'Asia/Kuching',
    shortName: '+08',
    value: 'Asia/Kuching',
  },
  {
    gmtOffset: 'GMT+08:00',
    name: 'Asia/Macao',
    shortName: 'CST',
    value: 'Asia/Macao',
  },
  {
    gmtOffset: 'GMT+08:00',
    name: 'Asia/Macau',
    shortName: 'CST',
    value: 'Asia/Macau',
  },
  {
    gmtOffset: 'GMT+08:00',
    name: 'Asia/Makassar',
    shortName: 'WITA',
    value: 'Asia/Makassar',
  },
  {
    gmtOffset: 'GMT+08:00',
    name: 'Asia/Manila',
    shortName: 'PST',
    value: 'Asia/Manila',
  },
  {
    gmtOffset: 'GMT+08:00',
    name: 'Asia/Shanghai',
    shortName: 'CST',
    value: 'Asia/Shanghai',
  },
  {
    gmtOffset: 'GMT+08:00',
    name: 'Asia/Singapore',
    shortName: '+08',
    value: 'Asia/Singapore',
  },
  {
    gmtOffset: 'GMT+08:00',
    name: 'Asia/Taipei',
    shortName: 'CST',
    value: 'Asia/Taipei',
  },
  {
    gmtOffset: 'GMT+08:00',
    name: 'Asia/Ujung Pandang',
    shortName: 'WITA',
    value: 'Asia/Ujung_Pandang',
  },
  {
    gmtOffset: 'GMT+08:00',
    name: 'Asia/Ulaanbaatar',
    shortName: '+08',
    value: 'Asia/Ulaanbaatar',
  },
  {
    gmtOffset: 'GMT+08:00',
    name: 'Asia/Ulan Bator',
    shortName: '+08',
    value: 'Asia/Ulan_Bator',
  },
  {
    gmtOffset: 'GMT+08:00',
    name: 'Australia/Perth',
    shortName: 'AWST',
    value: 'Australia/Perth',
  },
  {
    gmtOffset: 'GMT+08:00',
    name: 'Australia/West',
    shortName: 'AWST',
    value: 'Australia/West',
  },
  {
    gmtOffset: 'GMT+08:00',
    name: 'Etc/GMT-8',
    shortName: '+08',
    value: 'Etc/GMT-8',
  },
  {
    gmtOffset: 'GMT+08:00',
    name: 'Hongkong',
    shortName: 'HKT',
    value: 'Hongkong',
  },
  { gmtOffset: 'GMT+08:00', name: 'PRC', shortName: 'CST', value: 'PRC' },
  {
    gmtOffset: 'GMT+08:00',
    name: 'ROC',
    shortName: 'CST',
    value: 'ROC',
  },
  {
    gmtOffset: 'GMT+08:00',
    name: 'Singapore',
    shortName: '+08',
    value: 'Singapore',
  },
  {
    gmtOffset: 'GMT+08:45',
    name: 'Australia/Eucla',
    shortName: '+0845',
    value: 'Australia/Eucla',
  },
  {
    gmtOffset: 'GMT+09:00',
    name: 'Asia/Chita',
    shortName: '+09',
    value: 'Asia/Chita',
  },
  {
    gmtOffset: 'GMT+09:00',
    name: 'Asia/Dili',
    shortName: '+09',
    value: 'Asia/Dili',
  },
  {
    gmtOffset: 'GMT+09:00',
    name: 'Asia/Jayapura',
    shortName: 'WIT',
    value: 'Asia/Jayapura',
  },
  {
    gmtOffset: 'GMT+09:00',
    name: 'Asia/Khandyga',
    shortName: '+09',
    value: 'Asia/Khandyga',
  },
  {
    gmtOffset: 'GMT+09:00',
    name: 'Asia/Pyongyang',
    shortName: 'KST',
    value: 'Asia/Pyongyang',
  },
  {
    gmtOffset: 'GMT+09:00',
    name: 'Asia/Seoul',
    shortName: 'KST',
    value: 'Asia/Seoul',
  },
  {
    gmtOffset: 'GMT+09:00',
    name: 'Asia/Tokyo',
    shortName: 'JST',
    value: 'Asia/Tokyo',
  },
  {
    gmtOffset: 'GMT+09:00',
    name: 'Asia/Yakutsk',
    shortName: '+09',
    value: 'Asia/Yakutsk',
  },
  {
    gmtOffset: 'GMT+09:00',
    name: 'Etc/GMT-9',
    shortName: '+09',
    value: 'Etc/GMT-9',
  },
  { gmtOffset: 'GMT+09:00', name: 'Japan', shortName: 'JST', value: 'Japan' },
  {
    gmtOffset: 'GMT+09:00',
    name: 'Pacific/Palau',
    shortName: '+09',
    value: 'Pacific/Palau',
  },
  { gmtOffset: 'GMT+09:00', name: 'ROK', shortName: 'KST', value: 'ROK' },
  {
    gmtOffset: 'GMT+09:30',
    name: 'Australia/Darwin',
    shortName: 'ACST',
    value: 'Australia/Darwin',
  },
  {
    gmtOffset: 'GMT+09:30',
    name: 'Australia/North',
    shortName: 'ACST',
    value: 'Australia/North',
  },
  {
    gmtOffset: 'GMT+10:00',
    name: 'Antarctica/DumontDUrville',
    shortName: '+10',
    value: 'Antarctica/DumontDUrville',
  },
  {
    gmtOffset: 'GMT+10:00',
    name: 'Asia/Ust-Nera',
    shortName: '+10',
    value: 'Asia/Ust-Nera',
  },
  {
    gmtOffset: 'GMT+10:00',
    name: 'Asia/Vladivostok',
    shortName: '+10',
    value: 'Asia/Vladivostok',
  },
  {
    gmtOffset: 'GMT+10:00',
    name: 'Australia/Brisbane',
    shortName: 'AEST',
    value: 'Australia/Brisbane',
  },
  {
    gmtOffset: 'GMT+10:00',
    name: 'Australia/Lindeman',
    shortName: 'AEST',
    value: 'Australia/Lindeman',
  },
  {
    gmtOffset: 'GMT+10:00',
    name: 'Australia/Queensland',
    shortName: 'AEST',
    value: 'Australia/Queensland',
  },
  {
    gmtOffset: 'GMT+10:00',
    name: 'Etc/GMT-10',
    shortName: '+10',
    value: 'Etc/GMT-10',
  },
  {
    gmtOffset: 'GMT+10:00',
    name: 'Pacific/Chuuk',
    shortName: '+10',
    value: 'Pacific/Chuuk',
  },
  {
    gmtOffset: 'GMT+10:00',
    name: 'Pacific/Guam',
    shortName: 'ChST',
    value: 'Pacific/Guam',
  },
  {
    gmtOffset: 'GMT+10:00',
    name: 'Pacific/Port Moresby',
    shortName: '+10',
    value: 'Pacific/Port_Moresby',
  },
  {
    gmtOffset: 'GMT+10:00',
    name: 'Pacific/Saipan',
    shortName: 'ChST',
    value: 'Pacific/Saipan',
  },
  {
    gmtOffset: 'GMT+10:00',
    name: 'Pacific/Truk',
    shortName: '+10',
    value: 'Pacific/Truk',
  },
  {
    gmtOffset: 'GMT+10:00',
    name: 'Pacific/Yap',
    shortName: '+10',
    value: 'Pacific/Yap',
  },
  {
    gmtOffset: 'GMT+10:30',
    name: 'Australia/Adelaide',
    shortName: 'ACDT',
    value: 'Australia/Adelaide',
  },
  {
    gmtOffset: 'GMT+10:30',
    name: 'Australia/Broken Hill',
    shortName: 'ACDT',
    value: 'Australia/Broken_Hill',
  },
  {
    gmtOffset: 'GMT+10:30',
    name: 'Australia/South',
    shortName: 'ACDT',
    value: 'Australia/South',
  },
  {
    gmtOffset: 'GMT+10:30',
    name: 'Australia/Yancowinna',
    shortName: 'ACDT',
    value: 'Australia/Yancowinna',
  },
  {
    gmtOffset: 'GMT+11:00',
    name: 'Antarctica/Macquarie',
    shortName: '+11',
    value: 'Antarctica/Macquarie',
  },
  {
    gmtOffset: 'GMT+11:00',
    name: 'Asia/Magadan',
    shortName: '+11',
    value: 'Asia/Magadan',
  },
  {
    gmtOffset: 'GMT+11:00',
    name: 'Asia/Sakhalin',
    shortName: '+11',
    value: 'Asia/Sakhalin',
  },
  {
    gmtOffset: 'GMT+11:00',
    name: 'Asia/Srednekolymsk',
    shortName: '+11',
    value: 'Asia/Srednekolymsk',
  },
  {
    gmtOffset: 'GMT+11:00',
    name: 'Australia/ACT',
    shortName: 'AEDT',
    value: 'Australia/ACT',
  },
  {
    gmtOffset: 'GMT+11:00',
    name: 'Australia/Canberra',
    shortName: 'AEDT',
    value: 'Australia/Canberra',
  },
  {
    gmtOffset: 'GMT+11:00',
    name: 'Australia/Currie',
    shortName: 'AEDT',
    value: 'Australia/Currie',
  },
  {
    gmtOffset: 'GMT+11:00',
    name: 'Australia/Hobart',
    shortName: 'AEDT',
    value: 'Australia/Hobart',
  },
  {
    gmtOffset: 'GMT+11:00',
    name: 'Australia/LHI',
    shortName: '+11',
    value: 'Australia/LHI',
  },
  {
    gmtOffset: 'GMT+11:00',
    name: 'Australia/Lord Howe',
    shortName: '+11',
    value: 'Australia/Lord_Howe',
  },
  {
    gmtOffset: 'GMT+11:00',
    name: 'Australia/Melbourne',
    shortName: 'AEDT',
    value: 'Australia/Melbourne',
  },
  {
    gmtOffset: 'GMT+11:00',
    name: 'Australia/NSW',
    shortName: 'AEDT',
    value: 'Australia/NSW',
  },
  {
    gmtOffset: 'GMT+11:00',
    name: 'Australia/Sydney',
    shortName: 'AEDT',
    value: 'Australia/Sydney',
  },
  {
    gmtOffset: 'GMT+11:00',
    name: 'Australia/Tasmania',
    shortName: 'AEDT',
    value: 'Australia/Tasmania',
  },
  {
    gmtOffset: 'GMT+11:00',
    name: 'Australia/Victoria',
    shortName: 'AEDT',
    value: 'Australia/Victoria',
  },
  {
    gmtOffset: 'GMT+11:00',
    name: 'Etc/GMT-11',
    shortName: '+11',
    value: 'Etc/GMT-11',
  },
  {
    gmtOffset: 'GMT+11:00',
    name: 'Pacific/Bougainville',
    shortName: '+11',
    value: 'Pacific/Bougainville',
  },
  {
    gmtOffset: 'GMT+11:00',
    name: 'Pacific/Efate',
    shortName: '+11',
    value: 'Pacific/Efate',
  },
  {
    gmtOffset: 'GMT+11:00',
    name: 'Pacific/Guadalcanal',
    shortName: '+11',
    value: 'Pacific/Guadalcanal',
  },
  {
    gmtOffset: 'GMT+11:00',
    name: 'Pacific/Kosrae',
    shortName: '+11',
    value: 'Pacific/Kosrae',
  },
  {
    gmtOffset: 'GMT+11:00',
    name: 'Pacific/Noumea',
    shortName: '+11',
    value: 'Pacific/Noumea',
  },
  {
    gmtOffset: 'GMT+11:00',
    name: 'Pacific/Pohnpei',
    shortName: '+11',
    value: 'Pacific/Pohnpei',
  },
  {
    gmtOffset: 'GMT+11:00',
    name: 'Pacific/Ponape',
    shortName: '+11',
    value: 'Pacific/Ponape',
  },
  {
    gmtOffset: 'GMT+12:00',
    name: 'Asia/Anadyr',
    shortName: '+12',
    value: 'Asia/Anadyr',
  },
  {
    gmtOffset: 'GMT+12:00',
    name: 'Asia/Kamchatka',
    shortName: '+12',
    value: 'Asia/Kamchatka',
  },
  {
    gmtOffset: 'GMT+12:00',
    name: 'Etc/GMT-12',
    shortName: '+12',
    value: 'Etc/GMT-12',
  },
  {
    gmtOffset: 'GMT+12:00',
    name: 'Kwajalein',
    shortName: '+12',
    value: 'Kwajalein',
  },
  {
    gmtOffset: 'GMT+12:00',
    name: 'Pacific/Fiji',
    shortName: '+12',
    value: 'Pacific/Fiji',
  },
  {
    gmtOffset: 'GMT+12:00',
    name: 'Pacific/Funafuti',
    shortName: '+12',
    value: 'Pacific/Funafuti',
  },
  {
    gmtOffset: 'GMT+12:00',
    name: 'Pacific/Kwajalein',
    shortName: '+12',
    value: 'Pacific/Kwajalein',
  },
  {
    gmtOffset: 'GMT+12:00',
    name: 'Pacific/Majuro',
    shortName: '+12',
    value: 'Pacific/Majuro',
  },
  {
    gmtOffset: 'GMT+12:00',
    name: 'Pacific/Nauru',
    shortName: '+12',
    value: 'Pacific/Nauru',
  },
  {
    gmtOffset: 'GMT+12:00',
    name: 'Pacific/Norfolk',
    shortName: '+12',
    value: 'Pacific/Norfolk',
  },
  {
    gmtOffset: 'GMT+12:00',
    name: 'Pacific/Tarawa',
    shortName: '+12',
    value: 'Pacific/Tarawa',
  },
  {
    gmtOffset: 'GMT+12:00',
    name: 'Pacific/Wake',
    shortName: '+12',
    value: 'Pacific/Wake',
  },
  {
    gmtOffset: 'GMT+12:00',
    name: 'Pacific/Wallis',
    shortName: '+12',
    value: 'Pacific/Wallis',
  },
  {
    gmtOffset: 'GMT+13:00',
    name: 'Antarctica/McMurdo',
    shortName: 'NZDT',
    value: 'Antarctica/McMurdo',
  },
  {
    gmtOffset: 'GMT+13:00',
    name: 'Antarctica/South Pole',
    shortName: 'NZDT',
    value: 'Antarctica/South_Pole',
  },
  {
    gmtOffset: 'GMT+13:00',
    name: 'Etc/GMT-13',
    shortName: '+13',
    value: 'Etc/GMT-13',
  },
  { gmtOffset: 'GMT+13:00', name: 'NZ', shortName: 'NZDT', value: 'NZ' },
  {
    gmtOffset: 'GMT+13:00',
    name: 'Pacific/Auckland',
    shortName: 'NZDT',
    value: 'Pacific/Auckland',
  },
  {
    gmtOffset: 'GMT+13:00',
    name: 'Pacific/Enderbury',
    shortName: '+13',
    value: 'Pacific/Enderbury',
  },
  {
    gmtOffset: 'GMT+13:00',
    name: 'Pacific/Fakaofo',
    shortName: '+13',
    value: 'Pacific/Fakaofo',
  },
  {
    gmtOffset: 'GMT+13:00',
    name: 'Pacific/Tongatapu',
    shortName: '+13',
    value: 'Pacific/Tongatapu',
  },
  {
    gmtOffset: 'GMT+13:45',
    name: 'NZ-CHAT',
    shortName: '+1345',
    value: 'NZ-CHAT',
  },
  {
    gmtOffset: 'GMT+13:45',
    name: 'Pacific/Chatham',
    shortName: '+1345',
    value: 'Pacific/Chatham',
  },
  {
    gmtOffset: 'GMT+14:00',
    name: 'Etc/GMT-14',
    shortName: '+14',
    value: 'Etc/GMT-14',
  },
  {
    gmtOffset: 'GMT+14:00',
    name: 'Pacific/Apia',
    shortName: '+14',
    value: 'Pacific/Apia',
  },
  {
    gmtOffset: 'GMT+14:00',
    name: 'Pacific/Kiritimati',
    shortName: '+14',
    value: 'Pacific/Kiritimati',
  },
];
