<template>
  <div class="new-form-sidebar">
    <page-header class="_image">
      <template #title>
        <img
          src="@/assets/img/moon-icon.svg"
        >
      </template>
    </page-header>
    <m-content padding>
      <h2 class="_title">
        {{ $t('newFormSidebar.surveyTemplates') }}
      </h2>
      <!--eslint-disable-->
      <p
        class="_sub-title"
        v-html="this.$t('newFormSidebar.subTitle')"
      />
      <!--eslint-enable-->
      <div class="_items">
        <template
          v-for="item in navItems"
          :key="item.title"
        >
          <div
            :class="['_item', isActive(item) ? '-active' : '']"
          >
            <router-link
              :key="item.title"
              :to="item.to"
              class="_link"
            >
              {{ item.title }}
            </router-link>
          </div>
          <m-divider
            none
          />
        </template>
      </div>
    </m-content>
  </div>
</template>

<script>
import PageHeader from 'shared/components/PageHeader.vue';

export default {
  name: 'NewFormSidebar',
  props: {
    navItems: {
      type: Array,
      required: true,
    },
  },
  components: { PageHeader },
  methods: {
    isActive(item) {
      return JSON.stringify(this.$route.query) === JSON.stringify(item.to.query);
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .new-form-sidebar {
    flex: 1 0 32rem;
    background-color: white;
    border-right: 1px solid $border-color;

    ._image {
      img {
        max-width: 4rem;
        margin-bottom: 3rem;
      }
    }

    ._sub-title {
      margin-bottom: 6rem;
    }

    ._items {
      ._item {
        margin: 1.6rem 0;
        cursor: pointer;

        ._link {
          color: $font-color-primary;
          text-decoration: none;

          &.-active {
            font-weight: $font-weight-semibold;
          }
        }
      }
    }
  }
</style>
