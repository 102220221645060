<template>
  <div v-if="templatesLoading" />
  <update-editor
    v-else
    @cancel="$router.replace({ name: routeName.teamsTeamOkrs, query: { view: 'checkin' }})"
    @create="created"
  />
</template>

<script>
import UpdateEditor from '@/components/updates/UpdateEditor.vue';
import useUpdateTemplates from '@/composables/update-templates/update-templates';
import { routeName } from 'shared/constants.json';

export default {
  name: 'MSTeamsUpdatEditor',
  components: { UpdateEditor },
  setup() {
    const updateTemplatesSvc = useUpdateTemplates();
    return {
      fetchUpdateTemplates: updateTemplatesSvc.fetchUpdateTemplates,
      templatesLoading: updateTemplatesSvc.fetchUpdateTemplatesLoading,
    };
  },
  data() {
    return { routeName };
  },
  methods: {
    created() {
      this.$router.replace({ name: routeName.teamsTeamOkrs, query: { view: 'checkin' } });
    },
  },
  created() {
    this.fetchUpdateTemplates();
  },
};
</script>

<style>
</style>
