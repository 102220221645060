import { CREATED_AT, DEFAULT_ATTRIBUTES, ONE_TO_ONE, UID } from 'shared/api/query/constants';
import {
  accessPolicy,
  accessPolicyScope,
  account as accountConfig,
  distributionChannel,
  form,
  goalCycle,
  gridPageTile as gridPageTileConfig,
  mailProperties,
  planning as planningConfig,
  property as propertyConfig,
  propertyOption as propertyOptionConfig,
  propertySettings as propertySettingsConfig,
  propertyValue, savedView,
  selectedView as selectedViewConfig,
  space as spaceConfig,
  storageObject,
  user,
  userScopeTree,
} from 'shared/api/query/configs.json';
import { propertyVisibility } from 'shared/constants.json';
import { userScopeTreeChildren } from 'shared/api/query/user-scope';

export const reverseEdge = (edge) => `~${edge}`;

export const formDefaultEdges = [
  ...DEFAULT_ATTRIBUTES,
  {
    attr: form.edges.title,
    default: '',
  },
];

export const storageObjectEdges = [
  { attr: UID },
  { attr: 'getURL' },
  { attr: 'updateURL' },
  { attr: storageObject.edges.blob },
];

export const userEdgesSlim = [
  { attr: UID },
  { attr: user.edges.firstName, default: '' },
  { attr: user.edges.lastName, default: '' },
  { attr: user.edges.email, default: '' },
  { attr: user.edges.isDemo, default: false },
  {
    attr: user.edges.profileImage,
    default: null,
    model: storageObject.model,
    children: storageObjectEdges,
  },
];

export const propertySettingsEdges = [
  ...DEFAULT_ATTRIBUTES,
  { attr: propertySettingsConfig.edges.visibility, default: propertyVisibility.alwaysVisible },
  { attr: propertySettingsConfig.edges.property, model: propertyConfig.model, children: [{ attr: UID }] },
  { attr: propertySettingsConfig.edges.propertyOption, model: propertyOptionConfig.model, children: [{ attr: UID }] },
];

export const propertyOptionEdges = [
  ...DEFAULT_ATTRIBUTES,
  { attr: propertyOptionConfig.edges.archivedAt, default: null },
  { attr: propertyOptionConfig.edges.label, default: { de: '' } },
  { attr: propertyOptionConfig.edges.icon, default: '' },
  { attr: propertyOptionConfig.edges.color, default: null },
  { attr: propertyOptionConfig.edges.isNotDeletable, default: false },
  { attr: propertyOptionConfig.edges.isObjective, default: false },
  { attr: propertyOptionConfig.edges.isKeyResult, default: false },
  { attr: propertyOptionConfig.edges.goalProgressVisibility, default: propertyVisibility.alwaysVisible },
  { attr: propertyOptionConfig.edges.goalCycleVisibility, default: propertyVisibility.alwaysVisible },
  { attr: propertyOptionConfig.edges.goalParentVisibility, default: propertyVisibility.alwaysVisible },
  { attr: propertyOptionConfig.edges.descriptionVisibility, default: propertyVisibility.alwaysVisible },
  { attr: propertyOptionConfig.edges.descriptionRequired, default: false },
  { attr: propertyOptionConfig.edges.cycleRequired, default: false },
  { attr: propertyOptionConfig.edges.parentRequired, default: false },
  { attr: propertyOptionConfig.edges.score, default: null },
  {
    attr: propertyOptionConfig.edges.requiredProperties,
    model: propertyConfig.model,
    default: [],
    children: [{ attr: UID }],
  },
  {
    attr: propertyOptionConfig.edges.allowedMeasurementTypes,
    default: [],
  },
  {
    attr: propertyOptionConfig.edges.canBeChildOf,
    model: propertyOptionConfig.model,
    default: [],
    children: [{ attr: UID }],
  },
  {
    attr: reverseEdge(propertyOptionConfig.edges.parents),
    alias: 'children',
    model: propertyOptionConfig.model,
    children: [{ attr: UID }],
    default: [],
  },
  {
    attr: propertyOptionConfig.edges.property,
    model: propertyConfig.model,
    children: [{ attr: UID }],
  },
  {
    attr: propertyOptionConfig.edges.parents,
    model: propertyOptionConfig.model,
    children: [{ attr: UID }],
    default: [],
  },
  {
    attr: reverseEdge(propertySettingsConfig.edges.propertyOption),
    alias: 'propertySettings',
    model: propertySettingsConfig.model,
    children: propertySettingsEdges,
  },
];

const spaceEdgesSlim = [
  { attr: UID },
  { attr: spaceConfig.edges.title, default: '' },
  { attr: spaceConfig.edges.icon, default: '' },
  { attr: spaceConfig.edges.color, default: null },
];

export const propertyValueEdges = [
  ...DEFAULT_ATTRIBUTES,
  { attr: propertyValue.edges.number, default: null },
  { attr: propertyValue.edges.timestamp, default: null },
  { attr: propertyValue.edges.text, default: '' },
  {
    attr: propertyValue.edges.selectedOptions,
    model: propertyOptionConfig.model,
    default: [],
    children: propertyOptionEdges,
  },
  {
    attr: propertyValue.edges.spaces,
    model: spaceConfig.model,
    default: [],
    children: spaceEdgesSlim,
  },
  {
    attr: propertyValue.edges.property,
    model: propertyConfig.model,
    assoc: ONE_TO_ONE,
    children: [
      { attr: UID },
      { attr: propertyConfig.edges.type },
      { attr: propertyConfig.edges.label },
      { attr: propertyConfig.edges.isGoalType },
      { attr: propertyConfig.edges.status },
      { attr: propertyConfig.edges.numberFormat },
    ],
  },
  {
    attr: propertyValue.edges.users,
    model: user.model,
    default: [],
    order: [{ attr: user.edges.firstName }, { attr: user.edges.lastName }],
    children: userEdgesSlim,
  },
];

export const goalCycleChildren = [
  ...DEFAULT_ATTRIBUTES,
  { attr: goalCycle.edges.title },
  { attr: goalCycle.edges.color, default: 'default' },
  { attr: goalCycle.edges.start },
  { attr: goalCycle.edges.end },
];

export const savedViewChildren = [
  { attr: UID },
  { attr: savedView.edges.title, default: '' },
  { attr: savedView.edges.emoji, default: '' },
  { attr: savedView.edges.viewApplication },
  { attr: savedView.edges.viewType },
  { attr: savedView.edges.params, default: null },
  { attr: savedView.edges.isPublic, default: false },
  { attr: savedView.edges.isPublished, default: false },
  { attr: savedView.edges.passphrase },
  { attr: savedView.edges.space, model: spaceConfig.model, children: [{ attr: UID }] },
  { attr: savedView.edges.planning, model: planningConfig.model, children: [{ attr: UID }] },
  { attr: savedView.edges.gridPageTile, model: gridPageTileConfig.model, children: [{ attr: UID }] },
  { attr: savedView.edges.goalSort, default: [] },
  {
    attr: savedView.edges.creator,
    model: user.model,
    children: [{ attr: UID }, { attr: user.edges.firstName }, { attr: user.edges.lastName }],
    default: null,
  },
  { attr: savedView.edges.goalCycles, default: [], model: goalCycle.model, children: goalCycleChildren },
  { attr: savedView.edges.unassignedGoalCycleSelected, default: false },
];

export const selectedViewChildren = [
  { attr: UID },
  { attr: selectedViewConfig.edges.viewApplication },
  { attr: selectedViewConfig.edges.space, model: spaceConfig.model, children: [{ attr: UID }] },
  {
    attr: selectedViewConfig.edges.view,
    model: savedView.model,
    default: { uid: 0 },
    children: [{ attr: UID }, { attr: CREATED_AT }],
  },
  {
    attr: selectedViewConfig.edges.gridPageTile,
    model: gridPageTileConfig.model,
    children: [{ attr: UID }],
  },
  {
    attr: selectedViewConfig.edges.planning,
    model: planningConfig.model,
    children: [{ attr: UID }],
  },
];

export const accessPolicyScopeChildren = [
  { attr: UID },
  { attr: accessPolicyScope.edges.accessType },
  { attr: accessPolicyScope.edges.userIsInScope, default: false },
  {
    attr: accessPolicyScope.edges.scope,
    model: userScopeTree.model,
    default: null,
    children: userScopeTreeChildren,
  },
  {
    attr: accessPolicyScope.edges.users,
    model: user.model,
    default: [],
    children: userEdgesSlim,
  },
  {
    attr: accessPolicyScope.edges.scopeUsers,
    model: user.model,
    default: [],
    children: [{ attr: UID }],
  },
];

export const accessPolicyChildren = [
  { attr: UID },
  { attr: accessPolicy.edges.accountAccess },
  {
    attr: accessPolicy.edges.account,
    model: accountConfig.model,
    default: null,
    children: [{ attr: UID }],
  },
  {
    attr: accessPolicy.edges.scopes,
    model: accessPolicyScope.model,
    default: [],
    children: accessPolicyScopeChildren,
  },
];

export const distributionChannelChildren = [
  ...DEFAULT_ATTRIBUTES,
  { attr: distributionChannel.edges.type },
  {
    attr: distributionChannel.edges.mailProperties,
    default: null,
    model: mailProperties.model,
    children: [
      { attr: UID },
      { attr: mailProperties.edges.subject, default: '' },
      { attr: mailProperties.edges.body, default: '' },
    ],
  },
];
