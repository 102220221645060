import { meeting } from 'shared/api/query/configs.json';
import { propertyType } from 'shared/constants.json';

export const directProperties = ($t) => ([
  {
    label: $t('general.title'),
    edgeName: meeting.edges.title,
    type: propertyType.text,
    isTitle: true,
    hideInProps: true,
    noFilter: true,
    showByDefault: true,
    width: 600,
  },
  {
    label: $t('meeting.date'),
    type: propertyType.date,
    edgeName: meeting.edges.date,
    showByDefault: true,
    hideInProps: false,
    width: 200,
  },
  {
    label: $t('meeting.participants'),
    type: propertyType.user,
    edgeName: meeting.edges.participants,
    showByDefault: true,
    hideInProps: false,
    width: 200,
  },
]);
