import useGoals from '@/composables/goal/goals';
import useRepo from '@/nebula/repo';
import { goalActivity as goalActivityConfig } from 'shared/api/query/configs.json';

export default function useGoalActivity() {
  const repo = useRepo(goalActivityConfig.model);

  const goalsSvc = useGoals();

  const increaseGoalActivitiesCount = (goalActivities) => {
    const goals = goalsSvc.selectMultiple(goalActivities.map((ga) => ga.goal?.uid));
    goals.forEach((g) => {
      goalsSvc.updateSingle({ uid: g.uid, goalActivityCount: g.goalActivityCount + 1 }, { commitToRemote: false });
    });
  };

  const decreaseGoalActivitiesCount = (goalActivities) => {
    const goals = goalsSvc.selectMultiple(goalActivities.map((ga) => ga.goal?.uid));
    goals.forEach((g) => {
      goalsSvc.updateSingle({ uid: g.uid, goalActivityCount: g.goalActivityCount - 1 }, { commitToRemote: false });
    });
  };

  return {
    ...repo,
    increaseGoalActivitiesCount,
    decreaseGoalActivitiesCount,
  };
}
