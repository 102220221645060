<template>
  <div class="m-endless-scroll-list">
    <slot />
    <div
      ref="observable"
      class="_observable"
    />
  </div>
</template>

<script>
export default {
  name: 'MEndlessScrollList',
  data() {
    return { observer: null };
  },
  emits: ['visible'],
  methods: {
    handleObserved(event) {
      if (event[0].intersectionRatio > 0) {
        this.$emit('visible');
      }
    },
  },
  mounted() {
    this.observer = new IntersectionObserver(this.handleObserved, {
      root: null,
      rootMargin: '0px',
      threshold: this.$store.state.breakpoint.smAndDown ? 0.01 : 0.5,
    });
    this.observer.observe(this.$refs.observable);
  },
  beforeUnmount() {
    this.observer.unobserve(this.$refs.observable);
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .m-endless-scroll-list {
    position: relative;

    ._observable {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: .2rem;
    }
  }
</style>
