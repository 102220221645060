<template>
  <div class="account-settings">
    <full-screen-spinner v-if="initialLoading" />
    <m-content v-else>
      <page-header
        :title="$t('accountSettings.title')"
        boxed-xs
        heading="h3"
        underlined
        class="_header"
      />
      <m-content
        boxed-xs
        padding
        class="_content"
      >
        <m-section
          heading-size="h4"
          :title="$t('accountSettings.companyInfo.heading')"
          class="_settings _section"
        >
          <div class="_avatar">
            <m-avatar
              :size="120"
              :src="companyImage"
              class="_company-avatar"
              :text="loggedInUserAccount.companyName"
              shape="square"
            />
            <div class="_buttons">
              <m-tooltip :disabled="accountHasFeature([featureFlag.fileUpload])">
                <template #title>
                  {{ $t('fileUpload.featureDisabled') }}
                </template>
                <m-btn
                  :disabled="!accountHasFeature([featureFlag.fileUpload])"
                  @click="triggerInput"
                >
                  {{ $t('accountSettings.addLogo') }}
                </m-btn>
              </m-tooltip>

              <m-btn
                v-if="loggedInUserAccount.companyImage !== null"
                @click="deleteImage"
              >
                {{ $t('general.delete') }}
              </m-btn>
            </div>
          </div>
          <input
            ref="fileinput"
            type="file"
            :style="{display: 'none'}"
            accept="image/*"
            @change="fileChanged"
          >
          <m-dialog
            v-model:value="showCropImage"
            :title="$t('accountSettings.setCompanyPicture')"
            hide-footer
            keep-open-on-mask-click
            no-padding
          >
            <image-crop
              v-if="file !== null"
              class="_image-crop"
              :file="file"
              @cropped="uploadImage"
              @cancel="cancelImageCrop"
              @wrong-file-type="handleWrongFileType"
            />
          </m-dialog>
          <div
            class="_form"
          >
            <m-form-item
              :label="$t('accountSettings.companyInfo.nameLabel')"
            >
              <m-text-field
                v-model:value="companyName"
              />
            </m-form-item>
            <m-form-item
              :label="$t('accountSettings.companyInfo.visionLabel')"
            >
              <m-textarea
                v-model:value="vision"
                auto-size
              />
            </m-form-item>
            <m-form-item
              :label="$t('accountSettings.companyInfo.signUpPathName')"
              class="_domain"
            >
              <div class="_inner">
                <div class="_top">
                  <div class="_host">
                    {{ host }}
                  </div>
                  <m-text-field
                    v-model:value="signUpPathName"
                    :formatter="pathFormatter"
                  />
                </div>
                <div class="_description">
                  <i18n-t
                    keypath="accountSettings.companyInfo.signUpPathNameDescription"
                    tag="span"
                    @click="showHint = true"
                  >
                    <template #link>
                      <m-tooltip
                        :style="{ display: 'inline-flex', color: $colors.grey.darken1, textDecoration: 'underline' }"
                      >
                        <span
                          v-clipboard="signUpLink"
                          v-clipboard:success="clipboardSuccessHandler"
                          v-clipboard:error="onError"
                        >
                          <m-link
                            underline-on-hover
                            background-on-hover
                            inherit-color
                          >
                            {{ signUpLink }}
                          </m-link>
                        </span>
                        <template #title>
                          {{ $t('accountSettings.clickToCopy') }}
                        </template>
                      </m-tooltip>
                    </template>
                  </i18n-t>
                  <m-dialog
                    v-model:value="showHint"
                    hide-header
                    hide-footer
                    hide-cancel
                    max-width="30rem"
                    center
                  >
                    <div class="_invite-hint">
                      <div class="_icon">
                        <m-icon
                          type="warning"
                          :color="$colors.yellow.base"
                          size="40"
                        />
                      </div>
                      <div class="_hint-text">
                        {{ $t('accountSettings.companyInfo.inviteHint') }}
                      </div>
                      <m-btn
                        color="warning"
                        outlined
                        block
                        @click="showHint = false"
                      >
                        {{ $t('accountSettings.companyInfo.gotIt') }}
                      </m-btn>
                    </div>
                  </m-dialog>
                </div>
              </div>
            </m-form-item>
          </div>
          <m-btn
            class="_button"
            color="primary"
            :loading="loading"
            @click="submit"
          >
            {{ $t('general.save') }}
          </m-btn>
        </m-section>
        <m-section
          v-if="canManageModules"
          heading-size="h4"
          :title="$t('accountSettings.features.heading')"
          :sub-title="$t('accountSettings.features.subHeading')"
          class="_section"
        >
          <settings-switch-item
            :title="$t('accountSettings.features.goalsHeading')"
            :sub-title="$t('accountSettings.features.goalsDescription')"
            :value="accountSettings.usesGoals"
            :loading="loading"
            icon="compass"
            @click="showConfirm('goals', accountSettings.usesGoals)"
          />
          <m-divider small />
          <settings-switch-item
            :title="$t('accountSettings.features.updatesHeading')"
            :sub-title="$t('accountSettings.features.updatesDescription')"
            :value="accountSettings.usesUpdates"
            :loading="loading"
            icon="history"
            @click="showConfirm('updates', accountSettings.usesUpdates)"
          />
          <m-divider small />
        </m-section>
        <company-info
          class="_section"
        />
        <m-section
          heading-size="h4"
          :title="$t('accountSettings.reset.heading')"
          :sub-title="$t('accountSettings.reset.subHeading')"
          class="_section"
        >
          <m-btn
            class="_button"
            @click="showResetModal = true"
          >
            {{ $t('accountSettings.reset.button') }}
          </m-btn>
          <m-dialog
            v-model:value="showResetModal"
            hide-footer
            no-padding
          >
            <reset-account-card @close="showResetModal = false" />
          </m-dialog>
        </m-section>
      </m-content>
    </m-content>
  </div>
</template>

<script>
import CompanyInfo from '@/components/navigation/CompanyInfo.vue';
import FullScreenSpinner from 'shared/components/FullScreenSpinner.vue';
import ImageCrop from '@/components/ImageCrop.vue';
import PageHeader from 'shared/components/PageHeader.vue';
import ResetAccountCard from '@/components/ResetAccountCard.vue';
import SettingsSwitchItem from '@/components/SettingsSwitchItem.vue';
import useAccess from '@/composables/access/access';
import useAccountSettings from '@/composables/logged-in-user-account/account-settings';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import { featureFlag } from 'shared/constants.json';
import { logCatch } from '@/lib/logger/logger';

export default {
  name: 'AccountSettings',
  components: {
    SettingsSwitchItem,
    ResetAccountCard,
    FullScreenSpinner,
    PageHeader,
    ImageCrop,
    CompanyInfo,
  },
  setup() {
    const { accountHasFeature } = useAccess();
    const { loggedInUser } = useLoggedInUser();
    const accountSvc = useLoggedInUserAccount();
    const { accountSettings } = useAccountSettings();
    return {
      loggedInUser,
      loggedInUserAccount: accountSvc.loggedInUserAccount,
      accountSvc,
      accountSettings,
      featureFlag,
      accountHasFeature,
    };
  },
  data() {
    return {
      canManageModules: false,
      loading: false,
      initialLoading: false,
      showResetModal: false,
      file: null,
      showCropImage: false,
      signUpPathName: '',
      companyName: '',
      vision: '',
      showHint: false,
    };
  },
  computed: {
    values() {
      return {
        vision: this.vision,
        signUpPathName: this.signUpPathName,
        companyName: this.companyName,
      };
    },
    signUpLink() {
      return `${window.location.origin}/#/signup/${this.loggedInUserAccount.signUpPathName}`;
    },
    host() {
      return `${window.location.host}/#/signup/`;
    },
    companyImage() {
      if (this.loggedInUserAccount.companyImage === null) {
        return '';
      }
      return this.loggedInUserAccount.companyImage.getURL;
    },
  },
  methods: {
    deleteImage() {
      const deleteImage = () => {
        this.accountSvc.deleteImage().catch(logCatch(() => {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
        }));
      };
      this.$confirm({
        title: this.$t('accountSettings.deleteImagePrompt.content'),
        okText: this.$t('accountSettings.deleteImagePrompt.okText'),
        okType: 'danger',
        maskClosable: true,
        cancelText: this.$t('general.cancel'),
        onOk() {
          deleteImage();
        },
      });
    },
    clipboardSuccessHandler() {
      this.$showSnackbar({ color: 'success', message: this.$t('success.copied') });
    },
    onError() {
      this.$showSnackbar({ color: 'error', message: this.$t('error.duringCopying') });
    },
    pathFormatter(value) {
      let v = value;
      v = v.toLowerCase();
      v = v.replace('ö', 'oe');
      v = v.replace('ä', 'ae');
      v = v.replace('ü', 'ue');
      v = v.replace('ß', 'ss');
      v = v.replace(/\s/g, '-');
      v = v.replace(/[^a-zA-Z0-9_-]/g, '');
      return v;
    },
    triggerInput() {
      this.$refs.fileinput.click();
    },
    uploadImage(blob) {
      this.accountSvc.uploadImage(blob).then(() => {
        this.file = null;
        this.showCropImage = false;
        this.$showSnackbar({ color: 'success', message: this.$t('success.updated') });
      }).catch(logCatch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      }));
    },
    cancelImageCrop() {
      this.showCropImage = false;
      this.file = null;
    },
    handleWrongFileType() {
      this.showCropImage = false;
      this.file = null;
      this.$showSnackbar({ color: 'error', message: this.$t('error.notAnImage') });
    },
    fileChanged(event) {
      if (event.target.files.length === 0) {
        return;
      }

      this.file = event.target.files[0];
      this.showCropImage = true;
      this.$refs.fileinput.value = '';
    },
    submit() {
      this.loading = true;
      this.accountSvc.updateSingle({
        uid: this.loggedInUserAccount.uid,
        companyName: this.values.companyName,
        signUpPathName: this.values.signUpPathName,
        accountSettings: {
          uid: this.accountSettings.uid,
          vision: this.values.vision,
        },
      })
        .then(() => {
          this.$showSnackbar({ color: 'success', message: this.$t('success.updated') });
        })
        .catch(logCatch((e) => {
          if (e.message.indexOf('path name already exists') > -1) {
            this.$confirm({
              title: this.$t('accountSettings.nameTaken'),
              okText: this.$t('general.okay'),
              okType: 'warning',
              hideCancel: true,
              maskClosable: true,
            });
            return;
          }
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
        }))
        .finally(() => {
          this.loading = false;
        });
    },
    updateSettings({ appIntegration, accountSettings }) {
      const updateEntity = { uid: this.loggedInUserAccount.uid, accountSettings };
      if (appIntegration != null) {
        updateEntity.appIntegration = appIntegration;
      }

      this.loading = true;
      this.accountSvc.updateSingle(updateEntity)
        .then(() => {
          this.$showSnackbar({ color: 'success', message: this.$t('success.updated') });
        })
        .catch(logCatch(() => {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
        }))
        .finally(() => {
          this.loading = false;
        });
    },
    buttonText(isActive) {
      if (isActive) {
        return this.$t('accountSettings.features.deactivate');
      }
      return this.$t('accountSettings.features.activate');
    },
    buttonType(isActive) {
      if (isActive) {
        return 'danger';
      }
      return 'primary';
    },
    showConfirm(type, isActive) {
      const update = this.updateSettings;
      let title = '';
      let content = '';
      const accountSettings = { ...this.accountSettings };
      const appIntegration = null;
      switch (type) {
        case 'goals': {
          title = isActive ? this.$t('accountSettings.features.deactivateGoals.title') : this.$t('accountSettings.features.activateGoals.title');
          content = isActive ? this.$t('accountSettings.features.deactivateGoals.content') : this.$t('accountSettings.features.activateGoals.content');
          accountSettings.usesGoals = !accountSettings.usesGoals;
          break;
        }
        case 'surveys': {
          title = isActive ? this.$t('accountSettings.features.deactivateSurveys.title') : this.$t('accountSettings.features.activateSurveys.title');
          content = isActive ? this.$t('accountSettings.features.deactivateSurveys.content') : this.$t('accountSettings.features.activateSurveys.content');
          accountSettings.usesSurveys = !accountSettings.usesSurveys;
          break;
        }
        case 'updates': {
          title = isActive ? this.$t('accountSettings.features.deactivateUpdates.title') : this.$t('accountSettings.features.activateUpdates.title');
          content = isActive ? this.$t('accountSettings.features.deactivateUpdates.content') : this.$t('accountSettings.features.activateUpdates.content');
          accountSettings.usesUpdates = !accountSettings.usesUpdates;
          break;
        }
        default:
      }
      this.$confirm({
        title,
        content,
        okText: isActive ? this.$t('accountSettings.features.deactivate') : this.$t('accountSettings.features.activate'),
        okType: isActive ? 'danger' : 'primary',
        maskClosable: true,
        cancelText: this.$t('general.cancel'),
        onOk() {
          update({ accountSettings, appIntegration });
        },
      });
    },
    showReset() {
      if (typeof this.$route.query.showResetModal !== 'undefined') {
        this.showResetModal = true;
      }
    },
  },
  created() {
    this.showReset();
    this.companyName = this.loggedInUserAccount.companyName;
    this.vision = this.accountSettings.vision;
    this.signUpPathName = this.loggedInUserAccount.signUpPathName;
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .account-settings {
    ._header {
      margin-bottom: 2rem;
    }

    ._content {
      padding-bottom: 4rem;
    }

    ._section {
      margin-bottom: 4rem;
    }

    ._item {
      margin-bottom: 1.4rem;

      ._label {
        margin-bottom: .4rem;
        color: $font-color-secondary;
      }
    }

    ._domain {
      ._inner {
        display: flex;
        flex-direction: column;

        ._top {
          display: flex;

          ._host {
            display: flex;
            flex: 0 0 auto;
            align-items: center;
            margin-right: .4rem;
          }
        }

        ._description {
          display: flex;
          font-size: $font-size-3;
          line-height: 1.5;
          color: $font-color-secondary;
        }
      }
    }

    ._settings {
      ._avatar {
        margin-top: 2rem;
        margin-bottom: 2rem;

        ._company-avatar {
          margin-bottom: 1rem;
        }
      }

      ._buttons {
        display: flex;
        gap: .8rem;
      }
    }
  }

  ._invite-hint {
    ._icon {
      margin-bottom: 1.2rem;
    }

    ._hint-text {
      margin-bottom: 2rem;
    }
  }
</style>
