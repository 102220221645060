<template>
  <div
    class="_reminder"
  >
    <p>{{ $t('formSend.reminderDescription') }}</p>
    <m-alert
      v-if="surveyProcess.reminderCount === 0"
      type="info"
      class="_alert"
    >
      {{ $t('formSend.noReminders') }}
    </m-alert>
    <template
      v-if="surveyProcess.reminderCount > 0"
    >
      <p>
        {{ $t('formSend.timeDifferenceHeader') }}
      </p>
      <div class="_card-text">
        <duration-picker v-model:value="remindAfter" />
      </div>
    </template>
    <template
      v-if="surveyProcess.reminderCount > 0"
    >
      <h4 class="_reminder-title">
        {{ $t('formSend.previewTitle') }}
      </h4>
      <div class="_card-text -reminder-list">
        <transition-group
          name="list-left"
          mode="out-in"
        >
          <div
            v-for="i in surveyProcess.reminderCount"
            :key="i"
            class="_reminder-text"
          >
            <div class="_intro">
              {{ i }}. {{ $t('formSend.reminder') }}
            </div>
            <time-difference :duration="i * surveyProcess.remindAfter" />
            <m-btn
              icon="delete"
              fab
              class="_remove-btn"
              :disabled="disabled"
              hide-border
              small
              @click="decreaseReminderCount"
            />
          </div>
        </transition-group>
      </div>
    </template>
    <m-btn
      v-if="!disabled"
      text
      @click="addReminder"
    >
      {{ $t('formSend.addReminder') }}
    </m-btn>
  </div>
</template>

<script>
import DurationPicker from '@/components/DurationPicker.vue';
import TimeDifference from '@/components/TimeDifference.vue';

export default {
  name: 'SurveyReminderCard',
  props: {
    surveyProcess: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    DurationPicker,
    TimeDifference,
  },
  computed: {
    remindAfter: {
      get() {
        return this.$store.state.surveyProcess.remindAfter;
      },
      set(val) {
        this.$store.commit('SURVEY_PROCESS_REMIND_AFTER_CHANGED', { remindAfter: val });
      },
    },
  },
  methods: {
    decreaseReminderCount() {
      this.$store.commit('REMINDER_COUNT_DECREASED');
    },
    addReminder() {
      this.$store.commit('REMINDER_COUNT_INCREASED');
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  ._reminder {
    ._card-text {
      padding-top: 0;
      padding-bottom: 0;

      &.-reminder-list {
        padding-bottom: 0;
        margin-bottom: 1rem;
      }

      p {
        margin: 0;
      }

      ._alert {
        margin-top: 1.6rem;
      }
    }

    ._reminder-text {
      display: flex;
      align-items: center;
      padding: .4rem 0;

      ._intro {
        margin-right: .5rem;
      }

      ._remove-btn {
        margin-left: 1rem;
      }
    }

    ._reminder-title {
      margin-top: 1.2rem;
    }
  }
</style>
