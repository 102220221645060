<template>
  <m-dialog
    v-model:value="showUpgradeModal"
    hide-footer
    center
    max-width="35rem"
  >
    <div class="support-form">
      <m-btn
        icon="close"
        fab
        hide-border
        small
        class="_close-btn"
        @click="showUpgradeModal = false"
      />
      <div class="_header">
        <div class="_icon">
          <m-icon
            type="message"
            size="24"
          />
        </div>
        <div class="_heading">
          {{ $t('supportForm.heading') }}
        </div>
        <div class="_description">
          <i18n-t
            keypath="supportForm.description"
            tag="p"
          >
            <template #supportEmail>
              <m-link
                :href="`mailto:${$t('general.supportEmail')}`"
                underlined
              >
                <span style="font-weight: 500;">{{ $t('general.supportEmail') }}</span>
              </m-link>
            </template>
          </i18n-t>
          <div>
            {{ $t('supportForm.helpcenter.tip') }}
          </div>
        </div>
      </div>
      <m-btn
        color="primary"
        block
        target="_blank"
        :href="$t('general.helpCenterBaseUrl')"
      >
        <div :style="{ display: 'flex', alignItems: 'center' }">
          <div>{{ $t('supportForm.helpcenter.action') }}</div>
          <m-icon
            type="external-link"
            :style="{ marginLeft: '.8rem' }"
          />
        </div>
      </m-btn>
    </div>
  </m-dialog>
</template>

<script>
import useAccountSettings from '@/composables/logged-in-user-account/account-settings';
import { EVENTS } from '@/lib/constants';
import { EventBus } from '@/lib/event-bus';

export default {
  name: 'SupportFormDialog',
  setup() {
    const { accountSettings } = useAccountSettings();
    return { plan: accountSettings.planId };
  },
  data() {
    return { showUpgradeModal: false };
  },
  methods: {
    show() {
      this.showUpgradeModal = true;
      EventBus.$emit(EVENTS.UPSELL.CONTACT_SUPPORT_VIEWED, { currentPlan: this.plan });
    },
  },
  created() {
    EventBus.$on('show-support-form', this.show);
  },
  beforeUnmount() {
    EventBus.$off('show-support-form', this.show);
  },
};
</script>

<style scoped lang="scss" type="text/scss">
.support-form {
  position: relative;

  ._close-btn {
    position: absolute;
    top: -.4rem;
    right: -1.2rem;
  }

  ._icon {
    padding-top: 3rem;
    margin-bottom: 1.2rem;

    &.-success {
      padding-top: 1.2rem;
    }
  }

  ._header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
  }

  ._heading {
    margin-bottom: .6rem;
    font-size: $font-size-5;
    font-weight: $font-weight-semibold;
  }

  ._description {
    max-width: 25rem;
    font-size: $font-size-4;
    color: $font-color-secondary;
    text-align: center;
  }
}
</style>
