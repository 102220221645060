import {
  ONE_TO_ONE,
  RESULT,
  UID,
  VAR,
} from 'shared/api/query/constants';
import {
  distributionChannel,
  form,
  surveyProcess,
  user,
  userScopeTree,
} from 'shared/api/query/configs.json';
import { distributionChannelChildren, reverseEdge, userEdgesSlim } from 'shared/api/query/edges';
import { notHasFilter } from 'shared/api/query/filter';
import { userScopeTreeChildren } from 'shared/api/query/user-scope';

export const surveyProcessQuery = ({ formId, alias = RESULT }) => [
  {
    uid: [
      formId,
    ],
    alias: VAR,
    func: { name: UID },
    model: form.model,
    children: [
      {
        attr: reverseEdge(surveyProcess.edges.form),
        model: surveyProcess.model,
        filter: notHasFilter(surveyProcess.edges.cancelledAt),
        children: [
          { attr: UID, var: 'process' },
        ],
      },
    ],
  },
  {
    alias,
    model: surveyProcess.model,
    needsVar: [
      {
        name: 'process',
        typ: 1,
      },
    ],
    func: {
      name: UID,
      needsVar: [
        {
          name: 'process',
          typ: 1,
        },
      ],
    },
    children: [
      { attr: UID },
      { attr: surveyProcess.edges.schedule },
      { attr: surveyProcess.edges.remindAfter },
      { attr: surveyProcess.edges.reminderCount, default: 0 },
      { attr: surveyProcess.edges.activatedAt, default: null },
      { attr: surveyProcess.edges.cancelledAt, default: null },
      { attr: surveyProcess.edges.pulseMode, default: false },
      { attr: surveyProcess.edges.questionsPerPulse, default: 0 },
      {
        attr: surveyProcess.edges.form,
        model: form.model,
        children: [
          { attr: UID },
          { attr: form.edges.privacyThreshold, default: 5 },
          { attr: form.edges.pulseCalculation, default: false },
        ],
      },
      {
        attr: surveyProcess.edges.distributionChannels,
        default: [],
        model: distributionChannel.model,
        children: distributionChannelChildren,
      },
      {
        attr: surveyProcess.edges.userScopeTree,
        model: userScopeTree.model,
        default: null,
        assoc: ONE_TO_ONE,
        children: userScopeTreeChildren,
      },
      {
        attr: surveyProcess.edges.participants,
        model: user.model,
        default: [],
        children: userEdgesSlim,
      },
    ],
  },
];
