<template>
  <m-tag
    :icon="showIcon ? icon : ''"
    :icon-size="small ? 12 : 13"
    color="none"
    :xs="small"
    :m-style="mStyle"
    :small="!small"
    :title="diff"
    class="time-diff"
  />
</template>

<script>
import { DateTime } from 'luxon';
import { humanReadableDiffFromNow } from '@/lib/time/time';
import { mStyleProps } from 'shared/lib/m-style-props';

export default {
  name: 'TimeDiff',
  props: {
    ...mStyleProps,
    timestamp: {
      type: String,
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: 'calendar',
    },
  },
  computed: {
    diff() {
      const res = humanReadableDiffFromNow(DateTime.fromISO(this.timestamp));
      return this.$t(res.translationKey, res);
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
</style>
