import { UNASSIGNED } from '@/lib/constants';

export const goalIsInCycle = (goal, selectedCycles) => {
  if (!goal.completelyLoaded) {
    return false;
  }

  if (selectedCycles.length === 0) {
    return true;
  }

  const unassigned = selectedCycles.filter((c) => c.uid === UNASSIGNED).length > 0;
  if (unassigned && goal.goalCycle.length === 0) {
    return true;
  }

  const neededIds = selectedCycles.map((o) => o.uid);
  const goalCycleIds = goal.goalCycle.map((o) => o.uid);
  return neededIds.some((el) => goalCycleIds.indexOf(el) > -1);
};

export const goalTitleContains = (goal, searchTerm) => goal.title.toLowerCase().includes(searchTerm.toLowerCase());
