import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import { DateTime } from 'luxon';
import {
  accountSettings as accountSettingsConfig,
  user as userConfig,
} from 'shared/api/query/configs.json';
import { onboardingGuideSteps, onboardingSteps } from 'shared/constants.json';
import { ref } from 'vue';

export default function useOnboardingMarker(account, accountSettingsSvc) {
  const { loggedInUser, updateUser } = useLoggedInUser();
  const mappedSteps = {
    [onboardingGuideSteps.welcomeDialog]: { edge: userConfig.edges.welcomeModalShown, model: userConfig.model },
    [onboardingGuideSteps.productTour]: { edge: userConfig.edges.productTourCompleted, model: userConfig.model },
    [onboardingGuideSteps.videoTutorials]: { edge: userConfig.edges.watchedVideoTutorials, model: userConfig.model },
    [onboardingGuideSteps.okrResources]: { edge: userConfig.edges.checkedOutOKRResources, model: userConfig.model },
    [onboardingGuideSteps.gettingStartedGuide]: { edge: userConfig.edges.readGettingStartedGuide, model: userConfig.model },
    [onboardingGuideSteps.deleteDemoData]: { edge: accountSettingsConfig.edges.onboardingStepDeleteDemoData, model: accountSettingsConfig.model },
    [onboardingGuideSteps.inviteOthers]: { edge: accountSettingsConfig.edges.onboardingStepInviteOthers, model: accountSettingsConfig.model },
    [onboardingGuideSteps.setupSchedule]: { edge: userConfig.edges.checkinScheduleCreated, model: userConfig.model },
    [onboardingGuideSteps.markAllAsCompleted]: { edge: userConfig.edges.onboardingMarkAllAsDone, model: userConfig.model },
    [onboardingGuideSteps.whyMooncampIsUnique]: { edge: userConfig.edges.discoverMooncampCompleted, model: userConfig.model },
    [onboardingSteps.checkInTour]: { edge: userConfig.edges.onboardingStepCheckinChecked, model: userConfig.model },
    [onboardingSteps.planningListTour]: { edge: userConfig.edges.productTourPlanningListCompleted, model: userConfig.model },
    [onboardingSteps.planningDetailsTour]: { edge: userConfig.edges.productTourPlanningDetailsCompleted, model: userConfig.model },
    [onboardingSteps.goalTreeSlideShow]: { edge: userConfig.edges.goalTreeSlideShowShown, model: userConfig.model },
    [onboardingSteps.gridPageSlideShow]: { edge: userConfig.edges.gridPageSlideShowShown, model: userConfig.model },
  };

  const isDone = (step) => {
    if (loggedInUser.value.onboardingMarkAllAsDone !== null) {
      return true;
    }

    if (step === onboardingGuideSteps.messengers) {
      return (account.value.appIntegration.slackBotAccessToken !== '' && account.value.appIntegration.slackBotAccessToken !== null)
        || (loggedInUser.value.appIntegration !== null && loggedInUser.value.appIntegration.microsoftBotConversationID);
    }

    const mappedStep = mappedSteps[step];
    if (mappedStep === undefined) {
      throw new Error(`${step} not found in steps`);
    }

    if (mappedStep.model === userConfig.model) {
      return loggedInUser.value[mappedStep.edge] !== null;
    }
    if (mappedStep.model === accountSettingsConfig.model) {
      return accountSettingsSvc.accountSettings.value[mappedStep.edge];
    }
    return false;
  };

  const markAsDoneLoading = ref(false);
  const markAsDone = (steps) => {
    const userEdges = [];
    const accountSettingsEdges = [];
    steps.forEach((step) => {
      const mappedStep = mappedSteps[step];
      if (mappedStep === undefined) {
        return;
      }
      if (mappedStep.model === userConfig.model && loggedInUser.value[mappedStep.edge] === null) {
        userEdges.push(mappedStep.edge);
      }
      if (mappedStep.model === accountSettingsConfig.model && !accountSettingsSvc.accountSettings.value[mappedStep.edge]) {
        accountSettingsEdges.push(mappedStep.edge);
      }
    });

    if (userEdges.length === 0 && accountSettingsEdges.length === 0) {
      return new Promise((resolve) => { resolve(); });
    }

    markAsDoneLoading.value = true;
    const promises = [];
    if (userEdges.length > 0) {
      const entity = userEdges.reduce((res, next) => {
        res[next] = DateTime.local().toISO();
        return res;
      }, { uid: loggedInUser.value.uid });
      promises.push(updateUser(entity));
    }

    if (accountSettingsEdges.length > 0) {
      const entity = accountSettingsEdges.reduce((res, next) => {
        res[next] = true;
        return res;
      }, { uid: accountSettingsSvc.accountSettings.value.uid });
      promises.push(accountSettingsSvc.updateAccountSettings(entity));
    }

    return Promise.all(promises).finally(() => {
      markAsDoneLoading.value = false;
    });
  };

  return {
    markAsDoneLoading,
    markAsDone,
    isDone,
  };
}
