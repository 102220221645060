<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 68 62"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M53.2115 32.8233C45.0431 32.8233 38.4225 39.3543 38.4225 47.4111C38.4225 55.4685 45.0431 62 53.2115 62C61.3789 62 68 55.4685 68 47.4111C68 39.3543 61.3789 32.8233 53.2115 32.8233ZM14.7887 32.8243C6.62139 32.8243 0 39.3543 0 47.4119C0 55.4684 6.62139 62 14.7887 62C22.9566 62 29.5783 55.4684 29.5783 47.4119C29.5783 39.3543 22.9566 32.8243 14.7887 32.8243ZM48.7884 14.5878C48.7884 22.6447 42.1678 29.1772 34.0004 29.1772C25.8323 29.1772 19.2114 22.6447 19.2114 14.5878C19.2114 6.53155 25.8323 0 34.0004 0C42.1678 0 48.7884 6.53155 48.7884 14.5878Z"
      fill="url(#paint0_radial)"
    />
    <defs>
      <radialGradient
        id="paint0_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(34 33.8844) scale(45.0591 41.0833)"
      >
        <stop stop-color="#FFB900" />
        <stop
          offset="0.6"
          stop-color="#F95D8F"
        />
        <stop
          offset="0.9991"
          stop-color="#F95353"
        />
      </radialGradient>
    </defs>
  </svg>
</template>

<script>export default { name: 'Asana' }; </script>
