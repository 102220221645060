<template>
  <div
    :class="['submit-area', active ? '-active' : '']"
  >
    <div :class="['_submit _container', active ? '-active' : '']">
      <m-btn
        color="primary"
        large
        @click="sendForm"
      >
        {{ $t('submitArea.send') }}
      </m-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SubmitArea',
  props: {
    active: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['send'],
  methods: {
    sendForm() {
      this.$emit('send');
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .submit-area {
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: calc(70vh - 15rem);
    min-height: 25rem;
    padding: 3rem 0;
    overflow: hidden;
    pointer-events: none;

    @media only screen and (max-width: $screen-size-sm) {
      height: calc(70vh);
    }

    &.-active {
      margin-top: 15rem;
      pointer-events: all;
      border-top: .1rem solid $border-color;
    }

    ._submit {
      display: flex;
      align-items: center;
      visibility: hidden;
      transition: transform .3s;
      transform: translateY(20rem);

      &.-active {
        visibility: visible;
        transform: translateY(0);
      }

      .btn {
        pointer-events: all;

        @media only screen and (min-width: $screen-size-md) {
          margin-right: 2rem;
        }
      }
    }
  }
</style>
