import useSnackBar from '@/composables/snackbar';
import { ERRORS } from '@/lib/constants';
import { accessPolicyType, goalProgressMeasurement } from 'shared/constants.json';
import { difference, intersection } from 'lodash-es';
import { logCatch } from '@/lib/logger/logger';

export default function useSetParents({ goalsSvc, i18n, EventBus, snackbar = useSnackBar() }) {
  const onlyReadRights = (g) => ![accessPolicyType.write, accessPolicyType.full].includes(g.accessRight);
  const notMeasuredByAlignedItems = (g) => g.progressMeasurement !== goalProgressMeasurement.alignedItems;
  const measuredByNone = (g) => g.progressMeasurement === goalProgressMeasurement.none;
  const canChangeProgress = (g) => [accessPolicyType.write, accessPolicyType.full].includes(g.accessRight)
        && g.progressMeasurement === goalProgressMeasurement.alignedItems;

  const joinTitles = (titles) => {
    if (titles.length === 1) return titles[0];
    const firsts = titles.slice(0, titles.length - 1);
    const last = titles[titles.length - 1];
    return `${firsts.join(', ')} ${i18n.t('general.and')} ${last}`;
  };

  const changeParents = ({ goals, parents }, options = { showPrompt: true }) => {
    const missingRights = goals.filter(onlyReadRights);
    if (missingRights.length > 0) {
      return new Promise((_, reject) => {
        reject(new Error(`${ERRORS.REALIGN.MISSING_ACCESS_RIGHTS_FOR_GOAL}: ${missingRights.map((g) => g.title).join(', ')}`));
      });
    }

    const currentParents = goals.reduce((res, next) => {
      const ids = next.parents.map((p) => p.uid);
      res.all.push(...ids);
      if (res[next.uid] === undefined) {
        res[next.uid] = [];
      }
      res[next.uid].push(...ids.map((id) => ({ uid: id })));
      return res;
    }, { all: [] });
    const parentIds = parents.map((p) => p.uid);

    const commonIds = intersection(currentParents.all, parentIds);
    if (currentParents.all.length === parentIds.length && currentParents.all.length === commonIds.length) {
      return new Promise((resolve) => { resolve(); });
    }

    const newIds = difference(parentIds, currentParents.all);
    const newParents = goalsSvc.selectMultiple(newIds);
    const toRemove = difference(currentParents.all, parentIds);
    const parentsMissingRights = newParents.filter(onlyReadRights);
    const parentsNotAlignedItems = newParents.filter(notMeasuredByAlignedItems);
    const goalsByNone = goals.filter(measuredByNone);

    if (parentsMissingRights.length === newParents.length || parentsNotAlignedItems.length > 0 || goalsByNone.length > 0 || newIds.length === 0) {
      const entities = goals.map((g) => ({
        ...g,
        uid: g.uid,
        parents: parentIds.filter((uid) => !toRemove.includes(uid)).map((uid) => ({ uid })),
        paysOnto: g.paysOnto.filter((p) => !toRemove.includes(p.uid)).map((p) => ({ uid: p.uid })),
        progressMeasurement: g.progressMeasurement,
      }));
      goalsSvc.updateMultiple(entities);
      return new Promise((resolve) => { resolve(); });
    }

    const fullParents = goalsSvc.selectMultiple(parentIds);
    const allowedPaysOnto = fullParents.filter((p) => canChangeProgress(p));
    const newAllowedPaysOnto = allowedPaysOnto.filter((p) => newIds.includes(p.uid));

    const onOk = (setLoading, resolve) => {
      setLoading(true);
      const entities = goals.map((g) => ({
        ...g,
        uid: g.uid,
        parents: parentIds.map((uid) => ({ uid })),
        paysOnto: allowedPaysOnto.map((p) => ({ uid: p.uid })),
      }));
      goalsSvc.updateMultiple(entities)
        .catch(logCatch(() => {
          snackbar.error();
        }));
      setLoading(false);
      EventBus.$emit('hide-confirm');
      resolve();
    };

    const showPrompt = (resolve) => {
      const options = {
        title: i18n.t(
          'setParents.prompt',
          {
            goals: joinTitles(newAllowedPaysOnto.map((p) => {
              let title = p.title;
              if (title === '') {
                title = i18n.t('dataSource.untitled');
              }
              return `"${p.icon !== '' ? `${p.icon} ` : ''}${title}"`;
            })),
          },
          goals.length,
        ),
        cancelText: i18n.t('setParents.notSetPaysOnto'),
        okText: i18n.t('setParents.setPaysOnto'),
        okType: 'primary',
        cancelType: 'default',
        maskClosable: true,
        hideOnCancel: false,
        hideOnSubmit: false,
        onOk(setLoading) {
          return onOk(setLoading, resolve);
        },
        onCancel(setLoading) {
          setLoading(true);
          const entities = goals.map((g) => {
            const currentPaysOntoIds = g.paysOnto.map((g) => g.uid);
            return {
              ...g,
              uid: g.uid,
              parents: parentIds.map((uid) => ({ uid })),
              paysOnto: currentPaysOntoIds.filter((uid) => !newIds.includes(uid)).map((uid) => ({ uid })),
              progressMeasurement: g.progressMeasurement,
            };
          });
          goalsSvc.updateMultiple(entities)
            .catch(logCatch(() => {
              snackbar.error();
            }));
          setLoading(false);
          EventBus.$emit('hide-confirm');
          resolve();
        },
      };

      EventBus.$emit('show-confirm', options);
    };

    if (!options.showPrompt) {
      return new Promise((resolve) => {
        onOk(() => {}, resolve);
      });
    }
    return new Promise((resolve) => {
      showPrompt(resolve);
    });
  };

  return { changeParents };
}
