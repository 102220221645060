<template>
  <div
    class="meeting-list-item"
    :data-id="meeting.uid"
  >
    <list-item
      :entity="meeting"
      :props="props"
      :tiny="tiny"
      :selected="selected"
      :selected-secondary="selectedSecondary"
      :property-values-key="meetingConfig.edges.propertyValues"
      @select="select"
      @contextmenu="handleContextMenuClick"
    />
  </div>
</template>

<script>
import ListItem from '@/components/list/ListItem.vue';
import navigation from '@/mixins/router-navigation';
import { meeting as meetingConfig } from 'shared/api/query/configs.json';
import { routeName } from 'shared/constants.json';

export default {
  name: 'MeetingListItem',
  props: {
    meeting: {
      type: Object,
      required: true,
    },
    props: {
      type: Array,
      required: true,
    },
    tiny: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    selectedSecondary: {
      type: Boolean,
      default: false,
    },
    selectedIds: {
      type: Array,
      required: true,
    },
    createLoading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['selection-right-click', 'select-row'],
  components: { ListItem },
  data() {
    return { meetingConfig };
  },
  methods: {
    handleContextMenuClick(event) {
      event.preventDefault();
      if (this.selectedIds.length > 0 && this.selectedIds.includes(this.meeting.uid)) {
        this.$emit('selection-right-click', event);
        return;
      }

      this.$emit('select-row', { uid: this.meeting.uid, event });
    },
    select(meeting) {
      this.open({ uid: meeting.uid, paramKey: 'meetingId', mobileRouteName: routeName.meetingDetails });
    },
  },
  mixins: [navigation],
};
</script>

<style scoped lang="scss" type="text/scss">
  .meeting-list-item {
    ._children {
      margin-left: 2rem;

      ._item {
        padding: .2rem 0;
      }
    }
  }
</style>
