<template>
  <div
    :id="id"
    :class="['nav-item', active ? '-active' : '', `-hover-color-${hoverColor}`]"
    @click="onClick"
  >
    <m-tooltip
      placement="right"
      :disabled="!isTitleTruncated"
      :mouse-enter-delay=".5"
    >
      <template #title>
        {{ title }}
      </template>
      <m-link
        :to="to"
        inherit-color
        :style="{ width: '100%' }"
      >
        <nav-item-inner
          v-model:is-title-truncated="isTitleTruncated"
          :title="title"
          :badge="badge"
          :active="active"
          :icon="icon"
          :icon-size="iconSize"
          :show-avatar="showAvatar"
          :avatar-shape="avatarShape"
          :avatar-title="avatarTitle"
          :avatar-chars="avatarChars"
          :avatar-color="avatarColor"
          :avatar-black-and-white="avatarBlackAndWhite"
          :tag="tag"
          :force-show-actions="forceShowActions"
        >
          <template
            v-if="!!$slots.actions"
            #actions
          >
            <slot name="actions" />
          </template>
        </nav-item-inner>
      </m-link>
    </m-tooltip>
  </div>
</template>

<script>
import NavItemInner from '@/components/navigation/NavItemInner.vue';

export default {
  name: 'NavItem',
  props: {
    id: {
      type: [Number, String],
      default: () => null,
    },
    title: {
      type: String,
      required: true,
    },
    avatarTitle: {
      type: String,
      default: '',
    },
    avatarChars: {
      type: Number,
      default: 1,
    },
    avatarBlackAndWhite: {
      type: Boolean,
      default: false,
    },
    to: {
      type: [Object, String],
      default: '',
    },
    onClick: {
      type: Function,
      default: () => null,
    },
    icon: {
      type: String,
      default: '',
    },
    hoverColor: {
      type: String,
      default: 'regular',
    },
    src: {
      type: String,
      default: '',
    },
    avatarShape: {
      type: String,
      default: 'circle',
    },
    iconSize: {
      type: String,
      default: '14',
    },
    emoji: {
      type: String,
      default: '',
    },
    showAvatar: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    badge: {
      type: String,
      default: '',
    },
    avatarColor: {
      type: String,
      default: '',
    },
    forceShowActions: {
      type: Boolean,
      default: false,
    },
    tag: {
      type: Object,
      default: () => null,
    },
  },
  components: { NavItemInner },
  data() {
    return { isTitleTruncated: false };
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  .nav-item {
    width: 100%;
    padding: 0 .6rem 0 1.4rem;

    &.-hover-color-regular {
      &:hover {
        background-color: $hover-color;
      }

      &.-active {
        background-color: $hover-color;
      }
    }

    &.-hover-color-beige {
      &:hover {
        background-color: $sidebar-hover-color;
      }

      &.-active {
        background-color: $sidebar-hover-color;
      }
    }
  }
</style>
