<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1"
      y="7.36328"
      width="3"
      height="7.63636"
      fill="#3bb1cf"
    />
    <rect
      x="7"
      y="5.18164"
      width="3"
      height="9.81818"
      fill="#3bb1cf"
    />
    <rect
      x="13"
      y="3"
      width="3"
      height="12"
      fill="#3bb1cf"
    />
  </svg>
</template>

<script>
export default { name: 'CompletedIcon' };
</script>

<style scoped lang="scss" type="text/scss">

</style>
