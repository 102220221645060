import { DateTime } from 'luxon';
import { intervalType } from 'shared/num_constants.json';

export const getIntervalTypeByConfig = (intervalConfig) => {
  if (intervalConfig.from === null || intervalConfig.till === null) {
    return intervalType.monthly;
  }

  const start = DateTime.fromISO(intervalConfig.from);
  const end = DateTime.fromISO(intervalConfig.till);

  const { days } = end.diff(start, 'days').toObject();
  if (days < 14) {
    return intervalType.daily;
  }
  if (days < 7 * 14) {
    return intervalType.weekly;
  }
  return intervalType.monthly;
};
