<template>
  <div
    :class="['goal-update-form', disabled ? '-disabled' : '']"
    :data-id="activity.sortKey"
  >
    <div
      v-if="!!$slots.pre"
      class="_pre"
    >
      <slot
        name="pre"
        :activity="activity"
      />
    </div>
    <div :class="['_inner', showActions ? '-show-actions': '']">
      <m-content padding-small>
        <div
          :class="['_title']"
        >
          <m-tooltip
            v-if="activity.referenced === true || activity['goalActivities|referenced'] === true"
            class="_icon"
          >
            <span>
              <m-icon
                type="link"
              />
            </span>
            <template #title>
              {{ $t('goalActivityForm.referencedHint') }}
            </template>
          </m-tooltip>
          <m-tooltip
            style="overflow: hidden;"
            :disabled="wrapTitles"
          >
            <m-link
              :to="goalLink"
              inherit-color
              :no-pointer="readOnly"
              :target="disabled ? '_self' : '_blank'"
            >
              <item-title
                :title="title"
                :icons="[{ value: goalTypeIcon, showBackground: true, size: 18, tooltip: goalTypeIconTooltip }, { value: goalIcon }]"
                :class="['_title-text', readOnly? '' : '-clickable']"
                :wrap-title="wrapTitles"
              />
            </m-link>
            <template #title>
              {{ title }}
            </template>
          </m-tooltip>
        </div>
        <div
          v-if="!disabled && activity.referenced !== true && activity['goalActivities|referenced'] !== true"
          class="_update"
        >
          <div
            v-if="goal !== null && [goalProgressMeasurement.continuous, goalProgressMeasurement.threshold].includes(goal.progressMeasurement) && !disabled"
            class="_input"
          >
            <div
              class="_current"
            >
              <m-input-number
                ref="input"
                :value="current"
                :hide-border="disabled"
                :style="currentInputStyle"
                hide-handle
                :placeholder="$t('general.empty')"
                :formatter="formatter"
                :parser="parser"
                @input="updateCurrent"
              >
                <template
                  v-if="goal.metric !== ''"
                  #suffix
                >
                  {{ goal.metric }}
                </template>
              </m-input-number>
            </div>
          </div>
          <div
            v-if="goal.progressMeasurement === goalProgressMeasurement.binary"
            class="_input"
          >
            <m-checkbox
              :value="current === 1"
              :label="$t('goalActivityForm.done')"
              @input="handleComplete"
            />
          </div>
          <div class="_input">
            <m-select
              :value="selectedOptionOrDefault"
              full-width
              :grouped-items="groupedOptions"
              :value-key="'uid'"
              :item-text="'label.en'"
              return-object
              tags
              automatic-color
              has-custom-item
              :read-only="readOnly"
              :disabled="disabled"
              @change="updateStatus"
            >
              <template #item="item">
                <div>
                  <status-item
                    small
                    :selected-option="getSelectedOptionFromItem(item)"
                  />
                </div>
              </template>
            </m-select>
          </div>
          <div class="_input">
            <m-date-picker
              :value="customCreatedAt"
              class="_datepicker"
              :date-time="DateTime"
              :disabled="disabled"
              :hide-border="disabled"
              light
              placement="bottomCenter"
              show-time-picker
              :include-time="includeTime"
              :max="DateTime.local().toISO()"
              @change="updateCustomCreatedAt"
            />
          </div>
          <div
            v-if="goal.progressMeasurement === goalProgressMeasurement.alignedItems"
            class="_input"
          >
            <m-checkbox
              v-model:value="disableStatusAutoUpdate"
              light
              :label="$t('goalActivityForm.disableStatusAutoUpdate')"
              class="_checkbox"
              @input="updateDisableAutoUpdateStatus"
            />
          </div>
        </div>
        <div
          v-else
          class="_static"
        >
          <div
            class="_item"
          >
            <div
              v-if="typeof activity.previousCurrent !== 'undefined' && [goalProgressMeasurement.continuous, goalProgressMeasurement.threshold].includes(goal.progressMeasurement)"
              class="_number"
            >
              {{ formatNumber(activity.previousCurrent) }} {{ goal.metric }}
            </div>
            <div class="_status">
              <div
                v-if="showCompleted"
                class="_done"
              >
                <m-checkbox
                  :value="activity.previousCurrent > 0"
                  :clickable="false"
                  :label="$t('goalActivityForm.done')"
                />
              </div>
              <status-item
                :selected-option="previousSelectedOption"
              />
            </div>
          </div>
          <div class="_arrow">
            <m-icon
              type="arrow-go-forward"
              :color="rgbaToHex($colors.grey.base)"
              size="20"
            />
          </div>
          <div
            class="_item"
          >
            <div
              v-if="[goalProgressMeasurement.continuous, goalProgressMeasurement.threshold].includes(goal.progressMeasurement)"
              class="_number"
            >
              {{ formatNumber(activity.current) }} {{ goal.metric }}
            </div>
            <div class="_status">
              <div
                v-if="showCompleted"
                class="_done"
              >
                <m-checkbox
                  :value="activity.current > 0"
                  :clickable="false"
                  :label="$t('goalActivityForm.done')"
                />
              </div>
              <status-item
                :selected-option="selectedOption"
              />
            </div>
          </div>
          <div class="_custom-created-at">
            {{ formatDate(activity.customCreatedAt) }}
          </div>
        </div>
      </m-content>
      <m-content
        v-if="!disabled || activity.message !== null"
        padding-small
        :padding-top="0"
      >
        <m-divider none />
        <div :class="['_message', focused ? '-focused' : '', disabled ? '-disabled' : '']">
          <m-editor
            ref="editor"
            :disabled="disabled"
            :allowed-content="allowedContent"
            :initial-value="activity.message"
            light-placeholder
            :placeholder="$t('goalUpdateEditor.writeComment')"
            scrollable
            @focus="focused = true"
            @blur="focused = false"
            @input="setMessage"
          />
        </div>
      </m-content>
    </div>
    <div
      v-if="showActions"
      class="_actions"
    >
      <m-btn
        small
        light
        hide-border
        icon="close"
        fab
        @click="$emit('delete')"
      />
    </div>
  </div>
</template>

<script>
import StatusItem from '@/components/goal/StatusItem.vue';
import { DateTime } from 'luxon';
import { GOAL_DETAILS } from '@/route-names';
import { editorNodeType, goalProgressMeasurement, numberFormat, propertyType } from 'shared/constants.json';
import { findInArray } from 'shared/lib/array/array';
import { formatNumber } from '@/lib/goal/progress';
import { fromISO, toISO } from 'shared/lib/time';

import ItemTitle from '@/components/ItemTitle.vue';
import MEditor from '@/components/editor/MEditor.vue';
import useGoalProperty from '@/composables/property/goal-property';
import useGoalTypeProperty from '@/composables/customize-page/goal-type-property';
import useGoals from '@/composables/goal/goals';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useStatusProperty from '@/composables/goal/status-property';
import { buildIconFromEntity } from 'shared/lib/icon';
import { computed, ref } from 'vue';
import { numberFormatter, numberParser } from '@/lib/props/number';
import { rgbaToHex } from 'shared/lib/color';
import { textByLang } from 'shared/lib/language';

export default {
  name: 'GoalActivityForm',
  props: {
    activity: {
      type: Object,
      required: true,
    },
    preventSearch: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    wrapTitles: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { goalTypeIcon, goalTypeOption } = useGoalTypeProperty();
    const { statusProperty: statusProp } = useGoalProperty();
    const { userLang } = useLoggedInUser();

    const { getSelectedOption, getSelectedOptionLabel, activeStatusOption, closedStatusOption, groupedOptions, options, defaultOption, calculateStatus } = useStatusProperty(statusProp, userLang);

    const properties = ref([]);
    const selectedOption = computed(() => getSelectedOption(props.activity.properties));
    const selectedOptionOrDefault = computed(() => findInArray({ haystack: options.value, needle: selectedOption.value.uid, defaultValue: defaultOption.value }));
    const previousSelectedOption = computed(() => getSelectedOption(props.activity.previousProperties));

    const goalsSvc = useGoals();

    return {
      userLang,
      selectedOption,
      selectedOptionOrDefault,
      groupedOptions,
      getSelectedOption,
      getSelectedOptionLabel,
      activeStatusOption,
      closedStatusOption,
      options,
      calculateStatus,
      properties,
      previousSelectedOption,
      goalTypeIconFn: goalTypeIcon,
      goalTypeOption,
      goalsSvc,
    };
  },
  emits: ['delete', 'change'],
  components: { MEditor, ItemTitle, StatusItem },
  data() {
    return {
      statusSetManually: false,
      focused: false,
      customCreatedAt: fromISO(this.activity.customCreatedAt),
      current: this.activity.current,
      goalProgressMeasurement,
      rgbaToHex,
      DateTime,
      message: null,
      disableStatusAutoUpdate: false,
      allowedContent: [
        editorNodeType.codeBlock,
        editorNodeType.blockquote,
        editorNodeType.bulletList,
        editorNodeType.orderedList,
        editorNodeType.file,
        editorNodeType.mention,
      ],
    };
  },
  computed: {
    goal() {
      const g = this.goalsSvc.selectSingle(this.activity.goal.uid);
      if (g === undefined) {
        return null;
      }
      return g;
    },
    goalTypeIcon() {
      return this.goalTypeIconFn(this.goal);
    },
    goalTypeIconTooltip() {
      const type = this.goalTypeOption(this.goal);
      if (type === null) {
        return '';
      }
      return textByLang(type.label, this.userLang);
    },
    formatter() {
      return numberFormatter(numberFormat.number, this.userLang);
    },
    parser() {
      return numberParser(numberFormat.number, this.userLang);
    },
    includeTime() {
      if (this.activity.uid === 0) {
        return false;
      }

      return this.customCreatedAt.startTime !== '24:00';
    },
    showActions() {
      return this.clearable && !this.disabled;
    },
    showCompleted() {
      if (this.goal.progressMeasurement !== goalProgressMeasurement.binary) {
        return false;
      }

      return this.activity.current !== this.activity.previousCurrent;
    },
    goalLink() {
      if (this.readOnly) {
        return '';
      }

      if (this.goal === null) {
        return '';
      }

      return {
        name: GOAL_DETAILS,
        params: { goalId: this.goal.uid },
      };
    },
    title() {
      if (this.goal === null || this.goal.title === '') {
        return this.$t('list.noTitle');
      }

      return this.goal.title;
    },
    goalIcon() {
      return buildIconFromEntity(this.goal);
    },
    currentInputStyle() {
      let inputWidth = Math.ceil(`${this.current}`.length * 9) + 30;
      inputWidth += Math.ceil(this.goal.metric.length * 9);
      return {
        width: `${inputWidth}px`,
        minWidth: '10rem',
      };
    },
    propertyStatusIndex() {
      return this.properties.findIndex((propertyValue) => propertyValue.property.type === propertyType.status);
    },
  },
  methods: {
    textByLang,
    getSelectedOptionFromItem(item) {
      if (item.isTrigger) {
        return item.item;
      }
      return this.options.find((o) => o.uid === item.item);
    },
    updateDisableAutoUpdateStatus(value) {
      this.disableStatusAutoUpdate = value;
      this.$emit('change', {
        ...this.activity,
        goal: { ...this.goal, disableStatusAutoUpdate: this.disableStatusAutoUpdate },
      });
    },
    setMessage(message) {
      if (JSON.stringify(this.message) === JSON.stringify(message)) {
        return;
      }

      this.message = message;
      this.emitChange();
    },
    formatDate(date) {
      return DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED);
    },
    emitChange() {
      this.$emit('change', {
        ...this.activity,
        current: this.current,
        goal: { ...this.goal, disableStatusAutoUpdate: this.disableStatusAutoUpdate },
        properties: this.properties,
        message: this.message,
      });
    },
    updateCustomCreatedAt(value) {
      this.customCreatedAt = value;
      if (!this.statusSetManually) {
        this.properties[this.propertyStatusIndex].selectedOptions = [this.calculateStatus(this.current, this.goal, DateTime.fromISO(this.customCreatedAt.startDate))];
      }

      this.$emit('change', {
        ...this.activity,
        customCreatedAt: toISO(this.customCreatedAt),
        properties: this.properties,
      });
    },
    updateStatus(value) {
      this.properties[this.propertyStatusIndex].selectedOptions = [value];
      this.statusSetManually = true;
      this.emitChange();
    },
    updateCurrent(value) {
      this.current = value;
      if (!this.statusSetManually) {
        this.properties[this.propertyStatusIndex].selectedOptions = [this.calculateStatus(this.current, this.goal, DateTime.fromISO(this.customCreatedAt.startDate))];
      }
      this.emitChange();
    },
    formatNumber(value) {
      if (typeof value === 'undefined') {
        return '';
      }
      return formatNumber(value, this.userLang);
    },

    handleComplete(checked) {
      if (checked) {
        this.updateCurrent(1);
        return;
      }
      this.updateCurrent(0);
    },
  },
  watch: {
    activity() {
      this.current = this.activity.current;
      this.properties = this.activity.properties;
    },
  },
  created() {
    this.message = this.activity.message;
    this.properties = this.activity.properties;
    if (this.activity.goal !== undefined && this.activity.goal !== null) {
      this.disableStatusAutoUpdate = this.goal.disableStatusAutoUpdate === true;
    }
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  .goal-update-form {
    display: flex;
    background-color: white;
    border: 1px solid $input-border-color;
    border-radius: $border-radius-sm;

    ._pre {
      margin-top: .6rem;
      margin-right: .8rem;
    }

    ._inner {
      flex: 1 1 auto;

      //somehow needed: https://stackoverflow.com/questions/38223879/white-space-nowrap-breaks-flexbox-layout
      min-width: 0;
      max-width: 100%;

      &.-show-actions {
        max-width: calc(100% - 3.6rem);
      }

      ._message {
        padding: .4rem .4rem .2rem;
        margin: .9rem -.4rem -.4rem;
        border-radius: $default-border-radius;

        &:hover {
          &:not(.-focused) {
            &:not(.-disabled) {
              cursor: pointer;
              background-color: $hover-color;
            }
          }
        }
      }

      ._top {
        position: relative;
        display: flex;
        align-items: center;
      }
    }

    ._actions {
      display: flex;
      flex: 0 0 3rem;
      justify-content: flex-end;
      margin-left: auto;
      padding: .4rem;
    }

    ._static {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      ._item {
        display: flex;
        align-items: center;
        margin-top: .4rem;

        ._status {
          display: flex;
          align-items: center;
        }

        ._number {
          margin-right: 1.2rem;
          margin-left: .6rem;
          white-space: nowrap;
        }

        ._done {
          margin-right: .8rem;
        }
      }

      ._arrow {
        padding-top: .4rem;
        margin: 0 1rem;
      }

      ._custom-created-at {
        margin-top: .4rem;
        margin-left: 2.4rem;
        color: $font-color-secondary;
      }
    }

    ._update {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      ._input {
        display: flex;
        margin-top: .8rem;
        margin-right: 1.4rem;
        max-width: 100%;

        ._status-item {
          margin: 0;
        }

        ._select-item {
          display: flex;
          align-items: center;
        }

        ._current {
          position: relative;
          display: flex;
          align-items: center;

          ._metric-icon {
            margin-right: .8rem;
          }

        }

        ._checkbox {
          max-width: 100%;
        }
      }
    }

    ._title {
      display: flex;
      align-items: center;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      ._title-text {
        display: block;
        padding: .4rem .6rem;
        overflow: hidden;
        font-weight: $font-weight-medium;
        text-overflow: ellipsis;
        border-radius: $default-border-radius;

        &.-clickable {
          &:hover {
            background-color: $hover-color;
          }
        }
      }

      ._icon {
        padding-left: .4rem;
      }
    }

    &.-disabled {
      ._update {
        margin-bottom: .4rem;
        margin-left: .6rem;
      }

      ._pre {
        margin-top: .1rem;
      }
    }
  }
</style>
