<template>
  <item-wrapper
    v-if="hasChild"
    :tooltip="prop.label"
    :show-tooltip="showTooltip"
    :tooltip-placement="tooltipPlacement"
    :style="{ width: '100%' }"
  >
    <div
      class="component-prop"
    >
      <goal-progress-table-cell
        v-if="prop.property.component === GOAL_PROGRESS && entity.progressMeasurement !== goalProgressMeasurement.none"
        :entity="entity"
        :m-style="mStyle"
        :align-diff-right="alignDiffRight"
        :show-progress-diff="showProgressDiff"
        :read-only="readOnly"
        :small="small"
        :progress-bar-width="progressBarWidth"
        @click="$emit('click')"
      />
      <time-diff
        v-if="prop.property.component === TIME_DIFF && entity[prop.property.edgeName] !== null"
        :show-icon="showIcon"
        :timestamp="entity[prop.property.edgeName]"
        :icon="iconByType({ type: prop.property.type, edgeName: prop.property.edgeName })"
        class="_time-diff"
        :m-style="mStyle"
        :small="small"
      />
      <user-display
        v-if="prop.property.component === USER_NAME"
        :user="entity"
        :m-style="mStyle"
        :small="small"
      />
    </div>
  </item-wrapper>
</template>

<script>
import GoalProgressTableCell from '@/components/list/GoalProgressTableCell.vue';
import ItemWrapper from '@/components/list/ItemWrapper.vue';
import TimeDiff from '@/components/list/TimeDiff.vue';
import UserDisplay from 'shared/components/UserDisplay.vue';
import { GOAL_PROGRESS, TIME_DIFF, USER_NAME } from '@/lib/props/custom-types';
import { goalProgressMeasurement } from 'shared/constants.json';
import { iconByType } from '@/lib/property';
import { mStyleProps } from 'shared/lib/m-style-props';

export default {
  name: 'ComponentProp',
  props: {
    ...mStyleProps,
    prop: {
      type: Object,
      required: true,
    },
    entity: {
      type: Object,
      required: true,
    },
    propertyValuesKey: {
      type: String,
      default: 'properties',
    },
    progressBarWidth: {
      type: String,
      default: '100%',
    },
    showTooltip: {
      type: Boolean,
      default: false,
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
    tooltipPlacement: {
      type: String,
      default: 'top',
    },
    alignDiffRight: {
      type: Boolean,
      default: false,
    },
    showProgressDiff: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  components: { GoalProgressTableCell, UserDisplay, ItemWrapper, TimeDiff },
  data() {
    return { GOAL_PROGRESS, USER_NAME, TIME_DIFF, goalProgressMeasurement, iconByType };
  },
  computed: {
    hasChild() {
      if ([USER_NAME, TIME_DIFF].includes(this.prop.property.component)) {
        return true;
      }
      return this.prop.property.component === GOAL_PROGRESS && this.entity.progressMeasurement !== goalProgressMeasurement.none;
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .component-prop {
    display: flex;
    align-items: flex-start;
    width: 100%;

    ._time-diff {
      white-space: nowrap;
    }
  }
</style>
