<template>
  <m-card-item
    :icon="icon"
    :clickable="clickable"
  >
    <m-dialog
      v-model:value="showEdit"
      hide-footer
      :title="$t(`dataSourceItem.editTitle.${camelCase(dataSource.type)}`)"
    >
      <data-source-editor
        v-if="isOAuth2"
        :entity="dataSource"
        @close="close"
      />
      <jira-editor
        v-if="dataSource.type === dataSourceType.jira"
        :entity="dataSource"
        @close="close"
      />
    </m-dialog>
    <span>{{ dataSourceName(dataSource) }}</span>
    <m-content :padding-x="10">
      <m-tooltip>
        <template #title>
          {{ $t('dataSourceItem.creatorDescription') }}
        </template>
        <user-display
          :user="creator"
          :size="20"
          xs
        />
      </m-tooltip>
    </m-content>

    <template #right>
      <template v-if="expired">
        <m-tooltip
          v-if="expireWarningAction"
          placement="top"
        >
          <template #title>
            {{ $t('dataSourceItem.refreshDescription') }}
          </template>
          <div>
            <m-btn
              icon="warning"
              hide-border
              xs
              :icon-color="$colors.yellow.base"
              :disabled="disableManageActions"
              @click.stop="refresh"
            >
              {{ $t('dataSourceItem.refreshAction') }}
            </m-btn>
          </div>
        </m-tooltip>
        <div
          v-else-if="expireWarning"
          class="_expire-warning"
        >
          <div>
            <m-icon
              type="warning"
              :color="$colors.yellow.base"
            />
          </div>
          <div>{{ $t('dataSourceItem.connectionExpired') }}</div>
        </div>
      </template>
      <m-tooltip
        v-else-if="isIncomplete"
        placement="top"
      >
        <template #title>
          {{ $t('dataSourceItem.incompleteDescription') }}
        </template>
        <div :style="{marginRight: '.8rem'}">
          <m-btn
            icon="warning"
            hide-border
            xs
            :icon-color="$colors.yellow.base"
            :disabled="disableManageActions"
            @click.stop="edit"
          >
            {{ $t('dataSourceItem.establishAction') }}
          </m-btn>
        </div>
      </m-tooltip>

      <m-dropdown
        v-model:value="showActions"
        :title="$t('general.actions')"
        close-on-click
        :disabled="disableManageActions"
      >
        <m-btn
          icon="ellipsis"
          fab
          xs
          hide-border
          :disabled="disableManageActions"
          @click.stop="showActions = true"
        />
        <template #overlay>
          <m-card
            no-padding
            list
          >
            <m-card-item @click="edit">
              {{ $t('general.edit') }}
            </m-card-item>
            <m-card-item @click="deleteItem">
              {{ $t('general.delete') }}
            </m-card-item>
          </m-card>
        </template>
      </m-dropdown>
    </template>
  </m-card-item>
</template>

<script>
import DataSourceEditor from '@/components/datasource/DataSourceEditor.vue';
import JiraEditor from '@/components/datasource/JiraEditor.vue';
import UserDisplay from 'shared/components/UserDisplay.vue';
import useAccess from '@/composables/access/access';
import useDataSources from '@/composables/integrations/datasources';
import useGoalSettings from '@/composables/logged-in-user-account/goal-settings';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useUsers from '@/composables/user/users';
import { accessGroupFlag, dataSourceAuthenticationType, dataSourceType } from 'shared/constants.json';
import { camelCase } from 'lodash-es';
import { computed } from 'vue';
import { connectUrl, dataSourceIcon, expiresWithin } from '@/lib/data-source';
import { dataSourceInstallStatus } from '@/constants.json';
import { logCatch } from '@/lib/logger/logger';
import { windowMessage } from '@/lib/oauth-redirect';

export default {
  name: 'DataSourceItem',
  props: {
    dataSource: {
      type: Object,
      required: true,
    },
    clickable: {
      type: Boolean,
      default: true,
    },
    showExpireAction: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
  components: { DataSourceEditor, JiraEditor, UserDisplay },
  setup(props) {
    const { userHasRight } = useAccess();

    const { loggedInUser } = useLoggedInUser();
    const { dataSourceName, fetchDataSource, deleteDataSource } = useDataSources();
    const { goalSettings } = useGoalSettings();

    const userSvc = useUsers();

    return {
      loggedInUser,
      goalSettings,

      dataSourceName,
      fetchDataSource,
      deleteDataSource,

      userHasRight,
      accessGroupFlag,

      creator: computed(() => {
        if (props.dataSource.creator === null) {
          return null;
        }

        return userSvc.selectSingle(props.dataSource.creator.uid);
      }),
    };
  },
  data() {
    return { showActions: false, showEdit: false, camelCase, dataSourceType };
  },
  computed: {
    icon() {
      return dataSourceIcon(this.dataSource);
    },
    isOAuth2() {
      return [dataSourceType.msCalendar, dataSourceType.sheets, dataSourceType.excel, dataSourceType.hubspot, dataSourceType.asana, dataSourceType.salesforce].includes(this.dataSource.type);
    },
    expired() {
      return expiresWithin(this.dataSource, 5);
    },
    expireWarning() {
      if (!this.expired) {
        return false;
      }

      if (this.dataSource.creator !== null && this.dataSource.creator.uid !== this.loggedInUser.uid) {
        return true;
      }

      return !this.showExpireAction;
    },
    expireWarningAction() {
      if (!this.expired) {
        return false;
      }
      if (this.dataSource.creator !== null && this.dataSource.creator.uid !== this.loggedInUser.uid) {
        return false;
      }
      return this.showExpireAction;
    },
    isIncomplete() {
      switch (this.dataSource.authenticationType) {
        case dataSourceAuthenticationType.oauth:
        case dataSourceAuthenticationType.oauthv2:
          return this.dataSource.accessToken === '';
        case dataSourceAuthenticationType.apiToken:
          return this.dataSource.apiToken === '' || this.dataSource.apiServer === '' || this.dataSource.apiUser === '';
        default:
          return false;
      }
    },
    disableManageActions() {
      if (this.dataSource.creator === null && this.dataSource.shared) {
        return false;
      }

      if (this.dataSource.creator.uid === this.loggedInUser.uid) {
        return false;
      }

      return !this.userHasRight([accessGroupFlag.accountWriteAccess]);
    },
  },
  methods: {
    edit() {
      this.showEdit = true;
    },
    refresh() {
      window.open(connectUrl(this.dataSource), '', 'width=700,height=700');

      const eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
      const eventer = window[eventMethod];
      const messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message';

      eventer(messageEvent, (event) => {
        if (event.data.message !== windowMessage.oauthInstall || event.data.status !== dataSourceInstallStatus.success) {
          return;
        }
        this.fetchDataSource(this.dataSource.uid).catch(() => {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
        });
      }, false);
    },
    close() {
      this.showEdit = false;
      this.$emit('close');
    },
    deleteItem() {
      const deleteMethod = () => this.deleteDataSource(this.dataSource).then(() => {
        this.$showSnackbar({ color: 'success', message: this.$t('success.deleted') });
      }).catch(logCatch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      }));

      this.$confirm({
        title: this.$t('dataSourceItem.deletePrompt', { title: this.goalSettings.featureNamePlural }),
        okText: this.$t('general.yesDelete'),
        okType: 'danger',
        maskClosable: true,
        cancelText: this.$t('general.cancel'),
        onOk() {
          deleteMethod();
        },
      });
    },
  },
};
</script>

<style lang="scss" type="text/scss">
  ._expire-warning {
    display: flex;
    align-items: center;

    div {
      margin-right: .4rem;
    }
  }

  .user-display {
     color: $font-color-secondary;
  }
</style>
