<template>
  <div class="update-schedule-details">
    <schedule-detail-page :schedule-id="scheduleId" />
  </div>
</template>

<script>
import ScheduleDetailPage from '@/components/update-schedules/ScheduleDetailPage.vue';
import useUpdateSchedules from '@/composables/update-schedules/update-schedules';
import useUpdateSchedulesPromptOnUnsaved from '@/composables/update-schedules/prompt-on-unsaved';
import { copy } from 'shared/lib/copy';
import { findInArray } from 'shared/lib/array/array';

export default {
  name: 'UpdateDetails',
  components: { ScheduleDetailPage },
  data() {
    return { snapShot: null };
  },
  setup() {
    const { schedules, selectSingle } = useUpdateSchedules();
    const { saveOrDiscard } = useUpdateSchedulesPromptOnUnsaved();
    return { schedules, saveOrDiscard, selectSingle };
  },
  computed: {
    scheduleId() {
      return parseInt(this.$route.params.notificationId, 10);
    },
  },
  beforeRouteLeave(to, from, next) {
    const schedule = this.selectSingle(this.scheduleId);
    if (schedule === undefined) {
      next();
      return;
    }
    this.saveOrDiscard(next, schedule, this.snapShot);
  },
  watch: {
    scheduleId() {
      this.snapShot = copy(findInArray({ haystack: this.schedules, needle: this.scheduleId }));
    },
  },
  created() {
    this.snapShot = copy(findInArray({ haystack: this.schedules, needle: this.scheduleId }));
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .update-schedule-details {
    flex: 1 1 auto;
  }
</style>
