import { initIdList } from '@/lib/sort';

export default function useManualSort(edge, update) {
  const valid = (ids, toCheck) => {
    for (let i = 0; i < toCheck.length; i++) {
      if (!ids.includes(toCheck[i])) {
        return false;
      }
    }

    return true;
  };

  const orderFn = (listAfter, anchor, toMove) => {
    if (listAfter) {
      return [anchor, ...toMove];
    }

    return [...toMove, anchor];
  };

  const idList = (currentValue, operation) => {
    if (!valid(operation.list, [operation.anchor, ...operation.toMove])) {
      throw new Error(`anchor ${operation.anchor} or toMove ${operation.toMove} not in ids ${operation.list}`);
    }
    const res = [];
    const initialVal = initIdList(currentValue.map(({ uid }) => (uid)), operation.list);
    for (let i = 0; i < initialVal.length; i++) {
      if (operation.anchor === initialVal[i]) {
        res.push(...orderFn(operation.listAfter, operation.anchor, operation.toMove));
        continue;
      }
      if (operation.toMove.includes(initialVal[i])) {
        continue;
      }
      res.push(initialVal[i]);
    }
    return res.map((uid) => ({ uid }));
  };

  const listAfter = (entity) => (list, anchor, toMove) => {
    const sortOperation = { list, anchor, toMove, listAfter: true };
    const res = idList(entity[edge], sortOperation);
    return update({
      entity: { ...entity, [edge]: res },
      sortOperation,
    });
  };

  const listBefore = (entity) => (list, anchor, toMove) => {
    const sortOperation = { list, anchor, toMove, listAfter: false };
    const res = idList(entity[edge], sortOperation);
    return update({
      entity: { ...entity, [edge]: res },
      sortOperation,
    });
  };

  return {
    listAfter,
    listBefore,
  };
}
