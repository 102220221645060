import useLocalOverwrite from '@/composables/saved-views/local-overwrite';
import { UNASSIGNED } from '@/lib/constants';
import { savedView as savedViewConfig } from 'shared/api/query/configs.json';

const SAVED_VIEW_LOCAL_OVERWRITE_CYCLES = 'saved_view_local_overwrite_cycles';
const SAVED_VIEW_LOCAL_OVERWRITE_UNASSIGNED = 'saved_view_local_overwrite_unassigned';

export default function useLocalOverwriteCycles(localStorage, loggedInUserAccount) {
  const localStorageKeyCycles = `${loggedInUserAccount.value.uid}_${SAVED_VIEW_LOCAL_OVERWRITE_CYCLES}`;
  const localStorageKeyUnassigned = `${loggedInUserAccount.value.uid}_${SAVED_VIEW_LOCAL_OVERWRITE_UNASSIGNED}`;

  const localOverwritesCycle = useLocalOverwrite(savedViewConfig.edges.goalCycles, localStorageKeyCycles, localStorage);
  const localOverwritesUnassigned = useLocalOverwrite(savedViewConfig.edges.unassignedGoalCycleSelected, localStorageKeyUnassigned, localStorage);

  const hasLocalOverwrite = (originalView) => hasLocalOverwriteCycles(originalView) || hasLocalOverwriteUnassigned(originalView);

  const hasLocalOverwriteCycles = (originalView) => {
    const localCycles = localOverwritesCycle.findLocalOverwrite(originalView);
    if (localCycles === undefined) {
      return false;
    }
    if (!Array.isArray(localCycles)) {
      return false;
    }

    if (!Array.isArray(originalView.goalCycles)) {
      return localCycles.length !== 0;
    }

    const a = localCycles.map((c) => c.uid).sort().join('');
    const b = originalView.goalCycles.map((c) => c.uid).sort().join('');

    return a !== b;
  };

  const hasLocalOverwriteUnassigned = (originalView) => {
    const localUnassignedGoalCycleSelected = localOverwritesUnassigned.findLocalOverwrite(originalView);
    if (localUnassignedGoalCycleSelected === undefined) {
      return false;
    }

    return originalView.unassignedGoalCycleSelected !== localUnassignedGoalCycleSelected;
  };

  const saveOverwrite = (view) => {
    localOverwritesCycle.saveOverwrite({ ...view, goalCycles: view.goalCycles.filter((item) => item.uid !== UNASSIGNED) });
    const unassignedCycle = view.goalCycles.find((c) => c.uid === UNASSIGNED);
    localOverwritesUnassigned.saveOverwrite({ ...view, unassignedGoalCycleSelected: unassignedCycle !== undefined });
  };

  const removeLocalOverwrite = (view) => {
    localOverwritesCycle.removeLocalOverwrite(view);
    localOverwritesUnassigned.removeLocalOverwrite(view);
  };

  const initOverwrites = () => {
    localOverwritesCycle.initOverwrites();
    localOverwritesUnassigned.initOverwrites();
  };

  const mergeWithLocalOverwrites = (view) => localOverwritesUnassigned.mergeWithLocalOverwrites(localOverwritesCycle.mergeWithLocalOverwrites(view));

  return {
    saveOverwrite,
    removeLocalOverwrite,
    mergeWithLocalOverwrites,
    initOverwrites,

    hasLocalOverwrite,
  };
}
