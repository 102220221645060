<template>
  <div
    class="collection-component"
  >
    <div
      v-if="collection.hidden && !disabled"
      class="_hidden"
    >
      <m-btn
        hide-border
        small
        light
        @click="toggleHide"
      >
        {{ $t('collection.show', { title: collection.title }) }}
      </m-btn>
    </div>
    <template v-else>
      <div
        class="_header"
        @mouseover="hover = true"
        @mouseleave="hover = false"
      >
        <div :class="['_title']">
          <div
            v-if="(hover || showActions) && !disabled"
            class="_handle"
          >
            <m-dropdown
              v-model:value="showActions"
              :title="$t('general.actions')"
            >
              <m-btn
                icon="drag"
                hide-border
                small
                light
                fab
                :style="{ width: '2rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }"
                @click="showActions = true"
              />
              <template #overlay>
                <m-card
                  list
                  no-padding
                >
                  <m-card-item
                    icon="eye-invisible"
                    @click="toggleHide"
                  >
                    {{ $t('general.hide') }}
                  </m-card-item>
                  <m-card-item
                    icon="delete"
                    @click="deleteM"
                  >
                    {{ $t('general.delete') }}
                  </m-card-item>
                </m-card>
              </template>
            </m-dropdown>
          </div>
          <input
            ref="input"
            v-model="localTitle"
            :disabled="disabled"
            @keydown="focusFirstElement"
          >
        </div>
      </div>
      <div
        class="_items"
      >
        <m-draggable
          draggable-item-class="_handle"
          ghost-item-class="collection-item-inner"
          dragover-item-class="collection-item"
          can-drag-over-top
          can-drag-over-bottom
          :recreate-key="collection.items.length"
          :disabled="disabled"
          @set-drag-item="setDragItem"
          @over-top="setOverTop"
          @over-bottom="setOverBottom"
          @drag-drop="handleDrop"
          @cancel="cancelDragging"
        >
          <collection-item
            v-for="(item, index) in collection.items"
            :key="item.uid"
            :item="item"
            :parent-breadcrumbs="parentBreadcrumbs"
            :focused="index === focusedItem"
            :dragging="drag"
            :collection-id="collection.uid"
            :disabled="disabled"
            :class="itemClass"
            :selected="selectedItems.includes(item.uid)"
            :dragging-over-bottom="dragItemId !== '' && draggingOverBottom.includes(item.uid)"
            :dragging-over-top="index === 0 && dragItemId !== '' && draggingOverTop.includes(item.uid)"
            @selection-right-click="handleSelectionRightClick"
            @select-row="selectRow"
            @down="moveTo(index + 1)"
            @up="moveTo(index - 1)"
            @enter="createItem(index + 1)"
            @deleted="focusedItem = index - 1"
            @focus-below="focusBelow"
          />
        </m-draggable>
        <collection-items-context-menu
          ref="contextMenu"
          :collection-items="selectedItems"
          :collection-id="collection.uid"
        />
      </div>
      <m-btn
        v-if="collection.items.length === 0 || $store.state.breakpoint.smAndDown"
        icon="plus"
        hide-border
        block
        small
        :style="{ justifyContent: 'flex-start' }"
        @click="createItem(collection.items.length)"
      >
        {{ $t('general.new') }}
      </m-btn>
    </template>
  </div>
</template>

<script>
import CollectionItem from '@/components/CollectionItem.vue';
import CollectionItemsContextMenu from '@/components/CollectionItemsContextMenu.vue';
import deleteMixin from '@/mixins/delete';
import useDebounce from '@/composables/debounce';
import useSnackBar from '@/composables/snackbar';
import useSort from '@/composables/draggable/sort';
import { children as collectionChildren } from '@/api/query/collection';
import {
  collection as collectionConfig,
} from 'shared/api/query/configs.json';
import { collectionItemType } from 'shared/constants.json';
import { dogma } from '@/api';
import { logCatch } from '@/lib/logger/logger';
import { mapActions } from 'vuex';

export default {
  name: 'Collection',
  props: {
    collection: {
      type: Object,
      required: true,
    },
    selectedItems: {
      type: Array,
      default: () => [],
    },
    parentBreadcrumbs: {
      type: Array,
      default: () => [],
    },
    itemClass: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['select', 'focus-before', 'focus-below', 'title-updated'],
  components: { CollectionItem, CollectionItemsContextMenu },
  setup() {
    const { debounce } = useDebounce();
    const snackbar = useSnackBar();
    const {
      setDragItem,
      setOverBottom,
      setOverTop,
      draggingOverBottom,
      draggingOverTop,
      dropItem,
      cancelDragging,
      dragItemId,
    } = useSort();
    return {
      snackbar,
      debounce,
      dragItemId,
      setDragItem,
      setOverBottom,
      setOverTop,
      draggingOverBottom,
      draggingOverTop,
      dropItem,
      cancelDragging,
    };
  },
  data() {
    return {
      loading: false,
      localTitle: null,
      focusedItem: -1,
      drag: false,
      hover: false,
      showActions: false,
      collectionItemRefs: {},
    };
  },
  computed: {
    items: {
      get() {
        return this.collection.items;
      },
      set(items) {
        this.updateOrder(items);
      },
    },
  },
  methods: {
    ...mapActions(['getEntityV2', 'updateEntityV2']),
    handleSelectionRightClick(event) {
      this.$refs.contextMenu.show(event);
    },
    selectRow({ event, item }) {
      this.$emit('select', { items: [item.uid] });
      this.handleSelectionRightClick(event);
    },
    getItems(index) {
      const items = this.collection.items.map((i) => ({ uid: i.uid, type: i.type }));
      items.splice(index, 0, {
        type: collectionItemType.checkListItem,
        checked: false,
        content: {
          title: '',
          icon: '',
          content: null,
        },
      });
      return items.map((item, j) => ({
        ...item,
        'items|order': j,
      }));
    },
    createItem(index) {
      this.updateEntityV2(
        {
          entity: {
            ...this.collection,
            items: this.getItems(index),
          },
          model: collectionConfig.model,
          attributes: collectionChildren,
          options: { index },
          mutation: 'COLLECTION_ITEM_CREATED',
        },
      ).then((response) => {
        if (response.status !== 200) {
          throw new Error('failed to create collection items');
        }

        this.focusedItem = index;
      }).catch(logCatch(() => {
        this.snackbar.error();
      }));
    },
    focusFirstElement(event) {
      if (event.key === 'ArrowUp') {
        this.$emit('focus-before');
        return;
      }

      if (event.key !== 'ArrowDown') {
        return;
      }

      this.collectionItemRefs[0].$el.focus();
      this.focusedItem = 0;
    },
    focusBelow() {
      this.$emit('focus-below');
    },
    focus() {
      this.$refs.input.focus();
    },
    moveTo(index) {
      if (index < 0) {
        this.$refs.input.focus();
        return;
      }

      if (index > this.collection.items.length - 1) {
        this.focusBelow();
        return;
      }

      this.focusedItem = index;
    },
    handleDrop() {
      this.updateOrder(this.dropItem(this.collection.items));
    },
    updateOrder(items) {
      const entity = {
        ...this.collection,
        items: items.map((i, index) => ({
          ...i,
          'items|order': index,
        })),
      };

      this.$store.commit('COLLECTION_UPDATED', { entity });
      this.save(entity);
    },
    save(entity) {
      dogma.updateSingle(
        entity,
        collectionConfig.model,
        collectionChildren,
      ).then((response) => {
        if (response.status !== 200) {
          throw new Error('failed to update collection');
        }
      }).catch(logCatch(() => {
        this.snackbar.error();
      }));
    },
    updateTitle(val) {
      this.$emit('title-updated', val);
      this.save({
        ...this.collection,
        title: val,
      });
    },
    toggleHide() {
      const entity = {
        ...this.collection,
        hidden: !this.collection.hidden,
      };
      this.$store.commit('COLLECTION_UPDATED', { entity });
      this.save(entity);
      this.showActions = false;
    },
    deleteM() {
      this.showDeleteModal({
        entities: [this.collection],
        model: collectionConfig.model,
        mutation: 'COLLECTIONS_DELETED',
      })();
    },
  },
  watch: {
    focused(val) {
      if (!val) {
        return;
      }

      this.$refs.input.focus();
    },
    localTitle(newVal, oldVal) {
      if (oldVal === null || newVal === oldVal) {
        return;
      }

      const update = () => {
        this.updateTitle(newVal);
      };
      this.debounce(update, 500);
    },
  },
  mounted() {
    this.localTitle = this.collection.title;
  },
  mixins: [deleteMixin],
};
</script>

<style scoped lang="scss" type="text/scss">
  .collection-component {
    margin-bottom: 1rem;
    white-space: normal;

    ._header {
      padding: 0 10rem;
      margin: 0 -10rem;

      ._title {
        position: relative;

        ._handle {
          position: absolute;
          top: 1rem;
          left: -2.4rem;
        }

        input {
          width: 100%;
          font-size: $font-size-9;
          font-weight: $font-weight-semibold;
          background-color: transparent;
          border: none;
          outline: none;
        }
      }
    }
  }
</style>
