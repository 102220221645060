import { RESULT, UID, VAR } from 'shared/api/query/constants';
import { customDatasourceProperty, dataSourceType } from 'shared/constants.json';
import {
  dataSource,
  user as userConfig,
} from 'shared/api/query/configs.json';
import { reverseEdge, userEdgesSlim } from 'shared/api/query/edges';

const children = [
  { attr: UID },
  { attr: dataSource.edges.name, default: '' },
  { attr: dataSource.edges.accessToken, default: '' },
  { attr: dataSource.edges.type },
  { attr: dataSource.edges.shared },
  { attr: dataSource.edges.creator, model: userConfig.model, children: userEdgesSlim, default: null },
  { attr: dataSource.edges.apiServer, default: '' },
  { attr: dataSource.edges.apiToken, default: '' },
  { attr: dataSource.edges.refreshTokenCreatedAt, default: null },
  { attr: dataSource.edges.apiUser, default: '' },
  { attr: dataSource.edges.authenticationType, default: '' },
  { attr: dataSource.edges.oauthServer, default: '' },

  // ms calendar
  { attr: customDatasourceProperty.msCalendarEvents, default: [] },
];

export const msCalendarDataSourceQuery = ({ user, pagination = null, filter = null }) => [
  {
    alias: VAR,
    uid: [user.uid],
    func: { name: 'uid' },
    model: user.model,
    children: [{
      attr: reverseEdge(dataSource.edges.creator),
      model: dataSource.model,
      filter: { func: { name: 'eq', attr: 'type', args: [{ value: dataSourceType.msCalendar }] } },
      children: [{ attr: UID, var: 'via_user_ms_calendar_data_source' }],
    }],
  },
  {
    alias: RESULT,
    needsVar: [{ name: 'via_user_ms_calendar_data_source', typ: 1 }],
    func: { name: 'uid', needsVar: [{ name: 'via_user_ms_calendar_data_source', typ: 1 }] },
    model: dataSource.model,
    children: children.map((c) => {
      if (c.attr !== 'msCalendarEvents') {
        return c;
      }
      return { ...c, filter, args: pagination };
    }),
  },
];
