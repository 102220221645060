<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.5 17.4495V1.76028C5.5 1.08184 6.32346 0.744435 6.8006 1.22737L18.2795 12.8459C18.7532 13.3254 18.4131 14.1376 17.7387 14.1376H14.5896C14.0548 14.1376 13.6874 14.6746 13.8818 15.1722L16.2513 21.2359C16.3995 21.6152 16.2216 22.0437 15.8481 22.2069L14.1789 22.9362C13.7924 23.105 13.3422 22.9272 13.176 22.5399L10.4602 16.2115C10.2599 15.7448 9.66603 15.6024 9.27545 15.9275L6.74616 18.0324C6.2513 18.4442 5.5 18.0928 5.5 17.4495Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default { name: 'Mouse' };
</script>

<style scoped lang="scss" type="text/scss">

</style>
