<template>
  <div class="user-provisioning-attribute-dialog">
    <m-content padding>
      <div class="_description">
        {{ $t('userProvisioning.attributeMappingDescription') }}
      </div>
      <div class="_form">
        <div class="_attribute-select">
          <div class="_label">
            {{ $t('userProvisioning.attribute') }}
          </div>
          <m-select
            v-model:value="localUserProvisioningMapping.scimAttribute"
            :items="attributeItems"
            value-key="attribute"
            item-text="label"
            match-trigger-width
            show-search
            full-width
          />
        </div>
        <div
          v-if="isMultiple(localUserProvisioningMapping.scimAttribute)"
          class="_type-select"
        >
          <div class="_label">
            {{ $t('userProvisioning.scimAttributeType') }}
          </div>
          <m-select
            v-model:value="localUserProvisioningMapping.scimAttributeType"
            :items="types"
            match-trigger-width
            full-width
          />
        </div>

        <div
          v-if="isGroup(localUserProvisioningMapping.scimAttribute)"
          class="_type-select"
        >
          <div class="_label">
            {{ $t('userProvisioning.scimAttributeValues') }}
          </div>
          <m-select
            v-model:value="localUserProvisioningMapping.provisionedGroups"
            :items="userProvisioning.provisionedGroups"
            value-key="uid"
            item-text="displayName"
            multiple
            show-search
            return-object
            hide-arrow
            automatic-color
            match-trigger-width
            tags
            keep-open-on-click
            full-width
          />
        </div>

        <div class="_property-select">
          <div class="_label">
            {{ $t('userProvisioning.property') }}
          </div>
          <m-select
            v-model:value="localUserProvisioningMapping.property"
            :items="propertyItems"
            value-key="uid"
            item-text="label"
            match-trigger-width
            return-object
            show-search
            full-width
          />
        </div>

        <div v-if="localUserProvisioningMapping.property !== null && isGroup(localUserProvisioningMapping.scimAttribute)">
          <template v-if="properties.find(p => p.uid === localUserProvisioningMapping.property.uid).type === propertyType.space">
            <div class="_label">
              {{ $t('userProvisioning.space') }}
            </div>
            <space-selector
              :value="localUserProvisioningMapping.space === null ? [] : [localUserProvisioningMapping.space]"
              class="_select"
              full-width
              wrap
              :truncate="5"
              :property-label="localUserProvisioningMapping.property.label"
              @input="selectSpace"
            />
          </template>

          <template v-if="[propertyType.singleSelect, propertyType.options].includes(properties.find(p => p.uid === localUserProvisioningMapping.property.uid).type)">
            <div class="_label">
              {{ $t('userProvisioning.option') }}
            </div>
            <m-select
              v-model:value="localUserProvisioningMapping.propertyOption"
              :items="propertyOptions"
              value-key="uid"
              item-text="title"
              match-trigger-width
              return-object
              full-width
            />
          </template>
        </div>
      </div>
    </m-content>
    <div class="actions">
      <m-dialog-actions
        :submit-disabled="selectedProperty === null || selectedAttribute === null"
        :ok-text="userProvisioningMapping === null ? $t('general.create') : $t('general.save')"
        @ok="emitSelected"
        @cancel="$emit('cancel')"
      />
    </div>
  </div>
</template>

<script>
import SpaceSelector from '@/components/spaces/SpaceSelector.vue';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useProperties from '@/composables/property/property';
import useUserProvisioning from '@/composables/user-provisioning/user-provisioning';
import { camelCase, snakeCase } from 'lodash-es';
import { copy } from 'shared/lib/copy';
import { propertyType, userProvisioningAttribute, userProvisioningAttributeType } from 'shared/constants.json';
import { textByLang } from 'shared/lib/language';

export default {
  name: 'UserProvisioningAttributeDialog',
  props: {
    userProvisioningMapping: {
      type: Object,
      default: null,
    },
  },
  components: { SpaceSelector },
  setup() {
    const { isMultiple, isGroup, userProvisioning } = useUserProvisioning();
    const { properties } = useProperties();
    const { userLang } = useLoggedInUser();

    return { userLang, isMultiple, isGroup, properties, userProvisioning, propertyType };
  },
  emits: ['selected', 'cancel'],
  data() {
    return {
      snakeCase,

      localUserProvisioningMapping: {
        scimAttribute: null,
        scimAttributeType: null,
        property: null,
        provisionedGroups: [],
        space: null,
      },
    };
  },
  computed: {
    propertyOptions() {
      return this.properties.find((p) => p.uid === this.localUserProvisioningMapping.property.uid).options.map((o) => ({ ...o, title: textByLang(o.label, this.userLang) }));
    },
    usedAttributes() {
      return this.userProvisioning.mappings.map((m) => m.scimAttribute).filter((upa) => {
        if (this.userProvisioningMapping !== null) {
          return this.userProvisioningMapping.scimAttribute !== upa;
        }

        return true;
      });
    },

    propertyItems() {
      let properties = this.properties.filter((p) => p.usedFor.includes(propertyType.user));

      const translator = (p) => ({ uid: p.uid, label: textByLang(p.label, this.userLang) });

      if (this.isGroup(this.localUserProvisioningMapping.scimAttribute)) {
        const nonGroupUsage = this.userProvisioning.mappings.filter((m) => !this.isGroup(m.scimAttribute)).map((m) => m.property).map((p) => p.uid);

        properties = properties.filter((p) => [propertyType.options, propertyType.singleSelect, propertyType.space].includes(p.type));
        properties = properties.filter((p) => !nonGroupUsage.includes(p.uid));
        return properties.map(translator);
      }

      const usedProperties = this.userProvisioning.mappings.filter((m) => m.uid !== this.localUserProvisioningMapping.uid).map((m) => m.property).map((p) => p.uid);

      properties = properties.filter((p) => usedProperties.includes(p.uid));
      return properties.map((p) => ({ uid: p.uid, label: textByLang(p.label, this.userLang) }));
    },
    attributeItems() {
      return userProvisioningAttribute.all.filter((upa) => upa === userProvisioningAttribute.groups || !this.usedAttributes.includes(upa)).map((a) => ({
        attribute: a,
        label: camelCase(a),
      }));
    },
    types() {
      return userProvisioningAttributeType.all;
    },
  },
  methods: {
    emitSelected() {
      this.$emit('selected', copy(this.localUserProvisioningMapping));
    },
    selectSpace(spaces) {
      if (spaces.length === 0) {
        this.localUserProvisioningMapping.space = null;
        return;
      }
      this.localUserProvisioningMapping.space = spaces[0];
    },
  },
  mounted() {
    if (this.userProvisioningMapping !== null) {
      this.localUserProvisioningMapping = {
        scimAttribute: copy(this.userProvisioningMapping.scimAttribute),
        scimAttributeType: copy(this.userProvisioningMapping.scimAttributeType),
        provisionedGroups: copy(this.userProvisioningMapping.provisionedGroups),

        property: copy(this.userProvisioningMapping.property),
        space: copy(this.userProvisioningMapping.space),
        propertyOption: copy(this.userProvisioningMapping.propertyOption),
      };
    }
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .user-provisioning-attribute-dialog {
    ._description {
      margin-bottom: 2rem;
      color: $font-color-secondary;
    }

    ._label {
      margin-top: .8rem;
      margin-bottom: .4rem;
      color: $font-color-secondary;
    }
  }
</style>
