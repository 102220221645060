<template>
  <m-tooltip :class="['reaction-item']">
    <div
      :class="['_inner', selected ? '-selected' : '', readOnly ? '' : '-clickable']"
      @click="click"
    >
      <m-emoji
        class="_emoji"
        :size="13"
        :emoji="emoji"
      />
      <div class="_amount">
        {{ filteredUsers.length }}
      </div>
    </div>
    <template #title>
      <div
        class="_title"
      >
        {{ title }}
      </div>
    </template>
  </m-tooltip>
</template>

<script>
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useUsers from '@/composables/user/users';
import { guid } from 'shared/lib/uuid';

export default {
  name: 'ReactionItem',
  props: {
    emoji: {
      type: String,
      required: true,
    },
    users: {
      type: Array,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { deletedUser } = useUsers();
    const { loggedInUser } = useLoggedInUser();
    return { deletedUser, loggedInUser };
  },
  emits: ['add-or-remove'],
  computed: {
    filteredUsers() {
      return this.users.map((u) => {
        if (u === null) {
          return {
            ...this.deletedUser,
            uid: guid(),
          };
        }

        return u;
      });
    },
    selected() {
      return this.filteredUsers.map((u) => u.uid).includes(this.loggedInUser.uid);
    },
    names() {
      const names = this.filteredUsers.map((u) => {
        if (u.uid === this.loggedInUser.uid) {
          return this.$t('reactionItem.you');
        }
        return `${u.firstName} ${u.lastName}`;
      });
      if (names.length === 1) {
        return names[0];
      }

      if (names.length === 2) {
        return names.join(` ${this.$t('general.and')} `);
      }

      const first = names.slice(0, -2).join(', ');
      const last = names.slice(-2).join(` ${this.$t('general.and')} `);
      return `${first}, ${last}`;
    },
    have() {
      if (this.filteredUsers.length === 1 && this.filteredUsers[0].uid === this.loggedInUser.uid) {
        return this.$t('reactionItem.have2ndFormSingular');
      }

      return this.$t('reactionItem.have', this.filteredUsers.length);
    },
    title() {
      return this.$t('reactionItem.title', { names: this.names, have: this.have, emoji: this.emoji });
    },
  },
  methods: {
    click() {
      this.$emit('add-or-remove', this.emoji);
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  ._inner {
    display: flex;
    align-items: center;
    height: 2.6rem;
    padding: 0 .6rem;
    line-height: 1;
    background-color: map_get($grey, 'lighten-4');
    border: 1px solid transparent;
    border-radius: 1.3rem;

    &.-clickable {
      cursor: pointer;
    }

    &.-selected {
      color: $primary-color;
      background-color: map_get($blue, 'lighten-4');
      border: 1px solid $primary-color;
    }

    ._emoji {
      margin-right: .4rem;
    }

    ._amount {
      font-size: $font-size-3;
    }
  }
</style>
