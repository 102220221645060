import { BaseHandler } from '@/lib/filter/base-translator/handler';
import { meeting } from 'shared/api/query/configs.json';
import { reverseEdge } from 'shared/api/query/edges';

const childrenFn = (varName) => ([
  {
    attr: reverseEdge(meeting.edges.propertyValues),
    model: meeting.model,
    var: varName,
  },
]);

export class MeetingFilterHandler {
  constructor() {
    this.baseHandler = new BaseHandler({
      model: meeting.model,
      childrenFn,
      isFilterMode: true,
    });
  }

  Translate(tree) {
    return this.baseHandler.Translate(tree);
  }
}
