<template>
  <div class="goal-breadcrumb-item">
    <div
      v-if="item.parents.length > 0"
      class="_divider"
    >
      /
    </div>
    <router-link :to="goalLink">
      <m-tag
        :title="item.title"
        class="_tag"
        clickable
        :icon="buildIconFromEntity(item)"
      />
    </router-link>
  </div>
</template>

<script>
import { buildIconFromEntity } from 'shared/lib/icon';
import { routeName } from 'shared/constants.json';

export default {
  name: 'GoalBreadcrumbItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    goalLink() {
      return {
        name: routeName.goalDetails,
        params: { goalId: this.item.uid },
      };
    },
  },
  methods: { buildIconFromEntity },
};
</script>

<style scoped lang="scss" type="text/scss">
  .goal-breadcrumb-item {
    display: flex;
    align-items: center;
    min-height: 3.2rem;

    ._divider {
      margin: 0 .8rem;
    }

    ._tag {
      max-width: 20rem;
    }
  }
</style>
