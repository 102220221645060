export const goalTypeOption = (goal, goalTypeProperty) => {
  if (goal === null || goal.properties === undefined) {
    return null;
  }
  const goalTypes = goal.properties.find((p) => p.property.isGoalType || p.property.uid === goalTypeProperty.uid);
  if (goalTypes === undefined || goalTypes.selectedOptions.length === 0) {
    return null;
  }

  for (let i = 0; i < goalTypeProperty.options.length; i++) {
    if (goalTypeProperty.options[i].uid === goalTypes.selectedOptions[0].uid) {
      return goalTypeProperty.options[i];
    }
  }

  return null;
};

export const getAllowedParentItemGoalTypes = (type, goalTypeProperty) => goalTypeProperty.options.filter((o) => {
  if (type === null) {
    return true;
  }

  if (type.canBeChildOf.length === 0) {
    return false;
  }

  return type.canBeChildOf.map((c) => c.uid).includes(o.uid);
}).map((o) => ({ ...o, property: goalTypeProperty }));

export const getAllowedSubItemGoalTypes = (type, goalTypeProperty) => goalTypeProperty.options.filter((o) => {
  if (type === null) {
    return true;
  }

  if (o.canBeChildOf.length === 0) {
    return false;
  }

  return o.canBeChildOf.map((c) => c.uid).includes(type.uid);
}).map((o) => ({ ...o, property: goalTypeProperty }));
