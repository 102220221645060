import { GOAL_PLANNING, GOAL_RELATION } from '@/lib/props/custom-types';
import { directPropDateFn, makeDateFn } from '@/lib/filter/base-translator/date';
import { directPropGoalFn } from '@/lib/filter/base-translator/relations';
import { directPropNumberFn, makeNumberFn } from '@/lib/filter/base-translator/number';
import { directPropOptionFn, makeOptionsFn } from '@/lib/filter/base-translator/options';
import { directPropPlanningFn } from '@/lib/filter/base-translator/plannings';
import { directPropSpaceFn, makeSpaceFn } from '@/lib/filter/base-translator/space';
import { directPropTextFn, makeTextFn } from '@/lib/filter/base-translator/text';
import { directPropUserFn, makeUserFn } from '@/lib/filter/base-translator/user';
import { indirectChildrenFn } from '@/lib/filter/base-translator/indirect';
import { indirectVarId, varId } from '@/lib/filter/base-translator/varid';
import { lookupFn } from '@/lib/filter/base-translator/lookup';
import { propertyType, userScopeType } from 'shared/constants.json';
import { removeEmptyTrees } from '@/lib/filter/filter';
import { reverseEdge } from 'shared/api/query/edges';
import { reverseFn } from '@/lib/filter/base-translator/reverse';

// returns a BaseHandler that generates gql.FilterTrees from xfmr.UserScopeTrees
// It has two modes:
// In Filtermode, filters are perceived as narrowing down a full set. Therefore,
// empty options are perceived as no filter.
// The default mode is the SelectionMode (isFiltermode = false). Here, the basis is an empty
// set which gets filled with every additional filter.
export class BaseHandler {
  constructor({ model, childrenFn, staticUserFn, varIdFn, isFilterMode }) {
    let varFn = varIdFn;
    if (varIdFn === undefined) {
      varFn = varId(model);
    }
    this.staticUserFn = staticUserFn;
    this.varFn = varFn;
    this.isFilterMode = isFilterMode;

    this.userFn = makeUserFn(childrenFn, varFn, isFilterMode);
    this.dateFn = makeDateFn(childrenFn, varFn);
    this.spaceFn = makeSpaceFn(childrenFn, varFn, isFilterMode);
    this.optionsFn = makeOptionsFn(childrenFn, varFn, isFilterMode);
    this.numberFn = makeNumberFn(childrenFn, varFn);
    this.textFn = makeTextFn(childrenFn, varFn);
    this.lookupFn = lookupFn(childrenFn, varFn, isFilterMode);

    this.directUserFn = directPropUserFn(model, varFn, isFilterMode);
    this.directDateFn = directPropDateFn;
    this.directSpaceFn = directPropSpaceFn(model, varFn, isFilterMode);
    this.directOptionsFn = directPropOptionFn(isFilterMode);
    this.directNumberFn = directPropNumberFn;
    this.directTextFn = directPropTextFn;

    this.directPlanningsFn = directPropPlanningFn(isFilterMode);
    this.directGoalFn = directPropGoalFn(model, varFn, isFilterMode);

    this.indirectChildrenFn = indirectChildrenFn(model, varFn);
    this.reverseFn = reverseFn(model, varFn);
  }

  Translate(tree) {
    if (tree === null) {
      return {
        filterTree: null,
        varBlocks: [],
      };
    }

    const { filterTrees, varBlocks } = this.translateTree(tree, '0');
    return { filterTree: removeEmptyTrees(filterTrees[0]), varBlocks };
  }

  translateTree(tree, index) {
    if ((tree.children === undefined || tree.children === null || tree.children.length === 0)
      && (tree.scope === undefined || tree.scope === null)
    ) {
      return { filterTrees: [null], varBlocks: [] };
    }
    if (tree.children === undefined
      || tree.children === null
      || tree.children.length === 0
    ) {
      return this.translateScope(tree.scope, index);
    }

    const { children, varBlocks } = tree.children
      .map((item, i) => this.translateTree(item, `${index}_${i}`))
      .reduce((res, item) => ({
        children: [...res.children, ...item.filterTrees],
        varBlocks: [...res.varBlocks, ...item.varBlocks],
      }), { children: [], varBlocks: [] });

    const filterTree = { op: tree.op, child: children };
    return { filterTrees: [filterTree], varBlocks };
  }

  translateScope(scope, index) {
    if (scope.type !== undefined) {
      switch (scope.type) {
        case userScopeType.staticUsers:
          return this.translateStaticUsersUserScope(scope, index);
        case userScopeType.indirectProperty:
          return this.translateIndirectPropertyUserScope(scope, index);
        case userScopeType.directProperty:
          return this.translateDirectPropertyUserScope(scope, index);
        case userScopeType.property:
          return this.translatePropertyUserScope(scope, index);
        case userScopeType.reverseProperty:
          return this.translateReversePropertyUserScope(scope, index);
        default:
          throw new Error('user scope type not supported');
      }
    }
    // I still have to handle scopes which are stored as string and we couldn't run the migration to add a user scope type.
    if (scope.staticUsers !== undefined && scope.staticUsers !== null) {
      return this.translateStaticUsersUserScope(scope, index);
    }

    if (scope.indirectProperty !== undefined && scope.indirectProperty !== null) {
      return this.translateIndirectPropertyUserScope(scope, index);
    }

    if (scope.directProperty !== undefined && scope.directProperty !== null) {
      return this.translateDirectPropertyUserScope(scope, index);
    }

    if (scope.reverseProperty !== undefined && scope.reverseProperty !== null) {
      return this.translateReversePropertyUserScope(scope, index);
    }

    return this.translatePropertyUserScope(scope, index);
  }

  translateStaticUsersUserScope(scope, index) {
    return this.staticUserFn(scope, index);
  }

  translateIndirectPropertyUserScope(scope, index) {
    const indirectTranslator = new BaseHandler({
      model: scope.indirectProperty.model,
      childrenFn: (varName) => ([
        {
          attr: reverseEdge(scope.indirectProperty.scope.edgeName),
          model: scope.indirectProperty.model,
          var: varName,
        },
      ]),
      varIdFn: indirectVarId(this.varFn, scope.indirectProperty.model),
      isFilterMode: this.isFilterMode,
    });
    const { filterTree, varBlocks } = indirectTranslator.Translate({ scope: scope.indirectProperty.scope });
    return this.indirectChildrenFn(filterTree, varBlocks)(scope, index);
  }

  translateDirectPropertyUserScope(scope, index) {
    switch (scope.directProperty.type) {
      case propertyType.text:
        return this.directTextFn(scope);
      case propertyType.number:
        return this.directNumberFn(scope);
      case propertyType.user:
        return this.directUserFn(scope, index);
      case propertyType.date:
        return this.directDateFn(scope);
      case propertyType.space:
        return this.directSpaceFn(scope, index);
      case propertyType.options:
      case propertyType.singleSelect:
        return this.directOptionsFn(scope);
      case GOAL_PLANNING:
        return this.directPlanningsFn(scope);
      case GOAL_RELATION:
        return this.directGoalFn(scope, index);
      default:
        return { filterTrees: [], varBlocks: [] };
    }
  }

  translatePropertyUserScope(scope, index) {
    switch (scope.property.type) {
      case propertyType.text:
        return this.textFn(scope, index);
      case propertyType.number:
        return this.numberFn(scope, index);
      case propertyType.user:
        return this.userFn(scope, index);
      case propertyType.date:
        return this.dateFn(scope, index);
      case propertyType.space:
        return this.spaceFn(scope, index);
      case propertyType.options:
      case propertyType.status:
      case propertyType.singleSelect:
        return this.optionsFn(scope, index);
      case propertyType.lookup:
        return this.lookupFn(scope, index);
      default:
        return { filterTrees: [], varBlocks: [] };
    }
  }

  translateReversePropertyUserScope(scope, index) {
    return this.reverseFn(scope, index);
  }
}
