import { UID } from 'shared/api/query/constants';
import { accessPolicyChildren } from '@/api/query/nebula/access-policy';
import {
  accessPolicy as accessPolicyConfig,
  property as propertyConfig,
  propertyOption as propertyOptionConfig,
  propertyValue as propertyValueConfig,
  storageObject as storageObjectConfig,
  updateTemplate as updateTemplateConfig,
  user as userConfig,
} from 'shared/api/query/configs.json';
import { accessPolicyType } from 'shared/constants.json';
import { propertyValueEdges } from '@/api/query/nebula/property-value';
import { storageObjectEdges } from '@/api/query/nebula/storage-object';

export const updateTemplateChildren = [
  { attr: UID },
  { attr: updateTemplateConfig.edges.title },
  { attr: updateTemplateConfig.edges.icon },
  {
    attr: updateTemplateConfig.edges.image,
    model: storageObjectConfig.model,
    children: storageObjectEdges,
    default: null,
  },
  { attr: updateTemplateConfig.edges.template, default: null },
  { attr: updateTemplateConfig.edges.accessRight, default: accessPolicyType.read },
  { attr: updateTemplateConfig.edges.prefillGoalActivities, default: false },
  { attr: updateTemplateConfig.edges.goalTypes, default: [], model: propertyOptionConfig.model, children: [{ attr: UID }] },
  { attr: updateTemplateConfig.edges.userProperties, default: [], model: propertyConfig.model, children: [{ attr: UID }] },
  {
    attr: updateTemplateConfig.edges.propertyValues,
    default: [],
    model: propertyValueConfig.model,
    children: propertyValueEdges,
  },
  {
    attr: updateTemplateConfig.edges.creator,
    model: userConfig.model,
    children: [{ attr: UID }],
    default: null,
  },
  {
    attr: updateTemplateConfig.edges.accessPolicy,
    model: accessPolicyConfig.model,
    default: null,
    children: accessPolicyChildren,
  },
];
