import { ERRORS } from '@/lib/constants';
import { accessPolicyType } from 'shared/constants.json';
import { depth, idFromIndex, removeLastId } from '@/lib/sort-operation';
import { realignParentByIndex } from '@/lib/goal/realign';

export default function useDragAndDrop(
  nodes,
  dragItemId,
  draggingOverTop,
  draggingOverBottom,
  draggingOverLeft,
  draggingOverRight,
  currentViewService,
  goalsSvc,
  goalDetailSorter,
  viewSorter,
  multiAlignGoals,
) {
  const resetDragging = () => {
    draggingOverBottom.value = [];
    draggingOverTop.value = [];
    draggingOverRight.value = [];
    draggingOverLeft.value = [];
    dragItemId.value = '';
  };

  const getNewParentIndex = () => {
    if (draggingOverTop.value.length > 0) {
      return `${removeLastId(draggingOverTop.value[0])}`;
    }
    if (draggingOverBottom.value.length > 0) {
      return `${draggingOverBottom.value[0]}`;
    }
    if (draggingOverLeft.value.length > 0) {
      return `${removeLastId(draggingOverLeft.value[0])}`;
    }
    if (draggingOverRight.value.length > 0) {
      return `${removeLastId(draggingOverRight.value[0])}`;
    }

    return '';
  };

  const handleDrop = () => {
    const d = depth(dragItemId.value);
    const goalId = idFromIndex(dragItemId.value, d);
    const goalIndex = dragItemId.value;
    const goal = goalsSvc.selectSingle(goalId);

    if (draggingOverTop.value.length > 0) {
      const dTarget = depth(draggingOverTop.value[0]);
      const targetId = idFromIndex(draggingOverTop.value[0], dTarget);
      const targetParentId = idFromIndex(draggingOverTop.value[0], dTarget - 1);
      const target = goalsSvc.selectSingle(targetId);
      const targetParent = goalsSvc.selectSingle(targetParentId);
      resetDragging();
      if (targetParentId === 0) {
        const newParents = realignParentByIndex(target, draggingOverTop.value[0], goal.uid, multiAlignGoals);
        return goalsSvc.changeParents({ goals: [target], parents: newParents });
      }
      const children = targetParent.children.map((c) => c.uid);
      const newParents = realignParentByIndex(goal, goalIndex, targetParentId, multiAlignGoals);
      const p1 = goalsSvc.changeParents({ goals: [goal], parents: newParents })
        .then(() => {
          goalDetailSorter.listBefore(targetParent)(
            [...children, goal.uid],
            target.uid,
            [goal.uid],
          );
        });

      // We have to pretend that the goal is already moved under the new goal.
      // Therefore, I replace the current parent with the new parent and adapt the
      // goal index
      const g = {
        ...target,
        parents: target.parents.map((p) => {
          if (p.uid === targetParentId) {
            return goal;
          }
          return p;
        }),
      };

      return p1.then(() => {
        const newTargetParents = realignParentByIndex(g, [goalIndex, target.uid].join('_'), goal.uid, multiAlignGoals);
        return goalsSvc.changeParents({ goals: [target], parents: newTargetParents });
      });
    }
    if (draggingOverBottom.value.length > 0) {
      const dTarget = depth(draggingOverBottom.value[0]);
      const targetId = idFromIndex(draggingOverBottom.value[0], dTarget);
      resetDragging();
      const newParents = realignParentByIndex(goal, goalIndex, targetId, multiAlignGoals);
      return goalsSvc.changeParents({ goals: [goal], parents: newParents });
    }
    if (draggingOverLeft.value.length > 0 || draggingOverRight.value.length > 0) {
      const action = draggingOverLeft.value.length > 0 ? 'listBefore' : 'listAfter';
      const value = [...draggingOverLeft.value, ...draggingOverRight.value];
      resetDragging();
      const dTarget = depth(value[0]);
      const targetId = idFromIndex(value[0], dTarget);
      const targetParentId = idFromIndex(value[0], dTarget - 1);
      if (targetParentId === 0) {
        const rootList = nodes.value.filter((g) => g.isRoot).map((g) => g.entity.uid);
        // if it already is a root element, we only need to sort
        if (!currentViewService.canEditCurrentView.value) {
          return new Promise((resolve) => { resolve({ info: { message: ERRORS.SORT.VIEW_NOT_EDITABLE, entity: currentViewService.currentView.value } }); });
        }
        if (d === 0) {
          return viewSorter[action](currentViewService.currentView.value)([...rootList, goal.uid], targetId, [goal.uid]);
        }
        const newParents = realignParentByIndex(goal, goalIndex, null, multiAlignGoals);
        return goalsSvc.changeParents({ goals: [goal], parents: newParents })
          .then(() => viewSorter[action](currentViewService.currentView.value)([...rootList, goal.uid], targetId, [goal.uid]));
      }
      const target = goalsSvc.selectSingle(targetId);
      const targetParent = goalsSvc.selectSingle(targetParentId);
      if (goal.parents.map((p) => p.uid).includes(targetParentId)) {
        if (![accessPolicyType.full, accessPolicyType.write].includes(targetParent.accessRight)) {
          return new Promise((resolve) => { resolve({ info: { message: ERRORS.SORT.PARENT_NOT_EDITABLE, entity: targetParent } }); });
        }
        return goalDetailSorter[action](targetParent)(targetParent.children.map((c) => c.uid), target.uid, [goal.uid]);
      }

      const newParents = realignParentByIndex(goal, goalIndex, targetParentId, multiAlignGoals);
      return goalsSvc.changeParents({ goals: [goal], parents: newParents })
        .then(() => {
          if (![accessPolicyType.full, accessPolicyType.write].includes(targetParent.accessRight)) {
            return new Promise((resolve) => { resolve({ info: { message: ERRORS.SORT.PARENT_NOT_EDITABLE, entity: targetParent } }); });
          }
          return goalDetailSorter[action](targetParent)(
            [...targetParent.children.map((c) => c.uid), goal.uid],
            target.uid,
            [goal.uid],
          );
        });
    }
    resetDragging();
    return new Promise((resolve) => {
      resolve({ cancelled: true });
    });
  };

  return { handleDrop, getNewParentIndex };
}
