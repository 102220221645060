<template>
  <div class="property-settings-form">
    <m-content
      padding
      class="_header"
    >
      <m-btn
        icon="left-arrow-long"
        light
        fab
        small
        hide-border
        @click="$emit('back')"
      />
      <h6 class="_title">
        {{ $t('propertySettingsForm.edit', {title: propertyTitle}) }}
      </h6>
    </m-content>
    <m-card-item
      no-hover
      :clickable="false"
      large
    >
      {{ $t('propertySettingsForm.visibility') }}
      <template #right>
        <m-select
          :items="visibilityOptions"
          :value="visibility"
          light
          small
          btn
          hide-border
          @input="updateVisibility"
        />
      </template>
    </m-card-item>
    <m-card-item
      v-if="property.type === 'parents'"
      no-hover
      :clickable="false"
      large
    >
      {{ $t('propertySettingsForm.canBeChildOf') }}
      <template #right>
        <m-select
          :items="localGoalTypes.map(t => ({ text: t.label.en, value: t.uid, color: t.color, icon: buildIconFromEntity(t) }))"
          :value="goalType.canBeChildOf.map(t => t.uid)"
          :truncate-selected-items="1"
          :placeholder="$t('general.none')"
          :max-tag-text-length="15"
          tags
          multiple
          keep-open-on-click
          light
          small
          hide-border
          @input="updateCanBeChildOf"
        />
      </template>
    </m-card-item>
    <m-card-item
      v-if="property.type === 'progressMeasurement'"
      no-hover
      :clickable="false"
      large
    >
      {{ $t('propertySettingsForm.progressMeasurement') }}
      <plan-tag
        :style="{ marginLeft: '.8rem' }"
        :feature-flag="featureFlag.advancedOkrRules"
      />
      <template #right>
        <m-select
          :items="progressMeasurementOptions"
          :value="goalType.allowedMeasurementTypes"
          :truncate-selected-items="1"
          :placeholder="$t('general.all')"
          :max-tag-text-length="15"
          :disabled="!hasAdvancedOKRRules"
          tags
          multiple
          keep-open-on-click
          light
          small
          hide-border
          @input="updateAllowedMeasurementTypes"
        />
      </template>
    </m-card-item>
    <m-card-item
      v-if="requiredPropertiesMap[property.type] !== undefined"
      no-hover
      :clickable="false"
      large
    >
      <m-switch
        :value="goalType[requiredPropertiesMap[property.type]]"
        :disabled="!hasAdvancedOKRRules || (goalType.canBeChildOf.length === 0 && property.type === 'parents')"
        small
        @input="updateRequired"
      >
        {{ $t('propertySettingsForm.required') }}
        <plan-tag
          :style="{ marginLeft: '.8rem' }"
          :feature-flag="featureFlag.advancedOkrRules"
        />
      </m-switch>
    </m-card-item>
    <m-card-item
      v-if="!property.isDirect && property.type !== propertyType.lookup"
      no-hover
      :clickable="false"
      large
    >
      <m-switch
        :value="requiredProperties.includes(property.uid)"
        :disabled="property.uid === goalTypeProperty.uid || !hasAdvancedOKRRules"
        small
        @input="updateRequiredProperties"
      >
        {{ $t('propertySettingsForm.required') }}
        <plan-tag
          v-if="property.uid !== goalTypeProperty.uid"
          :style="{ marginLeft: '.8rem' }"
          :feature-flag="featureFlag.advancedOkrRules"
        />
      </m-switch>
    </m-card-item>
  </div>
</template>

<script>
import PlanTag from '@/components/plans/PlanTag.vue';
import useAccess from '@/composables/access/access';
import useGoalTypeProperty from '@/composables/customize-page/goal-type-property';
import useProgressMeasurement from '@/composables/goal/progress-measurement';
import useVisibilityOptions from '@/composables/customize-page/visibility-options';
import { buildIconFromEntity } from 'shared/lib/icon';
import { featureFlag, propertyType } from 'shared/constants.json';
import { getVisibilityEdge } from '@/lib/property-settings';
import { requiredDirectPropertiesMap } from '@/lib/rules/rules';
import { textByLang } from 'shared/lib/language';
import { uniqBy } from 'lodash-es';

export default {
  name: 'PropertySettingsForm',
  props: {
    property: {
      type: Object,
      required: true,
    },
    userLang: {
      type: String,
      required: true,
    },
    goalType: {
      type: Object,
      required: true,
    },
    goalTypes: {
      type: Array,
      required: true,
    },
  },
  components: { PlanTag },
  setup(props) {
    const { options } = useVisibilityOptions(props.property);
    const { accountHasFeature, accountCanActivateFeature } = useAccess();
    const { goalTypeProperty } = useGoalTypeProperty();
    const { progressMeasurementOptions } = useProgressMeasurement();
    return {
      visibilityOptions: options,
      accountHasFeature,
      progressMeasurementOptions,
      goalTypeProperty,
      accountCanActivateFeature,
    };
  },
  data() {
    return {
      requiredPropertiesMap: requiredDirectPropertiesMap,
      featureFlag,
      propertyType,
    };
  },
  emits: ['back', 'update-visibility', 'update-goal-type'],
  computed: {
    localGoalTypes() {
      return this.goalTypes.map((t) => {
        if (t.uid === this.goalType.uid) {
          return this.goalType;
        }
        return t;
      });
    },
    hasAdvancedOKRRules() {
      return this.accountHasFeature([featureFlag.advancedOkrRules]);
    },
    requiredProperties() {
      return uniqBy([...this.goalType.requiredProperties, this.goalTypeProperty], 'uid').map((p) => p.uid);
    },
    propertyTitle() {
      return textByLang(this.property.label, this.userLang);
    },
    visibility() {
      if (this.property.isDirect) {
        return this.goalType[getVisibilityEdge(this.property.key)];
      }
      return this.goalType.propertySettings.find((ps) => ps.property.uid === this.property.uid).visibility;
    },
  },
  methods: {
    buildIconFromEntity,
    updateVisibility(val) {
      this.$emit('update-visibility', { property: this.property, visibility: val });
    },
    updateCanBeChildOf(val) {
      if (val.length === 0) {
        this.$emit('update-goal-type', {
          ...this.goalType,
          canBeChildOf: val.map((uid) => ({ uid })),
          [this.requiredPropertiesMap[this.property.type]]: false,
        });
        return;
      }
      this.$emit('update-goal-type', { ...this.goalType, canBeChildOf: val.map((uid) => ({ uid })) });
    },
    updateAllowedMeasurementTypes(val) {
      this.$emit('update-goal-type', { ...this.goalType, allowedMeasurementTypes: val });
    },
    updateRequired(val) {
      this.$emit('update-goal-type', { ...this.goalType, [this.requiredPropertiesMap[this.property.type]]: val });
    },
    updateRequiredProperties(val) {
      if (!val) {
        this.$emit('update-goal-type', {
          ...this.goalType,
          requiredProperties: this.goalType.requiredProperties.filter((p) => p.uid !== this.property.uid),
        });
        return;
      }
      this.$emit('update-goal-type', {
        ...this.goalType,
        requiredProperties: uniqBy([...this.goalType.requiredProperties, { uid: this.property.uid }], 'uid'),
      });
    },
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  .property-settings-form {
    ._header {
      display: flex;
      align-items: center;

      ._title {
        margin-bottom: 0;
        margin-left: 1.2rem;
      }
    }
  }
</style>
