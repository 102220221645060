import useProperties from '@/composables/property/property';
import usePropertyOptions from '@/composables/property-option/property-option';
import useSpaces from '@/composables/space/spaces';
import useUsers from '@/composables/user/users';
import { computed, ref } from 'vue';
import { propertyType } from 'shared/constants.json';
import { unique } from 'shared/lib/array/array';

export default function useLookupProperty(propertyValues = ref([]), lookupProperty = ref({})) {
  const userSvc = useUsers();
  const spacesSvc = useSpaces();
  const propertySvc = useProperties();
  const propertyOptionSvc = usePropertyOptions();

  const propertyValue = computed(() => propertyValues.value.find((pv) => pv.property?.uid === lookupProperty.value.lookupRelation?.uid));
  const lookedUpProperty = computed(() => propertySvc.selectSingle(lookupProperty.value.lookupProperty?.uid));

  const buildUsers = (lookupProperty) => (res, next) => {
    const user = userSvc.selectSingle(next.uid);
    const pv = user.values.find((v) => v.property.uid === lookupProperty.lookupProperty.uid);
    res.push(...pv.users.map((u) => u.uid));
    return res;
  };

  const buildOptions = (lookupProperty) => (res, next) => {
    const user = userSvc.selectSingle(next.uid);
    const pv = user.values.find((v) => v.property.uid === lookupProperty.lookupProperty.uid);
    res.push(...pv.selectedOptions.map((u) => u.uid));
    return res;
  };

  const buildSpaces = (lookupProperty) => (res, next) => {
    const user = userSvc.selectSingle(next.uid);
    const pv = user.values.find((v) => v.property.uid === lookupProperty.lookupProperty.uid);
    res.push(...pv.spaces.map((u) => u.uid));
    return res;
  };

  const selectedUsers = computed(() => {
    if (propertyValue.value === undefined || lookedUpProperty.value.type !== propertyType.user) {
      return [];
    }
    return userSvc.selectMultiple(unique(propertyValue.value.users.reduce(buildUsers(lookupProperty.value), [])));
  });

  const selectedOptions = computed(() => {
    if (propertyValue.value === undefined || ![propertyType.options, propertyType.singleSelect].includes(lookedUpProperty.value.type)) {
      return [];
    }
    return propertyOptionSvc.selectMultiple(unique(propertyValue.value.users.reduce(buildOptions(lookupProperty.value), [])));
  });

  const selectedSpaces = computed(() => {
    if (propertyValue.value === undefined || lookedUpProperty.value.type !== propertyType.space) {
      return [];
    }
    return spacesSvc.selectMultiple(unique(propertyValue.value.users.reduce(buildSpaces(lookupProperty.value), [])));
  });

  const getSelectedUsers = (propertyValues, lookupProperty) => {
    const propertyValue = propertyValues.find((pv) => pv.property.uid === lookupProperty.lookupRelation.uid);
    const lookedUpProperty = propertySvc.selectSingle(lookupProperty.lookupProperty.uid);
    if (propertyValue === undefined || lookedUpProperty.type !== propertyType.user) {
      return [];
    }
    return userSvc.selectMultiple(unique(propertyValue.users.reduce(buildUsers(lookupProperty), [])));
  };

  const getSelectedOptions = (propertyValues, lookupProperty) => {
    const propertyValue = propertyValues.find((pv) => pv.property.uid === lookupProperty.lookupRelation.uid);
    const lookedUpProperty = propertySvc.selectSingle(lookupProperty.lookupProperty.uid);

    if (propertyValue === undefined || ![propertyType.options, propertyType.singleSelect].includes(lookedUpProperty.type)) {
      return [];
    }
    return propertyOptionSvc.selectMultiple(unique(propertyValue.users.reduce(buildOptions(lookupProperty), [])));
  };

  const getSpaces = (propertyValues, lookupProperty) => {
    const propertyValue = propertyValues.find((pv) => pv.property.uid === lookupProperty.lookupRelation.uid);
    const lookedUpProperty = propertySvc.selectSingle(lookupProperty.lookupProperty.uid);

    if (propertyValue === undefined || lookedUpProperty.type !== propertyType.space) {
      return [];
    }
    return spacesSvc.selectMultiple(unique(propertyValue.users.reduce(buildSpaces(lookupProperty), [])));
  };

  return {
    selectedOptions,
    selectedSpaces,
    selectedUsers,
    getSelectedOptions,
    getSpaces,
    getSelectedUsers,
  };
}
