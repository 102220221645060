<template>
  <m-dropdown
    v-model:value="show"
    :class="['icon-picker', clickable ? '-clickable' : '']"
    :title="$t('iconPicker.title')"
    :disabled="!clickable"
  >
    <m-btn
      class="_btn"
      :hide-border="!bordered"
      :disabled="disabled"
      :style="{ width: size, height: size, padding: 0 }"
    >
      <div
        class="_icon"
        :style="{ width: `${iconSize/10}rem`, height: `${iconSize/10}rem`, fontSize: `${iconSize/10}rem` }"
      >
        <template v-if="isEmptyIcon(icon)">
          <m-tooltip
            v-if="showPlaceholder"
            :placement="tooltipPlacement"
          >
            <span
              :style="{ fontSize: `${iconSize/10}rem` }"
              class="_placeholder"
            >
              <m-icon type="smile" />
            </span>
            <template #title>
              <template v-if="iconTypes.length === 1 && iconTypes[0] === iconType.emoji">
                {{ $t('iconPicker.placeholderTooltipEmoji') }}
              </template>
              <template v-else>
                {{ $t('iconPicker.placeholderTooltip') }}
              </template>
            </template>
          </m-tooltip>
        </template>
        <m-icon-display
          v-else
          :icon="icon"
          :size="iconSize"
        />
      </div>
    </m-btn>
    <template #overlay>
      <icon-picker-card
        :types="iconTypes"
        :active-tab="activeTab"
        :show-icon-variants="showIconVariants"
        @remove="remove"
        @change="select"
      />
    </template>
  </m-dropdown>
</template>

<script>
import IconPickerCard from '@/components/IconPickerCard.vue';
import { Icon, buildIcon, isEmptyIcon } from 'shared/lib/icon';
import { emojis } from 'shared/lib/emojis';
import { iconType } from 'shared/constants.json';

export default {
  name: 'IconPicker',
  props: {
    icon: {
      type: Icon,
      default: null,
    },
    size: {
      type: String,
      default: '1.4rem',
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showPlaceholder: {
      type: Boolean,
      default: false,
    },
    iconSize: {
      type: Number,
      default: 16,
    },
    bordered: {
      type: Boolean,
      default: false,
    },
    tooltipPlacement: {
      type: String,
      default: 'top',
    },
    iconTypes: {
      type: Array,
      default: () => [iconType.emoji, iconType.icon],
    },
    showIconVariants: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['change'],
  components: { IconPickerCard },
  data() {
    return {
      search: '',
      show: false,
      iconType,
    };
  },
  computed: {
    type() {
      if (this.icon === null) {
        return undefined;
      }
      return this.icon.type;
    },
    activeTab() {
      if (this.type !== iconType.custom && this.iconTypes.includes(this.type)) {
        return this.type;
      }
      return this.iconTypes[0];
    },
    clickable() {
      return !this.readOnly && !this.disabled;
    },
    emojis() {
      if (this.search === '') {
        return emojis;
      }
      return Object.keys(emojis).reduce((acc, category) => {
        Object.keys(emojis[category]).forEach((name) => {
          if (name.indexOf(this.search) > -1 && typeof acc[category] === 'undefined') {
            acc[category] = { [name]: emojis[category][name] };
            return;
          }
          if (name.indexOf(this.search) > -1) {
            acc[category] = {
              ...acc[category],
              [name]: emojis[category][name],
            };
          }
        });

        return acc;
      }, {});
    },
    randomEmoji() {
      const categories = Object.keys(emojis);
      const category = emojis[categories[Math.floor(Math.random() * categories.length)]];
      const e = Object.keys(category);
      return buildIcon(category[e[Math.floor(Math.random() * e.length) + 1]]);
    },
  },
  methods: {
    isEmptyIcon,
    select({ value, type } = { value: undefined, type: undefined }) {
      if (!this.clickable) {
        return;
      }
      this.$emit('change', buildIcon(value, type));
      this.show = false;
    },
    remove() {
      this.select();
    },
  },
  mounted() {
    if ((isEmptyIcon(this.icon)) && !this.showPlaceholder) {
      this.select(this.randomEmoji);
    }
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  .icon-picker {
    display: inline-flex;

    ._btn {
      display: flex;
      align-items: center;
      justify-content: center;
      pointer-events: none;
      cursor: default;

      ._icon {
        display: flex;
        align-items: center;
        justify-content: center;

        ._placeholder {
          color: $font-color-tertiary;
        }

        ._img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }

    &.-clickable {
      ._btn {
        pointer-events: auto;
        cursor: pointer;

        &:hover {
          background-color: $hover-color;
        }
      }
    }

  }
</style>
