import { AND, OR } from '@/lib/filter/scope-tree';
import { guid } from 'shared/lib/uuid';
import { propertyType, userScopeType } from 'shared/constants.json';

const goalPropertiesTypeUser = (goalProperties) => goalProperties.filter((p) => p.type === propertyType.user);

export const personalGoalsFilter = ({ goalProperties, user, account }) => {
  const props = goalPropertiesTypeUser(goalProperties);
  if (props.length === 0) {
    return null;
  }
  return {
    account,
    key: guid(),
    op: OR,
    children: props.map((p) => ({
      key: guid(),
      op: AND,
      children: [
        {
          key: guid(),
          scope: {
            isEmpty: false,
            property: p,
            users: [user],
            type: userScopeType.property,
          },
        },
      ],
    })),
  };
};
