<template>
  <div class="single-metric-cell">
    <div :class="['_metric', metricPositive ? '-positive' : '', metricNegative ? '-negative' : '']">
      {{ getRoundedValue(metric, minimumFractionDigits) }}{{ unit }}
    </div>
    <div
      :class="['_delta', deltaPositive ? '-positive' : '', deltaNegative ? '-negative' : '']"
    >
      <span v-if="previousMetric === null">--</span>
      <span v-else-if="previousMetric !== undefined">
        {{ deltaAbsolute > 0 ? '+' : deltaAbsolute === 0 ? '±': '' }}{{ getRoundedValue(deltaAbsolute, minimumFractionDigits) }}{{ unit }} ({{ deltaRelative > 0 ? '+' : deltaRelative === 0 ? '±': '' }}{{ isFinite(deltaRelative) ? roundedDeltaRelative : "∞" }}%)
      </span>
    </div>
  </div>
</template>

<script>
import { getRoundedValue } from '@/lib/charts/format';

export default {
  name: 'SingleMetricCell',
  props: {
    metric: {
      type: Number,
      required: true,
    },
    minimumFractionDigits: {
      type: Number,
      default: 0,
    },
    unit: {
      type: String,
      default: undefined,
    },
    metricNegativeThreshold: {
      type: Number,
      default: undefined,
    },
    metricPositiveThreshold: {
      type: Number,
      default: undefined,
    },
    previousMetric: {
      type: Number,
      default: undefined,
    },
    deltaNegativeThreshold: {
      type: Number,
      default: 0,
    },
    deltaPositiveThreshold: {
      type: Number,
      default: 0,
    },
    invertNegativePositive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return { getRoundedValue };
  },
  computed: {
    metricPositive() {
      if (this.metricPositiveThreshold === undefined) {
        return false;
      }
      const res = this.metric >= this.metricPositiveThreshold;
      return this.invertNegativePositive ? !res : res;
    },
    metricNegative() {
      if (this.metricNegativeThreshold === undefined) {
        return false;
      }
      const res = this.metric < this.metricNegativeThreshold;
      return this.invertNegativePositive ? !res : res;
    },
    deltaAbsolute() {
      return this.metric - this.previousMetric;
    },
    deltaRelative() {
      return (this.deltaAbsolute * 100) / this.previousMetric;
    },
    roundedDeltaRelative() {
      return getRoundedValue(this.deltaRelative, 0);
    },
    deltaPositive() {
      if (this.previousMetric === undefined || this.previousMetric === null || this.deltaPositiveThreshold === undefined) {
        return false;
      }
      const res = this.deltaAbsolute >= this.deltaPositiveThreshold;
      return this.invertNegativePositive ? !res : res;
    },
    deltaNegative() {
      if (this.previousMetric === undefined || this.previousMetric === null || this.deltaNegativeThreshold === undefined) {
        return false;
      }
      const res = this.deltaAbsolute < this.deltaNegativeThreshold;
      return this.invertNegativePositive ? !res : res;
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .single-metric-cell {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    height: 100%;

    ._metric {
      margin-bottom: .4rem;
      font-size: $font-size-4;
      line-height: 1;
    }

    ._delta {
      font-size: $font-size-2;
      line-height: 1;
      color: $font-color-secondary;
    }

    .-negative {
      color: $error-color;
    }

    .-positive {
      color: $success-color;
    }
  }
</style>
