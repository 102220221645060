<template>
  <div class="jira-count-of-issues">
    <div class="_label">
      {{ $t('jiraCountOfIssues.label') }}
    </div>
    <jira-jql-editor
      :jql="jiraQuery.countJql"
      :data-source="dataSource"
      class="_jql"
      @change="$emit('count-submit', $event)"
    />
  </div>
</template>

<script>
import JiraJqlEditor from '@/components/datasource/JiraJqlEditor.vue';
import { jqlLink } from '@/lib/jira';

export default {
  name: 'JiraCountOfIssuesVue',
  props: {
    jiraQuery: {
      type: Object,
      required: true,
    },
    dataSource: {
      type: Object,
      required: true,
    },
  },
  emits: ['count-submit'],
  components: { JiraJqlEditor },
  data() {
    return { jqlLink };
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .jira-count-of-issues {
    ._label {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: .4rem;
      color: $font-color-secondary;
    }

    ._jql {
      width: 100%;
    }
  }
</style>
