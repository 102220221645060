<template>
  <div :class="['page-top-bar', $store.state.breakpoint.smAndDown ? '-mobile' : '']">
    <div
      class="_left"
      :style="styleLeft"
    >
      <div
        v-if="$store.state.breakpoint.smAndDown && showMobileMenuToggle"
        class="_toggle"
      >
        <mobile-top-bar @toggle="toggleSidebar" />
      </div>
      <slot name="left">
        <breadcrumbs
          :breadcrumbs="breadcrumbs"
        />
      </slot>
      <slot name="after-breadcrumbs" />
    </div>
    <div class="_actions">
      <slot name="actions" />
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/breadcrumbs/Breadcrumbs.vue';
import MobileTopBar from '@/components/navigation/MobileTopBar.vue';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import usePersonalAppSettings from '@/composables/logged-in-user/personal-app-settings';
import { EventBus } from '@/lib/event-bus';
import { personalAppSettings as personalAppSettingsConfig } from 'shared/api/query/configs.json';

export default {
  name: 'PageTopBar',
  props: {
    breadcrumbs: {
      type: Array,
      default: () => [],
    },
    openInModal: {
      type: Boolean,
      default: false,
    },
    showMobileMenuToggle: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const { loggedInUser } = useLoggedInUser();
    const { personalAppSettings } = usePersonalAppSettings(loggedInUser);
    return { personalAppSettings };
  },
  components: { Breadcrumbs, MobileTopBar },
  computed: {
    styleLeft() {
      if (!this.openInModal && this.personalAppSettings[personalAppSettingsConfig.edges.desktopSidebarHidden] && !this.$store.state.breakpoint.smAndDown) {
        return { marginLeft: '2.4rem' };
      }

      return {};
    },
  },
  methods: {
    toggleSidebar() {
      EventBus.$emit('toggle-sidebar');
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .page-top-bar {
    display: flex;
    align-items: center;
    width: 100%;
    height: 4.5rem;
    padding-right: map_get($padding, '8');
    padding-left: map_get($padding, '8');
    border-top-left-radius: $default-border-radius;
    border-top-right-radius: $default-border-radius;

    &.-mobile {
      padding-left: 0;
      background-color: white;
      border-bottom: 1px solid $border-color;
    }

    ._actions {
      display: flex;
      align-items: center;
      margin-left: auto;
    }

    ._left {
      display: flex;
      align-items: center;
      max-width: 60%;
    }
  }
</style>
