<template>
  <div :class="['trash-content', $store.state.breakpoint.smAndDown ? '-mobile' : '']">
    <div
      class="_header"
    >
      <m-content
        padding-xs
        :padding-bottom="0"
        :padding-top="3"
        class="_tabs"
      >
        <m-tab
          :title="$t('trashContent.tabGoals')"
          :active="model === 'goal'"
          @click="model = 'goal'"
        />
      </m-content>
      <m-content
        padding-xs
        :padding-top="0"
      >
        <m-text-field
          v-model:value="search"
          class="_input"
          allow-clear
          auto-focus
          :placeholder="$t('general.typeToSearch')"
          @change="changeSearch"
        />
      </m-content>
    </div>
    <div
      :key="model"
      class="_body"
    >
      <m-content
        v-if="trashItems.length === 0 && !loading"
        :padding-x="9"
        class="_empty"
      >
        <template v-if="search === ''">
          {{ $t(`trashContent.noItems.${model}`) }}
        </template>
        <template v-else>
          {{ $t('trashContent.noItems.filter') }}
        </template>
      </m-content>
      <m-endless-scroll-list
        @visible="load"
      >
        <m-content
          :padding-y="6"
          :padding-x="0"
        >
          <div
            v-for="item in sortedTrashItems"
            :id="item.uid"
            :key="item.uid"
            class="_item"
          >
            <trash-item
              data-cy="trash-item"
              :item="item"
              @delete-permanently="onDeletePermanently"
              @restore="onRestore"
            />
          </div>
          <div
            v-if="loading"
            class="_spinner"
            data-cy="trash-spinner"
          >
            <m-spinner size="xs" />
          </div>
        </m-content>
      </m-endless-scroll-list>
    </div>
  </div>
</template>

<script>
import TrashItem from '@/components/trash/TrashItem.vue';
import useDebounce from '@/composables/debounce';
import useSnackBar from '@/composables/snackbar';
import useTrash from '@/composables/trash/trash';
import { SOFT_DELETED_AT } from 'shared/api/query/constants';
import { copy } from 'shared/lib/copy';
import { logCatch } from '@/lib/logger/logger';
import { ref } from 'vue';
import { sortByAttr, sortDatetime, sortReverse } from 'shared/lib/sort';

export default {
  name: 'TrashContent',
  components: { TrashItem },
  setup() {
    const snackbar = useSnackBar();
    const { debounce } = useDebounce({ onBeforeUnmountFlush: true });
    const model = ref('goal');
    const itemsPerPage = ref(20);
    const searchTerm = ref('');
    const { cleanCache, deleteSoftDeleted, getSoftDeletedLoading, hasMore, trashItems, loadMore, restoreSoftDeleted } = useTrash({ model, searchTerm, itemsPerPage });

    return {
      snackbar,
      debounce,
      model,
      cleanCache,
      deleteSoftDeleted,
      getSoftDeletedLoading,
      hasMore,
      itemsPerPage,
      loadMore,
      restoreSoftDeleted,
      searchTerm,
      trashItems,
    };
  },
  data() {
    return { search: '' };
  },
  computed: {
    sortedTrashItems() {
      const cp = copy(this.trashItems);
      cp.sort(sortReverse(sortByAttr(SOFT_DELETED_AT, sortDatetime)));
      return cp;
    },
    loading() {
      return this.getSoftDeletedLoading;
    },
  },
  methods: {
    load() {
      if (this.loading || !this.hasMore()) {
        return;
      }
      this.loadMore().catch(logCatch(() => {
        this.snackbar.error();
      }));
    },
    changeSearch(value) {
      if (this.searchTerm === value) {
        return;
      }
      this.debounce(() => {
        this.cleanCache();
        this.searchTerm = value;
        this.load();
      }, 300);
    },
    onRestore(event) {
      const router = this.$router;
      this.restoreSoftDeleted(event.item.uid).then(() => {
        if (this.trashItems.length < this.itemsPerPage) {
          this.load();
        }
        this.snackbar.success(this.$t('success.restored'), {
          action: {
            title: this.$t('trashContent.openGoal'),
            handler() {
              router.push(`/goals/${event.item.uid}`);
            },
          },
        });
      });
    },
    onDeletePermanently(event) {
      const deleteMethod = () => {
        this.deleteSoftDeleted(event.item.uid);
      };

      this.$confirm({
        title: this.$t('trashContent.action.confirmDeletePermanently', { title: event.item.title }),
        okText: this.$t('trashContent.action.yesDelete'),
        okType: 'danger',
        maskClosable: true,
        cancelText: this.$t('general.cancel'),
        onOk() {
          deleteMethod();
        },
      });
    },
  },
  mounted() {
    this.cleanCache();
    this.load();
  },
};
</script>

<style scoped lang="scss" type="text/scss">
.trash-content {
  ._header {
    ._tabs {
      border-bottom: 1px solid $border-color;
    }

    ._input {
      margin: 1rem 0 .5rem 0;
    }
  }

  ._body {
    height: 50vh;
    overflow-x: hidden;
    overflow-y: auto;

    ._empty {
      color: $font-color-tertiary;
    }

    ._item {
      min-height: 5rem;
    }
  }

  &.-mobile {
    ._body {
      height: calc(100vh - 11.2rem);
    }
  }

  ._spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 6rem;
  }
}
</style>
