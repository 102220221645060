import { CREATED_AT, DEFAULT_ATTRIBUTES, ONE_TO_ONE, UID } from 'shared/api/query/constants';
import {
  accessGroup as accessGroupConfig,
  accessPolicy as accessPolicyConfig,
  accessPolicyScope as accessPolicyScopeConfig,
  account as accountConfig,
  appIntegration as appIntegrationConfig,
  invitation as invitationConfig,
  notificationSetting as notificationSettingConfig,
  personalAppSettings as personalAppSettingsConfig,
  propertyValue as propertyValueConfig,
  selectedView as selectedViewConfig,
  storageObject as storageObjectConfig,
  user as userConfig,
} from 'shared/api/query/configs.json';
import { appIntegrationChildren } from '@/api/query/nebula/app-integration';
import { invitationEdges } from '@/api/query/nebula/inviation';
import { notificationSettingEdges } from '@/api/query/nebula/notification-setting';
import { personalAppSettingsEdges } from '@/api/query/nebula/personal-app-settings';
import { propertyValueEdges } from '@/api/query/nebula/property-value';
import {
  reverseEdge,
} from 'shared/api/query/edges';
import { selectedViewChildren } from '@/api/query/nebula/selected-view';
import { storageObjectEdges } from '@/api/query/nebula/storage-object';

export const userEdges = [
  ...DEFAULT_ATTRIBUTES,
  { attr: userConfig.edges.firstName, default: '' },
  { attr: userConfig.edges.lastName, default: '' },
  { attr: userConfig.edges.email, default: '' },
  { attr: userConfig.edges.language, default: 'en' },
  { attr: userConfig.edges.confirmed, default: false },
  { attr: userConfig.edges.isAccountAdmin, default: false },
  { attr: userConfig.edges.externalId, default: null },
  { attr: userConfig.edges.firstSessionCreatedAt, default: null },
  { attr: userConfig.edges.isDemo, default: false },
  { attr: userConfig.edges.account, model: accountConfig.model, children: [{ attr: UID }] },
  {
    attr: userConfig.edges.profileImage,
    default: null,
    model: storageObjectConfig.model,
    children: storageObjectEdges,
  },
  {
    attr: userConfig.edges.appIntegration,
    model: appIntegrationConfig.model,
    default: null,
    children: appIntegrationChildren,
  },
  {
    attr: userConfig.edges.notificationSetting,
    model: notificationSettingConfig.model,
    default: null,
    children: notificationSettingEdges,
  },
  {
    attr: userConfig.edges.values,
    default: [],
    model: propertyValueConfig.model,
    children: propertyValueEdges,
  },
  {
    alias: personalAppSettingsConfig.model,
    attr: reverseEdge(personalAppSettingsConfig.edges.user),
    assoc: ONE_TO_ONE,
    model: personalAppSettingsConfig.model,
    children: personalAppSettingsEdges,
  },
  {
    alias: selectedViewConfig.model,
    attr: reverseEdge(selectedViewConfig.edges.user),
    default: [],
    model: selectedViewConfig.model,
    children: selectedViewChildren,
  },
  {
    alias: invitationConfig.model,
    attr: reverseEdge(invitationConfig.edges.recipient),
    model: invitationConfig.model,
    default: null,
    assoc: ONE_TO_ONE,
    args: { first: '1' },
    order: [{ attr: CREATED_AT, desc: true }],
    children: invitationEdges,
  },
  {
    alias: 'accessGroupsDirectUsers',
    attr: reverseEdge(accessPolicyScopeConfig.edges.users),
    model: accessPolicyScopeConfig.model,
    normalize: true,
    children: [
      {
        attr: reverseEdge(accessPolicyConfig.edges.scopes),
        model: accessPolicyConfig.model,
        children: [
          {
            attr: reverseEdge(accessGroupConfig.edges.accessPolicy),
            model: accessGroupConfig.model,
            children: [
              { attr: UID, alias: UID },
            ],
          },
        ],
      },
    ],
  },
  {
    alias: 'accessGroupsScopeUsers',
    attr: reverseEdge(accessPolicyScopeConfig.edges.scopeUsers),
    model: accessPolicyScopeConfig.model,
    normalize: true,
    children: [
      {
        attr: reverseEdge(accessPolicyConfig.edges.scopes),
        model: accessPolicyConfig.model,
        children: [
          {
            attr: reverseEdge(accessGroupConfig.edges.accessPolicy),
            model: accessGroupConfig.model,
            children: [
              { attr: UID, alias: UID },
            ],
          },
        ],
      },
    ],
  },
];
