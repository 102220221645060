<template>
  <div class="space-general-form">
    <m-form-item
      class="_form-item"
      :label="$t('spaceGeneralForm.generalLabel')"
    >
      <div class="_general">
        <icon-picker
          class="_emoji-picker"
          :icon-types="iconTypes"
          :icon="icon"
          show-placeholder
          size="3.2rem"
          bordered
          :read-only="readOnly"
          @change="setIcon"
        />
        <color-picker
          :value="space.color"
          :read-only="readOnly"
          @input="setColor"
        />
        <m-text-field
          :value="space.title"
          :read-only="readOnly"
          :auto-focus="space.title === ''"
          :placeholder="$t('spaceGeneralForm.titlePlaceholder')"
          @input="setTitle"
          @keydown.enter="emit('enter')"
        />
      </div>
    </m-form-item>
    <m-form-item
      class="_form-item"
      :label="$t('spaceGeneralForm.parentsLabel')"
    >
      <space-selector
        :value="parents"
        :read-only="readOnly"
        :placeholder="$t('spaceGeneralForm.parentsPlaceholder')"
        :restricted-spaces="restrictedSpaces"
        :property-label="spaceProperty.label"
        full-width
        close-on-select
        :with-archived="withArchived"
        match-trigger-width
        @input="setParent"
      />
    </m-form-item>
    <m-form-item
      class="_form-item"
      :label="$t('spaceGeneralForm.descriptionLabel')"
    >
      <m-focusable
        class="_editor-wrapper"
        :read-only="readOnly"
        @click="editorRef.focus()"
      >
        <m-editor
          ref="editorRef"
          :initial-value="space.description"
          :placeholder="$t('general.empty')"
          :allowed-content="allowedContent"
          :disabled="readOnly"
          scrollable
          light-placeholder
          :ctrl-enter-handlers="[() => emit('enter')]"
          :mod-enter-handlers="[() => emit('enter')]"
          @input="setDescription"
        />
      </m-focusable>
    </m-form-item>
  </div>
</template>
<script setup>

import ColorPicker from '@/components/ColorPicker.vue';
import IconPicker from '@/components/IconPicker.vue';
import MEditor from '@/components/editor/MEditor.vue';
import SpaceSelector from '@/components/spaces/SpaceSelector.vue';
import useProperties from '@/composables/property/property';
import useSpaceDepthValidator from '@/composables/space/depth-validator';
import useSpaces from '@/composables/space/spaces';
import { buildIconFromEntity, replaceIconOnEntity } from 'shared/lib/icon';
import { computed, ref } from 'vue';
import { editorNodeType, iconType } from 'shared/constants.json';

const props = defineProps({
  space: {
    type: Object,
    required: true,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
  withArchived: {
    type: Boolean,
    default: false,
  },
});

const space = ref(props.space);

const emit = defineEmits(['enter', 'update:space']);

const iconTypes = [iconType.emoji, iconType.icon, iconType.custom];
const icon = computed(() => buildIconFromEntity(space.value));

const spacesSvc = useSpaces();
const parents = computed(() => (props.withArchived === true ? space.value.parents : space.value.parents.filter((p) => spacesSvc.selectSingle(p.uid).archivedAt === null)));

const { tooDeepItems } = useSpaceDepthValidator({
  getId: (o) => o.uid,
  getDepth: (o) => o.level,
  getChildrenMaxDepth: (o) => o.childrenMaxLevel,
});

const restrictedSpaces = computed(() => {
  const excludedIds = tooDeepItems(spacesSvc.allSpacesTreeFlat.value, { uid: props.space.uid }).map((i) => i.uid);
  return spacesSvc.allSpaces.value.filter((o) => !excludedIds.includes(o.uid));
});

const { spaceProperty } = useProperties();

const editorRef = ref(null);
const allowedContent = [
  editorNodeType.bulletList,
  editorNodeType.orderedList,
];

const setIcon = (icon) => {
  space.value = replaceIconOnEntity(space.value, icon);
  emit('update:space', space.value);
};
const setColor = (color) => {
  space.value.color = color;
  emit('update:space', space.value);
};
const setTitle = (title) => {
  space.value.title = title;
  emit('update:space', space.value);
};
const setParent = (parents) => {
  space.value.parents = parents;
  emit('update:space', space.value);
};
const setDescription = (description) => {
  space.value.description = description;
  emit('update:space', space.value);
};
</script>

<style scoped lang="scss" type="text/scss">
.space-general-form {
  display: flex;
  flex-direction: column;

  ._form-item {
    margin-bottom: 1.6rem;
  }

  ._general {
    display: flex;
    gap: .8rem;
  }

  ._editor-wrapper {
    position: relative;
    min-height: 10rem;
    max-height: 25rem;
    overflow: hidden;
    border: 1px solid $border-color;
    border-radius: $default-border-radius;
    padding: 0;
    cursor: text;
    align-items: flex-start;

    :deep(.m-editor) {
      width: 100%;

      ._editor {
        padding: 0.3rem 0.8rem;
      }
    }
  }
}

</style>
