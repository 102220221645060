export const showOnboarding = ({ user, account }) => {
  if (user.externalId !== null) {
    return false;
  }

  if (user.personalInfosCompleted === null) {
    return true;
  }

  if (!user.isAccountAdmin) {
    return false;
  }

  return account.accountSettings !== null && (
    account.accountSettings.accountInfosCompleted === null || account.accountSettings.moduleSelectionCompleted === null
  );
};
