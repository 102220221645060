import { copy, shallowCopy } from 'shared/lib/copy';
import { propertyType } from 'shared/constants.json';

export default function useCleanFilter(propertySvc, userSvc, spaceSvc) {
  const filterFunc = (props) => (current) => {
    const propIds = current.children.map((c) => {
      if (c.scope === undefined || c.scope.property === undefined) {
        return 0;
      }
      return c.scope.property.uid;
    });

    if (propIds.includes(0)) {
      return true;
    }

    for (let i = 0; i < propIds.length; i++) {
      if (!props.map((p) => p.property.uid).includes(propIds[i])) {
        return false;
      }
    }

    const lookupFilterProps = current.children.reduce((res, next) => {
      if (next.scope.property.type !== propertyType.lookup) {
        return res;
      }

      res.push(next.scope.property);
      return res;
    }, []);

    for (let i = 0; i < lookupFilterProps.length; i++) {
      const filtered = props.filter((p) => p.property.uid === lookupFilterProps[i].uid);
      if (filtered.length !== 1) {
        return false;
      }

      if (filtered[0].property.lookupProperty.type !== lookupFilterProps[i].lookupProperty.type) {
        return false;
      }
    }

    return true;
  };

  const cleanUsers = (filter) => {
    const usersInFilters = filter.reduce((res, next) => {
      const u = next.children.reduce((r, n) => {
        if (n.scope === undefined || n.scope.property === undefined) {
          return r;
        }
        if (n.scope.property.type === propertyType.user) {
          r.push(...n.scope.users.map((u) => u.uid));
        }
        return r;
      }, []);

      res.push(...u);
      return res;
    }, []);

    const knownUsers = userSvc.users.value.map((u) => u.uid);
    const unknownUsers = usersInFilters.filter((uid) => !knownUsers.includes(uid));
    if (unknownUsers.length === 0) {
      return filter;
    }

    const hasUser = (uid, filter) => filter.children.filter((c) => {
      if (c.scope === undefined || c.scope.property === undefined) {
        return false;
      }
      if (c.scope.property.type === propertyType.user) {
        return c.scope.users.filter((u) => u.uid === uid).length > 0;
      }
      return false;
    }).length > 0;

    return filter.filter((f) => unknownUsers.filter((uid) => hasUser(uid, f)).length === 0);
  };

  const cleanSelectedSpaces = (filter) => {
    const validSpaces = spaceSvc.allSpaces.value.map((o) => o.uid);

    const reduceFn = (res, next) => {
      if (Array.isArray(next.children) && next.children.length > 0) {
        next.children = next.children.reduce(reduceFn, []);
        res.push(next);
        return res;
      }

      if (next.scope === undefined || next.scope.spaces === undefined || next.scope.directProperty !== undefined) {
        res.push(next);
        return res;
      }

      // Scope.SelectedOptions->Spaces migration for local storage
      // TODO: expected removal in 1 year - Apr 2025
      if (next.scope.property !== undefined && next.scope.selectedOptions !== undefined && next.scope.property.type === 'space' && next.scope.selectedOptions.length > 0) {
        next.scope.spaces = shallowCopy(next.scope.selectedOptions);
        next.scope.selectedOptions = [];
      }

      res.push({
        ...next,
        scope: {
          ...next.scope,
          spaces: next.scope.spaces.filter((o) => validSpaces.includes(o.uid)),
        },
      });
      return res;
    };

    return filter.reduce(reduceFn, []);
  };

  const cleanSelectedOptions = (filter) => {
    const validOptions = propertySvc.properties.value.reduce((res, next) => {
      if (next.options !== undefined) {
        res.push(...next.options.map((o) => o.uid));
        return res;
      }

      return res;
    }, []);

    const reduceFn = (res, next) => {
      if (Array.isArray(next.children) && next.children.length > 0) {
        next.children = next.children.reduce(reduceFn, []);
        res.push(next);
        return res;
      }

      if (next.scope === undefined || next.scope.selectedOptions === undefined || next.scope.directProperty !== undefined) {
        res.push(next);
        return res;
      }

      res.push({
        ...next,
        scope: {
          ...next.scope,
          selectedOptions: next.scope.selectedOptions.filter((o) => validOptions.includes(o.uid)),
        },
      });
      return res;
    };

    return filter.reduce(reduceFn, []);
  };

  const cleanFilter = ({ filter, props }) => {
    if (filter === null) {
      return null;
    }

    const res = copy(filter);
    res.children = res.children.filter(filterFunc(props));
    res.children = cleanSelectedSpaces(res.children);
    res.children = cleanSelectedOptions(res.children);
    res.children = cleanUsers(res.children);

    return res;
  };

  return { cleanFilter };
}
