<template>
  <div class="editable-page">
    <page-top-bar>
      <template #left>
        <breadcrumbs
          :breadcrumbs="breadcrumbs"
        />
      </template>
      <template #actions>
        <m-btn

          icon="close"
          hide-border
          small
          fab
          @click="$emit('close')"
        />
      </template>
    </page-top-bar>
    <m-content padding-x="layout">
      <editable-page-header
        ref="header"
        :title="page.title"
        :icon="icon"
        :show-icon="!isEmptyIcon(icon) || emojiAdded"
        :class="['_header', isEmptyIcon(icon) ? '' : '-icon']"
        show-icon-on-top
        auto-focus
        :disabled="disabled"
        @change-icon="updateIcon"
        @change-title="updateTitle"
        @arrow-down="focusEditor"
        @enter="focusEditor"
      >
        <template
          v-if="!disabled"
          #actions
        >
          <div class="_header-actions">
            <m-btn
              v-if="isEmptyIcon(icon) && !emojiAdded"
              hide-border
              class="_item"
              icon="smile"
              light
              small
              @click="addEmoji"
            >
              {{ $t('page.addEmoji') }}
            </m-btn>
          </div>
        </template>
      </editable-page-header>
      <div
        class="_editor-wrapper"
        @click="$refs.editor.focus()"
      >
        <m-editor
          ref="editor"
          class="_editor"
          :initial-value="page.content"
          :allowed-content="allowedContent"
          :placeholder="$t('meetingPage.startTyping')"
          :disabled="disabled"
          :default-font-size="fontSizes[5]"
          @input="updateContent"
          @top-reached="$refs.header.focus()"
        />
      </div>
    </m-content>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/breadcrumbs/Breadcrumbs.vue';
import EditablePageHeader from '@/components/page/EditablePageHeader.vue';
import PageTopBar from '@/components/page/PageTopBar.vue';
import useAccess from '@/composables/access/access';
import useDebounce from '@/composables/debounce';
import { buildIconFromEntity, isEmptyIcon } from 'shared/lib/icon';
import { defineAsyncComponent } from 'vue';
import { dogma } from '@/api';
import { editorNodeType, featureFlag } from 'shared/constants.json';
import { fontSizes } from 'shared/font-sizes';
import { page as pageConfig } from 'shared/api/query/configs.json';

export default {
  name: 'EditablePage',
  props: {
    page: {
      type: Object,
      required: true,
    },
    breadcrumbs: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const allowedContent = [
      editorNodeType.heading,
      editorNodeType.codeBlock,
      editorNodeType.blockquote,
      editorNodeType.bulletList,
      editorNodeType.orderedList,
      editorNodeType.mention,
      editorNodeType.goalMention,
      editorNodeType.todoList,
    ];

    const { accountHasFeature } = useAccess();
    if (accountHasFeature([featureFlag.fileUpload])) {
      allowedContent.push(editorNodeType.image, editorNodeType.file);
    }

    const { debounce } = useDebounce();
    return { debounce, allowedContent };
  },
  emits: ['close'],
  components: {
    Breadcrumbs,
    MEditor: defineAsyncComponent(() => import('@/components/editor/MEditor.vue')),
    EditablePageHeader,
    PageTopBar,
  },
  data() {
    return {
      fontSizes,
      localTitle: '',
      emojiAdded: false,
    };
  },
  computed: {
    icon() {
      return buildIconFromEntity(this.page);
    },
  },
  methods: {
    isEmptyIcon,
    addEmoji() {
      this.emojiAdded = true;
    },
    focusEditor(event) {
      event.stopPropagation();
      event.preventDefault();
      this.$refs.editor.focus();
    },
    updateIcon(icon) {
      this.$store.commit('ENTITY_UPDATED', {
        entity: { ...this.page, icon: icon.value },
        model: pageConfig.model,
      });
    },
    updateTitle(title) {
      this.localTitle = title;

      const update = () => {
        this.$store.commit('ENTITY_UPDATED', {
          entity: { ...this.page, title },
          model: pageConfig.model,
        });
      };
      this.debounce(update, 500);
    },
    updateContent(js) {
      const update = () => {
        this.$store.commit('ENTITY_UPDATED', {
          entity: { ...this.page, content: js },
          model: pageConfig.model,
        });
      };
      this.debounce(update, 500);
    },
    save() {
      dogma.updateSingle(
        this.page,
        pageConfig.model,
        [],
      ).then((response) => {
        if (response.status !== 200) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
        }
      });
    },
  },
  watch: {
    page: {
      handler(newVal, oldVal) {
        if (JSON.stringify(newVal) === JSON.stringify(oldVal)) {
          return;
        }

        this.save();
      },
    },
  },
  mounted() {
    this.localTitle = this.page.title;
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .editable-page {
    ._header {
      margin-bottom: 3rem;

      &.-icon {
        margin-top: 6rem;
      }
    }

    ._editor-wrapper {
      display: flex;
      min-height: 40vh;
      padding-bottom: 15rem;

      ._editor {
        flex: 1 1 auto;
        width: 100%;
      }
    }
  }
</style>
