<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="12"
      cy="12"
      r="11"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.5 8.51011L10.5309 18L4.5 12.3324L6.02195 10.7298L10.4379 14.8798L17.8853 7L19.5 8.51011Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default { name: 'CheckMarkCircleFilled' };
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>

</style>
