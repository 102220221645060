import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useUserProperty from '@/composables/property/user-property';
import { computed } from 'vue';
import { createPropsList } from '@/lib/props';
import { directProperties } from '@/lib/user/properties';
import { useI18n } from 'vue-i18n';
import { user as userConfig } from 'shared/api/query/configs.json';

export default function useUserDefaultProps() {
  const { userLang } = useLoggedInUser();
  const i18n = useI18n();

  const { properties: userProperties } = useUserProperty();

  const defaultProps = computed(() => createPropsList({
    properties: userProperties.value,
    directProperties: directProperties(i18n.t),
    userLang: userLang.value,
    model: userConfig.model,
  }));

  return { defaultProps };
}
