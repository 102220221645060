import { NOT } from '@/lib/filter/scope-tree';

export const extractPropertyOptionIds = ({ filter, properties }) => {
  if (filter === null) {
    return [];
  }

  return filter.children.reduce((acc, current) => {
    const optionIds = current.children.reduce((a, c) => {
      const property = properties.find((p) => p.uid === c.scope.property.uid);
      if (c.scope.selectedOptions === undefined
        || c.scope.selectedOptions.length === 0
        || c.scope.property === undefined
        || property === undefined
      ) {
        return a;
      }

      const optionIds = c.scope.selectedOptions.map((o) => o.uid);

      if (current.op === NOT) {
        a.push(...property.options.filter((o) => !optionIds.includes(o.uid)).map((o) => o.uid));
        return a;
      }

      a.push(...optionIds);
      return a;
    }, []);
    acc.push(...optionIds);

    return acc;
  }, []);
};
