<template>
  <item-wrapper
    v-if="number !== null"
    class="number-prop"
    :tooltip="prop.label"
    :show-tooltip="showTooltip"
  >
    <div class="_number">
      {{ number }}
    </div>
  </item-wrapper>
</template>

<script>
import ItemWrapper from '@/components/list/ItemWrapper.vue';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import { getValueFromEntity } from '@/lib/props';
import { numberFormatter } from '@/lib/props/number';

export default {
  name: 'NumberProp',
  props: {
    prop: {
      type: Object,
      required: true,
    },
    entity: {
      type: Object,
      required: true,
    },
    propertyValuesKey: {
      type: String,
      default: 'properties',
    },
    showTooltip: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { userLang } = useLoggedInUser();
    return { userLang };
  },
  components: { ItemWrapper },
  computed: {
    number() {
      const value = getValueFromEntity(this.prop, this.entity, this.propertyValuesKey);
      if (value === null) {
        return '';
      }
      return numberFormatter(this.prop.property.numberFormat, this.userLang)(value);
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .number-prop {
    display: flex;
    align-items: center;
  }
</style>
