<template>
  <div class="space-list-table">
    <m-selectable
      v-model:value="selectedIds"
      selector-class="m-table-row"
    >
      <m-hover-menu
        v-if="showHoverMenu"
        :item-class="draggableClass"
        :recreate-key="rows"
        mousemove-area-class="m-content"
        :force-show="showHoverMenuActions"
        @set-active-element="setActiveElement"
      >
        <div class="_hover-menu-inner">
          <drag-or-click-btn
            :draggable="canDrag"
            clickable
            @click="handleHoverMenuDragClick"
            @drag="handleHoverMenuDragDrag"
          />
        </div>
      </m-hover-menu>
      <m-draggable
        ref="draggableRef"
        :dragover-item-class="draggableClass"
        :ghost-item-style="ghostItemStyle"
        can-drag-over-top
        can-drag-over-bottom
        can-drag-over
        :recreate-key="rows"
        @set-drag-item="initDrag"
        @over-top="setDragOverTop"
        @over-bottom="setDragOverBottom"
        @over="setDragOver"
        @drag-drop="handleDrop"
        @cancel="resetDragging"
      >
        <m-table
          :columns="columns"
          :data-source="rowsAtDrag"
          class="_table"
          hide-vertical-border
          :selected-rows="selectedOrDragOverIds"
          row-key="index"
          :rows-clickable="!dragging"
          :custom-row="handleRowClick"
          :show-drag-over-top-targets="dragOverTop"
          :show-drag-over-bottom-targets="dragOverBottom"
          @contextmenu="handleContextMenu"
        >
          <template #dragzoneTop>
            <m-dragzone
              class="_dragzone-top"
              :level="dragDepth"
            />
          </template>
          <template #dragzoneBottom>
            <m-dragzone
              class="_dragzone-bottom"
              :level="dragDepth"
            />
          </template>
          <template #name="{ row }">
            <hierarchical-table-cell
              v-if="showAsHierarchy"
              :level="row.level"
              :has-expand="row.children?.length > 0 && row.containsMatch"
              :expanded="getExpand(row)"
              :disabled="dragging"
              @toggle-expand="toggleExpand(row)"
            >
              <div class="_name">
                <m-tooltip
                  class="_title"
                  placement="bottom"
                  :disabled="!isTitleTruncated"
                  :mouse-enter-delay=".5"
                >
                  <template #title>
                    {{ row.title }}
                  </template>
                  <item-title
                    v-model:is-title-truncated="isTitleTruncated"
                    :title="row.title"
                    :icons="[{ value: row.icon }]"
                  />
                </m-tooltip>
                <m-tag
                  v-if="row.status === SPACE_STATUS_JOINED"
                  class="_tag"
                  icon="check-mark"
                  :title="$t('spaceStatus.joined')"
                  small
                  rounded
                />
              </div>
            </hierarchical-table-cell>
            <div
              v-else
              class="_name"
            >
              <m-tooltip
                class="_title"
                placement="bottom"
                :disabled="!isTitleTruncated"
                :mouse-enter-delay=".5"
              >
                <template #title>
                  {{ row.title }}
                </template>
                <item-title
                  v-model:is-title-truncated="isTitleTruncated"
                  :title="row.title"
                  :icons="[{ value: row.icon }]"
                />
              </m-tooltip>
              <m-tag
                v-if="row.status === SPACE_STATUS_JOINED"
                class="_tag"
                icon="check-mark"
                :title="$t('spaceStatus.joined')"
                small
                rounded
              />
            </div>
          </template>
          <template #owners="{ row }">
            <template
              v-if="row.owners.length === 0"
            >
              <div :style="{ color: colors.grey.base }">
                -
              </div>
            </template>
            <template
              v-else-if="row.owners.length === 1"
            >
              <user-display :user="row.owners[0]" />
            </template>
            <m-dropdown
              v-else
              :title="pluralize(textByLang(ownersProperty.label, userLang), userLang)"
              @click.stop
            >
              <m-btn
                hide-border
                small
              >
                {{ row.owners.length }} {{ pluralize(textByLang(ownersProperty.label, userLang), userLang) }}
                <m-icon
                  :style="{ marginLeft: '.4rem' }"
                  size="12"
                  :color="colors.grey.lighten1"
                  type="down"
                />
              </m-btn>
              <template #overlay>
                <m-card
                  list
                  no-padding
                >
                  <m-card-item
                    v-for="owner in row.owners"
                    :key="owner.uid"
                    :clickable="false"
                  >
                    <user-display :user="owner" />
                  </m-card-item>
                </m-card>
              </template>
            </m-dropdown>
          </template>
          <template #status="{ row }">
            <div class="_status">
              <template v-if="row.archivedAt === null">
                <m-icon
                  type="dot"
                  size="28"
                  :color="getIconColor(SPACE_STATUS_ACTIVE)"
                  :style="{ width: '1.6rem', marginRight: '.4rem', marginLeft: '.6rem' }"
                />
                <div>{{ $t(`spaceStatus.${SPACE_STATUS_ACTIVE}`) }}</div>
              </template>
              <m-dropdown
                v-else
                :title="$t('spaceListTable.header.status')"
                close-on-click
                @click.stop
              >
                <m-btn
                  hide-border
                  xs
                >
                  <m-icon
                    type="dot"
                    size="28"
                    :color="getIconColor(SPACE_STATUS_ARCHIVED)"
                    :style="{ width: '1.6rem', marginRight: '.4rem' }"
                  />
                  {{ $t(`spaceStatus.${SPACE_STATUS_ARCHIVED}`) }}
                </m-btn>
                <template #overlay>
                  <m-card list>
                    <m-card-item
                      :clickable="false"
                    >
                      {{ $t('spaceListTable.archivedAt') }} {{ formatDate(row.archivedAt) }}
                    </m-card-item>
                  </m-card>
                </template>
              </m-dropdown>
            </div>
          </template>
        </m-table>
      </m-draggable>
      <m-dialog
        :value="showEditModal"
        :title="$t('spaceEditor.edit')"
        hide-footer
        no-padding
        @close="showEditModal = false"
      >
        <space-editor
          :space="selectedSpace"
          :with-archived="withArchived"
          @updated="showEditModal = false"
          @deleted="showEditModal = false"
          @close="showEditModal = false"
        />
      </m-dialog>
      <space-context-menu
        ref="contextMenuRef"
        :spaces="selectedSpaces"
        @hide="hideContextMenu"
      />
    </m-selectable>
  </div>
</template>

<script setup>

import DragOrClickBtn from 'shared/components/DragOrClickBtn.vue';
import HierarchicalTableCell from '@/components/table/HierarchicalTableCell.vue';
import ItemTitle from '@/components/ItemTitle.vue';
import MDraggable from 'shared/components/base/MDraggable.vue';
import MDragzone from 'shared/components/base/MDragzone.vue';
import MDropdown from 'shared/components/base/MDropdown.vue';
import MHoverMenu from 'shared/components/base/MHoverMenu.vue';
import SpaceContextMenu from '@/components/spaces/SpaceContextMenu.vue';
import SpaceEditor from '@/components/spaces/SpaceEditor.vue';
import UserDisplay from 'shared/components/UserDisplay.vue';
import colors from 'shared/colors';
import useCascadeDragAndDrop from '@/composables/cascade-drag-and-drop';
import useExpand from '@/composables/expand';
import useLocalStorage from '@/composables/local-storage/local-storage';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useProperties from '@/composables/property/property';
import useSpaceOperationExecutor from '@/composables/space/drag-executor';
import useSpaceOperationValidator from '@/composables/space/drag-validator';
import useSpaces from '@/composables/space/spaces';
import useUsers from '@/composables/user/users';
import { DateTime } from 'luxon';
import {
  SPACE_STATUS_ACTIVE,
  SPACE_STATUS_ALL,
  SPACE_STATUS_ARCHIVED,
  SPACE_STATUS_JOINED,
} from '@/lib/constants';
import { arrayToTree, treeToArray } from '@/lib/tree/tree';
import { buildIconFromEntity } from 'shared/lib/icon';
import { computed, ref, toRef } from 'vue';
import { findInArray } from 'shared/lib/array/array';
import { isInFilter } from '@/lib/filter/base-frontend-filter/handler';
import { pluralize, textByLang } from 'shared/lib/language';
import { propertyType } from 'shared/constants.json';
import { sortAlphaNumeric, sortByAttr } from 'shared/lib/sort';
import { space as spaceConfig } from 'shared/api/query/configs.json';
import { useI18n } from 'vue-i18n';

const i18n = useI18n();

const props = defineProps({
  search: {
    type: String,
    default: '',
  },
  filter: {
    type: String,
    default: SPACE_STATUS_ACTIVE,
  },
});

const withArchived = computed(() => [SPACE_STATUS_ARCHIVED, SPACE_STATUS_JOINED, SPACE_STATUS_ALL].includes(props.filter));

const { userLang, myTeams } = useLoggedInUser();
const { spaceProperties } = useProperties();
const ownersProperty = computed(() => spaceProperties.value.find((prop) => prop.type === propertyType.user));
const { users } = useUsers();
const spacesSvc = useSpaces(toRef(props, 'search'));

const { loggedInUserAccount } = useLoggedInUserAccount();
const identifier = { getId: (o) => o.index };
const keyMaker = {
  getKey() {
    return computed(() => `${loggedInUserAccount.value.uid}_space-list-table_expand`);
  },
};
const localStorageSvc = useLocalStorage(localStorage, keyMaker);
const expandSvc = useExpand(identifier, localStorageSvc.data);

const isTitleTruncated = ref(false);

const showAsHierarchy = computed(() => [SPACE_STATUS_ACTIVE, SPACE_STATUS_ALL].includes(props.filter));

const columns = computed(() => {
  const columns = [
    {
      key: 'name',
      dataIndex: 'name',
      title: i18n.t('spaceListTable.header.name'),
      sorter: showAsHierarchy.value ? undefined : sortByAttr('title', sortAlphaNumeric),
      scopedSlots: { customRender: 'name' },
    },
    {
      key: 'members',
      dataIndex: 'members',
      title: i18n.t('spaceListTable.header.members'),
      sorter: showAsHierarchy.value ? undefined : sortByAttr('members', sortAlphaNumeric),
    },
  ];

  if (ownersProperty.value !== undefined) {
    columns.push({
      key: 'owners',
      dataIndex: 'owners',
      title: textByLang(ownersProperty.value.label, userLang),
      scopedSlots: { customRender: 'owners' },
    });
  }

  columns.push({
    key: 'status',
    dataIndex: 'status',
    title: i18n.t('spaceListTable.header.status'),
    scopedSlots: { customRender: 'status' },
  });

  return columns;
});

const filterMap = {
  [SPACE_STATUS_ACTIVE]: {
    scopeTree: {
      scope: {
        directProperty: {
          edgeName: spaceConfig.edges.archivedAt,
          type: propertyType.date,
        },
        isEmpty: true,
      },
    },
  },
  [SPACE_STATUS_ARCHIVED]: {
    scopeTree: {
      scope: {
        directProperty: {
          edgeName: spaceConfig.edges.archivedAt,
          type: propertyType.date,
        },
        isEmpty: false,
      },
    },
  },
};

const spaceRows = computed(() => {
  const ss = withArchived.value === true ? spacesSvc.allSpacesTreeFlat : spacesSvc.activeSpacesTreeFlat;
  return ss.value.map((space) => {
    const spaceMembers = users.value.filter((user) => {
      if (user.values !== undefined) {
        const userSpaces = user.values.filter((v) => v.property.type === propertyType.space)
          .map((pv) => pv.spaces)
          .flat();
        return findInArray({ haystack: userSpaces, needle: space.uid }) !== null;
      }
      return false;
    });

    let status = SPACE_STATUS_ACTIVE;
    if (space.archivedAt !== null) {
      status = SPACE_STATUS_ARCHIVED;
    }
    if (findInArray({ haystack: myTeams.value, needle: space.uid }) !== null) {
      status = SPACE_STATUS_JOINED;
    }

    let owners = [];
    if (space.properties !== undefined) {
      const pv = space.properties.find((v) => v.property.uid === ownersProperty.value.uid);
      if (pv !== undefined) {
        owners = pv.users.map((pvU) => users.value.find((u) => u.uid === pvU.uid)).filter((u) => u !== undefined);
      }
    }

    return {
      ...space,
      icon: buildIconFromEntity(space),
      status,
      members: spaceMembers.length,
      owners,
      disabled: !space.match,
    };
  });
});
const rows = computed(() => {
  const filteredBySearchRows = spaceRows.value.reduce((res, next) => {
    if (showAsHierarchy.value === false && next.disabled === true) {
      return res;
    }
    switch (props.filter) {
      case SPACE_STATUS_ALL:
        break;
      case SPACE_STATUS_JOINED:
        if (next.status !== SPACE_STATUS_JOINED) {
          return res;
        }
        break;
      default:
        if (filterMap[props.filter] !== undefined && !isInFilter({ isFilterMode: true })({ entity: next, scopeTree: filterMap[props.filter].scopeTree, propertyValues: next.properties })) {
          return res;
        }
    }

    if (!next.match && !next.containsMatch) {
      return res;
    }

    res.push(next);
    return res;
  }, []);

  if (showAsHierarchy.value === false) {
    return filteredBySearchRows;
  }

  const mapSiblings = (node, index, array) => ({
    ...node,
    firstSibling: index === 0,
    lastSibling: index === array.length - 1,
    children: node.children.map(mapSiblings),
  });
  const siblingAdjustedRows = treeToArray(arrayToTree(filteredBySearchRows).map(mapSiblings));

  const filteredByCollapseRows = siblingAdjustedRows.reduce((res, next) => {
    if (showAsHierarchy.value === false && next.disabled === true) {
      return res;
    }

    const isParentCollapsed = (node) => {
      if (node.parents.length === 0) {
        return false;
      }
      const parent = spaceRows.value.find((space) => space.uid === node.parents[0].uid);
      if (getExpand(parent) === false) {
        return true;
      }
      return isParentCollapsed(parent);
    };
    if (isParentCollapsed(next)) {
      return res;
    }

    res.push(next);
    return res;
  }, []);

  return filteredByCollapseRows;
});

const rowsAtDrag = computed(() => {
  if (!dragging.value) {
    return rows.value;
  }
  return rows.value.map((r) => {
    const clickable = dragOver.value.includes(dragAndDropElementReader.getId(r));
    return {
      ...r,
      clickable,
    };
  });
});

const selectedIds = ref([]);
const selectedOrDragOverIds = computed(() => [...selectedIds.value, ...dragOver.value]);
const selectedSpaces = ref([]);
const selectedSpace = computed(() => {
  if (selectedSpaces.value.length === 0) {
    return null;
  }
  return spacesSvc.selectSingle(selectedSpaces.value[0].uid);
});
const showEditModal = ref(false);
const handleRowClick = (row) => ({
  on: {
    click() {
      showEditModal.value = true;
      selectedSpaces.value = [row];
    },
  },
});

const contextMenuRef = ref(null);
const handleContextMenu = (event, row) => {
  event.preventDefault();
  if (selectedIds.value.find((id) => id === row.index) === undefined) {
    selectedIds.value = [row.index];
  }
  selectedSpaces.value = selectedIds.value.map((id) => rows.value.find(({ index }) => index === id)).filter((e) => e !== undefined);
  contextMenuRef.value.show(event);
};

const getExpand = (item) => {
  if (!expandSvc.has(item)) {
    return true;
  }
  return expandSvc.getExpand(item).value;
};
const toggleExpand = (item) => {
  const collapseTree = (root) => {
    expandSvc.collapseItem(root);
    if (root.children !== undefined) {
      root.children.forEach((node) => {
        collapseTree(node);
      });
    }
  };
  if (getExpand(item)) {
    collapseTree(item);
    return;
  }
  expandSvc.toggleExpand(item);
};

const formatDate = (date) => DateTime.fromISO(date).toLocaleString(DateTime.DATE_SHORT);
const getIconColor = (status) => {
  switch (status) {
    case SPACE_STATUS_ACTIVE:
      return colors.green.base;
    case SPACE_STATUS_ARCHIVED:
      return colors.yellow.base;
    default:
      return '';
  }
};

const showHoverMenuActions = ref(false);
const draggableRef = ref(null);
const draggableClass = 'm-table-row';
const ghostItemStyle = { display: 'inline-table', verticalAlign: 'middle' };

const showHoverMenu = computed(() => !dragging.value && selectedIds.value.length < 2);
const canDrag = computed(() => [SPACE_STATUS_ACTIVE, SPACE_STATUS_ALL].includes(props.filter));

const activeElementId = ref(null);
const setActiveElement = (id) => {
  activeElementId.value = id;
};
const activeElement = computed(() => {
  const el = rows.value.find((el) => identifier.getId(el) === activeElementId.value);
  return el !== undefined ? el : null;
});
const handleHoverMenuDragClick = (event) => {
  showHoverMenuActions.value = true;
  handleContextMenu(event, activeElement.value);
};
const hideContextMenu = () => {
  showHoverMenuActions.value = false;
};

const handleHoverMenuDragDrag = (event) => {
  const el = document.querySelector(`.${draggableClass}[data-id="${activeElementId.value}"]`);
  if (el === null) {
    throw new Error('element not found');
  }
  draggableRef.value.handleMouseDown(el)(event);
};

const dragAndDropElementReader = {
  getId: (o) => o.index,
  getDepth: (o) => o.level,
  isFirstSibling: (o) => o.firstSibling,
  isLastSibling: (o) => o.lastSibling,
  hasChildren: (o) => o.children.length > 0,
  isExpanded: (o) => getExpand(o),
};
const opElementReader = {
  getId: (o) => o.uid,
  getDragId: (o) => o.index,
  getDepth: (o) => o.level,
  getChildrenMaxDepth: (o) => o.childrenMaxLevel,
};
const spaceActionExecutor = useSpaceOperationExecutor(rows, opElementReader);
const spaceActionValidator = useSpaceOperationValidator(rows, opElementReader);
const dragAndDrop = useCascadeDragAndDrop(
  rows,
  dragAndDropElementReader,
  spaceActionExecutor,
  spaceActionValidator,
);
const setDragOverTop = dragAndDrop.setDragOverTop;
const setDragOverBottom = dragAndDrop.setDragOverBottom;
const setDragOver = dragAndDrop.setDragOver;
const resetDragging = dragAndDrop.resetDragging;
const dragItem = dragAndDrop.dragItem;
const dragOver = dragAndDrop.dragOver;
const dragOverTop = dragAndDrop.dragOverTop;
const dragOverBottom = dragAndDrop.dragOverBottom;
const dragging = computed(() => dragItem.value !== null);
const dragDepth = dragAndDrop.dragDepth;
const initDrag = (itemId) => {
  const position = draggableRef.value.$el.getBoundingClientRect();
  dragAndDrop.setDragItem(itemId, position.left);
};

const handleDrop = dragAndDrop.dropItem;

</script>

<style scoped lang="scss">
  .space-list-table {
    ._dragzone-top {
      top: -2px;
    }

    ._dragzone-bottom {
      bottom: -2px;
    }

    ._table {
      :deep(._cell) {
        max-width: 50rem;
      }
    }

    ._name {
      display: flex;
      justify-content: space-between;
      width: 100%;

      ._title {
        width: fill-available;
      }

      ._tag {
        display: flex;
        align-items: center;
      }
    }

    ._status {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    ._hover-menu-inner {
      display: flex;
      position: absolute;
      right: 0.4rem;
      top: .6rem;
      max-width: fit-content;
    }
  }
</style>

<style lang="scss">
// This is outside of the scoped style bc it operates on the #drag
#drag {
  ._ghost-item {
    td {
      height: 4rem;
      padding: 0.6rem 1.2rem;
    }

    .hierarchical-table-cell {
      ._name {
        display: flex;
        justify-content: space-between;
        width: 100%;

        ._title {
          width: fill-available;
        }

        ._tag {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
</style>
