<template>
  <div :class="['participation-chart']">
    <base-chart :options="chartOptions" />
  </div>
</template>

<script>

import BaseChart from '@/components/dashboard/BaseChart.vue';
import { DASH, DATETIME, DOT, LINE } from '@/lib/constants';
import { DateTime } from 'luxon';

export default {
  name: 'ParticipationChart',
  props: {
    chartData: {
      type: Array,
      default: () => [],
    },
  },
  components: { BaseChart },
  computed: {
    data() {
      const data = this.chartData.map((e) => ({
        x: e.x,
        y: e.y,
        participation: e.participation,
      }));
      data.sort((a, b) => (a.x <= b.x ? -1 : 1));
      return data;
    },
    chartOptions() {
      return {
        chart: {
          height: 150,
          marginBottom: 22,
          marginRight: 8,
        },
        series: [
          {
            type: LINE,
            data: this.data,
            clip: false,
            marker: {
              enabled: true,
              fillColor: '#FFFFFF',
              lineWidth: 2,
              lineColor: null, // inherit from series
              symbol: 'circle',
            },
            connectNulls: true,
            color: this.$colors.grey.darken3,
            dashStyle: DASH,
            fillColor: {
              linearGradient: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 1,
              },
              stops: [[0, 'transparent'], [1, 'transparent']],
            },
          },
        ],
        xAxis: {
          type: DATETIME,
          minTickInterval: 28 * 24 * 3600 * 1000,
          minRange: 28 * 24 * 3600 * 1000,
          min: null,
        },
        yAxis: {
          gridLineDashStyle: DOT,
          title: { text: '' },
          labels: {
            formatter() {
              return `${this.value} %`;
            },
          },
          max: 100,
          min: 0,
          endOnTick: false,
          startOnTick: false,
          tickAmount: 5,
        },
        tooltip: {
          shared: true,
          headerFormat: '',
          pointFormatter: this.pointFormatter((key) => this.$t(key)),
        },
      };
    },
  },
  methods: {
    pointFormatter($t) {
      /* eslint-disable */
      return function () {
        return `
<span style="font-size:10px">${DateTime.fromMillis(this.x).toLocaleString(DateTime.DATETIME_MED)}</span></br><br/>
${$t('participationChart.participation')}: <span style="font-weight: 700">${this.y}% (${this.participation})</span> </br><br/>
`;
      };
      /* eslint-enable */
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .goal-progress-chart {
    &.-loading {
      opacity: .5;
    }
  }
</style>
