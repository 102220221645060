<template>
  <div class="collapsable-nav-items">
    <m-tooltip
      placement="right"
      :disabled="item.disabled || !isTitleTruncated"
      :mouse-enter-delay=".5"
    >
      <template #title>
        {{ localTitle(item.title) }}
      </template>
      <m-link
        :id="item.id"
        :class="['_nav-item', expanded ? '-expanded' : '', item.active ? '-item-active' : '', indentationLevel === 0 ? '-root' : '', hasChildren === false ? '-leaf' : '', `-hover-color-${hoverColor}`, ]"
        :style="itemStyle"
        :to="item.to"
        :disabled="item.disabled"
        @click="item.onClick"
      >
        <div class="_expand">
          <m-btn
            :disabled="!hasChildren"
            :icon="expandIcon"
            fab
            xs
            hide-border
            super-light
            @click.stop.prevent="toggleExpand"
          />
        </div>
        <nav-item-inner
          v-model:is-title-truncated="isTitleTruncated"
          class="_inner"
          :title="item.title"
          :active="item.active"
          :icon="item.icon"
          :icon-size="iconSize"
          :show-avatar="item.showAvatar"
          :avatar-shape="item.avatarShape"
          :avatar-color="item.avatarColor"
          :show-expand-icon="hasChildren"
          :is-leaf="!hasChildren"
          :disabled="item.disabled"
          :tag="item.tag"
          :plan-tag="item.planTag"
          hover-color="transparent"
        >
          <template
            v-if="!!$slots.actions"
            #actions
          >
            <slot name="actions" />
          </template>
        </nav-item-inner>
      </m-link>
    </m-tooltip>
    <div
      v-show="expanded"
      class="_content"
    >
      <template
        v-for="(child, j) in item.children"
        :key="j"
      >
        <collapsable-nav-items
          :item="child"
          :icon-size="iconSize"
          :indentation-level="indentationLevel + 1"
          :hover-color="hoverColor"
          :expand-svc="expandSvc"
        />
      </template>
    </div>
  </div>
</template>

<script>
import NavItemInner from '@/components/navigation/NavItemInner.vue';

export default {
  name: 'CollapsableNavItems',
  props: {
    item: {
      type: Object,
      required: true,
    },
    iconSize: {
      type: String,
      default: '14',
    },
    indentationLevel: {
      type: Number,
      required: true,
    },
    hoverColor: {
      type: String,
      default: 'regular',
    },
    expandSvc: {
      type: Object,
      required: true,
    },
  },
  components: { NavItemInner },
  data() {
    return { isTitleTruncated: false };
  },
  computed: {
    itemStyle() {
      return { paddingLeft: `${this.indentationLevel * 2 + 1}rem` };
    },
    hasChildren() {
      return typeof this.item.children !== 'undefined' && this.item.children.length > 0;
    },
    expanded() {
      return this.expandSvc.getExpand(this.item).value;
    },
    expandIcon() {
      if (!this.hasChildren) {
        return 'dot';
      }

      if (this.expanded) {
        return 'down';
      }

      return 'right';
    },
    active() {
      return this.item.active;
    },
  },
  methods: {
    localTitle(title) {
      if (title === '') {
        return this.$t('general.untitled');
      }

      return title;
    },
    toggleExpand() {
      const collapseTree = (root) => {
        this.expandSvc.collapseItem(root);
        if (root.children !== undefined) {
          root.children.forEach((node) => {
            collapseTree(node);
          });
        }
      };
      if (this.expanded) {
        collapseTree(this.item);
        return;
      }
      this.expandSvc.toggleExpand(this.item);
    },
    getKey(index, subIndex = -1) {
      if (subIndex === -1) {
        return index;
      }
      return ((index + 1) * 1000) + subIndex;
    },
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  .collapsable-nav-items {
    ._nav-item {
      display: flex;
      align-items: center;
      border-radius: 0;
      padding: 0 .6rem 0 1.4rem;

      ._expand {
        width: 2rem;
        margin-right: .4rem;
      }

      ._inner {
        flex: 1 1 auto;
      }

      &:hover {
        background-color: $hover-color;
      }

      &.-hover-color-regular {
        &:hover {
          background-color: $hover-color;
        }

        &.-item-active {
          &.-leaf {
            &:not(:hover) {
              background-color: $hover-color;
            }
          }
        }
      }

      &.-hover-color-beige {
        &:hover {
          background-color: $sidebar-hover-color;
        }

        &.-item-active {
          &.-leaf {
            &:not(:hover) {
              background-color: $sidebar-hover-color;
            }
          }
        }
      }
    }
  }
</style>
