import { depth, idFromIndex } from '@/lib/sort-operation';

export const realignParentByIndex = (goal, index, newParentId, multiAlignGoals) => {
  const d = depth(index);
  const parentId = idFromIndex(index, d - 1);

  return realignParent(goal, parentId, newParentId, multiAlignGoals);
};

export const realignParent = (goal, currentParentId, newParentId, multiAlignGoals) => {
  if (multiAlignGoals === false || goal.parents.length === 0) {
    return [{ uid: newParentId }].filter((p) => p.uid !== null);
  }

  if (newParentId === null) {
    return goal.parents.filter((p) => p.uid !== currentParentId).map((p) => ({ uid: p.uid }));
  }

  const uids = goal.parents.map((p) => p.uid);
  const parentIdIndex = uids.indexOf(currentParentId);
  if (parentIdIndex !== -1) {
    uids[parentIdIndex] = newParentId;
    return uids.map((uid) => ({ uid }));
  }
  return [...uids, newParentId].map((uid) => ({ uid }));
};
