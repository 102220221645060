<template>
  <div
    class="_form"
  >
    <div class="_icon">
      <icon-picker
        :icon="buildIcon(emoji)"
        show-placeholder
        size="3.2rem"
        bordered
        class="_emoji-picker"
        @change="setEmoji"
      />
    </div>
    <m-form-item
      :colon="false"
      :label="$t('createFormTemplateForm.nameLabel')"
      class="_name"
    >
      <m-text-field
        v-model:value="title"
        auto-focus
        @keydown.enter="create"
      />
    </m-form-item>
  </div>
</template>

<script>
import IconPicker from '@/components/IconPicker.vue';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import { buildIcon } from 'shared/lib/icon';
import { mapActions } from 'vuex';

export default {
  name: 'CreateFormTemplateForm',
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  emits: ['created'],
  components: { IconPicker },
  setup() {
    const { loggedInUser } = useLoggedInUser();
    const { loggedInUserAccount } = useLoggedInUserAccount();
    return { loggedInUser, loggedInUserAccount };
  },
  data() {
    return {
      title: '',
      loading: false,
      emoji: null,
    };
  },
  methods: {
    buildIcon,
    ...mapActions([
      'createFormTemplate',
    ]),
    setEmoji(emoji) {
      this.emoji = emoji.value;
    },
    create() {
      this.loading = true;
      this.createFormTemplate({
        formTemplate: {
          title: this.title,
          creator: this.loggedInUser,
          account: { uid: this.loggedInUserAccount.uid },
          type: this.type,
          icon: this.emoji,
        },
      })
        .then((response) => {
          this.loading = false;
          if (response.status !== 201) {
            this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
            return;
          }
          this.$emit('created', { formTemplate: response.data });
        });
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  ._form {
    display: flex;

    ._icon {
      margin-top: 2.6rem;
      margin-right: 1.2rem;
    }

    ._name {
      flex: 1 1 auto;
    }
  }
</style>
