<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.53887 17.3814L2.99291 19.9254L4.06831 21L4.0686 20.9989L21.0063 4.07415L19.9313 3L17.385 5.54433C17.3192 5.4895 17.2523 5.43548 17.1843 5.38229C13.5728 2.55516 8.3671 3.14694 5.48113 6.6884C5.46753 6.70489 5.45737 6.72392 5.45125 6.74439C5.44512 6.76486 5.44316 6.78634 5.44548 6.80758C5.44779 6.82882 5.45434 6.84938 5.46473 6.86805C5.47512 6.88672 5.48914 6.90312 5.50598 6.91629L6.4518 7.6567C6.52116 7.711 6.61955 7.69906 6.67423 7.63256C7.22968 6.95655 7.89517 6.39975 8.65433 5.9798C9.43338 5.54747 10.2775 5.27542 11.1672 5.16605C12.0568 5.05667 12.9432 5.12015 13.8031 5.35359C14.6926 5.59486 15.5143 6.00683 16.2473 6.58065C16.2661 6.59537 16.2848 6.61017 16.3035 6.62506L6.62 16.3011C6.38956 16.0134 6.18218 15.707 6.00024 15.3845C5.5559 14.5969 5.27204 13.7291 5.16503 12.8313C5.13124 12.5532 5.1143 12.2731 5.11458 11.9937L6.64399 11.9962C6.67414 11.9964 6.70374 11.9881 6.72937 11.9722C6.755 11.9563 6.77563 11.9336 6.78888 11.9065C6.80213 11.8794 6.80746 11.8492 6.80427 11.8192C6.80108 11.7892 6.78949 11.7608 6.77083 11.7371L4.53218 8.89163C4.46818 8.81103 4.34401 8.81041 4.28103 8.89075L2.03471 11.7332C1.95197 11.8387 2.02642 11.9936 2.16026 11.9916L3.59395 11.9929C3.5926 13.9269 4.25566 15.8444 5.53887 17.3814Z"
      fill="currentColor"
    />
    <path
      d="M19.3375 7.89078L18.2096 9.01781C18.5345 9.69459 18.7459 10.4211 18.8346 11.1683C18.8684 11.4464 18.8854 11.7266 18.8851 12.006L17.3557 12.0034C17.3255 12.0032 17.2959 12.0116 17.2703 12.0274C17.2447 12.0433 17.224 12.0661 17.2108 12.0932C17.1975 12.1202 17.1922 12.1505 17.1954 12.1804C17.1986 12.2104 17.2102 12.2389 17.2288 12.2625L19.4675 15.108C19.5315 15.1886 19.6556 15.1892 19.7186 15.1089L21.9653 12.2693C22.048 12.1638 21.9736 12.0089 21.8397 12.0109L20.406 12.0095C20.4078 10.5781 20.0455 9.15613 19.3375 7.89078Z"
      fill="currentColor"
    />
    <path
      d="M9.01424 18.2062L7.88686 19.3327C11.3984 21.3073 15.9095 20.5159 18.5201 17.3125C18.5337 17.296 18.5439 17.277 18.55 17.2565C18.5561 17.236 18.5581 17.2145 18.5558 17.1933C18.5534 17.1721 18.5469 17.1515 18.5365 17.1328C18.5261 17.1142 18.5121 17.0978 18.4953 17.0846L17.5494 16.3442C17.4801 16.2899 17.3817 16.3018 17.327 16.3683C16.7716 17.0443 16.1061 17.6011 15.3469 18.0211C14.5679 18.4534 13.7225 18.727 12.8341 18.8348C11.9358 18.9457 11.0244 18.8778 10.1525 18.635C9.76014 18.5258 9.37931 18.3821 9.01424 18.2062Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default { name: 'NonAutomatic' };
</script>

<style scoped lang="scss" type="text/scss">

</style>
