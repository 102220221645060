<template>
  <m-card class="template-question-item">
    <div class="_content">
      <div class="_left">
        <div class="_icon">
          <form-field-icon
            :type="item.type"
            :show-index="false"
          />
        </div>
        <div class="_text">
          <div :class="['_title', hasTranslation ? '' : '-no-translation']">
            {{ title }}
          </div>
          <div
            v-if="description !== ''"
            class="_description"
          >
            {{ description }}
          </div>
        </div>
      </div>
      <div
        v-if="!disabled"
        class="_right"
      >
        <m-btn
          :loading="loading"
          @click="addQuestion"
        >
          {{ $t('general.add') }}
        </m-btn>
      </div>
    </div>
  </m-card>
</template>

<script>
import FormFieldIcon from '@/components/FormFieldIcon.vue';

export default {
  name: 'TemplateQuestionItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
    addQuestionMethod: {
      type: Function,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: { FormFieldIcon },
  data() {
    return { loading: false };
  },
  computed: {
    hasTranslation() {
      return typeof this.item.title[this.lang] !== 'undefined';
    },
    title() {
      if (this.hasTranslation) {
        return this.item.title[this.lang];
      }
      return this.$t('formEditor.translationNotAvailable');
    },
    description() {
      if (typeof this.item.description !== 'undefined' && typeof this.item.description[this.lang] !== 'undefined') {
        return this.item.description[this.lang];
      }
      return '';
    },
  },
  methods: {
    addQuestion() {
      this.loading = true;
      this.addQuestionMethod(this.item).then(() => {
        this.loading = false;
      });
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .template-question-item {
    ._content {
      display: flex;
      align-items: center;

      ._left {
        display: flex;
        margin-right: 1.6rem;

        ._icon {
          margin-right: 1.6rem;
        }

        ._text {
          ._title {
            margin: 0;

            &.-no-translation {
              font-style: italic;
            }
          }

          ._description {
            color: $font-color-secondary;
          }
        }
      }

      ._right {
        margin-left: auto;
      }
    }
  }
</style>
