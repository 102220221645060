<template>
  <m-content
    :padding-xxs="!noPadding"
    :class="['goal-progress', showIndicator ? '-show-indicator' : '', clickable ? '-clickable': '']"
    :style="progressBarWidth === '100%' ? { flexGrow: '1' } : { flexGrow: '0' }"
    @click="emitClick($event)"
    @mousedown="emitClick($event)"
  >
    <div class="_wrapper">
      <div
        v-if="dataSourceQuery !== null && showSmallDataSourceIndicator"
        class="_indicator-small"
      >
        <m-dropdown
          v-model:value="showIndicator"
          :title="$t('goalProgress.dataConnectionTitle')"
        >
          <m-btn
            class="_icon"
            hide-border
            xs
            fab
            :icon="dataSourceIcon"
            @click.stop="showIndicator = true"
          />
          <template #overlay>
            <m-card
              no-padding
              list
              class="_overlay"
            >
              <data-source-summary
                :goal="goal"
              />
            </m-card>
          </template>
        </m-dropdown>
      </div>
      <div
        ref="goalProgress"
        :class="['_container', hideDetails ? '-hide-details' : '']"
        :style="resolveStyles(mStyle)"
      >
        <div
          class="_inner"
          :style="progressBarWidth === '100%' ? { flexGrow: '1' } : { flexGrow: '0' }"
        >
          <div
            v-if="!hideDetails"
            class="_top"
          >
            <div
              ref="number"
              class="_number"
              :style="numberStyle"
            >
              {{ current }} {{ metric }}
            </div>
          </div>
          <div
            class="_progress"
            :style="{ width: progressBarWidth }"
          >
            <div
              class="_bar"
              :style="style"
            />
          </div>
          <div
            v-if="!hideDetails"
            class="_bottom"
          >
            <div class="_left">
              {{ start }} {{ progressMetric }}
            </div>
            <div class="_right">
              {{ end }} {{ progressMetric }}
            </div>
          </div>
        </div>
        <div
          v-if="hideDetails"
          class="_behind"
        >
          {{ current }} {{ metric }}
        </div>
      </div>
    </div>
  </m-content>
</template>

<script>
import DataSourceSummary from '@/components/datasource/DataSourceSummary.vue';
import camelCase from 'lodash-es/camelCase';
import useGoalDatasource from '@/composables/goal/goal-datasource';
import useGoalSettings from '@/composables/logged-in-user-account/goal-settings';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import { current, formatNumber } from '@/lib/goal/progress';
import { goalProgressMeasurement, propertyType } from 'shared/constants.json';
import { mStyleProps, resolveStyles } from 'shared/lib/m-style-props';
import { toRef } from 'vue';
import { translatePropertyOptionColor } from '@/lib/goal/status';

export default {
  name: 'GoalProgress',
  props: {
    ...mStyleProps,
    goal: {
      type: Object,
      required: true,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    hideStatus: {
      type: Boolean,
      default: false,
    },
    showSmallDataSourceIndicator: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: true,
    },
    progressBarWidth: {
      type: String,
      default: '100%',
    },
  },
  emits: ['click'],
  components: { DataSourceSummary },
  setup(props) {
    const goal = toRef(props, 'goal');
    const { userLang } = useLoggedInUser();
    const { query: dataSourceQuery, icon: dataSourceIcon } = useGoalDatasource(goal);
    const { goalSettings } = useGoalSettings();
    return { dataSourceQuery, dataSourceIcon, goalSettings, userLang };
  },
  data() {
    return {
      goalProgressWidth: 0,
      numberWidth: 0,
      showIndicator: false,
      camelCase,
    };
  },
  computed: {
    current() {
      const c = current(this.goal, this.goal.cachedCurrent);

      if ([goalProgressMeasurement.continuous, goalProgressMeasurement.threshold].includes(this.goal.progressMeasurement)) {
        return formatNumber(c, this.userLang);
      }

      return this.percent;
    },
    progressMetric() {
      switch (this.goal.progressMeasurement) {
        case goalProgressMeasurement.continuous:
          return this.goal.metric;
        default:
          return '%';
      }
    },
    metric() {
      switch (this.goal.progressMeasurement) {
        case goalProgressMeasurement.continuous:
        case goalProgressMeasurement.threshold:
          return this.goal.metric;
        default:
          return '%';
      }
    },
    percent() {
      return Math.round(Math.min(100, this.goal.cachedCalculatedCurrent));
    },
    style() {
      return {
        width: `${this.percent}%`,
        backgroundColor: this.color,
      };
    },
    numberStyle() {
      if ((this.percent / 100) * this.goalProgressWidth <= this.numberWidth) {
        return {
          left: '0',
          color: this.color,
        };
      }
      return {
        right: `${100 - this.percent}%`,
        color: this.color,
      };
    },
    color() {
      const propertyValue = this.goal.properties.find((pv) => pv.property.type === propertyType.status);
      if (propertyValue.selectedOptions.length === 0) {
        return this.$colors.grey.base;
      }

      return translatePropertyOptionColor(propertyValue.selectedOptions[0]);
    },
    start() {
      if (this.goal.progressMeasurement === goalProgressMeasurement.continuous) {
        return formatNumber(this.goal.start, this.userLang);
      }

      return 0;
    },
    end() {
      if (this.goal.progressMeasurement === goalProgressMeasurement.continuous) {
        return formatNumber(this.goal.end, this.userLang);
      }

      return 100;
    },
  },
  methods: {
    resolveStyles,
    emitClick(event) {
      if (this.clickable) {
        this.$emit('click', event);
      }
    },
  },
  mounted() {
    this.goalProgressWidth = this.$refs.goalProgress.clientWidth;
    if (!this.hideDetails) {
      this.numberWidth = this.$refs.number.clientWidth;
    }
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  ._overlay {
    width: 30rem;
  }

  .goal-progress {
    flex: 0 0 auto;

    &.-clickable {
      cursor: pointer;
    }

    ._wrapper {
      position: relative;
      display: flex;
      align-items: center;

      ._indicator-small {
        margin-right: .4rem;
      }

      ._container {
        flex: 1 1 auto;

        &.-no-metric {
          min-width: 0;
        }

        ._inner {
          flex: 0 0 auto;
          line-height: 1;

          &.-no-metric {
            display: flex;
            justify-content: flex-end;
            text-align: right;
          }

          ._top {
            position: relative;
            width: 100%;
            height: 1.6rem;
            margin-bottom: .2rem;

            ._number {
              position: absolute;
              top: 0;
              font-weight: $font-weight-semibold;
              white-space: nowrap;
            }
          }

          ._progress {
            width: 100%;
            height: 1.2rem;
            margin-bottom: .8rem;
            overflow: hidden;
            background-color: map_get($grey, 'lighten-3');
            border-radius: .6rem;

            ._bar {
              height: inherit;
              background-color: $primary-color;
              transition: width 1s ease-out;
            }
          }

          ._bottom {
            display: flex;

            ._right {
              margin-left: auto;
              text-align: right;
            }
          }
        }

        &.-hide-details {
          display: flex;
          align-items: center;

          ._inner {
            ._progress {
              margin-bottom: 0;
            }
          }
        }

        ._behind {
          display: flex;
          flex: 0 0 auto;
          align-items: center;
          margin-left: .4rem;
          text-align: left;
        }
      }
    }

    &.-show-indicator {
      ._wrapper {
        position: relative;
      }
    }
  }
</style>
