<template>
  <div class="reaction-list">
    <reaction-item
      v-for="reaction in groupedReactions"
      :key="reaction.emoji"
      :emoji="reaction.emoji"
      :users="reaction.users"
      class="_item"
      :read-only="readOnly"
      @add-or-remove="addOrRemove"
    />
    <m-dropdown
      v-if="!readOnly && reactions.length > 0"
      v-model:value="showMenu"
      :title="$t('reactionList.addEmoji')"
    >
      <m-btn
        bordered
        small
        round
        class="_item"
        @click="showMenu = true"
      >
        <m-icon
          type="add-reaction"
          size="16"
        />
      </m-btn>
      <template #overlay>
        <icon-picker-card
          hide-actions
          @change="(icon) => addOrRemove(icon.value)"
        />
      </template>
    </m-dropdown>
  </div>
</template>

<script>
import IconPickerCard from '@/components/IconPickerCard.vue';
import ReactionItem from '@/components/reaction/ReactionItem.vue';
import useReactions from '@/composables/reactions/reactions';
import { toRef } from 'vue';

export default {
  name: 'ReactionList',
  props: {
    update: {
      type: Object,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ReactionItem,
    IconPickerCard,
  },
  setup(props) {
    const { reactions, groupedReactions, toggleReaction } = useReactions(toRef(props, 'update'), props.readOnly);
    return {
      reactions,
      groupedReactions,
      toggleReaction,
    };
  },
  data() {
    return { showMenu: false };
  },
  methods: {
    addOrRemove(value) {
      this.showMenu = false;
      this.toggleReaction(value);
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .reaction-list {
    display: flex;
    flex-wrap: wrap;

    ._add-reaction-btn {
      ._icon {
        margin-right: .4rem;
      }
    }

    ._item {
      margin-right: .8rem;
      margin-bottom: .4rem;
    }
  }
</style>
