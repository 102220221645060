<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="10"
      cy="10"
      r="3"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default { name: 'Dot' };
</script>

<style scoped lang="scss" type="text/scss">

</style>
