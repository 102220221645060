<template>
  <div class="table-header">
    <m-draggable
      draggable-item-class="table-header-cell.-sortable"
      dragover-item-class="table-header-cell"
      scroll-container="table-header"
      can-drag-over-left
      can-drag-over-right
      class="_cells"
      ghost-item-movement-axes="x"
      :recreate-key="props.map(p => p.key)"
      :drag-between-height="24"
      :disabled="readOnly"
      @set-drag-item="setDragItem"
      @over-right="setOverRight"
      @over-left="setOverLeft"
      @drag-drop="handleDrop"
      @cancel="cancelDragging"
    >
      <table-header-cell
        v-for="item in props"
        :key="item.key"
        :item="item"
        :sortable="item.sortable"
        :draggable="item.draggable && !dragging"
        :read-only="readOnly"
        :dragging-over-left="draggingOverLeft.includes(item.key)"
        :dragging-over-right="draggingOverRight.includes(item.key)"
        @width-changed="widthChanged"
        @dragstart="$emit('cell-dragstart', $event)"
        @drag="$emit('cell-drag', $event)"
        @dragend="$emit('cell-dragend', $event)"
      />
    </m-draggable>

    <div
      :style="fillerColumnStyle"
      class="_empty-col"
    >
      <m-btn
        icon="ellipsis"
        hide-border
        large
        fab
        light
        align-left
        :button-style="{ borderRadius: 0, width: '100%', 'padding-left': '1rem' }"
        @click="showMenu"
      />
    </div>
    <div
      v-if="hasEmptyRow"
      class="_empty-row"
    />
  </div>
</template>

<script>
import TableHeaderCell from '@/components/table/TableHeaderCell.vue';
import useSort from '@/composables/draggable/sort';
import useViewParamsProps from '@/composables/view-params/view-params-props';
import useViewParamsPropsOperationValidator from '@/composables/view-params/drag-validator';
import { VIEWS_SERVICE } from '@/lib/constants';
import { equalP } from 'shared/lib/array/array';
import { inject, toRef } from 'vue';

export default {
  name: 'TableHeader',
  props: {
    props: {
      type: Array,
      required: true,
    },
    hasEmptyRow: {
      type: Boolean,
      default: false,
    },
    fillerColumnWidth: {
      type: Number,
      default: 40,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const actionValidator = useViewParamsPropsOperationValidator(toRef(props, 'props'));

    const getOperationBasedOnPosition = (dragItemIndex, anchorIndex, position) => {
      const anchorId = anchorIndex;
      let toSortIds = [];

      switch (position) {
        case 'right':
        case 'left':
          toSortIds = dragItemIndex;
          break;
        default:
          throw new Error('position unknown. Must be in top, right, left or bottom.');
      }

      const isSort = ['right', 'left'].includes(position);
      const sortAfter = position !== 'left';

      return {
        isRealign: false,
        isSort,
        anchorId,
        toSortIds,
        sortAfter,
      };
    };

    const validateDragAction = (keys, position) => {
      const filtered = keys.filter((k) => k !== dragItemId.value);
      if (filtered.length === 0) {
        return [];
      }

      const { isValid } = actionValidator.isValidDrag(getOperationBasedOnPosition([dragItemId.value], keys[0], position));
      if (!isValid) {
        return [];
      }
      return keys;
    };
    const {
      setDragItem,
      setOverLeft,
      setOverRight,
      setOverBottom,
      setOverTop,
      draggingOverRight,
      draggingOverLeft,
      draggingOverTop,
      draggingOverBottom,
      dragItemId,
      dropItem,
      cancelDragging,
      dragging,
    } = useSort('key', validateDragAction);
    const viewsService = inject(VIEWS_SERVICE);
    const { props: viewProps } = useViewParamsProps(viewsService.currentView);
    return {
      viewsService,
      viewProps,
      setDragItem,
      setOverLeft,
      setOverRight,
      setOverBottom,
      setOverTop,
      draggingOverRight,
      draggingOverLeft,
      draggingOverTop,
      draggingOverBottom,
      dragItemId,
      dropItem,
      cancelDragging,
      dragging,
    };
  },
  components: { TableHeaderCell },
  emits: ['cell-dragstart', 'cell-drag', 'cell-dragend', 'show-menu', 'prop-order-changed'],
  computed: {
    fillerColumnStyle() {
      return { flex: `0 0 ${this.fillerColumnWidth}px`, width: `${this.fillerColumnWidth}px` };
    },
  },
  methods: {
    handleDrop() {
      const oldPropOrder = this.viewProps.map(({ key }) => ({ key }));
      const newPropOrder = this.dropItem(oldPropOrder);
      if (equalP({ a: newPropOrder, b: oldPropOrder, key: 'key', order: true })) {
        return;
      }
      this.updateViewParams(newPropOrder, 'propOrder');
    },
    updateViewParams(value, key) {
      this.viewsService.setParams(
        this.viewsService.currentView.value,
        {
          ...this.viewsService.currentView.value.params,
          [key]: value,
        },
      );
    },
    widthChanged(prop) {
      const updatedProps = this.viewsService.currentView.value.params.props.map((p) => {
        if (p.key !== prop.key) {
          return p;
        }

        return prop;
      });
      this.updateViewParams(updatedProps, 'props');
    },
    showMenu() {
      this.$emit('show-menu');
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .table-header {
    display: inline-flex;
    background-color: white;
    border-bottom: 1px solid $border-color;
    border-top: 1px solid $border-color;

    ._cells {
      display: inline-flex;
    }

    ._empty-col {
      width: 100%;
    }

    .btn {
      width: 100%;
      justify-items: left;
    }

    ._empty-row {
      flex: 1 0 3.2rem;
    }
  }

</style>
