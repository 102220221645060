<template>
  <m-tooltip
    :disabled="!showTooltip"
    :placement="tooltipPlacement"
    class="_prop"
  >
    <slot />
    <template #title>
      {{ tooltip }}
    </template>
  </m-tooltip>
</template>

<script>
export default {
  name: 'ItemWrapper',
  props: {
    tooltip: {
      type: String,
      default: '',
    },
    showTooltip: {
      type: Boolean,
      default: false,
    },
    tooltipPlacement: {
      type: String,
      default: 'top',
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
</style>
