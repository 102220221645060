import { ONE_TO_ONE } from 'shared/api/query/constants';
import { identity } from 'shared/api/query/identity';
import {
  mSTeamsConfiguration,
  user,
} from 'shared/api/query/configs.json';
import { mSTeamsConfigurationChildren } from '@/api/query/nebula/ms-teams-configuration';
import { reverseEdge } from 'shared/api/query/edges';
import { userEdges } from '@/api/query/nebula/user';

export const loggedInUserEdges = [
  ...userEdges,
  { alias: 'sessionCount', attr: '~session.user', isCount: true },
  { attr: user.edges.personalInfosCompleted, default: null },
  { attr: user.edges.productTourCompleted, default: null },
  { attr: user.edges.productTourPlanningListCompleted, default: null },
  { attr: user.edges.productSurveyStatus, default: null },
  { attr: user.edges.productTourPlanningDetailsCompleted, default: null },
  { attr: user.edges.watchedVideoTutorials, default: null },
  { attr: user.edges.checkedOutOKRResources, default: null },
  { attr: user.edges.readGettingStartedGuide, default: null },
  { attr: user.edges.welcomeModalShown, default: null },
  { attr: user.edges.checkinScheduleCreated, default: null },
  { attr: user.edges.onboardingMarkAllAsDone, default: null },
  { attr: user.edges.onboardingStepCheckinChecked, default: null },
  { attr: user.edges.goalTreeSlideShowShown, default: null },
  { attr: user.edges.gridPageSlideShowShown, default: null },
  { attr: user.edges.discoverMooncampCompleted, default: null },
  {
    attr: reverseEdge(mSTeamsConfiguration.edges.user),
    alias: 'msTeamsConfiguration',
    model: mSTeamsConfiguration.model,
    children: mSTeamsConfigurationChildren,
    assoc: ONE_TO_ONE,
    default: null,
  },
];

export const loggedInUserQuery = () => ({
  alias: 'user',
  ...identity(),
  children: loggedInUserEdges,
});
