<template>
  <m-dropdown
    v-model:value="visible"
    class="metric-form"
    :title="$t('goal.goalEditor.progressMeasurement')"
    block
    :placement="placement"
    :match-trigger-width="typeof $slots.trigger === 'undefined'"
    :relocate-key="goal.progressMeasurement"
    :disabled="disabled || readOnly"
  >
    <metric-form-trigger
      :goal="goal"
      :read-only="readOnly"
      :disabled="disabled"
      :m-style="mStyle"
      class="_activator"
    />
    <template #overlay>
      <m-card
        class="_metric-form-overlay"
        no-padding
      >
        <metric-form-trigger
          :goal="goal"
          read-only
        />
        <m-divider none />
        <div class="_form-item">
          <div class="_label -progress">
            {{ $t('goal.goalEditor.progressMeasurement') }}
          </div>
          <m-select
            :items="progressMeasurementOptions"
            :value="goal.progressMeasurement"
            :read-only="readOnly"
            match-trigger-width
            hide-border
            full-width
            has-tooltip
            :trigger-style="{ borderRadius: 0, fontWeight: 'normal' }"
            tooltip-placement="right"
            @change="changeProgressMeasurement"
          >
            <template #item-tooltip="item">
              <div
                class="_coloring-item"
              >
                {{ $t(`metricForm.tooltip.${camelCase(item.item.value)}`) }}
              </div>
            </template>
          </m-select>
        </div>
        <template
          v-if="![goalProgressMeasurement.binary, goalProgressMeasurement.none].includes(goal.progressMeasurement)"
        >
          <m-divider
            xs
          />
          <div class="_container">
            <div class="_inner">
              <div
                v-if="goalProgressMeasurement.continuous === goal.progressMeasurement"
                class="_row"
              >
                <div class="_range">
                  <div class="_form-item">
                    <div class="_label">
                      {{ $t('metricForm.start') }}
                    </div>
                    <m-input-number
                      :value="start"
                      class="_number-input _input-field"
                      :placeholder="$t('general.empty')"
                      :read-only="readOnly"
                      :formatter="formatter"
                      :parser="parser"
                      @input="updateStartEnd($event, end)"
                    />
                    <div
                      v-if="startError !== ''"
                      class="_error"
                    >
                      {{ $t(`metricForm.${startError}`) }}
                    </div>
                  </div>
                  <div class="_form-item">
                    <div class="_label">
                      {{ $t('metricForm.end') }}
                    </div>
                    <m-input-number
                      :value="end"
                      class="_number-input _input-field"
                      :placeholder="$t('general.empty')"
                      :read-only="readOnly"
                      :formatter="formatter"
                      :parser="parser"
                      @input="updateStartEnd(start, $event)"
                    />
                    <div
                      v-if="endError !== ''"
                      class="_error"
                    >
                      {{ $t(`metricForm.${endError}`) }}
                    </div>
                  </div>
                  <div class="_form-item">
                    <div class="_label">
                      {{ $t('metricForm.metric') }}
                    </div>
                    <m-text-field
                      class="_input-field"
                      :value="metric"
                      :max-length="metricMaxLen"
                      :read-only="readOnly"
                      @change="updateMetric"
                    />
                  </div>
                </div>
                <div
                  v-if="continuousError !== ''"
                  class="_error"
                >
                  {{ $t(`metricForm.${continuousError}`) }}
                </div>
                <div
                  v-if="metricError !== ''"
                  class="_error"
                >
                  {{ $t(`metricForm.${metricError}`, {length: metricMaxLen}) }}
                </div>
                <data-source
                  v-if="integrationsAllowed"
                  class="_data-source"
                  :goal="goal"
                  :data-source-id-to-edit="dataSourceIdToEdit"
                  :disabled="readOnly || goal.publishedAt === null"
                  :disabled-hint="goal.publishedAt === null ? $t('metricForm.dataSourceDisabledHint') : ''"
                />
              </div>
              <div
                v-if="goal.progressMeasurement === goalProgressMeasurement.threshold"
                class="_row"
              >
                <div class="_range">
                  <div class="_form-item">
                    <div class="_label">
                      {{ $t('metricForm.threshold') }}
                    </div>
                    <m-input-number
                      :value="threshold"
                      class="_number-input _input-field"
                      :placeholder="$t('general.empty')"
                      :read-only="readOnly"
                      :formatter="formatter"
                      :parser="parser"
                      @input="updateThreshold"
                    />
                  </div>
                  <div class="_form-item">
                    <div class="_label">
                      {{ $t('metricForm.metric') }}
                    </div>
                    <m-text-field
                      class="_input-field"
                      :value="metric"
                      :max-length="metricMaxLen"
                      :read-only="readOnly"
                      @change="updateMetric"
                    />
                  </div>
                  <div class="_form-item">
                    <div class="_label">
                      {{ $t('metricForm.coloring') }}
                    </div>
                    <m-select
                      class="_input-field-type"
                      :items="thresholdTargetAreaItems"
                      :value="goal.thresholdTargetArea"
                      :read-only="readOnly"
                      tooltip-placement="right"
                      has-tooltip
                      @change="updateThresholdTargetArea"
                    >
                      <template #item-tooltip="item">
                        <div
                          class="_coloring-item"
                        >
                          <div class="_img">
                            <svg
                              v-if="[goalThresholdTargetArea.below, goalThresholdTargetArea.belowOrEqual].includes(item.item.value)"
                              width="119"
                              height="53"
                              viewBox="0 0 119 53"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                x="0.47168"
                                y="0.154541"
                                width="118"
                                height="26"
                                fill="#FADBE2"
                              />
                              <rect
                                x="0.47168"
                                y="26.1545"
                                width="118"
                                height="26"
                                fill="#DEF6F0"
                              />
                              <line
                                x1="0.47168"
                                y1="26.137"
                                x2="118.472"
                                y2="26.137"
                                stroke="#AFAFAC"
                                :stroke-dasharray="goalThresholdTargetArea.below === item.item.value ? '2 2' : undefined"
                              />
                            </svg>
                            <svg
                              v-if="[goalThresholdTargetArea.above, goalThresholdTargetArea.aboveOrEqual].includes(item.item.value)"
                              width="119"
                              height="53"
                              viewBox="0 0 119 53"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                x="0.47168"
                                y="0.154541"
                                width="118"
                                height="26"
                                fill="#DEF6F0"
                              />
                              <rect
                                x="0.47168"
                                y="26.1545"
                                width="118"
                                height="26"
                                fill="#FADBE2"
                              />
                              <line
                                x1="0.47168"
                                y1="26.1191"
                                x2="118.472"
                                y2="26.1191"
                                stroke="#AFAFAC"
                                :stroke-dasharray="goalThresholdTargetArea.above === item.item.value ? '2 2' : undefined"
                              />
                            </svg>
                          </div>
                          <div class="_description">
                            <i18n-t keypath="goalThresholdTargetArea.description">
                              <template #placement>
                                <strong>
                                  {{ $t(`goalThresholdTargetArea.placement.${camelCase(item.item.value)}`) }}
                                </strong>
                              </template>
                            </i18n-t>
                          </div>
                        </div>
                      </template>
                    </m-select>
                  </div>
                </div>
                <div
                  v-if="thresholdError !== ''"
                  class="_error"
                >
                  {{ $t(`metricForm.${thresholdError}`) }}
                </div>
                <div
                  v-if="metricError !== ''"
                  class="_error"
                >
                  {{ $t(`metricForm.${metricError}`, {length: metricMaxLen}) }}
                </div>
                <data-source
                  v-if="integrationsAllowed"
                  class="_data-source"
                  :goal="goal"
                  :data-source-id-to-edit="dataSourceIdToEdit"
                  :disabled="readOnly"
                />
              </div>
              <div
                v-else-if="goal.progressMeasurement === goalProgressMeasurement.alignedItems"
                class="_checkbox"
              >
                <p class="_description">
                  {{ $t('metricForm.alignedItemsDescription') }}
                </p>
                <aligned-item
                  v-for="g in goalChildren"
                  :key="g.uid"
                  class="_checkbox-item"
                  :goal="g"
                  :parent="goal"
                  :weight="weight(g)"
                  :show-weights="showWeights"
                  :total-weight="totalWeight"
                  :is-checked="isChecked(g)"
                  :disabled="readOnly || g.progressMeasurement === goalProgressMeasurement.none"
                  @is-checked-change="updateMeasurementDependencies($event, g)"
                  @weight-change="updateWeight($event, g)"
                />
              </div>
            </div>
          </div>
        </template>
      </m-card>
    </template>
  </m-dropdown>
</template>

<script>
import AlignedItem from '@/components/goal/AlignedItem.vue';
import DataSource from '@/components/datasource/DataSource.vue';
import MetricFormTrigger from '@/components/goal/MetricFormTrigger.vue';
import useDebounce from '@/composables/debounce';
import useGoalTypeProperty from '@/composables/customize-page/goal-type-property';
import useGoals from '@/composables/goal/goals';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useProgressMeasurement from '@/composables/goal/progress-measurement';
import { camelCase } from 'lodash-es';
import { findInArray } from 'shared/lib/array/array';
import { goalProgressMeasurement, goalThresholdTargetArea, numberFormat } from 'shared/constants.json';
import { mStyleProps } from 'shared/lib/m-style-props';
import { numberFormatter, numberParser } from '@/lib/props/number';

export default {
  name: 'MetricForm',
  props: {
    ...mStyleProps,
    goal: {
      type: Object,
      required: true,
    },
    goalChildren: {
      type: Array,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placement: {
      type: String,
      default: 'onTopLeft',
    },
  },
  setup() {
    const { goalTypeOption } = useGoalTypeProperty();
    const { allowedProgressMeasurementOptions } = useProgressMeasurement();
    const goalsSvc = useGoals();
    const { userLang } = useLoggedInUser();
    const { loggedInUserAccount } = useLoggedInUserAccount();
    const { debounce } = useDebounce();
    return {
      allowedProgressMeasurementOptions,
      goalTypeOption,
      loggedInUserAccount,
      userLang,
      goalsSvc,
      debounce,
    };
  },
  components: { MetricFormTrigger, DataSource, AlignedItem },
  data() {
    return {
      visible: false,
      metric: this.goal.metric,
      metricMaxLen: 16,
      customMetric: '',
      customMetrics: [],
      goalProgressMeasurement,
      startError: '',
      endError: '',
      metricError: '',
      continuousError: '',
      thresholdError: '',
      dataSourceIdToEdit: -1,
      goalThresholdTargetArea,
      camelCase,
      start: this.goal.start,
      end: this.goal.end,
      threshold: this.goal.threshold,
    };
  },
  computed: {
    formatter() {
      return numberFormatter(numberFormat.number, this.userLang);
    },
    parser() {
      return numberParser(numberFormat.number, this.userLang);
    },
    thresholdTargetAreaItems() {
      return [
        goalThresholdTargetArea.above,
        goalThresholdTargetArea.aboveOrEqual,
        goalThresholdTargetArea.belowOrEqual,
        goalThresholdTargetArea.below,
      ].map((e) => ({
        text: this.$t(`goalThresholdTargetArea.${camelCase(e)}`),
        value: e,
      }));
    },
    showWeights() {
      return this.loggedInUserAccount.accountSettings.usesWeightedGoals;
    },
    integrationsAllowed() {
      return !this.readOnly && (
        this.loggedInUserAccount.accountSettings.usesJira
          || this.loggedInUserAccount.accountSettings.usesAsana
          || this.loggedInUserAccount.accountSettings.usesSheets
          || this.loggedInUserAccount.accountSettings.usesExcel
          || this.loggedInUserAccount.accountSettings.usesHubspot
          || this.loggedInUserAccount.accountSettings.usesSalesforce
      );
    },
    progressMeasurementOptions() {
      return this.allowedProgressMeasurementOptions(this.goalTypeOption(this.goal));
    },
    totalWeight() {
      return this.goalChildren.reduce((res, next) => res + this.weight(next), 0);
    },
  },
  methods: {
    weight(goal) {
      const paysOnto = findInArray({ haystack: goal.paysOnto, needle: this.goal.uid });
      if (paysOnto === null) {
        return 0;
      }

      if (typeof paysOnto['paysOnto|weight'] === 'undefined') {
        return 1;
      }

      return paysOnto['paysOnto|weight'];
    },
    isChecked(goal) {
      return goal.paysOnto.map((g) => g.uid).includes(this.goal.uid);
    },
    updateWeight(value, goal) {
      const paysOnto = goal.paysOnto.map((p) => ({
        ...p,
        'paysOnto|weight': value,
      }));

      this.goalsSvc.updateSingle({ uid: goal.uid, paysOnto });
    },
    updateMeasurementDependencies(value, goal) {
      if (value) {
        this.goalsSvc.updateSingle({ uid: goal.uid, paysOnto: [...goal.paysOnto, { uid: this.goal.uid }] });
        return;
      }

      this.goalsSvc.updateSingle({ uid: goal.uid, paysOnto: goal.paysOnto.filter((p) => p.uid !== this.goal.uid) });
    },
    changeProgressMeasurement(value) {
      if (value === goalProgressMeasurement.none) {
        this.goalsSvc.updateSingle({ uid: this.goal.uid, paysOnto: [], progressMeasurement: value, start: this.goal.start, end: this.goal.end, threshold: this.goal.threshold, thresholdTargetArea: this.goal.thresholdTargetArea });
        return;
      }

      let start = this.goal.start;
      let end = this.goal.end;

      if (value === goalProgressMeasurement.continuous && start === end) {
        start = 0;
        end = 100;
      }

      this.goalsSvc.updateSingle({ uid: this.goal.uid, progressMeasurement: value, start, end, threshold: this.goal.threshold, thresholdTargetArea: this.goal.thresholdTargetArea }).then(() => {
        this.start = this.goal.start;
        this.end = this.goal.end;
      });
    },
    updateStartEnd(start, end) {
      this.start = start;
      if (start === '' || start === null || Number.isNaN(start)) {
        this.startError = 'notEmpty';
        return;
      }

      this.end = end;
      if (end === '' || end === null || Number.isNaN(end)) {
        this.endError = 'notEmpty';
        return;
      }

      if (start === end) {
        this.continuousError = 'notEqual';
        return;
      }

      this.startError = '';
      this.endError = '';
      this.continuousError = '';
      const update = () => {
        this.goalsSvc.updateSingle({ uid: this.goal.uid, start, end });
      };

      this.debounce(update, 500, 'updateStartEnd');
    },
    updateThreshold(value) {
      this.threshold = value;
      if (value === '' || value === null || Number.isNaN(value)) {
        this.thresholdError = 'notEmpty';
        return;
      }

      this.thresholdError = '';
      const update = () => {
        this.goalsSvc.updateSingle({ uid: this.goal.uid, threshold: value });
      };
      this.debounce(update, 500, 'updateThreshold');
    },
    updateThresholdTargetArea(value) {
      this.goalsSvc.updateSingle({ uid: this.goal.uid, thresholdTargetArea: value });
    },
    updateMetric(value) {
      this.metric = value;
      if (value === this.goal.metric) {
        return;
      }

      if (value.length === this.metricMaxLen) {
        this.metricError = 'metricMaxLen';
        return;
      }

      this.metricError = '';
      const update = () => {
        this.goalsSvc.updateSingle({ uid: this.goal.uid, metric: value });
      };
      this.debounce(update, 500, 'updateMetric');
    },
  },
  watch: {
    visible() {
      this.startError = '';
      this.endError = '';
      this.metricError = '';
      this.continuousError = '';
      this.thresholdError = '';
    },
  },
  created() {
    this.coloring = this.goal.coloring;
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  ._metric-form-overlay {
    min-width: 40rem;
    max-height: calc(100vh - 13rem);
    overflow: auto;

    @media (max-width: $screen-size-md) {
      min-width: unset;
    }

    ._container {
      padding: 0 1.1rem .8rem;
    }

    ._description {
      color: $font-color-secondary;
    }

    ._inner {
      display: flex;
      align-items: center;

      ._row {
        display: flex;
        flex-direction: column;
        width: 100%;

        ._range {
          display: flex;
          width: 100%;
        }
      }

      ._input-field {
        width: 10rem;
        margin-right: .8rem;
      }
    }

    ._form-item {
      margin-bottom: .8rem;

      &.-has-margin {
        margin-bottom: 2rem;
      }

      ._label {
        margin-bottom: .4rem;
        font-size: $font-size-2;
        color: $font-color-tertiary;

        &.-progress {
          padding: 0 1.2rem;
          margin-top: 1.2rem;
        }
      }
    }

    ._error {
      margin-top: .8rem;
      font-size: $font-size-2;
      color: $error-color;
    }

    ._data-source {
      margin-top: 2rem;
    }

    ._checkbox {
      width: 100%;

      ._checkbox-item {
        margin-bottom: .2rem;
      }
    }
  }

  ._coloring-item {
    width: 100%;

    ._img {
      display: flex;
      padding: .8rem;
      background-color: white;
      border-radius: $default-border-radius;
    }

    ._description {
      max-width: 11.4rem;
      margin-top: .4rem;
      font-size: $font-size-2;
    }
  }
</style>
