<template>
  <r-c-layout
    class="resource-center-content"
    show-back-button
    :title="$t('rcOverview.resources.title')"
    :resource-center-router="resourceCenterRouter"
  >
    <m-content
      class="okr-resources"
      padding-small
    >
      <p :style="{ color: $colors.grey.darken1 }">
        {{ $t('okrResources.hint') }}
      </p>
      <div
        v-for="item in readingList"
        :key="item.title"
        class="_item"
      >
        <onboarding-card-item
          :href="item.href"
          :title="item.title"
          :sub-title="item.subTitle"
          icon="read-filled"
        />
      </div>
    </m-content>
  </r-c-layout>
</template>

<script>
import OnboardingCardItem from '@/components/resource-center/OnboardingCardItem.vue';
import RCLayout from '@/components/resource-center/RCLayout.vue';
import useAccountSettings from '@/composables/logged-in-user-account/account-settings';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useOnboardingMarker from '@/composables/onboarding/onboarding-marker';
import { onboardingGuideSteps } from 'shared/constants.json';

export default {
  name: 'RcResources',
  props: {
    resourceCenterRouter: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { loggedInUserAccount } = useLoggedInUserAccount();
    const accountSettingsSvc = useAccountSettings();
    const { markAsDone } = useOnboardingMarker(loggedInUserAccount, accountSettingsSvc);
    return { markAsDone };
  },
  components: { OnboardingCardItem, RCLayout },
  data() {
    return {
      readingList: [
        'successStories',
        'okrGuide',
        'howtoWriteOkrs',
        'okrRolloutGuide',
        'okrCycle',
        'okrPlanning',
        'okrCheckin',
        'okrRetrospective',
        'review',
        'scoreOkrs',
        'benefits',
        'okrVsKpi',
        'outcomeVsOutput',
      ].map((title) => ({
        title: this.$t(`okrResources.${title}.title`),
        subTitle: this.$t(`okrResources.${title}.subTitle`),
        href: this.$t(`okrResources.${title}.href`),
      })),
    };
  },
  created() {
    this.markAsDone([onboardingGuideSteps.okrResources]);
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .okr-resources {
    ._item {
      margin-bottom: 1.6rem;
      overflow: unset;

      ._bottom {
        margin-top: .4rem;
        color: $font-color-secondary;
      }
    }
  }
</style>
