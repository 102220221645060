<template>
  <m-select
    ref="input"
    :full-width="fullWidth"
    :hide-border="hideBorder"
    :m-style="mStyle"
    :items="options"
    :value="transformedValue"
    :placeholder="placeholder"
    :hide-placeholder="hidePlaceholder"
    :placeholder-icon="placeholderIcon"
    :loading="loading"
    :hide-hover="hideHover"
    :max-tag-text-length="maxTagTextLength"
    :disabled="disabled"
    :read-only="readOnly"
    :small="small"
    :popup="popup"
    :nowrap="nowrap"
    :hide-selected-values="popup"
    :hide-arrow="hideArrow"
    :match-trigger-width="matchTriggerWidth"
    :description="$t('userPicker.description')"
    :auto-focus="autoFocus"
    :has-error="hasError"
    class="user-picker"
    value-key="value"
    item-text="text"
    show-search
    allow-clear
    multiple
    no-tags
    keep-open-on-click
    show-description
    has-custom-item
    cache-items
    @change="emitUpdate"
    @close="$emit('close')"
  >
    <template #item="item">
      <div
        class="_item"
      >
        <user-display
          :user="selectSingle(item.item)"
          :small="!small || !item.isTrigger"
          :xs="small && item.isTrigger"
        />
      </div>
    </template>
  </m-select>
</template>

<script>
import UserDisplay from 'shared/components/UserDisplay.vue';
import useAccessGroupsLoggedInUser from '@/composables/logged-in-user/access-groups-logged-in-user';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useUsers from '@/composables/user/users';
import { accessGroupFlag } from 'shared/constants.json';
import { mStyleProps } from 'shared/lib/m-style-props';
import { uniqBy } from 'lodash-es';
import { userHasRight } from 'shared/lib/access';

export default {
  name: 'UserPicker',
  props: {
    ...mStyleProps,
    maxTagTextLength: {
      default: () => 0,
      type: Number,
    },
    placeholderIcon: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    hidePlaceholder: {
      type: Boolean,
      default: false,
    },
    hideHover: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default: () => [],
    },
    nowrap: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    popup: {
      type: Boolean,
      default: false,
    },
    hideArrow: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    prependIcon: {
      type: String,
      default: '',
    },
    hideBorder: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    matchTriggerWidth: {
      type: Boolean,
      default: false,
    },
    autoFocus: {
      type: Boolean,
      default: false,
    },
    userDetailsPage: {
      type: String,
      default: '',
    },
    excludedUsers: {
      type: Array,
      default: () => [],
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    restrictForeignEntitySelection: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { users, selectSingle, selectMultiple } = useUsers();
    const { loggedInUser, peers } = useLoggedInUser();
    const { accessGroups } = useAccessGroupsLoggedInUser();

    return {
      stateUsers: users,
      selectSingle,
      selectMultiple,
      loggedInUser,
      peers,
      accessGroups,
    };
  },
  emits: ['input', 'update:value', 'change', 'close'],
  components: { UserDisplay },
  data() {
    return {
      search: '',
      loading: false,
      selectedUsers: [],
      rules: { required: (value) => value.length > 0 || this.$t('validation.oneUserNeeded') },
      // TODO: replace me with debounce composable
      debounced: null,
    };
  },
  computed: {
    transformedValue() {
      return this.value.map((v) => v.uid);
    },
    excludedUserIds() {
      return this.excludedUsers.map((u) => u.uid);
    },
    options() {
      const disabledP = (user) => {
        if (!this.restrictForeignEntityReferences) {
          return false;
        }

        if (this.loggedInUser.uid === user.uid) {
          return false;
        }

        return !this.peers.map((u) => u.uid).includes(user.uid);
      };

      return this.users.filter((u) => !this.excludedUserIds.includes(u.uid)).map((user) => ({
        text: this.getDisplayName(user),
        value: user.uid,
        disabled: disabledP(user),
        user,
      }));
    },
    restrictForeignEntityReferences() {
      if (!this.restrictForeignEntitySelection) {
        return false;
      }

      return !userHasRight([accessGroupFlag.foreignEntityReference], this.accessGroups);
    },
    users() {
      if (this.loggedInUser === null) {
        return this.stateUsers;
      }
      return uniqBy([this.loggedInUser, ...this.stateUsers], 'uid');
    },
  },
  methods: {
    show() {
      this.$refs.input.show();
    },
    getDisplayName(user) {
      if (user.firstName === '' && user.lastName === '') {
        return user.email;
      }
      return `${user.firstName} ${user.lastName}`;
    },
    emitUpdate(value) {
      const users = this.selectMultiple(value);
      this.$emit('change', users);
      this.$emit('input', users);
      this.$emit('update:value', users);
    },
  },
};
</script>

<style
scoped
    lang="scss"
    type="text/scss"
>
</style>
