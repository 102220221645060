import { mcColorSet } from '@/lib/charts/colors';

export default (t) => ({
  chart: {
    style: {
      fontFamily: '\'-apple-system\', \'BlinkMacSystemFont\', \'Segoe UI\', \'PingFang SC\', \'Hiragino Sans GB\', '
        + '\'Microsoft YaHei\', \'Helvetica Neue\', Helvetica, Arial, sans-serif',
    },
  },
  colors: mcColorSet,
  credits: { enabled: false },
  exporting: { enabled: false },
  legend: { enabled: false },
  title: { text: '' },
  // this only affects the initial animation
  plotOptions: { series: { animation: false } },
  lang: {
    decimalPoint: t('highcharts.decimalPoint'),
    thousandsSep: t('highcharts.thousandsSep'),
    months: t('highcharts.months'),
    shortMonths: t('highcharts.shortMonths'),
    weekdays: t('highcharts.weekdays'),
  },
});
