import { AND, CREATED_AT, GE, LE, ONE_TO_ONE, RESULT, UID } from 'shared/api/query/constants';
import { AnswerFilterHandler } from '@/lib/filter/answer/handler';
import { answer, answerItem, field, form } from 'shared/api/query/configs.json';
import { reverseEdge } from 'shared/api/query/edges';

const getAnswerFilters = (userScopeTree, intervalConfig) => {
  const filters = [];
  if (typeof intervalConfig.from !== 'undefined' && intervalConfig.from !== null) {
    filters.push({
      func: {
        name: GE,
        attr: CREATED_AT,
        args: [{ value: intervalConfig.from }],
      },
    });
  }

  if (typeof intervalConfig.till !== 'undefined' && intervalConfig.till !== null) {
    filters.push({
      func: {
        name: LE,
        attr: CREATED_AT,
        args: [{ value: intervalConfig.till }],
      },
    });
  }

  let blocks = [];
  if (typeof userScopeTree !== 'undefined' && userScopeTree !== null) {
    const handler = new AnswerFilterHandler();
    const { filterTree, varBlocks } = handler.Translate(userScopeTree);
    if (filterTree !== null) {
      filters.push(filterTree);
      blocks = varBlocks;
    }
  }

  return { filterTree: { op: AND, child: filters }, varBlocks: blocks };
};

export const answersBy = ({ formId, userScopeTree, intervalConfig }, pagination) => {
  const answerVar = 'answers';
  const { filterTree, varBlocks } = getAnswerFilters(userScopeTree, intervalConfig);
  return [
    {
      uid: [formId],
      alias: 'var',
      func: { name: UID },
      model: form.model,
      children: [
        {
          model: answer.model,
          attr: reverseEdge(answer.edges.form),
          var: answerVar,
        },
      ],
    },
    {
      alias: RESULT,
      needsVar: [{ name: answerVar, typ: 1 }],
      func: {
        name: 'uid',
        needsVar: [{ name: answerVar, typ: 1 }],
      },
      model: answer.model,
      pagination,
      filter: filterTree,
      order: [
        {
          attr: CREATED_AT,
          desc: true,
        },
      ],
      children: [
        { attr: UID },
        { attr: CREATED_AT },
        {
          attr: answer.edges.answerItems,
          model: answerItem.model,
          children: [
            { attr: answerItem.edges.value },
            { attr: answerItem.edges.text, default: '' },
            {
              attr: answerItem.edges.field,
              assoc: ONE_TO_ONE,
              model: field.model,
              children: [{ attr: UID }],
            },
          ],
        },
      ],
    },
    ...varBlocks,
  ];
};

export const answerCountBy = ({ formId, userScopeTree, intervalConfig }) => {
  const answerVar = 'answers';
  const { filterTree, varBlocks } = getAnswerFilters(userScopeTree, intervalConfig);
  return [
    {
      uid: [formId],
      alias: 'var',
      func: { name: UID },
      model: form.model,
      children: [
        {
          model: answer.model,
          attr: reverseEdge(answer.edges.form),
          var: answerVar,
        },
      ],
    },
    {
      alias: RESULT,
      needsVar: [{ name: answerVar, typ: 1 }],
      func: {
        name: 'uid',
        needsVar: [{ name: answerVar, typ: 1 }],
      },
      filter: filterTree,
      model: answer.model,
      children: [
        { attr: UID, isCount: true },
      ],
    },
    ...varBlocks,
  ];
};
