import { DateTime } from 'luxon';

export const currentGoalCycle = (goalCycles) => {
  const sortFn = (a, b) => {
    const aDiff = DateTime.fromISO(a.end).diff(DateTime.fromISO(a.start));
    const bDiff = DateTime.fromISO(b.end).diff(DateTime.fromISO(b.start));
    return aDiff.milliseconds - bDiff.milliseconds;
  };

  const filtered = goalCycles.filter((gc) => DateTime.fromISO(gc.start) <= DateTime.local() && DateTime.local() <= DateTime.fromISO(gc.end));
  const sorted = filtered.sort(sortFn);
  if (sorted.length === 0) {
    return [];
  }

  return [sorted[0]];
};
