<template>
  <div class="grid-item-header-single-goal">
    <grid-item-header
      :tile="tile"
      :title="title"
      :grid-row="gridRow"
      :read-only="props.readOnly"
      title-disable-edit
      :title-no-text="titleNoText"
      :title-placeholder="titlePlaceholder"
      :description-disable-edit="!hasGoal"
    >
      <template
        v-if="hasGoal && staticDescription !== ''"
        #staticDescription
      >
        {{ staticDescription }}
      </template>
      <template
        v-if="hasGoal || !props.readOnly"
        #extraActions="{ close }"
      >
        <m-dropdown
          v-if="!props.readOnly && gridPageTileSingleGoal !== null"
          :title="$t('gridPageTileSingleGoalEditor.title')"
          :relocate-key="gridPageTileSingleGoal.goal"
          block
          @hide="close"
        >
          <m-card-item icon="settings">
            {{ $t('gridPage.tile.header.singleGoal.settings') }}
          </m-card-item>
          <template #overlay>
            <grid-page-tile-single-goal-editor :grid-page-tile-single-goal="gridPageTileSingleGoal" />
          </template>
        </m-dropdown>
        <m-divider
          v-if="!props.readOnly && hasGoal"
          xxs
        />
        <m-card-item
          v-if="hasGoal"
          icon="central-preview"
          @click="openGoal(close)"
        >
          {{ $t('gridPage.tile.header.singleGoal.openGoal') }}
        </m-card-item>
      </template>
    </grid-item-header>
  </div>
</template>

<script setup>
import GridItemHeader from '@/components/custom-grid/GridItemHeader.vue';
import GridPageTileSingleGoalEditor from '@/components/custom-grid/GridPageTileSingleGoalEditor.vue';
import useGoals from '@/composables/goal/goals';
import useGridPage from '@/composables/grid-page/grid-page';
import useOpenPeekMode from '@/composables/goal/open-peek-mode';
import { CUSTOM_DATE, NONE, ONE_DAY_AGO, ONE_MONTH_AGO, ONE_WEEK_AGO } from '@/lib/constants';
import { DateTime } from 'luxon';
import { camelCase } from 'lodash-es';
import { computed } from 'vue';
import { dateScopeDynamicType, gridPageTileSingleGoalType } from 'shared/constants.json';
import { useI18n } from 'vue-i18n';

const gridPageService = useGridPage();

const props = defineProps({
  tile: {
    type: Object,
    required: true,
  },
  gridRow: {
    type: Object,
    required: true,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
});

const { t } = useI18n();
const gridPageTileSingleGoal = computed(() => {
  const res = gridPageService.gridPageTileSingleGoal.value.find((singleGoalTile) => singleGoalTile.tile.uid === props.tile.uid);
  if (res === undefined) {
    return null;
  }
  return res;
});

const { selectSingle } = useGoals();
const singleGoal = computed(() => {
  if (gridPageTileSingleGoal.value === null || gridPageTileSingleGoal.value.goal === null) {
    return null;
  }
  const res = selectSingle(gridPageTileSingleGoal.value.goal.uid);
  if (res === undefined) {
    return null;
  }
  return res;
});

const hasGoal = computed(() => gridPageTileSingleGoal.value !== null && gridPageTileSingleGoal.value.goal !== null && singleGoal.value !== null);

const title = computed(() => {
  if (!hasGoal.value || singleGoal.value === null) {
    return '';
  }
  return singleGoal.value.title;
});

const peekModeSvc = useOpenPeekMode();
const openGoal = (close) => {
  peekModeSvc.openGoal({ goalId: singleGoal.value.uid });
  close();
};

const titleNoText = computed(() => {
  if (hasGoal.value) {
    return singleGoal.value.title;
  }
  return undefined;
});
const titlePlaceholder = computed(() => {
  if (gridPageTileSingleGoal.value !== null && gridPageTileSingleGoal.value.goal === null) {
    return '';
  }
  return undefined;
});

const appendStaticDescription = (value, suffix) => {
  if (value === '') {
    return suffix;
  }
  return `${value}・${suffix}`;
};
const staticDescription = computed(() => {
  let description = '';
  if ([gridPageTileSingleGoalType.number].includes(gridPageTileSingleGoal.value.type)) {
    const compareTo = gridPageTileSingleGoal.value.compareTo;
    if (compareTo !== undefined && compareTo.value !== NONE) {
      let relativeDate = '';
      if ([ONE_DAY_AGO, ONE_WEEK_AGO, ONE_MONTH_AGO].includes(compareTo.value)) {
        relativeDate = t(`gridPage.tile.header.compareTo.${compareTo.value}`);
      }
      if ([CUSTOM_DATE].includes(compareTo.value)) {
        relativeDate = DateTime.fromISO(compareTo.date).toLocaleString(DateTime.DATE_SHORT);
      }
      const compareToSuffix = t('gridPage.tile.header.compareTo.base', { relativeDate });
      description = appendStaticDescription(description, compareToSuffix);
    }
  }
  if ([gridPageTileSingleGoalType.line].includes(gridPageTileSingleGoal.value.type)) {
    const timeRange = gridPageTileSingleGoal.value.timeRange;
    if (timeRange !== undefined && timeRange.dynamicType !== undefined && timeRange.dynamicType !== dateScopeDynamicType.allTime) {
      let timeRangeSuffix = '';
      if ([dateScopeDynamicType.lastXDays].includes(timeRange.dynamicType)) {
        timeRangeSuffix = t('dateScope.lastNDays', { value: timeRange.amount });
      } else {
        timeRangeSuffix = t(`dateScope.${camelCase(timeRange.dynamicType)}`);
      }
      description = appendStaticDescription(description, timeRangeSuffix);
    }
  }
  return description;
});

</script>
