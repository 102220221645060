<template>
  <div class="editor-image">
    <m-content
      v-if="name === '' || uploadFailed"
      padding-xxs
      class="_add-image"
      @click="showPrompt"
    >
      <div class="_add-image-inner">
        <div class="_icon">
          <m-icon
            type="file-image"
            size="22"
            :color="$colors.grey.base"
          />
        </div>
        <div class="_title">
          {{ $t('editorImage.addAnImage') }}
        </div>
        <input
          id="file"
          ref="fileInput"
          class="_file"
          type="file"
          :style="{ display: 'none' }"
          name="avatar"
          accept="image/*"
          @change="fileChanged"
        >
      </div>
    </m-content>
    <m-content
      v-else-if="src === ''"
      padding-xxs
      class="_placeholder"
    >
      <div class="_placeholder-inner">
        <div class="_icon">
          <m-icon
            type="file-image"
            size="22"
            :color="$colors.grey.base"
          />
        </div>
        <div class="_text">
          <div class="_name">
            {{ name }}
          </div>
          <div class="_size">
            {{ size }}
          </div>
          <m-spinner
            size="xxs"
            :color="$colors.grey.base"
            class="_spinner"
          />
          <div class="_progress">
            {{ progress }} %
          </div>
        </div>
      </div>
    </m-content>
    <div
      v-else
      class="_inner"
    >
      <div
        class="_actions"
        :style="actionsStyle"
      >
        <m-btn
          small
          class="_btn"
          hide-border
          :href="src"
          target="_blank"
          :button-style="{ backgroundColor: 'white' }"
        >
          {{ $t('editorImage.original') }}
        </m-btn>
        <m-dropdown
          v-model:value="showMenu"
          :title="$t('general.actions')"
        >
          <m-btn
            small
            fab
            hide-border
            icon="ellipsis"
            class="_btn"
            :button-style="{ backgroundColor: 'white' }"
            @click="showMenu = true"
          />
          <template #overlay>
            <m-card
              list
              no-padding
            >
              <m-card-item
                v-if="view.editable"
                icon="delete"
                @click="deleteNode"
              >
                {{ $t('general.delete') }}
              </m-card-item>
              <a
                :download="name"
                :href="src"
              >
                <m-card-item
                  icon="download"
                >
                  {{ $t('general.download') }}
                </m-card-item>
              </a>
            </m-card>
          </template>
        </m-dropdown>
      </div>
      <img :src="src">
    </div>
  </div>
</template>

<script>
import useFileInputDialog from '@/components/editor/file-input-dialog';
import { FILE_UPLOAD_LIMIT } from '@/lib/constants';
import { uploadFile } from '@/lib/image/image';

export default {
  name: 'EditorImage',
  props: {
    node: {
      type: Object,
      required: true,
    },
    updateAttrs: {
      type: Function,
      required: true,
    },
    view: {
      type: Object,
      required: true,
    },
    getPos: {
      type: Function,
      required: true,
    },
    editor: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const fileInputDialogHandler = useFileInputDialog();
    return { fileInputDialogHandler };
  },
  data() {
    return {
      progress: 0,
      showMenu: false,
      uploadFailed: false,
    };
  },
  computed: {
    actionsStyle() {
      if (this.showMenu) {
        return { display: 'flex' };
      }
      return {};
    },
    name: {
      get() {
        return this.node.attrs.name;
      },
      set({ name }) {
        this.updateAttrs({ name });
      },
    },
    size: {
      get() {
        if (this.node.attrs.size === null) {
          return '';
        }
        const size = this.node.attrs.size;
        if (size / 1000 < 1000) {
          return `${(size / 1000).toFixed(1)} kB`;
        }
        return `${(size / 1000000).toFixed(1)} MB`;
      },
      set(size) {
        this.updateAttrs({ size });
      },
    },
    src: {
      get() {
        return this.node.attrs.src;
      },
      set(src) {
        this.updateAttrs({ src });
      },
    },
    autoOpen: {
      get() {
        return this.node.attrs.autoOpen;
      },
      set(value) {
        this.udpateAttrs({ autoOpen: value });
      },
    },
    image() {
      return this.node.attrs.image;
    },
  },
  methods: {
    showPrompt() {
      if (!this.view.editable) {
        return;
      }

      this.fileInputDialogHandler.open(this.editor);
      this.$refs.fileInput.click();
    },
    fileChanged(event) {
      if (event.target.files.length === 0) {
        return;
      }

      const file = event.target.files[0];
      if (file.size > FILE_UPLOAD_LIMIT) {
        this.$showSnackbar({ color: 'error', message: this.$t('fileUpload.fileTooBig') });
        return;
      }

      this.updateAttrs({ name: file.name });
      this.updateAttrs({ size: file.size });
      this.upload(file);
    },
    deleteNode() {
      const tr = this.view.state.tr;
      const pos = this.getPos();
      tr.delete(pos, pos + this.node.nodeSize);
      this.view.dispatch(tr);
      this.showMenu = false;
    },
    onUploadProgress(event) {
      this.progress = Math.round((event.loaded / event.total) * 100);
    },
    upload(file) {
      this.uploadFailed = false;
      uploadFile(file, this.onUploadProgress).then((data) => {
        this.updateAttrs({ src: data.getURL });
      }).catch((e) => {
        this.uploadFailed = true;
        if (e.message.indexOf('bad input') > -1) {
          return;
        }
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      });
    },
  },
  mounted() {
    if (this.src !== '') {
      return;
    }

    if (this.name === '') {
      if (this.autoOpen) {
        this.showPrompt();
        this.$nextTick(() => {
          this.updateAttrs({ autoOpen: false });
        });
      }
      return;
    }

    this.upload(this.image);
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  @import "shared/assets/scss/box-shadow";

  .editor-image {
    display: flex;
    justify-content: center;
    margin: .8rem 0;

    ._add-image {
      width: 100%;
      white-space: initial;
      background-color: map_get($grey, 'lighten-5');
      border-radius: $border-radius-sm;

      &:hover {
        border-color: $hover-color;
      }

      ._add-image-inner {
        display: flex;
        align-items: center;
        height: 2.8rem;

        ._title {
          margin-left: 1.2rem;
          color: $font-color-secondary;
        }
      }
    }

    ._placeholder {
      width: 100%;
      white-space: initial;
      background-color: map_get($grey, 'lighten-5');
      border-radius: $border-radius-sm;

      ._placeholder-inner {
        display: flex;
        align-items: center;
        height: 2.8rem;

        ._icon {
          margin-right: 1rem;
        }

        ._text {
          display: flex;
          align-items: flex-end;
          width: 100%;
          max-width: calc(100% - 6rem);

          ._name {
            max-width: calc(100% - 13rem);
            margin-right: 1rem;
            overflow: hidden;
            font-size: $font-size-4;
            line-height: 1.5;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          ._size {
            font-size: $font-size-2;
            line-height: 1.5;
            color: $font-color-secondary;
          }

          ._spinner {
            margin-left: 1.2rem;
            height: 2rem;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          ._progress {
            margin-left: .4rem;
            font-size: $font-size-2;
            color: $font-color-secondary;
          }
        }
      }
    }

    ._inner {
      position: relative;

      img {
        max-width: 100%;
      }

      ._actions {
        position: absolute;
        top: .6rem;
        right: .6rem;
        z-index: 99;
        display: none;

        ._btn {
          margin-left: .4rem;

          @include box_shadow(1);
        }
      }

      &:hover {
        ._actions {
          display: flex;
        }
      }
    }
  }
</style>
