import { uniqBy } from 'lodash-es';

const extractMention = (res, next) => {
  if (next === null || next.content === null) {
    return res;
  }

  if (typeof next.content !== 'undefined') {
    res = next.content.reduce(extractMention, res);
    return res;
  }

  if (next.type === 'mention') {
    res.push({ uid: next.attrs.id });
  }
  return res;
};

export const extractMentions = (data) => {
  if (data === null || typeof data.content === 'undefined') {
    return [];
  }
  return uniqBy(data.content.reduce(extractMention, []), 'uid');
};
