import { computed } from 'vue';
import { useStore } from 'vuex';

export default function useCustomFuncCtx(ctxAliasRef) {
  const store = useStore();

  const state = computed(() => store.state.customFuncCtx[ctxAliasRef.value]);

  const intercept = (node) => {
    switch (true) {
      case (node instanceof Array): {
        const r = [];
        node.forEach((e) => {
          r.push(intercept(e));
        });
        return r;
      }
      case (node instanceof Object): {
        if (ctxAliasRef.value in node) {
          store.commit('CUSTOM_FUNC_CTX_SET', {
            ctxAlias: ctxAliasRef.value,
            node: { uid: node.uid },
            value: node[ctxAliasRef.value],
          });
          delete node[ctxAliasRef.value];
          return node;
        }
        Object.keys(node).forEach((k) => {
          node[k] = intercept(node[k]);
        });
        return node;
      }
      default:
        return node;
    }
  };

  return {
    alias: ctxAliasRef,
    intercept,
    state,
  };
}
