<template>
  <div
    class="_form"
  >
    <m-form-item
      :label="$t('createFormCard.nameLabel')"
    >
      <m-text-field
        v-model:value="title"
        auto-focus
        @keydown.enter="create"
      />
    </m-form-item>
  </div>
</template>

<script>
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import { defaultSurveyProcess } from '@/lib/survey-process';
import { mapActions, mapState } from 'vuex';
import { userScopeType } from 'shared/constants.json';

export default {
  name: 'CreateFormCard',
  data() {
    return { title: '', loading: false };
  },
  setup() {
    const { loggedInUser, userLang } = useLoggedInUser();
    const { loggedInUserAccount } = useLoggedInUserAccount();
    return { loggedInUser, userLang, loggedInUserAccount };
  },
  emits: ['created'],
  computed: { ...mapState({ form: (state) => state.form }) },
  methods: {
    ...mapActions([
      'createForm',
      'createSurveyProcess',
      'getFormAndProcess',
    ]),
    create() {
      this.loading = true;
      this.createForm({
        form: {
          title: this.title,
          creator: this.loggedInUser,
          editors: [this.loggedInUser],
          account: { uid: this.loggedInUserAccount.uid },
          resourcePolicy: {
            account: { uid: this.loggedInUserAccount.uid },
            writeScope: {
              account: { uid: this.loggedInUserAccount.uid },
              op: 'and',
              children: [{ op: 'and', scope: { staticUsers: [{ uid: this.loggedInUser.uid }], type: userScopeType.staticUsers } }],
            },
          },
          analyticAccess: {
            account: { uid: this.loggedInUserAccount.uid },
            readScope: {
              account: { uid: this.loggedInUserAccount.uid },
              op: 'and',
              children: [{ op: 'and', scope: { staticUsers: [{ uid: this.loggedInUser.uid }], type: userScopeType.staticUsers } }],
            },
          },
        },
      })
        .then((response) => {
          if (response.status !== 201) {
            this.loading = false;
            this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
            return;
          }

          this.createSurveyProcess({
            surveyProcess: defaultSurveyProcess({
              account: { uid: this.loggedInUserAccount.uid },
              languages: [this.userLang],
              form: response.data,
            }),
          }).then(() => {
            this.getFormAndProcess({ formId: response.data.uid }).then(() => {
              this.loading = false;
              this.$emit('created', { form: response.data });
            });
          });
        });
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
</style>
