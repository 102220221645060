<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.9148 20.7757C11.862 21.1078 11.6147 21.3283 11.2967 21.3283H11.2951C10.9755 21.3283 10.7373 21.1078 10.677 20.7757C9.84194 15.3414 9.08962 14.6478 3.65936 13.9248C3.31865 13.8851 3.07141 13.6338 3.07141 13.3223C3.07141 13.0005 3.31865 12.7492 3.65936 12.7095C9.15143 12.1159 9.92334 11.2621 10.677 5.85861C10.7283 5.53531 10.9755 5.31488 11.2951 5.31488C11.6147 5.31488 11.8529 5.53531 11.9132 5.85861C12.7168 11.3032 13.4902 12.0761 18.9325 12.7095C19.2717 12.7595 19.5204 12.999 19.5204 13.3223C19.5184 13.4765 19.4568 13.6243 19.3477 13.736C19.2387 13.8477 19.0904 13.9152 18.9325 13.9248C13.4374 14.5274 12.6655 15.3825 11.9148 20.7757Z"
      fill="#FDB237"
      stroke="#FDB237"
    />
    <path
      d="M19.4061 8.57783C19.3763 8.77539 19.2119 8.92736 18.9993 8.92736C18.7867 8.92736 18.6308 8.77677 18.6025 8.55849C18.2907 6.07585 18.1858 5.9902 15.5694 5.59232C15.3172 5.56468 15.1726 5.42377 15.1726 5.20548C15.1726 4.99826 15.3172 4.84629 15.5298 4.81865C18.1858 4.33787 18.2907 4.33787 18.6025 1.85386C18.6308 1.63696 18.7867 1.48499 18.9993 1.48499C19.2218 1.48499 19.3778 1.63696 19.4061 1.84419C19.7349 4.38484 19.8129 4.46083 22.4675 4.82003C22.6702 4.83799 22.8261 4.99964 22.8261 5.20686C22.8281 5.30215 22.7934 5.39471 22.7288 5.46631C22.6643 5.53791 22.5746 5.58335 22.4774 5.5937C19.8029 6.07447 19.7349 6.09382 19.4061 8.57783Z"
      fill="#FDB237"
      stroke="#FDB237"
      stroke-width="0.5"
    />
    <path
      d="M3.59143 22.5C3.72858 22.5 3.81572 22.4151 3.84572 22.2814C4.11858 20.7302 4.10858 20.7106 5.78858 20.3876C5.92572 20.3597 6.01286 20.2817 6.01286 20.1397C6.01286 20.0061 5.92572 19.921 5.78858 19.9016C4.10858 19.5966 4.13858 19.5673 3.84572 18.0078C3.81715 17.874 3.72858 17.7793 3.59143 17.7793C3.45429 17.7793 3.36715 17.874 3.33715 18.0078C3.04429 19.5493 3.08429 19.5673 1.39429 19.9016C1.25715 19.921 1.17001 20.0061 1.17001 20.1383C1.17001 20.2817 1.25715 20.3583 1.40429 20.3876C3.07429 20.7009 3.06429 20.7106 3.33715 22.2814C3.36572 22.4151 3.45429 22.5 3.59143 22.5Z"
      fill="#FDB237"
      stroke="#FDB237"
      stroke-width="0.5"
    />
  </svg>
</template>

<script>
export default { name: 'UpgradePlan' };
</script>

<style scoped lang="scss" type="text/scss">

</style>
