import { DateTime } from 'luxon';

export const humanReadableDiff = (t1, t2) => {
  const diff = t2.diff(t1);
  const diffInWeeks = diff.as('weeks');
  if (diffInWeeks > 12) {
    return {
      translationKey: 'time.date',
      dateTime: t1.toLocaleString(DateTime.DATE_MED),
    };
  }
  if (diffInWeeks >= 4.28 && diffInWeeks <= 12) { // 4.28 weeks = 30 days
    return {
      translationKey: 'time.weeksAgo',
      count: Math.floor(diffInWeeks),
    };
  }

  const diffAsDays = diff.as('days');
  if (diffAsDays >= 1) {
    return {
      translationKey: 'time.daysAgo',
      count: Math.floor(diffAsDays),
    };
  }
  const diffAsHours = diff.as('hours');
  if (diffAsHours >= 1) {
    return {
      translationKey: 'time.hoursAgo',
      count: Math.floor(diffAsHours),
    };
  }
  return {
    translationKey: 'time.minutesAgo',
    count: Math.ceil(diff.as('minutes')),
  };
};

export const humanReadableDiffFromNow = (t) => humanReadableDiff(t, DateTime.local());
