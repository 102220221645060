<template>
  <m-dropdown
    v-model:value="showEditor"
    class="favorite-button"
    :title="$t('favoriteButton.title')"
    :block="$store.state.breakpoint.smAndDown"
  >
    <component
      :is="$store.state.breakpoint.smAndDown ? 'm-card-item' : 'm-btn'"
      small
      hide-border
      :icon="favorite === null ? '' : 'check'"
      @click="showEditor = true"
    >
      <template v-if="favorite === null">
        {{ $t('favoriteButton.favorite') }}
      </template>
      <template v-else>
        {{ $t('favoriteButton.favorited') }}
      </template>
    </component>
    <template #overlay>
      <m-card
        no-padding
        list
      >
        <favorite-editor
          :entity="favorite"
          :icon-suggestion="iconSuggestion"
          :title-suggestion="titleSuggestion"
          :route-name="routeName"
          :route-query="routeQuery"
          :route-params="routeParams"
          @deleted="showEditor = false"
        />
      </m-card>
    </template>
  </m-dropdown>
</template>

<script>
import FavoriteEditor from '@/components/favorite/FavoriteEditor.vue';
import useFavorites from '@/composables/favorite/favorite';
import { Icon } from 'shared/lib/icon';

export default {
  name: 'FavoriteButton',
  props: {
    titleSuggestion: {
      type: String,
      default: '',
    },
    iconSuggestion: {
      type: Icon,
      default: null,
    },
    entityId: {
      type: Number,
      default: 0,
    },
    routeName: {
      type: String,
      default: '',
    },
    routeParams: {
      type: Object,
      default: () => null,
    },
    routeQuery: {
      type: Object,
      default: () => null,
    },
  },
  setup() {
    const { favorites } = useFavorites();
    return { favorites };
  },
  components: { FavoriteEditor },
  data() {
    return { showEditor: false };
  },
  computed: {
    favorite() {
      const filtered = this.favorites.filter((f) => this.favoriteMatches(f));

      if (filtered.length === 0) {
        return null;
      }

      return filtered[0];
    },
  },
  methods: {
    favoriteMatches(f) {
      if (this.entityId !== 0) {
        return JSON.stringify(f.params).includes(`${this.entityId}`);
      }
      return f.routeName === this.$route.name
        && JSON.stringify(f.params) === JSON.stringify(this.$route.params)
        && JSON.stringify(f.query) === JSON.stringify(this.$route.query);
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
</style>
