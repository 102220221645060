<template>
  <m-content
    padding
    class="m-dialog-actions"
  >
    <div class="__inner">
      <m-btn
        v-if="showCancel"
        :disabled="disabled"
        hide-border
        @click="$emit('cancel')"
      >
        {{ cancelText === '' ? $t('general.cancel') : cancelText }}
      </m-btn>
      <m-btn
        :disabled="disabled || submitDisabled"
        color="primary"
        class="__btn"
        @click="$emit('ok')"
      >
        {{ okText === '' ? $t('general.okay') : okText }}
      </m-btn>
    </div>
  </m-content>
</template>
<script>
export default {
  name: 'MDialogActions',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    okText: {
      type: String,
      default: '',
    },
    cancelText: {
      type: String,
      default: '',
    },
    submitDisabled: {
      type: Boolean,
      default: false,
    },
    showCancel: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['cancel', 'ok'],
};
</script>
<style lang="scss" type="text/scss">
  .m-dialog-actions {
    border-top: 1px solid $border-color;

    .__inner {
      display: flex;
      justify-content: flex-end;

      .__btn {
        margin-left: .4rem;
      }
    }
  }
</style>
