<template>
  <m-checkbox
    :value="value"
    class="always-sync-checkbox"
    @input="emitInput"
  >
    <span>
      {{ $t('dataSource.alwaysSync') }}
    </span>
    <div class="_icon">
      <m-tooltip>
        <span>
          <m-icon type="question-circle" />
        </span>
        <template #title>
          {{ $t('dataSource.alwaysSyncHint') }}
        </template>
      </m-tooltip>
    </div>
  </m-checkbox>
</template>

<script>
export default {
  name: 'AlwaysSyncCheckbox',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input', 'update:value'],
  methods: {
    emitInput(event) {
      this.$emit('input', event);
      this.$emit('update:value', event);
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">

  .always-sync-checkbox {
    color: $font-color-secondary;

    ._icon {
      margin-left: .4rem;
    }
  }

</style>
