<template>
  <div
    class="atlas-content"
  >
    <network
      id="network"
      ref="network"
      :nodes="network.nodes.value"
      :edges="network.edges.value"
      :options="options"
      class="network"
      @hover-node="handleNodeHover"
      @blur-node="handleBlurNode"
      @click="showGoal"
    />
    <atlas-legend :grouping="grouping" />
    <transition name="fade">
      <div
        v-if="goalId !== 0"
        class="_goal-card"
      >
        <goal-card
          :key="goalId"
          :identifier="`${currentView.uid}_${goalId}`"
          :entity="goalsSvc.selectSingle(goalId)"
          :props="props"
          :component-key="goalId"
          title-layout="column"
          clickable
          wrap
          :update-property="updateProp"
          @open="open"
          @deselect="goalId = 0"
        />
      </div>
    </transition>
    <m-btn-group
      class="_vertical-buttons"
      direction="vertical"
    >
      <m-btn
        fab
        icon="zoom-in"
        @click="zoomIn"
      />
      <m-btn
        fab
        icon="zoom-out"
        @click="zoomOut"
      />
      <m-btn
        fab
        icon="center-focus"
        @click="reset"
      />
      <m-tooltip placement="left">
        <m-btn
          fab
          icon="sync"
          :loading="loadMoreLoading"
          @click="$emit('load-more')"
        />
        <template #title>
          {{ $t('general.loadMore') }}
        </template>
      </m-tooltip>
    </m-btn-group>
  </div>
</template>

<script>
import AtlasLegend from '@/components/goal/atlas/AtlasLegend.vue';
import GoalCard from '@/components/goal/GoalCard.vue';
import Network from '@/lib/vis/Network.vue';
import useGoals from '@/composables/goal/goals';
import useOpenPeekMode from '@/composables/goal/open-peek-mode';
import { EventBus } from '@/lib/event-bus';
import { VIEWS_SERVICE } from '@/lib/constants';
import { companyNodeId } from '@/lib/goal/visify-goals';
import { inject } from 'vue';
import { logCatch } from '@/lib/logger/logger';
import { validationError } from 'shared/constants.json';

export default {
  name: 'AtlasContent',
  props: {
    network: {
      type: Object,
      required: true,
    },
    props: {
      type: Array,
      required: true,
    },
    grouping: {
      type: String,
      default: '',
    },
    groups: {
      type: Object,
      required: true,
    },
    loadMoreLoading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['load-more', 'get-children'],
  setup() {
    const goalsSvc = useGoals();
    const viewsService = inject(VIEWS_SERVICE);
    const peekModeSvc = useOpenPeekMode();
    return { currentView: viewsService.currentView, goalsSvc, peekModeSvc };
  },
  components: { GoalCard, AtlasLegend, Network },
  data() {
    return {
      goalId: 0,
      networkCanvas: null,
    };
  },
  computed: {
    options() {
      return {
        autoResize: true,
        locale: 'en',
        physics: {
          enabled: true,
          repulsion: {
            centralGravity: 0.4,
            springLength: 250,
            springConstant: 0.031,
            nodeDistance: 300,
          },
          stabilization: {
            enabled: true,
            iterations: 1000,
            updateInterval: 25,
          },
          minVelocity: 5.0,
          solver: 'repulsion',
          timestep: 0.8,
        },
        layout: { improvedLayout: false, randomSeed: 1 },
        nodes: {
          shape: 'dot',
          widthConstraint: { maximum: 250 },
          shapeProperties: { interpolation: false },
        },
        interaction: { hover: true },
        groups: this.groups,
      };
    },
  },
  methods: {
    changeCursor(cursor) {
      this.networkCanvas.style.cursor = cursor;
    },
    handleNodeHover(event) {
      if (`${event.node}`.includes('company') || `${event.node}`.includes('space_')) {
        return;
      }
      this.changeCursor('pointer');
    },
    handleBlurNode(event) {
      if (`${event.node}`.includes('company') || `${event.node}`.includes('space_')) {
        return;
      }
      this.changeCursor('default');
    },
    updateProp(value, property, goal) {
      this.goalsSvc.updateProperty(value, property, goal).catch(logCatch((err) => {
        if (err.message.includes(validationError.circularReferenceNotAllowed)) {
          this.snackbar.error(this.$t('goalMutationErrors.circularReference'));
          return;
        }

        this.snackbar.error();
      }));
    },
    open(goal) {
      this.peekModeSvc.openGoal({ goalId: goal.uid, modal: true });
    },
    showGoal(data) {
      if (typeof data.nodes === 'undefined') {
        return;
      }

      if (data.nodes.length === 0 || data.nodes[0] === companyNodeId || (typeof data.nodes[0] === 'string' && data.nodes[0].indexOf('space_') > -1)) {
        this.goalId = 0;
        return;
      }
      const node = this.$refs.network.getNode(data.nodes[0]);
      this.goalId = node.id;

      this.$emit('get-children', node.id);
    },
    zoomIn() {
      this.move({ scale: this.$refs.network.getScale() * 1.4 });
    },
    zoomOut() {
      this.move({ scale: this.$refs.network.getScale() * 0.7 });
    },
    reset() {
      this.$refs.network.fit({
        animation: {
          duration: 300,
          easingFunction: 'easeInOutQuad',
        },
      });
    },
    move(options) {
      this.$refs.network.moveTo({
        ...options,
        animation: {
          duration: 300,
          easingFunction: 'easeInOutQuad',
        },
      });
    },
  },
  mounted() {
    EventBus.$on('show-key-result-details', this.showGoal);
    this.networkCanvas = document
      .getElementById('network')
      .getElementsByTagName('canvas')[0];
  },
  beforeUnmount() {
    EventBus.$off('show-key-result-details', this.showGoal);
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  @import "shared/assets/scss/_box-shadow.scss";

  .atlas-content {
    position: relative;
    width: 100%;
    height: 100%;
    border-top: 1px solid map_get($grey, 'lighten-4');

    .network {
      height: 100%;
    }

    ._goal-card {
      position: absolute;
      top: 2rem;
      right: 2rem;
      width: 95%;
      max-width: 35rem;
      max-height: 35rem;
      overflow-y: auto;
      background: white;
      border-radius: $default-border-radius;

      @include box_shadow(1);
    }

    ._vertical-buttons {
      position: absolute;
      right: 2rem;
      bottom: 2rem;

      @include box_shadow(1);
    }
  }
</style>
