import i18n from '@/lang';
import { BAR } from '@/lib/constants';
import { FONT_COLOR } from '@/lib/charts/colors';
import { favorabilityLabels } from '@/lib/charts/text';
import { getRoundedValue, getValueAsPercent } from '@/lib/charts/format';

const BORDER_RADIUS = 4;
const findIndexWithData = (multiSeries) => {
  let min = -1;
  let max = multiSeries.length - 1;
  multiSeries.forEach((item, index) => {
    const val = item.data[0].y;
    const hasData = typeof val !== 'undefined' && val !== null && val !== 0;
    if (min === -1 && hasData) {
      min = index;
    }
    if (hasData) {
      max = index;
    }
  });
  if (min === -1) {
    min = 0;
  }
  return { min, max };
};

const addRoundedCorners = (multiSeries) => {
  const { min, max } = findIndexWithData(multiSeries);
  return multiSeries.map((item, index) => {
    const copy = {
      ...item,
      borderRadiusBottomLeft: 0,
      borderRadiusBottomRight: 0,
      borderRadiusTopLeft: 0,
      borderRadiusTopRight: 0,
    };
    if (index === min) {
      copy.borderRadiusBottomLeft = BORDER_RADIUS;
      copy.borderRadiusBottomRight = BORDER_RADIUS;
    }
    if (index === max) {
      copy.borderRadiusTopLeft = BORDER_RADIUS;
      copy.borderRadiusTopRight = BORDER_RADIUS;
    }
    return copy;
  });
};

export const getDistributionBarChart = (multiSeries, { showLabels = false, isFactor = false } = {}) => {
  function toolTipFormatter() {
    if (isFactor) {
      return `
      ${i18n.t('charts.answer')}: ${favorabilityLabels(this.series.name)}</br>
${i18n.t('charts.proportion')}: ${getValueAsPercent(this.point.raw.proportion, 0)}
`;
    }
    return `
      ${i18n.t('charts.answer')}: ${this.series.name}</br>
${i18n.t('charts.amountOfAnswers')}: ${this.y}</br>
${i18n.t('charts.proportion')}: ${getValueAsPercent(this.point.raw.proportion, 0)}
`;
  }

  return {
    chart: {
      type: BAR,
      height: 32,
      margin: [-17, 0, -17, 0],
    },
    xAxis: { visible: false },
    yAxis: {
      visible: false,
      reversedStacks: false,
    },
    plotOptions: {
      series: {
        borderWidth: 0,
        stacking: 'percent',
        dataLabels: {
          enabled: showLabels,
          style: {
            textOutline: 0,
            textAlign: 'center',
            color: FONT_COLOR,
          },
          formatter() {
            return `${getRoundedValue(this.percentage, 0)}%`;
          },
        },
      },
    },
    tooltip: {
      shared: false,
      useHTML: true,
      formatter: toolTipFormatter,
    },
    series: addRoundedCorners(multiSeries),
  };
};
