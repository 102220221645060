import { BaseHandler } from '@/lib/filter/base-translator/handler';
import { makeStaticUserFn } from '@/lib/filter/user/static-users';
import { reverseEdge } from 'shared/api/query/edges';
import { user } from 'shared/api/query/configs.json';

const childrenFn = (varName) => ([
  {
    attr: reverseEdge(user.edges.values),
    model: user.model,
    var: varName,
  },
]);

/* eslint-disable default-param-last */
export class UserFilterHandler {
  constructor(isFilterMode = false, varIdFn) {
    this.baseHandler = new BaseHandler({
      model: user.model,
      childrenFn,
      staticUserFn: makeStaticUserFn(),
      isFilterMode,
      varIdFn,
    });
  }

  Translate(tree) {
    return this.baseHandler.Translate(tree);
  }
}
/* eslint-enable default-param-last */
