<template>
  <div
    :class="['m-spacer']"
  />
</template>

<script>
export default { name: 'MSpacer' };
</script>

<style lang="scss" type="text/scss">
  .m-spacer {
    flex-grow: 1;
  }
</style>
