import useViewParamsProps from '@/composables/view-params/view-params-props';
import { AND } from 'shared/api/query/filter';
import { DateTime } from 'luxon';
import { GoalFilterHandler } from '@/lib/filter/goal/handler';
import { compareToDateTime } from '@/lib/compare-to';
import { computed } from 'vue';
import { viewType as viewTypes } from 'shared/constants.json';

export default function useCascadeViewParams(viewsService, defaultFilter, loggedInUserAccount, searchTerm, selectedCycles) {
  const queryFilter = computed(() => {
    if (defaultFilter === null) {
      return viewsService.currentView.value.params.filter;
    }
    if (viewsService.currentView.value.params.filter === null) {
      return {
        account: { uid: loggedInUserAccount.value.uid },
        op: AND,
        children: [defaultFilter],
      };
    }
    return {
      account: { uid: loggedInUserAccount.value.uid },
      op: AND,
      children: [defaultFilter, ...viewsService.currentView.value.params.filter.children],
    };
  });

  const gqlFilterObject = computed(() => {
    const handler = new GoalFilterHandler();
    return handler.Translate(queryFilter.value);
  });

  const compareToNow = DateTime.local();
  const compareTo = computed(() => {
    const compareTo = compareToDateTime(viewsService.currentView.value.params.compare, compareToNow);
    if (compareTo === null) {
      return [];
    }
    return [compareTo.toISO()];
  });

  const order = computed(() => {
    if (viewsService.currentView.value.params === undefined || viewsService.currentView.value.params.order === undefined) {
      return [];
    }
    return viewsService.currentView.value.params.order;
  });

  const applyFilterOnFirstLevelOnly = computed(() => viewsService.currentView.value.params.applyFilterOnFirstLevelOnly);

  const showParents = computed(() => {
    // TODO: Only cascade has ever been allowed to showParents, e.g. in tree parent alignment was not a visible prop.
    //  We might want to show parents in tree if we do work towards showing longer parent chains in different views, and create "disabled" cards
    if ([viewTypes.cascade].includes(viewsService.currentView.value.viewType)) {
      return viewsService.currentView.value.params.showParents === true;
    }
    return false;
  });

  const { props } = useViewParamsProps(viewsService.currentView);

  return {
    order,
    applyFilterOnFirstLevelOnly,
    showParents,
    queryFilter,
    gqlFilterObject,
    compareTo,
    searchTerm,
    selectedCycles,
    props,
  };
}
