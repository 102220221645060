<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.336 6.91419C18.7356 6.43742 19.469 6.43742 19.8687 6.91419L20.4615 7.62135C20.773 7.99298 20.773 8.53456 20.4615 8.90619L12.7664 18.0858C12.3667 18.5626 11.6333 18.5626 11.2336 18.0858L3.53853 8.90619C3.227 8.53456 3.227 7.99298 3.53853 7.62135L4.13132 6.91419C4.531 6.43742 5.26436 6.43742 5.66403 6.91419L12 14.4725L18.336 6.91419Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default { name: 'Down' };
</script>

<style scoped lang="scss" type="text/scss">

</style>
