import useRepo from '@/nebula/repo';
import useUserProvisioning from '@/composables/user-provisioning/user-provisioning';
import { userProvisioningMapping as userProvisioningMappingConfig } from 'shared/api/query/configs.json';

export default function useUserProvisioningMapping() {
  const repo = useRepo(userProvisioningMappingConfig.model);
  const { userProvisioning } = useUserProvisioning();

  const createSingle = (entity) => repo.createSingle({ ...entity, userProvisioning: userProvisioning.value });

  return { ...repo, createSingle };
}
