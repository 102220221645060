import { EventBus } from '@/lib/event-bus';
import { useI18n } from 'vue-i18n';

export default function useSnackBar() {
  const { t } = useI18n();

  const error = (message = t('error.default'), options = undefined) => {
    EventBus.$emit('show-snackbar', { color: 'error', message, ...options });
  };

  const success = (message, options = undefined) => {
    EventBus.$emit('show-snackbar', { color: 'success', message, ...options });
  };

  const info = (message, options = undefined) => {
    EventBus.$emit('show-snackbar', { color: 'info', message, ...options });
  };

  return {
    error,
    success,
    info,
  };
}
