<template>
  <div class="grid-page-tile-checkin-list">
    <updates-explorer
      show-create-view
      show-views-selector
      :read-only="props.readOnly"
    />
  </div>
</template>

<script setup>
import UpdatesExplorer from '@/components/updates/UpdatesExplorer.vue';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useUpdateDefaultProps from '@/composables/saved-views/update-default-props';
import useViewServiceInit from '@/composables/saved-views/view-service-init';
import { AND } from '@/lib/filter/scope-tree';
import { CREATED_AT } from 'shared/api/query/constants';
import { VIEWS_SERVICE } from '@/lib/constants';
import { provide, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { viewApplication, viewType } from 'shared/constants.json';

const loggedInUserAccountSvc = useLoggedInUserAccount();
const i18n = useI18n();

const isFeedRef = ref(false);

const defaultPropsSvc = useUpdateDefaultProps({ isFeed: isFeedRef });
const viewServiceInit = useViewServiceInit();

const viewApp = viewApplication.gridPageTileCheckin;
const defaultView = {
  viewType: viewType.list,
  title: i18n.t('list.list'),
  viewApplication: viewApp,
  params: {
    filter: { account: { uid: loggedInUserAccountSvc.loggedInUserAccount.value.uid }, children: [], op: AND },
    order: [{ attr: CREATED_AT, desc: true }],
    props: [],
  },
};

const props = defineProps({
  gridPageTile: { type: Object, required: true },
  readOnly: { type: Boolean, default: false },
});

let viewConfig = viewServiceInit.defaultConfig();
viewConfig = viewServiceInit.withDefaultProps(viewConfig, defaultPropsSvc.defaultProps);
viewConfig = viewServiceInit.withDefaultView(viewConfig, defaultView);
viewConfig = viewServiceInit.withViewApplication(viewConfig, viewApp);
viewConfig = viewServiceInit.withReadOnly(viewConfig, props.readOnly);
viewConfig = viewServiceInit.withGridPageTile(viewConfig, props.gridPageTile);

const viewSvc = viewServiceInit.gridPageViewService(viewConfig);
provide(VIEWS_SERVICE, viewSvc);

watch(viewSvc.currentView, (currentView) => {
  if (currentView.viewType === viewType.feed) {
    isFeedRef.value = true;
    return;
  }
  isFeedRef.value = false;
}, { immediate: true });

</script>

<style
  scoped
  lang="scss"
  type="text/scss"
>
  .grid-page-tile-checkin-list {
    padding: 0 1.6rem .8rem 1.6rem;
    height: inherit;
    overflow: auto;
  }
</style>
