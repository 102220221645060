import { UID } from 'shared/api/query/constants';
import {
  gridPageTile as gridPageTileConfig,
  personalAppSettings as personalAppSettingsConfig,
  planning as planningConfig,
  resourceSettings as resourceSettingsConfig,
  space as spaceConfig,
  viewCollection as viewCollectionConfig,
} from 'shared/api/query/configs.json';

export const viewCollectionChildren = [
  { attr: UID },
  { attr: viewCollectionConfig.edges.viewOrder, default: [] },
  { attr: viewCollectionConfig.edges.viewApplication },
  { attr: viewCollectionConfig.edges.planning, model: planningConfig.model, children: [{ attr: UID }] },
  { attr: viewCollectionConfig.edges.gridPageTile, model: gridPageTileConfig.model, children: [{ attr: UID }] },
  { attr: viewCollectionConfig.edges.space, model: spaceConfig.model, children: [{ attr: UID }] },
  { attr: viewCollectionConfig.edges.resourceSettings, model: resourceSettingsConfig.model, children: [{ attr: UID }] },
  { attr: viewCollectionConfig.edges.personalAppSettings, model: personalAppSettingsConfig.model, children: [{ attr: UID }] },
];
