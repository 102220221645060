<template>
  <div class="form-template-list">
    <m-content padding-x="layout">
      <page-header
        :title="title"
        heading="h3"
        underlined
        no-padding-x
        class="_header"
      />
    </m-content>
    <full-screen-spinner v-if="loading" />
    <template v-else>
      <m-content
        class="_content"
        padding-x="layout"
      >
        <div class="_list">
          <card-button
            v-if="canCreate"
            :title="$t('formTemplateList.createTitle')"
            icon="plus"
            class="_item"
            @click="showModal = true"
          />
          <m-dialog
            v-model:value="showModal"
            :title="$t('formTemplateList.modalHeading')"
            :ok-text="$t('general.save')"
            :cancel-text="$t('general.cancel')"
            @ok="$refs.createFormCard.create()"
            @cancel="showModal = false"
          >
            <create-form-template-form
              ref="createFormCard"
              :type="type"
              @created="created"
            />
          </m-dialog>
          <form-template-card
            v-for="item in sortedTemplates"
            :key="item.uid"
            :form-template="item"
            class="_item"
          />
        </div>
      </m-content>
    </template>
  </div>
</template>

<script>
import CardButton from '@/components/CardButton.vue';
import CreateFormTemplateForm from '@/components/CreateFormTemplateForm.vue';
import FormTemplateCard from '@/components/FormTemplateCard.vue';
import FullScreenSpinner from 'shared/components/FullScreenSpinner.vue';
import PageHeader from 'shared/components/PageHeader.vue';
import useAccess from '@/composables/access/access';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import { CREATE_FORM_TEMPLATE } from '@/route-names';
import { accessGroupFlag, formTemplateType, routeName } from 'shared/constants.json';
import { mapActions, mapState } from 'vuex';
import { shallowCopy } from 'shared/lib/copy';

export default {
  name: 'FormTemplateList',
  components: { FormTemplateCard, PageHeader, CardButton, CreateFormTemplateForm, FullScreenSpinner },
  setup() {
    const { userHasRight } = useAccess();
    const { loggedInUserAccount } = useLoggedInUserAccount();
    return {
      userHasRight,
      account: loggedInUserAccount,
    };
  },
  data() {
    return { showModal: false, loading: true };
  },
  computed: {
    ...mapState({ templates: (state) => state.formTemplates }),
    formTemplates() {
      return this.templates.filter((t) => t.type === this.type);
    },
    title() {
      if (this.$route.name === routeName.instantFeedbackTemplateList) {
        return this.$t('settingsNavigation.instantFeedbackTemplateList');
      }

      return this.$t('formTemplateList.title');
    },
    type() {
      if (this.$route.name === routeName.instantFeedbackTemplateList) {
        return formTemplateType.instantFeedback;
      }
      return formTemplateType.survey;
    },
    canCreate() {
      return this.userHasRight([accessGroupFlag.formTemplateWriteAccess]);
    },
    sortedTemplates() {
      return shallowCopy(this.formTemplates).sort((a) => (a.releasedAt !== null ? -1 : 1));
    },
  },
  methods: {
    ...mapActions([
      'getFormTemplatesByAccount',
    ]),
    created({ formTemplate }) {
      this.showModal = false;
      this.$router.push({ name: CREATE_FORM_TEMPLATE, params: { formTemplateId: formTemplate.uid } });
    },
    retrieveFormTemplateList() {
      this.loading = true;
      this.getFormTemplatesByAccount({ accountId: this.account.uid, type: this.type }).then((response) => {
        this.loading = false;
        if (response.status !== 200) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
        }
      });
    },
  },
  watch: {
    type() {
      this.retrieveFormTemplateList();
    },
  },
  mounted() {
    this.retrieveFormTemplateList();
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .form-template-list {
    ._header {
      margin-bottom: 2rem;
    }

    ._list {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -1rem;

      ._item {
        flex: 0 1 17.5rem;
        height: 21rem;
        margin: 1rem;
      }
    }
  }
</style>
