import colors from 'shared/colors';
import { isNotANumber } from '@/lib/charts/format';

export const FONT_COLOR = colors.grey.darken2;

export const mcColorSet = [
  colors.green.lighten2,
  colors.red.lighten2,
  colors.orange.lighten2,
  colors.blue.lighten2,
  colors.yellow.lighten2,
  colors.green.base,
  colors.orange.base,
  colors.blue.base,
  colors.red.base,
  colors.yellow.base,
  colors.orange.lighten1,
  colors.blue.lighten1,
  colors.green.lighten1,
  colors.red.lighten1,
  colors.yellow.lighten1,
];

export const getColorForNumber = (integer) => {
  const mod = integer % mcColorSet.length;
  return mcColorSet[mod];
};

export const applyRandomColorsToSeries = (series) => {
  const dataSeries = [];
  series.forEach((item, index) => {
    dataSeries.push({
      ...item,
      color: getColorForNumber(index),
    });
  });
  return dataSeries;
};

export const getColorForLikertValue = (value, max, startsFromZero = true) => {
  const colorMatrix = [
    [colors.red.lighten1, colors.green.base],
    [colors.red.lighten1, colors.grey.lighten3, colors.green.base],
    [colors.red.lighten1, colors.red.lighten3, colors.green.lighten2, colors.green.base],
    [colors.red.lighten1, colors.red.lighten3, colors.grey.lighten3, colors.green.lighten2, colors.green.base],
    [colors.red.lighten1, colors.red.lighten3, colors.red.lighten5, colors.green.lighten4, colors.green.lighten2,
      colors.green.base],
    [colors.red.lighten1, colors.red.lighten3, colors.red.lighten5, colors.grey.lighten3, colors.green.lighten4,
      colors.green.lighten2, colors.green.base],
    [colors.red.lighten1, colors.red.lighten3, colors.red.lighten4, colors.red.lighten5,
      colors.green.lighten4, colors.green.lighten3, colors.green.lighten2, colors.green.base],
    [colors.red.lighten1, colors.red.lighten3, colors.red.lighten4, colors.red.lighten5,
      colors.grey.lighten3, colors.green.lighten4, colors.green.lighten3, colors.green.lighten2, colors.green.base],
    [colors.red.lighten1, colors.red.lighten2, colors.red.lighten3, colors.red.lighten4,
      colors.red.lighten5, colors.green.lighten4, colors.green.lighten3, colors.green.lighten2,
      colors.green.lighten1, colors.green.base],
    [colors.red.lighten1, colors.red.lighten2, colors.red.lighten3, colors.red.lighten4,
      colors.red.lighten5, colors.grey.lighten3, colors.green.lighten4, colors.green.lighten3,
      colors.green.lighten2, colors.green.lighten1, colors.green.base],
  ];

  switch (startsFromZero) {
    case true: {
      if (typeof colorMatrix[max - 1] === 'undefined' || typeof colorMatrix[max - 1][value] === 'undefined') {
        return colors.grey.base;
      }
      return colorMatrix[max - 1][value];
    }
    case false: {
      if (typeof colorMatrix[max - 2] === 'undefined' || typeof colorMatrix[max - 2][value - 1] === 'undefined') {
        return colors.grey.base;
      }
      return colorMatrix[max - 2][value - 1];
    }
    default:
      return colors.grey.base;
  }
};

export const colorizeFromBadToGood = (list, maxValue, startsFromZero) => list.map((item, index) => {
  let value = index + 1;
  if (startsFromZero) {
    value = index;
  }
  return {
    ...item,
    color: getColorForLikertValue(value, maxValue, startsFromZero),
  };
});

export const colorizeFlatMultiSeriesFromBadToGood = (
  flatMultiSeries,
  maxValue,
  startsFromZero,
) => flatMultiSeries.map((item) => ({
  ...item,
  data: colorizeFromBadToGood(item.data, maxValue, startsFromZero),
}));

export const getColorForPercentage = (value) => {
  if (isNotANumber(value)) {
    return colors.grey.lighten3;
  }

  if (value < 0.1) {
    return colors.red.lighten1;
  }
  if (value < 0.2) {
    return colors.red.lighten2;
  }
  if (value < 0.3) {
    return colors.red.lighten3;
  }
  if (value < 0.4) {
    return colors.red.lighten4;
  }
  if (value < 0.5) {
    return colors.red.lighten5;
  }
  if (value < 0.6) {
    return colors.green.lighten4;
  }
  if (value < 0.7) {
    return colors.green.lighten3;
  }
  if (value < 0.7) {
    return colors.green.lighten2;
  }
  if (value < 0.9) {
    return colors.green.lighten1;
  }
  return colors.green.base;
};

export const getColorForDelta = (value) => {
  if (isNotANumber(value)) {
    return colors.grey.lighten3;
  }
  const val = value * 100;

  if (val < -35) {
    return colors.red.lighten1;
  }
  if (val < -20) {
    return colors.red.lighten2;
  }
  if (val < -10) {
    return colors.red.lighten3;
  }
  if (val < -5) {
    return colors.red.lighten4;
  }
  if (val < 0) {
    return colors.red.lighten5;
  }
  if (val < 5) {
    return colors.green.lighten4;
  }
  if (val < 10) {
    return colors.green.lighten3;
  }
  if (val < 20) {
    return colors.green.lighten2;
  }
  if (val < 35) {
    return colors.green.lighten1;
  }
  return colors.green.base;
};
