<template>
  <div class="reminder-editor">
    <div class="_top">
      <div class="_label">
        {{ $t('reminderEditor.after') }}
      </div>
      <div class="_input">
        <div class="_number">
          <m-input-number
            v-model:value="amount"
            :min="1"
          />
        </div>
        <div class="_unit">
          <m-select
            v-model:value="unit"
            :items="items"
            class="_select"
          />
        </div>
      </div>
    </div>
    <div class="_bottom">
      <m-btn
        color="primary"
        block
        @click="submit"
      >
        <template v-if="reminder === null">
          {{ $t('general.add') }}
        </template>
        <template v-else>
          {{ $t('general.save') }}
        </template>
      </m-btn>
    </div>
  </div>
</template>

<script>
import { camelCase } from 'lodash-es';
import { durationUnit } from '@/constants.json';

export default {
  name: 'ReminderEditor',
  props: {
    reminder: {
      type: Object,
      default: () => null,
    },
  },
  emits: ['create', 'update'],
  data() {
    return {
      amount: 1,
      unit: durationUnit.day,
    };
  },
  computed: {
    items() {
      return [
        {
          text: this.$t(`durationUnit.${camelCase(durationUnit.minute)}`, this.amount),
          value: durationUnit.minute,
        },
        {
          text: this.$t(`durationUnit.${camelCase(durationUnit.hour)}`, this.amount),
          value: durationUnit.hour,
        },
        {
          text: this.$t(`durationUnit.${camelCase(durationUnit.day)}`, this.amount),
          value: durationUnit.day,
        },
      ];
    },
    after() {
      switch (this.unit) {
        case durationUnit.day:
          return this.amount * 24 * 60 * 60 * (10 ** 9);
        case durationUnit.hour:
          return this.amount * 60 * 60 * (10 ** 9);
        case durationUnit.minute:
          return this.amount * 60 * (10 ** 9);
        default:
          return 0;
      }
    },
  },
  methods: {
    submit() {
      if (this.reminder === null) {
        this.$emit('create', { after: this.after });
      }
      this.$emit('update', { ...this.reminder, after: this.after });
    },
    init() {
      // in go durations are an int64 in nanoseconds (10 to the power of -9);
      const minutes = this.reminder.after / (10 ** 9) / 60;

      if (minutes % (24 * 60) === 0) {
        this.unit = durationUnit.day;
        this.amount = minutes / (24 * 60);
        return;
      }
      if (minutes % 60 === 0) {
        this.unit = durationUnit.hour;
        this.amount = minutes / 60;
        return;
      }

      this.unit = durationUnit.minute;
      this.amount = minutes;
    },
  },
  created() {
    if (this.reminder === null) {
      return;
    }

    this.init();
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .reminder-editor {
    ._top {
      ._label {
        color: $font-color-secondary;
      }

      ._input {
        display: flex;

        ._number {
          width: 9rem;
        }

        ._unit {
          flex: 1 0 10rem;
          margin-left: .8rem;

          ._select {
            min-width: 10rem;
          }
        }
      }
    }

    ._bottom {
      margin-top: 1rem;
    }
  }
</style>
