<template>
  <div class="sorter-builder">
    <div class="_items">
      <sort-order
        v-for="(order, index) in orders"
        :key="getKey(order)"
        class="_item"
        :order="order"
        :orders="orders"
        :sort-options="fixedSortOptions"
        :disabled="disabled"
        @change="change(index, $event)"
        @delete="deleteOrder(index)"
      />
    </div>
    <div
      v-if="!disabled && hasAddableEdge"
      class="_add"
    >
      <m-btn
        v-if="fixedSortOptions.length > value.length"
        class="_btn"
        icon="plus"
        small
        hide-border
        @click="add"
      >
        {{ $t('sorterBuilder.add') }}
      </m-btn>
    </div>
    <div
      v-if="disabled && orders.length === 0"
      class="_empty"
    >
      {{ $t('sorterBuilder.empty') }}
    </div>
  </div>
</template>

<script>
import SortOrder from '@/components/SortOrder.vue';
import { copy } from 'shared/lib/copy';
import { isEqual } from 'lodash-es';
import { orderEntitiesEqual } from '@/lib/sort';

export default {
  name: 'SorterBuilder',
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    sortOptions: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input', 'update:value'],
  components: { SortOrder },
  data() {
    return { orders: [] };
  },
  computed: {
    fixedSortOptions() {
      return this.sortOptions.map((so) => ({ ...so, desc: so.desc === undefined ? false : so.desc }));
    },
    firstAddableEdge() {
      return this.fixedSortOptions.find((option) => typeof this.orders.find((fromOrders) => orderEntitiesEqual(option, fromOrders)) === 'undefined');
    },
    hasAddableEdge() {
      return this.firstAddableEdge !== undefined;
    },
  },
  methods: {
    getKey(order) {
      if (order.langs !== undefined && order.langs.length === 1) {
        return order.langs[0];
      }
      return order.attr;
    },
    add() {
      this.orders.push(this.firstAddableEdge);
    },
    change(index, value) {
      this.orders.splice(
        index,
        1,
        value,
      );
    },
    deleteOrder(index) {
      this.orders.splice(
        index,
        1,
      );
    },
  },
  watch: {
    orders: {
      handler(val) {
        if (isEqual(val, this.value)) {
          return;
        }
        this.$emit('input', copy(val));
        this.$emit('update:value', copy(val));
      },
      deep: true,
    },
  },
  created() {
    this.orders = copy(this.value);
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .sorter-builder {
    min-width: 20rem;

    ._items {
      ._item {
        margin: .8rem 0;
      }
    }

    ._empty {
      color: $font-color-tertiary;
    }
  }
</style>
