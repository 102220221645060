<template>
  <div class="user-provisioning-errors">
    <page-title
      heading="h3"
      :title="$t('userProvisioningErrors.title')"
      :style="{ paddingBottom: '2.4rem'}"
    />
    <m-table
      :columns="columns"
      :data-source="datasource"
      row-key="uid"
    />
  </div>
</template>

<script>
import PageTitle from 'shared/components/PageTitle.vue';
import useUserProvisioning from '@/composables/user-provisioning/user-provisioning';

export default {
  name: 'UserProvisioningStatus',
  components: { PageTitle },
  setup() {
    const { userProvisioning } = useUserProvisioning();
    return { userProvisioning };
  },
  computed: {
    columns() {
      return [
        {
          key: 'givenName',
          dataIndex: 'givenName',
          title: this.$t('userProvisioningErrors.givenName'),
        },
        {
          key: 'familyName',
          dataIndex: 'familyName',
          title: this.$t('userProvisioningErrors.familyName'),
        },
        {
          key: 'email',
          dataIndex: 'email',
          title: this.$t('userProvisioningErrors.email'),
        },
        {
          key: 'error',
          dataIndex: 'error',
          title: this.$t('userProvisioningErrors.error'),
        },
      ];
    },
    datasource() {
      return this.userProvisioning.provisionedUsers.filter((pu) => pu.provisioningError !== null).map((pu) => ({
        uid: pu.uid,
        givenName: pu.name.givenName,
        familyName: pu.name.familyName,
        email: pu.emails[0].value,
        error: pu.provisioningError,
      }));
    },
  },
};
</script>

<style lang="scss" text="text/scss" scoped>
</style>
