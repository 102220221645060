<template>
  <full-screen-spinner v-if="dataLoading || space === undefined || space === null" />
  <space-details-template
    v-else
    :tabs="tabs"
    :property="property"
    :space="space"
    show-top-bar
    show-actions
  />
</template>

<script>
import FullScreenSpinner from 'shared/components/FullScreenSpinner.vue';
import SpaceDetailsTemplate from '@/views/SpaceDetailsTemplate.vue';
import useAccess from '@/composables/access/access';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useProperties from '@/composables/property/property';
import useSpaces from '@/composables/space/spaces';
import useViewNavigator from '@/composables/saved-views/navigator';
import { GOAL_FEED_VIEW, GOAL_INSIGHT_VIEW, GOAL_VIEW, UPDATE_VIEW, USER_VIEW } from '@/route-params';
import { HOME, NOT_FOUND } from '@/route-names';
import { accessGroupFlag, moduleFlag, propertyApplication, viewApplication } from 'shared/constants.json';
import { logCatch } from '@/lib/logger/logger';

export default {
  name: 'SpaceDetails',
  setup() {
    const { accountHasFeature, userHasRight } = useAccess();
    const { loggedInUserAccount } = useLoggedInUserAccount();
    const { linkToView, linkToRoute } = useViewNavigator();
    const { selectSingle } = useSpaces();
    const { spaceProperty } = useProperties();
    return {
      accountHasFeature,
      userHasRight,
      account: loggedInUserAccount,
      linkToView,
      linkToRoute,
      property: spaceProperty,
      selectSingle,
    };
  },
  props: {
    optionId: {
      type: [String, Number],
      default: '',
    },
  },
  components: { SpaceDetailsTemplate, FullScreenSpinner },
  data() {
    return { dataLoading: false };
  },
  computed: {
    tabs() {
      const tabs = [];
      if (this.property === null) {
        return tabs;
      }
      if (this.property.usedFor.includes(propertyApplication.goal) && this.accountHasFeature([moduleFlag.goals])) {
        tabs.push({
          title: this.account.goalSettings.featureNamePlural,
          to: this.linkToView(GOAL_VIEW, viewApplication.goalSpace, this.space),
          view: GOAL_VIEW,
        });
      }
      if (this.property.usedFor.includes(propertyApplication.goal) && this.accountHasFeature([moduleFlag.goals])) {
        tabs.push({
          title: `${this.$t('navigation.feed')}`,
          to: this.linkToView(GOAL_FEED_VIEW, viewApplication.updateFeedSpace, this.space),
          view: GOAL_FEED_VIEW,
        });
      }
      if (this.property.usedFor.includes(propertyApplication.update) && this.accountHasFeature([moduleFlag.updates])) {
        tabs.push({
          title: this.$t('navigation.updatesExplorer'),
          to: this.linkToView(UPDATE_VIEW, viewApplication.updateSpace, this.space),
          view: UPDATE_VIEW,
        });
      }
      if (this.property.usedFor.includes(propertyApplication.goal) && this.accountHasFeature([moduleFlag.goals]) && this.userHasRight([accessGroupFlag.goalDashboardView])) {
        tabs.push({
          title: this.$t('navigation.goalInsights'),
          to: this.linkToView(GOAL_INSIGHT_VIEW, viewApplication.insightSpace, this.space),
          view: GOAL_INSIGHT_VIEW,
        });
      }
      if (this.property.usedFor.includes(propertyApplication.user)) {
        tabs.push({
          title: this.$t('navigation.users'),
          to: this.linkToView(USER_VIEW),
          view: USER_VIEW,
        });
      }

      return tabs;
    },
    id() {
      const id = parseInt(this.optionId, 10);
      if (Number.isNaN(id)) {
        return 0;
      }

      return id;
    },
    space() {
      return this.selectSingle(this.id);
    },
  },
  methods: {
    getData() {
      this.dataLoading = true;
      this.selectSingle(this.id, { commitToRemote: true }).then((data) => {
        this.dataLoading = false;
        if (data === undefined) {
          this.$router.replace({ name: NOT_FOUND });
          return;
        }
        this.titleCache = data.label;
        this.emojiAdded = data.icon !== '';
      }).catch(logCatch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      }));
    },
  },
  watch: {
    id(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getData();
      }
    },
    space(newVal, oldVal) {
      if (oldVal !== undefined && newVal === undefined) {
        this.$router.replace(this.linkToRoute(this.$t('navigation.home'), HOME).to);
      }
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style scoped lang="scss">
</style>
