import { CUSTOM_DATE, NONE, ONE_DAY_AGO, ONE_MONTH_AGO, ONE_WEEK_AGO } from '@/lib/constants';
import { DateTime } from 'luxon';

export const showCompareTo = (compareTo) => {
  if (typeof compareTo === 'undefined' || compareTo === null) {
    return false;
  }
  return compareTo.value !== NONE;
};

export const compareToDateTime = (compareTo, now = undefined) => {
  const localNow = now || DateTime.local();

  if (typeof compareTo === 'undefined' || compareTo === null) {
    return null;
  }
  switch (compareTo.value) {
    case NONE:
      return null;
    case ONE_DAY_AGO:
      return localNow.minus({ days: 1 });
    case ONE_WEEK_AGO:
      return localNow.minus({ week: 1 });
    case ONE_MONTH_AGO:
      return localNow.minus({ month: 1 });
    case CUSTOM_DATE:
      return DateTime.fromISO(compareTo.date);
    default:
      return null;
  }
};
