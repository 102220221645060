import { findInArray } from 'shared/lib/array/array';

const depthFirstOrder = (res, node) => [...res, node, ...node.children.reduce(depthFirstOrder, [])];

export const treeToArray = (nodes) => nodes.reduce(depthFirstOrder, []);

const mapChildren = (elements, depth) => (c) => {
  const node = findInArray({ haystack: elements, needle: c.uid });
  if (node === null) {
    return c;
  }

  const children = elements.filter((el) => findInArray({ haystack: el.parents, needle: c.uid }) !== null)
    .map(mapChildren(elements, depth + 1));
  const childrenMaxDepth = children.reduce((acc, c) => (Math.max(acc, c.childrenMaxLevel)), depth);
  return {
    ...node,
    children,
    depth,
    childrenMaxDepth,
  };
};

export const arrayToTree = (elements) => elements.filter((el) => el.parents.length === 0)
  .map(mapChildren(elements, 0));
