import IsEqual from 'lodash-es/isEqual';
import { accessPolicyType } from 'shared/constants.json';
import { isNullOrUndefined } from 'shared/lib/object/object';
import {
  accessPolicy as policyConfig,
  accessPolicyScope as scopeConfig,
} from 'shared/api/query/configs.json';

const getHighestAccessRight = (rights) => {
  if (rights.includes(accessPolicyType.full)) {
    return accessPolicyType.full;
  }
  if (rights.includes(accessPolicyType.write)) {
    return accessPolicyType.write;
  }
  if (rights.includes(accessPolicyType.read)) {
    return accessPolicyType.read;
  }
  return accessPolicyType.disabled;
};

export const getAccessTypeOfUser = (entity, user) => {
  if (!isNullOrUndefined(entity.creator) && !isNullOrUndefined(user) && entity.creator.uid === user.uid) {
    return accessPolicyType.full;
  }

  const { accessPolicy } = entity;
  if (isNullOrUndefined(accessPolicy)) {
    return accessPolicyType.disabled;
  }

  if (accessPolicy[policyConfig.edges.accountAccess] === accessPolicyType.full) {
    return accessPolicyType.full;
  }

  if (accessPolicy[policyConfig.edges.scopes].length === 0) {
    return accessPolicy[policyConfig.edges.accountAccess];
  }

  const rights = accessPolicy[policyConfig.edges.scopes].reduce((res, next) => {
    if (next[scopeConfig.edges.userIsInScope]) {
      return [...res, next[scopeConfig.edges.accessType]];
    }
    return res;
  }, [accessPolicy[policyConfig.edges.accountAccess]]);

  return getHighestAccessRight(rights);
};

export const copyUserScope = (scope) => {
  if (isNullOrUndefined(scope)) {
    return scope;
  }
  const res = { ...scope };
  delete res.uid;

  if (!isNullOrUndefined(res.timeRange)) {
    delete res.timeRange.uid;
  }
  if (!isNullOrUndefined(res.numberRange)) {
    delete res.numberRange.uid;
  }
  return res;
};

export const copyUserScopeTree = (scopeTree) => {
  if (isNullOrUndefined(scopeTree)) {
    return scopeTree;
  }
  const res = { ...scopeTree };
  delete res.uid;
  if (!isNullOrUndefined(res.scope)) {
    res.scope = copyUserScope(res.scope);
  }
  if (Array.isArray(res.children)) {
    res.children = res.children.map((c) => copyUserScopeTree(c));
  }
  return res;
};

export const copyAccessPolicy = (policy) => {
  if (isNullOrUndefined(policy)) {
    return null;
  }
  const res = { ...policy };
  delete res.uid;
  if (!Array.isArray(res.scopes)) {
    return res;
  }

  res.scopes = res.scopes.map((s) => {
    const cp = { ...s };
    delete cp.uid;
    cp.scope = copyUserScopeTree(cp.scope);
    return cp;
  });
  return res;
};

/* returns scopes, that are common in a list of access policy scopes (array of array of scopes) */
export const accessPolicyCommonScopes = (accessPolicyScopes) => {
  if (accessPolicyScopes.length === 0) {
    return [];
  }

  const commonUsers = (r, s) => {
    if (r.users.length !== s.users.length) {
      return false;
    }
    return IsEqual(r.users.map((u) => u.uid).sort(), s.users.map((u) => u.uid).sort());
  };

  return accessPolicyScopes.reduce(
    (res, next) => res.filter((r) => next.find((s) => {
      if (r.accessType !== s.accessType) {
        return false;
      }

      if (s.scope !== null && r.scope !== null) {
        return s.scope.treeHash === r.scope.treeHash;
      }

      if (s.scope === null && r.scope === null) {
        return commonUsers(r, s);
      }

      return false;
    })),
    accessPolicyScopes[0],
  );
};

export const getDirectUsersFromAccessPolicy = (accessPolicy) => {
  if (accessPolicy === null || typeof accessPolicy === 'undefined') {
    return [];
  }

  return accessPolicy.scopes.reduce((res, next) => {
    if (!Array.isArray(next.users)) {
      return res;
    }
    if (typeof next.deletedAt !== 'undefined') {
      return res;
    }

    return [...res, ...next.users];
  }, []);
};
