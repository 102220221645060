import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import { AND } from '@/lib/filter/scope-tree';
import { viewType as viewTypes } from 'shared/constants.json';

export default function useParamDefaulter() {
  const loggedInUserAccountSvc = useLoggedInUserAccount();

  const setUserScopeDefaults = (userScope) => {
    if (userScope.relation === undefined) {
      userScope.relation = null;
    }

    if (userScope.spaces === undefined || userScope.spaces === null) {
      userScope.spaces = [];
    }

    if (userScope.selectedOptions === undefined || userScope.selectedOptions === null) {
      userScope.selectedOptions = [];
    }

    if (userScope.users === undefined || userScope.users === null) {
      userScope.users = [];
    }

    if (userScope.timestamp === undefined) {
      userScope.timestamp = null;
    }

    if (userScope.number === undefined) {
      userScope.number = null;
    }

    return userScope;
  };

  const setUserScopeTreeDefaults = (userScopeTree) => {
    if (Array.isArray(userScopeTree.children) && userScopeTree.children.length > 0) {
      userScopeTree.children = userScopeTree.children.map((c) => setUserScopeTreeDefaults(c));
    }

    if (userScopeTree.scope === undefined || userScopeTree.scope === null) {
      return userScopeTree;
    }

    userScopeTree.scope = setUserScopeDefaults(userScopeTree.scope);

    return userScopeTree;
  };

  const setDefaults = (params, viewType) => {
    if (params.order === null || params.order === undefined) {
      params.order = [];
    }

    if (params.props === null || params.props === undefined) {
      params.props = [];
    }

    if (params.applyFilterOnFirstLevelOnly === null || params.applyFilterOnFirstLevelOnly === undefined) {
      params.applyFilterOnFirstLevelOnly = false;
    }

    if (params.filter === null || params.filter === undefined) {
      params.filter = { account: { uid: loggedInUserAccountSvc.loggedInUserAccount.value.uid }, children: [], op: AND };
    }

    if (params.filter.account === null || params.filter.account === undefined) {
      params.filter.account = { uid: loggedInUserAccountSvc.loggedInUserAccount.value.uid };
    }

    if (params.filter.children === null || params.filter.children === undefined) {
      params.filter.children = [];
    }

    if (params.filter.op === null || params.filter.op === undefined) {
      params.filter.op = AND;
    }

    params.filter = setUserScopeTreeDefaults(params.filter);

    switch (viewType) {
      case viewTypes.cascade:
        if (params.showParents === null || params.showParents === undefined) {
          params.showParents = true;
        }
        break;
      default:
    }

    return params;
  };

  return { setDefaults };
}
