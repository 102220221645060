<template>
  <div class="upgrade-form">
    <m-btn
      icon="close"
      fab
      hide-border
      small
      class="_close-btn"
      @click="$emit('close')"
    />
    <div
      v-if="submitted"
      class="_success"
    >
      <div class="_icon -success">
        <m-icon
          type="check-circle"
          :color="$colors.green.base"
          size="40"
        />
      </div>
      <div class="_heading">
        {{ $t('upgradeForm.submittedTitle') }}
      </div>
      <div class="_description">
        {{ $t('upgradeForm.submittedSubTitle') }}
      </div>
    </div>
    <template v-else>
      <div class="_header">
        <div class="_icon">
          <m-icon
            type="message"
            size="24"
          />
        </div>
        <div class="_heading">
          <template v-if="title !== ''">
            {{ title }}
          </template>
          <template v-else>
            {{ $t('upgradeForm.heading') }}
          </template>
        </div>
        <div class="_description">
          <template v-if="description !== ''">
            {{ description }}
          </template>
          <template v-else>
            {{ $t('upgradeForm.description') }}
          </template>
        </div>
      </div>
      <div class="_form">
        <div class="_form-row _name">
          <div class="_form-cell _first-name">
            <div class="_label">
              {{ $t('upgradeForm.firstNameLabel') }}
            </div>
            <m-text-field
              v-model:value="firstName"
            />
          </div>
          <div class="_form-cell _last-name">
            <div class="_label">
              {{ $t('upgradeForm.lastNameLabel') }}
            </div>
            <m-text-field
              v-model:value="lastName"
            />
          </div>
        </div>
        <div class="_form-row _form-cell _question">
          <div class="_label">
            {{ $t('upgradeForm.questionLabel') }}
          </div>
          <m-select
            v-model:value="question"
            :items="questions"
            full-width
            match-trigger-width
          />
        </div>
        <div
          v-if="['book','demo'].includes(question)"
          class="_form-row _form-cell _explanation"
        >
          <template v-if="question === 'book'">
            {{ $t('upgradeForm.bookPlanExplanation') }}
          </template>
          <template v-if="question === 'demo'">
            {{ $t('upgradeForm.scheduleDemoExplanation') }}
          </template>
        </div>
        <template v-if="question === 'book'">
          <div
            class="_form-row _form-cell _plan"
          >
            <div class="_label">
              {{ $t('upgradeForm.plansLabel') }}
            </div>
            <m-select
              v-model:value="plan"
              :items="plans"
              full-width
              match-trigger-width
            />
          </div>
          <div
            class="_form-row _form-cell _licences"
          >
            <div class="_label">
              {{ $t('upgradeForm.licencesLabel') }}
            </div>
            <m-input-number
              v-model:value="licences"
              full-width
              :max="maxLicences"
              :min="minLicences"
            />
          </div>
          <div
            class="_form-row _form-cell _address"
          >
            <div class="_label">
              {{ $t('upgradeForm.address') }}
            </div>
            <m-textarea
              v-model:value="address"
              :rows="3"
              :placeholder="$t('upgradeForm.addressPlaceholder')"
            />
          </div>
          <div
            class="_form-row _form-cell _vat-id"
          >
            <div class="_label">
              {{ $t('upgradeForm.vatID') }}
            </div>
            <m-text-field v-model:value="vatID" />
          </div>
        </template>
        <div
          v-if="['book','other'].includes(question)"
          class="_form-row _form-cell _message"
        >
          <div class="_label">
            <template v-if="question === 'other'">
              {{ $t('upgradeForm.messageLabel') }}
            </template>
            <template v-else>
              {{ $t('upgradeForm.messageLabel') }} {{ $t('upgradeForm.optional') }}
            </template>
          </div>
          <m-textarea
            v-model:value="message"
            :rows="3"
          />
        </div>

        <m-tooltip
          v-if="['book','other'].includes(question)"
          placement="bottom"
          :disabled="!formIsInvalid"
        >
          <template #title>
            <div>
              {{ $t('upgradeForm.invalid') }}
            </div>
          </template>
          <m-btn
            color="primary"
            block
            :disabled="formIsInvalid"
            class="_submit"
            :loading="loading"
            @click="submit"
          >
            {{ $t('general.send') }}
          </m-btn>
        </m-tooltip>
        <m-btn
          v-if="['demo'].includes(question)"
          color="primary"
          block
          class="_submit"
          target="_blank"
          :href="$t('upgradeForm.scheduleDemoLink')"
          @click="$emit('close')"
        >
          <div :style="{ display: 'flex', alignItems: 'center' }">
            <div>{{ $t('upgradeForm.scheduleDemoButton') }}</div>
            <m-icon
              type="external-link"
              :style="{ marginLeft: '.8rem' }"
            />
          </div>
        </m-btn>
      </div>
    </template>
  </div>
</template>

<script>
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useUsers from '@/composables/user/users';
import { doSendContactSales } from '@/api';
import { findInArray } from 'shared/lib/array/array';
import { hsTicketCategory, productPlan } from 'shared/constants.json';

export default {
  name: 'UpgradeForm',
  props: {
    initialPlan: {
      type: String,
      default: '',
    },
    expired: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },
  setup() {
    const { users } = useUsers();
    const { loggedInUser } = useLoggedInUser();
    return { users, loggedInUser };
  },
  emits: ['close'],
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      licences: 10,
      plan: productPlan.business,
      message: '',
      submitted: false,
      loading: false,
      question: 'book',
      productPlan,
      address: '',
      vatID: '',
    };
  },
  computed: {
    countCurrentUsers() {
      return this.users.filter((u) => !u.isDemo).length;
    },
    formIsInvalid() {
      switch (this.question) {
        case 'book':
          return this.address === '';
        case 'other':
          return this.message === '';
        default:
      }
      return false;
    },
    questions() {
      return [
        {
          text: this.$t('upgradeForm.bookPlan'),
          value: 'book',
        },
        {
          text: this.$t('upgradeForm.scheduleDemo'),
          value: 'demo',
        },
        {
          text: this.$t('upgradeForm.other'),
          value: 'other',
        },
      ];
    },
    maxLicences() {
      return Infinity;
    },
    minLicences() {
      if (this.plan === productPlan.enterprise) {
        return 100;
      }

      return 5;
    },
    plans() {
      return [
        {
          text: this.$t('plans.business'),
          value: productPlan.business,
        },
        {
          text: this.$t('plans.pro'),
          value: productPlan.pro,
        },
        {
          text: this.$t('plans.enterprise'),
          value: productPlan.enterprise,
        },
      ];
    },
    subject() {
      if (this.question === 'book') {
        return `${this.$t('upgradeForm.questionLabel')}: ${this.getQuestion(this.question)}, ${this.$t('upgradeForm.plansLabel')}: ${this.getPlan(this.plan)}, ${this.$t('upgradeForm.licencesLabel')}: ${this.licences}`;
      }
      return `${this.$t('upgradeForm.questionLabel')}: ${this.getQuestion(this.question)}`;
    },
    hsTicketCategory() {
      switch (this.question) {
        case 'book':
          return hsTicketCategory.uPGRADEACCOUNT;
        case 'demo':
          return hsTicketCategory.dEMOREQUEST;
        default:
          return hsTicketCategory.gENERALINQUIRY;
      }
    },
  },
  methods: {
    createMessage(message) {
      return `Address: ${this.address}\nVAT ID: ${this.vatID}\nMessage: ${message}`;
    },
    getQuestion(question) {
      const f = findInArray({ haystack: this.questions, needle: question, key: 'value' });
      if (f === null) {
        return '';
      }

      return f.text;
    },
    getPlan(plan) {
      const f = findInArray({ haystack: this.plans, needle: plan, key: 'value' });
      if (f === null) {
        return '';
      }

      return f.text;
    },
    submit() {
      if (this.formIsInvalid) {
        return;
      }
      this.loading = true;
      doSendContactSales({
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.loggedInUser.email,
        plan: this.plan.value,
        licences: this.licences,
        subject: this.subject,
        hsTicketCategory: this.hsTicketCategory,
        message: this.createMessage(this.message),
      }).then((response) => {
        this.loading = false;
        if (response.status !== 200) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
          return;
        }

        this.submitted = true;
      });
    },
  },
  watch: {
    message() {
      this.showErrors = false;
    },
    plan() {
      this.licences = this.licences > this.minLicences ? this.licences : this.minLicences;
    },
  },
  created() {
    this.licences = this.countCurrentUsers > this.minLicences ? this.countCurrentUsers : this.minLicences;
    if (this.initialPlan !== '') {
      this.plan = this.initialPlan;
    }
    this.email = this.loggedInUser.email;
    this.firstName = this.loggedInUser.firstName;
    this.lastName = this.loggedInUser.lastName;
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .upgrade-form {
    position: relative;

    ._close-btn {
      position: absolute;
      top: -.4rem;
      right: -1.2rem;
    }

    ._icon {
      padding-top: 3rem;
      margin-bottom: 1.2rem;

      &.-success {
        padding-top: 1.2rem;
      }
    }

    ._heading {
      margin-bottom: .6rem;
      font-weight: $font-weight-semibold;
    }

    ._description {
      max-width: 25rem;
      font-size: $font-size-2;
      color: $font-color-secondary;
      text-align: center;
    }

    ._explanation {
      font-size: $font-size-2;
      color: $font-color-secondary;
      text-align: center;
    }

    ._header {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 2rem;
    }

    ._form-row {
      display: flex;
      justify-content: space-between;
      margin-bottom: .8rem;
    }

    ._form-cell {
      display: flex;
      flex-direction: column;
    }

    ._label {
      margin-bottom: .2rem;
      font-size: $font-size-2;
      color: $font-color-secondary;
    }

    ._name {
      ._first-name {
        flex: 0 0 calc(50% - .6rem);
      }

      ._last-name {
        flex: 0 0 calc(50% - .6rem);
      }
    }

    ._submit {
      margin-top: 2rem;
    }

    ._success {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
</style>
