import useBulkMutate from '@/nebula/bulk-mutate';
import useManualSort from '@/composables/sort/manual-sort';
import useResourceSettings from '@/composables/logged-in-user-account/resource-settings';
import useSpaces from '@/composables/space/spaces';
import { UID } from 'shared/api/query/constants';
import {
  resourceSettings as resourceSettingsConfig,
  space as spaceConfig,
} from 'shared/api/query/configs.json';
import { reverseEdge } from 'shared/api/query/edges';

export default function useSpaceOperationExecutor(collection, elReader) {
  const resourceSettingsSvc = useResourceSettings();
  const spacesSvc = useSpaces();

  const { bulkMutate } = useBulkMutate();

  const prepareRealign = (operation) => {
    const { toRealignIds, newParentId } = operation;

    const parents = [{ uid: newParentId }].filter((p) => p.uid !== null);
    return toRealignIds.map((uid) => ({ uid, parents }));
  };

  const updateSort = ({ entity, sortOperation }) => resourceSettingsSvc.updateResourceSettings({ uid: entity.uid, spaceOrder: entity.spaceOrder }, {}, { sortOperation });
  const { listBefore, listAfter } = useManualSort(resourceSettingsConfig.edges.spaceOrder, (res) => res);
  const prepareSort = (operation) => {
    const list = collection.value.map((el) => elReader.getId(el));
    if (operation.sortAfter) {
      return listAfter(resourceSettingsSvc.resourceSettings.value)(list, operation.anchorId, operation.toSortIds);
    }
    return listBefore(resourceSettingsSvc.resourceSettings.value)(list, operation.anchorId, operation.toSortIds);
  };

  const realignAndSort = (operation) => {
    const preparedRealign = prepareRealign(operation);
    const preparedSort = prepareSort(operation);

    return bulkMutate([
      {
        alias: spaceConfig.model,
        nodes: preparedRealign,
        model: spaceConfig.model,
        attributes: [
          { attr: UID },
          {
            attr: spaceConfig.edges.parents,
            model: spaceConfig.model,
            children: [{ attr: UID }],
            default: [],
          },
          {
            attr: reverseEdge(spaceConfig.edges.parents),
            alias: 'children',
            model: spaceConfig.model,
            children: [{ attr: UID }],
            default: [],
          },
        ],
      },
      {
        alias: resourceSettingsConfig.model,
        nodes: [{ uid: preparedSort.entity.uid, spaceOrder: preparedSort.entity.spaceOrder }],
        model: resourceSettingsConfig.model,
        attributes: [
          { attr: UID },
          { attr: resourceSettingsConfig.edges.spaceOrder, default: [] },
        ],
        updateHookParameter: { sortOperation: preparedSort.sortOperation },
      },
    ]);
  };

  const executeDrag = (operation) => {
    if (!operation.isRealign && !operation.isSort) {
      return new Promise((resolve) => { resolve(); });
    }

    if (operation.isRealign && !operation.isSort) {
      return spacesSvc.updateSpaces(prepareRealign(operation));
    }

    if (operation.isSort && !operation.isRealign) {
      return updateSort(prepareSort(operation));
    }

    return realignAndSort(operation);
  };

  return { executeDrag };
}
