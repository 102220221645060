import { goalProgressMeasurement } from 'shared/constants.json';
import { goalType } from '@/lib/goal/goal-type';
import { goalTypeOption } from '@/composables/goal/allowed-sub-items';
import { goalType as type } from '@/constants.json';

export const defaultProgressMeasurement = (goal) => {
  if (goalType(goal) === type.objective) {
    goal.progressMeasurement = goalProgressMeasurement.alignedItems;
    return goal;
  }
  goal.progressMeasurement = goalProgressMeasurement.continuous;
  return goal;
};

export const restrictProgressMeasurement = (goalTypeProperty) => (goal) => {
  const goalType = goalTypeOption(goal, goalTypeProperty);
  if (goalType === null) {
    return goal;
  }

  if (goalType.allowedMeasurementTypes.length === 0 || goalType.allowedMeasurementTypes.includes(goal.progressMeasurement)) {
    return goal;
  }
  goal.progressMeasurement = goalType.allowedMeasurementTypes[0];
  return goal;
};
