import { BLOCKED_BY_FEATURE_GATE, DATADOG_ACTION_WHITELIST, EVENTS } from '@/lib/constants';
import { EventBus } from '@/lib/event-bus';
import { datadogRum } from '@datadog/browser-rum';
import { environmentVariable, resolveEnvironmentVariable } from '@/lib/env';
import { obfuscateText } from '@/lib/text';

const registerEventListeners = () => {
  EventBus.$on(EVENTS.SESSION.LOGIN, ({ user, account }) => {
    datadogRum.setUser({
      id: user.uid,
      account: {
        id: account.uid,
        name: account.companyName,
        plan: account.accountSettings.planId,
      },
    });
    datadogRum.startSessionReplayRecording();
  });

  EventBus.$on(EVENTS.SESSION.LOGOUT, () => {
    datadogRum.stopSessionReplayRecording();
    datadogRum.clearUser();
  });

  EventBus.$on(EVENTS.UPSELL.FEATURE_GATE_VIEWED, (flag) => {
    datadogRum.addAction(BLOCKED_BY_FEATURE_GATE, { featureFlag: flag });
  });
};

export const initDatadog = (isForeignLogin) => {
  if (resolveEnvironmentVariable(environmentVariable.DATADOG_RUM_ACTIVE) === 'true' && isForeignLogin === false) {
    datadogRum.init({
      applicationId: resolveEnvironmentVariable(environmentVariable.DATADOG_RUM_APPLICATION_ID),
      clientToken: resolveEnvironmentVariable(environmentVariable.DATADOG_RUM_CLIENT_TOKEN),
      site: 'datadoghq.eu',
      env: resolveEnvironmentVariable(environmentVariable.DATADOG_RUM_ENVIRONMENT),
      service: resolveEnvironmentVariable(environmentVariable.DATADOG_RUM_SERVICE_NAME),
      version: resolveEnvironmentVariable(environmentVariable.DATADOG_RUM_SERVICE_VERSION),
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
      trackViewsManually: true,
      defaultPrivacyLevel: 'mask',
      startSessionReplayRecordingManually: false, // TODO: upon Datadog renewal, we should reduce our commitment to replays and set this back to true
      /* eslint-disable no-useless-escape */
      allowedTracingUrls: [/https?:\/\/(app|(\w+)\.stage\.x)\.mooncamp\.com/],
      /* eslint-enable no-useless-escape */
      // eslint-disable-next-line consistent-return
      beforeSend: (event) => {
        // TODO: RUM v5 states that it only collects trustedEvents, maybe it's time to remove the discards below
        //  https://docs.datadoghq.com/real_user_monitoring/guide/browser-sdk-upgrade/#trusted-events
        // discard a RUM error if its message includes 'profile is not defined'
        if (event.type === 'error'
          && (event.error.message.includes('Object Not Found Matching') || event.error.message.includes('https://fonts.gstatic'))
        ) {
          return false;
        }
        // redact action name from click actions
        if (event.type === 'action' && DATADOG_ACTION_WHITELIST[event.action.target.name] === undefined) {
          event.action.target.name = obfuscateText(event.action.target.name, 2, 2);
        }
        return true;
      },
    });

    registerEventListeners();
  }
};
