import useGoalSettings from '@/composables/logged-in-user-account/goal-settings';
import usePropertySettings from '@/composables/customize-page/property-settings';
import { computed } from 'vue';
import { isVisible } from '@/lib/display-props/display-props';
import { propertyType } from 'shared/constants.json';
import { sortByArray } from 'shared/lib/sort';

export default function useGoalDetailProperties({ goal, userLang, goalTypeProperty }) {
  const { goalSettings } = useGoalSettings();

  const selectedOptions = computed(() => goal.value.properties.find((p) => p.property.isGoalType).selectedOptions);

  const goalType = computed(() => {
    if (selectedOptions.value.length === 0) {
      return null;
    }

    const optionId = selectedOptions.value[0].uid;
    return goalTypeProperty.value.options.find((o) => optionId === o.uid);
  });

  const { goalProperties, updateVisibility } = usePropertySettings({
    goalType,
    userLang,
  });

  const sortedGoalDetailPageProperties = computed(() => goalProperties.value
    .filter((p) => p.type !== propertyType.status)
    .map((p) => ({
      ...p,
      visible: isVisible({ property: p, goal: goal.value }),
    })).sort(sortByArray(goalSettings.value.propertyOrder.map((el) => ({ key: el })), 'key')));

  return {
    sortedGoalDetailPageProperties,
    updateVisibility,
    goalType,
  };
}
