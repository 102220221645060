<template>
  <span class="m-icon">
    <i
      :style="style"
      class="_icon"
    >
      <component
        :is="t"
        :style="style"
        :class="['_icon-inner', spin ? '-spin' : '']"
      />
    </i>
  </span>
</template>

<script>
import Above from 'shared/components/icons/Above.vue';
import AboveOrEqual from 'shared/components/icons/AboveOrEqual.vue';
import ActiveStatus from 'shared/components/icons/ActiveStatus.vue';
import AddReaction from 'shared/components/icons/AddReaction.vue';
import AlignLeft from 'shared/components/icons/AlignLeft.vue';
import Aligned from 'shared/components/icons/Aligned.vue';
import Apartment from 'shared/components/icons/Apartment.vue';
import Api from 'shared/components/icons/Api.vue';
import Appstore from 'shared/components/icons/Appstore.vue';
import Archive from 'shared/components/icons/Archive.vue';
import ArrowDown from 'shared/components/icons/ArrowDown.vue';
import ArrowDownThin from 'shared/components/icons/ArrowDownThin.vue';
import ArrowGoForward from 'shared/components/icons/ArrowGoForward.vue';
import ArrowLeft from 'shared/components/icons/ArrowLeft.vue';
import ArrowUpThin from 'shared/components/icons/ArrowUpThin.vue';
import ArrowsAlt from 'shared/components/icons/ArrowsAlt.vue';
import Asana from 'shared/components/icons/Asana.vue';
import Audit from 'shared/components/icons/Audit.vue';
import Bars from 'shared/components/icons/Bars.vue';
import Bell from 'shared/components/icons/Bell.vue';
import Below from 'shared/components/icons/Below.vue';
import BelowOrEqual from 'shared/components/icons/BelowOrEqual.vue';
import Bold from 'shared/components/icons/Bold.vue';
import Book from 'shared/components/icons/Book.vue';
import Border from 'shared/components/icons/Border.vue';
import Branches from 'shared/components/icons/Branches.vue';
import Calendar from 'shared/components/icons/Calendar.vue';
import CaretDown from 'shared/components/icons/CaretDown.vue';
import CaretRight from 'shared/components/icons/CaretRight.vue';
import CaretUp from 'shared/components/icons/CaretUp.vue';
import Cascade from 'shared/components/icons/Cascade.vue';
import CenterFocus from 'shared/components/icons/CenterFocus.vue';
import CentralPreview from 'shared/components/icons/CentralPreview.vue';
import Check from 'shared/components/icons/Check.vue';
import CheckCircle from 'shared/components/icons/CheckCircle.vue';
import CheckMark from 'shared/components/icons/CheckMark.vue';
import CheckMarkCircleFilled from 'shared/components/icons/CheckMarkCircleFilled.vue';
import CheckSquare from 'shared/components/icons/CheckSquare.vue';
import ChevronDown from 'shared/components/icons/ChevronDown.vue';
import ChevronLeft from 'shared/components/icons/ChevronLeft.vue';
import ChevronRight from 'shared/components/icons/ChevronRight.vue';
import ChevronUp from 'shared/components/icons/ChevronUp.vue';
import ClockCircle from 'shared/components/icons/ClockCircle.vue';
import Close from 'shared/components/icons/Close.vue';
import CloseCircle from 'shared/components/icons/CloseCircle.vue';
import ClosedStatus from 'shared/components/icons/ClosedStatus.vue';
import Cluster from 'shared/components/icons/Cluster.vue';
import CodeIcon from 'shared/components/icons/CodeIcon.vue';
import Compass from 'shared/components/icons/Compass.vue';
import CompletedIcon from 'shared/components/icons/CompletedIcon.vue';
import Copy from 'shared/components/icons/Copy.vue';
import Custify from 'shared/components/icons/Custify.vue';
import CustomerService from 'shared/components/icons/CustomerService.vue';
import Dashboard from 'shared/components/icons/Dashboard.vue';
import Database from 'shared/components/icons/Database.vue';
import Datadog from 'shared/components/icons/Datadog.vue';
import Delete from 'shared/components/icons/Delete.vue';
import Dot from 'shared/components/icons/Dot.vue';
import DoubleLeft from 'shared/components/icons/DoubleLeft.vue';
import DoubleRight from 'shared/components/icons/DoubleRight.vue';
import Down from 'shared/components/icons/Down.vue';
import Download from 'shared/components/icons/Download.vue';
import DraftIcon from 'shared/components/icons/DraftIcon.vue';
import Drag from 'shared/components/icons/Drag.vue';
import Edit from 'shared/components/icons/Edit.vue';
import EditSquare from 'shared/components/icons/EditSquare.vue';
import Ellipsis from 'shared/components/icons/Ellipsis.vue';
import Enter from 'shared/components/icons/Enter.vue';
import ExcelOnline from 'shared/components/icons/ExcelOnline.vue';
import Exclamation from 'shared/components/icons/Exclamation.vue';
import ExclamationCircleFilled from 'shared/components/icons/ExclamationCircleFilled.vue';
import ExpandMinus from 'shared/components/icons/ExpandMinus.vue';
import ExpandPlus from 'shared/components/icons/ExpandPlus.vue';
import ExternalLink from 'shared/components/icons/ExternalLink.vue';
import Eye from 'shared/components/icons/Eye.vue';
import EyeInvisible from 'shared/components/icons/EyeInvisible.vue';
import FeedViewIcon from 'shared/components/icons/FeedViewIcon.vue';
import File from 'shared/components/icons/File.vue';
import FileImage from 'shared/components/icons/FileImage.vue';
import FileText from 'shared/components/icons/FileText.vue';
import FilterOutlined from 'shared/components/icons/FilterOutlined.vue';
import FitToScreen from 'shared/components/icons/FitToScreen.vue';
import Flag from 'shared/components/icons/Flag.vue';
import Folder from 'shared/components/icons/Folder.vue';
import Funnel from 'shared/components/icons/Funnel.vue';
import Global from 'shared/components/icons/Global.vue';
import Goals from 'shared/components/icons/Goals.vue';
import GoogleColor from 'shared/components/icons/GoogleColor.vue';
import GoogleDrive from 'shared/components/icons/GoogleDrive.vue';
import GoogleSheet from 'shared/components/icons/GoogleSheet.vue';
import GoogleSheets from 'shared/components/icons/GoogleSheets.vue';
import Hamburger from 'shared/components/icons/Hamburger.vue';
import Hand from 'shared/components/icons/Hand.vue';
import History from 'shared/components/icons/History.vue';
import Home from 'shared/components/icons/Home.vue';
import Hubspot from 'shared/components/icons/Hubspot.vue';
import Inbox from 'shared/components/icons/Inbox.vue';
import Info from 'shared/components/icons/Info.vue';
import InfoCircle from 'shared/components/icons/InfoCircle.vue';
import Italic from 'shared/components/icons/Italic.vue';
import Jira from 'shared/components/icons/Jira.vue';
import Key from 'shared/components/icons/Key.vue';
import KeyboardDown from 'shared/components/icons/KeyboardDown.vue';
import LastUpdate from 'shared/components/icons/LastUpdate.vue';
import Left from 'shared/components/icons/Left.vue';
import LeftArrowLong from 'shared/components/icons/LeftArrowLong.vue';
import LineChart from 'shared/components/icons/LineChart.vue';
import LinkIcon from 'shared/components/icons/Link.vue';
import LinkedIn from 'shared/components/icons/LinkedIn.vue';
import ListIcon from 'shared/components/icons/ListIcon.vue';
import Loading from 'shared/components/icons/Loading.vue';
import Lock from 'shared/components/icons/Lock.vue';
import Login from 'shared/components/icons/Login.vue';
import Logout from 'shared/components/icons/Logout.vue';
import LongText from 'shared/components/icons/LongText.vue';
import Lookup from 'shared/components/icons/Lookup.vue';
import MCircle from 'shared/components/icons/MCircle.vue';
import MSTeams from 'shared/components/icons/MSTeams.vue';
import Mail from 'shared/components/icons/Mail.vue';
import Message from 'shared/components/icons/Message.vue';
import MessageHeart from 'shared/components/icons/MessageHeart.vue';
import MicrosoftColor from 'shared/components/icons/MicrosoftColor.vue';
import MinusSquare from 'shared/components/icons/MinusSquare.vue';
import Mooncamp from 'shared/components/icons/Mooncamp.vue';
import More from 'shared/components/icons/More.vue';
import Mouse from 'shared/components/icons/Mouse.vue';
import Move from 'shared/components/icons/Move.vue';
import NetworkIcon from 'shared/components/icons/NetworkIcon.vue';
import NonAutomatic from 'shared/components/icons/NonAutomatic.vue';
import Notification from 'shared/components/icons/Notification.vue';
import Number from 'shared/components/icons/Number.vue';
import OffTrackIcon from 'shared/components/icons/OffTrackIcon.vue';
import OnTrackIcon from 'shared/components/icons/OnTrackIcon.vue';
import OpenAsPage from 'shared/components/icons/OpenAsPage.vue';
import Order from 'shared/components/icons/Order.vue';
import Outlook from 'shared/components/icons/Outlook.vue';
import PanelsDashboard from 'shared/components/icons/PanelsDashboard.vue';
import PaperClip from 'shared/components/icons/PaperClip.vue';
import Parent from 'shared/components/icons/Parent.vue';
import Pause from 'shared/components/icons/Pause.vue';
import Plan from 'shared/components/icons/Plan.vue';
import PlayCircleFilled from 'shared/components/icons/PlayCircleFilled.vue';
import PlayOutlined from 'shared/components/icons/PlayOutlined.vue';
import Plus from 'shared/components/icons/Plus.vue';
import PlusSquare from 'shared/components/icons/PlusSquare.vue';
import Poweroff from 'shared/components/icons/Poweroff.vue';
import ProgressingIcon from 'shared/components/icons/ProgressingIcon.vue';
import Pulse from 'shared/components/icons/Pulse.vue';
import Pushpin from 'shared/components/icons/Pushpin.vue';
import PushpinFilled from 'shared/components/icons/PushpinFilled.vue';
import Question from 'shared/components/icons/Question.vue';
import QuestionCircle from 'shared/components/icons/QuestionCircle.vue';
import QuestionCircleFilled from 'shared/components/icons/QuestionCircleFilled.vue';
import Read from 'shared/components/icons/Read.vue';
import ReadFilled from 'shared/components/icons/ReadFilled.vue';
import Redo from 'shared/components/icons/Redo.vue';
import Restore from 'shared/components/icons/Restore.vue';
import Retweet from 'shared/components/icons/Retweet.vue';
import Right from 'shared/components/icons/Right.vue';
import Rocket from 'shared/components/icons/Rocket.vue';
import SafetyCertificate from 'shared/components/icons/SafetyCertificate.vue';
import Salesforce from 'shared/components/icons/Salesforce.vue';
import Save from 'shared/components/icons/Save.vue';
import Scale from 'shared/components/icons/Scale.vue';
import Schedule from 'shared/components/icons/Schedule.vue';
import Search from 'shared/components/icons/Search.vue';
import Setting from 'shared/components/icons/Setting.vue';
import Settings from 'shared/components/icons/Settings.vue';
import ShareAlt from 'shared/components/icons/ShareAlt.vue';
import Shop from 'shared/components/icons/Shop.vue';
import Slack from 'shared/components/icons/Slack.vue';
import Smile from 'shared/components/icons/Smile.vue';
import Solution from 'shared/components/icons/Solution.vue';
import Star from 'shared/components/icons/Star.vue';
import Status from 'shared/components/icons/Status.vue';
import Stop from 'shared/components/icons/Stop.vue';
import Stripe from 'shared/components/icons/Stripe.vue';
import Sync from 'shared/components/icons/Sync.vue';
import Tags from 'shared/components/icons/Tags.vue';
import Team from 'shared/components/icons/Team.vue';
import Templates from 'shared/components/icons/Templates.vue';
import Threshold from 'shared/components/icons/Threshold.vue';
import Tool from 'shared/components/icons/Tool.vue';
import Translate from 'shared/components/icons/Translate.vue';
import TreeView from 'shared/components/icons/TreeView.vue';
import Underline from 'shared/components/icons/Underline.vue';
import Undo from 'shared/components/icons/Undo.vue';
import UnorderedList from 'shared/components/icons/UnorderedList.vue';
import Up from 'shared/components/icons/Up.vue';
import UpgradePlan from 'shared/components/icons/UpgradePlan.vue';
import Upload from 'shared/components/icons/Upload.vue';
import User from 'shared/components/icons/User.vue';
import UserGroupAdd from 'shared/components/icons/UserGroupAdd.vue';
import Warning from 'shared/components/icons/Warning.vue';
import WelcomeScreen from 'shared/components/icons/WelcomeScreen.vue';
import ZoomIn from 'shared/components/icons/ZoomIn.vue';
import ZoomOut from 'shared/components/icons/ZoomOut.vue';

export default {
  name: 'MIcon',
  props: {
    size: {
      type: String,
      default: '14',
    },
    color: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    spin: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Funnel,
    ArrowUpThin,
    ArrowDownThin,
    ArrowDown,
    ClosedStatus,
    Flag,
    MessageHeart,
    Border,
    Mouse,
    Hand,
    EditSquare,
    FitToScreen,
    TreeView,
    CentralPreview,
    Audit,
    ReadFilled,
    QuestionCircleFilled,
    LinkedIn,
    Upload,
    Book,
    PlayOutlined,
    Aligned,
    Parent,
    Lookup,
    Save,
    Copy,
    Poweroff,
    Setting,
    Drag,
    Move,
    Rocket,
    ActiveStatus,
    Mail,
    Enter,
    Sync,
    ZoomIn,
    ZoomOut,
    PaperClip,
    FileImage,
    CodeIcon,
    Bold,
    Italic,
    Underline,
    Login,
    Logout,
    Star,
    CustomerService,
    Warning,
    Pushpin,
    InfoCircle,
    Appstore,
    Eye,
    PlayCircleFilled,
    Info,
    ArrowLeft,
    Read,
    CheckMarkCircleFilled,
    Tool,
    ArrowsAlt,
    Schedule,
    DoubleRight,
    DoubleLeft,
    ClockCircle,
    Message,
    Smile,
    CheckSquare,
    Apartment,
    /* eslint-disable vue/no-reserved-component-names */
    Stop,
    /* eslint-enable vue/no-reserved-component-names */
    Stripe,
    LineChart,
    Retweet,
    UnorderedList,
    Dashboard,
    History,
    Inbox,
    Search,
    CaretDown,
    Bell,
    Team,
    Question,
    Delete,
    Bars,
    User,
    Shop,
    SafetyCertificate,
    Notification,
    Solution,
    LinkIcon,
    Home,
    Global,
    FileText,
    File,
    Compass,
    Api,
    QuestionCircle,
    Cluster,
    CloseCircle,
    NonAutomatic,
    Branches,
    Calendar,
    PushpinFilled,
    OpenAsPage,
    Threshold,
    Plan,
    UpgradePlan,
    Above,
    AboveOrEqual,
    Below,
    BelowOrEqual,
    Close,
    Up,
    Down,
    Plus,
    PlusSquare,
    MinusSquare,
    Left,
    Right,
    MSTeams,
    Slack,
    KeyboardDown,
    FilterOutlined,
    Order,
    Ellipsis,
    Hamburger,
    Hubspot,
    ChevronRight,
    Asana,
    ChevronLeft,
    ChevronUp,
    Key,
    GoogleColor,
    GoogleDrive,
    ChevronDown,
    Jira,
    GoogleSheets,
    GoogleSheet,
    MicrosoftColor,
    NetworkIcon,
    ListIcon,
    Cascade,
    CheckCircle,
    AlignLeft,
    CaretUp,
    CaretRight,
    Pause,
    AddReaction,
    CompletedIcon,
    CenterFocus,
    DraftIcon,
    ProgressingIcon,
    OnTrackIcon,
    OffTrackIcon,
    Settings,
    ArrowGoForward,
    LastUpdate,
    Download,
    ShareAlt,
    Goals,
    Templates,
    Translate,
    Pulse,
    Number,
    ExternalLink,
    EyeInvisible,
    ExpandPlus,
    ExpandMinus,
    LongText,
    Dot,
    Scale,
    MCircle,
    FeedViewIcon,
    WelcomeScreen,
    PanelsDashboard,
    CheckMark,
    ExcelOnline,
    Outlook,
    More,
    Tags,
    Check,
    Edit,
    Redo,
    Restore,
    Undo,
    Exclamation,
    ExclamationCircleFilled,
    Loading,
    Lock,
    Folder,
    Salesforce,
    LeftArrowLong,
    UserGroupAdd,
    Custify,
    Datadog,
    Mooncamp,
    Database,
    Status,
    Archive,
  },
  computed: {
    style() {
      if (this.size === 'inherit' || this.size.indexOf('%') > -1 || this.size.indexOf('em') > -1) {
        return {
          fontSize: this.size,
          color: this.color,
        };
      }
      return {
        'font-size': `${this.size}px`,
        color: this.color,
      };
    },
    t() {
      return this.mapType(this.type);
    },
  },
  methods: {
    mapType(t) {
      switch (t) {
        case 'circle':
          return 'm-circle';
        case 'off_track':
          return 'off-track-icon';
        case 'on_track':
          return 'on-track-icon';
        case 'progressing':
          return 'progressing-icon';
        case 'draft':
          return 'draft-icon';
        case 'completed':
          return 'completed-icon';
        case 'list':
          return 'list-icon';
        case 'atlas':
          return 'network-icon';
        case 'default-view':
          return 'panels-dashboard';
        case 'feed-view':
          return 'feed-view-icon';
        case 'sheets':
          return 'google-sheets';
        case 'excel':
          return 'excel-online';
        case 'ms-teams':
          return 'm-s-teams';
        case 'link':
          return 'link-icon';
        case 'code':
        case 'lookup':
        case 'parent':
        case 'aligned':
          return 'code-icon';
        case 'filter':
          return 'filter-outlined';
        default:
          return t;
      }
    },
  },
};
</script>

<style lang="scss" type="text/scss">
  .m-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    ._icon {
      display: inline-block;
      font-style: normal;
      line-height: 0;
      text-align: center;
      text-transform: none;
      vertical-align: -.125em;
      text-rendering: optimizelegibility;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      ._icon-inner {
        &.-spin {
          animation: loadingCircle 1s linear infinite;

          @keyframes loadingCircle {
            100% {
              transform: rotate(1turn);
            }
          }
        }
      }
    }
  }
</style>
