<template>
  <m-dropdown
    v-model:value="visible"
    :title="$t('dataSourcePicker.title')"
    block
    match-trigger-width
  >
    <m-focusable
      :class="['_input']"
      full-width
      type="clickable"
    >
      <div
        v-if="selectedDataSourceIcon !== ''"
        class="_icon"
      >
        <m-icon :type="selectedDataSourceIcon" />
      </div>
      <div class="_title">
        {{ dataSourceName(selectedDataSource) }}
      </div>
    </m-focusable>
    <template #overlay>
      <m-card
        no-padding
        list
      >
        <data-source-item
          v-for="ds in sources"
          :key="ds.uid"
          :ref="el => { if (el) dataSourceItemRefs[ds.uid] = el }"
          :data-source="ds"
          @click="select(ds)"
          @close="visible = false"
        />
        <m-content
          v-if="dataSources.length === 0"
          padding-xs
          class="_empty"
        >
          {{ $t('dataSource.empty', { title: goalSettings.featureNamePlural } ) }}
        </m-content>
        <m-divider xxs />
        <m-dropdown
          block
          placement="topRight"
          :title="$t('dataSourcePicker.integrations')"
        >
          <m-card-item
            icon="plus"
            close-on-click
            match-trigger-width
          >
            {{ $t('general.add') }}
          </m-card-item>
          <template #overlay>
            <m-card
              no-padding
              list
            >
              <m-card-item
                v-if="loggedInUserAccount.accountSettings.usesSheets"
                icon="sheets"
                @click="create(dataSourceType.sheets)"
              >
                {{ $t(`dataSource.${camelCase(dataSourceType.sheets)}`) }}
              </m-card-item>
              <m-card-item
                v-if="loggedInUserAccount.accountSettings.usesExcel"
                icon="excel"
                @click="create(dataSourceType.excel)"
              >
                {{ $t(`dataSource.${camelCase(dataSourceType.excel)}`) }}
              </m-card-item>
              <m-card-item
                v-if="loggedInUserAccount.accountSettings.usesJira"
                icon="jira"
                @click="create(dataSourceType.jira)"
              >
                {{ $t(`dataSource.${camelCase(dataSourceType.jira)}`) }}
              </m-card-item>
              <m-card-item
                v-if="loggedInUserAccount.accountSettings.usesHubspot"
                icon="hubspot"
                @click="create(dataSourceType.hubspot)"
              >
                {{ $t(`dataSource.${camelCase(dataSourceType.hubspot)}`) }}
              </m-card-item>
              <m-card-item
                v-if="loggedInUserAccount.accountSettings.usesAsana"
                icon="asana"
                @click="create(dataSourceType.asana)"
              >
                {{ $t(`dataSource.${camelCase(dataSourceType.asana)}`) }}
              </m-card-item>
              <m-card-item
                v-if="loggedInUserAccount.accountSettings.usesSalesforce"
                icon="salesforce"
                @click="create(dataSourceType.salesforce)"
              >
                {{ $t(`dataSource.${camelCase(dataSourceType.salesforce)}`) }}
              </m-card-item>
            </m-card>
          </template>
        </m-dropdown>
      </m-card>
    </template>
  </m-dropdown>
</template>

<script>
import DataSourceItem from '@/components/datasource/DataSourceItem.vue';
import camelCase from 'lodash-es/camelCase';
import useDataSources from '@/composables/integrations/datasources';
import useGoalSettings from '@/composables/logged-in-user-account/goal-settings';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import { computed } from 'vue';
import { dataSourceIcon } from '@/lib/data-source';
import { dataSourceType } from 'shared/constants.json';

export default {
  name: 'DataSourcePicker',
  props: {
    selectedDataSource: {
      type: Object,
      default: () => null,
    },
    dataSourceIdToEdit: {
      type: Number,
      default: -1,
    },
  },
  emits: ['select'],
  components: { DataSourceItem },
  setup() {
    const { loggedInUserAccount } = useLoggedInUserAccount();
    const { goalSettings } = useGoalSettings();
    const { dataSourceName, dataSources, createDataSource } = useDataSources();
    const goalDataSources = computed(() => dataSources.value.filter((ds) => ([
      dataSourceType.excel,
      dataSourceType.sheets,
      dataSourceType.asana,
      dataSourceType.jira,
      dataSourceType.hubspot,
      dataSourceType.salesforce,
    ]).includes(ds.type)));
    return {
      loggedInUserAccount,
      goalSettings,

      dataSources: goalDataSources,
      dataSourceName,
      createDataSource,
    };
  },
  data() {
    return {
      camelCase,
      dataSourceType,
      visible: false,
      dataSourceItemRefs: {},
    };
  },
  computed: {
    selectedDataSourceIcon() {
      return dataSourceIcon(this.selectedDataSource);
    },
    sources() {
      return this.dataSources.map((ds) => {
        const icon = dataSourceIcon(ds);
        if (icon !== '') {
          return { ...ds, icon };
        }
        return ds;
      });
    },
  },
  methods: {
    select(dataSource) {
      this.$emit('select', dataSource);
      this.close();
    },
    close() {
      this.visible = false;
    },
    create(dsType) {
      this.createDataSource({
        name: this.$t(`dataSourcePicker.defaultName.${dsType}`),
        shared: false,
        type: dsType,
      }).then((dataSource) => {
        this.$emit('select', dataSource);

        this.$nextTick(() => {
          this.dataSourceItemRefs[dataSource.uid].edit();
        });
      }).catch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      });
    },
  },
  beforeUpdate() {
    this.dataSourceItemRefs = {};
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  ._input {
    display: flex;
    align-items: center;
    margin-top: .4rem;

    ._icon {
      margin-top: -.1rem;
      margin-right: .4rem;
    }

    ._title {
      width: 100%;
    }
  }

  ._empty {
    color: $font-color-secondary;
  }
</style>
