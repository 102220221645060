<template>
  <svg
    width="1em"
    height="1em"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 64 64"
  >
    <path
      d="M58.963,33.037a1.19074,1.19074,0,0,0-.57607-1.02619v-.00014l-.00626-.00362-.02279-.01331L39.62569,21.01765a2.55937,2.55937,0,0,0-.25114-.14728h-.00007a2.52874,2.52874,0,0,0-2.30451,0h-.00014a2.55515,2.55515,0,0,0-.25107.14728L18.08661,31.99378l-.02279.01331-.00626.00362v.00014a1.20191,1.20191,0,0,0,.02912,2.06945L36.81876,45.05642a2.58226,2.58226,0,0,0,.25107.14714l.00014.00014a2.52874,2.52874,0,0,0,2.30451,0l.00007-.00014a2.58333,2.58333,0,0,0,.25114-.14714L58.35777,34.0803A1.19027,1.19027,0,0,0,58.963,33.037Z"
      fill="#123b6d"
    /><rect
      x="19.55556"
      y="25.77778"
      width="12.44444"
      height="11.4074"
      fill="#0364b8"
    /><path
      d="M57,15V10.33333A2.35445,2.35445,0,0,0,54.625,8H21.375A2.35446,2.35446,0,0,0,19,10.3333V15Z"
      fill="#0358a7"
    /><rect
      x="19"
      y="14"
      width="13"
      height="12"
      fill="#0078d4"
    /><polygon
      points="32 14 45 14 57 26 57 38 45 38 32 26 32 14"
      fill="#28a8ea"
    /><rect
      x="45"
      y="14"
      width="12"
      height="12"
      fill="#50d9ff"
    /><rect
      x="32"
      y="26"
      width="13"
      height="12"
      fill="#0078d4"
    /><rect
      x="32"
      y="38"
      width="13"
      height="12"
      fill="#0364b8"
    /><rect
      x="19.55556"
      y="37.18519"
      width="12.44444"
      height="10.37037"
      fill="#14447d"
    /><rect
      x="45"
      y="38"
      width="12"
      height="11"
      fill="#0078d4"
    /><path
      d="M58.4165,33.94727v.001l-.023.0127-.00585.00293L39.4209,44.10742a2.73725,2.73725,0,0,1-.25391.13574h0A2.75422,2.75422,0,0,1,38,44.5c-.023,0-.04443-.00684-.06738-.00732l-1.06348-.584a2.61118,2.61118,0,0,1-.25439-.13965L17.61375,33.3369l-.00586-.00293-.00928-.00537L17.00049,33H17V53.53564A2.54863,2.54863,0,0,0,19.625,56H56.44629A2.68183,2.68183,0,0,0,58,55.50977l-.04-.02149A2.40454,2.40454,0,0,0,59,53.53564V33A1.08317,1.08317,0,0,1,58.4165,33.94727Z"
      fill="url(#a)"
    /><path
      d="M58.41667,33.94752v.00082l-.02318.0124-.00579.00311L39.42115,44.10723a2.67017,2.67017,0,0,1-.25428.136H39.1668a2.7788,2.7788,0,0,1-2.33332,0l-.00011-.00006a2.66834,2.66834,0,0,1-.2542-.13594L17.6126,33.96385l-.00582-.00311-.02317-.0124v-.00082A1.084,1.084,0,0,1,17.00029,33H17V53.53569A2.54844,2.54844,0,0,0,19.625,56h36.75A2.54844,2.54844,0,0,0,59,53.53571V33.00012A1.084,1.084,0,0,1,58.41667,33.94752Z"
      fill="#1490df"
    /><path
      d="M19.62974,56H56.4463A2.68351,2.68351,0,0,0,58,55.51L36.86893,43.90854a2.65278,2.65278,0,0,1-.25431-.13963L17.6137,33.33694l-.00582-.00319-.00908-.005L17,33V53.46559A2.58338,2.58338,0,0,0,19.62974,56Z"
      fill="#28a8ea"
    /><path
      d="M57,33.56982V34.7002L39.41992,44.10986c-.08008.0503-.16992.09034-.25.13037A2.6775,2.6775,0,0,1,38,44.5h-.06006l-1.06982-.58984c-.08985-.04-.16992-.09034-.26026-.14014L19,34.1001V32.96l18.1001,9.93018c.08984.06006.1499.08984.1997.11963l.88038.48046a1.66449,1.66449,0,0,0,.56-.15039C38.79,43.31006,53.18994,35.60986,57,33.56982Z"
      fill="#0a2767"
      opacity="0.5"
      style="isolation:isolate;"
    /><path
      d="M33,20.33008V46.66992a1.7351,1.7351,0,0,1-.04.3999A2.31378,2.31378,0,0,1,30.66992,49H17V33l.50977.28027A1.011,1.011,0,0,1,17.48,33.04a1.19308,1.19308,0,0,1,.58008-1.03027c.00976-.00977.02-.00977.02978-.01954l1.47022-.86035V26H19V18H30.66992A2.326,2.326,0,0,1,33,20.32191Z"
      opacity="0.2"
      style="isolation:isolate;"
    /><path
      d="M34,20.33008V44.66992A3.36171,3.36171,0,0,1,30.66992,48H17V33l.50977.28027A1.011,1.011,0,0,1,17.48,33.04a1.19308,1.19308,0,0,1,.58008-1.03027c.00976-.00977.02-.00977.02978-.01954l1.47022-.86035V26H19V17H30.66992A3.34177,3.34177,0,0,1,34,20.33008Z"
      opacity="0.1"
      style="isolation:isolate;"
    /><path
      d="M33,20.33008V44.66992A2.326,2.326,0,0,1,30.67809,47H17V33l.50977.28027A1.011,1.011,0,0,1,17.48,33.04a1.19308,1.19308,0,0,1,.58008-1.03027c.00976-.00977.02-.00977.02978-.01954l1.47022-.86035V26H19V18H30.66992A2.326,2.326,0,0,1,33,20.32191Z"
      opacity="0.2"
      style="isolation:isolate;"
    /><path
      d="M32,20.33008V44.66992A2.326,2.326,0,0,1,29.67809,47H17V33l.50977.28027A1.011,1.011,0,0,1,17.48,33.04a1.19308,1.19308,0,0,1,.58008-1.03027c.00976-.00977.02-.00977.02978-.01954l1.47022-.86035V26H19V18H29.66992A2.326,2.326,0,0,1,32,20.32191Z"
      opacity="0.1"
      style="isolation:isolate;"
    /><rect
      x="4"
      y="18"
      width="28"
      height="28"
      rx="2.33333"
      fill="#0f78d4"
    /><path
      d="M11.73084,28.24309A6.34183,6.34183,0,0,1,14.22112,25.492,7.53054,7.53054,0,0,1,18.179,24.5a7.00244,7.00244,0,0,1,3.66128.94089,6.27762,6.27762,0,0,1,2.4238,2.62834,8.48872,8.48872,0,0,1,.84884,3.86581,8.95053,8.95053,0,0,1-.87441,4.04479,6.42927,6.42927,0,0,1-2.49539,2.72038,7.279,7.279,0,0,1-3.79934.96646,7.17191,7.17191,0,0,1-3.74309-.95112,6.37649,6.37649,0,0,1-2.45448-2.63345,8.27935,8.27935,0,0,1-.85907-3.81979A9.18377,9.18377,0,0,1,11.73084,28.24309Zm2.65391,6.45836a4.11954,4.11954,0,0,0,1.4011,1.81018,3.65742,3.65742,0,0,0,2.18858.65964,3.84175,3.84175,0,0,0,2.33688-.68009,3.94751,3.94751,0,0,0,1.36019-1.8153,7.01558,7.01558,0,0,0,.43465-2.52607,7.67826,7.67826,0,0,0-.40908-2.55676,4.04762,4.04762,0,0,0-1.31417-1.88177,3.62078,3.62078,0,0,0-2.31642-.71589,3.78112,3.78112,0,0,0-2.23972.66476A4.15707,4.15707,0,0,0,14.395,29.48567a7.24315,7.24315,0,0,0-.01023,5.21578Z"
      fill="#fff"
    /><path
      d="M40.417,43.57471l-.99609.53271A2.721,2.721,0,0,1,38,44.5l-.05518-.001L57.96533,55.49121a2.4383,2.4383,0,0,0,1.01026-1.72705Z"
      opacity="0.05"
      style="isolation:isolate;"
    /><path
      d="M57.96875,55.49316a2.53766,2.53766,0,0,0,.69873-.75732L39.36475,44.13965c-.06446.03662-.12989.07178-.19776.10351A2.75422,2.75422,0,0,1,38,44.5l-.05518-.001Z"
      opacity="0.05"
      style="isolation:isolate;"
    />
  </svg>
</template>

<script>
export default { name: 'Outlook' }; </script>
