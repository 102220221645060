<template>
  <div class="goal-cycles">
    <page-header
      :title="$t('goalCycles.title')"
      boxed-xs
      heading="h3"
      underlined
      class="_header"
    />
    <m-content
      boxed-xs
      no-padding
    >
      <m-content :padding-y="8" />
      <m-btn
        small
        super-light
        :href="$t('goalCycles.helpCenterLink')"
        hide-border
        icon="question-circle"
        target="_blank"
      >
        {{ $t('goalCycles.learnMore') }}
      </m-btn>
    </m-content>
    <m-content
      boxed-xs
      padding
      class="_content"
    >
      <goal-cycles-table
        @create="openCreateModal"
        @update="openEditModal"
        @delete="openDeleteModal"
      />
    </m-content>
    <m-dialog
      v-model:value="showEditModal"
      small
      hide-close-btn
      no-padding
      hide-footer
      :title="goalCycleToEdit.uid ? $t('editGoalCycleModal.editTitle') : $t('editGoalCycleModal.createTitle')"
      center
      @close="showEditModal = false"
    >
      <edit-goal-cycle
        :initial-value="goalCycleToEdit"
        :disabled="createGoalCycleLoading || updateGoalCycleLoading"
        @ok="saveOrUpdate"
        @cancel="showEditModal = false"
      />
    </m-dialog>
  </div>
</template>

<script>
import EditGoalCycle from '@/components/goal-cycles/EditGoalCycle.vue';
import GoalCyclesTable from '@/components/goal-cycles/GoalCyclesTable.vue';
import PageHeader from 'shared/components/PageHeader.vue';
import useGoalCycle from '@/composables/goal-cycle/goal-cycle';
import { automaticColor } from 'shared/lib/color';
import { copy } from 'shared/lib/copy';
import { guid } from 'shared/lib/uuid';
import { logCatch } from '@/lib/logger/logger';
import { optionColor } from 'shared/constants.json';

export default {
  name: 'GoalCycles',
  components: { EditGoalCycle, GoalCyclesTable, PageHeader },
  setup() {
    const {
      createGoalCycle,
      createGoalCycleLoading,
      updateGoalCycle,
      updateGoalCycleLoading,
      deleteGoalCycle,
      deleteGoalCycleLoading,
    } = useGoalCycle();
    return {
      createGoalCycle,
      createGoalCycleLoading,
      updateGoalCycle,
      updateGoalCycleLoading,
      deleteGoalCycle,
      deleteGoalCycleLoading,
    };
  },
  data() {
    return {
      showEditModal: false,
      newGoalCycle: {
        title: '',
        color: automaticColor(guid(), optionColor.all),
        start: null,
        end: null,
      },
      goalCycleToEdit: {},
    };
  },
  methods: {
    openCreateModal() {
      this.goalCycleToEdit = copy(this.newGoalCycle);
      this.goalCycleToEdit.color = automaticColor(guid(), optionColor.all);
      this.showEditModal = true;
    },
    openEditModal(goalCycle) {
      this.goalCycleToEdit = copy(goalCycle);
      this.showEditModal = true;
    },
    openDeleteModal(goalCycles) {
      const deleteMethod = this.deleteGoalCycle;
      const deleteMethodLoading = this.deleteGoalCycleLoading;
      const goalCycle = goalCycles[0];

      this.$confirm({
        title: this.$t('editGoalCycleModal.deletePromptLabel'),
        okText: this.$t('general.yesDelete'),
        okType: 'danger',
        maskClosable: true,
        cancelText: this.$t('general.cancel'),
        onOk(setLoading) {
          setLoading(deleteMethodLoading);
          deleteMethod(goalCycle).catch(logCatch(() => {
            this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
          }));
        },
      });
    },

    saveOrUpdate(goalCycle) {
      const saveOrUpdateMethod = this.goalCycleToEdit.uid ? this.updateGoalCycle : this.createGoalCycle;
      saveOrUpdateMethod(goalCycle).then(() => {
        this.showEditModal = false;
        this.goalCycleToEdit = copy(this.newGoalCycle);
        this.goalCycleToEdit.color = automaticColor(guid(), optionColor.all);
      }).catch(logCatch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      }));
    },
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
</style>
