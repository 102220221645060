import { RESOURCE_CENTER_OVERVIEW } from '@/lib/constants';
import { ref } from 'vue';

export default function useResourceCenterRouter(initialPage = RESOURCE_CENTER_OVERVIEW) {
  const currentPage = ref(initialPage);

  const navigate = (page) => {
    currentPage.value = page;
  };

  return {
    navigate,
    page: currentPage,
  };
}
