export const arrayToMap = (array, key = 'uid') => array.reduce((acc, next) => {
  acc[next[key]] = next;
  return acc;
}, {});

export const findInArray = ({ haystack, needle, key = 'uid', defaultValue = null }) => {
  if (!Array.isArray(haystack)) {
    return defaultValue;
  }

  const filtered = haystack.filter((p) => p[key] === needle);
  if (filtered.length === 0) {
    return defaultValue;
  }

  return filtered[0];
};

export const findIndexArray = ({ haystack, needle, key = 'uid' }) => {
  if (!Array.isArray(haystack)) {
    return -1;
  }

  return haystack.reduce((acc, p, i) => {
    if (p[key] === needle) {
      acc = i;
    }

    return acc;
  }, -1);
};

export const includesArray = (arrayOfArrays, arr2) => {
  for (let i = 0; i < arrayOfArrays.length; i++) {
    if (arrayOfArrays[i].length !== arr2.length) {
      continue;
    }

    if (arrayOfArrays[i].every((a) => arr2.includes(a))) {
      return true;
    }
  }

  return false;
};

export const equalP = ({ a, b, key = 'uid', order = false }) => {
  if (a.length !== b.length) {
    return false;
  }

  const aP = a.map((i) => i[key]);
  const bP = b.map((i) => i[key]);

  if (order) {
    return aP.every((v, i) => v === bP[i]);
  }
  return aP.every((e) => bP.includes(e));
};

export const insertBefore = (onto, target, toInsert) => {
  const targetIndex = onto.indexOf(target);
  onto.splice(targetIndex, 0, toInsert);
  return onto;
};

export const insertAfter = (onto, target, toInsert) => {
  const targetIndex = onto.indexOf(target);
  onto.splice(targetIndex + 1, 0, toInsert);
  return onto;
};

export const intersection = (a = [], b = []) => a.filter((el) => b.includes(el));
export const difference = (a = [], b = []) => a.filter((e) => !b.includes(e));
export const union = (a = [], b = []) => Object.values([...a, ...b].reduce((res, cur) => ({ ...res, [cur]: cur }), {}));
export const unique = union;
