export const encodeColumnIndex = (input) => {
  let res = '';
  while (input > 0) {
    const next = (input - 1) % 26;
    input = (input - next - 1) / 26;

    res = `${String.fromCharCode(next + 65)}${res}`;
  }
  return res;
};

export const decodeColumnIndex = (input) => {
  let res = 0;
  for (let i = 0; i < input.length; i++) {
    const c = input[i].charCodeAt(0);

    const next = (c - 64) * (26 ** (input.length - i - 1));
    res += next;
  }
  return res;
};
