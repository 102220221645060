import { AND, NOT, OR } from '@/lib/filter/scope-tree';
import { HAS, UID_IN } from 'shared/api/query/constants';

export const filter = (value, filterTree) => {
  if (filterTree.child !== undefined && filterTree.child.length > 0) {
    const all = filterTree.child.reduce((res, next) => {
      res.push(filter(value, next));
      return res;
    }, []);

    switch (filterTree.op) {
      case AND: {
        for (let i = 0; i < all.length; i++) {
          if (!all[i]) {
            return false;
          }
        }

        return true;
      }
      case OR: {
        for (let i = 0; i < all.length; i++) {
          if (all[i]) {
            return true;
          }
        }

        return false;
      }
      case NOT: {
        return !all[0];
      }
      default:
        return false;
    }
  }

  switch (filterTree.func.name) {
    case HAS: {
      return value[filterTree.func.attr] !== undefined && value[filterTree.func.attr] !== null;
    }
    case UID_IN: {
      if (value[filterTree.func.attr] === undefined || value[filterTree.func.attr] === null) {
        return false;
      }
      const values = filterTree.func.args.map((a) => a.value);
      if (Array.isArray(value[filterTree.func.attr])) {
        for (let i = 0; i < value[filterTree.func.attr].length; i++) {
          if (values.includes(`${value[filterTree.func.attr][i].uid}`)) {
            return true;
          }
        }

        return false;
      }

      return values.includes(`${value[filterTree.func.attr].uid}`);
    }
    default:
      return false;
  }
};
