import { copy } from 'shared/lib/copy';

export const isEmptyFilter = (filterTree) => {
  if (filterTree === undefined || filterTree === null) {
    return true;
  }
  if (filterTree.func !== undefined && filterTree.func !== null) {
    return false;
  }
  if (filterTree.child === undefined || filterTree.child === null || filterTree.child.length === 0) {
    return true;
  }

  return filterTree.child.reduce((prev, next) => prev && isEmptyFilter(next), true);
};

export const removeEmptyTrees = (filterTree) => {
  if (isEmptyFilter(filterTree)) {
    return null;
  }

  if (filterTree.child === undefined || filterTree.child === null || filterTree.child.length === 0) {
    return filterTree;
  }

  const cp = copy(filterTree);
  cp.child = filterTree.child.reduce((acc, next) => [...acc, removeEmptyTrees(next)], [])
    .filter((el) => el !== null);
  return cp;
};
