<template>
  <div :class="['meeting-page']">
    <full-screen-spinner v-if="loading" />
    <m-content
      v-else-if="Object.keys(meeting).length === 0"
      class="_not-found"
      padding
    >
      <div class="_inner">
        <div class="_not-found">
          {{ $t('general.notFound') }}
        </div>
        <m-btn to="/meetings">
          {{ $t('navigation.meetings') }}
        </m-btn>
      </div>
    </m-content>
    <template v-else>
      <div class="_top">
        <meeting-page-header
          :meeting="meeting"
          :modal="openInModal"
          :route-after-delete="routeAfterDelete"
          :disabled="!canEdit"
          @deleted="deleted"
          @go-to-details="goToDetails"
        />
      </div>
      <div
        v-if="meeting.isTemplate"
        class="_template-hint"
      >
        {{ $t('meetingPage.templateHint') }}
        <m-icon
          type="schedule"
          class="_icon"
        />
        <strong>{{ $t('navigation.meetings') }}</strong>
      </div>
      <m-content
        boxed-small
        padding-x="layout"
        class="_content"
        padding
      >
        <meeting-props-editor
          ref="propsEditor"
          :meeting="meeting"
          :open-in-modal="openInModal"
          :private-note="privateNote"
          :agenda="agenda"
          :has-comments-section="hasCommentsSection"
          :can-edit="canEdit"
          class="_editor"
          @add-comments-section="addCommentsSection"
          @go-down="goToSectionAfterProps"
        />

        <template v-if="hasCommentsSection">
          <discussion
            :meeting="meeting"
            :comments="comments"
            :disabled="!canEdit"
          />
          <m-divider small />
        </template>

        <template
          v-if="privateNote !== null"
        >
          <private-note
            class="_private-notes"
            :private-note="privateNote"
          />
          <m-divider
            v-if="!privateNote.hidden"
            no-border
            xs
          />
        </template>
        <template
          v-if="agenda !== null"
        >
          <m-selectable
            v-model:value="selectedCollectionItems"
            :selector-class="selectorClass"
            select-area-class="meeting-page"
          >
            <collection
              ref="collection"
              class="_agenda"
              :collection="agenda"
              :parent-breadcrumbs="breadcrumbs"
              :selected-items="selectedCollectionItems"
              :item-class="selectorClass"
              :disabled="!canEdit"
              @select="selectCollectionItems"
              @focus-below="$refs.editor.focus()"
              @focus-before="$refs.propsEditor.focusTitle()"
            />
          </m-selectable>
          <m-divider v-if="!agenda.hidden && canEdit" />
        </template>
        <div
          class="_editor-wrapper"
          @click="handleEditorWrapperClick"
        >
          <m-editor
            v-if="meeting.content !== null || agenda !== null || showEditor"
            ref="editor"
            class="_editor"
            :initial-value="meeting.content"
            :placeholder="$t('meetingPage.startTyping')"
            :allowed-content="allowedContent"
            :disabled="!canEdit"
            default-font-size="1.6rem"
            @input="updateContent"
            @top-reached="$refs.collection.focus()"
          />
          <div
            v-else-if="canEdit"
            class="_placeholder"
          >
            <div class="_heading">
              {{ $t('meetingPage.placeholderHeading') }}
            </div>
            <template-list-item
              v-for="template in meetingTemplates"
              :key="template.uid"
              :template="template"
              :style="{ borderRadius: '.4rem', paddingLeft: 0, paddingRight: 0 }"
              super-light
              @select-template="addContent"
              @edit="openTemplate"
              @delete="deleteTemplate"
            />
          </div>
          <div
            v-else
            class="_empty"
          >
            {{ $t('meetingPage.emptyPage') }}
          </div>
        </div>
      </m-content>
    </template>
  </div>
</template>

<script>
import Collection from '@/components/Collection.vue';
import Discussion from '@/components/Discussion.vue';
import FullScreenSpinner from 'shared/components/FullScreenSpinner.vue';
import MEditor from '@/components/editor/MEditor.vue';
import MSelectable from 'shared/components/base/MSelectable.vue';
import MeetingPageHeader from '@/components/meeting/MeetingPageHeader.vue';
import MeetingPropsEditor from '@/components/meeting/MeetingPropsEditor.vue';
import PrivateNote from '@/components/meeting/PrivateNote.vue';
import TemplateListItem from '@/components/page/TemplateListItem.vue';
import deleteMixin from '@/mixins/delete';
import navigationMixin from '@/mixins/router-navigation';
import useAccess from '@/composables/access/access';
import useComments from '@/composables/comments/comments';
import useDebounce from '@/composables/debounce';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useProperties from '@/composables/property/property';
import { accessPolicyType, editorNodeType, featureFlag, routeName } from 'shared/constants.json';
import { children as collectionChildren } from '@/api/query/collection';
import { collection as collectionConfig, meeting as meetingConfig } from 'shared/api/query/configs.json';
import { extractMentions } from '@/lib/mention/mention';
import { mapActions, mapGetters, mapState } from 'vuex';
import { meetingTemplates } from '@/api/query/meeting';

export default {
  name: 'MeetingPage',
  props: {
    id: {
      type: Number,
      required: true,
    },
    openInModal: {
      type: Boolean,
      default: false,
    },
    routeAfterDelete: {
      type: String,
      required: true,
    },
  },
  emits: ['close', 'deleted'],
  components: {
    Discussion,
    MeetingPageHeader,
    FullScreenSpinner,
    MeetingPropsEditor,
    MEditor,
    PrivateNote,
    Collection,
    MSelectable,
    TemplateListItem,
  },
  setup(props) {
    const allowedContent = [
      editorNodeType.todoList,
      editorNodeType.heading,
      editorNodeType.codeBlock,
      editorNodeType.blockquote,
      editorNodeType.bulletList,
      editorNodeType.orderedList,
      editorNodeType.mention,
      editorNodeType.goalMention,
    ];

    const { accountHasFeature } = useAccess();
    if (accountHasFeature([featureFlag.fileUpload])) {
      allowedContent.push(editorNodeType.image, editorNodeType.file);
    }

    const { meetingProperties } = useProperties();
    const { comments } = useComments();
    const { debounce } = useDebounce();
    const { loggedInUser } = useLoggedInUser();
    const { loggedInUserAccount } = useLoggedInUserAccount();
    return {
      debounce,
      meetingProperties,
      loggedInUser,
      loggedInUserAccount,
      comments: comments({ meetingId: props.id }),
      allowedContent,
    };
  },
  data() {
    return {
      loading: true,
      createBtnLoading: false,
      showUpdateProgressModal: false,
      childrenLoading: false,
      selectorClass: 'selector-item',
      selectedCollectionItems: [],
      showEditor: false,

      addComments: false,
    };
  },
  computed: {
    ...mapGetters(['meetings']),
    ...mapState({
      privateNotes: (state) => state.privateNote,
      collections: (state) => state.collection,
      pages: (state) => state.page,
    }),
    canEdit() {
      return [accessPolicyType.full, accessPolicyType.write].includes(this.meeting.accessRight);
    },
    meetingTemplates() {
      return [
        ...this.meetings.filter((m) => m.isTemplate && m.uid !== this.meeting.uid).map((t) => {
          const collection = this.getCollection(t);
          return {
            ...t,
            empty: t.content === null && collection === null,
            collection,
          };
        }),
        {
          title: this.$t('meetingTemplateList.empty'),
          content: null,
          empty: true,
          collection: null,
        },
      ];
    },
    breadcrumbs() {
      return [
        { title: this.meeting.title === '' ? this.$t('general.untitled') : this.meeting.title, disabled: true },
        { title: this.$t('meetingPage.agendaTitle'), disabled: true },
      ];
    },
    hasCommentsSection() {
      return this.addComments || this.comments.length > 0;
    },
    agenda() {
      const f = this.collections.filter((p) => p.meeting.uid === this.id);
      if (f.length === 0) {
        return null;
      }

      return f[0];
    },
    privateNote() {
      const f = this.privateNotes.filter((p) => p.meeting.uid === this.id);
      if (f.length === 0) {
        return null;
      }

      return f[0];
    },
    showSpinner() {
      return this.childrenLoading;
    },
    meeting() {
      const filtered = this.meetings.filter((m) => m.uid === this.id);
      if (filtered.length !== 1) {
        return {};
      }

      return filtered[0];
    },
  },
  methods: {
    ...mapActions(['getMeeting', 'createEntity', 'getMeetingTemplates']),
    getCollection(template) {
      const f = this.collections.filter((c) => c.meeting.uid === template.uid);
      if (f.length === 0) {
        return null;
      }

      return {
        ...f[0],
        items: f[0].items.map((item) => {
          const filtered = this.pages.filter((p) => p.uid === item.content.uid);
          if (filtered.length === 0) {
            return item;
          }
          return {
            ...item,
            content: filtered[0],
          };
        }),
      };
    },
    openTemplate(template) {
      this.open({ uid: template.uid, paramKey: 'meetingId', mobileRouteName: routeName.meetingDetails, name: routeName.meetingsExplorer });
    },
    deleteTemplate(template) {
      this.showDeleteModal({ entities: [template], model: meetingConfig.model, mutation: 'MEETINGS_DELETED' })();
    },
    loadMeetingTemplates() {
      if (this.$store.state.meetingTemplatesRetrieved) {
        return;
      }
      this.getMeetingTemplates({ queries: meetingTemplates() }).then((response) => {
        if (response.status !== 200) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
        }
      });
    },
    goToSectionAfterProps() {
      if (this.agenda !== null) {
        this.$refs.collection.focus();
        return;
      }

      this.handleEditorWrapperClick();
    },
    handleEditorWrapperClick() {
      this.showEditor = true;
      this.$nextTick(() => {
        this.$refs.editor.focus();
      });
    },
    addContent({ template }) {
      if (template.collection === null) {
        this.showEditor = true;
        this.$nextTick(() => {
          this.$refs.editor.setContent(template.content);
          this.$refs.editor.focus();
        });
        return;
      }

      this.createEntity({
        entity: {
          ...template.collection,
          uid: 0,
          meeting: { uid: this.meeting.uid },
          items: template.collection.items.map((i) => ({
            ...i,
            uid: 0,
            content: {
              ...i.content,
              uid: 0,
            },
          })),
        },
        entityById: () => [{ children: collectionChildren }],
        mutation: 'MEETING_AGENDA_CREATED',
        model: collectionConfig.model,
      }).then((r) => {
        if (r.status !== 201) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
          return;
        }

        this.showEditor = true;
        this.$nextTick(() => {
          this.$refs.editor.setContent(template.content);
          this.$refs.editor.focus();
        });
      });
    },
    addCommentsSection() {
      this.addComments = true;
    },
    selectCollectionItems({ items }) {
      this.selectedCollectionItems = items;
    },
    deleted() {
      this.$emit('close');
      this.$emit('deleted');
    },
    goToDetails({ to }) {
      this.$router.push(to);
    },
    get() {
      if (Object.keys(this.meeting).length > 0) {
        this.loading = false;
      } else {
        this.loading = true;
      }

      this.getMeeting({ id: this.id }).then((response) => {
        this.loading = false;
        if (response.status !== 200) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
        }
      });
    },
    updateContent(js) {
      if (!this.canEdit) {
        return;
      }

      const update = () => {
        this.$store.commit('MEETING_UPDATED', {
          entity: {
            ...this.meeting,
            content: js,
            mentions: extractMentions(js),
          },
        });
      };

      this.debounce(update, 500);
    },
  },
  watch: {
    id() {
      this.get();
    },
  },
  created() {
    this.get();
    this.loadMeetingTemplates();
  },
  mixins: [navigationMixin, deleteMixin],
};
</script>

<style lang="scss" scoped type="text/scss">
  .meeting-page {
    padding-bottom: 15rem;
    background-color: white;

    ._template-hint {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 5rem;
      margin-top: 1rem;
      color: $font-color-secondary;
      background-color: map_get($yellow, 'lighten-4');

      ._icon {
        margin: 0 .4rem;
      }
    }

    ._agenda {
      margin-top: 3rem;
    }

    ._private-notes {
      margin-top: 3rem;
    }

    ._editor-wrapper {
      display: flex;
      flex-direction: column;
      min-height: 40vh;
      margin-top: 3rem;

      ._editor {
        flex: 1 1 auto;
        width: 100%;
      }

      ._placeholder {
        color: $font-color-tertiary;

        ._heading {
          margin-bottom: 2rem;
        }
      }

      ._empty {
        color: $font-color-tertiary;
      }
    }
  }
</style>
