<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.625 7.14844H14.625V4.5C14.625 4.08516 14.2898 3.75 13.875 3.75H4.3125V2.8125C4.3125 2.70937 4.22812 2.625 4.125 2.625H2.8125C2.70937 2.625 2.625 2.70937 2.625 2.8125V21.1875C2.625 21.2906 2.70937 21.375 2.8125 21.375H4.125C4.22812 21.375 4.3125 21.2906 4.3125 21.1875V15H10.125V17.6484C10.125 18.0633 10.4602 18.3984 10.875 18.3984H20.625C21.0398 18.3984 21.375 18.0633 21.375 17.6484V7.89844C21.375 7.48359 21.0398 7.14844 20.625 7.14844ZM4.3125 13.3125V5.4375H12.9375V13.3125H4.3125ZM19.6875 16.7109H11.8125V15H14.4375C14.5406 15 14.625 14.9156 14.625 14.8125V8.83594H19.6875V16.7109Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default { name: 'Flag' };
</script>

<style scoped lang="scss" type="text/scss">

</style>
