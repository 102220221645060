import { DEFAULT_ATTRIBUTES } from 'shared/api/query/constants';
import { goalCycle as goalCycleConfig } from 'shared/api/query/configs.json';

export const goalCycleChildren = [
  ...DEFAULT_ATTRIBUTES,
  { attr: goalCycleConfig.edges.title },
  { attr: goalCycleConfig.edges.color, default: 'default' },
  { attr: goalCycleConfig.edges.start },
  { attr: goalCycleConfig.edges.end },
];
