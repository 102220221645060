import Node from '@/tiptap/tiptap/Utils/Node';
import SuggestionsPlugin from '@/tiptap/extensions/plugins/Suggestions';
import { replaceText } from '@/tiptap/commands/commands';

export default class EmojiSuggestion extends Node {
  get name() {
    return 'emoji-suggestion';
  }

  get defaultOptions() {
    return {
      matcher: {
        char: ''
          + '',
        allowSpaces: false,
        startOfLine: false,
      },
      suggestionClass: 'emoji-suggestion',
    };
  }

  get schema() {
    return {
      group: 'inline',
      inline: true,
      atom: true,
      toDOM: () => ['span', 0],
    };
  }

  commands({ schema }) {
    return (emoji) => replaceText(null, schema.nodes.text(emoji), {});
  }

  get plugins() {
    return [
      SuggestionsPlugin({
        matcher: this.options.matcher,
        onChange: this.options.onChange,
        onExit: this.options.onExit,
        suggestionClass: this.options.suggestionClass,
        onKeyDown: this.options.onKeyDown,
      }),
    ];
  }
}
