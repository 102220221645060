<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1"
      y="7"
      width="3"
      height="7"
      fill="#CDD1D3"
    />
    <rect
      x="7"
      y="5"
      width="3"
      height="9"
      fill="#CDD1D3"
    />
    <rect
      x="13"
      y="3"
      width="3"
      height="11"
      fill="#CDD1D3"
    />
  </svg>
</template>

<script>
export default { name: 'DraftIcon' };
</script>

<style scoped lang="scss" type="text/scss">

</style>
