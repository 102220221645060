import { arrayToMap } from 'shared/lib/array/array';

export const updateObjectInArray = (array, object, key = 'uid') => {
  let index = -1;
  if (typeof object === 'undefined') {
    return;
  }
  if (typeof object[key] === 'undefined') {
    return;
  }
  array.forEach((current, currentIndex) => {
    if (typeof current[key] === 'undefined') {
      return;
    }
    if (current[key] === object[key]) {
      index = currentIndex;
    }
  });

  if (index === -1) {
    return;
  }

  array.splice(index, 1, object);
};

export const updateObjectsInArray = (array, objects, key = 'uid') => {
  objects.forEach((o) => updateObjectInArray(array, o, key));
};

export const addOrUpdateObjectInArray = (array, object, key = 'uid') => {
  let index = -1;
  if (typeof object === 'undefined') {
    return;
  }

  if (typeof object[key] === 'undefined') {
    array.push(object);
    return;
  }

  for (let i = 0; i < array.length; i++) {
    if (typeof array[i][key] === 'undefined') {
      return;
    }
    if (array[i][key] === object[key]) {
      index = i;
    }
  }

  if (index === -1) {
    array.push(object);
    return;
  }

  array.splice(index, 1, object);
};

export const addOrUpdateObjectsInArray = (array, objects, key = 'uid') => {
  objects.forEach((o) => addOrUpdateObjectInArray(array, o, key));
};

export const removeObjectInArray = (array, object, key = 'uid') => {
  let index = -1;
  if (typeof object === 'undefined') {
    return;
  }
  if (typeof object[key] === 'undefined') {
    return;
  }
  array.forEach((current, currentIndex) => {
    if (typeof current[key] === 'undefined') {
      return;
    }
    if (current[key] === object[key]) {
      index = currentIndex;
    }
  });

  if (index === -1) {
    return;
  }

  array.splice(index, 1);
};

export const removeObjectsInArray = (array, objects, key = 'uid') => {
  objects.forEach((o) => removeObjectInArray(array, o, key));
};

export const addOrUpdate = (array, objects, key = 'uid') => {
  const map = arrayToMap(array, key);
  objects.forEach((o) => {
    if (typeof o === 'undefined' || o === null) {
      return;
    }
    map[o[key]] = o;
  });
  return Object.values(map);
};

export const addOrUpdateKeep = (array, objects, key = 'uid') => {
  const map = arrayToMap(array);
  objects.forEach((o) => {
    if (typeof o === 'undefined' || o === null) {
      return;
    }
    map[o[key]] = {
      ...map[o[key]],
      ...o,
    };
  });
  return Object.values(map);
};
