import { environmentVariable, resolveEnvironmentVariable } from '@/lib/env';

import { EVENTS } from '@/lib/constants';
import { EventBus } from '@/lib/event-bus';

const syncHost = () => {
  if (window.location.host.includes('stage.x.mooncamp.com')) {
    return window.location.host.replace('stage', 'stage-subscription');
  }

  return resolveEnvironmentVariable(environmentVariable.VORTEX_HOST);
};

let websocketConnection = null;

const connect = () => {
  const subscriptionUrl = `wss://${syncHost()}/api/v1/subscribe`;
  websocketConnection = new WebSocket(subscriptionUrl);
};

const disconnect = () => {
  if (websocketConnection === null) {
    return;
  }

  websocketConnection.close();
  websocketConnection = null;
};

let connector = null;
let eventHandlers = [];

const initConnector = () => {
  eventHandlers = [];
  connector = new Promise((resolve) => {
    const handler = () => { connect(); resolve(); };
    eventHandlers.push(handler);
    EventBus.$on(EVENTS.SESSION.LOGIN, handler);
  });
};

export const initVortex = () => {
  initConnector();

  EventBus.$on(EVENTS.SESSION.LOGOUT, () => {
    disconnect();
    eventHandlers.forEach((handler) => { EventBus.$off(EVENTS.SESSION.LOGIN, handler); });
    initConnector();
  });
};

export const vortex = () => connector.then(() => websocketConnection);
