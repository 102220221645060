export const walkPath = (node, parent, path, applyFn) => {
  if (path.length === 0) {
    throw new Error('path does not exist');
  }

  if (path.length > 1) {
    const val = node[path[0]];
    if (val === undefined || val === null || (!Array.isArray(val) && typeof val !== 'object')) {
      return;
    }
    if (Array.isArray(val)) {
      val.forEach((v) => {
        walkPath(v, node, path.slice(1), applyFn);
      });
      return;
    }
    walkPath(val, node, path.slice(1), applyFn);
    return;
  }

  applyFn(node, parent, path[0]);
};
