import { RESULT, UID } from 'shared/api/query/constants';
import {
  newDefaultBulkUpsert,
  newDefaultsQuery,
  newDefaultsUpsert,
} from 'shared/dogma/lib/default';
import { newExpectSingle } from 'shared/dogma/lib/single-result';
import { kebabCase as transformToKebabCase } from 'lodash-es';

export class Dogma {
  constructor(axiosInstance) {
    this.queryMiddlewares = [newDefaultsQuery];
    this.querySingleMiddlewares = [newDefaultsQuery, newExpectSingle];
    this.upsertMiddlewares = [newDefaultsUpsert];
    this.bulkUpsertMilddlewares = [newDefaultBulkUpsert];
    this.axiosInstance = axiosInstance;
  }

  query(queries, opts) {
    let queryFn = (qs) => this.queryFn.bind(this)(qs, opts);
    this.queryMiddlewares.forEach((f) => {
      queryFn = f(queryFn);
    });

    return queryFn(queries);
  }

  querySingle(queries, opts) {
    let queryFn = (qs) => this.queryFn.bind(this)(qs, opts);
    this.querySingleMiddlewares.forEach((f) => {
      queryFn = f(queryFn);
    });

    return queryFn(queries);
  }

  select(
    ids,
    model,
    attributes,
  ) {
    const q = {};
    q.uid = ids;
    q.model = model;
    q.alias = RESULT;
    q.func = { name: UID };

    if (attributes.length === 0) {
      q.children = [
        { attr: 'uid' },
        { attr: 'expand(_all_)' },
      ];

      return this.query([q]);
    }

    q.children = attributes;
    return this.query([q]);
  }

  selectSingle(
    id,
    model,
    attributes,
  ) {
    const q = {};
    q.uid = [id];
    q.model = model;
    q.alias = RESULT;
    q.func = { name: UID };

    if (attributes.length === 0) {
      q.children = [
        { attr: 'uid' },
        { attr: 'expand(_all_)' },
      ];

      return this.query([q]);
    }

    q.children = attributes;
    return this.querySingle([q]);
  }

  update(
    nodes,
    model,
    attributes,
    hookParameter,
  ) {
    let updateFn = this.updateFn.bind(this);
    this.upsertMiddlewares.forEach((f) => {
      updateFn = f(updateFn);
    });

    return updateFn(nodes, model, attributes, hookParameter);
  }

  updateSingle(
    node,
    model,
    attributes,
    hookParameter,
    opts,
  ) {
    let updateSingleFn = (node, model, attributes, hookParameter) => this.updateSingleFn.bind(this)(node, model, attributes, hookParameter, opts);
    this.upsertMiddlewares.forEach((f) => {
      updateSingleFn = f(updateSingleFn);
    });

    return updateSingleFn(node, model, attributes, hookParameter);
  }

  mutate(
    nodes,
    model,
    attributes,
    updateHookParameter,
    createHookParameter,
  ) {
    let mutateFn = this.mutateFn.bind(this);
    this.upsertMiddlewares.forEach((f) => {
      mutateFn = f(mutateFn);
    });

    return mutateFn(
      nodes,
      model,
      attributes,
      updateHookParameter,
      createHookParameter,
    );
  }

  mutateSingle(
    node,
    model,
    attributes,
    updateHookParameter,
    createHookParameter,
  ) {
    let mutateSingleFn = this.mutateSingleFn.bind(this);
    this.upsertMiddlewares.forEach((f) => {
      mutateSingleFn = f(mutateSingleFn);
    });

    return mutateSingleFn(
      node,
      model,
      attributes,
      updateHookParameter,
      createHookParameter,
    );
  }

  create(
    nodes,
    model,
    attributes,
    hookParameter,
  ) {
    let createFn = this.createFn.bind(this);
    this.upsertMiddlewares.forEach((f) => {
      createFn = f(createFn);
    });

    return createFn(nodes, model, attributes, hookParameter);
  }

  createSingle(
    node,
    model,
    attributes,
    hookParameter,
  ) {
    let createSingleFn = this.createSingleFn.bind(this);
    this.upsertMiddlewares.forEach((f) => {
      createSingleFn = f(createSingleFn);
    });

    return createSingleFn(node, model, attributes, hookParameter);
  }

  queryFn(queries, opts) {
    let header = this.axiosInstance.defaults.headers;
    if (typeof opts !== 'undefined' && typeof opts.header !== 'undefined') {
      header = { ...header, ...opts.header };
    }
    return this.axiosInstance
      .post(
        '/query',
        { queries },
        { ...this.axiosInstance.defaults, headers: header },
      )
      .then((response) => response)
      .catch((error) => error.response);
  }

  mutateFn(
    nodes,
    model,
    attributes,
    updateHookParameter,
    createHookParameter,
  ) {
    return this.axiosInstance.post(`/mutate/${transformToKebabCase(model)}`, {
      attributes,
      createHookParameter,
      nodes,
      updateHookParameter,
    });
  }

  mutateSingleFn(
    node,
    model,
    attributes,
    updateHookParameter,
    createHookParameter,
  ) {
    return this.axiosInstance.post(
      `/mutate-single/${transformToKebabCase(model)}`,
      {
        attributes,
        createHookParameter,
        node,
        updateHookParameter,
      },
    );
  }

  bulkMutateFn(bulkPayloads) {
    return this.axiosInstance.post('/mutate', bulkPayloads);
  }

  updateSingleFn(
    node,
    model,
    attributes,
    hookParameter,
    opts,
  ) {
    let header = this.axiosInstance.defaults.headers;
    if (typeof opts !== 'undefined' && typeof opts.header !== 'undefined') {
      header = { ...header, ...opts.header };
    }
    return this.axiosInstance.post(
      `/update-single/${transformToKebabCase(model)}`,
      {
        attributes,
        hookParameter,
        node,
      },
      { ...this.axiosInstance.defaults, headers: header },
    );
  }

  updateFn(
    nodes,
    model,
    attributes,
    hookParameter,
  ) {
    return this.axiosInstance.post(
      `/update/${transformToKebabCase(model)}`,
      {
        attributes,
        hookParameter,
        nodes,
      },
    );
  }

  createFn(
    nodes,
    model,
    attributes,
    hookParameter,
  ) {
    return this.axiosInstance.post(
      `/create/${transformToKebabCase(model)}`,
      {
        attributes,
        hookParameter,
        nodes,
      },
    );
  }

  createSingleFn(
    node,
    model,
    attributes,
    hookParameter,
  ) {
    return this.axiosInstance.post(
      `/create-single/${transformToKebabCase(model)}`,
      {
        attributes,
        hookParameter,
        node,
      },
    );
  }

  bulkMutate(bulkPayloads) {
    let bulkMutateFn = this.bulkMutateFn.bind(this);
    this.bulkUpsertMilddlewares.forEach((f) => {
      bulkMutateFn = f(bulkMutateFn);
    });

    return bulkMutateFn(bulkPayloads);
  }
}
