<template>
  <div class="welcome-screen">
    <h1 class="_title">
      {{ welcomeScreen.title[lang] }}
    </h1>
    <div class="_description">
      {{ welcomeScreen.description[lang] }}
    </div>
    <div class="_action">
      <m-btn
        color="primary"
        @click="$emit('start')"
      >
        {{ $t('welcomeScreen.start') }}
      </m-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WelcomeScreen',
  props: {
    welcomeScreen: {
      type: Object,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
  },
  emits: ['start'],
};
</script>

<style scoped lang="scss" type="text/scss">
  .welcome-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: inherit;
    padding: 1.6rem;

    ._title {
      text-align: center;
    }

    ._description {
      margin-bottom: 3rem;
    }
  }
</style>
