<template>
  <div class="sort-order">
    <m-select
      v-model:value="selectedSortOption"
      :items="availableSortOptions"
      :disabled="disabled"
      class="_block _option"
    />
    <m-select
      v-model:value="selectedSortOrder"
      :items="sortOrderOptions"
      :disabled="disabled"
      class="_block _order"
    />
    <div
      v-if="!disabled"
      class="_block -delete"
    >
      <m-btn
        icon="close"
        fab
        hide-border
        small
        @click="$emit('delete')"
      />
    </div>
  </div>
</template>

<script>
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useProperties from '@/composables/property/property';
import { iconByType } from '@/lib/property';
import { orderEntitiesEqual } from '@/lib/sort';
import { textByLang } from 'shared/lib/language';

export default {
  name: 'SortOrder',
  props: {
    order: {
      type: Object,
      required: true,
    },
    orders: {
      type: Array,
      required: true,
    },
    sortOptions: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { userLang } = useLoggedInUser();
    const { properties } = useProperties();
    return { properties, userLang };
  },
  emits: ['delete', 'change'],
  data() {
    return {
      selectedSortOption: this.order,
      selectedSortOrder: this.order.desc,
    };
  },
  computed: {
    availableSortOptions() {
      const res = [this.selectedSortOption];

      for (let i = 0; i < this.sortOptions.length; i++) {
        if (typeof this.orders.find((fromOrders) => orderEntitiesEqual(fromOrders, this.sortOptions[i])) !== 'undefined') {
          continue;
        }

        res.push(this.sortOptions[i]);
      }

      return res.map((e) => ({
        text: this.edgeDescription(e),
        value: e,
        icon: this.icon(e),
      }));
    },
    sortOrderOptions() {
      return [
        {
          text: this.$t('sortOrder.descending'),
          value: true,
        },
        {
          text: this.$t('sortOrder.ascending'),
          value: false,
        },
      ];
    },
  },
  methods: {
    icon(order) {
      if (typeof order.langs !== 'undefined' && order.langs.length === 1) {
        const property = this.properties.find((p) => p.uid === parseInt(order.langs[0].slice(1), 10));
        if (typeof property !== 'undefined') {
          return iconByType({ type: property.type });
        }
      }

      return iconByType({ edgeName: order.attr });
    },
    edgeDescription(order) {
      if (typeof order.langs !== 'undefined' && order.langs.length === 1) {
        const property = this.properties.find((p) => p.uid === parseInt(order.langs[0].slice(1), 10));
        if (typeof property !== 'undefined') {
          return textByLang(property.label, this.userLang);
        }
      }

      return this.$t(`edges.${order.attr}`);
    },
  },
  watch: {
    selectedSortOption(val) {
      this.$emit('change', {
        attr: val.attr,
        desc: true,
        langs: val.langs,
      });
    },
    selectedSortOrder(val) {
      this.$emit('change', {
        attr: this.selectedSortOption.attr,
        desc: val,
        langs: this.selectedSortOption.langs,
      });
    },
    order: {
      handler(val) {
        if (this.selectedSortOption.attr !== val.attr) {
          this.selectedSortOption.attr = val.attr;
        }
        if (this.selectedSortOption.langs !== val.langs) {
          this.selectedSortOption.langs = val.langs;
        }
        if (this.selectedSortOption.title !== val.title) {
          this.selectedSortOption.title = val.title;
        }
        if (this.selectedSortOrder !== val.desc) {
          if (typeof val.desc === 'undefined') {
            this.selectedSortOrder = true;
            return;
          }

          this.selectedSortOrder = val.desc;
        }
      },
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .sort-order {
    display: flex;
    align-items: center;

    ._block {
      margin-right: .4rem;

      &.-delete {
        margin-left: auto;
      }
    }
  }
</style>
