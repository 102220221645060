import { DateTime } from 'luxon';
import { computed } from 'vue';
import { continuous, current, timePassed } from '@/lib/goal/progress';
import { copy } from 'shared/lib/copy';
import { findNearestPropertyToScore, getScoreSuggestion } from '@/lib/goal/status';
import { goalProgressMeasurement, goalThresholdTargetArea, propertyType } from 'shared/constants.json';
import { textByLang } from 'shared/lib/language';

export default function useStatusProperty(statusProperty, userLang) {
  const getSelectedOption = (propVal) => {
    if (propVal.length === 0) {
      return null;
    }
    return propVal[0].selectedOptions[0];
  };
  const getSelectedOptionLabel = (propVal) => {
    const selectedOption = getSelectedOption(propVal);
    if (selectedOption === null) {
      return '';
    }
    return textByLang(selectedOption.label, userLang);
  };

  const calculateInitialPropertyStatus = (goal) => {
    const pvStatus = copy(goal.properties.filter((p) => p.property.type === propertyType.status));
    const poStatus = pvStatus[0].selectedOptions[0];
    const currentIsClosed = childrenClosedOption.value.some((o) => o.uid === poStatus.uid);
    if (currentIsClosed) {
      return pvStatus.map((pv) => ({ ...pv, uid: undefined }));
    }
    const currentIsRecommendable = optionsToRecommend.value.some((o) => o.uid === poStatus.uid);
    if (!currentIsRecommendable) {
      pvStatus[0].selectedOptions[0] = calculateStatus(current(goal, goal.cachedCurrent), goal, DateTime.now());
    }
    return pvStatus.map((pv) => ({ ...pv, uid: undefined }));
  };

  const calculateStatus = (current, goal, createdAt) => {
    const low = findNearestPropertyToScore(optionsToRecommend.value, 0, defaultOption.value);
    const high = findNearestPropertyToScore(optionsToRecommend.value, 10, defaultOption.value);

    if (goal.progressMeasurement === goalProgressMeasurement.binary) {
      if (current > 0) {
        return high;
      }
      return low;
    }

    if (goal.progressMeasurement === goalProgressMeasurement.threshold) {
      switch (goal.thresholdTargetArea) {
        case goalThresholdTargetArea.above:
          return current > goal.threshold ? high : low;
        case goalThresholdTargetArea.aboveOrEqual:
          return current >= goal.threshold ? high : low;
        case goalThresholdTargetArea.belowOrEqual:
          return current <= goal.threshold ? high : low;
        case goalThresholdTargetArea.below:
          return current < goal.threshold ? high : low;
        default:
          return low;
      }
    }

    let v = 0;
    if (goal.progressMeasurement === goalProgressMeasurement.continuous) {
      v = continuous(goal, current);
    }

    if (goal.progressMeasurement === goalProgressMeasurement.alignedItems) {
      v = current;
    }

    const tp = timePassed({
      goalCycles: goal.goalCycle,
      time: createdAt,
    });

    const scoreSuggestion = getScoreSuggestion(v, tp);
    return findNearestPropertyToScore(optionsToRecommend.value, scoreSuggestion, defaultOption.value);
  };

  const rootOptions = computed(() => statusProperty.value.options.filter((o) => o.parents.length === 0));
  const parentActiveOption = computed(() => rootOptions.value.find((o) => o.label.en === 'Active'));
  const parentClosedOption = computed(() => rootOptions.value.find((o) => o.label.en === 'Closed'));

  const options = computed(() => statusProperty.value.options
    .filter((o) => o.archivedAt === undefined || o.archivedAt === null));

  const leafOptions = computed(() => options.value.filter((o) => o.parents.length > 0));

  const optionsToRecommend = computed(() => options.value
    .filter((o) => o.score !== null)
    .filter((o) => o.parents.some((p) => p.uid === parentActiveOption.value.uid)));

  const childrenClosedOption = computed(() => options.value
    .filter((o) => o.parents.some((p) => p.uid === parentClosedOption.value.uid)));

  const defaultOption = computed(() => {
    const defaultOptionUIDs = statusProperty.value.defaultPropertyOptions.map((o) => o.uid);
    return options.value.find((o) => defaultOptionUIDs.includes(o.uid));
  });

  const translateStatusOptionToIcon = (opt) => {
    const option = statusProperty.value.options.find((o) => o.uid === opt.uid);
    const parents = option.parents.map((p) => p.uid);
    const parent = statusProperty.value.options.find((o) => parents.includes(o.uid));
    if (parent === undefined) {
      return '';
    }

    if (parent.label.en === 'Active') {
      return 'active-status';
    }
    if (parent.label.en === 'Closed') {
      return 'closed-status';
    }

    return '';
  };

  // TODO: filtering the options by label is valid until we have customized labels
  const activeStatusOption = computed(() => statusProperty.value.options.find((s) => s.label.en === 'Active'));
  const closedStatusOption = computed(() => statusProperty.value.options.find((s) => s.label.en === 'Closed'));

  const activeStatusOptionIDs = computed(() => {
    if (activeStatusOption.value === undefined || activeStatusOption.value.children === undefined) {
      return [];
    }
    return activeStatusOption.value.children.map((c) => c.uid);
  });
  const getActiveOptionsByColor = (color) => statusProperty.value.options.filter((s) => activeStatusOptionIDs.value.includes(s.uid) && s.color !== undefined && s.color === color);

  const onTrackStatusOptions = computed(() => getActiveOptionsByColor('green'));
  const offTrackStatusOptions = computed(() => getActiveOptionsByColor('red'));
  const progressingStatusOptions = computed(() => getActiveOptionsByColor('yellow'));

  const groupedOptions = computed(() => options.value.reduce((res, next) => {
    const parentIds = next.parents.map((p) => p.uid);
    if (parentIds.includes(activeStatusOption.value.uid)) {
      res[0].options.push(next);
      return res;
    }
    if (parentIds.includes(closedStatusOption.value.uid)) {
      res[1].options.push(next);
      return res;
    }
    return res;
  }, [{ label: textByLang(activeStatusOption.value.label, userLang.value), options: [] }, { label: textByLang(closedStatusOption.value.label, userLang.value), options: [] }]));

  return {
    getSelectedOption,
    groupedOptions,
    getSelectedOptionLabel,
    calculateInitialPropertyStatus,
    options,
    leafOptions,
    defaultOption,
    activeStatusOption,
    closedStatusOption,
    onTrackStatusOptions,
    offTrackStatusOptions,
    progressingStatusOptions,
    calculateStatus,
    translateStatusOptionToIcon,
  };
}
