<template>
  <div class="field-chart-row">
    <div class="_top">
      <div class="_question">
        <router-link
          v-if="titleLink !== null"
          :to="titleLink"
          class="_title -link"
        >
          {{ title }}
        </router-link>
        <div
          v-else
          class="_title"
        >
          {{ title }}
        </div>
        <div class="_sub-title">
          {{ amountAnswers }} {{ $t('fieldChartRow.responses', amountAnswers) }}
        </div>
        <div
          v-if="showComments"
          class="_comment-expand"
          @click="toggleComments"
        >
          <div class="_text">
            {{ $t('fieldChartCard.comments') }} ({{ comments.length }})
          </div>
          <m-icon
            :type="expandedComments ? 'up': 'down'"
            size="11"
          />
        </div>
      </div>
      <div class="_chart">
        <div
          v-if="!hasData"
          class="_no-result"
        >
          <div class="_inner">
            {{ $t('formResults.notEnoughData') }}
          </div>
        </div>
        <div v-else>
          <div
            v-if="isTextChart"
            class="_text-content"
          >
            <chart-comments
              :comments="comments"
            />
          </div>
          <div v-else>
            <m-chart
              :chart-data="chartData"
              :chart="chart"
              :max-value="maxValue"
              :starts-from-zero="startsFromZero"
            />
            <m-transition-expand v-if="showComments">
              <div v-show="expandedComments">
                <chart-comments
                  class="_comment-list"
                  show-value
                  :comments="comments"
                  :max-value="maxValue"
                  :starts-from-zero="startsFromZero"
                />
              </div>
            </m-transition-expand>
          </div>
        </div>
      </div>
      <div class="_score">
        {{ formattedResultValue }}
      </div>
    </div>
  </div>
</template>

<script>
import ChartComments from '@/components/ChartComments.vue';
import MChart from '@/components/MChart.vue';
import { chartType } from 'shared/constants.json';
import { getFormattedValue } from '@/lib/charts/format';

export default {
  name: 'ChartRow',
  props: {
    chart: {
      type: Object,
      required: true,
    },
    chartData: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    showComments: {
      type: Boolean,
      default: false,
    },
    startsFromZero: {
      type: Boolean,
      default: false,
    },
    maxValue: {
      type: Number,
      required: true,
    },
    titleLink: {
      type: Object,
      default: () => null,
    },
  },
  components: {
    ChartComments,
    MChart,
  },
  data() {
    return { expandedComments: false };
  },
  computed: {
    amountAnswers() {
      const latestIndex = this.chartData.intervals.length - 1;
      return this.chartData.series[0].data[latestIndex].amountAnswers;
    },
    resultObject() {
      const latestIndex = this.chartData.intervals.length - 1;
      return this.chartData.series[0].data[latestIndex];
    },
    formattedResultValue() {
      return getFormattedValue(this.resultObject.value, this.chart.aggregation);
    },
    hasData() {
      if (this.isTextChart) {
        return this.resultObject.comments.length > 0;
      }
      return this.resultObject.value !== null;
    },
    comments() {
      const latestIndex = this.chartData.intervals.length - 1;
      return this.chartData.series[0].data[latestIndex].comments;
    },
    isTextChart() {
      return this.chart.type === chartType.freeText;
    },
  },
  methods: {
    toggleComments() {
      this.expandedComments = !this.expandedComments;
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .field-chart-row {
    min-height: 6.8rem;

    &:hover {
      .-link {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    ._top {
      display: flex;

      ._question {
        flex: 0 0 25rem;
        padding-right: 2rem;

        ._title {
          margin-bottom: .4rem;
          font-weight: $font-weight-semibold;
          color: $font-color-primary;

          &.-link {
            color: $font-color-primary;
          }
        }

        ._sub-title {
          margin-bottom: .4rem;
          color: $font-color-secondary;
        }

        ._comment-expand {
          display: flex;
          color: $font-color-secondary;
          cursor: pointer;

          ._text {
            margin-right: .8rem;
          }
        }
      }

      ._chart {
        flex: 1 1 auto;
        margin: 1.8rem 0;

        ._no-result {
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 3.2rem;
          color: $font-color-tertiary;
        }

        ._comment-list {
          margin-top: 3.2rem;
        }
      }

      ._score {
        flex: 0 1 auto;
        min-width: 7rem;
        padding-left: 2rem;
        margin-top: 2.4rem;
        font-weight: $font-weight-semibold;
        text-align: center;
      }
    }
  }
</style>
