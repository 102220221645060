import {
  DEFAULT_ATTRIBUTES,
  EQ,
  HAS,
  NOT,
  ONE_TO_ONE,
  UID,
  VAR,
} from 'shared/api/query/constants';
import {
  account,
  accountSettings,
  appIntegration,
  companyInfo as companyInfoConfig,
  customerContract as customerContractConfig,
  goalSettings as goalSettingsConfig,
  oauthCodeGrantClient,
  property,
  propertyOption,
  provisionedGroup,
  provisionedUser,
  resourceSettings as resourceSettingsConfig,
  saml,
  space,
  storageObject,
  user,
  userProvisioning,
  userProvisioningMapping,
} from 'shared/api/query/configs.json';
import { children as accountSettingsChildren } from '@/api/query/nebula/account-settings';
import { appIntegrationChildren } from '@/api/query/nebula/app-integration';
import { children as companyInfoChildren } from '@/api/query/nebula/company-info';
import { customerContractEdges } from '@/api/query/nebula/customer-contract';
import { children as goalSettingsChildren } from '@/api/query/nebula/goal-settings';
import { provisionedGroupChildren, provisionedUserChildren } from '@/api/query/nebula/provisioned-resources';
import { children as resourceSettingsChildren } from '@/api/query/nebula/resource-settings';
import { reverseEdge } from 'shared/api/query/edges';
import { storageObjectEdges } from '@/api/query/nebula/storage-object';

export const samlChildren = [
  { attr: UID },
  { attr: saml.edges.account, model: account.model, children: [{ attr: UID }] },
  { attr: saml.edges.creator, model: user.model, children: [{ attr: UID }, { attr: user.edges.email }] },
  { attr: saml.edges.ssoUrl, default: '' },
  { attr: saml.edges.entityId, default: '' },
  { attr: saml.edges.certificate, default: '' },
];

export const userProvisioningMappingChildren = [
  { attr: UID },
  { attr: userProvisioningMapping.edges.scimAttribute },
  { attr: userProvisioningMapping.edges.scimAttributeType },
  { attr: userProvisioningMapping.edges.provisionedGroups, model: provisionedGroup.model, children: [{ attr: UID }, { attr: provisionedGroup.edges.displayName }], default: [] },
  {
    attr: userProvisioningMapping.edges.property,
    model: property.model,
    children: [{ attr: UID }],
  },
  { attr: userProvisioningMapping.edges.userProvisioning, model: userProvisioning.model, children: [{ attr: UID }] },
  { attr: userProvisioningMapping.edges.space, model: space.model, children: [{ attr: UID }], default: null },
  { attr: userProvisioningMapping.edges.propertyOption, model: propertyOption.model, children: [{ attr: UID }], default: null },
];

export const oauthCodeGrantClientChildren = [
  { attr: UID },
  { attr: oauthCodeGrantClient.edges.redirectUrls, default: [] },
  { attr: oauthCodeGrantClient.edges.clientId },
  { attr: oauthCodeGrantClient.edges.clientSecret },
  { attr: oauthCodeGrantClient.edges.pausedAt, default: null },
  { attr: oauthCodeGrantClient.edges.userProvisioning, model: userProvisioning.model, children: [{ attr: UID }] },
];

export const userProvisioningChildren = [
  { attr: UID },
  { attr: userProvisioning.edges.account, model: account.model, children: [{ attr: UID }] },
  { attr: userProvisioning.edges.language, default: 'en' },
  { attr: userProvisioning.edges.token, default: 'hidden' },
  {
    attr: reverseEdge(userProvisioningMapping.edges.userProvisioning),
    model: userProvisioningMapping.model,
    alias: 'mappings',
    children: userProvisioningMappingChildren,
    default: [],
  },
  {
    attr: reverseEdge(provisionedGroup.edges.userProvisioning),
    model: provisionedGroup.model,
    alias: 'provisionedGroups',
    default: [],
    filter: { op: NOT, child: [{ func: { name: HAS, attr: provisionedGroup.edges.deactivatedAt } }] },
    children: provisionedGroupChildren,
  },
  {
    alias: 'provisionedUsers',
    attr: reverseEdge(provisionedUser.edges.userProvisioning),
    model: provisionedUser.model,
    default: [],
    children: provisionedUserChildren,
  },
  { attr: userProvisioning.edges.pausedAt, default: null },
  {
    attr: reverseEdge(oauthCodeGrantClient.edges.userProvisioning),
    model: oauthCodeGrantClient.model,
    alias: 'oauthCodeGrantClient',
    children: [...oauthCodeGrantClientChildren],
    assoc: ONE_TO_ONE,
    default: null,
  },
];

export const provisionedUsersByUserProvisioning = (userProvisioningEntity) => [
  {
    alias: VAR,
    uid: [userProvisioningEntity.uid],
    func: { name: UID },
    model: userProvisioning.model,
    children: [{ attr: reverseEdge(provisionedUser.edges.userProvisioning), model: provisionedUser.model, filter: { op: 'not', child: [{ func: { name: EQ, attr: provisionedUser.edges.active, args: [{ value: false.toString() }] } }] }, children: [{ attr: 'uid', var: 'provisionedUsers' }] }],
  },
  {
    alias: 'provisionedUsers',
    needsVar: [{ name: 'provisionedUsers', typ: 1 }],
    model: provisionedUser.model,
    func: { name: UID, needsVar: [{ name: 'provisionedUsers', typ: 1 }] },
    children: provisionedUserChildren,
  },
];

export const children = [
  ...DEFAULT_ATTRIBUTES,
  { attr: account.edges.companyName, default: '' },
  { attr: account.edges.signUpPathName, default: '' },
  { attr: account.edges.numberOfEmployees, default: '' },
  {
    attr: account.edges.companyImage,
    default: null,
    model: storageObject.model,
    children: storageObjectEdges,
  },
  {
    attr: account.edges.accountSettings,
    default: null,
    model: accountSettings.model,
    children: accountSettingsChildren,
  },
  {
    attr: account.edges.resourceSettings,
    default: null,
    model: resourceSettingsConfig.model,
    children: resourceSettingsChildren,
  },
  {
    attr: account.edges.appIntegration,
    default: null,
    model: appIntegration.model,
    children: appIntegrationChildren,
  },
  {
    attr: account.edges.customerContract,
    default: null,
    model: customerContractConfig.model,
    children: customerContractEdges,
  },
  {
    attr: account.edges.goalSettings,
    default: null,
    model: goalSettingsConfig.model,
    children: goalSettingsChildren,
  },
  {
    attr: reverseEdge(companyInfoConfig.edges.account),
    alias: 'companyInfo',
    model: companyInfoConfig.model,
    children: companyInfoChildren,
    assoc: ONE_TO_ONE,
  },
  {
    attr: reverseEdge(saml.edges.account),
    alias: 'saml',
    model: saml.model,
    children: samlChildren,
    assoc: ONE_TO_ONE,
    default: null,
  },
  {
    attr: reverseEdge(userProvisioning.edges.account),
    model: userProvisioning.model,
    alias: 'userProvisioning',
    children: userProvisioningChildren,
    assoc: ONE_TO_ONE,
    default: null,
  },
];
