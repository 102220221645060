import axios from 'axios';
/* eslint-disable import/no-cycle */
import router from '@/router';
import store from '@/store';
/* eslint-enable import/no-cycle */
import { Dogma } from 'shared/dogma';
import { errorType } from '@/constants.json';
import { hasSignedRequest, token } from '@/lib/signed-request';
import { installRequestID } from '@/lib/request';

const BASE_URL = '/api/v1';
const httpclient = axios.create({ baseURL: BASE_URL });

const currentRoute = () => {
  if (typeof router.currentRoute !== 'undefined') {
    return router.currentRoute.value;
  }

  return router.default.currentRoute.value;
};

httpclient.interceptors.request.use(installRequestID);

httpclient.interceptors.request.use((config) => {
  if (typeof currentRoute().params.savedViewId !== 'undefined' && typeof currentRoute().query.s !== 'undefined') {
    const secret = atob(currentRoute().query.s);
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Basic ${btoa(`${currentRoute().params.savedViewId}:${secret}`)}`,
      },
    };
  }
  const url = `${config.baseURL}${config.url}`;
  if (hasSignedRequest(window.location.hash, url)) {
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${token(window.location.hash, url)}`,
      },
    };
  }

  return config;
}, (error) => Promise.reject(error));

const sendToLogin = (error) => {
  if (error.response.status !== 401) {
    return false;
  }

  return !['/api/v1/logout', '/logout'].includes(error.config.url);
};

const statusServiceUnavailable = 503;

const setVersion = (header) => {
  const version = header['api-version-sha'];
  const semverVersion = header['api-version'];
  if (version === undefined || version === null) {
    return;
  }

  if (semverVersion === undefined || semverVersion === null) {
    return;
  }

  store.commit('APP_VERSION_RETRIEVED', { version, semverVersion });
};

httpclient.interceptors.response.use(
  (response) => { setVersion(response.headers); return response; },
  (error) => {
    if (error.response === undefined || error.response === null) {
      // we are probably offline
      // TODO: add offline handler
      return error;
    }

    setVersion(error.response.headers);

    if (error.response.status === statusServiceUnavailable && error.response.data.includes(errorType.maintenanceMode)) {
      setTimeout(() => {
        store.commit('ENABLE_MAINTENANCE_MODE');
      }, 100);
    }

    if (sendToLogin(error)) {
      setTimeout(() => {
        store.commit('USER_NOT_LOGGED_IN');
      }, 100);
    }

    return Promise.reject(error);
  },
);

export const dogma = new Dogma(httpclient);

export const doSendPasswordForgetMail = ({ email }) => httpclient.post('/password-forget', { email })
  .then((response) => response)
  .catch((error) => error.response);

export const doSetPassword = ({ password }) => httpclient.post('/set-password', { password })
  .then((response) => response)
  .catch((error) => error.response);

export const doOAuthRegister = (payload) => httpclient.post('/oauth-register', payload)
  .then((response) => response)
  .catch((error) => error.response);

export const doOAuthLogin = (payload) => httpclient.post('/oauth-login', payload)
  .then((response) => response)
  .catch((error) => error.response);

export const doConfirmInvitation = (payload) => httpclient.post('/confirm-invitation', payload)
  .then((response) => response)
  .catch((error) => error.response);

export const doRegisterViaEmail = (payload) => httpclient.post('/register-mail', payload)
  .then((response) => response)
  .catch((error) => error.response);

export const doConfirmRegisterCode = (payload) => httpclient.post('/confirm-register-code', payload)
  .then((response) => response)
  .catch((error) => error.response);

export const doLoginUser = ({ email, password }) => httpclient.post('/login', {
  email,
  password,
})
  .then((response) => response)
  .catch((error) => error.response);

export const doLogoutUser = () => httpclient.post('/logout')
  .then((response) => response)
  .catch((error) => error.response);

export const doSamlLoginUser = ({ email }) => httpclient.get('/saml/login', { params: { email } })
  .then((response) => response)
  .catch((error) => error.response);

export const doDeleteLanguages = ({ model, ids, languages }) => httpclient.post(
  `/delete-language/${model}`,
  { ids, languages },
)
  .then((response) => response)
  .catch((error) => error.response);

export const doGetChartData = ({ charts, userScopeTree, intervalConfig }) => httpclient.post('/chart-data', {
  charts,
  userScopeTree,
  intervalConfig,
})
  .then((response) => response)
  .catch((error) => error.response);

export const doResetAccount = (payload) => httpclient.post('/delete-account', payload)
  .then((response) => response)
  .catch((error) => error.response);

export const doValidateCSV = (blob, lang) => httpclient.post(`/csv-validate?lang=${lang}`, blob)
  .then((response) => response)
  .catch((error) => error.response);

export const doImportCSV = (blob, lang) => httpclient.post(`/csv-import?lang=${lang}`, blob)
  .then((response) => response)
  .catch((error) => error.response);

export const doUploadFile = (formData, onUploadProgress = () => {
}) => httpclient.post(
  '/file',
  formData,
  { headers: { 'Content-Type': 'multipart/form-data' }, onUploadProgress },
).then((response) => response);

export const doCsvExport = ({ filter }) => httpclient.get('/csv-export', { params: { filter: JSON.stringify(filter) } });

export const doGetPublicAccountData = (signUpPathName) => httpclient.post(
  '/public-account-data',
  { signUpPathName },
).then((response) => response).catch((error) => error.response);

export const doConnectBot = (activity) => httpclient.post(
  '/microsoft-bot-connect',
  { activity },
).then((response) => response).catch((error) => error.response);

export const doSendContactSales = ({
  firstName,
  lastName,
  email,
  plan,
  licences,
  message,
  hsTicketCategory,
  subject,
}) => httpclient.post('/contact-sales', {
  firstName,
  lastName,
  email,
  plan,
  licences,
  message,
  hsTicketCategory,
  subject,
})
  .then((response) => response)
  .catch((error) => error.response);

export const doMSTeamsLogin = ({ accessToken, provider, refreshToken, tokenExpiry, signedTrackedRequest, samlResponse }) => httpclient.post(
  '/msteams/login',
  {
    accessToken: decodeURIComponent(accessToken),
    provider: decodeURIComponent(provider),
    refreshToken: decodeURIComponent(refreshToken),
    tokenExpiry: decodeURIComponent(tokenExpiry),

    // saml
    signedTrackedRequest: decodeURIComponent(signedTrackedRequest),
    samlResponse: decodeURIComponent(samlResponse),
  },
);

export const doProvisioningStatus = (userProvisioning) => httpclient.get(`/provisioning/status/${userProvisioning.uid}`).then((response) => response).catch((error) => error.response);

export const doProvisioningSync = (userProvisioning) => httpclient.post(`/provisioning/sync/${userProvisioning.uid}`).then((response) => response).catch((error) => error.response);

export const doGetSlackConsentUrl = (data) => httpclient.post('/slack-bot/consent-url', data)
  .then((response) => response)
  .catch((error) => error.response);

export const doGetStripePortal = (data) => httpclient.post('/stripe', data)
  .then((response) => response)
  .catch((error) => error.response);
