<template>
  <div class="">
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <!--eslint-disable-->
      <path
        fill="currentColor"
        d="M12.7909 14.763L10.4818 12.504L10.5091 12.477C12.0909 10.731 13.2182 8.724 13.8818 6.6H16.5455V4.8H10.1818V3H8.36364V4.8H2V6.591H12.1545C11.5455 8.328 10.5818 9.975 9.27273 11.415C8.42727 10.488 7.72727 9.471 7.17273 8.4H5.35455C6.01818 9.867 6.92727 11.253 8.06364 12.504L3.43636 17.022L4.72727 18.3L9.27273 13.8L12.1 16.599L12.7909 14.763ZM17.9091 10.2H16.0909L12 21H13.8182L14.8364 18.3H19.1545L20.1818 21H22L17.9091 10.2ZM15.5273 16.5L17 12.603L18.4727 16.5H15.5273Z"
      />
      <!--eslint-enable-->
    </svg>
  </div>
</template>

<script>
export default { name: 'Translate' };
</script>

<style scoped lang="scss" type="text/scss">

</style>
