import { UID } from 'shared/api/query/constants';
import { feedbackInquiry as feedbackInquiryConfig, field as fieldConfig, user as userConfig } from 'shared/api/query/configs.json';
import { children as fieldChildren } from '@/api/query/field';
import { userEdgesSlim } from 'shared/api/query/edges';

export const children = [
  { attr: UID },
  {
    attr: feedbackInquiryConfig.edges.creator,
    model: userConfig.model,
    children: userEdgesSlim,
  },
  { attr: feedbackInquiryConfig.edges.message, default: null },
  {
    attr: feedbackInquiryConfig.edges.questions,
    default: [],
    model: fieldConfig.model,
    children: fieldChildren,
  },
];
