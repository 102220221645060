import { ref } from 'vue';
import { uniq } from 'lodash-es';

export const EXPAND_ALL_MODE = 'expand_all';
export const COLLAPSE_ALL_MODE = 'collapse_all';
export const DEFAULT_MODE = 'default';

export default function useCascadeExpand(expandSvc, store = ref({ items: {}, mode: '' }), mode = DEFAULT_MODE) {
  const initStore = () => {
    if (store.value.mode === undefined || store.value.mode === '') {
      store.value.mode = mode;
    }
  };
  initStore();

  const findExpandedLeafs = (goals) => uniq(goals.reduce((res, next) => {
    if (!next.hasChildren || expandSvc.getExpand(next).value === false) {
      res.push(next);
    }
    return res;
  }, []));

  const highestLeafIdentation = (leafs) => leafs.reduce((highest, next) => {
    if (next.indentation > highest) {
      return next.indentation;
    }
    return highest;
  }, 0);

  const leafsWithIdentationGreaterEqualThan = (leafs, indentation) => leafs.filter((leaf) => leaf.indentation >= indentation);

  const leafsWithIdentationLowerEqualThan = (leafs, indentation) => leafs.filter((leaf) => leaf.indentation <= indentation);

  const expandNextLevel = (goals) => {
    store.value.mode = DEFAULT_MODE;

    const leafs = findExpandedLeafs(goals);
    const highestIdentation = highestLeafIdentation(leafs);
    const toBeExpanded = leafsWithIdentationLowerEqualThan(leafs, highestIdentation);

    expandSvc.expandItems(toBeExpanded);
  };

  const expandAll = (goals) => {
    store.value.mode = EXPAND_ALL_MODE;
    expandSvc.expandItems(goals);
  };

  const expandItem = (goal) => {
    store.value.mode = DEFAULT_MODE;
    expandSvc.expandItem(goal);
  };

  const expandItems = (goals) => {
    store.value.mode = DEFAULT_MODE;
    expandSvc.expandItems(goals);
  };

  const toggleExpand = (goal) => {
    store.value.mode = DEFAULT_MODE;
    expandSvc.toggleExpand(goal);
  };

  const collapseNextLevel = (goals) => {
    store.value.mode = DEFAULT_MODE;

    const leafs = findExpandedLeafs(goals);
    const highestIdentation = highestLeafIdentation(leafs);
    const toBeCollapsedParentsUid = uniq(leafsWithIdentationGreaterEqualThan(leafs, highestIdentation).reduce((parents, leaf) => {
      parents.push(...leaf.entity.parents.map((p) => p.uid));
      return parents;
    }, []));
    const toBeCollapsed = goals.filter((goal) => toBeCollapsedParentsUid.includes(goal.entity.uid));

    expandSvc.collapseItems(toBeCollapsed);
  };

  const collapseAll = (goals) => {
    store.value.mode = COLLAPSE_ALL_MODE;
    expandSvc.collapseItems(goals);
  };

  const setDefaultValues = (goalTree) => {
    switch (store.value.mode) {
      case DEFAULT_MODE: {
        const toExpand = [];
        goalTree.forEach((g) => {
          if (expandSvc.has(g)) {
            return;
          }
          if (g.indentation !== 0) {
            return;
          }
          toExpand.push(g);
        });
        expandSvc.expandItems(toExpand);
        break;
      }
      case EXPAND_ALL_MODE: {
        const toExpand = [];
        goalTree.forEach((g) => {
          if (expandSvc.getExpand(g).value === true) {
            return;
          }
          toExpand.push(g);
        });
        expandSvc.expandItems(toExpand);
        break;
      }
      case COLLAPSE_ALL_MODE: {
        const toCollapse = [];
        goalTree.forEach((g) => {
          if (expandSvc.getExpand(g).value === false) {
            return;
          }
          toCollapse.push(g);
        });
        expandSvc.collapseItems(toCollapse);
        break;
      }
      default:
    }
  };

  return {
    toggleExpand,
    getExpand: expandSvc.getExpand,
    realignAndRestoreExpandState: expandSvc.realignAndRestoreExpandState,
    expandItem,
    expandNextLevel,
    expandAll,
    expandItems,
    collapseNextLevel,
    collapseAll,
    setDefaultValues,
  };
}
