import useAccountSettings from '@/composables/logged-in-user-account/account-settings';
import useGridPageRepo from '@/composables/grid-page/grid-page-repo';
import { computed } from 'vue';

export default function useGridPageAllowance() {
  const { accountSettings } = useAccountSettings();
  const gridPageRepo = useGridPageRepo();

  const privatePagesAllowance = computed(() => accountSettings.value.volumePrivateDashboards);
  const publicPagesAllowance = computed(() => accountSettings.value.volumePublicDashboards);

  const privatePages = computed(() => gridPageRepo.gridPages.value.filter((e) => e.isPublic === false));
  const publicPages = computed(() => gridPageRepo.gridPages.value.filter((e) => e.isPublic === true));

  const hasPrivateAllowance = computed(() => privatePagesAllowance.value < 0 || privatePagesAllowance.value > privatePages.value.length);
  const hasPublicAllowance = computed(() => publicPagesAllowance.value < 0 || publicPagesAllowance.value > publicPages.value.length);

  const privateAllowanceAvailable = computed(() => privatePagesAllowance.value - privatePages.value.length);
  const publicAllowanceAvailable = computed(() => publicPagesAllowance.value - publicPages.value.length);

  return {
    privatePagesCount: computed(() => privatePages.value.length),
    publicPagesCount: computed(() => publicPages.value.length),
    privatePagesAllowance,
    publicPagesAllowance,
    hasPrivateAllowance,
    hasPublicAllowance,
    privateAllowanceAvailable,
    publicAllowanceAvailable,
  };
}
