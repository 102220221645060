<template>
  <m-drawer
    :value="showModal"
    hovering
    :title="$t('resourceCenter.title')"
    width="36rem"
    @close="hide"
  >
    <r-c-main
      :initial-page="initialPage"
      @close="hide"
    />
  </m-drawer>
</template>

<script>
import RCMain from '@/components/resource-center/RCMain.vue';
import { EVENTS, RESOURCE_CENTER_ONBOARDING_GUIDE, RESOURCE_CENTER_OVERVIEW } from '@/lib/constants';
import { EventBus } from '@/lib/event-bus';

export default {
  name: 'ResourceCenterDrawer',
  components: { RCMain },
  data() {
    return {
      showModal: false,
      initialPage: RESOURCE_CENTER_OVERVIEW,
    };
  },
  methods: {
    hide() {
      this.showModal = false;
    },
    show() {
      this.initialPage = RESOURCE_CENTER_OVERVIEW;
      this.showModal = true;
      EventBus.$emit(EVENTS.RESOURCE_CENTER.RESOURCE_CENTER_VIEWED);
    },
    showOnboarding() {
      this.initialPage = RESOURCE_CENTER_ONBOARDING_GUIDE;
      this.showModal = true;
    },
  },
  created() {
    EventBus.$on('show-resource-center', this.show);
    EventBus.$on('show-onboarding', this.showOnboarding);
  },
  beforeUnmount() {
    EventBus.$off('show-resource-center', this.show);
    EventBus.$off('show-onboarding', this.showOnboarding);
  },
};
</script>

<style scoped lang="scss" type="text/scss">

</style>
