<template>
  <div
    :class="['row-button', block ? '-block' : '']"
    @click="$emit('click', $event)"
  >
    <div
      v-if="icon !== ''"
      class="_icon"
    >
      <m-spinner
        v-if="loading"
        size="xs"
        :color="$colors.grey.lighten1"
      />
      <m-icon
        v-else
        :type="icon"
        :color="$colors.grey.lighten1"
      />
    </div>
    <div
      v-if="label !== ''"
      class="_label"
    >
      {{ label }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'RowButton',
  props: {
    label: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
};
</script>

<style scoped lang="scss" type="text/scss">
  .row-button {
    display: flex;
    align-items: center;
    height: 4rem;
    padding-left: 1.3rem;
    color: $font-color-secondary;
    cursor: pointer;
    border-radius: $input-field-border-radius;

    &.-block {
      width: 100%;
    }

    &:hover {
      background-color: $hover-color;
    }

    ._icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1.5rem;
      margin-right: 1rem;
    }
  }
</style>
