import { AND } from '@/lib/filter/scope-tree';
import { CREATED_AT, EQ, RESULT, TYPE, UID } from 'shared/api/query/constants';
import { accessPolicyChildren } from '@/api/query/nebula/access-policy';
import {
  accessPolicy as accessPolicyConfig,
  goalCycle as goalCycleConfig,
  planning as planningConfig,
  propertyValue as propertyValueConfig,
  user as userConfig,
} from 'shared/api/query/configs.json';
import { accessPolicyType } from 'shared/constants.json';
import { combine } from 'shared/api/query/filter';
import { propertyValueEdges } from '@/api/query/nebula/property-value';
import { searchTermFilter } from '@/lib/filter/search-term';

export const planningChildren = [
  { attr: UID },
  { attr: CREATED_AT },
  { attr: planningConfig.edges.title },
  { attr: planningConfig.edges.status },
  { attr: planningConfig.edges.accessRight, default: accessPolicyType.read },
  { attr: planningConfig.edges.creator, model: userConfig.model, children: [{ attr: UID }], default: null },
  {
    attr: planningConfig.edges.properties,
    default: [],
    model: propertyValueConfig.model,
    children: propertyValueEdges,
  },
  {
    attr: planningConfig.edges.goalCycles,
    default: [],
    model: goalCycleConfig.model,
    children: [{ attr: UID }],
  },
  {
    attr: planningConfig.edges.accessPolicy,
    model: accessPolicyConfig.model,
    children: accessPolicyChildren,
  },
];

const statusFilter = (status) => {
  if (status === '') {
    return null;
  }

  return { func: { name: EQ, attr: planningConfig.edges.status, args: [{ value: status }] } };
};

export const planningList = ({ status, searchTerm }) => {
  const filter = combine(AND, [statusFilter(status), searchTermFilter({ searchTerm, edgeName: planningConfig.edges.title })]);
  return [
    {
      alias: RESULT,
      model: planningConfig.model,
      func: { name: TYPE, args: [{ value: planningConfig.model }] },
      filter,
      default: [],
      children: planningChildren,
    },
  ];
};
