<template>
  <div :class="['question-field', active ? '-active' : '']">
    <div class="_number">
      <div class="_text">
        {{ questionIndex + 1 }}
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="10"
        viewBox="0 0 24 24"
      >
        <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
      </svg>
    </div>
    <div class="_title">
      {{ field.title[lang] }} <span v-if="required">*</span>
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'QuestionField',
  props: {
    questionIndex: {
      type: Number,
      required: true,
    },
    field: {
      type: Object,
      required: true,
    },
    active: {
      type: Boolean,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
  },
  computed: {
    required() {
      return this.field.validations.required;
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .question-field {
    position: relative;
    padding: 0 0 10rem;

    &:not(.-active) {
      opacity: .2;
    }

    ._number {
      position: absolute;
      top: 7rem;
      left: -3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 3rem;

      ._text {
        margin-right: .2rem;
      }

      svg {
        fill: $primary-color;
      }
    }

    ._title {
      padding-top: 6rem;
      margin: 1rem 0 3rem;
      font-size: $font-size-h3;
    }
  }
</style>
