<template>
  <goals-list-wrapper
    :header-slots="goalsListSlots"
    :can-create-goals="canCreate"
    :show-create-view="hasPublicViewAccess"
    :allowed-view-types="allowedViewTypes"
    fullscreen
    stretch-content
    show-views-selector
    show-errors
    :base-filter="publishedAtFilter"
    :selected-cycles="composedSelectedCycles"
    :change-selected-goal-cycles="changeSelectedGoalCycles"
    :default-props="defaultProps"
  />
</template>

<script setup>
import GoalsListWrapper from '@/components/goal/GoalsListWrapper.vue';
import useAccess from '@/composables/access/access';
import useGoalDefaultProps from '@/composables/saved-views/goal-default-props';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import usePersistedGoalCycle from '@/composables/goal-cycle/persisted-goal-cycle';
import usePublishedAtFilter from '@/composables/goal/published-at-filter';
import useViewServiceInit from '@/composables/saved-views/view-service-init';
import { AND } from '@/lib/filter/scope-tree';
import { SLOTS, VIEWS_SERVICE } from '@/lib/constants';
import { accessGroupFlag, viewApplication, viewType } from 'shared/constants.json';
import { computed, provide } from 'vue';
import { useI18n } from 'vue-i18n';

const allowedViewTypes = [viewType.cascade, viewType.tree, viewType.list, viewType.atlas];

const { userHasRight } = useAccess();

const canCreate = computed(() => userHasRight([accessGroupFlag.createGoal]));

const hasPublicViewAccess = computed(() => userHasRight([accessGroupFlag.publicSavedViews]));

const { publishedAtFilter } = usePublishedAtFilter();

const { composedSelectedCycles, changeSelectedGoalCycles } = usePersistedGoalCycle();

const defaultPropsSvc = useGoalDefaultProps();
const viewServiceInitService = useViewServiceInit();

const { loggedInUserAccount } = useLoggedInUserAccount();

const defaultProps = computed(() => defaultPropsSvc.defaultProps.value);

const i18n = useI18n();

const viewApp = viewApplication.goalAccount;
const defaultView = computed(() => ({
  title: i18n.t('list.cascade'),
  viewType: viewType.cascade,
  viewApplication: viewApp,
  params: {
    filter: { account: { uid: loggedInUserAccount.value.uid }, children: [], op: AND },
    props: [],
    order: [],
  },
}));

let routeAwareConfig = viewServiceInitService.defaultConfig();
routeAwareConfig = viewServiceInitService.withDefaultView(routeAwareConfig, defaultView.value);
routeAwareConfig = viewServiceInitService.withViewApplication(routeAwareConfig, viewApp);
routeAwareConfig = viewServiceInitService.withDefaultProps(routeAwareConfig, defaultProps);

const viewSvc = viewServiceInitService.routeAwareViewService(routeAwareConfig);

provide(VIEWS_SERVICE, viewSvc);

const goalsListSlots = [{ name: SLOTS.CYCLE_SELECTOR }, { name: SLOTS.FILTER }, { name: SLOTS.SORTER }, { name: SLOTS.STRING_FILTER }, { name: SLOTS.PROPERTIES }, { name: SLOTS.COMPARE_TO }];
</script>
