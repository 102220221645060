import { copy } from 'shared/lib/copy';
import { includesArray } from 'shared/lib/array/array';

const replaceInObject = (obj, excludedPaths, currentPath, key, defaultValue) => {
  if (obj === undefined || obj === null || typeof obj === 'number' || typeof obj === 'boolean' || typeof obj === 'string') {
    return obj;
  }

  const r = {};
  Object.keys(obj).forEach((k) => {
    const path = [...currentPath, k];
    if (k === key) {
      if (includesArray(excludedPaths, path)) {
        r[k] = obj[k];
        return;
      }
      r[k] = defaultValue;
      return;
    }
    if (obj[k] === undefined || obj[k] === null) {
      r[k] = obj[k];
      return;
    }
    if (Array.isArray(obj[k])) {
      r[k] = replaceInArray(obj[k], excludedPaths, path, key, defaultValue);
      return;
    }
    if (typeof obj[k] === 'object') {
      r[k] = replaceInObject(obj[k], excludedPaths, path, key, defaultValue);
      return;
    }

    r[k] = obj[k];
  });
  return r;
};

const replaceInArray = (arr, excludedPaths, currentPath, key, newValue) => arr.reduce((res, next) => {
  res.push(replaceInObject(next, excludedPaths, currentPath, key, newValue));
  return res;
}, []);

/**
 * ReplaceAll returns a copy of the object o with all instances where
 * the needle matches the key replaced by the new value.
 * ReplaceAll does not replace values in case the path to the value is
 * included in the array of arrays excludedPaths.
 * Undefined values will be removed from o since we use copy which stringifies and
 * parses an object.
 * @param {object} o
 * @param {string} needle
 * @param {any} newValue
 * @param {array} excludedPaths
 */
export const replaceAll = (o, needle, newValue, excludedPaths = []) => {
  if (Array.isArray(o)) {
    return replaceInArray(copy(o), excludedPaths, [], needle, newValue);
  }

  return replaceInObject(copy(o), excludedPaths, [], needle, newValue);
};
