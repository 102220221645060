import { UID } from 'shared/api/query/constants';
import { dataSource as dataSourceConfig, meeting as meetingConfig, msCalendarEvent as msCalendarEventConfig, user as userConfig } from 'shared/api/query/configs.json';

export const msCalendarEventChildren = [
  { attr: UID },
  { attr: msCalendarEventConfig.edges.creator, model: userConfig.model, children: [{ attr: UID }] },
  { attr: msCalendarEventConfig.edges.dataSource, model: dataSourceConfig.model, children: [{ attr: UID }], default: null },
  { attr: msCalendarEventConfig.edges.error, default: '' },
  { attr: msCalendarEventConfig.edges.eventId },
  { attr: msCalendarEventConfig.edges.meeting, model: meetingConfig.model, children: [{ attr: UID }] },
  { attr: msCalendarEventConfig.edges.nativeDate, default: null },
  { attr: msCalendarEventConfig.edges.weblink, default: null },
];
