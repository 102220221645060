<template>
  <goals-list-wrapper
    :header-slots="headerSlots"
    :can-create-goals="canCreate"
    :allowed-view-types="allowedViewTypes"
    fullscreen
    stretch-content
    show-views-selector
    show-create-view
    :base-filter="publishedAtFilter"
    show-errors
    :selected-cycles="selectedCycles"
    :change-selected-goal-cycles="changeSelectedGoalCycles"
    :default-props="defaultProps"
  />
</template>

<script>
import GoalsListWrapper from '@/components/goal/GoalsListWrapper.vue';
import useAccess from '@/composables/access/access';
import useGoalCycle from '@/composables/goal-cycle/goal-cycle';
import useGoalDefaultProps from '@/composables/saved-views/goal-default-props';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import usePersistedGoalCycle from '@/composables/goal-cycle/persisted-goal-cycle';
import usePublishedAtFilter from '@/composables/goal/published-at-filter';
import useViewServiceInit from '@/composables/saved-views/view-service-init';
import { SLOTS, VIEWS_SERVICE } from '@/lib/constants';
import { accessGroupFlag, viewApplication, viewType } from 'shared/constants.json';
import { provide } from 'vue';
import { filter as spaceFilter } from '@/lib/filter/space/filter';
import { useI18n } from 'vue-i18n';

export default {
  name: 'SpaceGoals',
  props: {
    property: {
      type: Object,
      required: true,
    },
    space: {
      type: Object,
      required: true,
    },
  },
  components: { GoalsListWrapper },
  setup(props) {
    const { userHasRight } = useAccess();
    const { loggedInUserAccount } = useLoggedInUserAccount();
    const { goalCycles } = useGoalCycle();
    const { publishedAtFilter } = usePublishedAtFilter();
    const { composedSelectedCycles, changeSelectedGoalCycles } = usePersistedGoalCycle();
    const i18n = useI18n();

    const initialFilter = spaceFilter({
      property: props.property,
      space: props.space,
      account: { uid: loggedInUserAccount.value.uid },
    });

    const viewApp = viewApplication.goalSpace;
    const defaultView = {
      title: i18n.t('list.cascade'),
      viewType: viewType.cascade,
      viewApplication: viewApp,
      space: props.space,
      params: {
        filter: initialFilter,
        order: [],
        props: [],
        wrapCells: true,
      },
    };

    const viewServiceInitService = useViewServiceInit();
    const defaultPropsSvc = useGoalDefaultProps();

    let routeAwareConfig = viewServiceInitService.defaultConfig();
    routeAwareConfig = viewServiceInitService.withDefaultView(routeAwareConfig, defaultView);
    routeAwareConfig = viewServiceInitService.withViewApplication(routeAwareConfig, viewApp);
    routeAwareConfig = viewServiceInitService.withDefaultProps(routeAwareConfig, defaultPropsSvc.defaultProps);
    routeAwareConfig = viewServiceInitService.withSpace(routeAwareConfig, props.space);

    const viewSvc = viewServiceInitService.routeAwareViewService(routeAwareConfig);
    provide(VIEWS_SERVICE, viewSvc);

    return {
      selectedCycles: composedSelectedCycles,
      changeSelectedGoalCycles,
      publishedAtFilter,
      userHasRight,
      account: loggedInUserAccount,
      goalCycles,

      defaultProps: defaultPropsSvc.defaultProps,
    };
  },
  data() {
    return {
      viewType,
      viewApplication,
      headerSlots: [{ name: SLOTS.CYCLE_SELECTOR }, { name: SLOTS.FILTER }, { name: SLOTS.SORTER }, { name: SLOTS.STRING_FILTER }, { name: SLOTS.PROPERTIES }, { name: SLOTS.COMPARE_TO }],
    };
  },
  computed: {
    allowedViewTypes() {
      return [viewType.cascade, viewType.tree, viewType.list, viewType.atlas];
    },
    canCreate() {
      return this.userHasRight([accessGroupFlag.createGoal]);
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">

</style>
