import { equalP } from 'shared/lib/array/array';
import { ref, watch } from 'vue';

export default function useLoader(
  incRootItems,
  initRenderedItems,
  incRenderedItems,
  goalFetcher,
  viewParamsService,
  debounce,
  selectedCycles,
  searchTerm,
  disabledGoalIds,
  baseFilter,
) {
  const renderedItems = ref(initRenderedItems);
  const totalRootItems = ref(0);
  const loadedRooItems = ref(incRootItems);

  const listLoading = ref(false);
  const retrieveGoals = (toggleLoading = true) => {
    if (toggleLoading) {
      listLoading.value = true;
    }

    return goalFetcher.getGoals(
      {
        searchTerm: searchTerm.value,
        selectedCycles: selectedCycles.value,
        disabledGoalIds: disabledGoalIds.value,
      },
      baseFilter.value,
      {
        page: 1,
        itemsPerPage: loadedRooItems.value,
      },
    ).then((response) => {
      totalRootItems.value = response.count[0].count;
    }).finally(() => {
      listLoading.value = false;
    });
  };

  const apply = (wait) => {
    debounce(retrieveGoals, wait);
  };

  const loadMore = () => {
    renderedItems.value += incRenderedItems;
    if (totalRootItems.value <= loadedRooItems.value) {
      return;
    }

    loadedRooItems.value += incRootItems;
    retrieveGoals(true);
  };

  watch(viewParamsService.applyFilterOnFirstLevelOnly, (val, oldVal) => {
    if (val === oldVal) {
      return;
    }
    apply(1000);
  });

  watch(viewParamsService.compareTo, (val, oldVal) => {
    if (JSON.stringify(val) === JSON.stringify(oldVal)) {
      return;
    }
    apply(1000);
  });
  watch(viewParamsService.gqlFilterObject, (val, oldVal) => {
    if (JSON.stringify(val) === JSON.stringify(oldVal)) {
      return;
    }
    apply(1000);
  });
  watch(selectedCycles, (val, oldVal) => {
    if (equalP({ a: val, b: oldVal })) {
      return;
    }
    apply(1000);
  });
  watch(searchTerm, (val, oldVal) => {
    if (JSON.stringify(val) === JSON.stringify(oldVal)) {
      return;
    }
    apply(1000);
  });
  watch(viewParamsService.order, (val, oldVal) => {
    if (JSON.stringify(val) === JSON.stringify(oldVal)) {
      return;
    }
    apply(1000);
  });

  return {
    listLoading,
    totalRootItems,
    retrieveGoals,
    loadMore,
    renderedItems,
  };
}
