<template>
  <span :class="['bread-crumb-item']">
    <component
      :is="item.disabled ? 'm-content' : 'm-btn'"
      :padding-x="6"
      :small="!item.disabled"
      :hide-border="!item.disabled"
      :to="item.to !== undefined ? item.to : ''"
      @click="handleClick"
    >
      <div
        class="_link"
        :style="linkStyle"
      >
        <item-title
          :icons="item.icons"
          :title="item.title"
        />
      </div>
    </component>
  </span>
</template>

<script>
import ItemTitle from '@/components/ItemTitle.vue';

export default {
  name: 'BreadCrumbItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
    amount: {
      type: Number,
      required: true,
    },
    maxWidth: {
      type: String,
      default: '23rem',
    },
  },
  emits: ['selected'],
  components: { ItemTitle },
  computed: {
    linkStyle() {
      if (!this.$store.state.breakpoint.smAndDown) {
        return { maxWidth: this.maxWidth };
      }

      if (this.amount === 1) {
        return { maxWidth: this.maxWidth };
      }

      return { maxWidth: '16rem' };
    },
  },
  methods: {
    handleClick(event) {
      if (this.item.disabled) {
        return;
      }
      if (this.item.onClick !== undefined) {
        this.item.onClick(event);
        return;
      }
      this.$emit('selected');
    },
  },
};
</script>

<style
  scoped
  lang="scss"
  type="text/scss"
>
  .bread-crumb-item {
    display: flex;
    align-items: center;
    height: 2.3rem;
    margin: 0 .4rem;
    border-radius: $border-radius-sm;

    ._link {
      display: flex;
      align-items: center;
      width: 100%;
      color: $font-color-primary;

      ._icon {
        margin-right: .6rem;
      }

      ._title {
        display: inline-block;
        overflow: hidden;
        line-height: 1.5;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
</style>
