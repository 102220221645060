<template>
  <div class="reminder-list">
    <m-card-item
      v-if="reminders.length === 0"
      :clickable="false"
    >
      <div class="_empty">
        {{ $t('reminderList.noReminders') }}
      </div>
    </m-card-item>
    <div
      v-for="(reminder, index) in reminders"
      :key="index"
      class="_reminder-item"
    >
      <reminder-item
        v-if="typeof reminder.deletedAt === 'undefined'"
        :reminder="reminder"
        :disabled="disabled"
        @update="$emit('update', $event, index)"
        @delete="$emit('delete', index)"
      />
    </div>
    <m-card-item
      v-if="!disabled"
      icon="plus"
      hide-border
      light
      @click="showAdd = true"
    >
      <m-dropdown
        v-model:value="showAdd"
        :title="$t('general.add')"
        placement="bottomCenter"
      >
        <span :style="{ color: $colors.grey.base }">
          {{ $t('reminderList.add') }}
        </span>
        <template #overlay>
          <m-card
            small-padding
          >
            <reminder-editor
              :reminder="null"
              @create="handleCreate"
            />
          </m-card>
        </template>
      </m-dropdown>
    </m-card-item>
  </div>
</template>

<script>
import ReminderEditor from '@/components/update-schedules/ReminderEditor.vue';
import ReminderItem from '@/components/update-schedules/ReminderItem.vue';

export default {
  name: 'ReminderList',
  props: {
    reminders: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update', 'delete', 'create'],
  components: { ReminderEditor, ReminderItem },
  data() {
    return { showAdd: false };
  },
  methods: {
    handleCreate(event) {
      this.showAdd = false;
      this.$emit('create', event);
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .reminder-list {
    ._empty {
      color: $font-color-tertiary;
    }
  }
</style>
