import useUpdateSchedules from '@/composables/update-schedules/update-schedules';
import { EventBus } from '@/lib/event-bus';
import { useI18n } from 'vue-i18n';

export default function useUpdateSchedulesPromptOnUnsaved() {
  const { t } = useI18n();
  const { updateSchedule, reset } = useUpdateSchedules();

  const save = ({ next, setLoading, schedule }) => {
    setLoading(true);

    updateSchedule(schedule).then(() => {
      setLoading(false);
      next();
    });
  };

  const saveOrDiscard = (next, schedule, snapShot) => {
    if (!schedule.isDirty) {
      next();
      return;
    }

    EventBus.$emit(
      'show-confirm',
      {
        title: t('general.discardEditPrompt'),
        cancelText: t('general.save'),
        okText: t('general.discardChanges'),
        okType: 'warning',
        cancelType: 'default',
        maskClosable: true,
        onOk() {
          reset(snapShot);
          next();
        },
        onCancel(setLoading) {
          save({ next, setLoading, schedule });
        },
      },
    );
  };

  return { saveOrDiscard };
}
