<template>
  <div class="required-error">
    {{ $t('requiredError.title') }}
  </div>
</template>

<script>
export default { name: 'RequiredError' };
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>

</style>
