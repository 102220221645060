<template>
  <item-wrapper
    v-if="text !== null"
    class="rich-text-prop"
    :show-tooltip="showTooltip"
    :tooltip="prop.label"
  >
    <div
      :class="['_text', wrap ? '-wrap' : '']"
    >
      <m-editor
        :initial-value="text"
        :allowed-content="allowedContent"
        :disabled="true"
      />
    </div>
  </item-wrapper>
</template>

<script>
import ItemWrapper from '@/components/list/ItemWrapper.vue';
import MEditor from '@/components/editor/MEditor.vue';
import useAccess from '@/composables/access/access';
import { editorNodeType, featureFlag } from 'shared/constants.json';
import { getValueFromEntity } from '@/lib/props';

export default {
  name: 'RichTextProp',
  props: {
    prop: {
      type: Object,
      required: true,
    },
    entity: {
      type: Object,
      required: true,
    },
    propertyValuesKey: {
      type: String,
      default: 'properties',
    },
    showTooltip: {
      type: Boolean,
      default: false,
    },
    wrap: {
      type: Boolean,
      default: false,
    },
  },
  components: { MEditor, ItemWrapper },
  setup() {
    const allowedContent = [
      editorNodeType.bulletList,
      editorNodeType.mention,
      editorNodeType.orderedList,
    ];

    const { accountHasFeature } = useAccess();
    if (accountHasFeature([featureFlag.fileUpload])) {
      allowedContent.push(editorNodeType.image, editorNodeType.file);
    }

    return { allowedContent };
  },
  computed: {
    text() {
      const val = getValueFromEntity(this.prop, this.entity, this.propertyValuesKey);
      if (val === undefined) {
        return null;
      }
      return val;
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .rich-text-prop {
    ._text {
      width: 100%;
      height: 3.2rem;
      overflow: hidden;
      text-overflow: ellipsis;

      &.-wrap {
        height: 100%;
        max-height: 40rem;
        overflow: auto;
      }
    }
  }
</style>
