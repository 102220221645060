<template>
  <div
    :class="['draggable-nav-item', draggingOver ? '-dragging-over': '']"
    :data-id="dataId"
  >
    <div
      v-if="draggingOverTop"
      class="_drag-over-top"
    />
    <slot />
    <div
      v-if="draggingOverBottom"
      class="_drag-over-bottom"
    />
  </div>
</template>

<script>

export default {
  name: 'DraggableNavItem',
  props: {
    dataId: {
      type: [Number, String],
      default: () => null,
    },
    draggingOverBottom: {
      type: Boolean,
      default: false,
    },
    draggingOverTop: {
      type: Boolean,
      default: false,
    },
    draggingOver: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  .draggable-nav-item {
    position: relative;

    &.-dragging-over {
      background-color: $highlighted-color-dark;
    }

    ._drag-over-top {
      position: absolute;
      top: -2px;
      right: 0;
      left: 0;
      z-index: 88;
      width: 100%;
      height: 4px;
      padding: 0 10rem;
      pointer-events: none;
      background: $highlighted-color-dark;
      opacity: 1;
    }

    ._drag-over-bottom {
      position: absolute;
      right: 0;
      bottom: -2px;
      left: 0;
      z-index: 88;
      width: 100%;
      height: 4px;
      padding: 0 10rem;
      pointer-events: none;
      background: $highlighted-color-dark;
      opacity: 1;
    }
  }
</style>
