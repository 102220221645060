<template>
  <div :class="['m-default-form-actions', bordered ? '-bordered' : '']">
    <m-spacer v-if="!showLeft" />
    <slot />
    <m-btn
      v-if="showCancel"
      class="_btn"
      @click="emitCancel"
    >
      {{ $t('general.cancel') }}
    </m-btn>
    <m-tooltip :disabled="submitMessage === ''">
      <m-btn
        :disabled="!clickable"
        :loading="loading"
        color="primary"
        class="_btn"
        @click="emitSubmit"
      >
        {{ submitButtonLabel }}
      </m-btn>
      <template #title>
        {{ submitMessage }}
      </template>
    </m-tooltip>
  </div>
</template>

<script>
export default {
  name: 'MDefaultFormActions',
  props: {
    showCancel: {
      type: Boolean,
      default: true,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    showLeft: {
      type: Boolean,
      default: false,
    },
    submitText: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    bordered: {
      type: Boolean,
      default: true,
    },
    submitMessage: {
      type: String,
      default: '',
    },
  },
  emits: ['cancel', 'submit'],
  computed: {
    submitButtonLabel() {
      if (this.submitText === '') {
        return this.$t('general.save');
      }
      return this.submitText;
    },
  },
  methods: {
    emitCancel() {
      this.$emit('cancel');
    },
    emitSubmit() {
      this.$emit('submit');
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  @import "shared/assets/scss/padding";

  .m-default-form-actions {
    display: flex;
    align-items: center;
    background-color: white;

    @include padding;

    &.-bordered {
      border-top: 1px solid $border-color;
    }

    ._btn {
      margin-left: .5rem;
    }
  }
</style>
