const state = new WeakMap();

export default function useFileInputDialog() {
  const open = (editor) => {
    state.set(editor, true);
    const listener = () => {
      state.delete(editor);
      window.removeEventListener('focus', listener);
    };
    window.addEventListener('focus', listener);
  };

  const isOpen = (editor) => state.get(editor) === true;

  return { open, isOpen };
}
