<template>
  <div class="access-group-page">
    <page-header
      :title="$t('views.accessGroupDetails')"
      boxed-xs
      heading="h3"
      underlined
      class="_header"
    />
    <m-content
      boxed-xs
      no-padding
    >
      <m-btn
        small
        super-light
        :href="$t('accessGroupDetails.helpLink')"
        hide-border
        icon="question-circle"
        target="_blank"
        class="_help-btn"
      >
        {{ $t('accessGroupDetails.help') }}
      </m-btn>
    </m-content>
    <m-content
      padding
      boxed-xs
      class="_inner"
    >
      <div
        class="_right"
      >
        <m-btn
          color="primary"
          @click="showCreateModal = true"
        >
          {{ $t('accessGroupDetails.addGroup') }}
        </m-btn>
        <m-drawer
          :title="$t('accessGroupDetails.addGroup')"
          width="50rem"
          :value="showCreateModal"
          @close="showCreateModal = false"
        >
          <access-group-form
            :key="showCreateModal"
            @cancel="showCreateModal = false"
            @created="showCreateModal = false"
            @updated="showCreateModal = false"
          />
        </m-drawer>
      </div>
      <div class="_content">
        <full-screen-spinner v-if="loading" />
        <template v-else>
          <div
            v-if="accessGroups.length === 0"
            class="_no-content"
          >
            <m-alert
              :message="$t('accessGroupDetails.noAccessGroups')"
              type="info"
            />
          </div>
          <template v-else>
            <div
              class="_list"
            >
              <access-group-list
                :access-groups="accessGroups"
              />
            </div>
          </template>
        </template>
      </div>
    </m-content>
  </div>
</template>

<script>
import AccessGroupForm from '@/components/access-group/AccessGroupForm.vue';
import AccessGroupList from '@/components/access-group/AccessGroupList.vue';
import FullScreenSpinner from 'shared/components/FullScreenSpinner.vue';
import PageHeader from 'shared/components/PageHeader.vue';
import useAccessGroup from '@/composables/access/access-group';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useSnackBar from '@/composables/snackbar';

export default {
  name: 'AccessGroupPage',
  components: {
    PageHeader,
    AccessGroupList,
    FullScreenSpinner,
    AccessGroupForm,
  },
  setup() {
    const snackbar = useSnackBar();
    const { loggedInUser } = useLoggedInUser();
    const { accessGroups } = useAccessGroup();
    const { loggedInUserAccount } = useLoggedInUserAccount();
    return {
      accessGroups,
      snackbar,
      loggedInUser,
      account: loggedInUserAccount,
    };
  },
  data() {
    return {
      loading: false,
      showCreateModal: false,
    };
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
.access-group-page {
  ._header {
    margin-bottom: 2rem;
  }

  ._content {
    display: flex;
    width: 100%;

    ._list {
      width: 100%;
    }
  }

  ._inner {
    ._header {
      margin-bottom: 3rem;
    }

    ._right {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 1.4rem;
    }
  }
}
</style>
