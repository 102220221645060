import useSpaces from '@/composables/space/spaces';

export default function useSpaceDepthValidator(elReader) {
  const { maxLevel: spaceMaxLevel } = useSpaces();

  const tooDeepItems = (list, node) => {
    const el = list.find((el) => elReader.getId(el) === elReader.getId(node));
    let height = 1;
    if (el !== undefined) {
      height += elReader.getChildrenMaxDepth(el) - elReader.getDepth(el);
    }
    const maxLevel = spaceMaxLevel - height;

    const restricted = list.filter((el) => elReader.getDepth(el) >= maxLevel);
    if (el === undefined) {
      return restricted;
    }

    const getChildren = (res, node) => {
      const children = node.children.reduce(getChildren, []);
      return [...res, ...children, node];
    };
    return [
      ...restricted,
      ...getChildren([], el),
    ];
  };

  return { tooDeepItems };
}
