import Mark from '@/tiptap/tiptap/Utils/Mark';
import { Plugin } from 'prosemirror-state';
import { pasteRule, removeMark, updateMark } from '@/tiptap/commands/commands';

const isControlKey = (event) => {
  const isMacComputer = window.navigator.userAgent.includes('Macintosh');
  if (isMacComputer) {
    return event.metaKey;
  }
  return event.ctrlKey;
};
const getTarget = (href, event = { ctrlKey: false, metaKey: false }) => {
  if (href.indexOf(window.location.origin) === 0 && !isControlKey(event)) {
    return '_self';
  }
  return '_blank';
};

/**
 * A regex that matches any string that contains a link
 */
export const pasteRegex = /https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z]{2,}\b(?:[-a-zA-Z0-9@:%._+~#=?!&/]*)(?:[-a-zA-Z0-9@:%._+~#=?!&/]*)/gi;

/**
 * A regex that matches an url
 */
export const pasteRegexExact = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z]{2,}\b(?:[-a-zA-Z0-9@:%._+~#=?!&/]*)(?:[-a-zA-Z0-9@:%._+~#=?!&/]*)$/gi;

export default class Link extends Mark {
  get name() {
    return 'link';
  }

  get defaultOptions() {
    return {
      openOnClick: true,
      linkOnPaste: true,
      target: null,
    };
  }

  get schema() {
    return {
      attrs: {
        href: { default: null },
        target: { default: null },
      },
      inclusive: false,
      parseDOM: [
        {
          tag: 'a[href]',
          getAttrs: (dom) => ({
            href: dom.getAttribute('href'),
            target: dom.getAttribute('target'),
          }),
        },
      ],
      toDOM: (node) => ['a', {
        ...node.attrs,
        rel: 'noopener noreferrer nofollow',
        target: getTarget(node.attrs.href),
      }, 0],
    };
  }

  commands({ type }) {
    return (attrs) => {
      if (attrs.href !== undefined) {
        return updateMark(type, attrs);
      }

      return removeMark(type);
    };
  }

  pasteRules({ type }) {
    return [
      pasteRule(
        pasteRegex,
        type,
        (url) => ({ href: url }),
      ),
    ];
  }

  get plugins() {
    const plugins = [];
    if (this.options.openOnClick) {
      plugins.push(new Plugin({
        props: {
          handleClick: (view, pos, event) => {
            if (!view.editable) {
              return;
            }

            const getMarkAttrs = (state, type) => {
              const node = state.doc.nodeAt(pos);
              if (node === null) {
                return {};
              }

              const mark = node.marks.find((markItem) => markItem.type.name === type.name);

              if (mark === undefined) {
                return {};
              }

              return mark.attrs;
            };

            const { schema } = view.state;
            const attrs = getMarkAttrs(view.state, schema.marks.link);
            if (attrs.href !== undefined) {
              switch (getTarget(attrs.href, event)) {
                case '_self':
                  window.location.href = attrs.href;
                  return;
                case '_blank':
                default:
                  event.stopPropagation();
                  window.open(attrs.href, '_blank');
              }
            }
          },
        },
      }));
    }

    if (this.options.linkOnPaste) {
      plugins.push(new Plugin({
        props: {
          handlePaste: (view, event, slice) => {
            const { state } = view;
            const { selection } = state;
            const { empty } = selection;

            if (empty) {
              return false;
            }

            let textContent = '';

            slice.content.forEach((node) => {
              textContent += node.textContent;
            });

            if (!textContent || !textContent.match(pasteRegexExact)) {
              return false;
            }

            this.editor.commands.link({ href: textContent });

            return true;
          },
        },
      }));
    }

    return plugins;
  }
}
