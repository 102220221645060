// some emojis are excluded (e.g. flags) for now due to missing icons on windows
export const emojis = {
  people: {
    grinning: '😀',
    smiley: '😃',
    smile: '😄',
    grin: '😁',
    laughing: '😆',
    sweat_smile: '😅',
    rofl: '🤣',
    joy: '😂',
    slightly_smiling_face: '🙂',
    upside_down_face: '🙃',
    wink: '😉',
    blush: '😊',
    innocent: '😇',
    smiling_face_with_three_hearts: '🥰',
    heart_eyes: '😍',
    star_struck: '🤩',
    kissing_heart: '😘',
    kissing: '😗',
    relaxed: '☺️️',
    kissing_closed_eyes: '😚',
    kissing_smiling_eyes: '😙',
    smiling_face_with_tear: '🥲',
    yum: '😋',
    stuck_out_tongue: '😛',
    stuck_out_tongue_winking_eye: '😜',
    zany_face: '🤪',
    stuck_out_tongue_closed_eyes: '😝',
    money_mouth_face: '🤑',
    hugs: '🤗',
    hand_over_mouth: '🤭',
    shushing_face: '🤫',
    thinking: '🤔',
    zipper_mouth_face: '🤐',
    raised_eyebrow: '🤨',
    neutral_face: '😐',
    expressionless: '😑',
    no_mouth: '😶',
    // face_in_clouds: '😶‍🌫️',
    smirk: '😏',
    unamused: '😒',
    roll_eyes: '🙄',
    grimacing: '😬',
    // face_exhaling: '😮‍💨',
    lying_face: '🤥',
    relieved: '😌',
    pensive: '😔',
    sleepy: '😪',
    drooling_face: '🤤',
    sleeping: '😴',
    mask: '😷',
    face_with_thermometer: '🤒',
    face_with_head_bandage: '🤕',
    nauseated_face: '🤢',
    vomiting_face: '🤮',
    sneezing_face: '🤧',
    hot_face: '🥵',
    cold_face: '🥶',
    woozy_face: '🥴',
    dizzy_face: '😵',
    // face_with_spiral_eyes: '😵‍💫',
    // exploding_head: '🤯',
    cowboy_hat_face: '🤠',
    partying_face: '🥳',
    disguised_face: '🥸',
    sunglasses: '😎',
    nerd_face: '🤓',
    monocle_face: '🧐',
    confused: '😕',
    worried: '😟',
    slightly_frowning_face: '🙁',
    frowning_face: '☹️',
    open_mouth: '😮',
    hushed: '😯',
    astonished: '😲',
    flushed: '😳',
    pleading_face: '🥺',
    anguished: '😧',
    fearful: '😨',
    cold_sweat: '😰',
    disappointed_relieved: '😥',
    cry: '😢',
    sob: '😭',
    scream: '😱',
    confounded: '😖',
    persevere: '😣',
    disappointed: '😞',
    sweat: '😓',
    weary: '😩',
    tired_face: '😫',
    yawning_face: '🥱',
    triumph: '😤',
    pout: '😡',
    angry: '😠',
    cursing_face: '🤬',
    smiling_imp: '😈',
    imp: '👿',
    skull: '💀',
    skull_and_crossbones: '☠️',
    hankey: '💩',
    clown_face: '🤡',
    japanese_ogre: '👹',
    japanese_goblin: '👺',
    ghost: '👻',
    alien: '👽',
    space_invader: '👾',
    robot: '🤖',
    smiley_cat: '😺',
    smile_cat: '😸',
    joy_cat: '😹',
    heart_eyes_cat: '😻',
    smirk_cat: '😼',
    kissing_cat: '😽',
    scream_cat: '🙀',
    crying_cat_face: '😿',
    pouting_cat: '😾',
    see_no_evil: '🙈',
    hear_no_evil: '🙉',
    speak_no_evil: '🙊',
    kiss: '💋',
    love_letter: '💌',
    cupid: '💘',
    gift_heart: '💝',
    sparkling_heart: '💖',
    heartpulse: '💗',
    heartbeat: '💓',
    revolving_hearts: '💞',
    two_hearts: '💕',
    heart_decoration: '💟',
    heavy_heart_exclamation: '❣️',
    broken_heart: '💔',
    // heart_on_fire: '❤️‍🔥',
    // mending_heart: '❤️‍🩹',
    heart: '❤️',
    orange_heart: '🧡',
    yellow_heart: '💛',
    green_heart: '💚',
    blue_heart: '💙',
    purple_heart: '💜',
    brown_heart: '🤎',
    black_heart: '🖤',
    white_heart: '🤍',
    100: '💯',
    anger: '💢',
    boom: '💥',
    dizzy: '💫',
    sweat_drops: '💦',
    dash: '💨',
    hole: '🕳️',
    bomb: '💣',
    speech_balloon: '💬',
    eye_speech_bubble: '👁️‍🗨️',
    left_speech_bubble: '🗨️',
    right_anger_bubble: '🗯️',
    thought_balloon: '💭',
    zzz: '💤',
    wave: '👋',
    raised_back_of_hand: '🤚',
    raised_hand_with_fingers_splayed: '🖐️',
    hand: '✋',
    vulcan_salute: '🖖',
    ok_hand: '👌',
    pinched_fingers: '🤌',
    pinching_hand: '🤏',
    v: '✌️',
    crossed_fingers: '🤞',
    love_you_gesture: '🤟',
    metal: '🤘',
    call_me_hand: '🤙',
    point_left: '👈',
    point_right: '👉',
    point_up_2: '👆',
    fu: '🖕',
    point_down: '👇',
    point_up: '☝️',
    '+1': '👍',
    '-1': '👎',
    fist: '✊',
    facepunch: '👊',
    fist_left: '🤛',
    fist_right: '🤜',
    clap: '👏',
    raised_hands: '🙌',
    open_hands: '👐',
    palms_up_together: '🤲',
    handshake: '🤝',
    pray: '🙏',
    writing_hand: '✍️',
    nail_care: '💅',
    selfie: '🤳',
    muscle: '💪',
    mechanical_arm: '🦾',
    mechanical_leg: '🦿',
    leg: '🦵',
    foot: '🦶',
    ear: '👂',
    ear_with_hearing_aid: '🦻',
    nose: '👃',
    brain: '🧠',
    anatomical_heart: '🫀',
    lungs: '🫁',
    tooth: '🦷',
    bone: '🦴',
    eyes: '👀',
    eye: '👁️',
    tongue: '👅',
    lips: '👄',
    baby: '👶',
    child: '🧒',
    boy: '👦',
    girl: '👧',
    adult: '🧑',
    blond_haired_person: '👱',
    man: '👨',
    bearded_person: '🧔',
    // man_beard: '🧔‍♂️',
    // woman_beard: '🧔‍♀️',
    red_haired_man: '👨‍🦰',
    curly_haired_man: '👨‍🦱',
    white_haired_man: '👨‍🦳',
    bald_man: '👨‍🦲',
    woman: '👩',
    red_haired_woman: '👩‍🦰',
    person_red_hair: '🧑‍🦰',
    curly_haired_woman: '👩‍🦱',
    person_curly_hair: '🧑‍🦱',
    white_haired_woman: '👩‍🦳',
    person_white_hair: '🧑‍🦳',
    bald_woman: '👩‍🦲',
    person_bald: '🧑‍🦲',
    blond_haired_woman: '👱‍♀️',
    blond_haired_man: '👱‍♂️',
    older_adult: '🧓',
    older_man: '👴',
    older_woman: '👵',
    frowning_person: '🙍',
    frowning_man: '🙍‍♂️',
    frowning_woman: '🙍‍♀️',
    pouting_face: '🙎',
    pouting_man: '🙎‍♂️',
    pouting_woman: '🙎‍♀️',
    no_good: '🙅',
    ng_man: '🙅‍♂️',
    ng_woman: '🙅‍♀️',
    ok_person: '🙆',
    ok_man: '🙆‍♂️',
    ok_woman: '🙆‍♀️',
    information_desk_person: '💁',
    sassy_man: '💁‍♂️',
    sassy_woman: '💁‍♀️',
    raising_hand: '🙋',
    raising_hand_man: '🙋‍♂️',
    raising_hand_woman: '🙋‍♀️',
    deaf_person: '🧏',
    deaf_man: '🧏‍♂️',
    deaf_woman: '🧏‍♀️',
    bow: '🙇',
    bowing_man: '🙇‍♂️',
    bowing_woman: '🙇‍♀️',
    facepalm: '🤦',
    man_facepalming: '🤦‍♂️',
    woman_facepalming: '🤦‍♀️',
    shrug: '🤷',
    man_shrugging: '🤷‍♂️',
    woman_shrugging: '🤷‍♀️',
    health_worker: '🧑‍⚕️',
    man_health_worker: '👨‍⚕️',
    woman_health_worker: '👩‍⚕️',
    student: '🧑‍🎓',
    man_student: '👨‍🎓',
    woman_student: '👩‍🎓',
    teacher: '🧑‍🏫',
    man_teacher: '👨‍🏫',
    woman_teacher: '👩‍🏫',
    judge: '🧑‍⚖️',
    man_judge: '👨‍⚖️',
    woman_judge: '👩‍⚖️',
    farmer: '🧑‍🌾',
    man_farmer: '👨‍🌾',
    woman_farmer: '👩‍🌾',
    cook: '🧑‍🍳',
    man_cook: '👨‍🍳',
    woman_cook: '👩‍🍳',
    mechanic: '🧑‍🔧',
    man_mechanic: '👨‍🔧',
    woman_mechanic: '👩‍🔧',
    factory_worker: '🧑‍🏭',
    man_factory_worker: '👨‍🏭',
    woman_factory_worker: '👩‍🏭',
    office_worker: '🧑‍💼',
    man_office_worker: '👨‍💼',
    woman_office_worker: '👩‍💼',
    scientist: '🧑‍🔬',
    man_scientist: '👨‍🔬',
    woman_scientist: '👩‍🔬',
    technologist: '🧑‍💻',
    man_technologist: '👨‍💻',
    woman_technologist: '👩‍💻',
    singer: '🧑‍🎤',
    man_singer: '👨‍🎤',
    woman_singer: '👩‍🎤',
    artist: '🧑‍🎨',
    man_artist: '👨‍🎨',
    woman_artist: '👩‍🎨',
    pilot: '🧑‍✈️',
    man_pilot: '👨‍✈️',
    woman_pilot: '👩‍✈️',
    astronaut: '🧑‍🚀',
    man_astronaut: '👨‍🚀',
    woman_astronaut: '👩‍🚀',
    firefighter: '🧑‍🚒',
    man_firefighter: '👨‍🚒',
    woman_firefighter: '👩‍🚒',
    cop: '👮',
    policeman: '👮‍♂️',
    policewoman: '👮‍♀️',
    detective: '🕵️',
    male_detective: '🕵️‍♂️',
    female_detective: '🕵️‍♀️',
    guard: '💂',
    guardsman: '💂‍♂️',
    guardswoman: '💂‍♀️',
    ninja: '🥷',
    construction_worker: '👷',
    construction_worker_man: '👷‍♂️',
    construction_worker_woman: '👷‍♀️',
    prince: '🤴',
    princess: '👸',
    person_with_turban: '👳',
    man_with_turban: '👳‍♂️',
    woman_with_turban: '👳‍♀️',
    man_with_gua_pi_mao: '👲',
    woman_with_headscarf: '🧕',
    person_in_tuxedo: '🤵',
    man_in_tuxedo: '🤵‍♂️',
    woman_in_tuxedo: '🤵‍♀️',
    person_with_veil: '👰',
    man_with_veil: '👰‍♂️',
    bride_with_veil: '👰‍♀️',
    pregnant_woman: '🤰',
    breast_feeding: '🤱',
    woman_feeding_baby: '👩‍🍼',
    man_feeding_baby: '👨‍🍼',
    person_feeding_baby: '🧑‍🍼',
    angel: '👼',
    santa: '🎅',
    mrs_claus: '🤶',
    mx_claus: '🧑‍🎄',
    superhero: '🦸',
    superhero_man: '🦸‍♂️',
    superhero_woman: '🦸‍♀️',
    supervillain: '🦹',
    supervillain_man: '🦹‍♂️',
    supervillain_woman: '🦹‍♀️',
    mage: '🧙',
    mage_man: '🧙‍♂️',
    mage_woman: '🧙‍♀️',
    fairy: '🧚',
    fairy_man: '🧚‍♂️',
    fairy_woman: '🧚‍♀️',
    vampire: '🧛',
    vampire_man: '🧛‍♂️',
    vampire_woman: '🧛‍♀️',
    merperson: '🧜',
    merman: '🧜‍♂️',
    mermaid: '🧜‍♀️',
    elf: '🧝',
    elf_man: '🧝‍♂️',
    elf_woman: '🧝‍♀️',
    genie: '🧞',
    genie_man: '🧞‍♂️',
    genie_woman: '🧞‍♀️',
    zombie: '🧟',
    zombie_man: '🧟‍♂️',
    zombie_woman: '🧟‍♀️',
    massage: '💆',
    massage_man: '💆‍♂️',
    massage_woman: '💆‍♀️',
    haircut: '💇',
    haircut_man: '💇‍♂️',
    haircut_woman: '💇‍♀️',
    walking: '🚶',
    walking_man: '🚶‍♂️',
    walking_woman: '🚶‍♀️',
    standing_person: '🧍',
    standing_man: '🧍‍♂️',
    standing_woman: '🧍‍♀️',
    kneeling_person: '🧎',
    kneeling_man: '🧎‍♂️',
    kneeling_woman: '🧎‍♀️',
    person_with_probing_cane: '🧑‍🦯',
    man_with_probing_cane: '👨‍🦯',
    woman_with_probing_cane: '👩‍🦯',
    person_in_motorized_wheelchair: '🧑‍🦼',
    man_in_motorized_wheelchair: '👨‍🦼',
    woman_in_motorized_wheelchair: '👩‍🦼',
    person_in_manual_wheelchair: '🧑‍🦽',
    man_in_manual_wheelchair: '👨‍🦽',
    woman_in_manual_wheelchair: '👩‍🦽',
    runner: '🏃',
    running_man: '🏃‍♂️',
    running_woman: '🏃‍♀️',
    dancer: '💃',
    man_dancing: '🕺',
    business_suit_levitating: '🕴️',
    dancers: '👯',
    dancing_men: '👯‍♂️',
    dancing_women: '👯‍♀️',
    sauna_person: '🧖',
    sauna_man: '🧖‍♂️',
    sauna_woman: '🧖‍♀️',
    climbing: '🧗',
    climbing_man: '🧗‍♂️',
    climbing_woman: '🧗‍♀️',
    person_fencing: '🤺',
    horse_racing: '🏇',
    skier: '⛷️',
    snowboarder: '🏂',
    golfing: '🏌️',
    golfing_man: '🏌️‍♂️',
    golfing_woman: '🏌️‍♀️',
    surfer: '🏄',
    surfing_man: '🏄‍♂️',
    surfing_woman: '🏄‍♀️',
    rowboat: '🚣',
    rowing_man: '🚣‍♂️',
    rowing_woman: '🚣‍♀️',
    swimmer: '🏊',
    swimming_man: '🏊‍♂️',
    swimming_woman: '🏊‍♀️',
    bouncing_ball_person: '⛹️',
    basketball_man: '⛹️‍♂️',
    basketball_woman: '⛹️‍♀️',
    weight_lifting: '🏋️',
    weight_lifting_man: '🏋️‍♂️',
    weight_lifting_woman: '🏋️‍♀️',
    bicyclist: '🚴',
    biking_man: '🚴‍♂️',
    biking_woman: '🚴‍♀️',
    mountain_bicyclist: '🚵',
    mountain_biking_man: '🚵‍♂️',
    mountain_biking_woman: '🚵‍♀️',
    cartwheeling: '🤸',
    man_cartwheeling: '🤸‍♂️',
    woman_cartwheeling: '🤸‍♀️',
    wrestling: '🤼',
    men_wrestling: '🤼‍♂️',
    women_wrestling: '🤼‍♀️',
    water_polo: '🤽',
    man_playing_water_polo: '🤽‍♂️',
    woman_playing_water_polo: '🤽‍♀️',
    handball_person: '🤾',
    man_playing_handball: '🤾‍♂️',
    woman_playing_handball: '🤾‍♀️',
    juggling_person: '🤹',
    man_juggling: '🤹‍♂️',
    woman_juggling: '🤹‍♀️',
    lotus_position: '🧘',
    lotus_position_man: '🧘‍♂️',
    lotus_position_woman: '🧘‍♀️',
    bath: '🛀',
    sleeping_bed: '🛌',
    people_holding_hands: '🧑‍🤝‍🧑',
    two_women_holding_hands: '👭',
    couple: '👫',
    two_men_holding_hands: '👬',
    couplekiss: '💏',
    couplekiss_man_woman: '👩‍❤️‍💋‍👨',
    couplekiss_man_man: '👨‍❤️‍💋‍👨',
    couplekiss_woman_woman: '👩‍❤️‍💋‍👩',
    couple_with_heart: '💑',
    couple_with_heart_woman_man: '👩‍❤️‍👨',
    couple_with_heart_man_man: '👨‍❤️‍👨',
    couple_with_heart_woman_woman: '👩‍❤️‍👩',
    family: '👪',
    family_man_woman_boy: '👨‍👩‍👦',
    family_man_woman_girl: '👨‍👩‍👧',
    family_man_woman_girl_boy: '👨‍👩‍👧‍👦',
    family_man_woman_boy_boy: '👨‍👩‍👦‍👦',
    family_man_woman_girl_girl: '👨‍👩‍👧‍👧',
    family_man_man_boy: '👨‍👨‍👦',
    family_man_man_girl: '👨‍👨‍👧',
    family_man_man_girl_boy: '👨‍👨‍👧‍👦',
    family_man_man_boy_boy: '👨‍👨‍👦‍👦',
    family_man_man_girl_girl: '👨‍👨‍👧‍👧',
    family_woman_woman_boy: '👩‍👩‍👦',
    family_woman_woman_girl: '👩‍👩‍👧',
    family_woman_woman_girl_boy: '👩‍👩‍👧‍👦',
    family_woman_woman_boy_boy: '👩‍👩‍👦‍👦',
    family_woman_woman_girl_girl: '👩‍👩‍👧‍👧',
    family_man_boy: '👨‍👦',
    family_man_boy_boy: '👨‍👦‍👦',
    family_man_girl: '👨‍👧',
    family_man_girl_boy: '👨‍👧‍👦',
    family_man_girl_girl: '👨‍👧‍👧',
    family_woman_boy: '👩‍👦',
    family_woman_boy_boy: '👩‍👦‍👦',
    family_woman_girl: '👩‍👧',
    family_woman_girl_boy: '👩‍👧‍👦',
    family_woman_girl_girl: '👩‍👧‍👧',
    speaking_head: '🗣️',
    bust_in_silhouette: '👤',
    busts_in_silhouette: '👥',
    people_hugging: '🫂',
    footprints: '👣',
  },
  nature: {
    monkey_face: '🐵',
    monkey: '🐒',
    gorilla: '🦍',
    orangutan: '🦧',
    dog: '🐶',
    dog2: '🐕',
    guide_dog: '🦮',
    service_dog: '🐕‍🦺',
    poodle: '🐩',
    wolf: '🐺',
    fox_face: '🦊',
    raccoon: '🦝',
    cat: '🐱',
    cat2: '🐈',
    black_cat: '🐈‍⬛',
    lion: '🦁',
    tiger: '🐯',
    tiger2: '🐅',
    leopard: '🐆',
    horse: '🐴',
    racehorse: '🐎',
    unicorn: '🦄',
    zebra: '🦓',
    deer: '🦌',
    bison: '🦬',
    cow: '🐮',
    ox: '🐂',
    water_buffalo: '🐃',
    cow2: '🐄',
    pig: '🐷',
    pig2: '🐖',
    boar: '🐗',
    pig_nose: '🐽',
    ram: '🐏',
    sheep: '🐑',
    goat: '🐐',
    dromedary_camel: '🐪',
    camel: '🐫',
    llama: '🦙',
    giraffe: '🦒',
    elephant: '🐘',
    mammoth: '🦣',
    rhinoceros: '🦏',
    hippopotamus: '🦛',
    mouse: '🐭',
    mouse2: '🐁',
    rat: '🐀',
    hamster: '🐹',
    rabbit: '🐰',
    rabbit2: '🐇',
    chipmunk: '🐿️',
    beaver: '🦫',
    hedgehog: '🦔',
    bat: '🦇',
    bear: '🐻',
    polar_bear: '🐻‍❄️',
    koala: '🐨',
    panda_face: '🐼',
    sloth: '🦥',
    otter: '🦦',
    skunk: '🦨',
    kangaroo: '🦘',
    badger: '🦡',
    feet: '🐾',
    turkey: '🦃',
    chicken: '🐔',
    rooster: '🐓',
    hatching_chick: '🐣',
    baby_chick: '🐤',
    hatched_chick: '🐥',
    bird: '🐦',
    penguin: '🐧',
    dove: '🕊️',
    eagle: '🦅',
    duck: '🦆',
    swan: '🦢',
    owl: '🦉',
    dodo: '🦤',
    feather: '🪶',
    flamingo: '🦩',
    peacock: '🦚',
    parrot: '🦜',
    frog: '🐸',
    crocodile: '🐊',
    turtle: '🐢',
    lizard: '🦎',
    snake: '🐍',
    dragon_face: '🐲',
    dragon: '🐉',
    sauropod: '🦕',
    't-rex': '🦖',
    whale: '🐳',
    whale2: '🐋',
    dolphin: '🐬',
    seal: '🦭',
    fish: '🐟',
    tropical_fish: '🐠',
    blowfish: '🐡',
    shark: '🦈',
    octopus: '🐙',
    shell: '🐚',
    snail: '🐌',
    butterfly: '🦋',
    bug: '🐛',
    ant: '🐜',
    bee: '🐝',
    beetle: '🪲',
    lady_beetle: '🐞',
    cricket: '🦗',
    cockroach: '🪳',
    spider: '🕷️',
    spider_web: '🕸️',
    scorpion: '🦂',
    mosquito: '🦟',
    fly: '🪰',
    worm: '🪱',
    microbe: '🦠',
    bouquet: '💐',
    cherry_blossom: '🌸',
    white_flower: '💮',
    rosette: '🏵️',
    rose: '🌹',
    wilted_flower: '🥀',
    hibiscus: '🌺',
    sunflower: '🌻',
    blossom: '🌼',
    tulip: '🌷',
    seedling: '🌱',
    potted_plant: '🪴',
    evergreen_tree: '🌲',
    deciduous_tree: '🌳',
    palm_tree: '🌴',
    cactus: '🌵',
    ear_of_rice: '🌾',
    herb: '🌿',
    shamrock: '☘️',
    four_leaf_clover: '🍀',
    maple_leaf: '🍁',
    fallen_leaf: '🍂',
    leaves: '🍃',
  },
  food_drink: {
    grapes: '🍇',
    melon: '🍈',
    watermelon: '🍉',
    mandarin: '🍊',
    lemon: '🍋',
    banana: '🍌',
    pineapple: '🍍',
    mango: '🥭',
    apple: '🍎',
    green_apple: '🍏',
    pear: '🍐',
    peach: '🍑',
    cherries: '🍒',
    strawberry: '🍓',
    blueberries: '🫐',
    kiwi_fruit: '🥝',
    tomato: '🍅',
    olive: '🫒',
    coconut: '🥥',
    avocado: '🥑',
    eggplant: '🍆',
    potato: '🥔',
    carrot: '🥕',
    corn: '🌽',
    hot_pepper: '🌶️',
    bell_pepper: '🫑',
    cucumber: '🥒',
    leafy_green: '🥬',
    broccoli: '🥦',
    garlic: '🧄',
    onion: '🧅',
    mushroom: '🍄',
    peanuts: '🥜',
    chestnut: '🌰',
    bread: '🍞',
    croissant: '🥐',
    baguette_bread: '🥖',
    flatbread: '🫓',
    pretzel: '🥨',
    bagel: '🥯',
    pancakes: '🥞',
    waffle: '🧇',
    cheese: '🧀',
    meat_on_bone: '🍖',
    poultry_leg: '🍗',
    cut_of_meat: '🥩',
    bacon: '🥓',
    hamburger: '🍔',
    fries: '🍟',
    pizza: '🍕',
    hotdog: '🌭',
    sandwich: '🥪',
    taco: '🌮',
    burrito: '🌯',
    tamale: '🫔',
    stuffed_flatbread: '🥙',
    falafel: '🧆',
    egg: '🥚',
    fried_egg: '🍳',
    shallow_pan_of_food: '🥘',
    stew: '🍲',
    fondue: '🫕',
    bowl_with_spoon: '🥣',
    green_salad: '🥗',
    popcorn: '🍿',
    butter: '🧈',
    salt: '🧂',
    canned_food: '🥫',
    bento: '🍱',
    rice_cracker: '🍘',
    rice_ball: '🍙',
    rice: '🍚',
    curry: '🍛',
    ramen: '🍜',
    spaghetti: '🍝',
    sweet_potato: '🍠',
    oden: '🍢',
    sushi: '🍣',
    fried_shrimp: '🍤',
    fish_cake: '🍥',
    moon_cake: '🥮',
    dango: '🍡',
    dumpling: '🥟',
    fortune_cookie: '🥠',
    takeout_box: '🥡',
    crab: '🦀',
    lobster: '🦞',
    shrimp: '🦐',
    squid: '🦑',
    oyster: '🦪',
    icecream: '🍦',
    shaved_ice: '🍧',
    ice_cream: '🍨',
    doughnut: '🍩',
    cookie: '🍪',
    birthday: '🎂',
    cake: '🍰',
    cupcake: '🧁',
    pie: '🥧',
    chocolate_bar: '🍫',
    candy: '🍬',
    lollipop: '🍭',
    custard: '🍮',
    honey_pot: '🍯',
    baby_bottle: '🍼',
    milk_glass: '🥛',
    coffee: '☕',
    teapot: '🫖',
    tea: '🍵',
    sake: '🍶',
    champagne: '🍾',
    wine_glass: '🍷',
    cocktail: '🍸',
    tropical_drink: '🍹',
    beer: '🍺',
    beers: '🍻',
    clinking_glasses: '🥂',
    tumbler_glass: '🥃',
    cup_with_straw: '🥤',
    bubble_tea: '🧋',
    beverage_box: '🧃',
    mate: '🧉',
    ice_cube: '🧊',
    chopsticks: '🥢',
    plate_with_cutlery: '🍽️',
    fork_and_knife: '🍴',
    spoon: '🥄',
    hocho: '🔪',
    amphora: '🏺',
  },
  activity: {
    jack_o_lantern: '🎃',
    christmas_tree: '🎄',
    fireworks: '🎆',
    sparkler: '🎇',
    firecracker: '🧨',
    sparkles: '✨',
    balloon: '🎈',
    tada: '🎉',
    confetti_ball: '🎊',
    tanabata_tree: '🎋',
    bamboo: '🎍',
    dolls: '🎎',
    flags: '🎏',
    wind_chime: '🎐',
    rice_scene: '🎑',
    red_envelope: '🧧',
    ribbon: '🎀',
    gift: '🎁',
    reminder_ribbon: '🎗️',
    tickets: '🎟️',
    ticket: '🎫',
    medal_military: '🎖️',
    trophy: '🏆',
    medal_sports: '🏅',
    '1st_place_medal': '🥇',
    '2nd_place_medal': '🥈',
    '3rd_place_medal': '🥉',
    soccer: '⚽',
    baseball: '⚾',
    softball: '🥎',
    basketball: '🏀',
    volleyball: '🏐',
    football: '🏈',
    rugby_football: '🏉',
    tennis: '🎾',
    flying_disc: '🥏',
    bowling: '🎳',
    cricket_game: '🏏',
    field_hockey: '🏑',
    ice_hockey: '🏒',
    lacrosse: '🥍',
    ping_pong: '🏓',
    badminton: '🏸',
    boxing_glove: '🥊',
    martial_arts_uniform: '🥋',
    goal_net: '🥅',
    golf: '⛳',
    ice_skate: '⛸️',
    fishing_pole_and_fish: '🎣',
    diving_mask: '🤿',
    running_shirt_with_sash: '🎽',
    ski: '🎿',
    sled: '🛷',
    curling_stone: '🥌',
    dart: '🎯',
    yo_yo: '🪀',
    kite: '🪁',
    '8ball': '🎱',
    crystal_ball: '🔮',
    magic_wand: '🪄',
    nazar_amulet: '🧿',
    video_game: '🎮',
    joystick: '🕹️',
    slot_machine: '🎰',
    game_die: '🎲',
    jigsaw: '🧩',
    teddy_bear: '🧸',
    pinata: '🪅',
    nesting_dolls: '🪆',
    spades: '♠️',
    hearts: '♥️',
    diamonds: '♦️',
    clubs: '♣️',
    chess_pawn: '♟️',
    black_joker: '🃏',
    mahjong: '🀄',
    flower_playing_cards: '🎴',
    performing_arts: '🎭',
    framed_picture: '🖼️',
    art: '🎨',
    thread: '🧵',
    sewing_needle: '🪡',
    yarn: '🧶',
    knot: '🪢',
  },
  travel: {
    earth_africa: '🌍',
    earth_americas: '🌎',
    earth_asia: '🌏',
    globe_with_meridians: '🌐',
    world_map: '🗺️',
    japan: '🗾',
    compass: '🧭',
    mountain_snow: '🏔️',
    mountain: '⛰️',
    volcano: '🌋',
    mount_fuji: '🗻',
    camping: '🏕️',
    beach_umbrella: '🏖️',
    desert: '🏜️',
    desert_island: '🏝️',
    national_park: '🏞️',
    stadium: '🏟️',
    classical_building: '🏛️',
    building_construction: '🏗️',
    bricks: '🧱',
    rock: '🪨',
    wood: '🪵',
    hut: '🛖',
    houses: '🏘️',
    derelict_house: '🏚️',
    house: '🏠',
    house_with_garden: '🏡',
    office: '🏢',
    post_office: '🏣',
    european_post_office: '🏤',
    hospital: '🏥',
    bank: '🏦',
    hotel: '🏨',
    love_hotel: '🏩',
    convenience_store: '🏪',
    school: '🏫',
    department_store: '🏬',
    factory: '🏭',
    japanese_castle: '🏯',
    european_castle: '🏰',
    wedding: '💒',
    tokyo_tower: '🗼',
    statue_of_liberty: '🗽',
    church: '⛪',
    mosque: '🕌',
    hindu_temple: '🛕',
    synagogue: '🕍',
    shinto_shrine: '⛩️',
    kaaba: '🕋',
    fountain: '⛲',
    tent: '⛺',
    foggy: '🌁',
    night_with_stars: '🌃',
    cityscape: '🏙️',
    sunrise_over_mountains: '🌄',
    sunrise: '🌅',
    city_sunset: '🌆',
    city_sunrise: '🌇',
    bridge_at_night: '🌉',
    hotsprings: '♨️',
    carousel_horse: '🎠',
    ferris_wheel: '🎡',
    roller_coaster: '🎢',
    barber: '💈',
    circus_tent: '🎪',
    steam_locomotive: '🚂',
    railway_car: '🚃',
    bullettrain_side: '🚄',
    bullettrain_front: '🚅',
    train2: '🚆',
    metro: '🚇',
    light_rail: '🚈',
    station: '🚉',
    tram: '🚊',
    monorail: '🚝',
    mountain_railway: '🚞',
    train: '🚋',
    bus: '🚌',
    oncoming_bus: '🚍',
    trolleybus: '🚎',
    minibus: '🚐',
    ambulance: '🚑',
    fire_engine: '🚒',
    police_car: '🚓',
    oncoming_police_car: '🚔',
    taxi: '🚕',
    oncoming_taxi: '🚖',
    car: '🚗',
    oncoming_automobile: '🚘',
    blue_car: '🚙',
    pickup_truck: '🛻',
    truck: '🚚',
    articulated_lorry: '🚛',
    tractor: '🚜',
    racing_car: '🏎️',
    motorcycle: '🏍️',
    motor_scooter: '🛵',
    manual_wheelchair: '🦽',
    motorized_wheelchair: '🦼',
    auto_rickshaw: '🛺',
    bike: '🚲',
    kick_scooter: '🛴',
    skateboard: '🛹',
    roller_skate: '🛼',
    busstop: '🚏',
    motorway: '🛣️',
    railway_track: '🛤️',
    oil_drum: '🛢️',
    fuelpump: '⛽',
    rotating_light: '🚨',
    traffic_light: '🚥',
    vertical_traffic_light: '🚦',
    stop_sign: '🛑',
    construction: '🚧',
    anchor: '⚓',
    boat: '⛵',
    canoe: '🛶',
    speedboat: '🚤',
    passenger_ship: '🛳️',
    ferry: '⛴️',
    motor_boat: '🛥️',
    ship: '🚢',
    airplane: '✈️',
    small_airplane: '🛩️',
    flight_departure: '🛫',
    flight_arrival: '🛬',
    parachute: '🪂',
    seat: '💺',
    helicopter: '🚁',
    suspension_railway: '🚟',
    mountain_cableway: '🚠',
    aerial_tramway: '🚡',
    artificial_satellite: '🛰️',
    rocket: '🚀',
    flying_saucer: '🛸',
    bellhop_bell: '🛎️',
    luggage: '🧳',
    hourglass: '⌛',
    hourglass_flowing_sand: '⏳',
    watch: '⌚',
    alarm_clock: '⏰',
    stopwatch: '⏱️',
    timer_clock: '⏲️',
    mantelpiece_clock: '🕰️',
    clock12: '🕛',
    clock1230: '🕧',
    clock1: '🕐',
    clock130: '🕜',
    clock2: '🕑',
    clock230: '🕝',
    clock3: '🕒',
    clock330: '🕞',
    clock4: '🕓',
    clock430: '🕟',
    clock5: '🕔',
    clock530: '🕠',
    clock6: '🕕',
    clock630: '🕡',
    clock7: '🕖',
    clock730: '🕢',
    clock8: '🕗',
    clock830: '🕣',
    clock9: '🕘',
    clock930: '🕤',
    clock10: '🕙',
    clock1030: '🕥',
    clock11: '🕚',
    clock1130: '🕦',
    new_moon: '🌑',
    waxing_crescent_moon: '🌒',
    first_quarter_moon: '🌓',
    moon: '🌔',
    full_moon: '🌕',
    waning_gibbous_moon: '🌖',
    last_quarter_moon: '🌗',
    waning_crescent_moon: '🌘',
    crescent_moon: '🌙',
    new_moon_with_face: '🌚',
    first_quarter_moon_with_face: '🌛',
    last_quarter_moon_with_face: '🌜',
    thermometer: '🌡️',
    sunny: '☀️',
    full_moon_with_face: '🌝',
    sun_with_face: '🌞',
    ringed_planet: '🪐',
    star: '⭐',
    star2: '🌟',
    stars: '🌠',
    milky_way: '🌌',
    cloud: '☁️',
    partly_sunny: '⛅',
    cloud_with_lightning_and_rain: '⛈️',
    sun_behind_small_cloud: '🌤️',
    sun_behind_large_cloud: '🌥️',
    sun_behind_rain_cloud: '🌦️',
    cloud_with_rain: '🌧️',
    cloud_with_snow: '🌨️',
    cloud_with_lightning: '🌩️',
    tornado: '🌪️',
    fog: '🌫️',
    wind_face: '🌬️',
    cyclone: '🌀',
    rainbow: '🌈',
    closed_umbrella: '🌂',
    open_umbrella: '☂️',
    umbrella: '☔',
    parasol_on_ground: '⛱️',
    zap: '⚡',
    snowflake: '❄️',
    snowman_with_snow: '☃️',
    snowman: '⛄',
    comet: '☄️',
    fire: '🔥',
    droplet: '💧',
    ocean: '🌊',
  },
  objects: {
    eyeglasses: '👓',
    dark_sunglasses: '🕶️',
    goggles: '🥽',
    lab_coat: '🥼',
    safety_vest: '🦺',
    necktie: '👔',
    shirt: '👕',
    jeans: '👖',
    scarf: '🧣',
    gloves: '🧤',
    coat: '🧥',
    socks: '🧦',
    dress: '👗',
    kimono: '👘',
    sari: '🥻',
    one_piece_swimsuit: '🩱',
    swim_brief: '🩲',
    shorts: '🩳',
    bikini: '👙',
    womans_clothes: '👚',
    purse: '👛',
    handbag: '👜',
    pouch: '👝',
    shopping: '🛍️',
    school_satchel: '🎒',
    thong_sandal: '🩴',
    mans_shoe: '👞',
    athletic_shoe: '👟',
    hiking_boot: '🥾',
    flat_shoe: '🥿',
    high_heel: '👠',
    sandal: '👡',
    ballet_shoes: '🩰',
    boot: '👢',
    crown: '👑',
    womans_hat: '👒',
    tophat: '🎩',
    mortar_board: '🎓',
    billed_cap: '🧢',
    military_helmet: '🪖',
    rescue_worker_helmet: '⛑️',
    prayer_beads: '📿',
    lipstick: '💄',
    ring: '💍',
    gem: '💎',
    mute: '🔇',
    speaker: '🔈',
    sound: '🔉',
    loud_sound: '🔊',
    loudspeaker: '📢',
    mega: '📣',
    postal_horn: '📯',
    bell: '🔔',
    no_bell: '🔕',
    musical_score: '🎼',
    musical_note: '🎵',
    notes: '🎶',
    studio_microphone: '🎙️',
    level_slider: '🎚️',
    control_knobs: '🎛️',
    microphone: '🎤',
    headphones: '🎧',
    radio: '📻',
    saxophone: '🎷',
    accordion: '🪗',
    guitar: '🎸',
    musical_keyboard: '🎹',
    trumpet: '🎺',
    violin: '🎻',
    banjo: '🪕',
    drum: '🥁',
    long_drum: '🪘',
    iphone: '📱',
    calling: '📲',
    phone: '☎️',
    telephone_receiver: '📞',
    pager: '📟',
    fax: '📠',
    battery: '🔋',
    electric_plug: '🔌',
    computer: '💻',
    desktop_computer: '🖥️',
    printer: '🖨️',
    keyboard: '⌨️',
    computer_mouse: '🖱️',
    trackball: '🖲️',
    minidisc: '💽',
    floppy_disk: '💾',
    cd: '💿',
    dvd: '📀',
    abacus: '🧮',
    movie_camera: '🎥',
    film_strip: '🎞️',
    film_projector: '📽️',
    clapper: '🎬',
    tv: '📺',
    camera: '📷',
    camera_flash: '📸',
    video_camera: '📹',
    vhs: '📼',
    mag: '🔍',
    mag_right: '🔎',
    candle: '🕯️',
    bulb: '💡',
    flashlight: '🔦',
    izakaya_lantern: '🏮',
    diya_lamp: '🪔',
    notebook_with_decorative_cover: '📔',
    closed_book: '📕',
    book: '📖',
    green_book: '📗',
    blue_book: '📘',
    orange_book: '📙',
    books: '📚',
    notebook: '📓',
    ledger: '📒',
    page_with_curl: '📃',
    scroll: '📜',
    page_facing_up: '📄',
    newspaper: '📰',
    newspaper_roll: '🗞️',
    bookmark_tabs: '📑',
    bookmark: '🔖',
    label: '🏷️',
    moneybag: '💰',
    coin: '🪙',
    yen: '💴',
    dollar: '💵',
    euro: '💶',
    pound: '💷',
    money_with_wings: '💸',
    credit_card: '💳',
    receipt: '🧾',
    chart: '💹',
    envelope: '✉️',
    'e-mail': '📧',
    incoming_envelope: '📨',
    envelope_with_arrow: '📩',
    outbox_tray: '📤',
    inbox_tray: '📥',
    package: '📦',
    mailbox: '📫',
    mailbox_closed: '📪',
    mailbox_with_mail: '📬',
    mailbox_with_no_mail: '📭',
    postbox: '📮',
    ballot_box: '🗳️',
    pencil2: '✏️',
    black_nib: '✒️',
    fountain_pen: '🖋️',
    pen: '🖊️',
    paintbrush: '🖌️',
    crayon: '🖍️',
    memo: '📝',
    briefcase: '💼',
    file_folder: '📁',
    open_file_folder: '📂',
    card_index_dividers: '🗂️',
    date: '📅',
    calendar: '📆',
    spiral_notepad: '🗒️',
    spiral_calendar: '🗓️',
    card_index: '📇',
    chart_with_upwards_trend: '📈',
    chart_with_downwards_trend: '📉',
    bar_chart: '📊',
    clipboard: '📋',
    pushpin: '📌',
    round_pushpin: '📍',
    paperclip: '📎',
    paperclips: '🖇️',
    straight_ruler: '📏',
    triangular_ruler: '📐',
    scissors: '✂️',
    card_file_box: '🗃️',
    file_cabinet: '🗄️',
    wastebasket: '🗑️',
    lock: '🔒',
    unlock: '🔓',
    lock_with_ink_pen: '🔏',
    closed_lock_with_key: '🔐',
    key: '🔑',
    old_key: '🗝️',
    hammer: '🔨',
    axe: '🪓',
    pick: '⛏️',
    hammer_and_pick: '⚒️',
    hammer_and_wrench: '🛠️',
    dagger: '🗡️',
    crossed_swords: '⚔️',
    gun: '🔫',
    boomerang: '🪃',
    bow_and_arrow: '🏹',
    shield: '🛡️',
    carpentry_saw: '🪚',
    wrench: '🔧',
    screwdriver: '🪛',
    nut_and_bolt: '🔩',
    gear: '⚙️',
    clamp: '🗜️',
    balance_scale: '⚖️',
    probing_cane: '🦯',
    link: '🔗',
    chains: '⛓️',
    hook: '🪝',
    toolbox: '🧰',
    magnet: '🧲',
    ladder: '🪜',
    alembic: '⚗️',
    test_tube: '🧪',
    petri_dish: '🧫',
    dna: '🧬',
    microscope: '🔬',
    telescope: '🔭',
    satellite: '📡',
    syringe: '💉',
    drop_of_blood: '🩸',
    pill: '💊',
    adhesive_bandage: '🩹',
    stethoscope: '🩺',
    door: '🚪',
    elevator: '🛗',
    mirror: '🪞',
    window: '🪟',
    bed: '🛏️',
    couch_and_lamp: '🛋️',
    chair: '🪑',
    toilet: '🚽',
    plunger: '🪠',
    shower: '🚿',
    bathtub: '🛁',
    mouse_trap: '🪤',
    razor: '🪒',
    lotion_bottle: '🧴',
    safety_pin: '🧷',
    broom: '🧹',
    basket: '🧺',
    roll_of_paper: '🧻',
    bucket: '🪣',
    soap: '🧼',
    toothbrush: '🪥',
    sponge: '🧽',
    fire_extinguisher: '🧯',
    shopping_cart: '🛒',
    smoking: '🚬',
    coffin: '⚰️',
    headstone: '🪦',
    funeral_urn: '⚱️',
    moyai: '🗿',
    placard: '🪧',
  },
  symbols: {
    atm: '🏧',
    put_litter_in_its_place: '🚮',
    potable_water: '🚰',
    wheelchair: '♿',
    mens: '🚹',
    womens: '🚺',
    restroom: '🚻',
    baby_symbol: '🚼',
    wc: '🚾',
    passport_control: '🛂',
    customs: '🛃',
    baggage_claim: '🛄',
    left_luggage: '🛅',
    warning: '⚠️',
    children_crossing: '🚸',
    no_entry: '⛔',
    no_entry_sign: '🚫',
    no_bicycles: '🚳',
    no_smoking: '🚭',
    do_not_litter: '🚯',
    'non-potable_water': '🚱',
    no_pedestrians: '🚷',
    no_mobile_phones: '📵',
    underage: '🔞',
    radioactive: '☢️',
    biohazard: '☣️',
    arrow_up: '⬆️',
    arrow_upper_right: '↗️',
    arrow_right: '➡️',
    arrow_lower_right: '↘️',
    arrow_down: '⬇️',
    arrow_lower_left: '↙️',
    arrow_left: '⬅️',
    arrow_upper_left: '↖️',
    arrow_up_down: '↕️',
    left_right_arrow: '↔️',
    leftwards_arrow_with_hook: '↩️',
    arrow_right_hook: '↪️',
    arrow_heading_up: '⤴️',
    arrow_heading_down: '⤵️',
    arrows_clockwise: '🔃',
    arrows_counterclockwise: '🔄',
    back: '🔙',
    end: '🔚',
    on: '🔛',
    soon: '🔜',
    top: '🔝',
    place_of_worship: '🛐',
    atom_symbol: '⚛️',
    om: '🕉️',
    star_of_david: '✡️',
    wheel_of_dharma: '☸️',
    yin_yang: '☯️',
    latin_cross: '✝️',
    orthodox_cross: '☦️',
    star_and_crescent: '☪️',
    peace_symbol: '☮️',
    menorah: '🕎',
    six_pointed_star: '🔯',
    aries: '♈',
    taurus: '♉',
    gemini: '♊',
    cancer: '♋',
    leo: '♌',
    virgo: '♍',
    libra: '♎',
    scorpius: '♏',
    sagittarius: '♐',
    capricorn: '♑',
    aquarius: '♒',
    pisces: '♓',
    ophiuchus: '⛎',
    twisted_rightwards_arrows: '🔀',
    repeat: '🔁',
    repeat_one: '🔂',
    arrow_forward: '▶️',
    fast_forward: '⏩',
    next_track_button: '⏭️',
    play_or_pause_button: '⏯️',
    arrow_backward: '◀️',
    rewind: '⏪',
    previous_track_button: '⏮️',
    arrow_up_small: '🔼',
    arrow_double_up: '⏫',
    arrow_down_small: '🔽',
    arrow_double_down: '⏬',
    pause_button: '⏸️',
    stop_button: '⏹️',
    record_button: '⏺️',
    eject_button: '⏏️',
    cinema: '🎦',
    low_brightness: '🔅',
    high_brightness: '🔆',
    signal_strength: '📶',
    vibration_mode: '📳',
    mobile_phone_off: '📴',
    female_sign: '♀️',
    male_sign: '♂️',
    transgender_symbol: '⚧️',
    heavy_multiplication_x: '✖️',
    heavy_plus_sign: '➕',
    heavy_minus_sign: '➖',
    heavy_division_sign: '➗',
    infinity: '♾️',
    bangbang: '‼️',
    interrobang: '⁉️',
    question: '❓',
    grey_question: '❔',
    grey_exclamation: '❕',
    exclamation: '❗',
    wavy_dash: '〰️',
    currency_exchange: '💱',
    heavy_dollar_sign: '💲',
    medical_symbol: '⚕️',
    recycle: '♻️',
    fleur_de_lis: '⚜️',
    trident: '🔱',
    name_badge: '📛',
    beginner: '🔰',
    o: '⭕',
    white_check_mark: '✅',
    ballot_box_with_check: '☑️',
    heavy_check_mark: '✔️',
    x: '❌',
    negative_squared_cross_mark: '❎',
    curly_loop: '➰',
    loop: '➿',
    part_alternation_mark: '〽️',
    eight_spoked_asterisk: '✳️',
    eight_pointed_black_star: '✴️',
    sparkle: '❇️',
    copyright: '©️',
    registered: '®️',
    tm: '™️',
    hash: '#️⃣',
    asterisk: '*️⃣',
    zero: '0️⃣',
    one: '1️⃣',
    two: '2️⃣',
    three: '3️⃣',
    four: '4️⃣',
    five: '5️⃣',
    six: '6️⃣',
    seven: '7️⃣',
    eight: '8️⃣',
    nine: '9️⃣',
    keycap_ten: '🔟',
    capital_abcd: '🔠',
    abcd: '🔡',
    1234: '🔢',
    symbols: '🔣',
    abc: '🔤',
    a: '🅰️',
    ab: '🆎',
    b: '🅱️',
    cl: '🆑',
    cool: '🆒',
    free: '🆓',
    information_source: 'ℹ️',
    id: '🆔',
    m: 'Ⓜ️',
    new: '🆕',
    ng: '🆖',
    o2: '🅾️',
    ok: '🆗',
    parking: '🅿️',
    sos: '🆘',
    up: '🆙',
    vs: '🆚',
    koko: '🈁',
    sa: '🈂️',
    u6708: '🈷️',
    u6709: '🈶',
    u6307: '🈯',
    ideograph_advantage: '🉐',
    u5272: '🈹',
    u7121: '🈚',
    u7981: '🈲',
    accept: '🉑',
    u7533: '🈸',
    u5408: '🈴',
    u7a7a: '🈳',
    congratulations: '㊗️',
    secret: '㊙️',
    u55b6: '🈺',
    u6e80: '🈵',
    red_circle: '🔴',
    orange_circle: '🟠',
    yellow_circle: '🟡',
    green_circle: '🟢',
    large_blue_circle: '🔵',
    purple_circle: '🟣',
    brown_circle: '🟤',
    black_circle: '⚫',
    white_circle: '⚪',
    red_square: '🟥',
    orange_square: '🟧',
    yellow_square: '🟨',
    green_square: '🟩',
    blue_square: '🟦',
    purple_square: '🟪',
    brown_square: '🟫',
    black_large_square: '⬛',
    white_large_square: '⬜',
    black_medium_square: '◼️',
    white_medium_square: '◻️',
    black_medium_small_square: '◾',
    white_medium_small_square: '◽',
    black_small_square: '▪️',
    white_small_square: '▫️',
    large_orange_diamond: '🔶',
    large_blue_diamond: '🔷',
    small_orange_diamond: '🔸',
    small_blue_diamond: '🔹',
    small_red_triangle: '🔺',
    small_red_triangle_down: '🔻',
    diamond_shape_with_a_dot_inside: '💠',
    radio_button: '🔘',
    white_square_button: '🔳',
    black_square_button: '🔲',
  },
  // flags: {
  //   checkered_flag: '🏁',
  //   triangular_flag_on_post: '🚩',
  //   crossed_flags: '🎌',
  //   black_flag: '🏴',
  //   white_flag: '🏳️',
  //   rainbow_flag: '🏳️‍🌈',
  //   transgender_flag: '🏳️‍⚧️',
  //   pirate_flag: '🏴‍☠️',
  //   ascension_island: '🇦🇨',
  //   andorra: '🇦🇩',
  //   united_arab_emirates: '🇦🇪',
  //   afghanistan: '🇦🇫',
  //   antigua_barbuda: '🇦🇬',
  //   anguilla: '🇦🇮',
  //   albania: '🇦🇱',
  //   armenia: '🇦🇲',
  //   angola: '🇦🇴',
  //   antarctica: '🇦🇶',
  //   argentina: '🇦🇷',
  //   american_samoa: '🇦🇸',
  //   austria: '🇦🇹',
  //   australia: '🇦🇺',
  //   aruba: '🇦🇼',
  //   aland_islands: '🇦🇽',
  //   azerbaijan: '🇦🇿',
  //   bosnia_herzegovina: '🇧🇦',
  //   barbados: '🇧🇧',
  //   bangladesh: '🇧🇩',
  //   belgium: '🇧🇪',
  //   burkina_faso: '🇧🇫',
  //   bulgaria: '🇧🇬',
  //   bahrain: '🇧🇭',
  //   burundi: '🇧🇮',
  //   benin: '🇧🇯',
  //   st_barthelemy: '🇧🇱',
  //   bermuda: '🇧🇲',
  //   brunei: '🇧🇳',
  //   bolivia: '🇧🇴',
  //   caribbean_netherlands: '🇧🇶',
  //   brazil: '🇧🇷',
  //   bahamas: '🇧🇸',
  //   bhutan: '🇧🇹',
  //   bouvet_island: '🇧🇻',
  //   botswana: '🇧🇼',
  //   belarus: '🇧🇾',
  //   belize: '🇧🇿',
  //   canada: '🇨🇦',
  //   cocos_islands: '🇨🇨',
  //   congo_kinshasa: '🇨🇩',
  //   central_african_republic: '🇨🇫',
  //   congo_brazzaville: '🇨🇬',
  //   switzerland: '🇨🇭',
  //   cote_divoire: '🇨🇮',
  //   cook_islands: '🇨🇰',
  //   chile: '🇨🇱',
  //   cameroon: '🇨🇲',
  //   cn: '🇨🇳',
  //   colombia: '🇨🇴',
  //   clipperton_island: '🇨🇵',
  //   costa_rica: '🇨🇷',
  //   cuba: '🇨🇺',
  //   cape_verde: '🇨🇻',
  //   curacao: '🇨🇼',
  //   christmas_island: '🇨🇽',
  //   cyprus: '🇨🇾',
  //   czech_republic: '🇨🇿',
  //   de: '🇩🇪',
  //   diego_garcia: '🇩🇬',
  //   djibouti: '🇩🇯',
  //   denmark: '🇩🇰',
  //   dominica: '🇩🇲',
  //   dominican_republic: '🇩🇴',
  //   algeria: '🇩🇿',
  //   ceuta_melilla: '🇪🇦',
  //   ecuador: '🇪🇨',
  //   estonia: '🇪🇪',
  //   egypt: '🇪🇬',
  //   western_sahara: '🇪🇭',
  //   eritrea: '🇪🇷',
  //   es: '🇪🇸',
  //   ethiopia: '🇪🇹',
  //   eu: '🇪🇺',
  //   finland: '🇫🇮',
  //   fiji: '🇫🇯',
  //   falkland_islands: '🇫🇰',
  //   micronesia: '🇫🇲',
  //   faroe_islands: '🇫🇴',
  //   fr: '🇫🇷',
  //   gabon: '🇬🇦',
  //   gb: '🇬🇧',
  //   grenada: '🇬🇩',
  //   georgia: '🇬🇪',
  //   french_guiana: '🇬🇫',
  //   guernsey: '🇬🇬',
  //   ghana: '🇬🇭',
  //   gibraltar: '🇬🇮',
  //   greenland: '🇬🇱',
  //   gambia: '🇬🇲',
  //   guinea: '🇬🇳',
  //   guadeloupe: '🇬🇵',
  //   equatorial_guinea: '🇬🇶',
  //   greece: '🇬🇷',
  //   south_georgia_south_sandwich_islands: '🇬🇸',
  //   guatemala: '🇬🇹',
  //   guam: '🇬🇺',
  //   guinea_bissau: '🇬🇼',
  //   guyana: '🇬🇾',
  //   hong_kong: '🇭🇰',
  //   heard_mcdonald_islands: '🇭🇲',
  //   honduras: '🇭🇳',
  //   croatia: '🇭🇷',
  //   haiti: '🇭🇹',
  //   hungary: '🇭🇺',
  //   canary_islands: '🇮🇨',
  //   indonesia: '🇮🇩',
  //   ireland: '🇮🇪',
  //   israel: '🇮🇱',
  //   isle_of_man: '🇮🇲',
  //   india: '🇮🇳',
  //   british_indian_ocean_territory: '🇮🇴',
  //   iraq: '🇮🇶',
  //   iran: '🇮🇷',
  //   iceland: '🇮🇸',
  //   it: '🇮🇹',
  //   jersey: '🇯🇪',
  //   jamaica: '🇯🇲',
  //   jordan: '🇯🇴',
  //   jp: '🇯🇵',
  //   kenya: '🇰🇪',
  //   kyrgyzstan: '🇰🇬',
  //   cambodia: '🇰🇭',
  //   kiribati: '🇰🇮',
  //   comoros: '🇰🇲',
  //   st_kitts_nevis: '🇰🇳',
  //   north_korea: '🇰🇵',
  //   kr: '🇰🇷',
  //   kuwait: '🇰🇼',
  //   cayman_islands: '🇰🇾',
  //   kazakhstan: '🇰🇿',
  //   laos: '🇱🇦',
  //   lebanon: '🇱🇧',
  //   st_lucia: '🇱🇨',
  //   liechtenstein: '🇱🇮',
  //   sri_lanka: '🇱🇰',
  //   liberia: '🇱🇷',
  //   lesotho: '🇱🇸',
  //   lithuania: '🇱🇹',
  //   luxembourg: '🇱🇺',
  //   latvia: '🇱🇻',
  //   libya: '🇱🇾',
  //   morocco: '🇲🇦',
  //   monaco: '🇲🇨',
  //   moldova: '🇲🇩',
  //   montenegro: '🇲🇪',
  //   st_martin: '🇲🇫',
  //   madagascar: '🇲🇬',
  //   marshall_islands: '🇲🇭',
  //   macedonia: '🇲🇰',
  //   mali: '🇲🇱',
  //   myanmar: '🇲🇲',
  //   mongolia: '🇲🇳',
  //   macau: '🇲🇴',
  //   northern_mariana_islands: '🇲🇵',
  //   martinique: '🇲🇶',
  //   mauritania: '🇲🇷',
  //   montserrat: '🇲🇸',
  //   malta: '🇲🇹',
  //   mauritius: '🇲🇺',
  //   maldives: '🇲🇻',
  //   malawi: '🇲🇼',
  //   mexico: '🇲🇽',
  //   malaysia: '🇲🇾',
  //   mozambique: '🇲🇿',
  //   namibia: '🇳🇦',
  //   new_caledonia: '🇳🇨',
  //   niger: '🇳🇪',
  //   norfolk_island: '🇳🇫',
  //   nigeria: '🇳🇬',
  //   nicaragua: '🇳🇮',
  //   netherlands: '🇳🇱',
  //   norway: '🇳🇴',
  //   nepal: '🇳🇵',
  //   nauru: '🇳🇷',
  //   niue: '🇳🇺',
  //   new_zealand: '🇳🇿',
  //   oman: '🇴🇲',
  //   panama: '🇵🇦',
  //   peru: '🇵🇪',
  //   french_polynesia: '🇵🇫',
  //   papua_new_guinea: '🇵🇬',
  //   philippines: '🇵🇭',
  //   pakistan: '🇵🇰',
  //   poland: '🇵🇱',
  //   st_pierre_miquelon: '🇵🇲',
  //   pitcairn_islands: '🇵🇳',
  //   puerto_rico: '🇵🇷',
  //   palestinian_territories: '🇵🇸',
  //   portugal: '🇵🇹',
  //   palau: '🇵🇼',
  //   paraguay: '🇵🇾',
  //   qatar: '🇶🇦',
  //   reunion: '🇷🇪',
  //   romania: '🇷🇴',
  //   serbia: '🇷🇸',
  //   ru: '🇷🇺',
  //   rwanda: '🇷🇼',
  //   saudi_arabia: '🇸🇦',
  //   solomon_islands: '🇸🇧',
  //   seychelles: '🇸🇨',
  //   sudan: '🇸🇩',
  //   sweden: '🇸🇪',
  //   singapore: '🇸🇬',
  //   st_helena: '🇸🇭',
  //   slovenia: '🇸🇮',
  //   svalbard_jan_mayen: '🇸🇯',
  //   slovakia: '🇸🇰',
  //   sierra_leone: '🇸🇱',
  //   san_marino: '🇸🇲',
  //   senegal: '🇸🇳',
  //   somalia: '🇸🇴',
  //   suriname: '🇸🇷',
  //   south_sudan: '🇸🇸',
  //   sao_tome_principe: '🇸🇹',
  //   el_salvador: '🇸🇻',
  //   sint_maarten: '🇸🇽',
  //   syria: '🇸🇾',
  //   swaziland: '🇸🇿',
  //   tristan_da_cunha: '🇹🇦',
  //   turks_caicos_islands: '🇹🇨',
  //   chad: '🇹🇩',
  //   french_southern_territories: '🇹🇫',
  //   togo: '🇹🇬',
  //   thailand: '🇹🇭',
  //   tajikistan: '🇹🇯',
  //   tokelau: '🇹🇰',
  //   timor_leste: '🇹🇱',
  //   turkmenistan: '🇹🇲',
  //   tunisia: '🇹🇳',
  //   tonga: '🇹🇴',
  //   tr: '🇹🇷',
  //   trinidad_tobago: '🇹🇹',
  //   tuvalu: '🇹🇻',
  //   taiwan: '🇹🇼',
  //   tanzania: '🇹🇿',
  //   ukraine: '🇺🇦',
  //   uganda: '🇺🇬',
  //   us_outlying_islands: '🇺🇲',
  //   united_nations: '🇺🇳',
  //   us: '🇺🇸',
  //   uruguay: '🇺🇾',
  //   uzbekistan: '🇺🇿',
  //   vatican_city: '🇻🇦',
  //   st_vincent_grenadines: '🇻🇨',
  //   venezuela: '🇻🇪',
  //   british_virgin_islands: '🇻🇬',
  //   us_virgin_islands: '🇻🇮',
  //   vietnam: '🇻🇳',
  //   vanuatu: '🇻🇺',
  //   wallis_futuna: '🇼🇫',
  //   samoa: '🇼🇸',
  //   kosovo: '🇽🇰',
  //   yemen: '🇾🇪',
  //   mayotte: '🇾🇹',
  //   south_africa: '🇿🇦',
  //   zambia: '🇿🇲',
  //   zimbabwe: '🇿🇼',
  //   england: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
  //   scotland: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
  //   wales: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
  // },
};
