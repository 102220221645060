<template>
  <div :class="['comment-layout', $store.state.breakpoint.smAndDown || showActions ? '-show-actions' : '']">
    <div class="_left">
      <user-avatar
        v-if="showMeta"
        :user="user"
        :src="user.src"
        :size="20"
      />
    </div>
    <div class="_right">
      <div
        v-if="!hideTitle && showMeta"
        class="_title"
      >
        <span
          class="_name"
        >
          {{ user.firstName }} {{ user.lastName }}
        </span>
        <span class="_timestamp">{{ timestamp }}</span>
        <slot name="post-title" />
      </div>
      <div class="_layout-editor">
        <slot />
      </div>
    </div>
    <div class="_layout-actions">
      <slot name="actions" />
    </div>
  </div>
</template>

<script>
import UserAvatar from 'shared/components/UserAvatar.vue';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useUsers from '@/composables/user/users';

export default {
  name: 'CommentLayout',
  props: {
    creator: {
      type: Object,
      default: () => ({}),
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
    timestamp: {
      type: String,
      default: '',
    },
    generated: {
      type: Boolean,
      default: false,
    },
    showActions: {
      type: Boolean,
      default: false,
    },
    showMeta: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { mooncampBotUser, deletedUser } = useUsers();
    const { loggedInUser } = useLoggedInUser();
    return { mooncampBotUser, deletedUser, loggedInUser };
  },
  components: { UserAvatar },
  computed: {
    user() {
      if (this.generated) {
        return this.mooncampBotUser;
      }

      if (this.creator === null) {
        return this.deletedUser;
      }

      return this.creator;
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .comment-layout {
    position: relative;
    display: flex;

    ._left {
      flex: 0 0 2.4rem;
      margin-top: .3rem;
      margin-right: .6rem;
    }

    ._right {
      flex: 1 1 auto;
      max-width: calc(100% - 3rem);

      ._title {
        ._name {
          padding-right: .4rem;
          font-weight: $font-weight-semibold;
        }

        ._timestamp {
          font-size: $font-size-2;
          color: $font-color-secondary;
        }
      }

      ._layout-editor {
        margin: -.3rem -.1rem;
      }
    }

    ._layout-actions {
      position: absolute;
      top: -.4rem;
      right: 0;
      z-index: 1;
      display: flex;
      justify-content: flex-end;
      margin-left: auto;
      visibility: hidden;
    }

    &:hover,
    &.-focused,
    &.-show-actions {
      ._layout-actions {
        visibility: visible;
      }
    }
  }
</style>
