<template>
  <m-focusable
    class="metric-form-trigger"
    :read-only="readOnly"
    :disabled="disabled"
    type="clickable"
    hide-border
    full-width
    :m-style="mStyle"
  >
    <div
      v-if="goal.progressMeasurement === 'continuous'"
      class="_inner _continuous"
    >
      <div class="_start">
        {{ start }} {{ goal.metric }}
      </div>
      <div class="_icon">
        <m-icon type="arrow-go-forward" />
      </div>
      <div class="_end">
        {{ end }} {{ goal.metric }}
      </div>
    </div>
    <div
      v-else-if="goal.progressMeasurement === goalProgressMeasurement.threshold"
      class="_inner _threshold"
    >
      <div
        class="_icon"
        size="12"
      >
        <m-icon :type="icon" />
      </div>
      <div class="_number">
        {{ goal.threshold }} {{ goal.metric }}
      </div>
    </div>
    <div
      v-else
      class="_inner _default"
    >
      <m-icon
        :type="icon"
        class="_icon"
        size="12"
      />
      {{ $t(`metricForm.${camelCase(goal.progressMeasurement)}`) }}
    </div>
  </m-focusable>
</template>

<script>
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import { camelCase } from 'lodash-es';
import { formatNumber } from '@/lib/goal/progress';
import { goalProgressMeasurement, goalThresholdTargetArea } from 'shared/constants.json';
import { mStyleProps } from 'shared/lib/m-style-props';

export default {
  name: 'MetricFormTrigger',
  props: {
    ...mStyleProps,
    goal: {
      type: Object,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { userLang } = useLoggedInUser();
    return { userLang };
  },
  data() {
    return { camelCase, goalProgressMeasurement, goalThresholdTargetArea };
  },
  computed: {
    icon() {
      switch (this.goal.progressMeasurement) {
        case goalProgressMeasurement.binary:
          return 'check-square';
        case goalProgressMeasurement.alignedItems:
          return 'apartment';
        case goalProgressMeasurement.threshold:
          return this.goal.thresholdTargetArea;
        case goalProgressMeasurement.none:
        default:
          return 'stop';
      }
    },
    threshold() {
      return formatNumber(this.goal.threshold, this.userLang);
    },
    start() {
      return formatNumber(this.goal.start, this.userLang);
    },
    end() {
      return formatNumber(this.goal.end, this.userLang);
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .metric-form-trigger {
    ._start {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    ._end {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    ._continuous {
      display: flex;
      align-items: center;

      ._icon {
        margin: 0 .8rem;
      }
    }

    ._threshold {
      display: flex;
      align-items: center;

      ._icon {
        margin-top: -.2rem;
        margin-right: .4rem;
      }
    }

    ._default {
      display: flex;
      align-items: center;

      ._icon {
        margin-right: .4rem;
      }
    }
  }
</style>
