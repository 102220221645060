import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useSavedViewCleaner from '@/composables/saved-views/saved-view-cleaner';
import { canEditView } from '@/lib/saved-view/saved-view';
import { computed } from 'vue';

export default function useCurrentView({ defaultProps, views, hasPublicViewAccess, selectedView, readOnly }) {
  const { clearView } = useSavedViewCleaner();
  const { myTeamsIds } = useLoggedInUser();

  const currentView = computed(() => {
    const view = views.value.find((v) => v.uid === selectedView.value.view.uid);
    if (view === undefined) {
      return views.value[0];
    }
    return view;
  });

  const clearedCurrentView = computed(() => clearView({
    view: currentView.value,
    props: defaultProps.value,
  }));

  const canEditCurrentView = computed(() => canEditView(clearedCurrentView.value, readOnly, hasPublicViewAccess, myTeamsIds));

  return {
    currentView: clearedCurrentView,
    canEditCurrentView,
  };
}
