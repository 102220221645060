<template>
  <div class="list-tab">
    <m-btn
      hide-border
      :light="!props.active"
      small
      :class="['_selector', !props.active ? '-inactive' : '']"
      @click="emit('click', $event)"
    >
      <slot />
    </m-btn>
    <div
      v-if="props.active"
      class="underline"
    />
  </div>
</template>
<script setup>

const props = defineProps({
  active: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['click']);
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>

.list-tab {
  position: relative;
  border-radius: $input-field-border-radius;

  ._selector {
   &.-inactive {
     :deep(.m-emoji) {
       opacity: .6;
     }
   }
  }

  .underline {
  position: absolute;
  bottom: -0.7rem;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: map_get($grey, 'darken-4');
  }
}

</style>
