import { directPropertyEmpty, isEmpty } from '@/lib/display-props/display-props';
import { goal as goalConfig } from 'shared/api/query/configs.json';
import { goalValidationError, propertyType } from 'shared/constants.json';
import { requiredDirectPropertiesMap } from '@/lib/rules/rules';

export default function useGoalDetailRules(goalTypeOptions, goalSelector, hasAdvancedOKRRules) {
  const createRules = (goalTypeOption) => {
    if (!hasAdvancedOKRRules) {
      return [canBeChildOf(goalTypeOption)];
    }
    return [
      canBeChildOf(goalTypeOption),
      required(goalTypeOption),
      validProgressMeasurement(goalTypeOption),
    ];
  };

  const canBeChildOf = (goalTypeOption) => {
    const allowedOptionIds = goalTypeOption.canBeChildOf.map((t) => t.uid);

    return (goal) => {
      if (allowedOptionIds.length === 0 && goal.parents.length > 0) {
        return [
          {
            key: goalValidationError.canBeChildOfMustBeEmpty,
            property: { isDirect: true, key: goalConfig.edges.parents },
            payload: { expected: [], actual: goal.parents, goal },
          },
        ];
      }

      for (let i = 0; i < goal.parents.length; i++) {
        const g = goalSelector.selectSingle(goal.parents[i].uid);
        if (g === undefined) {
          return [];
        }
        const selectedOptions = g.properties.find((p) => p.property.isGoalType).selectedOptions;
        for (let j = 0; j < selectedOptions.length; j++) {
          if (!allowedOptionIds.includes(selectedOptions[j].uid)) {
            return [
              {
                key: goalValidationError.canBeChildOf,
                property: { isDirect: true, key: goalConfig.edges.parents },
                payload: { expected: allowedOptionIds, actual: selectedOptions, goal },
              },
            ];
          }
        }
      }

      return [];
    };
  };

  const required = (goalTypeOption) => (goal) => {
    const directPropertiesErrors = Object.keys(requiredDirectPropertiesMap).reduce((res, next) => {
      if (!goalTypeOption[requiredDirectPropertiesMap[next]]) {
        return res;
      }

      if (!directPropertyEmpty({ attr: next, goal })) {
        return res;
      }

      res.push({
        key: goalValidationError.required,
        property: { isDirect: true, key: next },
        payload: { goal },
      });
      return res;
    }, []);

    const propertiesErrors = goalTypeOption.requiredProperties.reduce((res, next) => {
      const propValue = goal.properties.find((p) => p.property.uid === next.uid);
      if (propValue.property.type === propertyType.lookup) {
        return res;
      }

      if (!isEmpty(propValue.property, propValue)) {
        return res;
      }

      res.push({
        key: goalValidationError.required,
        property: { isDirect: false, uid: next.uid, key: next.uid },
        payload: { goal },
      });
      return res;
    }, []);

    return [...directPropertiesErrors, ...propertiesErrors];
  };

  const validProgressMeasurement = (goalTypeOption) => (goal) => {
    if (goalTypeOption.allowedMeasurementTypes.length === 0) {
      return [];
    }

    if (!goalTypeOption.allowedMeasurementTypes.includes(goal.progressMeasurement)) {
      return [{
        key: goalValidationError.measurementType,
        property: { isDirect: true, key: goalConfig.edges.progressMeasurement },
        payload: { expected: goalTypeOption.allowedMeasurementTypes, actual: goal.progressMeasurement, goal },
      }];
    }

    return [];
  };

  const rules = goalTypeOptions.reduce((res, next) => {
    res[`${next.uid}`] = createRules(next);
    return res;
  }, {});

  return { rules };
}
