<template>
  <m-content
    class="initial-import-modal"
    :padding-x="11"
    :padding-y="11"
  >
    <m-btn
      class="_btn"
      :style="btnStyle"
      hide-border
      @click="$emit('scratch')"
    >
      <m-icon
        type="file"
        size="36"
        :color="$colors.grey.lighten1"
        :style="iconStyle"
      />
      <div class="_text">
        {{ $t('planningDetails.importGoals.initialModal.scratch', {title: featureNamePlural}) }}
      </div>
    </m-btn>
    <div />
    <m-btn
      class="_btn"
      hide-border
      :style="btnStyle"
      @click="$emit('import')"
    >
      <m-icon
        :color="$colors.grey.lighten1"
        type="download"
        size="36"
        :style="iconStyle"
      />
      <div class="_text">
        {{ $t('planningDetails.importGoals.initialModal.import', {title: featureNamePlural}) }}
      </div>
    </m-btn>
  </m-content>
</template>

<script>
export default {
  name: 'InitialImportGoals',
  props: {
    featureNamePlural: {
      type: String,
      required: true,
    },
  },
  emits: ['scratch', 'import'],
  computed: {
    iconStyle() {
      return { padding: '1.2rem' };
    },
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  @import "shared/assets/scss/box-shadow";

  .initial-import-modal {
    display: grid;
    grid-template-columns: 1fr 2.4rem 1fr;

    ._btn {
      flex-direction: column;
      width: 100%;
      height: 17rem;

      @include box_shadow(1);
    }

    ._text {
      margin-top: 1.2rem;
      white-space: pre-line;
    }
  }
</style>
