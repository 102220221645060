import useGoalActivity from '@/composables/goal-activity/goal-activity';
import useSubscription from '@/composables/subscription/subscription';
import { intersection } from 'shared/lib/array/array';
import { subscriptionAction } from 'shared/constants.json';

export default function useGoalActivityDataSync(repo, options = { ignoreNonExistingData: false }) {
  const subscriptionSvc = useSubscription();
  const goalActivitySvc = useGoalActivity();

  const subscribe = () => {
    subscriptionSvc.subscribe({
      model: repo.model,
      syncFn: ({ action, ids }) => {
        switch (action) {
          case subscriptionAction.update: {
            if (options.ignoreNonExistingData) {
              const filteredIds = intersection(ids, repo.ids.value.map((id) => parseInt(id, 10)));
              repo.selectMultiple(filteredIds, { commitToRemote: true });
              return;
            }
            repo.selectMultiple(ids, { commitToRemote: true });
            break;
          }
          case subscriptionAction.create:
            repo.selectMultiple(ids, { commitToRemote: true }).then((goalActivities) => {
              goalActivitySvc.increaseGoalActivitiesCount(goalActivities);
            });
            break;
          case subscriptionAction.delete: {
            const filteredIds = intersection(ids, repo.ids.value.map((id) => parseInt(id, 10)));
            const entities = repo.selectMultiple(ids);
            goalActivitySvc.decreaseGoalActivitiesCount(entities);
            repo.deleteMultiple(filteredIds, { commitToRemote: false });
            break;
          }
          default:
            break;
        }
      },
    });
  };

  return { subscribe };
}
