<template>
  <m-dialog
    :value="value"
    hide-header
    hide-footer
    :max-width="$modalSizes.md"
    fade-in
    @close="$emit('close')"
  >
    <div class="_content">
      <div class="_welcome-emoji">
        🎉
      </div>
      <h3>
        {{ $t('afterAccountSpaceDemoModal.congratulations') }}
      </h3>
      <div class="_description">
        {{ $t('afterAccountSpaceDemoModal.description') }}
      </div>
      <div class="_cta">
        <m-btn
          color="primary"
          block
          class="_btn"
          @click="showOnboarding"
        >
          {{ $t('afterAccountSpaceDemoModal.goToOnboarding') }}
        </m-btn>
        <m-btn
          block
          class="_btn"
          @click="$emit('close')"
        >
          {{ $t('afterAccountSpaceDemoModal.explore') }}
        </m-btn>
      </div>
    </div>
  </m-dialog>
</template>

<script>
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import { EventBus } from '@/lib/event-bus';

export default {
  name: 'AfterAccountSpaceDemoModal',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const { loggedInUser } = useLoggedInUser();
    return { loggedInUser };
  },
  emits: ['close'],
  methods: {
    showOnboarding() {
      this.$emit('close');
      EventBus.$emit('show-onboarding');
    },
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  ._content {
    text-align: center;

    ._welcome-emoji {
      font-size: 4.4rem;
    }

    h3 {
      margin-bottom: 1.2rem;
    }

    ._sub-description {
      margin-bottom: 2.4rem;
      font-size: $font-size-3;
      color: $font-color-secondary;
    }

    ._description {
      margin-bottom: 2rem;
    }

    h5 {
      font-weight: $font-weight-bold;
    }

    ._btn {
      margin-top: .8rem;
    }
  }
</style>
