<template>
  <div class="plan-hint">
    {{ $t('planHint.currentPlan', { currentPlan }) }}
    <template v-if="accountSettings.planId === trial">
      {{ $t('planHint.trialExpiresAt', { expirationDate: formatDate(accountSettings.expiresAt) }) }}
    </template>
  </div>
</template>

<script>
import useAccountSettings from '@/composables/logged-in-user-account/account-settings';
import { DateTime } from 'luxon';
import { camelCase } from 'lodash-es';
import { productPlan } from 'shared/constants.json';

export default {
  name: 'PlanHint',
  setup() {
    const { accountSettings } = useAccountSettings();
    return { accountSettings };
  },
  data() {
    return { trial: productPlan.trial };
  },
  computed: {
    currentPlan() {
      return this.$t(`planHint.${camelCase(this.accountSettings.planId)}`);
    },
  },
  methods: {
    formatDate(date) {
      return DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED);
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .plan-hint {
    color: $font-color-secondary;
  }
</style>
