<template>
  <div class="access-policy-for-access-groups">
    <div
      v-for="scope in sortedScopes"
      :key="scope.uid"
      @click="showScopeSelector(scope)"
    >
      <m-content
        v-if="typeof scope.scope !== 'undefined' && scope.scope !== null"
        :padding-y="6"
        :padding-x="4"
        class="_list-item -scope"
      >
        <div class="_middle">
          <scope-filter
            :value="scope.scope"
            :props="userProps"
            :account="account"
            show-static-user-selection
            disabled
            view-mode
            class="_filter"
          />
        </div>
        <div
          class="_right"
          @click.stop=""
        >
          <m-btn
            hide-border
            class="_action"
            :disabled="disabled"
            icon="delete"
            light
            fab
            @click.stop="removeScope(scope)"
          />
        </div>
      </m-content>
      <m-content
        v-else-if="scope.users.length > 0"
        :padding-y="6"
        :padding-x="4"
        class="_list-item"
      >
        <user-display
          :user="scope.users[0]"
          large
        >
          <template #subName>
            <div class="_subtitle">
              {{ scope.users[0].email }}
            </div>
          </template>
        </user-display>
        <div class="_right">
          <m-btn
            hide-border
            class="_action"
            :disabled="disabled"
            light
            icon="delete"
            fab
            @click.stop="removeScope(scope)"
          />
        </div>
      </m-content>
    </div>
    <m-content
      :padding-y="6"
      :padding-x="4"
      class="_actions"
    >
      <div class="_btn _invite">
        <m-btn
          :disabled="disabled"
          block
          @click.stop="showScopeSelector(null)"
        >
          {{ $t('accessPolicyForAccessGroups.inviteButton') }}
        </m-btn>
      </div>
    </m-content>
    <m-dialog
      v-model:value="showModal"
      hide-footer
      no-padding
    >
      <access-policy-scope-selector
        :access-policy-scope="selectedScope"
        :disabled="disabled"
        :button-label="$t('general.add')"
        hide-type-selector
        :excluded-users="excludedUsers"
        @submit="scopeSubmitted"
      />
    </m-dialog>
  </div>
</template>

<script>
import AccessPolicyScopeSelector from '@/components/AccessPolicyScopeSelector.vue';
import ScopeFilter from '@/components/filter/ScopeFilter.vue';
import UserDisplay from 'shared/components/UserDisplay.vue';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useProperties from '@/composables/property/property';
import { DateTime } from 'luxon';
import { accessPolicyType } from 'shared/constants.json';
import { copy } from 'shared/lib/copy';
import { createProp } from '@/lib/props';
import { getDirectUsersFromAccessPolicy } from '@/lib/access-policy';
import { guid } from 'shared/lib/uuid';
import { accessPolicyScope as scopeConfig, user as userConfig } from 'shared/api/query/configs.json';
import { uniqBy } from 'lodash-es';

const REMOVE = 'remove';

export default {
  name: 'AccessPolicyForAccessGroups',
  props: {
    value: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { userProperties } = useProperties();
    const { loggedInUser, userLang } = useLoggedInUser();
    const { loggedInUserAccount } = useLoggedInUserAccount();
    return { userProperties, loggedInUser, userLang, account: loggedInUserAccount };
  },
  emits: ['input', 'update:value'],
  components: { UserDisplay, ScopeFilter, AccessPolicyScopeSelector },
  data() {
    return {
      showModal: false,
      selectedScope: null,
    };
  },
  computed: {
    excludedUsers() {
      return getDirectUsersFromAccessPolicy(this.value);
    },
    userProps() {
      return this.userProperties.map((p) => createProp(p, false, true, true, this.userLang, userConfig.model));
    },
    sortedScopes() {
      return copy(this.value.scopes).filter((s) => !s.deletedAt).sort((a, b) => a.users.length - b.users.length);
    },
  },
  methods: {
    showScopeSelector(scope) {
      if (scope !== null && (scope.scope === null || scope.users.length > 0)) {
        return;
      }
      this.selectedScope = scope;
      this.showModal = true;
    },
    scopeSubmitted(scope) {
      this.showModal = false;
      this.updateScopes(this.createSingleScopesPerUser(scope));
    },
    createSingleScopesPerUser(scope) {
      if (scope.users.length <= 1) {
        return [scope];
      }
      const singleScopes = scope.users.map((user) => ({
        [scopeConfig.edges.accessType]: scope[scopeConfig.edges.accessType],
        [scopeConfig.edges.users]: [user],
        [scopeConfig.edges.scope]: null,
      }));
      if (typeof scope.uid === 'undefined') {
        return singleScopes;
      }
      const deletedAt = DateTime.local().toISO();
      singleScopes.push({ ...scope, deletedAt });

      return singleScopes;
    },
    scopeTypeChanged(scope, newType) {
      const newScope = copy(scope);
      newScope.accessType = newType;

      if (newType === REMOVE) {
        newScope.accessType = accessPolicyType.disabled;
        this.removeScope(newScope);
        return;
      }

      this.updateScopes([newScope]);
    },
    scopeContainsUser(scope) {
      return scope.users.map((u) => u.uid).includes(this.loggedInUser.uid);
    },
    confirmRemoveSelf(newScope) {
      const update = this.updateScopes;
      this.$confirm({
        title: this.$t('accessPolicyForAccessGroups.confirmRemoveSelfText'),
        okText: this.$t('general.remove'),
        okType: 'danger',
        maskClosable: true,
        cancelText: this.$t('general.cancel'),
        onOk() {
          update(newScope);
        },
      });
    },
    removeScope(scope) {
      const deletedAt = DateTime.local().toISO();
      const scopesToDelete = [{ ...scope, deletedAt }];

      if (!this.scopeContainsUser(scope)) {
        this.updateScopes(scopesToDelete);
        return;
      }
      this.confirmRemoveSelf(scopesToDelete);
    },
    setKeyOnScope(scope) {
      let key = guid();
      if (typeof scope.key !== 'undefined') {
        key = scope.key;
      }
      if (typeof scope.uid !== 'undefined') {
        key = scope.uid;
      }
      return { ...scope, key };
    },
    updateScopes(newScopes) {
      let scopes = this.value.scopes.map((s) => this.setKeyOnScope(s));
      const newSc = newScopes.map((s) => this.setKeyOnScope(s));
      scopes = uniqBy([...newSc, ...scopes], 'key');
      const updated = {
        ...this.value,
        scopes,
      };
      this.$emit('input', updated);
      this.$emit('update:value', updated);
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .access-policy-for-access-groups {
    ._title {
      font-weight: $font-weight-semibold;
    }

    ._subtitle {
      font-size: $font-size-2;
      color: $font-color-secondary;
    }

    ._list-item {
      display: flex;
      flex: 1 1 auto;
      flex-wrap: wrap;
      align-items: center;

      &.-scope {
        min-height: 5.4rem;

        &:hover {
          cursor: pointer;
          background-color: $hover-color;
        }
      }

      ._left {
        margin-right: 1.2rem;
      }

      ._middle {
        flex: 1 1 0;
      }

      ._right {
        margin-left: auto;
      }
    }
  }
</style>
