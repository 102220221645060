import { UID } from 'shared/api/query/constants';
import { notificationSetting as notificationSettingConfig } from 'shared/api/query/configs.json';

export const notificationSettingEdges = [
  { attr: UID },
  { attr: notificationSettingConfig.edges.slack, default: false },
  { attr: notificationSettingConfig.edges.microsoft, default: false },
  { attr: notificationSettingConfig.edges.email, default: false },
  { attr: notificationSettingConfig.edges.suppressCheckinReminderNotifications, default: false },
  { attr: notificationSettingConfig.edges.suppressDirectMessageNotifications, default: false },
  { attr: notificationSettingConfig.edges.suppressGoalReferenceNotifications, default: false },
  { attr: notificationSettingConfig.edges.suppressGoalSubscriptionNotifications, default: false },
];
