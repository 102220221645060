import { EventBus } from '@/lib/event-bus';
import { useI18n } from 'vue-i18n';

export default function useConfirmDialog() {
  const { t } = useI18n();
  const confirm = (options) => {
    const defaultOptions = {
      title: t('general.deletePrompt'),
      okText: t('general.yesDelete'),
      okType: 'danger',
      cancelText: t('general.cancel'),
      maskClosable: true,
    };
    EventBus.$emit('show-confirm', { ...defaultOptions, ...options });
  };

  const hideConfirm = () => {
    EventBus.$emit('hide-confirm');
  };

  return {
    confirm,
    hideConfirm,
  };
}
