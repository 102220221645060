<template>
  <div :class="['scroll-container', props.noPaddingBottom ? '':'-padding-bottom']">
    <slot />
  </div>
</template>

<script setup>
const props = defineProps({
  noPaddingBottom: {
    type: Boolean,
    default: false,
  },
});
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  .scroll-container {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    overflow: auto;

    &.-padding-bottom {
      padding-bottom: 30vh;
    }
  }
</style>
