import '@/registerServiceWorker';
import App from '@/App.vue';
import Highcharts from 'highcharts';
import HighchartsBorderRadius from 'highcharts-border-radius';
import HighchartsNoData from 'highcharts-no-data-to-display';
import LoadScript from '@/plugins/vue-plugin-load-script';
import VueClipboard from 'vue3-clipboard';
import custifyClient from '@/plugins/custify';
import eventPipeline from '@/plugins/event-pipeline';
import i18n from '@/lang';
import router from '@/router';
import store from '@/store/index';
import { createApp } from 'vue';
import { init } from 'shared/components/base/_base';
import { initAppMixins } from '@/plugins/app-mixins';
import { initDatadog } from '@/plugins/datadog';
import { initRecursiveComponents } from '@/plugins/recursive-components';
import { initVortex } from '@/plugins/vortex';
import { recordRequests } from '@/composables/subscription/subscription';

initDatadog(store.state.foreignLogin);
initVortex();

HighchartsBorderRadius(Highcharts);
HighchartsNoData(Highcharts);

const app = createApp(App);
app.use(LoadScript);
app.use(router);
initAppMixins(app, i18n);
init(app);
initRecursiveComponents(app);
app.use(eventPipeline);
app.use(store);
app.use(i18n);
app.use(VueClipboard, { autoSetContainer: true, appendToBody: true });
app.use(custifyClient, { isForeignLogin: store.state.foreignLogin });
router.isReady().then(() => app.mount('#app'));

recordRequests();
