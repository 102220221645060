<template>
  <div class="single-slide">
    <h4>
      {{ title }}
    </h4>
    <div
      :style="{ minHeight: '6.3rem', marginBottom: '1.2rem' }"
    >
      <div v-html="description" />
      <m-link
        v-if="link !== null"
        :href="link.href"
        :target="link.target"
        inherit-color
        background-on-hover
        :style="{ color: $colors.grey.darken1, padding: '.4rem' }"
      >
        {{ link.text }}
      </m-link>
    </div>
    <div
      :style="{ height: '48rem', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }"
      class="_media-wrapper"
    >
      <img
        v-if="media.type === 'image'"
        class="_media"
        :src="media.src"
        :alt="media.alt"
        :style="media.style"
      >
      <video
        v-else-if="media.type === 'video'"
        class="_media"
        :src="media.src"
        autoplay
        muted
        loop
        playsinline
        preload="auto"
        :style="media.style"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SingleSlide',
  props: {
    media: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: '',
    },
    link: {
      type: Object,
      default: () => null,
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  @import "shared/assets/scss/box-shadow";

  .single-slide {
    ._media-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 48rem;
      border-radius: $default-border-radius;

      @include box_shadow(1);

      ._media {
        max-width: 100%;
        height: 100%;
      }
    }

    ul {
      padding-inline-start: 2rem;
    }
  }
</style>
