<template>
  <m-alert
    class="allowance-alert"
    type="warning"
    icon="upgrade-plan"
    hide-border
    :style="{ marginBottom: 0 }"
  >
    <div class="_message">
      <i18n-t
        tag="div"
        class="_text"
        :keypath="mainMessageKey"
      >
        <template #pagesCount>
          {{ props.pagesCount }}
        </template>
        <template #pagesAllowance>
          {{ props.pagesAllowance }}
        </template>
      </i18n-t>
      <div class="_subtext">
        {{ $t('allowanceAlert.subMessage') }}
      </div>
    </div>
    <m-btn
      v-if="canUpgradePlan"
      color="secondary"
      @click="callCustomDashboardsFeatureGate"
    >
      {{ $t('allowanceAlert.action') }}
    </m-btn>
  </m-alert>
</template>

<script type="text/javascript" setup>
import useAccountSettings from '@/composables/logged-in-user-account/account-settings';
import { computed } from 'vue';
import { featureFlag } from 'shared/constants.json';
import { nextPlan as nextPlanFn } from 'shared/lib/plans';
import { showFeatureGate } from '@/lib/feature-gate';

const props = defineProps({
  pagesCount: {
    type: Number,
    required: true,
  },
  pagesAllowance: {
    type: Number,
    required: true,
  },
  mainMessageKey: {
    type: String,
    required: true,
  },
});

const { accountSettings } = useAccountSettings();
const canUpgradePlan = computed(() => {
  const nextPlan = nextPlanFn(accountSettings.value.planId);
  return nextPlan !== undefined;
});
const callCustomDashboardsFeatureGate = () => {
  showFeatureGate(featureFlag.customDashboards, true);
};
</script>

<style scoped
  lang="scss"
  type="text/scss"
>
.allowance-alert {
  ._message {
    padding-right: 2.4rem;
    margin-right: auto;
    white-space: pre-wrap;
    display: flex;
    flex-direction: column;

    ._text {
      font-weight: $font-weight-bold;
    }

    ._subtext {
      white-space: pre-wrap;
    }
  }
}
</style>
