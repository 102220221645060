<template>
  <div class="questions-editor">
    <m-sider
      collapsed
      class="_nav"
      bordered
    >
      <icon-nav-items
        :items="sideNavItems"
        show-tooltip
      />
    </m-sider>
    <template v-if="editMode">
      <div
        class="_questions"
      >
        <form-field-list
          :entity="entity"
          :selected-question="selectedQuestion"
          :lang="lang"
          :welcome-screen-selected="welcomeScreenSelected"
          :languages="languages"
          :model="model"
          :disabled="disabled"
          @selected="selected"
          @open-settings="openSettings"
          @field-added="addField"
          @welcome-screen-added="addWelcomeScreen"
          @welcome-screen-selected="selectWelcomeScreen"
        />
      </div>
      <div
        v-if="selectedQuestion !== null && settingsOpen"
        class="_settings"
      >
        <form-field-settings
          :item="selectedQuestion"
          :field-index="selectedQuestionIndex"
          :model="model"
          :lang="lang"
          :disabled="disabled"
          @close="close"
        />
      </div>
    </template>
    <form-settings
      v-else-if="languageSettings"
      :entity="entity"
      :entity-by-id="entityById"
      :create-url="createUrl"
      :languages="languages"
      :model="model"
      :disabled="disabled"
      class="_form-settings"
    />
    <questions-bank
      v-else-if="questionsBank"
      class="_questions-bank"
      :entity="entity"
      :entity-by-id="entityById"
      :create-url="createUrl"
      :all-questions-url="allQuestionsUrl"
      :template-list-url="templateListUrl"
      :template-type="templateType"
      :lang="lang"
      :model="model"
      :disabled="disabled"
      :question-bank-template-detail-url="questionBankTemplateDetailUrl"
      @selected="selected"
      @welcome-screen-selected="selectWelcomeScreen"
    />
    <form-tags
      v-else-if="tags"
      class="_tags"
      :model="model"
      :disabled="disabled"
      :entity="entity"
      :entity-by-id="entityById"
      :create-url="createUrl"
    />
    <field-factors
      v-else-if="templateType !== formTemplateType.instantFeedback"
      :model="model"
      :disabled="disabled"
      :entity="entity"
      :entity-by-id="entityById"
      :lang="lang"
      :languages="languages"
    />
  </div>
</template>

<script>
import FieldFactors from '@/components/FieldFactors.vue';
import FormFieldList from '@/components/FormFieldList.vue';
import FormFieldSettings from '@/components/FormFieldSettings.vue';
import FormSettings from '@/components/FormSettings.vue';
import FormTags from '@/components/FormTags.vue';
import IconNavItems from '@/components/navigation/IconNavItems.vue';
import MSider from '@/components/layout/MSider.vue';
import QuestionsBank from '@/components/survey/QuestionsBank.vue';
import { QUESTION_BANK_TEMPLATE_DETAILS } from '@/route-names';
import { formTemplateType, routeName } from 'shared/constants.json';

export default {
  name: 'QuestionsEditor',
  props: {
    disabled: {
      type: Boolean,
      required: true,
    },
    entity: {
      type: Object,
      required: true,
    },
    sideNavItems: {
      type: Array,
      required: true,
    },
    selectedQuestionIndex: {
      type: Number,
      required: true,
    },
    selectedQuestion: {
      type: Object,
      default: null,
    },
    welcomeScreenSelected: {
      type: Boolean,
      required: true,
    },
    settingsOpen: {
      type: Boolean,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
    languages: {
      type: Array,
      required: true,
    },
    entityById: {
      type: Function,
      required: true,
    },
    editPage: {
      type: String,
      required: true,
    },
    model: {
      type: String,
      required: true,
    },
    settingsPage: {
      type: String,
      required: true,
    },
    createUrl: {
      type: Object,
      required: true,
    },
    allQuestionsUrl: {
      type: Object,
      required: true,
    },
    templateListUrl: {
      type: Object,
      required: true,
    },
    questionBankTemplateDetailUrl: {
      type: Function,
      required: true,
    },
  },
  emits: ['selected', 'open-settings', 'field-added', 'welcome-screen-added', 'welcome-screen-selected'],
  components: { IconNavItems, MSider, QuestionsBank, FormSettings, FormFieldList, FormFieldSettings, FieldFactors, FormTags },
  data() {
    return { formTemplateType };
  },
  computed: {
    templateType() {
      if (this.entity.type === formTemplateType.instantFeedback) {
        return formTemplateType.instantFeedback;
      }

      return formTemplateType.survey;
    },
    editMode() {
      return this.editPage === this.$route.name;
    },
    languageSettings() {
      return this.$route.name === this.settingsPage;
    },
    questionsBank() {
      return this.$route.name === this.allQuestionsUrl.name
        || this.$route.name === this.templateListUrl.name
        || this.$route.name === QUESTION_BANK_TEMPLATE_DETAILS
        || this.$route.name === routeName.questionBankTemplateDetailsFormTemplates;
    },
    tags() {
      return this.$route.name === routeName.formTemplateTags;
    },
  },
  methods: {
    selected({ fieldIndex }) {
      this.$emit('selected', { fieldIndex });
    },
    openSettings({ fieldIndex }) {
      this.$emit('open-settings', { fieldIndex });
    },
    close() {
      this.$emit('selected', { fieldIndex: -1 });
    },
    addField(field) {
      this.$emit('field-added', field);
    },
    addWelcomeScreen(welcomeScreen) {
      this.$emit('welcome-screen-added', welcomeScreen);
    },
    selectWelcomeScreen() {
      this.$emit('welcome-screen-selected');
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .questions-editor {
    display: flex;
    height: 100%;

    ._nav {
      padding-top: 1.2rem;
    }

    ._questions {
      display: flex;
      flex: 1 1 55%;
      height: calc(100vh - #{$top-bar-height});
      overflow: auto;
      background-color: white;
      border-right: .1rem solid $border-color;
    }

    ._form-settings {
      border-right: .1rem solid $border-color;
    }

    .questions-bank {
      height: calc(100vh - #{$top-bar-height});
      overflow: auto;
      border-right: .1rem solid $border-color;
    }

    ._settings {
      display: flex;
      flex: 1 1 45%;
      border-right: .1rem solid $border-color;
    }

    ._tags {
      flex: 1 1 45%;
      border-right: .1rem solid $border-color;
    }
  }
</style>
