<template>
  <m-dropdown
    :title="$t('timeRangeSelector.title')"
    class="time-range-selector"
  >
    <m-btn
      small
      hide-border
      class="_btn"
      light
    >
      {{ buttonText }}
    </m-btn>
    <template #overlay>
      <m-card

        no-padding
        class="time-range-selector _overlay"
        list
      >
        <div class="time-range-selector _items">
          <template
            v-for="item in items"
            :key="item.key"
          >
            <m-card-item
              clickable
              :active="isSelected(item)"
              :no-hover="false"
              @click="select(item)"
            >
              {{ item.text }}
            </m-card-item>
          </template>
          <m-dropdown
            v-model:value="customVisible"
            :title="$t('timeRangeSelector.customTitle')"
            block
          >
            <m-card-item
              clickable
              :no-hover="false"
              @click="customVisible = true"
            >
              {{ $t('timeRangeSelector.custom') }}
            </m-card-item>
            <template #overlay>
              <m-card

                no-padding
              >
                <m-content
                  padding-xs
                  class="time-range-selector _range-card"
                >
                  <m-form-item
                    :validate-status="rangeStatus"
                    class="_picker"
                  >
                    <m-date-picker
                      v-model:value="customValue"
                      full-width
                      :date-time="DateTime"
                      range
                    />
                  </m-form-item>
                  <m-btn
                    block
                    color="primary"
                    @click="addCustom()"
                  >
                    {{ $t('general.apply') }}
                  </m-btn>
                </m-content>
              </m-card>
            </template>
          </m-dropdown>
        </div>
      </m-card>
    </template>
  </m-dropdown>
</template>

<script>
import { DateTime } from 'luxon';
import { guid } from 'shared/lib/uuid';

export default {
  name: 'TimeRangeSelector',
  props: {
    value: {
      type: Object,
      default: () => null,
    },
  },
  emits: ['change'],
  data() {
    return {
      DateTime,
      customVisible: false,
      selected: null,
      rangeStatus: '',
      customValue: {
        startDate: null,
        endDate: null,
      },
      items: [
        {
          key: guid(),
          text: this.$t('timeRangeSelector.thisMonth'),
          range: {
            start: this.toOutputFormat(this.endOfToday().startOf('month')),
            end: this.toOutputFormat(this.endOfToday()),
          },
        },
        {
          key: guid(),
          text: this.$t('timeRangeSelector.lastMonth'),
          range: {
            start: this.toOutputFormat(this.endOfToday().plus({ months: -1 }).startOf('month')),
            end: this.toOutputFormat(this.endOfToday().plus({ months: -1 }).endOf('month')),
          },
        },
        {
          key: guid(),
          text: this.$t('timeRangeSelector.lastXMonth', { value: 3 }),
          range: {
            start: this.toOutputFormat(this.endOfToday().plus({ months: -2 }).startOf('month')),
            end: this.toOutputFormat(this.endOfToday()),
          },
        },
        {
          key: guid(),
          text: this.$t('timeRangeSelector.lastXMonth', { value: 6 }),
          range: {
            start: this.toOutputFormat(this.endOfToday().plus({ months: -5 }).startOf('month')),
            end: this.toOutputFormat(this.endOfToday()),
          },
        },
        {
          key: guid(),
          text: this.$t('timeRangeSelector.lastXMonth', { value: 12 }),
          range: {
            start: this.toOutputFormat(this.endOfToday().plus({ months: -11 }).startOf('month')),
            end: this.toOutputFormat(this.endOfToday()),
          },
        },
      ],
    };
  },
  computed: {
    buttonText() {
      if (this.selected === null) {
        return '';
      }
      return this.selected.text;
    },
  },
  methods: {
    endOfToday() {
      return DateTime.local().endOf('day');
    },
    toOutputFormat(dateTime) {
      return dateTime.startOf('second').toISO({ suppressMilliseconds: true });
    },
    addCustom() {
      if (this.customValue.startDate === null || this.customValue.endDate === null) {
        this.rangeStatus = 'error';
        return;
      }
      this.rangeStatus = '';
      const newItem = {
        key: guid(),
        text: this.rangeToText({ start: this.customValue.startDate, end: this.customValue.endDate }),
        range: {
          start: DateTime.fromISO(this.customValue.startDate).toISO(),
          end: DateTime.fromISO(this.customValue.endDate).toISO(),
        },
      };
      this.items.push(newItem);
      this.customVisible = false;
      this.selected = newItem;
      this.emitChange(newItem);
    },
    isSelected(item) {
      return this.selected !== null && this.selected.key === item.key;
    },
    rangeToText(range) {
      const start = DateTime.fromISO(range.start).toLocaleString(DateTime.DATE_MED);
      const end = DateTime.fromISO(range.end).toLocaleString(DateTime.DATE_MED);
      return `${start} - ${end}`;
    },
    select(item) {
      this.selected = item;
      this.emitChange(item);
    },
    emitChange(item) {
      this.$emit('change', item.range);
    },
    init() {
      if (this.value === null) {
        this.selected = this.items[0];
        return;
      }
      const filtered = this.items.filter((i) => i.range.start === this.value.start && i.range.end === this.value.end);
      if (filtered.length > 0) {
        this.selected = filtered[0];
        return;
      }
      const newItem = {
        key: guid(),
        text: this.rangeToText(this.value),
        range: this.value,
      };
      this.items.push(newItem);
      this.selected = newItem;
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .time-range-selector {
    &._range-card {
      width: 25.2rem;

      ._picker {
        margin-bottom: .8rem;
      }
    }

    &._overlay {
      min-width: 14rem;
    }
  }

</style>
