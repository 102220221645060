<template>
  <div
    class="access-policy-type-selector"
  >
    <m-dropdown
      v-model:value="show"
      :title="$t('accessPolicyTypeSelector.title')"
      :trigger="['click']"
      :disabled="disabled"
    >
      <m-btn
        :class="['_button']"
        hide-border
        :disabled="disabled"
      >
        <div class="_text">
          {{ selected.text }}
        </div>
        <m-icon
          type="caret-down"
          size="11"
          class="_icon"
        />
      </m-btn>
      <template #overlay>
        <m-card

          no-padding
          class="_overlay"
        >
          <div class="_items">
            <div
              v-for="item in options"
              :key="item.text"
              class="_item"
              @click="select(item)"
            >
              <div :class="['_text', item.class]">
                {{ item.text }}
              </div>
              <div class="_description">
                {{ item.description }}
              </div>
            </div>
          </div>
        </m-card>
      </template>
    </m-dropdown>
  </div>
</template>

<script>
import { accessPolicyType } from 'shared/constants.json';

const REMOVE = 'remove';

export default {
  name: 'AccessPolicyTypeSelector',
  props: {
    value: {
      type: String,
      default: '',
    },
    showRemove: {
      type: Boolean,
      default: false,
    },
    showDisable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    accessTypes: {
      type: Array,
      default: () => [accessPolicyType.read, accessPolicyType.write, accessPolicyType.full],
    },
  },
  emits: ['input', 'update:value', 'change'],
  components: {},
  data() {
    return {
      selected: '',
      show: false,
    };
  },
  computed: {
    options() {
      const res = [
        {
          value: 'full',
          text: this.$t('accessPolicyTypeSelector.full'),
          description: this.$t('accessPolicyTypeSelector.fullDescription'),
        },
        {
          value: 'write',
          text: this.$t('accessPolicyTypeSelector.write'),
          description: this.$t('accessPolicyTypeSelector.writeDescription'),
        },
        {
          value: 'comment',
          text: this.$t('accessPolicyTypeSelector.comment'),
          description: this.$t('accessPolicyTypeSelector.commentDescription'),
        },
        {
          value: 'read',
          text: this.$t('accessPolicyTypeSelector.read'),
          description: this.$t('accessPolicyTypeSelector.readDescription'),
        },
      ].filter((v) => this.accessTypes.includes(v.value));
      if (this.showDisable) {
        res.push({
          value: 'disabled',
          text: this.$t('accessPolicyTypeSelector.disabled'),
          class: '-danger',
        });
      }
      if (this.showRemove) {
        res.push({
          value: REMOVE,
          text: this.$t('accessPolicyTypeSelector.remove'),
          class: '-danger',
        });
      }
      return res;
    },
  },
  methods: {
    select(item) {
      this.show = false;
      this.$emit('change', item.value);
      this.$emit('input', item.value);
      this.$emit('update:value', item.value);
    },
    init(value) {
      const f = this.options.filter((o) => o.value === value);
      if (f.length === 0) {
        this.selected = this.options[0];
        return;
      }
      this.selected = f[0];
    },
  },
  watch: {
    value(newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      }

      this.init(newVal);
    },
  },
  created() {
    if (this.value === '') {
      this.selected = this.options[0];
      return;
    }
    this.init(this.value);
  },
};
</script>

<style scoped lang="scss" type="text/scss">

  .access-policy-type-selector {
    ._button {
      display: flex;
      align-items: center;

      ._text {
        margin-right: .8rem;
      }

      ._icon {
        margin-top: .2rem;
        color: $font-color-tertiary;
      }
    }
  }

  ._overlay {
    min-width: 24rem;

    ._items {
      padding: .4rem 0;

      ._item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: .4rem 1rem;
        cursor: pointer;

        &:hover {
          background-color: $hover-color;
        }

        ._text {
          &.-danger {
            color: $error-color;
          }
        }

        ._description {
          font-size: $font-size-2;
          color: $font-color-secondary;
        }
      }
    }
  }
</style>
