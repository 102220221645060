import { DEFAULT_ATTRIBUTES, ONE_TO_ONE, UID } from 'shared/api/query/constants';
import { property as propertyConfig,
  propertyOption as propertyOptionConfig,
  propertyValue as propertyValueConfig,
  space as spaceConfig,
  user as userConfig } from 'shared/api/query/configs.json';

export const propertyValueEdges = [
  ...DEFAULT_ATTRIBUTES,
  { attr: propertyValueConfig.edges.number, default: null },
  { attr: propertyValueConfig.edges.timestamp, default: null },
  { attr: propertyValueConfig.edges.text, default: '' },
  {
    attr: propertyValueConfig.edges.selectedOptions,
    model: propertyOptionConfig.model,
    default: [],
    children: [{ attr: UID }],
  },
  {
    attr: propertyValueConfig.edges.spaces,
    model: spaceConfig.model,
    default: [],
    children: [{ attr: UID }],
  },
  {
    attr: propertyValueConfig.edges.property,
    model: propertyConfig.model,
    assoc: ONE_TO_ONE,
    children: [{ attr: UID }],
  },
  {
    attr: propertyValueConfig.edges.users,
    model: userConfig.model,
    default: [],
    order: [{ attr: userConfig.edges.firstName }, { attr: userConfig.edges.lastName }],
    children: [{ attr: UID }],
  },
];
