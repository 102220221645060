<template>
  <m-card
    class="status-option-editor"
    list
  >
    <m-content padding-xs>
      <m-text-field
        :value="label"
        has-background
        auto-focus
        auto-select
        @input="updateLabel"
      />
    </m-content>
    <m-card-item
      v-if="!isCreate"
      icon="delete"
      :disabled="isDefault"
      :tooltip="archiveTooltip"
      :loading="archiveLoading"
      @click="handleArchive"
    >
      {{ $t('general.archive') }}
    </m-card-item>
    <m-card-item
      v-if="!isCreate"
      :disabled="isDefault"
      icon="flag"
      :tooltip="$t('goalStatusSettings.defaultFlagToolTip')"
      tooltip-placement="left"
      :loading="setAsDefaultLoading"
      @click="setAsDefault"
    >
      {{ $t('statusOptionEditor.setAsDefault') }}
    </m-card-item>
    <m-dropdown
      class="_group"
      :disabled="isCreate"
      :title="$t('statusOptionEditor.group')"
      block
      close-on-click
      placement="topRight"
    >
      <m-card-item
        icon="check-square"
        :disabled="isCreate"
      >
        {{ $t('statusOptionEditor.group') }}
        <template #right>
          <div :class="['_right', isCreate ? '-disabled' : '']">
            {{ selectedGroup.label }}
            <m-icon
              v-if="!isCreate"
              :style="{ marginLeft: '.6rem' }"
              type="right"
              size="11"
            />
          </div>
        </template>
      </m-card-item>
      <template #overlay>
        <m-card list>
          <m-card-item
            v-for="g in groups"
            :key="g.uid"
            @click="updateGroup(g)"
          >
            {{ g.label }}
          </m-card-item>
        </m-card>
      </template>
    </m-dropdown>
    <m-dropdown
      :title="$t('statusOptionEditor.score')"
      block
      placement="topRight"
    >
      <m-card-item
        icon="above"
        :tooltip="$t('goalStatusSettings.scoreTooltip')"
        tooltip-placement="left"
      >
        {{ $t('statusOptionEditor.score') }}
        <template #right>
          <div :style="{ color: $colors.grey.base, display: 'flex' }">
            <m-tag
              v-if="score === null"
              :title="$t('general.empty')"
              :custom-color="{ color: $colors.grey.lighten1, backgroundColor: 'transparent'}"
            />
            <template v-else>
              {{ score }} %
            </template>
            <m-icon
              :style="{ marginLeft: '.6rem' }"
              type="right"
              size="11"
            />
          </div>
        </template>
      </m-card-item>
      <template #overlay>
        <m-card padding-xxs>
          <m-input-number
            has-background
            nullable
            :min="0"
            :max="100"
            :value="score"
            @input="updateScore"
          />
        </m-card>
      </template>
    </m-dropdown>
    <sub-heading :title="$t('statusOptionEditor.color')" />
    <color-list
      :items="colors"
      :value="color"
      @select="updateColor"
    />
    <m-divider xs />
    <m-content padding-xs>
      <m-btn
        block
        color="primary"
        :disabled="!dirty"
        :loading="submitLoading"
        @click="submit"
      >
        {{ $t('general.save') }}
      </m-btn>
    </m-content>
  </m-card>
</template>

<script setup>
import ColorList from '@/components/ColorList.vue';
import SubHeading from 'shared/components/SubHeading.vue';
import useConfirmDialog from '@/composables/confirm-dialog';
import useProperties from '@/composables/property/property';
import usePropertyOptions from '@/composables/property-option/property-option';
import useSnackBar from '@/composables/snackbar';
import { DateTime } from 'luxon';
import { EventBus } from '@/lib/event-bus';
import { computed, onBeforeUnmount, ref } from 'vue';
import { getColor } from 'shared/lib/color-map';
import { optionColor } from 'shared/constants.json';
import { useI18n } from 'vue-i18n';

const i18n = useI18n();

const dirty = ref(false);

const props = defineProps({
  option: {
    type: Object,
    default: () => ({ label: { en: '' }, score: null }),
  },
  group: {
    type: Object,
    required: true,
  },
  groups: {
    type: Array,
    required: true,
  },
  isDefault: {
    type: Boolean,
    default: false,
  },
  statusProperty: {
    type: Object,
    required: true,
  },
});
const emit = defineEmits(['close']);

const propertyOptionSvc = usePropertyOptions();
const propertySvc = useProperties();
const snackbar = useSnackBar();

const colors = optionColor.all.map((c) => ({
  text: i18n.t(`colorPicker.${c}`),
  value: c,
  color: getColor(c),
}));

const setAsDefaultLoading = ref(false);
const setAsDefault = () => {
  if (props.isDefault) {
    return;
  }
  setAsDefaultLoading.value = true;
  propertySvc.updateSingle({ uid: props.statusProperty.uid, defaultPropertyOptions: [{ uid: props.option.uid }] }).then(() => {
    snackbar.success(i18n.t('success.saved'));
  }).finally(() => {
    setAsDefaultLoading.value = false;
  });
};

const archiveTooltip = computed(() => {
  if (!props.isDefault) {
    return '';
  }
  return i18n.t('statusOptionEditor.cannotArchiveDefaultOption');
});

const archiveLoading = ref(false);
const handleArchive = () => {
  archiveLoading.value = true;
  propertyOptionSvc.updateSingle({ uid: props.option.uid, archivedAt: DateTime.local().toISO() }).then(() => {
    snackbar.success(i18n.t('success.archived'));
  }).finally(() => {
    archiveLoading.value = false;
    emit('close');
  });
};

const label = ref(props.option.label.en);
const updateLabel = (value) => {
  dirty.value = true;
  label.value = value;
};

const selectedGroup = ref(props.group);

const updateGroup = (g) => {
  dirty.value = true;
  selectedGroup.value = g;
};

const score = ref(null);
if (props.option.score !== null) {
  score.value = props.option.score * 10;
}
const updateScore = (value) => {
  dirty.value = true;
  if (value === null) {
    score.value = value;
    return;
  }
  score.value = Math.round(value);
};

const color = ref(props.option.color);
const updateColor = (v) => {
  dirty.value = true;
  color.value = v.value;
};

const isCreate = computed(() => props.option.uid === undefined);

const submitLoading = ref(false);
const submit = () => {
  submitLoading.value = true;

  const entity = {
    label: { de: label.value, en: label.value },
    color: color.value,
    score: score.value === null ? null : score.value / 10,
    parents: [{ uid: selectedGroup.value.uid }],
    property: { uid: props.statusProperty.uid },
  };

  const successHandler = (propertyOption) => {
    snackbar.success(i18n.t('success.saved'));
    emit('close', propertyOption);
  };
  const finallyHandler = () => {
    submitLoading.value = false;
  };

  if (isCreate.value) {
    propertyOptionSvc.createSingle(entity).then(successHandler).finally(finallyHandler);
    return;
  }
  propertyOptionSvc.updateSingle({ ...entity, uid: props.option.uid }).then(successHandler).finally(finallyHandler);
};

const confirmDialog = useConfirmDialog();
const hide = () => {
  if (!dirty.value) {
    emit('close');
    return;
  }

  confirmDialog.confirm({
    title: i18n.t('general.discardEditPrompt'),
    okText: i18n.t('general.yesDiscard'),
    cancelText: i18n.t('general.cancel'),
    onOk() {
      emit('close');
    },
    onCancel: confirmDialog.hideConfirm(),
  });
};

defineExpose({ hide });

EventBus.$on('close-status-editor', hide);

onBeforeUnmount(() => {
  EventBus.$off('close-status-editor', hide);
});
</script>

<style scoped lang="scss">
.status-option-editor {
  min-width: 25rem;

  ._group {
    ._right {
      display: flex;
      color: $font-color-secondary;

      &.-disabled {
        color: $font-color-disabled;
        padding-right: 2rem;
      }
    }
  }
}
</style>
