import { intersection } from 'shared/lib/array/array';
import { sortByArray } from 'shared/lib/sort';

export default function useSimpleGoalTree() {
  /**
   * @property {Array} goals
   *
   * getTree calculates a sorted list of goals, but
   * doesn't assume any order of root elements.
   */
  const getTree = (goals) => {
    const ids = goals.map((g) => g.uid);
    const parentGoals = goals.filter((g) => {
      if (g.parents === undefined || g.parents.length === 0) {
        return true;
      }
      const parentIds = g.parents.map((p) => p.uid);
      return intersection(ids, parentIds).length === 0;
    });
    const reducer = (r, n) => {
      const g = goals.find((g) => g.uid === n.uid);
      if (g === undefined) {
        return r;
      }
      r.push(g);
      if (g.children === undefined) {
        return r;
      }
      const children = g.children.filter((c) => goals.find((g) => g.uid === c.uid));
      children.sort(sortByArray(g.childrenSort));
      if (children.length === 0) {
        return r;
      }
      r.push(...children.reduce(reducer, []));
      return r;
    };
    return parentGoals.reduce(reducer, []);
  };

  return { getTree };
}
