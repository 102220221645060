<template>
  <div class="space-navigation">
    <m-content
      :padding-x="8"
      :padding-y="9"
    >
      <div class="_search">
        <m-text-field
          v-model:value="search"
          class="_input-field"
          auto-focus
          :placeholder="$t('spaceNavigation.searchPlaceholder', { title })"
          has-background
          :input-style="{ backgroundColor: $colors.grey.lighten8 }"
        />
      </div>
    </m-content>
    <m-divider none />
    <div class="_items">
      <router-link
        key="company"
        :to="company.to"
        :class="['_company-item', company.title.toLowerCase().indexOf(search.toLowerCase()) === -1 ? '-light' : '']"
        data-id="company"
      >
        <div class="_left">
          <div class="_expandable">
            <m-btn
              :icon="expanded ? 'down' : 'right'"
              fab
              xs
              hide-border
              :icon-color="$colors.grey.base"
              @click.prevent="expanded = !expanded"
            />
          </div>
          <div class="_avatar">
            <m-avatar
              :src="company.src"
              :text="company.title"
              :shape="company.avatarShape"
            />
          </div>
          <div class="_title">
            {{ company.title }}
          </div>
        </div>
        <div class="_right">
          <m-tooltip :mouse-enter-delay=".3">
            <span>
              <m-btn
                :icon="companyIsPinned ? 'pushpin-filled' : 'pushpin'"
                hide-border
                xs
                fab
                light
                :loading="pinningLoading"
                class="_btn _pin"
                @click.prevent="pinItem"
              />
            </span>
            <template #title>
              <template v-if="companyIsPinned">
                {{ $t('spaceItem.unpinHelp', { title }) }}
              </template>
              <template v-else>
                {{ $t('spaceItem.pinHelp', { title }) }}
              </template>
            </template>
          </m-tooltip>
        </div>
      </router-link>
      <m-transition-expand
        v-for="item in spaces"
        :key="item.uid"
      >
        <space-tree-item
          v-show="expanded"
          :key="item.uid"
          :space="item"
          :indentation="1"
          :all-spaces="allSpaces"
          :property="property"
          :language="userLang"
          :pins="pins"
        />
      </m-transition-expand>
    </div>
  </div>
</template>

<script>
import SpaceTreeItem from '@/components/navigation/SpaceTreeItem.vue';
import useAccess from '@/composables/access/access';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import usePersonalAppSettings from '@/composables/logged-in-user/personal-app-settings';
import usePins from '@/composables/pin/pin';
import useProperties from '@/composables/property/property';
import useSelectedViews from '@/composables/saved-views/selected-views';
import useSpaces from '@/composables/space/spaces';
import { GOAL_VIEW } from '@/route-params';
import { findInArray } from 'shared/lib/array/array';
import { pinType, routeName, viewApplication } from 'shared/constants.json';
import { textByLang } from 'shared/lib/language';

export default {
  name: 'SpaceNavigation',
  props: {
    pins: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  emits: ['space-created'],
  setup() {
    const { getSelectedViewViewIdForApplication } = useSelectedViews();
    const { accountHasFeature, userHasRight } = useAccess();
    const { loggedInUser, userLang } = useLoggedInUser();
    const { loggedInUserAccount } = useLoggedInUserAccount();
    const { personalAppSettings } = usePersonalAppSettings(loggedInUser);
    const { createPinLoading, createPin, deletePinLoading, deletePin } = usePins();
    const { spaceProperty } = useProperties();
    const spacesSvc = useSpaces();
    return {
      getSelectedViewViewIdForApplication,
      accountHasFeature,
      userHasRight,
      account: loggedInUserAccount,
      personalAppSettings,

      createPinLoading,
      createPin,
      deletePinLoading,
      deletePin,
      property: spaceProperty,
      allSpaces: spacesSvc.spaces,
      spacesSvc,
      userLang,
    };
  },
  components: { SpaceTreeItem },
  data() {
    return {
      search: '',
      expanded: true,
      textByLang,
    };
  },
  computed: {
    companyIsPinned() {
      return this.companyPin !== null;
    },
    companyPin() {
      const filtered = this.pins.filter((p) => p.type === pinType.company);
      if (filtered.length === 0) {
        return null;
      }

      return filtered[0];
    },
    pinningLoading() {
      return this.createPinLoading || this.deletePinLoading;
    },
    companyImage() {
      if (this.account.companyImage === null) {
        return '';
      }
      return this.account.companyImage.getURL;
    },
    company() {
      return {
        to: {
          name: routeName.accountSpace,
          query: {
            view: GOAL_VIEW,
            viewId: this.getSelectedViewViewIdForApplication({ application: viewApplication.goalAccount }).value,
          },
        },
        title: this.account.companyName,
        showAvatar: true,
        active: this.$route.name === routeName.accountSpace,
        src: this.companyImage,
        avatarShape: 'square',
        id: 'company-space',
      };
    },
    spaces() {
      const mapChildren = (c) => {
        const item = findInArray({ haystack: this.allSpaces, needle: c.uid });
        if (item === null) {
          return c;
        }

        const children = this.allSpaces.filter((s) => s.parents.filter((p) => p.uid === item.uid).length > 0);
        const match = this.search === '' || item.title.toLowerCase().indexOf(this.search.toLowerCase()) > -1;

        if (children.length === 0) {
          return {
            ...item,
            match,
            hasChildrenMatches: false,
            children: [],
          };
        }

        return {
          ...item,
          match,
          children: children.map(mapChildren),
        };
      };

      const hasMatch = (res, next) => {
        if (next.match) {
          return true;
        }

        if (next.children.length === 0) {
          return res;
        }

        return next.children.reduce(hasMatch, res);
      };

      const reduceMatches = (res, next) => {
        if (next.children.length === 0 && !next.match) {
          return res;
        }

        if (next.match || next.children.reduce(hasMatch, false)) {
          res.push({
            ...next,
            children: next.children.reduce(reduceMatches, []),
          });
        }

        return res;
      };

      return this.allSpaces.filter((o) => o.parents.length === 0).map(mapChildren).reduce(reduceMatches, []);
    },
  },
  methods: {
    pinItem() {
      if (this.companyIsPinned) {
        this.deletePin(this.companyPin).catch(() => {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
        });
        return;
      }
      this.createPin({
        type: pinType.company,
        personalAppSettings: { uid: this.personalAppSettings.uid },
      }).catch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      });
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .space-navigation {
    ._disclaimer {
      position: relative;
      background-color: map_get($grey, 'lighten-6');

      ._disclaimer-inner {
        display: flex;

        ._icon {
          margin-top: .5rem;
          margin-right: 1rem;
        }

        ._text {
          width: calc(100% - 5rem);

          ._bottom {
            color: $font-color-secondary;
          }
        }

        ._close {
          position: absolute;
          top: .6rem;
          right: .6rem;
        }
      }
    }

    ._items {
      max-height: 70vh;
      padding: .4rem 0;
      overflow: auto;

      @media (max-width: $screen-size-md) {
        max-height: 100vh;
      }

      ._company-item {
        display: flex;
        align-items: center;
        height: 3.2rem;
        padding: 0 1.1rem;
        color: $font-color-primary;

        &:hover {
          background-color: $hover-color;
        }

        &.-light {
          opacity: .4;
        }

        ._left {
          display: flex;
          align-items: center;

          ._expandable {
            margin-right: .6rem;
          }

          ._avatar {
            margin-right: .6rem;
          }
        }

        ._right {
          display: flex;
          margin-left: auto;

          ._action {
            margin-right: .6rem;
          }
        }
      }
    }
  }
</style>
