export const getQueryParam = (route, name) => {
  const param = route.query[name];
  if (typeof param === 'undefined') {
    return '';
  }
  return param;
};

export const getQueryParamAsInt = (route, name) => {
  const param = route.query[name];
  if (typeof param === 'undefined') {
    return 0;
  }
  const paramAsInt = parseInt(param, 10);
  if (Number.isNaN(paramAsInt)) {
    return 0;
  }
  return paramAsInt;
};
