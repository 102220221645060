<template>
  <div
    class="expandable-content"
  >
    <div
      :class="['_header', secondary ? '-secondary' : '']"
      @click="toggle"
    >
      <div class="_text">
        {{ title }}
      </div>
      <m-icon
        :type="iconType"
        size="11"
      />
    </div>
    <m-transition-expand>
      <div
        v-show="expanded"
        class="_content"
      >
        <slot />
      </div>
    </m-transition-expand>
  </div>
</template>

<script>

export default {
  name: 'ExpandableContent',
  props: {
    title: {
      type: String,
      default: '',
    },
    secondary: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return { expanded: false };
  },
  computed: {
    iconType() {
      if (this.expanded) {
        return 'up';
      }
      return 'down';
    },
  },
  methods: {
    toggle() {
      this.expanded = !this.expanded;
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .expandable-content {
    ._header {
      display: flex;
      margin-bottom: .5rem;
      cursor: pointer;

      &.-secondary {
        color: $font-color-secondary;
      }

      ._text {
        margin-right: 1rem;
      }
    }
  }
</style>
