<template>
  <m-card-item
    v-if="item.key !== DIRECT_PROPERTY_CYCLE_KEY"
    icon="arrow-up-thin"
    @click="applySort(false)"
  >
    {{ $t('cascadeHeaderMenu.sortAscending') }}
  </m-card-item>
  <m-card-item
    v-if="item.key !== DIRECT_PROPERTY_CYCLE_KEY"
    icon="arrow-down-thin"
    @click="applySort(true)"
  >
    {{ $t('cascadeHeaderMenu.sortDescending') }}
  </m-card-item>
  <m-card-item
    icon="funnel"
    @click="applyFilter"
  >
    {{ $t('cascadeHeaderMenu.filter') }}
  </m-card-item>
</template>
<script setup>

import useGoalProperty from '@/composables/property/goal-property';
import useGoalsSorting from '@/composables/goal/sort';
import { DIRECT_PROPERTY_CYCLE_KEY, VIEWS_SERVICE } from '@/lib/constants';
import { EventBus } from '@/lib/event-bus';
import { inject, ref } from 'vue';
import { propertyType } from 'shared/constants.json';

const props = defineProps({ item: { type: Object, required: true } });

const emit = defineEmits(['close-menu']);
const viewsService = inject(VIEWS_SERVICE);
const currentView = ref(viewsService.currentView);

const { properties: goalProperties } = useGoalProperty();
const { sortOptions } = useGoalsSorting(goalProperties);

const applySort = (desc) => {
  const val = findCorrespondingAttribute(props.item.key, sortOptions);
  if (val !== null) {
    updateViewParams({
      attr: val.attr,
      desc,
      langs: val.langs,
    });
  }
  emit('close-menu');
};

const findCorrespondingAttribute = (key, haystack) => {
  for (let i = 0; i < haystack.length; i++) {
    if (haystack[i].attr === key) {
      return haystack[i];
    }
    if (haystack[i].langs !== undefined && haystack[i].langs[0].includes(key)) {
      return haystack[i];
    }
  }
  return null;
};

const updateViewParams = (value) => {
  viewsService.setParams(
    currentView.value,
    {
      ...currentView.value.params,
      order: [value],
    },
  );
};

const applyFilter = () => {
  emit('close-menu');
  if (props.item.key === 'goalCycle') {
    EventBus.$emit('applyCycle');
    return;
  }
  if (props.item.property.type === propertyType.text || props.item.property.type === propertyType.richText) {
    EventBus.$emit('applyText');
    return;
  }
  EventBus.$emit('applyFilter', props.item);
};
</script>
