<template>
  <div class="m-hover-menu">
    <div
      v-if="show || forceShow"
      :style="menuStyle"
      class="_menu"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash-es';
import { upToContainerWithClass } from 'shared/lib/dom';

export default {
  name: 'MHoverMenu',
  props: {
    itemClass: {
      type: String,
      required: true,
    },
    forceShow: {
      type: Boolean,
      default: false,
    },
    dataKey: {
      type: String,
      default: 'id',
    },
    recreateKey: {
      type: [String, Array, Boolean, Number],
      default: '',
    },
    mousemoveAreaClass: {
      type: String,
      default: '',
    },
  },
  emits: ['set-active-element'],
  data() {
    return {
      show: false,
      top: 0,
      left: 0,
      mouseDown: false,
      activeKey: 0,
      debounced: null,
      startPoint: {
        x: 0,
        y: 0,
      },
    };
  },
  computed: {
    menuStyle() {
      return {
        position: 'fixed',
        top: `${this.top}px`,
        left: `${this.left}px`,
      };
    },
    mouseMoveArea() {
      if (this.mousemoveAreaClass === '') {
        return window;
      }

      return upToContainerWithClass(this.$el, this.mousemoveAreaClass);
    },
  },
  methods: {
    handleMouseDown(event) {
      this.startPoint.x = event.clientX;
      this.startPoint.y = event.clientY;
      this.mouseDown = true;
    },
    handleMouseUp() {
      this.startPoint.x = 0;
      this.startPoint.y = 0;
      this.mouseDown = false;
    },
    getKey(element) {
      if (element.dataset[this.dataKey] === undefined) {
        throw new Error('element doesnt have data-id installed');
      }
      if (Number.isNaN(parseInt(element.dataset[this.dataKey], 10))) {
        return element.dataset[this.dataKey];
      }

      return parseInt(element.dataset[this.dataKey], 10);
    },
    getElement(element, count, threshold) {
      if (count >= threshold || element === null) {
        return null;
      }

      if (element.dataset[this.dataKey] === undefined) {
        return this.getElement(element.parentElement, count + 1, threshold);
      }

      if (![...element.classList].includes(this.itemClass)) {
        return this.getElement(element.parentElement, count + 1, threshold);
      }

      return element;
    },
    handleMousemove(event) {
      const handle = () => {
        // if user is dragging, hide hover menu
        if (this.mouseDown && (Math.abs(event.clientY - this.startPoint.y) > 5 || Math.abs(event.clientX - this.startPoint.x) > 5)) {
          this.reset();
          return;
        }

        const container = this.mouseMoveArea.getBoundingClientRect();
        const viewPort = document.body.getBoundingClientRect();

        const containerWidth = Math.min(viewPort.width - container.left, container.width);
        const srcEl = document.elementFromPoint(container.left + (containerWidth / 2), event.clientY);
        const el = this.getElement(srcEl, 1, 30);
        if (el === null) {
          this.reset();
          return;
        }

        const key = this.getKey(el);
        this.show = true;
        if (this.activeKey === key) {
          return;
        }

        const { top, left } = el.getBoundingClientRect();

        this.top = top;
        this.left = left;
        this.activeKey = key;
        this.$emit('set-active-element', key);
      };

      if (this.debounced !== null) {
        this.debounced.cancel();
      }

      this.debounced = debounce(handle, 5);
      this.debounced();
    },
    reset() {
      if (this.activeKey !== 0) {
        this.activeKey = 0;
        this.$emit('set-active-element', 0);
      }
      this.show = false;
    },
    hide() {
      this.show = false;
    },
  },
  mounted() {
    document.addEventListener('scroll', this.hide, true);
    document.addEventListener('mousedown', this.handleMouseDown);
    document.addEventListener('mouseup', this.handleMouseUp);
    this.mouseMoveArea.addEventListener('mousemove', this.handleMousemove);
    this.mouseMoveArea.addEventListener('mouseleave', this.hide);
  },
  beforeUnmount() {
    document.removeEventListener('scroll', this.hide);
    document.removeEventListener('mousedown', this.handleMouseDown);
    document.removeEventListener('mouseup', this.handleMouseUp);
    this.mouseMoveArea.removeEventListener('mousemove', this.handleMousemove);
    this.mouseMoveArea.removeEventListener('mouseleave', this.hide);
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  .m-hover-menu {
    z-index: 0;

    ._menu {
      animation: fadeIn .05s ease-in-out;

      @keyframes fadeIn {
        0% {
          opacity: 0;
        }

        100% {
          opacity: 1;
        }
      }
    }
  }
</style>
