import useBulkMutate from '@/nebula/bulk-mutate';
import useRepo from '@/nebula/repo';
import useSavedViewRepo from '@/composables/saved-views/saved-view-repo';
import { computed, ref } from 'vue';
import { findViews } from '@/lib/saved-view/saved-view';
import { savedViewChildren } from '@/api/query/nebula/saved-view';
import { savedView as savedViewConfig, viewCollection as viewCollectionConfig } from 'shared/api/query/configs.json';
import { viewCollectionChildren } from '@/api/query/nebula/view-collection';

export default function useViewsRepo({ application, space = null, planning = null, user = null, gridPageTile = null }) {
  const repo = useSavedViewRepo();
  const bulkMutator = useBulkMutate();

  const { entityList: allViews } = useRepo(savedViewConfig.model);
  const applicationViews = computed(() => findViews({
    views: allViews.value,
    application,
    space,
    planning,
    user,
    gridPageTile,
  }));

  const deleteView = (view) => repo.deleteSingle(view.uid);

  const createLoading = ref(false);
  const createViews = (views, viewCollection) => {
    createLoading.value = true;
    const payloads = [
      {
        alias: 'createViews',
        model: savedViewConfig.model,
        nodes: views.map((v, index) => ({ ...v, rid: index + 1, uid: undefined })),
        attributes: savedViewChildren,
      },

    ];
    if (viewCollection !== null) {
      payloads.push(
        {
          alias: 'updateViewOrder',
          nodes: [{ uid: viewCollection.uid, viewOrder: [...viewCollection.viewOrder, ...views.map((_, index) => ({ rid: index + 1 }))] }],
          model: viewCollectionConfig.model,
          attributes: viewCollectionChildren,
        },
      );
    }
    return bulkMutator.bulkMutate(payloads).then((response) => repo.selectMultiple(response.data.createViews.map((v) => v.uid))).finally(() => {
      createLoading.value = false;
    });
  };

  return {
    createLoading,
    createViews,
    selectSingle: repo.selectSingle,

    loadingUpdateView: repo.updateLoading,
    updateView: repo.updateSingle,

    loadingUpdateMetaData: repo.updateLoading,

    loadingDeleteView: repo.deleteLoading,
    deleteView,

    views: applicationViews,
  };
}
