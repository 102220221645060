<template>
  <div
    class="form-field-settings"
  >
    <m-toolbar small>
      <m-toolbar-title>
        {{ $t('general.settings') }}
      </m-toolbar-title>
      <m-spacer />
      <m-btn
        small
        icon="close"
        fab
        hide-border
        @click="$emit('close')"
      />
    </m-toolbar>
    <m-divider none />
    <m-content padding-small>
      <div class="_select">
        <div class="_label">
          {{ $t('formFieldSettings.questionTypeLabel') }}
        </div>
        <m-select
          :items="types"
          :value="item.field.type"
          full-width
          has-custom-item
          match-trigger-width
          @change="updateType"
        >
          <template #item="i">
            <div
              class="_select-option"
            >
              <form-field-icon
                :type="i.item"
                :show-index="false"
                small
                square
              />
              <span class="_title">{{ getLabel(i.item) }}</span>
            </div>
          </template>
        </m-select>
      </div>
      <m-divider small />
      <template
        v-if="item.field.type === 'opinion_scale'"
      >
        <div
          class="_option -steps"
        >
          <div class="_label">
            {{ $t('formFieldSettings.stepsLabel') }}
          </div>
          <div
            class="_slider"
          >
            <m-slider
              :value="steps"
              :max="11"
              :min="2"
              :step="1"
              :tooltip-visible="false"
              @change="updateSteps"
            />
          </div>
          <div class="_amount">
            {{ steps }}
          </div>
        </div>
        <div class="_option">
          <div class="_label">
            {{ $t('formFieldSettings.startsFromZero') }}
          </div>
          <div class="_switch">
            <m-switch
              v-model:value="startsFromZero"
            />
          </div>
        </div>
        <m-divider small />
        <div class="_option -text-input">
          <div class="_label">
            {{ $t('formFieldSettings.labelLabel') }}
          </div>
          <div class="_inputs">
            <language-field
              field-key="labelLeft"
              :value="item.field.opinionScaleProperties.labelLeft[lang]"
              :placeholder="$t('formFieldSettings.labelLeftLabel')"
              @update="updateLabel"
            />
            <language-field
              field-key="labelCenter"
              :value="item.field.opinionScaleProperties.labelCenter[lang]"
              :placeholder="$t('formFieldSettings.labelCenterLabel')"
              @update="updateLabel"
            />
            <language-field
              field-key="labelRight"
              :value="item.field.opinionScaleProperties.labelRight[lang]"
              :placeholder="$t('formFieldSettings.labelRightLabel')"
              @update="updateLabel"
            />
          </div>
        </div>
        <m-divider small />
      </template>
      <div class="_option">
        <div class="_label">
          {{ $t('formFieldSettings.requiredLabel') }}
        </div>
        <div class="_switch">
          <m-switch
            v-model:value="isRequired"
            color="primary"
          />
        </div>
      </div>
      <div
        v-if="showAllowComment"
        class="_option"
      >
        <div class="_label">
          {{ $t('formFieldSettings.allowComment') }}
        </div>
        <div class="_switch">
          <m-switch
            v-model:value="allowComment"
            color="primary"
          />
        </div>
      </div>
    </m-content>
  </div>
</template>

<script>
import FormFieldIcon from '@/components/FormFieldIcon.vue';
import LanguageField from '@/components/LanguageField.vue';
import { fieldType } from 'shared/constants.json';

export default {
  name: 'FormFieldSettings',
  props: {
    item: {
      type: Object,
      required: true,
    },
    fieldIndex: {
      type: Number,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
    model: {
      type: String,
      required: true,
    },
  },
  emits: ['close'],
  components: { LanguageField, FormFieldIcon },
  data() {
    return {
      types: [
        {
          text: this.$t('formFieldSettings.opinionScale'),
          value: fieldType.opinionScale,
        },
        {
          text: this.$t('formFieldSettings.longText'),
          value: fieldType.longText,
        },
      ],
    };
  },
  computed: {
    steps() {
      return this.item.field.opinionScaleProperties.steps;
    },
    showAllowComment() {
      return this.item.field.type !== fieldType.longText;
    },
    isRequired: {
      get() {
        return this.item.field.validations.required;
      },
      set(value) {
        this.updateRequired(value);
      },
    },
    startsFromZero: {
      get() {
        return this.item.field.opinionScaleProperties.startsFromZero;
      },
      set(value) {
        this.updateStartsFromZero(value);
      },
    },
    allowComment: {
      get() {
        return this.item.field.allowComment;
      },
      set(value) {
        this.updateAllowComment(value);
      },
    },
  },
  methods: {
    getLabel(value) {
      const filtered = this.types.filter((t) => t.value === value);
      if (filtered.length !== 1) {
        return '';
      }

      return filtered[0].text;
    },
    updateType(val) {
      this.$store.commit('ORDER_ITEM_CHANGED', {
        orderItem: {
          ...this.item,
          field: {
            ...this.item.field,
            type: val,
          },
        },
        orderItemIndex: this.fieldIndex,
        model: this.model,
      });
    },
    updateSteps(val) {
      this.$store.commit('ORDER_ITEM_CHANGED', {
        orderItem: {
          ...this.item,
          field: {
            ...this.item.field,
            opinionScaleProperties: {
              ...this.item.field.opinionScaleProperties,
              steps: val,
            },
          },
        },
        orderItemIndex: this.fieldIndex,
        model: this.model,
      });
    },
    updateStartsFromZero(val) {
      this.$store.commit('ORDER_ITEM_CHANGED', {
        orderItem: {
          ...this.item,
          field: {
            ...this.item.field,
            opinionScaleProperties: {
              ...this.item.field.opinionScaleProperties,
              startsFromZero: val,
            },
          },
        },
        orderItemIndex: this.fieldIndex,
        model: this.model,
      });
    },
    updateAllowComment(val) {
      this.$store.commit('ORDER_ITEM_CHANGED', {
        orderItem: {
          ...this.item,
          field: {
            ...this.item.field,
            allowComment: val,
          },
        },
        orderItemIndex: this.fieldIndex,
        model: this.model,
      });
    },
    updateRequired(val) {
      this.$store.commit('ORDER_ITEM_CHANGED', {
        orderItem: {
          ...this.item,
          field: {
            ...this.item.field,
            validations: {
              ...this.item.field.validations,
              required: val,
            },
          },
        },
        orderItemIndex: this.fieldIndex,
        model: this.model,
      });
    },
    updateLabel({ value, fieldKey }) {
      this.$store.commit('ORDER_ITEM_CHANGED', {
        orderItem: {
          ...this.item,
          field: {
            ...this.item.field,
            opinionScaleProperties: {
              ...this.item.field.opinionScaleProperties,
              [fieldKey]: { [this.lang]: value },
            },
          },
        },
        orderItemIndex: this.fieldIndex,
        model: this.model,
      });
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .form-field-settings {
    width: 100%;
    background-color: map_get($grey, 'lighten-6');

    ._select {
      ._label {
        margin-bottom: .8rem;
      }
    }

    ._option {
      display: flex;
      align-items: center;
      padding: $xxs-container-padding-y 0;

      &.-steps {
        display: flex;
        align-items: center;
        padding: $xxs-container-padding-y 0 0 0;

        ._slider {
          flex: 0 0 9rem;
          padding: .2rem 1rem 0 0;
          margin-left: auto;
        }

        ._amount {
          width: 2.5rem;
          text-align: right;
        }
      }

      &.-text-input {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        padding: 1.6rem 0;

        ._inputs {
          width: 100%;
          margin-top: .4rem;

          ._input {
            margin: .4rem 0;
          }
        }
      }

      ._switch {
        margin: 0 0 0 auto;
      }
    }
  }

  ._select-option {
    display: flex;
    align-items: center;

    ._title {
      margin-left: 1rem;
    }
  }
</style>
