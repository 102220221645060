<template>
  <div
    class="m-sider"
    :style="style"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'MSider',
  props: {
    width: {
      type: String,
      default: '20rem',
    },
    widthCollapsed: {
      type: String,
      default: '5.3rem',
    },
    collapsed: {
      type: Boolean,
      default: false,
    },
    bordered: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    style() {
      return {
        width: this.collapsed ? this.widthCollapsed : this.width,
        'border-right': this.bordered ? `1px solid ${this.$colors.grey.lighten4}` : 'none',
      };
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .m-sider {
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: .1px;
    background-color: white;
  }
</style>
