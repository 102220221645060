import useRepo from '@/nebula/repo';
import { accessGroup as accessGroupConfig } from 'shared/api/query/configs.json';
import { accessPolicyType } from 'shared/constants.json';
import { computed } from 'vue';

export default function useAccessGroupsLoggedInUser() {
  const repo = useRepo(accessGroupConfig.model);
  const accessGroups = computed(() => repo.entityList.value.filter((ag) => {
    // We use only access policy type "read" to determine the "member" (all users) access group
    if (ag.accessPolicy.accountAccess === accessPolicyType.read) {
      return true;
    }

    return ag.accessPolicy.scopes.some((s) => s.userIsInScope === true);
  }));

  return { accessGroups };
}
