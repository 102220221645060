import * as actions from '@/nebula/actions';
import attributesMap from '@/nebula/attributes-map';
import useSelector from '@/nebula/selectors';
import { RESULT, TYPE } from 'shared/api/query/constants';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';

/**
 * @typedef {Object} Repo
 * @property {String} model
 * @property {function} getList - Retrieve all items
 * @property {function} selectMultiple - Select entities by ids, per default locally
 * @property {function} selectSingle - Select single entity by id, per default locally
 * @property {function} updateMultiple - Per default committed to remote
 * @property {function} updateSingle - Per default committed to remote
 * @property {function} mutateMultiple - Per default committed to remote
 * @property {function} mutateSingle - Per default committed to remote
 * @property {function} deleteMultiple - Per default committed to remote
 * @property {function} deleteSingle - Per default committed to remote
 * @property {Object} selector
 * @property {Array} ids
 * @property {Object} entityMap
 * @property {Array} entityList
 *
 * Read operations via selectMultiple and selectSingle are per default
 * local while write operations per default are committed to remove.
 */

/**
 * @return {Repo} repo
 */
export default function useRepo(model, attributes = attributesMap[model]) {
  const store = useStore();
  const selector = useSelector(model);

  const queryLoading = ref(false);
  const query = (queries, options) => {
    queryLoading.value = true;
    return actions.queryMultiple(store, { queries }, options)
      .then((response) => response.data)
      .finally(() => {
        queryLoading.value = false;
      });
  };

  const defaultListQuery = {
    alias: RESULT,
    func: { name: TYPE, args: [{ value: model }] },
    default: [],
    model,
    children: attributes,
  };
  const getListLoading = ref(false);
  const getList = (query = defaultListQuery) => {
    getListLoading.value = true;
    return actions.queryMultiple(store, { queries: [query] })
      .then(() => selector.entityList.value)
      .finally(() => {
        getListLoading.value = false;
      });
  };

  const selectLoading = ref(false);
  const selectMultiple = (ids, options = { commitToRemote: false, attributes: undefined }) => {
    if (options.commitToRemote !== true) {
      return selector.selectMultiple(ids);
    }
    selectLoading.value = true;
    return actions.select(store, {
      ids,
      model,
      attributes: options?.attributes === undefined ? attributes : options.attributes,
    })
      .then((data) => selector.selectMultiple(data.map((d) => d.uid)))
      .finally(() => {
        selectLoading.value = false;
      });
  };

  const selectSingle = (id, options = { commitToRemote: false, attributes: undefined }) => {
    if (options.commitToRemote !== true) {
      return selector.selectSingle(id);
    }
    return selectMultiple([id], options).then((response) => response[0]);
  };

  const createLoading = ref(false);
  const createMultiple = (entities, options, hookParameter) => {
    createLoading.value = true;

    const mutation = actions.createEntities(store, {
      entities,
      model,
      attributes: options?.attributes === undefined ? attributes : options.attributes,
      hookParameter,
    }, options);

    const responseHandler = (data) => selector.selectMultiple(data.map((e) => e.uid));

    const finallyHandler = () => {
      createLoading.value = false;
    };

    return mutation.then(responseHandler).finally(finallyHandler);
  };

  const createSingle = (entity, options, hookParameter) => createMultiple([entity], options, hookParameter).then((response) => response[0]);

  const updateLoading = ref(false);
  const updateMultiple = (entities, options, hookParameter) => {
    updateLoading.value = true;

    const mutation = actions.updateEntities(store, {
      entities,
      model,
      attributes: options?.attributes === undefined ? attributes : options.attributes,
      hookParameter,
    }, options);

    const responseHandler = (data) => selector.selectMultiple(data.map((e) => e.uid));

    const finallyHandler = () => {
      updateLoading.value = false;
    };

    return mutation.then(responseHandler).finally(finallyHandler);
  };

  const updateSingle = (entity, options, hookParameter) => updateMultiple([entity], options, hookParameter).then((response) => response[0]);

  const mutateLoading = ref(false);
  const mutateMultiple = (entities, options, hookParameter) => {
    mutateLoading.value = true;

    const mutation = actions.mutateEntities(store, {
      entities,
      model,
      attributes: options?.attributes !== undefined ? options.attributes : attributes,
      hookParameter,
    }, options);

    const responseHandler = (data) => selector.selectMultiple(data.map((e) => e.uid));

    const finallyHandler = () => {
      mutateLoading.value = false;
    };

    return mutation.then(responseHandler).finally(finallyHandler);
  };

  const mutateSingle = (entity, options, hookParameter) => mutateMultiple([entity], options, hookParameter).then((response) => response[0]);

  const deleteLoading = ref(false);
  const deleteMultiple = (ids, options) => {
    deleteLoading.value = true;
    const mutation = actions.deleteEntities(store, {
      ids,
      model,
    }, options);

    const finallyHandler = () => {
      deleteLoading.value = false;
    };

    return mutation.finally(finallyHandler);
  };

  const deleteSingle = (id, options) => deleteMultiple([id], options);

  return {
    model,

    selectLoading,
    selectMultiple,
    selectSingle,

    getListLoading,
    getList,

    query,
    queryLoading,

    createLoading,
    createMultiple,
    createSingle,

    updateLoading,
    updateMultiple,
    updateSingle,

    mutateLoading,
    mutateMultiple,
    mutateSingle,

    deleteLoading,
    deleteMultiple,
    deleteSingle,

    entityList: selector.entityList,
    entityMap: selector.entityMap,
    ids: computed(() => Object.keys(selector.entityMap.value)),
  };
}
