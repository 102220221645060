import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import usePersonalAppSettings from '@/composables/logged-in-user/personal-app-settings';
import useUnassignedGoalCycle from '@/composables/goal-cycle/unassigned-goal-cycle';
import { UNASSIGNED } from '@/lib/constants';
import { computed } from 'vue';

export default function usePersistedGoalCycle() {
  const { unassignedCycle } = useUnassignedGoalCycle();

  const { loggedInUser } = useLoggedInUser();
  const { personalAppSettings, updateSingle } = usePersonalAppSettings(loggedInUser);

  const unassignedGoalCycleSelected = computed(() => personalAppSettings.value.unassignedGoalCycleSelected);
  const selectedGoalCycles = computed(() => {
    if (personalAppSettings.value.selectedGoalCycles === undefined) {
      return [];
    }
    return personalAppSettings.value.selectedGoalCycles;
  });
  const composedSelectedCycles = computed(() => {
    const res = [...selectedGoalCycles.value];
    if (unassignedGoalCycleSelected.value) {
      res.push(unassignedCycle);
    }
    return res;
  });

  const buildEntity = (items) => {
    const selectedGoalCycles = items.filter((item) => item.uid !== UNASSIGNED);

    const unassignedCycle = items.find((c) => c.uid === UNASSIGNED);
    return {
      uid: personalAppSettings.value.uid,
      unassignedGoalCycleSelected: unassignedCycle !== undefined,
      selectedGoalCycles,
    };
  };

  const changeSelectedGoalCycles = (items) => updateSingle(buildEntity(items));

  return {
    composedSelectedCycles,
    selectedGoalCycles,
    unassignedGoalCycleSelected,

    changeSelectedGoalCycles,
  };
}
