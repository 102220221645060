import * as microsoftTeams from '@microsoft/teams-js';
import FullScreenSpinner from 'shared/components/FullScreenSpinner.vue';
import useMSTeams from '@/composables/msteams';
import { encodeURLValues } from '@/lib/url';
import { h } from 'vue';

export default {
  name: 'MSAuth',
  setup() {
    const { encodeAuthenticationResult } = useMSTeams();
    return { encodeAuthenticationResult };
  },
  components: { FullScreenSpinner },
  render() {
    return h(FullScreenSpinner, { height: 600 });
  },
  methods: {
    auth() {
      const request = {
        loginHint: this.$route.query.loginHint,
        provider: this.$route.query.provider,
        tenant: this.$route.query.tenantId,
        samlEmail: this.$route.query.samlEmail,
      };

      switch (this.$route.query.action) {
        case 'init':
          window.location.assign(`/api/v1/msteams/auth?${encodeURLValues(request)}`);
          break;
        case 'return':

          microsoftTeams.app.initialize().then(() => {
            if (typeof this.$route.query.error !== 'undefined' && this.$route.query.error !== '') {
              microsoftTeams.authentication.notifyFailure(this.$route.query.error);
              return;
            }

            const result = {
              accessToken: this.$route.query.access_token,
              tokenExpiry: this.$route.query.token_expiry,
              refreshToken: this.$route.query.refresh_token,
              provider: this.$route.query.provider,

              // saml
              signedTrackedRequest: this.$route.query.signed_tracked_request,
              samlResponse: this.$route.query.saml_response,
            };

            microsoftTeams.authentication.notifySuccess(this.encodeAuthenticationResult(result));
          });
          break;

        default:
          throw Error(`action not recognized: ${this.$route.query.action}`);
      }
    },
  },
  created() {
    this.auth();
  },
};
