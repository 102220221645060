import useAppIntegration from '@/composables/app-integration';
import { doGetSlackConsentUrl } from '@/api';
import { encodeURLValues } from '@/lib/url';
import { eventRemover, eventer, messageEvent } from '@/lib/window-events';
import { ref } from 'vue';
import { slackBotInstallStatus } from '@/constants.json';
import { useI18n } from 'vue-i18n';

export default function useSlackInstaller() {
  const { t } = useI18n();
  const slackConsentUrl = ref('');
  const slackLoading = ref(false);

  const appIntegrationRepo = useAppIntegration();
  const goToSlack = () => {
    if (slackConsentUrl.value === '') {
      return;
    }
    slackLoading.value = true;
    const params = { oauthRedirect: slackConsentUrl.value };
    window.open(`/#/oauth-redirect?${encodeURLValues(params)}`, '_blank', 'width=700,height=700');
  };

  const status = ref('');
  const subscribeWindowEvents = () => {
    const eventHandler = (event) => {
      eventRemover()(messageEvent(), eventHandler);
      if (event.data.message !== 'slackInstall') {
        return;
      }

      status.value = event.data.status;
      // TODO: We do not yet receive update events for the slack install endpoint
      if (slackBotInstallStatus.success === status.value) {
        appIntegrationRepo.getList();
      }

      slackLoading.value = false;
    };

    eventer()(messageEvent(), eventHandler);
  };

  const showSlackStatus = (slackConnectInformation) => {
    switch (slackConnectInformation) {
      case slackBotInstallStatus.success:
        return { success: true, message: t('accountSettings.appIntegration.slackConnected') };
      case slackBotInstallStatus.error:
        return { success: false, message: t('accountSettings.appIntegration.slackConnectionError') };
      default:
        return { success: false, message: t('general.error') };
    }
  };
  const getSlackConsentUrl = () => doGetSlackConsentUrl({ originUrl: `${window.location.protocol}//${window.location.host}/#/oauth-redirect` })
    .then((response) => {
      slackConsentUrl.value = response.data;
    });

  return {
    goToSlack,
    subscribeWindowEvents,
    slackLoading,
    status,
    showSlackStatus,
    getSlackConsentUrl,
  };
}
