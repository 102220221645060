<template>
  <full-screen-spinner
    v-if="initialLoading"
    :height="150"
  />
  <div
    v-else
    class="planning-list"
  >
    <div class="_table">
      <m-selectable
        v-model:value="selectedIds"
        selector-class="m-table-row"
      >
        <m-table
          :columns="columns"
          :data-source="plannings"
          :order="{ createdAt: 'down' }"
          :loading="loading"
          :selected-rows="selectedIds"
          row-key="uid"
          rows-clickable
          layout-padding
          show-new-button
          :custom-row="handleRowClick"
          @contextmenu="handleContextMenu"
          @new="$emit('new')"
        >
          <template #title="{ title, row }">
            <m-dropdown
              :value="inlineEditingSvc.isInlineEditing(row.uid).value"
              placement="onTopLeft"
              match-trigger-width
              block
              :relocate-key="localTitle"
              :title="$t('cascadeTableRow.editTitle')"
              @hide="blur(row)"
            >
              <div class="_title">
                <item-title
                  :title="title"
                  wrap-title
                >
                  <template #title>
                    {{ title === '' ? $t('general.untitled') : title }}
                    <m-tag
                      v-if="row.status === planningStatus.closed"
                      :title="$t(`plannings.${row.status}`)"
                      :style="{ display: 'inline-flex', marginLeft: '.8rem' }"
                    />
                  </template>
                </item-title>
                <div
                  class="_title-actions"
                >
                  <m-tooltip
                    v-if="canEdit(row)"
                    class="_edit-btn"
                  >
                    <m-btn
                      small
                      fab
                      light
                      hide-border
                      icon="edit"
                      @click.stop="editTitle(row)"
                    />
                    <template #title>
                      {{ $t('cascadeTableRow.editTitle') }}
                    </template>
                  </m-tooltip>
                </div>
              </div>
              <template #overlay>
                <m-card
                  no-padding
                  class="_overlay"
                >
                  <div
                    class="_inner"
                  >
                    <simple-editor
                      :initial-value="title"
                      :placeholder="$t('list.addTitle')"
                      full-width
                      auto-focus
                      :read-only="false"
                      @enter="blur(row)"
                      @escape="blur(row)"
                      @blur="blur(row)"
                      @update:value="updateTitle"
                    />
                  </div>
                </m-card>
              </template>
            </m-dropdown>
          </template>
          <template #user="{ user }">
            <user-display
              :user="user"
              small
            />
          </template>
          <template
            v-for="prop in planningProperties"
            #[prop.uid]="{ row }"
            :key="prop.uid"
          >
            <div
              v-if="[propertyType.options,propertyType.singleSelect].includes(prop.type)"
              class="_property-options"
            >
              <div
                v-for="value in row.properties.filter(op => op.property.uid === prop.uid)"
                :key="value.uid"
                class="_option"
              >
                <m-tag
                  v-for="option in value.selectedOptions"
                  :key="option.uid"
                  :type="option.type"
                  :icon="option.icon"
                  :color="option.color"
                  automatic-color-fallback
                  small
                  class="_tag"
                  :title="textByLang(option.label, userLang)"
                />
              </div>
            </div>
            <div
              v-if="[propertyType.space].includes(prop.type)"
              class="_property-options"
            >
              <div
                v-for="value in row.properties.filter(op => op.property.uid === prop.uid)"
                :key="value.uid"
                class="_option"
              >
                <m-tag
                  v-for="space in value.spaces"
                  :key="space.uid"
                  :type="space.type"
                  :icon="buildIconFromEntity(space)"
                  :color="space.color"
                  automatic-color-fallback
                  small
                  class="_tag"
                  :title="space.title"
                />
              </div>
            </div>
          </template>
          <template #goalCycles="{ goalCycles }">
            <div class="_cycles">
              <div
                v-for="cycle in goalCycles"
                :key="cycle.uid"
                class="_cycle"
              >
                <m-tag
                  :title="cycle.title"
                  :color="cycle.color"
                />
              </div>
            </div>
          </template>
          <template #createdAt="{ createdAt }">
            {{ formatDate(createdAt) }}
          </template>
        </m-table>
        <plannings-context-menu
          ref="contextmenu"
          :ids="selectedIds"
        />
      </m-selectable>
    </div>
  </div>
  <list-onboarding-modal @create="$emit('new')" />
</template>

<script>
import FullScreenSpinner from 'shared/components/FullScreenSpinner.vue';
import ItemTitle from '@/components/ItemTitle.vue';
import ListOnboardingModal from '@/components/goal/plannings/ListOnboardingModal.vue';
import PlanningsContextMenu from '@/components/goal/plannings/PlanningsContextMenu.vue';
import SimpleEditor from '@/components/SimpleEditor.vue';
import UserDisplay from 'shared/components/UserDisplay.vue';
import useDebounce from '@/composables/debounce';
import useInlineEditing from '@/composables/inline-editing';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import usePlannings from '@/composables/planning/plannings';
import useProperties from '@/composables/property/property';
import useSelectedViews from '@/composables/saved-views/selected-views';
import { DateTime } from 'luxon';
import { accessPolicyType, planningStatus, propertyType, routeName, viewApplication } from 'shared/constants.json';
import { buildIconFromEntity } from 'shared/lib/icon';
import { textByLang } from 'shared/lib/language';

export default {
  name: 'PlanningList',
  props: {
    ids: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    UserDisplay,
    ItemTitle,
    SimpleEditor,
    ListOnboardingModal,
    FullScreenSpinner,
    PlanningsContextMenu,
  },
  data() {
    return {
      initialLoading: true,
      selectedIds: [],
      localTitle: '',
      planningStatus,
      textByLang,
      accessPolicyType,
      propertyType,
    };
  },
  emits: ['new'],
  setup() {
    const { getSelectedViewViewIdForApplication } = useSelectedViews();
    const { plannings, updatePlanning } = usePlannings();
    const inlineEditingSvc = useInlineEditing();
    const { debounce } = useDebounce();
    const { planningProperties } = useProperties();
    const { userLang } = useLoggedInUser();

    return {
      userLang,
      debounce,
      inlineEditingSvc,
      getSelectedViewViewIdForApplication,
      allPlannings: plannings,
      updatePlanning,
      planningProperties,
    };
  },
  computed: {
    plannings() {
      return this.allPlannings.filter((p) => this.ids.includes(p.uid));
    },
    columns() {
      return [
        {
          key: 'title',
          dataIndex: 'title',
          title: this.$t('planningList.title'),
          scopedSlots: { customRender: 'title' },
        },
        ...this.planningProperties.map((property) => ({
          key: property.uid.toString(),
          dataIndex: property.uid.toString(),
          title: textByLang(property.label, this.userLang),
          scopedSlots: { customRender: property.uid },
        })),
        {
          key: 'goalCycles',
          dataIndex: 'goalCycles',
          title: this.$t('planningList.goalCycle'),
          scopedSlots: { customRender: 'goalCycles' },
        },
        {
          key: 'creator',
          dataIndex: 'creator',
          title: this.$t('planningList.creator'),
          scopedSlots: { customRender: 'user' },
        },
        {
          key: 'createdAt',
          dataIndex: 'createdAt',
          title: this.$t('planningList.createdAt'),
          scopedSlots: { customRender: 'createdAt' },
        },
      ];
    },
  },
  methods: {
    buildIconFromEntity,
    formatDate(date) {
      return DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED);
    },
    handleRowClick(row) {
      const { $router, getSelectedViewViewIdForApplication } = this;
      return {
        on: {
          click() {
            $router.push({
              name: routeName.planningDetails,
              params: { id: row.uid },
              query: {
                viewId: getSelectedViewViewIdForApplication({
                  application: viewApplication.planningDetails,
                  planning: { uid: row.uid },
                }).value,
              },
            });
          },
        },
      };
    },
    handleContextMenu(event, row) {
      event.preventDefault();
      if (this.selectedIds.length === 0 || !this.selectedIds.includes(row.uid)) {
        this.selectedIds = [row.uid];
      }
      this.$refs.contextmenu.show(event);
    },
    canEdit(entity) {
      return [accessPolicyType.full, accessPolicyType.write].includes(entity.accessRight);
    },
    editTitle(entity) {
      this.localTitle = entity.title;
      this.inlineEditingSvc.set(entity.uid);
    },
    updateTitle(val) {
      this.localTitle = val;
    },
    blur(entity) {
      const update = () => this.updatePlanning({
        ...entity,
        title: this.localTitle,
      }).catch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      }).finally(() => {
        this.localTitle = '';
      });
      this.inlineEditingSvc.reset();
      this.selectedIds.push(entity.uid);
      this.debounce(update, 50);
    },
  },
  watch: {
    loading(newVal) {
      if (newVal === false) {
        this.initialLoading = false;
      }
    },
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  @import "shared/assets/scss/box-shadow";

  .planning-list {
    ._title {
      display: inline-flex;

      ._title-actions {
        position: absolute;
        top: -0.2rem;
        right: 0;
        display: flex;
        align-items: center;

        ._edit-btn {
          display: none;
          background-color: white;
          border-radius: $default-border-radius;

          @include box_shadow(1);
        }
      }
    }

    ._user {
      display: flex;
      align-items: center;

      ._avatar {
        margin-right: .8rem;
      }

      ._name {
        white-space: nowrap;
      }
    }

    ._property-options {
      display: flex;

      ._option {
        display: flex;
        flex-wrap: wrap;

        ._tag {
          margin: .4rem;
        }
      }
    }

    ._cycles {
      display: flex;
    }

    ._table {
      ._cell {
        &:hover {
          ._title-actions {
            @media (min-width: $screen-size-md) {
              ._edit-btn {
                display: flex;
              }
            }
          }
        }
      }
    }
  }

  ._overlay {
    display: flex;
    align-items: center;
    min-height: 4rem;
    margin: -0.8rem -1.2rem;

    ._inner {
      display: flex;
      align-items: center;
      padding: .8rem;
      line-height: 1.6;

      ._overlay-text {
        &:empty::before {
          color: $font-color-tertiary;
          content: attr(data-placeholder);
        }

        width: 100%;

        &:focus {
          outline: none;
        }
      }

      ._placeholder {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        height: 4rem;
        color: $font-color-tertiary;
        pointer-events: none;
      }
    }
  }
</style>
