<template>
  <svg
    class="SVGInline-svg"
    width="14"
    height="14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!--eslint-disable-->
    <path
      d="M0 2V12C0 12.5304 0.210714 13.0391 0.585786 13.4142C0.960859 13.7893 1.46957 14 2 14H7.5V0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2Z"
    />
    <!--eslint-enable-->
    <path d="M11 1H9V13H11V1Z" />
    <path d="M14 3H12.5V11H14V3Z" />
  </svg>
</template>

<script>
export default { name: 'WelcomeScreen' };
</script>

<style scoped lang="scss" type="text/scss">

</style>
