<template>
  <input
    :class="['top-bar-input', disabled ? '-disabled' : '']"
    :value="value"
    type="text"
    :style="inputStyle"
    :readonly="disabled"
    @input="updateMethod"
  >
</template>

<script>
export default {
  name: 'TopBarInput',
  props: {
    value: {
      type: String,
      required: true,
    },
    updateMethod: {
      type: Function,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    inputStyle() {
      return {
        width: `${Math.ceil(this.value.length * 7.5) + 16}px`,
        'max-width': '25rem',
      };
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .top-bar-input {
    width: 100%;
    height: $base-input-height;
    padding: .2rem .5rem;
    line-height: 21px;
    color: map_get($grey, 'darken-2');
    border: .1rem solid transparent;
    border-radius: .4rem;

    &:focus {
      outline: none;
    }

    &:not(.-disabled) {
      &:hover,
      &:focus {
        border: .1rem solid $border-color;
      }
    }
  }
</style>
