<template>
  <div class="goal-update-list">
    <m-endless-scroll-list @visible="loadMore">
      <template
        v-for="(update, index) in filteredUpdates.slice(0, amountOfItems)"
        :key="update.uid"
      >
        <goal-update-editor
          :initial-value="getContent(update)"
          :update="update"
          :last-update="lastUpdate(index)"
          :goal="goal"
          :goal-children="goalChildren"
          class="_item"
          :allow-comments="update.goalActivities.length === 0 || update.goal === null"
          :allow-goal-activity="update.goalActivities.length > 0 || update.goal === null"
          goals-clearable
          initial-disabled
          :read-only="readOnly"
        />
      </template>
    </m-endless-scroll-list>
  </div>
</template>

<script>
import GoalUpdateEditor from '@/components/goal/GoalUpdateEditor.vue';

export default {
  name: 'GoalUpdateList',
  props: {
    goal: {
      type: Object,
      required: true,
    },
    goalChildren: {
      type: Array,
      required: true,
    },
    updates: {
      type: Array,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  components: { GoalUpdateEditor },
  data() {
    return { loadingUpdates: false, amountOfItems: 10 };
  },
  computed: {
    filteredUpdates() {
      return this.updates.filter((u) => u.message !== '').reverse();
    },
  },
  methods: {
    lastUpdate(index) {
      if (index === 0) {
        return null;
      }
      return this.filteredUpdates[index - 1];
    },
    getContent(update) {
      if (update.goal === null) {
        return null;
      }
      return update.message;
    },
    loadMore() {
      this.amountOfItems += 10;
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .goal-update-list {
    ._item {
      margin-bottom: 1.2rem;
    }
  }
</style>
