export const idFromIndex = (index, level) => {
  const s = index.replace('root_', '');
  const res = parseInt(s.split('_')[level], 10);
  if (Number.isNaN(res)) {
    return 0;
  }

  return res;
};

export const indexToIdList = (index) => index.replace('root_', '').split('_').map((id) => parseInt(id, 10));
export const idListToIndex = (list) => ['root', ...list].join('_');

export const removeLastId = (index) => {
  const list = indexToIdList(index);
  list.splice(-1);
  return idListToIndex(list);
};

export const depth = (index) => index.replace('root_', '').split('_').length - 1;

const findIndex = (id, index) => {
  const s = index.split('_');
  const res = [];
  for (let i = 0; i < s.length; i++) {
    if (s[i] === `${id}`) {
      res.push(id);
      return res.join('_');
    }

    res.push(s[i]);
  }

  throw new Error('id not found');
};

const parent = (id, index) => {
  const s = index.replace('root_', '').split('_');
  for (let i = 0; i < s.length; i++) {
    if (`${id}` === s[i + 1]) {
      return parseInt(s[i], 10);
    }
  }

  return null;
};

const parentFromDepth = (anchor, anchorIndex, dragDepth) => {
  const anchorDepth = depth(anchorIndex);
  if (dragDepth > anchorDepth) {
    const p = idFromIndex(anchorIndex, anchorDepth);
    if (p === 0) {
      return null;
    }

    return p;
  }
  return parent(anchor, anchorIndex);
};

const getRealignIds = (dragItemIndex, newParentId, anchorId, anchorIndex) => dragItemIndex.filter((index) => {
  const d = depth(index);
  if (anchorId !== 0 && d !== depth(findIndex(anchorId, anchorIndex))) {
    return true;
  }

  const id = idFromIndex(index, d);
  const currentParent = parent(id, index);
  if (currentParent === null && newParentId === 0) {
    return false;
  }
  return currentParent !== newParentId;
}).map((index) => idFromIndex(index, depth(index)));

export const getCascadeOperation = (dragItemIndex, anchorIndex, dragTarget, dragDepth) => {
  const anchorId = idFromIndex(anchorIndex, dragDepth);
  const newParentId = parentFromDepth(anchorId, anchorIndex, dragDepth);
  let toRealignIds = getRealignIds(dragItemIndex, newParentId, anchorId, anchorIndex);
  let toSortIds = dragItemIndex.map((index) => idFromIndex(index, depth(index)));

  if (dragTarget.dragOver === true) {
    toRealignIds = toSortIds;
    toSortIds = [];
  }

  const isRealign = toRealignIds.length > 0;
  const isSort = dragTarget.dragOver !== true;
  const sortAfter = dragTarget.dragOverBottom === true;

  return {
    isRealign,
    isSort,
    anchorId,
    toRealignIds,
    newParentId,
    oldParentIdMap: dragItemIndex.reduce((res, next) => {
      const id = idFromIndex(next, depth(next));
      res[id] = parent(id, next);
      return res;
    }, {}),
    toSortIds,
    sortAfter,
  };
};

export const getOperationBasedOnPosition = (dragItemIndex, anchorIndex, position) => {
  const anchorId = idFromIndex(anchorIndex, depth(anchorIndex));
  let toRealignIds = [];
  let toSortIds = [];
  let newParentId = 0;

  switch (position) {
    case 'right':
    case 'left':
      newParentId = idFromIndex(anchorIndex, depth(anchorIndex) - 1);
      toRealignIds = getRealignIds(dragItemIndex, newParentId, anchorId, anchorIndex);
      toSortIds = dragItemIndex.map((index) => idFromIndex(index, depth(index)));
      break;
    case 'top':
      newParentId = idFromIndex(anchorIndex, depth(anchorIndex) - 1);
      toRealignIds = getRealignIds(dragItemIndex, newParentId, anchorId, anchorIndex);
      toSortIds = dragItemIndex.map((index) => idFromIndex(index, depth(index)));
      break;
    case 'bottom':
      newParentId = idFromIndex(anchorIndex, depth(anchorIndex));
      toRealignIds = getRealignIds(dragItemIndex, newParentId, anchorId, anchorIndex);
      toSortIds = dragItemIndex.map((index) => idFromIndex(index, depth(index)));
      break;
    default:
      throw new Error('position unknown. Must be in top, right, left or bottom.');
  }

  const isRealign = toRealignIds.length > 0;
  const isSort = ['right', 'left'].includes(position);
  const sortAfter = position !== 'left';

  return {
    isRealign,
    isSort,
    anchorId,
    toRealignIds,
    newParentId,
    oldParentIdMap: dragItemIndex.reduce((res, next) => {
      const id = idFromIndex(next, depth(next));
      res[id] = parent(id, next);
      return res;
    }, {}),
    toSortIds,
    sortAfter,
  };
};
