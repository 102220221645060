<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.5714 2.57143H3.42857C2.95519 2.57143 2.57143 2.95519 2.57143 3.42857V20.5714C2.57143 21.0447 2.95519 21.4286 3.42857 21.4286H20.5714C21.0447 21.4286 21.4286 21.0447 21.4286 20.5714V3.42857C21.4286 2.95519 21.0447 2.57143 20.5714 2.57143ZM3.42857 0H20.5714C22.465 0 24 1.53502 24 3.42857V20.5714C24 22.465 22.465 24 20.5714 24H3.42857C1.53502 24 0 22.465 0 20.5714V3.42857C0 1.53502 1.53502 0 3.42857 0Z"
      fill="currentColor"
    />
    <rect
      x="5.5"
      y="5.5"
      width="4.60361"
      height="5.54698"
      stroke="currentColor"
      stroke-width="2"
    />
    <rect
      x="5.5"
      y="14.9337"
      width="4.60361"
      height="3.66024"
      stroke="currentColor"
      stroke-width="2"
    />
    <rect
      x="18.5"
      y="18.594"
      width="4.60361"
      height="5.54698"
      transform="rotate(180 18.5 18.594)"
      stroke="currentColor"
      stroke-width="2"
    />
    <rect
      x="18.5"
      y="9.16025"
      width="4.60361"
      height="3.66024"
      transform="rotate(180 18.5 9.16025)"
      stroke="currentColor"
      stroke-width="2"
    />
  </svg>
</template>

<script>
export default { name: 'PanelsDashboard' };
</script>

<style scoped lang="scss" type="text/scss">

</style>
