<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="2"
      y="2"
      width="20"
      height="20"
      rx="1"
      fill="#009E5E"
    />
    <rect
      x="7.33301"
      y="4.66667"
      width="2.66667"
      height="14.6667"
      rx="0.5"
      fill="white"
    />
    <rect
      x="4.66699"
      y="7.33334"
      width="14.6667"
      height="2.66667"
      rx="0.5"
      fill="white"
    />
  </svg>
</template>

<script>
export default { name: 'GoogleSheet' };
</script>

<style scoped lang="scss" type="text/scss">

</style>
