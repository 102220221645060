export const directRelationFn = ({ isFilterMode = false }) => ({ entity, scope }) => {
  let key = scope.directProperty.edgeName;
  if (scope.directProperty.edgeAlias !== undefined && scope.directProperty.edgeAlias !== '') {
    key = scope.directProperty.edgeAlias;
  }
  const selectedValue = entity[key];
  if (selectedValue === undefined) {
    return false;
  }

  if (Array.isArray(selectedValue)) {
    if (scope.isEmpty) {
      return selectedValue.length === 0;
    }
    if (scope.relation === null) {
      return true;
    }
    return selectedValue.filter((v) => v.uid === scope.relation.uid).length > 0;
  }

  if (scope.isEmpty) {
    return selectedValue === null;
  }
  if (scope.relation === null && isFilterMode) {
    return true;
  }
  if (selectedValue === null) {
    return false;
  }
  return selectedValue.uid === scope.relation.uid;
};
