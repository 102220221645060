<template>
  <r-c-layout
    class="resource-overview-page"
    :resource-center-router="resourceCenterRouter"
  >
    <m-content
      :padding-x="10"
      :padding-y="10"
      :style="{ overflow: 'auto' }"
    >
      <div
        v-for="(item, index) in items"
        :key="index"
        class="_item"
      >
        <onboarding-card-item
          :icon="item.icon"
          :progress="item.progress"
          :href="item.href"
          :title="item.title"
          :sub-title="item.subTitle"
          @click="handleClick(item)"
        />
      </div>
    </m-content>
    <div class="_bottom-links">
      <m-card-item
        icon="message-heart"
        @click="showSupportForm"
      >
        {{ $t('helpButton.contactSupport') }}
      </m-card-item>
      <m-card-item
        v-if="userIsAdmin"
        icon="customer-service"
        @click="showSalesForm"
      >
        {{ $t('helpButton.chatWithSales') }}
      </m-card-item>
      <app-feedback-dropdown
        :label="$t('helpButton.productFeedback')"
        :placeholder="$t('helpButton.placeholderFeedback')"
        topic="general"
        trigger-component="m-card-item"
      />
      <m-card-item
        icon="star"
        class="_release-notes"
        @click="showReleaseNotes"
      >
        <div
          class="_content"
          :style="{ display: 'flex', alignItems: 'center' }"
        >
          <div class="_title">
            {{ $t('helpButton.whatsNew') }}
          </div>
          <div
            v-if="unreadReleaseNotes.length > 0"
            class="_badge"
            :style="{ marginLeft: '.6rem' }"
          >
            <m-badge
              :number="unreadReleaseNotes.length"
              :background-color="$colors.red.base"
            />
          </div>
        </div>
      </m-card-item>
      <m-tooltip
        v-if="showCompanyInfoInMenu"
        placement="left"
      >
        <m-card-item
          icon="infoCircle"
          @click="showCompanyInfo"
        >
          <div class="_company-info-title">
            {{ companyInfoTitle }}
          </div>
        </m-card-item>
        <template
          v-if="companyInfoTitle.length > 25"
          #title
        >
          {{ companyInfoTitle }}
        </template>
      </m-tooltip>
      <m-link
        :href="$t('rcOverview.legalInformationLink')"
        class="_sub-links"
        target="_blank"
      >
        <m-card-item
          icon="audit"
        >
          {{ $t('rcOverview.legalInformation') }}
          <m-icon
            type="external-link"
            size="12"
            :style="{ marginLeft: '.4rem', marginTop: '.2rem' }"
          />
        </m-card-item>
      </m-link>
      <m-divider xxs />
      <m-card-item
        super-light
        class="_sub-links"
        no-hover
        :clickable="false"
      >
        Mooncamp 1.{{ semverVersion }}
      </m-card-item>
    </div>
  </r-c-layout>
</template>

<script>
import AppFeedbackDropdown from '@/components/app-feedback/AppFeedbackDropdown.vue';
import OnboardingCardItem from '@/components/resource-center/OnboardingCardItem.vue';
import RCLayout from '@/components/resource-center/RCLayout.vue';
import useAccess from '@/composables/access/access';
import useAccountSettings from '@/composables/logged-in-user-account/account-settings';
import useCompanyInfo from '@/composables/logged-in-user-account/company-info';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useOnboardingMarker from '@/composables/onboarding/onboarding-marker';
import useOnboardingSteps from '@/composables/onboarding/onboarding-steps';
import usePersonalAppSettings from '@/composables/logged-in-user/personal-app-settings';
import useReleaseNotes from '@/composables/release-notes/release-notes';
import { EventBus } from '@/lib/event-bus';
import {
  RESOURCE_CENTER_OKR_RESOURCES,
  RESOURCE_CENTER_ONBOARDING_GUIDE,
  RESOURCE_CENTER_VIDEOS,
} from '@/lib/constants';
import { accessGroupFlag, onboardingGuideSteps } from 'shared/constants.json';
import { environmentVariable, resolveEnvironmentVariable } from '@/lib/env';

export default {
  name: 'RcOverview',
  props: {
    resourceCenterRouter: {
      type: Object,
      required: true,
    },
  },
  components: { AppFeedbackDropdown, OnboardingCardItem, RCLayout },
  setup() {
    const { userHasRight } = useAccess();
    const { loggedInUser } = useLoggedInUser();
    const personalAppSettingsSvc = usePersonalAppSettings(loggedInUser);
    const { unreadReleaseNotes } = useReleaseNotes(personalAppSettingsSvc, loggedInUser);
    const { loggedInUserAccount } = useLoggedInUserAccount();
    const { showCompanyInfoInMenu, companyInfoTitle } = useCompanyInfo();

    const accountSettingsSvc = useAccountSettings();
    const accessSvc = useAccess();
    const onboardingMarker = useOnboardingMarker(loggedInUserAccount, accountSettingsSvc);
    const { progress } = useOnboardingSteps(
      onboardingMarker,
      {},
      accountSettingsSvc,
      accessSvc,
    );

    return { userHasRight, unreadReleaseNotes, showCompanyInfoInMenu, companyInfoTitle, onboardingProgress: progress, onboardingMarker };
  },
  data() {
    return {
      RESOURCE_CENTER_ONBOARDING_GUIDE,
      RESOURCE_CENTER_VIDEOS,
      RESOURCE_CENTER_OKR_RESOURCES,
    };
  },
  computed: {
    userIsAdmin() {
      return this.userHasRight([accessGroupFlag.accountWriteAccess]);
    },
    semverVersion() {
      return resolveEnvironmentVariable(environmentVariable.SEMVER);
    },
    items() {
      const onboardingGuide = {
        title: this.$t('rcOverview.onboardingGuide.title'),
        icon: 'book',
        onclick: () => this.resourceCenterRouter.navigate(RESOURCE_CENTER_ONBOARDING_GUIDE),
        progress: this.onboardingProgress,
      };
      const items = [
        {
          title: this.$t('rcOverview.videos.title'),
          subTitle: this.$t('rcOverview.videos.subTitle'),
          icon: 'play-circle-filled',
          href: this.$t('rcOverview.videos.watchDemoLink'),
          onclick: () => {
            this.onboardingMarker.markAsDone([onboardingGuideSteps.videoTutorials]);
          },
        },
        {
          title: this.$t('rcOverview.videoFAQs.title'),
          subTitle: this.$t('rcOverview.videoFAQs.subTitle'),
          icon: 'play-circle-filled',
          href: this.$t('rcOverview.videoFAQs.link'),
        },
        {
          title: this.$t('rcOverview.helpCenter.title'),
          subTitle: this.$t('rcOverview.helpCenter.subTitle'),
          icon: 'question-circle-filled',
          href: this.$t('helpButton.helpCenterLink'),
        },
        {
          title: this.$t('rcOverview.resources.title'),
          subTitle: this.$t('rcOverview.resources.subTitle'),
          icon: 'read-filled',
          onclick: () => this.resourceCenterRouter.navigate(RESOURCE_CENTER_OKR_RESOURCES),
        },
      ];

      if (this.onboardingProgress === 100) {
        return [...items, onboardingGuide];
      }

      return [onboardingGuide, ...items];
    },
  },
  methods: {
    handleClick(item) {
      if (item.onclick === undefined) {
        return;
      }

      item.onclick();
    },
    showCompanyInfo() {
      EventBus.$emit('show-company-info');
    },
    showSupportForm() {
      EventBus.$emit('show-support-form');
    },
    showSalesForm() {
      EventBus.$emit('show-sales-form');
    },
    showReleaseNotes() {
      EventBus.$emit('show-release-notes');
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  @import "shared/assets/scss/box-shadow";

  .resource-overview-page {
    display: flex;
    flex-direction: column;
    height: 100%;

    ._item {
      &:not(:last-child) {
        margin-bottom: 1.6rem;
      }

      ._top {
        margin-bottom: .4rem;
        font-weight: $font-weight-medium;
      }

      ._bottom {
        height: 2rem;
        color: $font-color-secondary;
      }
    }

    ._bottom-links {
      padding: .4rem 0;
      margin-top: auto;
      border-top: 1px solid $border-color;

      ._company-info-title {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
</style>
