<template>
  <div :class="classes">
    <full-screen-spinner
      v-if="initiallyLoading"
      class="_spinner"
    />
    <template v-else>
      <m-endless-scroll-list
        :key="`${listLoading}`"
        @visible="loadMore"
      >
        <template
          v-for="item in sorted"
          :key="item.uid"
        >
          <meeting-list-item
            :meeting="item"
            :props="props"
            :class="['_item', itemClass]"
            :selected="selectedIds.includes(item.uid)"
            :selected-secondary="selectedIds.includes(item.uid) && item.accessRight === 'read'"
            :selected-ids="selectedIds"
            :create-loading="createLoading"
            @select-row="handleSelectRow"
            @selection-right-click="$emit('selection-right-click', $event)"
            @duplicated="load(false)"
            @create="$emit('create', $event)"
          />
          <m-divider
            v-if="bordered || $store.state.breakpoint.smAndDown"
            :key="`divider_${item.uid}`"
            small
          />
        </template>
      </m-endless-scroll-list>
      <m-content
        v-if="sorted.length === 0 && (!initiallyLoading && !listLoading)"
        :padding-x="4"
        :padding-y="4"
        class="_empty"
      >
        {{ $t('general.emptyList') }}
      </m-content>
      <m-btn
        hide-border
        light
        full-width
        block
        :style="{ justifyContent: 'flex-start' }"
        :loading="createLoading"
        icon="plus"
        @click="$emit('create')"
      >
        {{ $t('general.new') }}
      </m-btn>
    </template>
  </div>
</template>

<script>
import FullScreenSpinner from 'shared/components/FullScreenSpinner.vue';
import MeetingListItem from '@/components/meeting/MeetingListItem.vue';
import useDebounce from '@/composables/debounce';
import useViewParamsProps from '@/composables/view-params/view-params-props';
import { MeetingFilterHandler } from '@/lib/filter/meeting/handler';
import { RESULT } from 'shared/api/query/constants';
import { VIEWS_SERVICE } from '@/lib/constants';
import { inject } from 'vue';
import { mapActions, mapState } from 'vuex';
import { meeting as meetingConfig } from 'shared/api/query/configs.json';
import { meetingList } from '@/api/query/meeting';
import { sortByArray } from 'shared/lib/sort';

export default {
  name: 'MeetingsExplorerList',
  props: {
    bordered: {
      type: Boolean,
      default: false,
    },
    selectedIds: {
      type: Array,
      required: true,
    },
    itemClass: {
      type: String,
      required: true,
    },
    searchTerm: {
      type: String,
      default: '',
    },
    createLoading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['selection-right-click', 'create', 'select-row'],
  components: { FullScreenSpinner, MeetingListItem },
  setup() {
    const viewsService = inject(VIEWS_SERVICE);
    const { props } = useViewParamsProps(viewsService.currentView);
    const { debounce } = useDebounce();
    return {
      debounce,
      selectedView: viewsService.currentView,
      props,
    };
  },
  data() {
    return {
      initiallyLoading: true,
      itemsPerPage: 30,
      page: 1,
      loadMoreAmount: 10,
      listItems: [],
      totalAmount: 0,
      listLoading: false,
    };
  },
  computed: {
    ...mapState({ meetings: (state) => state.meeting }),
    sorted() {
      const ids = this.listItems.map((g) => g.uid);
      return this.meetings.filter((g) => ids.includes(g.uid)).sort(sortByArray(this.listItems));
    },
    classes() {
      return [
        'meetings-explorer-list',
        this.listLoading && !this.initiallyLoading ? '-loading' : '',
        this.bordered ? '-bordered' : '',
      ];
    },
    order() {
      return this.selectedView.params.order;
    },
    filter() {
      return this.selectedView.params.filter;
    },
    gqlFilterObject() {
      const handler = new MeetingFilterHandler();
      return handler.Translate(this.filter);
    },
  },
  methods: {
    ...mapActions(['getEntitiesV2']),
    handleSelectRow(data) {
      this.$emit('select-row', data);
    },
    load(toggleLoading) {
      if (toggleLoading) {
        this.listLoading = true;
      }

      this.getEntitiesV2({
        queries: meetingList({
          pagination: {
            page: this.page,
            itemsPerPage: this.itemsPerPage,
            countAlias: 'count',
          },
          searchTerm: this.searchTerm,
          filter: this.gqlFilterObject.filterTree,
          varBlocks: this.gqlFilterObject.varBlocks,
          order: this.order,
          alias: RESULT,
        }),
        model: meetingConfig.model,
        options: { alias: RESULT },
        mutation: 'MEETINGS_RETRIEVED',
      }).then((response) => {
        this.listLoading = false;
        this.initiallyLoading = false;
        if (response.status !== 200) {
          return;
        }

        this.listItems = response.data[RESULT];
        this.totalAmount = response.data.count[0].count;
      });
    },
    loadMore() {
      if (this.totalAmount <= this.itemsPerPage) {
        return;
      }

      this.itemsPerPage += this.loadMoreAmount;
      this.load(false);
    },
  },
  watch: {
    order(val, oldVal) {
      if (JSON.stringify(val) === JSON.stringify(oldVal)) {
        return;
      }
      this.debounce(this.load, 700);
    },
    searchTerm(val, oldVal) {
      if (val === oldVal) {
        return;
      }
      this.debounce(this.load, 700);
    },
    selectedCycles(val, oldVal) {
      if (JSON.stringify(val) === JSON.stringify(oldVal)) {
        return;
      }
      this.debounce(this.load, 1000);
    },
    gqlFilterObject: {
      handler(val, oldVal) {
        if (JSON.stringify(val) === JSON.stringify(oldVal)) {
          return;
        }
        this.debounce(this.load, 1000);
      },
      deep: true,
    },
  },
  created() {
    this.load();
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  @import "shared/assets/scss/loading";

  .meetings-explorer-list {
    ._empty {
      margin: .8rem 0 .8rem .6rem;
      color: $font-color-secondary;
    }

    &.-loading {
      @include loading();
    }

    ._item {
      margin: .4rem 0;
    }

    &.-bordered {
      ._item {
        margin: 0;
      }
    }
  }
</style>
