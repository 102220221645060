<template>
  <form-editor
    :model="model"
    :entity="formTemplate"
    :entity-by-id="formTemplateById"
    :side-nav-items="sideNavItems"
    :edit-page="CREATE_FORM_TEMPLATE"
    :settings-page="FORM_TEMPLATE_LANGUAGE_SETTINGS"
    :create-url="createUrl"
    :languages="languages"
    :lang="lang"
    :all-questions-url="allQuestionsUrl"
    :template-list-url="templateListUrl"
    :question-bank-template-detail-url="questionBankTemplateDetailUrl"
  />
</template>

<script>
import FormEditor from '@/components/FormEditor.vue';
import useFormTemplate from '@/composables/form/form-template';
import {
  CREATE_FORM_TEMPLATE,
  FORM_TEMPLATE_FIELD_FACTORS,
  FORM_TEMPLATE_LANGUAGE_SETTINGS, QUESTION_BANK_FORM_TEMPLATES,
  QUESTION_BANK_TEMPLATE_DETAILS_FORM_TEMPLATES, QUESTION_BANK_TEMPLATE_LIST_FORM_TEMPLATES,
} from '@/route-names';
import { formTemplate } from 'shared/api/query/configs.json';
import { formTemplateById } from 'shared/api/query/form-template';
import { formTemplateType, routeName } from 'shared/constants.json';

export default {
  name: 'CreateFormTemplate',
  components: { FormEditor },
  setup() {
    const { lang, formTemplate } = useFormTemplate();
    return { lang, formTemplate };
  },
  data() {
    return {
      formTemplateById,
      model: formTemplate.model,
      CREATE_FORM_TEMPLATE,
      FORM_TEMPLATE_LANGUAGE_SETTINGS,
    };
  },
  computed: {
    createUrl() {
      return {
        name: CREATE_FORM_TEMPLATE,
        params: { formTemplateId: this.formTemplate.uid },
        query: { lang: this.lang },
      };
    },
    sideNavItems() {
      const items = [
        {
          to: {
            name: CREATE_FORM_TEMPLATE,
            params: { formTemplateId: this.formTemplate.uid },
            query: { lang: this.lang },
          },
          icon: 'edit',
          title: this.$t('formEditor.editForm'),
          active: this.$route.name === CREATE_FORM_TEMPLATE,
        },
        {
          to: {
            name: FORM_TEMPLATE_LANGUAGE_SETTINGS,
            params: { formTemplateId: this.formTemplate.uid },
            query: { lang: this.lang },
          },
          icon: 'global',
          title: this.$t('formEditor.languageSettings'),
          active: this.$route.name === FORM_TEMPLATE_LANGUAGE_SETTINGS,
        },
        {
          to: {
            name: QUESTION_BANK_FORM_TEMPLATES,
            params: { formTemplateId: this.formTemplate.uid },
            query: { lang: this.lang },
          },
          icon: 'read',
          title: this.$t('formEditor.questionBank'),
          active: [
            QUESTION_BANK_FORM_TEMPLATES,
            QUESTION_BANK_TEMPLATE_LIST_FORM_TEMPLATES,
            QUESTION_BANK_TEMPLATE_DETAILS_FORM_TEMPLATES,
          ].includes(this.$route.name),
        },
      ];
      if (this.formTemplate.type === formTemplateType.instantFeedback) {
        items.push(
          {
            to: {
              name: routeName.formTemplateTags,
              params: { formTemplateId: this.formTemplate.uid },
              query: { lang: this.lang },
            },
            icon: 'tags',
            title: this.$t('formEditor.tags'),
            active: this.$route.name === routeName.formTemplateTags,
          },
        );
      }

      if (this.formTemplate.type === formTemplateType.survey) {
        items.push(
          {
            to: {
              name: FORM_TEMPLATE_FIELD_FACTORS,
              params: { formTemplateId: this.formTemplate.uid },
              query: { lang: this.lang },
            },
            icon: 'cluster',
            title: this.$t('formEditor.fieldFactors'),
            active: this.$route.name === FORM_TEMPLATE_FIELD_FACTORS,
          },
        );
      }

      return items;
    },
    allQuestionsUrl() {
      return {
        name: QUESTION_BANK_FORM_TEMPLATES,
        params: { formTemplateId: this.formTemplate.uid },
        query: { lang: this.lang },
      };
    },
    templateListUrl() {
      return {
        name: QUESTION_BANK_TEMPLATE_LIST_FORM_TEMPLATES,
        params: { formTemplateId: this.formTemplate.uid },
        query: { lang: this.lang },
      };
    },
  },
  methods: {
    questionBankTemplateDetailUrl({ entityId, templateId }) {
      return {
        name: routeName.questionBankTemplateDetailsFormTemplates,
        params: { templateId, formTemplateId: entityId },
        query: { lang: this.lang },
      };
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">

</style>
