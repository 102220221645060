<template>
  <div class="property-editor">
    <div class="_label">
      {{ textByLang(prop.label, userLang) }}
    </div>
    <div class="_item">
      <!-- FIXME: The user-picker is broken. Maybe we don't need to use special cases here and could ran with both exceptions via <property-form-item> instead? -->
      <user-picker
        v-if="prop.edgeName === meetingConfig.edges.participants"
        ref="userPicker"
        v-model:value="participants"
        full-width
        item-text="title"
        value-key="uid"
        multiple
        return-object
        hide-arrow
        match-trigger-width
        automatic-color
        popup
        show-search
        auto-focus
        tags
        show-description
        keep-open-on-click
        :placeholder="$t('general.empty')"
      />
      <m-date-picker
        v-else-if="prop.edgeName === meetingConfig.edges.date"
        ref="datePicker"
        :value="fromISO(date)"
        full-width
        :date-time="DateTime"
        :placeholder="$t('general.empty')"
        :locale="userLang"
        show-description
        include-time
        @input="updateDate"
      />
      <property-form-item
        v-else
        :property="prop"
        :property-values="[value]"
        full-width
        auto-focus
        @change="updateValue"
      />
    </div>
    <div class="_actions">
      <m-btn
        class="_btn"
        hide-border
        small
        @click="$emit('close')"
      >
        {{ $t('general.cancel') }}
      </m-btn>
      <m-btn
        color="primary"
        class="_btn"
        small
        @click="save"
      >
        {{ $t('general.save') }}
      </m-btn>
    </div>
  </div>
</template>

<script>
import PropertyFormItem from '@/components/PropertyFormItem.vue';
import UserPicker from '@/components/UserPicker.vue';
import useAccountSettings from '@/composables/logged-in-user-account/account-settings';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import { DateTime } from 'luxon';
import { fromISO, toISO } from 'shared/lib/time';
import { mapActions } from 'vuex';
import { children as meetingChildren } from '@/api/query/meeting';
import { meeting as meetingConfig } from 'shared/api/query/configs.json';
import { textByLang } from 'shared/lib/language';

export default {
  name: 'PropertyEditor',
  props: {
    entities: {
      type: Array,
      required: true,
    },
    prop: {
      type: Object,
      required: true,
    },
    successMessage: {
      type: String,
      default: '',
    },
  },
  emits: ['close', 'edited'],
  components: {
    PropertyFormItem,
    UserPicker,
  },
  setup() {
    const { accountSettings } = useAccountSettings();
    const { userLang } = useLoggedInUser();

    return { accountSettings, userLang };
  },
  data() {
    return {
      loading: false,
      meetingConfig,
      value: null,
      textByLang,
      participants: [],
      date: null,
      DateTime,
      fromISO,
    };
  },
  computed: {
    isValid() {
      switch (this.prop.edgeName) {
        case meetingConfig.edges.participants:
          return this.participants.length > 0;
        case meetingConfig.edges.date:
          return this.date !== null;
        default:
          return this.value !== null;
      }
    },
  },
  methods: {
    ...mapActions(['mutateEntities']),
    updateValue(value) {
      this.value = value;
    },
    updateDate(date) {
      this.date = toISO(date);
    },
    save() {
      if (!this.isValid) {
        return;
      }

      this.loading = true;
      this.mutateEntities({
        entities: this.prepare(),
        model: meetingConfig.model,
        attributes: meetingChildren,
        beforeCommit: this.beforeCommit,
        mutation: 'MEETINGS_UPDATED',
      }).then((response) => {
        this.loading = false;
        if (response.status !== 200) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
          return;
        }

        if (this.successMessage !== '') {
          this.$showSnackbar({ color: 'success', message: this.successMessage });
        }
        this.$emit('close');
        this.$emit('edited');
      });
    },
    prepare() {
      switch (this.prop.edgeName) {
        case meetingConfig.edges.participants:
          return this.entities.map((g) => ({
            ...g,
            participants: this.participants,
          }));
        case meetingConfig.edges.date:
          return this.entities.map((g) => ({
            ...g,
            date: this.date,
          }));
        default:
          return this.entities.map((g) => ({
            ...g,
            propertyValues: this.transformProperties({ propertyValues: g.propertyValues, value: this.value }),
          }));
      }
    },
    transformProperties({ propertyValues, value }) {
      return propertyValues.map((p) => {
        if (p.property.uid !== value.property.uid) {
          return p;
        }

        return {
          ...p,
          ...value,
        };
      });
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .property-editor {
    min-width: 25rem;

    ._label {
      margin-bottom: .2rem;
      font-size: $font-size-4;
      color: $font-color-secondary;
    }

    ._item {
      margin-bottom: 1rem;
    }

    ._actions {
      display: flex;
      justify-content: flex-end;

      ._btn {
        margin-left: .4rem;
      }
    }
  }
</style>
