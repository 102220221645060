const base = 1.6;

export const fontSizes = {
  10: `${base * 2.5}rem`, // 40 px
  9: `${base * 1.875}rem`, // 30 px
  8: `${base * 1.5}rem`, // 24 px
  7: `${base * 1.25}rem`, // 20 px
  6: `${base * 1.125}rem`, // 18 px
  5: `${base * 1}rem`, // 16 px
  4: `${base * 0.875}rem`, // 14 px
  3: `${base * 0.8125}rem`, // 13 px
  2: `${base * 0.75}rem`, // 12 px
  1: `${base * 0.6875}rem`, // 11 px
  0: `${base * 0.625}rem`, // 10 px
  get h1() {
    return this[10];
  },
  get h2() {
    return this[9];
  },
  get h3() {
    return this[8];
  },
  get h4() {
    return this[6];
  },
  get h5() {
    return this[5];
  },
  get h6() {
    return this[4];
  },
};
