export default {
  methods: {
    open({ uid, paramKey = 'uid', mobileRouteName, name = undefined }) {
      if (this.$store.state.breakpoint.smAndDown) {
        this.$router.push({ name: mobileRouteName, params: { [paramKey]: uid } });
        return;
      }

      let n = name;
      if (typeof n === 'undefined') {
        n = this.$route.name;
      }

      const query = { ...this.$route.query, [paramKey]: uid };
      this.$router.push({ name: n, query });
    },
  },
};
