<template>
  <m-sider
    :collapsed="collapsed"
    class="settings-navigation"
    bordered
    :width="$store.state.breakpoint.smAndDown ? 'calc(100vw - 5rem)' : '24rem'"
  >
    <div class="_heading">
      <m-btn
        icon="left"
        :to="lastVisit"
        hide-border
        block
        large
        :button-style="{ borderRadius: 0, justifyContent: 'flex-start' }"
        :icon-color="$colors.grey.lighten1"
      >
        <div class="_inner">
          {{ $t('settingsNavigation.title') }}
        </div>
      </m-btn>
    </div>
    <sub-heading :title="$t('settingsNavigation.account')" />
    <nav-items :items="userItems" />
    <sub-heading
      v-if="generalItems.length > 0"
      :title="$t('settingsNavigation.workspace')"
    />
    <nav-items :items="generalItems" />
    <template v-if="goalItems.length > 0">
      <sub-heading :title="$t('settingsNavigation.goals')" />
      <nav-items :items="goalItems" />
    </template>
    <template v-if="featureItems.length > 0">
      <sub-heading :title="$t('settingsNavigation.features')" />
      <nav-items :items="featureItems" />
    </template>
  </m-sider>
</template>

<script>
import MSider from '@/components/layout/MSider.vue';
import NavItems from '@/components/navigation/NavItems.vue';
import SubHeading from 'shared/components/SubHeading.vue';
import useAccess from '@/composables/access/access';
import useExpand from '@/composables/expand';
import useLocalStorage from '@/composables/local-storage/local-storage';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useMSTeams from '@/composables/msteams';
import {
  ACCESS_GROUP_LIST,
  ACCOUNT_SETTINGS, FORM_TEMPLATE_LIST,
  PROFILE_SETTINGS,
  USER_FIELDS_NO_SELECTION,
  USER_LIST,
} from '@/route-names';
import { accessGroupFlag, moduleFlag, routeName } from 'shared/constants.json';
import { computed } from 'vue';
import { mapState } from 'vuex';

export default {
  name: 'SettingsNavigation',
  props: {
    collapsed: {
      type: Boolean,
      default: false,
    },
    lastVisit: {
      type: [Object, String],
      required: true,
    },
  },
  components: { SubHeading, MSider, NavItems },
  setup() {
    const {
      accountHasFeature,
      userHasRight,
    } = useAccess();
    const { loggedInUserAccount } = useLoggedInUserAccount();
    const { isMsTeams } = useMSTeams();
    const identifier = { getId: (o) => o.id };
    const keyMaker = {
      getKey() {
        return computed(() => `${loggedInUserAccount.value.uid}_settings_navigation_expand`);
      },
    };
    const localStorageSvc = useLocalStorage(localStorage, keyMaker);
    const expandSvc = useExpand(identifier, localStorageSvc.data);
    return {
      accountHasFeature,
      userHasRight,
      account: loggedInUserAccount,
      isMsTeams,
      expandSvc,
    };
  },
  computed: {
    ...mapState({
      clientInfo: (state) => state.clientInfo,
      breakpoint: (state) => state.breakpoint,
    }),
    anyDataintegrationEnabled() {
      const dataIntegrationSettings = [
        this.account.accountSettings.usesSheets,
        this.account.accountSettings.usesJira,
        this.account.accountSettings.usesExcel,
        this.account.accountSettings.usesAsana,
        this.account.accountSettings.usesHubspot,
        this.account.accountSettings.usesSalesforce,
      ];
      return dataIntegrationSettings.some((s) => s === true);
    },
    userItems() {
      return [
        {
          to: { name: PROFILE_SETTINGS },
          title: this.$t('settingsNavigation.profile'),
          icon: 'user',
          active: this.$route.name === PROFILE_SETTINGS,
        },
        {
          to: { name: routeName.myNotifications },
          title: this.$t('settingsNavigation.myNotifications'),
          icon: 'bell',
          active: this.$route.name === routeName.myNotifications,
        },
      ];
    },
    generalItems() {
      const nav = [];
      if (this.userHasRight([accessGroupFlag.accountWriteAccess])) {
        nav.push({
          to: { name: ACCOUNT_SETTINGS },
          title: this.$t('settingsNavigation.general'),
          icon: 'shop',
          active: this.$route.name === ACCOUNT_SETTINGS,
        });
      }
      if (this.userHasRight([accessGroupFlag.userWriteAccess])) {
        nav.push({
          to: { name: USER_LIST },
          title: this.$t('settingsNavigation.userList'),
          icon: 'user',
          active: this.$route.name === USER_LIST,
        });
      }
      if (this.userHasRight([accessGroupFlag.spaceWriteAccess])) {
        nav.push({
          to: { name: routeName.spaceList },
          title: this.$t('settingsNavigation.spaceList'),
          icon: 'team',
          active: this.$route.name === routeName.spaceList,
        });
      }
      if (this.userHasRight([accessGroupFlag.accessGroupWriteAccess])) {
        nav.push({
          to: { name: ACCESS_GROUP_LIST },
          title: this.$t('settingsNavigation.accessControl'),
          icon: 'lock',
          active: this.$route.name === ACCESS_GROUP_LIST,
        });
      }
      if (this.userHasRight([accessGroupFlag.propertyWriteAccess])) {
        nav.push({
          to: { name: USER_FIELDS_NO_SELECTION },
          title: this.$t('settingsNavigation.userFields'),
          icon: 'tags',
          active: this.$route.name === USER_FIELDS_NO_SELECTION,
        });
      }
      if (this.userHasRight([accessGroupFlag.accountWriteAccess]) || this.anyDataintegrationEnabled) {
        nav.push({
          to: { name: routeName.integrationSettings },
          title: this.$t('views.integrationSettings'),
          icon: 'api',
          active: this.$route.name === routeName.integrationSettings,
        });
      }
      if (this.userHasRight([accessGroupFlag.accountWriteAccess])) {
        nav.push({
          to: { name: routeName.identityAndProvisioning },
          title: this.$t('settingsNavigation.identityAndProvisioning'),
          icon: 'safety-certificate',
          active: this.$route.name === routeName.identityAndProvisioning,
        });
      }
      if (this.showPlans) {
        nav.push({
          to: { name: routeName.plans },
          title: this.$t('settingsNavigation.plans'),
          icon: 'plan',
          active: this.$route.name === routeName.plans,
        });
      }
      return nav;
    },
    goalAccess() {
      return this.accountHasFeature([moduleFlag.goals]) && this.userHasRight([accessGroupFlag.goalCycleWriteAccess]);
    },
    goalItems() {
      const nav = [];
      if (this.goalAccess === true) {
        nav.push(...[
          {
            to: { name: routeName.goalSettings },
            title: this.$t('settingsNavigation.goalSettings'),
            icon: 'settings',
            active: this.$route.name === routeName.goalSettings,
          },
          {
            to: { name: routeName.goalCycles },
            title: this.$t('settingsNavigation.goalCycles'),
            icon: 'retweet',
            active: this.$route.name === routeName.goalCycles,
          },
          {
            to: { name: routeName.goalTypes },
            title: this.$t('settingsNavigation.goalTypes'),
            icon: 'database',
            active: this.$route.name === routeName.goalTypes,
          },
        ]);
        nav.push({
          to: { name: routeName.goalStatusSettings },
          title: this.$t('settingsNavigation.goalStatusSettings'),
          icon: 'status',
          active: this.$route.name === routeName.goalStatusSettings,
        });
      }
      return nav;
    },
    surveyAccess() {
      return this.accountHasFeature([moduleFlag.surveys]) && this.userHasRight([accessGroupFlag.formTemplateWriteAccess]);
    },
    instantFeedbacksAccess() {
      return this.accountHasFeature([moduleFlag.instantFeedbacks]) && this.userHasRight([accessGroupFlag.instantFeedbackTemplateAccess]);
    },
    featureItems() {
      const nav = [];
      if (this.surveyAccess === true) {
        nav.push({
          to: { name: FORM_TEMPLATE_LIST },
          title: this.$t('settingsNavigation.formTemplateList'),
          icon: 'solution',
          active: this.$route.name === FORM_TEMPLATE_LIST,
        });
      }
      if (this.instantFeedbacksAccess === true) {
        nav.push({
          to: { name: routeName.instantFeedbackTemplateList },
          title: this.$t('settingsNavigation.instantFeedbackTemplateList'),
          icon: 'notification',
          active: this.$route.name === routeName.instantFeedbackTemplateList,
        });
      }
      return nav;
    },
    showPlans() {
      if (this.breakpoint.smAndDown && this.isMsTeams) {
        return false;
      }

      return this.userHasRight([accessGroupFlag.accountWriteAccess]);
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .settings-navigation {
    height: 100vh;
    overflow: auto;

    ._heading {
      margin-top: 1rem;

      ._inner {
        margin-left: 1rem;
        font-size: $font-size-7;
      }
    }
  }
</style>
