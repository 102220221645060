<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 87 87"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M43.855 86.7771C67.6033 86.7771 86.855 67.5254 86.855 43.7771C86.855 20.0289 67.6033 0.7771 43.855 0.7771C20.1068 0.7771 0.85498 20.0289 0.85498 43.7771C0.85498 67.5254 20.1068 86.7771 43.855 86.7771Z"
      fill="#42ABCF"
    />
    <path
      d="M66.9963 80.0253C78.9385 72.3854 86.855 59.0057 86.855 43.777C86.855 20.0288 67.6033 0.776978 43.855 0.776978C33.9816 0.776978 24.8854 4.10467 17.6268 9.6997C16.0335 14.8058 15.175 20.2361 15.175 25.867C15.175 55.006 38.1653 78.7753 66.9963 80.0253Z"
      fill="#A6DAF1"
    />
    <path
      d="M35.885 43.977C42.9211 43.977 48.625 38.2731 48.625 31.237C48.625 24.2009 42.9211 18.497 35.885 18.497C28.8489 18.497 23.145 24.2009 23.145 31.237C23.145 38.2731 28.8489 43.977 35.885 43.977Z"
      fill="#42ABCF"
    />
    <path
      d="M64.985 32.237C68.503 32.237 71.355 29.3851 71.355 25.867C71.355 22.3489 68.503 19.497 64.985 19.497C61.4669 19.497 58.615 22.3489 58.615 25.867C58.615 29.3851 61.4669 32.237 64.985 32.237Z"
      fill="#42ABCF"
    />
    <path
      d="M43.435 78.687C48.7148 78.687 52.995 74.4068 52.995 69.127C52.995 63.8472 48.7148 59.567 43.435 59.567C38.1552 59.567 33.875 63.8472 33.875 69.127C33.875 74.4068 38.1552 78.687 43.435 78.687Z"
      fill="#42ABCF"
    />
  </svg>
</template>

<script>
export default { name: 'Mooncamp' };
</script>
