<template>
  <div>
    <div
      ref="trigger"
      class="m-tooltip _trigger"
      @mouseover="open"
      @mouseleave="hide"
    >
      <slot />
    </div>
    <teleport
      v-if="val"
      ref="inner2"
      to="#tooltip"
    >
      <div
        ref="inner"
        :style="contentStyle"
        class="_m-tooltip-inner"
      >
        <slot name="title" />
      </div>
    </teleport>
  </div>
</template>

<script>
import { debounce } from 'lodash-es';
import { guid } from 'shared/lib/uuid';

export default {
  name: 'MTooltip',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    mouseEnterDelay: {
      type: Number,
      default: 0.1,
    },
    placement: {
      type: String,
      default: 'top',
    },
    relocateKey: {
      type: [String, Number, Boolean, Array],
      default: '',
    },
    matchTriggerWidth: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['hide', 'input', 'update:value', 'change'],
  data() {
    return {
      val: false,
      current: [],
      // TODO: replace me with debounce composable
      debounced: null,
      id: guid(),
      overlayWidth: 0,
      overlayHeight: 0,
      windowWidth: 0,
      windowHeight: 0,
      triggerDimensions: {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
      },
    };
  },
  computed: {
    titleSlotSet() {
      return !!this.$slots.title;
    },
    contentStyle() {
      return this.fixVerticalPosition(this.fixHorizontalPosition(this.overlayStyle));
    },
    overlayStyle() {
      let width = null;
      if (this.matchTriggerWidth) {
        width = `${this.triggerDimensions.width}px`;
      }
      const top = this.triggerDimensions.y + this.triggerDimensions.height + 5;
      const style = {
        top: `${top}px`,
        position: 'fixed',
        width,
        maxWidth: '25rem',
      };

      if (this.placement === 'top') {
        return {
          ...style,
          top: `${this.triggerDimensions.y - 5 - this.overlayHeight}px`,
          left: `${this.triggerDimensions.x + (this.triggerDimensions.width / 2) - (this.overlayWidth / 2)}px`,
        };
      }
      if (this.placement === 'left') {
        return {
          ...style,
          top: `${this.triggerDimensions.y + (this.triggerDimensions.height / 2) - (this.overlayHeight / 2)}px`,
          left: `${this.triggerDimensions.x - 5 - this.overlayWidth}px`,
        };
      }
      if (this.placement === 'right') {
        return {
          ...style,
          top: `${this.triggerDimensions.y + (this.triggerDimensions.height / 2) - (this.overlayHeight / 2)}px`,
          left: `${this.triggerDimensions.x + this.triggerDimensions.width + 5}px`,
        };
      }
      if (this.placement === 'rightBottom') {
        return {
          ...style,
          top: `${this.triggerDimensions.y + (this.triggerDimensions.height) - (this.overlayHeight)}px`,
          left: `${this.triggerDimensions.x + this.triggerDimensions.width + 5}px`,
        };
      }
      if (this.placement === 'topRight') {
        return {
          ...style,
          top: `${this.triggerDimensions.y - 4}px`,
          left: `${this.triggerDimensions.x + this.triggerDimensions.width}px`,
        };
      }
      if (this.placement === 'topLeft') {
        return {
          ...style,
          top: `${this.triggerDimensions.y - 4}px`,
          left: `${this.triggerDimensions.x - this.overlayWidth}px`,
        };
      }
      if (this.placement === 'bottomLeft') {
        return {
          ...style,
          top: `${this.triggerDimensions.y + this.triggerDimensions.height + 5}px`,
          left: `${this.triggerDimensions.x}px`,
        };
      }

      if (this.placement === 'bottomRight') {
        return {
          ...style,
          top: `${this.triggerDimensions.y + this.triggerDimensions.height + 5}px`,
          left: `${this.triggerDimensions.x - (this.overlayWidth - this.triggerDimensions.width)}px`,
        };
      }
      if (this.placement === 'onTop') {
        return {
          top: `${this.triggerDimensions.y}px`,
          left: `${this.triggerDimensions.x}px`,
          position: 'fixed',
          width,
        };
      }

      // default bottomCenter
      const distanceLeft = this.triggerDimensions.x - (this.overlayWidth / 2 - this.triggerDimensions.width / 2);
      const distanceRight = this.windowWidth - distanceLeft - this.overlayWidth;
      if (distanceRight < distanceLeft) {
        if (this.matchTriggerWidth) {
          return {
            ...style,
            right: `${this.windowWidth - (this.triggerDimensions.x + this.triggerDimensions.width)}px`,
          };
        }
        return {
          ...style,
          right: `${Math.max(15, distanceRight)}px`,
        };
      }
      if (this.matchTriggerWidth) {
        return {
          ...style,
          left: `${this.triggerDimensions.x}px`,
        };
      }
      return {
        ...style,
        left: `${Math.max(15, distanceLeft)}px`,
      };
    },
    interactive() {
      if (this.disabled) {
        return false;
      }
      if (this.$store.state.breakpoint.smAndDown) {
        return false;
      }

      return this.titleSlotSet;
    },
  },
  methods: {
    fixHorizontalPosition(style) {
      const left = parseInt(style.left, 10);
      if (left < 0) {
        return {
          ...style,
          left: '5px',
        };
      }

      const distanceRight = left + this.overlayWidth;

      if (distanceRight > this.windowWidth) {
        style.left = `${this.triggerDimensions.x - this.overlayWidth + this.triggerDimensions.width}px`;
      }

      return style;
    },
    fixVerticalPosition(style) {
      if (this.$store.state.breakpoint.smAndDown) {
        return {
          ...style,
          top: 0,
          left: 0,
        };
      }

      const top = parseInt(style.top, 10);
      const bottom = top + this.overlayHeight;
      const missing = this.windowHeight - bottom;

      if (missing > 0) {
        return style;
      }

      if (this.placement === 'onTop') {
        return {
          ...style,
          top: `${top + missing - 20}px`,
        };
      }

      return {
        ...style,
        top: `${top + missing - 20}px`,
      };
    },
    hide() {
      this.calculateWindowSize();
      this.$emit('hide');
      if (this.debounced !== null) {
        this.debounced.cancel();
      }
      this.val = false;
    },
    open() {
      if (!this.interactive) {
        return;
      }
      this.calculateWindowSize();
      if (this.disabled) {
        return;
      }
      const setVal = () => {
        this.val = true;
      };
      if (this.debounced !== null) {
        this.debounced.cancel();
      }

      this.debounced = debounce(setVal, this.mouseEnterDelay * 1000);
      this.debounced();
    },
    calculateWindowSize() {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
    },
    setTriggerDimensions() {
      if (this.$refs.trigger === undefined) {
        return;
      }

      const d = this.$refs.trigger.getBoundingClientRect();
      this.triggerDimensions = {
        x: d.x,
        y: d.y,
        width: d.width,
        height: d.height,
      };
    },
    recalculateOverlaySize() {
      this.setOverlaySize();
      this.setTriggerDimensions();
    },
    setOverlaySize() {
      if (typeof this.$refs.inner === 'undefined') {
        return;
      }
      this.overlayWidth = this.$refs.inner.clientWidth;
      this.overlayHeight = this.$refs.inner.clientHeight;
    },
  },
  watch: {
    relocateKey() {
      // Since we use vue-portal, $refs are only available in the second flush of the rendering queue: https://github.com/LinusBorg/portal-vue/issues/119
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.recalculateOverlaySize();
        });
      });
    },
    val(val) {
      this.$emit('input', val);
      this.$emit('update:value', val);
      this.$emit('change', val);

      if (val) {
        this.$nextTick(() => {
          this.$nextTick(() => {
            this.setTriggerDimensions();
            this.recalculateOverlaySize();
          });
        });
      }
    },
    interactive(val) {
      if (val === false) {
        this.hide();
      }
    },
  },
  mounted() {
    this.calculateWindowSize();
    window.addEventListener('resize', this.calculateWindowSize);
    window.addEventListener('resize', this.setTriggerDimensions);
    document.addEventListener('scroll', this.hide, true);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.calculateWindowSize);
    window.removeEventListener('resize', this.setTriggerDimensions);
    document.removeEventListener('scroll', this.hide, true);
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  @import "shared/assets/scss/box-shadow";

  ._m-tooltip-inner {
    z-index: 1050;
    padding: .4rem .8rem;
    font-size: $font-size-2;
    color: white;
    white-space: pre-line;
    background-color: map_get($grey, 'darken-4');
    border-radius: $default-border-radius;

    @include box_shadow(1);
  }
</style>
