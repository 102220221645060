export const camelCase = (str) => str.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => (index === 0 ? word.toLowerCase() : word.toUpperCase())).replace(/\s+/g, '');

export const injectCSS = (css) => {
  const style = document.createElement('style');
  style.type = 'text/css';
  style.textContent = css;
  const { head } = document;
  const { firstChild } = head;

  if (firstChild) {
    head.insertBefore(style, firstChild);
  } else {
    head.appendChild(style);
  }
};

export const minMax = (value = 0, min = 0, max = 0) => Math.min(Math.max(parseInt(value, 10), min), max);
