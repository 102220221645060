<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 20.5C12.5051 20.5 12.9271 20.106 12.9271 19.6208V12.9078H19.5816C20.0768 12.9078 20.5 12.4944 20.5 11.9994C20.4974 11.7618 20.3997 11.5346 20.228 11.3667C20.0563 11.1989 19.8242 11.1038 19.5816 11.1019H12.9271V4.37807C12.9271 3.89406 12.5051 3.50004 12 3.50004C11.7597 3.49777 11.5283 3.58889 11.3564 3.75345C11.1845 3.91801 11.0861 4.14261 11.0829 4.37807V11.1019H4.41838C4.17584 11.1038 3.94374 11.1989 3.772 11.3667C3.60026 11.5346 3.50259 11.7618 3.5 11.9994C3.5 12.4944 3.9232 12.9078 4.41838 12.9078H11.0829V19.6208C11.0829 20.106 11.4949 20.5 12 20.5Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default { name: 'Plus' };
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>

</style>
