<template>
  <m-card
    class="icon-picker-card"
    no-padding
    border-radius-small
  >
    <m-content
      no-padding
      :padding-x="8"
      class="_header-container"
    >
      <div class="_header">
        <div class="_left">
          <m-tab
            v-for="type in types"
            :key="type"
            :title="$t(`iconPicker.types.${type}`)"
            small
            :active="localActiveTab === type"
            @click="localActiveTab = type"
          />
        </div>
        <div
          v-if="!hideActions"
          class="_actions"
        >
          <m-btn
            hide-border
            @click="remove"
          >
            {{ $t('iconPicker.remove') }}
          </m-btn>
        </div>
      </div>
    </m-content>
    <m-content
      v-if="[iconType.emoji, iconType.icon].includes(localActiveTab)"
      padding-xs
    >
      <m-text-field
        v-model:value="search"
        has-background
        :placeholder="$t('iconPicker.search')"
        auto-focus
      >
        <template #prefix>
          <m-icon
            type="search"
          />
        </template>
      </m-text-field>
    </m-content>
    <m-content
      padding-xs
      class="_list"
    >
      <icon-list
        v-show="localActiveTab === iconType.emoji"
        :icons="emojiList"
        type="emoji"
        @select="selectEmoji"
      />
      <icon-list
        v-show="localActiveTab === iconType.icon"
        :icons="iconList"
        :variants="iconVariations"
        type="icon"
        @select="selectIcon"
      />
      <div
        v-show="localActiveTab === iconType.custom"
        class="_custom"
      >
        <m-btn
          block
          :loading="uploadLoading"
          @click="openUploadCustom"
        >
          {{ $t(`iconPicker.custom.upload`) }}
        </m-btn>
        <div class="_recommended">
          {{ $t(`iconPicker.custom.recommended`) }}
        </div>
      </div>
    </m-content>
  </m-card>
  <input
    ref="fileinput"
    type="file"
    :style="{display: 'none'}"
    accept="image/*"
    @change="fileChanged"
  >
  <m-dialog
    v-model:value="showCropImage"
    :title="$t('iconPicker.custom.cropImage')"
    hide-footer
    keep-open-on-mask-click
    no-padding
  >
    <image-crop
      v-if="file !== null"
      class="_image-crop"
      :file="file"
      @cropped="selectCustom"
      @cancel="resetImageCrop"
      @wrong-file-type="handleWrongFileType"
    />
  </m-dialog>
</template>

<script>
import IconList from '@/components/IconList.vue';
import ImageCrop from '@/components/ImageCrop.vue';
import useIconList from '@/composables/icon-picker/icons';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import { ICON_VARIANTS, iconFromIconPicker } from 'shared/lib/icon-picker';
import { RECENT_EMOJIS_LOCALSTORAGE_PREFIX, RECENT_ICONS_LOCALSTORAGE_PREFIX } from '@/lib/constants';
import { buildIcon } from 'shared/lib/icon';
import { emojis as emojiListRaw } from 'shared/lib/emojis';
import { iconList as iconListRaw } from 'shared/lib/icon-picker/icon-list';
import { iconType } from 'shared/constants.json';
import { logCatch } from '@/lib/logger/logger';
import { ref } from 'vue';
import { uploadFile } from '@/lib/image/image';

export default {
  name: 'IconPickerCard',
  props: {
    hideActions: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: '1.4rem',
    },
    types: {
      type: Array,
      default: () => [iconType.emoji],
    },
    activeTab: {
      type: String,
      default: undefined,
    },
    showIconVariants: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['change', 'remove'],
  components: { ImageCrop, IconList },
  setup() {
    const { loggedInUserAccount } = useLoggedInUserAccount();
    const localStorageKeyEmojis = `${loggedInUserAccount.value.uid}_${RECENT_EMOJIS_LOCALSTORAGE_PREFIX}`;
    const localStorageKeyIcons = `${loggedInUserAccount.value.uid}_${RECENT_ICONS_LOCALSTORAGE_PREFIX}`;
    const search = ref('');
    const { list: iconList, random: randomIcon, storeRecent: storeRecentIcon } = useIconList(iconListRaw, localStorage, localStorageKeyIcons, search, { isValid: (icon) => iconFromIconPicker(icon.value) });
    const { list: emojiList, random: randomEmoji, storeRecent: storeRecentEmoji } = useIconList(emojiListRaw, localStorage, localStorageKeyEmojis, search, { isValid: () => true });
    return { search, emojiList, randomEmoji, storeRecentEmoji, storeRecentIcon, iconList, randomIcon };
  },
  data() {
    return {
      iconType,
      localActiveTab: this.activeTab ? this.activeTab : this.types[0],
      uploadLoading: false,
      file: null,
      showCropImage: false,
    };
  },
  computed: {
    iconVariations() {
      if (this.showIconVariants) {
        return ICON_VARIANTS;
      }
      return [];
    },
  },
  methods: {
    selectEmoji(val) {
      this.storeRecentEmoji(val);
      this.$emit('change', buildIcon(val.value, iconType.emoji));
    },
    selectIcon(val) {
      this.storeRecentIcon(val);
      this.$emit('change', buildIcon(val.value, iconType.icon));
    },
    selectCustom(blob) {
      this.resetImageCrop();
      this.uploadLoading = true;
      uploadFile(blob, () => {}).then((data) => {
        this.$emit('change', buildIcon({ blob: data.fileId, getURL: data.getURL }, iconType.custom));
      }).catch(logCatch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      })).finally(() => { this.uploadLoading = false; });
    },
    openUploadCustom() {
      this.$refs.fileinput.click();
    },
    fileChanged(event) {
      if (event.target.files.length === 0) {
        return;
      }
      this.file = event.target.files[0];
      this.showCropImage = true;
      this.$refs.fileinput.value = '';
    },
    resetImageCrop() {
      this.file = null;
      this.showCropImage = false;
    },
    handleWrongFileType() {
      this.resetImageCrop();
      this.$showSnackbar({ color: 'error', message: this.$t('error.notAnImage') });
    },
    remove() {
      this.$emit('remove');
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .icon-picker-card {
    z-index: 99;
    width: 39.1rem;
    max-width: 100vw;

    ._header-container {
      border-bottom: 1px solid $border-color;
    }

    ._header {
      display: flex;
      align-items: center;

      ._title {
        font-weight: $font-weight-semibold;
      }

      ._actions {
        margin-left: auto;
      }
    }

    ._list {
      min-height: 18rem;
      max-height: 40vh;
      overflow: auto;

      @media (max-width: $screen-size-md) {
        max-height: calc(100vh - 16rem);
      }

      ._custom {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;

        ._recommended {
          font-size: $font-size-2;
          color: $font-color-secondary;
        }
      }
    }
  }
</style>
