<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!--eslint-disable-->
    <path
      d="M6.33169 1L13.1923 2.06029L12.1413 8.58314L10.0621 5.41429L8.24549 8.18286L5.24922 7.72L6.33169 1ZM4.85106 4.69429C4.19771 4.4266 3.47682 4.35933 2.78362 4.50136C2.09041 4.6434 1.4575 4.98805 0.968475 5.48981C0.479447 5.99158 0.157309 6.62683 0.0446083 7.31169C-0.0680929 7.99654 0.0339445 8.69877 0.337244 9.32563C0.640543 9.95248 1.13084 10.4745 1.74337 10.8227C2.3559 11.1709 3.06185 11.3289 3.76798 11.2758C4.47412 11.2228 5.14721 10.9612 5.69837 10.5256C6.24953 10.09 6.65282 9.50083 6.85499 8.836L4.24813 8.43314L4.85106 4.69343V4.69429ZM6.1243 13L10.0621 7L14 13H6.1243Z"
      fill="currentColor"
    />
    <!--eslint-enable-->
  </svg>
</template>

<script>
export default { name: 'Templates' };
</script>

<style scoped lang="scss" type="text/scss">

</style>
