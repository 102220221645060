import useAccess from '@/composables/access/access';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useRepo from '@/nebula/repo';
import { EVENTS } from '@/lib/constants';
import { EventBus } from '@/lib/event-bus';
import { accountSettings as accountSettingsConfig } from 'shared/api/query/configs.json';
import { accountSettingsEdgeByFlag as accountSettingsEdgeByFlagLib } from 'shared/lib/access';
import { computed } from 'vue';
import { dataSourceType } from 'shared/constants.json';

export default function useAccountSettings() {
  const { updateSingle, selectSingle } = useRepo(accountSettingsConfig.model);

  const { loggedInUserAccount } = useLoggedInUserAccount();

  const accountSettings = computed(() => selectSingle(loggedInUserAccount.value.accountSettings?.uid));
  const { accountHasFeature, accountCanActivateFeature } = useAccess();

  const canToggleFlag = (flag) => accountCanActivateFeature(flag);
  const canManageFlag = (flag) => accountCanActivateFeature(flag) || accountHasFeature([flag]);
  const accountSettingsEdgeByFlag = (flag) => accountSettingsEdgeByFlagLib(flag);

  const toggleFlag = (flag) => {
    if (!canToggleFlag(flag)) {
      return new Promise((resolve) => { resolve(); });
    }

    const edge = accountSettingsEdgeByFlag(flag);
    const newValue = !accountSettings.value[edge];
    const entity = {
      ...accountSettings.value,
      [edge]: newValue,
    };

    return updateSingle(entity).then((settings) => {
      if ([dataSourceType.sheets, dataSourceType.excel, dataSourceType.jira, dataSourceType.asana, dataSourceType.hubspot, dataSourceType.salesforce].includes(flag)) {
        if (newValue === true) {
          EventBus.$emit(EVENTS.INTEGRATION.INTEGRATION_ENABLED, { settings, flag });
        } else {
          EventBus.$emit(EVENTS.INTEGRATION.INTEGRATION_DISABLED, { settings, flag });
        }
      }
      return settings;
    });
  };

  return {
    accountSettings,
    accountSettingsEdgeByFlag,
    canToggleFlag,
    canManageFlag,
    toggleFlag,
    updateAccountSettings: updateSingle,
  };
}
