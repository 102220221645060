<template>
  <div class="hierarchical-property-option-table-cell">
    <hierarchical-table-cell
      v-if="showAsHierarchy"
      :level="row.level"
      :has-expand="row.hasChildren && row.containsDataMatch"
      :expanded="!row.collapsed"
      @toggle-expand="$emit('toggle-collapse', $event)"
    >
      <m-tag
        :color="propertyOption.color"
        :icon="buildIconFromEntity(propertyOption)"
        automatic-color-fallback
        small
        class="_tag"
        :title="textByLang(propertyOption.label, userLang)"
        @click="handleOptionClick(propertyOption)"
      />
    </hierarchical-table-cell>
    <m-tag
      v-else
      :color="propertyOption.color"
      :icon="propertyOption.icon"
      automatic-color-fallback
      small
      class="_tag"
      :title="textByLang(propertyOption.label, userLang)"
      @click="handleOptionClick(propertyOption)"
    />
  </div>
</template>
<script>
import HierarchicalTableCell from '@/components/table/HierarchicalTableCell.vue';
import { buildIconFromEntity } from 'shared/lib/icon';
import { computed } from 'vue';
import { routeName } from 'shared/constants.json';
import { textByLang } from 'shared/lib/language';
import { useStore } from 'vuex';

export default {
  name: 'HierarchicalPropertyOptionTableCell',
  props: {
    propertyOption: {
      type: Object,
      required: true,
    },
    row: {
      type: Object,
      required: true,
    },
    showAsHierarchy: {
      type: Boolean,
      required: true,
    },
  },
  components: { HierarchicalTableCell },
  emits: ['toggle-collapse'],
  setup() {
    const store = useStore();
    const userLang = computed(() => store.getters.userLang);
    return { userLang };
  },
  data() {
    return { textByLang };
  },
  methods: {
    handleOptionClick(option) {
      this.$router.push({ name: routeName.spaceDetails, params: { optionId: option.uid } });
    },
    buildIconFromEntity,
  },
};
</script>

<style lang="scss" type="text/scss">
  @import 'shared/assets/scss/_padding.scss';

  .hierarchical-property-option-table-cell {
    display: flex;
    align-items: center;
    min-width: 24rem;
    min-height: 3rem;
    white-space: nowrap;

    ._indention {
      width: 4rem;
    }

    ._tag {
      cursor: pointer;
    }
  }
</style>
