import { UID } from 'shared/api/query/constants';
import {
  meeting as meetingConfig,
  privateNote as privateNoteConfig,
} from 'shared/api/query/configs.json';

export const children = [
  { attr: UID },
  {
    attr: privateNoteConfig.edges.content,
    default: null,
  },
  {
    attr: privateNoteConfig.edges.hidden,
    default: false,
  },
  {
    attr: privateNoteConfig.edges.meeting,
    model: meetingConfig.model,
    children: [
      { attr: UID },
    ],
  },
];
