<template>
  <m-card-item
    class="form-language-item"
  >
    <div class="_title">
      {{ englishLanguageName(lang) }}
    </div>
    <template #right>
      <m-btn
        v-if="!disabled"
        hide-border
        fab
        light
        icon="delete"
        @click="showPrompt"
      />
    </template>
  </m-card-item>
</template>

<script>

import { nameByCode } from '@/lib/language';

export default {
  name: 'FormLanguageItem',
  props: {
    lang: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['delete-language'],
  methods: {
    showPrompt() {
      const deletedMethod = this.deleteLanguage;
      this.$confirm({
        title: this.$t('formLanguageItem.prompt'),
        okText: this.$t('formLanguageItem.deleteLabel'),
        okType: 'danger',
        maskClosable: true,
        cancelText: this.$t('general.cancel'),
        onOk() {
          deletedMethod();
        },
      });
    },
    deleteLanguage() {
      this.$emit('delete-language', { lang: this.lang });
    },
    englishLanguageName(code) {
      return nameByCode(code);
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .form-language-item {
    ._title {
      display: flex;
      align-items: center;
      min-height: 3rem;
    }
  }
</style>
