<template>
  <question-field
    ref="opinionScale"
    :question-index="questionIndex"
    :field="field"
    :active="active"
    :lang="lang"
    class="opinion-scale"
  >
    <div class="_scale">
      <div
        v-for="val in answerValues"
        :key="val"
        class="_container"
        @click="setValue(val)"
      >
        <div
          :class="['_item', val === selectedAnswer ? '-selected' : '']"
        >
          {{ val }}
        </div>
      </div>
    </div>
    <div class="_labels">
      <div
        class="_label"
      >
        {{ properties.labelLeft === null ? '' : properties.labelLeft[lang] }}
      </div>
      <div
        class="_label"
      >
        {{ properties.labelCenter === null ? '' : properties.labelCenter[lang] }}
      </div>
      <div
        class="_label"
      >
        {{ properties.labelRight === null ? '' : properties.labelRight[lang] }}
      </div>
    </div>
    <div
      v-if="hasBeenActive && selectedAnswer === null && field.validations.required"
      class="_error"
    >
      {{ $t('form.required') }}
    </div>
    <div
      v-if="showComment"
      class="_comment"
    >
      <m-textarea
        v-model:value="comment"
        :placeholder="$t('form.commentLabel')"
        :rows="2"
        class="_input"
        @input="answerChanged"
      />
      <div class="_action-wrapper">
        <m-btn
          v-if="selectedAnswer !== null"
          color="primary"
          @click="answered"
        >
          {{ $t('form.continue') }}
        </m-btn>
      </div>
    </div>
  </question-field>
</template>

<script>

import QuestionField from 'shared/components/internal/form/QuestionField.vue';
import { getMinMaxOfField } from 'shared/lib/form-field';

export default {
  props: {
    questionIndex: {
      type: Number,
      required: true,
    },
    active: {
      type: Boolean,
      required: true,
    },
    field: {
      type: Object,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
  },
  components: { QuestionField },
  data() {
    return {
      selectedAnswer: null,
      hasBeenActive: false,
      comment: '',
    };
  },
  emits: ['question-selected', 'answer-changed'],
  computed: {
    properties() {
      return this.field.opinionScaleProperties;
    },
    answerValues() {
      const { min, max } = getMinMaxOfField(this.field);
      return this.generateArray(min, max);
    },
    showComment() {
      return this.field.allowComment;
    },
  },
  methods: {
    setValue(selectedAnswer) {
      this.selectedAnswer = selectedAnswer;
      if (!this.showComment) {
        this.answered({ timeout: 700 });
      }
    },
    answered({ timeout }) {
      this.$emit('question-selected', {
        questionIndex: this.questionIndex,
        answer: {
          field: { uid: this.field.uid },
          value: this.selectedAnswer,
          text: this.comment,
        },
        timeout,
      });
    },
    answerChanged() {
      this.$emit('answer-changed', {
        questionIndex: this.questionIndex,
        answer: {
          field: { uid: this.field.uid },
          value: this.selectedAnswer,
          text: this.comment,
        },
      });
    },
    generateArray(min, max) {
      const result = [];
      for (let i = min; i <= max; i++) {
        result.push(i);
      }
      return result;
    },
  },
  watch: {
    active(newValue, oldValue) {
      if (!newValue && oldValue) {
        this.hasBeenActive = true;
      }
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .opinion-scale {
    ._labels {
      display: flex;
      margin-top: .8rem;
      color: map_get($blue, 'base');

      ._label {
        flex: 1 1 0;
        text-align: center;

        &:first-of-type {
          text-align: left;
        }

        &:last-of-type {
          text-align: right;
        }
      }
    }

    ._scale {
      display: flex;
      justify-content: space-around;
      overflow: hidden;
      background-color: map_get($blue, 'lighten-4');
      border: .1rem solid map_get($blue, 'base');
      border-radius: .4rem;

      ._container {
        width: 100%;
        height: auto;

        ._item {
          display: flex;
          flex: 1 1 auto;
          align-items: center;
          justify-content: center;
          padding: 1.5rem 0;
          font-weight: 400;
          color: map_get($blue, 'base');
          cursor: pointer;
          box-shadow: map_get($blue, 'base') -.1rem 0;

          &:hover {
            background-color: map_get($blue, 'lighten-2');
          }

          &.-selected {
            color: $white;
            background-color: map_get($blue, 'base');
            animation: blink .25s ease 0s 2 normal none running;
          }

          @keyframes blink {
            50% {
              opacity: .3;
            }
          }
        }
      }
    }

    ._comment {
      ._input {
        margin: $small-container-padding-y 0;
      }

      ._action-wrapper {
        height: 3.5rem;
      }
    }

    ._error {
      margin: .4rem .4rem 2rem 0;
      font-size: $font-size-2;
      color: $error-color;
    }
  }
</style>
