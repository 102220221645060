<template>
  <m-content
    padding-xs
    class="asana-section-form"
  >
    <div
      v-for="(section, index) in sections"
      :key="section.uid"
      class="_record"
    >
      <div
        v-if="index !== 0"
        class="_operator"
      >
        {{ $t('asanaSectionForm.or') }}
      </div>
      <div class="_sel">
        <div
          class="_sel-input"
        >
          <div
            class="_label"
          >
            {{ $t('asanaSectionForm.section') }}
          </div>
          <m-select
            :items="availableSections"
            value-key="gid"
            item-text="name"
            show-search
            :value="section.gid"
            :loading="loading"
            full-width
            :disabled="readOnly"
            @change="(val) => submitSection({index, val})"
          />
        </div>
        <div class="_divider" />
        <div
          class="_sel-input"
        >
          <div
            class="_label"
          >
            {{ $t('asanaSectionForm.status') }}
          </div>
          <m-select
            :items="taskStates"
            value-key="status"
            item-text="name"
            show-search
            :value="section.status"
            :loading="loading"
            full-width
            :disabled="readOnly"
            @change="(val) => submitStatus({index, val})"
          />
        </div>
        <div
          v-if="!readOnly && index !== 0"
          class="_del"
        >
          <m-btn
            :disabled="loading"
            icon="close"
            hide-border
            fab
            xs
            light
            @click="del(index)"
          />
        </div>
      </div>
    </div>
    <m-btn
      v-if="!readOnly"
      icon="plus"
      light
      hide-border
      @click="add"
    >
      {{ $t('asanaSectionForm.addAction') }}
    </m-btn>
  </m-content>
</template>

<script>
import { asanaCompletionStatus, customDatasourceProperty } from 'shared/constants.json';

export default {
  name: 'AsanaSectionForm',
  props: {
    sections: {
      type: Array,
      required: true,
    },
    dataSource: {
      type: Object,
      default: null,
    },
    asanaQuery: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['submit-section', 'submit-status', 'add-section', 'del-section'],
  data() {
    return { asanaCompletionStatus };
  },
  computed: {
    availableSections() {
      if (this.dataSource === null) {
        return this.sections;
      }

      const project = this.dataSource[customDatasourceProperty.asanaProjects].find((p) => p.gid === this.asanaQuery.projectID);
      if (project === undefined) {
        return this.asanaQuery.sections;
      }

      let sections = [];
      if (project.sections !== undefined) {
        sections = project.sections;
      }

      return [...sections, { gid: 'global', name: this.$t('asanaSectionForm.any') }];
    },
    taskStates() {
      return asanaCompletionStatus.all.map((s) => ({ status: s, name: this.$t(`asanaForm.asanaCompletionStatus.${s}`) }));
    },
  },
  methods: {
    submitSection({ index, val }) {
      const section = this.availableSections.filter((s) => s.gid === val);
      const res = { gid: val, name: section[0].name };
      this.$emit('submit-section', { index, section: res });
    },
    submitStatus({ index, val }) {
      this.$emit('submit-status', { index, status: val });
    },
    add() {
      this.$emit('add-section', { gid: 'global', name: this.$t('asanaSectionForm.any'), status: asanaCompletionStatus.any });
    },
    del(index) {
      this.$emit('del-section', index);
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .asana-section-form {
    border: 1px solid $border-color;
    border-radius: $border-radius-sm;

    ._label {
      margin-bottom: .4rem;
      font-size: $font-size-2;
      color: $font-color-secondary;
    }

    ._record {
      display: flex;
      flex-direction: row;
      align-items: center;

      ._operator {
        flex: 0 0 4rem;
        margin-top: 1rem;
      }

      ._del {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: .4rem;
        margin-left: .8rem;
      }

      ._sel {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;
        width: 100%;
        margin-bottom: .8rem;

        ._divider {
          flex: 0 0 .8rem;
        }

        ._sel-input {
          flex: 0 1 50%;
        }
      }
    }
  }
</style>
