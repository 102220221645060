// autogenerated - do not edit

const beige = { base: '#faf5f0' };

const blue = {
  base: '#3bb1cf',
  darken1: '#288aa8',
  darken2: '#196682',
  darken3: '#0d445c',
  darken4: '#072636',
  lighten1: '#6cc4db',
  lighten2: '#9dd8e7',
  lighten3: '#ceebf3',
  lighten4: '#ebf7fa',
  lighten5: '#f5fbfd',
  lighten6: '#f9fdfe',
};

const brown = {
  base: '#986e57',
  darken1: '#7a5846',
  darken2: '#5b4234',
  darken3: '#3d2c23',
  darken4: '#2e211a',
  lighten1: '#ad8b79',
  lighten2: '#c1a89a',
  lighten3: '#d6c5bc',
  lighten4: '#e0d3cd',
  lighten5: '#eae2dd',
  lighten6: '#f5f0ee',
};

const dashboard = {
  blue: '#b1d9ee',
  green: '#84ddc6',
  orange: '#f8b7af',
  pink: '#f6bdf8',
  purple: '#c2b4e9',
  red: '#ee9aae',
  yellow: '#ffda8a',
};

const green = {
  base: '#32c6a0',
  darken1: '#20a185',
  darken2: '#127a67',
  darken3: '#085449',
  darken4: '#042e29',
  lighten1: '#5bd1b3',
  lighten2: '#84ddc6',
  lighten3: '#ade8d9',
  lighten4: '#d6f4ec',
  lighten5: '#ebf9f5',
  lighten6: '#f9fdfc',
};

const grey = {
  base: 'rgba(56, 54, 48, .6)',
  darken1: 'rgba(56, 54, 48, .7)',
  darken2: 'rgba(56, 54, 48, .8)',
  darken3: 'rgba(56, 54, 48, .9)',
  darken4: 'rgba(56, 54, 48, 1)',
  lighten1: 'rgba(56, 54, 48, .4)',
  lighten2: 'rgba(56, 54, 48, .3)',
  lighten3: 'rgba(56, 54, 48, .16)',
  lighten4: 'rgba(56, 54, 48, .09)',
  lighten5: 'rgba(56, 54, 48, .08)',
  lighten6: 'rgba(56, 54, 48, .06)',
  lighten7: 'rgba(56, 54, 48, .04)',
  lighten8: 'rgba(56, 54, 48, .02)',
};

const orange = {
  base: '#fa541c',
  darken1: '#d4380d',
  darken2: '#ad2102',
  darken3: '#871400',
  darken4: '#610b00',
  lighten1: '#ff7a45',
  lighten2: '#ff9c6e',
  lighten3: '#ffbb96',
  lighten4: '#ffd8bf',
  lighten5: '#fff2e8',
  lighten6: '#fffbf8',
};

const pink = {
  base: '#c14c8a',
  darken1: '#9a3d6e',
  darken2: '#742e53',
  darken3: '#4d1e37',
  darken4: '#3a1729',
  lighten1: '#cd70a1',
  lighten2: '#da94b9',
  lighten3: '#e6b7d0',
  lighten4: '#ecc9dc',
  lighten5: '#f3dbe8',
  lighten6: '#f3dbe8',
};

const purple = {
  base: '#9065b0',
  darken1: '#73518d',
  darken2: '#563d6a',
  darken3: '#3a2846',
  darken4: '#2b1e35',
  lighten1: '#a684c0',
  lighten2: '#bca3d0',
  lighten3: '#d3c1df',
  lighten4: '#ded1e7',
  lighten5: '#e9e0ef',
  lighten6: '#f4f0f7',
};

const red = {
  base: '#da4f64',
  darken1: '#ca4a60',
  darken2: '#b4445b',
  darken3: '#9e3f56',
  darken4: '#7b344c',
  lighten1: '#e05e78',
  lighten2: '#e7758f',
  lighten3: '#ee9aae',
  lighten4: '#f5c0cd',
  lighten5: '#fae6eb',
  lighten6: '#fef7f9',
};

const shades = {
  black: '#000000',
  transparent: 'transparent',
  white: '#ffffff',
};

const yellow = {
  base: '#fdb237',
  darken1: '#d68c24',
  darken2: '#b06a15',
  darken3: '#8a4c0a',
  darken4: '#633306',
  lighten1: '#ffc861',
  lighten2: '#ffda8a',
  lighten3: '#ffe9b3',
  lighten4: '#fff6db',
  lighten5: '#fffcf0',
  lighten6: '#fffefb',
};

export default {
  beige,
  blue,
  brown,
  dashboard,
  green,
  grey,
  orange,
  pink,
  purple,
  red,
  shades,
  yellow,
};
