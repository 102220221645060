<template>
  <div class="recurrence-selector">
    <m-select
      v-model:value="options"
      :items="recurringItems"
      :disabled="disabled"
      class="_select"
    />
    <m-dialog
      v-model:value="showModal"
      :title="$t('rRulePicker.customConfig')"
      :ok-text="$t('general.save')"
      :cancel-text="$t('general.cancel')"
      small
      @close="cancel"
      @cancel="cancel"
      @ok="selectRRule"
    >
      <div class="recurrence-selector-dialog">
        <div class="_row -freq-picker">
          <div class="_label">
            {{ $t('rRulePicker.repeatEvery') }}
          </div>
          <div class="_picker">
            <m-input-number
              v-model:value="interval"
              :min="1"
              :disabled="disabled"
              class="_interval"
            />
            <m-select
              v-model:value="freq"
              :items="freqItems"
              :disabled="disabled"
              class="_freq"
            />
          </div>
        </div>
        <div
          v-if="freqIsMonthly"
          class="_row -set-pos"
        >
          <div class="_label">
            {{ $t('rRulePicker.at') }}
          </div>
          <m-select
            v-model:value="bysetpos"
            class="_input-select"
            :items="setPosItems"
            :disabled="disabled"
            multiple
            tags
          />
          <m-select
            v-model:value="byweekday[0]"
            class="_input-select"
            :items="weekdays"
            :disabled="disabled"
          />
        </div>
        <div
          v-if="freqIsWeekly"
          class="_row -weekday-picker"
        >
          <div class="_label">
            {{ $t('rRulePicker.repeatAt') }}
          </div>
          <div class="_weekdays">
            <div
              v-for="(n, i) in weekdays"
              :key="i"
              :class="['_day', byweekday.indexOf(n.value) > -1 ? '-selected' : '']"
              @click="selectByDay(n.value)"
            >
              {{ weekday(n.value) }}
            </div>
          </div>
        </div>
        <div class="_row -end-date">
          <div class="_label">
            {{ $t('rRulePicker.end') }}
          </div>
          <m-radio-group
            :value="endOption"
            class="_radio-group"
            custom-items
          >
            <m-radio
              :selected="'never' === endOption"
              class="_item"
              value="never"
              @select="val => endOption = val"
            >
              {{ $t('rRulePicker.never') }}
            </m-radio>
            <m-radio
              :selected="'endsAt' === endOption"
              class="_item"
              value="endsAt"
              @select="val => endOption = val"
            >
              <div class="_radio-content">
                <div class="_inner">
                  <div class="_label">
                    {{ $t('rRulePicker.at') }}
                  </div>
                  <m-date-picker
                    :value="fromISO(untilDate)"
                    :min="startDate"
                    :date-time="DateTime"
                    :disabled="disabled || endOption !== 'endsAt'"
                    :locale="userLang"
                    @input="updateUntilDate"
                  />
                </div>
              </div>
            </m-radio>
            <m-radio
              :selected="'after' === endOption"
              class="_item"
              value="after"
              @select="val => endOption = val"
            >
              <div class="_radio-content">
                <div class="_inner">
                  <div class="_label">
                    {{ $t('rRulePicker.after') }}
                  </div>
                  <m-input-number
                    v-model:value="count"
                    :min="1"
                    :disabled="disabled || endOption !== 'after'"
                    class="_count"
                  />
                  <div class="_suffix">
                    {{ $t('rRulePicker.executions') }}
                  </div>
                </div>
              </div>
            </m-radio>
          </m-radio-group>
        </div>
      </div>
    </m-dialog>
  </div>
</template>

<script>
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import { DateTime } from 'luxon';
import { Frequency, RRule } from 'rrule';
import { fromISO, toISO } from 'shared/lib/time';
import { isEqual } from 'lodash-es';
import { rruleToText } from '@/lib/rrule-translations/translations';

export default {
  name: 'RecurrenceSelector',
  props: {
    value: {
      type: Object,
      default: () => null,
    },
    startDate: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { userLang } = useLoggedInUser();
    return { userLang };
  },
  emits: ['input', 'update:value'],
  data() {
    return {
      fromISO,
      rules: {
        moreThanZero: (value) => (value === null
          || parseInt(value, 10) > 0) || this.$t('validation.biggerThanZero'),
      },
      showModal: false,
      DateTime,
      endOption: 'never',
      customItems: [],
      beforeCustom: null,
      options: {
        bysetpos: null,
        byweekday: [],
        count: 1,
        freq: Frequency.WEEKLY,
        interval: 1,
        until: null,
      },
      bysetpos: null,
      byweekday: [],
      count: null,
      freq: Frequency.WEEKLY,
      interval: 1,
      until: null,
      untilDate: DateTime.local().toISO(),
    };
  },
  computed: {
    startDay() {
      return DateTime.fromISO(this.startDate);
    },
    bysetposCurrentWeek() {
      if (this.currentWeekInMonth > 4) {
        return -1;
      }
      return this.currentWeekInMonth;
    },
    weekdays() {
      return [
        {
          text: this.$t('rRulePicker.monday'),
          value: 0,
        },
        {
          text: this.$t('rRulePicker.tuesday'),
          value: 1,
        },
        {
          text: this.$t('rRulePicker.wednesday'),
          value: 2,
        },
        {
          text: this.$t('rRulePicker.thursday'),
          value: 3,
        },
        {
          text: this.$t('rRulePicker.friday'),
          value: 4,
        },
        {
          text: this.$t('rRulePicker.saturday'),
          value: 5,
        },
        {
          text: this.$t('rRulePicker.sunday'),
          value: 6,
        },
      ];
    },
    freqItems() {
      return [
        {
          text: this.$t('rRulePicker.day', this.interval),
          value: Frequency.DAILY,
        },
        {
          text: this.$t('rRulePicker.week', this.interval),
          value: Frequency.WEEKLY,
        },
        {
          text: this.$t('rRulePicker.month', this.interval),
          value: Frequency.MONTHLY,
        },
        {
          text: this.$t('rRulePicker.year', this.interval),
          value: Frequency.YEARLY,
        },
      ];
    },
    recurringItems() {
      const items = [
        {
          text: this.$t('rRulePicker.once'),
          value: {
            freq: Frequency.WEEKLY,
            bysetpos: null,
            byweekday: [],
            count: 1,
            interval: 1,
            until: null,
          },
        },
        {
          text: this.$t('rRulePicker.daily'),
          value: {
            freq: Frequency.DAILY,
            bysetpos: null,
            byweekday: [],
            count: null,
            interval: 1,
            until: null,
          },
        },
        {
          text: `${this.$t('rRulePicker.weeklyOn')} ${this.currentWeekday}`,
          value: {
            freq: Frequency.WEEKLY,
            bysetpos: null,
            byweekday: [this.currentWeekDayRRule],
            count: null,
            interval: 1,
            until: null,
          },
        },
        {
          text: `${this.$t('rRulePicker.monthlyOn')}
          ${this.currentWeekInMonth > 4 ? `${this.$t('rRulePicker.last')}` : `${this.currentWeekInMonth}.`} ${this.currentWeekday}`,
          value: {
            freq: Frequency.MONTHLY,
            bysetpos: [this.bysetposCurrentWeek],
            byweekday: [this.currentWeekDayRRule],
            count: null,
            interval: 1,
            until: null,
          },
        },
        {
          text: `${this.$t('rRulePicker.yearlyOn')} ${this.startDay.toLocaleString()}`,
          value: {
            freq: RRule.YEARLY,
            bysetpos: null,
            byweekday: [],
            count: null,
            interval: 1,
            until: null,
          },
        },
      ];
      items.push(...this.customItems);
      items.push(
        {
          text: this.$t('rRulePicker.custom'),
          value: 'custom',
        },
      );

      return items;
    },
    freqIsWeekly() {
      return this.freq === Frequency.WEEKLY;
    },
    freqIsMonthly() {
      return this.freq === Frequency.MONTHLY;
    },
    currentWeekday() {
      return this.startDay.weekdayLong;
    },
    currentWeekInMonth() {
      return Math.ceil(this.startDay.day / 7);
    },
    currentWeekDayRRule() {
      return this.startDay.weekday - 1;
    },
    setPosItems() {
      return [
        {
          text: this.$t('rRulePicker.first'),
          value: 1,
        },
        {
          text: this.$t('rRulePicker.second'),
          value: 2,
        },
        {
          text: this.$t('rRulePicker.third'),
          value: 3,
        },
        {
          text: this.$t('rRulePicker.fourth'),
          value: 4,
        },
        {
          text: this.$t('rRulePicker.last'),
          value: -1,
        },
      ];
    },
  },
  methods: {
    updateUntilDate(value) {
      this.untilDate = toISO(value);
    },
    cancel() {
      this.options = this.value;
      this.showModal = false;
    },
    selectBeforeCustom() {
      if (this.selectedRRule === 'custom') {
        this.selectedRRule = this.beforeCustom;
      }
    },
    selectByDay(n) {
      if (this.byweekday.indexOf(n) > -1 && this.byweekday.length === 1) {
        return;
      }
      if (this.byweekday.indexOf(n) > -1 && this.byweekday.length > 1) {
        this.byweekday.splice(this.byweekday.indexOf(n), 1);
        return;
      }
      this.byweekday.push(n);
    },
    weekday(n) {
      switch (n) {
        case 0:
          return this.$t('rRulePicker.monday').substring(0, 1);
        case 1:
          return this.$t('rRulePicker.tuesday').substring(0, 1);
        case 2:
          return this.$t('rRulePicker.wednesday').substring(0, 1);
        case 3:
          return this.$t('rRulePicker.thursday').substring(0, 1);
        case 4:
          return this.$t('rRulePicker.friday').substring(0, 1);
        case 5:
          return this.$t('rRulePicker.saturday').substring(0, 1);
        case 6:
          return this.$t('rRulePicker.sunday').substring(0, 1);
        default:
          return '';
      }
    },
    selectRRule() {
      const interval = parseInt(this.interval, 10);
      let count = parseInt(this.count, 10);
      /* eslint-disable */
        if (isNaN(count)) {
          count = this.count;
        }
        /* eslint-enable */

      const customOptions = {
        freq: this.freq,
        bysetpos: this.bysetpos,
        byweekday: this.byweekday,
        count,
        interval,
        until: this.until,
      };
      this.addCustomItem(customOptions);
      this.options = customOptions;
      this.showModal = false;
    },
    addCustomItem(options) {
      const filtered = this.recurringItems.filter((item) => isEqual(item.value, options));
      if (filtered.length === 1) {
        return;
      }

      const rule = new RRule(options);
      if (filtered.length > 1) {
        this.customItems = this.customItems.reduce((res, next) => {
          if (isEqual(options, next.value)) {
            return res;
          }

          res.push({
            text: rruleToText(rule, this.userLang),
            value: options,
          });

          return res;
        }, []);
        return;
      }

      this.customItems.push({
        text: rruleToText(rule, this.userLang),
        value: options,
      });
    },
  },
  watch: {
    startDate(newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      }

      this.addCustomItem(this.value);
    },
    showModal(val) {
      if (!val) {
        this.selectBeforeCustom();
      }
    },
    value(val) {
      this.options = val;
      this.addCustomItem(val);
    },
    untilDate() {
      if (this.endOption !== 'endsAt') {
        return;
      }
      const until = DateTime.fromISO(this.untilDate);
      this.until = new Date(Date.UTC(
        until.year,
        until.month - 1,
        until.day,
        0,
        0,
        0,
      ));
    },
    options: {
      handler(val, old) {
        if (val === 'custom') {
          this.beforeCustom = old;
          this.showModal = true;
          return;
        }
        this.$emit('input', val);
        this.$emit('update:value', val);
      },
      deep: true,
    },
    endOption(val) {
      if (val === 'never') {
        this.count = null;
        this.until = null;
        return;
      }
      if (val === 'endsAt') {
        this.count = null;
        return;
      }
      this.until = null;
    },
    freq: {
      handler(val) {
        if (val !== Frequency.MONTHLY) {
          this.bysetpos = null;
        }
        if (val === Frequency.MONTHLY) {
          this.bysetpos = [1];
        }
        if (val === Frequency.WEEKLY || val === Frequency.MONTHLY) {
          this.byweekday = [this.currentWeekDayRRule];
        }
        if (val !== Frequency.WEEKLY && val !== Frequency.MONTHLY) {
          this.byweekday = [];
        }
      },
      deep: true,
    },
  },
  created() {
    this.byweekday = [this.currentWeekDayRRule];
    if (this.value !== null) {
      this.options = this.value;
      this.addCustomItem(this.value);
    }
    if (this.value === null) {
      this.$emit('input', this.options);
    }
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .recurrence-selector {
    ._select {
      min-width: 15rem;
    }
  }

  .recurrence-selector-dialog {
    ._label {
      margin-right: 1rem;
    }

    ._row {
      margin: 2rem 0;

      &:first-of-type {
        margin-top: 0;
      }

      &:last-of-type {
        margin-bottom: 0;
      }

      &.-freq-picker {
        display: flex;
        align-items: center;

        ._picker {
          display: flex;

          ._interval {
            display: inline-block;
            width: 6rem;
            margin-right: 1rem;
          }

          ._freq {
            display: inline-block;
            width: 13rem;
          }
        }
      }

      &.-weekday-picker {
        ._label {
          margin-bottom: .5rem;
        }

        ._weekdays {
          display: flex;

          ._day {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 3rem;
            height: 3rem;
            margin: .5rem;
            cursor: pointer;
            background-color: map_get($grey, 'lighten-3');
            border-radius: 1.5rem;

            &:first-of-type {
              margin-left: 0;
            }

            &:last-of-type {
              margin-right: 0;
            }

            &.-selected {
              color: white;
              background-color: $primary-color;
            }
          }
        }
      }

      &.-set-pos {
        display: flex;
        align-items: center;

        ._input-select {
          display: inline-block;
          min-width: 10rem;
          max-width: 16rem;
          margin-right: 1rem;
        }
      }

      &.-end-date {
        ._item {
          margin-bottom: 1rem;
          height: $input-height-default;

          ._radio-content {
            display: inline-block;

            ._inner {
              display: flex;
              align-items: center;

              ._label {
                width: 5rem;
              }

              ._count {
                width: 6rem;
              }

              ._suffix {
                margin-left: 1rem;
              }
            }
          }
        }
      }
    }
  }
</style>
