<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.295 3.1251C12.7393 3.12265 12.2894 3.57377 12.2918 4.1295L12.2951 4.88057C12.2975 5.42941 12.7419 5.87373 13.2907 5.87615L16.5859 5.89067L5.76357 16.713L5.76357 13.4443C5.76357 12.892 5.31585 12.4443 4.76357 12.4443L3.97105 12.4443C3.416 12.4443 2.9671 12.8942 2.97076 13.4492C2.98056 14.9361 2.99894 17.8716 3.01219 20.8746L3.01236 20.9131L5.76357 20.9253V20.9252L10.8771 20.9477C11.4328 20.9502 11.8828 20.4991 11.8803 19.9433L11.877 19.1923C11.8746 18.6434 11.4303 18.1991 10.8814 18.1967L7.97695 18.1839L18.4085 7.75229L18.4085 10.6285C18.4085 11.1808 18.8563 11.6285 19.4085 11.6285L20.2011 11.6285C20.7561 11.6285 21.205 11.1787 21.2013 10.6236C21.1915 9.13649 21.1732 6.20044 21.1599 3.19702L21.1597 3.15974L18.4085 3.14755V3.14762L13.295 3.1251Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>

<script>
export default { name: 'OpenAsPage' };
</script>

<style scoped lang="scss" type="text/scss">

</style>
