<template>
  <m-dialog
    :value="value"
    hide-header
    hide-footer
    fade-in
    :max-width="$modalSizes.md"
    @close="$emit('close')"
  >
    <div class="_content">
      <div class="_welcome-emoji">
        🎉
      </div>
      <h3>
        {{ $t('afterCheckinTourModal.congratulations') }}
      </h3>
      <div class="_description">
        {{ $t('afterCheckinTourModal.description') }}
      </div>
      <div class="_cta">
        <h5>
          {{ $t('afterCheckinTourModal.cta') }}
        </h5>
        <m-btn
          color="primary"
          block
          class="_btn"
          @click="createUpdate"
        >
          {{ $t('afterCheckinTourModal.create') }}
        </m-btn>
        <m-btn
          block
          class="_btn"
          @click="$emit('close')"
        >
          {{ $t('afterCheckinTourModal.explore') }}
        </m-btn>
      </div>
    </div>
  </m-dialog>
</template>

<script>
import useUpdateTemplates from '@/composables/update-templates/update-templates';

export default {
  name: 'AfterCheckinTourModal',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['close'],
  setup() {
    const updateTemplatesSvc = useUpdateTemplates();
    return { updateTemplates: updateTemplatesSvc.updateTemplates };
  },
  methods: {
    createUpdate() {
      if (this.updateTemplates.length === 0) {
        this.$router.push({ query: { ...this.$route.query, create: '' } });
        this.$emit('close');
        return;
      }

      this.$router.push({ query: { ...this.$route.query, create: '', templateId: this.updateTemplates[0].uid } });
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  ._content {
    text-align: center;

    ._welcome-emoji {
      font-size: 4.4rem;
    }

    h3 {
      margin-bottom: 1.2rem;
    }

    ._sub-description {
      margin-bottom: 2.4rem;
      font-size: $font-size-3;
      color: $font-color-secondary;
    }

    h5 {
      margin-top: 2rem;
      font-weight: $font-weight-bold;
    }

    ._btn {
      margin-top: .8rem;
    }
  }
</style>
