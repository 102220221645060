<template>
  <top-bar class="create-form-top-bar">
    <template #left>
      <div

        class="_left"
      >
        <div
          class="_btn"
        >
          <m-btn
            to="/surveys"
            icon="arrow-left"
            fab
            hide-border
          />
        </div>
        <div
          class="_last"
        >
          <top-bar-input
            :value="form === null ? '-' : form.title"
            type="text"
            :disabled="!writeAccess"
            :update-method="updateTitle"
          />
        </div>
        <template v-if="writeAccess">
          <m-tag
            v-if="!processActive"
            large
            :title="$t('createFormTopBar.notActive')"
          />
          <m-tag
            v-else
            large
            color="success"
            :title="$t('createFormTopBar.active')"
          />
        </template>
      </div>
    </template>
    <template #center>
      <sub-menu-tabs
        v-if="writeAccess"

        :items="centerNavItems"
        height="5.2rem"
        no-border
      />
    </template>
    <template #right>
      <div

        class="_right"
      >
        <div class="_action">
          <m-select
            v-if="showLanguageSelect"
            :items="languageItems"
            :value="lang"
            class="_select-language"
            hide-details
            height="2rem"
            background-color="white"
            @change="changeLanguage"
          >
            <template #item="content">
              <div

                class="_select-option"
              >
                {{ getName(content.item) }}
              </div>
            </template>
          </m-select>
          <m-tooltip
            placement="bottom"
          >
            <m-btn
              v-show="isShareUrl && processActive"
              outlined
              color="primary"
              class="_action-item"
              :loading="sendBtnLoading"
              @click="showDeactivatePrompt"
            >
              {{ $t('createFormTopBar.deactivateProcess') }}
            </m-btn>
            <template #title>
              <span>{{ $t('createFormTopBar.deactivateNotice') }}</span>
            </template>
          </m-tooltip>
          <m-btn
            v-if="isCreateUrl"
            class="_action-item"
            @click="showAccessManagementModel = true"
          >
            {{ $t('createFormTopBar.accessManagement') }}
          </m-btn>
          <m-dialog
            v-model:value="showAccessManagementModel"
            :title="$t('createFormTopBar.accessManagement')"
            max-width="80rem"
            hide-footer
            no-padding
          >
            <resource-policy
              :entity="form"
              :entity-by-id="formById"
              :model="formConfig.model"
              @cancel="showAccessManagementModel = false"
              @submit="showAccessManagementModel = false"
            />
          </m-dialog>
          <m-btn
            v-if="isCreateUrl"
            class="_action-item"
            :to="previewURL"
          >
            {{ $t('createFormTopBar.preview') }}
          </m-btn>
          <div v-if="isCreateUrl">
            <m-tooltip placement="bottom">
              <span>
                <m-btn
                  color="primary"
                  text
                  :disabled="!unsavedChangesExist"
                  :loading="loading"
                  @click="update"
                >
                  <template v-if="unsavedChangesExist">
                    {{ $t('general.save') }}
                  </template>
                  <template v-else>
                    {{ $t('general.saved') }}
                  </template>
                </m-btn>
              </span>
              <template #title>
                <span v-if="unsavedChangesExist">{{ $t('createForm.needsSaving') }}</span>
                <span v-else>{{ $t('createForm.allSaved') }}</span>
              </template>
            </m-tooltip>
          </div>
        </div>
      </div>
    </template>
  </top-bar>
</template>

<script>
import ResourcePolicy from '@/components/ResourcePolicy.vue';
import SubMenuTabs from '@/components/SubMenuTabs.vue';
import TopBar from '@/components/navigation/TopBar.vue';
import TopBarInput from '@/components/TopBarInput.vue';
import colors from 'shared/colors';
import useAddFormEditor from '@/composables/form/add-form-editor';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import {
  CREATE_FORM,
  FIELD_FACTORS, FORM_DETAILS, FORM_RESULTS,
  FORM_SEND_CHANNELS,
  FORM_SEND_OPTIONS,
  FORM_SEND_PARTICIPANTS,
  FORM_SEND_SETTINGS,
  FORM_SEND_VERIFY,
  FORM_SETTINGS,
  FORM_SHARE, QUESTION_BANK,
  QUESTION_BANK_TEMPLATE_DETAILS, QUESTION_BANK_TEMPLATE_LIST,
} from '@/route-names';
import { DateTime } from 'luxon';
import { RRule } from 'rrule';
import { formById } from 'shared/api/query/form';
import { form as formConfig } from 'shared/api/query/configs.json';
import { isEqual } from 'lodash-es';
import { mapActions, mapState } from 'vuex';
import { nameByCode } from '@/lib/language';
import { setStartDateInRuleString, startDateIsBeforeDate } from '@/lib/rrule';
import { writeAccess } from '@/lib/form';

export default {
  name: 'CreateFormTopBar',
  props: {
    lang: {
      type: String,
      required: true,
    },
    languages: {
      type: Array,
      required: true,
    },
    form: {
      type: Object,
      default: null,
    },
    formId: {
      type: Number,
      required: true,
    },
  },
  setup() {
    const { loggedInUser } = useLoggedInUser();
    const { appendEditor } = useAddFormEditor();
    return { user: loggedInUser, appendEditor };
  },
  emits: ['activated', 'language-changed'],
  components: {
    ResourcePolicy,
    TopBarInput,
    SubMenuTabs,
    TopBar,
  },
  data() {
    return {
      loading: false,
      sendBtnLoading: false,
      showActivation: false,
      showDeactivation: false,
      showAccessManagementModel: false,
      colors,
      formById,
      formConfig,
    };
  },
  computed: {
    ...mapState({
      unsavedFormChangesExist: (state) => state.unsavedFormChangesExist,
      unsavedSurveyProcessChangesExist: (state) => state.unsavedSurveyProcessChangesExist,
      surveyProcess: (state) => state.surveyProcess,
      origSurveyProcess: (state) => state.origSurveyProcess,
    }),
    languageItems() {
      return this.languages.map((l) => ({
        text: l,
        value: l,
      }));
    },
    writeAccess() {
      return writeAccess(this.user, this.form);
    },
    isShareUrl() {
      return [
        FORM_SHARE,
        FORM_SEND_VERIFY,
        FORM_SEND_PARTICIPANTS,
        FORM_SEND_OPTIONS,
        FORM_SEND_CHANNELS,
      ].indexOf(this.$route.name) > -1;
    },
    processActive() {
      return this.surveyProcess !== null && this.surveyProcess.activatedAt !== null;
    },
    rruleStartsInFuture() {
      const rule = RRule.fromString(this.surveyProcess.schedule);
      return new Date(rule.options.dtstart) > new Date();
    },
    unsavedChangesExist() {
      return this.unsavedFormChangesExist || this.unsavedSurveyProcessChangesExist;
    },
    previewURL() {
      return {
        name: FORM_DETAILS,
        params: { id: this.formId },
        query: { lang: this.lang },
      };
    },
    centerNavItems() {
      return [
        {
          title: this.$t('views.createForm'),
          to: { name: CREATE_FORM, params: { formId: this.formId }, query: { lang: this.lang } },
          active: [
            CREATE_FORM, FORM_SETTINGS,
            FIELD_FACTORS,
            QUESTION_BANK,
            QUESTION_BANK_TEMPLATE_LIST,
            QUESTION_BANK_TEMPLATE_DETAILS,
          ].includes(this.$route.name),
        },
        {
          title: this.$t('views.formSend'),
          to: {
            name: this.processActive ? FORM_SEND_VERIFY : FORM_SEND_SETTINGS,
            params: { formId: this.formId },
          },
          active: [
            FORM_SEND_CHANNELS,
            FORM_SEND_OPTIONS,
            FORM_SEND_PARTICIPANTS,
            FORM_SEND_SETTINGS,
            FORM_SEND_VERIFY,
            FORM_SHARE,
          ].includes(this.$route.name),
        },
        {
          title: this.$t('views.formResults'),
          to: { name: FORM_RESULTS, params: { formId: this.formId } },
          active: [FORM_RESULTS].includes(this.$route.name),
        },
      ];
    },
    showLanguageSelect() {
      const isOtherLanguageUrl = [FORM_SEND_CHANNELS].includes(this.$route.name);
      return (this.isCreateUrl || isOtherLanguageUrl) && this.languages.length > 1;
    },
    isCreateUrl() {
      return [CREATE_FORM, FORM_SETTINGS, FIELD_FACTORS, QUESTION_BANK, QUESTION_BANK_TEMPLATE_DETAILS, QUESTION_BANK_TEMPLATE_LIST].indexOf(this.$route.name) > -1;
    },
  },
  methods: {
    ...mapActions([
      'updateEntity',
      'updateSurveyProcess',
      'createSurveyProcess',
      'deactivateSurveyProcess',
    ]),
    showDeactivatePrompt() {
      const deactivateMethod = this.deactivateSurvey;
      this.$confirm({
        title: this.$t('createFormTopBar.deactivatePrompt'),
        okText: this.$t('createFormTopBar.deactivateNow'),
        okType: 'warning',
        maskClosable: true,
        cancelText: this.$t('general.cancel'),
        onOk() {
          deactivateMethod();
        },
      });
    },
    deactivateSurvey() {
      this.sendBtnLoading = true;

      const toCancel = {
        ...this.surveyProcess,
        cancelledAt: DateTime.local().toISO({ suppressMilliseconds: true }),
      };

      const newProcess = {
        ...this.surveyProcess,
        form: { uid: this.formId },
      };
      const tomorrow = DateTime.local().plus({ day: 1 }).toISO();
      if (startDateIsBeforeDate(this.surveyProcess.schedule, tomorrow)) {
        newProcess.schedule = setStartDateInRuleString(newProcess.schedule, tomorrow);
      }

      delete (newProcess.uid);

      this.deactivateSurveyProcess({ toCancel, toCreate: newProcess }).then((response) => {
        this.sendBtnLoading = false;
        if (response.status !== 200) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
          return;
        }

        this.$store.commit('UNSAVED_SURVEY_PROCESS_CHANGES_EXIST_CHANGED', false);
        this.$showSnackbar({ color: 'success', message: this.$t('createFormTopBar.deactivated') });
        this.$emit('activated');
      });
    },
    changeLanguage(value) {
      this.$emit('language-changed', { lang: value });
    },
    updateTitle(event) {
      this.$store.commit('FORM_TITLE_UPDATED', { title: event.srcElement.value });
    },
    update() {
      if (this.unsavedFormChangesExist) {
        this.saveForm();
      }
    },
    saveForm() {
      this.loading = true;
      this.updateEntity({ entity: this.appendEditor(this.form), model: 'form', entityById: formById })
        .then((response) => {
          this.loading = false;
          if (response.status !== 200) {
            this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
            return;
          }
          this.$store.commit('UNSAVED_FORM_CHANGES_EXIST_CHANGED', false);
          this.$showSnackbar({ color: 'success', message: this.$t('success.saved') });
        });
    },
    saveSurveyProcess() {
      this.loading = true;
      this.updateSurveyProcess({ surveyProcess: this.surveyProcess }).then((response) => {
        this.loading = false;
        if (response.status !== 200) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
          return;
        }
        this.$store.commit('UNSAVED_SURVEY_PROCESS_CHANGES_EXIST_CHANGED', false);
        this.$showSnackbar({ color: 'success', message: this.$t('success.saved') });
      });
    },
    getName(code) {
      return nameByCode(code);
    },
  },
  watch: {
    surveyProcess: {
      handler(newValue, oldValue) {
        if (oldValue === null || isEqual(newValue, this.origSurveyProcess)) {
          return;
        }
        this.$store.commit('UNSAVED_SURVEY_PROCESS_CHANGES_EXIST_CHANGED', true);
      },
      deep: true,
    },
    form: {
      handler(newValue, oldValue) {
        if (oldValue === null) {
          return;
        }
        this.$store.commit('UNSAVED_FORM_CHANGES_EXIST_CHANGED', true);
      },
      deep: true,
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .create-form-top-bar {
    ._left {
      display: flex;
      align-items: center;
      font-weight: 400;

      ._btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: $side-bar-collapsed-width;
        height: $side-bar-collapsed-width;
        padding: .85rem;
      }
    }

    ._right {
      display: flex;
      align-items: center;
      padding: 0 1.6rem;

      ._action {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        ._action-item {
          margin-right: .8rem;
        }

        ._select-language {
          padding: 0;
          margin-right: .8rem;
        }
      }
    }

    ._last {
      margin-right: .8rem;
    }
  }
</style>
