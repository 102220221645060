<template>
  <dashboard-card
    class="pie-chart-card"
    :title="title"
    :loading="loading"
  >
    <template
      v-if="!!$slots.header"
      #header
    >
      <slot name="header" />
    </template>
    <div class="_content">
      <pie-chart
        :chart-data="chartData"
        :clickable="clickable"
        :tooltip="tooltip"
        @click="handleClick"
      />
    </div>
  </dashboard-card>
</template>

<script>
import DashboardCard from '@/components/dashboard/DashboardCard.vue';
import PieChart from '@/components/dashboard/PieChart.vue';

export default {
  name: 'PieChartCard',
  props: {
    title: {
      type: String,
      default: '',
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    chartData: {
      type: Array,
      required: true,
    },
    tooltip: {
      type: Boolean,
      default: false,
    },
  },
  components: { PieChart, DashboardCard },
  emits: ['click'],
  methods: {
    handleClick(point) {
      if (!this.clickable) {
        return;
      }
      this.$emit('click', point);
    },
  },
};
</script>

<style scoped lang="css" type="text/css">
  .pie-chart-card ._content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
  }
</style>
