import { DEFAULT_ATTRIBUTES, UID } from 'shared/api/query/constants';
import { property as propertyConfig,
  propertyOption as propertyOptionConfig,
  propertySettings as propertySettingsConfig,
  storageObject as storageObjectConfig } from 'shared/api/query/configs.json';
import { propertySettingsEdges } from '@/api/query/nebula/property-settings';
import { propertyVisibility } from 'shared/constants.json';
import { reverseEdge } from 'shared/api/query/edges';
import { storageObjectEdges } from '@/api/query/nebula/storage-object';

export const propertyOptionEdges = [
  ...DEFAULT_ATTRIBUTES,
  { attr: propertyOptionConfig.edges.archivedAt, default: null },
  { attr: propertyOptionConfig.edges.label, default: { de: '' } },
  { attr: propertyOptionConfig.edges.icon, default: '' },
  {
    attr: propertyOptionConfig.edges.image,
    model: storageObjectConfig.model,
    children: storageObjectEdges,
    default: null,
  },
  { attr: propertyOptionConfig.edges.color, default: null },
  { attr: propertyOptionConfig.edges.isNotDeletable, default: false },
  { attr: propertyOptionConfig.edges.isObjective, default: false },
  { attr: propertyOptionConfig.edges.isKeyResult, default: false },
  { attr: propertyOptionConfig.edges.goalProgressVisibility, default: propertyVisibility.alwaysVisible },
  { attr: propertyOptionConfig.edges.goalCycleVisibility, default: propertyVisibility.alwaysVisible },
  { attr: propertyOptionConfig.edges.goalParentVisibility, default: propertyVisibility.alwaysVisible },
  { attr: propertyOptionConfig.edges.descriptionVisibility, default: propertyVisibility.alwaysVisible },
  { attr: propertyOptionConfig.edges.descriptionRequired, default: false },
  { attr: propertyOptionConfig.edges.cycleRequired, default: false },
  { attr: propertyOptionConfig.edges.parentRequired, default: false },
  { attr: propertyOptionConfig.edges.score, default: null },
  {
    attr: propertyOptionConfig.edges.requiredProperties,
    model: propertyConfig.model,
    default: [],
    children: [{ attr: UID }],
  },
  {
    attr: propertyOptionConfig.edges.allowedMeasurementTypes,
    default: [],
  },
  {
    attr: propertyOptionConfig.edges.canBeChildOf,
    model: propertyOptionConfig.model,
    default: [],
    children: [{ attr: UID }],
  },
  {
    attr: reverseEdge(propertyOptionConfig.edges.parents),
    alias: 'children',
    model: propertyOptionConfig.model,
    children: [{ attr: UID }],
    default: [],
  },
  {
    attr: propertyOptionConfig.edges.property,
    model: propertyConfig.model,
    children: [{ attr: UID }],
  },
  {
    attr: propertyOptionConfig.edges.parents,
    model: propertyOptionConfig.model,
    children: [{ attr: UID }],
    default: [],
  },
  {
    attr: reverseEdge(propertySettingsConfig.edges.propertyOption),
    alias: 'propertySettings',
    model: propertySettingsConfig.model,
    children: propertySettingsEdges,
  },
];
