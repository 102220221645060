<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="2.48096"
      y="3.30774"
      width="14.0577"
      height="13.2308"
      fill="white"
    />
    <path
      d="M6.3572 10.5432H9.30311V13.4892C9.30311 13.5744 9.37288 13.6442 9.45816 13.6442H10.3884C10.4737 13.6442 10.5435 13.5744 10.5435 13.4892V10.5432H13.4894C13.5747 10.5432 13.6445 10.4735 13.6445 10.3882V9.45791C13.6445 9.37264 13.5747 9.30287 13.4894 9.30287H10.5435V6.35695C10.5435 6.27168 10.4737 6.2019 10.3884 6.2019H9.45816C9.37288 6.2019 9.30311 6.27168 9.30311 6.35695V9.30287H6.3572C6.27192 9.30287 6.20215 9.37264 6.20215 9.45791V10.3882C6.20215 10.4735 6.27192 10.5432 6.3572 10.5432Z"
      fill="currentColor"
    />
    <path
      d="M17.0555 2.17065H2.79109C2.44805 2.17065 2.1709 2.4478 2.1709 2.79085V17.0553C2.1709 17.3983 2.44805 17.6755 2.79109 17.6755H17.0555C17.3986 17.6755 17.6757 17.3983 17.6757 17.0553V2.79085C17.6757 2.4478 17.3986 2.17065 17.0555 2.17065ZM16.2803 16.28H3.56633V3.56609H16.2803V16.28Z"
      fill="currentColor"
    />
    <rect
      x="4.96191"
      y="5.78845"
      width="14.0577"
      height="13.2308"
      fill="white"
    />
    <path
      d="M8.83815 13.024H11.7841V15.9699C11.7841 16.0552 11.8538 16.1249 11.9391 16.1249H12.8694C12.9547 16.1249 13.0245 16.0552 13.0245 15.9699V13.024H15.9704C16.0556 13.024 16.1254 12.9542 16.1254 12.8689V11.9386C16.1254 11.8533 16.0556 11.7836 15.9704 11.7836H13.0245V8.83767C13.0245 8.75239 12.9547 8.68262 12.8694 8.68262H11.9391C11.8538 8.68262 11.7841 8.75239 11.7841 8.83767V11.7836H8.83815C8.75288 11.7836 8.68311 11.8533 8.68311 11.9386V12.8689C8.68311 12.9542 8.75288 13.024 8.83815 13.024Z"
      fill="currentColor"
    />
    <path
      d="M19.5365 4.65137H5.27205C4.929 4.65137 4.65186 4.92852 4.65186 5.27156V19.536C4.65186 19.879 4.929 20.1562 5.27205 20.1562H19.5365C19.8795 20.1562 20.1567 19.879 20.1567 19.536V5.27156C20.1567 4.92852 19.8795 4.65137 19.5365 4.65137ZM18.7612 18.7607H6.04729V6.0468H18.7612V18.7607Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default { name: 'ExpandPlus' };
</script>

<style scoped lang="scss" type="text/scss">

</style>
