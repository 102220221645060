<template>
  <div class="space-scope">
    <m-select
      v-if="!hideOp"
      v-model:value="selectedType"
      :items="operatorTypes"
      :read-only="props.readOnly"
      :disabled="props.disabled"
      class="_op"
    />
    <template
      v-if="!isEmpty"
    >
      <space-selector
        v-model:value="selectedSpaces"
        class="_select"
        full-width
        :read-only="props.readOnly"
        :disabled="disabled"
        :property-label="property.label"
        :max-tag-text-length="props.maxTagTextLength"
        multiple
        wrap
        :truncate="5"
      />
    </template>
  </div>
</template>

<script setup>
import SpaceSelector from '@/components/spaces/SpaceSelector.vue';
import useProperties from '@/composables/property/property';
import { AND, EMPTY, NOT, NOT_EMPTY } from '@/lib/filter/scope-tree';
import { computed, ref, watch } from 'vue';
import { copy } from 'shared/lib/copy';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  value: {
    type: Object,
    required: true,
  },
  isNot: {
    type: Boolean,
    required: true,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  hideOp: {
    type: Boolean,
    default: false,
  },
  maxTagTextLength: {
    type: Number,
    default: 0,
  },
  hideIsEmpty: {
    type: Boolean,
    default: false,
  },
});
const propertySvc = useProperties();
const property = propertySvc.selectSingle(props.value.property.uid);

const emit = defineEmits(['input', 'update:value', 'change-not', 'change']);

const { t } = useI18n();

const selectedSpaces = ref([]);
const isEmpty = ref(false);

const operatorTypes = computed(() => {
  if (props.hideIsEmpty) {
    return [
      { text: t('optionScope.contains'), value: AND },
      { text: t('optionScope.notContains'), value: NOT },
    ];
  }
  return [
    { text: t('optionScope.contains'), value: AND },
    { text: t('optionScope.notContains'), value: NOT },
    { text: t('optionScope.isEmpty'), value: EMPTY },
    { text: t('optionScope.isNotEmpty'), value: NOT_EMPTY },
  ];
});

const updateEmpty = (type) => {
  if ([EMPTY, NOT_EMPTY].includes(type)) {
    isEmpty.value = true;
    return;
  }
  isEmpty.value = false;
};

const selectedType = computed({
  get() {
    if (props.isNot) {
      if (props.isEmpty) {
        return NOT_EMPTY;
      }
      return NOT;
    }
    if (props.isEmpty) {
      return EMPTY;
    }
    return AND;
  },
  set(val) {
    updateEmpty(val);
    if ([NOT, NOT_EMPTY].includes(val)) {
      emit('change-not', NOT);
      return;
    }
    emit('change-not', AND);
  },
});

const updateValues = () => {
  isEmpty.value = false;
  if (props.value !== null && props.value.isEmpty !== undefined) {
    isEmpty.value = props.value.isEmpty;
  }

  if (props.value === null || props.value.spaces === null || props.value.spaces === undefined) {
    selectedSpaces.value = [];
    return;
  }
  selectedSpaces.value = props.value.spaces;
};
watch(props.value, () => updateValues(), { deep: true });
updateValues();

const emitUpdate = (val) => {
  emit('input', val);
  emit('update:value', val);
  emit('change', val);
};

const scope = computed(() => ({
  ...props.value,
  spaces: selectedSpaces.value,
  isEmpty: isEmpty.value,
}));

watch(
  scope,
  (newVal, oldVal) => {
    if (JSON.stringify(oldVal) === JSON.stringify(newVal)) {
      return;
    }

    const cp = copy(newVal);
    emitUpdate(cp);
  },
  { deep: true },
);

</script>

<style scoped lang="scss" type="text/scss">
  .space-scope {
    display: flex;

    ._op {
      display: block;
      margin-right: .4rem;
    }

    ._select {
      min-width: 15rem;
    }
  }
</style>
