<template>
  <div class="form-send-settings">
    <h4>
      {{ $t('formSendSettings.surveyTypeHeading') }}
    </h4>
    <p>{{ $t('formSendSettings.surveyTypeSubHeading') }}</p>
    <icon-card-input
      class="_mode-switch"
      :value="pulseMode"
      :items="items"
      width="21rem"
      height="15rem"
      @change="updatePulseMode"
    />
    <m-transition-expand>
      <div
        v-show="pulseMode"
        class="_pulse-details"
      >
        <h4 class="">
          {{ $t('formSendSettings.questionsPerPulseHeading') }}
        </h4>
        <p>
          {{ $t('formSendSettings.questionsPerPulseDescription') }}
        </p>
        <number-select-input
          :value="questionsPerPulse"
          :min="1"
          :max="form.orderItems.length"
          :start="1"
          :end="end"
          custom
          @change="updateQuestionsPerPulse"
        />
        <div class="_pulse-score _section">
          <h4 :style="{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }">
            {{ $t('formSendSettings.pulseCalculationHeading') }}
            <info-hint
              :description="$t('formSendSettings.pulseCalculationInfo')"
              class="_hint"
            />
          </h4>
          <m-switch
            v-model:value="pulseCalculation"
            class="_switch"
          />
        </div>
        <p>
          {{ $t('formSendSettings.pulseCalculationDescription') }}
        </p>
      </div>
    </m-transition-expand>
    <h4 class="_section">
      {{ $t('formSendSettings.privacyThreshold') }}
    </h4>
    <p>
      {{ $t('formSendSettings.privacyThresholdDescription') }}
    </p>
    <number-select-input
      :value="privacyThreshold"
      :min="1"
      :max="50"
      :start="1"
      :end="7"
      custom
      @change="updatePrivacyThreshold"
    />
  </div>
</template>

<script>
import IconCardInput from '@/components/IconCardInput.vue';
import InfoHint from '@/components/InfoHint.vue';
import NumberSelectInput from '@/components/NumberSelectInput.vue';

export default {
  name: 'FormSendSettings',
  props: {
    surveyProcess: {
      type: Object,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
  },
  components: { InfoHint, NumberSelectInput, IconCardInput },
  computed: {
    pulseMode: {
      get() {
        return this.surveyProcess.pulseMode;
      },
      set(value) {
        this.$store.commit('PULSE_MODE_CHANGED', value);
      },
    },
    end() {
      return Math.min(5, this.form.orderItems.length);
    },
    pulseCalculation: {
      get() {
        return this.surveyProcess.form.pulseCalculation;
      },
      set(value) {
        this.$store.commit('PULSE_CALCULATION_CHANGED', value);
      },
    },
    questionsPerPulse: {
      get() {
        return this.surveyProcess.questionsPerPulse;
      },
      set(value) {
        this.$store.commit('QUESTIONS_PER_PULSE_MODE_CHANGED', value);
      },
    },
    privacyThreshold: {
      get() {
        return this.surveyProcess.form.privacyThreshold;
      },
      set(value) {
        this.$store.commit('PRIVACY_THRESHOLD_CHANGED', value);
      },
    },
    items() {
      return [
        {
          value: false,
          icon: 'file-text',
          title: this.$t('formSendSettings.standardSurveyTitle'),
          description: this.$t('formSendSettings.standardSurveyDescription'),
        },
        {
          value: true,
          icon: 'pulse',
          title: this.$t('formSendSettings.pulseSurveyTitle'),
          description: this.$t('formSendSettings.pulseSurveyDescription'),
        },
      ];
    },
  },
  methods: {
    updatePulseMode(value) {
      this.pulseMode = value;
      this.pulseCalculation = value;
    },
    updateQuestionsPerPulse(value) {
      this.questionsPerPulse = value;
    },
    updatePrivacyThreshold(value) {
      this.privacyThreshold = value;
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .form-send-settings {
    ._section {
      margin-top: 2rem;
    }

    ._mode-switch {
      margin-bottom: 2rem;
    }

    ._pulse-details {
      padding-bottom: 2rem;

      ._pulse-score {
        display: flex;

        ._hint {
          margin-left: .6rem;
        }

        ._switch {
          margin-left: auto;
        }
      }
    }

    h4 {
      margin-bottom: .4rem;
    }

    p {
      margin-bottom: 2rem;
    }
  }
</style>
