import * as actions from '@/store/actions';
import * as getters from '@/store/getters';
import mutations from '@/store/mutations';
import { createStore } from 'vuex';
import { getDefaultState } from '@/store/helper';
import { initNebula } from '@/nebula';

const debug = import.meta.env.DEV;

const state = getDefaultState();

const nebula = initNebula();

const store = {
  state,
  actions,
  mutations,
  getters,
  modules: { nebula },
};

/* eslint-disable new-cap */
export default new createStore({
  ...store,
  strict: debug,
});
/* eslint-enable new-cap */
