<template>
  <span class="time-difference">
    {{ amount }} {{ unit }} {{ $t('timeDifference.afterInitialMail') }}
  </span>
</template>

<script>
import { Hour, Minute } from 'shared/lib/time';

const ONE_DAY = 24 * Hour;
const ONE_HOUR = Hour;
const ONE_MINUTE = Minute;

export default {
  name: 'TimeDifference',
  props: {
    duration: {
      type: Number,
      required: true,
    },
  },
  computed: {
    unit() {
      switch (true) {
        case this.duration % ONE_DAY === 0:
          return this.$t('timeDifference.day', this.duration / this.scalarUnit);
        case this.duration % ONE_HOUR === 0:
          return this.$t('timeDifference.hour', this.duration / this.scalarUnit);
        case this.duration % ONE_MINUTE === 0:
          return this.$t('timeDifference.minute', this.duration / this.scalarUnit);
        default:
          return '';
      }
    },
    scalarUnit() {
      switch (true) {
        case this.duration % ONE_DAY === 0:
          return ONE_DAY;
        case this.duration % ONE_HOUR === 0:
          return ONE_HOUR;
        case this.duration % ONE_MINUTE === 0:
          return ONE_MINUTE;
        default:
          return 0;
      }
    },
    amount() {
      return this.duration / this.scalarUnit;
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">

</style>
