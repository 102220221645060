<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!--    eslint-disable-->
    <path
      :fill="c"
      d="M8.95198 4.00078C8.67307 4.01692 8.41798 4.22751 8.33805 4.49486L6.29726 11.8748H2.68565C2.32681 11.8668 2 12.1989 2 12.5634C2 12.9197 2.32681 13.2522 2.68565 13.2438H6.80746C7.09459 13.2519 7.38143 13.0332 7.46136 12.7495L8.9123 7.476L11.5911 19.4734C11.6631 19.765 11.942 19.992 12.2368 19.9998C12.5319 20.0079 12.819 19.7895 12.8986 19.4976L15.6412 9.42031L17.0683 12.8304C17.1718 13.0736 17.4272 13.2438 17.6902 13.2438H21.589C21.9396 13.2519 22.2667 12.9197 22.2667 12.5634C22.2667 12.1989 21.9399 11.867 21.589 11.8748H18.1366L16.1355 7.06288C16.0239 6.8036 15.745 6.63362 15.466 6.64976C15.1948 6.66589 14.94 6.87677 14.8598 7.15218L12.3164 16.5087L9.64556 4.5277C9.57414 4.21973 9.2632 3.98465 8.95198 4.00078Z"
    />
    <!--    eslint-enable-->
  </svg>
</template>

<script>
export default {
  name: 'Pulse',
  props: {
    color: {
      type: String,
      default: '',
    },
  },
  computed: {
    c() {
      if (this.color === '') {
        return this.$colors.grey.darken2;
      }

      return this.color;
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">

</style>
