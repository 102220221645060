import { ONE_TO_ONE, RESULT, UID, VAR } from 'shared/api/query/constants';
import {
  factorField,
  field,
  form,
  formFactor,
  formFieldValidation,
  formOrderItem,
  opinionScaleProperties,
  resourcePolicy,
  user,
  userScopeTree,
  welcomeScreen,
} from 'shared/api/query/configs.json';
import { formDefaultEdges, reverseEdge } from 'shared/api/query/edges';

import { orderItemsMiddleware } from 'shared/constants.json';

import { userScopeTreeChildren } from 'shared/api/query/user-scope';

export const formWithCalculatedOrderItem = (id) => [
  {
    uid: [id],
    model: form.model,
    alias: RESULT,
    func: { name: UID },
    children: [
      ...formDefaultEdges,
      {
        attr: form.edges.creator,
        model: user.model,
        assoc: ONE_TO_ONE,
        children: [
          { attr: UID },
          { attr: user.edges.firstName },
          { attr: user.edges.lastName },
        ],
      },
      {
        attr: form.edges.editors,
        model: user.model,
        default: [],
        children: [
          { attr: UID },
          { attr: user.edges.firstName },
          { attr: user.edges.lastName },
        ],
      },
      { attr: orderItemsMiddleware.computedOrderItems, alias: form.edges.orderItems },
      {
        attr: form.edges.welcomeScreen,
        model: welcomeScreen.model,
        assoc: ONE_TO_ONE,
        default: null,
        children: [
          { attr: UID },
          { attr: welcomeScreen.edges.title },
          { attr: welcomeScreen.edges.description },
        ],
      },
    ],
  },
];

export const formById = (id, alias = RESULT) => [
  {
    uid: [id],
    model: form.model,
    alias,
    func: { name: UID },
    children: [
      ...formDefaultEdges,
      { attr: form.edges.pulseCalculation, default: false },
      {
        attr: form.edges.creator,
        model: user.model,
        assoc: ONE_TO_ONE,
        children: [
          { attr: UID },
          { attr: user.edges.firstName },
          { attr: user.edges.lastName },
        ],
      },
      {
        attr: form.edges.editors,
        model: user.model,
        default: [],
        children: [
          { attr: UID },
          { attr: user.edges.firstName },
          { attr: user.edges.lastName },
        ],
      },
      {
        attr: form.edges.formFactors,
        model: formFactor.model,
        default: [],
        order: [
          {
            attr: formFactor.edges.order,
            desc: false,
          },
        ],
        children: [
          { attr: UID },
          { attr: formFactor.edges.title },
          { attr: formFactor.edges.order, default: 0 },
          {
            attr: formFactor.edges.factorFields,
            default: [],
            model: factorField.model,
            children: [
              { attr: UID },
              {
                attr: factorField.edges.field,
                model: field.model,
                children: [
                  { attr: UID },
                  { attr: field.edges.type },
                  { attr: field.edges.title, default: { de: '' } },
                ],
              },
            ],
          },
        ],
      },
      {
        attr: form.edges.orderItems,
        model: formOrderItem.model,
        order: [
          {
            attr: formOrderItem.edges.value,
            desc: false,
          },
        ],
        default: [],
        children: [
          { attr: UID },
          { attr: formOrderItem.edges.value },
          {
            model: field.model,
            attr: formOrderItem.edges.field,
            assoc: ONE_TO_ONE,
            children: [
              { attr: UID },
              { attr: field.edges.type },
              { attr: field.edges.title, default: { de: '' } },
              {
                attr: field.edges.templateField,
                model: field.model,
                children: [
                  { attr: UID },
                  { attr: field.edges.type },
                ],
              },
              { attr: field.edges.allowComment, default: false },
              {
                attr: field.edges.opinionScaleProperties,
                model: opinionScaleProperties.model,
                assoc: ONE_TO_ONE,
                children: [
                  { attr: UID },
                  { attr: opinionScaleProperties.edges.steps },
                  { attr: opinionScaleProperties.edges.startsFromZero, default: false },
                  { attr: opinionScaleProperties.edges.labelLeft, default: {} },
                  { attr: opinionScaleProperties.edges.labelCenter, default: {} },
                  { attr: opinionScaleProperties.edges.labelRight, default: {} },
                ],
              },
              {
                attr: field.edges.validations,
                model: formFieldValidation.model,
                assoc: ONE_TO_ONE,
                children: [
                  { attr: UID },
                  { attr: formFieldValidation.edges.required },
                ],
              },
            ],
          },
        ],
      },
      {
        attr: form.edges.welcomeScreen,
        model: welcomeScreen.model,
        assoc: ONE_TO_ONE,
        default: null,
        children: [
          { attr: UID },
          { attr: welcomeScreen.edges.title },
          { attr: welcomeScreen.edges.description },
        ],
      },
      {
        attr: form.edges.resourcePolicy,
        model: resourcePolicy.model,
        default: {},
        children: [
          { attr: UID },
          {
            attr: resourcePolicy.edges.writeScope,
            model: userScopeTree.model,
            default: null,
            children: userScopeTreeChildren,
          },
          {
            attr: resourcePolicy.edges.write,
            model: user.model,
            default: [],
            children: [{ attr: 'uid' }, { attr: 'email' }],
          },
        ],
      },
      {
        attr: form.edges.analyticAccess,
        model: resourcePolicy.model,
        default: {},
        children: [
          { attr: UID },
          {
            attr: resourcePolicy.edges.readScope,
            model: userScopeTree.model,
            default: null,
            children: userScopeTreeChildren,
          },
        ],
      },
    ],
  },
];

export const
  formList = (loggedInUser) => [
    {
      alias: VAR,
      model: user.model,
      uid: [loggedInUser.uid],
      func: { name: UID },
      children: [
        {
          attr: reverseEdge(form.edges.creator),
          model: form.model,
          children: [{ attr: UID, var: 'via_creator_form' }],
        },
        {
          attr: reverseEdge(resourcePolicy.edges.write),
          model: resourcePolicy.model,
          children: [
            {
              attr: reverseEdge(form.edges.resourcePolicy),
              model: form.model,
              children: [{ attr: UID, var: 'via_resourcepolicy_form' }],
            },
          ],
        },
        {
          attr: reverseEdge(resourcePolicy.edges.read),
          model: resourcePolicy.model,
          children: [
            {
              attr: reverseEdge(form.edges.analyticAccess),
              model: form.model,
              children: [{ attr: UID, var: 'via_analyticaccess_form' }],
            },
          ],
        },
      ],
    },
    {
      model: form.model,
      alias: RESULT,
      needsVar: [
        { name: 'via_creator_form', typ: 1 },
        { name: 'via_resourcepolicy_form', typ: 1 },
        { name: 'via_analyticaccess_form', typ: 1 },
      ],
      func: {
        name: 'uid',
        needsVar: [
          { name: 'via_creator_form', typ: 1 },
          { name: 'via_resourcepolicy_form', typ: 1 },
          { name: 'via_analyticaccess_form', typ: 1 },
        ],
      },
      children: [
        ...formDefaultEdges,
        {
          attr: form.edges.creator,
          model: user.model,
          assoc: ONE_TO_ONE,
          default: {},
          children: [
            { attr: UID },
            { attr: user.edges.firstName },
            { attr: user.edges.lastName },
          ],
        },
        {
          attr: form.edges.resourcePolicy,
          model: resourcePolicy.model,
          default: {},
          children: [
            { attr: UID },
            {
              attr: resourcePolicy.edges.write,
              model: user.model,
              default: [],
              children: [{ attr: 'uid' }, { attr: 'email' }],
            },
          ],
        },
        {
          attr: form.edges.editors,
          model: user.model,
          default: [],
          children: [
            { attr: UID },
            { attr: user.edges.firstName },
            { attr: user.edges.lastName },
          ],
        },
        {
          attr: form.edges.isDemo,
          default: false,
        },
        {
          attr: form.edges.orderItems,
          model: formOrderItem.model,
          order: [
            {
              attr: formOrderItem.edges.value,
              desc: false,
            },
          ],
          default: [],
          children: [
            { attr: UID },
            { attr: formOrderItem.edges.value },
            {
              model: field.model,
              attr: formOrderItem.edges.field,
              assoc: ONE_TO_ONE,
              children: [
                { attr: UID },
                { attr: field.edges.type },
                { attr: field.edges.title },
                {
                  attr: field.edges.opinionScaleProperties,
                  model: opinionScaleProperties.model,
                  assoc: ONE_TO_ONE,
                  children: [
                    { attr: UID },
                    { attr: opinionScaleProperties.edges.steps },
                    { attr: opinionScaleProperties.edges.labelLeft },
                    { attr: opinionScaleProperties.edges.labelCenter },
                    { attr: opinionScaleProperties.edges.labelRight },
                  ],
                },
                {
                  attr: field.edges.validations,
                  model: formFieldValidation.model,
                  assoc: ONE_TO_ONE,
                  children: [
                    { attr: UID },
                    { attr: formFieldValidation.edges.required },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ];
